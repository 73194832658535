import isEmpty from "lodash/isEmpty";
import { array, arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";

import AccountsList from "pages/accounts/List";
import EquivalentTotalBalance from "pages/accounts/_components/EquivalentTotalBalance/EquivalentTotalBalance";
import Button from "pages/_components/Button";
import GeneralMsg from "pages/_components/GeneralMsg";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import * as configUtil from "util/config";

class Accounts extends Component {
    static propTypes = {
        isRequestAvailable: bool,
        accounts: arrayOf(array).isRequired,
        dispatch: func.isRequired,
        isMobile: bool,
        fetching: bool,
        equivalentTotalBalance: string,
        equivalentTotalBalanceDollars: string,
        history: func.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        isRequestAvailable: false,
        isMobile: false,
        fetching: false,
        equivalentTotalBalance: "",
        equivalentTotalBalanceDollars: "",
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.listAccounts());
    }

    renderHeader = () => {
        const { isMobile, activeEnvironment } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                addLinkToLabel="accounts.new"
                addLinkTo="/formCustom/accountOpening"
                showPlusIcon={!!activeEnvironment.permissions.accountOpening}
                accessibilityTextId="menu.accounts"
            />
        );
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/formCustom/accountOpening"));
    };

    render() {
        const {
            accounts,
            equivalentTotalBalance,
            equivalentTotalBalanceDollars,
            fetching,
            isRequestAvailable,
            history,
            isMobile,
            activeEnvironment,
        } = this.props;
        const isLoading = fetching;
        return (
            <Fragment>
                <Notification scopeToShow="accounts" />

                {this.renderHeader()}

                <MainContainer showLoader={isLoading}>
                    <div className="container-left">
                        {!isLoading && (
                            <>
                                <div className="title-left-header">
                                    <I18n id="menu.accounts" component="h1" />
                                </div>
                            </>
                        )}
                        {accounts.length ? (
                            <>
                                {isMobile && (
                                    <Button
                                        label="accounts.new"
                                        bsStyle="secondary"
                                        block
                                        image="images/plusWhite.svg"
                                        onClick={() => history.push("/formCustom/accountOpening")}
                                        className="ui-mb-8"
                                    />
                                )}
                                {!isEmpty(accounts) && equivalentTotalBalance != null && (
                                    <div className="detail-head-info-accounts">
                                        <EquivalentTotalBalance
                                            quantity={equivalentTotalBalance}
                                            currency={configUtil.get("core.masterCurrency")}
                                        />
                                        <EquivalentTotalBalance
                                            quantity={equivalentTotalBalanceDollars}
                                            currency="USD"
                                        />
                                    </div>
                                )}
                                <AccountsList accounts={accounts} history={history} isMobile={isMobile} />
                            </>
                        ) : (
                            <GeneralMsg
                                imagePath="images/coloredIcons/accounts.svg"
                                description={
                                    <I18n
                                        id={
                                            activeEnvironment.permissions.environmentHasNoAccounts
                                                ? `accounts.list.empty`
                                                : `accounts.list.noPermission`
                                        }
                                    />
                                }
                                callToAction={
                                    isRequestAvailable && (
                                        <Link className="btn btn-primary btn-block" to="/formCustom/accountOpening">
                                            <I18n id="accounts.new" />
                                        </Link>
                                    )
                                }
                            />
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    equivalentTotalBalance: accountsSelectors.getEquivalentTotalBalance(state),
    equivalentTotalBalanceDollars: accountsSelectors.getEquivalentTotalBalanceDollars(state),
    fetching: accountsSelectors.getFetching(state),
    isRequestAvailable: sessionSelectors
        .getActiveEnvironmentForms(state, "accounts")
        .find(({ idForm }) => idForm === "accountOpening"),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Accounts);
