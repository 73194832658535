import * as communicationMiddleware from "middleware/communication";
import * as fileMiddleware from "middleware/file";
import { push } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

import { actions, selectors, types } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";

import { download } from "util/download";

const sagas = [
    takeLatest([types.DETAIL_REQUEST], detailRequest),
    takeLatest([types.DELETE_REQUEST], deleteRequest),
    takeLatest([types.SEND_REQUEST], sendRequest),
    takeLatest([types.DOWNLOAD_ATTACHMENT_REQUEST], downloadAttachment),
    takeLatest([types.MARK_AS_READ_REQUEST], markAsReadRequest),
    takeLatest([types.MARK_AS_UNREAD_REQUEST], markAsUnReadRequest),
    takeLatest([types.REPLY_REQUEST], replyRequest),
    takeLatest([types.SELECT_ATTACHMENT], selectAttachment),
    takeLatest([types.START_PRELOADED_MESSAGE], startPreloadedMessage),
];

export default sagas;

function* detailRequest({ idCommunication, index }) {
    const response = yield call(communicationMiddleware.detailRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.detailFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { communicationThread, attachments } = response.data.data;
        yield put(actions.detailSuccess(communicationThread, attachments, index));
    }
}

function* deleteRequest({ idCommunication, isDesktop }) {
    const response = yield call(communicationMiddleware.deleteRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.deleteFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.deleteSuccess());

        if (isDesktop) {
            yield put(communicationsActions.listRequest({}));
        } else {
            yield put(push("/communications"));
        }
    }
}

function* sendRequest({ idCommunicationTray, subject, body, files, isDesktop, isSuspiciousActivity }) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");

        if (idFileList.hasError) {
            yield put(actions.sendFailure());
            yield put(
                notificationActions.showNotification(i18n.get(`returnCode.${idFileList.code}`), "error", [
                    "communications",
                    "communicationCompose",
                ]),
            );
        } else {
            const response = yield call(
                communicationMiddleware.sendRequest,
                idCommunicationTray,
                subject,
                body,
                idFileList,
                isSuspiciousActivity,
            );
            if (response.type === "W") {
                yield put(actions.sendFailure());
                yield put(
                    notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                        "communications",
                        "communicationCompose",
                    ]),
                );
            } else {
                yield put(actions.sendSuccess());
                yield put(
                    notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                        "communications",
                    ]),
                );
                if (isDesktop) {
                    yield put(communicationsActions.listRequest({}));
                    yield put(communicationsActions.hideAllPanels());
                    yield put(actions.resetPreloadedMessage());
                } else {
                    yield put(push("/communications"));
                }
            }
        }
    } catch (e) {
        yield put(actions.sendFailure);
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
                "communicationCompose",
            ]),
        );
    }
}

function* replyRequest({
    idCommunicationTray,
    subject,
    body,
    idCommunication,
    communicationPriority,
    files,
    isDesktop,
}) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");
        const response = yield call(
            communicationMiddleware.replyRequest,
            idCommunicationTray,
            subject,
            body,
            idFileList,
            idCommunication,
            communicationPriority,
        );

        if (response.type === "W") {
            yield put(actions.replyFailure());
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                    "communications",
                ]),
            );
        } else {
            yield put(actions.replySuccess());
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                    "communications",
                ]),
            );
            if (isDesktop) {
                yield put(communicationsActions.listRequest({}));
                yield put(actions.detailRequest(idCommunication));
                yield put(communicationsActions.showReadPanel());
            } else {
                yield put(push(`/communications/read/${idCommunication}`));
            }
        }
    } catch (e) {
        yield put(actions.replyFailure());
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
            ]),
        );
    }
}

function* uploadFiles(files, idActivity) {
    if (!files) {
        return [];
    }

    const responses = yield files.map((f) => call(fileMiddleware.upload, f.content, f.name, "", idActivity));
    const result = responses.filter((r) => r.type === "W");

    if (result.length > 0) {
        return {
            hasError: true,
            code: result[0].data.code,
        };
    }
    return responses.map((r) => r.data.data.file.idFile);
}

function* downloadAttachment({ idFile }) {
    const response = yield call(fileMiddleware.download, idFile);

    if (response.type === "W") {
        yield put(actions.sendFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { fileName, content } = response.data.data;

        download(fileName, content);

        yield put(actions.downloadAttachmentSuccess());
    }
}

function* markAsReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.markAsReadSuccess());
    }
}

function* markAsUnReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsUnReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.markAsUnReadSuccess());
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.readAsDataURL(file);
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

function* selectAttachment({ file }) {
    const selectedAttachmentsResult = yield select(selectors.selectedAttachments);
    const exitingFiles = selectedAttachmentsResult.filter((attachment) => attachment.name === file.name);

    const maxFileSize = yield call(configUtils.get, "core.maxFileSize");

    if (file?.size > maxFileSize) {
        yield put(notificationActions.showNotification(i18n.get("returnCode.COR080W"), "error", ["communications"]));
    } else if (exitingFiles.length > 0) {
        yield put(
            notificationActions.showNotification(i18n.get("communications.attachment.selected"), "error", [
                "communications",
            ]),
        );
    } else {
        const result = yield call(readFileAsDataUrl, file);
        yield put(actions.setAttachment({ name: file.name, size: file.size, content: result.split(",")[1] }));
    }
}

function* startPreloadedMessage({ isSuspicious, isMobile, origin }) {
    yield put(
        actions.preloadedMessage({
            subject: i18n.get("communications.suspectActivity.defaultSubject"),
            tray: i18n.get("communications.suspectActivity.defaultTray"),
            body: i18n.get(`communications.suspectActivity.defaultBody.${origin}`),
            suspiciousActivity: isSuspicious,
        }),
    );

    if (isMobile) {
        yield put(push("/communications/compose"));
    } else {
        yield put(push("/communications"));
    }
}
