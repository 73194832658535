import React, { useEffect, useState } from "react";

import { Field, withFormik } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { bool, shape, func, string } from "prop-types";
import { selectors as accountsSelectors } from "reducers/accounts";
import ExternalLinkComponent from "pages/_components/ExternalLink";
import I18n from "pages/_components/I18n";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "account.qr.enrollment";
export const ID_ACTIVITY = `${ID_FORM}.send`;
const EnrollmentQRForm = (props) => {
    const { mode, dispatch, location, transaction, currentLang, fromBackoffice, account, history } = props;
    const [subtitle, setSubtitle] = useState(null);
    const [isCheckboxEnabled, enableCheckbox] = useState(false);
    const isTicket = location.pathname.match("/transaction/") != null;

    useEffect(() => {
        if (mode === "edit" && !account) {
            history.goBack();
        }

        if (mode === "edit") {
            dispatch(
                formActions.previewForm({
                    formikBag: { props: { metadata: {} } },
                    idActivity: ID_ACTIVITY,
                    idTransaction: null,
                    values: { termsCondition: "0" },
                }),
            );
        }
    }, [dispatch, location, transaction, account, mode, history]);

    useEffect(() => {
        if (account) {
            const { formattedAccountNumber, productType } = account;
            const productTypeLabel = i18n.get(`productType.${productType}`);
            setSubtitle(`${productTypeLabel} Nro ${formattedAccountNumber}`);
        } else if (isTicket) {
            const {
                transaction: { data },
            } = props;
            setSubtitle(data?.accountLabel || null);
        }
    }, [account, transaction]);
    const updateParamsSubmit = (termsCondition) => {
        dispatch(
            formActions.addExtraParams({
                termsCondition,
                cbu: account.cbu,
                accountLabel: subtitle,
            }),
        );
    };

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: transaction?.idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const renderFields = () => (
        <>
            {mode !== "view" && (
                <>
                    <I18n
                        id="forms.account.qr.enrollment.terms1"
                        component="p"
                        componentProps={{ className: "ui-mt-0" }}
                    />
                    <I18n id="forms.account.qr.enrollment.terms2" component="p" />

                    <div className="ui-mt-3 ui-mb-7">
                        <ExternalLinkComponent
                            key="termsAndConditions"
                            className="termsAndConditionsLink"
                            name="termsAndConditionsLink"
                            label="forms.account.qr.download.link"
                            to={configUtils.get(
                                "account.qr.termsAndConditions.url",
                                "https://www.mariva.com.ar/sites/default/files/modo_-_teminos_y_condiciones_del_servicio_de_aceptacion_pct.pdf",
                            )}
                            handleClick={() => enableCheckbox(true)}
                        />
                    </div>
                </>
            )}

            {mode !== "view" && !isTicket && (
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.CheckBoxSelect}
                    key="termsCondition"
                    name="termsCondition"
                    idField="termsCondition"
                    labelCheck="forms.account.qr.enrollment.terms.check"
                    editable="true"
                    disabled={!isCheckboxEnabled}
                    onChangeParent={(value) => {
                        updateParamsSubmit(value);
                    }}
                />
            )}
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(transaction.idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: "forms.account.qr.enrollment.formName",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        noPre: true,
        returnBack: true,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle,
        subtitleClean: true,
        handleClickDownload,
        exportList: "pdf",
        confirmLabelEmpty: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: false,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    previewData: formSelectors.getPreviewData(state),
});
EnrollmentQRForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    account: shape({}),
    history: shape({ goBack: func }),
};

EnrollmentQRForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    account: {},
    history: null,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(EnrollmentQRForm);
