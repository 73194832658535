import * as b64toBlob from "b64-to-blob";
import FileDownload from "js-file-download";
/* eslint-disable */
const ANDROID_SAVE_DIRECTORY_NAME = "Download";

const fakeClick = (element) => {
    const event = document.createEvent("MouseEvents");

    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(event);
};

export const downloadPdf = (name, data) => {
    const exportBlob = b64toBlob(data, "application/pdf");
    if (window.cordova) {
        return new Promise((resolve, reject) => {
            saveFileMobile(name, exportBlob)
                .then((resp) => resolve(resp))
                .catch((error) => reject(error));
        });
    }

    const urlObject = window.URL || window.webkitURL || window;
    if ("msSaveBlob" in navigator) {
        /**
         *
         * Prefer msSaveBlob if available - Edge supports a[download] but ignores the filename provided,
         * using the blob UUID instead
         * msSaveBlob will respect the provided filename
         */
        navigator.msSaveBlob(exportBlob, name);
    } else if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

        saveLink.href = urlObject.createObjectURL(exportBlob);
        saveLink.download = name;
        fakeClick(saveLink);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};

export const downloadCsv = (name, blob) => {
    if (window.cordova) {
        return saveFileMobile(name, blob);
    }
    FileDownload(blob, name);
    return new Promise((resolve) => resolve(true));
};

export const downloadXls = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/vnd.ms-excel;base64,${window.atob(data)}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadTxt = (name, data) => {
    const buff = window.Buffer.from(data, "base64");
    const text = buff.toString("ascii");

    const element = document.createElement("a");
    element.setAttribute("href", `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute("download", name);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};

export const download = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    const mediaType = "application/octet-stream";
    saveLink.href = `data:${mediaType};base64,${data}`;
    saveLink.download = name;
    fakeClick(saveLink);
};

export const downloadLink = (name, path) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = path;
    saveLink.target = "_blank";
    saveLink.download = name;
    fakeClick(saveLink);
};

async function saveFileMobile(fileName, data) {
    let directory;

    if (window.cordova.platformId === "android") {
        directory = window.cordova.file.externalRootDirectory + ANDROID_SAVE_DIRECTORY_NAME;
    } else {
        directory = window.cordova.file.documentsDirectory;
    }

    try {
        const entryDirectory = await getDirectory(directory);
        const file = await getFile(entryDirectory, fileName, { create: true, exclusive: false });
        return await writeFile(file, data);
    } catch (err) {
        throw new Error(err);
    }
}

function getDirectory(directoryName) {
    return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(
            directoryName,
            (directoryEntry) => {
                resolve(directoryEntry);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

function getFile(directoryEntry, fileName, options = {}) {
    return new Promise((resolve, reject) => {
        directoryEntry.getFile(
            fileName,
            options,
            (fileEntry) => {
                resolve(fileEntry);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

function writeFile(fileEntry, data) {
    return new Promise((resolve, reject) => {
        fileEntry.createWriter((fileWriter) => {
            fileWriter.onwriteend = () => {
                resolve("OK4");
            };
            fileWriter.onerror = (error) => {
                reject(error);
            };
            fileWriter.write(data);
        });
    });
}

export const downloadImageTypeBase64 = (filename, imageBase64, mediaType) => {
    const dataBase64 = imageBase64.replace("data:" + mediaType + ";base64,", "");
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = `data:${mediaType};base64,${dataBase64}`;
    saveLink.download = filename;
    fakeClick(saveLink);
};

export const downloadImageBase64 = (filename, qrBase64) => {
    const dataBase64 = qrBase64.replace("data:image/png;base64,", "");
    const mediaType = "image/png";
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = `data:${mediaType};base64,${dataBase64}`;
    saveLink.download = filename;
    fakeClick(saveLink);
};

export const downloadImageBase64Mobile = (name, qrBase64) => {
    const data = qrBase64.replace("data:image/png;base64,", "");
    const exportBlob = b64toBlob(data, "application/png");
    if (window.cordova) {
        return saveFileMobile(`${name}-${new Date().getTime()}.png`, exportBlob);
    }
    const urlObject = window.URL || window.webkitURL || window;
    if ("msSaveBlob" in navigator) {
        navigator.msSaveBlob(exportBlob, name);
    } else if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        saveLink.href = urlObject.createObjectURL(exportBlob);
        saveLink.download = name;
        fakeClick(saveLink);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};
