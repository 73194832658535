import React, { Component } from "react";
import { connect } from "react-redux";
import { types as checkTypes, actions as checksActions, selectors as checkSelectors } from "reducers/checks";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { bool, func, shape, string, objectOf, any } from "prop-types";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import defaultDateFromList from "util/defaultDateFromList";
import { validateToResetFilters } from "util/filters";
import Filters from "./deferred/Filters";
import List from "./deferred/DeferredList";

const ID_ACTIVITY = "check.deferred.list";

class ChecksDeferredList extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        history: objectOf(any).isRequired,
        dispatch: func.isRequired,
        isResetQuery: bool.isRequired,
        filters: shape({}).isRequired,
        idActivityOnFilter: string.isRequired,
        showFilters: bool.isRequired,
    };

    componentWillMount = () => {
        const { isResetQuery, filters } = this.props;

        this.handleTabClick(isResetQuery ? "descontados" : filters.checkTypeDeferred);
    };

    handleTabClick = (checkTypeDeferred) => {
        const { dispatch, isResetQuery, idActivityOnFilter } = this.props;

        dispatch(filterActions.validateToResetFilters(checkTypes.RESET_FILTERS, ID_ACTIVITY));

        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            dispatch(
                checksActions.setFilters({
                    checkTypeDeferred,
                    state: "0",
                    dateFrom: defaultDateFromList().checksDeferred.dateFrom,
                    dateTo: defaultDateFromList().checksDeferred.dateTo,
                }),
            );
            const defaultFilters = {
                checkTypeDeferred,
                state: "0",
                checkFrom: "",
                operationNumber: "",
                dateFrom: defaultDateFromList().checksDeferred.dateFrom,
                dateTo: defaultDateFromList().checksDeferred.dateTo,
                pageNumber: 1,
            };
            dispatch(checksActions.checksDeferredListRequest(defaultFilters));
        }
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }
        return <Head showPlusIcon accessibilityTextId="menu.check.deferred.list" />;
    };

    renderFilters = () => {
        const { isMobile, showFilters } = this.props;

        if (isMobile) {
            return showFilters && <Filters idForm={ID_ACTIVITY} isMobile />;
        }

        return <Filters idForm={ID_ACTIVITY} />;
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    render() {
        const { isDesktop, history, isMobile, filters, showFilters } = this.props;
        const { checkTypeDeferred } = filters;

        return (
            <>
                <Notification scopeToShow="checksList" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="menu.check.deferred.list" component="h1" />
                        </div>
                        <>
                            <div className="tabs-wrapper">
                                <Button
                                    label={`${ID_ACTIVITY}.descontados.label`}
                                    onClick={() => this.handleTabClick("descontados")}
                                    bsStyle="outline"
                                    className={`${checkTypeDeferred === "descontados" ? "active" : ""}`}
                                />
                                <Button
                                    label={`${ID_ACTIVITY}.avalados.label`}
                                    onClick={() => this.handleTabClick("avalados")}
                                    bsStyle="outline"
                                    className={`${checkTypeDeferred === "avalados" ? "active" : ""}`}
                                />
                            </div>

                            {isMobile && (
                                <Button
                                    onClick={() => this.onToggleFilters()}
                                    bsStyle="filter"
                                    className="btn-link"
                                    block
                                    image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                                    label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                                />
                            )}

                            {this.renderFilters()}

                            <List
                                history={history}
                                isMobile={isMobile}
                                idActivity={ID_ACTIVITY}
                                isDesktop={isDesktop}
                                checkType={checkTypeDeferred}
                            />
                        </>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isResetQuery: filterSelectors.isResetFilters(state),
    filters: checkSelectors.getFilters(state),
    showFilters: filterSelectors.getShowFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
});

export default connect(mapStateToProps)(ChecksDeferredList);
