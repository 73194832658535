import React, { useState, useEffect } from "react";
import { bool, func, shape, string, oneOfType, arrayOf, objectOf, any } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import classNames from "classnames";
import Yup from "yup";
import { validateToResetFilters } from "util/filters";
import * as i18n from "util/i18n";
import defaultDateFromList from "util/defaultDateFromList";
import moment from "moment";

import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { selectors as i18nSelectors } from "reducers/i18n";

import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import { DateField } from "pages/_components/fields/DateField";
import { types as qrTypes, actions as qrActions, selectors as qrSelectors } from "reducers/qr";
import PageLoading from "pages/_components/PageLoading";
import { clearFiltersAndSubmit } from "util/form";
import * as config from "../../../util/config";
import * as FormFieldsComponents from "../../forms/_components/_fields/Index";
import QrAccountButton from "../QrAccountButton";
import Certificates from "./Certificates";
import Charges from "./Charges";

const ID_ACTIVITY = "qr.charges.certificates";

const FiltersChargesCertificates = ({
    accountsForQr,
    isFetching,
    values,
    isMobile,
    dispatch,
    isResetQuery,
    idActivityOnFilter,
    filters,
    setValues,
    setFieldValue,
    setFieldError,
    showFilters,
    currentLang,
    submitForm,
}) => {
    const accountsOptionList = accountsForQr?.map((item) => ({
        id: item.idProduct,
        label: item.label,
        ...item,
    }));
    const { selectedAccount, status, toggleState } = filters;

    const [accountSelected, setAccountSelected] = useState(selectedAccount);

    useEffect(() => {
        if (filters.selectedAccount && accountSelected !== null) {
            setAccountSelected(selectedAccount);
            dispatch(qrActions.validateTyC(filters.selectedAccount.cbu));
        }
    }, [filters]);

    const changeAccountSelected = (account) => {
        setAccountSelected(account);
    };

    const handleTabClick = (value) => {
        dispatch(filterActions.validateToResetFilters(qrTypes.QR_RESET_FILTERS, ID_ACTIVITY));

        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            const defaultFilters = {
                selectedAccount: accountsOptionList[0],
                dateFrom: defaultDateFromList().accountsQR,
                dateTo: defaultDateFromList().accountsQR,
                toggleState: value,
            };

            if (value === "charges") {
                dispatch(
                    qrActions.filterChargesForQR(
                        defaultFilters.selectedAccount,
                        status,
                        defaultFilters.dateFrom,
                        defaultFilters.dateTo,
                    ),
                );
            } else if (value === "certificates") {
                dispatch(
                    qrActions.filterCertificatesForQR(
                        defaultFilters.selectedAccount,
                        defaultFilters.dateFrom,
                        defaultFilters.dateTo,
                    ),
                );
            }
        }
    };

    const handleClearFilters = () => {
        clearFiltersAndSubmit(
            () =>
                setValues({
                    account: [accountsOptionList[0].id],
                    status: ["ALL"],
                    dateFrom: defaultDateFromList().accountsQR,
                    dateTo: defaultDateFromList().accountsQR,
                }),
            submitForm,
        );
    };

    const minDaysBack = config.get("accounts.qrChargesCertificates.defaultDaysBack", 180);

    const genericProps = {
        idActivity: "qr.changes.send",
        idForm: "qr.changes",
    };

    const getStatusTypes = () => {
        const states = config.get("accounts.qrChargesCertificates.status", "ALL|PENDING|APPROVED|REJECTED").split("|");
        const valuesFinal = states.map((valuesState) => ({
            id: valuesState,
            label:
                valuesState === "ALL"
                    ? i18n.get("transaction.status.Todos")
                    : i18n.get(`transaction.status.${valuesState}`),
        }));

        return valuesFinal;
    };

    const showDefaultFilters = () => (
        <>
            {toggleState === "charges" && (
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={getStatusTypes()}
                    key="status"
                    name="status"
                    labelText={i18n.get("global.status.label")}
                    idField="status"
                    renderAs="combo"
                    mode="edit"
                    isRequired
                    idForm={genericProps.idForm}
                    lang={currentLang}
                />
            )}
            <Field
                {...genericProps}
                idField="dateFrom"
                component={DateField}
                hidePlaceholder
                name="dateFrom"
                labelText={i18n.get("global.dateFrom.label")}
                dateFormat="DD/MM/YYYY"
                selectsStart
                showMonthYearDropdown
                style={`${isMobile ? "display: block" : ""}`}
                minDate={moment().add(-minDaysBack, "days")}
                maxDate={values.dateTo ? moment(values.dateTo) : moment()}
                isClearable
                isRequired
                onChange={(e) => {
                    setFieldValue("dateFrom", e);
                    setFieldError("dateFrom", null);
                }}
            />

            <Field
                {...genericProps}
                idField="dateTo"
                component={DateField}
                hidePlaceholder
                name="dateTo"
                labelText={i18n.get("global.dateTo.label")}
                selectsStart
                style={`${isMobile ? "display: block" : ""}`}
                showMonthYearDropdown
                minDate={values.dateFrom ? moment(values.dateFrom) : moment()}
                maxDate={moment()}
                isRequired
                onChange={(e) => {
                    setFieldValue("dateTo", e);
                    setFieldError("dateTo", null);
                }}
            />
            <Button
                bsStyle="primary"
                label="product.filters.filter"
                loading={isFetching}
                type="submit"
                block={isMobile}
            />
            <Button
                block={isMobile}
                bsStyle="outline"
                className={classNames("btn-clear-filters", {
                    "ui-mb-8": isMobile,
                })}
                image="images/ui-icons/ui-clear-filters.svg"
                label="product.filters.clearFilters"
                loading={isFetching}
                onClick={handleClearFilters}
            />
        </>
    );

    const onToggleFilters = () => {
        dispatch(filterActions.toggleShowFilters());
    };

    const renderFilters = () => (
        <div className={`filters-container ${toggleState === "charges" ? "charges-qr" : "certificates-qr"}`}>
            <Form className="form-content">
                {isMobile ? (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={accountsOptionList}
                            key="account"
                            name="account"
                            idField="account"
                            renderAs="combo"
                            mode="edit"
                            onChange={(e) => changeAccountSelected(e)}
                            isRequired
                        />
                        <QrAccountButton
                            account={accountSelected}
                            isDesktop={!isMobile}
                            asIcon={false}
                            showToolTip={false}
                            size="big"
                            additionalClassName="ui-mt-0"
                        />
                        <Button
                            onClick={() => onToggleFilters()}
                            bsStyle="filter"
                            className="btn-link ui-mt-8 ui-mb-6"
                            block
                            image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                            label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                            loading={isFetching}
                        />
                        {showFilters && showDefaultFilters()}
                    </>
                ) : (
                    <>
                        <div className="filters-row">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={accountsOptionList}
                                key="account"
                                name="account"
                                idField="account"
                                renderAs="combo"
                                mode="edit"
                                onChange={(e) => changeAccountSelected(e)}
                                isRequired
                                lang={currentLang}
                            />
                            <QrAccountButton
                                account={accountSelected}
                                isDesktop={!isMobile}
                                asIcon={false}
                                showToolTip={false}
                                size="big"
                            />
                        </div>
                        <div className="filters-row">{showDefaultFilters()}</div>
                    </>
                )}
            </Form>
        </div>
    );

    return (
        <>
            <div className="tabs-wrapper">
                <Button
                    label="accounts.qrChargesCertificates.charges.label"
                    onClick={() => handleTabClick("charges")}
                    bsStyle="outline"
                    className={`${toggleState === "charges" ? "active" : ""}`}
                />
                <Button
                    label="accounts.qrChargesCertificates.certificates.label"
                    onClick={() => handleTabClick("certificates")}
                    bsStyle="outline"
                    className={`${toggleState === "certificates" ? "active" : ""}`}
                />
            </div>
            <PageLoading loading={isFetching}>
                {renderFilters()}
                {toggleState === "charges" ? <Charges isMobile={isMobile} /> : <Certificates isMobile={isMobile} />}
            </PageLoading>
        </>
    );
};

FiltersChargesCertificates.propTypes = {
    accountsForQr: oneOfType([arrayOf(any), objectOf(any)]).isRequired,
    isFetching: bool.isRequired,
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    filters: shape({}).isRequired,
    values: shape({}).isRequired,
    setValues: func.isRequired,
    setFieldValue: func.isRequired,
    setFieldError: func.isRequired,
    isResetQuery: bool.isRequired,
    idActivityOnFilter: string.isRequired,
    showFilters: bool.isRequired,
    currentLang: string.isRequired,
    submitForm: func.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: qrSelectors.getFetching(state),
    accountsForQr: qrSelectors.getAccountListForQR(state),
    filters: qrSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    showFilters: filterSelectors.getShowFilters(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ filters }) => ({
            account: [filters.selectedAccount?.idProduct],
            status: filters && filters.status ? [filters.status] : ["ALL"],
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
        }),
        validationSchema: () => {
            const minDaysBack = config.get("mutualFunds.movements.minDaysBack", 730);
            const maxDateValidationMsg = i18n.get("investments.mutualFund.movements.dateTo.maxValidation.message", "");

            return Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: Yup.date()
                        .nullable()
                        .min(
                            moment().add(-config.get("accounts.qrChargesCertificates.defaultDaysBack", 180), "days"),
                            i18n.get("investments.mutualFund.date.validation.message", ""),
                        )
                        .max(
                            moment().add(moment().format("DD/MM/YYYY"), "days"),
                            i18n.get("investments.mutualFund.date.validation.message", ""),
                        )
                        .required(i18n.get("accounts.qrChargesCertificates.filters.dateFrom.empty.error")),
                    dateTo: Yup.date()
                        .nullable()
                        .min(values.dateFrom ?? moment().add(-minDaysBack, "days"), maxDateValidationMsg)
                        .required(i18n.get("accounts.qrChargesCertificates.filters.dateTo.empty.error")),
                }),
            );
        },
        handleSubmit: ({ ...values }, formikBag) => {
            const { status, dateFrom, dateTo } = values;
            const { dispatch, accountsForQr, filters } = formikBag.props;

            const selectedAccount = accountsForQr.find((account) => account.idProduct === values.account[0]);

            if (filters.toggleState === "charges") {
                dispatch(qrActions.filterChargesForQR(selectedAccount, status[0], dateFrom, dateTo));
            } else if (filters.toggleState === "certificates") {
                dispatch(qrActions.filterCertificatesForQR(selectedAccount, dateFrom, dateTo));
            }
        },
    }),
)(FiltersChargesCertificates);
