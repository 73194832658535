import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bool, node } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as i18nUtils from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Captcha from "pages/_components/fields/credentials/Captcha";
import * as configUtils from "util/config";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

const FORM_ID = "session.login.legacy.passwordExpired";

class StepPasswordExpired extends Component {
    static propTypes = {
        isMobileNative: bool.isRequired,
        footer: node,
        isSubmitting: bool.isRequired,
        showCaptcha: bool.isRequired,
    };

    static defaultProps = {
        footer: null,
    };

    getPasswordHelp = () => (
        <>
            <div className="pre-form-info">
                <div className="pre-form-info__text ui-mb-6">
                    <I18n id="session.login.legacy.passwordExpired.header" />

                    <I18n id="recoveryPassword.step3.description" component="p" />
                </div>
            </div>
            <div className="password-help-text">
                <I18n id="recoveryPassword.step3.requirements.rules.title" component="p" />
                <ul>
                    <I18n id="recoveryPassword.step3.requirements.rule1" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule2" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule3" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule4" component="li" />
                </ul>
            </div>
        </>
    );

    render() {
        const { footer, isSubmitting, showCaptcha, isMobileNative } = this.props;
        const maxLength = configUtils.getInteger("core.password.maxLength");

        return (
            <Form className="form-content container">
                {this.getPasswordHelp()}

                <Field name="password" idForm={FORM_ID} component={Credential} maxLength={maxLength} hidePlaceholder />
                <Field
                    name="newPassword"
                    idForm={FORM_ID}
                    component={Credential}
                    showStrength
                    maxLength={maxLength}
                    hidePlaceholder
                />
                <Field
                    name="newPasswordConfirmation"
                    idForm={FORM_ID}
                    component={Credential}
                    maxLength={maxLength}
                    hidePlaceholder
                />
                {showCaptcha && !isMobileNative && <Field idForm={FORM_ID} name="captcha" component={Captcha} />}
                <Button label="global.confirm" loading={isSubmitting} type="submit" bsStyle="primary" block />
                {footer}
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loginSelectors.getFetching(state),
    exchangeToken: loginSelectors.getExchangeToken(state),
    showCaptcha: loginSelectors.getShowCaptcha(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            newPassword: "",
            newPasswordConfirmation: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    password: Yup.string().required(i18nUtils.get(`${FORM_ID}.password.required`)),
                    newPassword: Yup.string()
                        .required(i18nUtils.get(`${FORM_ID}.newPassword.required`))
                        .min(
                            configUtils.getInteger("core.password.minLength"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.password.pattern"), "g"),
                            i18nUtils.get("enrollment.step3.password.error.unfulfilledRules"),
                        )
                        .oneOf(
                            [values.newPasswordConfirmation],
                            i18nUtils.get("enrollment.step3.password.error.passwordConfirmationMustMatch"),
                        ),
                    newPasswordConfirmation: Yup.string()
                        .required(i18nUtils.get(`${FORM_ID}.newPasswordConfirmation.required`))
                        .min(
                            configUtils.getInteger("core.password.minLength"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.password.pattern"), "g"),
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.unfulfilledRules"),
                        )
                        .oneOf(
                            [values.newPassword],
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.passwordMustMatch"),
                        ),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                loginActions.changePassword(
                    formikBag.props.exchangeToken,
                    values.password,
                    values.captcha,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(StepPasswordExpired);
