import React from "react";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import { func, bool } from "prop-types";
import { Helmet } from "react-helmet";

const HeaderLogin = ({ onHeaderClose, showBack }) => (
    <>
        <div className="view-header login-content__header">
            {showBack && (
                <div className="login-back" onClick={onHeaderClose}>
                    <Image src="images/back.svg" />
                </div>
            )}
            <Helmet>
                <title>{i18n.get("global.companyName")}</title>
            </Helmet>
            <div className="login-content__logo-wrapper">
                <Image src="images/logo/logo-mariva-horizontal-con-texto.svg" />
            </div>
        </div>
    </>
);

HeaderLogin.propTypes = {
    onHeaderClose: func.isRequired,
    showBack: bool,
};

HeaderLogin.defaultProps = {
    showBack: false,
};

export default HeaderLogin;
