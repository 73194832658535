import React, { Component, Fragment, Children } from "react";
import { string, shape, bool, oneOfType, array, object } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Dropdown from "pages/_components/Dropdown";

class MenuSection extends Component {
    static propTypes = {
        titleKey: string,
        image: string,
        imageClassName: string,
        title: string,
        children: oneOfType([array, object]).isRequired,
        permissions: shape({}),
        isCollapsed: bool,
        isSidebarHovered: bool,
    };

    static defaultProps = {
        titleKey: "",
        image: "",
        imageClassName: "",
        title: "",
        permissions: null,
        isCollapsed: false,
        isSidebarHovered: false,
    };

    getTitle = () => {
        const { image, imageClassName, titleKey, title } = this.props;
        let content = null;
        if (image) {
            content = imageClassName ? (
                <div className={imageClassName}>
                    <Image src={image} />
                </div>
            ) : (
                <Image src={image} />
            );
        }

        return (
            <Fragment>
                {content}
                {titleKey ? <I18n id={titleKey} /> : title}
            </Fragment>
        );
    };

    render() {
        const { children, permissions, image, titleKey, isCollapsed, isSidebarHovered } = this.props;

        const isActiveOption = children.find(
            (item) => item.props?.linkTo === window.location.pathname || item.props?.link === window.location.pathname,
        );

        return (
            <Dropdown
                image={image}
                label={titleKey}
                buttonClass={`toggle-menu-button ${isActiveOption ? "active" : ""}`}
                isCollapsed={isCollapsed}
                isSidebarHovered={isSidebarHovered}
                isMenuDropdown>
                {Children.map(children, (child) => child && React.cloneElement(child, { permissions }))}
            </Dropdown>
        );
    }
}

export default MenuSection;
