import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_ADMINISTRATION_TICKET_REQUEST: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_REQUEST",
    LOAD_ADMINISTRATION_TICKET_FAILURE: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_FAILURE",
    LOAD_ADMINISTRATION_TICKET_SUCCESS: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_SUCCESS",
};

export const INITIAL_STATE = {
    data: {},
    fetching: true,
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_ADMINISTRATION_TICKET_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_SUCCESS]: (_, { data }) => ({
        fetching: false,
        data,
    }),
});

export const actions = {
    loadAdministrationTicketRequest: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_REQUEST, "idTransaction"),
    loadAdministrationTicketFailure: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_FAILURE),
    loadAdministrationTicketSuccess: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_SUCCESS, "data"),
};

export const selectors = {
    isFetching: ({ administrationTicket }) => administrationTicket.fetching,
    getData: ({ administrationTicket }) => administrationTicket.data,
};
