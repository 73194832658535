import React, { Component } from "react";
import { bool, func, string, shape, oneOfType, number } from "prop-types";
import classNames from "classnames";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import FieldError from "pages/_components/fields/FieldError";

class Checkbox extends Component {
    static propTypes = {
        checked: bool,
        name: string,
        labelText: string,
        onChange: func,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string, bool]),
        }),
        idForm: string,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }),
        labelTextProps: shape({}),
    };

    static defaultProps = {
        onChange: () => {},
        checked: false,
        name: "",
        labelText: "",
        field: null,
        idForm: "",
        form: null,
        labelTextProps: {},
    };

    render() {
        const { checked, onChange, name, labelText, field, form, idForm, labelTextProps } = this.props;
        const controlName = field ? field.name : name;
        let hasError = false;
        let error = "";
        if (form) {
            const { touched, errors } = form;
            hasError = touched[field.name] && errors[field.name];
            error = errors[field.name];
        }

        return (
            <div
                className={classNames("c-control", "c-control--has-icon", "c-control--checkbox", {
                    "has-error": hasError,
                })}>
                <input
                    id={`checkbox_${controlName}`}
                    className="c-control-input"
                    type="checkbox"
                    name={controlName}
                    checked={field ? field.value : checked}
                    onChange={() => {
                        if (field && form) {
                            form.setFieldValue(field.name, !field.value);
                        }
                        onChange(field ? !field.value : !checked);
                    }}
                />
                <label className="c-control-label" htmlFor={`checkbox_${controlName}`}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    <div className="c-control-text">
                        {labelText && <I18n id={labelText} {...labelTextProps} />}
                        {!labelText && field && <I18n id={`${idForm}.${field.name}.label`} />}
                    </div>
                </label>

                {hasError && <FieldError error={error?.value ? error.value : error} />}
            </div>
        );
    }
}

export default Checkbox;
