import { call, put, takeLatest } from "redux-saga/effects";

import * as checksMiddleware from "middleware/checks";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { types, actions } from "reducers/checks";

const sagas = [
    takeLatest(types.CHECKS_DEFERRED_LIST_REQUEST, checksDeferredList),
    takeLatest(types.LOAD_MORE_CHECKS_DEFERRED_REQUEST, loadMoreChecksDeferredList),
    takeLatest(types.CHECKS_DEFERRED_DETAIL_REQUEST, checkDeferredDetail),
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_CHECKS_REQUEST, fetchMorechecks),
];

export default sagas;

function* checksDeferredList({ filters }) {
    const response = yield call(checksMiddleware.getChecksDeferredList, {
        ...filters,
    });
    if (response.type === "W") {
        yield put(actions.checksDeferredListFailure);
        yield put(notificationActions.showNotification(response.data.message, "error", ["checksList"]));
    } else {
        const { checksList, pageNumber, totalPages, moreChecks } = response.data.data;
        yield put(actions.checksDeferredListSuccess(checksList, pageNumber, totalPages, moreChecks));
    }
}
function* loadMoreChecksDeferredList({ filters }) {
    const response = yield call(checksMiddleware.getChecksDeferredList, {
        ...filters,
    });
    if (response.type === "W") {
        yield put(actions.loadMoreChecksDeferredFailure);
        yield put(notificationActions.showNotification(response.data.message, "error", ["checksList"]));
    } else {
        const { checksList, pageNumber, totalPages, moreChecks } = response.data.data;
        yield put(actions.loadMoreChecksDeferredSuccess(checksList, pageNumber, totalPages, moreChecks));
    }
}

function* checkDeferredDetail({ checkType, branch, operationNumber, checkNumber, orderNumber, idCheck }) {
    const response = yield call(
        checksMiddleware.getChecksDeferredDetail,
        checkType,
        branch,
        operationNumber,
        checkNumber,
        orderNumber,
        idCheck,
    );
    if (response.type === "W") {
        yield put(actions.checkDeferredDetailFailure);
        yield put(notificationActions.showNotification(response.data.message, "error", ["checksList"]));
    } else {
        const { checkDetail } = response.data.data;
        yield put(actions.checkDeferredDetailSuccess(checkDetail));
    }
}

function* loadListRequest({ filters }) {
    const response = yield call(checksMiddleware.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksList"]));
    } else {
        const { checks, pageNumber, totalPages, moreChecks } = response.data.data;
        yield put(actions.loadListSuccess(checks, pageNumber, totalPages, moreChecks));
    }
}

function* fetchMorechecks({ filters }) {
    const response = yield call(checksMiddleware.loadListRequest, { ...filters });
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksList"]));
    } else {
        const { checks, pageNumber, totalPages, moreChecks } = response.data.data;
        yield put(actions.loadMoreChecksSuccess(checks, pageNumber, totalPages, moreChecks));
    }
}
