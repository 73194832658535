import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as echeqsSelectors } from "reducers/echeqs";
import { selectors as formSelector } from "reducers/form";
import { bool, string, shape, func } from "prop-types";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Loader from "pages/_components/Loader";
import Table from "pages/_components/Table";
import EcheqGridItem from "./EcheqGridItem";

class MultipleEcheqsGrid extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        idForm: string.isRequired,
        fetching: bool.isRequired,
        emissionType: string.isRequired,
        data: shape({}).isRequired,
        previewData: shape({}).isRequired,
        showProcessedEcheqItems: bool,
        mode: string.isRequired,
        dispatch: func.isRequired,
        echeqsList: shape([]).isRequired,
        setFieldsAfterRemove: func,
        setFieldValue: func,
        disableComponents: bool.isRequired,
    };

    static defaultProps = {
        showProcessedEcheqItems: true,
        setFieldsAfterRemove: null,
        setFieldValue: null,
    };

    renderItem = (echeq) => {
        const {
            isDesktop,
            idForm,
            fetching,
            emissionType,
            showProcessedEcheqItems,
            dispatch,
            mode,
            setFieldsAfterRemove,
            setFieldValue,
            disableComponents,
        } = this.props;

        return isDesktop ? (
            <Table.Row key={echeq.cheque_id} additionalClassName="no-hover">
                <EcheqGridItem
                    isDesktop={isDesktop}
                    echeq={echeq}
                    isFetching={fetching}
                    idForm={idForm}
                    emissionType={emissionType}
                    showProcessedEcheqItems={showProcessedEcheqItems}
                    dispatch={dispatch}
                    mode={mode}
                    setFieldsAfterRemove={setFieldsAfterRemove}
                    setFieldValue={setFieldValue}
                    disableComponents={disableComponents}
                />
            </Table.Row>
        ) : (
            <EcheqGridItem
                isDesktop={isDesktop}
                echeq={echeq}
                idForm={idForm}
                emissionType={emissionType}
                isFetching={fetching}
                showProcessedEcheqItems={showProcessedEcheqItems}
                dispatch={dispatch}
                mode={mode}
                setFieldsAfterRemove={setFieldsAfterRemove}
                setFieldValue={setFieldValue}
                disableComponents={disableComponents}
            />
        );
    };

    renderList = (list) => {
        const { isDesktop, idForm, emissionType, showProcessedEcheqItems, mode } = this.props;
        const isEmpty = list.length === 0;
        if (isDesktop) {
            return (
                <>
                    {!isEmpty && (
                        <>
                            <Table className={`gridTable ui-mb-7 ${mode !== "edit" ? "ui-mt-7" : ""}`}>
                                <Table.Header>
                                    {showProcessedEcheqItems && (
                                        <>
                                            <Table.HeaderData align="left">
                                                <I18n id={`${idForm}.echeqNumber.label`} />
                                            </Table.HeaderData>

                                            <Table.HeaderData align="left">
                                                <I18n id={`${idForm}.checkbookNumber.label`} />
                                            </Table.HeaderData>
                                        </>
                                    )}
                                    <Table.HeaderData align="right">
                                        <I18n id={`${idForm}.amount.label`} />
                                    </Table.HeaderData>

                                    {!showProcessedEcheqItems && (
                                        <Table.HeaderData align="left">
                                            <I18n id={`${idForm}.echeqType.tableHeader.label`} />
                                        </Table.HeaderData>
                                    )}

                                    <Table.HeaderData align="left">
                                        <I18n id={`${idForm}.paymentDate.label`} />
                                    </Table.HeaderData>

                                    {emissionType === "personalized" && (
                                        <>
                                            <Table.HeaderData align="left">
                                                <I18n id={`${idForm}.character.label`} />
                                            </Table.HeaderData>

                                            <Table.HeaderData align="left">
                                                <I18n id={`${idForm}.reference.label`} />
                                            </Table.HeaderData>
                                        </>
                                    )}
                                    {!showProcessedEcheqItems && <Table.HeaderData />}
                                </Table.Header>

                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </>
                    )}
                </>
            );
        }

        return (
            list.length > 0 && (
                <div className={`generic-movements-wrapper ui-mb-7 ${mode !== "edit" ? "ui-mt-7" : ""}`}>{list}</div>
            )
        );
    };

    render() {
        const { data, previewData, fetching, isDesktop, echeqsList, mode } = this.props;
        const echeqs = mode !== "edit" ? previewData?.echeqs || data?.echeqs : echeqsList || [];

        return (
            <>
                {fetching ? (
                    <Loader />
                ) : (
                    <ProductList
                        firstFetched={false}
                        fetching={fetching}
                        items={echeqs}
                        renderItem={this.renderItem}
                        lastPage
                        isMobile={!isDesktop}>
                        {this.renderList}
                    </ProductList>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    data: formSelector.getData(state),
    previewData: formSelector.getPreviewData(state),
    fetching: echeqsSelectors.getFetching(state) && formSelector.getFetching(state),
    mode: formSelector.getMode(state),
    echeqsList: echeqsSelectors.getPersonalizedEcheqsList(state),
});

export default connect(mapStateToProps)(MultipleEcheqsGrid);
