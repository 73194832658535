import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, objectOf, any } from "prop-types";

import { actions as comexActions, selectors as comexSelectors } from "reducers/comex";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import defaultDateFromList from "util/defaultDateFromList";
import List from "./List";
import Filters from "./Filters";

const ID_ACTIVITY = "comex.receiptsList";

class ReceiptsComexList extends Component {
    static propTypes = {
        isMobile: bool,
        isDesktop: bool.isRequired,
        history: objectOf(any).isRequired,
        dispatch: func.isRequired,
        isResetQuery: bool.isRequired,
        showFilters: bool.isRequired,
    };

    static defaultProps = {
        isMobile: false,
    };

    componentWillMount = () => {
        const { dispatch, isResetQuery } = this.props;
        if (isResetQuery) {
            dispatch(comexActions.resetFilters());
        }
        dispatch(
            comexActions.receiptsComexListRequest({
                dateFrom: defaultDateFromList().comex.dateFrom,
                dateTo: defaultDateFromList().comex.dateTo,
            }),
        );
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }
        return <Head accessibilityTextId="menu.comex.receiptsList" showPlusIcon />;
    };

    renderFilters = ({ isMobile } = this.props) => <Filters isMobile={isMobile} idForm={ID_ACTIVITY} />;

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(comexActions.toggleFilters());
    };

    render() {
        const { isDesktop, history, isMobile, showFilters } = this.props;

        return (
            <>
                <Notification scopeToShow="receiptsList" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="menu.comex.receiptsList" component="h1" />
                            <I18n id="comex.receiptsList.subtitle" component="h2" />
                        </div>

                        <>
                            {this.renderFilters()}

                            <List
                                history={history}
                                isMobile={isMobile}
                                idActivity={ID_ACTIVITY}
                                isDesktop={isDesktop}
                                showFilters={showFilters}
                            />
                        </>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isResetQuery: comexSelectors.isResetQuery(state),
    showFilters: comexSelectors.getShowFilters(state),
});

export default connect(mapStateToProps)(ReceiptsComexList);
