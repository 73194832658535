import React from "react";
import GenericItem from "components/GenericItem/GenericItem";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import * as stringUtils from "util/string";
import Button from "pages/_components/Button";
import { actions as supplierActions } from "reducers/paymentToSuppliers";
import CheckBoxForItem from "../_components/CheckBoxForItem";

const CheckItem = (props) => {
    const { dispatch, detail, isMobile, headInfo, comeFromProcessed } = props;

    const formattedCuit = `${stringUtils.formatCuit(detail.cuit)}`;
    const generateIssuingAccount = () => `${detail.clientBranch}-${detail.clientAccount}-${detail.checkDigit}`;

    const renderCheckbox = () => (
        <CheckBoxForItem detail={detail} paymentType="check" keyForChechBox={detail.checkNumber} />
    );

    const renderItem = () => {
        if (isMobile) {
            return (
                <>
                    <GenericItem>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.issuingAccount.label" />
                            </div>
                            <div className="data-text">{generateIssuingAccount(detail)}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.supplier.label" />
                            </div>
                            <div className="data-text">{detail.supplier}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.payTo.label" />
                            </div>
                            <div className="data-text">{detail.payTo}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.dateOfIssue.label" />
                            </div>
                            <div className="data-text">{detail.paymentDate}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.correlativeNumber.label" />
                            </div>
                            <div className="data-text">{detail.correlativeNumber}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.amount.label" />
                                <FormattedAmount
                                    currency={detail.amount?.currency}
                                    quantity={detail.amount?.quantity}
                                />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                <Table.Data align="left">
                    <span className="data-text">{generateIssuingAccount(detail)}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {detail.supplier} <br /> {detail.issuingAccount}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {detail.payTo} <br /> {formattedCuit}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <div className="data-text">
                        <FormattedDate date={detail.paymentDate} />
                    </div>
                    <div className="data-text">
                        <FormattedDate date={detail.deferralDate} />
                    </div>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {detail.correlativeType} <br /> {detail.correlativeNumber}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <FormattedAmount currency={detail.amount?.currency} quantity={detail.amount?.quantity} />
                </Table.Data>
            </Table.Row>
        );
    };

    const renderProcessedItem = () => {
        if (isMobile) {
            return (
                <>
                    <GenericItem>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.issuingAccount.label" />
                            </div>
                            <div className="data-text">{generateIssuingAccount(detail)}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.supplier.label" />
                            </div>
                            <div className="data-text">
                                {detail.supplier} <br /> {detail.issuingAccount}
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.payTo.label" />
                            </div>
                            <div className="data-text">
                                {detail.payTo} <br /> {formattedCuit}
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.deferralDate.label" />;
                            </div>
                            <div className="data-text">{detail.deferralDate}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.checkNumber.label" />
                            </div>
                            <div className="data-text">{detail.checkNumber}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.checkEcheck.header.amount.label" />
                                <FormattedAmount
                                    currency={detail.amount?.currency}
                                    quantity={detail.amount?.quantity}
                                />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                {renderCheckbox()}
                <Table.Data align="left">
                    <span className="data-text">{generateIssuingAccount(detail)}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {detail.supplier} <br /> {detail.issuingAccount}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {detail.payTo} <br /> {formattedCuit}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <div className="data-text">
                        <FormattedDate date={detail.deferralDate} />
                    </div>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.checkNumber}</span>
                </Table.Data>

                <Table.Data align="left">
                    <FormattedAmount currency={detail.amount?.currency} quantity={detail.amount?.quantity} />
                </Table.Data>

                <Table.Data className="table-data-icons" align="right">
                    <div className="icons-container">
                        <Button
                            type="button"
                            className="btn-only-icon list-item-icon--download"
                            block={false}
                            onClick={() => {
                                dispatch(supplierActions.downloadVoucherDetails("check", "single", headInfo, [detail]));
                            }}
                            image="images/download.svg"
                        />
                    </div>
                </Table.Data>
            </Table.Row>
        );
    };

    if (comeFromProcessed) {
        return renderProcessedItem();
    }

    return renderItem();
};

CheckItem.propTypes = {
    dispatch: func.isRequired,
    headInfo: shape({}).isRequired,
    detail: shape({}).isRequired,
    isMobile: bool,
    comeFromProcessed: bool,
};
CheckItem.defaultProps = {
    isMobile: false,
    comeFromProcessed: false,
};

export default connect()(CheckItem);
