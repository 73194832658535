import moment from "moment";

// Esta función recibe los filtros, filtros excluídos (opcional), filtros tipo array y la fecha por defecto para los formularios de filtros de los listados.
// Retorna si se han aplicado filtros o no, para mostrar un mensaje determinado en caso de que no haya resultados o se llegue al final del listado.

const getFiltersStatus = ({
    filters,
    excludedFilters = [
        "checkType",
        "checkTypeDeferred",
        "pageNumber",
        "loanType",
        "shareholderAccount",
        "orderColumn",
        "orderDirection",
        "echeqType",
        "channels",
        "toggle",
        "selectedAccount",
    ],
    arrayFilters = ["operationType", "transactionType", "state"],
    defaultFilters,
}) => {
    let withFilters = false;
    // El siguiente array solo se usa para testear en la consola
    const appliedFilters = [];

    if (filters.status === "TODOS" || filters.status === "ALL") {
        excludedFilters.push("status");
    }

    if (filters.mutualFund?.number === "") {
        excludedFilters.push("mutualFund");
    }

    if (defaultFilters) {
        Object.keys(defaultFilters).map((defaultFilter) => {
            if (
                filters[defaultFilter] === defaultFilters[defaultFilter] ||
                (moment(filters[defaultFilter]).isValid() &&
                    moment(filters[defaultFilter]).format("DD-MM-YYYY") ===
                        moment(defaultFilters[defaultFilter]).format("DD-MM-YYYY"))
            ) {
                excludedFilters.push(defaultFilter);
            }

            if (
                Array.isArray(filters[defaultFilter]) &&
                filters[defaultFilter][0] === defaultFilters[defaultFilter][0]
            ) {
                excludedFilters.push(defaultFilter);
            }
            return null;
        });
    }

    Object.keys(filters).map((key) => {
        if (!excludedFilters.includes(key)) {
            if (
                arrayFilters.includes(key) &&
                Array.isArray(filters[key]) &&
                filters[key] !== "" &&
                filters[key][0] !== ""
            ) {
                withFilters = true;
                appliedFilters.push(key);
            }

            if (
                (!Array.isArray(filters[key]) &&
                    typeof filters[key] === "object" &&
                    filters[key] !== null &&
                    Object.keys(filters[key]).length) ||
                (typeof filters[key] === "string" && filters[key] !== "") ||
                (typeof filters[key] === "number" && filters[key] >= 0) ||
                filters[key] instanceof Date
            ) {
                withFilters = true;
                appliedFilters.push(key);
            }

            return null;
        }
        return null;
    });

    // console.log("filters", filters);
    // console.log("defaultFilters", defaultFilters);
    // console.log("appliedFilters", appliedFilters);
    // console.log("excludedFilters", excludedFilters);
    // console.log("withFilters", withFilters);

    return withFilters;
};

export default getFiltersStatus;
