import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool } from "prop-types";
import { push } from "react-router-redux/actions";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";

import withExchangeToken from "pages/_components/withExchangeToken";
import Alert from "pages/_components/Alert/Alert";

const FORM_ID = "enrollment.step3";

class EnrollmentStep0 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isSubmitting: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isSubmitting } = this.props;

        return (
            <>
                <div className="view-page justify-content-center">
                    <div className="view-content">
                        <main className="main-container">
                            <div className="title-header">
                                <I18n id="enrollment.index.header" component="h1" />
                            </div>
                            <Form className="form-content container">
                                <I18n
                                    id="enrollment.steps.step0.title"
                                    component="h2"
                                    componentProps={{ className: "ui-text-skyblue" }}
                                />

                                <hr className="ui-mt-0 mb-3" />

                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="environment"
                                    mode="view"
                                    additionalClassName="inline"
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="fullName"
                                    mode="view"
                                    additionalClassName="inline"
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="documentType"
                                    mode="view"
                                    additionalClassName="inline"
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="documentNumber"
                                    mode="view"
                                    additionalClassName="inline"
                                />
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="email"
                                    mode="view"
                                    additionalClassName="inline"
                                />

                                <hr className="ui-mt-0 mb-3" />

                                <Alert className="ui-mt-7 ui-mb-0" icon="images/ui-icons/ui-exclamation-orange.svg">
                                    <I18n id="enrollment.steps.step0.warning" />
                                </Alert>

                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    block
                                />
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            environment: props.invitation?.customParameters,
            fullName: props.invitation?.firstName,
            documentType: i18nUtils.get(`documentType.label.${props.invitation?.documentType}`),
            documentNumber: props.invitation?.documentNumber,
            email: props.invitation?.email,
        }),
        handleSubmit: (_data, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(push("/enrollment/step1"));
        },
    }),
)(withExchangeToken(EnrollmentStep0));
