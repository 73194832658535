import React from "react";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import { array, bool, oneOfType } from "prop-types";
import TableHeaderCheckbox from "../_components/TableHeaderCheckbox";

function TransferTableHeader({ comeFromProcessed, linesDetail }) {
    const vouchersToDownload = linesDetail.map((item) => item.transferNumber);

    return (
        <>
            <Table.Header>
                {comeFromProcessed && <TableHeaderCheckbox vouchersToDownload={vouchersToDownload} />}
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.transfer.header.operatingCode.label" />
                </Table.HeaderData>

                <Table.HeaderData align="left">
                    <I18n
                        id={`forms.payments.suppliers.detail.transfer.header.${
                            comeFromProcessed ? "receiver" : "cuit"
                        }.label`}
                    />
                </Table.HeaderData>

                {!comeFromProcessed && (
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.transfer.header.name.label" />
                    </Table.HeaderData>
                )}

                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.transfer.header.cbu.label" />
                </Table.HeaderData>

                <Table.HeaderData align="left">
                    <I18n
                        id={`forms.payments.suppliers.detail.transfer.header.${
                            comeFromProcessed ? "transferNumber" : "reference"
                        }.label`}
                    />
                </Table.HeaderData>

                <Table.HeaderData align="right">
                    <I18n id="forms.payments.suppliers.detail.transfer.header.amount.label" />
                </Table.HeaderData>
                {comeFromProcessed && <Table.HeaderData />}
            </Table.Header>
        </>
    );
}

TransferTableHeader.propTypes = {
    linesDetail: oneOfType([array]).isRequired,
    comeFromProcessed: bool,
};

TransferTableHeader.defaultProps = {
    comeFromProcessed: false,
};

export default TransferTableHeader;
