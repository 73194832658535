import React, { Fragment, Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bool, shape, string } from "prop-types";

import { resizableRoute } from "pages/_components/Resizable";
import { selectors as sessionSelectors } from "reducers/session";

import AssistantLogin from "pages/login/AssistantLogin";
import BackofficeLayout from "pages/_layouts/BackofficeLayout";
import BackofficeTransactionCustom from "pages/forms/customForms/BackofficeTransactionCustom";
import EnrollmentAssociateStep1 from "pages/enrollment/AssociateStep1";
import EnrollmentAssociateStep2 from "pages/enrollment/AssociateStep2";
import EnrollmentAssociateStep3 from "pages/enrollment/AssociateStep3";
import EnrollmentError from "pages/enrollment/Error";
import EnrollmentIndex from "pages/enrollment/Index";
import EnrollmentLayout from "pages/_layouts/EnrollmentLayout";
import EnrollmentStep3Part4 from "pages/enrollment/Step3Part4";
import EnrollmentStep0 from "pages/enrollment/EnrollmentStep0";
import EnrollmentStep1 from "pages/enrollment/EnrollmentStep1";
import EnrollmentStep2 from "pages/enrollment/EnrollmentStep2";
import EnrollmentStep3 from "pages/enrollment/EnrollmentStep3";
import EnrollmentStepIRS from "pages/enrollment/StepIRS";
import EnrollmentSuccess from "pages/enrollment/Success";
import Error from "pages/error/Error";
import ExpiredByMaxAttempts from "pages/session/expiredByMaxAttempts";
import ExpireEnvironmentModal from "pages/session/ExpireEnvironmentModal";
import ExpiredPasswordModal from "pages/session/ExpiredPasswordModal";
import ExternalLayout from "pages/_layouts/ExternalLayout";
import LanguageSelection from "pages/login/LanguageSelection";
import LoginAssistantStep from "pages/login/LoginStep5";
import LoginPasswordExpired from "pages/login/LoginPasswordExpired";
import LoginStep1 from "pages/login/LoginStep1";
import LoginStep2 from "pages/login/LoginStep2";
import LoginStep3 from "pages/login/LoginStep3";
import LoginStep4 from "pages/login/LoginStep4";
import OnboardingInvitationCodeSendFail from "pages/onboarding/_components/InvitationCodeSendFail";
import PrivateRoutes from "routes/PrivateRoutes";
import ProductRequestLayout from "pages/_layouts/ProductRequestLayout";
import RecoveryPassStep1 from "pages/recoveryPassword/RecoveryPassStep1";
import RecoveryPassStep2 from "pages/recoveryPassword/RecoveryPassStep2";
import RecoveryPassStep3 from "pages/recoveryPassword/RecoveryPassStep3";
import RecoveryPassStep4 from "pages/recoveryPassword/RecoveryPassStep4";
import RecoveryUserStep1 from "pages/recoverUser/RecoveryUserStep1";
import RecoveryUserStep2 from "pages/recoverUser/RecoveryUserStep2";
import RegionSelection from "pages/login/RegionSelection";
import RequestInvitationCode from "pages/enrollment/RequestInvitationCode";
import RequestInvitationCodeSuccess from "pages/enrollment/RequestInvitationCodeSuccess";
import SecurityPolicies from "pages/_components/SecurityPolicies";
import ServerError from "pages/error/ServerError";
import SupportInfo from "pages/_components/SupportInfo";
import TermsAndConditions from "pages/_components/TermsAndConditions";

class Routes extends Component {
    static propTypes = {
        hasActiveSession: bool,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    shouldComponentUpdate(nextProps) {
        const {
            location: { pathname },
            hasActiveSession,
        } = this.props;
        // With oauth, the login step 2 sets an active session before routing to step3
        // Changing the value of active session triggers an additional transition animation
        // This avoids that unnecessary re-render
        if (pathname.includes("loginStep2")) {
            if (!hasActiveSession && nextProps.hasActiveSession) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { hasActiveSession } = this.props;

        return (
            <Fragment>
                <Switch>
                    <Route exact path="/serverError" component={ServerError} />
                    <Route exact path="/error" component={Error} />

                    <ExternalLayout exact path="/assistant" component={AssistantLogin} />
                    <ExternalLayout exact path="/assistant/:pageToken" component={AssistantLogin} />
                    <ExternalLayout exact path="/" component={LoginStep1} />
                    <ExternalLayout exact path="/loginStep1" component={LoginStep1} />
                    <ExternalLayout exact path="/loginStep2" component={LoginStep2} />
                    <ExternalLayout exact path="/loginStep2/passwordExpired" component={LoginPasswordExpired} />
                    <ExternalLayout exact path="/loginStep3" component={LoginStep3} />
                    <ExternalLayout exact path="/loginStep4" component={LoginStep4} />
                    <ExternalLayout exact path="/loginStep5" component={LoginAssistantStep} />

                    <ExternalLayout exact path="/recoverUser/step1" component={RecoveryUserStep1} />
                    <ExternalLayout exact path="/recoverUser/step2" component={RecoveryUserStep2} />

                    <ExternalLayout exact path="/recoveryPassword/step1" component={RecoveryPassStep1} />
                    <ExternalLayout exact path="/recoveryPassword/step2" component={RecoveryPassStep2} />
                    <ExternalLayout exact path="/recoveryPassword/step3" component={RecoveryPassStep3} />
                    <ExternalLayout exact path="/recoveryPassword/step4" component={RecoveryPassStep4} />

                    <ExternalLayout exact path="/session/expiredByMaxAttempts" component={ExpiredByMaxAttempts} />
                    <BackofficeLayout exact path="/forms/backoffice/ticket" component={BackofficeTransactionCustom} />

                    <ExternalLayout exact path="/languageSelection" component={LanguageSelection} />
                    <ExternalLayout exact path="/regionSelection" component={RegionSelection} />

                    <EnrollmentLayout step={7} exact path="/enrollment" component={EnrollmentIndex} showBack />

                    <EnrollmentLayout exact path="/enrollment/error" component={EnrollmentError} />
                    <EnrollmentLayout
                        exact
                        path="/enrollment/associate/step1"
                        component={EnrollmentAssociateStep1}
                        showBack
                    />
                    <EnrollmentLayout
                        exact
                        path="/enrollment/associate/step2"
                        component={EnrollmentAssociateStep2}
                        showBack
                    />
                    <EnrollmentLayout exact path="/enrollment/associate/step3" component={EnrollmentAssociateStep3} />
                    <EnrollmentLayout
                        exact
                        path="/enrollment/requestInvitationCode"
                        component={RequestInvitationCode}
                        mustGobackWithClose
                        showBack
                    />
                    <EnrollmentLayout
                        exact
                        path="/enrollment/requestInvitationCode/success"
                        component={RequestInvitationCodeSuccess}
                    />
                    <EnrollmentLayout exact path="/enrollment/step0" component={EnrollmentStep0} />
                    <EnrollmentLayout exact path="/enrollment/step1" component={EnrollmentStep1} />
                    <EnrollmentLayout exact path="/enrollment/step2" component={EnrollmentStep2} />
                    <EnrollmentLayout exact path="/enrollment/step3" component={EnrollmentStep3} />
                    <EnrollmentLayout
                        step={12}
                        exact
                        path="/enrollment/step3part4"
                        component={EnrollmentStep3Part4}
                        showBack
                    />
                    <EnrollmentLayout exact path="/enrollment/stepIRS" component={EnrollmentStepIRS} />

                    <EnrollmentLayout exact path="/enrollment/success" component={EnrollmentSuccess} showBack />

                    {!hasActiveSession && (
                        <ExternalLayout
                            exact
                            path="/securityPolicies"
                            component={SecurityPolicies}
                            isTermsOrPoliciesPage
                        />
                    )}
                    {!hasActiveSession && (
                        <ExternalLayout
                            exact
                            path="/termsAndConditions"
                            component={TermsAndConditions}
                            isTermsOrPoliciesPage
                        />
                    )}
                    {!hasActiveSession && <ExternalLayout exact path="/support" component={SupportInfo} />}

                    <ProductRequestLayout
                        exact
                        path="/products/onboarding/invitation/fail"
                        component={OnboardingInvitationCodeSendFail}
                    />
                    <PrivateRoutes />
                </Switch>
                <Route path="/" component={ExpireEnvironmentModal} />
                <Route path="/" component={ExpiredPasswordModal} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Routes)));
