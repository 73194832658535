// Action types
export const types = {
    NOTIFICATIONS_CONFIGURATION_PRE: "settings/NOTIFICATIONS_CONFIGURATION_PRE",
    NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS: "settings/NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS",
    COMMUNICATION_TYPE_PRE: "settings/COMMUNICATION_TYPE_PRE",
    COMMUNICATION_TYPE_PRE_SUCCESS: "settings/COMMUNICATION_TYPE_PRE_SUCCESS",
    MODIFY_NOTIFICATION_CONFIGURATIONS: "settings/MODIFY_NOTIFICATION_CONFIGURATIONS",
    MODIFY_PAPERLESS_CONFIGURATIONS: "settings/MODIFY_PAPERLESS_CONFIGURATIONS",

    CHANGE_LANGUAGE_PRE: "settings/CHANGE_LANGUAGE_PRE",
    CHANGE_LANGUAGE_PRE_SUCCESS: "settings/CHANGE_LANGUAGE_PRE_SUCCESS",
    CHANGE_LANGUAGE_PRE_ERROR: "settings/CHANGE_LANGUAGE_PRE_ERROR",
    CHANGE_DEFAULT_ENVIRONMENT_PRE: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE",
    CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS",
    CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR",
    CHANGE_LANGUAGE: "settings/CHANGE_LANGUAGE",

    GET_USER_DATA_FAILURE: "settings/GET_USER_DATA_FAILURE",
    GET_USER_DATA_SUCCESS: "settings/GET_USER_DATA_SUCCESS",
    GET_USER_DATA: "settings/GET_USER_DATA",

    MAIL_SEND_CODE: "settings/MAIL_SEND_CODE",

    MAIL_UPDATE: "settings/MAIL_UPDATE",
    MAIL_UPDATE_SUCCESS: "settings/MAIL_UPDATE_SUCCESS",

    MOBILEPHONE_SEND_CODE_SUCCESS: "settings/MOBILEPHONE_SEND_CODE_SUCCESS",
    MOBILEPHONE_SEND_CODE_FAILURE: "settings/MOBILEPHONE_SEND_CODE_FAILURE",
    MOBILEPHONE_SEND_CODE: "settings/MOBILEPHONE_SEND_CODE",

    MOBILEPHONE_UPDATE: "settings/MOBILEPHONE_UPDATE",
    MOBILEPHONE_UPDATE_SUCCESS: "settings/MOBILEPHONE_UPDATE_SUCCESS",
    MOBILEPHONE_UPDATE_FAILURE: "settings/MOBILEPHONE_UPDATE_FAILURE",

    CHANGE_PASSWORD_REQUEST: "settings/CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "settings/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "settings/CHANGE_PASSWORD_FAILURE",

    CHANGE_SECURITY_SEAL_PRE: "settings/CHANGE_SECURITY_SEAL_PRE",
    CHANGE_SECURITY_SEAL_PRE_SUCCESS: "settings/CHANGE_SECURITY_SEAL_PRE_SUCCESS",
    CHANGE_SECURITY_SEAL_CONFIRMATION_PRE: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_PRE",
    CHANGE_SECURITY_SEAL_CONFIRMATION: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION",
    CHANGE_SECURITY_SEAL_CONFIRMATION_BACK: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_BACK",
    CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR",
    CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS",

    LOAD_DOCUMENTS_REQUEST: "settings/LOAD_DOCUMENTS_REQUEST",
    LOAD_DOCUMENTS_SUCCESS: "settings/LOAD_DOCUMENTS_SUCCESS",
    DOWNLOAD_DOCUMENT_REQUEST: "settings/DOWNLOAD_DOCUMENT_REQUEST",

    FETCH_PRODUCTS_LIST: "settings/FETCH_PRODUCTS_LIST",
    FETCH_PRODUCTS_LIST_SUCCESS: "settings/FETCH_PRODUCTS_LIST_SUCCESS",
    UPDATE_PRODUCT_LIST: "settings/UPDATE_PRODUCT_LIST",
    CLEAN_ERRORS: "settings/CLEAN_ERRORS",

    SET_ALL_PAPERLESS_REQ: "settings/SET_ALL_PAPERLESS_REQ",
    SET_ALL_PAPERLESS_SUCCESS: "settings/SET_ALL_PAPERLESS_SUCCESS",
    SET_ALL_PAPERLESS_ERROR: "settings/SET_ALL_PAPERLESS_ERROR",

    GET_ADDRESSES_INFORMATION_REQ: "settings/GET_ADDRESSES_INFORMATION_REQ",
    GET_ADDRESSES_INFORMATION_SUCCESS: "settings/GET_ADDRESSES_INFORMATION_SUCCESS",
    GET_ADDRESSES_INFORMATION_FAILURE: "settings/GET_ADDRESSES_INFORMATION_FAILURE",

    SET_ADDRESSES_INFORMATION_REQ: "settings/SET_ADDRESSES_INFORMATION_REQ",

    GET_TOKEN_DATA: "settings/GET_TOKEN_DATA",
    GET_TOKEN_DATA_SUCCESS: "settings/GET_TOKEN_DATA_SUCCESS",

    REGISTER_TOKEN: "settings/REGISTER_TOKEN",
    REGISTER_TOKEN_SUCCESS: "settings/REGISTER_TOKEN_SUCCESS",
    REGISTER_TOKEN_ERROR: "settings/REGISTER_TOKEN_ERROR",

    VALIDATE_TOKEN: "settings/VALIDATE_TOKEN",
    VALIDATE_TOKEN_SUCCESS: "settings/VALIDATE_TOKEN_SUCCESS",
    VALIDATE_TOKEN_ERROR: "settings/VALIDATE_TOKEN_ERROR",
};

// Initial state
export const INITIAL_STATE = {
    documents: [],
    communicationTypes: null,
    communicationTransportsConfigurations: null,
    securitySeals: {},
    selectedSecuritySealId: null,
    supportedLanguages: null,
    userData: null,
    fetching: false,
    errors: null,
    productsList: null,
    address: {},
    mailingAddress: {},
    countries: [],
    hasOtp: false,
    passcode: "",
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        // Address information
        case types.GET_ADDRESSES_INFORMATION_REQ:
            return {
                ...state,
                address: {},
                mailingAddress: {},
                countries: [],
                fetching: true,
            };
        case types.GET_ADDRESSES_INFORMATION_FAILURE:
        case types.GET_ADDRESSES_INFORMATION_SUCCESS:
            return {
                ...state,
                address: action.address,
                mailingAddress: action.mailingAddress,
                countries: action.countries,
                fetching: false,
            };
        // Set all paperless action
        case types.SET_ALL_PAPERLESS_REQ:
            return { ...state, fetching: true, productsList: null };
        case types.SET_ALL_PAPERLESS_SUCCESS:
            return { ...state };
        case types.SET_ALL_PAPERLESS_ERROR:
            return { ...state, fetching: false };
        // Change language actions
        case types.CHANGE_LANGUAGE_PRE:
            return { ...state, fetching: true };
        case types.CHANGE_LANGUAGE_PRE_SUCCESS:
            return { ...state, fetching: false, supportedLanguages: action.supportedLanguages };
        case types.CHANGE_LANGUAGE_PRE_ERROR:
            return { ...state, fetching: false };
        // Change default environment actions
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE:
            return { ...state, fetching: true };
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return { ...state, fetching: false };
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR:
            return { ...state, fetching: false };
        case types.CHANGE_LANGUAGE:
            return { ...state, fetching: true };
        // Change notifications configuration actions
        case types.NOTIFICATIONS_CONFIGURATION_PRE:
            return { ...state, fetching: true, communicationTypes: null };
        case types.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS:
            return { ...state, fetching: false, communicationTypes: action.communicationTypes };
        case types.COMMUNICATION_TYPE_PRE:
            return { ...state, fetching: true, communicationTransportsConfigurations: null };
        case types.COMMUNICATION_TYPE_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                communicationTransportsConfigurations: action.communicationTransportsConfigurations,
            };
        case types.MODIFY_NOTIFICATION_CONFIGURATIONS:
            return {
                ...state,
                communicationTransportsConfigurations: {
                    ...state.communicationTransportsConfigurations,
                    [action.transportConfiguration]: action.subscribed,
                },
                notificationType: action.notificationType,
            };
        // obtain user data actions
        case types.GET_USER_DATA:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.data,
                fetching: false,
            };

        // change security seal actions
        case types.CHANGE_SECURITY_SEAL_PRE:
            return { ...state, fetching: true, selectedSecuritySealId: null };
        case types.CHANGE_SECURITY_SEAL_PRE_SUCCESS:
            return { ...state, fetching: false, securitySeals: action.securitySeals };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE:
            return { ...state, selectedSecuritySealId: action.selectedSecuritySealId };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION:
            return { ...state, errors: null, fetching: true };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK:
            return { ...state, selectedSecuritySealId: null, errors: null };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR:
            return { ...state, errors: action.errors, fetching: false };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return { ...state, errors: null, fetching: false };
        case types.LOAD_DOCUMENTS_REQUEST:
            return { ...state, fetching: true };
        case types.LOAD_DOCUMENTS_SUCCESS:
            return { ...state, fetching: false, documents: action.documents };
        // fetch product list
        case types.FETCH_PRODUCTS_LIST:
            return { ...state, fetching: true };
        case types.FETCH_PRODUCTS_LIST_SUCCESS:
            return { ...state, fetching: false };
        case types.UPDATE_PRODUCT_LIST:
            return { ...state, productsList: action.productsList };
        // general actions
        case types.CLEAN_ERRORS:
            return { ...state, errors: null };
        case types.GET_TOKEN_DATA_SUCCESS:
            return { ...state, hasOtp: action.hasOtp };
        case types.REGISTER_TOKEN_SUCCESS:
            return { ...state, passcode: action.passcode };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    updateProductList: (productsList) => ({
        type: types.UPDATE_PRODUCT_LIST,
        productsList,
    }),
    setAllPaperlessValue: (paperless) => ({
        type: types.SET_ALL_PAPERLESS_REQ,
        paperless,
    }),
    // language actions
    changeLanguage: (language, changeUserLanguage = true) => ({
        type: types.CHANGE_LANGUAGE_PRE,
        language,
        changeUserLanguage,
    }),
    changeDefaultEnvironment: (idEnvironment) => ({
        type: types.CHANGE_DEFAULT_ENVIRONMENT_PRE,
        idEnvironment,
    }),
    notificationsConfigurationPre: () => ({
        type: types.NOTIFICATIONS_CONFIGURATION_PRE,
    }),
    communicationTypePre: (communicationType) => ({
        type: types.COMMUNICATION_TYPE_PRE,
        communicationType,
    }),
    modifyNotificationConfigurations: (notificationType, transport, subscribed, transportConfiguration) => ({
        type: types.MODIFY_NOTIFICATION_CONFIGURATIONS,
        notificationType,
        transport,
        subscribed,
        transportConfiguration,
    }),
    modifyPaperlessConfigurations: (idProduct, paperless) => ({
        type: types.MODIFY_PAPERLESS_CONFIGURATIONS,
        idProduct,
        paperless,
    }),
    fetchProductsList: () => ({ type: types.FETCH_PRODUCTS_LIST }),

    // user data actions
    getUserData: () => ({
        type: types.GET_USER_DATA,
    }),
    // send mail code actions
    sendMailCode: (mail, otp, formikBag) => ({
        type: types.MAIL_SEND_CODE,
        mail,
        otp,
        formikBag,
    }),
    // updating mail actions (needs mail code)
    updateMail: (mailCode, formikBag) => ({
        type: types.MAIL_UPDATE,
        mailCode,
        formikBag,
    }),
    // send mobile phone code actions
    sendMobilePhoneCode: (mobilePhone, otp, formikBag) => ({
        type: types.MOBILEPHONE_SEND_CODE,
        mobilePhone,
        otp,
        formikBag,
    }),
    // updating mobile phone actions (needs mobile phone code)
    updateMobilePhone: (mobilePhoneCode, formikBag) => ({
        type: types.MOBILEPHONE_UPDATE,
        mobilePhoneCode,
        formikBag,
    }),
    changePassword: (password, newPassword, newPasswordConfirmation, formikBag) => ({
        type: types.CHANGE_PASSWORD_REQUEST,
        password,
        newPassword,
        newPasswordConfirmation,
        formikBag,
    }),
    setAddressesInformation: (address, mailingAddress, otp, formikBag) => ({
        type: types.SET_ADDRESSES_INFORMATION_REQ,
        address,
        mailingAddress,
        otp,
        formikBag,
    }),

    changeSecuritySealPre: () => ({
        type: types.CHANGE_SECURITY_SEAL_PRE,
    }),
    changeSecuritySealConfirmationPre: (selectedSecuritySealId) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE,
        selectedSecuritySealId,
    }),
    changeSecuritySealConfirmationBack: () => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK,
    }),
    changeSecuritySealConfirmation: (selectedSecuritySealId, otp, formikBag) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION,
        securitySeal: selectedSecuritySealId,
        otp,
        formikBag,
    }),
    loadDocumentsRequest: () => ({
        type: types.LOAD_DOCUMENTS_REQUEST,
    }),
    downloadDocumentRequest: (fileNameKey) => ({
        type: types.DOWNLOAD_DOCUMENT_REQUEST,
        fileNameKey,
    }),
    // general actions
    cleanErrors: () => ({
        type: types.CLEAN_ERRORS,
    }),

    // Address information
    getAddressesInformation: () => ({
        type: types.GET_ADDRESSES_INFORMATION_REQ,
    }),

    getTokenData: () => ({
        type: types.GET_TOKEN_DATA,
    }),
    registerToken: (hasOtp, password, formikBag) => ({
        type: types.REGISTER_TOKEN,
        hasOtp,
        password,
        formikBag,
    }),
    validateToken: (otp, formikBag) => ({
        type: types.VALIDATE_TOKEN,
        otp,
        formikBag,
    }),
};

// Selectors
export const selectors = {
    getSecuritySeals: (state) => state.settings.securitySeals,
    getSelectedSecuritySeal: (state) => {
        if (state.settings.selectedSecuritySealId) {
            return state.settings.securitySeals[state.settings.selectedSecuritySealId];
        }
        return null;
    },
    getSelectedSecuritySealId: (state) => state.settings.selectedSecuritySealId,
    getSupportedLanguages: (state) => state.i18n.supportedLanguages,
    getCommunicationTypes: (state) => state.settings.communicationTypes,
    getCommunicationTransportsConfigurations: (state) => state.settings.communicationTransportsConfigurations,
    getCommunicationTransportsConfigurationsKeys: (state) => {
        if (!state.settings.communicationTransportsConfigurations) {
            return [];
        }
        const keys = Object.keys(state.settings.communicationTransportsConfigurations);
        keys.sort();
        return keys;
    },
    getErrors: (state) => state.settings.errors,
    isFetching: (state) => state.settings.fetching,
    getEmail: (state) => (state.settings.userData && state.settings.userData.mail ? state.settings.userData.mail : ""),
    getEmailConfirm: (state) => state.settings.confirmEmailCode,
    getMobilePhone: (state) => {
        if (state.settings.userData && state.settings.userData.mobilePhone) {
            return state.settings.userData.mobilePhone;
        }
        return null;
    },
    getDocuments: (state) => state.settings.documents,
    getProductsLists: (state) => state.settings.productsList,
    getAddress: (state) => state.settings.address,
    getMailingAdress: (state) => state.settings.mailingAddress,
    getCountries: (state) => state.settings.countries,
    getHasOtp: (state) => state.settings.hasOtp,
    getPasscode: (state) => state.settings.passcode,
};
