import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import types from "reducers/types/form";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import * as i18nUtils from "util/i18n";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.acceptCession.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");

const AcceptCessionForm = (props) => {
    const { echeq, activeEnvironment, mode, dispatch, location, data, transaction } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};
    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const clientDocNumber = activeEnvironment?.clients ? activeEnvironment.clients[0].idClient : null;
    const {
        cheque_id,
        cheque_numero,
        beneficiario_nombre,
        cesiones,
        estado,
        monto,
        fecha_pago,
        fecha_emision,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        cheque_caracter,
    } = echeq;
    const clientCessionData =
        data?.clientCessionData ||
        cesiones?.filter(
            (cesion) => cesion.estado_cesion === "PENDIENTE" && cesion.cesionario_documento === clientDocNumber,
        )[0];

    const { idTransactionStatus, idTransaction } = transaction;
    const paymentDate = fecha_pago?.substring(0, 10);
    const emissionDate = fecha_emision?.substring(0, 10);
    const isDeferred = data?.isDeferred || emissionDate !== paymentDate;
    let statusData = data?.estado || estado;
    if (statusData === "RECHAZADO") {
        statusData += `- ${i18nUtils.get("forms.echeqs.list.substatus.CESION-PENDIENTE")}`;
    }

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info ui-mb-8">
                    <I18n id={`${ID_FORM}.message`} />
                </div>
            )}
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.subtitle.echeqData`} componentProps={{ className: "data-label" }} />
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqId.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                <span>{i18nUtils.get(`${ID_FORM}.type.${isDeferred ? "deferred" : "today"}.label`)}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitEmitter.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.emitter.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitCedent.label`} componentProps={{ className: "data-label" }} />
                <span>{`${clientCessionData.cedente_documento_tipo} ${clientCessionData.cedente_documento}`}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cedent.label`} componentProps={{ className: "data-label" }} />
                <span>{clientCessionData.cedente_nombre}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                    <span>{statusData}</span>
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <span>
                    <FormattedDate date={data?.fecha_pago || fecha_pago} />
                </span>
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id: data?.cheque_id || cheque_id,
            cheque_numero: data?.cheque_numero || cheque_numero,
            isDeferred: data?.isDeferred || isDeferred,
            cheque_caracter: data?.cheque_caracter || cheque_caracter,
            emisor_cuit: data?.emisor_cuit || emisor_cuit,
            emisor_razon_social: data?.emisor_razon_social || emisor_razon_social,
            beneficiario_nombre: data?.beneficiario_nombre || beneficiario_nombre,
            cesiones: data?.cesiones || cesiones,
            estado: data?.estado || estado,
            amount: data?.amount || {
                quantity: monto,
                currency: emisor_moneda,
            },
            fecha_pago: data?.fecha_pago || fecha_pago,
            clientCessionData: data?.clientCessionData || clientCessionData,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

AcceptCessionForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};
AcceptCessionForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(AcceptCessionForm);
