import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { actions as investmentsActions, selectors as investmentsSelectors } from "../../reducers/investments";
import { selectors as sessionSelectors } from "../../reducers/session";
import InvestorProfileImage from "./_components/investorProfile/InvestorProfileImage";
import InvestorProfileType from "./_components/investorProfile/InvestorProfileType";

class InvesterProfileContainer extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        history: func.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        investorProfile: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        fetchingDownload: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, activeEnvironment } = this.props;
        dispatch(investmentsActions.getInvestorProfileRequest(activeEnvironment.segment));
    }

    handleDownloadProfile = () => {
        const { dispatch, investorProfile, activeEnvironment } = this.props;
        dispatch(investmentsActions.downloadInvestorProfileRequest(activeEnvironment.segment, investorProfile?.type));
    };

    getProfile = () => {
        const { isMobile, investorProfile, fetchingDownload, history } = this.props;
        return (
            <div>
                <InvestorProfileType profile={investorProfile} />

                <Button
                    onClick={() => {
                        history.push("/formCustom/investerProfileForm");
                    }}
                    type="submit"
                    bsStyle="primary"
                    block
                    label={`investments.mutualFund.investerProfile.${
                        investorProfile.status === "upToDate" ? "modifyProfile" : "updateProfile"
                    }`}
                    className="ui-mt-8"
                />

                {isMobile && (
                    <Button
                        className="ui-mt-4"
                        bsStyle="outline"
                        label="global.download"
                        image="/images/download.svg"
                        onClick={this.handleDownloadProfile}
                        block
                        loading={fetchingDownload}
                    />
                )}
            </div>
        );
    };

    noProfile = () => (
        <>
            <div className="ui-mb-7">
                <Row className="ui-dflex-center">
                    <InvestorProfileImage />
                </Row>
                <div className="invester-text">
                    <Row className="ui-dflex-center">
                        <I18n id="investments.mutualFund.investerProfile.noProfile" />
                    </Row>
                </div>
            </div>
            <Button
                type="submit"
                label="investments.mutualFund.investerProfile.createProfile"
                onClick={this.props.history.push("/formCustom/investerProfileForm")}
                bsStyle="primary"
            />
        </>
    );

    renderInvestorProfile = () => {
        const { isMobile, investorProfile } = this.props;
        return (
            <>
                <div className="title-left-header">
                    <I18n id="investments.mutualFund.investerProfile" component="h1" />
                </div>

                {investorProfile?.created ? this.getProfile(investorProfile, isMobile) : this.noProfile()}
            </>
        );
    };

    renderHeader = () => {
        const { isMobile, history, investorProfile } = this.props;
        return (
            <Fragment>
                {isMobile ? (
                    <Head onBack={history.goBack} showLogo arrowWhite additionalClassName="blue-main-header-mobile" />
                ) : (
                    <Head
                        addLinkToLabel="global.download"
                        addLinkTo="/"
                        customButtonOnClick={this.handleDownloadProfile}
                        customDownloadButton={investorProfile?.created}
                    />
                )}
            </Fragment>
        );
    };

    render() {
        const { fetching, investorProfile } = this.props;
        return (
            <Fragment>
                <Notification scopeToShow="investorProfile" />
                {!fetching && this.renderHeader()}

                <MainContainer showLoader={fetching}>
                    <div className="container-center">{investorProfile && this.renderInvestorProfile()}</div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: investmentsSelectors.getFetching(state),
    investorProfile: investmentsSelectors.getInvestorProfile(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetchingDownload: investmentsSelectors.getFetchingDownload(state),
});

export default connect(mapStateToProps)(InvesterProfileContainer);
