import { call, put, takeLatest } from "redux-saga/effects";

import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_GROUPS_OF_USER_TICKET_REQUEST, loadGroupsOfUserTicketRequest(middleware, actions)),
];

export default sagasCreator;

const loadGroupsOfUserTicketRequest = (middleware, actions) =>
    function*({ idTransaction }) {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put(actions.loadGroupsOfUserTicketFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const responseDetail = yield call(
                middleware.loadDetailsRequest,
                transactionResponse.data.data.transaction.data.idUser,
            );
            const { availableGroups } = responseDetail.data.data;
            const transactionGroups = transactionResponse.data.data.transaction.data.groups;
            const { user } = responseDetail.data.data;
            const groups = availableGroups.filter((group) => transactionGroups.indexOf(group.idGroup) > -1);

            if (responseDetail.type === "W") {
                yield put(actions.loadGroupsOfUserTicketFailure());
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "administration",
                    ]),
                );
            } else {
                yield put(actions.loadGroupsOfUserTickeSuccess({ groups, user }));
            }
        }
    };
