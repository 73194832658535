import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
// import Slider from "react-slick"; /* se comenta hasta tener mockup de mobile */
import { shape /* bool */ } from "prop-types";
import * as i18n from "util/i18n";

import { selectors as sessionSelectors } from "reducers/session";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

class DetailHeadInfo extends Component {
    static propTypes = {
        loan: shape({}).isRequired,
        // /*Se comenta hasta tener mockup de mobile */
        // payLoanPermission: bool.isRequired,
        // isDesktop: bool,
    };

    static defaultProps = {
        // isDesktop: false,
    };

    renderContent = (
        {
            date,
            amount,
            currencyCode,
            currency,
            amortizationType,
            settlement,
            underlyingAsset,
            quantity,
            agreedPrice,
            residualValue,
            speciesDescription,
            capitalAdjustment,
            quantityUVA,
            originalValueUVA,
            valueLastUpdateUVA,
            feesPeriod,
            gracePeriod,
            settlementCurrency,
            termInDays,
            expiry,
            firstExpiration,
            lastExpiration,
            beneficiary,
            rateType,
            baseRate,
            spread,
            currentRate,
            discountRate,
            referenceExchangeRate,
            balanceDifference,
            balanceAtExpiry,
            tea,
            cftTEA,
            tna,
            codeSIOC,
            totalFees,
        },
        loanType,
    ) => (
        <Fragment>
            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="loan.detail.date" />
                </span>
                <FormattedDate date={date} />
            </div>
            {codeSIOC !== "COCAMV" && codeSIOC !== "COCAMC" ? (
                <Fragment>
                    {amortizationType && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.amortizationType" />
                            </span>
                            <span className="data-numeric">{amortizationType}</span>
                        </div>
                    )}
                    {amount > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.amount" />
                            </span>
                            <FormattedAmount currency={currencyCode} quantity={amount} />
                        </div>
                    )}

                    {currencyCode && codeSIOC !== "PRE990" && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.currency" />
                            </span>
                            <span className="data-numeric">{i18n.get(`loan.detail.currency.${currency}`)}</span>
                        </div>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    {settlement && settlement !== "" && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.settlement" />
                            </span>
                            <span className="data-numeric">{settlement}</span>
                        </div>
                    )}
                    {underlyingAsset && underlyingAsset !== "" && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.underlyingAsset" />
                            </span>
                            <span className="data-numeric">{i18n.get(`currency.name.${underlyingAsset}`)}</span>
                        </div>
                    )}
                    {quantity > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.quantity" />
                            </span>
                            <span className="data-numeric">{quantity}</span>
                        </div>
                    )}
                    {agreedPrice > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.agreedPrice" />
                            </span>
                            <FormattedAmount currency={currencyCode} quantity={agreedPrice} />
                        </div>
                    )}
                </Fragment>
            )}
            {codeSIOC === "PRE990" && (
                <Fragment>
                    {residualValue > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.residualValue" />
                            </span>
                            <span className="data-numeric">{residualValue}</span>
                        </div>
                    )}
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.speciesDescription" />
                        </span>
                        <span className="data-numeric">{speciesDescription}</span>
                    </div>
                </Fragment>
            )}
            {codeSIOC === "OPREV1" && (
                <Fragment>
                    {capitalAdjustment > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.capitalAdjustment" />
                            </span>
                            <FormattedAmount currency={currencyCode} quantity={capitalAdjustment} />
                        </div>
                    )}
                    {quantityUVA > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.quantityUVA" />
                            </span>
                            <FormattedAmount quantity={quantityUVA} hideCurrency />
                        </div>
                    )}
                    {originalValueUVA > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.originalValueUVA" />
                            </span>
                            <FormattedAmount currency="ARS" quantity={originalValueUVA} />
                        </div>
                    )}
                    {valueLastUpdateUVA > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.valueLastUpdateUVA" />
                            </span>
                            <FormattedAmount currency="ARS" quantity={valueLastUpdateUVA} />
                        </div>
                    )}
                </Fragment>
            )}
            {totalFees > 1 && (
                <Fragment>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.totalFees" />
                        </span>
                        <span className="data-numeric">{totalFees}</span>
                    </div>
                    {feesPeriod > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.feesPeriod" />
                            </span>
                            <span className="data-numeric">{feesPeriod}</span>
                        </div>
                    )}
                </Fragment>
            )}
            {gracePeriod > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.gracePeriod" />
                    </span>
                    <span className="data-numeric">{gracePeriod}</span>
                </div>
            )}

            {(codeSIOC === "COCAMV" || codeSIOC === "COCAMC") && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.settlementCurrency" />
                    </span>
                    <span className="data-numeric">{i18n.get(`loan.detail.currency.${settlementCurrency}`)}</span>
                </div>
            )}
            {totalFees <= 1 ? (
                <Fragment>
                    {termInDays > 0 && (
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="loan.detail.termInDays" />
                            </span>
                            <span className="data-numeric">{termInDays}</span>
                        </div>
                    )}
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.expiry" />
                        </span>
                        <FormattedDate date={expiry} />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.firstExpiration" />
                        </span>
                        <FormattedDate date={firstExpiration} />
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.lastExpiration" />
                        </span>
                        <FormattedDate date={lastExpiration} />
                    </div>
                </Fragment>
            )}
            {(codeSIOC === "GOVS" || codeSIOC === "GOAVBA" || codeSIOC === "GOAVON") &&
                beneficiary &&
                beneficiary !== "" && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.beneficiary" />
                        </span>
                        <span className="data-numeric">{beneficiary}</span>
                    </div>
                )}
            {rateType && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.rateType" />
                    </span>
                    <span className="data-numeric">{rateType}</span>
                </div>
            )}
            {baseRate > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.baseRate" />
                    </span>
                    <span className="data-numeric">{baseRate}</span>
                </div>
            )}

            {spread > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.spread" />
                    </span>
                    <span className="data-numeric">{`${spread} %`}</span>
                </div>
            )}
            {tna > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.tna" />
                    </span>
                    <span className="data-numeric">{`${tna} %`}</span>
                </div>
            )}
            {tea > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.tea" />
                    </span>
                    <span className="data-numeric">{`${tea} %`}</span>
                </div>
            )}
            {cftTEA > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.cftTEA" />
                    </span>
                    <span className="data-numeric">{`${cftTEA} %`}</span>
                </div>
            )}
            {currentRate > 0 && loanType === "current" && totalFees > 1 && rateType === "VARIABLE" && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.currentRate" />
                    </span>
                    <span className="data-numeric">{`${currentRate} %`}</span>
                </div>
            )}
            {discountRate > 0 && (
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="loan.detail.discountRate" />
                    </span>
                    <span className="data-numeric">{`${discountRate} %`}</span>
                </div>
            )}

            {(codeSIOC === "COCAMV" || codeSIOC === "COCAMC") && loanType === "history" && (
                <Fragment>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.referenceExchangeRate" />
                        </span>
                        <FormattedAmount currency={currencyCode} quantity={referenceExchangeRate} />
                    </div>
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.balanceDifference" />
                        </span>
                        <FormattedAmount currency={currencyCode} quantity={balanceDifference} />
                    </div>
                </Fragment>
            )}

            {codeSIOC !== "COCAMV" &&
                codeSIOC !== "COCAMC" &&
                codeSIOC !== "MINAGD" &&
                codeSIOC !== "MINAGD2" &&
                totalFees <= 1 &&
                balanceAtExpiry > 0 && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="loan.detail.balanceAtExpiry" />
                        </span>
                        <FormattedAmount currency={currency} quantity={balanceAtExpiry} />
                    </div>
                )}
        </Fragment>
    );

    render() {
        const { loan } = this.props;

        /* Se comenta hasta tener mockup de mobile */
        // const { loan, isDesktop } = this.props;

        // const settings = {
        //     dots: true,
        //     infinite: false,
        //     speed: 200,
        //     slidesToShow: isDesktop ? 3 : 1,
        //     slidesToScroll: 1,
        //     arrows: false,
        // };

        return (
            <div className="content-data detail-head-info">{this.renderContent(loan)}</div>

            // /* Se comenta hasta tener mockup de mobile */
            // (isDesktop && (
            //     <div className="content-data-container">
            //         <Fragment>
            //             <div className="content-data detail-head-info">{this.renderContent(loan)}</div>
            //         </Fragment>
            //     </div>
            // )) || (
            //     <Fragment>
            //         {loan && (
            //             <div className="slick-slider-wrapper">
            //                 <Slider {...settings}>{this.renderContent(loan)}</Slider>
            //                 <div className="content-data-item">
            //                     {/* <ProgressBarLoan paidPercentage={loan.paidPercentage} /> */}
            //                 </div>

            //                 {/* <div className="content-data-item">{paymentButton}</div> */}
            //             </div>
            //         )}
            //     </Fragment>
            // )
        );
    }
}

const mapStateToProps = (state) => ({
    payLoanPermission: sessionSelectors.hasPermissions(state, ["payLoan"]),
});

export default connect(mapStateToProps)(DetailHeadInfo);
