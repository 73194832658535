import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    CLOSE_OPTIONS: "creditCardMovementDetail/CLOSE_OPTIONS",
    DETAILS_REQUEST: "creditCardMovementDetail/DETAILS_REQUEST",
    DETAILS_FAILURE: "creditCardMovementDetail/DETAILS_FAILURE",
    DETAILS_SUCCESS: "creditCardMovementDetail/DETAILS_SUCCESS",
    UPDATE_NOTE_REQUEST: "creditCardMovementDetail/UPDATE_NOTE_REQUEST",
    UPDATE_NOTE_FAILURE: "creditCardMovementDetail/UPDATE_NOTE_FAILURE",
    UPDATE_NOTE_SUCCESS: "creditCardMovementDetail/UPDATE_NOTE_SUCCESS",
};

export const INITIAL_STATE = {
    isFetching: true,
    detail: null,
};

export default createReducer(INITIAL_STATE, {
    [types.DETAILS_REQUEST]: (state, action) => INITIAL_STATE,
    [types.DETAILS_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAILS_SUCCESS]: (state, action) => ({
        isFetching: false,
        detail: action.movement,
    }),
    [types.UPDATE_NOTE_REQUEST]: (state, action) => ({ ...state }),
    [types.UPDATE_NOTE_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
    }),
    [types.UPDATE_NOTE_SUCCESS]: (state, action) => ({
        isFetching: false,
        detail: {
            ...state.detail,
            note: action.note,
        },
    }),
});

export const actions = {
    closeOptions: makeActionCreator(types.CLOSE_OPTIONS),
    detailRequest: makeActionCreator(types.DETAILS_REQUEST, "idCreditCard", "idStatement"),
    detailFailure: makeActionCreator(types.DETAILS_FAILURE),
    detailSuccess: makeActionCreator(types.DETAILS_SUCCESS, "movement"),
    updateNoteRequest: makeActionCreator(types.UPDATE_NOTE_REQUEST, "idProduct", "idStatement", "note"),
    updateNoteFailure: makeActionCreator(types.UPDATE_NOTE_FAILURE),
    updateNoteSuccess: makeActionCreator(types.UPDATE_NOTE_SUCCESS, "note"),
};

export const selectors = {
    isFetching: ({ creditCardMovementDetails }) => creditCardMovementDetails.isFetching,
    getMovement: ({ creditCardMovementDetails }) => creditCardMovementDetails.detail,
};
