import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as filterActions } from "reducers/filters";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { insertHTMLLinebreaks, insertLinebreaks } from "util/string";

const channels = { frontend: "Web", phonegap: "Mobile", assistant: "Assistant" };

class MovementDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idAccount: string,
            idStatement: string,
            note: string,
        }).isRequired,
        account: shape({
            currency: string,
        }).isRequired,
        fetching: bool,
        isMobile: bool.isRequired,
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    state = {
        note: {
            value: "",
            isEditing: true,
        },
    };

    componentDidMount() {
        const { movement, dispatch, match } = this.props;
        if (movement) {
            const hasNote = movement.note;
            this.setState({ note: { value: hasNote || "", isEditing: !hasNote } });
        } else {
            dispatch(replace(`/accounts/${match.params.id}`));
        }
    }

    handleChange = (event) => {
        const { value } = event.target;

        this.setState({ note: { value, isEditing: true } });
    };

    handleClick = () => {
        const { note } = this.state;
        const { dispatch } = this.props;
        const { isEditing } = note;
        let { value } = note;

        value = value.replace(/\n+/g, "\n");

        if (isEditing) {
            const { movement } = this.props;
            const { idAccount, idStatement } = movement;
            const valueTrim = value.replaceAll(" ", "").trim().length;
            if (valueTrim > 0 || value.replaceAll(" ", "").length > 0) {
                dispatch(accountsActions.editMovementNote(idAccount, idStatement, insertLinebreaks(value)));
                this.setState({ note: { isEditing: false, value } });
            } else {
                dispatch(
                    notificationActions.showNotification(i18n.get("accounts.movement.detail.noteUnsaved"), "error", [
                        "movementDetail",
                    ]),
                );
            }
        } else {
            this.setState({ note: { isEditing: true, value } });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(filterActions.setResetFilters(false));
        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(goBack());
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo arrowWhite onBack={this.handleBack} additionalClassName="blue-main-header-mobile" />;
        }

        return <Head onBack={this.handleBack} />;
    };

    renderDetails = () => {
        const { movement } = this.props;
        return movement && movement.details.map((detail) => detail !== "" && <div>{detail}</div>);
    };

    render() {
        const {
            note: { value, isEditing },
        } = this.state;

        const { account, fetching, movement, isMobile } = this.props;

        const isCheck = movement && movement.check && movement.check !== 0;

        const hasDetails = movement && movement.details?.filter((detail) => detail !== "").length > 0;

        return (
            movement && (
                <Fragment>
                    <Notification scopeToShow="movementDetail" />
                    {!fetching && this.renderHeader()}

                    <MainContainer showLoader={fetching}>
                        <div className="title-left-header">
                            <I18n id="accounts.movement.detail.title" component="h1" />
                        </div>
                        <form className="form-content">
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="accounts.movement.detail.date" />
                                </span>
                                <span className="data-date">
                                    <FormattedDate date={movement.date} />
                                </span>
                            </div>

                            <hr className="ui-mb-0" />

                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="accounts.movement.detail.description" />
                                </span>
                                <span className="data-reference">{movement.reference}</span>
                            </div>
                            <div className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="accounts.movement.detail.amount" />
                                </span>
                                <FormattedAmount
                                    className={`data-number data-numeric data-amount-${
                                        movement.credit ? "credit" : "debit"
                                    }`}
                                    currency={account.currency}
                                    quantity={movement.credit || movement.debit}
                                />
                            </div>
                            {movement.channel && (
                                <div className="data-wrapper channel">
                                    <span className="data-label">
                                        <I18n id="accounts.movement.detail.channel" />
                                    </span>
                                    <span className="data-desc">{channels[movement.channel]}</span>
                                </div>
                            )}
                            {Boolean(isCheck) && (
                                <div className="data-wrapper check">
                                    <span className="data-label">
                                        <I18n id="accounts.movement.detail.check" />
                                    </span>
                                    <span className="data-number">
                                        <span>{movement.check}</span>
                                    </span>
                                </div>
                            )}
                            {hasDetails && (
                                <div className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="accounts.movement.detail.details" />
                                    </span>
                                    <span className="data-text">{this.renderDetails()}</span>
                                </div>
                            )}

                            <hr />

                            <div className="form-group">
                                <div className="form-group-text">
                                    <label htmlFor="movement.detail" className="control-label">
                                        <I18n id="accounts.movement.detail.notes" />
                                    </label>
                                </div>
                                {(!isEditing && (
                                    <div dangerouslySetInnerHTML={{ __html: insertHTMLLinebreaks(value) }} />
                                )) || (
                                    <div className="input-group textarea">
                                        <textarea
                                            id="movement.detail"
                                            className="form-control ui-textarea"
                                            value={insertLinebreaks(value)}
                                            rows="5"
                                            maxLength="40"
                                            onChange={this.handleChange}
                                            resize="false"
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="ui-text-right">
                                <Button
                                    block={isMobile}
                                    bsStyle="primary"
                                    label={
                                        isEditing
                                            ? "accounts.movement.detail.saveNotes"
                                            : "accounts.movement.detail.editNotes"
                                    }
                                    onClick={this.handleClick}
                                />
                            </div>
                        </form>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetchingMovements(state),
    movement: accountsSelectors.getSelectedMovement(state),
    voucher: accountsSelectors.getVoucher(state),
});

export default connect(mapStateToProps)(MovementDetail);
