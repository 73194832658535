import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";

export const readForm = (idForm, data) =>
    API.executeWithAccessToken("/core.forms.read", {
        idForm,
        ...data,
    });

export const pre = (idActivity, data) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        ...data,
    });

export const preview = (idForm, idActivity, idTransaction, data) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...data,
    });

export const send = (idForm, idActivity, idTransaction, data, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    const params = {
        ...data,
        ...credentialsWithUnderscore,
    };

    if (idTransaction === null) {
        return API.executeWithAccessToken(`/${idActivity}`, {
            idForm,
            ...params,
        });
    }

    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...params,
    });
};

export const signPreview = (idForm, idActivity, idTransaction) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
    });

export const sign = (idForm, idActivity, idTransaction, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...credentialsWithUnderscore,
    });
};

export const listCredentialsGroups = (idForm, idActivity) =>
    API.executeWithAccessToken("/core.listTransactionCredentialsGroups", {
        idActivityToRead: idActivity,
        idForm,
    });

export const readTransaction = (idTransaction) =>
    API.executeWithAccessToken("/core.readTransaction", {
        idTransactionToRead: idTransaction,
    });

export const saveDraft = (idForm, transactionData, idActivityDraft, idTransactionToSave) =>
    API.executeWithAccessToken("/core.saveDraftTransaction", {
        idForm,
        idActivityDraft,
        idTransactionToSave,
        transactionData,
    });

export const saveTemplate = (idForm, data, templateName, idActivityTemplate) =>
    API.executeWithAccessToken("/core.createTransactionTemplate", {
        idForm,
        transactionData: data,
        idActivityTemplate,
        templateName,
    });

export const cancelTransaction = (idTransactionToCancel, credentials) =>
    API.executeWithAccessToken("/core.cancelTransaction", {
        idTransactionToCancel,
        ...credentials,
    });

export const cancelRequest = (idTransactionToCancel, credentials, cancelReason) =>
    API.executeWithAccessToken("/core.cancelTransaction", {
        idTransactionToCancel,
        cancelReason,
        ...credentials,
    });

export const moveToDraftTransaction = (idTransactionToMove) =>
    API.executeWithAccessToken("/core.moveToDraftTransaction", {
        idTransactionToMove,
    });

export const readTransactionFromBackoffice = (exchangeToken) =>
    API.executeAnonymous("/core.forms.backoffice.read", { _backofficeExchangeToken: exchangeToken });

export const downloadTicket = (idTicket, fileFormat, idForm) =>
    API.executeWithAccessToken("/transactions.downloadTicket", {
        idTicket,
        format: fileFormat,
        idForm,
    });
export const downLoadTicketCustom = (idActivity, idTransaction) =>
    API.executeWithAccessToken(`/${idActivity}`, {
        idTicket: idTransaction,
    });
