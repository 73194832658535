import { bool, func, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";

import { actions as communicationsActions, selectors as communicationsSelector } from "reducers/communications";
import { actions as miscActions } from "reducers/misc";

import LeftPanel from "pages/communications/_components/LeftPanel";
import List from "pages/communications/_components/List";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";

import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import * as utilsI18n from "util/i18n";

class CommunicationMain extends Component {
    state = {
        isComposing: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        preloadedMessage: shape({}).isRequired,
    };

    componentDidMount() {
        const { dispatch, preloadedMessage } = this.props;
        dispatch(communicationsActions.listTypesRequest());
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));

        if (preloadedMessage) {
            dispatch(communicationsActions.showComposePanel());
        }
    }

    handleComposeClick = (isReply) => {
        const { isDesktop, dispatch } = this.props;

        dispatch(communicationActions.resetPreloadedMessage());

        if (isDesktop) {
            if (isReply) {
                dispatch(communicationsActions.showReplyPanel());
            } else {
                dispatch(communicationsActions.showComposePanel());
            }
        } else {
            dispatch(push("/communications/compose"));
        }
    };

    handleComposeCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.resetPreloadedMessage());
        dispatch(communicationsActions.hideAllPanels());
    };

    handleBackClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.resetPreloadedMessage());
        dispatch(goBack());
    };

    goDigitalSignature = () => {
        const { dispatch } = this.props;
        dispatch(miscActions.getDigitalSignature());
    };

    render() {
        const { isDesktop } = this.props;
        const { isComposing } = this.state;

        const tooltipProps = {
            "data-tooltip": utilsI18n.get("window.tooltip.digitalSignature"),
            "data-tooltip-position": "bottom",
            "data-tooltip-name": "digitalSignature",
        };

        return (
            <>
                <Notification scopeToShow="communications" />

                <Head
                    isDesktop={isDesktop}
                    onAdd={this.handleComposeClick}
                    addLinkToLabel="communications.compose.message.new"
                    onBack={this.handleBackClick}
                    digitalSignature={() => this.goDigitalSignature()}
                    tooltipProps={tooltipProps}
                    showPlusIcon={isDesktop}
                    showLogo={!isDesktop}
                    arrowWhite={!isDesktop}
                    additionalClassName={isDesktop ? "" : "blue-main-header-mobile"}
                />

                <MainContainer>
                    <div className="title-left-header">
                        <I18n id="chat.title" component="h1" />
                    </div>

                    {isDesktop ? (
                        <div className="container-left">
                            <section className="container-fluid mailing">
                                <Row className="justify-content-center communications-desktop-cols">
                                    <Col className="col col-6 all-messages-container">
                                        <List isDesktop={isDesktop} />
                                    </Col>
                                    <Col className="col col-6 messages-container">
                                        <LeftPanel
                                            compose={isComposing}
                                            close={this.handleComposeCloseClick}
                                            reply={this.handleComposeClick}
                                            isDesktop={isDesktop}
                                        />
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    ) : (
                        <div className="container-fluid">
                            <div className="mailing">
                                <Button
                                    image="images/plusWhite.svg"
                                    label="communications.compose.message.new"
                                    onClick={this.handleComposeClick}
                                    bsStyle="secondary"
                                    block
                                    className="ui-mb-4"
                                />
                                <Button
                                    image="images/iconos/sign.svg"
                                    label="window.name.digitalSignature"
                                    onClick={() => this.goDigitalSignature()}
                                    bsStyle="outline"
                                    block
                                    className="ui-mb-3"
                                />
                                <I18n
                                    id="window.tooltip.digitalSignature.mobile"
                                    componentProps={{ className: "ui-body-small" }}
                                />

                                <List isDesktop={isDesktop} />
                            </div>
                        </div>
                    )}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    types: communicationsSelector.listTypes(state),
    preloadedMessage: communicationSelectors.getPreloadedMessage(state),
});

export default connect(mapStateToProps)(CommunicationMain);
