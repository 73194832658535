import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";

import { func, bool } from "prop-types";

import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Step1Content from "pages/recoveryPassword/_components/Step1Content";
import HeaderLogin from "../_components/header/HeaderLogin";

class RecoveryPassStep1 extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        const { isDesktop } = this.props;

        const mainContainerClass = classNames({
            "main-container": true,
            "container-fluid": isDesktop,
        });

        return (
            <>
                <Notification scopeToShow="recoveryPassword" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className={mainContainerClass}>
                        <Step1Content {...this.props} />
                    </MainContainer>
                </div>
            </>
        );
    }
}

RecoveryPassStep1.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    showCaptcha: recoveryPasswordSelectors.getShowCaptcha(state),
});

export default connect(mapStateToProps)(RecoveryPassStep1);
