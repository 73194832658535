export const types = {
    GEOLOCATION_REQUEST: "geo/GEOLOCATION_REQUEST",
    GEOLOCATION_SUCCESS: "geo/GEOLOCATION_SUCCESS",
    GEOLOCATION_FAIL: "geo/GEOLOCATION_FAIL",
};

const INITIAL_STATE = {
    locationFound: null,
    geoData: null,
    fetching: false,
};

const geolocationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GEOLOCATION_REQUEST:
            return { ...state, fetching: true };

        case types.GEOLOCATION_SUCCESS:
            return {
                ...state,
                locationFound: action.locationFound,
                geoData: action.geoData,
                fetching: false,
            };

        case types.GEOLOCATION_FAIL:
            return {
                state: INITIAL_STATE,
            };

        default:
            return state;
    }
};

export const actions = {
    geolocationRequest: (google) => ({
        type: types.GEOLOCATION_REQUEST,
        google,
    }),

    geolocationSuccess: (locationFound, geoData) => ({
        type: types.GEOLOCATION_SUCCESS,
        locationFound,
        geoData,
    }),

    geolocationFail: () => ({
        type: types.GEOLOCATION_FAIL,
    }),
};

export const selectors = {
    getGeoLocation: ({ geolocation }) => geolocation,
};

export default geolocationReducer;
