import * as API from "middleware/api";

export const recoveryUserSendValidatonCode = (documentType, documentNumber, email, secondFactor, recaptchaResponse) =>
    API.executeAnonymous("/session.recoverUser.step1", {
        documentType,
        documentNumber,
        email,
        _secondFactor: secondFactor,
        _captcha: recaptchaResponse,
    });

export const recoveryUser = (code, newUser, newUserConfirmation) =>
    API.executeAnonymous("/session.recoverUser.step2", {
        _code: code,
        _newUser: newUser,
        _newUserConfirmation: newUserConfirmation,
    });
