import React, { Component } from "react";
import { string, shape, func } from "prop-types";
import I18n from "pages/_components/I18n";
import EditableLabel from "pages/_components/fields/EditableLabel";
import { resizableRoute } from "pages/_components/Resizable";
import { actions as productsActions } from "reducers/products";

class Information extends Component {
    static propTypes = {
        deposit: shape({
            operationCode: string.isRequired,
            numberPrint: string.isRequired,
            descriptionSIOC: string.isRequired,
            holder: string.isRequired,
        }).isRequired,
        idForm: string.isRequired,
        dispatch: func.isRequired,
    };

    saveAlias = (alias) => {
        const { deposit, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, deposit.idProduct));
    };

    render() {
        const { deposit, idForm } = this.props;
        const { numberPrint, holder, operationCode, descriptionSIOC, productAlias } = deposit;

        return (
            <>
                <div className="detail-head-info-main in-column">
                    <div className="data-wrapper">
                        <I18n id={`${idForm}.detail.alias`} componentProps={{ className: "data-label" }} />
                        <EditableLabel
                            onSave={this.saveAlias}
                            value={productAlias || operationCode}
                            editWithModal
                            modalMainLabel="fixedTermDeposit.alias.modal.title"
                            modalButtonLabel="fixedTermDeposit.alias.modal.confirm.button"
                            isTitle={false}
                        />
                    </div>

                    {numberPrint !== 0 && (
                        <div className="data-wrapper">
                            <I18n id={`${idForm}.detail.numberPrint`} componentProps={{ className: "data-label" }} />
                            <span>{numberPrint}</span>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <I18n id={`${idForm}.detail.holder`} componentProps={{ className: "data-label" }} />
                        <span>{holder}</span>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${idForm}.detail.operationNumber`} componentProps={{ className: "data-label" }} />
                        <span>{operationCode}</span>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${idForm}.detail.descriptionSIOC`} componentProps={{ className: "data-label" }} />
                        <span>{descriptionSIOC}</span>
                    </div>
                </div>
            </>
        );
    }
}

export default resizableRoute(Information);
