import axios from "axios";

const getAxiosObject = (url) => {
    const axiosObject = axios.create();
    axiosObject.defaults.baseURL = url;
    axiosObject.defaults.headers.Accept = "application/json, application/octet-stream";

    if (window.cordova) {
        axiosObject.defaults.headers["x-app-version"] = window.cordova ? window.APP_VERSION: '';
    }

    return axiosObject;
};
export default getAxiosObject;
