import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { bool } from "prop-types";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "recoveryPassword.step3";

class Step3Content extends Component {
    getPasswordHelp = () => (
        <>
            <div className="pre-form-info">
                <div className="pre-form-info__text ui-mb-6">
                    <I18n id="recoveryPassword.step3.header" />

                    <I18n id="recoveryPassword.step3.description" component="p" />
                </div>
            </div>
            <div className="password-help-text">
                <I18n id="recoveryPassword.step3.requirements.rules.title" component="p" />
                <ul>
                    <I18n id="recoveryPassword.step3.requirements.rule1" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule2" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule3" component="li" />
                    <I18n id="recoveryPassword.step3.requirements.rule4" component="li" />
                </ul>
            </div>
        </>
    );

    render() {
        const { isSubmitting } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <Form className="form-content container">
                {this.getPasswordHelp()}

                <Field name="password" idForm={FORM_ID} component={Credential} maxLength={maxLength} hidePlaceholder />
                <Field
                    name="passwordConfirmation"
                    idForm={FORM_ID}
                    component={Credential}
                    showStrength
                    maxLength={maxLength}
                    hidePlaceholder
                />
                <Button block label="global.restore" loading={isSubmitting} type="submit" bsStyle="primary" />
            </Form>
        );
    }
}

Step3Content.propTypes = {
    isSubmitting: bool,
};

Step3Content.defaultProps = {
    isSubmitting: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ password: "", passwordConfirmation: "" }),
    validationSchema: () =>
        Yup.object().shape({
            password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
            passwordConfirmation: Yup.string().required(i18n.get(`${FORM_ID}.passwordConfirmation.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);
        formikBag.props.dispatch(
            recoveryPasswordActions.recoveryPassStep3(
                values.password,
                values.passwordConfirmation,
                formikBag.props.exchangeToken,
                formikBag.props.resetCode,
                formikBag,
            ),
        );
    },
})(Step3Content);
