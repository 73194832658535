import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form } from "formik";

import { actions as formActions, selectors as formSelectors } from "reducers/form";

import Button from "pages/_components/Button";

import { bool, shape, func, string } from "prop-types";

import SimpleModal from "components/SimpleModal/SimpleModal";

class TransactionTicketCancelConfirmation extends Component {
    state = {
        displayModal: false,
    };

    handleSubmit = () => {
        this.setState({ displayModal: true });
    };

    hideModal = () => {
        this.setState({ displayModal: false });
    };

    renderModal() {
        const { dispatch, transaction, submitForm } = this.props;

        const { displayModal } = this.state;

        const ACTIVITY_NAME = transaction?.activityName;

        return (
            <SimpleModal
                dispatch={dispatch}
                isDisplayed={displayModal}
                closeModal={this.hideModal}
                modalIcon="images/warningIcon.svg"
                modalMainText="forms.cancelTransaction.modalMessage"
                modalMainTextParams={{ ACTIVITY_NAME }}
                buttonAction={submitForm}
            />
        );
    }

    render() {
        const { isSubmitting, isLoading } = this.props;

        const { displayModal } = this.state;

        if (!isLoading) {
            return (
                <>
                    {displayModal && this.renderModal()}
                    <Form className="token-form">
                        <Button
                            type="button"
                            label="form.credential.cancel.button.label"
                            bsStyle="primary"
                            block
                            loading={isSubmitting}
                            onClick={this.handleSubmit}
                        />
                    </Form>
                </>
            );
        }
        return null;
    }
}

TransactionTicketCancelConfirmation.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isLoading: bool.isRequired,
    transaction: shape({
        activityName: string,
    }).isRequired,
    submitForm: func.isRequired,
};

const mapStateToProps = (state) => ({
    idForm: formSelectors.getId(state),
    isLoading: formSelectors.getFetching(state),
    transaction: formSelectors.getTransaction(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (credentials, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(formActions.cancelTransaction({ credentials, formikBag }));
        },
    }),
)(TransactionTicketCancelConfirmation);
