import { takeLatest, call, put } from "redux-saga/effects";

import { push } from "react-router-redux";

import { types } from "reducers/recoverUser";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

import * as recovery from "middleware/recoverUser";

const sagas = [
    takeLatest(types.RECOVERY_USER_CODE_REQUEST, handleRecoverUserSendValidationCode),
    takeLatest(types.RECOVERY_USER_REQUEST, handleRecoverUser),
];

export default sagas;

function* handleRecoverUserSendValidationCode({
    documentType,
    documentNumber,
    email,
    secondFactor,
    recaptchaResponse,
    formikBag,
}) {
    const response = yield call(
        recovery.recoveryUserSendValidatonCode,
        documentType,
        documentNumber,
        email,
        secondFactor,
        recaptchaResponse,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        if (response.data.code === "API021W" || response.data.code === "COR050W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoverUser"]));
            yield put({ type: types.RECOVERY_USER_CODE_REQUEST_FAILURE_REQUIRE_CAPTCHA });
        } else if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoverUser"]));
        }
    } else {
        yield put(push("/recoverUser/step2"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoverUser({ code, newUser, newUserRepeat, formikBag }) {
    const response = yield call(recovery.recoveryUser, code, newUser, newUserRepeat);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoverUser"]));
        }
    } else {
        yield put(notificationActions.showNotification(i18n.get("recoverUser.step2.success"), "success", ["login"]));
        yield put(push("/loginStep1"));
    }

    formikBag.setSubmitting(false);
}
