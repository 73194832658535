const handleBackInDetail = (items, dispatch, path, history) => {
    if (items > 1) {
        return () => {
            history.goBack();
        };
    }
    return null;
};

export default handleBackInDetail;
