import React, { Component } from "react";
import I18n from "pages/_components/I18n";

class Loader extends Component {
    render() {
        return (
            <div className="preloader" aria-live="polite">
                <div className="loader">
                    <div />
                    <div />
                    <I18n id="global.loading" componentProps={{ className: "visually-hidden" }} />
                </div>
            </div>
        );
    }
}

export default Loader;
