import React, { Component } from "react";
import { string, number, bool, oneOfType, shape } from "prop-types";

import ListItemDesktop from "pages/_components/listItem/ListItemDesktop";
import ListItemMobile from "pages/_components/listItem/ListItemMobile";
import { resizableRoute } from "pages/_components/Resizable";

class ListItem extends Component {
    static propTypes = {
        path: oneOfType([
            string,
            shape({
                pathname: string,
                search: string,
            }),
        ]).isRequired,
        name: string.isRequired,
        title: string.isRequired,
        reference: string.isRequired,
        currency: string.isRequired,
        amount: number.isRequired,
        amountLabel: string,
        icon: string,
        expirationDate: string,
        expiredText: string,
        expirationText: string,
        isExpired: bool,
        productType: string.isRequired,
        overdraftLine: number,
        isDepositsWidget: bool,
    };

    static defaultProps = {
        icon: "",
        amountLabel: "",
        expirationDate: "",
        expiredText: "",
        expirationText: "",
        isExpired: false,
        overdraftLine: 0,
        isDepositsWidget: false,
    };

    render() {
        const { productType, overdraftLine } = this.props;
        const overdraftEnabled = productType === "CC" && overdraftLine > 0;
        const { isDesktop, ...props } = { ...this.props, overdraftEnabled };

        if (isDesktop) {
            return (
                <>
                    <ListItemDesktop {...props} />
                    <div className="d-flex-justify-content-center">
                        <div className="line-product" />
                    </div>
                </>
            );
        }

        return (
            <>
                <ListItemMobile {...props} />
            </>
        );
    }
}

export default resizableRoute(ListItem);
