import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { arrayOf, element, shape, func, bool, string } from "prop-types";

class WithPermissions extends Component {
    static propTypes = {
        children: element.isRequired,
        activeEnvironment: shape({}).isRequired,
        redirectFunc: func,
        comparator: func,
        somePermissions: bool.isRequired,
        permissions: arrayOf(string),
    };

    static defaultProps = {
        redirectFunc: null,
        comparator: null,
        permissions: null,
    };

    render() {
        const { permissions, activeEnvironment, children, redirectFunc, comparator, somePermissions } = this.props;
        let hasPermissions = false;
        if (comparator) {
            hasPermissions = comparator(activeEnvironment.permissions);
        } else {
            const everyOrSomePermissions = () => {
                if (!somePermissions) {
                    return permissions.every((item) => activeEnvironment.permissions[item]);
                }
                return permissions.some((item) => activeEnvironment.permissions[item]);
            };
            hasPermissions = permissions ? everyOrSomePermissions() : true;
        }

        if (!hasPermissions && redirectFunc) {
            redirectFunc();
        }

        return hasPermissions ? children : null;
    }
}

function mapStateToProps(state) {
    return {
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    };
}

export default connect(mapStateToProps)(WithPermissions);

export function withPermissions(WrappedComponent) {
    // eslint-disable-next-line react/no-multi-comp
    class WithPermissionsHoc extends Component {
        static propTypes = {
            activeEnvironment: shape({}).isRequired,
            permissions: arrayOf(string).isRequired,
        };

        render() {
            const { permissions, activeEnvironment, ...props } = this.props;
            return (
                <WithPermissions permissions={permissions || null} activeEnvironment={activeEnvironment}>
                    <WrappedComponent {...props} />{" "}
                </WithPermissions>
            );
        }
    }

    return connect(mapStateToProps)(WithPermissionsHoc);
}
