import React, { Component } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { bool, number } from "prop-types";
import classNames from "classnames";

import { history } from "store";

import { MAX_FAILED_TIMES } from "constants.js";
import MainContainer from "pages/_components/MainContainer";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";
import Routes from "routes/Routes";

/* eslint-disable react/jsx-filename-extension */
class App extends Component {
    static propTypes = {
        loading: bool,
        timesConfigFailed: number,
        timesI18nFailed: number,
    };

    static defaultProps = {
        loading: false,
        timesConfigFailed: 0,
        timesI18nFailed: 0,
    };

    render() {
        const { loading, timesConfigFailed, timesI18nFailed } = this.props;

        const serverError = timesConfigFailed >= MAX_FAILED_TIMES || timesI18nFailed >= MAX_FAILED_TIMES;

        if (loading && !serverError) {
            return (
                <div className="view-page">
                    <div className="view-content">
                        <MainContainer showLoader className={classNames("main-container")} />
                    </div>
                </div>
            );
        }

        return (
            <ConnectedRouter history={history}>
                <Routes />
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = ({ isLoading, ...state }) => ({
    loading: isLoading || !i18nSelectors.getMessages(state) || !configSelectors.getConfig(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
});

export default connect(mapStateToProps)(App);
