import * as API from "middleware/api";

export const loadListRequest = (pageNumber) => API.executeWithAccessToken("/fixedTermDeposits.list", { pageNumber });

export const readDepositDetail = (idDeposit, operationOrder) =>
    API.executeWithAccessToken("/fixedTermDeposit.detail", {
        idDeposit,
        operationOrder,
    });

export const simulateDeposit = (formValues) =>
    API.executeWithAccessToken("/fixedTermDeposit.simulate", { ...formValues });

export const loadShareholderAccounts = (documentNumber) =>
    API.executeWithAccessToken("/shareholderAccount.list", {
        documentNumber,
    });

export const loadMutualFundsByAccount = (accountNumber, date) =>
    API.executeWithAccessToken("/mutualFund.list", {
        accountNumber,
        date,
    });

export const loadFundOffers = () => API.executeWithAccessToken("/mutualFundOffering.list");

export const loadAvailableFunds = (accountNumber) =>
    API.executeWithAccessToken("/availableFund.list", {
        accountNumber,
    });

export const loadOperatingFunds = (accountNumber, date, fundType, riskType) =>
    API.executeWithAccessToken("/operatingFund.list", {
        accountNumber,
        date,
        fundType,
        riskType,
    });

export const loadFundMovements = (accountNumber, fundNumber, fundClass, dateFrom, dateTo, pageNumber) =>
    API.executeWithAccessToken("/mutualFund.movements", {
        accountNumber,
        fundNumber,
        fundClass,
        dateFrom,
        dateTo,
        pageNumber,
    });
export const cancelRenewalPreviewRequest = (idProduct, operationOrder, operationCode) =>
    API.executeWithAccessToken("/fixedTermDeposits.cancelRenewal.preview", {
        idProduct,
        operationOrder,
        operationCode,
    });
export const downloadFundMovements = (
    accountNumber,
    accountName,
    fundNumber,
    fundName,
    fundClass,
    dateFrom,
    dateTo,
    pageNumber,
    format,
) =>
    API.executeWithAccessToken("/mutualFund.movements.export", {
        accountNumber,
        accountName,
        fundNumber,
        fundName,
        fundClass,
        dateFrom,
        dateTo,
        pageNumber,
        format,
    });

export const validateAccessRestriction = (actionType, mutualFund) =>
    API.executeWithAccessToken("/mutualFund.timeConstraint", { actionType, mutualFund });

export const getInvestorProfileBySegment = (segment) =>
    API.executeWithAccessToken("/investments.investerProfileForm.pre", {
        segment,
        getProfile: "true",
        getQuestions: "false",
    });

export const calculateInvestorProfile = (filteredAnswers, segment) =>
    API.executeWithAccessToken("/investments.investerProfileForm.preview", { filteredAnswers, segment });

export const downloadInvestorProfile = (segment, profile) =>
    API.executeWithAccessToken("/investments.investerProfileForm.download", { segment, profile });
