import React, { useEffect } from "react";

import { Field, withFormik } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import * as i18nUtils from "util/i18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import { selectors as accountsSelectors } from "reducers/accounts";
import I18n from "pages/_components/I18n";
import FormTransition from "pages/forms/_components/FormTransition";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

const ID_ACTIVITY = "echeqs.acceptTermsAndConditions.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");

const AcceptEcheqTermsConditionsForm = (props) => {
    const { mode, dispatch, location, transaction, currentLang, fromBackoffice, history, successEnrollment } = props;
    const idTransaction = transaction?.idTransaction;

    useEffect(() => {
        if (mode === "edit" && successEnrollment) {
            history.goBack();
        }

        if (mode === "edit") {
            dispatch(
                formActions.previewForm({
                    formikBag: { props: { metadata: {} } },
                    idActivity: ID_ACTIVITY,
                    idTransaction,
                    values: { termsCondition: "0" },
                }),
            );
        }
    }, [dispatch, location, transaction, mode, history, successEnrollment]);

    const updateParamsSubmit = (termsCondition) => {
        dispatch(
            formActions.addExtraParams({
                termsCondition,
            }),
        );
    };

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        ...genericProps,
        mode: "edit",
    };

    const renderFields = () => (
        <>
            {mode === "view" && (
                <>
                    <>
                        <I18n id={`${ID_FORM}.terms1`} component="p" componentProps={{ className: "ui-mt-0" }} />

                        <div className="termsAndConditions ui-mt-8">
                            <DisclaimerContainer>
                                <I18n
                                    id={`${ID_FORM}.disclaimerTitle.label`}
                                    componentProps={{ className: "fw-bold" }}
                                />
                                <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                            </DisclaimerContainer>
                        </div>
                    </>

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.CheckBoxSelect}
                        key="termsCondition"
                        name="termsCondition"
                        idField="termsCondition"
                        labelCheck={`${ID_FORM}.check`}
                        disabled
                        onChangeParent={(value) => {
                            updateParamsSubmit(value);
                        }}
                    />
                </>
            )}
        </>
    );

    const renderFieldsTermsAndConditions = () => (
        <>
            <>
                <I18n id={`${ID_FORM}.terms1`} component="p" componentProps={{ className: "ui-mt-0" }} />

                <div className="termsAndConditions ui-mt-8">
                    <DisclaimerContainer>
                        <I18n id={`${ID_FORM}.disclaimerTitle.label`} componentProps={{ className: "fw-bold" }} />
                        <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                    </DisclaimerContainer>
                </div>
            </>

            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`${ID_FORM}.check`),
                    },
                ]}
                key="termsCondition"
                name="termsCondition"
                idField="termsCondition"
                renderAs="check"
                additionalClassName="ui-mt-7"
            />
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: `${ID_FORM}.${mode === "view" ? "view.title" : "formName"}`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        renderFieldsTermsAndConditions,
        noPre: true,
        returnBack: true,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle: "",
        subtitleClean: true,
        handleClickDownload,
        exportList: "pdf",
        confirmLabelEmpty: true,
        buttonLabel: "global.confirm",
        ignoreEditStep: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    account: accountsSelectors.getSelectedAccount(state),
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    previewData: formSelectors.getPreviewData(state),
    successEnrollment: echeqsSelectors.getSuccessEnrollment(state),
    fetching: formSelectors.getFetching(state),
});
AcceptEcheqTermsConditionsForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    history: shape({ goBack: func }),
};

AcceptEcheqTermsConditionsForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    history: null,
    successEnrollment: false,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(AcceptEcheqTermsConditionsForm);
