import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import * as dateUtils from "util/date";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormTransition from "../_components/FormTransition";
import ChangeStatusTransactionComponent from "./ChangeStatusTransactionComponent";
import CustomScheduler from "../_components/_scheduler/CustomScheduler";

const ID_FORM = "transfers.internal";
const ID_ACTIVITY = "transfers.internal.send";
const ID_ACTIVITY_PRE = "transfers.internal.pre";
const TransferInternalForm = (props) => {
    const { mode, dispatch, location, data, currentLang, fromBackoffice, transaction, isDesktop } = props;
    const [showMailConfirm, setShowMailConfirm] = useState(false);
    const [amountMax, setAmountMax] = useState(null);
    const [currencySelected, setCurrency] = useState("");
    const [errorsAux, setErrorsAux] = useState({});
    const [amountData, setAmountData] = useState({
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: "",
                label: "",
            },
        ],
    });

    useEffect(() => {
        if (mode === "edit") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            setAmountData({
                options: [
                    {
                        id: "",
                        label: "",
                    },
                ],
            });
        }
        setShowMailConfirm(data.emailConfirm ? data.emailConfirm : false);
    }, [dispatch, location, transaction]);

    const idTransaction = transaction?.idTransaction;
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const onTemplateLoad = (templateData) => {
        if (Object.keys(templateData).length > 0 && mode === "edit") {
            setCurrency(templateData.amount.currency);
            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: templateData.amount.currency,
                        label: i18nUtils.get(`currency.label.${templateData.amount.currency}`),
                    },
                ],
            });
            setShowMailConfirm(templateData?.emailConfirm || false);
        }
    };

    const renderFields = (setFieldValue) => {
        const { values } = props;
        const preData = values.preData || {
            currencyList: [],
            debitAccountList: [],
            creditAccountPesosList: [],
            creditAccountDolarList: [],
        };
        const selectorOptions = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };

        const optionsDestinationPesos = {
            options: preData.creditAccountPesosList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };
        const optionsDestinationDolar = {
            options: preData.creditAccountDolarList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };
        const valueCurrency = (type, e) => {
            let filteredOptions;
            if (type === "debit") {
                filteredOptions = preData.debitAccountList?.find((acc) => acc.idProduct === e) || data;
                const currency = filteredOptions.currency || data.amount.currency;
                setAmountData({
                    decimalSeparator: ",",
                    precision: 2,
                    thousandsSeparator: ".",
                    options: [
                        {
                            id: currency,
                            label: i18nUtils.get(`currency.label.${currency}`),
                        },
                    ],
                });
                setCurrency(currency);
                setFieldValue("amount", {
                    currency,
                    quantity: data.amount?.quantity ? data.amount?.quantity : "",
                });
                setAmountMax(filteredOptions?.balance);
                setFieldValue("labelDebit", filteredOptions?.label);
            } else if (type === "creditPesos") {
                filteredOptions = preData.creditAccountPesosList?.find((acc) => acc.idProduct === e) || data;
                setFieldValue("labelCredit", filteredOptions?.label);
            } else {
                filteredOptions = preData.creditAccountDolarList?.find((acc) => acc.idProduct === e) || data;
                setFieldValue("labelCredit", filteredOptions?.label);
            }
        };
        const concepts = config.get("transfers.concepts").split("|");
        const listConcepts = concepts.map((value) => ({
            label: i18nUtils.get(`forms.transfers.concepts.${value}.label`),
            id: value,
        }));
        const valueInputAmount = (e) => {
            if (amountMax || amountMax === 0) {
                if (e > amountMax && e > 0) {
                    if (dateUtils.isOutOfTime(config.get("transfers.timeLimit"), dateUtils.TIME_ZONE_BANK)) {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.error") });
                    } else {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.message") });
                    }
                } else {
                    setErrorsAux({ amount: null });
                }
            }
        };
        const changeConfirmMail = () => {
            setFieldValue("emailConfirm", !showMailConfirm);
            setShowMailConfirm(!showMailConfirm);
            if (showMailConfirm) {
                setFieldValue("email", null);
                setFieldValue("message", null);
            }
        };

        return (
            <React.Fragment>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={selectorOptions}
                    key="debitAccount"
                    name="debitAccount"
                    idField="debitAccount"
                    handleChange={(e) => valueCurrency("debit", e)}
                    value={data.debitAccount || ""}
                    labelBackoffice={data.labelDebit}
                />
                {currencySelected === "ARS" ? (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        data={optionsDestinationPesos}
                        key="creditAccount"
                        name="creditAccount"
                        idField="creditAccount"
                        value={data.creditAccount || ""}
                        handleChange={(e) => valueCurrency("creditPesos", e)}
                        labelBackoffice={data.labelCredit}
                    />
                ) : (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        data={optionsDestinationDolar}
                        key="creditAccount"
                        name="creditAccount"
                        idField="creditAccount"
                        disabled={currencySelected === ""}
                        value={data.creditAccount || ""}
                        handleChange={(e) => valueCurrency("creditDol", e)}
                        labelBackoffice={data.labelCredit}
                    />
                )}
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={amountData}
                    key="amount"
                    name="amount"
                    idField="amount"
                    value={data.amount || ""}
                    handleChange={(e) => valueInputAmount(e)}
                    amountNull
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={listConcepts}
                    key="concept"
                    name="concept"
                    idField="concept"
                    renderAs="combo"
                    value={data.concept ? data.concept[0] : ["VAR"]}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="creditReference"
                    name="creditReference"
                    idField="creditReference"
                    maxLength={30}
                />

                <CustomScheduler
                    {...genericProps}
                    isDesktop={isDesktop}
                    scheduler={data.scheduler}
                    submitDate={transaction?.submitDateTime}
                />

                {mode === "edit" ? (
                    <div className="form-group form-group--message">
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={[
                                {
                                    id: "1",
                                    label: i18nUtils.get(`forms.${ID_FORM}.emailConfirmation.label`),
                                },
                            ]}
                            key="mailConfirm"
                            name="mailConfirm"
                            idField="mailConfirm"
                            renderAs="check"
                            values={data.mailConfirmation || []}
                            onChange={changeConfirmMail}
                        />

                        {showMailConfirm && (
                            <>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="email"
                                    name="email"
                                    idField="email"
                                />
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="notificationBody"
                                    name="message"
                                    idField="message"
                                    maxLength={500}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        {showMailConfirm && (
                            <>
                                {mode !== "edit" && <hr />}
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="email"
                                    name="email"
                                    idField="email"
                                />
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="notificationBody"
                                    name="message"
                                    idField="message"
                                    maxLength={500}
                                />
                            </>
                        )}
                    </>
                )}

                {mode === "view" && (
                    <ChangeStatusTransactionComponent transaction={transaction} genericProps={genericProps} />
                )}
            </React.Fragment>
        );
    };

    const renderFieldsTermsAndConditions = () => (
        <>
            <hr className="ui-mb-6" />
            <DisclaimerContainer textSmall>
                <div className="termsAndConditions text-justify">
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimer"
                        name="disclaimer"
                        idField="disclaimer"
                        link="https://www.bcra.gob.ar"
                        nameLink="www.bcra.gob.ar."
                    />
                </div>
            </DisclaimerContainer>
        </>
    );

    const validateFieldDraft = (values) => {
        const draftErrors = {};
        const { debitAccount } = values;
        if (!debitAccount || debitAccount.length === 0) {
            draftErrors.debitAccount = i18nUtils.get("program.date.blank");
        }
        return draftErrors;
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const { ...restPreData } = props.preData || {};

    const formProps = {
        title: "forms.transfers.internal.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            idActivity: ID_ACTIVITY,
            onTemplateLoad,
        },
        renderFields,
        preData: restPreData,
        renderFieldsTermsAndConditions,
        isCustom: true,
        returnBack: false,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle: "forms.transfers.internal.subtitle",
        confirmLabelEmpty: true,
        validateFieldDraft,
        modalButtonLabel: "forms.saveTemplate.link",
        exportList: "pdf",
        handleClickDownload,
        buttonLabel: "global.continue",
    };
    return <FormTransition {...props} {...formProps} errorsAux={errorsAux} />;
};

TransferInternalForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    formErrors: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    filterOptions: shape({}).isRequired,
    values: shape({}).isRequired,
    accountSelected: string,
};

TransferInternalForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    accountSelected: null,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    formErrors: formSelectors.getErrors(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(TransferInternalForm);
