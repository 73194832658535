import * as i18nUtils from "util/i18n";

export const fingerprintErrors = {
    BAD_PADDING_EXCEPTION: "BAD_PADDING_EXCEPTION",
    CERTIFICATE_EXCEPTION: "CERTIFICATE_EXCEPTION",
    FINGERPRINT_CANCELLED: "FINGERPRINT_CANCELLED",
    FINGERPRINT_DATA_NOT_DELETED: "FINGERPRINT_DATA_NOT_DELETED",
    FINGERPRINT_ERROR: "FINGERPRINT_ERROR",
    FINGERPRINT_NOT_AVAILABLE: "FINGERPRINT_NOT_AVAILABLE",
    FINGERPRINT_PERMISSION_DENIED: "FINGERPRINT_PERMISSION_DENIED",
    FINGERPRINT_PERMISSION_DENIED_SHOW_REQUEST: "BAD_PADDING_EXCEPTION",
    ILLEGAL_BLOCK_SIZE_EXCEPTION: "ILLEGAL_BLOCK_SIZE_EXCEPTION",
    INIT_CIPHER_FAILED: "INIT_CIPHER_FAILED",
    INVALID_ALGORITHM_PARAMETER_EXCEPTION: "INVALID_ALGORITHM_PARAMETER_EXCEPTION",
    IO_EXCEPTION: "IO_EXCEPTION",
    JSON_EXCEPTION: "JSON_EXCEPTION",
    MINIMUM_SDK: "MINIMUM_SDK",
    MISSING_ACTION_PARAMETERS: "MISSING_ACTION_PARAMETERS",
    MISSING_PARAMETERS: "MISSING_PARAMETERS",
    NO_SUCH_ALGORITHM_EXCEPTION: "NO_SUCH_ALGORITHM_EXCEPTION",
    SECURITY_EXCEPTION: "SECURITY_EXCEPTION",
};

export const getError = (error) => fingerprintErrors[error];

export const isAvailable = () => window.security.fingerprint.isAvailable();

export const verify = () =>
    window.security.fingerprint.verify({
        locale: i18nUtils.getLang() + (i18nUtils.getLang() === "en" ? "_US" : ""),
        title: i18nUtils.get("settings.fingerprintConfiguration.dialog.title"),
        message: i18nUtils.get("settings.fingerprintConfiguration.dialog.message"),
    });

export const enrollOnDevice = () => window.security.fingerprint.enrollOnDevice();
