import classNames from "classnames";
import { any, arrayOf, bool, func, node, number, objectOf, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { routerActions } from "react-router-redux/actions";

import { selectors as communicationsSelectos } from "reducers/communications";
import { selectors as formSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";

import Badge from "pages/_components/Badge";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import ViewTitle from "pages/_components/header/components/ViewTitle";
import Header from "pages/_components/header/Header";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Menu from "pages/_components/menu/Menu";
import { resizableRoute } from "pages/_components/Resizable";
import { Helmet } from "react-helmet";
import * as utilsI18n from "util/i18n";

import { selectors as assistantSelectors } from "reducers/assistant";

class Head extends Component {
    static propTypes = {
        // i18n head title
        title: node,
        // specify a translated text instead a i18n key
        titleText: string,
        // back link url
        backLinkTo: string,
        // back function, ignored if backLinkTo is specified
        onBack: func,
        // close link url
        closeLinkTo: string,
        // onClose function link, ignored if closeLinkTo is specified
        onClose: func,
        // link url, it links located under title
        linkTo: string,
        // I18n id of link text, it is located under title
        linkText: string,
        // head logo
        logo: node,
        // called right after button with closeLinkTo is clicked
        onCloseClick: func,
        hasCenterContent: bool,
        showPlusIcon: bool,
        accessibilityTextId: string,
        dispatch: func.isRequired,
        hasActiveSession: bool,
        isMobile: bool,
        isDesktop: bool,
        unreadCommunications: number,
        onAdd: func,
        addLinkTo: string,
        addLinkToLabel: string,
        exportList: string,
        handleInviteClick: func,
        environments: shape({}),
        activeEnvironment: shape({
            permissions: objectOf(bool),
        }),
        note: string,
        handleClick: func,
        isFetchingExport: bool,
        children: node,
        replace: string,
        hideNavbarInMobile: bool,
        updateFocus: bool, // Prop which defines if component will re render if updated or not
        propsClassNames: string,
        viewHeader: bool,
        arrowBackClass: string,
        addHeaderClassName: string,
        additionalClassName: string,
        showLogo: bool,
        mobileMenuOptions: arrayOf(any),
        arrowWhite: bool,
        showPlusSecondary: bool,
        digitalSignature: func,
        tooltipProps: shape({}),
        customButtonIcon: string,
        customButtonOnClick: func,
        customButtonStyle: string,
        mode: string.isRequired,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
        subtitle: string,
        customDownloadButton: bool,
        DropdownCustom: string,
    };

    static defaultProps = {
        title: null,
        titleText: "",
        backLinkTo: "",
        onBack: null,
        closeLinkTo: "",
        onClose: null,
        linkTo: "",
        linkText: "",
        logo: null,
        onCloseClick: () => {},
        hasCenterContent: false,
        showPlusIcon: false,
        accessibilityTextId: "",
        hasActiveSession: false,
        isMobile: false,
        isDesktop: false,
        unreadCommunications: 0,
        addLinkTo: "",
        addLinkToLabel: "",
        exportList: "",
        environments: {},
        activeEnvironment: null,
        note: "",
        isFetchingExport: false,
        replace: "",
        hideNavbarInMobile: false,
        updateFocus: true,
        propsClassNames: "",
        viewHeader: true,
        arrowBackClass: "",
        addHeaderClassName: "",
        additionalClassName: "",
        showLogo: false,
        mobileMenuOptions: null,
        arrowWhite: false,
        showPlusSecondary: false,
        digitalSignature: null,
        tooltipProps: {},
        customButtonIcon: null,
        customButtonOnClick: null,
        customButtonStyle: "outline",
        subtitle: null,
        customDownloadButton: false,
        DropdownCustom: "",
        onAdd: null,
        handleInviteClick: null,
        handleClick: null,
        children: null,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    componentDidUpdate() {
        const { updateFocus } = this.props;
        if (this.titleRef.current && updateFocus) {
            this.titleRef.current.focus({
                preventScroll: true,
            });
        }
    }

    back = () => {
        const { dispatch, backLinkTo } = this.props;
        dispatch(routerActions.replace({ pathname: backLinkTo, state: { transition: "transition-drill-out" } }));
    };

    getLeftOption = () => {
        const {
            backLinkTo,
            onBack,
            closeLinkTo,
            onClose,
            hasActiveSession,
            isMobile,
            hideNavbarInMobile,
            arrowBackClass,
            arrowWhite,
        } = this.props;
        if (backLinkTo || onBack) {
            return (
                <div className={`toolbar-item toolbar-item--fixed toolbar-item-left ${arrowBackClass}`}>
                    <Button
                        className="toolbar-btn view-back icon-view-back"
                        onClick={backLinkTo ? this.back : onBack}
                        image={`images/back${arrowWhite ? "White" : ""}.svg`}
                        label="global.back"
                        bsStyle="link"
                    />
                </div>
            );
        }

        if (hasActiveSession && isMobile && !hideNavbarInMobile && !closeLinkTo && !onClose) {
            return (
                <>
                    <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                        <Navbar.Toggle />
                    </div>
                    <Navbar.Collapse className="navbar-collapse-blue">
                        <Menu isMobile={isMobile} />
                    </Navbar.Collapse>
                </>
            );
        }

        return <div />;
    };

    close = () => {
        const { onCloseClick, dispatch, closeLinkTo } = this.props;
        onCloseClick();
        dispatch(routerActions.replace({ pathname: closeLinkTo, state: { transition: "transition-flow-close" } }));
    };

    add = () => {
        const { dispatch, onAdd, addLinkTo, isDesktop } = this.props;

        if (onAdd) {
            onAdd();
        }
        let routerAction = {
            pathname: addLinkTo,
            state: { transition: "transition-flow-open" },
        };

        if (isDesktop) {
            // con la transition no está funcionando en Desktop, se quita y se sigue el issue para encontrar el motivo
            routerAction = {
                pathname: addLinkTo,
            };
        }

        dispatch(routerActions.push(routerAction));
    };

    getRightOption = () => {
        const {
            addLinkTo,
            addLinkToLabel,
            closeLinkTo,
            exportList,
            handleInviteClick,
            hasActiveSession,
            isDesktop,
            isMobile,
            onAdd,
            onClose,
            showPlusIcon,
            note,
            unreadCommunications,
            mobileMenuOptions,
            showPlusSecondary,
            digitalSignature,
            tooltipProps,
            customButtonIcon,
            customButtonOnClick,
            customDownloadButton,
            customButtonStyle,
            DropdownCustom,
        } = this.props;

        let buttons = [];

        const noteElement = (
            <div key={note} className="toolbar-item view-note">
                <span>{note}</span>
            </div>
        );

        const closeElement = (
            <Button
                className="btn-small"
                onClick={closeLinkTo ? this.close : onClose}
                image="images/ui-icons/ui-cross-light.svg"
                bsStyle="outline"
                key="head.close-btn"
            />
        );

        if (exportList) {
            const { handleClick, isFetchingExport, mode, location } = this.props;
            const isTicket =
                location?.pathname?.match("/transaction/") != null ||
                location?.pathname?.match("/userTransaction/") != null;
            const showDownloadButton = (isTicket && mode === "view") || !isTicket;
            buttons = [
                ...buttons,
                <div key="exportList">
                    {exportList === "pdf" ? (
                        showDownloadButton && (
                            <Button
                                onClick={() => handleClick("pdf")}
                                label="global.download"
                                className="btn-outline btn-small btn btn-default"
                                image="images/download.svg"
                                key="head.dropdown-btn.defaultDownload"
                                loading={isFetchingExport}
                            />
                        )
                    ) : (
                        <Dropdown
                            image="images/download.svg"
                            label="global.download"
                            buttonClass="btn btn-secondary-outline btn-small"
                            fetching={isFetchingExport}
                            pullRight>
                            <Button
                                onClick={() => handleClick("pdf")}
                                label="global.download.pdf"
                                className="dropdown__item-btn"
                                bsStyle="link"
                                key="head.dropdown-btn.pdfDownload"
                            />
                            <Button
                                onClick={() => handleClick("xls")}
                                label="global.download.xls"
                                className="dropdown__item-btn"
                                bsStyle="link"
                                key="head.dropdown-btn.xlsDownload"
                            />
                        </Dropdown>
                    )}
                </div>,
            ];
        }

        if (DropdownCustom === "pays.suppliers") {
            const { handleClick, isFetchingExport } = this.props;
            buttons = [
                ...buttons,
                <div key="exportList">
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        buttonClass="btn btn-secondary-outline btn-small"
                        fetching={isFetchingExport}
                        pullRight>
                        <Button
                            onClick={() => handleClick("txt")}
                            label="global.download.dat"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            key="head.dropdown-btn.datDownload"
                        />
                        <Button
                            onClick={() => handleClick("xls")}
                            label="global.download.xls"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            key="head.dropdown-btn.suppliersXlsDownload"
                        />
                    </Dropdown>
                </div>,
            ];
        } else if (DropdownCustom === "echeqs.list") {
            const { handleClick, isFetchingExport } = this.props;
            buttons = [
                ...buttons,
                <div key="exportList">
                    <Dropdown
                        image="images/download.svg"
                        label="global.download"
                        buttonClass="btn btn-secondary-outline btn-small"
                        fetching={isFetchingExport}
                        pullRight>
                        <Button
                            onClick={() => handleClick("pdf")}
                            label="global.download.pdf"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            key="head.dropdown-btn.pdfDownload"
                        />
                        <Button
                            onClick={() => handleClick("xls")}
                            label="global.download.xls"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            key="head.dropdown-btn.xlsDownload"
                        />
                        <Button
                            onClick={() => handleClick("csv")}
                            label="global.download.csv"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            key="head.dropdown-btn.csvDownload"
                        />
                    </Dropdown>
                </div>,
            ];
        }

        const addButton = (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right top-right-button" key="add">
                <div className="btn-wrapper">
                    {digitalSignature && (
                        <a
                            role="button"
                            className={classNames({
                                "btn btn-outline btn-small": isDesktop,
                                "toolbar-btn view-close": !isDesktop,
                            })}
                            onClick={() => digitalSignature()}
                            {...tooltipProps}>
                            <Image src="images/iconos/sign.svg" className="icon-plus-header" />
                            <I18n
                                id="window.name.digitalSignature"
                                componentProps={!isDesktop && { className: "visually-hidden" }}
                            />
                        </a>
                    )}
                    <Button
                        onClick={this.add}
                        bsStyle="secondary"
                        className={classNames({
                            "btn-small": isDesktop,
                            "toolbar-btn view-close": !isDesktop,
                        })}
                        image="images/plusWhite.svg"
                        label={addLinkToLabel}
                        key="head.add-btn"
                    />
                </div>
            </div>
        );

        const buttonPlusSecondary = (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    onClick={this.add}
                    image="images/plusWhite.svg"
                    label={addLinkToLabel}
                    bsStyle="secondary"
                    className="btn-small"
                    key="head.secondary-btn"
                />
            </div>
        );

        const customButton = (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right" key="add">
                <Button
                    onClick={customButtonOnClick}
                    image={customButtonIcon}
                    label={addLinkToLabel}
                    bsStyle={customButtonStyle}
                    className="btn-small"
                    key="head.custom-btn"
                />
            </div>
        );

        const download = (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right " key="add">
                <Button
                    className="btn-small btn-secondary-outline "
                    onClick={customButtonOnClick}
                    image="/images/download.svg"
                    label={addLinkToLabel}
                    bsStyle="outline"
                    key="head.custom-btn.download"
                />
            </div>
        );

        if (addLinkTo || onAdd) {
            if (showPlusIcon) {
                buttons = [addButton, ...buttons];
            }
            if (showPlusSecondary) {
                buttons = [buttonPlusSecondary, ...buttons];
            }
            if (customButtonIcon !== null) {
                buttons = [customButton, ...buttons];
            }
            if (customDownloadButton) {
                buttons = [download, ...buttons];
            }
        }

        if (closeLinkTo || onClose) {
            buttons = [...buttons, closeElement];
        }

        if (noteElement) {
            buttons = [...buttons, noteElement];
        }

        if (handleInviteClick) {
            buttons = [
                <div key="invite">
                    <Button
                        onClick={handleInviteClick}
                        className="btn btn-small btn-outline"
                        image="/images/plus.svg"
                        label="administration.users.list.addUser"
                        key="head.invite-btn"
                    />
                </div>,
                ...buttons,
            ];
        }

        if (isMobile && mobileMenuOptions) {
            return (
                <div className="toolbar-getRightOption">
                    <div className="toolbar-item toolbar-item--fixed toolbar-btn-right">
                        <Dropdown
                            image="images/ui-icons/menu/ui-menu-options.svg"
                            closeImage="images/ui-icons/menu/ui-menu-close.svg"
                            label="global.download"
                            buttonClass="btn-only-icon toolbar-btn"
                            pullRight>
                            {mobileMenuOptions}
                        </Dropdown>
                    </div>
                </div>
            );
        }

        if (hasActiveSession && isMobile && !closeLinkTo && !onClose && !mobileMenuOptions) {
            return (
                <div className="toolbar-item toolbar-item--fixed toolbar-item-right mailIcon">
                    <Link className="btn toolbar-btn" to="/communications">
                        <Image className="svg-icon svg-caret" src="images/email.svg" />
                        <I18n id="menu.chat" />
                        {unreadCommunications > 0 && (
                            <>
                                <Badge count={unreadCommunications} />
                                <I18n id="menu.chat.badge" componentProps={{ className: "visually-hidden" }} />
                            </>
                        )}
                    </Link>
                </div>
            );
        }

        return buttons.length ? <div className="toolbar-getRightOption">{buttons}</div> : null;
    };

    getCenterContent = () => {
        const {
            children,
            logo,
            replace,
            title,
            titleText,
            hasCenterContent,
            accessibilityTextId,
            propsClassNames,
            showLogo,
            subtitle,
        } = this.props;
        return (
            (title || titleText || logo || hasCenterContent || accessibilityTextId || showLogo) && (
                <Fragment>
                    <Helmet>
                        <title>
                            {// eslint-disable-next-line no-nested-ternary
                            utilsI18n.get(accessibilityTextId || title) !== "*undefined*"
                                ? `Banco Mariva - ${`${utilsI18n.get(
                                      accessibilityTextId || title,
                                  )} ${replace.USER_NAME || ""}`}`
                                : titleText !== ""
                                ? `Banco Mariva - ${titleText}`
                                : "Banco Mariva"}
                        </title>
                    </Helmet>
                    {(title || titleText) && !showLogo && (
                        <>
                            <ViewTitle
                                id={title}
                                accessibilityTextId={accessibilityTextId}
                                replace={replace}
                                defaultValue={titleText}
                                classNames={propsClassNames}
                            />
                            <ViewTitle
                                id={subtitle}
                                accessibilityTextId={accessibilityTextId}
                                replace={replace}
                                defaultValue={titleText}
                                classNames={propsClassNames}
                            />
                        </>
                    )}

                    {showLogo && <Image src="images/logoMarivaHome.svg" />}
                    {children && children}
                    {this.getCenterOption()}
                </Fragment>
            )
        );
    };

    getCenterOption = () => {
        const { linkTo, linkText } = this.props;
        if (linkTo) {
            return (
                <p>
                    <Link to={linkTo}>
                        <I18n id={linkText} />
                    </Link>
                </p>
            );
        }

        return null;
    };

    render() {
        const { viewHeader, addHeaderClassName, additionalClassName } = this.props;
        return (
            <>
                {viewHeader && (
                    <Header addClassName={addHeaderClassName} additionalClassName={additionalClassName}>
                        {this.getLeftOption()}
                        <div className="toolbar-item view-title" ref={this.titleRef}>
                            {this.getCenterContent()}
                        </div>
                        {this.getRightOption()}
                    </Header>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: assistantSelectors.isAssistantLogin(state) ? false : sessionSelectors.isLoggedIn(state),
    unreadCommunications: communicationsSelectos.getUnreadCommunications(state),
    environments: sessionSelectors.getEnvironments(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    mode: formSelectors.getMode(state),
    location: state.router.location,
});

export default connect(mapStateToProps)(resizableRoute(Head));
