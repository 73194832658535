import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { func, arrayOf, objectOf, bool } from "prop-types";

import { actions, selectors } from "reducers/settings";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";

class NotificationsConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        communicationTypes: arrayOf(objectOf(bool)),
        fetching: bool.isRequired,
        isMobile: bool.isRequired,
        history: func.isRequired,
    };

    static defaultProps = {
        communicationTypes: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.notificationsConfigurationPre());
    }

    renderCommunicationTypes = () => {
        const { communicationTypes } = this.props;

        return communicationTypes.map((communicationType) => {
            const [key] = Object.entries(communicationType)[0];

            return (
                <li className="navigational-list-item" key={key}>
                    <Link to={`/settings/notificationsConfiguration/${key}`} className="d-flex-justify-content-between">
                        <span>
                            <I18n id={`communications.communicationsTypes.${key}`} />
                        </span>
                        <Image src="images/chevromRight.svg" className="svg-icon svg-caret" />
                    </Link>
                </li>
            );
        });
    };

    renderContent = () => {
        const { communicationTypes } = this.props;

        return (
            communicationTypes && (
                <>
                    <div className="title-left-header">
                        <I18n id="settings.notificationsConfiguration" component="h1" />
                    </div>
                    <div className="container-center">
                        <form className="form-content">
                            <div className="form-text-info ui-mb-8">
                                <I18n id="settings.notificationsConfiguration.title" component="p" />
                            </div>
                            <I18n id="settings.notificationsConfiguration.subtitle" component="h4" />
                            <ul className="navigational-list navigational-list--notifications">
                                {this.renderCommunicationTypes()}
                            </ul>
                        </form>
                    </div>
                </>
            )
        );
    };

    render() {
        const { fetching, history, isMobile } = this.props;

        return (
            <Fragment>
                {!fetching && (
                    <Head
                        onBack={history.goBack}
                        showLogo={isMobile}
                        arrowWhite={isMobile}
                        additionalClassName={isMobile ? "blue-main-header-mobile" : ""}
                    />
                )}
                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTypes: selectors.getCommunicationTypes(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(NotificationsConfiguration);
