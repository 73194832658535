import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import { connect } from "react-redux";
import "./_Statement.scss";

class Statement extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        statement: shape({}).isRequired,
        idAccount: string.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    handleClick = () => {
        const { dispatch, statement, idAccount } = this.props;
        const { idStatementLine, path, filename } = statement;

        dispatch(accountsActions.downloadStatement(idAccount, idStatementLine, path, filename));
    };

    render() {
        const { statement, fetching } = this.props;

        return (
            <div className="statement-item">
                <span>{`${i18nUtils.getArray("global.months")[statement.month - 1]}`}</span>
                <Button
                    bsStyle="outline"
                    image="images/download.svg"
                    label="global.download.pdf"
                    className="btn-small"
                    onClick={this.handleClick}
                    loading={fetching}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    idAccount: accountsSelectors.getIdAccountForStatement(state),
    fetching: accountsSelectors.getFetchingDownloadStatement(state),
});

export default connect(mapStateToProps)(Statement);
