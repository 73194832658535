import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { func, shape, string, objectOf, bool, oneOfType, arrayOf } from "prop-types";

import { actions, selectors } from "reducers/settings";
import * as i18n from "util/i18n";
import { getArray } from "util/config";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import SwitchField from "pages/_components/fields/SwitchField";

class NotificationsConfigurationTransports extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                communicationType: string.isRequired,
            }),
        }).isRequired,
        communicationTransportsConfigurations: objectOf(oneOfType([bool, string])),
        fetching: bool.isRequired,
        keys: arrayOf(string).isRequired,
        history: func.isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        communicationTransportsConfigurations: null,
    };

    componentDidMount() {
        const { dispatch, match } = this.props;

        dispatch(actions.communicationTypePre(match.params.communicationType));
    }

    toggleSwitch = ({ target }, id, transportConfiguration) => {
        const { dispatch, match } = this.props;

        dispatch(
            actions.modifyNotificationConfigurations(
                match.params.communicationType,
                id,
                target.value,
                transportConfiguration,
            ),
        );
    };

    renderCommunicationTransportsConfigurations = (defaultTransports) => {
        const { communicationTransportsConfigurations, keys } = this.props;

        // HOTFIX (OLD)
        // const copiedKeys = [...Object.keys(communicationTransportsConfigurations)];
        // copiedKeys.sort();

        const channelsAllowed = ["default", "mail", "push"];

        const orderedKeys = [keys[0], keys[1], keys[4], keys[3], keys[2], keys[5], keys[6], keys[7]];

        return orderedKeys.map((transportConfiguration) => {
            const transportConfigurationName = transportConfiguration.substring("subscribed".length).toLowerCase();

            if (
                !(transportConfiguration === "idUser" || transportConfiguration === "communicationType") &&
                channelsAllowed.includes(transportConfigurationName)
            ) {
                return (
                    <SwitchField
                        name={transportConfigurationName}
                        key={transportConfigurationName}
                        label={i18n.get(
                            `userInfo.preferences.notificationsConfiguration.${transportConfigurationName}.label`,
                        )}
                        value={communicationTransportsConfigurations[transportConfiguration]}
                        onChange={(event) =>
                            this.toggleSwitch(event, transportConfigurationName, transportConfiguration)
                        }
                        disabled={defaultTransports.includes(transportConfigurationName.toUpperCase())}
                    />
                );
            }

            return null;
        });
    };

    renderContent() {
        const { communicationTransportsConfigurations, match } = this.props;

        if (communicationTransportsConfigurations) {
            const defaultTransports = getArray(
                `core.communications.communicationTypes.${match.params.communicationType}.default`,
            );

            const defaultTransportsUpper = defaultTransports.map((item) => item.toUpperCase());
            const communicationType = i18n.get(`communications.communicationsTypes.${match.params.communicationType}`);

            return (
                <>
                    <div className="title-left-header pb-0">
                        <I18n id="settings.notificationsConfiguration" component="h1" />
                        <h2>{communicationType}</h2>
                    </div>
                    <div className="container-center">
                        <form className="form-content">
                            <div className="form-group container">
                                <div className="form-group-control-list">
                                    {this.renderCommunicationTransportsConfigurations(defaultTransportsUpper)}
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            );
        }

        return null;
    }

    render() {
        const { fetching, history, isMobile } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="settings.notificationsConfiguration.modify" />
                <Head
                    backLinkTo="/settings/notificationsConfiguration"
                    onBack={history.goBack}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                    additionalClassName={isMobile ? "blue-main-header-mobile" : ""}
                />

                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTransportsConfigurations: selectors.getCommunicationTransportsConfigurations(state),
    fetching: selectors.isFetching(state),
    keys: selectors.getCommunicationTransportsConfigurationsKeys(state),
});

export default withRouter(connect(mapStateToProps)(NotificationsConfigurationTransports));
