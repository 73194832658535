import { bool, shape, func, string } from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field } from "formik";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as suppliersSelectors, actions as paymentToSuppliersActions } from "reducers/paymentToSuppliers";
import I18n from "pages/_components/I18n";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";
import SimpleModal from "components/SimpleModal/SimpleModal";
import * as config from "util/config";
import { actions as notificationActions } from "reducers/notification";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Button from "pages/_components/Button";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { push } from "react-router-redux/actions";
import Alert from "pages/_components/Alert/Alert";
import * as supplierUtils from "util/paymentSupplier";
import FormTransition from "../_components/FormTransition";
import NoDataContainer from "../../../components/NoDataContainer/NoDataContainer";
import UploadedFile from "../_components/UploadedFile/UploadedFile";

let ID_ACTIVITY = "pays.supplier.send";
const ID_ACTIVITY_PRE = "pays.supplier.pre";
const ID_FORM = "forms.payments.suppliers";

const PaymentToSuppliers = (props) => {
    const {
        dispatch,
        data,
        mode,
        fromBackoffice,
        preData,
        currentLang,
        isUploading,
        nameFileCharged,
        idFileCharged,
        transaction,
        linesWithErrors,
        fileToSave,
        totalLines,
        totalAmount,
        date,
        debitAccount,
        nameFileError,
        typeFileError,
        goToPreviewForm,
        fileType,
        location,
        fetching,
        activeEnvironment,
        availableFilesToUse,
        idTransactionTicket,
        goToTicket,
        isMobile,
        uploadType,
        previewData,
        fileUploadError,
        amountForCap,
    } = props;
    const { idTransaction } = transaction;
    const [showUploadingModal, setShowUploadingModal] = useState(isUploading);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [showFileUploadErrorModal, setShowFileUploadErrorModal] = useState(false);
    const [typeTransaction, setTypeTransaction] = useState();
    const [isInvalidFormat, setIsInvalidFormat] = useState(false);
    const [invalidFormatFileName, setInvalidFormatFileName] = useState();
    const [invalidFormatFileType, setInvalidFormatFileType] = useState();
    const [fileId, setFileId] = useState();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [invalidNomenclature, setInvalidNomenclature] = useState(false);
    const [invalidNomenclatureName, setInvalidNomenclatureName] = useState();
    const fileRef = useRef();
    const showSubmit = !!(typeTransaction && (fileId || linesWithErrors.length > 0 || isInvalidFormat));

    const getAvailableFiles = () => {
        if (preData?.availablePaymentFilesToUse) {
            dispatch(paymentToSuppliersActions.setAvailableFilesToUse(preData?.availablePaymentFilesToUse));
            return preData?.availablePaymentFilesToUse;
        }
        if (availableFilesToUse.length) {
            return availableFilesToUse;
        }
        return [];
    };

    const availableFiles = getAvailableFiles();

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        availablePaymentFilesToUse,
        ...restPreData
    } = preData || {};

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const uploadTypeOptions = [
        {
            id: "transmitter",
            label: i18nUtils.get(`${ID_FORM}.uploadType.transmitter.label`),
        },
        {
            id: "file",
            label: i18nUtils.get(`${ID_FORM}.uploadType.file.label`),
        },
    ];

    const selectActivity = (type) => {
        switch (type) {
            case "transfer":
                return "pays.supplier.transfer.send";
            case "check":
            case "echeck":
                return "pays.supplier.check.echeq.send";
            case "retention":
                return "pays.supplier.withholdings.send";
            default:
                return "";
        }
    };

    useEffect(() => {
        if (!typeTransaction) {
            setDisableSubmit(true);
        } else if (typeTransaction === "transmitter" && (fileId === "" || availableFilesToUse.length === 0)) {
            setDisableSubmit(true);
        } else if (typeTransaction === "file" && (fileId === "" || linesWithErrors.length > 0 || isInvalidFormat)) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }, [isUploading, availableFilesToUse, linesWithErrors, isInvalidFormat, typeTransaction, fileId]);

    useEffect(() => {
        setFileId(idFileCharged);
    }, [idFileCharged]);

    useEffect(() => {
        setShowUploadingModal(isUploading);
    }, [isUploading]);

    useEffect(() => {
        if (fileUploadError) {
            setShowFileUploadErrorModal(true);
        }
    }, [fileUploadError]);

    useEffect(() => {
        if ((!goToPreviewForm && !goToTicket) || (idTransaction && (mode === "edit" || mode === "view"))) {
            if (mode !== "view") {
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
            }
            dispatch(paymentToSuppliersActions.goToPreviewForm(false));
        } else if (goToTicket && idTransactionTicket) {
            dispatch(push(`/transaction/${idTransactionTicket}`));
            dispatch(paymentToSuppliersActions.setComeFormDetail(true));
            dispatch(paymentToSuppliersActions.goToTicket(false));
        } else {
            dispatch(
                formActions.previewForm({
                    formikBag: { props: { metadata: {} } },
                    idActivity: ID_ACTIVITY,
                    idTransaction,
                    values: {
                        idFile: idFileCharged,
                        amountForCap,
                    },
                }),
            );
            dispatch(paymentToSuppliersActions.goToPreviewForm(false));
        }

        dispatch(paymentToSuppliersActions.comeToList(false));
        dispatch(paymentToSuppliersActions.comeToTicket(""));
    }, [location, dispatch]);

    useEffect(() => {
        if (mode === "view" && transaction) {
            ID_ACTIVITY = selectActivity(transaction.data?.fileType.toLowerCase());
        } else if (goToTicket) {
            dispatch(paymentToSuppliersActions.setComeFormDetail(true));
        }
    }, [mode]);

    useEffect(() => {
        if (previewData) {
            setTypeTransaction(previewData?.uploadType);
        } else if (uploadType !== "") {
            setTypeTransaction(uploadType);
        }
    }, [previewData, uploadType]);

    const handleShowUploadingModal = (value) => {
        setShowUploadingModal(value);
    };

    const handleShowPermissionModal = () => {
        setShowPermissionModal(false);
    };

    const handleInputFileClick = () => {
        fileRef.current.click();
    };

    const convertToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result.split(",")[1]);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });

    const resetDataInvalidFormat = () => {
        setIsInvalidFormat(false);
        setInvalidFormatFileName("");
        setInvalidFormatFileType("");
        fileRef.current.value = "";
    };

    const resetDataInvalidNomenclature = () => {
        setInvalidNomenclature(false);
        setInvalidNomenclatureName("");
    };

    const hasPermission = (type) => {
        switch (type) {
            case "transfer":
                return !!activeEnvironment.permissions["suppliers.transfer"];
            case "check":
            case "echeck":
                return !!activeEnvironment.permissions["suppliers.checkEcheq"];
            case "retention":
                return !!activeEnvironment.permissions["suppliers.withholdings"];
            default:
                return "";
        }
    };

    const onChangeFile = async (event, setFieldValue) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const type = supplierUtils.typeFileByName(file.name);
        const fileBase64 = await convertToBase64(file);
        if (file) {
            const fileTypes = config.get("paymentSuppliers.file.extensions", "dat");
            const fileExtension = `${file.name.split(".").slice(-1)}`;
            if (type === "") {
                setInvalidNomenclature(true);
                setInvalidNomenclatureName(file.name);
            } else if (file.name.length > 19 || file.name.length < 19) {
                dispatch(
                    notificationActions.showNotification(
                        i18nUtils.get("forms.payments.suppliers.fileLengthError.label"),
                        "error",
                        ["form"],
                    ),
                );
            } else if (fileTypes.indexOf(fileExtension.toLowerCase()) > -1) {
                if (hasPermission(type)) {
                    dispatch(
                        paymentToSuppliersActions.saveFile(
                            fileBase64,
                            file.name,
                            type,
                            "descriptionFile",
                            "idActivity",
                        ),
                    );
                    dispatch(paymentToSuppliersActions.saveBase64(fileBase64));
                } else {
                    setShowPermissionModal(true);
                }
                setFieldValue("amount", 10);
                ID_ACTIVITY = selectActivity(type);
            } else {
                setIsInvalidFormat(true);
                setInvalidFormatFileName(file.name);
                setInvalidFormatFileType(type);
            }
        }
    };

    const handleLoadTypeChange = (e) => {
        setFileId("");
        setTypeTransaction(e);
        dispatch(paymentToSuppliersActions.resetUploadedFileData());
    };

    const renderChargingInstructions = () => {
        const filesTypes = [
            "transfer.design",
            "transfer",
            "echeq.design",
            "echeq",
            "withholdings.design",
            "withholdings",
        ];

        return (
            <div className="charging-instructions__download">
                {filesTypes.map((type, index) => (
                    <Button
                        key={type}
                        bsStyle="link"
                        className={`btn-link--wIcon ${index !== 0 ? "ui-mt-4" : ""}`}
                        image="images/ui-icons/ui-download.svg"
                        label={`forms.payments.suppliers.download.${type}.label`}
                        onClick={() => {
                            dispatch(paymentToSuppliersActions.downloadPaymentsPerBatchFilesExample(type));
                        }}
                    />
                ))}
            </div>
        );
    };

    const renderFields = (setFieldValue) => (
        <>
            {mode === "edit" && (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="uploadType"
                        name="uploadType"
                        idField="uploadType"
                        renderAs="radio"
                        onChange={(e) => handleLoadTypeChange(e)}
                        optionList={uploadTypeOptions}
                        value={typeTransaction ? [typeTransaction] : ""}
                        inLineControl
                        customI18nLabel="forms.pays.supplier.uploadType.label"
                    />

                    {typeTransaction === "file" && (
                        <>
                            <div className="form-text-info ui-mt-3">
                                <I18n
                                    id={`${ID_FORM}.message.label`}
                                    component="p"
                                    componentProps={{ className: "ui-mb-0" }}
                                />
                                <I18n id={`${ID_FORM}.remember.label`} component="p" />
                            </div>

                            {renderChargingInstructions()}

                            <input
                                id="myInput"
                                type="file"
                                ref={fileRef}
                                style={{ display: "none" }}
                                onChange={(e) => onChangeFile(e, setFieldValue)}
                            />

                            {nameFileCharged === "" && !isInvalidFormat && linesWithErrors.length === 0 && (
                                <Button
                                    bsStyle="outline"
                                    image="images/attachment.svg"
                                    className="ui-mt-7"
                                    id={`${ID_FORM}.buttonAttachment.label`}
                                    label={`${ID_FORM}.buttonAttachment.label`}
                                    onClick={handleInputFileClick}
                                />
                            )}

                            {showPermissionModal && (
                                <SimpleModal
                                    modalIcon="images/warningIcon.svg"
                                    showActions={false}
                                    closeModal={handleShowPermissionModal}
                                    isDisplayed
                                    customCancellationFunction={() => null}
                                    modalMainText={`${ID_FORM}.modalPermission.label`}
                                />
                            )}

                            {showUploadingModal && (
                                <SimpleModal
                                    className="uploading-file-modal"
                                    modalIcon="images/processing-archive.svg"
                                    modalBigTitle={`${ID_FORM}.uploadFile.modalTitle.label`}
                                    showActions={false}
                                    closeModal={() => handleShowUploadingModal(false)}
                                    buttonAction={() => {}}
                                    isDisplayed
                                    customCancellationFunction={() => null}>
                                    <I18n
                                        component="p"
                                        id={`${ID_FORM}.uploadFile.modalParagraph.label`}
                                        componentProps={{ className: "ui-text-center ui-mt-3" }}
                                    />
                                </SimpleModal>
                            )}

                            {showFileUploadErrorModal && (
                                <SimpleModal
                                    modalIcon="images/warningIcon.svg"
                                    showActions={false}
                                    closeModal={() => {
                                        setShowFileUploadErrorModal(false);
                                        fileRef.current.value = "";
                                        dispatch(paymentToSuppliersActions.resetUploadErrors());
                                    }}
                                    isDisplayed
                                    customCancellationFunction={() => null}
                                    modalMainText={fileUploadError}
                                    defaultMainText={fileUploadError}
                                    buttonAction={() => {}}
                                />
                            )}
                        </>
                    )}

                    {typeTransaction === "transmitter" && (
                        <>
                            {availableFiles.length ? (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    key="paymentFilesAvailable"
                                    name="paymentFilesAvailable"
                                    idField="paymentFilesAvailable"
                                    renderAs="radio"
                                    onChange={(e) => {
                                        setFileId(Number(e));
                                        const { label } = availableFiles.find((item) => item.id === Number(e));
                                        const type = supplierUtils.typeFileByName(label);
                                        ID_ACTIVITY = selectActivity(type);
                                    }}
                                    optionList={availableFiles}
                                    value={fileId ? [fileId] : ""}
                                    customI18nLabel="forms.pays.supplier.paymentFilesAvailable.label"
                                />
                            ) : (
                                <NoDataContainer
                                    fetching={fetching}
                                    noDataImage="images/coloredIcons/folder-empty.svg"
                                    noDataText={`${ID_FORM}.transmitter.noData.message.1`}
                                    className="ui-mt-3 ui-mb-8"
                                    applyMaxWidth={false}>
                                    <I18n
                                        id={`${ID_FORM}.transmitter.noData.message.2`}
                                        componentProps={{ className: "ui-mt-3" }}
                                    />
                                </NoDataContainer>
                            )}
                        </>
                    )}

                    {isInvalidFormat && (
                        <>
                            <div className="data-wrapper ui-mt-7">
                                <I18n
                                    id={`${ID_FORM}.fileCharged.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                            </div>

                            <UploadedFile
                                nameFile={invalidFormatFileName}
                                idFileCharged={idFileCharged}
                                onClick={() => resetDataInvalidFormat()}
                            />

                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.typeFile.label`} componentProps={{ className: "data-label" }} />
                                <span>{invalidFormatFileType}</span>
                            </div>

                            <Alert className="ui-mt-7 ui-mb-8">
                                <div className="ui-fw-sbold">
                                    <span className="ui-text-error">
                                        {i18nUtils.get(`${ID_FORM}.invalidFormatError.title.label`)}
                                    </span>
                                    <p className="ui-mt-0">
                                        <I18n
                                            id={`${ID_FORM}.invalidFormatError.paragraph.label`}
                                            componentProps={{ className: "data-label" }}
                                        />
                                    </p>
                                </div>
                            </Alert>
                        </>
                    )}

                    {typeTransaction === "file" && (nameFileCharged !== "" || linesWithErrors.length !== 0) && (
                        <>
                            <div className="data-wrapper ui-mt-7">
                                <I18n
                                    id={`${ID_FORM}.fileCharged.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                            </div>
                            <UploadedFile
                                nameFile={nameFileCharged !== "" ? nameFileCharged : nameFileError}
                                idFileCharged={idFileCharged}
                                onClick={() => {
                                    if (nameFileCharged !== "") {
                                        dispatch(paymentToSuppliersActions.deleteFile(idFileCharged));
                                        dispatch(paymentToSuppliersActions.resetUploadedFileData());
                                        fileRef.current.value = "";
                                    } else {
                                        dispatch(push("/paymentSuppliers"));
                                        dispatch(paymentToSuppliersActions.resetUploadedFileData());
                                    }
                                }}
                            />

                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.typeFile.label`} componentProps={{ className: "data-label" }} />
                                {nameFileCharged !== "" ? (
                                    <span>
                                        {i18nUtils.get(
                                            `${ID_FORM}.typeFile.${supplierUtils.typeFileByName(
                                                nameFileCharged,
                                            )}.label`,
                                        )}
                                    </span>
                                ) : (
                                    <span>{typeFileError}</span>
                                )}
                            </div>
                        </>
                    )}

                    {linesWithErrors.length !== 0 && (
                        <>
                            <Alert className="ui-mt-7 ui-mb-8">
                                <div className="ui-fw-sbold">
                                    <I18n
                                        id={`${ID_FORM}.warningError.title.label`}
                                        componentProps={{ className: "ui-text-error" }}
                                    />
                                    <I18n
                                        id={`${ID_FORM}.warningError.paragraph.label`}
                                        componentProps={{ className: "ui-mt-0" }}
                                        component="p"
                                    />
                                </div>
                            </Alert>

                            <Button
                                bsStyle="outline"
                                image="images/attachment.svg"
                                className="charging-instructions__attachment"
                                id={`${ID_FORM}.buttonseeLines.Errors.label`}
                                label={`${ID_FORM}.buttonseeLines.Errors.label`}
                                onClick={() => {
                                    dispatch(
                                        paymentToSuppliersActions.setData({
                                            uploadType: typeTransaction,
                                        }),
                                    );
                                    dispatch(paymentToSuppliersActions.comeToList(true));
                                    dispatch(paymentToSuppliersActions.setComeFromProcessed(false));
                                    dispatch(push("/suppliersPaymentList/supplierList"));
                                }}
                            />
                        </>
                    )}

                    {invalidNomenclature && (
                        <>
                            <div className="data-wrapper ui-mt-7">
                                <I18n
                                    id={`${ID_FORM}.fileCharged.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                            </div>

                            <UploadedFile
                                nameFile={invalidNomenclatureName}
                                onClick={() => resetDataInvalidNomenclature()}
                            />

                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.typeFile.label`} componentProps={{ className: "data-label" }} />
                                <span>-</span>
                            </div>

                            <Alert className="ui-mt-7 ui-mb-8">
                                <div className="ui-fw-sbold">
                                    <span className="ui-text-error">
                                        {i18nUtils.get(`${ID_FORM}.fileNameError.title.label`)}
                                    </span>
                                    <p className="ui-mt-0">{i18nUtils.get(`${ID_FORM}.fileNameError.label`)}</p>
                                </div>
                            </Alert>
                        </>
                    )}
                </>
            )}

            {(mode === "preview" || mode === "view") && (
                <>
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.typeFile.label`} componentProps={{ className: "data-label" }} />
                        {data.fileType ? (
                            <I18n
                                id={`${ID_FORM}.typeFile.${data.fileType.toLowerCase()}.label`}
                                componentProps={{ className: "data-text" }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.nameFile.label`} componentProps={{ className: "data-label" }} />
                        <span className="data-text"> {data?.fileName.slice(0, -4) || ""}</span>
                    </div>
                    {mode === "preview" && (
                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.date.label`} componentProps={{ className: "data-label" }} />
                            <FormattedDate date={data?.creationDate || ""} />
                        </div>
                    )}
                    <div className="data-wrapper">
                        <I18n
                            id={
                                fileType === "RETENTION"
                                    ? `${ID_FORM}.numberRecords.label`
                                    : `${ID_FORM}.numberPayments.label`
                            }
                            componentProps={{ className: "data-label" }}
                        />
                        <span className="data-text"> {data?.totalLines || ""}</span>
                    </div>
                    {fileType === "TRANSFER" && (
                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.debitAccount.label`} componentProps={{ className: "data-label" }} />
                            <span className="data-text"> {data?.debitAccount || ""}</span>
                        </div>
                    )}
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                        <FormattedAmount
                            quantity={data?.totalAmount?.quantity || ""}
                            currency={data?.totalAmount?.currency || ""}
                        />
                    </div>
                    {!isMobile && !fromBackoffice && (
                        <Button
                            bsStyle="outline"
                            image="images/password-show.svg"
                            className={`ui-mt-7 ${mode === "view" ? "ui-mb-3" : ""}`}
                            id={`${ID_FORM}.buttonDetail.label`}
                            label={`${ID_FORM}.buttonDetail.label`}
                            onClick={() => {
                                if (mode === "preview") {
                                    dispatch(paymentToSuppliersActions.comeToList(true));
                                    dispatch(paymentToSuppliersActions.goToPreviewForm(true));
                                } else {
                                    dispatch(paymentToSuppliersActions.comeToTicket(idTransaction));
                                    dispatch(paymentToSuppliersActions.goToTicket(true));
                                }

                                dispatch(paymentToSuppliersActions.seeDetail(data));
                                dispatch(paymentToSuppliersActions.setComeFromProcessed(false));
                                dispatch(push("/suppliersPaymentList/supplierList"));
                            }}
                        />
                    )}
                    {mode === "preview" && (
                        <>
                            <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mt-7 ui-mb-0">
                                <I18n
                                    id={`${ID_FORM}.warningPreview.label`}
                                    componentProps={{ className: "ui-fw-sbold" }}
                                />
                            </Alert>

                            <DisclaimerContainer
                                disclaimer={`${ID_FORM}.disclaimerPreview.label`}
                                className="important-text ui-mt-7"
                            />
                        </>
                    )}
                </>
            )}
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, "pays.supplier.download.ticket"));
    };

    const formProps = {
        title: `${ID_FORM}.title.label`,
        subtitle:
            mode !== "view" ? `${ID_FORM}.${mode === "preview" ? "subtitle.confirm.label" : "subtitle.label"}` : "",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
            showSubmit,
        },
        data: {
            idFile: fileId,
            fileType,
            fileName: nameFileCharged,
            fileToSave,
            totalLines,
            totalAmount,
            amountForCap,
            creationDate: date,
            debitAccount,
        },
        renderFields,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        exportList: "pdf",
        handleClickDownload,
        buttonLabel: "global.continue",
        titleConfirmation: true,
        submitDisabled: disableSubmit,
        isModifiable: false,
    };
    return <FormTransition {...props} {...formProps} />;
};
PaymentToSuppliers.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    previewData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    idActivity: string.isRequired,
    idForm: string.isRequired,
    accountSelected: string.isRequired,
    nameFileCharged: string.isRequired,
    idFileCharged: string.isRequired,
    isUploading: bool.isRequired,
    fetching: bool.isRequired,
    linesWithErrors: shape({}).isRequired,
    availableFilesToUse: shape([]).isRequired,
    fileToSave: string.isRequired,
    fileType: string.isRequired,
    totalLines: string.isRequired,
    totalAmount: string.isRequired,
    date: string.isRequired,
    debitAccount: string.isRequired,
    nameFileError: string.isRequired,
    typeFileError: string.isRequired,
    comeToList: bool.isRequired,
    goToPreviewForm: bool.isRequired,
    idTransactionTicket: string.isRequired,
    goToTicket: bool.isRequired,
    isMobile: bool,
    uploadType: string.isRequired,
    fileUploadError: string.isRequired,
    amountForCap: string.isRequired,
};
PaymentToSuppliers.defaultProps = {
    fromBackoffice: false,
    preData: null,
    previewData: null,
    useDefaultSubmit: true,
    isMobile: false,
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isUploading: suppliersSelectors.isUploading(state),
    nameFileCharged: suppliersSelectors.getnameFileCharged(state),
    idFileCharged: suppliersSelectors.getidFileCharged(state),
    linesWithErrors: suppliersSelectors.getlinesWithErrors(state),
    fileToSave: suppliersSelectors.getFileToSave(state),
    totalLines: suppliersSelectors.getTotalLines(state),
    totalAmount: suppliersSelectors.getTotalAmount(state),
    date: suppliersSelectors.getDate(state),
    fileType: suppliersSelectors.getFileType(state),
    debitAccount: suppliersSelectors.getDebitAccount(state),
    nameFileError: suppliersSelectors.getNameFileError(state),
    comeToList: suppliersSelectors.getComeToList(state),
    goToPreviewForm: suppliersSelectors.getGoToPreviewForm(state),
    availableFilesToUse: suppliersSelectors.getAvailableFilesToUse(state),
    idTransactionTicket: suppliersSelectors.getIdTransactionTicket(state),
    goToTicket: suppliersSelectors.getGoToTicket(state),
    uploadType: suppliersSelectors.getUploadType(state),
    fileUploadError: suppliersSelectors.getFileUploadError(state),
    amountForCap: suppliersSelectors.getAmountForCap(state),
});

export default compose(connect(mapStateToProps), withRouter)(PaymentToSuppliers);
