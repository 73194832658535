import moment from "moment";
import { array, bool, func, oneOfType, shape, string, objectOf, any } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { types, actions, selectors } from "reducers/investments";
import { actions as notificationActions } from "reducers/notification";
import { get } from "util/i18n";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { validateToResetFilters } from "util/filters";
import PageLoading from "pages/_components/PageLoading";
import { selectors as sessionSelectors } from "../../reducers/session";
import Button from "../_components/Button";
import Head from "../_components/Head";
import I18n from "../_components/I18n";
import MainContainer from "../_components/MainContainer";
import Notification from "../_components/Notification";
import Holding from "./_components/Holding";
import Offers from "./_components/Offers";
import Filters from "./_components/Filters";

const ID_ACTIVITY = "investments.mutualFund";

const MutualFunds = ({
    history,
    isMobile,
    shareholderAccounts,
    dispatch,
    isFetching,
    toggleState,
    user,
    activeEnvironment,
    mutualFunds,
    operationFunds,
    isResetQuery,
    idActivityOnFilter,
    filters,
    isFirstFetched,
}) => {
    const handleTabClick = (selectedType) => {
        dispatch(filterActions.validateToResetFilters(types.RESET_FILTERS, ID_ACTIVITY));

        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            dispatch(actions.onToggleOptions(selectedType));

            let newFilters = {};
            if (selectedType === "offer") {
                newFilters = {
                    accountNumber: "",
                    date: moment().format("YYYY-MM-DD"),
                };
                dispatch(actions.loadFundOffers(newFilters.accountNumber, newFilters.date, "", ""));
            }
            if (selectedType === "holding") {
                newFilters = {
                    accountNumber: "",
                    date: "",
                };
                dispatch(
                    actions.loadMutualFundByAccount(newFilters.accountNumber, newFilters.date, user.documentNumber),
                );
            }

            dispatch(actions.setFundListFilters(newFilters));
        }
    };

    useEffect(() => {
        handleTabClick(isResetQuery ? "holding" : toggleState);
    }, []);

    const handleOnClick = (item, type) => {
        if (type === "Movements") {
            const mutualFund = mutualFunds.find((fund) => fund.number === item.number);
            dispatch(
                actions.setMovementFilters({ shareholderAccount: filters.accountNumber, mutualFund: mutualFund ?? {} }),
            );
            history.push("/mutualFunds/movements");
        }
        if (type === "supplementaryInformation") {
            fetch(`https://www.marivafondos.com.ar/sites/default/files/fact_sheet_${item.number}.pdf`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/pdf",
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `FileName.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })

                .catch(() => {
                    window.open(`https://www.marivafondos.com.ar/sites/default/files/fact_sheet_${item.number}.pdf`);
                });
        }

        if (type !== "Movements" && type !== "supplementaryInformation") {
            if (item.status) {
                dispatch(
                    notificationActions.showNotification(get("forms.request.investment.serviceClose"), "error", [
                        "mutualFunds",
                    ]),
                );
            } else if (!shareholderAccounts.length) {
                dispatch(
                    notificationActions.showNotification(
                        get("investments.mutualFund.accounts.notFound.message"),
                        "error",
                        ["mutualFunds"],
                    ),
                );
            } else {
                const shareHolder = shareholderAccounts.filter(
                    (account) => account.number === filters.accountNumber,
                )[0];
                const opFund = operationFunds.filter((fund) => fund.number === item.number)[0];
                const timeNow = moment().format("YYYY-MM-DD");
                let expiryDateProfileInvestor;
                const caseForValidate = activeEnvironment.expiryDateProfileInvestor;
                if (caseForValidate) {
                    expiryDateProfileInvestor = moment(caseForValidate, "YYYY-MM-DD").format("YYYY-MM-DD");
                }

                if (!item.riskLevel && !opFund?.riskLevel) {
                    dispatch(
                        notificationActions.showNotification(get("forms.request.investment.fundRiskLevel"), "error", [
                            "mutualFunds",
                        ]),
                    );
                } else if (!activeEnvironment.riskLevel && type === "Subscribe") {
                    dispatch(
                        notificationActions.showNotification(get("forms.request.investment.investorTest"), "error", [
                            "mutualFunds",
                        ]),
                    );
                } else if (expiryDateProfileInvestor <= timeNow && type === "Subscribe") {
                    dispatch(
                        notificationActions.showNotification(get("forms.request.investment.expiryToOperate"), "error", [
                            "mutualFunds",
                        ]),
                    );
                } else {
                    dispatch(actions.setSelectedInvestment(item, shareHolder));
                    dispatch(actions.validateAccessRestriction(type, item));
                }
            }
        }
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head showLogo arrowWhite additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                addLinkToLabel="investments.mutualFund.request.account.label.button"
                addLinkTo="/formCustom/shareholderAccountOpening"
                showPlusSecondary
                accessibilityTextId="menu.investments.mutualFund"
            />
        );
    };

    const renderFilters = () => (
        <Filters idForm={ID_ACTIVITY} isMobile={isMobile} handleOnClick={handleOnClick} isResetQuery={isResetQuery} />
    );

    return (
        <>
            <Notification scopeToShow="mutualFunds" />

            {renderHeader()}

            <MainContainer>
                <div className="container-left">
                    <div className="title-left-header">
                        <I18n id="investments.mutualFund.title" component="h1" />
                    </div>

                    {isMobile && (
                        <Button
                            onClick={() => dispatch(push("/formCustom/shareholderAccountOpening"))}
                            image="images/plusWhite.svg"
                            label="investments.mutualFund.request.account.label.button"
                            bsStyle="secondary"
                            block
                            className="ui-mb-7"
                        />
                    )}
                    <div className="tabs-wrapper">
                        <Button
                            label="investments.mutualFund.holding.label"
                            onClick={() => handleTabClick("holding")}
                            bsStyle="outline"
                            className={`${toggleState === "holding" ? "active" : ""}`}
                            disabled={!shareholderAccounts.length}
                        />

                        <Button
                            label="investments.mutualFund.offer.label"
                            onClick={() => handleTabClick("offer")}
                            bsStyle="outline"
                            className={`${toggleState === "offer" ? "active" : ""}`}
                        />
                    </div>

                    <PageLoading loading={isFetching && isFirstFetched}>
                        {renderFilters()}

                        {toggleState === "holding" ? (
                            <Holding isMobile={isMobile} handleOnClick={handleOnClick} />
                        ) : (
                            <Offers handleOnClick={handleOnClick} isMobile={isMobile} />
                        )}
                    </PageLoading>
                </div>
            </MainContainer>
        </>
    );
};

MutualFunds.propTypes = {
    shareholderAccounts: oneOfType([array]).isRequired,
    mutualFunds: oneOfType([array]).isRequired,
    operationFunds: oneOfType([array]).isRequired,
    toggleState: string.isRequired,
    isFetching: bool.isRequired,
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    history: objectOf(any).isRequired,
    user: shape({}).isRequired,
    activeEnvironment: shape({}).isRequired,
    isResetQuery: bool.isRequired,
    idActivityOnFilter: string.isRequired,
    filters: shape().isRequired,
    isFirstFetched: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: selectors.getFetching(state),
    shareholderAccounts: state.investments.shareholderAccounts,
    mutualFunds: state.investments.mutualFunds,
    operationFunds: state.investments.operationFunds,
    toggleState: state.investments.toggleState,
    user: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getEnvironments(state)[sessionSelectors.getActiveEnvironment(state).id],
    filters: selectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    isFirstFetched: selectors.getFirstFetched(state),
});

export default connect(mapStateToProps)(MutualFunds);
