export const NO_TRANSITION = "noTransition";

export const ACTIVITIES_WITHOUT_PREVIEW_STEP = [
    "administration.medium.modify.signature.send",
    "administration.restrictions.user.delete.send",
];

export const MAX_FAILED_TIMES = 2;

export const TIME_IN_MILLIS_TO_REFRESH = 15000;

export const MAX_LAST_LOGIN_TO_SHOW = 5;

export const CORPORATE_GROUP_ENVIRONMENT_TYPE = "corporateGroup";
export const RETAIL_ENVIRONMENT_TYPE = "retail";

export const SALARY_PAYMENT_ID_FORM = "salaryPayment";

export const REGION_USA = "US";
export const REGION_REST_OF_LATAM = "LATAM";
export const REGION_ARG = "AR";
export const REGION_CHI = "CL";
export const REGION_CAN = "CA";
export const REGION_UY = "UY";
export const REGION_COL = "CO";
export const REGION_MEX = "MX";
export const REGION_PER = "PE";
export const REGION_ECU = "EC";
export const REGION_PAR = "PY";
export const REGION_PAN = "PA";
export const REGION_CR = "CR";
export const AVAILABLE_REGIONS = [
    REGION_USA,
    REGION_REST_OF_LATAM,
    REGION_ARG,
    REGION_CHI,
    REGION_CAN,
    REGION_UY,
    REGION_COL,
    REGION_MEX,
    REGION_PER,
    REGION_ECU,
    REGION_PAR,
    REGION_PAN,
    REGION_CR,
];
