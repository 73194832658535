import React, { Component } from "react";
import classNames from "classnames";
import { string, func, bool, oneOfType, node, arrayOf } from "prop-types";

import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import Image from "pages/_components/Image";

class Checkbox extends Component {
    static propTypes = {
        name: string.isRequired,
        onChange: func,
        onBlur: func,
        error: string,
        label: oneOfType([string, node]),
        hideLabel: bool,
        idForm: string,
        checked: bool,
        readOnly: bool,
        block: bool,
        formGroup: bool,
        value: oneOfType([bool, arrayOf(string), string]).isRequired,
    };

    static defaultProps = {
        error: "",
        label: "",
        hideLabel: false,
        idForm: "",
        checked: false,
        readOnly: false,
        block: true,
        formGroup: true,
        onChange: null,
        onBlur: null,
    };

    render() {
        const { idForm, error, hideLabel, label, block, formGroup, readOnly, ...props } = this.props;

        return (
            <div
                className={classNames("c-control c-control--has-icon c-control--checkbox", {
                    "has-error": error,
                    "c-control-block": block,
                    "form-group": formGroup,
                })}>
                <input
                    id={props.name}
                    tabIndex={props.tabIndex}
                    className="c-control-input"
                    type="checkbox"
                    {...props}
                    readOnly={readOnly}
                />

                <label className="c-control-label" htmlFor={props.name}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>

                    {!hideLabel && (
                        <div className="form-group-text">{label || <I18n id={`${idForm}.${props.name}.label`} />}</div>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </div>
        );
    }
}

export default Checkbox;
