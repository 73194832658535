import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool, number, string } from "prop-types";

import I18n from "pages/_components/I18n";
import MessageActions from "pages/communications/_components/MessageActions";

function MessageSender(props) {
    const { communication } = props;

    if (communication.direction === "BANK_TO_CUSTOMER") {
        return (
            <div className="message-item-sender">
                <I18n
                    component="span"
                    className="data-aux"
                    id={`communications.tray.${communication.idCommunicationTray}`}
                />
                <span> &gt; </span>
                <I18n component="span" id="communication.thread.message.me" />
            </div>
        );
    }
    return (
        <div className="message-item-sender">
            <I18n component="span" id="communication.thread.message.me" />
            <span> &gt; </span>
            <I18n
                component="span"
                className="data-aux"
                id={`communications.tray.${communication.idCommunicationTray}`}
            />
        </div>
    );
}

MessageSender.propTypes = {
    communication: shape({
        idCommunication: number,
        direction: string,
    }).isRequired,
};

class MessageItem extends Component {
    static propTypes = {
        index: number.isRequired,
        communication: shape({
            idCommunication: number,
            userRead: bool,
            sentDateAsString: string,
            direction: string,
            subject: string,
            body: string,
        }).isRequired,
        handleRemoveClick: func.isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleSelectMessageClick: func.isRequired,
        selectedIdCommunication: string.isRequired,
        leftPanel: string.isRequired,
    };

    removeHTMLTags = (html) => {
        const parseHTML = new DOMParser().parseFromString(html, "text/html");
        return parseHTML.body.textContent;
    };

    render() {
        const {
            communication,
            index,
            handleRemoveClick,
            handleChangeMessageStatus,
            handleSelectMessageClick,
            selectedIdCommunication,
            leftPanel,
        } = this.props;

        const displayDate = (date) => `${date.split(" ")[0]} | ${date.split(" ")[1]}`;

        const bodyWithoutHTMLTags = this.removeHTMLTags(communication.body);

        return (
            <li
                ref={index}
                onClick={() => handleSelectMessageClick(communication.idCommunication, communication.userRead, index)}
                key={communication.idCommunication}
                className={`${communication.userRead ? "read-message" : ""} ${
                    communication.idCommunication === selectedIdCommunication && leftPanel === "read" ? "selected" : ""
                }`}>
                <div className="message-item">
                    <div className="message-item--row">
                        <div className="d-flex-direction-column">
                            <span className="data-date">{displayDate(communication.sentDateAsString)}</span>
                            <MessageSender communication={communication} />
                        </div>
                        <MessageActions
                            communication={communication}
                            handleRemoveClick={handleRemoveClick}
                            handleChangeMessageStatus={handleChangeMessageStatus}
                            index={index}
                        />
                    </div>

                    <div className="message-item--row">
                        <div className="ellipsis">
                            <h4 className={`message-title ${!communication.userRead ? "fw-bold" : ""}`}>
                                {communication.subject}
                            </h4>

                            <div className="data-desc list-content ellipsis">{bodyWithoutHTMLTags}</div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedIdCommunication: state.communication.selectedIdCommunication,
    leftPanel: state.communications.leftPanel,
});

export default connect(mapStateToProps)(MessageItem);
