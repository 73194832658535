import * as API from "middleware/api";

export const loadDetailsRequest = (id) =>
    API.executeWithAccessToken("/administration.advanced.user.details.read", { id });

export const loadChannelsRequest = (id) => API.executeWithAccessToken("/administration.medium.read.channels", { id });

export const updateChannelsPreview = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.channels.preview", data);

export const updateChannelsRequest = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.channels.send", data);

export const updateSignatureRequest = (data) =>
    API.executeWithAccessToken("/administration.medium.modify.signature.send", data);

export const updateGroupsOfUserPreview = (data) =>
    API.executeWithAccessToken("/administration.user.detail.groups.modify.preview", data);

export const updateGroupsOfUser = (data) =>
    API.executeWithAccessToken("/administration.user.detail.groups.modify.send", data);

export const loadGroupRequest = (id) => API.executeWithAccessToken("/administration.advanced.group.read", { id });

export const loadGroupFormDataRequest = (id) => {
    if (id) {
        return API.executeWithAccessToken("/administration.advanced.group.modify.pre", { id });
    }
    return API.executeWithAccessToken("/administration.advanced.group.create.pre", { id });
};

export const createSignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.create.send", data);

export const createSignaturesSchemePre = () => API.executeWithAccessToken("/administration.signatures.create.pre");

export const listSignaturesSchemes = ({ pageNumber = 1, orderBy = "id_signature ASC" } = {}) =>
    API.executeWithAccessToken("/administration.signatures.list", {
        pageNumber,
        orderBy,
    });

export const deleteSignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.delete.send", data);

export const deleteSignaturesSchemePre = (id) =>
    API.executeWithAccessToken("/administration.signatures.delete.pre", { id });

export const modifySignaturesScheme = (data) =>
    API.executeWithAccessToken("/administration.signatures.modify.send", data);

export const modifySignaturesSchemePre = (id) =>
    API.executeWithAccessToken("/administration.signatures.modify.pre", { id });

export const submitGroupFormPreviewRequest = (formData, id) => {
    if (id) {
        return API.executeWithAccessToken("/administration.advanced.group.modify.preview", {
            ...formData,
            id,
        });
    }
    return API.executeWithAccessToken("/administration.advanced.group.create.preview", {
        ...formData,
        id,
    });
};

export const submitGroupFormRequest = (data, id) => {
    if (id) {
        return API.executeWithAccessToken("/administration.advanced.group.modify.send", {
            ...data,
            id,
        });
    }
    return API.executeWithAccessToken("/administration.advanced.group.create.send", {
        ...data,
        id,
    });
};

export const exportSignatureSchemes = (data) => API.executeWithAccessToken("/administration.signatures.export", data);
