const NUMBER_FOR_SEPARATORS = 12345.6;

export const countDecimalPlaces = (number, decimalSeparator = ",") => {
    if (!number) {
        return 0;
    }
    let value = number;

    if (typeof number === "string") {
        const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");
        value = value.replace(INPUT_REGEX_REPLACE, "");
    } else {
        value = `${value}`.replace(".", decimalSeparator);
    }

    return value && value % 1 !== 0 ? `${value}`.split(decimalSeparator)[1].length : 0;
};

export const numberFormat = (lang) => {
    const localeString = NUMBER_FOR_SEPARATORS.toLocaleString(lang || undefined);

    const decimalSeparatorIndex = Math.max(localeString.lastIndexOf("."), localeString.lastIndexOf(","));
    const thousandSeparatorIndex = Math.min(localeString.lastIndexOf("."), localeString.lastIndexOf(","));

    return {
        decimalSeparator: localeString[decimalSeparatorIndex],
        thousandSeparator: localeString[thousandSeparatorIndex],
    };
};

export const toNumber = (str, decimalSeparator = ",", precision = 2) => {
    const INPUT_REGEX_REPLACE = new RegExp(`[^0-9${decimalSeparator}]`, "g");

    return str && str !== "" && str !== null
        ? parseFloat(parseFloat(str.replace(INPUT_REGEX_REPLACE, "").replace(decimalSeparator, ".")).toFixed(precision))
        : str;
};

export const customFormatNumber = (amount, maximumFractionDigits = 2) =>
    amount.toLocaleString("es-AR", {
        maximumFractionDigits,
    });

export const clamp = (n, min, max) => Math.max(Math.min(n, max), min);

export const getSafeRandomNumber = () => {
    const crypto = window.crypto || window.msCrypto;
    const array = new Uint32Array(1);

    return crypto.getRandomValues(array)[0];
};
