import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, Field, withFormik } from "formik";
import { func, bool, shape, arrayOf, string } from "prop-types";
import { compose } from "redux";
import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as loginSelectors } from "reducers/login";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import AddressGoogleField from "pages/_components/fields/AddressGoogleField";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Checkbox from "pages/_components/Checkbox";
import Yup from "yup";

const FORM_ID = "settings.changeAddresses";

class ChangeAddresses extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        countries: arrayOf(shape({ id: string, name: string })).isRequired,
        values: shape({ setMailing: bool }).isRequired,
        activeRegion: string.isRequired,
        setFieldValue: func.isRequired,
        isMobile: bool.isRequired,
        history: func,
    };

    static defaultProps = {
        history: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(settingsActions.getAddressesInformation());
    }

    onPlaceChanged = (city, country, federalState) => {
        const { setFieldValue } = this.props;
        setFieldValue("city", city);
        setFieldValue("country", country);
        setFieldValue("federalState", federalState);
    };

    onPlaceChangedM = (city, country, federalState) => {
        const { setFieldValue } = this.props;
        setFieldValue("cityM", city);
        setFieldValue("countryM", country);
        setFieldValue("federalStateM", federalState);
    };

    renderForm = () => {
        const {
            isDesktop,
            fetching,
            countries,
            values: { setMailing },
            activeRegion,
        } = this.props;

        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                    <Container.Column sm={12} md={9} lg={6} xl={6}>
                        <p className="text-lead">
                            <I18n id="settings.changeAddresses.description" />
                        </p>
                        {activeRegion && (
                            <Field
                                component={AddressGoogleField}
                                idForm={FORM_ID}
                                name="addressLine1"
                                activeRegion={activeRegion}
                                onPlaceChanged={this.onPlaceChanged}
                                isDesktop={isDesktop}
                            />
                        )}
                        <Field
                            component={TextField}
                            idForm={FORM_ID}
                            name="addressLine2"
                            isDesktop={isDesktop}
                            hideLabel
                        />
                        <Field
                            component={Selector}
                            options={countries && countries.map((c) => ({ value: c.id, label: c.name }))}
                            idForm={FORM_ID}
                            searchable={isDesktop}
                            name="country"
                        />
                        <Field component={TextField} idForm={FORM_ID} name="city" isDesktop={isDesktop} />
                        <Field component={TextField} idForm={FORM_ID} name="federalState" isDesktop={isDesktop} />
                        <Field component={TextField} idForm={FORM_ID} name="zipcode" isDesktop={isDesktop} />
                        <Field component={Checkbox} idForm={FORM_ID} name="setMailing" isDesktop={isDesktop} />
                    </Container.Column>
                    {setMailing && (
                        <Container.Column sm={12} md={9} lg={6} xl={6}>
                            <p className="text-lead">
                                <I18n id="settings.changeAddresses.description" />
                            </p>
                            {activeRegion && (
                                <Field
                                    component={AddressGoogleField}
                                    idForm={FORM_ID}
                                    name="addressLine1M"
                                    activeRegion={activeRegion}
                                    onPlaceChanged={this.onPlaceChangedM}
                                    isDesktop={isDesktop}
                                />
                            )}
                            <Field
                                component={TextField}
                                idForm={FORM_ID}
                                name="addressLine2M"
                                isDesktop={isDesktop}
                                hideLabel
                            />

                            <Field
                                component={Selector}
                                options={countries && countries.map((c) => ({ value: c.id, label: c.name }))}
                                idForm={FORM_ID}
                                name="countryM"
                            />
                            <Field component={TextField} idForm={FORM_ID} name="cityM" isDesktop={isDesktop} />
                            <Field component={TextField} idForm={FORM_ID} name="federalStateM" isDesktop={isDesktop} />
                            <Field component={TextField} idForm={FORM_ID} name="zipcodeM" isDesktop={isDesktop} />
                        </Container.Column>
                    )}
                </Container>
                <Container className="container--layout" gridClassName="form-content">
                    <Container.Column sm={12} md={9} lg={6} xl={6}>
                        <hr />
                        <Field idForm={FORM_ID} name="otp" component={Credential} type="otp" />
                    </Container.Column>
                </Container>
                <Container className="container--layout" gridClassName="form-content">
                    <Container.Column sm={12} md={9} lg={6} xl={6}>
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.modify"
                            loading={fetching}
                            disabled={fetching}
                        />
                    </Container.Column>
                </Container>
            </Form>
        );
    };

    render() {
        const { fetching, history, isMobile } = this.props;

        return (
            <Fragment>
                {!fetching && (
                    <Head
                        title="settings.changeAddresses"
                        closeLinkTo="/settings"
                        propsClassNames="title-size"
                        addHeaderClassName={isMobile && "toolbar-mobile-config-1"}
                        onBack={history.goBack}
                    />
                )}
                <Notification scopeToShow="changeAddresses" />
                <MainContainer showLoader={fetching}>{this.renderForm()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: settingsSelectors.isFetching(state),
    address: settingsSelectors.getAddress(state),
    mailingAddress: settingsSelectors.getMailingAdress(state),
    countries: settingsSelectors.getCountries(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            const { address, mailingAddress } = props;
            return {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                country: address.country,
                city: address.city,
                federalState: address.federalState,
                zipcode: address.zipcode,
                setMailing: mailingAddress != null,
                addressLine1M: mailingAddress ? mailingAddress.addressLine1 : "",
                addressLine2M: mailingAddress ? mailingAddress.addressLine2 : "",
                countryM: mailingAddress ? mailingAddress.country : address.country,
                cityM: mailingAddress ? mailingAddress.city : "",
                federalStateM: mailingAddress ? mailingAddress.federalState : "",
                zipcodeM: mailingAddress ? mailingAddress.zipcode : "",
                otp: "",
            };
        },

        validationSchema: () =>
            Yup.lazy((values) => {
                const { setMailing } = values;

                return Yup.object().shape({
                    addressLine1: Yup.string().required(
                        i18nUtils.get("settings.changeAddresses.validations.requiredField"),
                    ),
                    city: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    country: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    federalState: Yup.string().required(
                        i18nUtils.get("settings.changeAddresses.validations.requiredField"),
                    ),
                    zipcode: Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField")),
                    addressLine1M: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    cityM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    countryM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    federalStateM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                    zipcodeM: setMailing
                        ? Yup.string().required(i18nUtils.get("settings.changeAddresses.validations.requiredField"))
                        : Yup.string(),
                });
            }),
        handleSubmit: (
            {
                addressLine1,
                addressLine2,
                country,
                city,
                federalState,
                zipcode,
                addressLine1M,
                addressLine2M,
                countryM,
                cityM,
                federalStateM,
                zipcodeM,
                setMailing,
                otp,
            },
            formikBag,
        ) => {
            const { dispatch } = formikBag.props;
            const address = {
                addressLine1,
                addressLine2,
                country,
                city,
                federalState,
                zipcode,
            };
            const mailingAddress = {
                addressLine1: addressLine1M,
                addressLine2: addressLine2M,
                country: countryM,
                city: cityM,
                federalState: federalStateM,
                zipcode: zipcodeM,
            };

            dispatch(
                settingsActions.setAddressesInformation(address, setMailing ? mailingAddress : null, otp, formikBag),
            );
        },
    }),
)(ChangeAddresses);
