import { func, node, shape } from "prop-types";
import React, { Children, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions as footerActions } from "reducers/footer";
import { selectors as i18nSelectors } from "reducers/i18n";

import * as i18n from "util/i18n";

class FooterDesktop extends Component {
    static propTypes = {
        moreOptions: node,
        messages: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        moreOptions: null,
    };

    handleLinkClick = () => {
        const { dispatch } = this.props;
        dispatch(footerActions.setCurrentPath(window.location.pathname));
    };

    render() {
        const { moreOptions, messages } = this.props;

        if (!messages) {
            return null;
        }

        return (
            <>
                <div className="app-footer">
                    <ul className="legal-nav list-inline">
                        <li>
                            <Link to="/securityPolicies" onClick={this.handleLinkClick}>{`${i18n.get(
                                "global.privacy",
                            )} |`}</Link>
                        </li>
                        <div>
                            <li>
                                <Link to="/termsAndConditions" onClick={this.handleLinkClick}>{`${i18n.get(
                                    "global.termAndConditions",
                                )} |`}</Link>
                            </li>
                            <li>
                                <Link to="/support" onClick={this.handleLinkClick}>
                                    {i18n.get("global.support")}
                                </Link>
                            </li>
                            {moreOptions && Children.map(moreOptions, (option) => <li>{option}</li>)}
                        </div>
                    </ul>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    messages: i18nSelectors.getMessages(state),
});

export default connect(mapStateToProps)(FooterDesktop);
