import React, { Component } from "react";
import { any, arrayOf, bool, objectOf, oneOfType, shape, string } from "prop-types";

import LapseSubOption from "pages/forms/_components/_scheduler/LapseSubOption";
import Col from "react-bootstrap/lib/Col";
import { Field } from "formik";
import { DateField } from "pages/_components/fields/DateField";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";

const FORM_ID = "scheduler";

class CommonFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool),
        errors: objectOf(string),
        nonWorkingDays: arrayOf(bool),
        lapse: shape({
            day: string,
        }).isRequired,
        frequency: string.isRequired,
        startsOn: oneOfType([string, objectOf(any)]).isRequired,
    };

    static defaultProps = {
        errors: null,
        enabledWeekDays: [],
        nonWorkingDays: [],
    };

    render() {
        const { frequency, lapse, startsOn, enabledWeekDays, nonWorkingDays, errors } = this.props;

        return (
            <>
                <Col sm={12} md={12} className="col col-12">
                    <Field
                        component={DateField}
                        idForm={FORM_ID}
                        name={frequency !== schedulerUtils.ONCE ? "startsOn" : "date"}
                        minDate={startsOn}
                        maxDate={null}
                        dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                        idField={frequency !== schedulerUtils.ONCE ? "startsOn.date" : "date.date"}
                        exceptionDatePicker
                    />
                </Col>
                {frequency !== schedulerUtils.ONCE && (
                    <div className="scheduler-frequency-suboptions">
                        <Field
                            name="lapse"
                            component={LapseSubOption}
                            enabledWeekDays={enabledWeekDays}
                            errors={errors}
                            firstWorkingDate={startsOn}
                            nonWorkingDays={nonWorkingDays}
                            value={lapse}
                            frequency={frequency}
                        />
                    </div>
                )}
            </>
        );
    }
}

export default CommonFrequencySubOption;
