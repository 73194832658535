import React, { useState } from "react";
import * as config from "util/config";
import Table from "pages/_components/Table";
import Checkbox from "pages/_components/Checkbox";
import { actions as supplierActions } from "reducers/paymentToSuppliers";
import { array, func, oneOfType } from "prop-types";
import { connect } from "react-redux";

const TableHeaderCheckbox = ({ dispatch, vouchersToDownload }) => {
    const [allBoxesChecked, setAllBoxesChecked] = useState(false);

    const multipleDownloadLimit = config.get("transactions.maxLines.to.download", 50);

    const checkAllBoxes = () => {
        const isItemChecked = !allBoxesChecked;
        if (isItemChecked) {
            const items = [];
            vouchersToDownload.map((payment) => (items.length < multipleDownloadLimit ? items.push(payment) : null));
            dispatch(supplierActions.setVouchersToDownload(items));
        } else {
            dispatch(supplierActions.setVouchersToDownload([]));
        }

        setAllBoxesChecked(isItemChecked);
    };

    return (
        <Table.HeaderData align="left">
            <Checkbox
                key="checkAll"
                name="checkAll"
                value="ALL"
                label=""
                checked={allBoxesChecked}
                onChange={checkAllBoxes}
                hideLabel
            />
        </Table.HeaderData>
    );
};

TableHeaderCheckbox.propTypes = {
    dispatch: func.isRequired,
    vouchersToDownload: oneOfType([array]).isRequired,
};

export default connect()(TableHeaderCheckbox);
