import { bool, func, shape } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as settingsSelectors } from "reducers/settings";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

import GoogleMapContainer from "pages/settings/_components/GoogleMap";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

import ReportSuspiciousActivity from "pages/communications/_components/ReportSuspiciousActivity";
import ReportSuspiciousActivityHeader from "pages/communications/_components/ReportSuspiciousActivityHeader";
import SimpleModal from "components/SimpleModal/SimpleModal";
import { goBack } from "react-router-redux";

class LastLogin extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        userData: shape({}).isRequired,
        isMobile: bool.isRequired,
    };

    state = {
        displayModal: false,
        cityToSend: "",
        countryToSend: "",
    };

    componentDidMount() {
        const { userData } = this.props;

        let cityToSend = "";
        if (userData?.previousLoginInfo && userData.previousLoginInfo?.length > 0) {
            const { city, country } = userData.previousLoginInfo[0];

            this.setState({
                cityToSend: city,
                countryToSend: country,
            });

            cityToSend = city;
        }

        if (cityToSend === "noGps" || cityToSend === "unknown") {
            this.setState({ displayModal: true });
        }
    }

    renderHeader = () => {
        const { isMobile, dispatch } = this.props;
        return <ReportSuspiciousActivityHeader dispatch={dispatch} isMobile={isMobile} origin="lastLogin" />;
    };

    hideModal = () => {
        this.setState({ displayModal: false });
    };

    renderAlert = () => {
        const { isMobile, dispatch } = this.props;

        const { cityToSend, displayModal } = this.state;

        if (cityToSend === "noGps") {
            return (
                <SimpleModal
                    dispatch={dispatch}
                    isDisplayed={displayModal}
                    modalIcon="images/warningIcon.svg"
                    modalBigTitle="settings.lastLogin.place.noGps.tittle"
                    buttonActionLabel="global.continue"
                    isDesktop={!isMobile}
                    closeModal={() => this.hideModal()}
                    customCancellationFunction={() => dispatch(goBack())}
                    buttonAction={() => this.hideModal()}>
                    <div className="ui-mt-7 ui-text-center">
                        <I18n id="settings.lastLogin.place.noGps.description" />
                    </div>
                </SimpleModal>
            );
        }

        if (cityToSend === "unknown") {
            return (
                <SimpleModal
                    isDisplayed={displayModal}
                    modalIcon="images/error.svg"
                    modalBigTitle="settings.lastLogin.place.unknown.tittle"
                    isDesktop={!isMobile}
                    showActions={false}
                    buttonAction={() => {}}
                    closeModal={() => this.hideModal()}>
                    <div className="ui-mt-7 ui-text-center">
                        <I18n id="settings.lastLogin.place.unknown.description" />
                    </div>
                </SimpleModal>
            );
        }

        return null;
    };

    render() {
        const { dispatch, userData, isMobile } = this.props;
        const { cityToSend, countryToSend } = this.state;

        return (
            <Fragment>
                {this.renderHeader()}

                {this.renderAlert()}

                <MainContainer>
                    <div className="container-center">
                        <div className="title-left-header">
                            <I18n id="settings.lastLogin.head" component="h1" />
                        </div>
                        <div>
                            <ul className="data-list inline">
                                <li className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="settings.lastLogin.date" />
                                    </span>{" "}
                                    <span className="data-desc">{`${getLastLoginDate(
                                        userData.previousLoginInfo[0],
                                    )} hs.`}</span>
                                </li>
                                <li className="data-wrapper">
                                    <span className="data-label">
                                        <I18n id="settings.lastLogin.place" />
                                    </span>{" "}
                                    <span className="data-desc">{getLastLoginPlace(cityToSend, countryToSend)}</span>
                                </li>
                            </ul>

                            <Container className="googleMap">
                                <Col className="col-12">
                                    <GoogleMapContainer positions={userData.previousLoginInfo} />
                                </Col>
                            </Container>

                            {isMobile && (
                                <>
                                    <ReportSuspiciousActivity
                                        dispatch={dispatch}
                                        isMobile={isMobile}
                                        origin="lastLogin"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    userData: sessionSelectors.getUser(state),
    errors: settingsSelectors.getErrors(state),
});

export default connect(mapStateToProps)(LastLogin);
