import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";
import { Mixpanel } from "util/clickstreaming";
import * as config from "util/config";
import * as i18n from "util/i18n";
import Yup from "yup";
import keyboardIcon from "../../../styles/images/virtual_keyboard.svg";

const FORM_ID = "login.step2";

class Step2Content extends Component {
    static propTypes = {
        isMobileNative: bool.isRequired,
        securitySeal: string.isRequired,
        securitySealAlt: string.isRequired,
        showCaptcha: bool.isRequired,
        isFetching: bool.isRequired,
        setFieldValue: func.isRequired,
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
    };

    state = {
        openVirtualKeyboard: false,
        password: "",
    };

    componentDidUpdate(prevProps) {
        const { openVirtualKeyboard } = this.state;

        if (prevProps.values.password !== this.props.values.password) {
            if (!openVirtualKeyboard) {
                /* eslint-disable react/no-did-update-set-state */
                this.setState({ password: this.props.values.password });
            }
        }
    }

    onChangeKeyboard = (e) => {
        const { setFieldValue } = this.props;
        // el componente password tiene limite de 20 caracteres, asi que por lo tanto se lo limita aca tambien

        if (e.length < 20) {
            setFieldValue("password", this.state.password.concat(e));
        }
    };

    toggleVirtualKeyboard() {
        const { openVirtualKeyboard } = this.state;
        this.setState({ openVirtualKeyboard: !openVirtualKeyboard });
    }

    render() {
        const { securitySeal, securitySealAlt, showCaptcha, isMobileNative, isDesktop, isFetching } = this.props;
        const { openVirtualKeyboard } = this.state;
        return (
            <Form className="form-content container">
                <div className="security-image">
                    <div id="figureId" className="security-image__image">
                        <figure>
                            <img
                                aria-describedby="verifyImage"
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                tabIndex="0"
                                src={securitySeal}
                                alt={i18n.get(securitySealAlt)}
                            />
                        </figure>
                    </div>

                    <div className="security-image__text">
                        <I18n id="login.step2.header" />
                        <I18n id="login.step2.verifyYourImage" component="p" />
                    </div>
                </div>

                <Field
                    idForm={FORM_ID}
                    name="password"
                    hidePlaceholder
                    pattern={config.get("core.password.pattern")}
                    copyEnabled={false}
                    component={Credential}
                    additionalClassName="ui-mb-3"
                />
                {showCaptcha && !isMobileNative && <Field idForm={FORM_ID} name="captcha" component={Captcha} />}

                <div className="form-group">
                    <Link
                        to="/recoveryPassword/step1"
                        onClick={() => Mixpanel.track("login.recoveryPassword.step1")}
                        className="recover-user small">
                        <I18n id="login.step2.forgotPassword" />
                    </Link>
                </div>

                <Button block type="submit" bsStyle="primary" label="global.continue" loading={isFetching} />

                {isDesktop && (
                    <>
                        <div aria-hidden="true" className="virtual-keyboard">
                            <img
                                src={keyboardIcon}
                                alt="Virtual keyboard"
                                onClick={() => this.toggleVirtualKeyboard()}
                            />
                            <span onClick={() => this.toggleVirtualKeyboard()}>Teclado Virtual</span>
                        </div>
                        {openVirtualKeyboard && <Keyboard onChange={this.onChangeKeyboard} />}
                    </>
                )}
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeal: loginSelectors.getSecuritySeal(state),
    securitySealAlt: loginSelectors.getSecuritySealAlt(state),
    showCaptcha: loginSelectors.getShowCaptcha(state),
    isFetching: loginSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: "",
            captcha: "",
        }),
        validationSchema: ({ isMobileNative, showCaptcha }) =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                captcha:
                    !isMobileNative && showCaptcha
                        ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`))
                        : Yup.string(),
            }),
        handleSubmit: ({ password, captcha }, formikBag) => {
            formikBag.props.dispatch(loginActions.loginStep2(password, captcha, formikBag));

            Mixpanel.track(FORM_ID);
        },
    }),
)(Step2Content);
