import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/lib/Navbar";
import classNames from "classnames";
import { push } from "react-router-redux/actions";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as communicationsSelectors } from "reducers/communications";
import { actions as notificationActions } from "reducers/notification";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";
import { selectors as i18nSelectors } from "reducers/i18n";
import { get as getMessage } from "util/i18n";
import * as config from "util/config";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import UserLink from "pages/_components/UserLink";
import Badge from "pages/_components/Badge";
import Dropdown from "pages/_components/Dropdown";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import Popup from "pages/_components/Popup";
import Avatar from "pages/_components/Avatar";

class NavigationBarDesktop extends React.Component {
    static propTypes = {
        activeEnvironment: shape({
            administrationScheme: string,
            forms: shape({}),
            id: number,
            name: string,
            permissions: shape({
                accounts: bool,
                creditcards: bool,
                loans: bool,
                payCreditCard: bool,
                payLoan: bool,
                payThirdPartiesCreditCard: bool,
                payThirdPartiesLoan: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            type: string,
        }),
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        unreadCommunications: number,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        activeEnvironment: null,
        environments: null,
        loggedUser: null,
        unreadCommunications: 0,
    };

    state = {
        communications: 0,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    goToEnvironment = (idEnvironment) => {
        const { dispatch, environments } = this.props;
        if (environments[idEnvironment].allowedToAccess === "true") {
            dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
        } else {
            dispatch(
                notificationActions.showNotification(
                    getMessage("administration.restrictions.unavailableEnvironment"),
                    "error",
                    ["desktop"],
                ),
            );
            dispatch(push("/desktop"));
        }
    };

    getLastLoginInfo = (toggleOpen) => {
        const { loggedUser } = this.props;
        const { previousLoginInfo } = loggedUser;

        if (previousLoginInfo && previousLoginInfo.length) {
            return (
                <>
                    <div className="last-login" onClick={toggleOpen}>
                        <I18n id="settings.lastLogin.date" />
                        <span>{getLastLoginDate(previousLoginInfo[0])}</span>
                        <Link to="/settings/lastLogin">
                            {getLastLoginPlace(previousLoginInfo[0].city, previousLoginInfo[0].country)}
                        </Link>
                    </div>
                </>
            );
        }
        return null;
    };

    onMailboxAnimationEnd = () => {
        const { unreadCommunications } = this.props;
        this.setState({ communications: unreadCommunications });
    };

    render() {
        const { environments, activeEnvironment, loggedUser, unreadCommunications, fetching } = this.props;
        const { communications } = this.state;
        const hasToAnimateMailBox = communications === unreadCommunications;

        return (
            <Navbar collapseOnSelect fluid className="navbar-shadow">
                <Navbar.Header>
                    <div>
                        <Navbar.Brand>
                            <Link className="navbar-brand logo-navbar" to="/desktop">
                                <Image src="images/logoMarivaHome.svg" />

                                <I18n id="global.companyName" componentProps={{ className: "visually-hidden" }} />
                            </Link>
                        </Navbar.Brand>

                        <ul className="nav navbar-nav navbar-right header-command-pallette">
                            {environments && Object.keys(environments).length > 1 ? (
                                <li className="enviroments-item header-list">
                                    <Dropdown
                                        buttonClass="header-dropdown-button header-btn"
                                        dropdownButtonContent={
                                            <EnvironmentTag
                                                type={activeEnvironment?.type}
                                                name={activeEnvironment?.name.substring(0, 40)}
                                                showIcon={false}
                                            />
                                        }
                                        pullRight
                                        fetching={fetching}>
                                        {Object.values(environments)
                                            .filter(
                                                (environment) =>
                                                    parseInt(environment.idEnvironment, 10) !== activeEnvironment.id,
                                            )
                                            .map((environment) => (
                                                <div
                                                    key={environment.idEnvironment}
                                                    className="header-dropdown-item"
                                                    onClick={() => this.goToEnvironment(environment.idEnvironment)}>
                                                    <EnvironmentTag
                                                        type={environment.environmentType}
                                                        name={environment.name.substring(0, 40)}
                                                        showIcon={false}
                                                    />
                                                </div>
                                            ))}
                                    </Dropdown>
                                </li>
                            ) : (
                                <li className="enviroments-item header-list">
                                    <div className="header-dropdown-button btn single-environment">
                                        <EnvironmentTag
                                            type={activeEnvironment.type}
                                            name={activeEnvironment.name.substring(0, 40)}
                                            showIcon={false}
                                        />
                                    </div>
                                </li>
                            )}

                            <li className="enviroments-item header-list">
                                <Popup
                                    popupTitleID="menu.settings.panelTitle"
                                    popupButtonContent={
                                        <>
                                            <Avatar user={loggedUser} />
                                            <I18n id="menu.settings" />
                                        </>
                                    }>
                                    <UserLink loggedUser={loggedUser} getLastLoginInfo={this.getLastLoginInfo} />
                                </Popup>
                            </li>
                            <li className="enviroments-item header-list">
                                <Link to="/communications" className="header-link">
                                    <div>
                                        <div className="rounder-content">
                                            {unreadCommunications > 0 && (
                                                <>
                                                    <Badge
                                                        count={unreadCommunications}
                                                        className={classNames({ animation: !hasToAnimateMailBox })}
                                                    />
                                                    <I18n
                                                        id="menu.chat.badge"
                                                        componentProps={{ className: "visually-hidden" }}
                                                    />
                                                </>
                                            )}
                                            <Image className="svg-icon" src="images/ui-icons/ui-header-email.svg" />
                                        </div>
                                    </div>
                                    <I18n id="menu.chat" />
                                </Link>
                            </li>
                            <li className="enviroments-item header-list">
                                <Link
                                    to={{ pathname: config.get("client.helpURL", "#") }}
                                    target="_blank"
                                    className="header-link">
                                    <div>
                                        <div className="rounder-content">
                                            <Image className="svg-icon" src="images/ui-icons/ui-header-help.svg" />
                                        </div>
                                    </div>
                                    <I18n id="menu.help" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Navbar.Header>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    lang: i18nSelectors.getLang(state),
    loggedUser: sessionSelectors.getUser(state),
    fetching: sessionSelectors.isFetching(state),
    unreadCommunications: communicationsSelectors.getUnreadCommunications(state),
});

export default connect(mapStateToProps)(NavigationBarDesktop);
