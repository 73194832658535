import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

class ExpiredByMaxAtemps extends Component {
    send = () => {
        this.props.dispatch(push("/"));
    };

    getContent = () => (
        <div className="above-the-fold container login-desktop-wrapper">
            <p className="text-lead mt-10">
                <I18n id="session.expiredByMaxAtemps.title" />
            </p>
            <Button
                label="session.expiredByMaxAtemps.loginAgain"
                loading={false}
                onClick={this.send}
                type="button"
                bsStyle="primary"
            />
        </div>
    );

    render() {
        const mainContainerClass = classNames({
            "main-container": true,
            "container-fluid": this.props.isDesktop,
        });

        return (
            <Fragment>
                <MainContainer className={mainContainerClass}>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(ExpiredByMaxAtemps);
