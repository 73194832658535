import React, { Component } from "react";
import { string, shape } from "prop-types";
import * as i18n from "util/i18n";

class DetailHeadTitle extends Component {
    static propTypes = {
        deposit: shape({
            idProduct: string,
            productType: string.isRequired,
            productAlias: string,
            label: string,
            shortLabel: string,
            number: string.isRequired,
            currency: string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    render() {
        const { deposit } = this.props;
        const { operationCode, productAlias, productType, currency } = deposit;

        return (
            <>
                <div className="title-left-header">
                    <div className="visually-hidden" ref={this.titleRef}>
                        <span>{`${productType} ${currency}, ${productAlias || operationCode}`}</span>
                    </div>

                    <h1 className="ellipsis">{i18n.get("fixedTermDeposit.detail.title")}</h1>
                </div>
            </>
        );
    }
}

export default DetailHeadTitle;
