import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    RECEIPTS_COMEX_LIST_REQUEST: "comex/RECEIPTS_COMEX_LIST_REQUEST",
    RECEIPTS_COMEX_LIST_SUCCESS: "comex/RECEIPTS_COMEX_LIST_SUCCESS",
    RECEIPTS_COMEX_LIST_FAILURE: "comex/RECEIPTS_COMEX_LIST_FAILURE",

    LOAD_MORE_RECEIPTS_COMEX_LIST_REQUEST: "comex/LOAD_MORE_RECEIPTS_COMEX_LIST_REQUEST",
    LOAD_MORE_RECEIPTS_COMEX_LIST_SUCCESS: "comex/LOAD_MORE_RECEIPTS_COMEX_LIST_SUCCESS",
    LOAD_MORE_RECEIPTS_COMEX_LIST_FAILURE: "comex/LOAD_MORE_RECEIPTS_COMEX_LIST_FAILURE",

    DOWNLOAD_RECEIPTS_COMEX_REQUEST: "comex/DOWNLOAD_RECEIPTS_COMEX_REQUEST",
    DOWNLOAD_RECEIPTS_COMEX_SUCCESS: "comex/DOWNLOAD_RECEIPTS_COMEX_SUCCESS",
    DOWNLOAD_RECEIPTS_COMEX_FAILURE: "comex/DOWNLOAD_RECEIPTS_COMEX_FAILURE",

    SET_RESET_QUERY: "comex/SET_RESET_QUERY",
    RESET_FILTERS: "comex/RESET_FILTERS",
    TOGGLE_SHOW_FILTERS: "comex/TOGGLE_SHOW_FILTERS",
    RESET_CHECKS: "comex/RESET_CHECKS",
    SET_FILTERS: "comex/SET_FILTERS",
};

export const INITIAL_STATE = {
    receiptsList: [],
    isFetching: false,
    totalPages: "",
    filters: { pageNumber: 1 },
    isFirstFetching: false,
    moreReceipts: false,
    resetQueryOnMount: true,
    showFilters: false,
    isDownloadingFile: false,
};

export default createReducer(INITIAL_STATE, {
    [types.RECEIPTS_COMEX_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: {
            ...state.filters,
            ...action.filters,
        },
        isFirstFetching: true,
    }),
    [types.RECEIPTS_COMEX_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        receiptsList: action.receiptsList,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        totalPages: action.totalPages,
        moreReceipts: action.moreReceipts,
        isFirstFetching: false,
    }),
    [types.RECEIPTS_COMEX_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SET_RESET_QUERY]: (state, action) => ({
        ...state,
        resetQueryOnMount: action.reset,
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
        showFilters: INITIAL_STATE.showFilters,
    }),
    [types.TOGGLE_SHOW_FILTERS]: (state) => ({
        ...state,
        showFilters: !state.showFilters,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: {
            ...state.filters,
            ...action.filters,
        },
    }),
    [types.DOWNLOAD_RECEIPTS_COMEX_REQUEST]: (state) => ({
        ...state,
        isDownloadingFile: true,
    }),
    [types.DOWNLOAD_RECEIPTS_COMEX_SUCCESS]: (state) => ({
        ...state,
        isDownloadingFile: false,
    }),
    [types.DOWNLOAD_RECEIPTS_COMEX_FAILURE]: (state) => ({
        ...state,
        isDownloadingFile: false,
    }),
    [types.LOAD_MORE_RECEIPTS_COMEX_LIST_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_RECEIPTS_COMEX_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        receiptsList: state.receiptsList ? state.receiptsList.concat(action?.receiptsList) : action?.receiptsList,
        hasMoreData: action.pageNumber < action.totalPages,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        moreReceipts: action.moreReceipts,
    }),
});

export const actions = {
    receiptsComexListRequest: makeActionCreator(types.RECEIPTS_COMEX_LIST_REQUEST, "filters"),
    receiptsComexListSuccess: makeActionCreator(
        types.RECEIPTS_COMEX_LIST_SUCCESS,
        "receiptsList",
        "pageNumber",
        "totalPages",
        "moreReceipts",
    ),
    receiptsComexListFailure: makeActionCreator(types.RECEIPTS_COMEX_LIST_FAILURE),

    downloadReceiptsComexRequest: makeActionCreator(types.DOWNLOAD_RECEIPTS_COMEX_REQUEST, "path", "fileName"),
    downloadReceiptsComexSuccess: makeActionCreator(types.DOWNLOAD_RECEIPTS_COMEX_SUCCESS),
    downloadReceiptsComexFailure: makeActionCreator(types.DOWNLOAD_RECEIPTS_COMEX_FAILURE),

    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    setResetQuery: makeActionCreator(types.SET_RESET_QUERY, "reset"),
    resetFilters: makeActionCreator(types.RESET_FILTERS),
    toggleFilters: makeActionCreator(types.TOGGLE_SHOW_FILTERS),

    loadMoreReceiptsComexListRequest: makeActionCreator(types.LOAD_MORE_RECEIPTS_COMEX_LIST_REQUEST, "filters"),
    loadMoreReceiptsComexListSuccess: makeActionCreator(
        types.LOAD_MORE_RECEIPTS_COMEX_LIST_SUCCESS,
        "receiptsList",
        "pageNumber",
        "totalPages",
        "moreReceipts",
    ),
    loadMoreReceiptsComexListFailure: makeActionCreator(types.LOAD_MORE_RECEIPTS_COMEX_LIST_FAILURE),
};

export const selectors = {
    getReceipts: ({ comex }) => comex.receiptsList,
    getFetching: ({ comex }) => comex.isFetching,
    getFilters: ({ comex }) => comex.filters,
    getPageNumber: ({ comex }) => comex.filters.pageNumber,
    getTotalPages: ({ comex }) => comex.totalPages,
    getMoreReceipts: ({ comex }) => comex.moreReceipts,
    getFirstFetched: ({ comex }) => comex.isFirstFetching,
    getHasMoreData: ({ comex }) => comex.hasMoreData,
    isResetQuery: ({ comex }) => comex.resetQueryOnMount,
    getShowFilters: ({ comex }) => comex.showFilters,
    isDownloadingFile: ({ comex }) => comex.isDownloadingFile,
};
