import React, { Component } from "react";
import { func, arrayOf, string, bool } from "prop-types";

import Dropdown from "pages/_components/Dropdown";
import Button from "pages/_components/Button";

class DownloadDropdown extends Component {
    static propTypes = {
        onDownloadClick: func.isRequired,
        formats: arrayOf(string).isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    render() {
        const { onDownloadClick, formats, fetching } = this.props;
        return (
            <Dropdown
                image="images/download.svg"
                label="global.download"
                buttonClass="btn btn-outline"
                fetching={fetching}
                pullRight>
                {formats.map((format) => (
                    <Button
                        label={`global.download.${format}`}
                        onClick={() => onDownloadClick(format)}
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                ))}
            </Dropdown>
        );
    }
}

export default DownloadDropdown;
