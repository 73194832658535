import React from "react";
import { bool, string, func, node } from "prop-types";
import { Modal } from "react-bootstrap";

import * as i18n from "util/i18n";

import Image from "../Image";

import "./_bigModal.scss";

const BigModal = ({
    children,
    className,
    handleShowModal,
    modalFooter,
    isMobile,
    modalImage,
    modalTitle,
    modalWidth,
    showModal,
}) => {
    BigModal.propTypes = {
        children: node,
        className: string,
        handleShowModal: func.isRequired,
        isMobile: bool.isRequired,
        modalFooter: node,
        modalImage: string,
        modalTitle: string,
        modalWidth: string,
        showModal: bool.isRequired,
    };

    BigModal.defaultProps = {
        children: null,
        className: "",
        modalFooter: null,
        modalWidth: "548px",
        modalImage: null,
        modalTitle: null,
    };

    return (
        <Modal show={showModal} dialogClassName="bigModal" className={className}>
            <div className="bigModal-container" style={{ width: `${!isMobile ? modalWidth : null}` }}>
                <div className="bigModal-header">
                    <button type="submit" className="bigModal-header__btnClose" onClick={handleShowModal}>
                        <Image src="images/cross.svg" />
                    </button>
                </div>
                <div className="bigModal-content">
                    {modalImage && (
                        <div className="modal-logo mt-1">
                            <Image src={modalImage} />
                        </div>
                    )}
                    {modalTitle && <h3 className="bigModal-content__title">{i18n.get(modalTitle)}</h3>}
                    {children}
                </div>
                {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
            </div>
        </Modal>
    );
};

export default BigModal;
