import React, { Component } from "react";
import { string, bool, func } from "prop-types";
import Image from "pages/_components/Image";

class Checkbox extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: string.isRequired,
        controlStyle: string,
        value: string.isRequired,
        image: string,
        onChange: func.isRequired,
        name: string,
        link: string,
        nameLink: string,
        disabled: bool,
        hideLabel: bool,
    };

    static defaultProps = {
        controlStyle: "checkbox",
        image: "images/check.svg",
        name: "",
        link: "",
        nameLink: "",
        disabled: false,
        hideLabel: false,
    };

    handleClick = () => {
        const { onChange, value } = this.props;
        onChange(value);
    };

    renderLabelLink() {
        const { label, link, nameLink } = this.props;
        const linkIndex = label.indexOf(nameLink);

        if (linkIndex === -1) {
            return "";
        }

        const preLink = `${label.slice(0, linkIndex)} `;
        const postLink = ` ${label.slice(linkIndex + 1 + nameLink.length)}`;

        return (
            <div>
                {preLink}
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {nameLink}
                </a>
                {postLink}
            </div>
        );
    }

    render() {
        const { controlStyle, value, label, checked, image, name, link, disabled, hideLabel } = this.props;
        const uniqueId = `${name}--${label.replace(/ /gm, "_")}`;
        return (
            <div
                className={`c-control c-control--has-icon c-control--checkbox c-control-block ${
                    link ? "c-control-has-link" : ""
                } ${disabled && "c-control--checkbox-disabled"}`}>
                <input
                    readOnly
                    className="c-control-input"
                    type="checkbox"
                    value={value}
                    checked={checked}
                    id={uniqueId}
                    onClick={disabled ? null : this.handleClick}
                    name={name}
                    disabled={disabled}
                />
                <label className="c-control-label" htmlFor={uniqueId}>
                    {controlStyle === ("checkbox" || "radio") && (
                        <div className="c-control-icons">
                            <div className="c-control-mark c-control-icon-checkbox">
                                <Image src={image} className="svg-icon svg-caret" />
                            </div>
                        </div>
                    )}
                    {!link && !hideLabel && <div className="form-group-text">{label} </div>}
                    {link && <>{this.renderLabelLink()}</>}
                </label>
            </div>
        );
    }
}

export default Checkbox;
