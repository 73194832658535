import React, { Component } from "react";
import { string } from "prop-types";

import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";
import I18n from "pages/_components/I18n";
import * as utilDownload from "util/download";

class MultipleEcheqsTicketHeader extends Component {
    static propTypes = {
        transactionStatus: string.isRequired,
        customHeaderMessage: string,
    };

    static defaultProps = {
        customHeaderMessage: null,
    };

    handleClickDownloadPdf = (fileNameKey) => {
        utilDownload.downloadLink(fileNameKey, "/pdf/overdraft.pdf");
    };

    render() {
        const { transactionStatus, customHeaderMessage } = this.props;

        return (
            <div className="transaction-ticket__default-header">
                <figure>
                    <TransactionStatus iconFidelity="ticket" idTransactionStatus={transactionStatus} />
                </figure>

                <I18n id={`forms.transaction.ticket.status.${transactionStatus}`} />
                {customHeaderMessage && <span className="ui-fw-regular">{customHeaderMessage}</span>}
            </div>
        );
    }
}

export default MultipleEcheqsTicketHeader;
