import React, { Component } from "react";
import { string, number as numberType, bool, oneOfType, number } from "prop-types";

import * as i18nUtils from "util/i18n";

import ListItem from "pages/_components/listItem/ListItem";

class FixedTermDepositsListItem extends Component {
    static propTypes = {
        number: string.isRequired,
        productAlias: string,
        idProduct: string,
        productType: string.isRequired,
        paymentAmount: numberType,
        nextDueDate: string,
        numberOfFees: numberType,
        numberOfPaidFees: numberType,
        operationCode: string.isRequired,
        nextExpiration: string,
        accountNumber: string.isRequired,
        descriptionSIOC: string.isRequired,
        currencyDescription: string,
        currency: string.isRequired,
        amount: numberType.isRequired,
        dueDate: string.isRequired,
        showPayment: bool,
        showLink: bool,
        operationBranch: string,
        operationOrder: oneOfType([string, number]).isRequired,
    };

    static defaultProps = {
        productAlias: null,
        idProduct: null,
        showPayment: false,
        showLink: true,
        operationBranch: "",
        currencyDescription: "",
        paymentAmount: null,
        nextDueDate: "",
        nextExpiration: "",
        numberOfPaidFees: null,
        numberOfFees: null,
    };

    render() {
        const {
            productAlias,
            operationCode,
            idProduct,
            operationOrder,
            descriptionSIOC,
            currency,
            amount,
            dueDate,
            showLink,
            ...props
        } = this.props;

        const productName = productAlias || operationCode;

        const finalPath = {
            pathname: `/fixedTermDeposits/${idProduct}/${operationOrder}`,
        };

        return (
            <ListItem
                {...props}
                idProduct={idProduct}
                title={descriptionSIOC}
                name={productName}
                currency={currency}
                productNumber={operationCode}
                amountLabel={i18nUtils.get("desktop.widgets.fixedTermDeposits.amount.label")}
                expirationText={i18nUtils.get("desktop.widgets.fixedTermDeposits.expirationDate.label")}
                amount={amount}
                expirationDate={dueDate}
                path={showLink ? finalPath : null}
                isDepositsWidget
            />
        );
    }
}

export default FixedTermDepositsListItem;
