import React, { useEffect, useState } from "react";
import { bool, func, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "formik";
import { goBack, replace } from "react-router-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as formSelectors, actions as formActions } from "../../../../reducers/form";
import typeSelector from "../../../../reducers/types/form";
import { selectors as i18nSelectors } from "../../../../reducers/i18n";
import FormTransition from "../../_components/FormTransition";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import { selectors as echeqsSelectors } from "../../../../reducers/echeqs";
import { selectors as sessionSelectors } from "../../../../reducers/session";
import I18n from "../../../_components/I18n";
import FormattedAmount from "../../../_components/FormattedAmount";
import FormattedDate from "../../../_components/FormattedDate";
import DisclaimerContainer from "../../../../components/DisclaimerContainer/DisclaimerContainer";
import * as i18nUtils from "../../../../util/i18n";
import Alert from "../../../_components/Alert/Alert";
import CustomerEcheqData from "./_components/CustomerEcheqData";

const ID_ACTIVITY = "echeqs.requestGuarantee.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");
const RequestGuarantee = (props) => {
    const {
        mode,
        dispatch,
        currentLang,
        fromBackoffice,
        transaction,
        data: dataTrx,
        data,
        isMobile,
        echeq,
        location,
        bankedCustomer,
        errors,
    } = props;

    const echeqSelected = dataTrx?.echeqSelected ? dataTrx?.echeqSelected : echeq;
    const guarantorInfo = dataTrx?.guarantor ?? bankedCustomer;
    const guarantorCharacter = i18nUtils.get(`${ID_FORM}.character.text`);
    const [guarantorData, setGuarantorData] = useState(
        data?.guarantor ?? {
            documento_tipo: "",
            documento: "",
        },
    );

    useEffect(() => {
        if (Object.keys(bankedCustomer).length) {
            setGuarantorData({
                documento_tipo: bankedCustomer.documento_tipo,
                documento: bankedCustomer.documento,
                nombre: bankedCustomer.nombre,
            });
        }
    }, [bankedCustomer]);

    useEffect(() => {
        if (mode === "edit") {
            if ((echeqSelected && Object.keys(echeqSelected).length > 0) || Object.keys(dataTrx)?.length > 0) {
                dispatch({
                    type: typeSelector.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch({
                type: typeSelector.PRE_FORM_SUCCESS,
            });
        }
    }, [dispatch, location]);

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const idTransaction = transaction?.idTransaction;

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        ...genericProps,
        mode: "edit",
    };

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));

    const handleBack = () => dispatch(replace("/echeqs"));

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="128px">
                    <I18n id={`${ID_FORM}.termsAndCondition.title`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
                additionalClassName="ui-mt-8"
                values={data?.termsAndConditions || false}
            />
        </>
    );

    const renderFields = (setFieldValue) => {
        function searchSuccessAction(bankedCustomerInfoData) {
            setFieldValue("bankedCustomerInfo", bankedCustomerInfoData);
        }

        function getDataFromComponent(searchInfo) {
            setGuarantorData({
                documento_tipo: searchInfo.documentType,
                documento: searchInfo.documentNumber,
            });
        }

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mb-7">
                            <I18n id="forms.echeqs.requestGuarantee.textInfo.label" />
                        </Alert>
                        <div className="form-text-info ui-mb-7">
                            <I18n id="forms.echeqs.requestGuarantee.message.label" />
                        </div>
                    </>
                )}

                <div className="data-wrapper">
                    <I18n
                        id="forms.echeqs.requestGuarantee.echeqInfo.section.title"
                        componentProps={{ className: "data-label ui-fw-bold" }}
                    />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_id}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkNo.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_numero}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.issuerCuit.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.emisor_cuit}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.transmitter.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.emisor_razon_social}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.endoserCuit.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.tenencia_beneficiario_documento}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.endoser.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.tenencia_beneficiario_nombre}</span>
                </div>

                {mode === "edit" && (
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                        <span>{echeqSelected?.estado}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                    <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.concept.label`} componentProps={{ className: "data-label" }} />
                    {echeqSelected?.cheque_concepto && (
                        <I18n
                            id={`forms.transfers.concepts.${echeqSelected.cheque_concepto}.label`}
                            defaultValue={echeqSelected.cheque_concepto}
                        />
                    )}
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                    <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
                </div>

                {Object.keys(guarantorInfo).length > 0 ? (
                    <>
                        <hr className="ui-mt-7 ui-mb-7" />

                        <I18n
                            id={`${ID_FORM}.section.guarantor.title`}
                            componentProps={{ className: "data-label ui-fw-bold" }}
                        />

                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.guarantorName.label`} componentProps={{ className: "data-label" }} />
                            <span>{guarantorInfo?.nombre || guarantorInfo?.aval_nombre}</span>
                        </div>

                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.document.label`} componentProps={{ className: "data-label" }} />
                            <span>{`${guarantorInfo?.documento_tipo ||
                                guarantorInfo?.aval_documento_tipo} ${guarantorInfo?.documento ||
                                guarantorInfo?.aval_documento}`}</span>
                        </div>

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="home"
                            name="home"
                            idField="home"
                        />

                        <hr className={`${mode === "edit" ? "ui-mt-0" : "ui-mt-7"} ui-mb-7`} />
                    </>
                ) : (
                    <>
                        {mode === "edit" && (
                            <>
                                <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-2" : "ui-mb-7"}`} />
                                <div className="data-wrapper">
                                    <I18n
                                        id={`${ID_FORM}.section.guarantor.title`}
                                        componentProps={{ className: "data-label ui-fw-bold" }}
                                    />
                                </div>

                                <CustomerEcheqData
                                    idActivity={ID_ACTIVITY}
                                    genericProps={genericProps}
                                    isMobile={isMobile}
                                    searchSuccessAction={searchSuccessAction}
                                    data={data}
                                    buttonLabel={`${ID_FORM}.consult.label`}
                                    sendDataBack={getDataFromComponent}
                                />

                                <hr className="ui-mt-0 ui-mb-7" />
                            </>
                        )}
                    </>
                )}

                <I18n id={`${ID_FORM}.section.endorsement.title`} componentProps={{ className: "data-label" }} />

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                    <span>{guarantorCharacter}</span>
                </div>
            </>
        );
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            holder: {
                name: echeqSelected?.tenencia_beneficiario_nombre,
                documentNumber: echeqSelected?.tenencia_beneficiario_documento,
                documentType: echeqSelected?.tenencia_beneficiario_documento_tipo,
            },
            guarantor: {
                aval_importe_avalado: echeqSelected?.monto,
                aval_nombre: guarantorData.nombre,
                aval_documento_tipo: guarantorData.documento_tipo,
                aval_documento: guarantorData.documento,
                aval_sujeto_avalado: echeqSelected?.tenencia_beneficiario_nombre,
                aval_caracter: guarantorCharacter,
            },
            amount: {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
            chequeId: echeqSelected?.cheque_id,
            echeqSelected,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        renderEditableInPreview,
        handleClickDownload,
        handleBack,
        returnBack: true,
        errorsAux: errors,
    };

    return <FormTransition {...props} {...formProps} />;
};

RequestGuarantee.defaultProps = {
    fromBackoffice: false,
    preData: null,
    isMobile: false,
};

RequestGuarantee.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    bankedCustomer: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    isMobile: bool,
    errors: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    preData: formSelectors.getPreData(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    data: formSelectors.getData(state),
    bankedCustomer: echeqsSelectors.getBankedCustomerInfo(state),
    fetching: formSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    currentLang: i18nSelectors.getLang(state),
    errors: echeqsSelectors.getErrors(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestGuarantee);
