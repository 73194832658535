import SimpleModal from "components/SimpleModal/SimpleModal";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as qrActions, selectors as qrSelectors } from "reducers/qr";
import AccountQRComponent from "./_components/AccountQR";

const AccountQRModal = ({ qrBase64, isVisible, onHide, fetchingDownload, cbuAccount, dispatch }) =>
    !fetchingDownload && (
        <SimpleModal
            block
            buttonAction={() => dispatch(qrActions.downloadQrAccountRequest(qrBase64, cbuAccount))}
            buttonActionLabel="global.download"
            buttonCancelLabel="global.close"
            className="qr-modal modal-bg-white"
            closeButton={false}
            closeModal={onHide}
            isDisplayed={isVisible}>
            <AccountQRComponent urlQR={qrBase64} />
        </SimpleModal>
    );
AccountQRModal.propTypes = {
    cbuAccount: string,
    dispatch: func.isRequired,
    fetchingDownload: bool,
    isVisible: bool,
    onHide: func,
    qrBase64: string,
};
AccountQRModal.defaultProps = {
    cbuAccount: null,
    fetchingDownload: false,
    isVisible: false,
    onHide: null,
    qrBase64: "",
};

const mapStateToProps = (state) => ({
    fetchingDownload: qrSelectors.getFetchingDownloadQR(state),
    qrBase64: qrSelectors.getQrAccountBase64(state),
});

export default connect(mapStateToProps)(AccountQRModal);
