import { types, actions, selectors } from "reducers/filters";
import { put, select, takeLatest } from "redux-saga/effects";

const sagas = [takeLatest(types.VALIDATE_TO_RESET_FILTERS, validateToResetFilters)];

export default sagas;

function* validateToResetFilters({ filterToReset, idActivity }) {
    const isResetQuery = yield select(selectors.isResetFilters);
    const idActivityOnFilter = yield select(selectors.getIdActivityOnFilter);

    if (isResetQuery || idActivityOnFilter !== idActivity) {
        yield put({ type: filterToReset });
        yield put(actions.resetAllFilters());
    }

    yield put(actions.setResetFilters(true, idActivity));
}
