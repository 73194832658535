import { call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE } from "util/responses.js";
import { types, actions } from "reducers/administration/common/groupFormData";
import { types as sessionTypes } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import { permissionsActions } from "reducers/administration/advanced";
import * as administrationGroups from "middleware/administration/advanced";
import * as form from "middleware/form";
import { credentialsToUnderscoreFormat } from "util/form.js";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LOAD_GROUP_FORM_DATA_REQUEST, loadGroupFormDataRequest),
    takeLatest(types.SUBMIT_GROUP_FORM_PREVIEW_REQUEST, submitGroupFormPreviewRequest),
    takeLatest(types.SUBMIT_GROUP_FORM_REQUEST, submitGroupFormRequest),
];

export default sagas;

const errorsKey = {
    noField: "NO_FIELD",
};

function* loadGroupFormDataRequest({ id }) {
    const response = yield call(administrationGroups.loadGroupFormDataRequest, id);

    if (response.type === "W") {
        yield put(actions.loadGroupFormDataFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        const { group, permissions, isAdminGroup, availableUsers, adminUsers } = response.data.data;

        yield put(actions.loadGroupFormDataSuccess(group, permissions, adminUsers, isAdminGroup, availableUsers));
        yield put(permissionsActions.loadPermissionsSuccess(response.data.data));
    }
}

function* submitGroupFormPreviewRequest({ formData, formikBag, idGroup }) {
    const response = yield call(administrationGroups.submitGroupFormPreviewRequest, formData, idGroup, formikBag);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response.type === "W") {
        const errorMessage = response.data.data[errorsKey.noField] || i18n.get("global.unexpectedError");
        if (formikBag) {
            formikBag.setErrors(response.data.data);
        }
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
    } else {
        const pathname = yield select(({ router }) => router.location.pathname);

        if (idGroup) {
            yield put(routerActions.push(pathname.split(idGroup).join(`${idGroup}/confirm`)));
        } else {
            yield put(routerActions.push(pathname.split("create").join("create/confirm")));
        }
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.advanced.group.create.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;
        yield put(actions.submitGroupFormPreviewSuccess(credentialGroups));
    }
}

function* submitGroupFormRequest({ formData, formikBag, idGroup }) {
    const { name, description, permissions, users, status, ...credentials } = formData;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    const response = yield call(
        administrationGroups.submitGroupFormRequest,
        { name, description, permissions, users, status, ...credentialsWithUnderscore },
        idGroup,
    );

    formikBag.setSubmitting(false);

    if (response.type === "W") {
        const errorMessage = response.data.data[errorsKey.noField] || i18n.get("global.unexpectedError");

        formikBag.setErrors(response.data.data);
        yield put(notificationActions.showNotification(errorMessage, "error", ["administration"]));
    } else {
        const successMesage = response.data.message;
        if (response.data.code === ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE) {
            yield put(notificationActions.showNotification(successMesage, "success", ["transactions"]));
            yield put(routerActions.replace("/transactions/list"));
        } else {
            yield put(notificationActions.showNotification(successMesage, "success", ["administration"]));
            yield put(routerActions.replace(`/administration/advanced/group/${response.data.data.idGroup}/details`));
            yield put({
                type: sessionTypes.UPDATE_PERMISSIONS,
                permissions: response.data.data.permissions,
            });
            yield put(actions.submitGroupFormSuccess());
        }
    }
}
