import React from "react";
import GenericItem from "../../../components/GenericItem/GenericItem";
import I18n from "../../_components/I18n";
import * as i18nUtils from "../../../util/i18n";
import Table from "../../_components/Table";
import Dropdown from "../../_components/Dropdown";
import Button from "../../_components/Button";

const AccountItem = (props) => {
    const { ACTIVITY_ID, account, isDesktop, handleOnClick } = props;

    const { currency, formattedAccountNumber, productAlias, productType, status } = account;

    const currencyDescription = i18nUtils.get(`currency.name.${currency}`);

    const webContent = (
        <>
            <Table.Data align="left">
                <span className="data-text">{productAlias || i18nUtils.get(`${ACTIVITY_ID}.noAlias.label`)}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className="data-text">{i18nUtils.get(`productType.${productType}`)}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className="data-text">{formattedAccountNumber}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className="data-text">{currencyDescription}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className={`data-text ${Number(status?.statusCode) ? "ui-text-success" : "ui-text-error"}`}>
                    {status?.shortDescription.toUpperCase()}
                </span>
            </Table.Data>

            <Table.Data className="table-data-icon" align="right">
                <Dropdown
                    image={`images/ui-icons/${!isDesktop ? "ui-dropdown-mobile.svg" : "ui-dropdown.svg"}`}
                    label=""
                    bsStyle="link"
                    className="btn-small"
                    pullRight>
                    {status?.statusCode === 0
                        ? [
                              <Button
                                  key="echeqs.managementAccounts.list.action.enabled.label"
                                  label="echeqs.managementAccounts.list.action.enabled.label"
                                  className="dropdown__item-btn"
                                  bsStyle="link"
                                  onClick={() => handleOnClick(account, "activate")}
                              />,
                          ]
                        : [
                              <Button
                                  key="echeqs.managementAccounts.list.action.disabled.label"
                                  label="echeqs.managementAccounts.list.action.disabled.label"
                                  className="dropdown__item-btn"
                                  onClick={() => handleOnClick(account, "deactivate")}
                                  bsStyle="link"
                              />,
                          ]}
                </Dropdown>
            </Table.Data>
        </>
    );

    const mobileContent = (
        <GenericItem
            hoverBehavior={false}
            options={
                <Dropdown
                    image="images/ui-icons/ui-dropdown-mobile.svg"
                    label=""
                    bsStyle="link"
                    className="btn-small"
                    pullRight>
                    {status?.statusCode === 0
                        ? [
                              <Button
                                  key="echeqs.managementAccounts.list.action.enabled.label"
                                  label="echeqs.managementAccounts.list.action.enabled.label"
                                  className="dropdown__item-btn"
                                  bsStyle="link"
                                  onClick={() => handleOnClick(account, "activate")}
                              />,
                          ]
                        : [
                              <Button
                                  key="echeqs.managementAccounts.list.action.disabled.label"
                                  label="echeqs.managementAccounts.list.action.disabled.label"
                                  className="dropdown__item-btn"
                                  onClick={() => handleOnClick(account, "deactivate")}
                                  bsStyle="link"
                              />,
                          ]}
                </Dropdown>
            }>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${ACTIVITY_ID}.head.alias.label`} />
                </div>
                <span className="data-text">{productAlias || i18nUtils.get(`${ACTIVITY_ID}.noAlias.label`)}</span>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${ACTIVITY_ID}.head.type.label`} />
                </div>
                <div className="data-text">{i18nUtils.get(`productType.${productType}`)}</div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${ACTIVITY_ID}.head.accountNumber.label`} />
                </div>
                <div className="data-text">{formattedAccountNumber}</div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${ACTIVITY_ID}.head.amount.label`} />
                </div>
                <div className="data-amount">
                    <span className="data-text">{currencyDescription}</span>
                </div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${ACTIVITY_ID}.head.status.label`} />
                </div>
                <div className={`data-text ${Number(status?.statusCode) ? "ui-text-success" : "ui-text-error"}`}>
                    {status?.shortDescription}
                </div>
            </div>
        </GenericItem>
    );

    return isDesktop ? webContent : mobileContent;
};

export default AccountItem;
