import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";

export const getFrequentRecipients = (pageNumber, nameSearch) =>
    API.executeWithAccessToken("frequentdestinations.list", { pageNumber, nameSearch });

export const createFrequentRecipient = (
    recipientName,
    cuit,
    entity,
    number,
    currency,
    destinationType,
    destinationIdentifier,
    reference,
    email,
    credentials,
) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    return API.executeWithAccessToken("/frequentDestination.create.send", {
        recipientName,
        cuit,
        entity,
        number,
        currency,
        destinationType,
        destinationIdentifier,
        reference,
        email,
        ...credentialsWithUnderscore,
    });
};

export const modifyFrequentDestination = (params) =>
    API.executeWithAccessToken("/frequentdestinations.modify", {
        id: params.frequentDestination.idFrequentDestination,
        email: params.frequentDestination.email,
        headline: params.frequentDestination.headline,
        reference: params.frequentDestination.reference,
        idForm: params.frequentDestination.idForm,
    });
export const deleteFrequentRecipient = (idFrequentDestination) =>
    API.executeWithAccessToken("/frequentdestinations.delete", {
        id: idFrequentDestination,
    });

export const frequentDestinationPre = () => API.executeWithAccessToken("/frequentDestination.create.preview");

export const searchFrequentRecipient = (destinationType, destinationIdentifier, cuit) =>
    API.executeWithAccessToken("/frequentdestinations.search", {
        destinationType,
        destinationIdentifierS: destinationIdentifier,
        cuit,
    });
