import * as API from "middleware/api";
import { format } from "date-fns";

export const loadListRequest = (filters) => {
    let { paymentDateFrom, paymentDateTo, emissionDateFrom, emissionDateTo } = filters;
    const {
        echeqType,
        cuit,
        echeqNumber,
        status,
        subStatus,
        echeqId,
        groupId,
        quickFilter,
        pageNumber,
        channel,
        client,
        orderDirection,
        orderColumn,
    } = filters;

    paymentDateFrom = paymentDateFrom ? format(paymentDateFrom, "YYYY-MM-DD") : null;
    paymentDateTo = paymentDateTo ? format(paymentDateTo, "YYYY-MM-DD") : null;
    emissionDateFrom = emissionDateFrom ? format(emissionDateFrom, "YYYY-MM-DD") : null;
    emissionDateTo = emissionDateTo ? format(emissionDateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/echeqs.list", {
        echeqType,
        cuit,
        echeqNumber,
        status,
        subStatus,
        paymentDateFrom,
        paymentDateTo,
        echeqId,
        groupId,
        emissionDateFrom,
        emissionDateTo,
        quickFilter,
        pageNumber,
        orderDirection,
        orderColumn,
        channel,
        client,
    });
};

export const getECheqDetail = (id) => API.executeWithAccessToken("/echeqs.detail", { id });
export const getBankedCustomerInfo = (documentType, documentNumber) =>
    API.executeWithAccessToken("/echeqs.customerbank", {
        documentNumber,
        documentType,
    });

export const getListIMF = () => API.executeWithAccessToken("/echeqs.list.IMF");

export const getAuthorizedEntityList = () => API.executeWithAccessToken("/echeqs.list.authorizedEntityList");
export const getEcheqIssuingAccounts = (status = "activa") =>
    API.executeWithAccessToken("/echeqs.emitEcheq.getAuthorizedAccounts", { status });

export const disableAccount = (action, cbu, cuit, account) =>
    API.executeWithAccessToken("/echeqs.managementAccounts.activateOrDeactivate.send", {
        action,
        issuer: { cuit, cbu },
        account,
        activitySuffix: action,
    });

export const getAcceptEcheqTermsConditionsRequest = () =>
    API.executeWithAccessToken("/echeqs.acceptTermsAndConditions.preview");

export const downloadEcheqList = (formatToDownload, filters) => {
    let { paymentDateFrom, paymentDateTo, emissionDateFrom, emissionDateTo } = filters;
    const { echeqType, cuit, echeqNumber, status, echeqId, groupId, quickFilter, orderDirection } = filters;

    paymentDateFrom = paymentDateFrom ? format(paymentDateFrom, "YYYY-MM-DD") : null;
    paymentDateTo = paymentDateTo ? format(paymentDateTo, "YYYY-MM-DD") : null;
    emissionDateFrom = emissionDateFrom ? format(emissionDateFrom, "YYYY-MM-DD") : null;
    emissionDateTo = emissionDateTo ? format(emissionDateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/echeqList.echeqs.export", {
        formatToDownload,
        echeqType,
        cuit,
        echeqNumber,
        status,
        echeqId,
        groupId,
        quickFilter,
        orderDirection,
        paymentDateFrom,
        paymentDateTo,
        emissionDateFrom,
        emissionDateTo,
    });
};

export const downloadEcheqMovementDetail = (echeq, formatToDownload, subStates) =>
    API.executeWithAccessToken("/echeqMovementDetail.echeq.export", {
        echeq,
        formatToDownload,
        subStates,
    });

export const validatePersonalizedEcheqRequest = ({ amount, echeqType, character, paymentDate, reference }) =>
    API.executeWithAccessToken("/echeqs.emitEcheq.preview", {
        amount,
        echeqType,
        character,
        paymentDate,
        reference,
        validatePersonalizedEcheq: true,
    });
