import SimpleModal from "components/SimpleModal/SimpleModal";
import { bool, func, number, string } from "prop-types";
import React, { Component } from "react";

import { actions } from "reducers/campaigns";
import { actions as loginActions } from "reducers/login";
import { Mixpanel } from "util/clickstreaming";

class CloseCampaignModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        goBack: func,
        isDisplayed: bool,
        idCampaign: number.isRequired,
        closeModal: func.isRequired,
        section: string.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        goBack: () => {},
        isDisplayed: false,
    };

    state = {
        isClosing: false,
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    dismissPermanentHandler = () => {
        const { dispatch, idCampaign, section, closeModal } = this.props;
        Mixpanel.track("campaign.dismiss.permanent", {
            id: idCampaign,
        });
        dispatch(actions.dismissPermanentCampaign(idCampaign, section));
        closeModal();
    };

    dismissHandler = () => {
        const { dispatch, idCampaign } = this.props;
        Mixpanel.track("campaign.dismiss", {
            id: idCampaign,
        });
        dispatch(loginActions.dismissCampaigns());
    };

    render() {
        const { isDisplayed, closeModal, isDesktop } = this.props;
        const { isClosing } = this.state;
        return (
            <SimpleModal
                buttonAction={this.dismissPermanentHandler}
                buttonActionLabel="desktop.campaigns.modal.button.dismissPermanent"
                buttonCancelLabel="desktop.campaigns.modal.button.dismiss"
                className="campaignModal"
                closeModal={closeModal}
                customCancellationFunction={this.dismissHandler}
                isDisplayed={isDisplayed && !isClosing}
                modalIcon="images/warningIcon.svg"
                modalMainText="desktop.campaigns.modal.title"
                reverse={!isDesktop}
            />
        );
    }
}

export default CloseCampaignModal;
