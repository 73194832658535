import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { string, func, shape, bool, number } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { selectors as checksSelectors } from "reducers/checks";
import { actions as filterActions } from "reducers/filters";

class CheckDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        check: shape({
            date: string,
            checkNumber: string,
            state: string,
            account: shape({
                number: string,
                currency: string,
            }),
            amount: number,
            depositDate: string,
            currency: string,
            productType: string,
            rejectionReason: string,
        }).isRequired,
        isMobile: bool.isRequired,
        checkType: string.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { dispatch, check } = this.props;
        if (check === null || Object.keys(check).length === 0) {
            dispatch(goBack());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.setResetFilters(false));
        dispatch(goBack());
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" onBack={this.handleBack} arrowWhite />;
        }
        return <Head onBack={this.handleBack} showPlusIcon />;
    };

    render() {
        const { fetching, check, checkType } = this.props;
        const { checkNumber, bank, amount, state, depositDate, rejectionReason, account } = check;

        return (
            check &&
            Object.keys(check).length > 0 && (
                <Fragment>
                    {this.renderHeader()}
                    <Notification scopeToShow="checkDetail" />

                    <MainContainer showLoader={fetching}>
                        <div className="container-center">
                            <div className="title-left-header">
                                <I18n id="checks.listChecks.detail.title" component="h1" />
                            </div>

                            <div className="details-container check-detail">
                                <div className="details__default-content">
                                    <ul className="data-list inline">
                                        <div className="data-wrapper">
                                            <I18n
                                                id="forms.checks.listChecks.checkNumber.label"
                                                componentProps={{ className: "data-label" }}
                                            />
                                            <span className="data-text">{checkNumber}</span>
                                        </div>

                                        <div className="data-wrapper">
                                            <I18n
                                                id="forms.checks.listChecks.state.label"
                                                componentProps={{ className: "data-label" }}
                                            />
                                            <span className="data-uppercase">{state}</span>
                                        </div>

                                        <div className="data-wrapper">
                                            <I18n
                                                id="checks.listChecks.account.label"
                                                componentProps={{ className: "data-label" }}
                                            />

                                            <span className="data-text">
                                                {account?.accountNumber}-{account?.currencyNumber}-{account?.digit}
                                            </span>
                                        </div>

                                        <div className="data-wrapper">
                                            <I18n
                                                id={`checks.listChecks.${checkType}.bank.label`}
                                                componentProps={{ className: "data-label" }}
                                            />
                                            <span className="data-text">{bank}</span>
                                        </div>

                                        <div className="data-wrapper">
                                            <I18n
                                                id="checks.listChecks.amount.label"
                                                componentProps={{ className: "data-label" }}
                                            />

                                            <FormattedAmount
                                                className="formattedAmount fw-regular"
                                                currency={account?.currency || "ARS"}
                                                quantity={amount}
                                            />
                                        </div>
                                        {depositDate && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="checks.listChecks.date"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="data-date">
                                                    <FormattedDate date={depositDate} />
                                                </span>
                                            </div>
                                        )}

                                        {state === "Rechazado" && (
                                            <div className="data-wrapper">
                                                <I18n
                                                    id="checks.listChecks.received.state.rejectionReason"
                                                    componentProps={{ className: "data-label" }}
                                                />
                                                <span className="data-text">{rejectionReason}</span>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: checksSelectors.getFetching(state),
    check: checksSelectors.getSelectedCheck(state),
    checkType: checksSelectors.getFilters(state).checkType,
});

export default connect(mapStateToProps)(CheckDetail);
