import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    DOWNLOAD_FILE_REQUEST: "files/DOWNLOAD_FILE_REQUEST",
    DOWNLOAD_FILE_SUCCESS: "files/DOWNLOAD_FILE_SUCCESS",
    GET_FILE_CONTENTS_REQUEST: "files/GET_FILE_CONTENTS_REQUEST",
    GET_FILE_CONTENTS_SUCCESS: "files/GET_FILE_CONTENTS_SUCCESS",
    UPLOAD_FILE_REQUEST: "files/UPLOAD_FILE_REQUEST",
    UPLOAD_FILE_SUCCESS: "files/UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAILURE: "files/UPLOAD_FILE_FAILURE",
    RESET_DATA: "files/RESET_DATA",
};

export const INITIAL_STATE = {
    fetching: false,
    uploading: false,
    lines: [],
};

export default createReducer(INITIAL_STATE, {
    [types.DOWNLOAD_FILE_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.DOWNLOAD_FILE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.UPLOAD_FILE_REQUEST]: (state) => ({ ...state, uploading: true }),
    [types.UPLOAD_FILE_SUCCESS]: (state) => ({ ...state, uploading: false }),
    [types.UPLOAD_FILE_FAILURE]: (state) => ({ ...state, uploading: false }),
    [types.RESET_DATA]: () => ({ ...INITIAL_STATE }),
});

export const actions = {
    getFileContentsRequest: makeActionCreator(types.GET_FILE_CONTENTS_REQUEST, "idFile", "deleteFile"),
    downloadFileRequest: makeActionCreator(types.DOWNLOAD_FILE_REQUEST, "idFile", "fileName"),
    downloadFileSuccess: makeActionCreator(types.DOWNLOAD_FILE_SUCCESS),
    uploadFileRequest: makeActionCreator(
        types.UPLOAD_FILE_REQUEST,
        "idActivity",
        "idForm",
        "description",
        "idEnvironment",
        "lang",
        "content",
        "accountSelected",
    ),
    uploadFileResponse: makeActionCreator(types.UPLOAD_FILE_SUCCESS, "data"),
    uploadFileFailure: makeActionCreator(types.UPLOAD_FILE_FAILURE),
    resetData: makeActionCreator(types.RESET_DATA),
};

// Selectors
export const selectors = {
    isFetching: (state) => state.files.fetching,
};
