import { LOCATION_CHANGE } from "react-router-redux";

export const types = {
    LOAD_TEMPLATE_LIST: "template/LOAD_TEMPLATE_LIST",
    LOAD_TEMPLATE_LIST_SUCCESS: "template/LOAD_TEMPLATE_LIST_SUCCESS",
    LOAD_TEMPLATE_LIST_FAILURE: "template/LOAD_TEMPLATE_LIST_FAILURE",
    DELETE_TEMPLATE: "template/DELETE_TEMPLATE",
    DELETE_TEMPLATE_SUCCESS: "template/DELETE_TEMPLATE_SUCCESS",
    DELETE_TEMPLATE_FAILURE: "template/DELETE_TEMPLATE_FAILURE",

    SAVE_TEMPLATE_REQUEST: "template/SAVE_TEMPLATE_REQUEST",
    SAVE_TEMPLATE_SUCCESS: "template/SAVE_TEMPLATE_SUCCESS",
    SAVE_TEMPLATE_FAILURE: "template/SAVE_TEMPLATE_FAILURE",

    CREATE_TEMPLATE: "template/CREATE_TEMPLATE",
    CLOSE_CREATE_TEMPLATE: "template/CLOSE_CREATE_TEMPLATE",
};

export const INITIAL_STATE = {
    fetching: true,
    list: [],
    createTemplateModalVisible: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOAD_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                list: action.templates,
                fetching: false,
            };

        case types.LOAD_TEMPLATE_LIST_FAILURE:
            return {
                ...state,
                list: [],
                fetching: false,
            };

        case types.SAVE_TEMPLATE_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case types.DELETE_TEMPLATE_SUCCESS:
            return {
                ...state,
                list: state.list.filter((item) => item.idTransactionTemplate !== action.item.idTransactionTemplate),
            };

        case types.CREATE_TEMPLATE:
            return {
                ...state,
                createTemplateModalVisible: true,
            };

        case types.SAVE_TEMPLATE_SUCCESS:
        case types.CLOSE_CREATE_TEMPLATE:
            return {
                ...state,
                createTemplateModalVisible: false,
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                list: [],
            };
        default:
            return state;
    }
};

export const actions = {
    loadTemplateList: (idForm, idActivityTemplate) => ({
        type: types.LOAD_TEMPLATE_LIST,
        idForm,
        idActivityTemplate,
    }),

    deleteTemplate: (item) => ({
        type: types.DELETE_TEMPLATE,
        item,
    }),

    createTemplate: () => ({
        type: types.CREATE_TEMPLATE,
    }),

    saveTemplate: (idForm, data, templateName, formikBag, idActivityTemplate) => ({
        type: types.SAVE_TEMPLATE_REQUEST,
        idForm,
        data,
        templateName,
        formikBag,
        idActivityTemplate,
    }),

    closeCreateModal: () => ({
        type: types.CLOSE_CREATE_TEMPLATE,
    }),
};

export const selectors = {
    isFetching: (state) => state.template.fetching,
    getTemplateList: (state) => state.template.list,
    isCreateModalVisible: (state) => state.template.createTemplateModalVisible,
};
