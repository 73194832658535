import React from "react";
import { func, string } from "prop-types";
import Button from "pages/_components/Button";
import { isMobileNative } from "util/device";
import { openLink } from "util/browser";
import I18n from "pages/_components/I18n";

class ExternalLinkComponent extends React.Component {
    static propTypes = {
        idForm: string.isRequired,
        idField: string.isRequired,
        setValue: func.isRequired,
        to: string.isRequired,
        className: string,
        label: string,
        handleClick: func,
    };

    static defaultProps = {
        className: "",
        label: null,
        handleClick: null,
    };

    handleClick = () => {
        const { to, setValue, handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
        if (setValue) {
            setValue(true);
        }
        if (isMobileNative) {
            openLink(to).then();
        } else {
            window.open(to, "_blank").focus();
        }
    };

    render() {
        const { idForm, idField, className, label, setValue } = this.props;

        if (setValue) {
            return (
                <div className={className}>
                    <div style={{ flex: 1 }}>
                        <Button
                            key={idField}
                            name={idField}
                            label={label || `forms.${idForm}.${idField}.link.label`}
                            onClick={() => this.handleClick()}
                            bsStyle="link"
                        />
                    </div>
                </div>
            );
        }
        return (
            <a className="ui-link-gray" onClick={() => this.handleClick()} target="_blank" rel="noopener noreferrer">
                <I18n component="span" id="forms.account.qr.download.link" />
            </a>
        );
    }
}

export default ExternalLinkComponent;
