import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import isEmpty from "lodash/isEmpty";
import { func, shape, bool, string } from "prop-types";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";

import SimpleModal from "components/SimpleModal/SimpleModal";
import { capitalizeFirstLetter } from "../../util/string";

class FingerprintConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fingerprintConfiguredUserDevices: shape({
            device: shape({
                deviceId: string,
                deviceModel: string,
            }),
        }),
        isDeviceWithFingerprint: bool,
        availability: shape({
            isHardwareDetected: bool,
            isAvailable: bool,
        }),
        fetching: bool,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: false,
        availability: null,
        fetching: false,
    };

    state = {
        displayModal: false,
        deviceToDelete: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintConfigurationPre());
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    getContent() {
        const { isMobile } = this.props;

        return (
            <>
                <div className="title-left-header">
                    {isMobile ? (
                        <I18n id="settings.biometrics.title.mobile" component="h1" />
                    ) : (
                        <I18n id="settings.biometrics.title.desktop" component="h1" />
                    )}
                </div>
                <div className="container-center">
                    <form className="form-content">
                        <div className="form-text-info ui-mb-8">
                            {isMobile ? (
                                <I18n id="settings.biometrics.textInfo.mobile" component="p" />
                            ) : (
                                <I18n id="settings.biometrics.textInfo.desktop" component="p" />
                            )}
                        </div>
                        {this.renderDevices()}

                        {this.renderConfigureButton()}
                    </form>
                </div>
            </>
        );
    }

    configureNow = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintVerification());
    };

    enrollFingerprintOnDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(fingerprintActions.enrollFingerprintOnDevice());
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    isDeviceIdInSession = () => {
        const { fingerprintConfiguredUserDevices } = this.props;
        if (deviceUtils.isMobileNativeFunc() && fingerprintConfiguredUserDevices) {
            const device = fingerprintConfiguredUserDevices.filter(
                (item) => item.deviceId.search(deviceUtils.getDeviceId()) !== -1,
            );
            return !isEmpty(device);
        }
        return false;
    };

    isBiometricAvailable = () => {
        const { isDeviceWithFingerprint, availability } = this.props;
        return (
            deviceUtils.isMobileNativeFunc() &&
            !isDeviceWithFingerprint &&
            availability &&
            availability.isHardwareDetected &&
            !this.isDeviceIdInSession()
        );
    };

    showModal = (e, deviceIndex) => {
        e.preventDefault();
        const { dispatch, fingerprintConfiguredUserDevices } = this.props;

        dispatch(fingerprintActions.fingerprintDeleteUserDevicePre(fingerprintConfiguredUserDevices[deviceIndex]));

        this.setState({
            displayModal: true,
            deviceToDelete: fingerprintConfiguredUserDevices[deviceIndex],
        });
    };

    hideModal = () => {
        this.setState({ displayModal: false });
    };

    renderDevices() {
        const { fingerprintConfiguredUserDevices } = this.props;

        return (
            <>
                <I18n id="settings.fingerprintConfiguration.configuredDevices" component="h4" />

                <div className="navigational-list devices">
                    {fingerprintConfiguredUserDevices && fingerprintConfiguredUserDevices.length ? (
                        Object.keys(fingerprintConfiguredUserDevices).map((deviceIndex) => {
                            const { deviceId, deviceModel } = fingerprintConfiguredUserDevices[deviceIndex];

                            const deviceModelList = deviceModel.split("#"); // deviceModel#deviceManufacturer

                            const deviceShowName = `${
                                deviceModelList.length > 1
                                    ? capitalizeFirstLetter(deviceModelList[1]).concat(" - ")
                                    : ""
                            } ${i18n.get(
                                `devices.apple.identifier.${deviceModelList[0]}`,
                                `${deviceModelList[0]}`,
                            )} - ${deviceId.substring(deviceId.length - 4, deviceId.length)}`;

                            return (
                                <li className="navigational-list-item device" key={deviceIndex}>
                                    <div className="navigational-list-item-container">
                                        <div>{deviceShowName}</div>
                                        <Button
                                            block={false}
                                            className="btn-only-icon btn-delete"
                                            image="images/ui-icons/ui-delete.svg"
                                            onClick={(e) => this.showModal(e, deviceIndex)}
                                            label="settings.fingerprintConfiguration.device.remove"
                                        />
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <I18n id="settings.fingerprintConfiguration.configuredDevices.none" />
                    )}
                </div>
            </>
        );
    }

    renderConfigureButton() {
        const { availability } = this.props;

        if (this.isBiometricAvailable()) {
            if (availability.hasEnrolledFingerprints && availability.isAvailable) {
                return (
                    <Button
                        block
                        label="settings.fingerprintConfiguration.device.register"
                        bsStyle="primary"
                        onClick={this.configureNow}
                    />
                );
            }

            return (
                <Button
                    block
                    label="settings.fingerprintConfiguration.device.enrolledFingerprints.enroll"
                    bsStyle="primary"
                    onClick={this.enrollFingerprintOnDevice}
                />
            );
        }
        return null;
    }

    /* Se comenta ya que este texto no es necesario mostrar. En todo caso borrarlo */
    // renderText() {
    //     const { availability } = this.props;
    //     if (this.isBiometricAvailable()) {
    //         if (availability.hasEnrolledFingerprints && availability.isAvailable) {
    //             return <I18n id="settings.fingerprintConfiguration.warning" />;
    //         }
    //         return <I18n id="settings.fingerprintConfiguration.device.enrolledFingerprints.none" />;
    //     }
    //     return null;
    // }

    renderModal() {
        const { dispatch } = this.props;

        const { displayModal, deviceToDelete } = this.state;

        const { deviceId, deviceModel, idSession } = deviceToDelete;

        const deviceModelList = deviceModel.split("#"); // deviceModel#deviceManufacturer

        const deviceShowName = `${
            deviceModelList.length > 1 ? capitalizeFirstLetter(deviceModelList[1]).concat(" - ") : ""
        } ${i18n.get(`devices.apple.identifier.${deviceModelList[0]}`, `${deviceModelList[0]}`)} - ${deviceId.substring(
            deviceId.length - 4,
            deviceId.length,
        )}`;

        const deleteDevice = (_idSession, deviceShowNameAux) => () => {
            dispatch(fingerprintActions.fingerprintDeleteDevice(_idSession, deviceShowNameAux));

            this.setState({
                displayModal: false,
                deviceToDelete: {},
            });
        };

        return (
            <SimpleModal
                dispatch={dispatch}
                isDisplayed={displayModal}
                closeModal={this.hideModal}
                modalIcon="images/warningIcon.svg"
                modalMainText="settings.biometrics.modalText"
                buttonAction={deleteDevice(idSession, deviceShowName)}>
                <div className="device-name ui-mt-7">{deviceId && deviceShowName}</div>
            </SimpleModal>
        );
    }

    render() {
        const { fetching, isMobile } = this.props;

        const { displayModal } = this.state;

        return (
            <>
                {!fetching && (
                    <>
                        {!isMobile ? (
                            <Head
                                propsClassNames="title-size"
                                headerClassName={!isMobile && "mt-2"}
                                onBack={this.handleHeaderBack}
                            />
                        ) : (
                            <Head
                                onBack={this.handleHeaderBack}
                                showLogo
                                arrowWhite
                                additionalClassName={isMobile && "blue-main-header-mobile"}
                            />
                        )}
                    </>
                )}
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
                {displayModal && this.renderModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    availability: fingerprintSelectors.getAvailability(state),
    isDeviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    fingerprintConfiguredUserDevices: fingerprintSelectors.getFingerprintConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(FingerprintConfiguration);
