import * as i18n from "util/i18n";
import * as config from "util/config";

export const loadSubStates = () => {
    const keySubStates = "forms.echeqs.list.substatus";
    const subStates = {};
    subStates._1_CHEQUE_ACORDADO = i18n.get(`${keySubStates}.CHEQUE-ACORDADO`);
    subStates._2_SOLICITANDO_ACUERDO = i18n.get(`${keySubStates}.SOLICITANDO-ACUERDO`);
    subStates._3_RE_PRESENTAR = i18n.get(`${keySubStates}.RE-PRESENTAR`);
    subStates._4_CERTIFICADO_EMITIDO = i18n.get(`${keySubStates}.CERTIFICADO-EMITIDO`);
    subStates._5_ORDEN_NO_PAGAR = i18n.get(`${keySubStates}.ORDEN-NO-PAGAR`);
    subStates._6_REPUDIO_ENDOSO = i18n.get(`${keySubStates}.REPUDIO_ENDOSO`);
    subStates._7_VENCIDO = i18n.get(`${keySubStates}.VENCIDO`);
    subStates._8_CESION_PENDIENTE = i18n.get(`${keySubStates}.CESION-PENDIENTE`);
    subStates._9_CEDIDO = i18n.get(`${keySubStates}.CEDIDO`);
    subStates._10_AVALADO = i18n.get(`${keySubStates}.AVALADO`);
    subStates._11_MANDATO_COBRO = i18n.get(`${keySubStates}.MANDATO-COBRO`);
    subStates._12_MANDATO_NEGOCIACION = i18n.get(`${keySubStates}.MANDATO-NEGOCIACION`);
    subStates._13_ACUERDO_RECHAZADO = i18n.get(`${keySubStates}.ACUERDO-RECHAZADO`);

    return subStates;
};

const getSubStatesByState = (echeq, echeqSubStates) => {
    const subStates = [];
    const statusesWithSubstatus = config.get("echeq.statusesWithSubstatus")?.split("|");

    if (statusesWithSubstatus?.includes(echeq.estado) && echeqSubStates) {
        switch (echeq.estado) {
            case "ACTIVO_PENDIENTE":
                if (echeq.fecha_pago_vencida) {
                    subStates.push(echeqSubStates._7_VENCIDO);
                }
                if (echeq.avalado) {
                    subStates.push(echeqSubStates._10_AVALADO);
                }
                break;

            case "ACTIVO":
                if (echeq.fecha_pago_vencida) {
                    subStates.push(echeqSubStates._7_VENCIDO);
                }
                if (echeq.cedido) {
                    subStates.push(echeqSubStates._9_CEDIDO);
                }
                if (echeq.avalado) {
                    subStates.push(echeqSubStates._10_AVALADO);
                }
                if (echeq.mandato_neg) {
                    subStates.push(echeqSubStates._12_MANDATO_NEGOCIACION);
                }
                break;

            case "CUSTODIA":
                if (echeq.mandato_cobro) {
                    subStates.push(echeqSubStates._11_MANDATO_COBRO);
                }
                break;

            case "RECHAZADO":
                if (echeq.cheque_acordado) {
                    subStates.push(echeqSubStates._1_CHEQUE_ACORDADO);
                }
                if (echeq.solicitando_acuerdo) {
                    subStates.push(echeqSubStates._2_SOLICITANDO_ACUERDO);
                }
                if (echeq.re_presentar) {
                    subStates.push(echeqSubStates._3_RE_PRESENTAR);
                }
                if (echeq.certificado_emitido) {
                    subStates.push(echeqSubStates._4_CERTIFICADO_EMITIDO);
                }
                if (echeq.onp) {
                    subStates.push(echeqSubStates._5_ORDEN_NO_PAGAR);
                }
                if (echeq.cesion_pendiente) {
                    subStates.push(echeqSubStates._8_CESION_PENDIENTE);
                }
                if (echeq.cedido) {
                    subStates.push(echeqSubStates._9_CEDIDO);
                }
                if (echeq.acuerdo_rechazado) {
                    subStates.push(echeqSubStates._13_ACUERDO_RECHAZADO);
                }
                break;

            case "REPUDIO":
                subStates.push(echeqSubStates._6_REPUDIO_ENDOSO);
                break;

            default:
                break;
        }
    }

    return subStates;
};

export default getSubStatesByState;
