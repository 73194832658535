import React, { Component } from "react";
import { arrayOf, shape, string } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";
import I18n from "pages/_components/I18n";
import { selectors as loginSelectors } from "reducers/login";
import { REGION_USA } from "constants.js";
import { connect } from "react-redux";
import * as utilDownload from "util/download";

class TransferForeign extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
        activeRegion: string.isRequired,
        transferReceipt: arrayOf(shape({ fileNameKey: string, size: string })).isRequired,
    };

    handleClickDownloadReceiptPdf = (fileNameKey) => {
        utilDownload.downloadLink(fileNameKey, "/pdf/TKT.pdf");
    };

    render() {
        const { transaction, activeRegion, transferReceipt } = this.props;
        const isUSA = REGION_USA === activeRegion;

        return (
            <section className="container--layout align-items-center flex-grow">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                            <div className="media-object ">
                                {
                                    <figure>
                                        <TransactionStatus
                                            iconFidelity="ticket"
                                            idTransactionStatus={transaction.idTransactionStatus}
                                        />
                                    </figure>
                                }
                                {
                                    <div className="media-object--text">
                                        <p className="text-lead">
                                            <I18n
                                                id={`forms.transaction.ticket.status.${transaction.idTransactionStatus}`}
                                            />
                                        </p>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    {isUSA && (
                        <div className="form-group">
                            {transferReceipt.map((doc) => (
                                <button
                                    id="btn-internationalTransfer"
                                    type="button"
                                    className="btn btn-asLink"
                                    onClick={() => this.handleClickDownloadReceiptPdf(doc.fileNameKey)}>
                                    <I18n id="forms.transferThirdParties.confirmation.transferReceipt" />
                                </button>
                            ))}
                            <p>
                                <I18n id="forms.transferThirdParties.confirmation.transferCancelMessage" />
                            </p>
                        </div>
                    )}
                </Grid>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
    transferReceipt: [{ fileNameKey: "tkt.pdf", size: "345" }],
});

export default connect(mapStateToProps)(TransferForeign);
