import React, { Component } from "react";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { string, shape, bool, func } from "prop-types";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";

class ReceiptsComex extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        receipts: shape({}).isRequired,
        idActivity: string.isRequired,
        onClick: func.isRequired,
        fileDownloading: string,
        isDownloadingFile: bool.isRequired,
    };

    static defaultProps = {
        fileDownloading: "",
    };

    renderReceiptsMobile = () => {
        const { receipts, idActivity, onClick, isDownloadingFile, fileDownloading } = this.props;
        const { dateTransaction, liquidation, currency, amount, conceptLiquidation, fileName } = receipts;
        return (
            <GenericItem>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.date.label`} />
                    </div>
                    <div className="data-text">
                        <FormattedDate date={dateTransaction.substring(0, 10)} />
                    </div>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.liquidation.label`} />
                    </div>
                    <span className="data-text">{liquidation}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.amount.label`} />
                    </div>
                    <FormattedAmount currency={currency} quantity={amount} />
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.concept.label`} />
                    </div>
                    <span className="data-text">{conceptLiquidation}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.voucher.label`} />
                    </div>
                    <div className="ui-dflex ui-mt-3">
                        <Button
                            image="images/download-comex.svg"
                            label="comex.receiptsList.receipts.label"
                            onClick={() => onClick()}
                            bsStyle="outline"
                            className="btn-small"
                            loading={isDownloadingFile && fileDownloading === fileName}
                        />
                    </div>
                </div>
            </GenericItem>
        );
    };

    render() {
        const { isDesktop, receipts, isDownloadingFile, onClick, fileDownloading } = this.props;
        const { dateTransaction, liquidation, currency, amount, conceptLiquidation, fileName } = receipts;
        return isDesktop ? (
            <>
                <Table.Data align="left">
                    <span>{dateTransaction && <FormattedDate date={dateTransaction.substring(0, 10)} />}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span>{liquidation}</span>
                </Table.Data>

                <Table.Data align="left">
                    <FormattedAmount currency={currency} quantity={amount} />
                </Table.Data>

                <Table.Data align="left">
                    <span>{conceptLiquidation}</span>
                </Table.Data>

                <Table.Data align="right">
                    <Button
                        image="images/download-comex.svg"
                        label="comex.receiptsList.receipts.label"
                        onClick={() => onClick()}
                        bsStyle="outline"
                        loading={isDownloadingFile && fileDownloading === fileName}
                        className="btn-small"
                    />
                </Table.Data>
            </>
        ) : (
            this.renderReceiptsMobile()
        );
    }
}

export default ReceiptsComex;
