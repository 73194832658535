import { call, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as settings from "middleware/settings";
import { types as settingsTypes, selectors as settingsSelectors } from "reducers/settings";
import { types as typesSession, selectors as sessionSelectors, actions as sessionActions } from "reducers/session";
import { actions as i18nActions, types as i18nTypes } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { adjustIdFieldErrors } from "util/form.js";
import * as utilDownload from "util/download";
import globalTypes from "reducers/types/global";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(settingsTypes.CHANGE_LANGUAGE_PRE, changeLanguage),

    takeLatest(settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE, changeDefaultEnvironment),

    takeLatest(settingsTypes.CHANGE_PASSWORD_REQUEST, changePassword),

    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_PRE, changeSecuritySealPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, changeSecuritySealConfirmationPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK, changeSecuritySealConfirmationBack),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION, changeSecuritySealConfirmation),

    takeLatest(settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE, notificationsConfigurationPre),
    takeLatest(settingsTypes.COMMUNICATION_TYPE_PRE, communicationTypePre),
    takeEvery(settingsTypes.MODIFY_NOTIFICATION_CONFIGURATIONS, modifyNotificationConfigurations),
    takeEvery(settingsTypes.MODIFY_PAPERLESS_CONFIGURATIONS, modifyPaperlessConfigurations),

    takeLatest(settingsTypes.GET_USER_DATA, getUserData),
    takeLatest(settingsTypes.MAIL_SEND_CODE, sendMailCode),
    takeLatest(settingsTypes.MAIL_UPDATE, updateMail),

    takeLatest(settingsTypes.MOBILEPHONE_SEND_CODE, sendMobilePhoneCode),
    takeLatest(settingsTypes.MOBILEPHONE_UPDATE, updateMobilePhone),
    takeLatest(settingsTypes.LOAD_DOCUMENTS_REQUEST, loadDocumentsRequest),
    takeLatest(settingsTypes.DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentRequest),

    takeLatest(settingsTypes.FETCH_PRODUCTS_LIST, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_SUCCESS, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_REQ, setAllPaperlessValue),

    takeLatest(settingsTypes.GET_ADDRESSES_INFORMATION_REQ, getAddressesInformationRequest),
    takeLatest(settingsTypes.SET_ADDRESSES_INFORMATION_REQ, setAddressesInformationRequest),

    takeLatest(settingsTypes.GET_TOKEN_DATA, getTokenData),
    takeLatest(settingsTypes.REGISTER_TOKEN, registerToken),
    takeLatest(settingsTypes.VALIDATE_TOKEN, validateToken),
];

export default sagas;

function* changeLanguage({ language, changeUserLanguage }) {
    if (changeUserLanguage) {
        const result = yield call(settings.changeLanguage, language);

        if (result && result.status === 200) {
            configUtils.setRecaptchaLang(language);
            yield put(i18nActions.setLang(language));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_SUCCESS });
        } else {
            const confirmationMessage = i18n.get("settings.changeLanguage.errorMessage");
            yield put(notificationActions.showNotification(confirmationMessage, "error", ["changeLanguage"]));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_ERROR });
        }
    } else {
        yield put(i18nActions.setLang(language));
        yield takeLatest(i18nTypes.UPDATE_SUCCESS, notifyLanguageChangedSuccess);
    }
}

function* changeDefaultEnvironment({ idEnvironment }) {
    const userData = yield select(sessionSelectors.getUser);
    const { idDefaultEnvironment } = userData;

    const response = yield call(settings.changeDefaultEnvironment, idEnvironment);
    const { code, data } = response.data;

    let environmentName;
    if (code === "COR000I") {
        const defaultEnvironmentRemoved = data.idEnvironment === 0;
        if (!defaultEnvironmentRemoved) {
            const environments = yield select(sessionSelectors.getEnvironments);
            environmentName = environments[idEnvironment].name;
        }
        yield put({
            type: settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS,
            idDefaultEnvironment: !defaultEnvironmentRemoved ? data.idEnvironment : null,
        });
        const { showAlertExpire, daysToExpire } = data;
        if (showAlertExpire) {
            yield put({ type: typesSession.SET_ALERT_ENVIRONMENT_EXPIRE, daysToExpire, environmentName });
        }
        if (defaultEnvironmentRemoved) {
            yield put(
                notificationModalActions.showNotification(
                    "settings.defaultEnvironment.confirmationRemovedMessage",
                    "success",
                ),
            );
        } else if (idDefaultEnvironment) {
            yield put(
                notificationModalActions.showNotification("settings.defaultEnvironment.confirmationMessage", "success"),
            );
        } else {
            yield put(
                notificationModalActions.showNotification(
                    "settings.defaultEnvironment.newEnvironment.confirmationMessage",
                    "success",
                ),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification("settings.defaultEnvironment.errorMessage", "error", [
                "changeDefaultEnvironment",
            ]),
        );
    }
}

function* notifyLanguageChangedSuccess() {
    const confirmationMessage = i18n.get("settings.changeLanguage.confirmationMessage");
    yield put(notificationActions.showNotification(confirmationMessage, "success", ["changeLanguage"]));
}

function* notificationsConfigurationPre() {
    const response = yield call(settings.notificationsConfigurationPre);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS,
            communicationTypes: response.data.data.communicationConfigurations,
        });
    }
}

function* communicationTypePre({ communicationType }) {
    const response = yield call(settings.communicationTypePre, communicationType);

    if (response && response.status === 200) {
        const communicationTransportsConfigurations = response.data.data.communicationConfigurations;

        yield put({
            type: settingsTypes.COMMUNICATION_TYPE_PRE_SUCCESS,
            communicationTransportsConfigurations,
        });
    } else {
        yield put(push("/desktop"));
    }
}

function* modifyNotificationConfigurations({ notificationType, transport, subscribed }) {
    const response = yield call(settings.modifyNotificationConfigurations, notificationType, transport, subscribed);

    if (response && response.status === 200) {
        if (response.data.data.transportModified) {
            /* yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.confirmationMessage"),
                    "success",
                    ["settings.notificationsConfiguration.modify"]
                )
            ); */
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.errorMessage"),
                    "error",
                    ["settings.notificationsConfiguration.modify"],
                ),
            );
        }
    }
}

function* modifyPaperlessConfigurations({ idProduct, paperless }) {
    const response = yield call(settings.modifyPaperlessConfigurations, idProduct, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    }
}

function* fetchProductsList() {
    const response = yield call(settings.fetchProductsList);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.UPDATE_PRODUCT_LIST, productsList: response.data.data });
        yield put({ type: settingsTypes.FETCH_PRODUCTS_LIST_SUCCESS });
    }
}

function* setAllPaperlessValue({ paperless }) {
    const response = yield call(settings.setAllPaperlessValue, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_ERROR });
    } else {
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_SUCCESS });
    }
}

function* getUserData() {
    const response = yield call(settings.getUserData);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_USER_DATA_SUCCESS, data: response.data.data });
    }
}

function* sendMailCode({ mail, otp, formikBag }) {
    const response = yield call(settings.sendMailCode, mail, otp);

    if (response.type === "W") {
        if (response.data.data.otp === "returnCode.COR091W") {
            yield put({ type: globalTypes.CLEAN_UP });
            yield put(
                push({
                    pathname: "/session/expiredByMaxAttempts",
                }),
            );
        } else {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
    } else {
        yield put(replace("/settings/changeEmail/confirmation"));
    }

    formikBag.setSubmitting(false);
}

function* updateMail({ mailCode, formikBag }) {
    const response = yield call(settings.updateMail, mailCode);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        const { mail } = response.data.data;
        const rememberedUser = yield select(loginSelectors.getRememberedUser);

        if (rememberedUser !== null) {
            yield put(loginActions.setRememberedUser({ ...rememberedUser, username: mail }));
        }

        yield put({
            type: settingsTypes.MAIL_UPDATE_SUCCESS,
            mail,
        });
        yield put(notificationActions.showNotification(response.data.message, "success", ["settings"]));
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* sendMobilePhoneCode({ mobilePhone, otp, formikBag }) {
    const { prefix, areaCode, value: number } = mobilePhone;
    const phone = `${prefix}${areaCode}${number}`;
    const response = yield call(settings.sendMobilePhoneCode, phone, otp);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(replace("/settings/changePhone/confirmation"));
    }
    formikBag.setSubmitting(false);
}

function* updateMobilePhone({ mobilePhoneCode, formikBag }) {
    const response = yield call(settings.updateMobilePhone, mobilePhoneCode);

    const message = response.data.data.message || response.data.message;

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(notificationActions.showNotification(message, "success", ["settings"]));
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* setAddressesInformationRequest({ address, mailingAddress, otp, formikBag }) {
    const response = yield call(settings.setAddressesInformation, address, mailingAddress, otp);

    const message = response.data.data.message || response.data.message;

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(notificationActions.showNotification(message, "success", ["settings"]));
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changePassword({ password, newPassword, newPasswordConfirmation, formikBag }) {
    const response = yield call(settings.changePassword, password, newPassword, newPasswordConfirmation);
    if (response.type === "W") {
        let errorMessage = response.data.message;
        let errorData = response.data.data;
        if (response.data.code === "COR048W") {
            errorMessage = i18n.get("preferences.password.password.incorrect");
            errorData = { _password: errorMessage };
            formikBag.setErrors(adjustIdFieldErrors(errorData));
        } else {
            formikBag.setErrors(adjustIdFieldErrors(errorData));
            yield put(notificationActions.showNotification(errorMessage, "error", ["changePassword"]));
        }
    } else {
        yield put(
            notificationModalActions.showNotification(
                i18n.get("settings.changePassword.success.notificationMessage"),
                "success",
                null,
                null,
                () => formikBag.props.history.push("/settings"),
            ),
        );
    }
    formikBag.setSubmitting(false);
}

function* changeSecuritySealPre() {
    const response = yield call(settings.listSecuritySeals);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.CHANGE_SECURITY_SEAL_PRE_SUCCESS,
            // eslint-disable-next-line no-underscore-dangle
            securitySeals: response.data.data._securitySeals,
        });
    }
}

function* changeSecuritySealConfirmationPre() {
    yield put(push("/settings/changeSecuritySeal/confirmation"));
}

function* changeSecuritySealConfirmationBack() {
    yield put(push("/settings/changeSecuritySeal/"));
}

function* changeSecuritySealConfirmation({ securitySeal, otp, formikBag }) {
    const response = yield call(settings.modifySecuritySeal, securitySeal, otp);

    if (response && response.status === 200) {
        if (response.type === "W") {
            if (response.data.code === "API519W") {
                yield put(sessionActions.logout());
                yield put(notificationModalActions.showNotification("returnCode.API519W", "warning"));
            } else {
                let errorMessage = response.data.data;
                if (response.data.code === "COR027W") {
                    errorMessage = { otp: response.data.message };
                }
                formikBag.setErrors(adjustIdFieldErrors(errorMessage));
                yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR, errors: errorMessage });
            }
        } else {
            const confirmationMessage = i18n.get("settings.changeSecuritySeal.confirmationMessage");

            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS, securitySeal });
            yield put(
                notificationModalActions.showNotification(confirmationMessage, "success", null, null, () =>
                    formikBag.props.history.push("/settings"),
                ),
            );
        }
    }
}

function* loadDocumentsRequest() {
    const { type, data } = yield call(settings.listAllGeneralConditionDocuments);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.LOAD_DOCUMENTS_SUCCESS, documents: data.data.documents });
    }
}

function* downloadDocumentRequest({ fileNameKey }) {
    const { type, data } = yield call(settings.downloadDocument, fileNameKey);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        const { content } = data.data;
        utilDownload.downloadPdf(i18n.get(fileNameKey), content);
    }
}

function* getAddressesInformationRequest() {
    const response = yield call(settings.getAddressesInformation);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["session"]));
        yield put({ type: settingsTypes.GET_ADDRESSES_INFORMATION_FAILURE });
    } else {
        yield put({
            type: settingsTypes.GET_ADDRESSES_INFORMATION_SUCCESS,
            address: response.data.data.address,
            mailingAddress: response.data.data.mailingAddress,
            countries: response.data.data.countries,
        });
    }
}

function* getTokenData() {
    const response = yield call(settings.getTokenData);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_TOKEN_DATA_SUCCESS, hasOtp: response.data.data.hasOtp });
    }
}

function* registerToken({ hasOtp, password, formikBag }) {
    const response = yield call(settings.registerToken, hasOtp, password);

    if (response && response.status === 200) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);
            yield put({ type: settingsTypes.REGISTER_TOKEN_ERROR, errors: response.data.data });
        } else {
            yield put({ type: settingsTypes.REGISTER_TOKEN_SUCCESS, passcode: response.data.data.passcode });
            yield put(push("/registerToken/validate"));
        }
    }
}

function* validateToken({ otp, formikBag }) {
    const hasOtp = yield select(settingsSelectors.getHasOtp);
    const response = yield call(settings.validateToken, hasOtp, otp);

    if (response && response.status === 200) {
        if (response.type === "W") {
            yield put({ type: settingsTypes.VALIDATE_TOKEN_ERROR, errors: response.data.data });
            if (response.data.code === "COR020W") {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                formikBag.setSubmitting(false);
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]),
                );
                yield put(push("/settings"));
            }
        } else {
            yield put({ type: settingsTypes.VALIDATE_TOKEN_SUCCESS });
            yield put(notificationModalActions.showNotification(i18n.get("settings.registerToken.success"), "success"));
        }
    }
}
