import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { bool, arrayOf, shape, func, string, number } from "prop-types";
import { routerActions } from "react-router-redux/actions";
import { actions as fileActions } from "reducers/files";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Navbar from "pages/_components/Navbar";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import FormattedAmount from "pages/_components/FormattedAmount";
import Hero from "pages/_components/Hero";
import I18n from "pages/_components/I18n";
import Carrousel from "pages/_components/Carrousel";
import DataContent from "pages/_components/DataContent";
import PaymentLinesModal from "pages/_components/Modal";
import PaymentLinesForm from "pages/forms/_components/_fields/_transactionlines/Form";
import TransactionLinesList from "pages/forms/_components/_fields/_transactionlines/List";
import { listItemProps } from "pages/forms/_components/_fields/_transactionlines/ListItem";
import TransactionLinesFilters from "pages/forms/_components/_fields/_transactionlines/Filters";
import TransactionLinesFiltersForm from "pages/forms/_components/_fields/_transactionlines/FiltersForm";
import PaymentLinesButtons from "pages/forms/_components/_fields/_transactionlines/PaymentLinesButtons";
import DownloadDropdown from "pages/_components/DownloadDropdown";
import PageLoading from "pages/_components/PageLoading";

class PaymentLines extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        transactionLines: arrayOf(shape(listItemProps)),
        isConfirmation: bool,
        handleDownloadClick: func.isRequired,
        isEditingPayment: bool.isRequired,
        renderMoreDetails: func,
        isFetching: bool.isRequired,
        dispatch: func.isRequired,
        match: shape({ url: string.isRequired }).isRequired,
        totalLines: number.isRequired,
        isTicket: bool,
        isLastPage: bool,
        formats: arrayOf(string).isRequired,
        onDownloadClick: func.isRequired,
        totalAmount: shape({
            quantity: number,
            currency: string,
        }).isRequired,
        paymentCurrency: string.isRequired,
        transaction: shape({
            idTransaction: string,
        }).isRequired,
        idFile: string,
    };

    static defaultProps = {
        transactionLines: [],
        isConfirmation: false,
        renderMoreDetails: null,
        isTicket: false,
        isLastPage: false,
        idFile: "",
    };

    state = {
        isExpanded: false,
        filter: undefined,
        filterData: null,
    };

    componentDidMount() {
        const { isConfirmation, dispatch, idFile } = this.props;
        if (isConfirmation && idFile) {
            dispatch(fileActions.getFileContentsRequest(idFile, false));
        }
        dispatch(transactionLinesActions.initializeEditedLines());
        dispatch(transactionLinesActions.setPageNumber(0));
    }

    handleCloseClick = () => {
        this.navigate(routerActions.push("/desktop"));
    };

    handleBackClick = () => {
        const { match } = this.props;
        const newRoute = match.url
            .split("/")
            .slice(0, -1)
            .join("/");

        this.navigate(routerActions.replace({ pathname: newRoute, state: { shouldLoadForm: false } }));
    };

    navigate = (action) => {
        const { dispatch } = this.props;
        dispatch(action);
    };

    hasFilters = () => {
        const { totalLines } = this.props;
        return totalLines > configUtils.get("transactions.rowsPerPage", 10);
    };

    handleCollapseClick = () => {
        this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
    };

    handleFilterSubmit = ({ filter, data }) => {
        const minAmount = data.minAmount && data.minAmount.amount;
        const maxAmount = data.maxAmount && data.maxAmount.amount;
        const { accountName, creditAccount, accountBank } = data;
        const { dispatch, transaction, isTicket } = this.props;
        const { filterData } = this.state;
        if (isTicket) {
            this.setState(
                {
                    filterData: {
                        minAmount,
                        maxAmount,
                        accountName,
                        creditAccount,
                        accountBank,
                    },
                },
                () => {
                    dispatch(transactionLinesActions.onFiltersChanged(filterData));
                    dispatch(transactionLinesActions.clearTransactionLines());
                    dispatch(
                        transactionLinesActions.listTransactionLinesRequest({
                            id: transaction.idTransaction,
                            pageNumber: 1,
                            ...this.state.filterData,
                        }),
                    );
                    dispatch(transactionLinesActions.setPageNumber(0));
                },
            );
        } else {
            this.setState({
                filter: (line) => {
                    switch (filter) {
                        case "amount": {
                            return TransactionLinesFiltersForm.filters[filter](
                                minAmount,
                                maxAmount,
                                line.creditAmountQuantity,
                            );
                        }
                        case "payee": {
                            return TransactionLinesFiltersForm.filters[filter](accountName, line.creditAccountName);
                        }
                        case "account": {
                            return TransactionLinesFiltersForm.filters[filter](creditAccount, line.creditAccountNumber);
                        }

                        case "bank":
                            return TransactionLinesFiltersForm.filters[filter](accountBank, line.bankIdentifier);
                        default: {
                            return true;
                        }
                    }
                },
                filterData: {
                    minAmount,
                    maxAmount,
                    accountName,
                    creditAccount,
                },
            });
            dispatch(transactionLinesActions.setPageNumber(0));
        }
    };

    handleCloseModal = () => {
        const { dispatch } = this.props;
        dispatch(transactionLinesActions.setIsEditingPayment(false));
    };

    handleDownloadClick = (format) => {
        const { onDownloadClick } = this.props;
        const { filter, filterData } = this.state;
        onDownloadClick({ filter, filterData }, format);
    };

    handleFinishClick = () => {
        const { dispatch } = this.props;
        dispatch(transactionLinesActions.setIsEditingPayment(false));
    };

    renderMobile = () => {
        const { isConfirmation, isTicket, transactionLines, isLastPage } = this.props;
        const { filter } = this.state;

        return (
            <TransactionLinesList
                filter={filter}
                noMoreDataText="transaction.process.noMoreTransactions"
                loadMoreText="massive.payments.line.more.results"
                noDataImage="images/coloredIcons/no-more-transactions.svg"
                noDataText="transaction.process.none"
                isReadOnly={isConfirmation || isTicket}
                isTicket={isTicket}
                lines={transactionLines}
                isLastPage={isLastPage}
            />
        );
    };

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        dispatch(transactionLinesActions.addTransactionLine(values));
    };

    renderModal(initialValues) {
        const { isEditingPayment } = this.props;

        const paymentsModal = (
            <PaymentLinesModal
                handleDismiss={this.handleCloseModal}
                hasDismissButton
                isModalDisplayed={isEditingPayment}
                title={i18nUtils.get("salaryPayment.manual.addPayment.title")}>
                <PaymentLinesForm
                    initialValues={initialValues}
                    submitButtonLabel="salaryPayment.manual.addPayment.addAndContinue"
                    cancelButtonHandler={this.handleCloseModal}
                    onSubmit={this.handleSubmit}
                />
            </PaymentLinesModal>
        );

        return ReactDOM.createPortal(paymentsModal, document.querySelector("#modal"));
    }

    renderDesktop = () => {
        const {
            isDesktop,
            isEditingPayment,
            isConfirmation,
            paymentCurrency,
            isTicket,
            transactionLines,
            isFetching,
            isLastPage,
        } = this.props;
        const { isExpanded, filter } = this.state;
        const initialValues = {
            creditAccountNumber: "",
            creditAccountName: "",
            credit: {
                currency: paymentCurrency,
                amount: "",
            },
            bankIdentifier: "",
        };
        const { match } = this.props;
        const backUrl = match.url
            .split("/")
            .slice(0, -1)
            .join("/");

        return (
            <MainContainer showLoader={isFetching}>
                <div className="above-the-fold">
                    <Container className="container--layout">
                        <Container.Column>
                            <div className="table-title-wrapper">
                                <h4 className="table-legend">
                                    <I18n id="salaryPayment.manual.list.title" />
                                </h4>
                                {this.hasFilters() && (
                                    <TransactionLinesFilters
                                        hasState={false}
                                        isExpanded={isExpanded}
                                        selectedFilter={null}
                                        onFilterClick={null}
                                        onCollapseClick={this.handleCollapseClick}
                                    />
                                )}
                            </div>

                            {!(isConfirmation || isTicket) && (
                                <PaymentLinesButtons isEditingPayment={isEditingPayment} backUrl={backUrl} />
                            )}
                            {this.hasFilters() && (
                                <TransactionLinesFiltersForm
                                    onSubmit={this.handleFilterSubmit}
                                    isExpanded={isExpanded}
                                    currency={configUtils.get("core.masterCurrency")}
                                />
                            )}
                        </Container.Column>
                    </Container>

                    <Container className="container--layout">
                        <Container.Column>
                            <TransactionLinesList
                                filter={filter}
                                noMoreDataText={isDesktop && "transaction.process.noMoreTransactions"}
                                loadMoreText={isDesktop && "massive.payments.line.more.results"}
                                noDataImage="images/coloredIcons/no-more-transactions.svg"
                                noDataText="transaction.process.none"
                                isReadOnly={isConfirmation || isTicket}
                                isTicket={isTicket}
                                lines={transactionLines}
                                isLastPage={isLastPage}
                            />
                        </Container.Column>
                    </Container>
                </div>
                {!isConfirmation && this.renderModal(initialValues)}
            </MainContainer>
        );
    };

    renderDetailsColumns = () => {
        const { totalLines, totalAmount } = this.props;

        return [
            <Container.Column key="detail.column.amount" className="col-12" sm={12} md={4}>
                <DataContent label={i18nUtils.get("transaction.process.details.heading.totalAmount")} strong>
                    <FormattedAmount className="content-data-strong" {...totalAmount} />
                </DataContent>
            </Container.Column>,
            <Container.Column key="detail.column.totalLines" className="col-12" sm={12} md={4}>
                <DataContent label={i18nUtils.get("transaction.process.details.heading.totalLines")} strong>
                    {totalLines}
                </DataContent>
            </Container.Column>,
        ];
    };

    render() {
        const { isDesktop, renderMoreDetails, isFetching, formats } = this.props;
        return (
            <Fragment>
                <PageLoading loading={isFetching}>
                    <header className="view-header">
                        <Navbar>
                            <Navbar.Item align="left">
                                <Button
                                    className="toolbar-btn view-back"
                                    onClick={this.handleBackClick}
                                    image="images/arrowLeft.svg"
                                    bsStyle="link"
                                    label="global.back"
                                />
                            </Navbar.Item>
                            <Navbar.Item className="view-title">
                                <h2>
                                    <I18n id="salaryPayment.manual.title" />
                                </h2>
                            </Navbar.Item>
                            {isDesktop && (
                                <Navbar.Item align="right">
                                    <DownloadDropdown formats={formats} onDownloadClick={this.handleDownloadClick} />
                                </Navbar.Item>
                            )}
                            <Navbar.Item align="right">
                                <Button
                                    className="toolbar-btn view-close"
                                    onClick={this.handleCloseClick}
                                    image="images/cross.svg"
                                    label="global.close"
                                />
                            </Navbar.Item>
                        </Navbar>
                        {renderMoreDetails && isDesktop && renderMoreDetails()}
                    </header>
                    <Hero renderAs={isDesktop ? Container : Carrousel} className={isDesktop && "container--layout"}>
                        {this.renderDetailsColumns()}
                    </Hero>
                    {isDesktop ? this.renderDesktop() : this.renderMobile()}
                </PageLoading>
            </Fragment>
        );
    }
}

export default PaymentLines;
