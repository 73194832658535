import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "reducers/types/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.rejectReturnRequestOrAgreement.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");

const RejectReturnRequestOrAgreement = (props) => {
    const { echeq, data, dispatch, transaction, mode, location } = props;
    const { idTransactionStatus, idTransaction } = transaction;
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        cheque_concepto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        tenencia_beneficiario_documento,
        cheque_motivo_pago,
        beneficiario_nombre,
    } = echeq;

    const activitySuffix =
        data?.activitySuffix || (echeq.estado === "RECHAZADO" ? "repudiateAgreement" : "rejectReturn");

    const echeqConcept = data?.cheque_concepto || cheque_concepto;

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.message.label`} />
                </div>
            )}

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.idEcheq.label`} />
                </span>
                <span className="data-text">{data?.cheque_id || cheque_id}</span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} />
                </span>
                <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.cuitCuilTransmitter.label`} />
                </span>
                <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.transmitterBusinessName.label`} />
                </span>
                <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.cuitCuilEndorser.label`} />
                </span>
                <span className="data-text">
                    {data?.tenencia_beneficiario_documento || tenencia_beneficiario_documento}
                </span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                </span>
                <span className="data-text">{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.cuitCuilEndorser.label`} />
                </span>
                <span className="data-text">
                    {data?.tenencia_beneficiario_documento || tenencia_beneficiario_documento}
                </span>
            </div>

            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.state.label`} />
                    </span>
                    <span className="data-text">{data?.estado || estado}</span>
                </div>
            )}

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.amount.label`} />
                </span>
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.concept.label`} />
                </span>
                {echeqConcept && (
                    <I18n id={`forms.transfers.concepts.${echeqConcept}.label`} defaultValue={echeqConcept} />
                )}
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.paymentDate.label`} />
                </span>
                <FormattedDate date={data?.fecha_pago || fecha_pago} />
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.reason.${activitySuffix}.label`} />
                </span>
                <span className="data-text">{data?.cheque_motivo_pago || cheque_motivo_pago}</span>
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${activitySuffix}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id,
            cheque_numero,
            estado,
            cheque_concepto,
            fecha_pago,
            emisor_moneda,
            emisor_cuit,
            emisor_razon_social,
            tenencia_beneficiario_documento,
            amount: data?.amount || {
                quantity: monto,
                currency: emisor_moneda,
            },
            activitySuffix,
            cheque_motivo_pago,
            beneficiario_nombre,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

RejectReturnRequestOrAgreement.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};
RejectReturnRequestOrAgreement.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(RejectReturnRequestOrAgreement);
