import React, { Component } from "react";
import { arrayOf, number, shape, string, func } from "prop-types";

import I18n from "pages/_components/I18n";
import ChatAttachment from "./ChatAttachment";

function MessageSender(props) {
    const { direction, date, idCommunicationTray } = props;

    const displayDate = (originalDate) => `${originalDate.split(" ")[0]} | ${originalDate.split(" ")[1]}`;

    if (direction === "BANK_TO_CUSTOMER") {
        return (
            <div className="message-sender">
                {displayDate(date)}
                <div>
                    <I18n id={`communications.tray.${idCommunicationTray}`} />
                    <span> &gt; </span>
                    <I18n id="communication.thread.message.me" />
                </div>
            </div>
        );
    }
    return (
        <div className="message-sender">
            {displayDate(date)}
            <div>
                <I18n id={`communications.tray.${idCommunicationTray}`} />
                <span> &gt; </span>
                <I18n id="communication.thread.message.me" />
            </div>
        </div>
    );
}

MessageSender.propTypes = {
    direction: string.isRequired,
    date: string.isRequired,
    idCommunicationTray: string.isRequired,
};

class ChatLayout extends Component {
    static propTypes = {
        attachments: arrayOf({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        scrollToBottom: func.isRequired,
        handleDownloadAttachment: func.isRequired,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
    };

    componentDidMount() {
        const { scrollToBottom } = this.props;
        scrollToBottom();
    }

    drawChatBubbles = () => {
        const { detail, attachments, handleDownloadAttachment } = this.props;

        return (
            <ul>
                {detail.map((obj, i = 0) => (
                    <li className={`messageContainer ${detail.length > 1 ? "response" : ""} ${obj.direction}`} key={i}>
                        <div key={i}>
                            <MessageSender
                                direction={obj.direction}
                                date={obj.sentDateAsString}
                                idCommunicationTray={obj.communicationTray.idCommunicationTray}
                            />
                            <div className="message-body" dangerouslySetInnerHTML={{ __html: obj.body }} />
                            {attachments &&
                                attachments[i] &&
                                attachments[i].idCommunication === obj.idCommunication &&
                                attachments[i].fileList && (
                                    <ChatAttachment
                                        attachment={attachments[i]}
                                        handleDownloadAttachment={handleDownloadAttachment}
                                    />
                                )}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        return this.drawChatBubbles();
    }
}
export default ChatLayout;
