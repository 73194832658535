import { arrayOf, bool, func, number, shape, string, objectOf, any } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as checkActions, selectors as checkSelectors } from "reducers/checks";

import * as config from "util/config";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Loader from "pages/_components/Loader";
import Checks from "./CheckDeferred";

class List extends Component {
    static propTypes = {
        filters: shape({}).isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        checks: arrayOf(shape({})).isRequired,
        moreChecks: bool.isRequired,
        idActivity: string.isRequired,
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        pageNumber: number.isRequired,
        checkType: string.isRequired,
        history: objectOf(any).isRequired,
        isFirstFetched: bool.isRequired,
    };

    fetchMoreChecks = () => {
        const { dispatch, checkType } = this.props;
        let { filters, pageNumber } = this.props;
        pageNumber += 1;
        filters = { ...filters, checkType, pageNumber };
        dispatch(checkActions.loadMoreChecksDeferredRequest(filters));
    };

    handleCheckClick = (check) => {
        const { dispatch, checkType, history } = this.props;
        const { checkNumber } = check;

        dispatch(checkActions.setCheckDeferred(checkType, check));
        const link = `/check/${checkType}/${checkNumber}/detail`;
        history.push(link);
    };

    renderItem = (check) => {
        const { isDesktop, idActivity } = this.props;
        return isDesktop ? (
            <Table.Row
                className="table-row"
                role="button"
                onClick={() => this.handleCheckClick(check)}
                key={check.checkNumber}>
                <Checks isDesktop={isDesktop} check={check} idActivity={idActivity} />
            </Table.Row>
        ) : (
            <Checks
                isDesktop={isDesktop}
                check={check}
                idActivity={idActivity}
                onClick={() => this.handleCheckClick(check)}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, idActivity } = this.props;
        return isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.checkNumber.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.idCheck.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.dateOperation.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.operationNumber.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.bank.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.dateDeposit.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id={`${idActivity}.amount.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.state.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const { checks, fetching, filters, moreChecks, isFirstFetched, isMobile } = this.props;

        const { checkTypeDeferred } = filters;

        const maxDaysBack = config.get("checks.deferred.maxDaysBack", 365);
        const maxDaysForward = config.get("checks.deferred.maxDaysForward", 365);

        const defaultFilters = {
            dateFrom: defaultDateFromList().checksDeferred.dateFrom,
            dateTo: defaultDateFromList().checksDeferred.dateTo,
            state: "0",
        };

        const withFilters = getFiltersStatus({
            filters,
            defaultFilters,
        });

        let noMoreDataText = "";
        const noDataText = `check.deferred.list.${checkTypeDeferred}.empty.label`;

        if (withFilters) {
            noMoreDataText = `check.deferred.list.${checkTypeDeferred}.noMoreData.withFilters`;
        } else {
            noMoreDataText = `check.deferred.list.${checkTypeDeferred}.noMoreData.default`;
        }

        return (
            <>
                {fetching && isFirstFetched ? (
                    <Loader />
                ) : (
                    <ProductList
                        fetching={fetching}
                        firstFetched={isFirstFetched}
                        items={checks}
                        renderItem={this.renderItem}
                        lastPage={!moreChecks}
                        onLoadMoreClick={this.fetchMoreChecks}
                        filters={filters || {}}
                        noMoreDataText={noMoreDataText}
                        loadMoreText="check.deferred.list.searchMoreChecks"
                        noDataText={noDataText}
                        noFiltersDataText="check.deferred.list.filters.none"
                        defaultFilters={defaultFilters}
                        listMessagesParams={{
                            noMoreDataText: {
                                DAYS_BACK: maxDaysBack,
                                DAYS_FORWARD: maxDaysForward,
                            },
                            noDataText: {
                                DAYS_BACK: maxDaysBack,
                                DAYS_FORWARD: maxDaysForward,
                            },
                            noFiltersDataText: {
                                DAYS_BACK: maxDaysBack,
                                DAYS_FORWARD: maxDaysForward,
                            },
                        }}
                        isMobile={isMobile}>
                        {this.renderList}
                    </ProductList>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: checkSelectors.getFetching(state),
    filters: checkSelectors.getFilters(state),
    checks: checkSelectors.getChecksDeferred(state),
    moreChecks: checkSelectors.getMoreChecks(state),
    pageNumber: checkSelectors.getPageNumber(state),
    isFirstFetched: checkSelectors.getFirstFetched(state),
});

export default connect(mapStateToProps)(List);
