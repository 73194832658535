import React from "react";
import { bool, string } from "prop-types";
import Image from "pages/_components/Image";

function EnvironmentTag({ type, name, showIcon }) {
    return (
        <div className="environment-tag">
            <span className="visually-hidden"> {`${type}, ${name}`} </span>
            {showIcon && <Image src={`images/${type}.svg`} />}
            <span aria-hidden>{name}</span>
        </div>
    );
}

EnvironmentTag.defaultProps = {
    showIcon: true,
};

EnvironmentTag.propTypes = {
    name: string.isRequired,
    type: string.isRequired,
    showIcon: bool,
};

export default EnvironmentTag;
