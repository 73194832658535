import React, { useRef, useEffect } from "react";
import { Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import * as i18nUtils from "util/i18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { actions as echeqsActions, selectors as echeqsSelectors } from "reducers/echeqs";
import { compose } from "redux";
import { connect } from "react-redux";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as configUtils from "util/config";
import { actions as notificationActions } from "reducers/notification";
import { selectors as formSelectors } from "reducers/form";
import { format } from "date-fns";
import moment from "moment";
import MultipleEcheqsGrid from "./MultipleEcheqsGrid";
import EmitEcheqCommonFields from "./EmitEcheqCommonFields";

const EmitPersonalizedEcheqs = (props) => {
    const { ID_FORM, genericProps, isMobile, dispatch, echeqFormProps, validatedEcheqsList, transaction } = props;

    const {
        conceptsList,
        handleOtherFields,
        otherFieldsSelected,
        data,
        previewData,
        setFieldValue,
        mode,
        emissionTypeSelected,
        disableComponents,
    } = echeqFormProps;
    const echeqsQuantity = data?.echeqs?.length || previewData?.echeqs?.length;
    const previousValidatedList = useRef([]);
    const maxEcheqsToEmit = parseInt(configUtils.get("amount_echeq_max", "10"), 10);

    useEffect(() => {
        if (
            mode === "edit" &&
            Object.keys(transaction)?.length > 0 &&
            transaction?.idTransactionStatus === "DRAFT" &&
            validatedEcheqsList.length === 0 &&
            transaction?.data?.emissionType?.[0] === "personalized"
        ) {
            const dateToday = new Date();
            const finalEcheqsList = transaction.data.echeqs.map((echeq) => ({
                ...echeq,
                fecha_pago:
                    moment(echeq.fecha_pago).toDate() >= dateToday
                        ? echeq.fecha_pago
                        : format(dateToday, "YYYY-MM-DDTHH:mm:ss.SSSXZ"),
                cheque_numero: null,
                numero_chequera: null,
                cmc7: null,
            }));
            previousValidatedList.current = finalEcheqsList;
            dispatch(echeqsActions.setValidatedPersonalizedEcheqsFromTransaction(finalEcheqsList));
        }

        if (
            JSON.stringify(previousValidatedList.current) !== JSON.stringify(validatedEcheqsList) &&
            validatedEcheqsList?.length === maxEcheqsToEmit
        ) {
            dispatch(echeqsActions.setShowPersonalizedEcheqModal(false));
        }
        previousValidatedList.current = validatedEcheqsList;
    }, [validatedEcheqsList, mode, transaction]);

    const totalAmountToShow = previewData?.totalAmount || data?.totalAmount || {};

    const setFieldsAfterRemove = (setFieldValueFunc) => {
        setFieldValueFunc("originAccount", otherFieldsSelected?.originAccount || null);
        setFieldValueFunc("emissionType", emissionTypeSelected);
        setFieldValueFunc("concept", [otherFieldsSelected?.concept]);
        setFieldValueFunc("reason", otherFieldsSelected?.reason);
        setFieldValueFunc("reference", otherFieldsSelected?.reference);
        setFieldValueFunc("mode", ["crossed"]);
    };

    return (
        <>
            <EmitEcheqCommonFields {...echeqFormProps} hideHR={mode === "view"} />

            {mode === "edit" ? (
                <div className="form-group">
                    <Button
                        block={isMobile}
                        label={`${ID_FORM}.personalized.addEcheq`}
                        bsStyle="outline"
                        image="images/plus.svg"
                        onClick={() => {
                            if (validatedEcheqsList.length < maxEcheqsToEmit) {
                                dispatch(echeqsActions.setShowPersonalizedEcheqModal(true));
                            } else {
                                dispatch(
                                    notificationActions.showNotification(
                                        i18nUtils.get(`${ID_FORM}.personalized.addEcheq.error`),
                                        "error",
                                        ["form"],
                                    ),
                                );
                            }
                        }}
                        disabled={disableComponents}
                    />
                </div>
            ) : (
                <div className="data-wrapper">
                    <I18n
                        id={`${ID_FORM}.multiple.echeqsQuantity.label`}
                        componentProps={{ className: "data-label" }}
                    />
                    <span> {echeqsQuantity} </span>
                </div>
            )}

            {validatedEcheqsList?.length > 0 && (
                <>
                    {mode !== "view" && (
                        <MultipleEcheqsGrid
                            emissionType="personalized"
                            idForm={ID_FORM}
                            isDesktop={!isMobile}
                            echeqsList={validatedEcheqsList}
                            showProcessedEcheqItems={echeqFormProps?.mode !== "edit"}
                            dispatch={dispatch}
                            setFieldsAfterRemove={setFieldsAfterRemove}
                            setFieldValue={setFieldValue}
                            disableComponents={disableComponents}
                        />
                    )}

                    {mode !== "edit" && totalAmountToShow && (
                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.totalAmount.label`} componentProps={{ className: "data-label" }} />
                            <FormattedAmount
                                currency={totalAmountToShow?.currency || "ARS"}
                                quantity={totalAmountToShow?.quantity || 0}
                            />
                        </div>
                    )}

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="mode"
                        name="mode"
                        idField="mode"
                        renderAs="combo"
                        optionList={[
                            {
                                id: "crossed",
                                label: i18nUtils.get(`${ID_FORM}.mode.crossed.label`),
                            },
                        ]}
                        value={["crossed"]}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="concept"
                        name="concept"
                        idField="concept"
                        renderAs="combo"
                        optionList={conceptsList}
                        onChange={(e) => handleOtherFields("concept", e.id)}
                        value={data?.concept || [otherFieldsSelected?.concept]}
                    />

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="reason"
                        name="reason"
                        idField="reason"
                        onChange={(e) => handleOtherFields("reason", e)}
                        value={data?.reason || otherFieldsSelected?.reason}
                        maxLength={150}
                        additionalClassName="reason-input"
                    />
                </>
            )}
        </>
    );
};
EmitPersonalizedEcheqs.propTypes = {
    ID_FORM: string.isRequired,
    amountData: shape({}).isRequired,
    genericProps: shape({}).isRequired,
    isMobile: bool.isRequired,
    errors: shape({}).isRequired,
    validatedEcheqsList: shape([]).isRequired,
    showModal: bool.isRequired,
    dispatch: func.isRequired,
    transaction: shape({}).isRequired,
    echeqFormProps: {
        handleOtherFields: func.isRequired,
        otherFieldsSelected: shape({}).isRequired,
        data: shape({}).isRequired,
        conceptsList: shape([]).isRequired,
        mode: string.isRequired,
        disableComponents: bool.isRequired,
    }.isRequired,
};

const mapStateToProps = (state) => ({
    errors: echeqsSelectors.getPersonalizedEcheqErrors(state),
    validatedEcheqsList: echeqsSelectors.getPersonalizedEcheqsList(state),
    showModal: echeqsSelectors.getShowPersonalizedEcheqModal(state),
    transaction: formSelectors.getTransaction(state),
});

export default compose(connect(mapStateToProps))(EmitPersonalizedEcheqs);
