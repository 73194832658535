import React, { Component, Fragment } from "react";
import { bool, string, func } from "prop-types";

import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";
import formField from "pages/forms/_components/_fields/_commons/formField";
import { Field } from "formik";
import * as utilI18n from "util/i18n";
import I18n from "pages/_components/I18n";

class Termsandconditions extends Component {
    static propTypes = {
        showAcceptOption: bool.isRequired,
        editing: bool,
        i18n: string,
        name: string,
        value: string,
        setValue: func.isRequired,
        termsAndConditions: string,
        isOnlyText: bool,
        link: string,
        nameLink: string,
    };

    static defaultProps = {
        editing: false,
        i18n: "",
        name: "",
        value: "",
        termsAndConditions: "termsAndConditions",
        isOnlyText: false,
        link: "",
        nameLink: "",
    };

    componentDidMount() {
        const { showAcceptOption, setValue } = this.props;
        if (!showAcceptOption) {
            setValue(true);
        }
    }

    handleChange = () => {
        const { setValue, value } = this.props;
        setValue(!value);
    };

    renderTextTermsAndConditions() {
        const { i18n, termsAndConditions, link, nameLink } = this.props;
        const linkIndex = i18n(termsAndConditions).search(nameLink);
        const preLink = i18n(termsAndConditions).slice(0, linkIndex);
        const postLink = i18n(termsAndConditions).slice(linkIndex + nameLink.length);
        return (
            <>
                <I18n defaultValue={preLink} component="span" />
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {nameLink}
                </a>
                <I18n defaultValue={postLink} component="span" />
            </>
        );
    }

    render() {
        const { editing, i18n, name, value, showAcceptOption, termsAndConditions, isOnlyText, link } = this.props;
        if (editing && !isOnlyText) {
            return (
                <Fragment>
                    <Field
                        component="textarea"
                        type="text"
                        className="input-group-text-area input-group"
                        value={i18n(termsAndConditions)}
                        aria-label={utilI18n.get("global.termAndConditions")}
                        editing={false}
                        id={name}
                        onChange={() => {}} // Ignores onChange event of Checkbox
                    />
                    {showAcceptOption && (
                        <Checkbox
                            value={name}
                            label={`${i18n("showAcceptOptionText")}`}
                            checked={!!value}
                            onChange={this.handleChange}
                        />
                    )}
                </Fragment>
            );
        }

        if (isOnlyText) {
            return (
                <div className="text-termsAndConditions">
                    {link ? this.renderTextTermsAndConditions() : <span>{i18n(termsAndConditions)}</span>}
                </div>
            );
        }
        return <div className="data-desc">{i18n("termsAndConditions")}</div>;
    }
}

export default formField({
    formClass: "form-group--termsAndConditions",
})(Termsandconditions);
