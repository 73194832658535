import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace, push } from "react-router-redux";
import { types, selectors as sessionSelectors, actions as sessionActions } from "reducers/session";

import { actions as notificationActions } from "reducers/notification";
import globalTypes from "reducers/types/global";
import { types as statusTypes } from "reducers/status";
import { types as desktopTypes } from "reducers/desktop";
import { selectors as fingerprintSelectors, types as fingerprintTypes } from "reducers/fingerprint";

import * as session from "middleware/session";
import * as i18n from "util/i18n";
import { mapActiveEnvironment } from "util/security";
import { persistor } from "../store";

const sagas = [
    takeLatest(types.LOGOUT_REQUEST, handleLogoutRequest),
    takeLatest(types.CHANGE_ENVIRONMENT_REQUEST, handleChangeEnvironmentRequest),
    takeLatest(types.CHANGE_ENVIRONMENT_SIGN_TYC, handleSignTyc),
    takeLatest(types.EXTEND, extendSession),
    takeLatest(types.EXPIRE, expireSession),
];

export default sagas;

function* handleLogoutRequest() {
    try {
        yield call(session.logout);
        persistor.purge();
        const showAgain = yield select(fingerprintSelectors.isShowAgain);

        if (showAgain) {
            yield put({ type: fingerprintTypes.FINGERPRINT_SHOW_WIDGET });
        }
    } finally {
        yield put({ type: globalTypes.CLEAN_UP });
    }
}

export function* handleSignTyc({ acceptConditions, formikBag }) {
    const tempData = yield select(sessionSelectors.getTempData);
    yield put(
        sessionActions.changeEnvironment(
            tempData.idEnvironment,
            tempData.rememberEnvironment,
            acceptConditions,
            formikBag,
        ),
    );
}
export function* handleChangeEnvironmentRequest({
    idEnvironment,
    rememberEnvironment,
    acceptConditions,
    formikBag,
    shouldNavigate = true,
}) {
    const response = yield call(session.changeEnvironment, idEnvironment, rememberEnvironment, acceptConditions);

    const { code, data } = response.data;

    if (code === "COR020W") {
        const { message } = response.data;
        if (data.cantAccessByRestriction) {
            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get(data.cantAccessByRestriction), "error", ["desktop"]),
            );
            yield put(push("/desktop"));
        } else {
            const errorMessage =
                data.idEnvironmentToChange && typeof data.idEnvironmentToChange === "string"
                    ? data.idEnvironmentToChange
                    : message;

            yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
            yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            yield put(push("/settings"));
        }
    } else if (code === "API520W") {
        yield put({ type: types.CHANGE_ENVIRONMENT_TEMP_DATA, tempData: { idEnvironment, rememberEnvironment } });
        yield put(push("/settings/TyC"));
    } else if (code === "API526W") {
        const environments = yield select(sessionSelectors.getEnvironments);
        const environmentName = environments[idEnvironment].name;
        yield put({ type: types.SET_ALERT_ENVIRONMENT_EXPIRE, daysToExpire: false, environmentName });
        yield put({ type: types.CHANGE_ENVIRONMENT_FAILURE });
        yield put(push("/desktop"));
    } else {
        let forms = null;
        if (data.forms) {
            forms = {};

            for (let i = 0; i < data.forms.length; i++) {
                let category = forms[data.forms[i].category];
                if (!category) {
                    category = [];
                    forms[data.forms[i].category] = category;
                }
                category.push(data.forms[i]);
            }
        }
        const { showAlertExpire, daysToExpire } = data;
        if (showAlertExpire) {
            yield put({
                type: types.SET_ALERT_ENVIRONMENT_EXPIRE,
                daysToExpire,
                environmentName: data.activeEnvironmentName,
            });
        }

        const environment = yield call(mapActiveEnvironment, data, forms);

        const { environments, isAdministrator, enabledAssistant, hasAccountQr } = response.data.data;
        yield put({ type: types.TRANSFERS_CHECKACCOUNTS_REQUEST });
        yield put({
            type: types.CHANGE_ENVIRONMENT_SUCCESS,
            environment,
            environments,
            isAdministrator,
            hasAccountQr,
        });

        yield put({ type: desktopTypes.LOAD_LAYOUT_REQUEST });
        yield put({ type: types.SET_ENABLED_ASSISTANT, enabledAssistant });

        if (shouldNavigate) {
            yield put(replace("/desktop"));
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* extendSession() {
    yield call(session.extend);
    yield put({ type: types.EXTEND_SUCCESS });
}

function* expireSession({ lastHref }) {
    // dispatching action to save last href in status state
    yield put({ type: statusTypes.SAVE_LAST_HREF, lastHref });
    yield put({ type: types.LOGOUT_REQUEST });

    yield put(notificationActions.showNotification(i18n.get("session.expired"), "error", ["login"]));
}
