import * as configUtils from "util/config";

export const invitationCodeMask = () => {
    // TODO - Mariva : Agregar parametrizacion de cantidad de caracteres por grupo (4)
    const groups = configUtils.get("validationCodes.id.groups.length");

    let mask = new Array(4).fill(/[a-zA-Z\d]/);
    for (let i = 0; i < groups - 1; i++) {
        mask = [...mask, "-", ...new Array(4).fill(/[a-zA-Z\d]/)];
    }

    return mask;
};

export const timeCodeMask = () => [/\d/, /\d/, ":", /\d/, /\d/];

export const timeRegex = () => "^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";

export const cuitMask = [
    /[\d]/,
    /[\d]/,
    "-",
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    "-",
    /[\d]/,
];

export default {};
