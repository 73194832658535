import React, { Component } from "react";
import { connect } from "react-redux";

import { actions as recoverUserActions } from "reducers/recoverUser";
import { actions as loginActions } from "reducers/login";

import { func, bool } from "prop-types";

import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Step2Content from "pages/recoverUser/_components/Step2Content";
import HeaderLogin from "../_components/header/HeaderLogin";

class RecoveryUserStep2 extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(recoverUserActions.recoveryUserCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        return (
            <>
                <Notification scopeToShow="recoverUser" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <Step2Content {...this.props} />
                    </MainContainer>
                </div>
            </>
        );
    }
}

RecoveryUserStep2.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

export default connect()(RecoveryUserStep2);
