import React, { Component, Fragment } from "react";
import { bool, node, arrayOf, oneOfType } from "prop-types";

import Loader from "pages/_components/Loader";

/**
 * * Puede considerarse ubicarlo en sitios diferentes para bloquear toda o parte de la app
 */
class PageLoading extends Component {
    static propTypes = {
        loading: bool,
        children: oneOfType([arrayOf(node), node]),
    };

    static defaultProps = {
        loading: true,
        children: null,
    };

    render() {
        const { children, loading } = this.props;

        return <Fragment>{loading ? <Loader /> : children}</Fragment>;
    }
}

export default PageLoading;
