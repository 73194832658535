import { call, put, takeLatest, select } from "redux-saga/effects";

import { types, actions, selectors } from "reducers/userTransactions";
import { actions as notificationActions } from "reducers/notification";
import list from "middleware/userTransactions";
import * as i18n from "util/i18n";
import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.LIST_REQUEST, listRequest),
    takeLatest(types.LOAD_MORE_REQUEST, fetchMore),
    takeLatest(types.USER_TRANSACTION_DETAIL_REQUEST, userTransactionDetailRequest),
];

export default sagas;

function* listRequest({ filters, page, formikBag }) {
    const response = yield call(list, filters, page);

    if (response.type === "W") {
        yield put(actions.listFailure());
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["userTransactions"]),
            );
        }
    } else {
        const { userTransactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.listSuccess(userTransactions, pageNumber, totalPages));
    }
}

function* userTransactionDetailRequest() {
    const response = yield call(list);

    if (response.type === "W") {
        yield put(actions.listFailure());
    } else {
        const { userTransactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.listSuccess(userTransactions, pageNumber, totalPages));
    }
}

function* fetchMore({ filters }) {
    const page = yield select(selectors.getPageNumber);
    const response = yield call(list, filters, page + 1);
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["userTransactions"]),
        );
    } else {
        const { userTransactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadMoreSuccess(userTransactions, pageNumber, totalPages));
    }
}
