import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { func, shape, node, bool, oneOfType, array } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";

import { actions, selectors } from "reducers/widgets";

import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import WidgetLoading from "pages/_components/WidgetLoading";
import { resizableRoute } from "pages/_components/Resizable";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import WidgetHeader from "../_components/WidgetHeader";
import Overlay from "../_components/Overlay";

import "./_accounts.scss";

class Accounts extends Component {
    static propTypes = {
        isFetching: bool,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        accountsList: oneOfType([array]).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
        isEditable: bool,
    };

    static defaultProps = {
        closeButton: null,
        isFetching: false,
        isDesktop: false,
        isEditable: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("accounts"));
    }

    renderItem = (account, isDesktop) => {
        const finalPath = {
            pathname: `/accounts/${account.idProduct}`,
        };
        if (isDesktop) {
            return (
                <div className="table-row-container" key={account.idProduct}>
                    <Link aria-describedby={account.idProduct} to={finalPath} role="menuitem">
                        <div key={account.idProduct} className="table-row">
                            <div className="table-data data-name">
                                {account.productAlias || i18nUtils.get(`desktop.widgets.accounts.noAlias.label`)}
                            </div>
                            <div className="table-data data-details">
                                <div className="ui-dflex-column">
                                    <span className="account-data-label">
                                        {i18nUtils.get(`productType.${account.productType}`)}
                                    </span>
                                    <span className="data-info">{account.formattedAccountNumber}</span>
                                </div>
                            </div>
                            <div className="table-data data-number">
                                <div className="data-label">
                                    {i18nUtils.get(`desktop.widgets.accounts.balance.label`)}
                                </div>{" "}
                                <FormattedAmount quantity={account.balance} currency={account.currency} />
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }
        return (
            <Link aria-describedby={account.idProduct} to={finalPath} role="menuitem">
                <Col key={account.idProduct} className="col-12">
                    <Row className="data-wrapper text-center">
                        <span className="data-name">
                            {account.productAlias || i18nUtils.get(`desktop.widgets.accounts.noAlias.label`)}
                        </span>
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="account-data-label">
                            {i18nUtils.get(`productType.${account.productType}`)}
                        </span>
                        {account.formattedAccountNumber}
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">{i18nUtils.get(`desktop.widgets.accounts.balance.label`)}</span>
                        <FormattedAmount quantity={account.balance} currency={account.currency} />
                    </Row>
                    <Row className="data-wrapper text-right">
                        <hr />
                        <SeuoLabel />
                    </Row>
                </Col>
            </Link>
        );
    };

    renderList = () => {
        const { accountsList, isFetching, isDesktop, isEditable } = this.props;

        const ArrowLeft = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-left.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const ArrowRight = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-right.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: config.get("accounts.productsPerWidget"),
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 860,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        if (!accountsList.length) {
            if (!isFetching) {
                return (
                    <NoProduct text="desktop.widgets.accounts.empty" imagePath="images/coloredIcons/component.svg" />
                );
            }
        }
        if (isDesktop) {
            return (
                <WidgetLoading loading={!accountsList.length && isFetching}>
                    {accountsList ? (
                        <div className="table widget--accounts" aria-hidden={isEditable}>
                            <div className="table-body">
                                {accountsList.map((item) => this.renderItem(item, isDesktop))}
                            </div>
                            <SeuoLabel />
                        </div>
                    ) : null}
                </WidgetLoading>
            );
        }
        return (
            <Slider {...settings} dots infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                {accountsList.map((account) => (
                    <div key={account.idProduct}>{this.renderItem(account, isDesktop)}</div>
                ))}
            </Slider>
        );
    };

    render() {
        const uiAutomationProp = { "data-ui-automation": "widgetAccounts" };
        const { isDesktop, draggableItemProps, closeButton, isEditable } = this.props;

        const widgetTitle = i18nUtils.get("desktop.widgets.accounts.title");

        if (isDesktop) {
            return (
                <WidgetList name="accounts" shouldMapList={false}>
                    {() => (
                        <div
                            role={isEditable ? "button" : ""}
                            className="newWidget"
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader title={widgetTitle} action={closeButton} />
                            {this.renderList()}
                            <Overlay />
                        </div>
                    )}
                </WidgetList>
            );
        }
        return (
            <div
                role={isEditable ? "button" : ""}
                className="newWidget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={widgetTitle} action={closeButton} />
                <div className="widget--accounts" aria-hidden={isEditable}>
                    {this.renderList()}
                </div>
                <Overlay />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: selectors.getWidget(state, "accounts").isFetching,
    accountsList: selectors.getWidget(state, "accounts").data.accounts,
});
export default compose(connect(mapStateToProps), resizableRoute)(Accounts);
