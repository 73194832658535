import { Field, Form, withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { any, bool, func, objectOf, shape, string } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as massPaymentsActions, selectors as massivePaymentsSelector } from "reducers/massPayments";

import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";

import Button from "pages/_components/Button";
import FrequentRecipients from "pages/transfers/FrequentRecipients";
import * as FormFieldsComponents from "../_components/_fields/Index";
import Notification from "../../_components/Notification";
import Alert from "../../_components/Alert/Alert";

const ID_ACTIVITY = "pay.transfers.massive.payment.create.send";

const UNIQUE_CBU_MASK = [
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
    /[\d]/,
];

const CreateMassivePayment = (props) => {
    const {
        dispatch,
        location,
        handleShowModal,
        paymentHeader,
        isSaving,
        values,
        currentLang,
        clearPaymentSelected,
        formErrors,
        setErrors,
        resetForm,
        isSubmitting,
        paymentSelected,
        isDesktop,
        setFieldValue,
        history,
    } = props;

    const [amountData, setAmountData] = useState({
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: paymentHeader?.currency,
                label: i18nUtils.get(`currency.label.${paymentHeader?.currency}`),
            },
        ],
    });
    const [showNotification, setShowNotification] = useState(false);
    const [isFrequentDestinationsShowed, setStatusFrequentDestination] = useState(false);

    const mode = paymentSelected ? "edit" : "create";
    const mapsFieldsToChange = new Map([
        ["name", "name"],
        ["cuit", "cuit"],
        ["cbu", "cbu"],
        ["reference", "reference"],
    ]);

    const handleUploadCompletion = () => {
        clearPaymentSelected();
        dispatch(massPaymentsActions.clearErrors());
        handleShowModal();
    };

    useEffect(() => {
        dispatch(massPaymentsActions.clearErrors());
        setErrors([]);
        return () => {
            dispatch(massPaymentsActions.clearErrors());
            setErrors([]);
        };
    }, []);

    useEffect(() => {
        setAmountData({
            decimalSeparator: ",",
            precision: 2,
            thousandsSeparator: ".",
            options: [
                {
                    id: paymentHeader?.currency,
                    label: i18nUtils.get(`currency.label.${paymentHeader?.currency}`),
                },
            ],
        });
    }, [dispatch, location]);

    useEffect(() => {
        setShowNotification(false);
        if (Object.keys(formErrors).length > 0) {
            if (formErrors?.NO_FIELD) {
                setShowNotification(true);
            } else {
                setErrors(formErrors);
            }
        } else if (isSubmitting && !isSaving) {
            resetForm();
            if (mode === "create") {
                if (values.isSecondButton) {
                    handleUploadCompletion();
                }
            } else if (mode === "edit") {
                handleUploadCompletion();
            }
        }
    }, [formErrors, isSubmitting, isSaving]);

    const genericProps = {
        mode: "edit",
        lang: currentLang,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const handleOnClickItem = (item) => {
        setStatusFrequentDestination(false);
        setFieldValue("name", item.headline);
        setFieldValue("cuit", item.cuit);
        setFieldValue("cbu", item.destinationIdentifier);
    };

    const renderFields = () => (
        <>
            <div className={`form-group ${isFrequentDestinationsShowed ? "ui-mb-0" : "ui-mb-3"}`}>
                <Button
                    image="images/ui-icons/ui-add-user.svg"
                    label="forms.pay.transfers.massive.payment.create.recipientAddressBook.label"
                    bsStyle="link"
                    className="btn-link--lowerCase btn-link--wIcon"
                    onClick={() => setStatusFrequentDestination(true)}
                />
            </div>

            {isFrequentDestinationsShowed && (
                <>
                    <FrequentRecipients
                        mapsFieldsToChange={mapsFieldsToChange}
                        setFieldValueToChange={setFieldValue}
                        changeStatusModal={setStatusFrequentDestination}
                        renderAs="intoModal"
                        showTitle={false}
                        showHeader={false}
                        handleOnClickItem={handleOnClickItem}
                        resetOnUnmount={false}
                        isMobile={!isDesktop}
                        history={history}
                    />
                    <hr className="ui-mt-6 ui-mb-6" />
                </>
            )}
            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="name"
                name="name"
                idField="name"
                maxLength={50}
                idForm="pay.transfers.massive.payment.create"
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="cuit"
                name="cuit"
                idField="cuit"
                mask={maskUtils.cuitMask}
                idForm="pay.transfers.massive.payment.create"
                maxLength={12}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="cbu"
                name="cbu"
                idField="cbu"
                maxLength={22}
                mask={UNIQUE_CBU_MASK}
                idForm="pay.transfers.massive.payment.create"
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Amount}
                data={amountData}
                key="amount"
                name="amount"
                idField="amount"
                handleChange={() => {}}
                amountNull
                value={values.amount}
                idForm="pay.transfers.massive.payment.create"
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reference"
                name="reference"
                idField="reference"
                maxLength={11}
                idForm="pay.transfers.massive.payment.create"
            />
        </>
    );

    return (
        <>
            <Notification scopeToShow="payments/add" />
            <Form className="form-content">
                {showNotification && (
                    <Alert
                        icon="images/ui-icons/ui-exclamation-red.svg"
                        closedButton={() => setShowNotification(!showNotification)}>
                        {formErrors?.NO_FIELD}
                    </Alert>
                )}
                {renderFields()}
                <div className="form-footer">
                    {mode !== "edit" ? (
                        <>
                            {isDesktop && (
                                <Button
                                    type="submit"
                                    label="forms.pay.transfers.massive.payment.create.action.saveAndAddAnother.label"
                                    bsStyle="outline"
                                    onClick={() => setFieldValue("isSecondButton", false, false)}
                                    loading={isSaving}
                                />
                            )}
                            <Button
                                type="submit"
                                label="forms.pay.transfers.massive.payment.create.action.finishUpload.label"
                                bsStyle="primary"
                                onClick={() => setFieldValue("isSecondButton", true, false)}
                                loading={isSaving}
                                block={!isDesktop}
                            />
                            {!isDesktop && (
                                <Button
                                    type="submit"
                                    label="forms.pay.transfers.massive.payment.create.action.saveAndAddAnother.label"
                                    bsStyle="outline"
                                    loading={isSaving}
                                    onClick={() => setFieldValue("isSecondButton", false, false)}
                                    block
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {isDesktop && (
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleShowModal}
                                />
                            )}
                            <Button type="submit" label="global.save" bsStyle="primary" loading={isSaving} />
                            {!isDesktop && (
                                <Button
                                    type="button"
                                    label="global.cancel"
                                    bsStyle="outline"
                                    onClick={handleShowModal}
                                />
                            )}
                        </>
                    )}
                </div>
            </Form>
        </>
    );
};

CreateMassivePayment.defaultProps = {
    paymentSelected: null,
};

CreateMassivePayment.propTypes = {
    values: shape({}).isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    currentLang: string.isRequired,
    handleShowModal: func.isRequired,
    clearPaymentSelected: func.isRequired,
    paymentHeader: shape({}).isRequired,
    isSaving: bool.isRequired,
    isSubmitting: bool.isRequired,
    paymentSelected: shape({}),
    formErrors: shape({}).isRequired,
    setErrors: func.isRequired,
    resetForm: func.isRequired,
    isDesktop: bool.isRequired,
    setFieldValue: func.isRequired,
    history: objectOf(any).isRequired,
};

const mapStateToProps = (state) => ({
    currentLang: i18nSelectors.getLang(state),
    paymentHeader: massivePaymentsSelector.getPaymentHeader(state),
    isSaving: massivePaymentsSelector.isSaving(state),
    formErrors: massivePaymentsSelector.getFormErrors(state),
    location: state.router.location,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            amount: {
                currency: props?.paymentHeader?.currency,
                quantity: props?.paymentSelected?.amount?.quantity || "",
            },
            name: props?.paymentSelected?.name || "",
            reference: props?.paymentSelected?.reference || "",
            cbu: props?.paymentSelected?.cbu || "",
            cuit: props?.paymentSelected?.cuit || "",
            isSecondButton: "none",
        }),
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch, paymentSelected, paymentHeader } = formikBag.props;

            const objectToSave = {
                debitAccount: paymentHeader?.account?.idProduct,
                name: values?.name,
                cuit: values?.cuit,
                cbu: values?.cbu,
                amount: values?.amount,
                reference: values?.reference,
            };

            dispatch(massPaymentsActions.clearErrors());
            dispatch(massPaymentsActions.addOrEditPayment(objectToSave, paymentSelected?.id, formikBag));
        },
    }),
)(CreateMassivePayment);
