import React from "react";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import * as configUtils from "util/config";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import { selectors as suppliersSelectors, actions as supplierActions } from "reducers/paymentToSuppliers";
import { connect } from "react-redux";
import { getKeyForSupplierValidation } from "util/string";

const ButtonToDownloadMultipleItems = ({
    dispatch,
    linesDetail,
    paymentType,
    headInfo,
    vouchersToDownload,
    fetchingDownloadFile,
}) => {
    const multipleDownloadLimit = configUtils.get("transactions.maxLines.to.download", 50);

    const handleDownloadMultiple = () => {
        const keyDetailForValidation = getKeyForSupplierValidation(paymentType);

        const selectedVouchersToDownload = linesDetail.filter((detail) =>
            vouchersToDownload.includes(detail[keyDetailForValidation]),
        );

        dispatch(supplierActions.downloadVoucherDetails(paymentType, "multiple", headInfo, selectedVouchersToDownload));
    };

    return (
        <>
            <div className="ui-dflex">
                <Button
                    label="pays.supplier.list.file.query.button.downloadSelection.label"
                    image="images/ui-icons/ui-download.svg"
                    onClick={(e) => handleDownloadMultiple(e)}
                    bsStyle="outline"
                    loading={fetchingDownloadFile}
                    block={false}
                />
            </div>
            <I18n
                id="forms.massivePayments.transactions.maxLines.to.download"
                MAXLINES={multipleDownloadLimit}
                componentProps={{ className: "ui-body-small ui-mt-1" }}
            />
        </>
    );
};

ButtonToDownloadMultipleItems.propTypes = {
    dispatch: func.isRequired,
    paymentType: string.isRequired,
    headInfo: shape({}).isRequired,
    linesDetail: oneOfType([array]).isRequired,
    vouchersToDownload: oneOfType([array]).isRequired,
    fetchingDownloadFile: bool.isRequired,
};

const mapStateToProps = (state) => ({
    vouchersToDownload: suppliersSelectors.getVouchersToDownload(state),
    fetchingDownloadFile: suppliersSelectors.getFetchingDownloadFile(state),
});

export default connect(mapStateToProps)(ButtonToDownloadMultipleItems);
