import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { arrayOf, func, shape, string } from "prop-types";
import { selectors as loginSelectors, actions as loginActions } from "reducers/login";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { getArray } from "util/config";

class RegionSelection extends Component {
    static propTypes = {
        activeRegion: string.isRequired,
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }),
        supportedRegions: arrayOf(string).isRequired,
        legendTextID: string,
    };

    static defaultProps = {
        history: null,
        legendTextID: "settings.changeRegion",
    };

    handleClick = (region) => {
        const { activeRegion, dispatch } = this.props;

        if (region !== activeRegion) {
            dispatch(loginActions.setRegion(region));
        }
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { activeRegion, supportedRegions, legendTextID } = this.props;
        return (
            <Fragment>
                <div className="mtop-4rem">
                    <Head
                        title="settings.changeRegion"
                        onBack={this.backButtonAction}
                        arrowBackClass="arrow-back-login"
                    />
                </div>
                <Notification scopeToShow="changeRegion" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center container--layout-regions">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                        <fieldset className="form-group">
                                            {legendTextID && (
                                                <I18n
                                                    id={legendTextID}
                                                    component="legend"
                                                    componentProps={{ className: "visually-hidden" }}
                                                />
                                            )}
                                            <Row className="selection-list region-relection">
                                                {Object.keys(supportedRegions).map((idRegion) => (
                                                    <div
                                                        className="c-control c-control--radio two-columns"
                                                        key={`region-${idRegion}`}>
                                                        <input
                                                            defaultChecked={
                                                                supportedRegions[idRegion] === activeRegion
                                                                    ? "checked"
                                                                    : ""
                                                            }
                                                            id={`region-${supportedRegions[idRegion]}`}
                                                            type="radio"
                                                            name="region"
                                                            className="c-control-input"
                                                            onClick={() => this.handleClick(supportedRegions[idRegion])}
                                                        />
                                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                        <label
                                                            className="c-control-label c-control-label--environment"
                                                            htmlFor={`region-${supportedRegions[idRegion]}`}>
                                                            <I18n
                                                                id={`settings.regions.label.${supportedRegions[idRegion]}`}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </Row>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    supportedRegions: getArray("frontend.regions") ? getArray("frontend.regions").sort() : [],
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(RegionSelection);
