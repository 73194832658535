import * as API from "middleware/api";
import { format } from "date-fns";

export const loadListRequest = (filters, onlyPendings, pendingDispatch) => {
    let { dateFrom, dateTo } = filters;
    const { pageNumber, minAmount, maxAmount, channel, client, state, transactionType, keyWord } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/transactions.list", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        onlyPendings,
        pendingDispatch,
        client,
        state: state ? state[0] : null,
        transactionType: transactionType ? transactionType[0] : null,
        keyWordTransactionType: keyWord,
    });
};

export const deleteDraftRequest = (idTransaction) =>
    API.executeWithAccessToken("/core.deleteTransactionDraft", { idTransactionToDelete: idTransaction });

export const getPendingTransactionsQuantity = () => API.executeWithAccessToken("/transactions.get.pending.quantity");
