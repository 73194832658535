import ErrorBoundary from "pages/_components/ErrorBoundary";
import FooterDesktop from "pages/_components/FooterDesktop";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, node, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";
import PageTransition from "react-router-page-transition";
import { getTransition } from "util/transition";

class ExternalLayout extends Component {
    static propTypes = {
        history: shape({}).isRequired,
        location: shape({}).isRequired,
        transition: string,
        component: oneOfType([node, func]).isRequired,
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        showBack: bool,
        isTermsOrPoliciesPage: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isMobile: false,
        isMobileNative: false,
        transition: "",
        showBack: false,
        isTermsOrPoliciesPage: false,
    };

    state = {
        transition: "",
        styles: {},
    };

    componentDidMount() {
        const { isMobileNative } = this.props;

        if (isMobileNative) {
            const deviceHeight = window.innerHeight;

            this.setState({
                styles: {
                    position: "absolute",
                    top: 0,
                    height: deviceHeight,
                },
            });
        }

        document.body.style.backgroundColor = "#f9f9fb";
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition: nextProps.transition || getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, showBack } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, showBack };
        const { transition, styles } = this.state;

        return (
            <div className={`${transition}`} style={styles}>
                <PageTransition timeout={transition ? 600 : 0}>
                    <div className="app">
                        <div className="view-wrapper transition-item login-wrapper">
                            <ErrorBoundary>
                                <ReceivedComponent {...matchProps} {...extras} />
                            </ErrorBoundary>
                        </div>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, showBack } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, showBack };
        const { transition } = this.state;

        return (
            <PageTransition
                timeout={transition ? 600 : 0}
                style={{
                    background: "linear-gradient(transparent, transparent 67.5%, black)",
                }}>
                <div className="app login-wrapper transition-item">
                    <ErrorBoundary>
                        <Grid className="login-content-container">
                            <ReceivedComponent {...matchProps} {...extras} />
                            <FooterDesktop />
                        </Grid>
                    </ErrorBoundary>
                </div>
            </PageTransition>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(resizableRoute(ExternalLayout));
