import { call, put, select, takeLatest } from "redux-saga/effects";

import * as accountsMiddleware from "middleware/accounts";
import { selectors, types } from "reducers/accounts";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { unifyListParams } from "util/account";
import { downloadImageTypeBase64, downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.ACCOUNT_READ_REQUEST, accountRead),
    takeLatest(types.ACCOUNT_DETAILS_REQUEST, accountDetails),
    takeLatest(types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST, downloadStatement),
    takeLatest(types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovements),
    takeLatest(types.ACCOUNT_MOVEMENT_DETAILS_REQUEST, movementDetails),
    takeLatest(types.EDIT_MOVEMENT_NOTE_REQUEST, editMovementNote),
    takeLatest(types.LIST_ACCOUNT_STATEMENTS_REQUEST, listStatementLines),
    takeLatest(types.LIST_ACCOUNTS_REQUEST, listAccountsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.ACCOUNT_FETCH_MOVEMENTS_REQUEST, fetchMovements),
    takeLatest(types.EXPORT_CBU_DETAIL_REQUEST, exportCBUDetailRequest),
    takeLatest(types.EXPORT_ACCOUNT_DETAIL_REQUEST, exportDetailAccountRequest),
    takeLatest(types.LOAD_LIST_ACCOUNTS_REQUEST, loadListAccountsRequest),
];

export default sagas;

function* accountDetails({ idSelectedAccount, formikBag }) {
    const filters = yield select(selectors.getFilters);
    const response = yield call(accountsMiddleware.listMovements, idSelectedAccount, filters);

    if (response) {
        if (response.type === "W" && formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.status === 200) {
            yield put({
                type: types.ACCOUNT_DETAILS_SUCCESS,
                ...unifyListParams(response),
            });
        }
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* accountRead({ idAccount }) {
    const response = yield call(accountsMiddleware.readAccount, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_READ_SUCCESS,
            account: response.data.data ? response.data.data.account : response.data,
        });
    }
}

function* downloadStatement({ idAccount, idStatement, path, fileNameSelected }) {
    const response = yield call(accountsMiddleware.downloadStatement, idAccount, idStatement, path, fileNameSelected);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        if (content && fileName) {
            downloadPdf(fileName, content);

            yield put(
                notificationModalActions.showNotification(
                    i18n.get("accounts.details.statements.download.successMessage"),
                    "success",
                ),
            );

            yield put({
                type: types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS,
            });
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "accountsStatements",
                ]),
            );

            yield put({
                type: types.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE,
            });
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["accountsStatements"]),
        );

        yield put({
            type: types.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE,
        });
    }
}

function* editMovementNote({ accountId, movementId, note }) {
    const response = yield call(accountsMiddleware.editMovementNote, accountId, movementId, note);

    if (response && response.status === 200) {
        const confirmationMessage = i18n.get("accounts.movement.detail.noteSaved");

        yield put(notificationActions.showNotification(confirmationMessage, "success", ["movementDetail"]));
    } else {
        const errorMessage = i18n.get("accounts.movement.detail.noteUnsaved");

        yield put(notificationActions.showNotification(errorMessage, "error", ["movementDetail"]));
    }
}

function* fetchMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* fetchMoreMovements({ accountId, filters }) {
    const response = yield call(accountsMiddleware.listMovements, accountId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS,
            ...unifyListParams(response),
        });
    }
}

function* listAccountsRequest() {
    const response = yield call(accountsMiddleware.listAccounts);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNTS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* listStatementLines({ idAccount, selectedAccount }) {
    const response = yield call(accountsMiddleware.listStatementLines, idAccount);

    if (response && response.status === 200) {
        yield put({
            type: types.LIST_ACCOUNT_STATEMENTS_SUCCESS,
            statementLines: response.data.data.statementLines,
            idAccountForStatement: idAccount,
            selectedAccountStatement: selectedAccount,
        });
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["accountsStatements"]),
        );

        yield put({
            type: types.LIST_ACCOUNT_STATEMENTS_FAILURE,
        });
    }
}

function* movementDetails({ idAccount, idStatement }) {
    const response = yield call(accountsMiddleware.movementDetails, idAccount, idStatement);

    if (response && response.status === 200) {
        yield put({
            type: types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* downloadMovements({ idAccount, format }) {
    const filters = yield select(selectors.getFilters);
    const { type, data } = yield call(accountsMiddleware.downloadMovements, idAccount, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put(
            notificationActions.showNotification(i18n.get("accounts.movement.detail.download.success"), "success", [
                "account/details",
            ]),
        );
        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}

function* exportCBUDetailRequest({ cbuData, idAccount }) {
    const { type, data } = yield call(accountsMiddleware.exportCBUDetailRequest, cbuData, idAccount);

    if (type === "W") {
        yield put({ type: types.EXPORT_CBU_DETAIL_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "accounts",
                "account/details",
                "form",
            ]),
        );
    } else {
        const { content, fileName } = data.data;
        downloadPdf(fileName, content);
        yield put({ type: types.EXPORT_CBU_DETAIL_SUCCESS });
        yield put(
            notificationActions.showNotification(i18n.get("notification.download.successful"), "success", [
                "transaction/details",
            ]),
        );
    }
}

function* exportDetailAccountRequest({ dataToExport, idAccount, isDesktop }) {
    if (isDesktop) {
        const fileExtension = "pdf";

        const { type, data } = yield call(
            accountsMiddleware.exportAccountDetailRequest,
            dataToExport,
            idAccount,
            fileExtension,
        );

        if (type === "W") {
            yield put({ type: types.EXPORT_ACCOUNT_DETAIL_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "accounts",
                    "account/details",
                    "form",
                ]),
            );
        } else {
            const { content, fileName } = data.data;

            downloadImageTypeBase64(fileName, content, `"image/"${fileExtension}`);

            yield put({ type: types.EXPORT_ACCOUNT_DETAIL_SUCCESS });
            yield put(notificationModalActions.showNotification("accounts.download.details.successful", "success"));
        }
    } else if (window.plugins && window.plugins.socialsharing) {
        let messageToExport = `${i18n.get("accounts.share.title.mobile.label")} \n`;
        messageToExport += `${i18n.get("accounts.accountHolder")}: ${dataToExport.titular} \n`;
        messageToExport += `${i18n.get("accounts.cuit.label")}: ${dataToExport.cuit} \n`;
        messageToExport += `${i18n.get("accounts.accountType.label")}: ${dataToExport.accountType} \n`;
        messageToExport += `${i18n.get("accounts.accountNumber.label")}: ${dataToExport.accountNumber} \n`;
        messageToExport += `${i18n.get("accounts.cbu.label")}: ${dataToExport.cbu} \n`;

        if (dataToExport.alias) {
            messageToExport += `${i18n.get("accounts.cbuNick.label")}: ${dataToExport.alias} \n`;
        }

        window.plugins.socialsharing.share(messageToExport);
    }
}

function* loadListAccountsRequest({ idAccount }) {
    const response = yield call(accountsMiddleware.listAccounts);
    if (response && response.status === 200) {
        const { accounts } = response.data.data;

        let selectedAccount;
        let selectedAccountOption = null;

        if (idAccount) {
            selectedAccount = accounts.find((account) => account.idProduct === idAccount);

            const { productType, formattedAccountNumber } = selectedAccount;
            const newLabel = `${i18n.get(`productType.${productType}`)} Nº ${formattedAccountNumber}`;

            selectedAccountOption = {
                id: selectedAccount.idProduct,
                newLabel,
                ...selectedAccount,
            };
        }

        if (accounts.length > 0) {
            const optionList = accounts.map((account) => {
                const { productType, formattedAccountNumber } = account;
                const newLabel = `${i18n.get(`productType.${productType}`)} Nº ${formattedAccountNumber}`;
                return {
                    id: account.idProduct,
                    newLabel,
                    ...account,
                };
            });

            yield put({
                type: types.LOAD_LIST_ACCOUNTS_SUCCESS,
                optionListAccounts: optionList,
                selectedAccountStatement: selectedAccountOption || optionList[0],
                selectedAccount,
            });

            yield put({
                type: types.LIST_ACCOUNT_STATEMENTS_REQUEST,
                idAccount: selectedAccountOption?.id || optionList[0].id,
                selectedAccount: selectedAccountOption || optionList[0],
            });
        }
    } else {
        yield put({
            type: types.LOAD_LIST_ACCOUNTS_FAILURE,
        });
    }
}
