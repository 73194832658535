import React, { Component } from "react";
import moment from "moment";
import { shape, string, bool, func } from "prop-types";
import { isSameDay, isPast } from "date-fns";
import isEmpty from "lodash/isEmpty";
import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import SchedulerModal from "pages/forms/_components/_scheduler/SchedulerModal";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import * as configUtils from "util/config";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Image from "pages/_components/Image";

const FORM_ID = "scheduler";

class Scheduler extends Component {
    static propTypes = {
        data: shape({}).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        schedulerLabelCustom: string,
        schedulerTooltip: shape({}),
        modalTitleLabelCustom: string,
        disclaimerLabelCustom: string,
        isSubscriptionOrRescue: bool,
        handleValueChange: func,
    };

    static defaultProps = {
        schedulerLabelCustom: "",
        schedulerTooltip: null,
        modalTitleLabelCustom: null,
        disclaimerLabelCustom: null,
        isSubscriptionOrRescue: false,
        handleValueChange: null,
    };

    state = {
        errors: null,
        isDisplayed: false,
    };

    componentDidMount() {
        const { field, form, handleValueChange } = this.props;

        if (field.value === null) {
            const scheduleValue = {
                selectedOption: schedulerUtils.TODAY,
                valueDate: this.getDefaultValueDate(),
            };

            form.setFieldValue(field.name, scheduleValue);

            if (handleValueChange) {
                handleValueChange(scheduleValue);
            }
        }
    }

    static getDerivedStateFromProps(props) {
        const {
            form: { errors },
        } = props;

        const programErrors = Object.keys(errors)
            .filter((key) => key.includes("program@"))
            .reduce(
                (obj, key) => ({
                    ...obj,
                    [key]: errors[key],
                }),
                {},
            );

        return {
            errors: programErrors,
        };
    }

    getDefaultValueDate() {
        const {
            data: { firstWorkingDate },
        } = this.props;

        return moment(firstWorkingDate, "YYYY-MM-DD");
    }

    handleClick = () => {
        this.setState({ isDisplayed: true });
    };

    renderErrors = () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
            return (
                <div className="form-group has-error">
                    {Object.keys(errors).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[error]} />
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    handleValueChange = (data) => {
        const { field, form, isSubscriptionOrRescue, handleValueChange } = this.props;

        let setTime = {};
        if (isSubscriptionOrRescue) {
            setTime = { time: configUtils.get("scheduler.transactions.subscriptionRescue.execution.hour", "09:00") };
        }

        let scheduleValue = {};

        if (data.frequency === schedulerUtils.ONCE) {
            scheduleValue = {
                ...field.value,
                selectedOption: data.frequency,
                valueDate: moment(data.date),
                program: null,
                ...setTime,
            };

            form.setFieldValue(field.name, scheduleValue);
        } else {
            const newProgramData = {
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1", // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
                ...setTime,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }

            scheduleValue = {
                ...field.value,
                selectedOption: newProgramData.frequency,
                program: newProgramData,
                valueDate: moment(data.startsOn),
            };

            form.setFieldValue(field.name, scheduleValue);
            form.setErrors({});
        }

        if (handleValueChange) {
            handleValueChange(scheduleValue);
        }

        form.setTouched({ ...form.touched, [field.name]: true });
        this.handleCloseBottomSheetClick();
    };

    handleCloseBottomSheetClick = () => {
        this.setState({ isDisplayed: false });
    };

    updatePastDate(value) {
        if (value && value.valueDate) {
            const currentDate = new Date();
            if (isPast(value.valueDate) && !isSameDay(currentDate.getTime(), value.valueDate)) {
                const { form } = this.props;
                form.values.scheduler.valueDate = new Date();
            }
        }
    }

    render() {
        const {
            data: { mode, programable, schedulable, isDesktop },
            field: { value },
            schedulerLabelCustom,
            schedulerTooltip,
            modalTitleLabelCustom,
            disclaimerLabelCustom,
        } = this.props;
        const { errors, isDisplayed } = this.state;

        this.updatePastDate(value);
        if (programable || schedulable) {
            if (mode === "edit") {
                return (
                    <>
                        <SchedulerModal
                            idForm={FORM_ID}
                            handleCloseBottomSheetClick={this.handleCloseBottomSheetClick}
                            goBack={this.handleCloseBottomSheetClick}
                            onScheduleClick={this.handleValueChange}
                            value={value}
                            isDisplayed={isDisplayed}
                            isDesktop={isDesktop}
                            titleLabelCustom={modalTitleLabelCustom}
                            disclaimerLabelCustom={disclaimerLabelCustom}
                        />
                        <div className="form-group form-group--scheduler">
                            <FieldLabel
                                labelKey={schedulerLabelCustom || "scheduler.label"}
                                idField="readableID"
                                tooltip={schedulerTooltip}
                            />
                            <div className="form-group-text scheduler">
                                <span className="scheduler-message">
                                    <Message value={value || { valueDate: "" }} />
                                </span>
                                <button
                                    aria-haspopup="dialog"
                                    className="btn btn-link btn-link--wIcon"
                                    onClick={this.handleClick}
                                    aria-expanded={isDisplayed}
                                    type="button">
                                    <Image src="images/ui-icons/ui-scheduler.svg" className="svg-icon" />
                                    <>
                                        <I18n
                                            id={
                                                !value || value?.selectedOption === "TODAY"
                                                    ? "scheduler.schedule"
                                                    : "scheduler.edit"
                                            }
                                            componentProps={{ "aria-hidden": true }}
                                        />
                                        <I18n
                                            id="scheduler.schedule.a11y"
                                            componentProps={{ className: "visually-hidden" }}
                                        />
                                    </>
                                </button>

                                {!isEmpty(errors) && this.renderErrors()}
                            </div>
                        </div>
                    </>
                );
            }
        }

        return null;
    }
}

export default Scheduler;
