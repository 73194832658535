import React from "react";
import { shape, number, string, bool } from "prop-types";
import AccordionItem from "pages/_components/AccordionItem/AccordionItem";
import FormattedDate from "pages/_components/FormattedDate";
import GenericItem from "components/GenericItem/GenericItem";
import I18n from "../_components/I18n";
import Table from "../_components/Table";

const DetailItemForAssignment = ({ position, title, items, totalItems, section, labels, isMobile }) => (
    <AccordionItem
        key={section}
        customTitle={<I18n id={title} component="h4" />}
        additionalClassName="echeq-collapsable-panel__table">
        {!isMobile ? (
            <>
                {totalItems > 0 ? (
                    <>
                        <Table className="gridTable">
                            <Table.Header>
                                {labels.map((key) => (
                                    <Table.HeaderData align="left">
                                        <I18n id={`echeqs.detail.section.${section}.${key}.title.label`} />
                                    </Table.HeaderData>
                                ))}
                            </Table.Header>

                            <Table.Body>
                                <>
                                    {items.map((item) => (
                                        <Table.Row key={`${section}-${position}`} renderAs="div">
                                            <div className="table-data">
                                                <span className="data-date">
                                                    <FormattedDate date={item.fecha_emision_cesion} />
                                                </span>
                                            </div>

                                            <div className="table-data">
                                                <span className="data-text">{item.cesion_id}</span>
                                            </div>

                                            <div className="table-data">
                                                <div className="data-wrapper-group-column">
                                                    <span className="data-text-none">{item.cedente_nombre}</span>
                                                    <span className="data-text-none">{item.cedente_documento}</span>
                                                </div>
                                            </div>

                                            <div className="table-data">
                                                <div className="data-wrapper-group-column">
                                                    <span className="data-text-none">{item.cesionario_nombre}</span>
                                                    <span className="data-text-none">{item.cesionario_documento}</span>
                                                </div>
                                            </div>

                                            <div className="table-data">
                                                <span className="data-text">{item.entidadRegistro}</span>
                                            </div>

                                            <div className="table-data">
                                                <div className="data-wrapper-group-column">
                                                    <span className="data-text data-capitalize">
                                                        {item.estado_cesion}
                                                    </span>
                                                    <span className="data-text">{item.cesion_motivo_repudio}</span>
                                                </div>
                                            </div>
                                        </Table.Row>
                                    ))}
                                </>
                            </Table.Body>
                        </Table>
                    </>
                ) : (
                    <I18n
                        id={`echeqs.detail.section.${section}.noData.label`}
                        component="p"
                        componentProps={{ className: "ui-text-center ui-mt-0" }}
                    />
                )}
            </>
        ) : (
            <>
                {totalItems > 0 ? (
                    <>
                        {items.map((item) => (
                            <GenericItem key={`${section}-${position}`}>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.date.title.label`} />
                                    </div>
                                    <span className="data-date">
                                        <FormattedDate date={item.fecha_emision_cesion} />
                                    </span>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.id.title.label`} />
                                    </div>
                                    <span className="data-text">{item.cesion_id}</span>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.assignor.title.label`} />
                                    </div>
                                    <span className="data-text">{item.cedente_nombre}</span>
                                    <span className="data-text">{item.cedente_documento}</span>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.assignee.title.label`} />
                                    </div>
                                    <span className="data-text">{item.cesionario_nombre}</span>
                                    <span className="data-text">{item.cesionario_documento}</span>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.entity.title.label`} />
                                    </div>
                                    <span className="data-text">{item.entidadRegistro}</span>
                                </div>

                                <div className="data-wrapper">
                                    <div className="data-label">
                                        <I18n id={`echeqs.detail.section.${section}.status.title.label`} />
                                    </div>
                                    <span className="data-text data-capitalize">{item.estado_cesion}</span>
                                    <span className="data-text">{item.cesion_motivo_repudio}</span>
                                </div>
                            </GenericItem>
                        ))}
                    </>
                ) : (
                    <I18n
                        id={`echeqs.detail.section.${section}.noData.label`}
                        component="p"
                        componentProps={{ className: "ui-text-center ui-mt-7" }}
                    />
                )}
            </>
        )}
    </AccordionItem>
);
DetailItemForAssignment.propTypes = {
    position: number.isRequired,
    title: string.isRequired,
    items: shape([]).isRequired,
    totalItems: number.isRequired,
    section: string.isRequired,
    labels: shape([]).isRequired,
    isMobile: bool.isRequired,
};
export default DetailItemForAssignment;
