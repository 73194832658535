import React, { useEffect, useRef, useState } from "react";

import * as config from "util/config";
import * as i18n from "util/i18n";
import { func, shape, string, bool } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import SimpleModal from "components/SimpleModal/SimpleModal";

import "./_ChargingInstructions.scss";
import { actions } from "reducers/files";
import { actions as notificationActions } from "reducers/notification";
import { actions as massPaymentsActions } from "reducers/massPayments";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelector } from "reducers/session";
import Button from "../../../_components/Button";

const ChargingInstructions = ({
    idActivity,
    idForm,
    dispatch,
    isUploading,
    currentLang,
    environment,
    accountSelected,
    isMobile,
}) => {
    const ID_OPTIONS = "forms.pay.transfers.massive.uploadType.fileUpload.chargingInstructions";
    const [showUploadingModal, setShowUploadingModal] = useState(isUploading);

    useEffect(() => {
        setShowUploadingModal(isUploading);
    }, [isUploading]);

    const fileRef = useRef();

    const handleInputFileClick = () => {
        fileRef.current.click();
    };

    const handleShowUploadingModal = (value) => {
        setShowUploadingModal(value);
    };

    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        if (file) {
            const fileTypes = config.get("payments.files.allowedFileExtensions", "csv");
            const fileExtension = `${file.name.split(".").slice(-1)}`;

            if (fileTypes.indexOf(fileExtension) > -1) {
                dispatch(
                    actions.uploadFileRequest(
                        idActivity,
                        idForm,
                        "Payments",
                        environment.id,
                        currentLang,
                        file,
                        accountSelected,
                    ),
                );
            } else {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.invalid"),
                        "error",
                        ["form", "transaction/details"],
                    ),
                );
            }
        }

        fileRef.current.value = "";
    };

    return (
        <>
            <hr className="ui-mt-0 ui-mb-7" />

            <div className="charging-instructions">
                <I18n
                    component="h4"
                    id={`${ID_OPTIONS}.title`}
                    componentProps={{ className: "charging-instructions__title" }}
                />

                <I18n
                    component="p"
                    id={`${ID_OPTIONS}.1`}
                    componentProps={{ className: "charging-instructions__option" }}
                />
                <div className="charging-instructions__important">
                    <I18n
                        component="h5"
                        id={`${ID_OPTIONS}.subtitleImportant`}
                        componentProps={{ className: "charging-instructions__important-subtitle" }}
                    />
                    <ul>
                        <I18n
                            component="li"
                            id={`${ID_OPTIONS}.subitem1`}
                            componentProps={{ className: "charging-instructions__important-item" }}
                        />
                        <I18n
                            component="li"
                            id={`${ID_OPTIONS}.subitem2`}
                            componentProps={{ className: "charging-instructions__important-item" }}
                        />
                    </ul>
                </div>

                <div className="charging-instructions__download">
                    <Button
                        bsStyle="link"
                        className="btn-link--wIcon"
                        onClick={() => {
                            dispatch(massPaymentsActions.downloadPaymentSampleFile());
                        }}>
                        <Image src="images/ui-icons/ui-download.svg" className="svg-icon" />
                        <I18n id={`${ID_OPTIONS}.download.label`} />
                    </Button>
                </div>

                <I18n
                    component="p"
                    id={`${ID_OPTIONS}.2`}
                    componentProps={{ className: "charging-instructions__option" }}
                />

                <I18n
                    component="p"
                    id={`${ID_OPTIONS}.3`}
                    componentProps={{ className: "charging-instructions__option" }}
                />

                <Button
                    bsStyle="outline"
                    image="images/attachment.svg"
                    className="charging-instructions__attachment"
                    id={`${ID_OPTIONS}.buttonAttachment.label`}
                    label={`${ID_OPTIONS}.buttonAttachment.label`}
                    onClick={handleInputFileClick}
                    block={isMobile}
                />

                <input
                    id="myInput"
                    type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    onChange={(e) => onChangeFile(e)}
                />

                <hr className="ui-mb-7" />
            </div>
            {showUploadingModal && (
                <SimpleModal
                    modalIcon="images/processing-archive.svg"
                    modalBigTitle="forms.pay.transfers.massive.uploadType.fileUpload.modalTitle"
                    showActions={false}
                    closeModal={handleShowUploadingModal}
                    buttonAction={() => {}}
                    isDisplayed>
                    <I18n
                        id="forms.pay.transfers.massive.uploadType.modalParagraph"
                        componentProps={{ className: "modal-alt-text " }}
                    />
                </SimpleModal>
            )}
        </>
    );
};

ChargingInstructions.propTypes = {
    dispatch: func.isRequired,
    idActivity: string.isRequired,
    idForm: string.isRequired,
    isUploading: bool.isRequired,
    currentLang: string.isRequired,
    environment: shape({}).isRequired,
    accountSelected: shape({}).isRequired,
    isMobile: bool.isRequired,
};

const mapPropsToState = (state) => ({
    isUploading: state.files.uploading,
    currentLang: i18nSelectors.getLang(state),
    environment: sessionSelector.getActiveEnvironment(state),
});

export default connect(mapPropsToState)(ChargingInstructions);
