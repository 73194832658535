import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Captcha from "pages/_components/fields/credentials/Captcha";
import Credential from "pages/_components/fields/credentials/Credential";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool, func, string, shape } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";

const FORM_ID = "enrollment.associate.step2";

class AssociateStep2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        account: shape({
            accountName: string.isRequired,
        }).isRequired,
        captchaRequired: bool,
        isMobileNative: bool,
        securitySeal: string.isRequired,
    };

    static defaultProps = {
        captchaRequired: true,
        isMobileNative: false,
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { account, captchaRequired, isDesktop, isMobileNative, isSubmitting, securitySeal } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <div className="app-page">
                <div className="app-content">
                    <div className="view-wrapper">
                        <div className="view-page justify-content-center associate-step">
                            <div className="view-content">
                                <div className="title-header">
                                    <I18n id="enrollment.associate.step2.header" component="h1" />
                                </div>
                                <Form className="form-content container">
                                    <div className="pre-form-info">
                                        <div className="pre-form-info__text">
                                            <I18n
                                                id="enrollment.associate.step2.title"
                                                component="p"
                                                componentProps={{ className: "ui-mt-0" }}
                                                ENVIRONMENT_OWNER={account && account.accountName}
                                            />
                                        </div>
                                    </div>

                                    <div className="security-image">
                                        <figure className="security-image__image">
                                            <img src={securitySeal} alt="securitySealAlternativeText" />
                                        </figure>

                                        <div className="security-image__text">
                                            <I18n id="login.step2.header" />
                                            <I18n id="login.step2.verifyYourImage" component="p" />
                                        </div>
                                    </div>

                                    <Field
                                        idForm={FORM_ID}
                                        name="password"
                                        component={Credential}
                                        autoFocus={isDesktop}
                                        maxLength={maxLength}
                                    />
                                    {!isMobileNative && captchaRequired && (
                                        <Field name="captcha" idForm={FORM_ID} component={Captcha} />
                                    )}
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={isSubmitting}
                                        type="submit"
                                        block
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    captchaRequired: enrollmentSelectors.getCaptchaRequired(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    securitySeal: enrollmentSelectors.getSecuritySeal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            captcha: "",
            password: "",
        }),
        validationSchema: (props) => {
            const { captchaRequired, isMobileNative } = props;

            return Yup.object().shape({
                captcha:
                    !isMobileNative && captchaRequired
                        ? Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.error.required`))
                        : Yup.string(),
                password: Yup.string().required(i18nUtils.get(`${FORM_ID}.password.error.empty`)),
            });
        },
        handleSubmit: ({ captcha, password }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.associateStep2Verify(captcha, password, formikBag));
        },
    }),
)(withExchangeToken(AssociateStep2));
