import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as comexSelectors, actions as comexActions } from "reducers/comex";
import { bool, func, string, arrayOf, number, shape } from "prop-types";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Loader from "pages/_components/Loader";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Table from "pages/_components/Table";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import ReceiptsComex from "./ReceiptsComex";

class List extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        pageNumber: number.isRequired,
        isDesktop: bool.isRequired,
        firstFetched: bool.isRequired,
        receipts: arrayOf(shape({})).isRequired,
        moreReceipts: bool.isRequired,
        idActivity: string.isRequired,
        isFetchingReceipts: bool.isRequired,
        isDownloadingFile: bool.isRequired,
    };

    state = {
        showFilters: false,
        fileDownloading: "",
    };

    fetchMoreReceipts = () => {
        const { dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(comexActions.loadMoreReceiptsComexListRequest(filters));
    };

    handleFiltersClick = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    handleReceiptsDownload = (receipts) => {
        const { dispatch } = this.props;
        const { path, fileName } = receipts;
        dispatch(comexActions.downloadReceiptsComexRequest(path, fileName));
        this.setState({
            fileDownloading: fileName,
        });
    };

    renderItem = (receipts) => {
        const { isDesktop, idActivity, isDownloadingFile } = this.props;
        const { fileDownloading } = this.state;
        return isDesktop ? (
            <Table.Row key={receipts.liquidation}>
                <ReceiptsComex
                    isDesktop={isDesktop}
                    receipts={receipts}
                    onClick={() => this.handleReceiptsDownload(receipts)}
                    fileDownloading={fileDownloading}
                    isDownloadingFile={isDownloadingFile}
                    idActivity={idActivity}
                />
            </Table.Row>
        ) : (
            <ReceiptsComex
                key={receipts.liquidation}
                isDesktop={isDesktop}
                receipts={receipts}
                idActivity={idActivity}
                onClick={() => this.handleReceiptsDownload(receipts)}
                fileDownloading={fileDownloading}
                isDownloadingFile={isDownloadingFile}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, idActivity } = this.props;
        const isEmpty = list.length === 0;
        if (isDesktop) {
            return (
                <>
                    {!isEmpty && (
                        <>
                            <Table className="gridTable">
                                <Table.Header>
                                    <Table.HeaderData align="left">
                                        <I18n id={`${idActivity}.date.label`} />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="left">
                                        <I18n id={`${idActivity}.liquidation.label`} />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="right">
                                        <I18n id={`${idActivity}.amount.label`} />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="left">
                                        <I18n id={`${idActivity}.concept.label`} />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="right" styles={{ paddingRight: "36px" }}>
                                        <I18n id={`${idActivity}.voucher.label`} />
                                    </Table.HeaderData>
                                </Table.Header>

                                <Table.Body>{list}</Table.Body>
                            </Table>

                            <SeuoLabel />

                            {renderLoadMore()}
                        </>
                    )}
                </>
            );
        }

        return (
            list.length > 0 && (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    {renderLoadMore()}
                </>
            )
        );
    };

    render() {
        const { firstFetched, moreReceipts, receipts, filters, isFetchingReceipts, isDesktop } = this.props;
        receipts.sort((a, b) => {
            if (a.dateTransaction === b.dateTransaction) {
                return a.amount - b.amount;
            }
            return 0;
        });

        const defaultFilters = {
            dateFrom: defaultDateFromList().comex.dateFrom,
            dateTo: defaultDateFromList().comex.dateTo,
        };

        const withFilters = getFiltersStatus({ filters, defaultFilters });

        let noMoreDataText;

        if (withFilters) {
            noMoreDataText = "comex.receiptsList.noMoreData.withFilters";
        } else {
            noMoreDataText = "comex.receiptsList.noMoreData.default";
        }

        return (
            <>
                {isFetchingReceipts && firstFetched ? (
                    <Loader />
                ) : (
                    <ProductList
                        fetching={isFetchingReceipts}
                        items={receipts}
                        renderItem={this.renderItem}
                        lastPage={!moreReceipts}
                        onLoadMoreClick={this.fetchMoreReceipts}
                        firstFetched={firstFetched}
                        filters={filters || {}}
                        noMoreDataText={noMoreDataText}
                        loadMoreText="comex.receiptsList.searchMoreReceipts"
                        noDataText="comex.receiptsList.empty.label"
                        noFiltersDataText="comex.receiptsList.filters.none"
                        isMobile={!isDesktop}
                        defaultFilters={defaultFilters}>
                        {this.renderList}
                    </ProductList>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: comexSelectors.getFilters(state),
    firstFetched: comexSelectors.getFirstFetched(state),
    receipts: comexSelectors.getReceipts(state),
    moreReceipts: comexSelectors.getMoreReceipts(state),
    pageNumber: comexSelectors.getPageNumber(state),
    isFetchingReceipts: comexSelectors.getFetching(state),
    isDownloadingFile: comexSelectors.isDownloadingFile(state),
});

export default connect(mapStateToProps)(List);
