import FooterDesktop from "pages/_components/FooterDesktop";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, node, oneOfType, string } from "prop-types";
import React, { Component } from "react";

export const MainContainerContext = React.createContext();

class MainContainer extends Component {
    static propTypes = {
        children: oneOfType([arrayOf(node), node]),
        className: string,
        showLoader: bool,
        showChildrenWithLoader: bool,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        children: null,
        className: "main-container",
        showLoader: false,
        showChildrenWithLoader: false,
    };

    render() {
        const { className, showLoader, showChildrenWithLoader, children, isMobile } = this.props;

        return (
            <MainContainerContext.Provider value={this.scrollRef}>
                <div className="view-page">
                    <div
                        className="view-content"
                        ref={(ref) => {
                            this.scrollRef = ref;
                        }}>
                        <main className={className}>
                            <PageLoading loading={showLoader}>
                                {showChildrenWithLoader ? children : !showLoader && children}
                                {isMobile && <FooterDesktop />}
                            </PageLoading>
                        </main>
                    </div>
                </div>
            </MainContainerContext.Provider>
        );
    }
}

export default resizableRoute(MainContainer);
