import { bool, shape, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Field } from "formik";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import types from "reducers/types/form";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors, actions as echeqActions } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import * as i18n from "util/i18n";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import PageLoading from "pages/_components/PageLoading";
import FormTransition from "../../_components/FormTransition";
import CustomerEcheqData from "./_components/CustomerEcheqData";

const ID_ACTIVITY = "echeqs.requestMandate.payment.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");

const RequestPaymentMandateForm = (props) => {
    const {
        echeq,
        mode,
        dispatch,
        location,
        data,
        transaction,
        isMobile,
        bankedCustomerInfo,
        listIMF,
        fetching,
        currentLang,
        fromBackoffice,
        errors,
        AuthorizedEntityList,
    } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const [mandateAddressSelected, setMandateAddressSelected] = useState(null);
    const [accountTypeSelected, setAccountTypeSelected] = useState(null);
    const [mandataryTypeSelected, setMandataryTypeSelected] = useState(null);
    const [mandataryDocumentSelected, setMandataryDocumentSelected] = useState(null);
    const [mandataryEntityCodeSelected, setMandataryEntityCodeSelected] = useState(null);
    const [bankedCustomerInfoSelected, setBankedCustomerInfoSelected] = useState({});

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq)?.length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }

        if (data?.depositAccountType) {
            setAccountTypeSelected(data.depositAccountType);
        }
        if (data?.mandataryType) {
            setMandataryTypeSelected(data.mandataryType);
            if (data?.mandataryType?.[0] === "IMF") {
                dispatch(echeqActions.getListIMFRequest());
            }
        }
        if (data?.mandateAddress) {
            setMandateAddressSelected(data.mandateAddress);
        }
        if (data?.bankedCustomerInfo) {
            setBankedCustomerInfoSelected(data.bankedCustomerInfo);
        }
        if (data?.mandataryDocument) {
            setMandataryDocumentSelected(data.mandataryDocument);
        }
        if (data?.mandataryEntityCode) {
            setMandataryEntityCodeSelected(data.mandataryEntityCode);
        }
    }, [dispatch, location]);

    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        tenencia_beneficiario_documento,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        fecha_emision,
        cheque_caracter,
    } = echeq;

    const { idTransactionStatus, idTransaction } = transaction;
    const paymentDate = fecha_pago?.substring(0, 10);
    const emissionDate = fecha_emision?.substring(0, 10);
    const isDeferred = data?.isDeferred || emissionDate !== paymentDate;
    const substatuses = [];
    if (echeq.fecha_pago_vencida) {
        substatuses.push(i18n.get(`forms.echeqs.list.substatus.VENCIDO`));
    }
    if (echeq.avalado) {
        substatuses.push(i18n.get(`forms.echeqs.list.substatus.AVALADO`));
    }
    if (echeq.cedido) {
        substatuses.push(i18n.get(`forms.echeqs.list.substatus.CEDIDO`));
    }
    if (echeq.mandato_neg) {
        substatuses.push(i18n.get(`forms.echeqs.list.substatus.MANDATO-NEGOCIACION`));
    }
    let substatusesStr = null;
    substatuses.forEach((substatus) => {
        if (substatusesStr !== null) {
            substatusesStr += ` - ${substatus}`;
        } else {
            substatusesStr = substatus;
        }
    });

    const optionsListIMF =
        data?.optionsListIMF && mode !== "edit"
            ? data.optionsListIMF
            : listIMF?.map((imf) => {
                  const documentLabel = imf.documento
                      ?.substring(0, 2)
                      .concat("-")
                      .concat(imf.documento?.substring(2, 10))
                      .concat("-")
                      .concat(imf.documento?.substring(10));
                  return {
                      ...imf,
                      id: imf.documento,
                      label: `${imf.nombre} ${documentLabel}`,
                  };
              });

    const optionsListEntity =
        data?.optionsListEntity && mode !== "edit"
            ? data.optionsListEntity
            : AuthorizedEntityList?.map((entity) => {
                  const documentLabel = entity.document
                      ?.substring(0, 2)
                      .concat("-")
                      .concat(entity.document?.substring(2, 10))
                      .concat("-")
                      .concat(entity.document?.substring(10));
                  return {
                      ...entity,
                      id: entity.document,
                      label: `${entity.name} ${documentLabel}`,
                  };
              });

    const handleDepositAccountType = (e, setFieldValue) => {
        setAccountTypeSelected([e.id]);
        if (e.id === "ownAccount") {
            setMandataryTypeSelected(["entity"]);
            setFieldValue("mandataryType", ["entity"]);
            const successAction = () => {
                setFieldValue("depositAccountType", accountTypeSelected);
                setFieldValue("mandateAddress", mandateAddressSelected);
                setFieldValue("mandataryEntityCode", mandataryEntityCodeSelected);
            };
            dispatch(echeqActions.getAuthorizedEntityListRequest(successAction));
        } else {
            setFieldValue("mandatorCBU", null);
        }
    };

    function setValuesCustom(setFieldValue) {
        setFieldValue("depositAccountType", accountTypeSelected);
        setFieldValue("mandataryType", mandataryTypeSelected);
        setFieldValue("mandateAddress", mandateAddressSelected);
        setFieldValue("mandataryDocument", mandataryDocumentSelected);
        setFieldValue("mandataryEntityCode", mandataryEntityCodeSelected);
    }

    const handleMandataryType = (e, setFieldValue) => {
        setMandataryTypeSelected([e.id]);
        setAccountTypeSelected(accountTypeSelected);
        if (e.id !== "thirdParty") {
            dispatch(echeqActions.cleanBankedCustomerInfo());
            setBankedCustomerInfoSelected({});
            setFieldValue("bankedCustomerInfo", {});
            const successAction = () => {
                setFieldValue("depositAccountType", accountTypeSelected);
                setFieldValue("mandateAddress", mandateAddressSelected);
            };
            if (e.id === "IMF") {
                dispatch(echeqActions.getListIMFRequest(successAction));
            } else {
                dispatch(echeqActions.getAuthorizedEntityListRequest(successAction));
            }
        }
        if (e.id !== "entity") {
            setFieldValue("mandataryDocument", null);
            setFieldValue("mandataryEntityCode", null);
            setMandataryDocumentSelected(null);
            setMandataryEntityCodeSelected(null);
        }
    };

    const handleMandatarySelection = (value, setFieldValue) => {
        // para los valores que se resetean al traer la info de cliente bancario
        if (optionsListIMF?.length > 0 || AuthorizedEntityList?.length > 0) {
            setValuesCustom(setFieldValue);
            if (AuthorizedEntityList?.length > 0) {
                setFieldValue("mandataryEntityCode", value.code);
                setMandataryEntityCodeSelected(value.code);
            }
        }
    };

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        ...genericProps,
        mode: "edit",
    };

    const tooltipProps = {
        "data-tooltip": i18n.get("forms.echeqs.requestMandate.payment.mandateAddress.tooltip"),
        "data-tooltip-position": "right",
        "data-tooltip-class": "scheduler",
    };

    const renderFields = (setFieldValue) => {
        const searchSuccessAction = (bankedCustomerInfoData) => {
            setValuesCustom(setFieldValue);
            setFieldValue("bankedCustomerInfo", bankedCustomerInfoData);
            setBankedCustomerInfoSelected(bankedCustomerInfoData);
        };

        return (
            <PageLoading loading={fetching}>
                {mode === "edit" && (
                    <div className="form-text-info ui-mb-8">
                        <I18n id={`${ID_FORM}.textInfo.label`} />
                    </div>
                )}

                <div className={`data-wrapper ${mode === "edit" ? "ui-mt-0" : ""}`}>
                    <I18n
                        id={`${ID_FORM}.subtitle.echeqData`}
                        componentProps={{ className: "data-label ui-fw-bold" }}
                    />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.idEcheq.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.cheque_id || cheque_id}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.cheque_numero || cheque_numero}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                    <span>{i18n.get(`${ID_FORM}.type.${isDeferred ? "deferred" : "today"}.label`)}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.cheque_caracter || cheque_caracter}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.cuitCuilEmitter.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.emisor_cuit || emisor_cuit}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.emitter.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.emisor_razon_social || emisor_razon_social}</span>
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.tenencia_beneficiario_documento || tenencia_beneficiario_documento}</span>
                </div>
                {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                        <span>{`${data?.estado || estado}${
                            substatusesStr !== null ? ` - ${substatusesStr}` : ""
                        }`}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                    <FormattedAmount
                        quantity={data?.amount?.quantity || monto}
                        currency={data?.amount?.currency || emisor_moneda}
                    />
                </div>
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                    <span>
                        <FormattedDate date={data?.fecha_pago || fecha_pago} />
                    </span>
                </div>

                <hr className="ui-mt-7 ui-mb-7" />

                <I18n id={`${ID_FORM}.subtitle.mandateData`} componentProps={{ className: "data-label ui-fw-bold" }} />

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.mandateType.label`} componentProps={{ className: "data-label" }} />
                    <I18n id={`${ID_FORM}.mandateType.payment`} />
                </div>

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="mandateAddress"
                    name="mandateAddress"
                    idField="mandateAddress"
                    isRequired
                    maxLength={150}
                    idActivity={ID_ACTIVITY}
                    value={mandateAddressSelected || data?.mandateAddress}
                    tooltip={tooltipProps}
                    onChange={(e) => setMandateAddressSelected(e)}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "ownAccount",
                            label: i18n.get(`${ID_FORM}.depositAccountType.ownAccount`),
                        },
                        {
                            id: "mandataryAccount",
                            label: i18n.get(`${ID_FORM}.depositAccountType.mandataryAccount`),
                        },
                    ]}
                    key="depositAccountType"
                    name="depositAccountType"
                    idField="depositAccountType"
                    isRequired
                    renderAs="combo"
                    maxLength={150}
                    idActivity={ID_ACTIVITY}
                    value={accountTypeSelected || data?.depositAccountType || ""}
                    onChange={(e) => handleDepositAccountType(e, setFieldValue)}
                />

                {accountTypeSelected !== null && accountTypeSelected?.[0] !== null && accountTypeSelected?.[0] !== "" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={[
                            {
                                id: "entity",
                                label: i18n.get(`${ID_FORM}.mandataryType.entity`),
                            },
                            {
                                id: "thirdParty",
                                label: i18n.get(`${ID_FORM}.mandataryType.thirdParty`),
                            },
                            {
                                id: "IMF",
                                label: i18n.get(`${ID_FORM}.mandataryType.IMF`),
                            },
                        ]}
                        key="mandataryType"
                        name="mandataryType"
                        idField="mandataryType"
                        isRequired
                        disabled={accountTypeSelected?.[0] === "ownAccount"}
                        renderAs="combo"
                        idActivity={ID_ACTIVITY}
                        value={mandataryTypeSelected || [data?.mandataryType] || ""}
                        onChange={(e) => handleMandataryType(e, setFieldValue)}
                    />
                )}

                {mandataryTypeSelected?.[0] === "IMF" && optionsListIMF?.length > 0 && !fetching && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={optionsListIMF}
                        key="mandatary"
                        name="mandatary"
                        idField="mandatary"
                        isRequired
                        renderAs="combo"
                        maxLength={150}
                        idActivity={ID_ACTIVITY}
                        value={data?.mandatary}
                        onChange={(e) => handleMandatarySelection(e, setFieldValue)}
                    />
                )}

                {mandataryTypeSelected?.[0] === "IMF" && optionsListIMF?.length === 0 && !fetching && (
                    <I18n id={`${ID_FORM}.mandatary.noneFound`} componentProps={{ className: "ui-mb-8" }} />
                )}

                {accountTypeSelected?.[0] === "mandataryAccount" &&
                    mandataryTypeSelected?.[0] === "thirdParty" &&
                    Object.keys(bankedCustomerInfoSelected)?.length === 0 && (
                        <>
                            <CustomerEcheqData
                                idActivity={ID_ACTIVITY}
                                genericProps={genericProps}
                                isMobile={isMobile}
                                searchSuccessAction={searchSuccessAction}
                                data={data}
                                customLabel="forms.echeqs.requestMandate.payment.documentNumber.label"
                                buttonLabel={`${ID_FORM}.mandatary.search`}
                            />
                        </>
                    )}

                {accountTypeSelected?.[0] === "mandataryAccount" &&
                    mandataryTypeSelected?.[0] === "thirdParty" &&
                    Object.keys(bankedCustomerInfoSelected)?.length > 0 && (
                        <>
                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.mandatary.label`} componentProps={{ className: "data-label" }} />
                                <span>{bankedCustomerInfoSelected?.nombre}</span>
                            </div>
                            <div className="data-wrapper">
                                <I18n
                                    id={`${ID_FORM}.mandatary.document.nonEditable.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                                <span>{`${bankedCustomerInfoSelected?.documento_tipo} ${bankedCustomerInfoSelected?.documento}`}</span>
                            </div>
                        </>
                    )}

                {mandataryTypeSelected?.[0] === "entity" && optionsListEntity?.length > 0 && !fetching && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={optionsListEntity}
                        key="mandataryDocument"
                        name="mandataryDocument"
                        idField="mandataryDocument"
                        isRequired
                        renderAs="combo"
                        maxLength={150}
                        idActivity={ID_ACTIVITY}
                        value={data?.mandatary}
                        onChange={(e) => handleMandatarySelection(e, setFieldValue)}
                    />
                )}

                {accountTypeSelected?.[0] === "ownAccount" && mandataryTypeSelected?.[0] === "entity" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="mandatorCBU"
                        name="mandatorCBU"
                        idField="mandatorCBU"
                        isRequired
                        renderAs="combo"
                        idActivity={ID_ACTIVITY}
                        value={data?.mandatorCBU || ""}
                        maxLength={22}
                        validationRegularExpresion="^[0-9]*$"
                    />
                )}
            </PageLoading>
        );
    };

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="134px">
                    <I18n id={`${ID_FORM}.termsAndConditions.label`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>
                <Field
                    {...genericPropsConditions}
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "1",
                            label: i18n.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                        },
                    ]}
                    key="termsAndConditionsAcceptance"
                    name="termsAndConditionsAcceptance"
                    idField="termsAndConditionsAcceptance"
                    renderAs="check"
                    additionalClassName="ui-mt-7"
                />
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id: data?.cheque_id || cheque_id,
            cheque_numero: data?.cheque_numero || cheque_numero,
            isDeferred: data?.isDeferred || isDeferred,
            cheque_caracter: data?.cheque_caracter || cheque_caracter,
            emisor_cuit: data?.emisor_cuit || emisor_cuit,
            emisor_razon_social: data?.emisor_razon_social || emisor_razon_social,
            tenencia_beneficiario_documento: data?.tenencia_beneficiario_documento || tenencia_beneficiario_documento,
            estado: data?.estado || estado,
            mandateAddress: data?.mandateAddress || null,
            depositAccountType: data?.depositAccountType || null,
            mandataryType: data?.mandataryType || null,
            mandatorCBU: data?.mandatorCBU || null,
            amount: data?.amount || {
                quantity: monto,
                currency: emisor_moneda,
            },
            mandatary: data?.mandatary || null,
            bankedCustomerInfo:
                mode === "edit" && Object.keys(bankedCustomerInfo).length > 0
                    ? bankedCustomerInfo
                    : data?.bankedCustomerInfo,
            optionsListIMF,
            fecha_pago: data?.fecha_pago || fecha_pago,
            mandataryDocument: data?.mandataryDocument || null,
            mandataryEntityCode: data?.mandataryEntityCode || null,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        renderEditableInPreview,
        handleClickDownload,
        handleBack,
        errorsAux: errors,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

RequestPaymentMandateForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
    isMobile: bool,
    bankedCustomerInfo: shape({}).isRequired,
    listIMF: shape([]).isRequired,
    fetching: bool.isRequired,
    errors: shape({}).isRequired,
    AuthorizedEntityList: shape([]).isRequired,
};
RequestPaymentMandateForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state) && echeqsSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    bankedCustomerInfo: echeqsSelectors.getBankedCustomerInfo(state),
    listIMF: echeqsSelectors.getListIMF(state),
    errors: echeqsSelectors.getErrors(state),
    AuthorizedEntityList: echeqsSelectors.getAuthorizedEntityList(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestPaymentMandateForm);
