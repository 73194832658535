import { groupBy } from "lodash";
import { types as productsTypes } from "reducers/products";
import { types as loginTypes } from "reducers/login";
import { types as sessionTypes } from "reducers/session";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

export const types = {
    ACCOUNT_DETAILS_REQUEST: "accounts/ACCOUNT_DETAILS_REQUEST",
    ACCOUNT_DETAILS_SUCCESS: "accounts/ACCOUNT_DETAILS_SUCCESS",
    ACCOUNT_DOWNLOAD_STATEMENT_REQUEST: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_REQUEST",
    ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS",
    ACCOUNT_DOWNLOAD_STATEMENT_FAILURE: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_FAILURE",
    ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS",
    ACCOUNT_MOVEMENT_DETAILS_REQUEST: "accounts/ACCOUNT_MOVEMENT_DETAILS_REQUEST",
    ACCOUNT_MOVEMENT_DETAILS_SUCCESS: "accounts/ACCOUNT_MOVEMENT_DETAILS_SUCCESS",
    ACCOUNT_READ_REQUEST: "accounts/ACCOUNT_READ_REQUEST",
    ACCOUNT_READ_SUCCESS: "accounts/ACCOUNT_READ_SUCCESS",
    CLOSE_OPTIONS: "accounts/CLOSE_OPTIONS",
    DOWNLOAD_MOVEMENTS_REQUEST: "accounts/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_FAILURE: "accounts/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_MOVEMENTS_SUCCESS: "accounts/DOWNLOAD_MOVEMENTS_SUCCESS",
    EDIT_MOVEMENT_NOTE_REQUEST: "accounts/EDIT_MOVEMENT_NOTE_REQUEST",
    LIST_ACCOUNT_STATEMENTS_REQUEST: "accounts/LIST_ACCOUNT_STATEMENTS_REQUEST",
    LIST_ACCOUNT_STATEMENTS_SUCCESS: "accounts/LIST_ACCOUNT_STATEMENTS_SUCCESS",
    LIST_ACCOUNT_STATEMENTS_FAILURE: "accounts/LIST_ACCOUNT_STATEMENTS_FAILURE",
    LIST_ACCOUNTS_REQUEST: "accounts/LIST_ACCOUNTS_REQUEST",
    LIST_ACCOUNTS_SUCCESS: "accounts/LIST_ACCOUNTS_SUCCESS",
    RESET_FILTERS: "accounts/RESET_FILTERS",
    SET_FILTERS: "accounts/SET_FILTERS",
    SET_NEW_CBU_ALIAS: "accounts/SET_NEW_CBU_ALIAS",
    SET_MOBILE_FILTER: "accounts/SET_MOBILE_FILTER",
    SET_SELECTED_ACCOUNT: "accounts/SET_SELECTED_ACCOUNT",
    SET_SELECTED_MOVEMENT: "accounts/SET_SELECTED_MOVEMENT",
    SET_UNIQUE_ACCOUNT: "accounts/SET_UNIQUE_ACCOUNT",
    TOGGLE_OPTIONS: "accounts/TOGGLE_OPTIONS",
    ACCOUNT_FETCH_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MOVEMENTS_SUCCESS",
    CBU_ALIAS_VIEW: "accounts/CBU_ALIAS_VIEW",
    SET_CHANGE_CBU_SEND_ACTIVITY: "accounts/SET_CHANGE_CBU_SEND_ACTIVITY",
    EXPORT_CBU_DETAIL_REQUEST: "accounts/EXPORT_CBU_DETAIL_REQUEST",
    EXPORT_CBU_DETAIL_FAILURE: "accounts/EXPORT_CBU_DETAIL_FAILURE",
    EXPORT_CBU_DETAIL_SUCCESS: "accounts/EXPORT_CBU_DETAIL_SUCCESS",
    EXPORT_ACCOUNT_DETAIL_REQUEST: "accounts/EXPORT_ACCOUNT_DETAIL_REQUEST",
    EXPORT_ACCOUNT_DETAIL_FAILURE: "accounts/EXPORT_ACCOUNT_DETAIL_FAILURE",
    EXPORT_ACCOUNT_DETAIL_SUCCESS: "accounts/EXPORT_ACCOUNT_DETAIL_SUCCESS",
    LOAD_LIST_ACCOUNTS_REQUEST: "accounts/LOAD_LIST_ACCOUNTS_REQUEST",
    LOAD_LIST_ACCOUNTS_SUCCESS: "accounts/LOAD_LIST_ACCOUNTS_SUCCESS",
    LOAD_LIST_ACCOUNTS_FAILURE: "accounts/LOAD_LIST_ACCOUNTS_FAILURE",
};

export const INITIAL_STATE = {
    account: {}, // No encuentro que se utilice, dado que el detalle utiliza selectedAccount
    accounts: [],
    equivalentTotalBalance: null,
    equivalentTotalBalanceDollars: null,
    fetching: false,
    fetchingDownload: false,
    fetchingMovements: false,
    fetchingAccount: false,
    fetchingMoreMovements: false,
    firstFetched: true,
    idSelectedAccount: null,
    mobileFilter: null,
    movements: [],
    moreMovements: false,
    pageNumber: 1,
    selectedAccount: null,
    selectedFilter: null,
    selectedMovement: null,
    statementLines: null,
    statementLinesByYear: null,
    voucher: null,
    isOptionsVisible: false,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
        selectedFilter: "all",
        movementType: "all",
    },
    totalCount: 0,
    changeCBUSendActivity: "account.set.cbuAlias",
    fetchingExport: false,
    fetchingDownloadStatements: false,
    optionListAccounts: [],
    selectedAccountStatement: null,
    newCbuAlias: null,
    hasAccountQr: false,
};
const reducer = (state = INITIAL_STATE, action = {}) => {
    const {
        account,
        accounts,
        alias,
        equivalentTotalBalance,
        equivalentTotalBalanceDollars,
        filters,
        idSelectedAccount,
        mobileFilter,
        moreStatements,
        pageNumber,
        selectedMovement,
        statement,
        statements,
        statementLines,
        totalCount,
        idAccountForStatement,
        optionListAccounts,
        selectedAccountStatement,
        selectedAccount,
        newCbuAlias,
    } = action;

    switch (action.type) {
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
                filters: filters || state.filters,
                movements: [],
                pageNumber: 1,
            };
        case types.ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                firstFetched: false,
                moreMovements: moreStatements,
                movements: statements,
                totalCount,
            };
        case types.ACCOUNT_READ_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetchingAccount: true,
                idSelectedAccount,
            };
        case types.ACCOUNT_READ_SUCCESS:
            return {
                ...state,
                fetchingAccount: false,
                selectedAccount: account,
            };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...state, fetchingDownloadStatements: true };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS:
            return { ...state, fetchingDownloadStatements: false };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_FAILURE:
            return { ...state, fetchingDownloadStatements: false };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingMoreMovements: true,
            };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMoreMovements: false,
                movements: state.movements ? state.movements.concat(statements) : statements,
                moreMovements: moreStatements,
                pageNumber,
            };
        case types.ACCOUNT_MOVEMENT_DETAILS_REQUEST:
            return { ...state, fetching: true, fetchingMovements: true };
        case types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS:
            return { ...state, fetching: false, voucher: statement.voucher, fetchingMovements: false };
        case types.LIST_ACCOUNT_STATEMENTS_REQUEST:
            return {
                ...state,
                fetching: true,
                statementLines: null,
                statementLinesByYear: null,
                idAccountForStatement: null,
            };
        case types.LIST_ACCOUNT_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                statementLines,
                statementLinesByYear: statementLines ? groupBy(statementLines, "year") : {},
                idAccountForStatement,
                selectedAccountStatement,
            };
        case types.LIST_ACCOUNT_STATEMENTS_FAILURE:
            return {
                ...state,
                fetching: false,
                statementLines: null,
                statementLinesByYear: null,
                idAccountForStatement: null,
            };
        case types.LIST_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
            };
        case types.LIST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                equivalentTotalBalanceDollars,
                fetching: false,
                isOptionsVisible: false,
            };
        case types.RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters };
        case types.SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case types.SET_MOBILE_FILTER:
            return {
                ...state,
                mobileFilter,
            };
        case types.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement,
            };
        case types.SET_UNIQUE_ACCOUNT:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                selectedAccount: accounts[0],
            };

        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }

        case productsTypes.CHANGE_PRODUCT_ALIAS: {
            return {
                ...state,
                fetchingAccount: true,
            };
        }
        case productsTypes.CHANGE_PRODUCT_ALIAS_FAILURE: {
            return {
                ...state,
                fetchingAccount: false,
            };
        }
        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            return {
                ...state,
                selectedAccount: {
                    ...state.selectedAccount,
                    productAlias: alias,
                },
                fetchingAccount: false,
            };
        }
        case types.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_MOVEMENTS_FAILURE:
        case types.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.ACCOUNT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...state,
                pageNumber: 1,
                filters: action.filters,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
                totalCount: action.totalCount,
            };
        case types.SET_CHANGE_CBU_SEND_ACTIVITY:
            return {
                ...state,
                changeCBUSendActivity: action.changeCBUSendActivity,
            };
        case types.EXPORT_CBU_DETAIL_REQUEST:
            return {
                ...state,
                fetchingExport: true,
            };
        case types.EXPORT_CBU_DETAIL_FAILURE:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.EXPORT_CBU_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.EXPORT_ACCOUNT_DETAIL_REQUEST:
            return {
                ...state,
                fetchingExport: true,
            };
        case types.EXPORT_ACCOUNT_DETAIL_FAILURE:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.EXPORT_ACCOUNT_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingExport: false,
            };
        case types.LOAD_LIST_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetching: true,
                optionListAccounts: [],
                selectedAccountStatement: null,
                statementLines: null,
                statementLinesByYear: null,
            };
        case types.LOAD_LIST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                optionListAccounts,
                selectedAccountStatement,
                selectedAccount,
            };
        case types.LOAD_LIST_ACCOUNTS_FAILURE:
            return {
                ...state,
                fetching: false,
                optionListAccounts: [],
                selectedAccountStatement: null,
            };
        case types.SET_NEW_CBU_ALIAS:
            return {
                ...state,
                newCbuAlias,
            };
        case loginTypes.LOGIN_SUCCESS:
            return {
                ...state,
                hasAccountQr: action.hasAccountQr,
            };
        case sessionTypes.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                hasAccountQr: action.hasAccountQr,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "accounts",
        storage: storageSession,
        whitelist: ["hasAccountQr"],
    },
    reducer,
);

export const actions = {
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    details: (idSelectedAccount, filters, formikBag) => ({
        type: types.ACCOUNT_DETAILS_REQUEST,
        idSelectedAccount,
        filters,
        formikBag,
    }),
    downloadMovements: (idAccount, format) => ({
        type: types.DOWNLOAD_MOVEMENTS_REQUEST,
        idAccount,
        format,
    }),
    downloadStatement: (idAccount, idStatement, path, fileNameSelected) => ({
        type: types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST,
        idAccount,
        idStatement,
        path,
        fileNameSelected,
    }),
    editMovementNote: (accountId, movementId, note) => ({
        type: types.EDIT_MOVEMENT_NOTE_REQUEST,
        accountId,
        movementId,
        note,
    }),
    fetchMoreMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    listAccounts: () => ({
        type: types.LIST_ACCOUNTS_REQUEST,
    }),
    listStatementLines: (idAccount, selectedAccount) => ({
        type: types.LIST_ACCOUNT_STATEMENTS_REQUEST,
        idAccount,
        selectedAccount,
    }),
    movementDetails: (idAccount, idStatement) => ({
        type: types.ACCOUNT_MOVEMENT_DETAILS_REQUEST,
        idAccount,
        idStatement,
    }),
    readAccount: (idAccount) => ({
        type: types.ACCOUNT_READ_REQUEST,
        idAccount,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    setFilters: (filters) => ({
        type: types.SET_FILTERS,
        filters,
    }),
    setMobileFilter: (mobileFilter) => ({
        type: types.SET_MOBILE_FILTER,
        mobileFilter,
    }),
    setSelectedMovement: (selectedMovement) => ({
        type: types.SET_SELECTED_MOVEMENT,
        selectedMovement,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    fetchMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    setChangeCBUSendActivity: (changeCBUSendActivity) => ({
        type: types.SET_CHANGE_CBU_SEND_ACTIVITY,
        changeCBUSendActivity,
    }),
    exportCBUDetailRequest: (cbuData, idAccount) => ({
        type: types.EXPORT_CBU_DETAIL_REQUEST,
        cbuData,
        idAccount,
    }),
    exportAccountDetailsRequest: (dataToExport, idAccount, isDesktop) => ({
        type: types.EXPORT_ACCOUNT_DETAIL_REQUEST,
        dataToExport,
        idAccount,
        isDesktop,
    }),
    loadListAccounts: (idAccount) => ({
        type: types.LOAD_LIST_ACCOUNTS_REQUEST,
        idAccount,
    }),
    setNewCbuAlias: (newCbuAlias) => ({
        type: types.SET_NEW_CBU_ALIAS,
        newCbuAlias,
    }),
};

export const selectors = {
    getAccount: ({ accounts }) => accounts.account,
    getAccounts: ({ accounts }) => accounts.accounts,
    getTotalAccounts: ({ accounts }) => accounts.accounts.length,
    getEquivalentTotalBalance: ({ accounts }) => accounts.equivalentTotalBalance,
    getEquivalentTotalBalanceDollars: ({ accounts }) => accounts.equivalentTotalBalanceDollars,
    getFetching: ({ accounts }) => accounts.fetching,
    getFetchingDownload: ({ accounts }) => accounts.fetchingDownload,
    getFetchingMovements: ({ accounts }) => accounts.fetchingMovements,
    getFetchingAccount: ({ accounts }) => accounts.fetchingAccount,
    getFetchingMoreMovements: ({ accounts }) => accounts.fetchingMoreMovements,
    getFilters: ({ accounts }) => accounts.filters,
    getFirstFetched: ({ accounts }) => accounts.firstFetched,
    getIdSelectedAccount: ({ accounts }) => accounts.idSelectedAccount,
    getIsOptionsVisible: ({ accounts }) => accounts.isOptionsVisible,
    getSelectedAccount: ({ accounts }) => accounts.selectedAccount,
    getSelectedFilter: ({ accounts }) => accounts.selectedFilter,
    getSelectedMovement: ({ accounts }) => accounts.selectedMovement,
    getStatementLines: ({ accounts }) => accounts.statementLines,
    getStatementLinesByYear: ({ accounts }) => accounts.statementLinesByYear,
    getPageNumber: ({ accounts }) => accounts.pageNumber,
    getMobileFilter: ({ accounts }) => accounts.mobileFilter,
    getMoreMovements: ({ accounts }) => accounts.moreMovements,
    getMovements: ({ accounts }) => accounts.movements,
    getVoucher: ({ accounts }) => accounts.voucher,
    getTotalCount: ({ accounts }) => accounts.totalCount,
    getChangeCBUSendActivity: ({ accounts }) => accounts.changeCBUSendActivity,
    isFetchingExport: ({ administrationUsers }) => administrationUsers.fetchingExport,
    getFetchingDownloadStatement: ({ accounts }) => accounts.fetchingDownloadStatements,
    getIdAccountForStatement: ({ accounts }) => accounts.idAccountForStatement,
    getOptionListAccounts: ({ accounts }) => accounts.optionListAccounts,
    getSelectedAccountStatement: ({ accounts }) => accounts.selectedAccountStatement,
    getIsFetchingExport: ({ accounts }) => accounts.fetchingExport,
    getNewCbuAlias: ({ accounts }) => accounts.newCbuAlias,
    getAccountQr: ({ accounts }) => accounts.hasAccountQr,
};
