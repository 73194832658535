import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { func, instanceOf, shape, number, string, bool, arrayOf, any, oneOfType } from "prop-types";

import { actions as userTransactionsActions, selectors as userTransactionsSelectors } from "reducers/userTransactions";

import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import UserTransactionItem from "pages/userTransactions/_components/TransactionItem";
import Button from "pages/_components/Button";
import { ID_ACTIVITY as activityChangePersonalData } from "pages/forms/customForms/RequestChangePersonalData";
import { push } from "react-router-redux";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import moment from "moment";

const ACTIVITIES_WITH_DETAIL = [activityChangePersonalData];
class List extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        pageNumber: number,
        filters: shape({
            idFilter: string,
            dateFrom: oneOfType([instanceOf(Date), instanceOf(moment)]),
            dateTo: oneOfType([instanceOf(Date), instanceOf(moment)]),
            pageNumber: number,
        }).isRequired,
        showSearchButton: bool.isRequired,
        userTransactions: arrayOf(any),
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetching: bool.isRequired,
        showEnvironment: bool,
    };

    static defaultProps = {
        pageNumber: 1,
        showEnvironment: false,
        userTransactions: [],
    };

    state = {
        searchMoreTransactionsVisible: false,
    };

    fetchMoreTransactions = () => {
        const { filters, dispatch, pageNumber } = this.props;

        dispatch(userTransactionsActions.loadMoreRequest(filters, pageNumber));
    };

    onPullForMoreInfo = () => {
        const { showSearchButton } = this.props;
        if (showSearchButton) {
            this.setState({ searchMoreTransactionsVisible: true });
        }
    };

    hasDetails = (transaction) =>
        transaction && transaction.idActivity && ACTIVITIES_WITH_DETAIL.includes(transaction.idActivity);

    onClickTransaction = (transaction) => {
        const { dispatch } = this.props;
        dispatch(push(`/userTransaction/${transaction.idTransaction}`));
    };

    render() {
        const { userTransactions, hasMoreData, isDesktop, showSearchButton, fetching, showEnvironment } = this.props;

        const { searchMoreTransactionsVisible } = this.state;
        if (!userTransactions) {
            return null;
        }

        const list = userTransactions.map((transaction) => (
            <Table.Row
                key={transaction.idTransaction}
                renderAs={this.hasDetails(transaction) ? Link : "div"}
                to={`/userTransaction/${transaction.idTransaction}`}
                additionalClassName={transaction.idActivity !== "request.changePersonalData.send" ? "no-hover" : ""}>
                <UserTransactionItem
                    key={`userTransactions-${transaction.idTransaction}`}
                    transaction={transaction}
                    isDesktop={isDesktop}
                    showEnvironment={showEnvironment}
                />
            </Table.Row>
        ));

        if (!userTransactions.length) {
            return <NoDataContainer fetching={fetching} withFilters noFiltersDataText="userTransactions.list.none" />;
        }

        if (!isDesktop) {
            return (
                <>
                    <Notification scopeToShow="userTransactionsList" />
                    {userTransactions.map((transaction) => (
                        <UserTransactionItem
                            key={`userTransactions-${transaction.idTransaction}`}
                            transaction={transaction}
                            isDesktop={isDesktop}
                            showEnvironment={showEnvironment}
                            onClick={this.hasDetails(transaction) ? () => this.onClickTransaction(transaction) : null}
                        />
                    ))}

                    {hasMoreData && (
                        <Button
                            block
                            bsStyle="secondary"
                            onClick={this.fetchMoreTransactions}
                            image="images/plusWhite.svg"
                            label="userTransactions.more"
                            className="ui-mt-7"
                            loading={fetching}
                        />
                    )}
                </>
            );
        }

        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="userTransactions.list.header.date" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="userTransactions.list.header.time" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="userTransactions.list.header.channel" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="userTransactions.list.header.activity" />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id="userTransactions.list.header.ip" />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>
                        {list}
                        {showSearchButton && searchMoreTransactionsVisible && (
                            <Link
                                className="btn btn-block btn-link"
                                key="searchMoreTransactionsButton"
                                to="/userTransactions/list/filters">
                                <I18n id="userTransactions.list.search" />
                            </Link>
                        )}
                    </Table.Body>
                </Table>
                {hasMoreData && (
                    <Button
                        bsStyle="secondary"
                        className="btn-show-more"
                        onClick={this.fetchMoreTransactions}
                        image="images/plusWhite.svg"
                        label="userTransactions.more"
                        loading={fetching}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userTransactions: userTransactionsSelectors.getUserTransactions(state),
    hasMoreData: userTransactionsSelectors.getHasMoreData(state),
    fetching: userTransactionsSelectors.getFetching(state),
    pageNumber: userTransactionsSelectors.getPageNumber(state),
    filters: userTransactionsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(List);
