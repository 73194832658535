import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "creditCards/LIST_REQUEST",
    LIST_FAILURE: "creditCards/LIST_FAILURE",
    LIST_SUCCESS: "creditCards/LIST_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: true,
    list: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LIST_SUCCESS]: (_, action) => ({
        fetching: false,
        list: action.list,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "path"),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list"),
};

export const selectors = {
    isFetching: ({ creditCards }) => creditCards.fetching,
    getList: ({ creditCards }) => creditCards.list,
};
