import { arrayOf, bool, func, object, string } from "prop-types";
import React, { Component } from "react";

import RadioButton from "pages/_components/fields/Radio";

class RadioButtonGroup extends Component {
    static propTypes = {
        inLineControl: bool,
        labelKey: string,
        name: string.isRequired,
        onChange: func.isRequired,
        options: arrayOf(object).isRequired,
        value: string,
        valueKey: string,
        selectorId: string.isRequired,
        disabled: bool,
    };

    static defaultProps = {
        labelKey: "label",
        inLineControl: false,
        value: "",
        valueKey: "id",
        disabled: false,
    };

    handleRadioChange = (event) => {
        const { onChange } = this.props;

        onChange(event.target.value);
    };

    render() {
        const { inLineControl, labelKey, name, options, selectorId, value, valueKey, disabled } = this.props;

        return (
            <div className="form-group-control-list">
                {options.map((option) => (
                    <RadioButton
                        id={`${selectorId}_${option[valueKey]}`}
                        checked={option[valueKey] === value}
                        inLineControl={inLineControl}
                        key={`${selectorId}_${option[valueKey]}`}
                        label={option[labelKey]}
                        name={name}
                        onChange={this.handleRadioChange}
                        value={option[valueKey]}
                        disabled={disabled}
                    />
                ))}
            </div>
        );
    }
}

export default RadioButtonGroup;
