import Image from "pages/_components/Image";
import { bool, shape } from "prop-types";
import React from "react";

import "./_investorProfile.scss";

const getImage = (profile) => {
    const investorProfileUrl = "images/investorProfile";
    if (profile) {
        const status = profile.status ? profile.status : "upToDate";
        return `${investorProfileUrl}/${profile.type}_${status}.svg`;
    }

    return `${investorProfileUrl}/noProfile.svg`;
};

const getImageStyle = (profile) => {
    if (profile) {
        if (profile.status === null || profile.status === "upToDate") {
            return "invester-image-svg";
        }
    }
    return "invester-image-svg-e";
};

const InvestorProfileImage = ({ profile, showClassName }) => (
    <div className={showClassName ? "invester-image" : ""}>
        <Image src={getImage(profile)} className={getImageStyle(profile)} />
    </div>
);

InvestorProfileImage.propTypes = {
    profile: shape({}),
    showClassName: bool,
};

InvestorProfileImage.defaultProps = {
    profile: null,
    showClassName: true,
};

export default InvestorProfileImage;
