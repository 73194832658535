import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string, func } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as comexActions, selectors as comexSelectors } from "reducers/comex";
import { selectors as sessionSelectors } from "reducers/session";
import Date from "pages/_components/fields/DateField";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Yup from "yup";
import * as i18n from "util/i18n";
import { compose } from "redux";
import defaultDateFromList from "util/defaultDateFromList";
import { clearFiltersAndSubmit } from "util/form";

class Filters extends Component {
    static propTypes = {
        fetching: bool,
        dateFrom: string,
        dateTo: string,
        idForm: string.isRequired,
        dispatch: func.isRequired,
        isMobile: bool,
        setFieldValue: func.isRequired,
        setFieldError: func.isRequired,
        setValues: func.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        dateFrom: null,
        dateTo: null,
        isMobile: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(comexActions.setResetQuery(true));
    }

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    dateFrom: defaultDateFromList().comex.dateFrom,
                    dateTo: defaultDateFromList().comex.dateTo,
                }),
            submitForm,
        );
    };

    render() {
        const { fetching, idForm, dateTo, dateFrom, isMobile, setFieldValue, setFieldError } = this.props;
        return (
            <div className="filters-container comex">
                <Form className="form-content">
                    <Field
                        idField="dateFrom"
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={idForm}
                        isClearable
                        name="dateFrom"
                        selectsStart
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={defaultDateFromList().comex.dateFrom}
                        onChange={(e) => {
                            setFieldValue("dateFrom", e);
                            setFieldError("dateFrom", null);
                        }}
                    />
                    <Field
                        idField="dateToPeriodFilter"
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm={idForm}
                        isClearable
                        name="dateTo"
                        selectsEnd
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={dateFrom}
                        onChange={(e) => {
                            setFieldValue("dateTo", e);
                            setFieldError("dateTo", null);
                        }}
                    />

                    <Button
                        block={isMobile}
                        bsStyle="primary"
                        label="product.filters.filter"
                        loading={fetching}
                        type="submit"
                    />
                    <Button
                        block={isMobile}
                        bsStyle="outline"
                        className="btn-clear-filters"
                        label="product.filters.clearFilters"
                        image="images/ui-icons/ui-clear-filters.svg"
                        loading={fetching}
                        onClick={this.handleClearFilters}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentLang: i18nSelectors.getLang(state),
    filters: comexSelectors.getFilters(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            dateFrom: props.filters.dateFrom ? props.filters.dateFrom : null,
            dateTo: props.filters.dateTo ? props.filters.dateTo : null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get("comex.receiptsList.dateFrom.range.error"))
                              .required(i18n.get("comex.receiptsList.dateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("comex.receiptsList.dateFrom.empty.error")),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get("comex.receiptsList.dateTo.range.error"))
                              .required(i18n.get("comex.receiptsList.dateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("comex.receiptsList.dateTo.empty.error")),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch } = formikBag.props;
            const newFilters = { ...values };
            dispatch(comexActions.receiptsComexListRequest(newFilters, formikBag));
        },
    }),
)(Filters);
