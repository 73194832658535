import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { selectors as loginSelectors } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

import SettingsOption from "pages/settings/_components/SettingsOption";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { get as getMessage } from "util/i18n";

import "./_settings.scss";

class Settings extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        user: shape({
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            userFullName: string,
        }),
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        environments: null,
        user: null,
    };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    getSettingsData = () => {
        const { user, isMobile, isDesktop, activeEnvironment } = this.props;
        const { previousLoginInfo, userFullName, email, mobileNumber, documentType, documentNumber } = user;

        const { accountOfficer } = activeEnvironment;

        return (
            <div className="card-settings-data inline">
                <span className="user-fullname">{userFullName}</span>

                <div className="data-wrapper">
                    <span className="data-label">{getMessage(`documentType.label.${documentType}`)}</span>
                    <span>{documentNumber}</span>
                </div>

                <div className="data-wrapper">
                    <span className="data-label">{getMessage("settings.label.email")}</span>
                    <span>{email}</span>
                </div>

                <div className="data-wrapper">
                    <span className="data-label">{getMessage("settings.label.phone")}</span>
                    <span>{mobileNumber}</span>
                </div>

                {previousLoginInfo.length > 0 && (
                    <div className="data-wrapper">
                        <span className="data-label">{getMessage("settings.lastLogin.date")}</span>
                        <div className="d-flex-wrap last-conection">
                            <span>{getLastLoginDate(previousLoginInfo[0])}&nbsp;hs.</span>
                            {isDesktop && <span>&nbsp;-&nbsp;</span>}
                            <span>{getLastLoginPlace(previousLoginInfo[0].city, previousLoginInfo[0].country)}</span>
                            {isDesktop && <span>&nbsp;|&nbsp;</span>}
                            <Link to="/settings/lastLogin">{getMessage("settings.lastLogin.lastLocation")}</Link>
                        </div>
                    </div>
                )}

                <div className="data-wrapper">
                    <span className="data-label">{getMessage("settings.label.officer")}</span>
                    <span>{accountOfficer}</span>
                </div>

                {isMobile && (
                    <div className="data-wrapper user-activity">
                        <Image src="images/user-activity.svg" />
                        <Link to="/userTransactions/list">{getMessage("settings.userTransactions")}</Link>
                    </div>
                )}
            </div>
        );
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return <Head accessibilityTextId="settings.index" />;
    };

    render() {
        const { environments, isMobile } = this.props;
        return (
            <>
                <Notification scopeToShow="settings" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="title-left-header">
                        <I18n id="settings.index" component="h1" />
                    </div>
                    <div className="container">
                        <Row>
                            <Col className="col-12">{this.getSettingsData()}</Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6}>
                                <I18n
                                    id="settings.title.personal"
                                    component="h3"
                                    componentProps={{ className: "navigational-list-title-settings" }}
                                />
                                <ul className="navigational-list">
                                    <SettingsOption
                                        linkTo="/formCustom/requestChangePersonalData"
                                        messageKey="settings.options.changePersonalData"
                                    />
                                </ul>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6}>
                                <I18n
                                    id="settings.title.configuration"
                                    component="h3"
                                    componentProps={{ className: "navigational-list-title-settings" }}
                                />

                                <ul className="navigational-list">
                                    <SettingsOption
                                        linkTo="/settings/fingerprintConfiguration"
                                        messageKey={
                                            isMobile
                                                ? "settings.biometrics.title.mobile"
                                                : "settings.options.biometricsConfiguration"
                                        }
                                    />
                                    <SettingsOption
                                        linkTo="/settings/pushNotifications"
                                        messageKey={
                                            isMobile
                                                ? "settings.pushNotifications.title.mobile"
                                                : "settings.options.pushNotifications"
                                        }
                                    />
                                    <SettingsOption
                                        linkTo="/settings/notificationsConfiguration"
                                        messageKey="settings.options.notificationsConfiguration"
                                    />

                                    {environments && Object.keys(environments).length > 1 && (
                                        <SettingsOption
                                            linkTo="/settings/setDefaultEnvironment"
                                            messageKey="settings.options.setDefaultEnvironment"
                                        />
                                    )}
                                </ul>
                            </Col>

                            <Col sm={12} md={6}>
                                <I18n
                                    id="settings.title.security"
                                    component="h3"
                                    componentProps={{ className: "navigational-list-title-settings" }}
                                />

                                <ul className="navigational-list">
                                    <SettingsOption
                                        linkTo="/settings/changePassword"
                                        messageKey="settings.options.changePassword"
                                    />
                                    <SettingsOption
                                        linkTo="/settings/changeSecuritySeal"
                                        messageKey="settings.options.changeSecuritySeal"
                                    />
                                    <SettingsOption
                                        linkTo="/settings/myDevices"
                                        messageKey="settings.options.myDevices"
                                    />
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(Settings);
