import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { any, arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";

import * as i18n from "util/i18n";

import { actions as massPaymentsActions, selectors as massivePaymentsSelector } from "reducers/massPayments";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as formActions } from "reducers/form";
import {
    actions as frequentRecipientsActions,
    selectors as frequentRecipientsSelectors,
} from "reducers/frequentRecipients";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import BigModal from "pages/_components/BigModal/BigModal";
import SimpleModal from "components/SimpleModal/SimpleModal";
import CreateMassivePayment from "./CreateMassivePayment";
import DetailHeadInfo from "../_components/DetailHeadInfo/DetailHeadInfo";
import PaymentList from "../_components/PaymentList/PaymentList";
import AddRecipientModal from "./echeqs/frecuentRecipients/AddRecipientModal";

const TransferMassiveStep2 = (props) => {
    const {
        isMobile,
        customMode,
        payments,
        fetching,
        dispatch,
        transaction,
        pageNumber,
        hasMoreData,
        paymentHeader,
        dataToEdit: { paymentsToEdit, detailsToEdit },
        setFieldValue,
        fetchingDownload,
        firstFetched,
        frequentRecipients,
        frequentRecipientsForMassive,
        notificationModalShowed,
        history,
        activeEnvironment,
    } = props;

    const mode = customMode?.replace("massive-list-", "");

    const { idTransaction, successfullyCompleted } = transaction;

    const [showModal, setShowModal] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        if (mode === "view" && (successfullyCompleted || idTransaction)) {
            dispatch(massPaymentsActions.getListOfTransferPaymentsRequest(idTransaction, 1, true));
            dispatch(massPaymentsActions.getPaymentSummary(idTransaction));
        } else if (mode === "edit" || mode === "preview") {
            if (payments?.length === 0 && mode === "edit") {
                setShowModal(true);
            }

            dispatch(massPaymentsActions.loadPaymentsToEdit());
            dispatch(frequentRecipientsActions.loadFrequentRecipients(1, null, true));
        }
    }, []);

    useEffect(() => {
        if (mode === "edit" && paymentsToEdit?.length && !showModal && !notificationModalShowed) {
            handleShowNotificationModal();
        }
    }, [paymentsToEdit, showModal, notificationModalShowed]);

    const fetchMorePayments = () => {
        const nextPageNumber = hasMoreData ? pageNumber + 1 : pageNumber;
        dispatch(massPaymentsActions.getListOfTransferPaymentsRequest(idTransaction, nextPageNumber, false));
    };

    function handleShowNotificationModal() {
        dispatch(
            notificationModalActions.showNotification(
                "",
                "beneficiary",
                i18n.get("forms.pay.transfers.massive.payment.list.edit.notificationModal.mainText"),
                "forms.pay.transfers.massive.payment.list.edit.notificationModal.title",
            ),
        );
        if (!notificationModalShowed) {
            return dispatch(massPaymentsActions.setNotificationModalShowed(true));
        }
        return null;
    }

    const handleShowNewPaymentModal = () => {
        setShowModal(!showModal);
        setPaymentSelected(null);
    };

    const paymentDeleteHandler = (id) => {
        setShowConfirmationModal(true);

        setIdToDelete(id);
    };

    const paymentEditHandler = (id) => {
        setPaymentSelected(paymentsToEdit?.find((payment) => payment?.id === id));
        setShowModal(true);
    };

    const clearPaymentSelected = () => setPaymentSelected(null);

    const renderFields = () => (
        <>
            <DetailHeadInfo
                isDesktop={!isMobile}
                details={detailsToEdit}
                mode={mode}
                isFinished={successfullyCompleted || false}
                currency={paymentHeader?.currency}
            />

            <PaymentList
                isDesktop={!isMobile}
                showSearchButton={false}
                itemsAreClickeable={false}
                fetching={false}
                paymentItems={paymentsToEdit || []}
                mode={mode}
                paymentDeleteHandler={paymentDeleteHandler}
                paymentEditHandler={paymentEditHandler}
                hasMoreData={hasMoreData}
                isFinished={successfullyCompleted || false}
                idTransaction={idTransaction}
                fetchingDownload={fetchingDownload}
                fetchMorePayments={fetchMorePayments}
                firstFetched={firstFetched}
                pageNumber={pageNumber}
                frequentRecipients={frequentRecipients}
                frequentRecipientsForMassive={frequentRecipientsForMassive}
                frequentRecipientsPermissions={activeEnvironment.permissions.frequentDestinations}
            />

            {showModal &&
                ReactDOM.createPortal(
                    <BigModal
                        className="massive-transfers-payment-modal"
                        showModal={showModal}
                        handleShowModal={handleShowNewPaymentModal}
                        modalWidth="548px"
                        modalTitle={
                            paymentSelected
                                ? "forms.pay.transfers.massive.payment.edit.formName"
                                : "forms.pay.transfers.massive.payment.create.formName"
                        }
                        isMobile={isMobile}>
                        <CreateMassivePayment
                            handleShowModal={handleShowNewPaymentModal}
                            paymentSelected={paymentSelected}
                            clearPaymentSelected={clearPaymentSelected}
                            isDesktop={!isMobile}
                            paymentDetails={detailsToEdit}
                            paymentHeader={paymentHeader}
                            frequentRecipients={frequentRecipients}
                            history={history}
                        />
                    </BigModal>,
                    document.querySelector("#modal"),
                )}

            <AddRecipientModal isMobile={isMobile} dispatch={dispatch} />

            {showConfirmationModal && (
                <SimpleModal
                    modalIcon="images/warningIcon.svg"
                    modalMainText="forms.pay.transfers.massive.payment.list.delete.confirmationModal.label"
                    closeModal={() => setShowConfirmationModal(false)}
                    buttonAction={() => {
                        dispatch(massPaymentsActions.deletePayment(idToDelete));
                        dispatch(
                            notificationModalActions.showNotification(
                                "forms.pay.transfers.massive.payment.list.delete.confirmationModal.success.label",
                                "success",
                            ),
                        );
                    }}
                    isDisplayed
                />
            )}
        </>
    );

    const cancel = () => {
        dispatch(massPaymentsActions.loadPaymentsToEdit());
    };

    const saveAndContinue = () => {
        setFieldValue("uploadType", ["manually"]);
        setFieldValue("fileId", null);
        dispatch(massPaymentsActions.setPaymentsFromEdit());
        dispatch(formActions.closeConfirmation());
    };

    const renderEditActions = () => {
        if (isMobile) {
            return (
                <>
                    <Button
                        type="button"
                        label="forms.pay.transfers.massive.payment.list.action.saveAndContinue.label"
                        bsStyle="primary"
                        loading={fetching}
                        onClick={saveAndContinue}
                    />
                    <Button
                        type="button"
                        label="forms.pay.transfers.massive.payment.list.action.addPayment.label"
                        bsStyle="secondary"
                        image="images/plusWhite.svg"
                        loading={fetching}
                        onClick={handleShowNewPaymentModal}
                    />
                    <Button type="button" label="global.cancel" bsStyle="outline" loading={fetching} onClick={cancel} />
                </>
            );
        }
        return (
            <>
                <Button type="button" label="global.cancel" bsStyle="outline" loading={fetching} onClick={cancel} />
                <Button
                    type="button"
                    label="forms.pay.transfers.massive.payment.list.action.addPayment.label"
                    bsStyle="secondary"
                    image="images/plusWhite.svg"
                    loading={fetching}
                    onClick={handleShowNewPaymentModal}
                />
                <Button
                    type="button"
                    label="forms.pay.transfers.massive.payment.list.action.saveAndContinue.label"
                    bsStyle="primary"
                    loading={fetching}
                    onClick={saveAndContinue}
                />
            </>
        );
    };

    return (
        <>
            {renderFields()}
            <div className="ui-btn-wrapper-stretch">{mode === "edit" && renderEditActions()}</div>
        </>
    );
};

TransferMassiveStep2.propTypes = {
    customMode: string.isRequired,
    isMobile: bool.isRequired,
    hasMoreData: bool.isRequired,
    fetching: bool.isRequired,
    transaction: shape({}),
    payments: arrayOf(any),
    dispatch: func.isRequired,
    pageNumber: number.isRequired,
    paymentHeader: shape([]).isRequired,
    dataToEdit: shape({}).isRequired,
    setFieldValue: func,
    fetchingDownload: bool.isRequired,
    firstFetched: bool.isRequired,
    frequentRecipients: arrayOf(any).isRequired,
    frequentRecipientsForMassive: arrayOf(any).isRequired,
    notificationModalShowed: bool.isRequired,
    history: objectOf(any).isRequired,
    activeEnvironment: objectOf(any).isRequired,
};

TransferMassiveStep2.defaultProps = {
    transaction: {},
    setFieldValue: () => {},
    payments: [],
};

const mapStateToProps = (state) => ({
    payments: massivePaymentsSelector.getPayments(state),
    pageNumber: state.massPayments.pageNumber,
    totalPages: state.massPayments.totalPages,
    hasMoreData: state.massPayments.hasMoreData,
    fetching: state.massPayments.fetching,
    paymentHeader: massivePaymentsSelector.getPaymentHeader(state),
    dataToEdit: massivePaymentsSelector.getDataToEdit(state),
    fetchingDownload: massivePaymentsSelector.getFetchingDownloadFile(state),
    firstFetched: massivePaymentsSelector.getIsFirstFetching(state),
    frequentRecipients: frequentRecipientsSelectors.getRecipientList(state),
    frequentRecipientsForMassive: frequentRecipientsSelectors.getRecipientListForMassive(state),
    notificationModalShowed: massivePaymentsSelector.getNotificationModalShowed(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(withRouter, connect(mapStateToProps))(TransferMassiveStep2);
