import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LOCATION_CHANGE } from "react-router-redux";

import { types as fingerprintTypes } from "reducers/fingerprint";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    RESET: "login/RESET",
    INIT_LOGIN_FLOW: "login/INIT_LOGIN_FLOW",

    FINALIZE_LOGIN: "login/FINALIZE_LOGIN",

    LOGOUT_REQUEST: "login/LOGOUT_REQUEST",

    LOGIN_STEP_1_REQUEST: "login/LOGIN_STEP_1_REQUEST",
    LOGIN_STEP_1_SUCCESS: "login/LOGIN_STEP_1_SUCCESS",

    // TODO: Delete these types if oauth is the only flavour
    LOGIN_STEP_2_REQUEST: "login/LOGIN_STEP_2_REQUEST",
    LOGIN_STEP_2_SUCCESS: "login/LOGIN_STEP_2_SUCCESS",
    LOGIN_STEP_2_FAILURE: "login/LOGIN_STEP_2_FAILURE",

    CHANGE_PASSWORD_REQUEST: "login/CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "login/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "login/CHANGE_PASSWORD_FAILURE",
    CANCEL_PASSWORD_CHANGE: "login/CANCEL_PASSWORD_CHANGE",
    PASSWORD_EXPIRED: "login/PASSWORD_EXPIRED",

    LOGIN_OAUTH_REQUEST: "login/LOGIN_OAUTH_REQUEST",
    LOGIN_OAUTH_SUCCESS: "login/LOGIN_OAUTH_SUCCESS",
    LOGIN_OAUTH_FAILURE: "login/LOGIN_OAUTH_FAILURE",
    LOGIN_LIST_ENVIRONMENTS_REQUEST: "login/LOGIN_LIST_ENVIRONMENTS_REQUEST",
    LOGIN_LIST_ENVIRONMENTS_SUCCESS: "login/LOGIN_LIST_ENVIRONMENTS_SUCCESS",

    LOGIN_STEP_3_REQUEST: "login/LOGIN_STEP_3_REQUEST",
    LOGIN_STEP_3_SUCCESS: "login/LOGIN_STEP_3_SUCCESS",
    LOGIN_STEP_3_FAILURE: "login/LOGIN_STEP_3_FAILURE",
    LOGIN_STEP_4_REQUEST: "login/LOGIN_STEP_4_REQUEST",

    LOGIN_FAILURE: "login/LOGIN_FAILURE",
    LOGIN_FAILURE_REQUIRE_CAPTCHA: "LOGIN_FAILURE_REQUIRE_CAPTCHA",
    LOGIN_FINGERPRINT_FAILURE: "login/LOGIN_FINGERPRINT_FAILURE",
    LOGIN_SUCCESS: "login/LOGIN_SUCCESS",

    FINGERPRINT_LOGIN_SUBMIT: "login/FINGERPRINT_LOGIN_SUBMIT",
    FINGERPRINT_LOGIN_PRE: "login/FINGERPRINT_LOGIN_PRE",
    FINGERPRINT_LOGIN_PRE_SUCCESS: "login/FINGERPRINT_LOGIN_PRE_SUCCESS",

    SET_REMEMBERED_USER: "login/SET_REMEMBERED_USER",
    REMOVE_REMEMBERED_USER: "login/REMOVE_REMEMBERED_USER",
    SET_FROM_ONBOARDING_DATA: "login/SET_FROM_ONBOARDING_DATA",
    MARK_ENVIRONMENTS_DISABLED: "login/MARK_ENVIRONMENTS_DISABLED",
    GO_BACK_LOGIN_SHOW_MESSAGE: "login/GO_BACK_LOGIN_SHOW_MESSAGE",
    GO_BACK_LOGIN: "login/GO_BACK_LOGIN",
    SET_FINGERPRINT_ENVIRONMENT_RESTRICTED: "login/SET_FINGERPRINT_ENVIRONMENT_RESTRICTED",
    SET_REGION: "session/SET_REGION",
    GET_CLIENT_COUNTRY_REQ: "session/GET_CLIENT_COUNTRY_REQ",
    GET_CLIENT_COUNTRY_RES: "session/GET_CLIENT_COUNTRY_RES",

    DISMISS_CAMPAIGN_REQUEST: "campaigns/DISMISS_CAMPAIGN_REQUEST",

    SET_ALERT_EXPIRED_PASSWORD: "SET_ALERT_EXPIRED_PASSWORD",
    HIDE_ALERT_EXPIRED_PASSWORD: "HIDE_ALERT_EXPIRED_PASSWORD",
};

export const INITIAL_STATE = {
    rememberedUser: null,
    exchangeToken: null,
    showCaptcha: false,
    fetching: false,
    lang: "",
    environments: {},
    fromOnboardingLoginData: null,
    username: null,
    userFirstName: null,
    userFullName: null,
    fingerPrintEnvironmentRestricted: false,
    idEnvironmentToAcceptConditions: null,
    region: null,
    clientCountry: null,
    hasLogged: null,
    displayCampaigns: true,
    shouldRememberEmail: false,
    document: null,
    prevUsername: null,
    prevDocument: null,
    prevSecuritySeal: null,
    prevSecuritySealAlt: null,
    passwordExpired: false,
    loginSuccess: false,
    showAlertExpiredPassword: false,
};

const reducer = createReducer(INITIAL_STATE, {
    [LOCATION_CHANGE]: (state) => ({ ...state, fetching: false }),
    [types.SET_REGION]: (state, { region }) => ({
        ...state,
        region,
    }),
    [types.GET_CLIENT_COUNTRY_RES]: (state, { clientCountry }) => ({
        ...state,
        clientCountry,
    }),
    [types.SET_FROM_ONBOARDING_DATA]: (state, { fromOnboardingLoginData }) => ({
        ...state,
        fromOnboardingLoginData,
    }),
    [types.RESET]: ({ rememberedUser, fromOnboardingLoginData, region, clientCountry }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        fromOnboardingLoginData,
        region,
        clientCountry,
        fetching: false,
    }),
    [types.INIT_LOGIN_FLOW]: ({ fromOnboardingLoginData, rememberedUser, region, clientCountry }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        fromOnboardingLoginData,
        region,
        clientCountry,
    }),

    [types.SET_FINGERPRINT_ENVIRONMENT_RESTRICTED]: (state) => ({ ...state, fingerPrintEnvironmentRestricted: true }),
    [types.LOGIN_STEP_1_REQUEST]: (state) => ({ ...state, fetching: true, notification: null, passwordExpired: false }),
    [types.LOGIN_STEP_1_SUCCESS]: (
        state,
        { exchangeToken, securitySeal, securitySealAlt, username, document, hasLogged, shouldRememberEmail },
    ) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        exchangeToken,
        securitySeal,
        securitySealAlt,
        username,
        document,
        hasLogged,
        shouldRememberEmail,
    }),

    // TODO: Delete these types if oauth is the only flavour
    [types.LOGIN_STEP_2_REQUEST]: (state) => ({ ...state, fetching: true, notification: null, passwordExpired: false }),
    [types.LOGIN_STEP_2_SUCCESS]: (state, { environments, lang, _userFirstName, _userFullName }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        lang,
        _userFirstName,
        _userFullName,
        environments,
        loginSuccess: false,
    }),
    [types.LOGIN_STEP_2_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.CHANGE_PASSWORD_REQUEST]: (state) => ({ ...state, fetching: true }),
    [types.CHANGE_PASSWORD_FAILURE]: (state) => ({ ...state, fetching: false }),
    [types.CHANGE_PASSWORD_SUCCESS]: (state) => ({ ...state, fetching: false, passwordExpired: false }),
    [types.PASSWORD_EXPIRED]: (state) => ({ ...state, fetching: false, passwordExpired: true }),

    [types.MARK_ENVIRONMENTS_DISABLED]: (state, { environments }) => ({
        ...state,
        fetching: false,
        environments,
    }),

    [types.LOGIN_OAUTH_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_OAUTH_SUCCESS]: (state, { environments, lang, _userFirstName, _userFullName }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        lang,
        _userFirstName,
        _userFullName,
        environments,
    }),
    [types.LOGIN_OAUTH_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.LOGIN_STEP_3_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_STEP_3_SUCCESS]: (state, { termsAndConditions }) => ({
        ...state,
        fetching: false,
        termsAndConditions,
    }),
    [types.LOGIN_STEP_3_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOGIN_STEP_4_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_SUCCESS]: ({ rememberedUser, region, clientCountry }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        region,
        clientCountry,
        fetching: false,
        loginSuccess: true,
    }),

    [types.REMOVE_REMEMBERED_USER]: (state) => ({ ...state, rememberedUser: INITIAL_STATE.rememberedUser }),
    [types.SET_REMEMBERED_USER]: (state, { rememberedUser }) => ({ ...state, rememberedUser }),

    [types.LOGIN_FAILURE]: (state) => ({ ...state, fetching: false, fingerprintSubmiting: false }),

    [types.LOGIN_FAILURE_REQUIRE_CAPTCHA]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
    }),
    [types.LOGIN_FINGERPRINT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
        fingerprintLoginFail: true,
    }),
    [types.FINALIZE_LOGIN]: (state, { response }) => ({
        ...state,
        idEnvironmentToAcceptConditions: response.data.data.idEnvironmentToAcceptConditions,
    }),
    [types.FINGERPRINT_LOGIN_SUBMIT]: (state) => ({ ...state, fingerprintSubmiting: true }),
    [types.FINGERPRINT_LOGIN_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        fetching: false,
        exchangeToken,
    }),

    [fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        exchangeToken,
    }),
    [types.DISMISS_CAMPAIGN_REQUEST]: (state) => ({
        ...state,
        displayCampaigns: false,
    }),
    [types.GO_BACK_LOGIN]: (state) => ({
        ...INITIAL_STATE,
        prevUsername: state.username,
        prevDocument: state.document,
        prevSecuritySeal: state.securitySeal,
        prevSecuritySealAlt: state.securitySealAlt,
    }),
    [types.SET_ALERT_EXPIRED_PASSWORD]: (state) => ({
        ...state,
        fetching: false,
        showAlertExpiredPassword: true,
    }),
    [types.HIDE_ALERT_EXPIRED_PASSWORD]: (state) => ({
        ...state,
        showAlertExpiredPassword: false,
    }),
});

export default persistReducer(
    {
        storage,
        key: "login",
        whitelist: ["rememberedUser", "region", "clientCountry"],
    },
    reducer,
);

export const actions = {
    dismissCampaigns: () => ({
        type: types.DISMISS_CAMPAIGN_REQUEST,
    }),
    getClientContry: () => ({
        type: types.GET_CLIENT_COUNTRY_REQ,
    }),
    setRegion: (region) => ({
        type: types.SET_REGION,
        region,
    }),
    reset: makeActionCreator(types.RESET),
    initLoginFlow: makeActionCreator(types.INIT_LOGIN_FLOW),

    loginStep1: makeActionCreator(
        types.LOGIN_STEP_1_REQUEST,
        "user",
        "documentType",
        "documentNumber",
        "shouldRememberEmail",
        "recaptchaResponse",
        "formikBag",
    ),
    loginStep2: makeActionCreator(types.LOGIN_STEP_2_REQUEST, "password", "recaptchaResponse", "formikBag"),
    passwordHasExpired: makeActionCreator(types.PASSWORD_EXPIRED),
    changePassword: makeActionCreator(
        types.CHANGE_PASSWORD_REQUEST,
        "exchangeToken",
        "password",
        "captcha",
        "newPassword",
        "newPasswordConfirmation",
        "formikBag",
    ),
    cancelChangePassword: makeActionCreator(types.CANCEL_PASSWORD_CHANGE),
    oauth: makeActionCreator(types.LOGIN_OAUTH_REQUEST, "password", "recaptchaResponse", "formikBag"),
    loginStep3: makeActionCreator(types.LOGIN_STEP_3_REQUEST, "idEnvironment", "rememberEnvironment", "formikBag"),
    loginStep4: makeActionCreator(types.LOGIN_STEP_4_REQUEST, "acceptConditions", "formikBag"),

    fingerprintLoginPre: makeActionCreator(types.FINGERPRINT_LOGIN_PRE),

    setRememberedUser: makeActionCreator(types.SET_REMEMBERED_USER, "rememberedUser"),
    removeRememberedUser: makeActionCreator(types.REMOVE_REMEMBERED_USER),
    goBackToLoginAndShowMessage: makeActionCreator(types.GO_BACK_LOGIN_SHOW_MESSAGE, "message"),
    goBackToLogin: makeActionCreator(types.GO_BACK_LOGIN),
    setAlertExpiredPassword: makeActionCreator(types.SET_ALERT_EXPIRED_PASSWORD),
    hideAlertExpiredPassword: makeActionCreator(types.HIDE_ALERT_EXPIRED_PASSWORD),
};

export const selectors = {
    getUsername: ({ login }) => login.username,
    getFingerPrintEnvironmentRestricted: ({ login }) => login.fingerPrintEnvironmentRestricted,
    getRememberedUser: ({ login }) => login.rememberedUser,
    getShouldRememberEmail: ({ login }) => login.shouldRememberEmail,
    getUserFirstName: ({ login }) => login.userFirstName,
    getUserFullName: ({ login }) => login.userFullName,
    getSecuritySeal: ({ login }) => login.securitySeal,
    getSecuritySealAlt: ({ login }) => login.securitySealAlt,
    getShowCaptcha: ({ login }) => login.showCaptcha,
    getFetching: ({ login }) => login.fetching,
    getNotification: ({ login }) => login.notification,
    getTermsAndConditions: ({ login }) => login.termsAndConditions,
    getExchangeToken: ({ login }) => login.exchangeToken,
    getAccessToken: ({ login }) => login.accessToken,
    getFingerprintSubmiting: ({ login }) => login.fingerprintSubmiting,
    getFingerprintLoginFail: ({ login }) => login.fingerprintLoginFail,
    getEnvironments: ({ login }) => login.environments,
    getIsInFlow: ({ login }) => !!login.exchangeToken,
    getFromOnboardingLoginData: ({ login }) => login.fromOnboardingLoginData,
    getIdEnvironmentToAcceptConditions: ({ login }) => login.idEnvironmentToAcceptConditions,
    getRegion: ({ login }) => login.region,
    getHasLogged: ({ login }) => login.hasLogged,
    getDisplayCampaigns: ({ login }) => login.displayCampaigns,
    getPrevUsername: ({ login }) => login.prevUsername,
    getPrevDocument: ({ login }) => login.prevDocument,
    getPrevSecuritySeal: ({ login }) => login.getPrevSecuritySeal,
    getPrevSecuritySealAlt: ({ login }) => login.getPrevSecuritySealAlt,
    isPasswordExpired: ({ login }) => login.passwordExpired,
    isLoginSuccess: ({ login }) => login.loginSuccess,
    isShowAlertExpiredPassword: ({ login }) => login.showAlertExpiredPassword,
};
