import React, { Component } from "react";
import { connect } from "react-redux";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";
import { bool, func } from "prop-types";

import Notification from "pages/_components/Notification";
import Step2Content from "pages/recoveryPassword/_components/Step2Content";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import HeaderLogin from "../_components/header/HeaderLogin";

class RecoveryPassStep2 extends Component {
    componentWillUnmount() {
        this.props.dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        return (
            <>
                <Notification scopeToShow="recoveryPassword" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <Step2Content {...this.props} />
                    </MainContainer>
                </div>
            </>
        );
    }
}

RecoveryPassStep2.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

export default connect(null)(withExchangeToken(RecoveryPassStep2));
