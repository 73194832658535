import React from "react";
import { connect } from "react-redux";
import { selectors, actions } from "reducers/session";
import { bool, func, string } from "prop-types";
import { push } from "react-router-redux";
import I18n from "pages/_components/I18n";

import SimpleModal from "components/SimpleModal/SimpleModal";

const PasswordCloseToExpireModal = ({ daysToExpirePassword, isOpen, dispatch }) => {
    const closeAlert = () => {
        dispatch(actions.hideAlertPasswordCloseToExpire());
    };

    const changePassword = () => {
        dispatch(push("/settings/changePassword"));
    };

    const titleParams = {
        DAYS: daysToExpirePassword,
    };

    return (
        <SimpleModal
            isDisplayed={isOpen}
            closeModal={closeAlert}
            modalIcon="images/warningIcon.svg"
            modalBigTitle="password.close.to.expire.modalTitle"
            modalBigTitleParams={titleParams}
            buttonAction={changePassword}
            buttonActionLabel="password.close.to.expire.confirm.button.label"
            buttonCancelLabel="password.close.to.expire.cancel.button.label">
            <I18n
                component="p"
                componentProps={{ className: "ui-text-center" }}
                id="password.close.to.expire.modalMessage"
            />
        </SimpleModal>
    );
};

PasswordCloseToExpireModal.propTypes = {
    daysToExpirePassword: string,
    isOpen: bool.isRequired,
    dispatch: func.isRequired,
};

PasswordCloseToExpireModal.defaultProps = {
    daysToExpirePassword: "",
};

const mapStateToProps = (state) => ({
    isOpen: selectors.isShowAlertPasswordCloseToExpire(state),
    daysToExpirePassword: selectors.getDaysToExpirePassword(state),
});

export default connect(mapStateToProps)(PasswordCloseToExpireModal);
