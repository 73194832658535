import mixpanel from "mixpanel-browser";
import * as config from "util/config";
import { store } from "store";
import { selectors as sessionSelectors } from "reducers/session";

/* global process */
const envCheck = process.env.NODE_ENV === "production";
const formSuscribe = ["requestLoan"];

export const isProviderEnabled = (provider) => {
    const providers = config.getArray("clickstreaming.providers");
    return envCheck && providers.includes(provider);
};

export const Mixpanel = {
    init: (token) => {
        if (token !== null && token !== "") {
            mixpanel.init(token);
        }
    },

    isEnable: () => isProviderEnabled("mixpanel") && Object.prototype.hasOwnProperty.call(mixpanel, "config"),

    track_user: (name, props) => {
        Mixpanel.identifyPeople();
        Mixpanel.track(name, props);
    },

    identifyPeople: () => {
        const user = sessionSelectors.getUser(store.getState());
        if (user !== null) {
            Mixpanel.identify(user.userId);
            Mixpanel.people.set({
                id: user.userId,
                name: user.userFullName,
                email: user.email,
            });
        }
    },

    track: (name, props) => {
        if (Mixpanel.isEnable()) {
            mixpanel.track(name, props);
        }
    },

    identify: (id) => {
        if (Mixpanel.isEnable()) {
            mixpanel.identify(id);
        }
    },

    track_form: (name, props) => {
        if (formSuscribe.includes(name)) {
            Mixpanel.track_user(name, props);
        }
    },

    people: {
        set: (props) => {
            if (Mixpanel.isEnable()) {
                mixpanel.people.set(props);
            }
        },
    },
};

export const initProvidersClickstreaming = () => {
    const providers = config.getArray("clickstreaming.providers");
    if (envCheck) {
        providers.forEach((provider) => {
            switch (provider) {
                case "mixpanel":
                    Mixpanel.init(config.get("clickstreaming.mixpanel.apikey"));
                    break;
                default:
                    break;
            }
        });
    }
};
