// eslint-disable-next-line no-unused-vars
import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";

import * as settings from "middleware/settings";

import { types as fingerprintTypes } from "reducers/fingerprint";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { selectors as sessionSelectors } from "reducers/session";

import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import * as i18nUtils from "util/i18n";
import * as secureStorageUtils from "util/secureStorage";

import { store } from "../store";

import { capitalizeFirstLetter } from "../util/string";

const sagas = [
    takeLatest(fingerprintTypes.FINGERPRINT_AVAILABILITY, isAvailable),
    takeLatest(fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE, fingerprintConfigurationPre),
    takeLatest(fingerprintTypes.FINGERPRINT_VERIFICATION_REQUEST, verify),
    takeLatest(fingerprintTypes.FINGERPRINT_DELETE_DEVICE, deleteDevice),
    takeLatest(fingerprintTypes.ENROLL_FINGERPRINT_ON_DEVICE, enrollFingerprintOnDevice),
    takeLatest(fingerprintTypes.CLEAN_UP, handleFingerprintCleanUp),
];

export default sagas;

function* isAvailable() {
    if (window.cordova) {
        try {
            const availability = yield call(fingerprintUtils.isAvailable);

            yield put({ type: fingerprintTypes.FINGERPRINT_AVAILABILITY_SUCCESS, availability });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("ERROR!!! - ", error);
        }
    }
}

function* fingerprintConfigurationPre() {
    const response = yield call(settings.listFingerprintConfiguredUserDevices);
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = false;

    if (response && response.status === 200) {
        try {
            fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
            if (fingerprintAuthToken.length > 0) {
                fingerprintAuthTokenExists = true;
            }
        } catch (error) {
            fingerprintAuthTokenExists = false;
        }
        yield put({
            type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS,
            isDeviceWithFingerprint: fingerprintAuthTokenExists,
            fingerprintConfiguredUserDevices: response.data.data.devices,
            // eslint-disable-next-line no-underscore-dangle
            exchangeToken: response.data.data._exchangeToken,
        });
    }
}

function* verify() {
    try {
        const result = yield call(fingerprintUtils.verify);

        if (result && result.withFingerprint) {
            const response = yield call(
                settings.saveFingerprint,
                deviceUtils.getDeviceId(),
                deviceUtils.getDeviceModel(),
                deviceUtils.getDeviceManufacturer(),
            );

            if (response && response.status === 200) {
                yield call(
                    secureStorageUtils.set,
                    "fingerprintAuthToken",
                    sessionSelectors.getAccessToken(store.getState()),
                );
                yield call(
                    secureStorageUtils.set,
                    "fingerprintUsername",
                    sessionSelectors.getUsernameFingerPrint(store.getState()),
                );
                const deviceName = `${capitalizeFirstLetter(
                    deviceUtils.getDeviceManufacturer(),
                )} - ${deviceUtils.getDeviceModel()} - ${deviceUtils.getDeviceId().slice(-4)}`;
                yield put(
                    notificationModalActions.showNotification(
                        "settings.fingerprintConfiguration.register.success",
                        "success",
                        deviceName,
                    ),
                );
                yield put({ type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE });
            } else {
                const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.register.error");
                yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            }
        }
    } catch (error) {
        if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR
        ) {
            // do nothing
        } else if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) !== fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED
        ) {
            yield put(
                notificationActions.showNotification(fingerprintUtils.getError(error), "error", [
                    "fingerprintConfiguration",
                ]),
            );
        } else if (deviceUtils.getDevicePlatform() === "iOS" && error.code !== -128) {
            yield put(
                notificationActions.showNotification(error.localizedDescription, "error", ["fingerprintConfiguration"]),
            );
        }
    }
}

function* deleteDevice({ idSession, deviceShowName }) {
    const response = yield call(settings.deleteFingerprint, idSession);

    if (response && response.status === 200) {
        yield put({ type: fingerprintTypes.CLEAN_UP });
        yield put({ type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE });
        yield put(
            notificationModalActions.showNotification(
                "settings.fingerprintConfiguration.device.remove.success",
                "success",
                deviceShowName,
            ),
        );
    } else {
        yield put(
            notificationModalActions.showNotification("settings.fingerprintConfiguration.device.remove.error", "error"),
        );
    }
}

function* enrollFingerprintOnDevice() {
    const response = yield call(fingerprintUtils.enrollOnDevice);

    if (!response) {
        const errorMessage = i18nUtils.get(
            "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll.error",
        );
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    } else {
        yield put(push("/settings/fingerprintConfiguration"));
    }
}

function* handleFingerprintCleanUp() {
    if (window.cordova) {
        try {
            yield call(secureStorageUtils.remove, "fingerprintAuthToken");
            yield call(secureStorageUtils.remove, "fingerprintUsername");
        } catch (error) {
            /* do something with the error */
        }
    }
}
