import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import { types as loginTypes } from "reducers/login";
import { types as settingsTypes } from "reducers/settings";
import globalTypes from "reducers/types/global";
import * as configUtils from "util/config";
import { makeActionCreator } from "util/redux";

export const types = {
    CHANGE_ENVIRONMENT_REQUEST: "session/CHANGE_ENVIRONMENT_REQUEST",
    CHANGE_ENVIRONMENT_FAILURE: "session/CHANGE_ENVIRONMENT_FAILURE",
    CHANGE_ENVIRONMENT_SUCCESS: "session/CHANGE_ENVIRONMENT_SUCCESS",

    LOGOUT_REQUEST: "session/LOGOUT_REQUEST",
    LOGOUT_MODAL: "session/LOGOUT_MODAL",

    SET_TOKENS: "session/SET_TOKENS",

    SET_ENABLED_ASSISTANT: "session/SET_ENABLED_ASSISTANT",

    EXTEND: "session/EXTEND",
    EXTEND_SUCCESS: "session/EXTEND_SUCCESS",
    EXPIRE: "session/EXPIRE",

    LOCATION_REQUESTED: "session/LOCATION_REQUESTED",
    UPDATE_PENDINGACTIONS_PEP: "session/PENDING_ACTIONS_PEP",
    UPDATE_PENDINGACTIONS_IRS: "session/PENDING_ACTIONS_IRS",

    UPDATE_PERMISSIONS: "session/UPDATE_PERMISSIONS",

    CHANGE_ENVIRONMENT_TEMP_DATA: "session/CHANGE_ENVIRONMENT_TEMP_DATA",
    CHANGE_ENVIRONMENT_SIGN_TYC: "session/CHANGE_ENVIRONMENT_SIGN_TYC",

    SET_ALERT_ENVIRONMENT_EXPIRE: "SET_ALERT_ENVIRONMENT_EXPIRE",
    SHOW_ALERT_ENVIRONMENT_EXPIRE: "SHOW_ALERT_ENVIRONMENT_EXPIRE",
    HIDE_ALERT_ENVIRONMENT_EXPIRE: "HIDE_ALERT_ENVIRONMENT_EXPIRE",
    SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE: "SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE",
    HIDE_ALERT_PASSWORD_CLOSE_TO_EXPIRE: "HIDE_ALERT_PASSWORD_CLOSE_TO_EXPIRE",

    UPDATE_ENVIRONMENTS: "session/UPDATE_ENVIRONMENTS",
    UPDATE_EMAIL_PHONE: "session/UPDATE_EMAIL_PHONE",
};

export const INITIAL_STATE = {
    activeEnvironment: null,
    environments: {},
    user: null,
    fetching: false,
    isLocationRequested: false,
    enabledAssistant: false,
    tempData: {},
    showModalLogout: false,
    showAlertExpire: false,
    daysToExpire: null,
    daysToExpirePassword: null,
    showAlertPasswordCloseToExpire: false,
    environmentNameExpire: null,
    isDefaultExpired: false,
    locationFound: null,
    geoData: null,
    availableFeatures: {},
    echeqSubStates: null,
    featuresForWidgets: {},
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOCATION_REQUESTED:
            return {
                ...state,
                isLocationRequested: true,
                locationFound: action.locationFound,
            };
        case globalTypes.CLEAN_UP:
        case loginTypes.INIT_LOGIN_FLOW:
        case types.LOGOUT_REQUEST:
            return INITIAL_STATE;

        case loginTypes.LOGIN_SUCCESS:
            return {
                ...state,
                activeEnvironment: action.environment,
                environments: action.environments,
                user: action.user,
                isAdministrator: action.isAdministrator,
                transactionTypes: action.environment.transactionTypes,
                availableFeatures: action.environment.availableFeatures,
                echeqSubStates: action.echeqSubStates,
                featuresForWidgets: action.featuresForWidgets,
            };
        case types.LOGOUT_MODAL:
            return {
                ...state,
                showModalLogout: action.isOpen,
            };
        case types.SET_ENABLED_ASSISTANT:
            return {
                ...state,
                enabledAssistant: action.enabledAssistant,
            };
        case types.UPDATE_PENDINGACTIONS_PEP:
            return {
                ...state,
                user: {
                    ...state.user,
                    pepCompleted: true,
                },
            };
        case types.UPDATE_PENDINGACTIONS_IRS:
            return {
                ...state,
                user: {
                    ...state.user,
                    irsCompleted: true,
                },
            };
        case loginTypes.MARK_ENVIRONMENTS_DISABLED:
            return {
                ...state,
                environments: action.environments,
            };
        case loginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return { ...state, activeEnvironment: action.environment };
        case types.CHANGE_ENVIRONMENT_REQUEST:
            return { ...state, fetching: true };
        case types.CHANGE_ENVIRONMENT_FAILURE:
            return { ...state, fetching: false };
        case types.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                activeEnvironment: action.environment,
                environments: action.environments,
                isAdministrator: action.isAdministrator,
                tempData: {},
            };
        case types.SET_TOKENS:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken };
        case settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securitySeal: action.securitySeal,
                },
            };
        case settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    idDefaultEnvironment: action.idDefaultEnvironment,
                },
            };
        case types.UPDATE_PERMISSIONS:
            return {
                ...state,
                activeEnvironment: {
                    ...state.activeEnvironment,
                    permissions: action.permissions,
                },
            };
        case types.CHANGE_ENVIRONMENT_TEMP_DATA:
            return {
                ...state,
                tempData: action.tempData,
                fetching: false,
            };

        case types.SET_ALERT_ENVIRONMENT_EXPIRE:
            return {
                ...state,
                daysToExpire: action.daysToExpire || null,
                environmentNameExpire: action.environmentName,
                showAlertExpire: true,
                isDefaultExpired: action.isDefaultExpired,
            };
        case types.HIDE_ALERT_ENVIRONMENT_EXPIRE:
            return {
                ...state,
                daysToExpire: INITIAL_STATE.daysToExpire,
                showAlertExpire: false,
            };

        case types.SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE:
            return {
                ...state,
                daysToExpirePassword: action.daysToExpirePassword || null,
                showAlertPasswordCloseToExpire: true,
            };
        case types.HIDE_ALERT_PASSWORD_CLOSE_TO_EXPIRE:
            return {
                ...state,
                daysToExpirePassword: INITIAL_STATE.daysToExpirePassword,
                showAlertPasswordCloseToExpire: false,
            };
        case types.UPDATE_ENVIRONMENTS:
            return {
                ...state,
                fetching: false,
                environments: action.environments,
            };

        case types.UPDATE_EMAIL_PHONE:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.email,
                    mobileNumber: action.mobileNumber,
                },
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "session",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    logout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    changeEnvironment: (idEnvironment, rememberEnvironment, acceptConditions, formikBag) => ({
        type: types.CHANGE_ENVIRONMENT_REQUEST,
        idEnvironment,
        acceptConditions: acceptConditions || false,
        rememberEnvironment,
        formikBag,
    }),
    extend: () => ({
        type: types.EXTEND,
    }),
    expire: (lastHref) => ({
        type: types.EXPIRE,
        lastHref,
    }),
    setTokens: makeActionCreator(types.SET_TOKENS, "accessToken", "refreshToken"),
    changeEnvironmentSignTyc: (acceptConditions, formikBag) => ({
        type: types.CHANGE_ENVIRONMENT_SIGN_TYC,
        acceptConditions,
        formikBag,
    }),
    setModalLogout: (isOpen) => ({
        type: types.LOGOUT_MODAL,
        isOpen,
    }),
    setAlertEnvironmentExpire: (daysToExpire, environmentName, isDefaultExpired) => ({
        type: types.SET_ALERT_ENVIRONMENT_EXPIRE,
        daysToExpire,
        environmentName,
        isDefaultExpired,
    }),
    hideAlertEnvironmentExpire: () => ({
        type: types.HIDE_ALERT_ENVIRONMENT_EXPIRE,
    }),
    setAlertPasswordCloseToExpire: (daysToExpirePassword) => ({
        type: types.SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE,
        daysToExpirePassword,
    }),
    hideAlertPasswordCloseToExpire: () => ({
        type: types.HIDE_ALERT_PASSWORD_CLOSE_TO_EXPIRE,
    }),
    updateEnvironments: (environments) => ({
        type: types.UPDATE_ENVIRONMENTS,
        environments,
    }),
    updateEmailPhone: (email, mobileNumber) => ({
        type: types.UPDATE_EMAIL_PHONE,
        email,
        mobileNumber,
    }),
};

export const selectors = {
    getAccessToken: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user ? session.user.accessToken : null;
        }
        return session.accessToken;
    },
    isLoggedIn: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user && !!session.user.accessToken;
        }
        return !!session.accessToken;
    },
    getUser: ({ session }) => session.user,
    // TODO MARIVA: revisar getUsername
    getUsername: ({ session }) => (session.user ? session.user.email : null),
    getUserFullName: ({ session }) => (session.user ? session.user.userFullName : ""),
    getUsernameFingerPrint: ({ session }) => (session.user ? session.user.username : null),
    getEnvironments: ({ session }) => session.environments,
    getEnabledAssistant: ({ session }) => session.enabledAssistant,
    getActiveEnvironment: ({ session }) => session.activeEnvironment,
    getActiveEnvironmentForms: ({ session }, idForm) => session.activeEnvironment.forms[idForm] || [],
    hasForm: ({ session }, category, idForm) =>
        session.activeEnvironment.forms[category] &&
        !!session.activeEnvironment.forms[category].find((f) => f.idForm === idForm),
    getUserSecuritySeal: ({ session }) => (session.user ? session.user.securitySeal : null),
    isFetching: ({ session }) => session.fetching,
    isAdministrator: ({ session }) => session.isAdministrator,
    getAdministrationScheme: ({ session }) => session.activeEnvironment.administrationScheme,
    hasPermissions: ({ session }, permissions) =>
        !permissions ||
        !permissions.find(
            (permission) => Object.keys(session.activeEnvironment.permissions).indexOf(permission) === -1,
        ),
    getTempData: ({ session }) => session.tempData,
    getModalLogout: ({ session }) => session.showModalLogout,
    isShowAlertExpire: ({ session }) => session.showAlertExpire,
    getDaysToExpire: ({ session }) => session.daysToExpire,
    getEnvironamentNameExpire: ({ session }) => session.environmentNameExpire,
    getIsDefaultExpired: ({ session }) => session.isDefaultExpired,
    getCurrentLocation: ({ session }) => session.locationFound,
    getTransactionTypes: ({ session }) => session.transactionTypes,
    getAvailableFeatures: ({ session }) => session.availableFeatures,
    isShowAlertPasswordCloseToExpire: ({ session }) => session.showAlertPasswordCloseToExpire,
    getDaysToExpirePassword: ({ session }) => session.daysToExpirePassword,
    getEcheqSubStates: ({ session }) => session.echeqSubStates,
    getFeaturesForWidgets: ({ session }) => session.featuresForWidgets,
};
