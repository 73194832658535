import React from "react";
import I18n from "pages/_components/I18n";
import { node, string, bool } from "prop-types";

import "./_disclaimerContainer.scss";

const DisclaimerContainer = ({
    children,
    className,
    component,
    disclaimer,
    maxHeight,
    tableFooter,
    textSmall,
    title,
    justifyText,
}) => (
    <div
        className={`disclaimer-container ${textSmall ? "disclaimer-small" : ""} ${
            justifyText ? "justify-text" : ""
        } ${className}`}>
        {tableFooter && (
            <div className="disclaimer__table-footer">
                <I18n id={tableFooter} component={component} />
            </div>
        )}
        <div className={maxHeight && "scrollable-content scroll-disclaimer"} style={{ maxHeight }}>
            {disclaimer && (
                <>
                    {title && <I18n id={title} component={component} />}
                    <I18n id={disclaimer} component={component} />
                </>
            )}

            {children}
        </div>
    </div>
);

DisclaimerContainer.propTypes = {
    children: node,
    component: string,
    disclaimer: string,
    maxHeight: string,
    tableFooter: string,
    title: string,
    textSmall: bool,
    className: string,
    justifyText: bool,
};

DisclaimerContainer.defaultProps = {
    children: null,
    component: "p",
    disclaimer: null,
    maxHeight: null,
    tableFooter: null,
    title: null,
    textSmall: false,
    className: "",
    justifyText: true,
};

export default DisclaimerContainer;
