import { removeElement } from "util/array";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    DETAIL_REQUEST: "communication/DETAIL_REQUEST",
    DETAIL_FAILURE: "communication/DETAIL_FAILURE",
    DETAIL_SUCCESS: "communication/DETAIL_SUCCESS",
    DELETE_REQUEST: "communication/DELETE_REQUEST",
    DELETE_FAILURE: "communication/DELETE_FAILURE",
    DELETE_SUCCESS: "communication/DELETE_SUCCESS",
    SEND_REQUEST: "communication/SEND_REQUEST",
    SEND_FAILURE: "communication/SEND_FAILURE",
    SEND_SUCCESS: "communication/SEND_SUCCESS",
    DOWNLOAD_ATTACHMENT_REQUEST: "communication/DOWNLOAD_ATTACHMENT",
    DOWNLOAD_ATTACHMENT_FAILURE: "communication/DOWNLOAD_ATTACHMENT_FAILURE",
    DOWNLOAD_ATTACHMENT_SUCCESS: "communication/DOWNLOAD_ATTACHMENT_SUCCESS",
    REPLY_REQUEST: "communication/REPLY_REQUEST",
    REPLY_FAILURE: "communication/REPLY_FAILURE",
    REPLY_SUCCESS: "communication/REPLY_SUCCESS",
    MARK_AS_READ_REQUEST: "communication/MARK_AS_READ_REQUEST",
    MARK_AS_READ_FAILURE: "communication/MARK_AS_READ_FAILURE",
    MARK_AS_READ_SUCCESS: "communication/MARK_AS_READ_SUCCESS",
    MARK_AS_UNREAD_REQUEST: "communication/MARK_AS_UNREAD_REQUEST",
    MARK_AS_UNREAD_FAILURE: "communication/MARK_AS_UNREAD_FAILURE",
    MARK_AS_UNREAD_SUCCESS: "communication/MARK_AS_UNREAD_SUCCESS",
    SELECT_ATTACHMENT: "communication/SELECT_ATTACHMENT",
    SET_ATTACHMENT: "communication/SET_ATTACHMENT",
    REMOVE_ATTACHMENT: "communication/REMOVE_ATTACHMENT",
    START_PRELOADED_MESSAGE: "communication/START_PRELOADED_MESSAGE",
    PRELOADED_MESSAGE: "communication/PRELOADED_MESSAGE",
    RESET_PRELOADED_MESSAGE: "communication/RESET_PRELOADED_MESSAGE",
    SET_SELECTED_INDEX: "communication/SET_SELECTED_INDEX",
    SET_SELECTED_ID_COMMUNICATION: "communication/SET_SELECTED_ID_COMMUNICATION",
};

export const INITIAL_STATE = {
    detail: null,
    attachments: [],
    selectedIndex: null,
    selectedIdCommunication: null,
    isFetching: false,
    selectedAttachments: [],
    preloadedMessage: null,
};

export default createReducer(INITIAL_STATE, {
    [types.DETAIL_REQUEST]: (state) => ({
        ...state,
        selectedIndex: state.selectedIndex,
        selectedIdCommunication: state.selectedIdCommunication,
        selectedAttachments: [],
        isFetching: true,
    }),
    [types.DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        detail: action.detail,
        attachments: action.attachments,
        isFetching: false,
    }),
    [types.DELETE_REQUEST]: (state) => ({ ...state, isFetching: true }),
    [types.DELETE_FAILURE]: () => ({ isFetching: false }),
    [types.DELETE_SUCCESS]: (state) => ({
        ...state,
        detail: null,
        attachments: [],
        isFetching: false,
    }),
    [types.SEND_REQUEST]: (state) => ({ ...state, isFetching: true }),
    [types.SEND_FAILURE]: (state) => ({ ...state, isFetching: false }),
    [types.SEND_SUCCESS]: (state) => ({ ...state, isFetching: false, selectedAttachments: [] }),
    [types.DOWNLOAD_ATTACHMENT_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.DOWNLOAD_ATTACHMENT_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DOWNLOAD_ATTACHMENT_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.REPLY_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.REPLY_FAILURE]: (state) => ({ ...state, isFetching: false }),
    [types.REPLY_SUCCESS]: (state) => ({ ...state, selectedAttachments: [], isFetching: false }),
    [types.MARK_AS_READ_SUCCESS]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.MARK_AS_READ_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.MARK_AS_READ_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.MARK_AS_UNREAD_SUCCESS]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.MARK_AS_UNREAD_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.MARK_AS_UNREAD_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SELECT_ATTACHMENT]: (state) => ({ ...state }),
    [types.SET_ATTACHMENT]: (state, action) => ({
        ...state,
        selectedAttachments: [...state.selectedAttachments, action.file],
    }),
    [types.REMOVE_ATTACHMENT]: (state, action) => ({
        ...state,
        selectedAttachments: removeElement(state.selectedAttachments, action.index),
    }),
    [types.PRELOADED_MESSAGE]: (state, action) => ({
        ...state,
        preloadedMessage: action.preloadedMessage,
    }),
    [types.RESET_PRELOADED_MESSAGE]: (state) => ({
        ...state,
        preloadedMessage: null,
    }),
    [types.SET_SELECTED_INDEX]: (state, action) => ({
        ...state,
        selectedIndex: action.index,
    }),
    [types.SET_SELECTED_ID_COMMUNICATION]: (state, action) => ({
        ...state,
        selectedIdCommunication: action.idCommunication,
    }),
});

export const actions = {
    detailRequest: makeActionCreator(types.DETAIL_REQUEST, "idCommunication", "index"),
    detailFailure: makeActionCreator(types.DETAIL_FAILURE),
    detailSuccess: makeActionCreator(types.DETAIL_SUCCESS, "detail", "attachments", "index"),
    deleteRequest: makeActionCreator(types.DELETE_REQUEST, "idCommunication", "isDesktop"),
    deleteFailure: makeActionCreator(types.DELETE_FAILURE),
    deleteSuccess: makeActionCreator(types.DELETE_SUCCESS),
    downloadAttachmentRequest: makeActionCreator(types.DOWNLOAD_ATTACHMENT_REQUEST, "idFile"),
    downloadAttachmentFailure: makeActionCreator(types.DOWNLOAD_ATTACHMENT_FAILURE),
    downloadAttachmentSuccess: makeActionCreator(types.DOWNLOAD_ATTACHMENT_SUCCESS),
    sendRequest: makeActionCreator(
        types.SEND_REQUEST,
        "idCommunicationTray",
        "subject",
        "body",
        "files",
        "isDesktop",
        "isSuspiciousActivity",
    ),
    sendFailure: makeActionCreator(types.SEND_FAILURE),
    sendSuccess: makeActionCreator(types.SEND_SUCCESS),
    replyRequest: makeActionCreator(
        types.REPLY_REQUEST,
        "idCommunicationTray",
        "subject",
        "body",
        "idCommunication",
        "communicationPriority",
        "files",
        "isDesktop",
    ),
    replyFailure: makeActionCreator(types.REPLY_FAILURE),
    replySuccess: makeActionCreator(types.REPLY_SUCCESS),
    markAsReadRequest: makeActionCreator(types.MARK_AS_READ_REQUEST, "idCommunication"),
    markAsReadFailure: makeActionCreator(types.MARK_AS_READ_FAILURE),
    markAsReadSuccess: makeActionCreator(types.MARK_AS_READ_SUCCESS),
    markAsUnReadRequest: makeActionCreator(types.MARK_AS_UNREAD_REQUEST, "idCommunication"),
    markAsUnReadFailure: makeActionCreator(types.MARK_AS_UNREAD_FAILURE),
    markAsUnReadSuccess: makeActionCreator(types.MARK_AS_UNREAD_SUCCESS),
    selectAttachment: makeActionCreator(types.SELECT_ATTACHMENT, "file"),
    setAttachment: makeActionCreator(types.SET_ATTACHMENT, "file"),
    removeSelectedAttachment: makeActionCreator(types.REMOVE_ATTACHMENT, "index"),
    startPreloadedMessage: makeActionCreator(types.START_PRELOADED_MESSAGE, "isSuspicious", "isMobile", "origin"),
    preloadedMessage: makeActionCreator(types.PRELOADED_MESSAGE, "preloadedMessage"),
    resetPreloadedMessage: makeActionCreator(types.RESET_PRELOADED_MESSAGE, "preloadedMessage"),
    setSelectedIndex: makeActionCreator(types.SET_SELECTED_INDEX, "index"),
    setSelectedIdCommunication: makeActionCreator(types.SET_SELECTED_ID_COMMUNICATION, "idCommunication"),
};

export const selectors = {
    detail: ({ communication }) => communication.detail,
    attachments: ({ communication }) => communication.attachments,
    getSelectedIndex: ({ communication }) => communication.selectedIndex,
    getSelectedIdCommunication: ({ communication }) => communication.selectedIdCommunication,
    isFetching: ({ communication }) => communication.isFetching,
    selectedAttachments: ({ communication }) => communication.selectedAttachments,
    getPreloadedMessage: ({ communication }) => communication.preloadedMessage,
};
