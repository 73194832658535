import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, shape, func, oneOfType, number } from "prop-types";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Checkbox from "pages/_components/Checkbox";

class BitwiseDaysInline extends Component {
    static propTypes = {
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        hideLabel: bool,
        onChange: func,
        labelKey: string,
    };

    static defaultProps = {
        hideLabel: false,
        onChange: () => {},
        labelKey: null,
    };

    dayClicked = (day) => {
        const { field, form, onChange } = this.props;
        let newValue;
        /* eslint-disable no-bitwise */
        if (day & field.value) {
            newValue = field.value - day;
        } else {
            newValue = field.value + day;
        }
        /* eslint-enable no-bitwise */
        form.setFieldValue(field.name, newValue);
        onChange(newValue);
    };

    renderField = (name, value) => (
        /* eslint-disable no-bitwise */
        <Checkbox
            checked={value & this.props.field.value}
            name={name}
            labelText={`scheduler.shortDay.${name}`}
            onChange={() => {
                this.dayClicked(value);
            }}
        />
        /* eslint-enable no-bitwise */
    );

    renderControl = () => {
        const weekDays = [
            ["monday", 1],
            ["tuesday", 2],
            ["wednesday", 4],
            ["thursday", 8],
            ["friday", 16],
        ];

        const weekDayOptions = [];
        weekDays.forEach((elem) => {
            weekDayOptions.push(
                <li className="c-control c-control--has-icon" key={elem}>
                    {this.renderField(elem[0], elem[1])}
                </li>,
            );
        });

        return weekDayOptions;
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
            labelKey,
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--image-selector", {
                    "has-error": hasError,
                })}>
                {!hideLabel && labelKey && <FieldLabel labelKey={labelKey} />}
                {!hideLabel && !labelKey && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                <ul className="form-check-group weekday-selection c-control-icon--rounded">{this.renderControl()}</ul>
                {hasError && <FieldError error={errors[field.name].value} />}
            </div>
        );
    }
}

export default BitwiseDaysInline;
