import ListItem from "pages/accounts/ListItem";
import Table from "pages/_components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SeuoLabel from "./SeuoLabel/SeuoLabel";

class AccountsList extends Component {
    static propTypes = {
        accounts: PropTypes.oneOfType([PropTypes.array]).isRequired,
        history: PropTypes.func,
        isMobile: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        history: null,
    };

    renderList = () => {
        const { accounts, isMobile } = this.props;

        if (!accounts.length) {
            return null;
        }

        return !isMobile ? (
            <>
                <Table className="gridTable">
                    <Table.Body>
                        {accounts.map((account) => (
                            <ListItem {...account} key={account.idProduct} isMobile={isMobile} />
                        ))}
                    </Table.Body>
                </Table>
                <SeuoLabel />
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">
                    {accounts.map((account) => (
                        <ListItem {...account} key={account.idProduct} isMobile={isMobile} />
                    ))}
                </div>
                <SeuoLabel />
            </>
        );
    };

    render() {
        return <>{this.renderList()}</>;
    }
}

export default AccountsList;
