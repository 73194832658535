import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import { string, func, bool, shape } from "prop-types";

import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

class PushNotificationsDeleteConfirmation extends Component {
    static propTypes = {
        device: shape({
            idDevice: string.isRequired,
            model: string.isRequired,
            alias: string.isRequired,
        }).isRequired,
        dispatch: func.isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    deleteDevice = (e) => {
        e.preventDefault();
        const { device, dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsDeleteUserDevice(device.idDevice));
    };

    getContent = () => {
        const {
            device: { idDevice, model },
        } = this.props;

        return (
            <form className="above-the-fold">
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <strong>
                                        <I18n id="settings.pushNotifications.deleteConfirmation.action" />
                                    </strong>
                                </p>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <span className="text-lead">
                                    <I18n id={`devices.apple.identifier.${model}`} defaultValue={model} />
                                    <span> - {idDevice.substring(idDevice.length - 4, idDevice.length)}</span>
                                </span>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.pushNotifications.deleteConfirmation.confirmWarning" />
                                </p>
                            </Col>
                            <Row>
                                <Col sm={12} className="col col-12">
                                    <Button
                                        label="settings.pushNotifications.deleteConfirmation.confirm"
                                        bsStyle="primary"
                                        onClick={this.deleteDevice}
                                    />
                                </Col>
                            </Row>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    };

    render() {
        const { fetching } = this.props;
        return (
            <React.Fragment>
                <Notification scopeToShow="pushNotifications" />
                <Head title="settings.pushNotifications" backLinkTo="/settings/pushNotifications" />
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
    device: pushNotificationsSelectors.getDevice(state),
});

export default connect(mapStateToProps)(PushNotificationsDeleteConfirmation);
