import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "reducers/types/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as stringUtils from "util/string";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.cancelEndorsement.send";
const ID_FORM = "forms.echeqs.cancelEndorsement";

const CancelEndorsement = (props) => {
    const { echeq, data, dispatch, transaction, mode, location, currentLang, fromBackoffice } = props;
    const { idTransactionStatus, idTransaction } = transaction;
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        cheque_tipo,
        beneficiario_nombre,
        cheque_caracter,
        endosos,
        total_endosos,
    } = echeq;
    const endorsements = total_endosos > 0 ? endosos[0] : [];

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.message.label`} />
                </div>
            )}

            <I18n id={`${ID_FORM}.dataEcheqTitle.label`} componentProps={{ className: "data-label" }} />

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.idEcheq.label`} />
                </span>
                <span className="data-text">{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} />
                </span>
                <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqType.label`} />
                </span>
                <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqCharacter.label`} />
                </span>
                <span className="data-text">{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.CUIT/CUILIssuer.label`} />
                </span>
                <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.issuerName.label`} />
                </span>
                <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                </span>
                <span className="data-text">{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.state.label`} />
                    </span>
                    <span className="data-text">{data?.estado || estado}</span>
                </div>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.amount.label`} />
                </span>
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.paymentDate.label`} />
                </span>
                <FormattedDate date={data?.fecha_pago || fecha_pago} />
            </div>

            <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-3" : "ui-mb-7"}`} />

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.dataEndorsementTitle.label`} />
                </span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.typeEndorsement.label`} />
                </span>
                <span className="data-text">{data?.tipo_endoso || endorsements?.tipo_endoso}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryEndorsement.label`} />
                </span>
                <span className="data-text">{data?.benef_razon_social || endorsements?.benef_razon_social}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.typeAndNumberDocument.label`} />
                </span>
                <span className="data-text">
                    {stringUtils.returnTypeAndNumberDocument(
                        data?.benef_documento_tipo || endorsements?.benef_documento_tipo,
                        data?.benef_documento || endorsements?.benef_documento,
                    ) || "-"}
                </span>
            </div>

            <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-3" : "ui-mb-7"}`} />

            {mode === "edit" ? (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.reason.label`} />
                        </span>
                    </div>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Textarea}
                        key="reason"
                        name="reason"
                        idField={mode === "edit" ? "reasonEdit" : "reason"}
                        isRequired
                        editing={mode === "edit"}
                        idActivity={ID_ACTIVITY}
                        value={data?.reason || ""}
                        maxLength={500}
                    />
                </>
            ) : (
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.reason.confirm.label`} />
                    </span>
                    <span className="data-text">{data?.reason}</span>
                </div>
            )}
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id,
            cheque_numero,
            estado,
            amount: {
                quantity: monto,
                currency: emisor_moneda,
            },
            fecha_pago,
            emisor_moneda,
            emisor_cuit,
            emisor_razon_social,
            cheque_tipo,
            beneficiario_nombre,
            cheque_caracter,
            tipo_endoso: data?.tipo_endoso || endorsements?.tipo_endoso,
            benef_razon_social: data?.benef_razon_social || endorsements?.benef_razon_social,
            benef_documento_tipo: data?.benef_documento_tipo || endorsements?.benef_documento_tipo,
            benef_documento: data?.benef_documento || endorsements?.benef_documento,
            endosante_documento_tipo: data?.endosante_documento_tipo || endorsements?.emisor_documento_tipo,
            endosante_documento: data?.endosante_documento || endorsements?.emisor_documento,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

CancelEndorsement.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};

CancelEndorsement.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(CancelEndorsement);
