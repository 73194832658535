import React, { Component } from "react";
import { bool, shape, string } from "prop-types";

import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";
import I18n from "pages/_components/I18n";

class Default extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
        userTransactionDetail: shape({}),
        withoutTransaction: bool,
    };

    static defaultProps = {
        userTransactionDetail: {},
        withoutTransaction: false,
    };

    render() {
        const { transaction, userTransactionDetail, withoutTransaction } = this.props;
        return (
            <div className="transaction-ticket__default-header">
                <figure>
                    <TransactionStatus
                        iconFidelity="ticket"
                        idTransactionStatus={
                            withoutTransaction && userTransactionDetail
                                ? userTransactionDetail.idTransactionStatus
                                : transaction.idTransactionStatus
                        }
                    />
                </figure>

                <I18n
                    id={`forms.transaction.ticket.status.${
                        withoutTransaction && userTransactionDetail
                            ? userTransactionDetail.idTransactionStatus
                            : transaction.idTransactionStatus
                    }`}
                />
            </div>
        );
    }
}

export { default as SalaryPayment } from "pages/forms/_components/ticketHeaders/SalaryPayment";
export { default as AccountOpening } from "pages/forms/_components/ticketHeaders/AccountOpening";
export { default as TransferForeign } from "pages/forms/_components/ticketHeaders/TransferForeign";
export { default as MultipleEcheqsTicketHeader } from "pages/forms/_components/ticketHeaders/MultipleEcheqsTicketHeader";

export default Default;
