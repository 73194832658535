import classNames from "classnames";
import Movements from "pages/accounts/Movements";
import AccountAction from "pages/accounts/_components/AccountAction";
import DetailHeadInfo from "pages/accounts/_components/DetailHeadInfo";
import DetailHeadTitle from "pages/accounts/_components/DetailHeadTitle";
import Information from "pages/accounts/_components/Information";
import MoreFilters from "pages/accounts/_components/MoreFilters";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import { array, arrayOf, func, number, oneOfType, shape, bool, string, any, objectOf } from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push, replace } from "react-router-redux";
import { types as accountTypes, actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as checksActions } from "reducers/checks";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtil from "util/config";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import { selectors as widgetSelector } from "reducers/widgets";
import defaultDateFromList from "util/defaultDateFromList";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import I18n from "pages/_components/I18n";

import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { validateToResetFilters } from "util/filters";
import handleBackInDetail from "../../util/navigation";

const ID_ACTIVITY = "accounts.details.list";

class Details extends Component {
    static propTypes = {
        account: shape({
            productType: string,
            idProduct: string,
            permissions: shape({}),
            currency: string,
        }),
        activeEnvironmentForms: oneOfType([
            shape({
                reduce: func,
            }),
            array,
        ]).isRequired,
        channels: arrayOf(any).isRequired,
        fetching: bool.isRequired,
        fetchingDownload: bool.isRequired,
        fetchingMovements: bool.isRequired,
        totalAccounts: number.isRequired,
        match: shape().isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        totalCount: number,
        activeRegion: string,
        isResetQuery: bool.isRequired,
        filtersSaved: shape().isRequired,
        isMobileNative: bool.isRequired,
        isMobile: bool.isRequired,
        history: objectOf(any).isRequired,
        dataFromWidget: shape({}).isRequired,
        changeCBUSendActivity: string.isRequired,
        idActivityOnFilter: string.isRequired,
        showFilter: bool.isRequired,
    };

    static defaultProps = {
        account: null,
        activeRegion: "",
        totalCount: null,
    };

    componentDidMount() {
        const { dispatch, isResetQuery, filtersSaved, idActivityOnFilter, ...rest } = this.props;

        dispatch(filterActions.validateToResetFilters(accountTypes.RESET_FILTERS, ID_ACTIVITY));
        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            dispatch(accountsActions.readAccount(rest.match.params.id));
            dispatch(
                accountsActions.setFilters({
                    dateFrom: defaultDateFromList().accountsMovements.dateFrom,
                    dateTo: defaultDateFromList().accountsMovements.dateTo,
                    selectedFilter: "all",
                }),
            );

            dispatch(accountsActions.details(rest.match.params.id));
        }
    }

    componentDidUpdate() {
        const { account, changeCBUSendActivity, dispatch } = this.props;
        if (changeCBUSendActivity !== "account.set.cbuAlias" && account?.cbuNick === "") {
            dispatch(accountsActions.setChangeCBUSendActivity("account.set.cbuAlias"));
        }
    }

    resetSelectedFilter = () => {
        const { dispatch } = this.props;
        dispatch(
            accountsActions.setFilters({
                selectedFilter: "more",
            }),
        );
    };

    handleMoreFiltersClick = () => {
        this.resetSelectedFilter();
        this.onToggleFilters();
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(accountsActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { dispatch, filtersSaved, ...rest } = this.props;

        let period = [];
        switch (id) {
            case "lastMonth":
                period = dateUtils.getLastMonthPeriod();
                break;
            case "secondLastMonth":
                period = dateUtils.getSecondLastMonthPeriod();
                break;
            case "all":
            default:
                break;
        }

        const filters = {
            dateFrom: period[0],
            dateTo: period[1],
            selectedFilter: id,
            movementType: filtersSaved.movementType,
        };

        dispatch(filterActions.setToggleFilters(false));
        dispatch(accountsActions.fetchMovements(rest.match.params.id, filters));
    };

    setAccountSelected = (idProduct, type) => {
        const { dispatch } = this.props;

        dispatch(checksActions.setAccountIdSelected(idProduct));
        if (type === "check") {
            dispatch(push("/formCustom/requestCheckbook"));
        } else if (type === "transferInternal") {
            dispatch(push("/formCustom/transferInternal"));
        } else if (type === "transferThirdParties") {
            dispatch(push("/formCustom/transferThirdParties"));
        } else if (type === "transferLocal") {
            dispatch(push("/formCustom/transferLocal"));
        } else if (type === "transferMassive") {
            dispatch(push("/formCustom/transferMassive"));
        }
    };

    menuOptionItems = () => {
        const { isDesktop } = this.props;

        const menuOptions = [];

        menuOptions.push(
            <AccountAction
                isDesktop={isDesktop}
                key="downloadPdf"
                labelKey="accounts.mobileOptions.download.movements"
                handleClick={() => this.handleClickDownload("pdf")}
                className="dropdown__item-btn"
            />,
        );

        return menuOptions;
    };

    renderShowStatements = () => {
        const { isMobile, dispatch, ...rest } = this.props;

        return (
            <>
                <Button
                    bsStyle="secondary"
                    image="images/ui-icons/ui-show-white.svg"
                    label="accounts.details.headTitle.showStatements"
                    className={isMobile ? "ui-mt-7" : "btn-small"}
                    block={isMobile}
                    onClick={() => dispatch(push(`/accounts/${rest.match.params.id}/statements`))}
                />
                <Button
                    bsStyle="tertiary"
                    image="images/ui-icons/ui-subtract-white.svg"
                    label="accounts.details.headTitle.requestAccountClosing"
                    className={isMobile ? "ui-mt-3 ui-mb-8" : "btn-small"}
                    block={isMobile}
                    onClick={() => dispatch(push(`/formCustom/accountClosing`))}
                />
            </>
        );
    };

    handleClickDownload = (format) => {
        const { dispatch, match } = this.props;
        dispatch(accountsActions.downloadMovements(match.params.id, format));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(replace(`/accounts/`));
    };

    showFilters = (totalCount) => totalCount > configUtil.get("accounts.statementsPerPage");

    renderQuickFilters = () => {
        const { filtersSaved } = this.props;

        return (
            <RadioButtonGroup
                inLineControl
                options={[
                    {
                        id: "all",
                        label: <I18n id="accounts.details.quickFilters.all" />,
                    },
                    {
                        id: "credit",
                        label: <I18n id="accounts.details.quickFilters.credit" />,
                    },
                    {
                        id: "debit",
                        label: <I18n id="accounts.details.quickFilters.debit" />,
                    },
                ]}
                name="detailsQuickFilters"
                onChange={this.handleQuickFilterChange}
                selectorId="2"
                value={filtersSaved.movementType}
            />
        );
    };

    handleQuickFilterChange = (e) => {
        const { account, dispatch, filtersSaved } = this.props;

        dispatch(accountsActions.details(account.idProduct, { ...filtersSaved, movementType: e }));
    };

    render() {
        const {
            totalCount,
            account,
            channels,
            fetching,
            fetchingDownload,
            fetchingMovements,
            dispatch,
            isDesktop,
            activeRegion,
            filtersSaved,
            isResetQuery,
            isMobile,
            dataFromWidget,
            history,
            showFilter,
            totalAccounts,
            ...rest
        } = this.props;

        const { selectedFilter } = filtersSaved;

        const filters = [
            <Button
                className={classNames({
                    "is-active": selectedFilter === "all",
                })}
                key="all"
                label="accounts.movements.filters.all"
                onClick={() => this.handleFilterButtonsClick("all")}
            />,
            <Button
                className={classNames({
                    "is-active": selectedFilter === "lastMonth",
                })}
                key="lastMonth"
                label="accounts.movements.filters.lastMonth"
                onClick={() => this.handleFilterButtonsClick("lastMonth")}
            />,
            <Button
                className={classNames({
                    "is-active": selectedFilter === "secondLastMonth",
                })}
                key="secondLastMonth"
                label="accounts.movements.filters.secondLastMonth"
                onClick={() => this.handleFilterButtonsClick("secondLastMonth")}
                replace={{
                    SECOND_LAST_MONTH: `${
                        i18nUtils.getArray("global.months")[dateUtils.getSecondLastMonthPeriod()[0].getMonth()]
                    } ${dateUtils
                        .getSecondLastMonthPeriod()[0]
                        .getFullYear()
                        .toString()}`,
                }}
            />,
        ];

        return (
            <PageLoading loading={fetching}>
                {!fetching && account && (
                    <Fragment>
                        <Notification scopeToShow="account/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={handleBackInDetail(
                                    dataFromWidget.data.accounts.length || totalAccounts,
                                    dispatch,
                                    "accounts",
                                    history,
                                )}
                                dispatch={dispatch}
                                handleOptionsClick={this.handleClick}
                                productId={rest.match.params.id}
                                fetchingDownload={fetchingDownload}
                                onClickDownloadPDF={() => this.handleClickDownload("pdf")}
                                onClickDownloadXLS={() => this.handleClickDownload("xls")}
                                customRightButton={this.renderShowStatements()}
                                mobileMenuOptions={this.menuOptionItems()}
                            />
                        </ProductDetail>

                        <MainContainer className="main-container">
                            <div className="container-left accounts-details">
                                <ProductDetail>
                                    <DetailHeadTitle account={account} isDesktop={isDesktop} dispatch={dispatch} />
                                    <Information
                                        account={account}
                                        keyLabel="accounts.information"
                                        dispatch={dispatch}
                                        activeRegion={activeRegion}
                                        isMobile={isMobile}
                                    />

                                    {isMobile && this.renderShowStatements()}

                                    <DetailHeadInfo {...this.props} />

                                    <ProductDetail.Body
                                        closeMoreFiltersWhenSiblingClick
                                        isDesktop={isDesktop}
                                        filters={filters}
                                        quickFilters={this.renderQuickFilters()}
                                        forceOpen={showFilter}
                                        filtersKeyLabel="accounts.movements"
                                        handleMoreFiltersClick={this.handleMoreFiltersClick}
                                        moreFilters={
                                            <MoreFilters
                                                channels={channels}
                                                currency={account.currency}
                                                dispatch={dispatch}
                                                isDesktop={isDesktop}
                                                isSubmitting={rest.isSubmitting}
                                                productId={rest.match.params.id}
                                                resetSelectedFilter={this.resetSelectedFilter}
                                                filtersSaved={showFilter ? filtersSaved : null}
                                            />
                                        }
                                        moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                        moreFiltersOpenedKeyLabel="accounts.movements.filters.less"
                                        fetching={fetchingMovements}
                                        showSubtitle
                                        subtitleLabel="accounts.movements">
                                        <Movements
                                            accountId={account.idProduct}
                                            accountCurrency={account.currency}
                                            isDesktop={isDesktop}
                                            keyLabel="accounts.movements"
                                            quickFilters={this.renderQuickFilters()}
                                            renderMoreFilters={
                                                <MoreFilters
                                                    channels={channels}
                                                    currency={account.currency}
                                                    dispatch={dispatch}
                                                    isDesktop={isDesktop}
                                                    isSubmitting={rest.isSubmitting}
                                                    productId={rest.match.params.id}
                                                />
                                            }
                                        />
                                    </ProductDetail.Body>
                                </ProductDetail>
                            </div>
                        </MainContainer>
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    totalAccounts: accountsSelectors.getTotalAccounts(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: accountsSelectors.getFetchingAccount(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
    fetchingMovements: accountsSelectors.getFetchingMovements(state),
    activeEnvironmentForms: sessionSelectors.getActiveEnvironmentForms(state, "accounts"),
    totalCount: accountsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    filtersSaved: accountsSelectors.getFilters(state),
    dataFromWidget: widgetSelector.getWidget(state, "accounts"),
    changeCBUSendActivity: accountsSelectors.getChangeCBUSendActivity(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    showFilter: filterSelectors.getShowFilters(state),
});

export default connect(mapStateToProps)(Details);
