import { selectors, actions } from "reducers/paymentToSuppliers";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import { actions as filterActions } from "reducers/filters";
import Head from "../_components/Head";
import MainContainer from "../_components/MainContainer";
import I18n from "../_components/I18n";

const FORM_ID = "forms.payments.suppliers.errorDetails";
const ErrorDetails = ({ errors, isFetching, isMobile, fileName, location, dispatch, filters }) => {
    useEffect(() => {
        dispatch(
            actions.getErrorsRequest(filters?.internalFileName, filters?.internalMemberFileName, filters?.fileName),
        );
    }, [dispatch, location]);

    const handleBack = () => {
        dispatch(filterActions.setResetFilters(false));
        dispatch(goBack());
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head showLogo onBack={handleBack} arrowWhite additionalClassName="blue-main-header-mobile" />;
        }
        return <Head onBack={handleBack} />;
    };

    return (
        <>
            {renderHeader()}
            <MainContainer showLoader={isFetching}>
                <div className="title-left-header">
                    <h1>{fileName}</h1>
                    <I18n id={`${FORM_ID}.subtitle.label`} component="h2" />
                </div>

                <br />

                <div className="container-left">
                    {errors.map((item) => (
                        <p>{`Error en linea ${item?.line}: ${item?.description}`}</p>
                    ))}
                </div>
            </MainContainer>
        </>
    );
};

ErrorDetails.propTypes = {
    isFetching: bool.isRequired,
    isMobile: bool.isRequired,
    errors: oneOfType([array]).isRequired,
    filters: oneOfType([array]).isRequired,
    fileName: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    errors: selectors.getErrors(state),
    isFetching: selectors.isFetching(state),
    fileName: selectors.getNameFileError(state),
    filters: selectors.getFiltersErrors(state),
});

export default connect(mapStateToProps)(ErrorDetails);
