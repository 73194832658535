import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Enviroments from "pages/_components/Enviroments";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, oneOfType, array, object } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";
import { Mixpanel } from "util/clickstreaming";
import * as i18n from "util/i18n";
import Yup from "yup";

const FORM_ID = "login.step3";

class Step3Content extends Component {
    static propTypes = {
        isSubmitting: bool,
        environments: oneOfType([array, object]).isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    render() {
        const { environments, isSubmitting } = this.props;

        return (
            <Form className="form-content container">
                <div className="pre-form-info">
                    <div className="pre-form-info__text ui-mb-6">
                        <h2 className="ui-h4">{i18n.get("login.step3.subtitle")}</h2>
                    </div>
                </div>
                <Field
                    name="environment"
                    environments={environments}
                    component={Enviroments}
                    legendTextID="login.step3.environment.required"
                    fromSetDefaultEnvironmentPage={false}
                />
                <Field name="rememberEnvironment" idForm={FORM_ID} component={SwitchField} />
                <Button block type="submit" loading={isSubmitting} label="login.nextStep" bsStyle="primary" />
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(loginSelectors.getEnvironments(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ environment: "", rememberEnvironment: false }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.environment.required`)),
            }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            Mixpanel.track(FORM_ID, { environment, rememberEnvironment });
            formikBag.props.dispatch(loginActions.loginStep3(environment, rememberEnvironment, formikBag));
        },
    }),
)(Step3Content);
