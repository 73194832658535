import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bool, number, oneOfType, shape, string } from "prop-types";
import classNames from "classnames";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ItemExpirationDate from "pages/_components/item/ExpirationDate";
import ItemName from "pages/_components/item/Name";

class ListItemMobile extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string.isRequired,
        currency: string.isRequired,
        expirationDate: string,
        expirationText: string,
        expiredText: string,
        icon: string,
        isExpired: bool,
        name: string.isRequired,
        overdraftEnabled: bool,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        title: string.isRequired,
        franchise: string,
        productTypeTitle: string.isRequired,
        idProduct: string.isRequired,
        productNumber: string.isRequired,
        isDepositsWidget: bool,
    };

    static defaultProps = {
        expirationDate: null,
        expirationText: null,
        expiredText: null,
        icon: null,
        isExpired: null,
        overdraftEnabled: null,
        franchise: "",
        isDepositsWidget: false,
    };

    getContent = () => {
        const {
            amount,
            amountLabel,
            currency,
            expirationDate,
            expirationText,
            expiredText,
            icon,
            isExpired,
            name,
            overdraftEnabled,
            title,
            idProduct,
            productNumber,
            isDepositsWidget,
        } = this.props;

        if (isDepositsWidget) {
            return (
                <div className={classNames("Product deposits", { "is-expired": isExpired })}>
                    <div id={idProduct} className="Product-row text-right">
                        <span className="Product-label">
                            <p className="data-label">
                                <I18n id="widgets.deposits.alias.label" />
                            </p>
                            {name && <ItemName name={name} icon={icon} />}
                        </span>
                        <span className="Product-label">
                            <ItemExpirationDate
                                date={expirationDate}
                                expiredText={expiredText}
                                expirationText={expirationText}
                                isExpired={isExpired}
                            />
                        </span>
                        <span className="Product-label">
                            <p className="data-label">{amountLabel}</p>
                            <FormattedAmount quantity={amount} currency={currency} />
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className={classNames("Product", { "is-expired": isExpired })}>
                {name && <ItemName name={name} icon={icon} />}
                <div className="Product-row">
                    <p className="data-label">{title}</p>
                    <p className="data-desc">{productNumber}</p>
                </div>
                <div id={idProduct} className="Product-row">
                    <p className="data-label">{amountLabel}</p>
                    <FormattedAmount quantity={amount} currency={currency} />
                    {overdraftEnabled && (
                        <span className="Product-label">
                            <span className="text-label quiet-label">
                                <I18n id="accounts.overdraft.enabled" />
                            </span>
                        </span>
                    )}
                    {expirationDate && (
                        <span className="Product-label">
                            <ItemExpirationDate
                                date={expirationDate}
                                expiredText={expiredText}
                                expirationText={expirationText}
                                isExpired={isExpired}
                            />
                        </span>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { name, path, franchise, productTypeTitle, idProduct } = this.props;

        if (path) {
            return (
                <Link
                    aria-label={`${productTypeTitle}: ${franchise !== "" ? `${franchise} ` : ""}${name}`}
                    aria-describedby={idProduct}
                    className="col col-sm-12"
                    to={path}
                    role="menuitem">
                    {this.getContent()}
                </Link>
            );
        }
        return this.getContent();
    }
}

export default ListItemMobile;
