export const unifyListParams = (response) => {
    const { data } = response;
    if (data && data.body) {
        return {
            moreStatements: !(data.body.last || data.body.numberOfElements < data.body.size),
            pageNumber: data.body.pageable.pageNumber + 1,
            totalCount: data.body.totalElements,
            statements: data.body.content,
        };
    }
    return data.data;
};

export default unifyListParams;
