import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { array, bool, func, oneOfType } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as filterActions } from "reducers/filters";
import { routerActions } from "react-router-redux/actions";
import { selectors as qrSelectors } from "reducers/qr";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { Col, Row } from "react-bootstrap";
import CommissionsList from "./CommissionsList";
import WithholdingsList from "./WithholdingsList";

const ChargesDetail = ({ chargeList, isMobile, history, isFetching, dispatch, isDownloading, ...rest }) => {
    const [charge, setCharge] = useState({});
    const [withholdings, setWithholdings] = useState([]);
    const [commissions, setCommissions] = useState([]);

    useEffect(() => {
        const filterCharge = chargeList.find((item) => item.uuid === rest.match.params.idCharge);

        if (filterCharge) {
            setCharge(filterCharge);
            setWithholdings(filterCharge.withHoldings);
            setCommissions(filterCharge.commissions);
        } else {
            history.push("/qrChargesCertificates/initValue");
        }
    }, []);

    const handleClickDownload = () => {};

    const handleOnBack = () => {
        dispatch(filterActions.setResetFilters(false));
        dispatch(routerActions.push("/qrChargesCertificates/return"));
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head onBack={handleOnBack} arrowWhite showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                onBack={handleOnBack}
                showPlusIcon
                accessibilityTextId="accounts.qrChargesCertificates.charges.details.label"
                isFetchingExport={isDownloading}
                handleClick={handleClickDownload}
            />
        );
    };

    const renderChangeData = () => (
        <div className="details-container">
            <Row className="details__bg-content">
                <Col sm={12} md={6}>
                    <div className="data-list inline">
                        <div className="data-wrapper">
                            <I18n id="tableHeader.paydate" componentProps={{ className: "data-label" }} />
                            <FormattedDate date={charge.paymentDate} />
                        </div>
                        <div className="data-wrapper">
                            <I18n id="tableHeader.concept" componentProps={{ className: "data-label" }} />
                            <span className="data-text">{charge.concept}</span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="tableHeader.payerName" />
                            </div>
                            <span className="data-text">{charge.payerName}</span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="tableHeader.payerDoc" />
                            </div>
                            <span className="data-text">{charge.payerDocument}</span>
                        </div>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div className="data-list inline">
                        <div className="data-wrapper">
                            <I18n id="tableHeader.type" componentProps={{ className: "data-label" }} />
                            <span className="data-text">{charge.type}</span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="tableHeader.grossAmountNoCurrency" />
                            </div>
                            <FormattedAmount currency="ARS" quantity={charge.grossAmount} />
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="tableHeader.netAmountNoCurrency" />
                            </div>
                            <FormattedAmount currency="ARS" quantity={charge.netAmount} />
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="tableHeader.authCode" />
                            </div>
                            <span className="data-text">{charge.authorizationCode}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <>
            {renderHeader()}

            <MainContainer className="main-container" showLoader={isFetching}>
                <div className="container-left">
                    <div className="title-left-header">
                        <I18n id="accounts.qrChargesCertificates.charges.details.label" component="h1" />
                        <div className="data-wrapper ui-mt-3">
                            <mark className="mark-subtitle">
                                <small>{i18n.get(`transaction.status.${charge.status}`).toUpperCase()}</small>
                            </mark>
                        </div>
                    </div>
                    <>
                        {renderChangeData()}
                        <CommissionsList isMobile={isMobile} commissions={commissions} />
                        <WithholdingsList isMobile={isMobile} withholdings={withholdings} />
                    </>
                </div>
            </MainContainer>
        </>
    );
};

ChargesDetail.propTypes = {
    chargeList: oneOfType([array]).isRequired,
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    history: func.isRequired,
    isFetching: bool.isRequired,
    isDownloading: bool.isRequired,
};

const mapPropsToState = (state) => ({
    chargeList: qrSelectors.getChargeList(state),
    isFetching: state.investments.isFetchingMovements,
    isDownloading: state.investments.isDownloading,
});

export default compose(withRouter, connect(mapPropsToState))(ChargesDetail);
