import React, { Component } from "react";
import { shape, func, string, bool } from "prop-types";
import { connect } from "react-redux";

import { selectors } from "reducers/status";
import { actions as sessionActions } from "reducers/session";

import SimpleModal from "components/SimpleModal/SimpleModal";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

class SessionAboutToExpire extends Component {
    static getDerivedStateFromProps({ status }, { seconds }) {
        if (status.sessionAboutToExpire && seconds === 0) {
            return { seconds: status.sessionSecondsToExpire };
        }

        if (!status.sessionAboutToExpire && seconds !== 0) {
            return { seconds: 0 };
        }

        return null;
    }

    state = {
        seconds: 0,
    };

    interval = null;

    static propTypes = {
        status: shape({
            sessionAboutToExpire: bool.isRequired,
        }).isRequired,
        location: shape({
            href: string,
        }).isRequired,
        dispatch: func.isRequired,
    };

    componentDidUpdate() {
        const { status } = this.props;

        if (!this.interval && status.sessionAboutToExpire) {
            this.interval = setInterval(this.decreaseSeconds, 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    decreaseSeconds = () => {
        const { seconds } = this.state;
        const { location, dispatch } = this.props;

        if (seconds > 1) {
            this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
        } else {
            dispatch(sessionActions.expire(location.href));
        }
    };

    extendSession = () => {
        const { dispatch } = this.props;

        dispatch(sessionActions.extend());
        clearInterval(this.interval);
        this.interval = null;
    };

    render() {
        const { status } = this.props;
        const { seconds } = this.state;

        return (
            <SimpleModal
                isDisplayed={status.sessionAboutToExpire}
                modalIcon="images/coloredIcons/chronometer.svg"
                modalMainText="session.aboutToExpire"
                showActions={false}
                closeButton={false}>
                <I18n
                    component="p"
                    componentProps={{ className: "giant-chronometer ui-mt-3" }}
                    id="session.expires.in"
                    SECONDS={seconds}
                />
                <Button
                    className="ui-mt-7"
                    label="session.extend"
                    onClick={() => this.extendSession()}
                    bsStyle="primary"
                    block
                />
            </SimpleModal>
        );
    }
}

const mapStateToProps = (state) => ({ status: selectors.getStatus(state) });

export default connect(mapStateToProps)(SessionAboutToExpire);
