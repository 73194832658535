import { delay } from "redux-saga";
import { call, takeLatest, put, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";

import * as i18n from "middleware/i18n";
import * as configUtil from "util/config";
import { types as loginTypes } from "reducers/login";

import { MAX_FAILED_TIMES, TIME_IN_MILLIS_TO_REFRESH } from "constants.js";
import globalTypes from "reducers/types/global";
import { types, selectors as i18nSelector } from "reducers/i18n";
import { deviceLanguage } from "util/i18n";
import { store } from "../store";

const sagas = [
    takeLatest(globalTypes.INIT, checkInitialLang),
    takeLatest(types.SET_LANG, changeLanguage),
    takeLatest(loginTypes.LOGIN_STEP_2_SUCCESS, changeLanguage),
    takeLatest(loginTypes.LOGIN_OAUTH_SUCCESS, changeLanguage),
    takeLatest([types.SET_INIT_LANG, types.RESET_SAGAS_UPDATE], update),
];

export default sagas;

function* changeLanguage({ lang, etag }) {
    try {
        yield put({ type: types.UPDATE_REQUEST });

        moment.locale(lang);

        const response = yield call(i18n.listMessages, lang, etag);
        if (response.status !== 304) {
            const { data } = response.data;
            const etagResponse = response.headers.etag;

            yield put({ type: types.UPDATE_SUCCESS, items: data, etag: etagResponse, lang });
        } else {
            yield put({ type: types.UPDATE_FETCH_TO_FALSE });
        }
    } catch (err) {
        yield call(updateFailure);
    }
}

function* checkInitialLang() {
    const initialLang = i18nSelector.getLang(store.getState());

    if (initialLang) {
        moment.locale(initialLang);
        yield call(update, { lang: initialLang });
    } else {
        const deviceLang = deviceLanguage();

        if (deviceLang) {
            moment.locale(deviceLang);
            yield put({ type: types.SET_INIT_LANG, lang: deviceLang });
        }
    }
}

function* update({ lang, etag }) {
    while (true) {
        try {
            const timesFailed = yield select(i18nSelector.getTimesFailed);

            if (timesFailed >= MAX_FAILED_TIMES) {
                yield put(push({ pathname: "/serverError" }));
            }

            yield put({ type: types.UPDATE_REQUEST });

            const response = yield call(i18n.listMessages, lang, etag);
            if (response.status !== 304) {
                const { code } = response.data;

                if (code === "COR000I") {
                    const { data } = response.data;
                    const { etag: etagResponse } = response.headers;

                    yield put({ type: types.UPDATE_SUCCESS, items: data, etag: etagResponse, lang });
                    yield call(
                        delay,
                        configUtil.getTimeInMillis("frontend.i18n.refresh.interval", TIME_IN_MILLIS_TO_REFRESH),
                    );
                } else {
                    yield call(updateFailure, TIME_IN_MILLIS_TO_REFRESH);
                }
            } else {
                yield put({ type: types.UPDATE_FETCH_TO_FALSE });
                yield call(
                    delay,
                    configUtil.getTimeInMillis("frontend.i18n.refresh.interval", TIME_IN_MILLIS_TO_REFRESH),
                );
            }
        } catch (err) {
            yield call(updateFailure, TIME_IN_MILLIS_TO_REFRESH);
        }
    }
}

function* updateFailure(timeInMillisToDelay = 0) {
    yield put({ type: types.UPDATE_FAILURE });
    yield call(delay, timeInMillisToDelay);
}
