import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { createReducer } from "util/redux";

import globalTypes from "reducers/types/global";

import accounts from "reducers/accounts";
import administration from "reducers/administration";
import administrationTicket from "reducers/administration/common/administrationTicket";
import administrationSignaturesSchemes from "reducers/administration/common/signaturesSchemes";
import administrationGroups from "reducers/administration/groups";
import restrictions from "reducers/administration/restrictions";
import administrationUsers from "reducers/administration/users";
import administrationUsersInvite from "reducers/administration/usersInvite";
import assistant from "reducers/assistant";
import bankSelector from "reducers/bankSelector";
import bottomSheet from "reducers/bottomSheet";
import campaigns from "reducers/campaigns";
import chatbot from "reducers/chatbot";
import checks from "reducers/checks";
import comex from "reducers/comex";
import communication from "reducers/communication";
import communications from "reducers/communications";
import communicationTrays from "reducers/communicationTrays";
import config from "reducers/config";
import creditCard from "reducers/creditCard";
import creditCardMovementDetails from "reducers/creditCardMovementDetails";
import creditCardRequest from "reducers/creditCardRequest";
import creditCards from "reducers/creditCards";
import desktop from "reducers/desktop";
import enrollment from "reducers/enrollment";
import files from "reducers/files";
import fingerprint from "reducers/fingerprint";
import form from "reducers/form";
import formFields from "reducers/formFields";
import frequentRecipients from "reducers/frequentRecipients";
import i18n from "reducers/i18n";
import investments from "reducers/investments";
import loans from "reducers/loans";
import loansPayment from "reducers/loansPayment";
import login from "reducers/login";
import misc from "reducers/misc";
import notification from "reducers/notification";
import notificationModal from "reducers/notificationModal";
import onboarding from "reducers/onboarding";
import pointsOfInterest from "reducers/pointsOfInterest";
import pushNotifications from "reducers/pushNotifications";
import qr from "reducers/qr";
import recoverUser from "reducers/recoverUser";
import recoveryPassword from "reducers/recoveryPassword";
import session, { types as sessionTypes } from "reducers/session";
import settings from "reducers/settings";
import status from "reducers/status";
import template from "reducers/template";
import transactions from "reducers/transactions";
import userTransactions from "reducers/userTransactions";
import weather from "reducers/weather";
import widgets from "reducers/widgets";
import footerReducer from "./footer";
import geolocationReducer from "./geolocation";
import massPayments from "./massPayments";
import echeqs from "./echeqs";
import paymentToSuppliers from "./paymentToSuppliers";
import filters from "./filters";

export const actions = {
    init: () => ({
        type: globalTypes.INIT,
    }),
};

const appReducer = combineReducers({
    router: routerReducer,
    isLoading: createReducer(true, {
        [globalTypes.INIT]: () => false,
    }),

    assistant,
    accounts,
    bottomSheet,
    campaigns,
    chatbot,
    communications,
    communication,
    communicationTrays,
    config,
    creditCard,
    creditCards,
    creditCardMovementDetails,
    desktop,
    enrollment,
    fingerprint,
    form,
    i18n,
    loans,
    loansPayment,
    notification,
    notificationModal,
    onboarding,
    pushNotifications,
    recoverUser,
    recoveryPassword,
    session,
    settings,
    status,
    template,
    widgets,
    userTransactions,
    transactions,
    bankSelector,
    login,
    weather,
    administration,
    administrationGroups,
    administrationSignaturesSchemes,
    administrationTicket,
    administrationUsers,
    administrationUsersInvite,
    files,
    formFields,
    pointsOfInterest,
    creditCardRequest,
    restrictions,
    misc,
    checks,
    investments,
    frequentRecipients,
    comex,
    geolocation: geolocationReducer,
    footer: footerReducer,
    massPayments,
    qr,
    echeqs,
    paymentToSuppliers,
    filters,
});

const rootReducer = (state = {}, action) => {
    const initializationState = {
        i18n: state.i18n,
        config: state.config,
        notification: state.notification,
        notificationModal: state.notificationModal,
        isLoading: state.isLoading,
        session: state.session,
        fingerprint: state.fingerprint,
        weather: state.weather,
        login: state.login,
        misc: state.misc,
        accounts: state.accounts,
    };

    switch (action.type) {
        case globalTypes.CLEAN_UP:
        case sessionTypes.LOGOUT_REQUEST:
            return appReducer(
                {
                    ...initializationState,
                    login: state.login,
                },
                action,
            );
        // cleaning all specific environment data
        case sessionTypes.CHANGE_ENVIRONMENT_SUCCESS:
            return appReducer(
                {
                    ...initializationState,
                },
                action,
            );
        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
