import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, shape, func, oneOfType, number } from "prop-types";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { loadScript } from "util/general.js";
import * as i18n from "util/i18n";
import { REGION_REST_OF_LATAM } from "constants.js";
import * as config from "util/config";

class AddressGoogleField extends Component {
    static propTypes = {
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        hideLabel: bool,
        hidePlaceholder: bool,
        placeholderText: string,
        activeRegion: string,
        onPlaceChanged: func,
    };

    static defaultProps = {
        hideLabel: false,
        hidePlaceholder: false,
        placeholderText: null,
        activeRegion: REGION_REST_OF_LATAM,
        onPlaceChanged: () => {},
    };

    constructor(props) {
        super(props);
        this.autoCompleteRef = React.createRef();
    }

    componentDidMount() {
        const { field } = this.props;
        if (window.google !== undefined && window.google.maps !== undefined) {
            this.handleScriptLoad();
        } else {
            const apiKey = config.get("googlemap.apikey", "AIzaSyAeG8KAM9fZ7amUikdy5AZvsD5bYCAXH40");
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, () =>
                this.handleScriptLoad(),
            );
        }
        const inputField = document.getElementById(field.name);
        inputField.value = field.value;
    }

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    renderField = () => {
        const { field } = this.props;
        return (
            <div className="input-group">
                <input
                    id={field.name}
                    ref={this.autoCompleteRef}
                    className="form-control"
                    autoComplete="field"
                    placeholder={this.renderPlaceholder()}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    };

    handleBlur = (event) => {
        const { field, form } = this.props;
        if (field.value !== event.target.value) {
            form.setFieldValue(field.name, event.target.value);
        }
    };

    initAutocomplete = () => {
        const { activeRegion } = this.props;
        const componentRestrictions = {};
        if (activeRegion !== REGION_REST_OF_LATAM) {
            componentRestrictions.country = activeRegion.toLowerCase();
        }
        if (window.google && window.google.maps && window.google.maps.places) {
            this.autoComplete = new window.google.maps.places.Autocomplete(this.autoCompleteRef.current, {
                componentRestrictions,
            });
        }
    };

    handleScriptLoad = () => {
        this.initAutocomplete();
        this.autoComplete.setFields(["address_components", "formatted_address", "name", "adr_address"]);
        this.autoComplete.addListener("place_changed", this.place_changed);
    };

    place_changed = () => {
        const { form, field, onPlaceChanged } = this.props;
        const addressObject = this.autoComplete.getPlace();
        if (addressObject !== undefined) {
            form.setFieldValue(field.name, addressObject.name);
            const inputField = document.getElementById(field.name);
            inputField.value = addressObject.name;
            // Get City
            let city = "";
            const locality = addressObject.address_components.filter(
                (x) => x.types.filter((y) => y === "locality").length > 0,
            );
            if (locality.length > 0) {
                city = locality[0].long_name;
            }
            // Get Country
            let country = "";
            const countryObj = addressObject.address_components.filter(
                (x) => x.types.filter((y) => y === "country").length > 0,
            );
            if (countryObj.length > 0) {
                country = countryObj[0].short_name;
            }

            let state = "";
            const administrative_area_level_1 = addressObject.address_components.filter(
                (x) => x.types.filter((y) => y === "administrative_area_level_1").length > 0,
            );
            if (administrative_area_level_1.length > 0) {
                state = administrative_area_level_1[0].long_name;
            }
            // Get administrative_area_level_1
            onPlaceChanged(city, country, state);
        }
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
        } = this.props;

        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--image-selector", {
                    "has-error": hasError,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <input type="hidden" value="something" />
                {this.renderField()}
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default AddressGoogleField;
