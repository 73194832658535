import React from "react";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { bool, node, shape, string } from "prop-types";

import "./_noDataContainer.scss";

const NoDataContainer = ({
    className,
    fetching,
    key,
    noDataImage,
    noDataText,
    noFiltersDataText,
    withFilters,
    children,
    applyMaxWidth,
    customIconStyles,
    i18nParams,
}) => (
    <div className={`no-data-image-container ${applyMaxWidth ? "max-width" : ""} ${className || ""}`} key={key}>
        {!fetching && (
            <>
                <div className={`no-data-image ${children ? "ui-mb-0" : ""}`}>
                    <div className="illustration-wrapper">
                        <Image
                            src={withFilters ? "images/search-no-results.svg" : noDataImage}
                            className="svg-big-icon"
                            styles={customIconStyles}
                        />
                    </div>
                    {withFilters ? (
                        <I18n id={noFiltersDataText} component="p" {...i18nParams?.noFiltersDataText} />
                    ) : (
                        <I18n id={noDataText} component="p" {...i18nParams?.noDataText} />
                    )}
                </div>
                {children}
            </>
        )}
    </div>
);

NoDataContainer.propTypes = {
    className: string,
    fetching: bool,
    key: string,
    noDataImage: string.isRequired,
    noDataText: string.isRequired,
    noFiltersDataText: string,
    withFilters: bool,
    children: node,
    applyMaxWidth: bool,
    customIconStyles: shape({}),
    i18nParams: shape({}),
};

NoDataContainer.defaultProps = {
    className: "",
    fetching: true,
    key: "",
    noFiltersDataText: "",
    withFilters: false,
    children: null,
    applyMaxWidth: true,
    customIconStyles: null,
    i18nParams: null,
};

export default NoDataContainer;
