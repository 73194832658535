import React, { Component } from "react";
import { arrayOf, number, shape, string, func } from "prop-types";

import Image from "pages/_components/Image";

class ChatAttachment extends Component {
    static propTypes = {
        attachment: shape({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        handleDownloadAttachment: func.isRequired,
    };

    static defaultProps = {
        attachment: null,
    };

    getFileExtension = (fileName) => fileName.split(".").pop();

    drawAttachmentIcon = (fileName) => {
        if (fileName === "txt") {
            return <Image src="images/fileIconsByExtension/file-txt.svg" />;
        }
        if (fileName === "doc" || fileName === "docx") {
            return <Image src="images/fileIconsByExtension/file-doc.svg" />;
        }
        if (fileName === "xls" || fileName === "xlsx") {
            return <Image src="images/fileIconsByExtension/file-xls.svg" />;
        }
        if (fileName === "ppt" || fileName === "pptx") {
            return <Image src="images/fileIconsByExtension/file-ppt.svg" />;
        }
        if (fileName === "jpg") {
            return <Image src="images/fileIconsByExtension/file-jpg.svg" />;
        }
        if (fileName === "gif") {
            return <Image src="images/fileIconsByExtension/file-gif.svg" />;
        }
        if (fileName === "bmp") {
            return <Image src="images/fileIconsByExtension/file-bmp.svg" />;
        }
        if (fileName === "png") {
            return <Image src="images/fileIconsByExtension/file-png.svg" />;
        }
        if (fileName === "pdf") {
            return <Image src="images/fileIconsByExtension/file-pdf.svg" />;
        }
        return <Image src="images/fileIconsByExtension/file-default.svg" />;
    };

    render() {
        const { attachment, handleDownloadAttachment } = this.props;

        return attachment.fileList.map((file) => (
            <div className="attachment-tag" role="button" onClick={handleDownloadAttachment(file.idFile)}>
                {this.drawAttachmentIcon(this.getFileExtension(file.fileName))}
                <span role="presentation" key={file.idFile}>
                    {file.fileName}
                </span>
            </div>
        ));
    }
}
export default ChatAttachment;
