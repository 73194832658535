import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as echeqSelector, actions as echeqActions } from "reducers/echeqs";
import { push } from "react-router-redux";
import { bool, func, shape, string } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import PageLoading from "pages/_components/PageLoading";
import Table from "../_components/Table";
import I18n from "../_components/I18n";
import NoDataContainer from "../../components/NoDataContainer/NoDataContainer";
import AccountItem from "./components/AccountItem";
import MainContainer from "../_components/MainContainer";
import Head from "../_components/Head";
import SeuoLabel from "../accounts/SeuoLabel/SeuoLabel";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import Notification from "../_components/Notification";

const ACTIVITY_ID = "echeqs.managementAccounts.list";
const AccountListToIssueECheq = ({ accounts, fetching, isDesktop, mode, dispatch, location, activeEnvironment }) => {
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [accountSelected, setAccountSelected] = useState(null);

    useEffect(() => {
        const { permissions } = activeEnvironment;
        const echeqsTyCSigned = permissions["echeqs.termsAndConditions.alreadySigned"];

        if (!echeqsTyCSigned && permissions["echeqs.termsAndConditions.adherence"]) {
            dispatch(echeqActions.getAcceptEcheqTermsConditionsRequest());
        }
        dispatch(echeqActions.getEcheqIssuingAccountsRequest("todas"));
    }, [dispatch, location]);

    const renderHeader = () => {
        if (!isDesktop) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return <Head />;
    };

    const handleOnClick = (account, option) => {
        if (option === "activate") {
            dispatch(echeqActions.setAccountSelected(account));
            dispatch(push("/echeqs/management/account/activate"));
        } else {
            setAccountSelected(account);
            setShowDisableModal(true);
        }
    };

    const handleDisableAccount = () => {
        setShowDisableModal(false);
        dispatch(
            echeqActions.disableAccountRequest(
                "disable",
                accountSelected?.cbu,
                accountSelected?.client?.idClient,
                accountSelected,
            ),
        );
    };

    const list = accounts.map((item) => (
        <>
            {isDesktop ? (
                <Table.Row key={item.id} renderAs="div" additionalClassName="no-hover">
                    <AccountItem
                        key={item?.accountNumber}
                        account={item}
                        isDesktop={isDesktop}
                        mode={mode}
                        handleOnClick={handleOnClick}
                        ACTIVITY_ID={ACTIVITY_ID}
                    />
                </Table.Row>
            ) : (
                <AccountItem
                    key={item?.accountNumber}
                    account={item}
                    isDesktop={isDesktop}
                    mode={mode}
                    handleOnClick={handleOnClick}
                    ACTIVITY_ID={ACTIVITY_ID}
                />
            )}
        </>
    ));

    return (
        <>
            <Notification scopeToShow="eCheqAccounts" />
            {renderHeader()}

            <MainContainer>
                <div className="container-left">
                    <div className="title-left-header">
                        <I18n id="echeqs.managementAccounts.list.title" component="h1" />
                    </div>
                    <PageLoading loading={fetching}>
                        {accounts.length ? (
                            <>
                                {isDesktop ? (
                                    <>
                                        <Table className="gridTable">
                                            <Table.Header>
                                                <Table.HeaderData align="left">
                                                    <I18n id={`${ACTIVITY_ID}.head.alias.label`} />
                                                </Table.HeaderData>
                                                <Table.HeaderData align="left">
                                                    <I18n id={`${ACTIVITY_ID}.head.type.label`} />
                                                </Table.HeaderData>
                                                <Table.HeaderData align="left">
                                                    <I18n id={`${ACTIVITY_ID}.head.accountNumber.label`} />
                                                </Table.HeaderData>
                                                <Table.HeaderData align="left">
                                                    <I18n id={`${ACTIVITY_ID}.head.amount.label`} />
                                                </Table.HeaderData>
                                                <Table.HeaderData align="left">
                                                    <I18n id={`${ACTIVITY_ID}.head.status.label`} />
                                                </Table.HeaderData>
                                                <Table.HeaderData />
                                            </Table.Header>

                                            <Table.Body>{list}</Table.Body>
                                        </Table>

                                        <SeuoLabel />
                                    </>
                                ) : (
                                    <>
                                        <div className="generic-movements-wrapper">{list}</div>
                                        <SeuoLabel />
                                    </>
                                )}
                            </>
                        ) : (
                            <NoDataContainer
                                fetching={fetching}
                                noDataImage="images/coloredIcons/folder-empty.svg"
                                noDataText={`${ACTIVITY_ID}.noData.message`}
                            />
                        )}

                        {showDisableModal && (
                            <SimpleModal
                                isDisplayed={showDisableModal}
                                closeModal={() => setShowDisableModal(false)}
                                customCancellationFunction={() => setShowDisableModal(false)}
                                modalIcon="images/warningIcon.svg"
                                modalMainText="forms.echeqs.managementAccounts.activateOrDeactivate.disable.message"
                                buttonAction={() => handleDisableAccount()}
                            />
                        )}
                    </PageLoading>
                </div>
            </MainContainer>
        </>
    );
};

AccountListToIssueECheq.propTypes = {
    dispatch: func.isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    accounts: shape([]).isRequired,
    fetching: bool.isRequired,
    isDesktop: bool.isRequired,
    activeEnvironment: shape({
        permissions: shape({}),
    }).isRequired,
};

const mapStateToProps = (state) => ({
    accounts: echeqSelector.getEcheqIssuingAccounts(state),
    fetching: echeqSelector.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AccountListToIssueECheq);
