import React from "react";
import ReactSelect from "react-select";
import { Field } from "formik";
import { string, arrayOf, shape, func, bool, node, oneOfType } from "prop-types";
import FieldError from "pages/_components/fields/FieldError";
import classNames from "classnames";

class Select extends React.Component {
    static propTypes = {
        idForm: string,
        name: string.isRequired,
        options: arrayOf(
            shape({
                value: string,
                label: oneOfType([node, string]),
            }),
        ).isRequired,
        className: string,
        hideLabel: bool,
        label: oneOfType([node, string]),
        optional: string,
        placeholder: string,
        noResultsText: string,
        onChange: func,
        searchable: bool,
        clearable: bool,
    };

    static defaultProps = {
        idForm: "",
        className: "form-group",
        placeholder: "",
        noResultsText: "",
        searchable: false,
        clearable: false,
        onChange: null,
        hideLabel: false,
        label: "",
        optional: "",
    };

    render() {
        const { idForm, name, optional, className, hideLabel, label, onChange, ...selectProps } = this.props;
        const idField = `${idForm}.${name}`;

        return (
            <Field name={name}>
                {({ field, form }) => {
                    const hasError = form.touched[field.name] && form.errors[field.name];
                    const controlClassName = classNames(className, {
                        "has-error": hasError,
                    });
                    return (
                        <div className={controlClassName}>
                            {!hideLabel && label && (
                                <div className="form-group-text">
                                    <label className="control-label" id={`label.${idField}`} htmlFor={idField}>
                                        {label}
                                        {optional && <small className="text-optional">{optional}</small>}
                                    </label>
                                </div>
                            )}
                            <div className="input-group">
                                <input type="hidden" id={idField} name={name} />
                                <ReactSelect
                                    inputProps={{ id: idField, name }}
                                    value={typeof field.value === "string" ? field.value : undefined}
                                    {...selectProps}
                                    onChange={(option) => {
                                        if (onChange) {
                                            onChange(option);
                                        } else {
                                            form.setFieldValue(field.name, !option ? "" : option.value);
                                        }
                                    }}
                                />
                            </div>
                            {hasError && <FieldError error={form.errors[field.name]} />}
                        </div>
                    );
                }}
            </Field>
        );
    }
}

export default Select;
