import React, { Component } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

class MessageActions extends Component {
    static propTypes = {
        communication: shape({
            direction: string,
            idCommunication: number,
            idFileList: arrayOf(number),
            userRead: bool,
        }).isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleRemoveClick: func.isRequired,
        index: number.isRequired,
    };

    render() {
        const { communication, handleRemoveClick, handleChangeMessageStatus, index } = this.props;

        return (
            <div className="icons">
                <div className="icon">
                    {communication.idFileList.length > 0 && (
                        <>
                            <Image src="images/ui-icons/ui-attachment.svg" />
                            <I18n id="communications.hasAttachment" componentProps={{ className: "visually-hidden" }} />
                        </>
                    )}
                </div>
                <Button
                    className="btn-only-icon read-icon"
                    block={false}
                    onClick={(e) =>
                        handleChangeMessageStatus(e, communication.idCommunication, communication.userRead, index)
                    }
                    bsStyle="circle">
                    <Image
                        src={
                            communication.userRead ? "images/ui-icons/ui-read.svg" : "images/ui-icons/ui-email-gray.svg"
                        }
                    />
                    <I18n id={communication.userRead ? "communications.unreadMessage" : "communications.readMessage"} />
                </Button>
                <Button
                    bsStyle="default"
                    label="communications.deleteMessage"
                    className="btn-only-icon btn-delete"
                    block={false}
                    onClick={(e) => handleRemoveClick(e, communication.idCommunication, index)}
                    image="images/ui-icons/ui-delete.svg"
                />
            </div>
        );
    }
}

export default MessageActions;
