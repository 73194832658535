import { get } from "./i18n";

const formatPaymentDeadline = (hours = 0) => {
    if (isNaN(hours)) { // eslint-disable-line
        return hours;
    }
    if (hours == 0) { // eslint-disable-line
        return get("investments.mutualFund.formatPaymentDeadline.withoutHours");
    }
    return `${hours} ${get("investments.mutualFund.formatPaymentDeadline.withHours")}`;
};

export default formatPaymentDeadline;
