import Movement from "pages/accounts/_components/Movement";
import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import { arrayOf, bool, func, node, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import SeuoLabel from "./SeuoLabel/SeuoLabel";

class Movements extends Component {
    static propTypes = {
        accountId: string.isRequired,
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        pageNumber: number.isRequired,
        accountCurrency: string.isRequired,
        isDesktop: bool.isRequired,
        firstFetched: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
        fetchingMoreMovements: bool.isRequired,
        moreMovements: bool.isRequired,
        renderMoreFilters: node,
        history: shape({}).isRequired,
        quickFilters: shape({}),
    };

    state = {
        showFilters: false,
    };

    static defaultProps = {
        renderMoreFilters: null,
        quickFilters: null,
    };

    fetchMoreMovements = () => {
        const { accountId, dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(accountsActions.fetchMoreMovements(accountId, filters));
    };

    handleClick = () => {
        this.fetchMoreMovements();
    };

    handleFilterClick = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    handleMovementClick = (movement) => {
        const { accountId, dispatch, history } = this.props;
        const movementAux = movement;
        movementAux.idAccount = accountId;
        dispatch(accountsActions.setSelectedMovement(movementAux));
        history.push(`/accounts/${accountId}/movement`);
    };

    renderDetails = (movement) => movement && movement.details.map((detail) => detail !== "" && <div>{detail}</div>);

    renderItem = (movement) => {
        const { accountCurrency, isDesktop, filters } = this.props;
        const hasDetails = movement && movement.details?.filter((detail) => detail !== "").length > 0;

        return isDesktop ? (
            <Table.Row role="button" onClick={() => this.handleMovementClick(movement)} key={movement.idStatement}>
                <Movement
                    accountCurrency={accountCurrency}
                    isDesktop={isDesktop}
                    movement={movement}
                    filters={filters}
                    isAccountMovement
                    hasDetails={hasDetails}
                    renderDetails={this.renderDetails}
                />
            </Table.Row>
        ) : (
            <Movement
                accountCurrency={accountCurrency}
                isDesktop={isDesktop}
                movement={movement}
                filters={filters}
                role="button"
                onClick={() => this.handleMovementClick(movement)}
                isAccountMovement
                hasDetails={hasDetails}
                renderDetails={this.renderDetails}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, renderMoreFilters, filters, quickFilters } = this.props;
        const { showFilters } = this.state;
        const primaryFilter =
            filters.selectedFilter === "all" ||
            filters.selectedFilter === "lastMonth" ||
            filters.selectedFilter === "secondLastMonth";
        const emptyFilter = !(
            filters.dateFrom ||
            filters.dateTo ||
            filters.maxAmount ||
            filters.minAmount ||
            filters.reference
        );
        const showBalance = primaryFilter || emptyFilter;

        return isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.date" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.reference" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left" className="hide-on-lg">
                            <I18n id="tableHeader.voucher" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="tableHeader.debit" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="tableHeader.credit" />
                        </Table.HeaderData>

                        {showBalance && (
                            <Table.HeaderData align="right" className="hide-on-lg">
                                <I18n id="tableHeader.balance" />
                            </Table.HeaderData>
                        )}

                        <Table.HeaderData />

                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        ) : (
            <>
                <Button
                    onClick={this.handleFilterClick}
                    bsStyle="filter"
                    className="btn-link"
                    block
                    image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                    label={!showFilters ? "accounts.filterMovements" : "accounts.filterMovements.less"}
                />
                {showFilters && renderMoreFilters}
                {quickFilters && quickFilters}
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel isTable isMobile={!isDesktop} />
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const {
            firstFetched,
            moreMovements,
            movements,
            isDesktop,
            filters,
            fetchingMoreMovements,
            pageNumber,
        } = this.props;

        const defaultFilters = {
            dateFrom: defaultDateFromList().accountsMovements.dateFrom,
            dateTo: defaultDateFromList().accountsMovements.dateTo,
            selectedFilter: "all",
            movementType: "all",
        };

        const withFilters = getFiltersStatus({ filters, defaultFilters });
        let noMoreDataText;

        if (withFilters) {
            noMoreDataText = "accounts.movements.noMoreMovements.withFilters";
        } else {
            noMoreDataText = "accounts.movements.noMoreMovements.default";
        }

        return (
            <>
                <ProductList
                    fetching={fetchingMoreMovements}
                    items={movements}
                    renderItem={this.renderItem}
                    lastPage={!moreMovements}
                    pageNumber={pageNumber}
                    onLoadMoreClick={this.handleClick}
                    firstFetched={firstFetched}
                    filters={filters}
                    loadMoreText="accounts.searchMovements"
                    noDataText="accounts.movements.noData.message"
                    noFiltersDataText="accounts.movements.filters.noData"
                    noMoreDataText={noMoreDataText}
                    isMobile={!isDesktop}
                    defaultFilters={defaultFilters}>
                    {this.renderList}
                </ProductList>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetchingMovements(state),
    fetchingMoreMovements: accountsSelectors.getFetchingMoreMovements(state),
    filters: accountsSelectors.getFilters(state),
    firstFetched: accountsSelectors.getFirstFetched(state),
    movements: accountsSelectors.getMovements(state),
    moreMovements: accountsSelectors.getMoreMovements(state),
    pageNumber: accountsSelectors.getPageNumber(state),
});

export default connect(mapStateToProps)(withRouter(Movements));
