import React, { useEffect, useState } from "react";
import { bool, string, func, shape, arrayOf, object, number } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack } from "react-router-redux";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as suppliersSelectors, actions as supplierActions } from "reducers/paymentToSuppliers";
import { actions as filterActions } from "reducers/filters";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import * as supplierUtils from "util/paymentSupplier";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import moment from "moment";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import MainContainer from "pages/_components/MainContainer";
import Loader from "pages/_components/Loader";
import Checkbox from "pages/_components/fields/Checkbox";
import SimpleModal from "components/SimpleModal/SimpleModal";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import Notification from "pages/_components/Notification";
import Dropdown from "pages/_components/Dropdown";
import ProductList from "../_components/product/List";
import Head from "../_components/Head";
import SupplierPaymentSentFilters from "./SupplierPaymentSentFilters";

const FORM_ID = "pays.supplier.list.file.query";

const SupplierPaymentFileQueryList = (props) => {
    const {
        dispatch,
        answerFiles,
        pageNumber,
        user,
        isFetching,
        isMobile,
        isLastPage,
        fileStatus,
        history,
        showDownloadModal,
        showDownloadErrorModal,
        filters,
        isResetFiltersQuery,
        isFirstFetched,
        isFetchingDownloadFiles,
    } = props;

    const { documentNumber, documentType } = user;
    const defaultDateFrom =
        fileStatus.toUpperCase() === "PROCESSED"
            ? defaultDateFromList().paymentsListSent.processed.dateFrom
            : defaultDateFromList().paymentsListSent.rejected.dateFrom;
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allBoxesChecked, setAllBoxesChecked] = useState(false);
    const [fileToDownload, setFileToDownload] = useState("");

    useEffect(() => {
        // Se redirige ya que esta funcionalidad no está para mobile
        if (isMobile) {
            history.push("/paymentSuppliers/files");
        }

        if (isResetFiltersQuery) {
            dispatch(supplierActions.resetFiltersQuery());
            dispatch(supplierActions.resetAnswerFilesData());
            dispatch(
                supplierActions.setFilterQuery({
                    dateFrom: defaultDateFrom,
                    dateTo: moment(),
                }),
            );

            dispatch(
                supplierActions.getAnswerFilesRequest(
                    {
                        docTypeClient: documentType,
                        docNumberClient: documentNumber,
                        docType: "CU",
                        dateFrom: moment(defaultDateFrom).format("YYYY-MM-DD"),
                        dateTo: moment().format("YYYY-MM-DD"),
                        filterFileType: "",
                        fileStatus,
                        pageNumber: 1,
                    },
                    true,
                ),
            );

            dispatch(supplierActions.setResetFiltersQuery(true));
        }
    }, []);

    const fetchMoreData = () => {
        dispatch(
            supplierActions.getAnswerFilesRequest(
                {
                    docTypeClient: documentType,
                    docNumberClient: documentNumber,
                    docType: "CU",
                    dateFrom: filters.dateFrom
                        ? moment(filters.dateFrom).format("YYYY-MM-DD")
                        : moment(defaultDateFrom).format("YYYY-MM-DD"),
                    dateTo: moment(filters.dateTo).format("YYYY-MM-DD"),
                    filterFileType: "",
                    pageNumber,
                    fileStatus,
                },
                false,
            ),
        );
    };

    const deleteArray = (filter, type) => {
        let newArray = [];
        if (type === "check") {
            newArray = selectedCheckboxes.filter((e, i) => i !== filter);
        } else {
            newArray = selectedFiles.filter((e, i) => i !== filter);
        }

        return newArray;
    };

    const handleChange = (event, index, longFileName) => {
        if (event.target.checked) {
            const diff = answerFiles.length - selectedCheckboxes.length;
            if (diff === 1) {
                setAllBoxesChecked(true);
            }
            setSelectedFiles([...selectedFiles, longFileName]);
            setSelectedCheckboxes([...selectedCheckboxes, index]);
        } else {
            const posFile = selectedFiles.indexOf(longFileName);
            const posIndex = selectedCheckboxes.indexOf(index);
            setSelectedCheckboxes(deleteArray(posIndex, "check"));
            setSelectedFiles(deleteArray(posFile, "files"));
            if (allBoxesChecked) {
                setAllBoxesChecked(false);
            }
        }
        if (fileToDownload) {
            setFileToDownload("");
        }
    };

    const checkAllBoxes = () => {
        if (allBoxesChecked) {
            setSelectedCheckboxes([]);
            setSelectedFiles([]);
            setAllBoxesChecked(false);
        } else {
            const selectedBoxesArray = [];
            const selectedFilesArray = [];
            answerFiles.map((file, index) => {
                selectedBoxesArray.push(index);
                selectedFilesArray.push(file.longFileName);
                return null;
            });
            setSelectedCheckboxes(selectedBoxesArray);
            setSelectedFiles(selectedFilesArray);
            setAllBoxesChecked(true);
        }
        return null;
    };

    const handleBack = () => {
        dispatch(filterActions.setResetFilters(false));
        dispatch(goBack());
    };

    const handleDownloadFile = (format, fileName) => {
        const fileType = supplierUtils.typeFileByName(fileName);

        dispatch(supplierActions.getFileContentProcessed(true, fileType, fileName, format));
    };

    const renderHeader = () => (
        <Head onBack={handleBack} showPlusSecondary accessibilityTextId="menu.investments.mutualFund" />
    );

    const renderItem = (file, index) => {
        const { longFileName, processDate, processTime } = file;

        return (
            <Table.Row additionalClassName="no-hover" key={`${longFileName + index}`}>
                <Table.Data align="left" styles={{ minWidth: "60px" }}>
                    <Checkbox
                        key={`${longFileName + index}`}
                        name={`${longFileName + index}`}
                        value={`${longFileName + index}`}
                        label=""
                        checked={selectedCheckboxes.includes(index)}
                        onChange={(e) => handleChange(e, index, longFileName)}
                        hideLabel
                    />
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{longFileName}</span>
                </Table.Data>
                <Table.Data align="left">
                    <FormattedDate date={processDate} />
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{processTime.slice(0, -3)}</span>
                </Table.Data>
                <Table.Data align="center">
                    <Button
                        image="images/ui-icons/ui-eye-big.svg"
                        label={`${FORM_ID}.button.see.label`}
                        onClick={() => {
                            dispatch(supplierActions.setFetchingSupplier());
                            dispatch(supplierActions.seeDetailFromService(file));
                            dispatch(supplierActions.setComeFromProcessed(true));
                            dispatch(supplierActions.setResetFiltersQuery(false));
                            setFileToDownload(longFileName);
                        }}
                        bsStyle="outline"
                        className="btn-small"
                        loading={isFetching && fileToDownload === longFileName}
                    />
                </Table.Data>
                <Table.Data align="right">
                    <div className="ui-dflex-end">
                        <Dropdown
                            image="images/download.svg"
                            label={`${FORM_ID}.button.download.label`}
                            buttonClass="btn btn-secondary-outline btn-small"
                            fetching={isFetchingDownloadFiles && fileToDownload === longFileName}
                            pullRight>
                            <Button
                                label={`${FORM_ID}.button.download.type.dat.label`}
                                className="dropdown__item-btn"
                                onClick={() => {
                                    setFileToDownload(longFileName);
                                    handleDownloadFile("txt", longFileName);
                                }}
                                bsStyle="link"
                            />
                            <Button
                                label={`${FORM_ID}.button.download.type.xls.label`}
                                className="dropdown__item-btn"
                                onClick={() => {
                                    setFileToDownload(longFileName);
                                    handleDownloadFile("xls", longFileName);
                                }}
                                bsStyle="link"
                            />
                        </Dropdown>
                    </div>
                </Table.Data>
            </Table.Row>
        );
    };

    const renderList = (list, renderLoadMore) => (
        <>
            <Table className="gridTable">
                <Table.Header>
                    <Table.HeaderData align="left">
                        <Checkbox
                            key="checkAll"
                            name="checkAll"
                            value="ALL"
                            label=""
                            checked={allBoxesChecked}
                            onChange={checkAllBoxes}
                            hideLabel
                        />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.header.nameFile.label`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.header.dateOfProcess.label`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.header.time.label`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="left" />
                    <Table.HeaderData align="left" />
                </Table.Header>
                <Table.Body>{list}</Table.Body>
            </Table>
            <SeuoLabel />
            <div className="ui-dflex">
                <Button
                    label={`${FORM_ID}.button.downloadSelection.label`}
                    image="images/ui-icons/ui-download.svg"
                    onClick={() => {
                        dispatch(supplierActions.downloadFileFromService(selectedFiles, "MULTIDOWNLOAD"));
                        setSelectedCheckboxes([]);
                        setSelectedFiles([]);
                    }}
                    bsStyle="outline"
                    loading={isFetchingDownloadFiles && fileToDownload === ""}
                />
            </div>
            {renderLoadMore()}
        </>
    );

    const defaultFilters = {
        dateFrom:
            fileStatus.toUpperCase() === "PROCESSED"
                ? defaultDateFromList().paymentsListSent.processed.dateFrom
                : defaultDateFromList().paymentsListSent.rejected.dateFrom,
        dateTo: moment(),
    };

    const withFilters = getFiltersStatus({ filters, defaultFilters });

    let noMoreDataText;
    const noDataText = `${FORM_ID}.${fileStatus}.empty.label`;

    if (withFilters) {
        noMoreDataText = `${FORM_ID}.${fileStatus}.noMoreData.withFilters`;
    } else {
        noMoreDataText = `${FORM_ID}.${fileStatus}.noMoreData.default`;
    }

    return (
        <>
            <Notification scopeToShow="supplierPaymentQueryList" />
            {renderHeader()}
            <MainContainer>
                <div className="title-left-header">
                    <I18n id={`${FORM_ID}.${fileStatus}.title.label`} component="h1" />
                    <I18n id={`${FORM_ID}.${fileStatus}.subtitle.label`} component="h2" />
                </div>
                <div className="container-left">
                    {isFetching && isFirstFetched ? (
                        <Loader />
                    ) : (
                        <>
                            <SupplierPaymentSentFilters
                                isFetching={isFetching}
                                defaultDateFrom={defaultDateFrom}
                                fileStatus={fileStatus}
                            />
                            <ProductList
                                fetching={isFetching}
                                items={answerFiles}
                                renderItem={renderItem}
                                lastPage={isLastPage}
                                filters={filters}
                                firstFetched={isFirstFetched}
                                loadMoreText={`${FORM_ID}.button.paginated.seeMore.label`}
                                noDataText={noDataText}
                                noMoreDataText={noMoreDataText}
                                noFiltersDataText={`${FORM_ID}.filter.noMoreFile.label`}
                                onLoadMoreClick={() => fetchMoreData()}
                                defaultFilters={defaultFilters}
                                pageNumber={pageNumber}
                                isMobile={isMobile}
                                listMessagesParams={{
                                    noMoreDataText: {
                                        DAYS:
                                            fileStatus === "PROCESSED"
                                                ? config.get("payment.supplier.default_days.search")
                                                : config.get("default_days.Search_process.Transfer"),
                                    },
                                    noDataText: {
                                        DAYS:
                                            fileStatus === "PROCESSED"
                                                ? config.get("payment.supplier.default_days.search")
                                                : config.get("default_days.Search_process.Transfer"),
                                    },
                                }}>
                                {renderList}
                            </ProductList>
                        </>
                    )}
                </div>
            </MainContainer>

            {showDownloadModal && (
                <SimpleModal
                    modalIcon="images/success.svg"
                    showActions={false}
                    closeModal={() => dispatch(supplierActions.setDownloadModal(false))}
                    isDisplayed
                    modalMainText="forms.payments.suppliers.downloadProcessedModal.label"
                />
            )}

            {showDownloadErrorModal && (
                <SimpleModal
                    modalIcon="images/warningIcon.svg"
                    showActions={false}
                    closeModal={() => dispatch(supplierActions.setDownloadErrorModal(false))}
                    isDisplayed
                    modalMainText="forms.payments.suppliers.downloadProcessedErrorModal.label"
                />
            )}
        </>
    );
};
SupplierPaymentFileQueryList.propTypes = {
    dispatch: func.isRequired,
    pageNumber: number.isRequired,
    isFetching: bool.isRequired,
    isMobile: bool.isRequired,
    isLastPage: bool.isRequired,
    user: shape({}).isRequired,
    answerFiles: arrayOf(object).isRequired,
    fileStatus: string.isRequired,
    history: shape({}).isRequired,
    showDownloadModal: bool.isRequired,
    filters: shape({}).isRequired,
    showDownloadErrorModal: bool.isRequired,
    isResetFiltersQuery: bool.isRequired,
    isFirstFetched: bool.isRequired,
    isFetchingDownloadFiles: bool.isRequired,
};

const mapStateToProps = (state, otherProps) => ({
    currentLang: i18nSelectors.getLang(state),
    user: sessionSelectors.getUser(state),
    answerFiles: suppliersSelectors.getAnswerFiles(state),
    pageNumber: suppliersSelectors.getPageNumber(state),
    isLastPage: suppliersSelectors.isLastPage(state),
    fileStatus: otherProps.match.params.fileStatus.toUpperCase(),
    isFetching: suppliersSelectors.isFetching(state),
    showDownloadModal: suppliersSelectors.getShowDownloadModal(state),
    filters: suppliersSelectors.getFiltersQuery(state),
    showDownloadErrorModal: suppliersSelectors.getShowDownloadErrorModal(state),
    isResetFiltersQuery: suppliersSelectors.isResetFiltersQuery(state),
    isFirstFetched: suppliersSelectors.getFirstFetching(state),
    isFetchingDownloadFiles: suppliersSelectors.getFetchingDownloadFiles(state),
});

export default compose(connect(mapStateToProps))(SupplierPaymentFileQueryList);
