import React, { Component } from "react";
import { array, shape, bool, string, func, oneOfType, objectOf, any } from "prop-types";
import flowRight from "lodash/flowRight";
import formField from "pages/forms/_components/_fields/_commons/formField";
import FormattedAmount from "pages/_components/FormattedAmount";
import withFocus from "pages/_components/withFocus";
import ProductTag from "pages/_components/ProductTag";
import Select from "pages/forms/_components/_fields/Select";

class Productselector extends Component {
    static propTypes = {
        data: shape({ options: array }).isRequired,
        editing: bool.isRequired,
        value: oneOfType([
            string,
            shape({
                isFrequentDestination: bool,
                value: string,
            }),
        ]).isRequired,
        toggleIsFocused: func.isRequired,
        mode: string.isRequired,
        isRequired: bool.isRequired,
        setValue: func.isRequired,
        idField: string.isRequired,
        accountSelected: string,
        field: objectOf(any).isRequired,
        labelBackoffice: string,
        handleChange: func.isRequired,
        disabled: bool,
        triggerHandlerChange: bool,
    };

    static defaultProps = {
        accountSelected: "",
        disabled: false,
        triggerHandlerChange: true,
        labelBackoffice: "",
    };

    state = {
        data: this.props.data,
    };

    componentDidMount() {
        const { mode, isRequired, data, accountSelected, triggerHandlerChange } = this.props;
        let { value } = this.props;

        if (mode === "edit") {
            if (!value && !isRequired) {
                value = "_empty";
            }
            if (!value && data?.options?.length) {
                if (accountSelected) {
                    this.handleChange({ id: accountSelected });
                }
            } else if (value) {
                // es value.value si es un campo cargado con setFieldValue (dado que el valor es
                // {value: xxx, isFrequestDestination: bool}) si es un valor que viene cargado por url,
                // el value es directamente el id de la cuenta es así hasta que eliminemos el
                // isFrequestDestination y esto se resuelva de otra forma (con value solito :P)
                if (triggerHandlerChange) {
                    this.handleChange({ id: value.value || value });
                }
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.data?.options && props.data?.options && props.data !== state.data) {
            return {
                ...state,
                data: props.data,
            };
        }
        return { ...state };
    }

    handleChange = ({ id }) => {
        const { setValue, handleChange } = this.props;
        if (id) {
            setValue(id);
        }
        if (handleChange) {
            handleChange(id);
        }
    };

    buildProductOptions = (originalOptions) => {
        const { isRequired } = this.props;

        // \u00A0 es un character especial para forzar un espacio en blanco
        const options = isRequired
            ? originalOptions
            : [{ id: "_empty", isFrequentDestination: false, label: "\u00A0" }, ...originalOptions];
        return options.map((elem) => {
            if (elem.balance) {
                return {
                    id: elem.id,
                    label: (
                        <>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </>
                    ),
                };
            }
            return {
                id: elem.id,
                label: elem.label,
            };
        });
    };

    render() {
        const {
            editing,
            value,
            toggleIsFocused,
            idField,
            accountSelected,
            field,
            labelBackoffice,
            disabled,
        } = this.props;
        const productId = value || accountSelected;
        const {
            data: { options },
        } = this.state;

        if (editing) {
            const newOptions = this.buildProductOptions(options);

            return (
                <div className="input-group ">
                    <div style={{ flex: 1 }}>
                        <Select
                            id={idField}
                            value={productId}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="label"
                            options={newOptions}
                            onFocus={toggleIsFocused}
                            onBlur={toggleIsFocused}
                            className="flex-container slideFromBottom"
                            optionClassName="needsclick"
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
        }
        const { label } = options.find(({ id }) => id === productId) || {};
        return (
            <>
                <ProductTag alias={label || labelBackoffice || field?.value} />
            </>
        );
    }
}

export default flowRight(withFocus, formField())(Productselector);
