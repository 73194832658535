import AccordionItem from "pages/_components/AccordionItem/AccordionItem";
import { func, shape } from "prop-types";
import React, { Component } from "react";
import { actions as accountsActions } from "reducers/accounts";
import Statement from "./Statement";
import "./_Statement.scss";

class StatementsAccordion extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        statementsByYear: shape({}).isRequired,
    };

    handleOnChange = (e) => {
        const { dispatch } = this.props;

        dispatch(accountsActions.listStatementLines(e.id, e));
    };

    render() {
        const { statementsByYear } = this.props;

        return (
            <div className="container">
                {Object.keys(statementsByYear)
                    .map((year) => (
                        <AccordionItem title={year} key={`year-${year}`}>
                            {Object.values(statementsByYear[year]).map((statement) => (
                                <Statement statement={statement} />
                            ))}
                        </AccordionItem>
                    ))
                    .reverse()}
            </div>
        );
    }
}

export default StatementsAccordion;
