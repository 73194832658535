import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";

import React from "react";
import FormRender from "pages/forms/_components/FormRender";

import AccountOpeningForm from "./AccountOpeningForm";
import AccountClosingForm from "./AccountClosingForm";
import CancelDepositRenewal from "./CancelDepositRenewal";
import CreateFixedTermDepositForm from "./CreateFixedTermDepositForm";
import CreateFrequentRecipient from "./CreateFrequentRecipient";
import EnrollmentQRForm from "./EnrollmentQRForm";
import InvesterProfileForm from "./InvesterProfileForm";
import PaymentToSuppliers from "./PaymentToSuppliers";
import RequestChangePersonalData from "./RequestChangePersonalData";
import RequestCheckbookForm from "./RequestCheckbookForm";
import RequestInvestmentRescueForm from "./RequestInvestmentRescueForm";
import RequestInvestmentSubscribeForm from "./RequestInvestmentSubscribeForm";
import SetCBUAlias from "./SetCBUAlias";
import ShareholderAccountOpeningForm from "./ShareholderAccountOpeningForm";
import TransferInternalForm from "./TransferInternalForm";
import TransferLocalForm from "./TransferLocalForm";
import TransferMassive from "./TransferMassive";
import TransferThirdParties from "./TransferThirdParties";

import AcceptCessionForm from "./echeqs/AcceptCessionForm";
import AcceptEcheqForm from "./echeqs/AcceptEcheqForm";
import AcceptEcheqTermsConditionsForm from "./echeqs/AcceptEcheqTermsConditionsForm";
import AcceptMandateNegotiationOrPayment from "./echeqs/AcceptMandateNegotiationOrPayment";
import AcceptReturnOrAgreement from "./echeqs/AcceptReturnOrAgreement";
import AccountActivationForm from "./echeqs/EnableOrDisableAccountsToIssueECheq";
import AdmitGuaranteeRequest from "./echeqs/AdmitGuaranteeRequest";
import CancelEcheqForm from "./echeqs/CancelEcheqForm";
import CancelEndorsement from "./echeqs/CancelEndorsement";
import CancelGuaranteeRequest from "./echeqs/CancelGuaranteeRequest";
import CancelMandateForNegotiation from "./echeqs/CancelMandateForNegotiation";
import CancelMandatePaymentRequest from "./echeqs/CancelMandatePaymentRequest";
import CancelReturnOrAgreementForm from "./echeqs/CancelReturnOrAgreementForm";
import CustodyRequest from "./echeqs/CustodyRequest";
import DepositEcheq from "./echeqs/DepositEcheq";
import EmitEcheqForm from "./echeqs/emitEcheq/EmitEcheqForm";
import EmitRejectionCertificateForm from "./echeqs/EmitRejectionCertificateForm";
import EndorsementRequest from "./echeqs/EndorsementRequest";
import GiveEcheq from "./echeqs/GiveEcheq";
import RejectReturnRequestOrAgreement from "./echeqs/RejectReturnRequestOrAgreement";
import RepudiateEcheqForm from "./echeqs/RepudiateEcheqForm";
import RepudiateOrCancelGiveEcheq from "./echeqs/RepudiateOrCancelGiveEcheq";
import RequestGuarantee from "./echeqs/RequestGuarantee";
import RequestMandateForNegotiation from "./echeqs/RequestMandateForNegotiation";
import RequestPaymentMandateForm from "./echeqs/RequestPaymentMandateForm";
import RequestReturnOrAgreementForm from "./echeqs/RequestReturnOrAgreementForm";
import RescueFromCustodyForm from "./echeqs/RescueFromCustodyForm";
import RevokeMandateNegotiationOrPayment from "./echeqs/RevokeMandateNegotiationOrPayment";

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    metadata: formSelectors.getMetadata(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    fromBackoffice: true,
});

class BackofficeTransactionCustom extends React.Component {
    formMap = {
        "account.delete.cbuAlias": <SetCBUAlias {...this.props} />,
        "account.qr.enrollment.send": <EnrollmentQRForm {...this.props} />,
        "account.requestOpening.send": <AccountOpeningForm {...this.props} />,
        "account.requestClosing.send": <AccountClosingForm {...this.props} />,
        "account.set.cbuAlias": <SetCBUAlias {...this.props} />,
        "fixedTermDeposit.create.send": <CreateFixedTermDepositForm {...this.props} />,
        "fixedTermDeposits.cancelRenewal.send": <CancelDepositRenewal {...this.props} />,
        "frequentDestination.create.send": <CreateFrequentRecipient {...this.props} />,
        "investments.investerProfileForm.send": <InvesterProfileForm {...this.props} />,
        "investments.shareholderAccountOpening.send": <ShareholderAccountOpeningForm {...this.props} />,
        "pay.transfers.massive.send": <TransferMassive {...this.props} />,
        "pays.supplier.check.echeq.send": <PaymentToSuppliers {...this.props} />,
        "pays.supplier.transfer.send": <PaymentToSuppliers {...this.props} />,
        "pays.supplier.withholdings.send": <PaymentToSuppliers {...this.props} />,
        "request.changePersonalData.send": <RequestChangePersonalData {...this.props} />,
        "request.checkbook.send": <RequestCheckbookForm {...this.props} />,
        "request.investment.rescue.send": <RequestInvestmentRescueForm {...this.props} />,
        "request.investment.subscribe.send": <RequestInvestmentSubscribeForm {...this.props} />,
        "transfers.internal.send": <TransferInternalForm {...this.props} />,
        "transfers.local.send": <TransferLocalForm {...this.props} />,
        "transfers.thirdParties.send": <TransferThirdParties {...this.props} />,

        "echeqs.acceptCession.send": <AcceptCessionForm {...this.props} />,
        "echeqs.acceptEcheq.send": <AcceptEcheqForm {...this.props} />,
        "echeqs.acceptMandateNegotiation.send": <AcceptMandateNegotiationOrPayment {...this.props} />,
        "echeqs.acceptMandatePayment.send": <AcceptMandateNegotiationOrPayment {...this.props} />,
        "echeqs.acceptReturnOrAgreement.send": <AcceptReturnOrAgreement {...this.props} />,
        "echeqs.acceptTermsAndConditions.send": <AcceptEcheqTermsConditionsForm {...this.props} />,
        "echeqs.admitGuaranteeRequest.send": <AdmitGuaranteeRequest {...this.props} />,
        "echeqs.cancelEcheq.send": <CancelEcheqForm {...this.props} />,
        "echeqs.cancelEndorsement.send": <CancelEndorsement {...this.props} />,
        "echeqs.cancelReturnOrAgreement.send": <CancelReturnOrAgreementForm {...this.props} />,
        "echeqs.depositEcheq.send": <DepositEcheq {...this.props} />,
        "echeqs.emitEcheq.send": <EmitEcheqForm {...this.props} />,
        "echeqs.emitRejectionCertificate.send": <EmitRejectionCertificateForm {...this.props} />,
        "echeqs.endorseRequest.send": <EndorsementRequest {...this.props} />,
        "echeqs.giveEcheq.send": <GiveEcheq {...this.props} />,
        "echeqs.managementAccounts.activateOrDeactivate.send": <AccountActivationForm {...this.props} />,
        "echeqs.negateGuaranteeRequest.send": <CancelGuaranteeRequest {...this.props} />,
        "echeqs.negateMandateForNegotiation.send": <CancelMandateForNegotiation {...this.props} />,
        "echeqs.negateMandatePaymentRequest.send": <CancelMandatePaymentRequest {...this.props} />,
        "echeqs.putIntoCustody.send": <CustodyRequest {...this.props} />,
        "echeqs.rejectReturnRequestOrAgreement.send": <RejectReturnRequestOrAgreement {...this.props} />,
        "echeqs.repudiateEcheq.send": <RepudiateEcheqForm {...this.props} />,
        "echeqs.repudiateGuaranteeRequest.send": <CancelGuaranteeRequest {...this.props} />,
        "echeqs.repudiateMandateForNegotiation.send": <CancelMandateForNegotiation {...this.props} />,
        "echeqs.repudiateMandatePaymentRequest.send": <CancelMandatePaymentRequest {...this.props} />,
        "echeqs.repudiateOrCancelGiveEcheq.send": <RepudiateOrCancelGiveEcheq {...this.props} />,
        "echeqs.requestGuarantee.send": <RequestGuarantee {...this.props} />,
        "echeqs.requestMandate.payment.send": <RequestPaymentMandateForm {...this.props} />,
        "echeqs.requestMandateForNegotiation.send": <RequestMandateForNegotiation {...this.props} />,
        "echeqs.requestReturnOrAgreement.send": <RequestReturnOrAgreementForm {...this.props} />,
        "echeqs.rescueFromCustody.send": <RescueFromCustodyForm {...this.props} />,
        "echeqs.revokeMandateNegotiation.send": <RevokeMandateNegotiationOrPayment {...this.props} />,
        "echeqs.revokeMandatePayment.send": <RevokeMandateNegotiationOrPayment {...this.props} />,
    };

    render() {
        /* eslint-disable react/prop-types */
        const { transaction, fetching, mode } = this.props;
        const { idActivity } = transaction;
        /* eslint-enable react/prop-types */
        if (!fetching && transaction && this.formMap[idActivity] !== undefined && mode === "view") {
            return this.formMap[idActivity];
        }
        return <FormRender {...this.props} />;
    }
}

export default connect(mapStateToProps)(BackofficeTransactionCustom);
