import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import Select from "react-select";
import { compose } from "redux";
import classNames from "classnames";
import { bool, string, number, func, arrayOf, shape, oneOfType } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { getInteger } from "util/config";
import { countDecimalPlaces, numberFormat } from "util/number";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";

export class AmountField extends Component {
    static propTypes = {
        clearable: bool,
        disableSelect: bool,
        hideLabel: bool,
        hideCurrency: bool,
        labelText: string,
        idForm: string,
        name: string.isRequired,
        optional: string,
        isFocused: bool.isRequired,
        hasError: bool,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        toggleIsFocused: func.isRequired,
        onCurrencyChange: func.isRequired,
        onInputChange: func.isRequired,
        onBlur: func.isRequired,
        size: string,
        error: string,
        currency: string,
        amount: oneOfType([string, number]),
        maxLength: string,
        data: shape({
            options: arrayOf(
                shape({
                    id: number.isRequired,
                    label: string.isRequired,
                }),
            ),
        }),
        field: shape({}),
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        hideLabel: false,
        hideCurrency: false,
        labelText: null,
        idForm: "",
        size: "",
        error: "",
        optional: "",
        hasError: false,
        amount: "",
        currency: "",
        maxLength: "20",
        data: {},
        field: {},
    };

    renderLabel = () => {
        const { idForm, name, labelText, optional } = this.props;
        const id = `${idForm}.${name}`;
        if (labelText !== null) {
            return <FieldLabel labelText={labelText} optional={optional} idField={id} />;
        }
        return <FieldLabel labelKey={`${idForm}.${name}.label`} optional={optional} idField={id} />;
    };

    render() {
        /* eslint-disable react/prop-types */
        const {
            field,
            idForm,
            clearable,
            data,
            disableSelect,
            hideLabel,
            isFocused,
            lang,
            maximumDecimals,
            minimumDecimals,
            toggleIsFocused,
            hideCurrency,
            size,
            hasError,
            onCurrencyChange,
            onInputChange,
            currency,
            amount,
            maxLength,
            onBlur,
            error,
        } = this.props;
        /* eslint-enable react/prop-types */

        let options;
        if (!hideCurrency) {
            ({ options } = data);
        }
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const decimalPlaces = this.amountRef ? countDecimalPlaces(this.amountRef.value, decimalSeparator) : 0;
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
        const idField = `${idForm}.${field.name}`;

        return (
            <div
                className={classNames("form-group", "form-group--composite", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                    "form-group--small": size === "small",
                })}
                onFocus={toggleIsFocused}>
                {!hideLabel && this.renderLabel()}
                <div className="input-group">
                    {!hideCurrency && options.length === 1 ? (
                        <span className="currency">{options[0].label}</span>
                    ) : (
                        !hideCurrency && (
                            <Select
                                clearable={clearable}
                                className="currency-selector slideFromBottom flex-container"
                                disabled={disableSelect}
                                onChange={onCurrencyChange}
                                options={options.map(({ id, label }) => ({ value: id, label }))}
                                searchable={false}
                                value={currency}
                                onBlur={() => {
                                    toggleIsFocused();
                                    onBlur();
                                }}
                                optionClassName="needsclick"
                            />
                        )
                    )}
                    <NumberFormat
                        name={field.name}
                        id={idField}
                        allowNegative={false}
                        className="form-control"
                        decimalScale={decimalScale}
                        decimalSeparator={decimalSeparator}
                        maxLength={maxLength}
                        onChange={onInputChange}
                        thousandSeparator={thousandSeparator}
                        type="text"
                        onBlur={() => {
                            toggleIsFocused();
                            onBlur();
                        }}
                        value={amount}
                    />
                </div>
                {hasError && <FieldError error={error} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maximumDecimals: getInteger("defaultDecimal.maximum"),
    minimumDecimals: getInteger("defaultDecimal.minimum"),
});

export default compose(connect(mapStateToProps), withFocus)(AmountField);
