import React, { Component, Fragment } from "react";
import { string, number, bool } from "prop-types";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { countDecimalPlaces, numberFormat } from "util/number";

class FormattedAmount extends Component {
    static propTypes = {
        className: string,
        frequencyInSpan: bool,
        currency: string,
        frequency: string,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        customMinimumDecimals: number,
        customMaximumDecimals: number,
        quantity: number,
        showAbbreviature: bool,
        hideCurrency: bool,
        species: string,
    };

    static defaultProps = {
        className: "data-amount",
        frequencyInSpan: true,
        frequency: "",
        showAbbreviature: false,
        quantity: 0,
        customMinimumDecimals: null,
        customMaximumDecimals: null,
        currency: configUtils.get("core.masterCurrency"),
        hideCurrency: false,
        species: null,
    };

    getAmountDetails = (quantity) => {
        const { showAbbreviature } = this.props;
        const million = 1000000;

        if (showAbbreviature) {
            if (quantity >= million) {
                return {
                    quantity: quantity / million,
                    abbreviature: i18n.get("formattedAmount.millionAbbreviature"),
                };
            }
        }

        return {
            quantity,
            abbreviature: "",
        };
    };

    renderText = (formattedValue) => {
        const { className, frequencyInSpan, currency, frequency, hideCurrency, species } = this.props;
        const speciesString = species ? `${species} ` : "";
        const currencyToShow = currency ? `${i18n.get(`currency.label.${currency}`)}` : "";
        return (
            <Fragment>
                <span className={className}>
                    <span className="data-amount-currency">
                        {!hideCurrency ? `${speciesString}${currencyToShow}${formattedValue}` : formattedValue}
                    </span>

                    {frequencyInSpan && <span> {frequency}</span>}
                </span>
                {!frequencyInSpan && <span className="data-desc">{frequency}</span>}
            </Fragment>
        );
    };

    render() {
        const {
            quantity,
            maximumDecimals,
            minimumDecimals,
            customMinimumDecimals,
            customMaximumDecimals,
            lang,
        } = this.props;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);

        const amount = this.getAmountDetails(quantity);
        let decimalPlaces;
        let decimalScale;

        if (amount.abbreviature.length > 0) {
            decimalScale = 1;
        } else {
            decimalPlaces = countDecimalPlaces(quantity, decimalSeparator);
            decimalScale = Math.max(
                Math.min(decimalPlaces, customMaximumDecimals ?? maximumDecimals),
                customMinimumDecimals ?? minimumDecimals,
            );
        }
        return (
            <NumberFormat
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={decimalScale}
                value={amount.quantity}
                displayType="text"
                renderText={(formattedValue) => this.renderText(formattedValue)}
                suffix={amount.abbreviature}
                fixedDecimalScale
            />
        );
    }
}

const mapStateToProps = (state) => ({
    maximumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.minimum"] || 0),
    lang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(FormattedAmount);
