import Button from "pages/_components/Button";
import AccountQRModal from "pages/accounts/AccountQRModal";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as qrActions, selectors as qrSelectors } from "reducers/qr";
import * as i18n from "util/i18n";

const QrAccountButton = ({
    dispatch,
    account,
    showQRmodal,
    isDesktop,
    fetchingDownloadQR,
    showToolTip,
    size,
    asIcon,
    additionalClassName,
}) => {
    const handleShowQR = () => {
        dispatch(qrActions.getQrAccountRequest(account.cbu, account.idProduct));
    };

    const handleHideQR = () => {
        dispatch(qrActions.hideQrModal());
    };

    const tooltipProps = isDesktop && {
        "data-tooltip": i18n.get("forms.account.qr.download.showqr.description"),
        "data-tooltip-position": "bottom",
    };

    const getClassName = () => {
        let className = "";

        if (size === null) {
            className = isDesktop ? "btn-small" : "ui-mt-7";
        } else if (size === "small") {
            className = "btn-small";
        }

        return className;
    };

    const renderButtonOrIcon = () => (
        <>
            {asIcon && (
                <Button
                    className={`btn-only-icon ${additionalClassName}`}
                    image="images/ui-icons/ui-qr-button-gray.svg"
                    onClick={account?.productType === "CC" ? handleShowQR : null}
                    loading={fetchingDownloadQR}
                    label="forms.account.qr.download.showqr"
                />
            )}
            {!asIcon && (
                <>
                    <Button
                        onClick={account?.productType === "CC" ? handleShowQR : null}
                        bsStyle="secondary"
                        loading={fetchingDownloadQR}
                        image="images/ui-icons/ui-qr.svg"
                        label="forms.account.qr.download.showqr"
                        className={`${getClassName()} ${additionalClassName}`}
                        block={!isDesktop}
                        {...(showToolTip ? tooltipProps : null)}
                    />
                    {!isDesktop && showToolTip && (
                        <div className="ui-mt-3 ui-mb-8">
                            <div className="ui-body-small">
                                *{i18n.get("forms.account.qr.download.showqr.description")}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );

    return (
        <>
            {renderButtonOrIcon()}

            <AccountQRModal isVisible={showQRmodal} onHide={handleHideQR} cbuAccount={account?.cbu || ""} />
        </>
    );
};

QrAccountButton.propTypes = {
    dispatch: func.isRequired,
    account: shape({}),
    showQRmodal: bool.isRequired,
    isDesktop: bool.isRequired,
    fetchingDownloadQR: bool.isRequired,
    showToolTip: bool,
    size: string,
    asIcon: bool,
    additionalClassName: string,
};

QrAccountButton.defaultProps = {
    account: null,
    showToolTip: true,
    size: null,
    asIcon: true,
    additionalClassName: "",
};

const mapStateToProps = (state) => ({
    showQRmodal: qrSelectors.showQRmodal(state),
    fetchingDownloadQR: qrSelectors.getFetchingDownloadQR(state),
});

export default connect(mapStateToProps)(QrAccountButton);
