import React, { Component } from "react";
import { Collapse as BSCollapse } from "react-bootstrap";
import { string, oneOfType, element, arrayOf, bool } from "prop-types";

import Button from "pages/_components/Button";

class Collapse extends Component {
    static propTypes = {
        buttonClassName: string,
        buttonLabelClosed: string,
        buttonLabelOpened: string,
        children: oneOfType([element, arrayOf(element)]).isRequired,
        showArrow: bool,
    };

    static defaultProps = {
        buttonClassName: "btn-small",
        buttonLabelClosed: "",
        buttonLabelOpened: "",
        showArrow: false,
    };

    state = {
        open: false,
    };

    handleClick = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    render() {
        const { open } = this.state;
        const { buttonClassName, buttonLabelClosed, buttonLabelOpened, showArrow, children } = this.props;

        const arrowImage = open ? "selectArrowUpBlack.svg" : "selectArrowDown.svg";

        return (
            <>
                <Button
                    label={open ? buttonLabelOpened : buttonLabelClosed}
                    onClick={this.handleClick}
                    bsStyle="link"
                    className={buttonClassName}
                    aria-haspopup
                    aria-expanded={open}
                    image={showArrow ? `images/${arrowImage}` : ""}
                />
                <BSCollapse in={open}>
                    <div style={{ width: "100%" }}>{children}</div>
                </BSCollapse>
            </>
        );
    }
}

export default Collapse;
