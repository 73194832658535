import React, { Component } from "react";
import { number, string } from "prop-types";
import cn from "classnames";

class Badge extends Component {
    static propTypes = {
        count: number.isRequired,
        className: string,
    };

    static defaultProps = {
        className: undefined,
    };

    render() {
        const { count, className } = this.props;

        if (count === 0) {
            return null;
        }
        const componentClassName = cn("badge", className);
        return (
            <div className={componentClassName}>
                <span>{count > 99 ? "+99" : count}</span>
            </div>
        );
    }
}

export default Badge;
