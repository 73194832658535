import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import { bool, func, shape, string } from "prop-types";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as settingsActions } from "reducers/settings";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { Mixpanel } from "util/clickstreaming";
import { selectors as i18nSelectors } from "reducers/i18n";

const FORM_ID = "enrollment.index";

class Index extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
        activeLanguage: string.isRequired,
        match: shape({}).isRequired,
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    componentDidMount = () => {
        const { match, dispatch, activeLanguage } = this.props;
        const linkLang = match.params.lang;
        if (linkLang !== undefined && linkLang !== null && linkLang !== activeLanguage) {
            dispatch(settingsActions.changeLanguage(linkLang, false));
        }
    };

    render() {
        const { isSubmitting } = this.props;
        const isDigitalEnrollmentEnabled = configUtils.getBoolean("enrollment.digital.automatic");

        return (
            <div className="container">
                <div className="view-page">
                    <div className="title-header ui-mb-7">
                        <I18n id={`${FORM_ID}.header`} component="h1" />
                    </div>
                    <Form className="form-content">
                        <div className="pre-form-info">
                            <div className="pre-form-info__text">
                                <I18n
                                    id="enrollment.index.title"
                                    component="h4"
                                    componentProps={{ className: "ui-fw-regular" }}
                                />
                            </div>
                        </div>
                        <Field
                            autoComplete="off"
                            component={MaskedTextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            mask={maskUtils.invitationCodeMask()}
                            maxLength={50}
                            name="invitationCode"
                        />
                        {isDigitalEnrollmentEnabled && (
                            <Button
                                block
                                bsStyle="primary"
                                label="global.continue"
                                loading={isSubmitting}
                                type="submit"
                            />
                        )}
                    </Form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentData: onboardingSelectors.getDocumentData(state),
    activeLanguage: i18nSelectors.getLang(state),
    region: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            invitationCode: "",
        }),
        validationSchema: () => {
            // TODO - Mariva : Agregar parametrizacion de cantidad de caracteres por grupo (4)
            const groups = configUtils.get("validationCodes.id.groups.length");
            return Yup.object().shape({
                invitationCode: Yup.string()
                    .matches(
                        new RegExp(`^([a-zA-Z\\d]{4}(-[a-zA-Z\\d]{4}){${groups - 1}})$`, "g"),
                        i18nUtils.get("enrollment.index.invitationCode.invalidFormat"),
                    )
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty")),
            });
        },
        handleSubmit: ({ invitationCode }, formikBag, region) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.verifyInvitationCode(invitationCode, formikBag, region));
            Mixpanel.track(FORM_ID);
        },
    }),
)(Index);
