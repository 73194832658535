import React, { Component, Fragment } from "react";
import { Field, withFormik } from "formik";
import { compose } from "redux";
import { bool, string, func, oneOf, shape, arrayOf } from "prop-types";
import { replace } from "react-router-redux";
import cloneDeep from "lodash/cloneDeep";
import queryString from "query-string";
import moment from "moment";
import { routerActions } from "react-router-redux/actions";

import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as formActions } from "reducers/form";
import { actions as templateActions } from "reducers/template";
import { isVisible, isRequired } from "util/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import { Mixpanel } from "util/clickstreaming";

import FormActions from "pages/forms/_components/FormActions";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import mapProps from "pages/_components/mapProps";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import TransactionTicket from "pages/forms/_components/TransactionTicket/TransactionTicket";
import Container from "pages/_components/Container";
import TemplateList from "pages/forms/_components/TemplateList";
import TicketHead from "pages/_components/TicketHead";
import FormConfirmation from "./FormConfirmation";
import OpeningAccountStep2 from "./_regulations/OpeningAccountStep2";

class FormRender extends Component {
    static propTypes = {
        values: shape({}).isRequired,
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        currentLang: string.isRequired,
        validations: shape({}).isRequired,
        metadata: shape({}).isRequired,
        errors: shape({}).isRequired,
        handleSubmit: func.isRequired,
        mode: oneOf(["view", "edit", "edit-step2", "preview"]),
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        // tells if the form is being rendered to confirm a recently made transaction
        ticketConfirmation: bool,
        templates: arrayOf(shape()),
        history: shape({ location: shape({ pathname: String }) }).isRequired,
        match: shape({ path: String, url: String, isExact: bool }).isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        setFieldValue: func.isRequired,
        isMobile: bool,
        isDesktop: bool.isRequired,
        isCancellingTransaction: bool,
        fetching: bool,
        location: shape({ pathname: String }).isRequired,
        id: String,
        childrenTransactions: shape({}),
        parentTransaction: shape({}),
        transaction: shape({
            idTransaction: string.isRequired,
        }).isRequired,
        title: string,
        subtitle: string,
    };

    static defaultProps = {
        mode: "edit",
        fromBackoffice: false,
        ticketConfirmation: false,
        templates: [],
        isMobile: false,
        isCancellingTransaction: false,
        fetching: false,
        id: null,
        childrenTransactions: null,
        parentTransaction: null,
        title: "",
        subtitle: null,
    };

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match.url;

        if (!pathname.includes(url)) {
            dispatch(formActions.formClosed());
        }
    }

    handleClick = (action) => {
        const {
            dispatch,
            metadata: { idForm, idActivity },
            transaction,
            values,
        } = this.props;

        const { idTransaction } = transaction;

        Mixpanel.track_form(idForm, {
            action,
            idActivity,
            ...values,
        });
        switch (action) {
            case "createTemplate":
                dispatch(templateActions.createTemplate());
                break;
            case "saveDraft":
                dispatch(
                    formActions.saveDraft({
                        idForm,
                        idActivityDraft: idActivity,
                        data: values,
                        idTransaction: transaction ? idTransaction : null,
                    }),
                );
                break;
            case "cancelTransaction":
                dispatch(formActions.cancelTransactionPre({ idActivity, idForm }));
                break;
            case "modifyTransaction":
                dispatch(formActions.modifyTransaction(idTransaction));
                break;
            case "signTransaction":
                dispatch(
                    formActions.signTransactionPreview({
                        idForm,
                        idActivity,
                        idTransaction: transaction.idTransaction,
                    }),
                );
                break;
            case "downloadTicketPdf":
                dispatch(formActions.downloadTicket(idTransaction, "pdf", idForm));
                break;
            case "shareTicket":
                dispatch(formActions.shareTicket(idTransaction, "pdf", idForm));
                break;
            default:
                break;
        }
    };

    renderFields = () => {
        const {
            currentLang,
            metadata,
            validations,
            mode,
            fromBackoffice,
            transaction: { idTransaction },
            values,
        } = this.props;

        return metadata.fieldList.reduce((fields, field) => {
            if (validations[field.idField].isVisible && (mode === "view" || !field.ticketOnly)) {
                const FormField = FormFieldsComponents[field.type.charAt(0).toUpperCase() + field.type.substr(1)];
                const required = validations[field.idField].isRequired;
                const value = values[field.idField];

                if (!required && (mode === "preview" || mode === "view")) {
                    if (
                        stringUtils.isEmpty(value) ||
                        (typeof value === "object" &&
                            Object.values(value).some((element) => stringUtils.isEmpty(element)))
                    ) {
                        return fields;
                    }
                }
                return [
                    ...fields,
                    <Field
                        {...field}
                        fieldList={metadata.fieldList}
                        dateFormat={i18n.get("datepicker.format", "DD/MM/YYYY")}
                        isRequired={required}
                        key={field.idField}
                        name={field.idField}
                        component={FormField}
                        lang={currentLang}
                        mode={mode}
                        formTitle={metadata.formNameMap[currentLang]}
                        fromBackoffice={fromBackoffice}
                        idTransactionTicket={idTransaction}
                    />,
                ];
            }

            return fields;
        }, []);
    };

    renderScheduler = () => {
        const {
            currentLang,
            metadata: {
                enabledWeekDays,
                firstWorkingDate,
                maxDaysToSchedule,
                nonWorkingDays,
                programable,
                schedulable,
            },
            mode,
        } = this.props;

        const data = {
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            nonWorkingDays,
            lang: currentLang,
            mode,
            programable,
            schedulable,
        };

        return <Field component={Scheduler} data={data} name="scheduler" />;
    };

    openTemplateScreen = () => {
        const { dispatch, match, values } = this.props;

        dispatch(routerActions.push(`${match.url}/templates`, { shouldLoadForm: true }));
        dispatch(formActions.setData(values));
    };

    handleClose = () => {
        const { dispatch, location } = this.props;
        const pathname = location.pathname.match("/transaction/") ? "/transactions/list" : "/desktop";

        dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(formActions.closeConfirmation());
    };

    handleTemplateSelect = (template) => {
        const { setValues, setErrors, dispatch } = this.props;
        setValues(template);
        setErrors({});
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
    };

    renderFieldsSection = () => {
        const {
            metadata: { programable, schedulable, templatesEnabled },
            mode,
            isDesktop,
            templates,
        } = this.props;
        const isTemplatesVisibile = mode === "edit" && templatesEnabled && templates.length > 0;
        return (
            <Container className="align-items-center flex-grow" gridClassName="form-content">
                <Container.Column
                    sm={12}
                    md={isTemplatesVisibile ? 6 : 12}
                    lg={isTemplatesVisibile ? 6 : 12}
                    xl={isTemplatesVisibile ? 6 : 12}>
                    <Container.ColumnBody>
                        {!isDesktop && isTemplatesVisibile && (
                            <Button
                                className="btn-ico"
                                onClick={this.openTemplateScreen}
                                label="forms.templates.load"
                                image="images/draft.svg"
                            />
                        )}
                        {this.renderFields()}
                        {(programable || schedulable) && this.renderScheduler()}
                    </Container.ColumnBody>
                </Container.Column>
                {isDesktop && isTemplatesVisibile && (
                    <Container.Column sm={6} md={6} lg={6} xl={6}>
                        <Container.ColumnHeader title="forms.templates" />
                        <Container.ColumnBody>
                            <TemplateList onSelect={this.handleTemplateSelect} className="navigational-list" />
                        </Container.ColumnBody>
                    </Container.Column>
                )}
            </Container>
        );
    };

    renderFormContent = () => {
        const {
            mode,
            transaction,
            childrenTransactions,
            parentTransaction,
            fromBackoffice,
            ticketConfirmation,
            isSubmitting,
            metadata: { draftsEnabled, templatesEnabled, idForm },
        } = this.props;

        const isEditable =
            typeof childrenTransactions === "undefined" ||
            childrenTransactions === null ||
            childrenTransactions.length === 0;

        const actionButtons = !fromBackoffice && (
            <FormActions
                onClick={this.handleClick}
                fetching={isSubmitting}
                mode={mode}
                draftsEnabled={draftsEnabled}
                templatesEnabled={templatesEnabled}
                transaction={transaction}
                isEditable={isEditable}
                idForm={idForm}
            />
        );

        if (mode === "edit") {
            return (
                <div>
                    {this.renderFieldsSection()}
                    {actionButtons}
                </div>
            );
        }

        return (
            <TransactionTicket
                childrenTransactions={childrenTransactions}
                formActions={actionButtons}
                fromBackoffice={fromBackoffice}
                parentTransaction={parentTransaction}
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}>
                {this.renderFieldsSection()}
            </TransactionTicket>
        );
    };

    renderContent = () => {
        const { mode, values, id, metadata, currentLang, handleSubmit, setFieldValue } = this.props;
        const { idActivity } = metadata;

        let isOverdraftFlow = false;

        switch (mode) {
            case "edit":
                return (
                    <Fragment>
                        <form className={`above-the-fold ${id}`} onSubmit={handleSubmit}>
                            {this.renderFormContent()}
                        </form>
                        <CreateTemplateModal values={values} idForm={id} idActivityTemplate={idActivity} />
                    </Fragment>
                );
            case "view":
                return (
                    <>
                        <div className="above-the-fold">{this.renderFormContent()}</div>
                    </>
                );
            case "edit-step2":
                if (metadata && metadata.fieldList) {
                    isOverdraftFlow = metadata.fieldList.filter((i) => i.idField === "overdraft").length > 0;
                }
                if (isOverdraftFlow) {
                    return (
                        <OpeningAccountStep2
                            overdraft={values.overdraft}
                            accountType={values.accountType}
                            setFieldValue={setFieldValue}
                        />
                    );
                }
                return <></>;
            case "preview":
                return (
                    <FormConfirmation
                        idForm={id}
                        metadata={metadata}
                        currentLang={currentLang}
                        renderFields={this.renderFields}
                    />
                );

            default:
                return <></>;
        }
    };

    renderHeader = () => {
        const {
            mode,
            metadata: { formNameMap },
            currentLang,
            fromBackoffice,
            location,
            isMobile,
            title,
            subtitle,
            history,
        } = this.props;

        const isTicket = location.pathname.match("/transaction/") != null;

        if (!fromBackoffice) {
            if (mode !== "view" && mode !== "edit") {
                return (
                    <Head
                        accessibilityTextId={i18n.get("administration.forms.confirm.credentials")}
                        onClose={this.handleClose}
                        titleText={formNameMap[currentLang]}
                        onBack={!isMobile && this.handleBack}
                        hideMenu
                        propsClassNames="title-size"
                    />
                );
            }

            if (isTicket || (isMobile && mode === "view")) {
                return (
                    <>
                        {isMobile && (
                            <>
                                <Head
                                    onBack={history.goBack}
                                    propsClassNames="title-size"
                                    showLogo
                                    arrowWhite
                                    additionalClassName="blue-main-header-mobile"
                                />
                            </>
                        )}
                        <TicketHead
                            onBack={history.goBack}
                            accessibilityTextId="administration.forms.transaction.details"
                            onClose={this.handleClose}
                            titleText={formNameMap[currentLang]}
                            handleClick={this.handleClick}
                            propsClassNames="title-size"
                        />
                        <div className="title-left-header">
                            <h3>{i18n.get(title, this.props.transaction?.activityName)}</h3>
                            {subtitle && <h4>{i18n.get(subtitle)}</h4>}
                        </div>
                    </>
                );
            }
            return (
                <Head
                    onClose={this.handleClose}
                    onBack={!isMobile && history.goBack}
                    titleText={formNameMap[currentLang]}
                    hideMenu
                    accessibilityTextId={isTicket || (mode === "view" && "administration.forms.transaction.details")}
                    propsClassNames="title-size"
                />
            );
        }
        return null;
    };

    render() {
        const { fetching, isCancellingTransaction, metadata, currentLang, errors, isMobile } = this.props;
        const hasMetadata = Object.keys(metadata).length > 2;

        return (
            <Fragment>
                <Notification scopeToShow="form" errors={errors} metadata={metadata} currentLang={currentLang} />

                {!fetching && this.renderHeader()}

                <MainContainer showLoader={fetching && !isCancellingTransaction && !hasMetadata} shouldHideOnLoad>
                    <div className={isMobile ? "container-fluid" : "container"}>{this.renderContent()}</div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default compose(
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ metadata, data, location }) => {
            const { query } = queryString.parseUrl(location.search);

            return {
                ...metadata.fieldList.reduce((values, { idField }) => {
                    // TODO: Either remove mapPropsToValues and call setValue in every form field's componentDidMount
                    // or modify the query string of creditCard to an encoded JSON with the correct initial value
                    // instead of just having the credit card id
                    if (idField === "creditCard" || idField === "debitAccount") {
                        return { ...values, [idField]: data[idField] || "" };
                    }
                    return { ...values, [idField]: query[idField] || data[idField] || "" };
                }, {}),
                scheduler: data.scheduler
                    ? {
                          ...data.scheduler,
                          valueDate: data.scheduler.valueDate ? moment(data.scheduler.valueDate) : null,
                      }
                    : null,
            };
        },
        validate: (values, { metadata, currentLang, dispatch }) => {
            const { errors: formErrors } = metadata.fieldList.reduce(
                ({ insideValues, errors }, { idField, type, requiredErrorMap }) => {
                    const isFieldVisible = isVisible(idField, metadata.fieldList, insideValues);
                    const copiedInsideValues = { ...insideValues };

                    if (!isFieldVisible) {
                        copiedInsideValues[idField] = null;
                    }

                    if (
                        isRequired(idField, metadata.fieldList, copiedInsideValues) &&
                        isFieldVisible &&
                        (stringUtils.isEmpty(copiedInsideValues[idField]) ||
                            (Array.isArray(copiedInsideValues[idField]) &&
                                copiedInsideValues[idField].every((element) => stringUtils.isEmpty(element))) ||
                            (typeof copiedInsideValues[idField] === "object" &&
                                Object.values(copiedInsideValues[idField]).some((element) =>
                                    stringUtils.isEmpty(element),
                                )) ||
                            (type === "termsandconditions" && !copiedInsideValues[idField]))
                    ) {
                        return {
                            errors: {
                                ...errors,
                                [idField]: requiredErrorMap[currentLang],
                            },
                            insideValues: copiedInsideValues,
                        };
                    }

                    return { errors, insideValues };
                },
                { errors: {}, insideValues: cloneDeep(values) },
            );

            if (Object.keys(formErrors).length !== 0) {
                dispatch(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            }

            return formErrors;
        },
        handleSubmit: (values, formikBag) => {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(values).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0].text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            Mixpanel.track_form(formikBag.props.id, filteredValues);
            formikBag.props.dispatch(
                formActions.previewForm({
                    formikBag,
                    idForm: formikBag.props.id,
                    idActivity: formikBag.props.metadata.idActivity,
                    idTransaction: formikBag.props.transaction ? formikBag.props.transaction.idTransaction : null,
                    values: filteredValues,
                }),
            );
        },
    }),
    mapProps(({ metadata, ...props }) => ({
        ...props,
        metadata,
        validations: metadata.fieldList.reduce(
            ({ validations, insideValues }, { idField }) => {
                const isFieldVisible = isVisible(idField, metadata.fieldList, insideValues);
                const copiedInsideValues = { ...insideValues };

                if (!isFieldVisible) {
                    copiedInsideValues[idField] = null;
                }

                return {
                    validations: {
                        ...validations,
                        [idField]: {
                            isVisible: isFieldVisible,
                            isRequired: isRequired(idField, metadata.fieldList, copiedInsideValues),
                        },
                    },
                    insideValues: copiedInsideValues,
                };
            },
            { validations: {}, insideValues: cloneDeep(props.values) },
        ).validations,
    })),
)(FormRender);
