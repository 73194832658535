import React, { Component } from "react";
import ReactSVG from "react-svg";
import { string, shape, bool } from "prop-types";
import { withRouter } from "react-router-dom";

import { resizableRoute } from "pages/_components/Resizable";

class Image extends Component {
    static propTypes = {
        src: string.isRequired,
        className: string,
        wrapperClassName: string,
        styles: shape({}),
        isMobileNative: bool,
    };

    static defaultProps = {
        className: "svg-image",
        wrapperClassName: "svg-wrapper",
        styles: {},
        isMobileNative: false,
    };

    render() {
        const { isMobileNative, src, styles } = this.props;

        const path = src.replace("images/", "").replace(/^\//, "");
        let fullPath;
        try {
            fullPath = require(`styles/images/${path}`);
        } catch {
            fullPath = require(`styles/images/en-flag.svg`);
        }

        fullPath = fullPath.replace(/^\//, "");

        if (!isMobileNative) {
            const pathDepth = window.location.pathname.split("/").length - 1;

            const pathPrefix = Array(pathDepth).join("../");

            fullPath = pathPrefix + fullPath;
        }

        return (
            <ReactSVG
                path={fullPath}
                className={this.props.className}
                wrapperClassName={this.props.wrapperClassName}
                style={styles}
            />
        );
    }
}

export default resizableRoute(withRouter(Image));
