import React, { Children } from "react";
import cx from "classnames";
import { element, oneOf, node, func, string, bool, number } from "prop-types";
import styles from "./StepIndicator.module.css";

const orientations = { horizontal: "horizontal", vertical: "vertical" };

const defaultRenderStep = (child, index, parentProps) => (
    <li className={styles.step}>
        {React.cloneElement(child, {
            ...child.props,
            index,
            showAsPill: parentProps.orientation === orientations.horizontal,
        })}
    </li>
);

const Stepper = ({
    children,
    className,
    orientation = orientations.vertical,
    WrapperComponent = "ol",
    renderStep = defaultRenderStep,
}) => {
    const activeItems = Children.toArray(children).filter((component) => component.props.active).length;
    if (activeItems > 1) {
        throw new Error(`Please add only one active element, your code has ${activeItems} active steps`);
    }

    return (
        <WrapperComponent
            className={cx(
                styles.wrapper,
                {
                    [styles.vertical]: orientation === orientations.vertical,
                    [styles.horizontal]: orientation === orientations.horizontal,
                },
                className,
            )}>
            {Children.map(children, (child, index) => renderStep(child, index, { orientation }))}
        </WrapperComponent>
    );
};

Stepper.propTypes = {
    children: element.isRequired,
    className: string,
    orientation: oneOf(Object.keys(orientations)),
    WrapperComponent: node.isRequired,
    renderStep: func,
};

Stepper.defaultProps = {
    className: undefined,
    orientation: orientations.vertical,
    renderStep: defaultRenderStep,
};

const Step = ({ currentStep, children, showAsPill, stepNumber }) => {
    const displayNumber = stepNumber;
    const active = currentStep === displayNumber;
    const done = currentStep > displayNumber;

    return (
        <div
            className={cx({
                [styles.pill]: showAsPill,
                [styles.active]: active,
                [styles.done]: done,
            })}>
            <span
                className={cx([styles.reference], {
                    hidden: showAsPill && !active,
                })}>
                {displayNumber < 10 ? `0${displayNumber}` : displayNumber}
                {!showAsPill && ". "}
            </span>
            <span
                className={cx({
                    "visually-hidden": showAsPill && active,
                    hidden: showAsPill && !active,
                })}>
                {children}
            </span>
        </div>
    );
};

Step.propTypes = {
    currentStep: number.isRequired,
    showAsPill: bool,
    children: element.isRequired,
    stepNumber: number.isRequired,
};

Step.defaultProps = {
    showAsPill: false,
};

export default Stepper;
export { orientations, Step };
