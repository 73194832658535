import React, { Component } from "react";
import { string, bool } from "prop-types";

import FormattedDate from "pages/_components/FormattedDate";

class ItemExpirationDate extends Component {
    static propTypes = {
        isExpired: bool.isRequired,
        date: string.isRequired,
        expiredText: string.isRequired,
        expirationText: string.isRequired,
    };

    render() {
        const { isExpired, expiredText, expirationText, date } = this.props;

        if (isExpired) {
            return (
                <span className="text-label danger-label dueDate-item">
                    <span>{expiredText}</span>
                </span>
            );
        }

        return (
            <span className="dueDate-item">
                {expirationText && <div className="data-label ">{expirationText} </div>}
                <span className="data-date">
                    <FormattedDate date={date} />
                </span>
            </span>
        );
    }
}

export default ItemExpirationDate;
