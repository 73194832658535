import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { arrayOf, number, shape, string, func } from "prop-types";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import InfoWindowsExtension from "pages/settings/_components/InfoWindowsExtension";

import { MAX_LAST_LOGIN_TO_SHOW } from "constants.js";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { naturalDate } from "util/format";
import { getLastLoginPlace } from "util/settings";

export class MapContainer extends Component {
    state = {
        activeMarker: null,
        newPositions: [],
        bounds: null,
    };

    static propTypes = {
        dispatch: func.isRequired,
        google: shape({}).isRequired,
        positions: arrayOf(
            shape({
                latitude: number,
                longitude: number,
                date: string,
            }),
        ),
    };

    static defaultProps = {
        positions: [],
    };

    pathToMarker = () => {
        if (window.cordova) {
            return require(`styles/images//markerLocation.svg`).replace(/^\//, ""); // eslint-disable-line
        }
        return require(`styles/images/markerLocation.svg`); // eslint-disable-line
    };

    onMarkerClick = (props, marker) => {
        let country = null;
        let city = null;
        const { position, google } = props;
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(position.lat, position.lng);

        // This case is for example for credit card payment location, where we dont have the country and city in the response from API
        if (!marker.country) {
            geocoder.geocode({ latLng: latlng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[1]) {
                        let index;
                        for (let j = 0; j < results.length; j++) {
                            if (results[j].types[0] === "locality") {
                                index = j;
                                break;
                            }
                        }

                        if (results[index] && results[index].address_components) {
                            for (let i = 0; i < results[index].address_components.length; i++) {
                                if (results[index].address_components[i].types[0] === "locality") {
                                    city = results[index].address_components[i];
                                }
                                if (results[index].address_components[i].types[0] === "country") {
                                    country = results[index].address_components[i];
                                }
                            }
                            marker.country = country.long_name; // eslint-disable-line
                            marker.city = city.long_name; // eslint-disable-line
                        }
                    }

                    this.setState({
                        activeMarker: marker,
                    });
                }
            });
        } else {
            // In this case we already have the city and country from API
            this.setState({
                activeMarker: marker,
            });
        }
    };

    radians = (degrees) => (degrees * Math.PI) / 180;

    // Return the distance between two coordinates in meters
    getDistanceBetweenCoordinates = (latitude1, longitude1, latitude2, longitude2) => {
        const earthRadius = 6371;
        const dLat = this.radians(latitude2 - latitude1);
        const dLon = this.radians(longitude2 - longitude1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.radians(latitude1)) *
                Math.cos(this.radians(latitude2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.asin(Math.sqrt(a));
        const d = earthRadius * c;
        return d * 1000;
    };

    onMapClick = () =>
        this.setState({
            activeMarker: null,
        });

    makeBounds = (mapProps, newPositionsParam) => {
        const { newPositions } = this.state;
        const { google } = mapProps;
        const bounds = new google.maps.LatLngBounds();
        newPositionsParam.forEach((marker) => {
            const { latitude, longitude } = marker;
            bounds.extend(new google.maps.LatLng(latitude, longitude));
        });
        if (newPositions.length === 0) {
            this.setState({ bounds });
        }
    };

    onReady = (mapProps, newPositionsParam) => {
        this.makeBounds(mapProps, newPositionsParam);
    };

    redirectDevices = (idDevice) => {
        const { dispatch } = this.props;

        dispatch(routerActions.push(`/settings/myDevices?idDevice=${idDevice}`));
    };

    renderActiveMarkerContent = (date) => {
        let deviceFrom = null;
        if (date.deviceInfo) {
            if (date.deviceInfo.model) {
                deviceFrom = i18n.get(`devices.apple.identifier.${date.deviceInfo.model}`, `${date.deviceInfo.model}`);
            } else {
                deviceFrom = date.deviceInfo.platform;
                deviceFrom = i18n.get(`devices.platform.${date.deviceInfo.platform}`, `${date.deviceInfo.platform}`);
            }
        }
        if (deviceFrom) {
            return (
                // eslint-disable-next-line
                <button className="btn btn-asLink-for-map" onClick={() => this.redirectDevices(date.idDevice)}>
                    {naturalDate(date.date)} {`${i18n.get("settings.lastLogin.OSFrom")} ${deviceFrom}`}{" "}
                </button>
            );
        }
        return <span> {naturalDate(date.date)} </span>;
    };

    render() {
        const { google, positions } = this.props;
        const { activeMarker, bounds } = this.state;
        const newPositionsToView = [];

        for (
            let i = 0, len = positions.length < MAX_LAST_LOGIN_TO_SHOW ? positions.length : MAX_LAST_LOGIN_TO_SHOW;
            i < len;
            i++
        ) {
            const existPosition = newPositionsToView.find(
                (elem) =>
                    this.getDistanceBetweenCoordinates(
                        elem.latitude,
                        elem.longitude,
                        positions[i].latitude,
                        positions[i].longitude,
                    ) < 50,
            );

            if (!existPosition) {
                const { city, country, date, latitude, longitude, idDevice, deviceInfo } = positions[i];
                newPositionsToView.push({
                    city,
                    country,
                    dates: [{ date, idDevice, deviceInfo: deviceInfo ? JSON.parse(deviceInfo) : undefined }],
                    latitude,
                    longitude,
                });
            } else {
                existPosition.dates.push({
                    date: positions[i].date,
                    idDevice: positions[i].idDevice,
                    deviceInfo: positions[i].deviceInfo ? JSON.parse(positions[i].deviceInfo) : undefined,
                });
            }
        }

        const containerStyle = {
            width: "100%",
            height: "318px",
            borderRadius: "8px",
        };

        return (
            <div style={containerStyle}>
                <Map
                    google={google}
                    containerStyle={containerStyle}
                    onReady={newPositionsToView.length > 0 ? () => this.onReady(this.props, newPositionsToView) : null}
                    bounds={bounds}
                    maxZoom={16}
                    minZoom={1}
                    keyboardShortcuts={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                    rotateControl={false}
                    fullscreenControl={false}
                    zoom={13}>
                    {newPositionsToView &&
                        newPositionsToView.map((position) => {
                            const { city, country, dates, latitude: lat, longitude: lng } = position;
                            return (
                                <Marker
                                    city={city}
                                    country={country}
                                    dates={dates}
                                    icon={{
                                        url: this.pathToMarker(),
                                        anchor: new google.maps.Point(32, 32),
                                        scaledSize: new google.maps.Size(32, 32),
                                    }}
                                    key={`${lat}${lng}`}
                                    onClick={this.onMarkerClick}
                                    position={{ lat, lng }}
                                    zoom={13}
                                />
                            );
                        })}
                    <InfoWindowsExtension marker={activeMarker} visible={!!activeMarker}>
                        {activeMarker && (
                            <div className="marker">
                                <div className="marker-title">
                                    {getLastLoginPlace(activeMarker.city, activeMarker.country, true)}
                                </div>
                                <div className="marker-divider" />
                                <div className="marker-content">
                                    {activeMarker.dates.map((date) => (
                                        <div key={date.date}>{this.renderActiveMarkerContent(date)}</div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </InfoWindowsExtension>
                </Map>
            </div>
        );
    }
}

const mapStateToProps = () => ({
    customMapsApi: config.get("googlemap.apikey", "AIzaSyAeG8KAM9fZ7amUikdy5AZvsD5bYCAXH40"),
});

export default connect(mapStateToProps)(
    GoogleApiWrapper((props) => ({
        apiKey: props.customMapsApi,
    }))(MapContainer),
);
