import React, { Component, Fragment } from "react";
import { func, shape, number, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Col from "react-bootstrap/lib/Col";
import isEmpty from "lodash/isEmpty";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { selectors as poiSelectors, actions as poiActions } from "reducers/pointsOfInterest";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PointsOfInterestMap from "pages/pointsOfInterest/_components/PoiMap";
import PointsOfInterestList from "pages/pointsOfInterest/_components/List";

import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";
import BottomSheet from "pages/pointsOfInterest/_components/BottomSheet";

import I18n from "pages/_components/I18n";

class PointsOfInterest extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        positions: arrayOf(
            shape({
                idPoi: number,
                idPoiType: number,
                latitude: number,
                longitude: number,
                visible: bool,
            }),
        ).isRequired,
        center: shape({ lat: number, lng: number }).isRequired,
        currentPosition: shape({ lat: number, lng: number }).isRequired,
        zoom: number.isRequired,
        isDesktop: bool.isRequired,
        isBottomSheetDisplayed: bool.isRequired,
        isLastPage: bool.isRequired,
        isLoading: bool.isRequired,
        filters: shape({}).isRequired,
        history: func.isRequired,
        isMobile: bool.isRequired,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.filters && isEmpty(prevState.filters)) {
            return {
                ...prevState,
                filters: nextProps.filters.reduce((init, actual) => Object.assign(init, { [actual]: true }), {}),
            };
        }
        return null;
    }

    state = {
        selectedPoi: null,
        selectedMarker: null,
        filters: {},
        menuVisibilty: false,
        isBottomSheetDisplayed: false,
        pageNumber: 1,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        this.fetchMorePointsOfInterest();
        dispatch(poiActions.getFilters());
    }

    componentWillUnmount() {
        this.handleBottomSheetClick(false);
    }

    fetchMorePointsOfInterest = () => {
        const { dispatch } = this.props;
        const { pageNumber, filters } = this.state;

        let poiTypes = Object.entries(filters).reduce(
            (result, [typeId, isVisible]) => (isVisible ? [...result, typeId] : result),
            [],
        );
        if (!poiTypes.length) {
            poiTypes = null;
        }
        dispatch(
            poiActions.getPoiData({
                poiTypes,
                limitRow: configUtils.get("pois.maxPoisToRetrive") * pageNumber,
            }),
        );
        this.setState(({ pageNumber: page }) => ({
            pageNumber: page + 1,
        }));
    };

    handleChange = (value) => {
        const { dispatch } = this.props;
        const { filters } = this.state;
        const newFilters = { ...filters, [value]: !filters[value] };
        const hasFiltersChecked = Object.values(newFilters).some((filterValue) => filterValue);
        if (hasFiltersChecked) {
            this.setState({ pageNumber: 1, selectedPoi: null, filters: newFilters }, () => {
                dispatch(poiActions.filterData({ [value]: filters[value] }));
                this.fetchMorePointsOfInterest();
            });
        }
    };

    onCenterChange = (center) => {
        const { dispatch } = this.props;
        dispatch(poiActions.changeMapData({ center }));
        this.setState({
            selectedPoi: null,
        });
    };

    onZoomChange = (zoom) => {
        const { dispatch } = this.props;
        dispatch(poiActions.changeMapData({ zoom }));
    };

    setCurrentLocationCenter = () => {
        const { dispatch, currentPosition } = this.props;
        this.setState({
            selectedPoi: null,
        });
        dispatch(poiActions.changeMapData({ center: currentPosition }));
    };

    handleBottomSheetClick = (state) => {
        const { dispatch } = this.props;
        dispatch(
            poiActions.setBottomSheetDisplay({
                isBottomSheetDisplayed: state,
            }),
        );
    };

    handleInfoClose = () => {
        this.setState({
            selectedPoi: null,
        });
    };

    selectPoiItem = ({ latitude, longitude }, i) => {
        const { dispatch } = this.props;
        dispatch(
            poiActions.changeMapData({
                center: {
                    lat: latitude,
                    lng: longitude,
                },
                zoom: 15,
            }),
        );
        this.setState({ selectedPoi: i });
    };

    handleListItemClick = (position, i) => {
        this.selectPoiItem(position, i);
        this.handleBottomSheetClick(false);
    };

    handleInfiniteLoad = () => {
        const { isLastPage } = this.props;
        if (isLastPage) {
            return;
        }
        this.fetchMorePointsOfInterest();
    };

    toggleBottomSheet(state) {
        this.handleBottomSheetClick(!state);
    }

    renderMobile = () => {
        const { filters, selectedPoi } = this.state;
        const {
            positions,
            currentPosition,
            center,
            zoom,
            isBottomSheetDisplayed,
            isLoading,
            isLastPage,
            isDesktop,
        } = this.props;

        return (
            <div className="above-the-fold">
                <Container className="container--layout align-items-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <div className="flex-container">
                            {Object.entries(filters).map((value) => (
                                <Checkbox
                                    name={value[0]}
                                    checked={value[1]}
                                    label={i18n.get(`pointsofinterest.types.${value[0]}`)}
                                    onChange={this.handleChange}
                                    value={value[0]}
                                />
                            ))}
                        </div>
                    </Col>
                </Container>
                <section className="container--layout flex-grow googleMap">
                    <div className="google-map-button-wrapper">
                        <Button
                            bsStyle="primary"
                            className="btn-small"
                            label="pointsofinterest.map.currentPosition"
                            onClick={this.setCurrentLocationCenter}
                        />
                    </div>
                    <PointsOfInterestMap
                        google={window.google}
                        positions={positions}
                        center={center}
                        zoom={zoom}
                        currentLocation={currentPosition}
                        onCenterChange={this.onCenterChange}
                        onZoomChange={this.onZoomChange}
                        onInfoClose={this.handleInfoClose}
                        onMarkerClick={this.selectPoiItem}
                        selectedPoi={selectedPoi}
                    />
                </section>

                <Container className="container--layout align-items-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <Button
                            bsStyle="primary"
                            label="pointsofinterest.title.list"
                            onClick={() => {
                                this.handleBottomSheetClick(true);
                            }}
                        />
                    </Col>
                </Container>

                <BottomSheet
                    isDisplayed={isBottomSheetDisplayed}
                    handleDismiss={() => {
                        this.handleBottomSheetClick(false);
                    }}
                    onChange={() => {
                        this.toggleBottomSheet(isBottomSheetDisplayed);
                    }}
                    hasDismissButton
                    title={i18n.get("pointsofinterest.bottomSheet.title", "Buttom sheet title")}>
                    <BottomSheet.Content>
                        <PointsOfInterestList
                            isDesktop={isDesktop}
                            positions={positions}
                            onItemClick={this.handleListItemClick}
                            selectedPoi={selectedPoi}
                            fetchMoreData={this.handleInfiniteLoad}
                            isLoading={isLoading}
                            isLastPage={isLastPage}
                        />
                    </BottomSheet.Content>
                </BottomSheet>
            </div>
        );
    };

    renderDesktop = () => {
        const { filters, selectedPoi } = this.state;
        const { positions, currentPosition, center, zoom, isLoading, isLastPage, isDesktop } = this.props;
        return (
            <div className="above-the-fold">
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={8} lg={8} className="col col-12">
                        <I18n
                            component="p"
                            componentProps={{ className: "text-lead" }}
                            id="pointsofinterest.subtitle"
                        />
                    </Col>
                </Container>

                <Container className="container--layout align-items-center flex-grow container--viewport-fit">
                    <Col sm={12} md={6} lg={4} className="col" style={{ height: "100%" }}>
                        <I18n component="h3" id="pointsofinterest.title.list" />

                        <div className="flex-container">
                            {Object.entries(filters).map((value) => (
                                <Checkbox
                                    name={value[0]}
                                    key={value[0]}
                                    checked={value[1]}
                                    label={i18n.get(`pointsofinterest.types.${value[0]}`)}
                                    onChange={this.handleChange}
                                    value={value[0]}
                                />
                            ))}
                        </div>
                        <div className="list-wrapper--poi">
                            <PointsOfInterestList
                                isDesktop={isDesktop}
                                positions={positions}
                                onItemClick={this.handleListItemClick}
                                selectedPoi={selectedPoi}
                                fetchMoreData={this.handleInfiniteLoad}
                                isLoading={isLoading}
                                isLastPage={isLastPage}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={8} className="col">
                        <div className="google-map-wrapper">
                            <div className="google-map-button-wrapper">
                                <Button
                                    bsStyle="primary"
                                    className="btn-small"
                                    label="pointsofinterest.map.currentPosition"
                                    onClick={this.setCurrentLocationCenter}
                                />
                            </div>
                            <PointsOfInterestMap
                                google={window.google}
                                positions={positions}
                                center={center}
                                zoom={zoom}
                                currentLocation={currentPosition}
                                onCenterChange={this.onCenterChange}
                                onZoomChange={this.onZoomChange}
                                selectedPoi={selectedPoi}
                                onInfoClose={this.handleInfoClose}
                                onMarkerClick={this.selectPoiItem}
                            />
                        </div>
                    </Col>
                </Container>
            </div>
        );
    };

    render() {
        const { isDesktop, history, isLoading, isMobile } = this.props;

        return (
            <Fragment>
                {!isLoading && (
                    <Head
                        title="pointsofinterest.head"
                        closeLinkTo={!isMobile && "/desktop"}
                        propsClassNames={`title-size ${isMobile && "title-content-white"}`}
                        additionalClassName={isMobile && "blue-main-header-mobile"}
                        onBack={!isMobile && history.goBack}
                    />
                )}
                <MainContainer showLoader={isLoading}>
                    {isDesktop ? this.renderDesktop() : this.renderMobile()}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: poiSelectors.isLoading(state),
    isLastPage: poiSelectors.isLastPage(state),
    isBottomSheetDisplayed: poiSelectors.getBottomSheetDisplay(state),
    positions: poiSelectors.getPointsData(state).filter((poi) => poi.visible),
    currentPosition: poiSelectors.getCurrentPosition(state),
    center: poiSelectors.getCenter(state),
    zoom: poiSelectors.getZoom(state),
    filters: poiSelectors.getFilters(state),
});

export default compose(withRouter, connect(mapStateToProps))(PointsOfInterest);
