import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as checksSelectors } from "reducers/checks";
import { selectors as templateSelectors } from "reducers/template";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import Button from "pages/_components/Button";
import * as dateUtils from "util/date";
import AddFrequentRecipientButton from "pages/transfers/_components/AddFrequentRecipientButton";
import FormTransition from "../_components/FormTransition";
import FrequentRecipients from "../../transfers/FrequentRecipients";
import ChangeStatusTransactionComponent from "./ChangeStatusTransactionComponent";
import CustomScheduler from "../_components/_scheduler/CustomScheduler";
import * as maskUtils from "util/mask";

const ID_FORM = "transfers.thirdParties";
const ID_ACTIVITY = "transfers.thirdParties.send";
const ID_ACTIVITY_PRE = "transfers.thirdParties.pre";
const TransfersThirdParties = (props) => {
    const { mode, dispatch, location, data, currentLang, fromBackoffice, transaction, isDesktop } = props;
    const [showMailConfirm, setShowMailConfirm] = useState(false);
    const [amountMax, setAmountMax] = useState(null);
    const [recipientSelected, setRecipientSelect] = useState(["CBU"]);
    const [isFrequentDestinationsShowed, setStatusFrequentDestination] = useState(false);
    const [cuitToAdd, setCuitToAdd] = useState(null);
    const [destinationIdentifierToAdd, setDestinationIdentifierToAdd] = useState(null);
    const [errorsAux, setErrorsAux] = useState({});
    const mapsFieldsToChange = new Map([
        ["cuit", "cuitDestination"],
        ["destination", "identifyAccount"],
        ["type", "recipient"],
        ["email", "email"],
    ]);
    const [amountData, setAmountData] = useState({
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: "",
                label: "",
            },
        ],
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setErrors(props?.formErrors);
    }, [props?.formErrors]);

    useEffect(() => {
        if (mode === "edit") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
                transfersValidation: {
                    accountDescription: null,
                    clientDescription: null,
                    cuit: null,
                    bank: null,
                    alias: null,
                    cbu: null,
                },
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            setAmountData({
                options: [
                    {
                        id: "",
                        label: "",
                    },
                ],
            });
        }
        setShowMailConfirm(data.emailConfirm ? data.emailConfirm : false);
        setDestinationIdentifierToAdd(
            data.identifyAccount && data.identifyAccount.length > 0 ? data.identifyAccount : null,
        );
        setRecipientSelect(data.recipient && data.recipient.length > 0 ? data.recipient : recipientSelected);
        setCuitToAdd(data.cuitDestination && data.cuitDestination.length > 0 ? data.cuitDestination : null);
    }, [dispatch, location, transaction]);
    const showFieldButtonMail = mode === "edit";

    const idTransaction = transaction?.idTransaction;
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    // Función para que el focus se aplique a ambos campos en casos como este
    // Solo reemplazar el targetId por el del input text (no el del selector)
    const focusDoubleInput = (e) => {
        const targetId = "identifyAccount";
        const targetElement = document.querySelector(`.${targetId}`);

        if (targetElement) {
            if (e.target.classList.contains("Select-control")) {
                targetElement.classList.add("has-focus");
            } else if (e.target.id !== targetId) {
                targetElement.classList.remove("has-focus");
            }
        }
    };

    const onTemplateLoad = (templateData) => {
        if (Object.keys(templateData).length > 0 && mode === "edit") {
            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: templateData.amount.currency,
                        label: i18nUtils.get(`currency.label.${templateData.amount.currency}`),
                    },
                ],
            });
            setShowMailConfirm(templateData?.emailConfirm || false);
            setDestinationIdentifierToAdd(
                templateData.identifyAccount && templateData.identifyAccount.length > 0
                    ? templateData.identifyAccount
                    : null,
            );
            setRecipientSelect(
                templateData.recipient && templateData.recipient.length > 0
                    ? templateData.recipient
                    : recipientSelected,
            );
            setCuitToAdd(
                templateData.cuitDestination && templateData.cuitDestination.length > 0
                    ? templateData.cuitDestination
                    : null,
            );
        }
    };

    useEffect(() => {
        document.addEventListener("click", focusDoubleInput);
        document.addEventListener("touchstart", focusDoubleInput);

        return () => {
            document.removeEventListener("click", focusDoubleInput);
            document.removeEventListener("touchstart", focusDoubleInput);
        };
    }, []);
    // Fin de la función y su useEffect

    const renderFields = (setFieldValue) => {
        const { values, isMobile } = props;

        const preData = values.preData || {
            currencyList: [],
            debitAccountList: [],
        };

        const selectorOptions = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };

        const valueCurrency = (e) => {
            const filteredOptions = preData.debitAccountList?.find((acc) => acc.idProduct === e) || data;
            const currency = filteredOptions.currency || data.amount.currency;
            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: currency,
                        label: i18nUtils.get(`currency.label.${currency}`),
                    },
                ],
            });
            setFieldValue("amount", {
                currency,
                quantity: data.amount?.quantity ? data.amount?.quantity : "",
            });
            setAmountMax(filteredOptions.balance);
            setFieldValue("labelDebit", filteredOptions?.label);
        };
        const accountIdentifier = config.get("transfers.accountIdentifier").split("|");
        const listAccountIdentifier = accountIdentifier.map((value) => ({
            label: i18nUtils.get(`forms.${ID_FORM}.accountIdentifier.${value}.label`),
            id: value,
        }));
        const concepts = config.get("transfers.concepts").split("|");
        const listConcepts = concepts.map((value) => ({
            label: i18nUtils.get(`forms.transfers.concepts.${value}.label`),
            id: value,
        }));
        const valueInputAmount = (e) => {
            if (amountMax || amountMax === 0) {
                if (e > amountMax && e > 0 && !errors.amount) {
                    if (dateUtils.isOutOfTime(config.get("transfers.timeLimit"), dateUtils.TIME_ZONE_BANK)) {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.error") });
                    } else {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.message") });
                    }
                } else {
                    setErrorsAux({ amount: null });
                }
            }
        };
        const changeConfirmMail = () => {
            if (showMailConfirm) {
                setFieldValue("email", null);
                setFieldValue("message", null);
            }
            setFieldValue("emailConfirm", !showMailConfirm);
            setShowMailConfirm(!showMailConfirm);
        };

        const tooltipProps = {
            "data-tooltip": i18nUtils.get("forms.transfers.thirdParties.tooltip.label", null, {
                timeLimit: config.get("transfers.timeLimit"),
            }),
            "data-tooltip-position": "top-right",
        };

        const { transfersValidation } = data || null;

        return (
            <React.Fragment>
                {mode === "view" || mode === "preview" ? (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.ProductselectorCustom}
                            data={selectorOptions}
                            key="debitAccount"
                            name="debitAccount"
                            idField="debitAccount"
                            handleChange={(e) => valueCurrency(e)}
                            value={data.debitAccount || ""}
                            labelBackoffice={data.labelDebit}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="creditAccount"
                            name="creditAccount"
                            idField="creditAccount"
                            value={
                                transfersValidation.label
                                    ? transfersValidation.label
                                    : transfersValidation.accountDescription
                                    ? transfersValidation.accountDescription
                                    : ""
                            }
                            maxLength={50}
                            editing
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="holder"
                            name="holder"
                            idField="holder"
                            value={transfersValidation.clientDescription || ""}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="cuitcuil"
                            name="cuitcuil"
                            idField="cuitcuil"
                            value={
                                transfersValidation.cuit
                                    ? transfersValidation.cuit
                                          .substring(0, 2)
                                          .concat("-")
                                          .concat(transfersValidation.cuit.substring(2, 10))
                                          .concat("-")
                                          .concat(transfersValidation.cuit.substring(10))
                                    : ""
                            }
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="bank"
                            name="bank"
                            idField="bank"
                            value={transfersValidation.bank || ""}
                            maxLength={50}
                        />
                        {transfersValidation?.alias && (
                            <>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="alias"
                                    name="alias"
                                    idField="alias"
                                    value={data?.transfersValidation?.alias || ""}
                                    maxLength={50}
                                />
                            </>
                        )}
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="cbu"
                            name="cbu"
                            idField="cbu"
                            value={transfersValidation.cbu || ""}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            data={amountData}
                            key="amount"
                            name="amount"
                            idField="amount"
                            value={data.amount || ""}
                            amountNull
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={listConcepts}
                            key="concept"
                            name="concept"
                            idField="concept"
                            renderAs="combo"
                            value={data.concept ? data.concept[0] : ["VAR"]}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="creditReference"
                            name="creditReference"
                            idField="creditReference"
                            maxLength={11}
                        />

                        <CustomScheduler {...genericProps} isDesktop={isDesktop} scheduler={data.scheduler} />

                        {showMailConfirm && (
                            <>
                                <hr />
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="email"
                                    name="email"
                                    idField="email"
                                />
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="notificationBody"
                                    name="message"
                                    idField="message"
                                    maxLength={500}
                                />
                            </>
                        )}

                        <ChangeStatusTransactionComponent transaction={transaction} genericProps={genericProps} />
                    </>
                ) : (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.ProductselectorCustom}
                            data={selectorOptions}
                            key="debitAccount"
                            name="debitAccount"
                            idField="debitAccount"
                            handleChange={(e) => valueCurrency(e)}
                            value={data.debitAccount || ""}
                            labelBackoffice={data.labelDebit}
                        />

                        <hr className="ui-mt-0 ui-mb-7" />

                        <div className="form-group ui-mb-3">
                            <div className="form-group-text">
                                <label className="control-label">
                                    {i18nUtils.get("forms.transfers.thirdParties.recipient.label")}
                                </label>
                            </div>
                            <Button
                                image="images/ui-icons/ui-add-user.svg"
                                label={`forms.${ID_FORM}.recipientAddressBook.label`}
                                bsStyle="link"
                                className="btn-link--lowerCase btn-link--wIcon"
                                onClick={() => setStatusFrequentDestination(true)}
                            />
                        </div>

                        {isFrequentDestinationsShowed && (
                            <FrequentRecipients
                                mapsFieldsToChange={mapsFieldsToChange}
                                setFieldValueToChange={setFieldValue}
                                changeStatusModal={setStatusFrequentDestination}
                                renderAs="modal"
                                {...props}
                            />
                        )}

                        <div className="form-group--mixed-field">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={listAccountIdentifier}
                                key="recipient"
                                name="recipient"
                                renderAs="combo"
                                value={data.recipient ? data.recipient : recipientSelected}
                                onChange={({ id }) => setRecipientSelect([id])}
                                additionalClassName="recipientType-selector"
                            />

                            {recipientSelected[0] === "CBU" ? (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="identifyAccount"
                                    name="identifyAccount"
                                    // idField="identifyAccount"
                                    maxLength={22}
                                    onChange={setDestinationIdentifierToAdd}
                                    idField="recipient.selector"
                                />
                            ) : (
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="identifyAccount"
                                    name="identifyAccount"
                                    // idField="identifyAccount"
                                    maxLength={22}
                                    onChange={setDestinationIdentifierToAdd}
                                    idField="recipient.selector"
                                />
                            )}
                        </div>

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="cuitDestination"
                            name="cuitDestination"
                            idField="cuitDestination"
                            maxLength={12}
                            value={
                                data.cuitDestination
                                    ? data.cuitDestination
                                          .substring(0, 2)
                                          .concat("-")
                                          .concat(data.cuitDestination.substring(2, 10))
                                          .concat("-")
                                          .concat(data.cuitDestination.substring(10))
                                    : ""
                            }
                            mask={maskUtils.cuitMask}
                            onChange={setCuitToAdd}
                        />

                        {mode === "edit" && (
                            <AddFrequentRecipientButton
                                isMobile={isMobile}
                                urlOrigin={location.pathname}
                                cuit={cuitToAdd}
                                destinationType={recipientSelected}
                                destinationIdentifierS={destinationIdentifierToAdd}
                            />
                        )}

                        {mode === "edit" && <hr className="ui-mt-7 ui-mb-7" />}

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            data={amountData}
                            key="amount"
                            name="amount"
                            idField="amount"
                            value={data.amount || ""}
                            handleChange={(e) => valueInputAmount(e)}
                            tooltipProps={tooltipProps}
                            amountNull
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={listConcepts}
                            key="concept"
                            name="concept"
                            idField="concept"
                            renderAs="combo"
                            value={data.concept ? data.concept[0] : ["VAR"]}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="creditReference"
                            name="creditReference"
                            idField="creditReference"
                            maxLength={11}
                        />

                        <CustomScheduler {...genericProps} isDesktop={isDesktop} />

                        {showFieldButtonMail && (
                            <div className="form-group form-group--message">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    optionList={[
                                        {
                                            id: "1",
                                            label: i18nUtils.get(`forms.${ID_FORM}.emailConfirmation.label`),
                                        },
                                    ]}
                                    key="mailConfirm"
                                    name="mailConfirm"
                                    idField="mailConfirm"
                                    renderAs="check"
                                    values={data.mailConfirmation || []}
                                    checked={data.mailConfirmation || false}
                                    onChange={changeConfirmMail}
                                />

                                {showMailConfirm && (
                                    <>
                                        {mode !== "edit" && <hr />}
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Text}
                                            key="email"
                                            name="email"
                                            idField="email"
                                        />
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Textarea}
                                            key="notificationBody"
                                            name="message"
                                            idField="message"
                                            maxLength={500}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };
    const renderFieldsTermsAndConditions = () => (
        <>
            <hr />
            <div className="termsAndConditions text-justify">
                <Field
                    isOnlyText
                    {...genericPropsConditions}
                    component={FormFieldsComponents.Termsandconditions}
                    key="disclaimer"
                    name="disclaimer"
                    idField="disclaimer"
                    link="https://www.bcra.gob.ar"
                    nameLink="www.bcra.gob.ar."
                />
            </div>
        </>
    );

    const validateFieldDraft = (values) => {
        const error = {};
        const { debitAccount } = values;
        if (!debitAccount || debitAccount.length === 0) {
            error.debitAccount = i18nUtils.get("program.date.blank");
        }
        return error;
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const { ...restPreData } = props.preData || {};

    const formProps = {
        title: "forms.transfers.internal.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            idActivity: ID_ACTIVITY,
            onTemplateLoad,
        },
        renderFields,
        preData: restPreData,
        renderFieldsTermsAndConditions,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle: "forms.transfers.thirdParties.subtitle",
        validateFieldDraft,
        modalButtonLabel: "forms.saveTemplate.link",
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} errorsAux={errorsAux} />;
};

TransfersThirdParties.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    fetching: bool.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool,
    useDefaultSubmit: bool,
    values: shape({}).isRequired,
    accountSelected: string,
    formErrors: shape({}).isRequired,
};

TransfersThirdParties.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    accountSelected: null,
    isMobile: false,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    accountSelected: checksSelectors.getAccountIdSelected(state),
    formErrors: formSelectors.getErrors(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(TransfersThirdParties);
