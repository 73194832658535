import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import * as OAUTH from "middleware/oauth";
import * as deviceUtils from "util/device";

export const loginStep1 = (user, documentType, documentNumber) =>
    API.executeAnonymous("/session.login.legacy.step1", {
        _user: user,
        documentType,
        documentNumber,
    });

export const loginStep2 = (exchangeToken, password, recaptchaResponse) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step2",
        {
            _password: password,
            _captcha: recaptchaResponse,
        },
        exchangeToken,
    );

export const changePassword = (exchangeToken, _password, _captcha, newPassword, newPasswordConfirmation) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.passwordExpired",
        {
            _password,
            _captcha,
            _newPassword: newPassword,
            _newPasswordConfirmation: newPasswordConfirmation,
        },
        exchangeToken,
    );

export const oauth = (email, exchangeToken = "") => OAUTH.executePasswordGrant(email, `${exchangeToken}`);

export const thirdPartyOauth = (
    email,
    password,
    thirdPartyToken,
    redirectUri,
    exchangeToken = "",
    recaptchaResponse = "",
) =>
    OAUTH.executeImplicit(
        "assistant-client",
        redirectUri,
        thirdPartyToken,
        email,
        `${password}#${exchangeToken}#${recaptchaResponse}`,
    );

export const fingerprintOauth = (email, fingerprintAuthToken) =>
    OAUTH.executePasswordGrant(
        email,
        `fingerprint#${fingerprintAuthToken}#${deviceUtils.getDeviceId()}#${deviceUtils.getDeviceModel()}#${deviceUtils.getDeviceManufacturer()}`,
    );

export const listEnvironments = () => API.executeWithAccessToken("session.login.oauth.step2b");

export const loginStep3 = (exchangeToken, environment, setAsDefault, location, idDevice, assistant, geoData) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step3",
        {
            environment,
            setAsDefault,
            idDevice,
            ...location,
            ...assistant,
            ...geoData,
        },
        exchangeToken,
    );
export const loginStep4 = (exchangeToken, acceptConditions) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step4",
        {
            acceptConditions,
        },
        exchangeToken,
    );

export const logout = () => {
    const response = API.executeWithAccessToken("/session.logout");

    API.setAuthToken(null);
    restAPI.setAuthToken(null);
    return response;
};

export const setAuthToken = (token) => {
    API.setAuthToken(token);
    restAPI.setAuthToken(token);
};

export const changeEnvironment = (idEnvironmentToChange, setAsDefault, acceptConditions) =>
    API.executeWithAccessToken("/session.changeEnvironment", {
        idEnvironmentToChange,
        setAsDefault,
        acceptConditions,
    });

export const check = () => API.executeWithAccessToken("/session.get");

export const extend = () => API.executeWithAccessToken("/session.extend");

export const checkFingerprintSession = (_accessToken) => {
    API.setAuthToken(_accessToken);
    restAPI.setAuthToken(_accessToken);

    return API.executeWithAccessToken("/fingerprint.sessionExist");
};

export const fingerprintLogin = (location, geoData) => API.executeWithAccessToken("/login.fingerprint", { ...location, ...geoData });

export const registerUserDevice = (exchangeToken, idDevice, pushToken, extraInfo, geoData) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.registerUserDevice",
        {
            idDevice,
            pushToken,
            extraInfo,
            ...geoData,
        },
        exchangeToken,
    );

export const getClientCountry = () => API.executeAnonymous("/session.getUserCountry", null, 2000);

export const validateEnvironmentExpire = (environment) =>
    API.executeAnonymous("/environment.expire.validation", { environment });
