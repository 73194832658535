import { REGION_USA } from "constants.js";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedNumber from "pages/_components/FormattedNumber";
import I18n from "pages/_components/I18n";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { push } from "react-router-redux/actions";

import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "../../../util/i18n";

class Information extends Component {
    static propTypes = {
        account: shape({
            ownerName: string,
            nationalIdentifier: string,
        }).isRequired,
        activeRegion: string,
        dispatch: func.isRequired,
        activeEnvironment: shape({}).isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        activeRegion: "",
    };

    renderSetCbuAliasButtons = () => {
        const { account, dispatch, activeEnvironment, isMobile } = this.props;
        const { permissions } = activeEnvironment;
        const hasActivePermission = permissions["account.set.cbuAlias"] || false;

        let tooltipProps = null;
        let isDisabled = false;
        if (!hasActivePermission) {
            tooltipProps = {
                "data-tooltip": i18n.get("returnCode.COR005E"),
                "data-tooltip-position": `${isMobile ? "top" : "right"}`,
            };
            isDisabled = true;
        }

        const cbuAlias = account?.cbuDetail?.cbuAlias || account?.cbuNick;

        return (
            <div className={`data-wrapper ${cbuAlias === "" ? "ui-mt-0" : ""}`}>
                <div className="data-text ui-fw-sbold">{i18n.get("accounts.cbuNick.label")}</div>
                {cbuAlias !== "" ? (
                    <>
                        <span className="data-text">{cbuAlias}</span>
                        <span {...tooltipProps}>
                            <Button
                                className="btn-only-icon btn-circle"
                                image="images/editPen.svg"
                                label="products.alias.modifyButtonLabel.a11y"
                                onClick={() => dispatch(push(`/formCustom/setCBUAlias/${account.idProduct}`))}
                                disabled={isDisabled}
                            />
                        </span>
                    </>
                ) : (
                    <span {...tooltipProps}>
                        <Button
                            className="btn-small"
                            label="accounts.cbu.create"
                            onClick={() => dispatch(push(`/formCustom/setCBUAlias/${account.idProduct}`))}
                            bsStyle="secondary"
                            disabled={isDisabled}
                        />
                    </span>
                )}
            </div>
        );
    };

    render() {
        const { account, activeRegion } = this.props;

        const { formattedAccountNumber, productType } = account;

        const isUSA = REGION_USA === activeRegion;

        return (
            <div className="detail-head-info-main">
                <mark className="product-name">
                    {`${i18n.get(`productType.${productType}`)} Nº ${formattedAccountNumber}`}
                </mark>

                <div className="data-wrapper">
                    <div className="data-text ui-fw-sbold">{i18n.get("accounts.accountHolder")}</div>
                    <span className="data-text">{account?.client?.name}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-text ui-fw-sbold">{i18n.get("accounts.cbu.label")}</div>
                    <span className="data-text">{account.cbu}</span>
                </div>

                {this.renderSetCbuAliasButtons()}

                {isUSA && (
                    <>
                        <div className="data-wrapper">
                            <I18n id="accounts.apy.interestYTD" componentProps={{ className: "data-text" }} />

                            <span className="data-aux">
                                <FormattedAmount
                                    className="data-amount content-data-strong"
                                    quantity={10.73}
                                    currency={account.currency}
                                />
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <I18n id="accounts.apy.annualPercentage" componentProps={{ className: "data-text" }} />

                            <span className="data-aux">
                                <FormattedNumber fixedDecimalScale suffix=" %">
                                    {1}
                                </FormattedNumber>
                            </span>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Information);
