import * as API from "middleware/api";

export const associateStep1Pre = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.pre", { _code }, _exchangeToken);

export const associateStep1Verify = (_user, document, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.associate.verifyStep1",
        { _user, documentType: document.type, documentNumber: document.document },
        _exchangeToken,
    );

export const associateStep2Verify = (_captcha, _code, _password, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.verifyStep2", { _captcha, _code, _password }, _exchangeToken);

export const associateStep3 = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.associate.finish", { _code }, _exchangeToken);

export const requestInvitationCode = (_captcha, docCountry, docNumber, docType, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.digital.finish",
        { _captcha, docCountry, docNumber, docType },
        _exchangeToken,
    );

export const requestInvitationCodePre = () => API.executeAnonymous("/enrollment.digital.pre");

export const requestPersonalData = (_code, _verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.personalData", { _code, _verificationCode }, _exchangeToken);

export const requestSecuritySeals = (_exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.securitySeals.list", {}, _exchangeToken);

export const requestVerificationCodePre = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.pre", { _code }, _exchangeToken);

export const resendVerificationCode = (_code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.resendVerificationCode", { _code }, _exchangeToken);

export const enrollmentStep1Verify = (
    _user,
    _userConfirmation,
    _code,
    _password,
    _passwordConfirmation,
    _exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.verifyStep1",
        {
            _user,
            _userConfirmation,
            _code,
            _password,
            _passwordConfirmation,
        },
        _exchangeToken,
    );

export const enrollmentStep2Verify = (_securitySeal, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.verifyStep2", { _securitySeal }, _exchangeToken);

export const enrollmentStep3Finish = (_otp, _code, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.finish", { _otp, _code }, _exchangeToken);

export const esignAccept = (userMail, idEnvironment, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.esignAccept", { userMail, idEnvironment }, _exchangeToken);

export const sendIRS = (IRS, validateSSNID) =>
    API.executeWithAccessToken("/pendingActions.irs", { IRS, validateSSNID });

export const sendPEP = () => API.executeWithAccessToken("/pendingActions.pep");

export const sendDocumentsByEmail = (userEmail) =>
    API.executeAnonymous("/session.sendGeneralDocumentsByMail", { userEmail });

export const verifyInvitationCode = (_code) => API.executeAnonymous("/enrollment.invitationCode.verify", { _code });

export const verifyVerificationCode = (personalDataEnabled, _code, _verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.verificationCode",
        { personalDataEnabled, _code, _verificationCode },
        _exchangeToken,
    );

export const reactivateToken = (_exchangeToken, _code) =>
    API.executeWithExchangeToken("/enrollment.reactivateToken", { _code }, _exchangeToken);
