import axios from "axios";

import { encryptText } from "util/security";
import { channel } from "middleware/api";

const oauthAxios = axios.create({
    validateStatus(status) {
        return status >= 200 && status < 500;
    },
});
oauthAxios.defaults.baseURL = window.OAUTH_URL;
oauthAxios.defaults.headers.common.Accept = "application/json";

oauthAxios.interceptors.response.use(
    (response) => {
        if (response.status === 204) {
            return response;
        }

        // cubre el caso de un download de un archivo
        if (response.status === 200 && response.request.responseType === "blob") {
            return response;
        }
        if (response.status === 200) {
            response.type = "I";
        } else {
            response.type = "W";
        }
        return response;
    },
    (error) => {
        // se agrega el atributo para saber que es un error de saga
        // eslint-disable-next-line
        error.httpError = true;

        throw error;
    },
);

export function executePasswordGrant(username, password) {
    const body = encode({
        grant_type: "password",
        username: encryptText(username),
        password: encryptText(password),
        channel: channel(),
    });
    return oauthAxios.post(`/token`, body, {
        headers: {
            Authorization: `Basic ${btoa("web-client:web-secret")}`,
            "Content-Type": "application/x-www-form-urlencoded",
            channel: channel(),
        },
    });
}

export function executeImplicit(clientId, redirectUri, thirdPartyToken, email, password) {
    return oauthAxios.post(
        `/authorize?response_type=token&client_id=${clientId}&custom_token=${thirdPartyToken}&redirect_uri=${redirectUri}`,
        null,
        {
            headers: {
                Authorization: `Basic ${btoa(`${encryptText(email)}:${encryptText(password)}`)}`,
                "Content-Type": "application/x-www-form-urlencoded",
                channel: "assistant",
            },
        },
    );
}

function encode(json) {
    let ret = "";
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(json)) {
        ret += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
    return ret.slice(0, -1);
}
