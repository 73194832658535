import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import GenericItem from "components/GenericItem/GenericItem";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Loader from "pages/_components/Loader";
import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import { array, bool, objectOf, any, oneOfType, shape, number } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as qrSelectors } from "reducers/qr";
import { compose } from "redux";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import * as i18n from "util/i18n";

const Charges = (props) => {
    const { history, chargeList, isMobile, filters, isFetching, pageNumber, isFirstFetched } = props;

    const handleOnClick = (item) => {
        history.push(`/qrChargesCertificates/details/${item.uuid}`);
    };

    const renderItem = (item) => {
        const { uuid, status, concept, grossAmount, netAmount, paymentDate, payerName } = item;

        const statusValue = i18n.get(`transaction.status.${status}`).toUpperCase();
        let statusStyle = "";
        if (status === "APPROVED") {
            statusStyle = "ui-text-success";
        } else if (status === "PENDING") {
            statusStyle = "ui-text-alert";
        } else {
            statusStyle = "ui-text-error";
        }

        return isMobile ? (
            <GenericItem role="button" onClick={() => handleOnClick(item)} arrow>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.date" />
                    </div>
                    <span className="data-text">
                        <FormattedDate date={paymentDate} />
                    </span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.state" />
                    </div>
                    <span className={`data-text ${statusStyle}`}>{statusValue}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.concept" />
                    </div>
                    <span className="data-text">{concept}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.payer" />
                    </div>
                    <span className="data-text">{payerName}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.grossAmount" />
                    </div>
                    <FormattedAmount currency="ARS" quantity={grossAmount} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.netAmount" />
                    </div>
                    <FormattedAmount currency="ARS" quantity={netAmount} />
                </div>
            </GenericItem>
        ) : (
            <div className="table-row" key={uuid} role="button" onClick={() => handleOnClick(item)}>
                <div className="table-data">
                    <span className="data-text text-left">
                        <FormattedDate date={paymentDate} />
                    </span>
                </div>
                <div className="table-data">
                    <span className={`data-text ${statusStyle}`}>{statusValue}</span>
                </div>
                <div className="table-data">
                    <span className="data-text">{concept}</span>
                </div>
                <div className="table-data">
                    <span className="data-text">{payerName}</span>
                </div>
                <div className="table-data align-right">
                    <FormattedAmount currency="ARS" quantity={grossAmount} />
                </div>
                <div className="table-data align-right">
                    <FormattedAmount currency="ARS" quantity={netAmount} />
                </div>

                <div className="table-data table-data-icon">
                    <ChevromRight />
                </div>
            </div>
        );
    };

    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    <DisclaimerContainer
                        className={`${chargeList.length ? "ui-mt-8" : ""}`}
                        disclaimer="accounts.qrChargesCertificates.disclaimer.label"
                        textSmall
                    />
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.date" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.state" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.concept" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="tableHeader.payer" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="tableHeader.grossAmount" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="tableHeader.netAmount" />
                        </Table.HeaderData>
                        <Table.HeaderData />
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer
                    className="ui-mt-8"
                    disclaimer="accounts.qrChargesCertificates.disclaimer.label"
                    textSmall
                />
            </>
        );
    };

    const defaultFilters = {
        dateFrom: defaultDateFromList().accountsQR,
        dateTo: defaultDateFromList().accountsQR,
        toggleState: filters.toggleState,
    };

    const withFilters = getFiltersStatus({
        filters,
        defaultFilters,
    });

    let noMoreDataText;

    if (withFilters) {
        noMoreDataText = "accounts.qrChargesCertificates.charges.list.noMoreData.withFilters.label";
    } else {
        noMoreDataText = "accounts.qrChargesCertificates.charges.list.noMoreData.default.label";
    }

    return (
        <>
            {isFetching && isFirstFetched ? (
                <Loader />
            ) : (
                <ProductList
                    firstFetched={isFirstFetched}
                    fetching={isFetching}
                    items={chargeList}
                    renderItem={renderItem}
                    filters={filters}
                    pageNumber={pageNumber}
                    loadMoreText="global.label.loadMore"
                    noDataText="accounts.qrChargesCertificates.charges.list.empty.label"
                    noMoreDataText={noMoreDataText}
                    noFiltersDataText="accounts.qrChargesCertificates.filter.notFound.message"
                    defaultFilters={defaultFilters}
                    isMobile={isMobile}
                    onLoadMoreClick={() => {}}>
                    {renderList}
                </ProductList>
            )}
        </>
    );
};

Charges.propTypes = {
    chargeList: oneOfType([array]).isRequired,
    isMobile: bool.isRequired,
    filters: shape({}).isRequired,
    isFetching: bool.isRequired,
    history: objectOf(any).isRequired,
    pageNumber: number.isRequired,
    isFirstFetched: bool.isRequired,
};

const mapStateToProps = (state) => ({
    chargeList: qrSelectors.getChargeList(state),
    filters: qrSelectors.getFilters(state),
    isFetching: qrSelectors.getFetching(state),
    pageNumber: qrSelectors.getPageNumber(state),
    isFirstFetched: qrSelectors.getFirstFetching(state),
});

export default compose(withRouter, connect(mapStateToProps))(Charges);
