import React, { useState, useEffect } from "react";
import { string, func, shape } from "prop-types";
import Button from "./Button";

export default function OrderFilterHeadTable({ columnFilter, filterName, changeFilter }) {
    const [ascendent, setAscendent] = useState(true);

    OrderFilterHeadTable.propTypes = {
        columnFilter: shape({}).isRequired,
        filterName: string.isRequired,
        changeFilter: func.isRequired,
    };

    const changeOrder = () => {
        setAscendent(!ascendent);
        changeFilter(!ascendent);
    };

    useEffect(() => {
        if (columnFilter.filterSelected === filterName) {
            if (columnFilter.directionFilter === "descendent") {
                setAscendent(false);
            }
        }
    }, [columnFilter, filterName]);

    return (
        <Button
            type="button"
            onClick={() => changeOrder()}
            bsStyle="link"
            className="btn-only-icon"
            image="images/ui-icons/ui-order-column.svg"
        />
    );
}
