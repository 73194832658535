import { noGpsResponse, unknownResponse } from "util/geolocation";

const callApiService = async (google, lat, lng) => {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };

    try {
        const response = await geocoder.geocode({ location: latlng });

        const { results } = response;

        // se agrega el atributo ya que es una propiedad de google maps platform
        // eslint-disable-next-line
        const { address_components } = results[0];

        let geoData = address_components.reduce((next, key) => {
            if (key.types[0] === "country") {
                return { ...next, country: key.long_name };
            }
            if (key.types[0] === "locality") {
                return { ...next, city: key.long_name };
            }
            return next;
        }, null);

        if (geoData === null) {
            const newResults = results.filter((item) => item.types[0] === "administrative_area_level_1");
            // se agrega el atributo ya que es una propiedad de google maps platform
            // eslint-disable-next-line
            const { address_components: addressComponents } = newResults[0];

            geoData = {
                city: addressComponents[0].long_name,
                country: addressComponents[1].long_name,
            };
        }

        // se agrega la consola para determinar si se pudo obtener la ubicacion
        // eslint-disable-next-line no-console
        console.log("geoData on service ", geoData);

        return geoData;
    } catch (error) {
        // se agrega el atributo para saber que es un error del api
        // eslint-disable-next-line
        console.log("ERROR ON GEO API ", error);
        return unknownResponse;
    }
};

const geocodeLatLngGoogle = async (google, position) => {
    const { lat, lng, isDefault } = position;

    let geoData = {};
    if (isDefault) {
        geoData = noGpsResponse;
    } else {
        geoData = await callApiService(google, lat, lng);
    }

    // se agrega la consola para determinar si se pudo obtener la ubicacion
    // eslint-disable-next-line no-console
    console.log("geoData ", geoData);

    return geoData;
};

export default geocodeLatLngGoogle;
