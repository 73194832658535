import { isPast } from "date-fns";
import * as i18n from "util/i18n";
import moment from "moment";

export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 4;
export const THURSDAY = 8;
export const FRIDAY = 16;
export const SATURDAY = 32;
export const SUNDAY = 64;
export const TIME_ZONE_BANK = "America/Argentina/Buenos_Aires";

export const getLastMonthPeriod = (date = new Date()) => {
    if (date.getMonth() === 0) {
        return [new Date(date.getFullYear() - 1, 11, 1), new Date(date.getFullYear() - 1, 11, 31)];
    }
    return [new Date(date.getFullYear(), date.getMonth() - 1, 1), new Date(date.getFullYear(), date.getMonth(), 0)];
};

export const getSecondLastMonthPeriod = (date = new Date()) => {
    if (date.getMonth() === 0) {
        return [new Date(date.getFullYear() - 1, 10, 1), new Date(date.getFullYear() - 1, 10, 30)];
    }
    return [new Date(date.getFullYear(), date.getMonth() - 2, 1), new Date(date.getFullYear(), date.getMonth() - 1, 0)];
};

export const getCurrentPeriod = (date = new Date()) => [new Date(date.getFullYear(), date.getMonth(), 1), date];

export const isDateLessThanToday = (date) =>
    // hay un issue pendiente para deprecar este utilitario y remplazar las ocurrencias
    // por metodos que ofrece 'date-fns'
    isPast(date);

export const stringTimeToMinutes = (strTime) => {
    const a = strTime.split(":"); // split it at the colons
    // Hours are worth 60 minutes.
    return +a[0] * 60 + +a[1];
};

/* eslint-disable no-bitwise */
export const timeinMinToString = (min) => `${`0${(min / 60) ^ 0}`.slice(-2)}:${`0${min % 60}`.slice(-2)}`;
/* eslint-enable no-bitwise */

export const getDaysNamesWithBitwiseNumber = (bitWiseNumber) => {
    const i18nDays = "administration.restrictions.days.";

    /* eslint-disable no-bitwise */
    const daysName = [
        bitWiseNumber & 1 ? i18n.get(`${i18nDays}monday`) : null,
        bitWiseNumber & 2 ? i18n.get(`${i18nDays}tuesday`) : null,
        bitWiseNumber & 4 ? i18n.get(`${i18nDays}wednesday`) : null,
        bitWiseNumber & 8 ? i18n.get(`${i18nDays}thursday`) : null,
        bitWiseNumber & 16 ? i18n.get(`${i18nDays}friday`) : null,
        bitWiseNumber & 32 ? i18n.get(`${i18nDays}saturday`) : null,
        bitWiseNumber & 64 ? i18n.get(`${i18nDays}sunday`) : null,
    ].filter((i) => i !== null);
    /* eslint-enable no-bitwise */

    return daysName;
};

export const getDayFromDate = (date) => {
    let result = 0;

    switch (date.getDay()) {
        case 0: // SUNDAY
            result = 64;
            break;
        case 1: // MONDAY
            result = 1;
            break;
        case 2: // TUESDAY
            result = 2;
            break;
        case 3: // WEDNESDAY
            result = 4;
            break;
        case 4: // THURSDAY
            result = 8;
            break;
        case 5: // FRIDAY
            result = 16;
            break;
        case 6: // SATURDAY
            result = 32;
            break;
        default:
            break;
    }

    return result;
};

export const moreThan2daysSelected = (value) =>
    value !== 1 && value !== 2 && value !== 4 && value !== 8 && value !== 16 && value !== 32 && value !== 64;

export const weekOfMonth = (date) => {
    let weekInYearIndex = date.week();
    if (date.year() !== date.weekYear()) {
        weekInYearIndex =
            date
                .clone()
                .subtract(1, "week")
                .week() + 1;
    }
    return (
        weekInYearIndex -
        moment(date)
            .startOf("month")
            .week() +
        1
    );
};

export const FRIENDY_DATE_FORMAT = (lang) => {
    switch (lang) {
        case "en":
            return "dddd, MMMM DD, YYYY";
        case "es":
        case "pt":
            return "dddd, DD MMMM, YYYY";
        default:
            return "dddd, MMMM DD, YYYY";
    }
};

export const isOutOfTime = (limitHour, timeZone) => {
    const today = new Date().getTime();
    let dateReference = new Date();
    const limit = limitHour.split(":");
    dateReference.setHours(limit[0]);
    dateReference.setMinutes(limit[1]);
    dateReference.setSeconds("00");
    dateReference = dateReference.toLocaleString("en-US", {
        timeZone,
    });
    dateReference = Date.parse(dateReference);
    const response = today > dateReference;

    return response;
};

export const parseServiceDate = (date) => date.substring(0, 10);
