import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import { shape, node, func, bool } from "prop-types";

import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";
import * as config from "util/config";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import WidgetLoading from "pages/_components/WidgetLoading";
import WidgetHeader from "../_components/WidgetHeader";
import Overlay from "../_components/Overlay";

import "./_exchangeRates.scss";

class ExchangeRates extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isEditable: bool,
        isFetching: bool,
        isDesktop: bool,
        data: shape({}).isRequired,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isFetching: false,
        isDesktop: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.listRequest("exchangeRates"));
    }

    renderItem = ({ targetCurrencyCode, purchase, baseCurrencyCode, sale }) => (
        <>
            <div className="card-flag">
                {targetCurrencyCode !== "555" && (
                    <div className="flag">
                        <Image src={`images/currencyFlags/${targetCurrencyCode}.svg`} />
                        <span className="visually-hidden">
                            {i18nUtils.get(`widgets.exchangeRates.flag.${targetCurrencyCode}`)}
                        </span>
                    </div>
                )}
            </div>
            <div className="card-title">
                <span>{i18nUtils.get(`currency.name.${targetCurrencyCode}`)}</span>
            </div>
            <div className="card-purchase-value">
                <span>{i18nUtils.get(`widgets.exchangeRates.purchase`)}</span>{" "}
                <FormattedAmount quantity={purchase} currency={baseCurrencyCode} />
            </div>
            <div className="card-sale-value">
                <span>{i18nUtils.get(`widgets.exchangeRates.sale`)}</span>{" "}
                <FormattedAmount quantity={sale} currency={baseCurrencyCode} />
            </div>
        </>
    );

    renderList = () => {
        const ArrowLeft = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-left.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const ArrowRight = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-right.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );

        const {
            data: { rates },
            isDesktop,
            isFetching,
        } = this.props;
        const invalidCurrencies = ["888", "999"];
        const filteredRates = rates.filter(({ targetCurrencyCode }) => !invalidCurrencies.includes(targetCurrencyCode));

        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: config.get("exchangeRates.productsPerWidget"),
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 860,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };

        if (!filteredRates.length) {
            if (isFetching) {
                return <WidgetLoading loading />;
            }

            return <Col className="col col-12">{i18nUtils.get("desktop.widgets.exchangeRates.empty")}</Col>;
        }
        if (isDesktop) {
            return (
                <div className="widget--exchangeRates-cards-container">
                    {filteredRates.map((rate) => (
                        <div key={rate.targetCurrencyCode} className="widget--exchangeRates-card">
                            {this.renderItem(rate)}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <Col className="col-12">
                <Slider {...settings} dots infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                    {filteredRates.map((rate) => (
                        <div key={rate.targetCurrencyCode}>
                            <div className="widget--exchangeRates-card">{this.renderItem(rate)}</div>
                        </div>
                    ))}
                </Slider>
            </Col>
        );
    };

    render() {
        const { closeButton, draggableItemProps, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetExchangeRates" };

        const { isEditable } = this.props;

        return (
            <div
                role={isEditable ? "button" : ""}
                className="newWidget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={i18nUtils.get("widgets.exchangeRates.title")} action={closeButton} />
                <div className="widget--exchangeRates" aria-hidden={isEditable}>
                    {isDesktop ? this.renderList() : <Row>{this.renderList()}</Row>}
                    <I18n
                        id="widgets.exchangeRates.description"
                        componentProps={{ className: "widget--exchangeRates-description" }}
                        component="p"
                    />
                </div>

                <Overlay />
            </div>
        );
    }
}

const mapStateToProps = (state) => selectors.getWidget(state, "exchangeRates");

export default compose(connect(mapStateToProps), resizableRoute)(ExchangeRates);
