import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import types from "reducers/types/form";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.repudiateOrCancelGiveEcheq.send";
const ID_FORM = "forms.echeqs.repudiateOrCancelGiveEcheq";

const RepudiateOrCancelGiveEcheq = (props) => {
    const { echeq, mode, dispatch, location, data, transaction, currentLang, fromBackoffice, filters } = props;
    const echeqType = data?.echeqType || filters.echeqType;
    const { idTransaction } = transaction;
    const action = echeqType === "received" ? "repudiateCession" : "cancelCession";
    const activitySuffix = data?.activitySuffix || action;
    const isRepudiateCession = activitySuffix === "repudiateCession";
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const {
        cheque_id,
        cheque_numero,
        cheque_tipo,
        cheque_caracter,
        emisor_cuit,
        emisor_razon_social,
        beneficiario_nombre,
        estado,
        emisor_moneda,
        monto,
        fecha_pago,
        cesiones,
    } = echeq;
    const cession = cesiones?.length > 0 ? cesiones[0] : [];

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.message.label`} />
                </div>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.dataEcheqTitle.label`} />
                </span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.idEcheq.label`} />
                </span>
                <span className="data-text">{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} />
                </span>
                <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqType.label`} />
                </span>
                <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqCharacter.label`} />
                </span>
                <span className="data-text">{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.CUIT/CUILIssuer.label`} />
                </span>
                <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.issuerName.label`} />
                </span>
                <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                </span>
                <span className="data-text">{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            {!isRepudiateCession ? (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.documentBeneficiaryCession.label`} />
                        </span>
                        <span className="data-text">
                            {data?.cesionario_nombre || cession?.cesionario_nombre || "-"}
                        </span>
                    </div>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.nameBeneficiaryCession.label`} />
                        </span>
                        <span className="data-text">
                            {data?.cesionario_documento || cession?.cesionario_documento || "-"}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.documentAssignor.label`} />
                        </span>
                        <span className="data-text">
                            {data?.cedente_documento || cession?.cedente_documento || "-"}
                        </span>
                    </div>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.nameAssignor.label`} />
                        </span>
                        <span className="data-text">{data?.cedente_nombre || cession?.cedente_nombre || "-"}</span>
                    </div>
                </>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.state.label`} />
                </span>
                <span className="data-text">{data?.estado || estado}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.amount.label`} />
                </span>
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.paymentDate.label`} />
                </span>
                <FormattedDate date={data?.fecha_pago || fecha_pago} />
            </div>
            {isRepudiateCession && (
                <>
                    {mode === "edit" ? (
                        <>
                            <hr className="ui-mt-7 ui-mb-3" />
                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.reasonRepudiate.label`} />
                                </span>
                            </div>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Textarea}
                                key="reasonRepudiate"
                                name="reasonRepudiate"
                                idField={mode === "edit" ? "reasonRepudiateEdit" : "reasonRepudiate"}
                                isRequired
                                editing={mode === "edit"}
                                idActivity={ID_ACTIVITY}
                                value={data?.reasonRepudiate || ""}
                                maxLength={100}
                            />
                        </>
                    ) : (
                        <>
                            <hr className="ui-mt-7 ui-mb-7" />
                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.reasonRepudiate.confirm.label`} />
                                </span>
                                <span className="textarea-view">{data?.reasonRepudiate}</span>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${activitySuffix}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id,
            cheque_numero,
            cheque_tipo,
            cheque_caracter,
            emisor_cuit,
            emisor_razon_social,
            beneficiario_nombre,
            estado,
            amount: {
                quantity: monto,
                currency: emisor_moneda,
            },
            fecha_pago,
            cesion_id: data?.cesion_id || cession?.cesion_id || "-",
            cedente_documento_tipo: data?.cedente_documento_tipo || cession?.cedente_documento_tipo || "-",
            cedente_nombre: data?.cedente_nombre || cession?.cedente_nombre || "-",
            cedente_documento: data?.cedente_documento || cession?.cedente_documento || "-",
            cesionario_documento_tipo: data?.cesionario_documento_tipo || cession?.cesionario_documento_tipo || "-",
            cesionario_nombre: data?.cesionario_nombre || cession?.cesionario_nombre || "-",
            cesionario_documento: data?.cesionario_documento || cession?.cesionario_documento || "-",
            activitySuffix,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

RepudiateOrCancelGiveEcheq.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
    filters: shape({}).isRequired,
};

RepudiateOrCancelGiveEcheq.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filters: echeqsSelectors.getFilters(state),
});

export default compose(connect(mapStateToProps), withRouter)(RepudiateOrCancelGiveEcheq);
