/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { func, node, string, bool, arrayOf, any } from "prop-types";

import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import { resizableRoute } from "pages/_components/Resizable";
import { Helmet } from "react-helmet";
import Head from "pages/_components/Head";

class ProductHead extends Component {
    static propTypes = {
        /**
         * back link url
         */
        backLinkTo: string,
        /**
         * back function, ignored if backLinkTo is specified
         */
        onBack: func,
        fetchingDownload: bool,
        onClickDownloadPDF: func,
        onClickDownloadXLS: func,
        renderDownload: func,
        handleOptionsClick: func,
        isDesktop: bool.isRequired,
        hideDownloadButton: bool,
        customRightButton: node,
        mobileMenuOptions: arrayOf(any),
        downloadLabel: string,
    };

    static defaultProps = {
        onClickDownloadPDF: null,
        onClickDownloadXLS: null,
        renderDownload: null,
        handleOptionsClick: null,
        backLinkTo: "",
        fetchingDownload: false,
        onBack: null,
        hideDownloadButton: false,
        customRightButton: null,
        mobileMenuOptions: null,
        downloadLabel: "global.download.movements",
    };

    handleClick = () => {
        const { handleOptionsClick } = this.props;

        if (handleOptionsClick) {
            handleOptionsClick();
        }
        document.activeElement.blur();
    };

    renderDownloadDropdown = () => {
        const { fetchingDownload, onClickDownloadPDF, onClickDownloadXLS, renderDownload, downloadLabel } = this.props;

        if (renderDownload) {
            return renderDownload(fetchingDownload);
        }
        return (
            <div className="toolbar-item download">
                <Dropdown
                    image="images/download.svg"
                    label={downloadLabel}
                    bsStyle="outline"
                    buttonClass="secondary-outline btn-small"
                    fetching={fetchingDownload}
                    pullRight>
                    <Button
                        onClick={onClickDownloadPDF}
                        label="accounts.pdfFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    <Button
                        onClick={onClickDownloadXLS}
                        label="accounts.xlsFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                </Dropdown>
            </div>
        );
    };

    renderHeader = () => {
        const { backLinkTo, isDesktop, onBack, hideDownloadButton, customRightButton, mobileMenuOptions } = this.props;

        return isDesktop ? (
            <header className="view-header theme-product-detail">
                <Helmet>
                    <title>{`Banco Mariva - ${i18n.get("activities.accounts.read")}`}</title>
                </Helmet>
                <Navbar collapseOnSelect>
                    <Navbar.Header>
                        <div className="toolbar">
                            {onBack && (
                                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                    <Button
                                        className="btn toolbar-btn view-back back-product-head"
                                        onClick={onBack}
                                        image="images/back.svg"
                                        block={false}
                                        label="global.back"
                                        bsStyle="link"
                                    />
                                </div>
                            )}
                            <div className="toolbar toolbar-product-name">
                                {backLinkTo && (
                                    <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                        <Link className="btn btn-link toolbar-btn view-back" to={backLinkTo}>
                                            <Image className="svg-icon svg-caret" src="images/back.svg" />
                                            <I18n id="global.back" componentProps={{ className: "visually-hidden" }} />
                                        </Link>
                                    </div>
                                )}
                            </div>

                            <div className="toolbar-getRightOption">
                                {customRightButton || null}
                                {!hideDownloadButton && this.renderDownloadDropdown()}
                            </div>
                        </div>
                    </Navbar.Header>
                </Navbar>
            </header>
        ) : (
            <Head
                showLogo
                arrowWhite
                onBack={onBack}
                additionalClassName="blue-main-header-mobile"
                mobileMenuOptions={mobileMenuOptions || null}
            />
        );
    };

    render() {
        return <>{this.renderHeader()}</>;
    }
}

export default resizableRoute(ProductHead);
