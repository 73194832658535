import React from "react";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import "./_PaymentDetail.scss";
import Image from "pages/_components/Image";
import { bool, func, shape, string } from "prop-types";
import { actions } from "reducers/files";
import { actions as formActions } from "reducers/form";
import { selectors as massivePaymentsSelector } from "reducers/massPayments";
import { connect } from "react-redux";
import FormattedAmount from "../../../_components/FormattedAmount";
import ChargingInstructions from "../ChargingInstructions/ChargingInstructions";

const PaymentDetail = ({ detail, dispatch, uploadType, accountSelected, isMobile, paymentHeader }) => {
    const ID_OPTIONS = "forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail";

    const renderDetails = () => (
        <div className="payment-detail">
            <I18n id={`${ID_OPTIONS}.title`} component="h4" componentProps={{ className: "payment-detail__title" }} />

            <div className="payment-detail__currency payment-detail__line">
                <I18n id={`${ID_OPTIONS}.currency.label`} componentProps={{ className: "payment-detail__label" }} />

                <I18n
                    id={`${ID_OPTIONS}.currency.${paymentHeader?.currency}`}
                    componentProps={{
                        className: "payment-detail__value",
                    }}
                />
            </div>

            <div className="payment-detail__number-payments payment-detail__line">
                <I18n
                    id={`${ID_OPTIONS}.numberOfPayments.label`}
                    componentProps={{ className: "payment-detail__label" }}
                />

                <span className="payment-detail__value">{detail?.quantity}</span>
            </div>

            {uploadType.includes("file") && (
                <>
                    <div className="payment-detail__line">
                        <I18n
                            id={`${ID_OPTIONS}.linesOk.label`}
                            componentProps={{ className: "payment-detail__correct-lines" }}
                        />

                        <span>{detail?.validLines}</span>
                    </div>

                    <div className="payment-detail__line">
                        <I18n
                            id={`${ID_OPTIONS}.linesWithError.label`}
                            componentProps={{ className: "payment-detail__incorrect-lines" }}
                        />

                        <span>{detail?.invalidLines}</span>
                    </div>

                    {detail?.invalidFileId && (
                        <div className="payment-detail__download">
                            <Button
                                bsStyle="link"
                                className="btn-link--wIcon"
                                onClick={() =>
                                    dispatch(
                                        actions.downloadFileRequest(
                                            detail?.invalidFileId,
                                            `invalidLines-${new Date().getTime()}.csv`,
                                        ),
                                    )
                                }>
                                <Image src="images/download.svg" />
                                <I18n id={`${ID_OPTIONS}.download`} />
                            </Button>
                        </div>
                    )}
                </>
            )}

            <div className="payment-detail__line">
                <I18n id={`${ID_OPTIONS}.totalAmount.label`} componentProps={{ className: "payment-detail__label" }} />
                <FormattedAmount maximumDecimals="2" currency={paymentHeader?.currency} quantity={detail?.total} />
            </div>

            <Button
                bsStyle="outline"
                image="images/ui-icons/ui-edit-pen.svg"
                className="payment-detail__editBtn"
                label={`${ID_OPTIONS}.editPayment.button.label`}
                onClick={() => {
                    dispatch(formActions.setMode("massive-list-edit", "edit"));
                }}
            />
        </div>
    );

    const renderFileDetails = () => {
        if (detail) {
            return renderDetails();
        }

        return (
            <ChargingInstructions
                dispatch={dispatch}
                idForm="transferPayment"
                idActivity="pay.transfers.massive.send"
                accountSelected={accountSelected}
                isMobile={isMobile}
            />
        );
    };

    const renderManualDetails = () => {
        if (detail && detail.quantity > 0) {
            return renderDetails();
        }

        return (
            <div className="form-group">
                <Button
                    block={false}
                    bsStyle="outline"
                    image="images/plus.svg"
                    label="forms.pay.transfers.massive.addPayment.button.label"
                    onClick={() => {
                        dispatch(formActions.setMode("massive-list-edit", "edit"));
                    }}
                />
            </div>
        );
    };

    return <>{uploadType.includes("file") ? renderFileDetails() : renderManualDetails()}</>;
};

PaymentDetail.propTypes = {
    detail: shape({}),
    dispatch: func.isRequired,
    uploadType: string.isRequired,
    accountSelected: shape({}).isRequired,
    isMobile: bool.isRequired,
    paymentHeader: shape([]).isRequired,
};

PaymentDetail.defaultProps = {
    detail: {},
};

const mapStateToProps = (state) => ({
    paymentHeader: massivePaymentsSelector.getPaymentHeader(state),
});

export default connect(mapStateToProps)(PaymentDetail);
