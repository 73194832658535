import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors, actions as sessionActions } from "reducers/session";

import { bool, func } from "prop-types";

import SimpleModal from "components/SimpleModal/SimpleModal";

class LogoutModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isOpen: bool.isRequired,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    closeModalLogout = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.setModalLogout(false));
    };

    render() {
        const { isOpen } = this.props;

        return (
            <SimpleModal
                isDisplayed={isOpen}
                modalIcon="images/warningIcon.svg"
                modalMainText="session.logout.modalText"
                closeModal={this.closeModalLogout}
                buttonAction={this.logOut}
            />
        );
    }
}
const mapStateToProps = (state) => ({
    isOpen: selectors.getModalLogout(state),
});

export default connect(mapStateToProps)(LogoutModal);
