import React, { Component, Fragment } from "react";
import { node, bool, shape, string, oneOfType, array, object } from "prop-types";

import ProductFilters from "pages/_components/ProductFilters";
import PageLoading from "pages/_components/PageLoading";
import I18n from "./I18n";

class ProductBody extends Component {
    static propTypes = {
        children: node.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        filters: oneOfType([array, object]).isRequired,
        quickFilters: shape(),
        hideTabs: bool,
        showSubtitle: bool,
        subtitleLabel: string,
        className: string,
    };

    static defaultProps = {
        hideTabs: false,
        showSubtitle: false,
        subtitleLabel: "",
        className: "",
        quickFilters: null,
    };

    render() {
        const {
            children,
            fetching,
            filters,
            quickFilters,
            isDesktop,
            hideTabs,
            showSubtitle,
            subtitleLabel,
            className,
            ...rest
        } = this.props;

        return (
            <Fragment>
                {(isDesktop && (
                    <>
                        {filters && (
                            <ProductFilters className={className} {...rest}>
                                {filters}
                            </ProductFilters>
                        )}
                        {quickFilters && quickFilters}
                        <PageLoading loading={fetching}>{children}</PageLoading>
                    </>
                )) || (
                    <div>
                        {showSubtitle && (
                            <I18n
                                id={subtitleLabel}
                                component="h2"
                                componentProps={{ className: "ui-text-black ui-fw-regular" }}
                            />
                        )}
                        <PageLoading loading={fetching}>{children}</PageLoading>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default ProductBody;
