import React, { Component } from "react";
import { bool, arrayOf, shape, string, number } from "prop-types";
import { connect } from "react-redux";
import FileDownload from "js-file-download";

import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";

import PaymentLines from "pages/forms/PaymentLines";
import { listItemProps } from "pages/forms/_components/_fields/_transactionlines/ListItem";

class FormPaymentLines extends Component {
    static propTypes = {
        transactionLines: arrayOf(shape(listItemProps)),
        isConfirmation: bool,
        paymentCurrency: string.isRequired,
        totalLines: number.isRequired,
        isFetching: bool.isRequired,
    };

    static defaultProps = {
        transactionLines: [],
        isConfirmation: false,
    };

    linesToData = (lines, currency) => {
        const id = Date.now();
        return lines.reduce(
            (data, { creditAccountNumber, creditAmountCurrency, creditAmountQuantity, creditAccountName }) =>
                `${data}\n${creditAccountNumber},${creditAmountCurrency},${creditAmountQuantity},${creditAccountName}`,
            `${id},${currency}`,
        );
    };

    handleDownloadClick = (filterState, format) => {
        const { transactionLines, paymentCurrency } = this.props;
        const { filter } = filterState;
        const linesToDownload = transactionLines.filter((line) => !filter || filter(line));
        const data = this.linesToData(linesToDownload, paymentCurrency);

        FileDownload(data, `fileName.${format}`);
    };

    render() {
        const { transactionLines, paymentCurrency, isFetching } = this.props;
        const totalAmount = transactionLines.reduce(
            (result, line) => ({ currency: result.currency, quantity: result.quantity + line.creditAmountQuantity }),
            {
                quantity: 0,
                currency: paymentCurrency,
            },
        );
        return (
            <PaymentLines
                totalAmount={totalAmount}
                totalLines={transactionLines.length}
                isFetching={isFetching}
                onDownloadClick={this.handleDownloadClick}
                formats={["xls", "txt"]}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
    paymentCurrency: transactionLinesSelectors.getCurrency(state),
    isFetching: transactionLinesSelectors.isFetching(state),
    transactionLines: transactionLinesSelectors.getEditedLines(state),
    isEditingPayment: transactionLinesSelectors.isEditingPayment(state),
});

export default connect(mapStateToProps)(FormPaymentLines);
