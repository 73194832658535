import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as investmentSelectors } from "reducers/investments";

import * as i18nUtils from "util/i18n";
import formatPaymentDeadline from "util/investments";
import { customFormatNumber } from "util/number";

import Yup from "yup";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormTransition from "../_components/FormTransition";
import CustomScheduler from "../_components/_scheduler/CustomScheduler";

const ID_FORM = "request.investment.rescue";
const ID_ACTIVITY = "request.investment.rescue.send";
const ID_ACTIVITY_PRE = "request.investment.rescue.pre";

const RequestInvestmentRescueForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        data,
        currentLang,
        fromBackoffice,
        transaction,
        shareHolder,
        account,
        operatingFunds,
        values,
        isDesktop,
    } = props;
    const [totalOrPartial, setTotalOrPartial] = useState("total");
    const [currencyOrShares, setCurrencyOrShares] = useState("");

    useEffect(() => {
        if (mode === "edit") {
            const requestData = {
                account,
                shareHolder,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        }
    }, [dispatch, location, transaction]); // eslint-disable-line

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
            setTotalOrPartial(data?.rescueTotal[0]);
            if (data?.rescueCurrencyOrShares?.length > 0) {
                setCurrencyOrShares(data?.rescueCurrencyOrShares[0]);
            }
        }
    }, [dispatch, data]);

    const shareholderAccountList = account?.bankAccounts || [];
    const operationFundsList = values?.operatingFunds || [];
    const accountList = account || [];

    const { name: accountName, number } = accountList;
    const shareholderAccountOptions = data?.shareholderAccountOptions || {
        options: shareholderAccountList.map((acc) => ({
            ...acc,
            id: acc.idCpt,
            label: `${acc.accountCurrency} ${acc.accountNumber} ${acc.accountDescription}`,
        })),
    };

    const idTransaction = transaction?.idTransaction;
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const rescueTotalPartialOptions = [
        {
            id: "total",
            label: i18nUtils.get("forms.request.investment.rescue.rescueTotal.total.label"),
        },
        {
            id: "parcial",
            label: i18nUtils.get("forms.request.investment.rescue.rescueTotal.parcial.label"),
        },
    ];

    const operatingFundSelected = operationFundsList
        ? operationFundsList.filter((valu) => valu.id === shareHolder.id)
        : null;

    const { name, typeDescription, balance } = shareHolder;
    const valueFCI = `${name} - ${typeDescription} `;
    const blockUp = balance ? balance.blockUp : null;
    const total = balance ? balance.total : null;
    const lastValue = balance ? balance.lastValue : null;
    const numberOfShares = shareHolder ? total - blockUp : null;
    const tenureAvailable = shareHolder && (numberOfShares ? numberOfShares * lastValue : null);
    const currency = shareHolder?.currencyDescription ? shareHolder.currencyDescription.toLowerCase() : "";
    const currencyId = shareHolder?.currencyId ? shareHolder.currencyId : "";
    const rescueCurrencyOrShares = [
        {
            id: "amount",
            label: i18nUtils.get(`forms.request.investment.rescue.rescueCurrencyOrShares.amount.label`),
        },
        {
            id: "shares",
            label: i18nUtils.get("forms.request.investment.rescue.rescueCurrencyOrShares.shares.label"),
        },
    ];

    const genericPropsView = {
        mode: "view",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const dataAmount = {
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [],
    };
    const dataShareholder = {
        decimalSeparator: ",",
        precision: 4,
        thousandsSeparator: ".",
        options: [],
    };
    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const placeholderAmount =
        currencyOrShares === "shares"
            ? "forms.request.investment.rescue.amountShares.error"
            : "forms.request.investment.rescue.amount.error";

    const onChangeTotal = (e) => {
        setTotalOrPartial(e);
    };
    const onChangeCurrencyOrShares = (e) => {
        setCurrencyOrShares(e);
    };

    const formattedDeadline =
        data?.formattedDeadline || formatPaymentDeadline(data?.paymentDeadline || operatingFundSelected[0]?.deadline);

    const renderFields = (setFieldValue) => {
        const validateTotal = (e) => {
            if (
                (currencyOrShares === "shares" && e + 0.0001 > numberOfShares) ||
                (currencyOrShares === currency && e + 0.01 > tenureAvailable)
            ) {
                setFieldValue("rescueTotal", ["total"]);
                setTotalOrPartial("total");
            } else if (
                (currencyOrShares === "shares" && e - 0.0001 < numberOfShares) ||
                (currencyOrShares === currency && e - 0.01 < tenureAvailable)
            ) {
                setFieldValue("rescueTotal", ["parcial"]);
                setTotalOrPartial("parcial");
            }
            if (currencyOrShares === "shares") {
                setFieldValue("amount", {
                    currency: currencyId,
                    quantity: e * lastValue || "",
                });
            } else {
                setFieldValue("amountShares", null);
            }
        };
        return (
            <>
                <div className="hidden-field">
                    <Field
                        {...genericPropsView}
                        component={FormFieldsComponents.Text}
                        key="fci"
                        name="fci"
                        idField="fci"
                        value={data?.fci ? data.fci : valueFCI}
                    />
                </div>

                <div className="form-group">
                    <h4 className="ui-text-uppercase">
                        {i18nUtils.get("forms.request.investment.rescue.fci.label")} <br />
                        {data?.fci ? data.fci : valueFCI}
                    </h4>
                </div>

                <Field
                    {...genericPropsView}
                    component={FormFieldsComponents.Text}
                    key="paymentDeadline"
                    name="paymentDeadline"
                    idField="paymentDeadline"
                    value={formattedDeadline}
                />

                <Field
                    {...genericPropsView}
                    component={FormFieldsComponents.Text}
                    key="accountLabel"
                    name="accountLabel"
                    idField="accountLabel"
                    value={data.accountLabel ? data.accountLabel : `${number} ${accountName}`}
                />

                {mode === "edit" && (
                    <>
                        <div className="data-wrapper">
                            <I18n
                                id="forms.request.investment.rescue.numberOfShares.label"
                                componentProps={{ className: "data-label" }}
                            />
                            <FormattedAmount quantity={numberOfShares} customMaximumDecimals={4} hideCurrency />{" "}
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="forms.request.investment.rescue.availableTenure.label"
                                componentProps={{ className: "data-label" }}
                            />
                            <FormattedAmount quantity={tenureAvailable} currency={currencyId} />{" "}
                        </div>
                        <I18n
                            id="forms.request.investment.rescue.numberOfSharesRescueInfo.label"
                            componentProps={{ className: "ui-body-small ui-mb-3 ui-mt-1" }}
                        />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            key="rescueTotal"
                            name="rescueTotal"
                            idField="rescueTotal"
                            renderAs="radio"
                            onChange={(e) => onChangeTotal(e)}
                            value={totalOrPartial}
                            defaultValue={["total"]}
                            optionList={rescueTotalPartialOptions}
                            inLineControl
                        />
                    </>
                )}
                {totalOrPartial === "total" && (
                    <>
                        <div className="data-wrapper">
                            <I18n
                                id={
                                    mode === "edit"
                                        ? "forms.request.investment.rescue.numberShares.label"
                                        : "forms.request.investment.rescue.numberOfSharesRescue.label"
                                }
                                componentProps={{ className: "data-label" }}
                            />
                            <FormattedAmount quantity={numberOfShares} customMaximumDecimals={4} hideCurrency />{" "}
                        </div>
                    </>
                )}

                {mode === "edit" && totalOrPartial === "parcial" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="rescueCurrencyOrShares"
                        name="rescueCurrencyOrShares"
                        idField="rescueCurrencyOrShares"
                        renderAs="radio"
                        onChange={(e) => onChangeCurrencyOrShares(e)}
                        optionList={rescueCurrencyOrShares}
                        inLineControl
                    />
                )}

                {totalOrPartial !== "total" && currencyOrShares && (
                    <>
                        {currencyOrShares === "shares" && (
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                key="amountShares"
                                name="amountShares"
                                idField={mode === "edit" ? "amountShares" : "amountSharesConfirm"}
                                data={dataShareholder}
                                value={data?.amountShares || ""}
                                hideCurrency
                                hideCurrencyViewMode
                                handleChange={(e) => validateTotal(e)}
                                messageHelp={i18nUtils.get(placeholderAmount, "", {
                                    cuotaMaximaDisponibles: customFormatNumber(numberOfShares, 4),
                                })}
                            />
                        )}

                        {currencyOrShares !== "shares" && (
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                key="amount"
                                name="amount"
                                idField={mode === "edit" ? "amount" : "amountConfirm"}
                                data={dataAmount}
                                value={data.amount}
                                hideCurrency={mode === "edit"}
                                handleChange={(e) => validateTotal(e)}
                                messageHelp={i18nUtils.get(placeholderAmount, "", {
                                    cuotaMaximaDisponibles: customFormatNumber(numberOfShares, 4),
                                })}
                            />
                        )}
                    </>
                )}
                <CustomScheduler
                    {...genericProps}
                    isDesktop={isDesktop}
                    scheduler={data?.scheduler}
                    submitDate={transaction?.submitDateTime}
                    schedulerLabelCustom="forms.request.investment.rescue.scheduler.label"
                    modalTitleLabelCustom="forms.request.investment.rescue.scheduler.modal.title"
                    disclaimerLabelCustom="forms.request.investment.subcribeRescue.scheduler.disclaimer.text"
                    isSubscriptionOrRescue
                />
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        key="sourceAccount"
                        name="sourceAccount"
                        idField="sourceAccount"
                        data={shareholderAccountOptions}
                        value={data.sourceAccount || null}
                    />
                </>

                <DisclaimerContainer className={`important-text ${mode !== "edit" ? "ui-mt-7" : ""}`}>
                    <I18n id="forms.request.investment.rescue.importantAnnouncement.label" />
                    {mode === "edit" && (
                        <I18n
                            id="forms.request.investment.disclaimer.CNV.label"
                            component="p"
                            componentProps={{ className: "ui-fw-sbold ui-mt-7" }}
                        />
                    )}
                </DisclaimerContainer>
            </>
        );
    };
    const renderFieldsTermsAndConditions = () => (
        <>
            <div className="termsAndConditions ui-mt-8 ui-mb-7">
                <DisclaimerContainer maxHeight="134px">
                    <I18n
                        id="forms.request.investment.rescue.disclaimer.label"
                        componentProps={{ className: "ui-fw-bold" }}
                        component="p"
                    />
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimer"
                        name="disclaimer"
                        idField="disclaimer"
                    />
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimerLink"
                        name="disclaimerLink"
                        idField="disclaimerLink"
                        link="https://www.marivafondos.com.ar"
                        nameLink="Mariva Asset Management"
                    />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`forms.${ID_FORM}.termsAndConditionsRescue.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
                values={data.termsAndConditionsSubscriptionAcceptance || []}
            />
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {}; // eslint-disable-line

    const formProps = {
        title: "forms.request.investment.rescue.formName",
        buttonLabel: "global.continue",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        renderFieldsTermsAndConditions,
        data: {
            account,
            shareHolder,
            operatingFunds,
            shareholderAccountOptions,
            formattedDeadline,
        },
        idActivity: ID_ACTIVITY,
        exportList: "pdf",
        handleClickDownload,
        additionalFieldsToValidate: ["termsAndConditionsAcceptance"],
    };
    return <FormTransition {...props} {...formProps} />;
};

RequestInvestmentRescueForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    shareHolder: shape({}).isRequired,
    account: shape({}).isRequired,
    operatingFunds: shape({}).isRequired,
    values: shape({}).isRequired,
};

RequestInvestmentRescueForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    shareHolder: investmentSelectors.getSelectedInvestment(state),
    account: investmentSelectors.getSelectedAccount(state),
    operatingFunds: investmentSelectors.getOperatingFunds(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    amountShares: Yup.string().required(
                        i18nUtils.get("forms.request.investment.rescue.amountShares.empty", ""),
                    ),
                    amount: Yup.string().required(i18nUtils.get("forms.request.investment.rescue.amount.empty", "")),
                }),
            ),
    }),
    withRouter,
)(RequestInvestmentRescueForm);
