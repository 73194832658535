import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { shape, node, func, bool } from "prop-types";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import { resizableRoute } from "pages/_components/Resizable";
import { selectors, actions as widgetActions } from "reducers/widgets";
import * as i18nUtils from "util/i18n";
import LoansListItem from "pages/loans/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";
import FormattedAmount from "pages/_components/FormattedAmount";
import Link from "react-router-dom/Link";
import Button from "pages/_components/Button";
import * as config from "util/config";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import FormattedDate from "pages/_components/FormattedDate";
import WidgetLoading from "pages/_components/WidgetLoading";
import { push } from "react-router-redux/actions";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import * as dateUtils from "util/date";
import moment from "moment";
import WidgetHeader from "../_components/WidgetHeader";
import { actions as loanActions } from "../../../../reducers/loans";
import Overlay from "../_components/Overlay";

class Loans extends Component {
    static propTypes = {
        isFetching: bool,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
        isEditable: bool,
        listLoans: shape({}).isRequired,
    };

    static defaultProps = {
        isFetching: false,
        closeButton: null,
        isDesktop: false,
        isEditable: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(widgetActions.listRequest("loans"));
    }

    renderAll = (uiAutomationProp) => {
        const { isDesktop, draggableItemProps, closeButton, isEditable } = this.props;
        if (isDesktop) {
            return (
                <WidgetList item={LoansListItem} keyExtractor={(item) => item.idProduct} name="loans" withDisclaimer>
                    {() => (
                        <div
                            role={isEditable ? "button" : ""}
                            className="newWidget"
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader title={i18nUtils.get("desktop.widgets.loans.title")} action={closeButton} />
                            {this.renderList()}
                            <Overlay />
                        </div>
                    )}
                </WidgetList>
            );
        }
        return (
            <div
                className="newWidget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={i18nUtils.get("desktop.widgets.loans.title")} action={closeButton} />
                <div className="widget--fixedTermDeposits" aria-hidden={isEditable}>
                    {this.renderList()}
                </div>
                <Overlay />
            </div>
        );
    };

    renderItem = (loan, isDesktop) => {
        const finalPath = {
            pathname: `/loans/${loan.idProduct}/${loan.operationCode}/${loan.operationBranch}/${loan.operationOrder}/detail`,
        };

        const expiryDate = loan.nextExpiration !== null ? loan.nextExpiration : loan.lastExpiration;
        let dueDate = moment(expiryDate);
        dueDate = dueDate.add(1, "days").toDate();
        const isExpired = dateUtils.isDateLessThanToday(dueDate);

        if (isDesktop) {
            return (
                <div
                    aria-describedby={loan.idProduct}
                    onClick={() => {
                        this.props.dispatch(loanActions.selectLoan(loan));
                        this.props.dispatch(push(finalPath));
                    }}
                    role="menuitem">
                    <div className="table-row-container">
                        <div key={loan.idProduct} className="table-row widgetLoan">
                            <div className="table-data data-name">{loan.productAlias || loan.operationCode}</div>
                            <div className="table-data data-details">
                                <span className="data-info">{loan.descriptionSIOC}</span>
                            </div>
                            <div className="table-data data-date content-data-expiration">
                                {isExpired ? (
                                    <span className="data-expired">{i18nUtils.get(`loans.list.item.expired`)}</span>
                                ) : (
                                    <div>
                                        <span className="data-label">
                                            {i18nUtils.get(`loans.list.item.expiration`)}
                                        </span>
                                        <FormattedDate date={expiryDate} />
                                    </div>
                                )}
                            </div>
                            <div className="table-data data-number">
                                <div className="data-label">{i18nUtils.get(`desktop.widgets.loans.amount.label`)}</div>
                                <FormattedAmount quantity={loan.amount} currency={loan.currency} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <Link aria-describedby={loan.idProduct} to={finalPath} role="menuitem">
                <Col key={loan.idProduct} className="col-12">
                    <Row className="data-wrapper text-center">
                        <span className="data-name">{loan.productAlias || loan.operationCode}</span>
                        <span className="visually-hidden">
                            {i18nUtils.get(`desktop.widgets.fixedTermDeposits.title`)}
                        </span>
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">{loan.descriptionSIOC}</span>
                    </Row>
                    <Row className="data-wrapper text-center content-data-expiration">
                        {isExpired ? (
                            <p className="data-expired">{i18nUtils.get(`loans.list.item.expired`)}</p>
                        ) : (
                            <div>
                                <span className="data-label">{i18nUtils.get(`loans.list.item.expiration`)}</span>
                                <FormattedDate date={expiryDate} />
                            </div>
                        )}
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">{i18nUtils.get(`desktop.widgets.loans.amount.label`)}</span>
                        <FormattedAmount quantity={loan.amount} currency={loan.currency} />
                    </Row>
                    <Row className="data-wrapper text-right">
                        <hr />
                        <SeuoLabel />
                    </Row>
                </Col>
            </Link>
        );
    };

    renderList = () => {
        const { listLoans, isFetching, isDesktop, isEditable } = this.props;
        const ArrowLeft = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-left.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const ArrowRight = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-right.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: config.get("fixedTermDeposits.productsPerWidget"),
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 760,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        if (!listLoans.data.loans.length) {
            if (!isFetching) {
                return <NoProduct text="desktop.widgets.loans.empty" imagePath="images/coloredIcons/component.svg" />;
            }
        }
        if (isDesktop) {
            return (
                <WidgetLoading loading={!listLoans.data.loans.length && isFetching}>
                    {listLoans.data.loans ? (
                        <div className="table" aria-hidden={isEditable}>
                            <div className="table-body">
                                {listLoans.data.loans.map((item) => (
                                    <div key={item.idProduct} role="button">
                                        {this.renderItem(item, isDesktop)}
                                    </div>
                                ))}
                            </div>
                            <SeuoLabel />
                        </div>
                    ) : null}
                </WidgetLoading>
            );
        }
        return (
            <Slider {...settings} dots infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                {listLoans.data.loans.map((loan) => (
                    <div key={loan.idProduct}>{this.renderItem(loan, isDesktop)}</div>
                ))}
            </Slider>
        );
    };

    render() {
        const uiAutomationProp = { "data-ui-automation": "widgetLoans" };
        return this.renderAll(uiAutomationProp, this.settings);
    }
}
const mapStateToProps = (state) => ({ listLoans: selectors.getWidget(state, "loans") });
export default compose(connect(mapStateToProps), resizableRoute)(Loans);
