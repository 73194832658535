import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";

import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, string } from "prop-types";

const FORM_ID = "settings.registerTokenConfirmation";

class ValidateToken extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        history: func.isRequired,
        passcode: string.isRequired,
        isMobile: bool.isRequired,
    };

    registerTokenConfirmationForm = () => {
        const { isSubmitting, passcode } = this.props;

        return (
            <>
                <div className="container-center">
                    <div className="title-left-header">
                        <I18n id="settings.registerToken.title" component="h1" />
                    </div>

                    <Form className="form-content" noValidate="novalidate">
                        <div className="form-text-info ui-mb-7">
                            <I18n component="p" id="settings.registerTokenConfirmation.title" />
                            <I18n component="p" id="settings.registerTokenConfirmation.step1" />

                            <div className="activate-password-container text-center">
                                <I18n
                                    component="p"
                                    id="settings.registerTokenConfirmation.passcode"
                                    passcode={passcode}
                                />
                            </div>

                            <I18n component="p" id="settings.registerTokenConfirmation.extra.step" />
                        </div>

                        <Field name="otp" type="otp" idForm={FORM_ID} component={Credential} showLabelInfo={false} />

                        <Button
                            block
                            type="submit"
                            bsStyle="primary"
                            label="settings.registerTokenConfirmation.send"
                            loading={isSubmitting}
                        />
                    </Form>
                </div>
            </>
        );
    };

    render() {
        const { history, isMobile } = this.props;

        return (
            <Fragment>
                <Head
                    headerClassName={!isMobile && "mt-2"}
                    onBack={history.goBack}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                    additionalClassName={isMobile && "blue-main-header-mobile"}
                />

                <MainContainer>
                    <Notification scopeToShow="registerTokenConfirmation" />
                    {this.registerTokenConfirmationForm()}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    passcode: settingsSelectors.getPasscode(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: ({ otp }, formikBag) => {
            formikBag.props.dispatch(settingsActions.validateToken(otp, formikBag));
        },
    }),
)(ValidateToken);
