import React from "react";
import { string, shape } from "prop-types";

import I18n from "pages/_components/I18n";

import "./_noDataContainer.scss";

const NoDataTextContainer = ({ noMoreDataText, i18nParams, className }) => (
    <div className={`no-more-data-text ${className || ""}`}>
        <I18n id={noMoreDataText} component="p" {...i18nParams} />
    </div>
);

NoDataTextContainer.propTypes = {
    noMoreDataText: string.isRequired,
    className: string,
    i18nParams: shape({}),
};

NoDataTextContainer.defaultProps = {
    className: "",
    i18nParams: null,
};

export default NoDataTextContainer;
