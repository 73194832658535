import React, { Component } from "react";
import { shape, string, func, number } from "prop-types";

import FormattedDate from "pages/_components/FormattedDate";
import * as utilsI18n from "util/i18n";
import Button from "pages/_components/Button";

class TemplateItem extends Component {
    static propTypes = {
        item: shape({
            name: string.isRequired,
        }).isRequired,
        onClick: func.isRequired,
        onDelete: func.isRequired,
        num: number.isRequired,
    };

    handleClick = (event) => {
        const { item, onDelete } = this.props;
        event.stopPropagation();
        onDelete(item);
    };

    render() {
        const { item, num, onClick } = this.props;
        return (
            <li
                num={num}
                onClick={() => onClick(num)}
                className="template-item"
                name="Plantillas"
                aria-describedby="ItemBtnDescription">
                <div className="template-item__content">
                    <span className="template-date" aria-hidden>
                        <FormattedDate date={item.creationDate} />
                    </span>
                    <span className="template-name">{item.name}</span>
                </div>
                <Button
                    bsStyle="default"
                    type="button"
                    className="btn-only-icon btn-delete"
                    aria-label={`${utilsI18n.get("file.upload.input.labelButtonRemoveItem")} ${item.name}`}
                    onClick={this.handleClick}
                    image="images/ui-icons/ui-delete.svg"
                />
            </li>
        );
    }
}

export default TemplateItem;
