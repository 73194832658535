import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";

import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";

const FORM_ID = "enrollment.step3";

class EnrollmentStep3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isSubmitting: bool.isRequired,
        hasOtp: bool.isRequired,
        passcode: string,
        exchangeToken: string.isRequired,
        fetching: bool.isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        passcode: null,
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleReactivate = () => {
        const { dispatch, exchangeToken, invitationCode } = this.props;

        dispatch(enrollmentActions.reactivateToken(exchangeToken, invitationCode));
    };

    render() {
        const { isSubmitting, hasOtp, passcode, fetching, isMobile } = this.props;

        return (
            <>
                <Notification scopeToShow="step3" />
                <div className="view-page justify-content-center">
                    <div className="view-content">
                        <main className="main-container">
                            <div className="title-header">
                                <I18n id="enrollment.index.header" component="h1" />
                            </div>
                            <Form className="form-content">
                                {hasOtp ? (
                                    <I18n
                                        component="h2"
                                        id="enrollment.steps.step3.withToken.title"
                                        componentProps={{ className: "ui-text-skyblue" }}
                                    />
                                ) : (
                                    <div className="token-info">
                                        <I18n
                                            component="h2"
                                            id="enrollment.steps.step3.withoutToken.title"
                                            componentProps={{ className: "ui-text-skyblue" }}
                                        />
                                        <I18n
                                            component="p"
                                            id="enrollment.withoutActiveToken.info.part1"
                                            componentProps={{ className: "ui-mt-0" }}
                                        />
                                        <I18n component="p" id="enrollment.withoutActiveToken.info.part2" />
                                        <I18n component="p" id="enrollment.withoutActiveToken.step1" />

                                        <div className="form-group">
                                            <Button
                                                bsStyle="link"
                                                label="enrollment.withoutActiveToken.resendMail"
                                                onClick={this.handleReactivate}
                                            />
                                        </div>

                                        <I18n component="p" id="enrollment.withoutActiveToken.step2" />
                                        <I18n component="p" id="enrollment.withoutActiveToken.step3" />

                                        <div className="withoutActiveToken-container">
                                            <I18n
                                                component="p"
                                                id="enrollment.withoutActiveToken"
                                                passcode={passcode}
                                            />
                                        </div>
                                        <I18n component="p" id="enrollment.withoutActiveToken.step4" />
                                    </div>
                                )}

                                <div className={!hasOtp ? "token-form" : ""}>
                                    <Field
                                        name="otp"
                                        idForm={FORM_ID}
                                        component={Credential}
                                        type="otp"
                                        hidePlaceholder
                                        showLabelInfo={false}
                                    />
                                </div>

                                {hasOtp && (
                                    <>
                                        <I18n
                                            component="p"
                                            id="enrollment.reactivate.help"
                                            componentProps={{ className: "ui-mt-0 ui-mb-7" }}
                                        />

                                        <div className="ui-dflex-center">
                                            <Button
                                                label="enrollment.reactivate"
                                                type="button"
                                                bsStyle="tertiary"
                                                loading={fetching && !isSubmitting}
                                                onClick={this.handleReactivate}
                                                disabled={isSubmitting}
                                                block={isMobile}
                                            />
                                        </div>
                                    </>
                                )}

                                <Button
                                    label="enrollment.setcredentials.securitySeal.submit.register"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    disabled={fetching}
                                    block
                                />
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.getFetching(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentData: onboardingSelectors.getDocumentData(state),
    hasOtp: enrollmentSelectors.getHasOtp(state),
    passcode: enrollmentSelectors.getPasscode(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: "",
        }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.enrollmentStep3Finish(otp, formikBag));
        },
    }),
)(withExchangeToken(EnrollmentStep3));
