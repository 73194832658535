import React, { Component } from "react";
import { node } from "prop-types";

class DetailHeadInfo extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;

        return <div className="detail-head-info-border">{children}</div>;
    }
}

export default DetailHeadInfo;
