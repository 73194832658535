import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";

import TooltipTour from "pages/forms/_components/_fields/TooltipTour";
import Step2Content from "pages/login/_components/Step2Content";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import { push } from "react-router-redux/actions";
import * as i18n from "util/i18n";
import HeaderLogin from "../_components/header/HeaderLogin";

class LoginStep2 extends Component {
    state = {
        stepsTour: [],
        runTooltipTour: false,
    };

    componentDidMount() {
        const { dispatch, firstLog, activeEnvironment, username } = this.props;

        if (activeEnvironment) {
            dispatch(push("/desktop"));
            return;
        }
        if (!username) {
            dispatch(push("/"));
            return;
        }
        this.setState({
            stepsTour: [
                {
                    selector: "figureId",
                    title: "tooltipTour.login.securityImage.title",
                    content: i18n.get("tooltipTour.login.securityImage.content"),
                },
            ],
            runTooltipTour: firstLog,
        });
    }

    componentWillUnmount() {
        this.setState({
            stepsTour: [],
            runTooltipTour: false,
        });
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.goBackToLogin());
        dispatch(push("/"));
    };

    render() {
        const { isInFlow } = this.props;
        const { stepsTour, runTooltipTour } = this.state;

        return (
            <>
                <Notification scopeToShow="loginStep2" />
                <div className="login-content">
                    <TooltipTour
                        steps={stepsTour}
                        run={runTooltipTour}
                        showNavigation={false}
                        showCloseButton={false}
                        showNumber={false}
                        closeWithMask={false}
                        lastStepNextButton={
                            <Button className="btn btn-outline btn-small">
                                {i18n.get("tooltipTour.lastButton.label")}
                            </Button>
                        }
                    />

                    {isInFlow && (
                        <>
                            <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                            <MainContainer className="main-container">
                                <Step2Content />
                            </MainContainer>
                        </>
                    )}
                </div>
            </>
        );
    }
}

LoginStep2.propTypes = {
    isInFlow: bool.isRequired,
    dispatch: func.isRequired,
    firstLog: bool.isRequired,
    username: string,
    activeEnvironment: shape({}),
};

LoginStep2.defaultProps = {
    activeEnvironment: null,
    username: "",
};

const mapStateToProps = (state) => ({
    isInFlow: loginSelectors.getIsInFlow(state),
    fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    firstLog: !loginSelectors.getHasLogged(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep2));
