import { NO_TRANSITION } from "constants.js";
import * as form from "middleware/form";
import * as investmentsMiddleware from "middleware/investments";
import { push, replace } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import { actions, types } from "reducers/investments";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import formTypes from "reducers/types/form";
import { call, put, take, takeLatest } from "redux-saga/effects";
import { downloadPdf, downloadXls } from "util/download";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LIST_FIXED_TERM_DEPOSITS_REQUEST, loadListRequest),
    takeLatest(types.LIST_FIXED_TERM_MORE_REQUEST, fetchMoreDeposits),
    takeLatest(types.READ_DEPOSIT_DETAIL_REQUEST, readDepositDetail),
    takeLatest(types.SIMULATE_DEPOSIT_REQUEST, simulateDepositRequest),
    takeLatest(types.SHAREHOLDER_ACCOUNTS_REQUESTED, fetchShareholderAccounts),
    takeLatest(types.MUTUAL_FUNDS_REQUESTED, fetchMutualFunds),
    takeLatest(types.FUND_OFFERS_REQUESTED, fetchFundOffers),
    takeLatest(types.AVAILABLE_FUNDS_REQUESTED, fetchAvailableFunds),
    takeLatest(types.OPERATING_FUNDS_REQUESTED, fetchOperatingFunds),
    takeLatest(types.FUND_MOVEMENTS_REQUESTED, fetchFundMovements),
    takeLatest(types.CANCEL_DEPOSIT_RENEWAL_PREVIEW_REQUEST, cancelRenewalPreviewRequest),
    takeLatest(types.DOWNLOAD_FUND_MOVEMENTS_REQUESTED, downloadMovements),
    takeLatest(types.VALIDATION_ACCESS_REQUESTED, accessRestrictionValidation),
    takeLatest(types.INVERSOR_PROFILE_REQUESTED, fetchInvestorProfile),
    takeLatest(types.CALCULATE_INVESTOR_PROFILE_REQUEST, calculateInvestorProfileRequest),
    takeLatest(types.SAVE_INVESTOR_PROFILE, saveInvestorProfile),
    takeLatest(types.DOWNLOAD_INVESTOR_PROFILE_REQUEST, downloadInvestorProfile),
];

export default sagas;

function* loadListRequest() {
    const response = yield call(investmentsMiddleware.loadListRequest);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "fixedTermDepositsList",
            ]),
        );
    } else {
        const { deposits, moreDeposits } = response.data.data;
        yield put(actions.loadListSuccess(deposits, moreDeposits));
    }
}

function* fetchMoreDeposits({ pageNumber }) {
    const response = yield call(investmentsMiddleware.loadListRequest, pageNumber);

    if (response && response.status === 200) {
        const { deposits, moreDeposits } = response.data.data;

        yield put(actions.fetchMoreFixedDepositsSuccess(deposits, pageNumber, moreDeposits));
    }
}

function* readDepositDetail({ idDeposit, operationOrder }) {
    const order = operationOrder || 0;
    const response = yield call(investmentsMiddleware.readDepositDetail, idDeposit, order);
    if (response.type === "W") {
        yield put(actions.readDepositDetailsFailure());
        yield put(
            replace({
                pathname: `/fixedTermDeposits`,
                state: { transition: NO_TRANSITION },
            }),
        );
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "fixedTermDepositsList",
            ]),
        );
    } else {
        const { deposit } = response.data.data;
        yield put(actions.readDepositDetailsSuccess(deposit));
    }
}

function* simulateDepositRequest({ formData }) {
    const { formikBag, values } = formData;
    const { quantity } = values.capitalToInvest;
    const finalValues = { ...values, idTransaction: null };
    const response = yield call(investmentsMiddleware.simulateDeposit, { ...finalValues, capitalToInvest: quantity });
    if (response.type === "W") {
        const { capitalToInvest, days, ...rest } = response.data.data;
        let fieldErrors = rest;
        let errors = {};
        // importante que sea un espacio y no vacio, para que aparezcan los bordes rojos de error sin mostrar texto
        const errorToHighlightField = " ";
        if (capitalToInvest && capitalToInvest !== i18n.get("forms.mariva.allFieldsMandatory")) {
            errors = { capitalToInvest };
            fieldErrors = { capitalToInvest: errorToHighlightField, ...fieldErrors };
        } else {
            fieldErrors = { capitalToInvest, ...fieldErrors };
        }
        if (days && days !== i18n.get("forms.mariva.allFieldsMandatory")) {
            errors = { days, ...errors };
            fieldErrors = { days: errorToHighlightField, ...fieldErrors };
        } else {
            fieldErrors = { days, ...fieldErrors };
        }
        yield put(actions.simulateDepositFailure(errors));
        yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors.custom"), "error", ["form"]));
        formikBag.setErrors(adjustIdFieldErrors(fieldErrors));
    } else {
        const { depositDetail } = response.data.data;
        yield put(actions.simulateDepositSuccess(depositDetail));
    }
    formikBag.setSubmitting(false);
}

function* fetchShareholderAccounts({ documentNumber }) {
    const response = yield call(investmentsMiddleware.loadShareholderAccounts, documentNumber);
    if (response && response.status === 200) {
        const { shareholderAccounts } = response.data.data;
        if (shareholderAccounts.length) {
            yield put(actions.loadShareholderAccountsSuccess(shareholderAccounts));
        } else {
            yield put(actions.loadShareholderAccountsFailure());
            yield put(actions.onToggleOptions("offer"));
            yield put(
                notificationActions.showNotification(
                    i18n.get("investments.mutualFund.accounts.notFound.message"),
                    "error",
                    ["mutualFunds"],
                ),
            );
        }
    }
}

function* fetchMutualFunds({ accountNumber, date = new Date(), documentNumber }) {
    let response = null;
    if (accountNumber) {
        const newFilters = { accountNumber, date };
        response = yield call(investmentsMiddleware.loadMutualFundsByAccount, accountNumber, date);
        yield put(actions.setFundListFilters(newFilters));
    } else {
        const responseShareHolders = yield call(investmentsMiddleware.loadShareholderAccounts, documentNumber);
        if (responseShareHolders && responseShareHolders.status === 200) {
            const { shareholderAccounts } = responseShareHolders.data.data;
            if (shareholderAccounts.length) {
                const { number } = shareholderAccounts[0];

                const newFilters = { accountNumber: number, date };
                response = yield call(investmentsMiddleware.loadMutualFundsByAccount, number, date);
                yield put(actions.setFundListFilters(newFilters));
                yield put(actions.loadShareholderAccountsSuccess(shareholderAccounts));
            } else {
                yield put(actions.loadShareholderAccountsFailure());
                yield put(actions.onToggleOptions("offer"));
                yield put(
                    notificationActions.showNotification(
                        i18n.get("investments.mutualFund.accounts.notFound.message"),
                        "error",
                        ["mutualFunds"],
                    ),
                );
            }
        }
    }

    if (response && response.status === 200) {
        const { mutualFunds, operationFunds } = response.data.data;
        yield put(actions.loadMutualFundByAccountSuccess(mutualFunds, operationFunds));
    }
}

function* fetchFundOffers({ accountNumber, date, fundType, riskType }) {
    const response = yield call(investmentsMiddleware.loadFundOffers);
    if (response && response.status === 200) {
        const { offerings } = response.data.data;
        if (offerings.length) {
            yield put(actions.loadFundOffersSuccess(offerings));
            yield put(actions.loadOperatingFunds(accountNumber, date, fundType, riskType));
        }
    }
}

function* fetchOperatingFunds({ accountNumber, date, fundType, riskType }) {
    const response = yield call(investmentsMiddleware.loadOperatingFunds, accountNumber, date, fundType, riskType);
    if (response && response.status === 200) {
        const { operatingFunds } = response.data.data;
        yield put(actions.loadOperatingFundsSuccess(operatingFunds));
    }
}

function* fetchAvailableFunds({ documentNumber, filters }) {
    if (!documentNumber) {
        yield call(refreshOrLoadMovements, filters.shareholderAccount, filters);
    } else {
        const response = yield call(investmentsMiddleware.loadShareholderAccounts, documentNumber);
        if (response && response.status === 200) {
            const { shareholderAccounts } = response.data.data;
            const account =
                filters.shareholderAccount === "" ? shareholderAccounts[0]?.number : filters.shareholderAccount;
            yield call(refreshOrLoadMovements, account, filters);

            while (yield take([types.FUND_MOVEMENTS_REQUEST_FAILED, types.FUND_MOVEMENTS_RECEIVED])) {
                yield put(actions.loadShareholderAccountsSuccess(shareholderAccounts));
            }
        }
    }
}

function* refreshOrLoadMovements(accountNumber, filters) {
    const { mutualFund, dateFrom, dateTo, pageNumber } = filters;
    const { status, data } = yield call(investmentsMiddleware.loadAvailableFunds, accountNumber);
    if (status === 200) {
        const { availableFunds } = data.data;
        yield put(actions.loadAvailableFundsSuccess(availableFunds));
        yield put(
            actions.loadFundMovements(
                accountNumber,
                mutualFund?.number ?? availableFunds[0]?.number,
                mutualFund?.typeId ?? availableFunds[0]?.typeId,
                dateFrom,
                dateTo,
                pageNumber,
            ),
        );
    }
}

function* fetchFundMovements({ accountNumber, fundNumber, fundClass, dateFrom, dateTo, pageNumber }) {
    const response = yield call(
        investmentsMiddleware.loadFundMovements,
        accountNumber,
        fundNumber,
        fundClass,
        dateFrom,
        dateTo,
        pageNumber,
    );
    if (response && response.status === 200) {
        const { movements } = response.data.data;
        const { nextPage } = response.data.data;
        const { hasNextPage } = response.data.data;
        yield put(actions.loadFundMovementsSuccess(movements, nextPage, hasNextPage));
    }
}

function* cancelRenewalPreviewRequest({ payload }) {
    const { idForm, idActivity, values } = payload;
    const { idProduct, operationOrder, operationCode, dueDate } = values;
    const response = yield call(
        investmentsMiddleware.cancelRenewalPreviewRequest,
        idProduct,
        operationOrder,
        operationCode,
        dueDate,
    );
    if (response && response.status === 200) {
        const responseCredentials = yield call(form.listCredentialsGroups, idForm, idActivity);
        yield put({
            type: formTypes.PREVIEW_FORM_SUCCESS,
            idForm,
            credentialsGroups: responseCredentials.data.data.groups,
            submitAction: formActions.sendForm,
            submitActionParams: {
                idForm: null,
                idActivity,
                idTransaction: null,
                values: { scheduler: null, ...values },
            },
            previewData: null,
            mode: "preview",
        });
    }
}

function* downloadMovements({
    accountNumber,
    accountName,
    fundNumber,
    fundName,
    fundClass,
    dateFrom,
    dateTo,
    pageNumber,
    format,
}) {
    const response = yield call(
        investmentsMiddleware.downloadFundMovements,
        accountNumber,
        accountName,
        fundNumber,
        fundName,
        fundClass,
        dateFrom,
        dateTo,
        pageNumber,
        format,
    );

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put(actions.downloadFundMovementsSuccess());
    } else {
        yield put(actions.downloadFundMovementsFailure());
    }
}

function* accessRestrictionValidation({ actionType, mutualFund }) {
    const response = yield call(investmentsMiddleware.validateAccessRestriction, actionType, mutualFund);
    if (response && response.status === 200) {
        const { isAccessAllowed, message } = response.data.data;

        yield put({
            type: types.VALIDATION_ACCESS_RECEIVED,
            isAccessAllowed,
        });

        if (isAccessAllowed) {
            yield put(
                push({
                    pathname: `/formCustom/requestInvestment${actionType}Form/`,
                    state: { transition: NO_TRANSITION },
                }),
            );
        } else {
            let bigTitle = "";
            let altText = "";
            if (message) {
                bigTitle = `${message.split(".")[0]}.`;
                altText = `${message.split(".")[1]}.`;
            }
            yield put(notificationModalActions.showNotification("", "error", altText, bigTitle));
        }
    }
}

function* fetchInvestorProfile({ segment }) {
    const response = yield call(investmentsMiddleware.getInvestorProfileBySegment, segment);

    if (response.type === "W") {
        yield put(actions.getInvestorProfileFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["investorProfile", "desktop"]));
        yield put(replace("/desktop"));
    } else {
        const { profile } = response.data.data;

        yield put(actions.getInvestorProfileSuccess(profile));
    }
}

function* calculateInvestorProfileRequest({ formData, questionsList, segment }) {
    const { formikBag, values } = formData;

    const filteredAnswers = [];

    /* eslint-disable */
    for (const property in values) {
        if (property.startsWith("FPI-PJ-F2")) {
            const questionFiltered = questionsList.find((item) => item.questionId === property);
            const answerFiltered = questionFiltered?.options.find((item) => item.id === values[property][0]);

            let item = {
                questionId: questionFiltered?.questionId,
                questionLabel: questionFiltered?.questionLabel,
                answerId: answerFiltered?.id,
                answerLabel: answerFiltered?.label,
            };

            filteredAnswers.push(item);
        }
    }
    /* eslint-enable */

    const response = yield call(investmentsMiddleware.calculateInvestorProfile, filteredAnswers, segment);
    const { type, data } = response;
    if (type === "W") {
        if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["form"]));
        } else {
            yield put(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
        }

        yield put(actions.calculateInvestorProfileFailure());
    } else {
        const { profile } = data.data;
        yield put(actions.calculateInvestorProfileSuccess(profile, filteredAnswers, formikBag));
    }
    formikBag.setSubmitting(false);
}

function* saveInvestorProfile({ payload }) {
    const { idActivity, values, formikBag } = payload;

    yield put(
        formActions.sendForm({
            idForm: null,
            idActivity,
            idTransaction: null,
            values: { scheduler: null, ...values },
            credentials: ["accessToken"],
            formikBag,
        }),
    );

    yield put({ type: types.RESET_CALCULATED_INVESTOR_PROFILE });
}

function* downloadInvestorProfile({ segment, profile }) {
    const response = yield call(investmentsMiddleware.downloadInvestorProfile, segment, profile);
    const { type, data } = response;
    if (type === "W") {
        yield put(actions.downloadInvestorProfileFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["investorProfile"]));
    } else {
        const { name, base64 } = data.data;

        if (base64) {
            downloadPdf(name, base64);
            yield put(actions.downloadInvestorProfileSuccess(name, base64));
            yield put(
                notificationActions.showNotification(i18n.get("notification.download.successful"), "success", [
                    "investorProfile",
                ]),
            );
        } else {
            yield put(actions.downloadInvestorProfileFailure());
            yield put(
                notificationActions.showNotification(
                    i18n.get("investments.mutualFund.investerProfile.download.error"),
                    "error",
                    ["investorProfile"],
                ),
            );
        }
    }
}
