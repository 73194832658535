import { createReducer, makeActionCreator } from "util/redux";

import { types as productsTypes } from "reducers/products";

export const types = {
    DETAILS_REQUEST: "creditCard/DETAILS_REQUEST",
    DETAILS_FAILURE: "creditCard/DETAILS_FAILURE",
    DETAILS_SUCCESS: "creditCard/DETAILS_SUCCESS",
    DOWNLOAD_MOVEMENTS_REQUEST: "creditCard/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_SUCCESS: "creditCard/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_MOVEMENTS_FAILURE: "creditCard/DOWNLOAD_MOVEMENTS_FAILURE",
    MOVEMENTS_REQUEST: "creditCardMovements/MOVEMENTS_REQUEST",
    MOVEMENTS_FAILURE: "creditCardMovements/MOVEMENTS_FAILURE",
    MOVEMENTS_SUCCESS: "creditCardMovements/MOVEMENTS_SUCCESS",
    SHOW_OPTIONS: "creditCardOptions/SHOW",
    HIDE_OPTIONS: "creditCardOptions/HIDE",
    FETCH_MORE_MOVEMENTS_REQUEST: "creditCardMovements/FETCH_MORE_MOVEMENTS_REQUEST",
    FETCH_MORE_MOVEMENTS_FAILURE: "creditCardMovements/FETCH_MORE_MOVEMENTS_FAILURE",
    FETCH_MORE_MOVEMENTS_SUCCESS: "creditCardMovements/FETCH_MORE_MOVEMENTS_SUCCESS",
};

export const INITIAL_STATE = {
    isFetching: true,
    isFetchingMovements: false,
    detail: null,
    movements: [],
    pageNumber: 0,
    hasMoreMovements: false,
    isOptionsVisible: false,
    from: null,
    filters: {},
    totalCount: 0,
    totalCurrentPeriod: 0,
};

export default createReducer(INITIAL_STATE, {
    [types.DETAILS_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
    }),
    [types.DETAILS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.DETAILS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        detail: action.detail,
        movements: action.movements,
        pageNumber: action.pageNumber,
        hasMoreMovements: action.hasMoreMovements,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
    }),
    [types.MOVEMENTS_REQUEST]: (state, action) => ({
        ...state,
        isFetchingMovements: true,
        filters: action.filters,
        pageNumber: 1,
    }),
    [types.FETCH_MORE_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: action.movements,
        hasMoreMovements: action.hasMoreMovements,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
    }),
    [types.FETCH_MORE_MOVEMENTS_FAILURE]: (state) => ({ ...state, isFetchingMovements: false }),
    [types.FETCH_MORE_MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: state.movements ? state.movements.concat(action.movements) : action.movements,
        hasMoreMovements: action.hasMoreMovements,
        pageNumber: action.pageNumber,
        totalCount: action.totalCount,
        totalCurrentPeriod: action.totalCurrentPeriod,
    }),
    [types.SHOW_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: true,
    }),
    [types.HIDE_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: false,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS]: (state, action) => ({
        ...state,
        detail: {
            ...state.detail,
            productAlias: action.alias,
        },
    }),
    [types.DOWNLOAD_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_MOVEMENTS_FAILURE]: () => ({}),
    [types.DOWNLOAD_MOVEMENTS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
});

export const actions = {
    detailRequest: makeActionCreator(types.DETAILS_REQUEST, "id", "filters"),
    detailFailure: makeActionCreator(types.DETAILS_FAILURE),
    detailSuccess: makeActionCreator(
        types.DETAILS_SUCCESS,
        "detail",
        "movements",
        "pageNumber",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
    ),
    movementsRequest: makeActionCreator(types.MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    movementsFailure: makeActionCreator(types.MOVEMENTS_FAILURE),
    movementsSuccess: makeActionCreator(
        types.MOVEMENTS_SUCCESS,
        "movements",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
    ),
    showOptions: makeActionCreator(types.SHOW_OPTIONS),
    hideOptions: makeActionCreator(types.HIDE_OPTIONS),
    fetchMoreMovementsRequest: makeActionCreator(types.FETCH_MORE_MOVEMENTS_REQUEST, "id", "filters", "formikBag"),
    fetchMoreMovementsFailure: makeActionCreator(types.FETCH_MORE_MOVEMENTS_FAILURE),
    fetchMoreMovementsSuccess: makeActionCreator(
        types.FETCH_MORE_MOVEMENTS_SUCCESS,
        "movements",
        "hasMoreMovements",
        "totalCount",
        "totalCurrentPeriod",
        "pageNumber",
    ),
    downloadMovements: makeActionCreator(types.DOWNLOAD_MOVEMENTS_REQUEST, "idCreditCard", "filters", "format"),
};

export const selectors = {
    getFetching: ({ creditCard }) => creditCard.isFetching,
    isFetchingMovements: ({ creditCard }) => creditCard.isFetchingMovements,
    getDetail: ({ creditCard }) => creditCard.detail,
    getMovements: ({ creditCard }) => creditCard.movements,
    isHasMoreMovements: ({ creditCard }) => creditCard.hasMoreMovements,
    getPageNumber: ({ creditCard }) => creditCard.pageNumber,
    isOptionsVisible: ({ creditCard }) => creditCard.isOptionsVisible,
    getFilters: ({ creditCard }) => creditCard.filters,
    getTotalCount: ({ creditCard }) => creditCard.totalCount,
    getTotalCurrentPeriod: ({ creditCard }) => creditCard.totalCurrentPeriod,
};
