import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";
import { func, bool, shape, string } from "prop-types";
import { Form, Field, withFormik } from "formik";

import * as i18n from "util/i18n";
import { selectors as templateSelectors, actions as templateActions } from "reducers/template";

import TextField from "pages/_components/fields/TextField";
import { resizableRoute } from "pages/_components/Resizable";
import ModalNotification from "pages/forms/_components/ModalNotification";
import SimpleModal from "components/SimpleModal/SimpleModal";
import Button from "pages/_components/Button";

const FORM_ID = "forms.template";

class CreatTemplateModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        isVisible: bool.isRequired,
        errors: shape({}).isRequired,
        modalButtonLabel: string,
    };

    static defaultProps = {
        modalButtonLabel: "global.send",
    };

    handleHide = () => {
        this.props.dispatch(templateActions.closeCreateModal());
    };

    render() {
        const { isSubmitting, isDesktop, isVisible, errors, modalButtonLabel } = this.props;

        return (
            <>
                <SimpleModal
                    className="create-template"
                    isDisplayed={isVisible}
                    closeModal={this.handleHide}
                    noCancelButton
                    showActions={false}
                    modalMainText={`${FORM_ID}.title`}>
                    <ModalNotification formId={FORM_ID} errors={errors} />
                    <Form>
                        <Field name="templateName">
                            {({ field, form }) => (
                                <TextField
                                    maxLength={100}
                                    pattern="^[a-zA-Z0-9 ]*$"
                                    autoFocus={isDesktop}
                                    idForm={FORM_ID}
                                    field={field}
                                    form={{ ...form, touched: { templateName: true } }}
                                    hidePlaceholder
                                    hideLabel
                                />
                            )}
                        </Field>

                        <Button
                            className="ui-mt-7"
                            block
                            type="submit"
                            label={modalButtonLabel}
                            bsStyle="primary"
                            loading={isSubmitting}
                        />
                    </Form>
                </SimpleModal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: templateSelectors.isCreateModalVisible(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            templateName: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                templateName: Yup.string()
                    .trim()
                    .required(i18n.get(`${FORM_ID}.templateName.required`)),
            }),
        handleSubmit: ({ templateName }, formikBag) => {
            const { idForm, values, idActivityTemplate } = formikBag.props;

            formikBag.props.dispatch(
                templateActions.saveTemplate(idForm, values, templateName, formikBag, idActivityTemplate),
            );

            formikBag.resetForm();
        },
    }),
    resizableRoute,
)(CreatTemplateModal);
