import React from "react";
import { string, node } from "prop-types";

function WidgetHeader({ title, action }) {
    return (
        <header className="flex-container">
            <h2>{title}</h2>
            {action}
        </header>
    );
}

WidgetHeader.propTypes = {
    title: string.isRequired,
    action: node,
};
WidgetHeader.defaultProps = {
    action: null,
};

export default WidgetHeader;
