import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { func, shape, bool, arrayOf, string } from "prop-types";
import { withRouter } from "react-router-dom";
import { resizableRoute } from "pages/_components/Resizable";
import { actions as notificationActions } from "reducers/notification";
import { get as getMessage } from "util/i18n";
import WithPermissions from "./WithPermissions";

class WithPermissionsRedirect extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        permissions: arrayOf(string),
        children: shape({}),
        comparator: func,
        somePermissions: bool,
        activeEnvironment: shape({
            permissions: shape({}),
        }).isRequired,
        location: shape({
            pathname: string,
        }),
    };

    static defaultProps = {
        children: null,
        comparator: null,
        somePermissions: false,
        permissions: null,
        location: null,
    };

    redirectFunc = (permissionsList) => {
        const { dispatch, activeEnvironment, location } = this.props;
        let errorMessage = null;
        let routeRedirect = "/desktop";

        if (
            permissionsList != null &&
            (permissionsList.includes("loans") ||
                (permissionsList.includes("accounts") && !permissionsList.includes("listEcheqs")))
        ) {
            errorMessage = "products.operation.NoDispose";
        } else if (
            permissionsList != null &&
            (permissionsList.includes("listEcheqs") || permissionsList.includes("echeqs.accountAdherence"))
        ) {
            if (
                !activeEnvironment.permissions["echeqs.termsAndConditions.alreadySigned"] &&
                !activeEnvironment.permissions["echeqs.termsAndConditions.adherence"]
            ) {
                errorMessage = "menu.feature.disabled";
            } else if (permissionsList.includes("listEcheqs") && activeEnvironment.permissions.listEcheqs) {
                errorMessage = "products.operation.NoDispose";
            } else {
                errorMessage = "returnCode.COR005E";
            }
        } else {
            if (
                activeEnvironment.permissions.accounts &&
                activeEnvironment.permissions.listEcheqs &&
                location != null &&
                location.pathname != null &&
                location.pathname.includes("/echeqs") &&
                !location.pathname.includes("/echeqs/management")
            ) {
                routeRedirect = "/echeqs";
            }
            errorMessage = "returnCode.COR005E";
        }

        dispatch(notificationActions.showNotification(getMessage(errorMessage), "error", ["menu"]));
        dispatch(push(routeRedirect));
    };

    render() {
        const { permissions, children, comparator, somePermissions, activeEnvironment } = this.props;
        const finalPermissions = permissions;
        if (permissions?.includes("listEcheqs") || permissions?.includes("echeqs.accountAdherence")) {
            if (activeEnvironment.permissions["echeqs.termsAndConditions.alreadySigned"]) {
                finalPermissions.push("echeqs.termsAndConditions.alreadySigned");
            } else {
                finalPermissions.push("echeqs.termsAndConditions.adherence");
            }
        }

        return (
            <WithPermissions
                somePermissions={somePermissions}
                permissions={finalPermissions}
                comparator={comparator || null}
                redirectFunc={() => this.redirectFunc(finalPermissions)}>
                {children}
            </WithPermissions>
        );
    }
}

function mapStateToProps(state) {
    return {
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    };
}

export default withRouter(connect(mapStateToProps)(resizableRoute(WithPermissionsRedirect)));
