import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { func, bool, string, shape } from "prop-types";
import * as i18nUtils from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import { isMobileNative } from "util/device";
import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";

class PendingTransactionsListItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idForm: string,
        activityName: string.isRequired,
        idTransaction: string.isRequired,
        isDesktop: bool,
        creationDateTime: string.isRequired,
        onlyDrafts: bool.isRequired,
        idTransactionStatus: string.isRequired,
        valueDateTime: string,
        data: shape({}).isRequired,
        idActivity: string.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
        valueDateTime: null,
        idForm: "",
    };

    renderModifiedDate = () => {
        const { onlyDrafts, idTransactionStatus, valueDateTime, data } = this.props;
        const { scheduler } = data;
        if (onlyDrafts) {
            return null;
        }
        if (idTransactionStatus === "DRAFT") {
            return "-";
        }
        return (
            <Fragment>
                <span className="data-label">{i18nUtils.get(`transactions.list.item.${idTransactionStatus}`)}</span>{" "}
                <span className="data-info">
                    <FormattedDate date={scheduler ? scheduler.valueDate : valueDateTime} />
                </span>
            </Fragment>
        );
    };

    onMobileItemCLick = (event) => {
        const { idActivity, idTransactionStatus } = this.props;
        const { dispatch, idForm } = this.props;
        if (idForm === SALARY_PAYMENT_ID_FORM) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "desktop",
                ]),
            );
        } else if (idActivity === "pay.transfers.massive.send" && idTransactionStatus === "DRAFT" && isMobileNative) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "desktop",
                ]),
            );
        }
    };

    render() {
        const {
            activityName,
            idActivity,
            idTransaction,
            idTransactionStatus,
            isDesktop,
            creationDateTime,
            valueDateTime,
            data,
        } = this.props;
        const { scheduler, amount, emissionType } = data;
        const totalAmount =
            idActivity === "echeqs.emitEcheq.send" && emissionType?.[0] === "multiple" ? data?.totalAmount : null;
        const finalAmount = totalAmount || amount;
        const finalActivityName = data?.activitySuffix
            ? i18nUtils.get(`activities.${idActivity.replace("send", `${data.activitySuffix}.send`)}`)
            : activityName;
        if (isDesktop) {
            return (
                <div className="table-row-container">
                    <Link className="table-row" to={`/transaction/${idTransaction}`}>
                        <div className="table-data data-details">
                            <span className="data-info">{finalActivityName}</span>
                        </div>
                        <div className="table-data data-icon">
                            <div className="data-text">
                                <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                            </div>
                        </div>
                        <div className="table-data data-date">
                            <div className="data-label">{i18nUtils.get("transactions.list.item.created")}</div>{" "}
                            <div className="data-info">
                                <FormattedDate date={creationDateTime} />
                            </div>
                        </div>
                        <div className="table-data data-date">{this.renderModifiedDate()}</div>
                        <div className="table-data data-number">
                            {amount && (
                                <FormattedAmount quantity={finalAmount.quantity} currency={finalAmount.currency} />
                            )}
                        </div>
                    </Link>
                </div>
            );
        }
        return (
            <div className="table-row-container">
                <Link
                    className="table-row"
                    onClick={(e) => this.onMobileItemCLick(e)}
                    to={`/transaction/${idTransaction}`}>
                    <div className="table-data data-icon">
                        <div className="data-text">
                            <TransactionStatus idTransactionStatus={idTransactionStatus} showLabel />
                        </div>
                    </div>
                    <div className="table-data data-details">
                        <span className="data-info">{finalActivityName}</span>
                    </div>
                    {(idTransactionStatus === "DRAFT" && <Fragment />) || (
                        <div className="table-data data-details">
                            <span className="data-info data-small">
                                {i18nUtils.get(`transactions.list.item.${idTransactionStatus}`)}{" "}
                                <FormattedDate date={scheduler ? scheduler.valueDate : valueDateTime} />
                            </span>
                        </div>
                    )}
                    {amount && (
                        <div className="table-data data-number">
                            <FormattedAmount quantity={amount.quantity} currency={amount.currency} />
                        </div>
                    )}
                </Link>
            </div>
        );
    }
}
export default resizableRoute(PendingTransactionsListItem);
