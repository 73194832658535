import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, withFormik, Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import Select from "pages/forms/_components/_fields/Select";
import Checkbox from "pages/_components/Checkbox";
import CommonFrequencySubOption from "pages/forms/_components/_scheduler/CommonFrequencySubOption";
import CustomFrequencySubOption from "pages/forms/_components/_scheduler/CustomFrequencySubOption";
import moment from "moment";
import I18n from "pages/_components/I18n";
import ModalNotification from "pages/forms/_components/ModalNotification";
import BigModal from "pages/_components/BigModal/BigModal";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

const dayNoWorking = 7 - moment(new Date()).day();
const STARTS_ON_DATE =
    dayNoWorking === 1 || dayNoWorking === 2
        ? moment(new Date()).add(dayNoWorking + 1, "days")
        : moment(new Date()).add(1, "days");

class SchedulerModal extends Component {
    static propTypes = {
        errors: shape({}),
        goBack: func,
        handleCloseBottomSheetClick: func,
        idForm: string.isRequired,
        isDisplayed: bool,
        isDesktop: bool.isRequired,
        onScheduleClick: func,
        setErrors: func,
        setTouched: func,
        values: shape({}).isRequired,
        initialValues: shape({}).isRequired,
        setValues: func.isRequired,
        titleLabelCustom: string,
        disclaimerLabelCustom: string,
    };

    static defaultProps = {
        goBack: () => {},
        onScheduleClick: () => {},
        setTouched: () => {},
        setErrors: () => {},
        handleCloseBottomSheetClick: null,
        errors: {},
        isDisplayed: false,
        titleLabelCustom: null,
        disclaimerLabelCustom: null,
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    validateFields = () => {
        const { setErrors, setTouched, values } = this.props;
        let res = true;
        // clean errors
        setErrors({});

        const moreThan2daysaWeek = dateUtils.moreThan2daysSelected(values.days);
        let toucheds = {};
        let errors = {};
        if (values.acceptCondition === false) {
            toucheds = {
                ...toucheds,
                acceptCondition: true,
            };
            errors = {
                ...errors,
                acceptCondition: { value: i18nUtils.get(`scheduler.acceptCondition.empty`) },
            };
            res = false;
        }

        // validate if it's week and days is just one day and freqValue = 1
        if (values.customFreq === schedulerUtils.WEEK) {
            if (moreThan2daysaWeek && values.customFreqValue !== "1") {
                toucheds = {
                    ...toucheds,
                    days: true,
                };
                errors = {
                    ...errors,
                    days: { value: i18nUtils.get(`scheduler.multipleDaysSelectedValidation`) },
                };
                res = false;
            }
            if (values.days === 0) {
                toucheds = {
                    ...toucheds,
                    days: true,
                };
                errors = {
                    ...errors,
                    days: { value: i18nUtils.get(`scheduler.days.empty`) },
                };
                res = false;
            }
        }

        // starts on must be after than today
        if (moment(values.startsOn).isBefore(moment())) {
            toucheds = {
                ...toucheds,
                startsOn: true,
            };
            errors = {
                ...errors,
                startsOn: i18nUtils.get(`scheduler.startsOnBeforeToday`),
            };
            res = false;
        }
        if (Object.keys(errors).length) {
            setTouched({ ...toucheds });
            setErrors({ ...errors });
        }

        return res;
    };

    onScheduleClick = () => {
        const { onScheduleClick, values } = this.props;

        if (this.validateFields()) {
            onScheduleClick(values);
        }
    };

    handleDismissModal = () => {
        const { handleCloseBottomSheetClick, initialValues, setValues } = this.props;
        if (handleCloseBottomSheetClick) {
            handleCloseBottomSheetClick();
        }

        setValues(initialValues);
    };

    render() {
        const {
            errors,
            idForm,
            isDisplayed,
            isDesktop,
            values: { lapse, frequency, customFreq },
            titleLabelCustom,
            disclaimerLabelCustom,
        } = this.props;

        return (
            <BigModal
                handleShowModal={this.handleDismissModal}
                isMobile={!isDesktop}
                modalTitle={titleLabelCustom || "scheduler.title"}
                showModal={isDisplayed}
                className="modal-scheduler">
                <Form className="form-content scheduler">
                    <ModalNotification formId={idForm} errors={errors} />

                    <div className="form-group">
                        <div className="form-text-group">
                            <label id={`label.${idForm}.frequency`} className="control-label">
                                <I18n id="scheduler.frequency" />
                            </label>
                        </div>
                        <div className="input-group">
                            <Field
                                id={`${idForm}.frequency`}
                                component={Select}
                                name="frequency"
                                className="slideFromBottom"
                                options={schedulerUtils.listRecurrencies()}
                                clearable={false}
                                searchable={false}
                                aria-owns="endRulesFieldsetID"
                                aria-haspopup
                                aria-expanded={isDisplayed}
                            />
                        </div>
                    </div>
                    {frequency !== schedulerUtils.CUSTOM ? (
                        <CommonFrequencySubOption lapse={lapse} frequency={frequency} startsOn={STARTS_ON_DATE} />
                    ) : (
                        <CustomFrequencySubOption
                            lapse={lapse}
                            frequency={frequency}
                            startsOn={STARTS_ON_DATE}
                            customFreq={customFreq}
                        />
                    )}

                    <div className="termsAndConditions">
                        <DisclaimerContainer
                            maxHeight="98px"
                            disclaimer={disclaimerLabelCustom || "forms.transfers.scheduler.text"}
                            textSmall
                        />
                    </div>
                    <Field
                        name="acceptCondition"
                        id="acceptCondition"
                        component={Checkbox}
                        idForm={idForm}
                        errors={errors}
                    />
                    <Button
                        block
                        type="button"
                        bsStyle="primary"
                        label="scheduler.schedule"
                        onClick={this.onScheduleClick}
                    />
                </Form>
            </BigModal>
        );
    }
}

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value } = props;
            const newProgram = {
                frequency: schedulerUtils.ONCE,
                startsOn:
                    value && value.selectedOption === schedulerUtils.ONCE
                        ? value.valueDate.toDate()
                        : STARTS_ON_DATE.toDate(),
                date:
                    value && value.selectedOption === schedulerUtils.ONCE
                        ? value.valueDate.toDate()
                        : STARTS_ON_DATE.toDate(),
                lapse: {
                    date: STARTS_ON_DATE.toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
                acceptCondition: false,
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;
            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
                acceptCondition: false,
            };
        },
    }),
)(SchedulerModal);
