import React from "react";

import Alert from "pages/_components/Alert/Alert";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import { shape } from "prop-types";
import InvestorProfileImage from "./InvestorProfileImage";
import InvestorProfileName from "./InvestorProfileName";

import "./_investorProfile.scss";

const InvestorProfileType = ({ profile }) => {
    const renderAlert = profile.status && profile.status !== "upToDate";

    const alertIcon =
        profile.status === "toExpire"
            ? "images/ui-icons/ui-exclamation-orange.svg"
            : "images/ui-icons/ui-exclamation-red.svg";

    return (
        <>
            <div className="profile-container">
                <InvestorProfileImage profile={profile} />

                <div className="invester-text">
                    <InvestorProfileName profileName={profile.type} usedFor="forProfile" component="p" />

                    {profile.expirationDate && (
                        <span className={profile.status}>
                            <I18n id="investments.mutualFund.investerProfile.expirationDate" />
                            <FormattedDate date={profile.expirationDate} />
                        </span>
                    )}
                </div>

                {renderAlert && (
                    <Alert icon={alertIcon}>
                        <I18n
                            id={`investments.mutualFund.investerProfile.expirationAlert.${profile.status}`}
                            componentProps={{ className: "ui-fw-sbold" }}
                        />
                    </Alert>
                )}
            </div>
        </>
    );
};

InvestorProfileType.propTypes = {
    profile: shape({}).isRequired,
};

export default InvestorProfileType;
