import React, { Component } from "react";

import classNames from "classnames";
import { string, oneOf, bool } from "prop-types";

import * as utils from "util/general";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

import "./_transactionStatus.scss";

class TransactionStatus extends Component {
    static propTypes = {
        idTransactionStatus: string.isRequired,
        iconFidelity: oneOf(["transaction", "ticket"]),
        showLabel: bool,
        showIcon: bool,
    };

    static defaultProps = {
        iconFidelity: "transaction",
        showLabel: false,
        showIcon: true,
    };

    render() {
        const { idTransactionStatus, iconFidelity, showIcon, showLabel } = this.props;
        return (
            <div className="transaction-status">
                {showIcon && (
                    <div className="transaction-status__icon">
                        <Image
                            src={`images/${iconFidelity}StatusIcons/${utils.getTransactionStatusIcon(
                                idTransactionStatus,
                            )}.svg`}
                            className={classNames("svg-icon", {
                                "svg-icon-big": iconFidelity === "ticket",
                            })}
                        />
                    </div>
                )}
                {showLabel && (
                    <div className="transaction-status__text">
                        <I18n id={`transaction.status.${idTransactionStatus}`} />
                    </div>
                )}
            </div>
        );
    }
}

export default TransactionStatus;
