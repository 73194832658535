import { call, put, takeLatest } from "redux-saga/effects";
import * as file from "middleware/file";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as massPaymentsActions, types } from "reducers/massPayments";
import * as i18n from "util/i18n";
import { base64toBlob } from "util/file";

import { download, downloadCsv, downloadXls, downloadPdf } from "util/download";
import * as api from "../middleware/massPayments";

const sagas = [
    takeLatest([types.DOWNLOAD_LINES_REQUEST], downloadLines),
    takeLatest(types.LIST_TRANSFER_TRANSACTIONS_REQUEST, getListOfTransferPayments),
    takeLatest(types.ADD_EDIT_PAYMENT_REQUEST, saveOrEditPayment),
    takeLatest(types.SUMMARY_PAYMENT_REQUEST, getPaymentSummary),
    takeLatest(types.DOWNLOAD_FILE_EXAMPLE_REQUEST, downloadPaymentSampleFile),
    takeLatest(types.DOWNLOAD_PAYMENTS_CVS_FILE, downloadPaymentsCsvFile),
    takeLatest(types.SET_PAYMENTS_REQUEST, setPaymentsRequest),
    takeLatest(types.DOWNLOAD_TICKET_DETAILS_REQUEST, downloadTicketDetails),
];

export default sagas;

function* downloadLines({ idTransaction, filters, format }) {
    const { type, data } = yield call(file.downloadLines, idTransaction, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LINES_FAILURE });

        // TODO: Change environment of notification
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "txt") {
            download(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LINES_SUCCESS });
    }
}

function* getListOfTransferPayments({ transactionId, pageNumber }) {
    const response = yield call(api.getListOfTransferPayments, transactionId, pageNumber);

    if (response.status === 200) {
        yield put(massPaymentsActions.getListOfTransferPaymentsSuccess(response.data.data));
    } else {
        yield put(massPaymentsActions.getListOfTransferPaymentsFailure());
        yield notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["payments/list"]);
    }
}

function* saveOrEditPayment({ payment, paymentId }) {
    const { name, cuit, cbu, amount, reference } = payment;
    const response = yield call(
        api.validatePaymentByTransfer,
        payment?.debitAccount,
        name,
        cuit,
        cbu,
        amount,
        reference,
    );

    if (response.type === "W") {
        yield put(massPaymentsActions.addOrEditPaymentFailure(response.data.data));
    } else {
        const paymentToSave = {
            debitAccount: payment?.debitAccount,
            name,
            cuit,
            cbu,
            amount,
            reference,
        };
        if (!paymentId) {
            paymentToSave.id = response?.data?.idTransaction;
            yield put(massPaymentsActions.addPayment(paymentToSave));
        } else {
            paymentToSave.id = paymentId;
            yield put(massPaymentsActions.editPayment(paymentToSave, paymentId));
            yield put(
                notificationModalActions.showNotification(
                    "forms.pay.transfers.massive.payment.list.edit.confirmationModal.success.label",
                    "success",
                ),
            );
        }
        yield put(massPaymentsActions.updatePaymentDetails());
        yield put(massPaymentsActions.clearErrors());
    }
}

function* getPaymentSummary({ transactionId }) {
    const response = yield call(api.getPaymentSummary, transactionId);

    if (response.type === "W") {
        yield put(massPaymentsActions.getPaymentSummaryFailure());
    } else {
        yield put(massPaymentsActions.getPaymentSummarySuccess(response.data.data));
    }
}

function* downloadPaymentSampleFile() {
    const response = yield call(api.downloadPaymentSampleFile);

    if (response.type !== "W") {
        const blob = base64toBlob(response.data.data.content, "text/csv");
        downloadCsv(response.data.data.fileName, blob);
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.pay.transfers.massive.payment.file.download.message.success"),
                "success",
                ["form"],
            ),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.pay.transfers.massive.payment.file.download.message.error"),
                "error",
                ["form"],
            ),
        );
    }
}

function* downloadPaymentsCsvFile({ transactionId, transactionStatusLabel, paymentDetails, paymentsToDownload }) {
    const response = yield call(
        api.downloadPaymentsCsvFile,
        transactionId,
        transactionStatusLabel,
        paymentDetails,
        paymentsToDownload,
    );

    if (response.type !== "W") {
        const blob = base64toBlob(response.data.data.content, "text/csv");
        downloadCsv(response.data.data.fileName, blob);
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.pay.transfers.massive.payment.file.download.message.success"),
                "success",
                ["transfer_massive_step2"],
            ),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("forms.pay.transfers.massive.payment.file.download.message.error"),
                "error",
                ["transfer_massive_step2"],
            ),
        );
    }
}

function* setPaymentsRequest({ payments, additionalData }) {
    if (payments && payments.length > 0) {
        yield put(massPaymentsActions.setPaymentsSuccess(payments));
        yield put(massPaymentsActions.updatePaymentDetails(additionalData));
    } else {
        yield put(massPaymentsActions.setPaymentsSuccess(null));
        yield put(massPaymentsActions.setPaymentDetails(null));
    }
}

function* downloadTicketDetails({ idTransaction, downloadType, payments }) {
    try {
        const { type, data } = yield call(api.downloadTicketDetails, idTransaction, downloadType, payments);
        if (type === "W") {
            yield put({ type: types.DOWNLOAD_TICKET_DETAILS_FAILURE });
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", ["transfer_massive_step2"]));
        } else {
            const { content, fileName } = data.data;
            if (downloadType === "single" && fileName.includes(".pdf")) {
                yield call(downloadPdf, fileName, content);
                yield put({ type: types.DOWNLOAD_TICKET_DETAILS_SUCCESS });
                yield put(
                    notificationActions.showNotification(i18n.get("notification.download.successful"), "success", [
                        "transfer_massive_step2",
                    ]),
                );
            } else if (downloadType === "multiple" && fileName.includes(".zip")) {
                const blob = base64toBlob(content, "zip");
                yield downloadCsv(fileName, blob);
                yield put({ type: types.DOWNLOAD_TICKET_DETAILS_SUCCESS });
                yield put(
                    notificationActions.showNotification(i18n.get("notification.download.successful"), "success", [
                        "transfer_massive_step2",
                    ]),
                );
            }
        }
    } catch (error) {
        yield put({ type: types.DOWNLOAD_TICKET_DETAILS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "transfer_massive_step2",
            ]),
        );
    }
}
