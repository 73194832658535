import * as API from "middleware/api";
import { format } from "date-fns";

export const listReceiptsComex = (filters) => {
    let { dateFrom, dateTo } = filters;
    const { pageNumber, channel, client } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/list.receiptsComex", {
        dateFrom,
        dateTo,
        pageNumber,
        channel,
        client,
    });
};

export const downloadReceipt = (path, fileName) =>
    API.executeWithAccessToken("/download.receiptsComex", {
        path,
        fileName,
    });
