import React, { useEffect } from "react";
import { Field } from "formik";
import { func, shape, bool, string, number } from "prop-types";
import moment from "moment";
import defaultDateFromList from "util/defaultDateFromList";
import * as i18nUtils from "../../../../../util/i18n";
import * as FormFieldsComponents from "../../../_components/_fields/Index";
import EmitEcheqCommonFields from "./EmitEcheqCommonFields";

const EmitIndividualEcheq = (props) => {
    const {
        data,
        ID_FORM,
        amountData,
        handleAmount,
        handleEcheqType,
        handleOtherFields,
        otherFieldsSelected,
        genericProps,
        amountSelected,
        echeqTypeSelected,
        conceptsList,
        previewData,
        isPersonalizedTicket,
        setFieldValue,
        disableComponents,
    } = props;

    const echeq = previewData?.echeqs?.[0] || data?.echeqs?.[0] || null;
    const echeqTypeToUse = isPersonalizedTicket ? [echeq?.cheque_tipo] : data?.echeqType || [echeqTypeSelected];
    const characterToUse = isPersonalizedTicket
        ? [echeq?.cheque_caracter]
        : data?.character || [otherFieldsSelected?.character];
    const amountToUse = isPersonalizedTicket
        ? data?.totalAmount
        : data?.amount || { currency: "ARS", quantity: echeq?.monto || amountSelected };

    useEffect(() => {
        // no alcanzaba con pasarle los values en los componentes para estos 3 campos
        if (setFieldValue && isPersonalizedTicket) {
            setFieldValue("amount", amountToUse);
            setFieldValue("character", characterToUse);
            setFieldValue("echeqType", echeqTypeToUse);
        }
    }, [isPersonalizedTicket]);

    return (
        <>
            <EmitEcheqCommonFields {...props} isPersonalizedTicket={isPersonalizedTicket} />

            <div className="form-group--double-field form-group-echeq-individual">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="echeqType"
                    name="echeqType"
                    idField="echeqType"
                    renderAs="combo"
                    onChange={(e) => handleEcheqType(e.id)}
                    optionList={[
                        {
                            id: "today",
                            label: i18nUtils.get(`${ID_FORM}.echeqType.today.label`),
                        },
                        {
                            id: "deferred",
                            label: i18nUtils.get(`${ID_FORM}.echeqType.deferred.label`),
                        },
                    ]}
                    value={echeqTypeToUse}
                    disabled={disableComponents}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Date}
                    key="paymentDate"
                    name="paymentDate"
                    idField="paymentDate"
                    disabled={echeqTypeSelected === "today"}
                    editing={false}
                    value={otherFieldsSelected?.paymentDate}
                    onChange={(e) => handleOtherFields("paymentDate", e)}
                    minDate={echeqTypeSelected === "today" ? moment() : moment().add(1, "days")}
                    maxDate={defaultDateFromList().echeqs.maxDaysToEmmit}
                />
            </div>

            <div className="form-group--double-field">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="character"
                    name="character"
                    idField="character"
                    renderAs="combo"
                    optionList={[
                        {
                            id: "transferable",
                            label: i18nUtils.get(`${ID_FORM}.character.transferable.label`),
                        },
                        {
                            id: "nonTransferable",
                            label: i18nUtils.get(`${ID_FORM}.character.nonTransferable.label`),
                        },
                    ]}
                    onChange={(e) => handleOtherFields("character", e.id)}
                    value={characterToUse}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="mode"
                    name="mode"
                    idField="mode"
                    renderAs="combo"
                    optionList={[
                        {
                            id: "crossed",
                            label: i18nUtils.get(`${ID_FORM}.mode.crossed.label`),
                        },
                    ]}
                    value={["crossed"]}
                />
            </div>
            <Field
                {...genericProps}
                component={FormFieldsComponents.Amount}
                key="amount"
                name="amount"
                idField="amount"
                value={amountToUse}
                data={amountData}
                handleChange={(e) => handleAmount(e, "amount")}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                key="concept"
                name="concept"
                idField="concept"
                renderAs="combo"
                optionList={conceptsList}
                onChange={(e) => handleOtherFields("concept", e.id)}
                value={data?.concept || [otherFieldsSelected?.concept]}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reason"
                name="reason"
                idField="reason"
                onChange={(e) => handleOtherFields("reason", e)}
                value={data?.reason || otherFieldsSelected.reason}
                maxLength={150}
                additionalClassName="reason-input"
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reference"
                name="reference"
                idField="reference"
                value={data?.reference || otherFieldsSelected.reference}
                onChange={(e) => handleOtherFields("reference", e)}
                maxLength={20}
            />
        </>
    );
};
EmitIndividualEcheq.propTypes = {
    mode: string.isRequired,
    authorizedAccountsOptions: shape([]).isRequired,
    data: shape({}).isRequired,
    ID_FORM: string.isRequired,
    searchSuccessAction: func.isRequired,
    bankedCustomerInfo: shape({}).isRequired,
    handleEmissionType: func.isRequired,
    emissionTypeSelected: string.isRequired,
    amountData: shape({}).isRequired,
    setAmountSelected: func.isRequired,
    handleEcheqType: func.isRequired,
    handleOtherFields: func.isRequired,
    otherFieldsSelected: shape({}).isRequired,
    genericProps: shape({}).isRequired,
    handleChangeAccount: func.isRequired,
    isMobile: bool.isRequired,
    amountSelected: number.isRequired,
    echeqTypeSelected: shape([]).isRequired,
    isPersonalizedTicket: bool,
    handleAmount: func.isRequired,
    conceptsList: shape([]).isRequired,
    previewData: shape({}).isRequired,
    setFieldValue: func,
    disableComponents: bool.isRequired,
};

EmitIndividualEcheq.defaultProps = {
    isPersonalizedTicket: false,
    setFieldValue: null,
};

export default EmitIndividualEcheq;
