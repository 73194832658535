import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { bool, func, number as numberProp, shape, string } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { actions as filterActions } from "reducers/filters";

class CheckDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        check: shape({
            date: string,
            checkNumber: string,
            state: string,
            amount: numberProp,
            currency: string,
            productType: string,
            taxesList: shape(),
            businessName: string,
        }).isRequired,
        isMobile: bool.isRequired,
        checkTypeSelected: string.isRequired,
        checkDeferred: shape({}).isRequired,
        fetching: bool,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { dispatch, checkDeferred } = this.props;
        if (checkDeferred === null || Object.keys(checkDeferred).length === 0) {
            this.handleBack();
        } else {
            const { type, check } = checkDeferred;
            const { branch, operationNumber, checkNumber, orderNumber, idCheck } = check;
            dispatch(
                checksActions.checkDeferredDetailRequest(
                    type,
                    branch,
                    operationNumber,
                    checkNumber,
                    orderNumber,
                    idCheck,
                ),
            );
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.setResetFilters(false));
        dispatch(goBack());
    };

    renderTaxes = () => {
        const { check } = this.props;
        const { taxesList, currency } = check;

        return (
            taxesList &&
            taxesList.map((taxes) => (
                <div className="data-wrapper">
                    <p>{taxes.description}</p>
                    <FormattedAmount className="formattedAmount" currency={currency || "ARS"} quantity={taxes.amount} />
                </div>
            ))
        );
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" onBack={this.handleBack} arrowWhite />;
        }
        return <Head onBack={this.handleBack} showPlusIcon />;
    };

    renderItemsDiscounted = () => {
        const { check, isMobile } = this.props;
        const {
            finalExpiration,
            businessName,
            address,
            addressNumber,
            addressFloor,
            addressApartment,
            postalCode,
            location,
            currency,
            cuit,
            iva,
            taxesList,
            cft,
            agreedRate,
            operationType,
            liquidity,
            liquidityCapital,
            liquidityInterest,
            netAmount,
            voucherSettlement,
        } = check;

        return (
            <>
                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.discountDate.label" componentProps={{ className: "data-label" }} />

                    <span className="data-date">
                        <FormattedDate date={voucherSettlement} />
                    </span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.businessName.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">{businessName}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.address.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">
                        <span>{`${address}, Nro ${addressNumber}, ${addressFloor}, ${addressApartment}`}</span>
                        {isMobile && <br />}
                        <span>{`C.P. ${postalCode}, - ${location}`}</span>
                    </span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.cuit.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">{cuit}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.taxRate.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">{iva}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.operationType.label" componentProps={{ className: "data-label" }} />
                    <span className="data-desc">{operationType}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.currency.label" componentProps={{ className: "data-label" }} />
                    <span className="data-desc">{currency}</span>
                </li>

                <li className="data-wrapper">
                    <I18n
                        id="check.deferred.detail.expirationFinal.label"
                        componentProps={{ className: "data-label" }}
                    />

                    <span className="data-date">
                        <FormattedDate date={finalExpiration} />
                    </span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.agreedRate.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">{agreedRate}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.cft.label" componentProps={{ className: "data-label" }} />

                    <span className="data-desc">{cft}</span>
                </li>

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.liquidity.label" componentProps={{ className: "data-label" }} />
                    <span className="data-desc">{liquidity}</span>
                </li>

                <li className="data-wrapper">
                    <I18n
                        id="check.deferred.detail.liquidityCapital.label"
                        componentProps={{ className: "data-label" }}
                    />

                    <FormattedAmount
                        className="formattedAmount"
                        currency={currency || "ARS"}
                        quantity={liquidityCapital}
                    />
                </li>

                <li className="data-wrapper">
                    <I18n
                        id="check.deferred.detail.liquidityInterest.label"
                        componentProps={{ className: "data-label" }}
                    />

                    <FormattedAmount
                        className="formattedAmount"
                        currency={currency || "ARS"}
                        quantity={liquidityInterest}
                    />
                </li>

                {taxesList && taxesList.length > 0 && (
                    <li className="data-wrapper">
                        <I18n id="check.deferred.detail.taxes.label" componentProps={{ className: "data-label" }} />
                        {this.renderTaxes()}
                    </li>
                )}

                <li className="data-wrapper">
                    <I18n id="check.deferred.detail.netAmount.label" componentProps={{ className: "data-label" }} />
                    <FormattedAmount className="formattedAmount" currency={currency || "ARS"} quantity={netAmount} />
                </li>
            </>
        );
    };

    render() {
        const { fetching, check, checkTypeSelected } = this.props;
        const {
            finalExpiration,
            operationNumber,
            bank,
            amount,
            businessName,
            operationDate,
            currency,
            taxesList,
            number,
        } = check;
        const isDiscounted = checkTypeSelected === "descontados";
        return (
            check && (
                <Fragment>
                    {this.renderHeader()}
                    <Notification scopeToShow="checkDetail" />

                    <MainContainer showLoader={fetching}>
                        <div className="container-center">
                            <div className="title-left-header">
                                <I18n id={`checks.${checkTypeSelected}.detail.title`} component="h1" />
                            </div>

                            <div className="details-container check-detail">
                                <div className="details__default-content">
                                    <ul className="data-list inline">
                                        {isDiscounted ? (
                                            this.renderItemsDiscounted()
                                        ) : (
                                            <>
                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.businessName.label"
                                                    />

                                                    <span className="data-desc">{businessName}</span>
                                                </li>

                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.tradingDate.label"
                                                    />
                                                    <span className="data-desc">
                                                        <FormattedDate date={operationDate} />
                                                    </span>
                                                </li>

                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.operationNumber.label"
                                                    />

                                                    <span className="data-desc">{operationNumber}</span>
                                                </li>

                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.bank.label"
                                                    />

                                                    <span className="data-desc">{bank}</span>
                                                </li>

                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.number.label"
                                                    />

                                                    <span className="data-desc">{number}</span>
                                                </li>
                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.amount.label"
                                                    />

                                                    <FormattedAmount currency={currency || "ARS"} quantity={amount} />
                                                </li>
                                                <li className="data-wrapper">
                                                    <I18n
                                                        componentProps={{ className: "data-label" }}
                                                        id="check.deferred.detail.finalExpiration.label"
                                                    />
                                                    <span className="data-desc">
                                                        <FormattedDate date={finalExpiration} />
                                                    </span>
                                                </li>

                                                {taxesList && taxesList.length > 0 && (
                                                    <>
                                                        <li className="data-wrapper">
                                                            <I18n
                                                                componentProps={{ className: "data-label" }}
                                                                id="check.deferred.detail.commission.label"
                                                            />

                                                            <span className="data-desc">{`${taxesList[0].commission}%`}</span>
                                                        </li>
                                                        <li className="data-wrapper">
                                                            <I18n
                                                                componentProps={{ className: "data-label" }}
                                                                id="check.deferred.detail.commissionAmount.label"
                                                            />

                                                            <FormattedAmount
                                                                currency={currency || "ARS"}
                                                                quantity={taxesList[0].amount}
                                                            />
                                                        </li>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </ul>
                                </div>
                                <div className="details__footer">
                                    <SeuoLabel />
                                </div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: checksSelectors.getFetching(state),
    check: checksSelectors.getCheckDetail(state),
    checkTypeSelected: checksSelectors.getFilters(state).checkTypeDeferred,
    checkDeferred: checksSelectors.getCheckDeferred(state),
});

export default connect(mapStateToProps)(CheckDetail);
