import React, { Component } from "react";
import { shape, bool, func } from "prop-types";
import { withFormik } from "formik";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import * as i18nUtils from "util/i18n";
import Dropdown from "pages/_components/Dropdown";
import Button from "pages/_components/Button";

class AccountTransfers extends Component {
    static propTypes = {
        account: shape({}).isRequired,
        isDesktop: bool.isRequired,
        handleClick: func,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        handleClick: null,
    };

    handleChange = (value) => {
        const { dispatch } = this.props;
        const { id } = value;
        dispatch(push(id));
    };

    render() {
        const { account, isDesktop, handleClick } = this.props;
        const transferActions = [];

        if (account.permissions["transfer.internal"]) {
            transferActions.push(
                <Button
                    label="menu.transfers.internal"
                    key="transferInternal"
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() =>
                        handleClick({
                            id: "transferInternal",
                            label: i18nUtils.get("menu.transfers.internal"),
                        })
                    }
                />,
            );
        }
        if (account.permissions["transfer.thirdParties"]) {
            transferActions.push(
                <Button
                    label="menu.transfers.thirdParties"
                    key="transferThirdParties"
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() =>
                        handleClick({
                            id: "transferThirdParties",
                            label: i18nUtils.get("menu.transfers.thirdParties"),
                        })
                    }
                />,
            );
        }
        if (account.permissions["transfer.local"]) {
            transferActions.push(
                <Button
                    label="menu.transfers.local"
                    key="transferLocal"
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() =>
                        handleClick({
                            id: "transferLocal",
                            label: i18nUtils.get("menu.transfers.local"),
                        })
                    }
                />,
            );
        }
        if (account.permissions["transfer.massive"]) {
            transferActions.push(
                <Button
                    label="menu.transfers.massive"
                    key="transferMassive"
                    className="dropdown__item-btn"
                    bsStyle="link"
                    onClick={() =>
                        handleClick({
                            id: "transferMassive",
                            label: i18nUtils.get("menu.transfers.massive"),
                        })
                    }
                />,
            );
        }

        if (transferActions.length > 0) {
            return isDesktop ? (
                <Dropdown
                    image="images/selectArrowDownWhite.svg"
                    label="forms.transfers.transfers.label"
                    bsStyle="tertiary btn-dropdown">
                    {transferActions}
                </Dropdown>
            ) : (
                transferActions
            );
        }
        return null;
    }
}
const mapStateToProps = (state) => ({
    currentLang: i18nSelectors.getLang(state),
});
export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: () => {},
    }),
)(AccountTransfers);
