export const types = {
    CHANGE_PRODUCT_ALIAS: "products/CHANGE_PRODUCT_ALIAS",
    CHANGE_PRODUCT_ALIAS_SUCCESS: "products/CHANGE_PRODUCT_ALIAS_SUCCESS",
    CHANGE_PRODUCT_ALIAS_FAILURE: "products/CHANGE_PRODUCT_ALIAS_FAILURE",
};

export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const actions = {
    changeProductAlias: (alias, idProduct, backAfterCall = false, formikBag = null) => ({
        type: types.CHANGE_PRODUCT_ALIAS,
        alias,
        idProduct,
        backAfterCall,
        formikBag,
    }),
};
