import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";

import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import { Mixpanel } from "util/clickstreaming";
import Image from "pages/_components/Image";

const FORM_ID = "enrollment.step3";

class EnrollmentStep1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isSubmitting: bool.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isSubmitting, fetching, isDesktop } = this.props;
        const maxLength = configUtils.getInteger("core.password.maxLength");

        return (
            <>
                <Notification scopeToShow="step3" />
                <div className="view-page justify-content-center">
                    <div className="view-content">
                        <main className="main-container">
                            <div className="title-header">
                                <I18n id="enrollment.index.header" component="h1" />
                            </div>
                            <Form className="form-content container">
                                <I18n
                                    id="enrollment.steps.step1.title"
                                    component="h2"
                                    componentProps={{ className: "ui-text-skyblue" }}
                                />

                                <I18n id="enrollment.steps.step1.title.username" component="h3" />

                                <I18n
                                    id="enrollment.step3.usernameRules.label"
                                    MIN_LENGTH={configUtils.get("username.minLength")}
                                    MAX_LENGTH={configUtils.get("username.maxLength")}
                                    component="small"
                                    componentProps={{ className: "ui-mb-7" }}
                                />

                                <div className="form-group--double-field">
                                    <Field
                                        component={Credential}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        pattern={configUtils.get("core.idUser.pattern")}
                                        maxLength={configUtils.getInteger("core.idUser.maxLength", 50)}
                                        name="user"
                                        copyEnabled={false}
                                    />
                                    <Field
                                        component={Credential}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        pattern={configUtils.get("core.idUser.pattern")}
                                        maxLength={configUtils.getInteger("core.idUser.maxLength", 50)}
                                        name="userConfirmation"
                                        copyEnabled={false}
                                    />
                                </div>

                                <I18n
                                    id="enrollment.steps.step1.title.password"
                                    component="h3"
                                    componentProps={{ className: "ui-mt-3" }}
                                />

                                <div className="password-help-text ui-mb-7">
                                    <I18n id={`${FORM_ID}.passwordRules.info.part2`} component="p" />
                                    <ul>
                                        <li>
                                            <I18n
                                                id={`${FORM_ID}.passwordRules.rule1`}
                                                MIN_LENGTH={configUtils.get("core.password.minLength")}
                                            />
                                        </li>
                                        <li>
                                            <I18n id={`${FORM_ID}.passwordRules.rule2`} />
                                        </li>
                                        <li>
                                            <I18n id={`${FORM_ID}.passwordRules.rule3`} />
                                        </li>
                                    </ul>
                                    <div className="ui-dflex ui-mt-7">
                                        <Image src="images/iconos/warning.svg" wrapperClassName="tiny-small-icon" />
                                        <I18n id={`${FORM_ID}.passwordRules.info.part1`} />
                                    </div>
                                </div>

                                <div className="form-group--double-field">
                                    <Field
                                        component={Credential}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        pattern={configUtils.get("core.password.pattern")}
                                        name="password"
                                        maxLength={maxLength}
                                        additionalClassName={`${isDesktop ? "ui-mb-0" : ""}`}
                                    />
                                    <Field
                                        component={Credential}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        pattern={configUtils.get("core.password.pattern")}
                                        name="passwordConfirmation"
                                        maxLength={maxLength}
                                        additionalClassName="ui-mb-0"
                                    />
                                </div>

                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    disabled={fetching}
                                    block
                                />
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.getFetching(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            environment: props.invitation?.customParameters,
            fullName: props.invitation?.firstName,
            documentType: i18nUtils.get(`documentType.label.${props.invitation?.documentType}`),
            documentNumber: props.invitation?.documentNumber,
            email: props.invitation?.email,
            user: "",
            userConfirmation: "",
            password: "",
            passwordConfirmation: "",
        }),
        handleSubmit: ({ user, userConfirmation, password, passwordConfirmation }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(
                enrollmentActions.enrollmentStep1Verify(
                    user,
                    userConfirmation,
                    password,
                    passwordConfirmation,
                    formikBag,
                ),
            );
            Mixpanel.track(`${FORM_ID}.password`);
        },
    }),
)(withExchangeToken(EnrollmentStep1));
