import React, { Component, Fragment } from "react";
import { shape, string, bool, func, number } from "prop-types";
import { compose } from "redux";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import Select from "react-select";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as i18n from "util/i18n";
import classNames from "classnames";

class Document extends Component {
    static propTypes = {
        defaultDocumentType: string.isRequired,
        data: shape({}).isRequired,
        editing: bool.isRequired,
        value: shape({
            document: string,
            documentCountry: string,
            documentType: string,
        }).isRequired,
        focus: bool.isRequired,
        setValue: func.isRequired,
        placeholder: string,
        pattern: string,
        maxLength: number,
        readOnly: bool.isRequired,
        idField: string.isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        handleOnChange: func.isRequired,
        idActivity: string.isRequired,
        mode: string.isRequired,
    };

    static defaultProps = {
        placeholder: "",
        pattern: "^[0-9]*$",
        maxLength: 11,
    };

    state = {
        focusSelect: false,
        focusInput: false,
    };

    constructor(props) {
        super(props);
        this.docNumberRef = null;
    }

    componentDidMount() {
        const { editing, value, focus } = this.props;
        if (editing && !value) {
            const { setValue } = this.props;

            setValue({
                documentCountry: "AR",
                documentType: "DN",
                document: "",
            });
        }
        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.docNumberRef && focus) {
            this.docNumberRef.focus();
        }
    }

    toggleFocusSelect = () => {
        const { focusSelect } = this.state;
        this.setState({
            focusInput: false,
            focusSelect: !focusSelect,
        });
    };

    toggleFocusInput = () => {
        const { focusInput } = this.state;
        this.setState({
            focusSelect: false,
            focusInput: !focusInput,
        });
    };

    handleCountryChange = ({ id: documentCountry }) => {
        const {
            setValue,
            value: previousValue,
            data: { documentCountryMap },
            defaultDocumentType,
        } = this.props;

        if (documentCountry === previousValue.documentCountry) {
            return;
        }

        const existsType = (country, type) => documentCountryMap[country].some(({ id }) => id === type);

        let documentType;

        if (existsType(documentCountry, previousValue.documentType)) {
            documentType = previousValue.documentType;
        } else if (existsType(documentCountry, defaultDocumentType)) {
            documentType = defaultDocumentType;
        } else {
            documentType = "";
        }

        setValue({
            documentType,
            documentCountry,
            document: documentType === previousValue.documentType ? previousValue.document : "",
        });
    };

    handleTypeChange = ({ id }) => {
        const { setValue, value: previousValue } = this.props;

        setValue({
            ...previousValue,
            documentType: id,
        });
    };

    handleDocumentChange = (event) => {
        const { field, form, handleOnChange, maxLength } = this.props;
        if (event.target.validity.valid) {
            if (event.target.value.length <= maxLength) {
                if (handleOnChange) {
                    handleOnChange(event);
                }
            }
            form.setFieldValue(field.name, {
                ...field.value,
                document: event.target.value,
            });
        }
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            pattern,
            maxLength,
            readOnly,
            defaultDocumentType,
            data,
            idField,
            idActivity,
            mode,
        } = this.props;

        const selectedType = value && value.documentType ? value.documentType : defaultDocumentType;
        const selectedNumber = value && value.document ? value.document : "";
        const { focusInput, focusSelect } = this.state;

        if (editing) {
            return (
                <Fragment>
                    <div className="form-group">
                        <FieldLabel
                            labelKey={`${idActivity}.${idField}.type.label`}
                            mode="edit"
                            idField={`${idField}.documentType`}
                        />
                        <div
                            className={classNames("input-group", {
                                "has-focus": focusSelect,
                            })}>
                            <Select
                                id={`${idField}.documentType`}
                                name="documentType"
                                searchable={false}
                                onChange={this.handleTypeChange}
                                onBlur={this.toggleFocusSelect}
                                onFocus={this.toggleFocusSelect}
                                value={selectedType}
                                valueKey="id"
                                labelKey="name"
                                options={
                                    data
                                        ? data.map((date) => ({
                                              id: date.id,
                                              name: i18n.get(`documentType.label.${date.id}`),
                                          }))
                                        : []
                                }
                                clearable={false}
                                className="fslideFromBottom"
                                optionClassName="needsclick"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <FieldLabel
                            labelKey={`${idActivity}.${idField}.number.label`}
                            mode="edit"
                            idField={`${idField}.documentNumber`}
                        />
                        <div
                            className={classNames("input-group", {
                                "has-focus": focusInput,
                            })}>
                            <input
                                id={`${idField}.documentNumber`}
                                className="form-control"
                                name="documentNumber"
                                type="text"
                                onChange={this.handleDocumentChange}
                                onBlur={this.toggleFocusInput}
                                onFocus={this.toggleFocusInput}
                                ref={(ref) => {
                                    this.docNumberRef = ref;
                                }}
                                placeholder={placeholder}
                                pattern={pattern}
                                readOnly={readOnly}
                                value={selectedNumber}
                                maxLength={maxLength}
                            />
                        </div>
                    </div>
                </Fragment>
            );
        }

        let { documentTypeLabel } = data.find(({ id }) => id === selectedType) || {};
        if (mode === "view" && selectedType === "DN") {
            documentTypeLabel = i18n.get(`documentType.label.${selectedType}.acronym`);
        }

        return (
            <>
                <span className="data-label">{i18n.get(`${idActivity}.${idField}.type.label`)}</span>
                <ul>
                    <li>{documentTypeLabel || selectedType}</li>
                </ul>
            </>
        );
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        isEmptyValue: (value) => {
            const { documentType, document } = value || {};
            return !documentType || !document;
        },
        renderLegend: true,
    }),
)(Document);
