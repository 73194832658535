import React, { Fragment, Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { func, bool, shape, string } from "prop-types";

import { actions as statusActions } from "reducers/status";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";

import ChangeEnvironment from "pages/settings/ChangeEnvironment";
import SetDefaultEnvironment from "pages/settings/SetDefaultEnvironment";
import ChangePassword from "pages/settings/ChangePassword";
import RegisterToken from "pages/token/RegisterToken";
import ValidateToken from "pages/token/ValidateToken";
import ChangeAddresses from "pages/settings/ChangeAddresses";
import ChangeSecuritySeal from "pages/settings/ChangeSecuritySeal";
import ChangeSecuritySealConfirmation from "pages/settings/ChangeSecuritySealConfirmation";
import LastLogin from "pages/settings/LastLogin";
import DefaultLayout from "pages/_layouts/DefaultLayout";
import Accounts from "pages/accounts/Accounts";
import FingerprintConfiguration from "pages/settings/FingerprintConfiguration";
import FingerprintDeleteConfirmation from "pages/settings/FingerprintDeleteConfirmation";
import PushNotifications from "pages/settings/PushNotifications";
import PushNotificationsDeleteConfirmation from "pages/settings/PushNotificationsDeleteConfirmation";
import MyDevices from "pages/settings/MyDevices";
import DocumentsIndex from "pages/settings/_esign/DocumentsIndex";
import EsignDocumentsSigned from "pages/settings/_esign/EsignDocumentsSigned";
import ModalConsultation from "pages/MipymeInvoice/ModalConsultation";
import NotificationsConfiguration from "pages/settings/NotificationsConfiguration";
import NotificationsConfigurationTransports from "pages/settings/NotificationsConfigurationTransports";
import Settings from "pages/settings/Settings";
import Form from "pages/forms/Form";
import SessionAboutToExpire from "pages/status/SessionAboutToExpire";
import LogoutModal from "pages/session/LogoutModal";
import BackButtonListener from "pages/_components/BackButtonListener";
import AccountDetail from "pages/accounts/Details";
import MovementDetail from "pages/accounts/_components/MovementDetail";
import AccountStatements from "pages/accounts/_components/Statements";
import Filters from "pages/accounts/_components/MobileFilters";
import Results from "pages/accounts/_components/MobileFiltersResults";
import Desktop from "pages/desktop/Desktop";
import PendingActions from "pages/desktop/PendingActions";
import Loans from "pages/loans/Loans";
import LoanDetails from "pages/loans/Details";
import LoanMobileFilters from "pages/loans/_components/MobileFilters";
import LoanStatementDetail from "pages/loans/_components/StatementDetail";
import LoansPaymentList from "pages/loansPayment/List";
import CommunicationMain from "pages/communications/Main";
import NewMessage from "pages/communications/NewMessage";
import ReadMessage from "pages/communications/ReadMessage";
import ReplyMessage from "pages/communications/ReplyMessage";
import PendingTransactionList from "pages/transactions/PendingTransactionList";
import UserTransactionsList from "pages/userTransactions/UserTransactionsList";

import TransactionsList from "pages/transactions/TransactionsList";
import TransactionsListFilters from "pages/transactions/_components/MobileFilters";
import TransactionsFiltersResults from "pages/transactions/_components/MobileFiltersResults";
import BankSearch from "pages/forms/_components/_fields/_bankselector/bankSearch/BankSearch";
import MobileTransfers from "pages/accounts/_components/MobileTransfers";
import SecurityPolicies from "pages/_components/SecurityPolicies";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import SupportInfo from "pages/_components/SupportInfo";
import PointsOfInterest from "pages/pointsOfInterest";
import Templates from "pages/forms/_components/Templates";
import FormPaymentLines from "pages/forms/FormPaymentLines";
import TransactionPaymentLines from "pages/forms/TransactionPaymentLines";
import StepPEP from "pages/onboarding/StepPEP";
import StepIRS from "pages/enrollment/StepIRS";
import TransferInternalForm from "pages/forms/customForms/TransferInternalForm";
import RequestCheckbookForm from "pages/forms/customForms/RequestCheckbookForm";
import TransactionCustom from "pages/forms/customForms/TransactionCustom";
import AccountOpeningForm from "pages/forms/customForms/AccountOpeningForm";
import AccountClosingForm from "pages/forms/customForms/AccountClosingForm";
import ShareholderAccountOpeningForm from "pages/forms/customForms/ShareholderAccountOpeningForm";
import ChecksDeferredList from "pages/checks/ChecksDeferredList";
import CheckDeferredDetail from "pages/checks/deferred/DeferredDetail";
import Checks from "pages/checks/CheckList";
import CheckDetail from "pages/checks/Detail";
import ChecksFilters from "pages/checks/Filters";
import FixedTermDepositsList from "pages/investment/FixedTermDepositsList";
import DepositDetail from "pages/investment/DepositDetail";
import CreateFixedTermDeposit from "pages/forms/customForms/CreateFixedTermDepositForm";
import ChargesCertificateContainer from "pages/qr/charges/ChargesCertificateContainer";
import RequestChangePersonalData from "pages/forms/customForms/RequestChangePersonalData";
import AcceptTermsAndConditions from "pages/settings/AcceptTermsAndConditions";
import CancelDepositRenewal from "pages/forms/customForms/CancelDepositRenewal";
import TransferThirdParties from "pages/forms/customForms/TransferThirdParties";
import ReceiptsComexList from "pages/comex/ReceiptsComexList";
import EnrollmentQRForm from "pages/forms/customForms/EnrollmentQRForm";
import NotificationModal from "pages/_components/NotificationModal";
import TransferMassive from "pages/forms/customForms/TransferMassive";
import CreateMassivePayment from "pages/forms/customForms/CreateMassivePayment";
import InvesterProfileContainer from "pages/investment/InvesterProfileContainer";
import InvesterProfileForm from "pages/forms/customForms/InvesterProfileForm";
import ECheqsList from "pages/echeqs/ECheqsList";
import ECheqFilters from "pages/echeqs/Filters";
import AcceptReturnOrAgreement from "pages/forms/customForms/echeqs/AcceptReturnOrAgreement";
import CancelEcheqForm from "pages/forms/customForms/echeqs/CancelEcheqForm";
import AcceptMandateNegotiationOrPayment from "pages/forms/customForms/echeqs/AcceptMandateNegotiationOrPayment";
import RevokeMandateNegotiationOrPayment from "pages/forms/customForms/echeqs/RevokeMandateNegotiationOrPayment";
import GiveEcheq from "pages/forms/customForms/echeqs/GiveEcheq";
import RepudiateOrCancelGiveEcheq from "pages/forms/customForms/echeqs/RepudiateOrCancelGiveEcheq";
import CancelEndorsement from "pages/forms/customForms/echeqs/CancelEndorsement";
import DepositEcheq from "pages/forms/customForms/echeqs/DepositEcheq";
import PaymentToSuppliers from "pages/forms/customForms/PaymentToSuppliers";
import SupplierPaymentFileList from "pages/suppliers/SupplierPaymentFileList";
import PaymentToSuppliersList from "pages/forms/_components/SuppliersPaymentList/SupplierList";
import AcceptEcheqTermsConditionsForm from "pages/forms/customForms/echeqs/AcceptEcheqTermsConditionsForm";
import ChargesDetail from "pages/qr/charges/details/ChargesDetail";
import PasswordCloseToExpireModal from "pages/session/PasswordCloseToExpireModal";
import RequestGuarantee from "../pages/forms/customForms/echeqs/RequestGuarantee";
import MutualFunds from "../pages/investment/MutualFunds";
import RequestInvestmentSubscribeForm from "../pages/forms/customForms/RequestInvestmentSubscribeForm";
import RequestInvestmentRescueForm from "../pages/forms/customForms/RequestInvestmentRescueForm";
import MutualFundsMovements from "../pages/investment/MovementDetail";
import SetCBUAlias from "../pages/forms/customForms/SetCBUAlias";
import FrequentRecipients from "../pages/transfers/FrequentRecipients";
import ModifyRecipient from "../pages/transfers/ModifyRecipient";
import CreateFrequentRecipient from "../pages/forms/customForms/CreateFrequentRecipient";
import TransferLocalForm from "../pages/forms/customForms/TransferLocalForm";
import SearchRecipient from "../pages/transfers/_components/SearchRecipient";
import ECheqDetail from "../pages/echeqs/EcheqDetail";
import RequestReturnOrAgreementForm from "../pages/forms/customForms/echeqs/RequestReturnOrAgreementForm";
import CancelReturnOrAgreementForm from "../pages/forms/customForms/echeqs/CancelReturnOrAgreementForm";
import RejectReturnRequestOrAgreement from "../pages/forms/customForms/echeqs/RejectReturnRequestOrAgreement";
import AcceptEcheqForm from "../pages/forms/customForms/echeqs/AcceptEcheqForm";
import RepudiateEcheqForm from "../pages/forms/customForms/echeqs/RepudiateEcheqForm";
import CancelOrRepudiateGuarantee from "../pages/forms/customForms/echeqs/CancelGuaranteeRequest";
import CancelOrRepudiateMandatePayment from "../pages/forms/customForms/echeqs/CancelMandatePaymentRequest";
import AdmitGuaranteeRequest from "../pages/forms/customForms/echeqs/AdmitGuaranteeRequest";
import RequestMandateForNegotiation from "../pages/forms/customForms/echeqs/RequestMandateForNegotiation";
import CancelMandateForNegotiation from "../pages/forms/customForms/echeqs/CancelMandateForNegotiation";
import RequestPaymentMandateForm from "../pages/forms/customForms/echeqs/RequestPaymentMandateForm";
import AcceptCessionForm from "../pages/forms/customForms/echeqs/AcceptCessionForm";
import RescueFromCustodyForm from "../pages/forms/customForms/echeqs/RescueFromCustodyForm";
import EndorseRequest from "../pages/forms/customForms/echeqs/EndorsementRequest";
import EmitRejectionCertificateForm from "../pages/forms/customForms/echeqs/EmitRejectionCertificateForm";
import AccountList from "../pages/echeqs/AccountListToIssueECheq";
import AccountActivationForm from "../pages/forms/customForms/echeqs/EnableOrDisableAccountsToIssueECheq";
import CustodyRequest from "../pages/forms/customForms/echeqs/CustodyRequest";
import EmitEcheqForm from "../pages/forms/customForms/echeqs/emitEcheq/EmitEcheqForm";
import SupplierPaymentFileQueryList from "../pages/suppliers/SupplierPaymentFileQueryList";
import ErrorDetails from "../pages/suppliers/ErrorDetails";

class PrivateRoutes extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(statusActions.saveLastHref(location));
    }

    hasLoanListPermission = (permissions) =>
        permissions["loans.operations"] && (permissions["loans.active"] || permissions["loans.history"]);

    hasSuppliersPermission = (permissions) =>
        permissions["suppliers.checkEcheq"] ||
        permissions["suppliers.transfer"] ||
        permissions["suppliers.withholdings"];

    render() {
        const { hasActiveSession } = this.props;
        const useGireForEcheqs = config.get("echeq.useGireForEcheqs", false) === "true";
        if (!hasActiveSession) {
            return <Redirect to="/" />;
        }

        return (
            <Fragment>
                <Switch>
                    <DefaultLayout key="/desktop" exact path="/desktop" component={Desktop} />
                    <DefaultLayout
                        key="/pendingActions"
                        exact
                        path="/pendingActions"
                        component={PendingActions}
                        withoutLayout
                    />
                    <DefaultLayout key="/completePEP" exact path="/completePEP" component={StepPEP} withoutLayout />
                    <DefaultLayout key="/completeIRS" exact path="/completeIRS" component={StepIRS} withoutLayout />
                    {/* ACCOUNTS */}
                    <DefaultLayout key="/accounts" exact path="/accounts" component={Accounts} />
                    <DefaultLayout
                        key="/accounts/:id"
                        exact
                        path="/accounts/:id"
                        component={AccountDetail}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        key="/accounts/:id/movement"
                        exact
                        path="/accounts/:id/movement"
                        component={MovementDetail}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/accounts/:id/statements"
                        exact
                        path="/accounts/:id/statements"
                        component={AccountStatements}
                    />
                    <DefaultLayout
                        key="/accounts/statements/list"
                        exact
                        path="/accounts/statements/list"
                        component={AccountStatements}
                    />
                    <DefaultLayout
                        key="/accounts/:id/filters"
                        exact
                        path="/accounts/:id/filters"
                        component={Filters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/accounts/:id/filters/results"
                        exact
                        path="/accounts/:id/filters/results"
                        component={Results}
                    />
                    <DefaultLayout
                        key="/accounts/:id/transfers"
                        exact
                        path="/accounts/:id/transfers"
                        component={MobileTransfers}
                    />
                    {/* CHECKS */}
                    <DefaultLayout key="/checks" exact path="/checks" component={Checks} permissions={["accounts"]} />
                    <DefaultLayout key="/checks/:id/detail" exact path="/checks/:id/detail" component={CheckDetail} />
                    <DefaultLayout
                        key="/checks/filters"
                        exact
                        path="/checks/filters"
                        component={ChecksFilters}
                        transition="transition-flow-open"
                    />
                    {/* ECHEQS */}
                    {!useGireForEcheqs && [
                        <DefaultLayout
                            key="/echeqs"
                            exact
                            path="/echeqs"
                            component={ECheqsList}
                            permissions={["accounts", "listEcheqs"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/filters"
                            exact
                            path="/echeqs/filters"
                            component={ECheqFilters}
                            transition="transition-flow-open"
                        />,

                        <DefaultLayout
                            key="/echeqs/:id/detail"
                            exact
                            path="/echeqs/:id/detail"
                            component={ECheqDetail}
                            transition="transition-flow-open"
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/requestReturn"
                            exact
                            path="/echeqs/formCustom/requestReturn"
                            component={RequestReturnOrAgreementForm}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/requestAgreement"
                            exact
                            path="/echeqs/formCustom/requestAgreement"
                            component={RequestReturnOrAgreementForm}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cancelReturn"
                            exact
                            path="/echeqs/formCustom/cancelReturn"
                            component={CancelReturnOrAgreementForm}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cancelAgreement"
                            exact
                            path="/echeqs/formCustom/cancelAgreement"
                            component={CancelReturnOrAgreementForm}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/requestGuarantee"
                            exact
                            path="/echeqs/formCustom/requestGuarantee"
                            component={RequestGuarantee}
                            transition="transition-flow-open"
                            permissons={["requestGuarantee"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/acceptAgreement"
                            exact
                            path="/echeqs/formCustom/acceptAgreement"
                            component={AcceptReturnOrAgreement}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/acceptReturn"
                            exact
                            path="/echeqs/formCustom/acceptReturn"
                            component={AcceptReturnOrAgreement}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiateAgreement"
                            exact
                            path="/echeqs/formCustom/repudiateAgreement"
                            component={RejectReturnRequestOrAgreement}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/rejectReturn"
                            exact
                            path="/echeqs/formCustom/rejectReturn"
                            component={RejectReturnRequestOrAgreement}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/accept"
                            exact
                            path="/echeqs/formCustom/accept"
                            component={AcceptEcheqForm}
                            permissions={["echeqs.acceptEcheqs"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiate"
                            exact
                            path="/echeqs/formCustom/repudiate"
                            component={RepudiateEcheqForm}
                            permissions={["echeqs.repudiateEcheqs"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cancelEcheq"
                            exact
                            path="/echeqs/formCustom/cancelEcheq"
                            component={CancelEcheqForm}
                            permissions={["echeqs.cancel"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/negateGuarantee"
                            exact
                            path="/echeqs/formCustom/negateGuarantee"
                            component={CancelOrRepudiateGuarantee}
                            permissions={["requestGuarantee"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiateGuarantee"
                            exact
                            path="/echeqs/formCustom/repudiateGuarantee"
                            component={CancelOrRepudiateGuarantee}
                            permissions={["replyGuaranteeRequest"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/negateMandatePayment"
                            exact
                            path="/echeqs/formCustom/negateMandatePayment"
                            component={CancelOrRepudiateMandatePayment}
                            permissions={["echeqs.mandatoCobro.op.auth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiateMandatePayment"
                            exact
                            path="/echeqs/formCustom/repudiateMandatePayment"
                            component={CancelOrRepudiateMandatePayment}
                            permissions={["echeqs.mandatoCobro.op.auth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/admitGuarantee"
                            exact
                            path="/echeqs/formCustom/admitGuarantee"
                            component={AdmitGuaranteeRequest}
                            permissions={["admitGuaranteeRequest"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/acceptMandate.payment"
                            exact
                            path="/echeqs/formCustom/acceptMandate.payment"
                            component={AcceptMandateNegotiationOrPayment}
                            permissions={["echeqs.mandatoCobro.op.auth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/acceptMandate.negotiation"
                            exact
                            path="/echeqs/formCustom/acceptMandate.negotiation"
                            component={AcceptMandateNegotiationOrPayment}
                            permissions={["echeqs.mandatoNegociacion.op.auth.token"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/revokeMandate.negotiation"
                            exact
                            path="/echeqs/formCustom/revokeMandate.negotiation"
                            component={RevokeMandateNegotiationOrPayment}
                            permissions={["echeqs.mandateForNegotiation.op"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/revokeMandate.payment"
                            exact
                            path="/echeqs/formCustom/revokeMandate.payment"
                            component={RevokeMandateNegotiationOrPayment}
                            permissions={["echeqs.mandatoCobro.op.auth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiateMandateForNegotiation"
                            exact
                            path="/echeqs/formCustom/repudiateMandateForNegotiation"
                            component={CancelMandateForNegotiation}
                            permissions={["echeqs.mandateForNegotiation.op"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/negateMandateForNegotiation"
                            exact
                            path="/echeqs/formCustom/negateMandateForNegotiation"
                            component={CancelMandateForNegotiation}
                            permissions={["echeqs.mandateForNegotiation.op"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/requestMandatePayment"
                            exact
                            path="/echeqs/formCustom/requestMandatePayment"
                            component={RequestPaymentMandateForm}
                            permissions={["echeqs.mandatePaymentOpAuthToken"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cede"
                            exact
                            path="/echeqs/formCustom/cede"
                            component={GiveEcheq}
                            permissions={["echeqs.assignmentRight.op.auth.token"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/requestMandateForNegotiation"
                            exact
                            path="/echeqs/formCustom/requestMandateForNegotiation"
                            component={RequestMandateForNegotiation}
                            permissions={["echeqs.mandatoNegociacion.op.auth.token"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cancelCession"
                            exact
                            path="/echeqs/formCustom/cancelCession"
                            component={RepudiateOrCancelGiveEcheq}
                            permissions={["echeqs.cessionsOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/repudiateCession"
                            exact
                            path="/echeqs/formCustom/repudiateCession"
                            component={RepudiateOrCancelGiveEcheq}
                            permissions={["echeqs.cessionsOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/acceptCession"
                            exact
                            path="/echeqs/formCustom/acceptCession"
                            component={AcceptCessionForm}
                            permissions={["echeqs.cessionsOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/cancelEndorsement"
                            exact
                            path="/echeqs/formCustom/cancelEndorsement"
                            component={CancelEndorsement}
                            permissions={["echeqs.signEndorse.op.auth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/rescueFromCustody"
                            exact
                            path="/echeqs/formCustom/rescueFromCustody"
                            component={RescueFromCustodyForm}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/endorseRequest"
                            exact
                            path="/echeqs/formCustom/endorseRequest"
                            component={EndorseRequest}
                            permissions={["echeqs.endorseRequest.op.auth.token"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/emitCertificate"
                            exact
                            path="/echeqs/formCustom/emitCertificate"
                            component={EmitRejectionCertificateForm}
                            permissions={["echeqs.functions.signersOpAuthToken"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/deposit"
                            exact
                            path="/echeqs/formCustom/deposit"
                            component={DepositEcheq}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/management/account/list"
                            exact
                            path="/echeqs/management/account/list"
                            component={AccountList}
                            permissions={["echeqs.accountAdherence"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/management/account/activate"
                            exact
                            path="/echeqs/management/account/activate"
                            component={AccountActivationForm}
                            permissions={["echeqs.accountAdherence"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/putIntoCustody"
                            exact
                            path="/echeqs/formCustom/putIntoCustody"
                            component={CustodyRequest}
                            permissions={["echeqs.functions.signersOpAuth"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/acceptEcheqTermsConditions"
                            exact
                            path="/echeqs/acceptEcheqTermsConditions"
                            component={AcceptEcheqTermsConditionsForm}
                            permissions={["echeqs.termsAndConditions.adherence"]}
                        />,

                        <DefaultLayout
                            key="/echeqs/formCustom/emitEcheq"
                            exact
                            path="/echeqs/formCustom/emitEcheq"
                            component={EmitEcheqForm}
                            permissions={["echeqs.endorseRequest.op.auth.token"]}
                        />,
                    ]}
                    {/* PAYMENTS TO SUPPLIERS */}
                    <DefaultLayout
                        key="/paymentSuppliers"
                        exact
                        path="/paymentSuppliers"
                        component={PaymentToSuppliers}
                        comparator={this.hasSuppliersPermission}
                    />
                    <DefaultLayout
                        key="/paymentSuppliers/files"
                        exact
                        path="/paymentSuppliers/files"
                        component={SupplierPaymentFileList}
                    />
                    <DefaultLayout
                        key="/paymentSuppliers/fileQueryList/:fileStatus"
                        exact
                        path="/paymentSuppliers/fileQueryList/:fileStatus"
                        component={SupplierPaymentFileQueryList}
                    />
                    <DefaultLayout
                        key="/suppliersPaymentList/supplierList"
                        exact
                        path="/suppliersPaymentList/supplierList"
                        component={PaymentToSuppliersList}
                        comparator={this.hasSuppliersPermission}
                    />
                    <DefaultLayout
                        key="/paymentSuppliers/errors"
                        exact
                        path="/paymentSuppliers/errors"
                        component={ErrorDetails}
                    />
                    {/* INVESTMENTS */}
                    <DefaultLayout
                        key="/fixedTermDeposits"
                        exact
                        path="/fixedTermDeposits"
                        component={FixedTermDepositsList}
                        permissions={["accounts"]}
                    />
                    <DefaultLayout
                        key="/fixedTermDeposits/:id/:order"
                        exact
                        path="/fixedTermDeposits/:id/:order"
                        component={DepositDetail}
                        permissions={["fixedTermDeposits"]}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        key="/mutualFunds"
                        exact
                        path="/mutualFunds"
                        component={MutualFunds}
                        transition="transition-flow-open"
                        permissions={["mutualFundsRead"]}
                    />
                    <DefaultLayout
                        key="/mutualFunds/movements"
                        exact
                        path="/mutualFunds/movements"
                        component={MutualFundsMovements}
                        transition="transition-flow-open"
                        permissions={["mutualFundsRead"]}
                    />
                    <DefaultLayout
                        key="/investerProfile"
                        exact
                        path="/investerProfile"
                        component={InvesterProfileContainer}
                        transition="transition-flow-open"
                        permissions={["mutualFundsRead"]}
                    />
                    <DefaultLayout
                        key="/formCustom/investerProfileForm"
                        exact
                        path="/formCustom/investerProfileForm"
                        component={InvesterProfileForm}
                        transition="transition-flow-open"
                        permissions={["mutualFundsRead"]}
                    />
                    {/* SETTINGS */}
                    <DefaultLayout key="/settings" exact path="/settings" component={Settings} />
                    <DefaultLayout
                        key="/settings/changePassword"
                        exact
                        path="/settings/changePassword"
                        component={ChangePassword}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/changeSecuritySeal"
                        exact
                        path="/settings/changeSecuritySeal"
                        component={ChangeSecuritySeal}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/changeSecuritySeal/confirmation"
                        exact
                        path="/settings/changeSecuritySeal/confirmation"
                        component={ChangeSecuritySealConfirmation}
                    />
                    <DefaultLayout
                        key="/settings/notificationsConfiguration"
                        exact
                        path="/settings/notificationsConfiguration"
                        component={NotificationsConfiguration}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/notificationsConfiguration/:communicationType"
                        path="/settings/notificationsConfiguration/:communicationType"
                        component={NotificationsConfigurationTransports}
                    />
                    <DefaultLayout
                        key="/settings/fingerprintConfiguration"
                        exact
                        path="/settings/fingerprintConfiguration"
                        component={FingerprintConfiguration}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/fingerprintConfiguration/deleteConfirmation"
                        exact
                        path="/settings/fingerprintConfiguration/deleteConfirmation"
                        component={FingerprintDeleteConfirmation}
                    />
                    <DefaultLayout
                        key="/settings/pushNotifications"
                        exact
                        path="/settings/pushNotifications"
                        component={PushNotifications}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/myDevices"
                        exact
                        path="/settings/myDevices"
                        component={MyDevices}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/esign/documents"
                        exact
                        path="/settings/esign/documents"
                        component={DocumentsIndex}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/esign/documentsSigned"
                        exact
                        path="/settings/esign/documentsSigned"
                        component={EsignDocumentsSigned}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/pushNotifications/deleteConfirmation"
                        exact
                        path="/settings/pushNotifications/deleteConfirmation"
                        component={PushNotificationsDeleteConfirmation}
                    />
                    <DefaultLayout
                        key="/settings/changeEnvironment"
                        exact
                        path="/settings/changeEnvironment"
                        component={ChangeEnvironment}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/TyC"
                        exact
                        path="/settings/TyC"
                        component={AcceptTermsAndConditions}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/setDefaultEnvironment"
                        exact
                        path="/settings/setDefaultEnvironment"
                        component={SetDefaultEnvironment}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/registerToken"
                        exact
                        path="/registerToken"
                        component={RegisterToken}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/registerToken/validate"
                        exact
                        path="/registerToken/validate"
                        component={ValidateToken}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/settings/ChangeAddresses"
                        exact
                        path="/settings/ChangeAddresses"
                        component={ChangeAddresses}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout key="/settings/LastLogin" exact path="/settings/LastLogin" component={LastLogin} />
                    {/* LOANS */}
                    <DefaultLayout
                        key="/loansList"
                        exact
                        path="/loansList"
                        component={Loans}
                        transition="transition-flow-open"
                        comparator={this.hasLoanListPermission}
                    />
                    <DefaultLayout
                        key="/loans/:id/:code/:branch/:order/detail"
                        exact
                        path="/loans/:id/:code/:branch/:order/detail"
                        component={LoanDetails}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        key="/loans/:id/:code/:branch/:order/statement"
                        exact
                        path="/loans/:id/:code/:branch/:order/statement"
                        component={LoanStatementDetail}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        key="/loans/:id/filters"
                        exact
                        path="/loans/:id/filters"
                        component={LoanMobileFilters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/ChecksDeferredList"
                        exact
                        path="/ChecksDeferredList"
                        component={ChecksDeferredList}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/check/:checkType/:id/detail"
                        exact
                        path="/check/:checkType/:id/detail"
                        component={CheckDeferredDetail}
                    />
                    <DefaultLayout
                        key="/formCustom/cancelDepositRenewal"
                        exact
                        path="/formCustom/cancelDepositRenewal"
                        component={CancelDepositRenewal}
                        permissions={["fixedTermDeposits", "cancelDepositRenewal"]}
                    />
                    {/* FORMS */}
                    <DefaultLayout
                        key="/formCustom/shareholderAccountOpening"
                        exact
                        path="/formCustom/shareholderAccountOpening"
                        component={ShareholderAccountOpeningForm}
                        permissions={["investmentShareholderOpening"]}
                    />
                    <DefaultLayout
                        key="/formCustom/transferInternal"
                        exact
                        path="/formCustom/transferInternal"
                        component={TransferInternalForm}
                        permissions={["transferInternal"]}
                    />
                    <DefaultLayout
                        key="/formCustom/transferMassive"
                        exact
                        path="/formCustom/transferMassive"
                        component={TransferMassive}
                    />
                    <DefaultLayout
                        key="/transferMassive/createPayment"
                        exact
                        path="/transferMassive/createPayment"
                        component={CreateMassivePayment}
                    />
                    <DefaultLayout
                        key="/formCustom/transferLocal"
                        exact
                        path="/formCustom/transferLocal"
                        component={TransferLocalForm}
                        permissions={["transferLocal"]}
                    />
                    <DefaultLayout
                        key="/formCustom/requestCheckbook"
                        exact
                        path="/formCustom/requestCheckbook"
                        component={RequestCheckbookForm}
                        permissions={["accounts.requestCheckbook"]}
                    />
                    <DefaultLayout
                        key="/formCustom/accountOpening"
                        exact
                        path="/formCustom/accountOpening"
                        component={AccountOpeningForm}
                        permissions={["accountOpening"]}
                    />
                    <DefaultLayout
                        key="/formCustom/accountClosing"
                        exact
                        path="/formCustom/accountClosing"
                        component={AccountClosingForm}
                        permissions={["accountClosing"]}
                    />
                    <DefaultLayout
                        key="/formCustom/createFixedTermDeposit"
                        exact
                        path="/formCustom/createFixedTermDeposit"
                        component={CreateFixedTermDeposit}
                        permissions={["accounts", "fixedTermDepositOpening"]}
                    />
                    <DefaultLayout
                        key="/formCustom/setCBUAlias/:id"
                        exact
                        path="/formCustom/setCBUAlias/:id"
                        component={SetCBUAlias}
                        transition="transition-flow-open"
                        permissions={["accounts"]}
                    />
                    <DefaultLayout
                        key="/form/:idForm"
                        exact
                        path="/form/:idForm"
                        component={Form}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/transaction/:idTransaction"
                        path="/transaction/:idTransaction"
                        component={TransactionCustom}
                        transition="transition-flow-open"
                        exact
                    />
                    <DefaultLayout
                        key="/userTransaction/:idTransaction"
                        path="/userTransaction/:idTransaction"
                        component={TransactionCustom}
                        transition="transition-flow-open"
                        exact
                    />
                    <DefaultLayout
                        key="/form/:idForm/processDetail"
                        path="/form/:idForm/processDetail"
                        component={FormPaymentLines}
                        transition="transition-drill-in"
                        componentProps={{ isConfirmation: true }}
                    />
                    <DefaultLayout
                        key="/transaction/:idTransaction/processDetail"
                        path="/transaction/:idTransaction/processDetail"
                        component={TransactionPaymentLines}
                        transition="transition-drill-in"
                    />
                    <DefaultLayout
                        key="/formCustom/requestChangePersonalData"
                        exact
                        path="/formCustom/requestChangePersonalData"
                        component={RequestChangePersonalData}
                    />
                    <DefaultLayout
                        key="/formCustom/requestInvestmentSubscribeForm"
                        exact
                        path="/formCustom/requestInvestmentSubscribeForm"
                        component={RequestInvestmentSubscribeForm}
                        permissions={["investmentSubscription"]}
                    />
                    <DefaultLayout
                        key="/formCustom/requestInvestmentRescueForm"
                        exact
                        path="/formCustom/requestInvestmentRescueForm"
                        component={RequestInvestmentRescueForm}
                        permissions={["investmentRescue"]}
                    />
                    <DefaultLayout
                        key="/formCustom/transferThirdParties"
                        exact
                        path="/formCustom/transferThirdParties"
                        component={TransferThirdParties}
                        permissions={["transferThirdParties"]}
                    />
                    <DefaultLayout
                        key="/formCustom/enrollmentqrAccount"
                        exact
                        path="/formCustom/enrollmentqrAccount"
                        component={EnrollmentQRForm}
                        permissions={["accountOpening"]}
                    />
                    {/* COMEX */}
                    <DefaultLayout
                        key="/receiptsComexList"
                        exact
                        path="/receiptsComexList"
                        component={ReceiptsComexList}
                        permissions={["receiptsComex"]}
                    />
                    {/* PAYMENT LISTS */}
                    <DefaultLayout key="/loansPayment/list" path="/loansPayment/list" component={LoansPaymentList} />
                    {/* MANUAL MASS PAYMENT */}
                    <DefaultLayout
                        key="/form/:idForm/manual"
                        path="/form/:idForm/manual"
                        component={FormPaymentLines}
                        transition="transition-drill-in"
                    />
                    {/* COMMUNICATIONS */}
                    <DefaultLayout key="/communications" exact path="/communications" component={CommunicationMain} />
                    <DefaultLayout
                        key="/communications/compose"
                        exact
                        path="/communications/compose"
                        component={NewMessage}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/communications/reply/:id"
                        exact
                        path="/communications/reply/:id"
                        component={ReplyMessage}
                    />
                    <DefaultLayout
                        key="/communications/read/:id"
                        exact
                        path="/communications/read/:id"
                        component={ReadMessage}
                        transition="transition-flow-open"
                    />
                    {/* TRANSACTIONS */}
                    <DefaultLayout
                        key="/userTransactions/list"
                        exact
                        path="/userTransactions/list"
                        component={UserTransactionsList}
                    />
                    <DefaultLayout
                        key="/transactions/list"
                        exact
                        path="/transactions/list"
                        component={TransactionsList}
                    />
                    <DefaultLayout
                        key="/pendingTransaction/list"
                        exact
                        path="/pendingTransaction/list"
                        component={PendingTransactionList}
                    />
                    <DefaultLayout
                        key="/transactions/list/filters"
                        exact
                        path="/transactions/list/filters"
                        component={TransactionsListFilters}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/transactions/list/filters/results"
                        exact
                        path="/transactions/list/filters/results"
                        component={TransactionsFiltersResults}
                    />
                    <DefaultLayout
                        key="/form/:formId/bankSearch/:fieldId"
                        path="/form/:formId/bankSearch/:fieldId"
                        component={BankSearch}
                    />
                    <DefaultLayout key="/form/:formId/templates" path="/form/:formId/templates" component={Templates} />
                    {/* EXTERNAL */}
                    <DefaultLayout
                        key="/securityPolicies"
                        exact
                        path="/securityPolicies"
                        component={SecurityPolicies}
                        hasActiveSession={hasActiveSession}
                    />
                    <DefaultLayout
                        key="/termsAndConditions"
                        exact
                        path="/termsAndConditions"
                        component={TermsAndConditions}
                        hasActiveSession={hasActiveSession}
                    />
                    <DefaultLayout
                        key="/support"
                        exact
                        path="/support"
                        component={SupportInfo}
                        hasActiveSession={hasActiveSession}
                    />
                    <DefaultLayout
                        key="/settings/pointsOfInterest"
                        exact
                        path="/settings/pointsOfInterest"
                        component={PointsOfInterest}
                    />
                    {/* end of administration */}
                    <DefaultLayout key="/recipients" exact path="/recipients" component={FrequentRecipients} />
                    <DefaultLayout
                        key="/recipients/create"
                        exact
                        path="/recipients/create"
                        component={CreateFrequentRecipient}
                    />
                    <DefaultLayout
                        key="/recipients/modify"
                        exact
                        path="/recipients/modify"
                        component={ModifyRecipient}
                    />
                    <DefaultLayout
                        key="/recipients/search"
                        exact
                        path="/recipients/search"
                        component={SearchRecipient}
                        transition="transition-flow-open"
                    />
                    {/* QR */}
                    <DefaultLayout
                        key="/qrChargesCertificates/:value"
                        exact
                        path="/qrChargesCertificates/:value"
                        component={ChargesCertificateContainer}
                        transition="transition-flow-open"
                    />
                    <DefaultLayout
                        key="/qrChargesCertificates/details/:idCharge"
                        exact
                        path="/qrChargesCertificates/details/:idCharge"
                        component={ChargesDetail}
                        transition="transition-flow-open"
                    />
                </Switch>
                <BackButtonListener />
                <Route path="/" component={SessionAboutToExpire} />
                <Route path="/" component={LogoutModal} />
                <Route path="/" component={NotificationModal} />
                <Route path="/" component={ModalConsultation} />
                <Route path="/" component={PasswordCloseToExpireModal} />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(PrivateRoutes));
