import { replace, routerActions } from "react-router-redux";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { actions as assistantActions, selectors as assistantSelectors } from "reducers/assistant";
import { types as enrollmentTypes } from "reducers/enrollment";
import { selectors as geolocationSelectors } from "reducers/geolocation";
import { actions as i18nActions } from "reducers/i18n";
import { types as loansTypes } from "reducers/loans";
import { actions as loginActions, selectors, types } from "reducers/login";
import { actions as sessionActions, types as sessionTypes } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import formTypes from "reducers/types/form";
import globalTypes from "reducers/types/global";

import { AVAILABLE_REGIONS, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import * as session from "middleware/session";
import { actions as notificationActions } from "reducers/notification";
import * as configUtils from "util/config";
import * as fingerprintUtils from "util/fingerprint";
import { adjustIdFieldErrors } from "util/form";
import { unknownResponse } from "util/geolocation";
import * as i18n from "util/i18n";
import * as secureStorageUtils from "util/secureStorage";

import * as deviceUtils from "util/device";
import { mapActiveEnvironment } from "util/security";
import { loadSubStates } from "util/echeqs";

const NOTOKENLOGIN = "notokenlogin";
const sagas = [
    takeEvery(types.RESET, reset),

    takeLatest(types.LOGIN_STEP_1_REQUEST, handleLoginStep1Request),
    takeLatest(types.LOGIN_STEP_2_REQUEST, handleLoginStep2Request),
    takeLatest(types.CHANGE_PASSWORD_REQUEST, handleChangeExpiredPassword),
    takeLatest(types.LOGIN_OAUTH_REQUEST, handleOauthRequest),
    takeLatest(types.LOGIN_STEP_3_REQUEST, handleLoginStep3Request),
    takeLatest(types.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    takeLatest(types.FINALIZE_LOGIN, handleFinalizeLogin),
    takeLatest(types.FINGERPRINT_LOGIN_PRE, handleFingerprintLoginPre),
    takeLatest(types.GO_BACK_LOGIN_SHOW_MESSAGE, goBackToLoginAndShowMessage),
    takeLatest(types.GET_CLIENT_COUNTRY_REQ, getClientCountry),
];

export default sagas;

function* getClientCountry() {
    const activeRegion = yield select(selectors.getRegion);
    if (!activeRegion) {
        try {
            const { type, data } = yield call(session.getClientCountry);
            if (type !== "W") {
                const { country } = data.data;
                if (country) {
                    yield put({ type: types.GET_CLIENT_COUNTRY_RES, clientCountry: country.iso_code });

                    // Verify if the client country is in our supported countries
                    if (AVAILABLE_REGIONS.indexOf(country.iso_code) !== -1) {
                        yield put({ type: types.SET_REGION, region: country.iso_code });
                    } else {
                        yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
                    }
                }
            } else {
                yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
            }
        } catch (e) {
            yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
        }
    }
}

function* goBackToLoginAndShowMessage({ message }) {
    yield put(notificationActions.showNotification(message, "error", ["login"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* reset() {
    yield put(routerActions.replace({ pathname: "/loginStep1" }));
}

function* handleFinalizeLogin({ response }) {
    const activeRegion = yield select(selectors.getRegion);
    const { isAdministrator, generalConditions, permissions } = response.data.data;
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);

    if (generalConditions) {
        const {
            generalConditionsShowExpiration,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
        if (activeRegion === REGION_USA) {
            const firstName = yield select(selectors.getUserFirstName);
            yield put({
                type: enrollmentTypes.SET_INVITATION,
                invitation: {
                    username,
                    firstName,
                    lastName: "",
                },
            });
            yield put(replace("/enrollment/Step3Part4"));
        } else {
            yield put(replace("/loginStep4"));
        }
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { environments, lang } = response.data.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(lang);
        yield put(i18nActions.setLang(lang));

        const responseOAuth = yield call(session.oauth, username, exchangeToken);

        if (responseOAuth.type === "W") {
            const errorMsg = i18n.get(
                `returnCode.${responseOAuth.data.error_description}`,
                responseOAuth.data.error_description,
            );
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        } else {
            // se agrega el los atributos debido a que asi llegan desde el response
            // eslint-disable-next-line
            const { access_token, refresh_token } = responseOAuth.data;

            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));

            if (permissions["loans.operations"]) {
                yield put({ type: loansTypes.LIST_LOANS_EXPIRING_SOON_REQUEST });
            }
            const { pepCompleted, irsCompleted } = loginData.user;
            yield put({
                type: types.LOGIN_SUCCESS,
                environment: loginData.environment,
                user: loginData.user,
                environments,
                isAdministrator,
                echeqSubStates: loginData.echeqSubStates,
                featuresForWidgets: loginData.featuresForWidgets,
                hasAccountQr: loginData.hasAccountQr,
            });
            if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
                yield put(replace("/pendingActions"));
            } else if (lastHref) {
                yield put(replace(lastHref));
                yield put(statusActions.deleteLastHref());
            } else {
                yield put(replace("/desktop"));
                yield put(statusActions.resetComeFromLogin());
            }
        }
    }
}

const ERROR_LOGIN_STEP_1_ARRAY = ["COR020W", "API516W", "API517W", "API518W", "API22E", "COR049W", "COR048W"];
function* handleLoginStep1Request({ user, documentType, documentNumber, shouldRememberEmail, formikBag }) {
    const response = yield call(session.loginStep1, user, documentType, documentNumber);
    const { _exchangeToken, _securitySeal, _securitySealAlt, hasLogged } = response.data.data;
    // eslint-disable-next-line no-useless-concat
    const username = `${documentNumber}-${documentType}-` + `AR`;

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
        if (!response.data || !response.data.code || ERROR_LOGIN_STEP_1_ARRAY.includes(response.data.code)) {
            yield put(notificationActions.showNotification(response.data.message || "", "error", ["login"]));
            yield put({ type: types.LOGIN_FAILURE });
        }
    } else {
        yield put({
            type: types.LOGIN_STEP_1_SUCCESS,
            exchangeToken: _exchangeToken,
            securitySeal: _securitySeal,
            securitySealAlt: _securitySealAlt,
            username,
            documentNumber,
            hasLogged,
            shouldRememberEmail,
        });
        yield put(replace("/loginStep2"));
    }
}

function* handleLoginStep2Request({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);

    if (exchangeToken === NOTOKENLOGIN) {
        formikBag.setSubmitting(false);
        yield put(
            notificationActions.showNotification(i18n.get("login.dataEntered.notValid"), "error", ["loginStep2"]),
        );
        yield put({
            type: formTypes.SEND_FORM_DATA_FAILURE,
            idForm: "loginStep2",
            code: "COR020W",
            errors: null,
        });
        return;
    }
    const response = yield call(session.loginStep2, exchangeToken, password, recaptchaResponse);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);

        if (
            response.data.code === "COR020W" ||
            response.data.code === "API019W" ||
            response.data.code === "API020W" ||
            response.data.code === "COR050W" ||
            response.data.code === "COR029E" ||
            response.data.code === "COR047E" ||
            response.data.code === "API516W" ||
            response.data.code === "API517W" ||
            response.data.code === "API518W"
        ) {
            // Wrong credentials || capcha required || capcha invalid
            yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep2"]));
            yield put({ type: types.LOGIN_STEP_2_FAILURE });
            if (response.data.code === "API020W" || response.data.code === "COR050W") {
                // Capcha required || capcha invalid
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep2",
                code: response.data.code,
                errors: response.data.data,
            });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        const { isPasswordExpired } = response.data.data;

        if (isPasswordExpired) {
            yield put({ type: types.SET_ALERT_EXPIRED_PASSWORD });
        } else {
            const {
                environments,
                selectEnvironment,
                lang,
                _userFirstName,
                _userFullName,
                defEnvironment,
                expiredEnvironment,
            } = response.data.data;

            const rememberUser = yield select(selectors.getShouldRememberEmail);

            // Remember user
            if (rememberUser) {
                const userName = yield select(selectors.getUsername);
                yield put(
                    loginActions.setRememberedUser({
                        username: userName,
                        userFirstName: _userFirstName,
                        userFullName: _userFullName,
                    }),
                );
            }

            yield put({
                type: types.LOGIN_STEP_2_SUCCESS,
                lang,
                _userFirstName,
                _userFullName,
                environments,
            });

            if (selectEnvironment && Object.keys(environments).length > 1) {
                yield put(replace("/loginStep3"));
            } else if (defEnvironment !== -1 && expiredEnvironment) {
                const environmentName = environments[defEnvironment].name;
                if (Object.keys(environments).length > 1) {
                    yield put({
                        type: sessionTypes.SET_ALERT_ENVIRONMENT_EXPIRE,
                        daysToExpire: false,
                        environmentName,
                        isDefaultExpired: true,
                    });
                    yield put(replace("/loginStep3"));
                } else {
                    yield put({
                        type: sessionTypes.SET_ALERT_ENVIRONMENT_EXPIRE,
                        daysToExpire: false,
                        environmentName,
                        isDefaultExpired: true,
                    });
                    yield put({
                        type: formTypes.SEND_FORM_DATA_FAILURE,
                        idForm: "loginStep2",
                        code: response.data.code,
                        errors: response.data.data,
                    });
                }
            } else {
                yield put({
                    type: types.LOGIN_STEP_3_REQUEST,
                    idEnvironment: defEnvironment,
                    formikBag,
                });
            }
        }
    }
}

function* handleChangeExpiredPassword({ password, captcha, newPassword, newPasswordConfirmation, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const response = yield call(
        session.changePassword,
        exchangeToken,
        password,
        captcha,
        newPassword,
        newPasswordConfirmation,
    );
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);

        if (
            response.data.code === "COR020W" ||
            response.data.code === "API019W" ||
            response.data.code === "API020W" ||
            response.data.code === "COR050W" ||
            response.data.code === "COR029E" ||
            response.data.code === "COR047E" ||
            response.data.code === "API516W" ||
            response.data.code === "API517W" ||
            response.data.code === "API518W"
        ) {
            // Wrong credentials || capcha required || capcha invalid
            yield put(notificationActions.showNotification(response.data.message, "error", ["loginPasswordExpired"]));
            yield put({ type: types.LOGIN_STEP_2_FAILURE });
            if (response.data.code === "API020W" || response.data.code === "COR050W") {
                // Capcha required || capcha invalid
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginPasswordExpired",
                code: response.data.code,
                errors: response.data.data,
            });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        const {
            environments,
            selectEnvironment,
            lang,
            _userFirstName,
            _userFullName,
            defEnvironmentEnabled,
        } = response.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        const rememberUser = yield select(selectors.getShouldRememberEmail);

        // Remember user
        if (rememberUser) {
            const userName = yield select(selectors.getUsername);
            yield put(
                loginActions.setRememberedUser({
                    username: userName,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                }),
            );
        }

        yield put({
            type: types.LOGIN_STEP_2_SUCCESS,
            lang,
            _userFirstName,
            _userFullName,
            environments,
        });

        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleOauthRequest({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    // eslint-disable-next-line camelcase
    const { redirect_uri } = yield select(assistantSelectors.getParams);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);

    let response;
    if (isFromMessenger || isFromWhatsapp) {
        const thirdPartyToken = yield select(assistantSelectors.getThirdPartyToken);
        response = yield call(
            session.thirdPartyOauth,
            username,
            password,
            thirdPartyToken,
            redirect_uri,
            exchangeToken,
            recaptchaResponse,
        );
        if (response.data) {
            response.data.access_token = thirdPartyToken;
        } else {
            response.data = {
                access_token: thirdPartyToken,
            };
        }
    } else {
        response = yield call(session.oauth, username, password, exchangeToken);
    }

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors({}));
        formikBag.setSubmitting(false);
        const errorMsg = i18n.get(`returnCode.${response.data.error_description}`, response.data.error_description);
        if (
            response.data.error_description === "COR020W" ||
            response.data.error_description === "API019W" ||
            response.data.error_description === "API020W" ||
            response.data.error_description === "COR050W" ||
            response.data.error_description === "COR029E" ||
            response.data.error_description === "COR047E"
        ) {
            // Wrong credentials || captcha required || captcha invalid
            yield put(notificationActions.showNotification(errorMsg, "error", ["loginStep2"]));

            if (response.data.error_description === "API020W" || response.data.error_description === "COR050W") {
                // Captcha required || captcha invalid
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                code: response.data.error_description,
            });
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        // Ignored because it's the OAUTH standard
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data;
        if (!isFromMessenger && !isFromWhatsapp) {
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
        } else {
            yield put(assistantActions.setAccessToken(access_token));
            yield put(replace("/loginStep5"));
            return;
        }
        const environmentsResponse = yield call(session.listEnvironments);
        const {
            environments,
            selectEnvironment,
            lang,
            _userFirstName,
            _userFullName,
            defEnvironmentEnabled,
        } = environmentsResponse.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        const rememberUser = yield select(selectors.getShouldRememberEmail);

        // Remember user
        if (rememberUser) {
            const userName = yield select(selectors.getUsername);
            yield put(
                loginActions.setRememberedUser({
                    username: userName,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                }),
            );
        }

        yield put({
            type: types.LOGIN_OAUTH_SUCCESS,
            lang,
            _userFirstName,
            _userFullName,
            environments,
        });

        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleLoginStep3Request({ idEnvironment, rememberEnvironment, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);
    const geolocation = yield select(geolocationSelectors.getGeoLocation);

    const { locationFound, geoData } = geolocation;
    let locationFoundVar = locationFound;
    let geoDataVar = geoData;

    if (!locationFoundVar || Object.keys(locationFoundVar).length === 0) {
        locationFoundVar = configUtils.getDefaultPosition("pois.defaultMapLocation");
        geoDataVar = unknownResponse;
    }
    // se agrega la consola para determinar si se pudo obtener la ubicacion
    // eslint-disable-next-line no-console
    console.log("location for send ", locationFoundVar);
    // se agrega la consola para determinar si se pudo obtener la ubicacion
    // eslint-disable-next-line no-console
    console.log("geolocation for send ", geoDataVar);

    const location = {
        latitude: locationFoundVar.lat,
        longitude: locationFoundVar.lng,
    };

    let extraInfo = {};
    let idDevice = null;
    let registrationId = null;

    if (window.app) {
        const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
        extraInfo = window.device;
        extraInfo.isTablet = false;
        extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
        extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
        extraInfo.uuid = window.app.getDeviceUUID();
        registrationId = registrationIdResult;
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
        extraInfo = yield deviceUtils.getExtraInfo();
    }

    yield call(
        session.registerUserDevice,
        exchangeToken,
        idDevice,
        registrationId,
        JSON.stringify(extraInfo),
        geoDataVar,
    );
    const response = yield call(
        session.loginStep3,
        exchangeToken,
        idEnvironment,
        rememberEnvironment,
        location,
        idDevice,
        {
            isFromMessenger,
            isFromGoogle,
            isFromAmazon,
            isFromWhatsapp,
            accountLinkingToken,
            clientNumber,
        },
        geoDataVar,
    );
    const environments = yield select(selectors.getEnvironments);
    let environmentName;
    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "API526W") {
            if (idEnvironment === -1) {
                environmentName = Object.values(environments)[0].name;
            } else {
                environmentName = environments[idEnvironment].name;
            }
            yield put({ type: sessionTypes.SET_ALERT_ENVIRONMENT_EXPIRE, daysToExpire: false, environmentName });
            yield put({ type: types.LOGIN_STEP_3_FAILURE });
            formikBag.setSubmitting(false);
        } else if (code === "COR020W" && data.environment) {
            // mark the environment as disabled and redirecting the user to the step 3 again
            if (environments[idEnvironment]) {
                environments[idEnvironment].allowedToAccess = false;
                yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
            }
            yield put(replace("/loginStep3"));
        } else {
            // en este paso lo unico que podria suceder es que se venciera el exchange token
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0
            yield put(replace("/"));
        }
    } else {
        const { data } = response.data;

        const {
            data: {
                data: { enabledAssistant },
            },
        } = response;

        yield put({ type: types.FINALIZE_LOGIN, response });
        yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });
        if (data) {
            const {
                showAlertExpire,
                daysToExpire,
                generalConditions,
                activeIdEnvironment,
                idEnvironmentToAcceptConditions,
                daysToExpirePassword,
            } = data;
            if (showAlertExpire) {
                const idEnvironmentToShow = generalConditions ? idEnvironmentToAcceptConditions : activeIdEnvironment;
                environmentName = environments[idEnvironmentToShow]?.name;
                yield put({ type: sessionTypes.SET_ALERT_ENVIRONMENT_EXPIRE, daysToExpire, environmentName });
            }
            if (daysToExpirePassword > 0) {
                yield put({ type: sessionTypes.SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE, daysToExpirePassword });
            }
        }
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    const activeRegion = yield select(selectors.getRegion);
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const idEnvironmentToAcceptConditions = yield select(selectors.getIdEnvironmentToAcceptConditions);
    const response = yield call(session.loginStep4, exchangeToken, acceptConditions, idEnvironmentToAcceptConditions);
    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep4"]));
        yield put({ type: types.LOGIN_FAILURE, errors: response.data.data });
    } else {
        const loginData = yield call(processLoginSuccess, response);
        const { data } = response.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(data.lang);
        yield put(i18nActions.setLang(data.lang));

        const responseOAuth = yield call(session.oauth, username, exchangeToken);

        if (responseOAuth.type === "W") {
            const errorMsg = i18n.get(
                `returnCode.${responseOAuth.data.error_description}`,
                responseOAuth.data.error_description,
            );
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["login"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        } else {
            // se agrega el los atributos debido a que asi llegan desde el response
            // eslint-disable-next-line
            const { access_token, refresh_token } = responseOAuth.data;
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
            yield put({
                type: types.LOGIN_SUCCESS,
                environment: loginData.environment,
                user: loginData.user,
                environments: data.environments,
                isAdministrator: data.isAdministrator,
                echeqSubStates: loginData.echeqSubStates,
                featuresForWidgets: loginData.featuresForWidgets,
            });

            const { pepCompleted, irsCompleted } = loginData.user;

            if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
                yield put(replace("/pendingActions"));
            } else if (lastHref) {
                // si la sesion expiró y se guardó la url en la que se estaba voy para ahi
                yield put(replace(lastHref));
                yield put(statusActions.deleteLastHref());
            } else {
                yield put(statusActions.resetComeFromLogin());
                // si no, voy a desktop
                yield put(replace("/desktop"));
            }
        }
    }
}

function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        username,
        userFullName,
        userId,
        previousLoginInfo,
        defaultAvatarId,
        email,
        mobileNumber,
        documentType,
        documentNumber,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        hasAccountQr,
        ...data
    } = response.data.data;
    const user = {
        defaultAvatarId,
        previousLoginInfo,
        userFullName,
        userId,
        username,
        email,
        mobileNumber,
        documentType,
        documentNumber,
        accessToken: _accessToken,
        securitySeal: _securitySeal,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        idDefaultEnvironment:
            data.idDefaultEnvironment && data.idDefaultEnvironment > 0 ? data.idDefaultEnvironment : null,
    };

    let forms = null;
    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i++) {
            let category = forms[data.forms[i].category];
            if (!category) {
                category = [];
                forms[data.forms[i].category] = category;
            }
            category.push(data.forms[i]);
        }
    }

    const environment = mapActiveEnvironment(data, forms);
    const echeqSubStates = loadSubStates();
    const featuresForWidgets = data?.featuresForWidgets;

    return { user, environment, echeqSubStates, featuresForWidgets, hasAccountQr };
}

function* handleFingerprintLoginPre() {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;
    const geolocation = yield select(geolocationSelectors.getGeoLocation);

    const { locationFound, geoData } = geolocation;
    let locationFoundVar = locationFound;
    let geoDataVar = geoData;

    if (!locationFoundVar || Object.keys(locationFoundVar).length === 0) {
        locationFoundVar = configUtils.getDefaultPosition("pois.defaultMapLocation");
        geoDataVar = unknownResponse;
    }

    const location = {
        latitude: locationFoundVar.lat,
        longitude: locationFoundVar.lng,
    };

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        const fingerPrintLoginFail = yield select((state) => state.session.fingerprintLoginFail);

        if (!fingerPrintLoginFail) {
            try {
                yield call(fingerprintUtils.verify);

                const username = yield call(secureStorageUtils.get, "fingerprintUsername");
                const response = yield call(session.fingerprintOauth, username, fingerprintAuthToken);
                if (response.status === 200) {
                    yield put({ type: types.FINGERPRINT_LOGIN_SUBMIT });

                    // eslint-disable-next-line camelcase

                    if (response.type === "W") {
                        const errorMsg = i18n.get(
                            `returnCode.${response.data.error_description}`,
                            response.data.error_description,
                        );
                        yield put({ type: types.LOGIN_OAUTH_FAILURE });
                        // exchangeToken expired, restart flow
                        yield put(notificationActions.showNotification(errorMsg, "error", ["login"]));
                        yield put({ type: globalTypes.BACK_TO_STEP_0 });
                        yield put(replace("/"));
                    } else {
                        // se agrega el los atributos debido a que asi llegan desde el response
                        // eslint-disable-next-line
                        const { access_token, refresh_token } = response.data;

                        session.setAuthToken(access_token);
                        yield put(sessionActions.setTokens(access_token, refresh_token));

                        const responseFinger = yield call(session.fingerprintLogin, location, geoDataVar);
                        if (!responseFinger) {
                            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("login.fingerprint.session.wrongAccess"),
                                    "error",
                                    ["login"],
                                ),
                            );
                            yield put({ type: types.BACK_TO_STEP_0 });
                            yield put(replace("/"));
                        } else {
                            const { type } = responseFinger;
                            if (type === "W") {
                                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                                yield put(
                                    notificationActions.showNotification(
                                        responseFinger.data?.message ||
                                            i18n.get("login.fingerprint.session.wrongAccess"),
                                        "error",
                                        ["login"],
                                    ),
                                );
                            } else {
                                const { environments, isAdministrator } = responseFinger.data.data;
                                const loginData = yield call(processLoginSuccess, responseFinger);
                                yield put({
                                    type: types.LOGIN_SUCCESS,
                                    environment: loginData.environment,
                                    user: loginData.user,
                                    environments,
                                    isAdministrator,
                                    echeqSubStates: loginData.echeqSubStates,
                                    featuresForWidgets: loginData.featuresForWidgets,
                                });
                                /**
                                 * Set alert expire env
                                 */
                                if (responseFinger.data && responseFinger.data.data) {
                                    const {
                                        showAlertExpire,
                                        daysToExpire,
                                        daysToExpirePassword,
                                    } = responseFinger.data.data;
                                    if (showAlertExpire) {
                                        yield put({
                                            type: sessionTypes.SET_ALERT_ENVIRONMENT_EXPIRE,
                                            daysToExpire,
                                            environmentName: loginData.environment.name,
                                        });
                                    }

                                    if (daysToExpirePassword > 0) {
                                        yield put({
                                            type: sessionTypes.SET_ALERT_PASSWORD_CLOSE_TO_EXPIRE,
                                            daysToExpirePassword,
                                        });
                                    }
                                }

                                yield put(replace("/desktop"));
                                yield put(statusActions.resetComeFromLogin());
                            }
                        }
                    }
                }
            } catch (error) {
                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    // el detector de huella de android tiene 2 botones para cancelar, si se aprieta uno solo tira FINGERPRINT_ERROR, si se aprietan los 2 tira FINGERPRINT_CANCELLED
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                } else if (error !== fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    // eslint-disable-next-line no-console
                    console.log("Fingerprint - The following error has ocurred: ", error);
                    if (error.status && error.status === 200) {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE });
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.wrongAccess"),
                                "error",
                                ["login"],
                            ),
                        );
                    } else if (error.response && error.response.status && error.response.status === 401) {
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("login.fingerprint.session.expired"),
                                "error",
                                ["login"],
                            ),
                        );
                        yield call(secureStorageUtils.remove, "fingerprintAuthToken");
                        yield put({ type: types.BACK_TO_STEP_0 });
                        yield put(replace("/"));
                    }
                }
            }
        }
    }
}
