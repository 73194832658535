import React, { Component } from "react";
import { string, arrayOf, shape } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "../../../util/i18n";

export default class Signatures extends Component {
    static propTypes = {
        list: arrayOf(shape({})).isRequired,
        title: string.isRequired,
        customClass: string,
    };

    static defaultProps = {
        customClass: "",
    };

    render() {
        const { list, title, customClass } = this.props;

        return (
            <div className={`data-wrapper ${customClass}`}>
                <I18n id={title} componentProps={{ className: "data-label" }} />

                <div className="d-flex-direction-column">
                    {list?.map(({ firstName, lastName, date, fullName, signatureLevel }) => (
                        <span className="data-text">
                            {fullName || (lastName ? `${lastName}, ${firstName}` : firstName)}{" "}
                            {signatureLevel &&
                                i18nUtils.get("forms.transaction.ticket.signatureLevel", "(Nivel) ", {
                                    SIGNATURE_LEVEL: signatureLevel,
                                })}{" "}
                            {date && (
                                <>
                                    <FormattedDate date={date} dateFormat="DD/MM/YYYY HH:mm" />
                                </>
                            )}{" "}
                            <br />
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}
