import React, { Component } from "react";
import { node, oneOfType, func, string } from "prop-types";

class Hero extends Component {
    static propTypes = {
        children: node.isRequired,
        renderAs: oneOfType([func, string]),
    };

    static defaultProps = {
        renderAs: "div",
    };

    render() {
        const { renderAs: Element, children, ...props } = this.props;
        return (
            <div className="view-morphing">
                <Element {...props}>{children}</Element>
            </div>
        );
    }
}

export default Hero;
