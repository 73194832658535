import React, { Component } from "react";
import { bool, string, func, shape, arrayOf, oneOfType, object, number } from "prop-types";
import Select from "react-select";
import FieldLabel from "pages/_components/fields/FieldLabel";

class SelectorInput extends Component {
    inputRef = React.createRef();

    inputGroupRef = React.createRef();

    static propTypes = {
        inputProps: shape({
            name: string.isRequired,
            inputLabelKey: string.isRequired,
            value: string,
            placeholder: string,
        }),
        isFocused: bool.isRequired,
        onChange: func.isRequired,
        selectProps: shape({
            name: string.isRequired,
            selectLabelKey: string.isRequired,
            value: oneOfType([string, object]),
            options: arrayOf(
                shape({
                    label: string.isRequired,
                    value: string.isRequired,
                }),
            ),
        }),
        toggleIsFocused: func.isRequired,
        onBlur: func,
        inputMaxLength: number.isRequired,
        idField: string.isRequired,
    };

    static defaultProps = {
        onBlur: () => {},
        inputProps: {},
        selectProps: {},
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = ({ target }) => {
        const { isFocused, toggleIsFocused, onBlur } = this.props;

        if (this.inputGroupRef.current.contains(target) && !isFocused) {
            toggleIsFocused();
        }

        if (!this.inputGroupRef.current.contains(target) && isFocused) {
            toggleIsFocused();
            onBlur();
        }
    };

    render() {
        const { selectProps, inputProps, onChange, inputMaxLength, idField } = this.props;

        return (
            <div className="input-group" ref={this.inputGroupRef}>
                <FieldLabel hideLabel labelKey={selectProps.selectLabelKey} mode="edit" idField={idField} />
                <Select
                    id={idField}
                    instanceId={idField}
                    aria-labelledby={`label.${idField}`}
                    className="slideFromBottom flex-container"
                    searchable={false}
                    onChange={(selectValue) => onChange(selectValue, inputProps.value)}
                    clearable={false}
                    placeholder=""
                    optionClassName="needsclick"
                    {...selectProps}
                />
                <FieldLabel hideLabel labelKey={inputProps.inputLabelKey} mode="edit" idField={`${idField}.number`} />
                <input
                    id={`${idField}.number`}
                    className="form-control"
                    type="text"
                    ref={this.inputRef}
                    maxLength={inputMaxLength}
                    {...inputProps}
                    onChange={({ target }) => onChange(selectProps.value, target.value)}
                />
            </div>
        );
    }
}

export default SelectorInput;
