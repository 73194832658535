import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import MainContainer from "pages/_components/MainContainer";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { bool, func, shape } from "prop-types";
import I18n from "pages/_components/I18n";
import Alert from "pages/_components/Alert/Alert";
import * as i18nUtils from "util/i18n";
import { actions } from "../../reducers/frequentRecipients";
import Head from "../_components/Head";
import { actions as miscActions } from "../../reducers/misc";

const FORM_ID = "frequentDestination.detailModifyDelete";
const ModifyRecipient = ({ fetching, dispatch, recipient, isMobile, history, setFieldValue }) => {
    const [mode, setMode] = useState("view");

    useEffect(() => {
        if (Object.keys(recipient).length === 0) {
            history.goBack();
        }

        dispatch(miscActions.getDocumentTypes());
    }, [dispatch, history, recipient]);

    const handleDeleteRecipient = () => {
        dispatch(actions.deleteRecipientRequest(recipient.idFrequentDestination));
    };

    const handleCancelDelete = () => {
        history.goBack();
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head showLogo arrowWhite onBack={history.goBack} additionalClassName="blue-main-header-mobile" />;
        }

        return <Head onBack={history.goBack} accessibilityTextId="frequentDestination.detailModifyDelete.title" />;
    };

    const cancelEdit = () => {
        setMode("view");
        setFieldValue("reference", recipient.reference);
        setFieldValue("email", recipient.email);
    };

    const renderForm = () => (
        <Fragment>
            <Notification scopeToShow={FORM_ID} />
            <div className="container-center">
                <div className="title-left-header">
                    <I18n id="frequentDestination.detailModifyDelete.title" component="h1" />
                </div>
                <Form className="form-content">
                    <div className="data-wrapper">
                        <I18n id={`${FORM_ID}.headline.label`} componentProps={{ className: "data-label" }} />
                        <div className="data-text">{recipient.headline}</div>
                    </div>

                    {mode !== "edit" && (
                        <div className="data-wrapper">
                            <I18n id={`${FORM_ID}.reference.label`} componentProps={{ className: "data-label" }} />
                            <div className="data-text">{recipient.reference}</div>
                        </div>
                    )}

                    <div className="data-wrapper">
                        <I18n id={`${FORM_ID}.cuit.label`} componentProps={{ className: "data-label" }} />
                        <div className="data-text">{recipient.cuit}</div>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${FORM_ID}.bank.label`} componentProps={{ className: "data-label" }} />
                        <div className="data-text">{recipient.bank}</div>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${FORM_ID}.accountNumber.label`} componentProps={{ className: "data-label" }} />
                        <div className="data-text">{recipient.accountNumber}</div>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${FORM_ID}.currency.label`} componentProps={{ className: "data-label" }} />
                        <div className="data-text">
                            {i18nUtils.get(
                                `forms.frequentDestination.create.currencyOptions.${recipient.currency}.label`,
                                "",
                            )}
                        </div>
                    </div>

                    <div className="data-wrapper">
                        <I18n
                            id={`${FORM_ID}.destinationIdentifier.label`}
                            componentProps={{ className: "data-label" }}
                        />
                        <div className="data-text">{recipient.destinationIdentifier}</div>
                    </div>

                    {mode !== "edit" && (
                        <div className="data-wrapper">
                            <I18n id={`${FORM_ID}.email.label`} componentProps={{ className: "data-label" }} />
                            <div className="data-text">{recipient.email}</div>
                        </div>
                    )}

                    <div className="btn-wrapper ui-mt-8">
                        <Button
                            bsStyle={`outline ${mode === "delete" && "active"}`}
                            label="global.delete"
                            onClick={() => setMode("delete")}
                            loading={fetching}
                        />
                        <Button
                            bsStyle={`outline ${mode === "edit" && "active"}`}
                            label="frequentDestination.detailModifyDelete.action.modify.label"
                            onClick={() => setMode("edit")}
                        />
                    </div>

                    {mode === "edit" && (
                        <>
                            <hr className="ui-mt-7 ui-mb-7" />

                            <Field
                                mode={mode}
                                idForm={FORM_ID}
                                component={TextField}
                                name="reference"
                                type="text"
                                isFocused
                            />
                            <Field
                                mode={mode}
                                idForm={FORM_ID}
                                component={TextField}
                                name="email"
                                type="text"
                                isFocused
                            />

                            <div className="form-footer">
                                {isMobile && (
                                    <Button
                                        block={isMobile}
                                        bsStyle="primary"
                                        type="submit"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                )}
                                <Button
                                    block={isMobile}
                                    onClick={() => cancelEdit()}
                                    bsStyle="outline"
                                    label="global.cancel"
                                    loading={fetching}
                                />
                                {!isMobile && (
                                    <Button
                                        block={isMobile}
                                        bsStyle="primary"
                                        type="submit"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                )}
                            </div>
                        </>
                    )}

                    {mode === "delete" && (
                        <>
                            <hr className="ui-mt-7 ui-mb-7" />

                            <Alert className="ui-mb-3" icon="images/ui-icons/ui-exclamation-orange.svg">
                                <I18n id="frequentDestination.delete.message.confirmAlert" />
                            </Alert>

                            <I18n
                                id="frequentDestination.delete.message.confirm"
                                component="p"
                                componentProps={{ className: "ui-fw-bold" }}
                            />

                            <div className="btn-wrapper ui-mt-7 frequent-destination-container">
                                {isMobile && (
                                    <Button
                                        onClick={handleDeleteRecipient}
                                        bsStyle="primary"
                                        type="submit"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                )}
                                <Button
                                    onClick={handleCancelDelete}
                                    bsStyle="outline"
                                    label="global.cancel"
                                    loading={fetching}
                                />

                                {!isMobile && (
                                    <Button
                                        onClick={handleDeleteRecipient}
                                        bsStyle="primary"
                                        type="submit"
                                        label="global.confirm"
                                        loading={fetching}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <Notification scopeToShow={FORM_ID} />
            {renderHeader()}
            <MainContainer showLoader={fetching}>{renderForm()}</MainContainer>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    recipient: state.frequentRecipients.recipientSelected,
    fetching: state.frequentRecipients.fetching,
});

ModifyRecipient.propTypes = {
    fetching: bool.isRequired,
    isMobile: bool.isRequired,
    recipient: shape({}).isRequired,
    history: func.isRequired,
    dispatch: func.isRequired,
    setFieldValue: func.isRequired,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            headline:
                props.recipient.headline === null || props.recipient.headline === undefined
                    ? ""
                    : props.recipient.headline,
            reference:
                props.recipient.reference === null || props.recipient.reference === undefined
                    ? ""
                    : props.recipient.reference,
            accountNumber:
                props.recipient.accountNumber === null || props.recipient.accountNumber === undefined
                    ? ""
                    : props.recipient.accountNumber,
            bank: props.recipient.bank === null || props.recipient.bank === undefined ? "" : props.recipient.bank,
            email: props.recipient.email === null || props.recipient.email === undefined ? "" : props.recipient.email,
            destinationIdentifier:
                props.recipient.destinationIdentifier === null || props.recipient.destinationIdentifier === undefined
                    ? ""
                    : props.recipient.destinationIdentifier,
            cuit: props.recipient.cuit === null || props.recipient.cuit === undefined ? "" : props.recipient.cuit,
        }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, recipient } = formikBag.props;

            const frequentDestination = {
                idFrequentDestination: recipient.idFrequentDestination,
                reference: values.reference,
                headline: values.headline,
                email: values.email,
                idForm: "frequentDestination",
            };
            dispatch(actions.updateRecipientRequest(frequentDestination, formikBag));
        },
    }),
)(ModifyRecipient);
