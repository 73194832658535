import React, { Component } from "react";
import { connect } from "react-redux";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";

import { func, bool } from "prop-types";

import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Step3Content from "pages/recoveryPassword/_components/Step3Content";
import withExchangeToken from "pages/_components/withExchangeToken";
import HeaderLogin from "../_components/header/HeaderLogin";

class RecoveryPassStep3 extends Component {
    componentWillUnmount() {
        this.props.dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        return (
            <>
                <Notification scopeToShow="recoveryPassword" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <Step3Content {...this.props} />
                    </MainContainer>
                </div>
            </>
        );
    }
}

RecoveryPassStep3.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
    resetCode: recoveryPasswordSelectors.getResetCode(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep3));
