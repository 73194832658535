import React, { Component } from "react";
import { history } from "store";
import { shape, bool, func } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import ItemExpirationDate from "pages/_components/item/ExpirationDate";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import I18n from "pages/_components/I18n";
import Dropdown from "pages/_components/Dropdown";
import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import GenericItem from "components/GenericItem/GenericItem";

class Deposit extends Component {
    static propTypes = {
        deposit: shape().isRequired,
        isMobile: bool.isRequired,
        cancelRenewal: func.isRequired,
        hasPermission: bool.isRequired,
    };

    render() {
        const { deposit, isMobile, cancelRenewal, hasPermission } = this.props;
        const {
            idProduct,
            productAlias,
            operationCode,
            currency,
            descriptionSIOC,
            agreedRate,
            dueDate,
            amount,
            netToPay,
            renewable,
            operationOrder,
        } = deposit;

        return (
            <>
                {isMobile ? (
                    <GenericItem
                        role="button"
                        aria-label={productAlias || operationCode}
                        aria-describedby={idProduct}
                        onClick={(e) =>
                            !e.target.classList.contains("btn-link") &&
                            history.push(`/fixedTermDeposits/${idProduct}/${operationOrder}`)
                        }
                        options={
                            <>
                                {renewable === "S" && hasPermission && (
                                    <Dropdown
                                        image="images/ui-icons/ui-dropdown-mobile.svg"
                                        label=""
                                        bsStyle="link"
                                        className="btn-only-icon"
                                        pullRight>
                                        <Button
                                            key="investments.listFixedTermDeposits.cancelRenewal.label"
                                            label="investments.listFixedTermDeposits.cancelRenewal.label"
                                            className="dropdown__item-btn"
                                            bsStyle="link"
                                            onClick={() => cancelRenewal(deposit)}
                                        />
                                    </Dropdown>
                                )}
                            </>
                        }
                        arrow>
                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.alias"
                                componentProps={{ className: "data-label" }}
                            />
                            <span>{productAlias || operationCode}</span>
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.descriptionSIOC"
                                componentProps={{ className: "data-label" }}
                            />
                            <span>{descriptionSIOC}</span>
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.expiryDate"
                                componentProps={{ className: "data-label" }}
                            />
                            <span className="deposits-item">
                                <ItemExpirationDate date={dueDate} />
                                {renewable !== "N" && <span className="renewable">AUTO</span>}
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.agreedRate"
                                componentProps={{ className: "data-label" }}
                            />
                            <span>{`${agreedRate} %`}</span>
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.investedCapital"
                                componentProps={{ className: "data-label" }}
                            />
                            <span>
                                <FormattedAmount currency={currency} quantity={amount} />
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <I18n
                                id="tableHeader.listFixedTermDeposits.netToPay"
                                componentProps={{ className: "data-label" }}
                            />
                            <span>
                                <FormattedAmount currency={currency} quantity={netToPay} />
                            </span>
                        </div>
                    </GenericItem>
                ) : (
                    <Table.Row
                        key={`deposit-${idProduct}`}
                        role="button"
                        onClick={(e) =>
                            !e.target.classList.contains("btn-link") &&
                            history.push(`/fixedTermDeposits/${idProduct}/${operationOrder}`)
                        }>
                        <Table.Data align="left">
                            <span>{productAlias || operationCode}</span>
                        </Table.Data>

                        <Table.Data align="left">
                            <span>{descriptionSIOC}</span>
                        </Table.Data>

                        <Table.Data align="left">
                            <span className="deposits-item">
                                <ItemExpirationDate date={dueDate} />
                                {renewable !== "N" && <span className="renewable">AUTO</span>}
                            </span>
                        </Table.Data>

                        <Table.Data align="left">
                            <span>{`${agreedRate} %`}</span>
                        </Table.Data>

                        <Table.Data align="right">
                            <FormattedAmount currency={currency} quantity={amount} />
                        </Table.Data>

                        <Table.Data align="right">
                            <FormattedAmount currency={currency} quantity={netToPay} />
                        </Table.Data>

                        <Table.Data align="left" className="table-data-icon">
                            {renewable === "S" && (
                                <Dropdown
                                    image="images/ui-icons/ui-dropdown.svg"
                                    label=""
                                    bsStyle="link"
                                    className="btn-only-icon"
                                    pullRight>
                                    <Button
                                        key="investments.listFixedTermDeposits.cancelRenewal.label"
                                        label="investments.listFixedTermDeposits.cancelRenewal.label"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                        onClick={() => cancelRenewal(deposit)}
                                    />
                                </Dropdown>
                            )}
                        </Table.Data>

                        <Table.Data align="right" className="table-data-icon">
                            <ChevromRight />
                        </Table.Data>
                    </Table.Row>
                )}
            </>
        );
    }
}
export default Deposit;
