import React from "react";
import Stepper, { Step, orientations } from "pages/_components/StepIndicator/index";
import I18n from "pages/_components/I18n";
import { string, number, oneOf } from "prop-types";
import { connect } from "react-redux";

import { selectors as loginSelectors } from "reducers/login";
import { REGION_USA } from "constants.js";

const EnrollmentStepper = ({ currentStep, orientation, className, activeRegion }) => {
    if (activeRegion === REGION_USA) {
        return (
            <Stepper className={className} orientation={orientation}>
                <Step currentStep={currentStep} stepNumber={1}>
                    <I18n id="onboarding.steps.indicator.emailValidation" />
                </Step>
                <Step currentStep={currentStep} stepNumber={2}>
                    <I18n id="onboarding.steps.indicator.cellPhoneValidation" />
                </Step>
                <Step currentStep={currentStep} stepNumber={3}>
                    <I18n id="onboarding.steps.indicator.password" />
                </Step>
                <Step currentStep={currentStep} stepNumber={4}>
                    <I18n id="onboarding.steps.indicator.secSeal" />
                </Step>
                <Step currentStep={currentStep} stepNumber={5}>
                    <I18n id="onboarding.steps.indicator.esign" />
                </Step>
            </Stepper>
        );
    }

    return (
        <Stepper className={className} orientation={orientation}>
            <Step currentStep={currentStep} stepNumber={1}>
                <I18n id="onboarding.steps.indicator.emailValidation" />
            </Step>
            <Step currentStep={currentStep} stepNumber={2}>
                <I18n id="onboarding.steps.indicator.cellPhoneValidation" />
            </Step>
            <Step currentStep={currentStep} stepNumber={3}>
                <I18n id="onboarding.steps.indicator.password" />
            </Step>
            <Step currentStep={currentStep} stepNumber={4}>
                <I18n id="onboarding.steps.indicator.secSeal" />
            </Step>
        </Stepper>
    );
};

EnrollmentStepper.propTypes = {
    currentStep: number.isRequired,
    orientation: oneOf(Object.keys(orientations)),
    className: string,
    activeRegion: string.isRequired,
};

EnrollmentStepper.defaultProps = {
    orientation: orientations.vertical,
    className: undefined,
};

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(EnrollmentStepper);
export { orientations };
