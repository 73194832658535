import React, { Component } from "react";
import { compose } from "redux";
import { actions as productsActions } from "reducers/products";
import { shape, string, func } from "prop-types";

import { resizableRoute } from "pages/_components/Resizable";
import EditableLabel from "pages/_components/fields/EditableLabel";

import * as i18n from "../../../util/i18n";

class Information extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        loanType: string.isRequired,
        loan: shape({
            operationCode: string,
            holder: string,
            productType: string,
        }).isRequired,
        loanId: string.isRequired,
    };

    saveAlias = (alias) => {
        const { loanId, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, loanId));
    };

    render() {
        const { loan, loanType } = this.props;
        const { descriptionSIOC, operationCode, holder, productAlias } = loan || {};
        return (
            <>
                <div className="title-left-header ui-mb-3">
                    {loanType !== "history" ? (
                        <div className="editable-title">
                            <EditableLabel
                                onSave={this.saveAlias}
                                value={productAlias || operationCode}
                                editWithModal
                                modalMainLabel="loans.alias.modal.title"
                                modalButtonLabel="loans.alias.modal.confirm.button"
                                isTitle
                            />
                        </div>
                    ) : (
                        <h1 className="product-title">{productAlias || operationCode}</h1>
                    )}
                </div>
                <div className="detail-head-info-main">
                    <mark className="product-name product-name-account">
                        <span>{descriptionSIOC}</span>
                    </mark>

                    <div className="data-wrapper">
                        <div className="data-label">{i18n.get("loan.detail.operationNumber")}</div>
                        <span className="data-numeric">{`${operationCode}`}</span>
                    </div>

                    <div className="data-wrapper">
                        <div className="data-label">{i18n.get("loan.detail.holder")}</div>
                        <span className="data-numeric">{`${holder}`}</span>
                    </div>
                </div>
            </>
        );
    }
}

export default compose(resizableRoute)(Information);
