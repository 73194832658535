import React, { Component, Fragment } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18nUtils from "util/i18n";
import GenericItem from "components/GenericItem/GenericItem";

class TransactionsItem extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        transaction: shape({
            cancelEnabled: bool,
            transaction: shape({
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        onClick: func,
    };

    static defaultProps = {
        onClick: null,
    };

    onHandleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    render() {
        const { isDesktop, transaction } = this.props;
        const { dateTime, idActivity, clientIP, channel } = transaction;

        const webContent = (
            <Fragment>
                <div className="table-data">
                    <span className="data-date text-left">
                        <FormattedDate date={dateTime} />
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-date text-left">
                        <FormattedDate date={dateTime} dateFormat="HH:mm" />
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-aux">{i18nUtils.get(`userTransactions.channels.${channel}`)}</span>
                </div>
                <div className="table-data">
                    <span className="data-aux">{i18nUtils.get(`activities.${idActivity}`)}</span>
                </div>
                <div className="table-data">
                    <span className="data-aux">{clientIP}</span>
                </div>
            </Fragment>
        );

        const mobileContent = (
            <GenericItem onClick={this.onHandleClick}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="userTransactions.list.header.date" />
                    </div>
                    <div className="data-text">
                        <FormattedDate date={dateTime} />
                    </div>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="userTransactions.list.header.time" />
                    </div>
                    <div className="data-text">
                        <FormattedDate date={dateTime} dateFormat="HH:mm" />
                    </div>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="userTransactions.list.header.channel" />
                    </div>
                    <span className="data-text">{i18nUtils.get(`userTransactions.channels.${channel}`)}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="userTransactions.list.header.activity" />
                    </div>
                    <span className="data-text">{i18nUtils.get(`activities.${idActivity}`)}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="userTransactions.list.header.ip" />
                    </div>
                    <span className="data-text">{clientIP}</span>
                </div>
            </GenericItem>
        );

        return isDesktop ? webContent : mobileContent;
    }
}

export default connect()(TransactionsItem);
