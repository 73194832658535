import React, { Component } from "react";
import { func, string, number, oneOfType } from "prop-types";
import NumberFormat from "react-number-format";

class NumberFormatPercentage extends Component {
    static propTypes = {
        value: oneOfType([string, number]).isRequired,
        decimalScale: number.isRequired,
        renderText: func.isRequired,
    };

    render() {
        const { value } = this.props;
        return value === "N/A" ? (
            <span>{value}</span>
        ) : (
            <NumberFormat decimalSeparator="." thousandSeparator="," displayType="text" suffix="%" {...this.props} />
        );
    }
}

export default NumberFormatPercentage;
