import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import queryString from "query-string";

import * as creditCards from "middleware/creditCards";
import * as i18n from "util/i18n";
import { NO_TRANSITION } from "constants.js";
import { types, actions } from "reducers/creditCards";
import { actions as creditCardActions } from "reducers/creditCard";
import { actions as notificationActions } from "reducers/notification";

const sagas = [takeLatest(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest({ path }) {
    const response = yield call(creditCards.listRequest);

    if (response.type === "W") {
        yield put(actions.listFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardsPayment",
                "creditCardsList",
            ]),
        );
    } else {
        const { creditCards: responseCards } = response.data.data;

        yield put(creditCardActions.hideOptions());

        if (responseCards.length === 1 && path) {
            const { idProduct } = responseCards[0];

            // listRequest method is reused on credit cards payment which should redirect to a dynamic
            // form instead of credit card detail
            if (path.startsWith("/form")) {
                yield put(
                    replace({
                        pathname: path,
                        search: queryString.stringify({ creditCard: idProduct }),
                    }),
                );
            } else {
                yield put(
                    replace({
                        pathname: `${path}/${idProduct}`,
                        state: { transition: NO_TRANSITION },
                    }),
                );
            }
        } else {
            yield put(actions.listSuccess(responseCards));
        }
    }
}
