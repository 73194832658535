import * as API from "middleware/api";
import { format } from "date-fns";
import * as configUtils from "util/config";

export const detailsRequest = (id) => API.executeWithAccessToken("/creditCards.read", { idCreditCard: id });

export const movementsRequest = (id, filters = {}) => {
    let { dateFrom, dateTo } = filters;
    const { minAmount, maxAmount, pageNumber, concept } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    const params = {
        idCreditCard: id,
        currency: configUtils.get("core.masterCurrency"),
        dateFrom,
        dateTo,
        maxAmount,
        minAmount,
        pageNumber,
        concept,
    };

    return API.executeWithAccessToken("creditCards.listStatements", params);
};

export const downloadMovements = (idCreditCard, filters, fileFormat) => {
    let { dateFrom, dateTo } = filters;
    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/creditCards.downloadMovements", {
        idCreditCard,
        format: fileFormat,
        currency: configUtils.get("core.masterCurrency"),
        ...filters,
        dateFrom,
        dateTo,
    });
};
