import React, { Component, Fragment } from "react";
import { bool, node, string, shape, oneOf, arrayOf, func, oneOfType } from "prop-types";
import { connect } from "react-redux";
import PageTransition from "react-router-page-transition";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";

import { getTransition } from "util/transition";
import Notification from "pages/_components/Notification";

import { resizableRoute } from "pages/_components/Resizable";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import NavigationBarDesktop from "pages/_components/NavigationBarDesktop";
import FooterDesktop from "pages/_components/FooterDesktop";
import Menu from "pages/_components/menu/Menu";
import WithPermissionsRedirect from "../_components/WithPermissionsRedirect";

class DefaultLayout extends Component {
    static propTypes = {
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        component: oneOfType([node, func]).isRequired,
        componentProps: shape({}),
        transition: oneOf([
            "transition-drill-out",
            "transition-drill-in",
            "transition-flow-close",
            "transition-flow-open",
            "transition-change-feature",
        ]),
        location: shape({
            pathname: string.isRequired,
            state: shape({
                transition: oneOf([
                    "transition-drill-out",
                    "transition-drill-in",
                    "transition-flow-close",
                    "transition-flow-open",
                    "transition-change-feature",
                ]),
            }),
        }).isRequired,
        withoutLayout: bool,
        somePermissions: bool,
        permissions: arrayOf(string),
        comparator: func,
        hasActiveSession: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isMobile: false,
        isMobileNative: false,
        transition: "transition-flow-open",
        withoutLayout: false,
        somePermissions: false,
        permissions: null,
        comparator: null,
        hasActiveSession: false,
        componentProps: null,
    };

    state = {
        transition: "transition-flow-open",
        isSidebarCollapsed: false,
        isNavbarHover: false,
        isSidebarHovered: false,
        styles: {},
    };

    componentDidMount() {
        const { isMobileNative } = this.props;
        if (isMobileNative) {
            this.setState({
                styles: {
                    position: "absolute",
                    top: 0,
                    height: "100%",
                },
            });
        }

        document.body.style.backgroundColor = "#f9f9fb";
    }

    handleToggleSidebar = () => {
        const { isSidebarCollapsed } = this.state;

        this.setState((prevState) => ({ isSidebarCollapsed: !prevState.isSidebarCollapsed }));

        if (!isSidebarCollapsed) {
            window.dispatchEvent(new Event("resize"));
        }
    };

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition:
                    (nextProps.location.state && nextProps.location.state.transition) ||
                    nextProps.transition ||
                    getTransition(this.props, nextProps),
            });
        }
    }

    setNavbarHover = (isMouseHover) => {
        this.setState({ isNavbarHover: isMouseHover });
    };

    setSidebarHover = (isMouseHover) => {
        this.setState({ isSidebarHovered: isMouseHover });
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, hasActiveSession } = this.props;
        const extras = { isMobile, isDesktop };
        const { transition, styles } = this.state;

        return (
            <div className={transition} style={styles}>
                <PageTransition timeout={600}>
                    <div className="view-wrapper theme-auth transition-item">
                        <ErrorBoundary>
                            <Notification scopeToShow="menu" />
                            <ReceivedComponent {...matchProps} {...extras} hasActiveSession={hasActiveSession} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            componentProps,
            withoutLayout,
            hasActiveSession,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, ...componentProps };
        const { isSidebarCollapsed, isNavbarHover, isSidebarHovered } = this.state;

        return (
            <ErrorBoundary>
                {!withoutLayout && (
                    <div className="app theme-auth default-layout">
                        <header className="app-header">
                            <NavigationBarDesktop />
                        </header>

                        <div className={`app-page ${isSidebarCollapsed && "sidebar-collapsed"}`}>
                            <aside className="app-sidebar">
                                <div
                                    className={`scrollable-sidebar ${isNavbarHover ? "sidebar-hover" : ""}`}
                                    onMouseLeave={() => this.setSidebarHover(false)}
                                    onMouseEnter={() => this.setSidebarHover(true)}>
                                    <Menu
                                        isMobile={isMobile}
                                        onToggleSidebar={this.handleToggleSidebar}
                                        isCollapsed={isSidebarCollapsed}
                                        setNavbarHover={this.setNavbarHover}
                                        isSidebarHovered={isSidebarHovered}
                                    />
                                </div>
                            </aside>

                            <div className={`app-content ${isSidebarHovered ? "app-content-hovered" : ""}`}>
                                <div className="view-wrapper theme-auth ui-scrollbar">
                                    <Notification scopeToShow="menu" />
                                    <ReceivedComponent
                                        {...matchProps}
                                        {...extras}
                                        hasActiveSession={hasActiveSession}
                                    />
                                </div>

                                <FooterDesktop />
                            </div>
                        </div>
                    </div>
                )}
                {withoutLayout && (
                    <div className="app-page sidebar-collapsed">
                        <div className="app-content">
                            <div className="view-wrapper theme-auth ui-scrollbar">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </div>
                        </div>
                    </div>
                )}
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, somePermissions, permissions, comparator, ...rest } = this.props;

        return (
            <WithPermissionsRedirect
                somePermissions={somePermissions}
                permissions={permissions}
                comparator={comparator}>
                <Route
                    {...rest}
                    render={(matchProps) => {
                        const { isMobile } = this.props;

                        if (isMobile) {
                            return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                        }

                        return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                    }}
                />
            </WithPermissionsRedirect>
        );
    }
}

export default withRouter(connect()(resizableRoute(DefaultLayout)));
