import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bool, number, oneOfType, shape, string, object, func } from "prop-types";
import classNames from "classnames";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ItemExpirationDate from "pages/_components/item/ExpirationDate";
import ItemName from "pages/_components/item/Name";

class ListItemDesktop extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string.isRequired,
        currency: string.isRequired,
        expirationDate: string,
        expirationText: string,
        expiredText: string,
        icon: string,
        isExpired: bool,
        name: string.isRequired,
        overdraftEnabled: bool,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        title: string.isRequired,
        status: oneOfType([object]),
        franchise: string,
        productTypeTitle: string.isRequired,
        idProduct: string.isRequired,
        onClick: func.isRequired,
        productNumber: string.isRequired,
        isDepositsWidget: bool,
    };

    static defaultProps = {
        expirationDate: null,
        expirationText: null,
        expiredText: null,
        icon: null,
        isExpired: null,
        overdraftEnabled: null,
        status: null,
        franchise: "",
        isDepositsWidget: false,
    };

    getContent = () => {
        const {
            amount,
            amountLabel,
            currency,
            expirationDate,
            expirationText,
            expiredText,
            icon,
            isExpired,
            name,
            overdraftEnabled,
            title,
            status,
            idProduct,
            productNumber,
            isDepositsWidget,
        } = this.props;

        if (isDepositsWidget) {
            return (
                <div id={idProduct} className="Product deposits">
                    <div className="Product-row">
                        <ItemName name={name} icon={icon} />
                        <p className="data-label">{title}</p>
                    </div>
                    <div className="Product-row">
                        {overdraftEnabled && (
                            <span className="Product-label">
                                <span className="text-label quiet-label">
                                    <I18n id="accounts.overdraft.enabled" />
                                </span>
                            </span>
                        )}
                        {expirationDate && (
                            <span className="Product-label">
                                <ItemExpirationDate
                                    date={expirationDate}
                                    expiredText={expiredText}
                                    expirationText={expirationText}
                                    isExpired={isExpired}
                                />
                            </span>
                        )}
                    </div>
                    <div className="Product-row">
                        <div>
                            {status && <span className="Product-label">{status.shortDescription}</span>}
                            <p className="data-label">{amountLabel}</p>
                            <FormattedAmount quantity={amount} currency={currency} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="Product">
                <ItemName name={name} icon={icon} />
                <div className="Product-row">
                    <p className="data-label">{title}</p>
                    <p className="data-desc">{productNumber}</p>
                </div>
                <div id={idProduct} className="Product-row">
                    <p className="data-label">{amountLabel}</p>
                    <FormattedAmount quantity={amount} currency={currency} />
                    {overdraftEnabled && (
                        <span className="Product-label">
                            <span className="text-label quiet-label">
                                <I18n id="accounts.overdraft.enabled" />
                            </span>
                        </span>
                    )}
                    {expirationDate && (
                        <span className="Product-label">
                            <ItemExpirationDate
                                date={expirationDate}
                                expiredText={expiredText}
                                expirationText={expirationText}
                                isExpired={isExpired}
                            />
                        </span>
                    )}
                </div>
                {status && <span className="Product-label">{status.shortDescription}</span>}
            </div>
        );
    };

    onHandleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    render() {
        const { name, path, franchise, productTypeTitle, idProduct } = this.props;
        if (path) {
            return (
                <Link
                    aria-label={`${productTypeTitle}: ${franchise !== "" ? `${franchise} ` : ""}${name}`}
                    aria-describedby={idProduct}
                    className={classNames("table-row")}
                    to={path}
                    onClick={this.onHandleClick}
                    role="menuitem">
                    {this.getContent()}
                </Link>
            );
        }
        return this.getContent();
    }
}

export default ListItemDesktop;
