import React, { Component } from "react";
import { withFormik } from "formik";
import { compose } from "redux";
import { bool, string, func, oneOf, shape, arrayOf, node, any } from "prop-types";
import { replace, goBack } from "react-router-redux";
import moment from "moment";
import { actions as filterActions } from "reducers/filters";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as templateActions } from "reducers/template";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import { Mixpanel } from "util/clickstreaming";

import FormActions from "pages/forms/_components/FormActions";
import mapProps from "pages/_components/mapProps";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import TransactionTicket from "pages/forms/_components/TransactionTicket/TransactionTicket";
import MultipleTransactionsTicket from "pages/forms/_components/TransactionTicket/MultipleTransactionsTicket";
import TemplateList from "pages/forms/_components/TemplateList";
import FooterDesktop from "pages/_components/FooterDesktop";
import { connect } from "react-redux";
import { actions as accountActions } from "reducers/accounts";
import FormConfirmation from "./FormConfirmation";
import OpeningAccountStep2 from "./_regulations/OpeningAccountStep2";
import TransferMassiveStep2 from "../customForms/TransferMassiveStep2";
import CancelRequest from "../customForms/CancelRequest";

let showTitle = false;

class FormRenderCustom extends Component {
    static propTypes = {
        values: shape({}).isRequired,
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        currentLang: string.isRequired,
        metadata: shape({}).isRequired,
        handleSubmit: func.isRequired,
        mode: oneOf([
            "view",
            "edit",
            "edit-step2",
            "preview",
            "cancel-request",
            "massive-list-edit",
            "massive-list-preview",
            "massive-list-view",
        ]),
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        // tells if the form is being rendered to confirm a recently made transaction
        ticketConfirmation: bool,
        title: string,
        templates: arrayOf(shape()),
        history: shape({ location: shape({ pathname: string }) }).isRequired,
        match: shape({ path: string, url: string, isExact: bool }).isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        setFieldValue: func.isRequired,
        isMobile: bool,
        isDesktop: bool.isRequired,
        fetching: bool,
        location: shape({ pathname: string }).isRequired,
        id: string,
        childrenTransactions: shape({}),
        parentTransaction: shape({}),
        transaction: shape({
            idTransaction: string,
        }),
        renderFields: func.isRequired,
        idActivity: string.isRequired,
        renderAdditionalActions: func,
        renderEditableInPreview: func,
        renderFootNote: node,
        showSubmit: bool,
        titleConfirmation: bool,
        renderFieldsTermsAndConditions: func,
        customLabelMessage: string,
        showConfirmationMessageAboveField: bool,
        returnBack: bool,
        noPre: bool,
        subtitle: string,
        handleCancel: func,
        exportList: string,
        isFetchingExport: bool,
        handleClickDownload: func,
        confirmLabelEmpty: bool,
        validateFieldDraft: func,
        buttonLabel: string,
        modalButtonLabel: string,
        subtitleClean: bool,
        hideHeaderBack: bool,
        resetForm: func.isRequired,
        withoutTransaction: bool,
        userTransactionDetail: shape({}),
        additionalFieldsToValidate: arrayOf(string),
        showDownloadButtonInForm: bool,
        formConfirmationClass: string,
        showHeader: bool,
        fullWidthLayout: bool,
        errorsAux: shape({}),
        mobileMenuOptions: arrayOf(any),
        data: shape({}).isRequired,
        multipleTransactionsForm: bool,
        submitDisabled: bool,
        modalCustom: func,
        showModalCustom: bool,
        comeFormDetail: bool,
        isModifiable: bool,
        showDownloadButton: bool,
        handleReportClick: func,
        isFetchingDownloadTicket: bool,
        hideTitle: bool,
    };

    static defaultProps = {
        mode: "edit",
        fromBackoffice: false,
        ticketConfirmation: false,
        title: "",
        templates: [],
        isMobile: false,
        fetching: false,
        id: null,
        childrenTransactions: null,
        parentTransaction: null,
        renderAdditionalActions: null,
        renderEditableInPreview: null,
        renderFootNote: null,
        showSubmit: true,
        titleConfirmation: false,
        renderFieldsTermsAndConditions: null,
        customLabelMessage: "",
        showConfirmationMessageAboveField: false,
        returnBack: false,
        noPre: false,
        subtitle: null,
        handleCancel: null,
        exportList: "",
        isFetchingExport: false,
        handleClickDownload: null,
        confirmLabelEmpty: false,
        validateFieldDraft: null,
        buttonLabel: "global.send",
        modalButtonLabel: "global.send",
        subtitleClean: false,
        hideHeaderBack: false,
        withoutTransaction: false,
        userTransactionDetail: null,
        additionalFieldsToValidate: [],
        showDownloadButtonInForm: false,
        formConfirmationClass: null,
        showHeader: true,
        fullWidthLayout: true,
        errorsAux: null,
        mobileMenuOptions: null,
        multipleTransactionsForm: false,
        submitDisabled: false,
        modalCustom: null,
        showModalCustom: false,
        comeFormDetail: false,
        isModifiable: true,
        showDownloadButton: false,
        handleReportClick: null,
        isFetchingDownloadTicket: false,
        hideTitle: false,
        transaction: {},
    };

    componentDidMount() {
        this.loadTemplateList();
    }

    componentDidUpdate(prevProps) {
        const prevTemplateSize = prevProps.templates.length;
        const currentTemplateSize = this.props.templates.length;

        const { errorsAux, setErrors } = this.props;

        if (prevTemplateSize !== 0 && currentTemplateSize !== prevTemplateSize) {
            this.loadTemplateList();
        }

        if (prevProps.errorsAux !== errorsAux) {
            setErrors({ ...errorsAux });
        }
    }

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match;
        if (!pathname.includes(url)) {
            dispatch(formActions.formClosed());
        }
    }

    loadTemplateList = () => {
        const {
            metadata: { templatesEnabled },
            mode,
            dispatch,
            idActivity,
            fromBackoffice,
        } = this.props;
        const isTemplatesVisibile = mode === "edit" && templatesEnabled && !fromBackoffice;
        if (isTemplatesVisibile) {
            const idActivityTemplate = idActivity.replace(".pre", ".send");
            dispatch(templateActions.loadTemplateList(null, idActivityTemplate));
        }
    };

    // eslint-disable-next-line consistent-return
    handleClick = (action) => {
        const {
            dispatch,
            metadata: { idForm, idActivity, draftsEnabled },
            transaction,
            userTransactionDetail,
            withoutTransaction,
            values,
        } = this.props;

        let newValues = values;
        const { idTransaction } = withoutTransaction && userTransactionDetail ? userTransactionDetail : transaction;

        if (draftsEnabled && values?.amount?.quantity === "") {
            const newAmount = {
                currency: values.amount.currency,
                quantity: "0",
            };
            newValues = {
                ...values,
                amount: newAmount,
            };
        }
        Mixpanel.track_form(idForm, {
            action,
            idActivity,
            ...newValues,
        });
        switch (action) {
            case "createTemplate":
                dispatch(templateActions.createTemplate());
                this.loadTemplateList();
                break;
            case "saveDraft": {
                const { setErrors, validateFieldDraft } = this.props;
                if (validateFieldDraft) {
                    const errorForm = validateFieldDraft(values);
                    if (errorForm && Object.keys(errorForm).length > 0) {
                        return setErrors(errorForm);
                    }
                }
                dispatch(
                    formActions.saveDraft({
                        idActivityDraft: idActivity,
                        data: newValues,
                        idTransaction: transaction ? idTransaction : null,
                    }),
                );

                break;
            }
            case "cancelTransaction":
                dispatch(formActions.cancelTransactionPre({ idActivity, idForm }));
                break;
            case "modifyTransaction":
                dispatch(formActions.modifyTransaction(idTransaction));
                dispatch(formActions.setCancellingTransaction(false));
                break;
            case "signTransaction":
                dispatch(
                    formActions.signTransactionPreview({
                        idForm,
                        idActivity,
                        idTransaction,
                    }),
                );
                dispatch(formActions.setCancellingTransaction(false));
                break;
            case "downloadTicketPdf":
                dispatch(formActions.downloadTicketCustom(idTransaction, idActivity));
                dispatch(formActions.setCancellingTransaction(false));
                break;
            case "shareTicket":
                dispatch(formActions.shareTicket(idTransaction, "pdf", idForm));
                dispatch(formActions.setCancellingTransaction(false));
                break;
            case "cancelRequest":
                dispatch(formActions.setMode("cancel-request", "view"));
                break;
            default:
                dispatch(formActions.setCancellingTransaction(false));
                break;
        }
    };

    getClosePathName = () => {
        const { location, idActivity, dispatch } = this.props;
        if (location.pathname.match("/transaction/")) {
            return "/transactions/list";
        }

        if (location.pathname.match("/userTransaction/")) {
            return "/userTransactions/list";
        }
        if (location.pathname.match("/recipients/create")) {
            return "/recipients/search";
        }
        if (location.pathname.match("/formCustom/investerProfileForm")) {
            return "/investerProfile";
        }

        if (location.pathname.match("/echeqs/formCustom/") && idActivity.match(/echeqs.*send/)) {
            dispatch(filterActions.setResetFilters(false));
            dispatch(filterActions.setReloadData(true));

            return "/echeqs";
        }

        if (location.pathname.match("/formCustom/")) {
            return location.pathname;
        }

        return "/desktop";
    };

    handleClose = () => {
        this.props.resetForm();
        const { dispatch, location } = this.props;
        if (this.getClosePathName() === location.pathname) {
            dispatch(replace(location.pathname));
        } else {
            dispatch(replace({ pathname: this.getClosePathName(), state: { transition: "transition-flow-close" } }));
        }
    };

    handleBack = (isTicket) => {
        const {
            dispatch,
            mode,
            returnBack,
            noPre,
            transaction: { idTransactionStatus },
            location,
            match,
        } = this.props;

        if (
            mode === "preview" &&
            !noPre &&
            idTransactionStatus !== "DRAFT" &&
            !location.pathname.match("/transaction/")
        ) {
            if (location.pathname.match("/formCustom/setCBUAlias")) {
                dispatch(accountActions.setNewCbuAlias(""));
                dispatch(formActions.setPreviewFormErrors(""));
            }
            dispatch(formActions.goBackCleanTransaction());
        } else if (
            mode === "preview" &&
            !noPre &&
            idTransactionStatus !== "DRAFT" &&
            location.pathname.match("/transaction/") &&
            match.params?.idTransaction
        ) {
            dispatch(formActions.closeConfirmation());
        } else if (!noPre && mode === "preview" && idTransactionStatus === "DRAFT") {
            dispatch(formActions.closeConfirmation());
        } else if (mode === "massive-list-edit") {
            dispatch(formActions.closeConfirmation());
        } else if (mode === "massive-list-preview") {
            if (idTransactionStatus && idTransactionStatus !== "DRAFT") {
                dispatch(formActions.setMode("preview", "view"));
            } else {
                dispatch(formActions.setMode("preview", "edit"));
            }
        } else if (mode === "massive-list-view" || mode === "cancel-request") {
            dispatch(formActions.setMode("view", ""));
        } else if (returnBack || isTicket) {
            dispatch(filterActions.setResetFilters(false));
            dispatch(goBack());
        } else {
            dispatch(goBack());
        }
    };

    handleTemplateSelect = (template) => {
        const { setValues, setErrors, dispatch, metadata } = this.props;
        const templateToBeLoaded = template;
        const { onTemplateLoad } = metadata;

        if (templateToBeLoaded?.scheduler?.valueDate) {
            const valueDate = moment(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        setValues(templateToBeLoaded);
        setErrors({});
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
        if (onTemplateLoad) {
            onTemplateLoad(templateToBeLoaded);
        }
    };

    renderFieldsSection = () => {
        const { renderFields, setFieldValue, values } = this.props;

        return <>{renderFields(setFieldValue, values)}</>;
    };

    renderTemplatesSection = () => {
        const {
            metadata: { templatesEnabled },
            mode,
            templates,
        } = this.props;
        const isTemplatesVisibile = mode === "edit" && templatesEnabled && templates.length > 0;
        return (
            <aside className="templates-container">
                {isTemplatesVisibile && (
                    <>
                        <span className="templates-container__title">{i18n.get("forms.template")}</span>
                        <TemplateList onSelect={this.handleTemplateSelect} className="navigational-list" />
                    </>
                )}
            </aside>
        );
    };

    getRenderContentEdit = (renderFormContent, values, id, idActivity, buttonLabel, handleSubmit, modalButtonLabel) => (
        <>
            <form className={`form-content ${id}`} onSubmit={handleSubmit}>
                {renderFormContent()}
            </form>
            {this.renderTemplatesSection()}
            <CreateTemplateModal
                values={values}
                idForm={id}
                idActivityTemplate={idActivity}
                modalButtonLabel={modalButtonLabel}
            />
        </>
    );

    renderFormContent = () => {
        const {
            mode,
            transaction,
            childrenTransactions,
            parentTransaction,
            fromBackoffice,
            ticketConfirmation,
            isSubmitting,
            isDesktop,
            metadata: { draftsEnabled, templatesEnabled, idForm, showSubmit, multipleTransactionsForm },
            renderAdditionalActions,
            renderFootNote,
            buttonLabel,
            handleClickDownload,
            userTransactionDetail,
            withoutTransaction,
            title,
            subtitle,
            subtitleClean,
            history,
            match,
            submitDisabled,
            isModifiable,
        } = this.props;

        const isEditable =
            typeof childrenTransactions === "undefined" ||
            childrenTransactions === null ||
            childrenTransactions.length === 0;

        const actionButtons = !fromBackoffice && (
            <FormActions
                onClick={this.handleClick}
                fetching={isSubmitting}
                mode={mode}
                draftsEnabled={draftsEnabled}
                templatesEnabled={templatesEnabled}
                transaction={transaction}
                userTransactionDetail={userTransactionDetail}
                withoutTransaction={withoutTransaction}
                isEditable={isEditable && isModifiable}
                idForm={idForm}
                isDesktop={isDesktop}
                additionalActions={renderAdditionalActions}
                showSubmit={showSubmit}
                renderFootNote={renderFootNote}
                buttonLabel={buttonLabel}
                handleDownload={handleClickDownload}
                submitDisabled={submitDisabled}
            />
        );

        if (mode === "edit") {
            return (
                <>
                    {this.renderFieldsSection()}
                    {actionButtons}
                </>
            );
        }

        if (multipleTransactionsForm) {
            // puede haber una transaccion si se termina el formulario desde un borrador, por eso se pone antes que el TransactionTicket
            return (
                <MultipleTransactionsTicket title={title} history={history} match={match}>
                    {this.renderFieldsSection()}
                </MultipleTransactionsTicket>
            );
        }
        if (transaction.data !== undefined || userTransactionDetail) {
            return (
                <TransactionTicket
                    childrenTransactions={childrenTransactions}
                    formActions={actionButtons}
                    fromBackoffice={fromBackoffice}
                    parentTransaction={parentTransaction}
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    userTransactionDetail={userTransactionDetail}
                    withoutTransaction={withoutTransaction}
                    title={title}
                    subtitle={subtitle}
                    subtitleClean={subtitleClean}>
                    {this.renderFieldsSection()}
                </TransactionTicket>
            );
        }

        return null;
    };

    renderContent = () => {
        const {
            mode,
            values,
            id,
            metadata,
            currentLang,
            handleSubmit,
            setFieldValue,
            renderFields,
            titleConfirmation,
            renderFieldsTermsAndConditions,
            customLabelMessage,
            showConfirmationMessageAboveField,
            renderEditableInPreview,
            handleCancel,
            confirmLabelEmpty,
            buttonLabel,
            modalButtonLabel,
            additionalFieldsToValidate,
            formConfirmationClass,
            isMobile,
            transaction,
        } = this.props;
        const { idActivity } = metadata;

        const isOverdraftFlow = false;
        switch (mode) {
            case "edit":
                return this.getRenderContentEdit(
                    this.renderFormContent,
                    values,
                    id,
                    idActivity,
                    buttonLabel,
                    handleSubmit,
                    modalButtonLabel,
                );

            case "view":
                return this.renderFormContent();
            case "edit-step2":
                if (isOverdraftFlow) {
                    return (
                        <OpeningAccountStep2
                            overdraft={values.overdraft}
                            accountType={values.accountType}
                            setFieldValue={setFieldValue}
                        />
                    );
                }
                return <></>;
            case "preview":
                return (
                    <FormConfirmation
                        idForm={id}
                        metadata={metadata}
                        currentLang={currentLang}
                        renderFields={renderFields}
                        titleConfirmation={titleConfirmation}
                        renderFieldsTermsAndConditions={renderFieldsTermsAndConditions}
                        customLabelMessage={customLabelMessage}
                        showMessageAboveField={showConfirmationMessageAboveField}
                        renderEditableFields={renderEditableInPreview}
                        values={values}
                        handleCancel={handleCancel}
                        confirmLabelEmpty={confirmLabelEmpty}
                        additionalFieldsToValidate={additionalFieldsToValidate}
                        formConfirmationClass={formConfirmationClass}
                        isMobile={isMobile}
                    />
                );
            case "massive-list-edit":
            case "massive-list-preview":
            case "massive-list-view":
                return (
                    <TransferMassiveStep2
                        customMode={mode}
                        isMobile={isMobile}
                        transaction={transaction}
                        setFieldValue={setFieldValue}
                    />
                );
            case "cancel-request":
                return <CancelRequest transaction={transaction} />;

            default:
                return <></>;
        }
    };

    renderHeader = () => {
        const {
            mode,
            location,
            isMobile,
            title,
            isFetchingExport,
            handleClickDownload,
            exportList,
            transaction,
            returnBack,
            hideHeaderBack,
            userTransactionDetail,
            withoutTransaction,
            showDownloadButtonInForm,
            mobileMenuOptions,
            comeFormDetail,
            showDownloadButton,
            handleReportClick,
            isFetchingDownloadTicket,
            hideTitle,
            showHeader,
        } = this.props;
        const { idTransaction } = withoutTransaction && userTransactionDetail ? userTransactionDetail : transaction;
        const isTicket =
            location.pathname.match("/transaction/") != null || location.pathname.match("/userTransaction/") != null;

        if (!showHeader && hideTitle) {
            showTitle = false;
            return null;
        }

        if (mode.includes("massive-list-")) {
            showTitle = true;
            if (isMobile) {
                return (
                    <Head
                        showLogo
                        additionalClassName="blue-main-header-mobile"
                        onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                        arrowWhite
                        mobileMenuOptions={showDownloadButton ? mobileMenuOptions : null}
                    />
                );
            }

            return (
                <Head
                    onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                    addLinkToLabel="global.download"
                    addLinkTo={showDownloadButton ? "/" : ""}
                    customButtonOnClick={handleReportClick}
                    customDownloadButton
                />
            );
        }

        if (mode === "cancel-request") {
            showTitle = true;
            if (isMobile) {
                return (
                    <Head
                        showLogo
                        additionalClassName="blue-main-header-mobile"
                        onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                        arrowWhite
                    />
                );
            }
            return (
                <Head
                    onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                    onClose={idTransaction ? this.handleClose : null}
                />
            );
        }

        if (mode !== "view" && mode !== "edit") {
            showTitle = true;
            if (isMobile) {
                return (
                    <Head
                        showLogo
                        additionalClassName="blue-main-header-mobile"
                        onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                        arrowWhite
                        exportList={showDownloadButtonInForm ? exportList : null}
                        isFetchingExport={showDownloadButtonInForm ? isFetchingExport : false}
                        handleClick={showDownloadButtonInForm ? () => handleClickDownload() : null}
                        mobileMenuOptions={mobileMenuOptions || null}
                    />
                );
            }
            return (
                <Head
                    hideMenu
                    accessibilityTextId="administration.forms.confirm.credentials"
                    onBack={hideHeaderBack ? null : () => this.handleBack(isTicket)}
                    exportList={showDownloadButtonInForm ? exportList : null}
                    isFetchingExport={showDownloadButtonInForm ? isFetchingExport : false}
                    handleClick={showDownloadButtonInForm ? () => handleClickDownload() : null}
                />
            );
        }

        if (isTicket) {
            if (isMobile) {
                if (mode === "view") {
                    return (
                        <Head
                            accessibilityTextId="administration.forms.transaction.details"
                            showLogo
                            arrowWhite
                            onBack={comeFormDetail ? false : () => this.handleBack(isTicket)}
                            additionalClassName="blue-main-header-mobile"
                            exportList={exportList}
                            isFetchingExport={isFetchingDownloadTicket}
                            handleClick={() => handleClickDownload()}
                        />
                    );
                }
                return (
                    <Head
                        accessibilityTextId="administration.forms.transaction.details"
                        showLogo
                        additionalClassName="blue-main-header-mobile"
                        exportList={exportList}
                        isFetchingExport={isFetchingDownloadTicket}
                        handleClick={() => handleClickDownload()}
                    />
                );
            }

            showTitle = transaction?.idTransactionStatus === "DRAFT";
            return (
                <Head
                    hideMenu
                    accessibilityTextId="administration.forms.transaction.details"
                    onBack={comeFormDetail ? false : () => this.handleBack(isTicket)}
                    exportList={exportList}
                    isFetchingExport={isFetchingDownloadTicket}
                    handleClick={() => handleClickDownload()}
                />
            );
        }

        if (idTransaction) {
            showTitle = mode !== "view";

            if (isMobile) {
                return (
                    <Head
                        accessibilityTextId={title}
                        showLogo
                        additionalClassName="blue-main-header-mobile"
                        exportList={exportList}
                        isFetchingExport={isFetchingExport}
                        handleClick={() => handleClickDownload()}
                    />
                );
            }
            return (
                <Head
                    hideMenu
                    accessibilityTextId={title}
                    onClose={idTransaction ? this.handleClose : null}
                    exportList={exportList}
                    isFetchingExport={isFetchingExport}
                    handleClick={() => handleClickDownload()}
                />
            );
        }

        showTitle = true;
        if (isMobile) {
            return (
                <Head
                    onBack={returnBack ? () => this.handleBack(isTicket) : null}
                    arrowWhite
                    accessibilityTextId={title}
                    showLogo
                    additionalClassName="blue-main-header-mobile"
                    exportList={showDownloadButtonInForm ? exportList : null}
                    isFetchingExport={showDownloadButtonInForm ? isFetchingExport : false}
                    handleClick={showDownloadButtonInForm ? () => handleClickDownload() : null}
                />
            );
        }
        return (
            <Head
                hideMenu
                accessibilityTextId={title}
                onBack={returnBack ? () => this.handleBack(isTicket) : null}
                exportList={showDownloadButtonInForm ? exportList : null}
                isFetchingExport={showDownloadButtonInForm ? isFetchingExport : false}
                handleClick={showDownloadButtonInForm ? () => handleClickDownload() : null}
            />
        );
    };

    render() {
        const {
            fetching,
            isMobile,
            showHeader,
            fullWidthLayout,
            title,
            subtitle,
            subtitleClean,
            fromBackoffice,
            modalCustom,
            showModalCustom,
            setFieldValue,
            isSubmitting,
            mode,
            hideTitle,
        } = this.props;
        const renderHeader = !fetching && showHeader && !fromBackoffice;
        let layout = fullWidthLayout ? "container-center" : "container-left";
        if (mode.includes("massive-list-")) {
            layout = "container-left";
        }

        return (
            <>
                {renderHeader && this.renderHeader()}
                {modalCustom && showModalCustom && modalCustom(setFieldValue, isSubmitting)}

                <div className="view-page">
                    <div className="view-content form-render-custom">
                        <div className="main-container">
                            <div className={isMobile ? "container-fluid" : layout}>
                                <PageLoading loading={fetching}>
                                    {showTitle && !hideTitle && (
                                        <div className="title-left-header">
                                            <h1>{i18n.get(title)}</h1>
                                            {subtitle && <h2>{subtitleClean ? subtitle : i18n.get(subtitle)}</h2>}
                                        </div>
                                    )}
                                    {!fetching && this.renderContent()}
                                </PageLoading>
                            </div>
                        </div>
                        {isMobile && !fetching && <FooterDesktop />}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    comeFormDetail: formSelectors.getComeFromDetail(state),
    isFetchingDownloadTicket: formSelectors.isFetchingDownloadTicket(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ data }) => ({
            ...data,
            scheduler: data?.scheduler
                ? {
                      ...data.scheduler,
                      valueDate: data.scheduler.valueDate ? moment(data.scheduler.valueDate) : null,
                  }
                : null,
        }),
        handleSubmit: (values, formikBag) => {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(values).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0]?.text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            Mixpanel.track_form(formikBag.props.id, filteredValues);

            if (formikBag.props.useDefaultSubmit) {
                formikBag.props.dispatch(
                    formActions.previewForm({
                        formikBag,
                        idActivity: formikBag.props.metadata.idActivity,
                        idTransaction:
                            formikBag.props.transaction?.idTransaction ||
                            formikBag.props.userTransactionDetail?.idTransaction ||
                            null,
                        idTransactionStatus: formikBag.props.transaction?.idTransactionStatus || null,
                        values: filteredValues,
                    }),
                );
            } else {
                formikBag.props.dispatch(formikBag.props.alternateSubmitFunc({ formikBag, values: filteredValues }));
            }
        },
    }),
    mapProps(({ metadata, ...props }) => ({
        ...props,
        metadata,
    })),
)(FormRenderCustom);
