import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, node, func, bool } from "prop-types";
import * as i18nUtils from "util/i18n";
import { actions, selectors } from "reducers/widgets";
import PendingTransactionsListItem from "pages/desktop/widgets/_components/PendingTransactionsListItem";
import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";
import WidgetLoading from "pages/_components/WidgetLoading";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import WidgetHeader from "../_components/WidgetHeader";
import Overlay from "../_components/Overlay";

const WIDGET_NAME = "pendingTransactions";
class PendingTransactions extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetching: bool,
        isEditable: bool,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
    };

    static defaultProps = {
        closeButton: null,
        isFetching: false,
        isEditable: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("pendingTransactions"));
    }

    renderList = (list) => {
        const { dispatch, isFetching, isEditable } = this.props;
        let pendingTransactions = [];
        if (list.length) {
            const onlyDrafts = list.every(({ transaction }) => transaction.idTransactionStatus === "DRAFT");
            pendingTransactions = list.map(({ transaction, transactionAmounts }) => {
                const [currency, quantity] = Object.entries(transactionAmounts);
                return {
                    ...transaction,
                    currency,
                    quantity,
                    onlyDrafts,
                };
            });
        }
        return (
            <WidgetLoading loading={!list.length && isFetching}>
                {list.length ? (
                    <div className="table" aria-hidden={isEditable}>
                        <div className="table-body">
                            {pendingTransactions.map((item) => (
                                <PendingTransactionsListItem key={item.idTransaction} dispatch={dispatch} {...item} />
                            ))}
                        </div>
                    </div>
                ) : (
                    !isFetching && (
                        <div className="table" aria-hidden={isEditable}>
                            <NoProduct
                                text={`desktop.widgets.${WIDGET_NAME}.empty`}
                                imagePath="images/coloredIcons/component.svg"
                            />
                        </div>
                    )
                )}
            </WidgetLoading>
        );
    };

    render() {
        const { closeButton, draggableItemProps, isEditable } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetPendingTransactions" };
        return (
            <WidgetList name={WIDGET_NAME} shouldMapList={false}>
                {(list) => (
                    <div
                        role={isEditable ? "button" : ""}
                        className="newWidget"
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <div>
                            <WidgetHeader
                                title={i18nUtils.get("desktop.widgets.pendingTransactions.title")}
                                action={closeButton}
                            />
                            {this.renderList(list)}
                            <Overlay />
                        </div>
                    </div>
                )}
            </WidgetList>
        );
    }
}
const mapStateToProps = (state) => ({
    isFetching: selectors.getWidget(state, WIDGET_NAME).isFetching,
});
export default connect(mapStateToProps)(PendingTransactions);
