import React, { Component } from "react";
import classNames from "classnames";
import { string, func, bool } from "prop-types";

import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";

class SwitchField extends Component {
    static propTypes = {
        name: string,
        onChange: func,
        onBlur: func,
        error: string,
        label: string,
        hideLabel: bool,
        idForm: string,
        readOnly: bool,
        value: bool,
        formGroup: string,
        biggerLabel: bool,
        additionalClassName: string,
    };

    static defaultProps = {
        name: "",
        error: "",
        label: "",
        hideLabel: false,
        idForm: "",
        readOnly: false,
        value: false,
        onChange: () => {},
        onBlur: null,
        formGroup: "",
        biggerLabel: false,
        additionalClassName: "",
    };

    handleChange = ({ target, ...event }) => {
        const { onChange } = this.props;

        onChange({
            ...event,
            target: {
                ...target,
                value: target.value === "true",
            },
        });
    };

    render() {
        const {
            idForm,
            error,
            hideLabel,
            label,
            value,
            formGroup,
            biggerLabel,
            additionalClassName,
            ...props
        } = this.props;

        return (
            <div
                className={classNames("c-control c-control-block c-control--switch", {
                    "form-group": formGroup,
                    "has-error": error,
                    additionalClassName,
                })}>
                <input
                    id={props.name}
                    className="c-control-input"
                    type="checkbox"
                    {...props}
                    onChange={this.handleChange}
                    value={!value}
                    checked={value}
                    readOnly
                />
                <label className="c-control-label" htmlFor={props.name}>
                    {!hideLabel && !biggerLabel && (
                        <div className="form-group-text">{label || <I18n id={`${idForm}.${props.name}.label`} />}</div>
                    )}
                    {!hideLabel && biggerLabel && (
                        <h4 className="table-legend">{label || <I18n id={`${idForm}.${props.name}.label`} />}</h4>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </div>
        );
    }
}

export default SwitchField;
