import * as API from "middleware/api";

export const recoveryPassStep1 = (documentType, documentNumber, secondFactor, recaptchaResponse) =>
    API.executeAnonymous("/session.recoverPassword.step1", {
        documentType,
        documentNumber,
        _secondFactor: secondFactor,
        _captcha: recaptchaResponse,
    });

export const recoveryPassStep2 = (code) =>
    API.executeAnonymous("/session.recoverPassword.step2", {
        _resetCode: code,
    });

export const recoveryPassStep3 = (newPassword, newPasswordConfirmation, resetCode, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step3",
        {
            _password: newPassword,
            _passwordConfirmation: newPasswordConfirmation,
            _resetCode: resetCode,
        },
        exchangeToken,
    );
