import React, { Component, Fragment } from "react";
import { bool, func, node, oneOf, shape, string } from "prop-types";
import classNames from "classnames";
import { Button as BSButton } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class Button extends Component {
    static propTypes = {
        block: bool,
        bsStyle: oneOf([
            "success",
            "warning",
            "danger",
            "info",
            "default",
            "primary",
            "link",
            "secondary",
            "link,",
            "outline",
            "tertiary",
            "filter",
        ]),
        children: node,
        className: string,
        defaultLabelText: string,
        disabled: bool,
        href: string,
        id: string,
        image: string,
        label: string,
        loading: bool,
        onClick: func,
        replace: shape({}),
        style: shape({}),
        type: string,
    };

    static defaultProps = {
        block: false,
        bsStyle: undefined,
        className: "",
        children: null,
        defaultLabelText: "",
        disabled: false,
        href: "",
        id: "",
        image: "",
        label: "",
        loading: false,
        onClick: () => {},
        replace: null,
        style: null,
        type: "button",
    };

    constructor(props) {
        super(props);
        this.alreadyClicked = false;
    }

    onClick = (e) => {
        const { loading, onClick } = this.props;

        if (!loading && !this.alreadyClicked) {
            this.alreadyClicked = true;

            if (typeof onClick === "function") {
                onClick(e);
            }
            /* workaround para casos en que no se cambia el loading, que siempre está en false */
            setTimeout(this.resetLink, 0);
        }
    };

    resetLink = () => {
        this.alreadyClicked = false;
    };

    renderChildren = ({ children, image, label, defaultLabelText, ...replace }) => (
        <>
            {children ? (
                <Fragment>{children}</Fragment>
            ) : (
                <Fragment>
                    {image && <Image src={image} className="svg-icon" />}
                    <I18n id={label} {...replace} defaultValue={defaultLabelText} />
                </Fragment>
            )}
        </>
    );

    render() {
        const {
            bsStyle,
            block,
            className,
            id,
            image,
            label,
            loading = false,
            disabled,
            type,
            defaultLabelText,
            style,
            href,
            onClick,
            replace,
            children,
            ...props
        } = this.props;

        const target = href ? { href } : { onClick };

        let styleToClassname = "";
        let newBsStyle;
        const notSupportedBsStyle = ["outline", "secondary", "circle", "tertiary", "filter"];

        if (notSupportedBsStyle.includes(bsStyle)) {
            styleToClassname = `btn-${bsStyle}`;
            newBsStyle = "default";
        }

        return (
            <BSButton
                {...target}
                id={id}
                type={type}
                disabled={loading || disabled}
                block={block}
                className={classNames(`${styleToClassname} ${className}`, { "is-loading": loading })}
                bsStyle={newBsStyle || bsStyle}
                style={style}
                {...props}>
                {loading ? <Spinner /> : this.renderChildren({ children, image, label, defaultLabelText, ...replace })}
            </BSButton>
        );
    }
}

function Spinner() {
    return (
        <Fragment>
            <span className="btn-loading-indicator">
                <span />
                <span />
                <span />
            </span>
            <span className="btn-loading-text">Loading</span>
        </Fragment>
    );
}

export default Button;
