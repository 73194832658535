import React from "react";
import { func, node, string, oneOfType } from "prop-types";

class TableRow extends React.Component {
    static propTypes = {
        children: node.isRequired,
        renderAs: oneOfType([func, string]),
        additionalClassName: string,
    };

    static defaultProps = {
        renderAs: "div",
        additionalClassName: "",
    };

    render() {
        const { children, renderAs: Component, additionalClassName, ...props } = this.props;
        return (
            <Component className={`table-row ${additionalClassName}`} {...props}>
                {children}
            </Component>
        );
    }
}

export default TableRow;
