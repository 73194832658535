import React from "react";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { arrayOf, bool, element, func, number, string, shape, oneOfType } from "prop-types";
import NotificationSystem from "react-notification-system";
import Image from "pages/_components/Image";

/*
Por customizacion ver, ver: https://github.com/igorprado/react-notification-system
*/
class Notification extends React.Component {
    static propTypes = {
        scopeToShow: string.isRequired, // what scope this notification component must listen
        message: string,
        scopes: oneOfType([arrayOf(element), arrayOf(string)]).isRequired,
        dispatch: func.isRequired,
        level: string,
        errors: shape({}),
        metadata: shape({
            idForm: string,
            version: number,
            enabled: bool,
            category: string,
            type: string,
            adminOption: string,
            idActivity: string,
            fieldList: arrayOf(
                shape({
                    idField: string,
                    labelMap: shape({}),
                    requiredErrorMap: shape({}),
                }),
            ),
        }),
        currentLang: string,
    };

    static defaultProps = {
        errors: {},
        message: null,
        level: null,
        metadata: null,
        currentLang: null,
    };

    state = {
        notificationSystem: null,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-string-refs
        const { notificationSystem } = this.refs;

        this.setState({ notificationSystem });
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow } = this.props;

        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    image = (level) => {
        switch (level) {
            case "success":
                return "success";
            case "warning": {
                return "alert";
            }
            case "error":
                return "error";
            default:
                return "important";
        }
    };

    addNotification = () => {
        const { notificationSystem } = this.state;
        const { dispatch, message, level, errors, metadata, currentLang } = this.props;

        let allFormFieldsInfo;
        let errorFieldsKeys;
        let errorFieldsInfo;

        if (metadata) {
            allFormFieldsInfo = metadata.fieldList;
            errorFieldsKeys = Object.keys(errors);
            errorFieldsInfo = allFormFieldsInfo.filter((field) => errorFieldsKeys.includes(field.idField));
        }

        notificationSystem.clearNotifications();
        notificationSystem.addNotification({
            message,
            level,
            position: "tc",
            autoDismiss: "8",
            dismissible: true,
            children: (
                <>
                    <Image src={`images/${this.image(level)}.svg`} className="svg-icon" />
                    <div className="visually-hidden">
                        {errorFieldsInfo &&
                            errorFieldsInfo.map((field) => (
                                <div>{`${field.labelMap[currentLang]} : ${field.requiredErrorMap[currentLang]}`}</div>
                            ))}
                    </div>
                </>
            ),
        });

        dispatch(notificationActions.removeNotification());
    };

    render() {
        // ignoring warning because the only way to remove all the styles from the component is to pass style as false
        return (
            <div aria-live="polite">
                <NotificationSystem
                    // eslint-disable-next-line react/no-string-refs
                    ref="notificationSystem"
                    // eslint-disable-next-line react/style-prop-object
                    style={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    message: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
});

export default connect(mapStateToProps)(Notification);
