import React, { Component } from "react";
import { bool, func, string, array, number, shape, arrayOf } from "prop-types";

import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import NoDataTextContainer from "components/NoDataContainer/NoDataTextContainer";
import filterStatus from "util/getFiltersStatus";
import PageLoading from "../PageLoading";
import Alert from "../Alert/Alert";
import I18n from "../I18n";

class ProductList extends Component {
    static propTypes = {
        onLoadMoreClick: func.isRequired,
        renderItem: func.isRequired,
        children: func.isRequired,
        noDataText: string.isRequired,
        // we don't care about the shape of item because we don't handle the renderItem in this component
        // eslint-disable-next-line react/forbid-prop-types
        items: array,
        loadMoreText: string,
        noMoreDataText: string,
        noFiltersDataText: string,
        fetching: bool,
        lastPage: bool,
        pageNumber: number,
        noDataImage: string,
        firstFetched: bool,
        filters: shape({}),
        politeMessage: shape({}),
        match: shape({
            params: shape({ idForm: string }),
        }),
        lines: arrayOf(shape({})),
        isMobile: bool.isRequired,
        alertText: string,
        defaultFilters: shape({}),
        listMessagesParams: shape({}),
    };

    static defaultProps = {
        loadMoreText: "",
        noMoreDataText: "",
        noFiltersDataText: "accounts.movements.filters.none",
        fetching: false,
        lastPage: true,
        pageNumber: 0,
        items: [],
        noDataImage: "images/coloredIcons/folder-empty.svg",
        firstFetched: true,
        filters: {},
        politeMessage: React.createRef(),
        match: undefined,
        lines: undefined,
        alertText: "",
        defaultFilters: null,
        listMessagesParams: null,
    };

    handleClick = () => {
        const { onLoadMoreClick, pageNumber } = this.props;
        onLoadMoreClick(pageNumber);
    };

    renderPoliteMessage = (message, params) => {
        const { politeMessage } = this.props;
        if (politeMessage && politeMessage.current) {
            politeMessage.current.innerText = i18n.get(message, "", params);
        }
    };

    // special case for in memory front pagination. Manuallt add lines
    manageSalaryPaymentCase = () => {
        const { fetching, loadMoreText, noMoreDataText, items, lines } = this.props;
        if ((loadMoreText || fetching) && items.length < lines.length) {
            return (
                <div className="text-center no-more-data flex">
                    <Button
                        bsStyle="link"
                        onClick={this.handleClick}
                        image="images/show.svg"
                        loading={fetching}
                        label={loadMoreText}
                        className="btn-small"
                    />
                </div>
            );
        }
        return <NoDataTextContainer noMoreDataText={noMoreDataText} />;
    };

    renderLoadMore = () => {
        const {
            fetching,
            loadMoreText,
            lastPage,
            pageNumber,
            noMoreDataText,
            items,
            match,
            filters,
            isMobile,
            listMessagesParams,
        } = this.props;
        const isEmpty = items.length === 0;
        const pageNumberToUse = filters.pageNumber || pageNumber;
        if (lastPage) {
            if (!isEmpty && noMoreDataText && pageNumberToUse !== 0) {
                this.renderPoliteMessage(noMoreDataText, listMessagesParams?.noMoreDataText);
            }
            return (
                !isEmpty &&
                noMoreDataText &&
                pageNumberToUse !== 0 && (
                    <NoDataTextContainer
                        noMoreDataText={noMoreDataText}
                        i18nParams={listMessagesParams?.noMoreDataText}
                    />
                )
            );
        }

        if (match && match.params && match.params.idForm === SALARY_PAYMENT_ID_FORM) {
            return this.manageSalaryPaymentCase();
        }

        // TODO doing a workaround here to show a loader when fetching more items on mobile
        // it will be better that I pass a loading label from props and then show it when fetching
        return (
            (loadMoreText || fetching) && (
                <Button
                    block={isMobile}
                    className="btn-show-more"
                    bsStyle="secondary"
                    onClick={this.handleClick}
                    image="images/plusWhite.svg"
                    loading={fetching}
                    label={loadMoreText}
                />
            )
        );
    };

    render() {
        const {
            firstFetched,
            fetching,
            filters,
            children,
            items,
            renderItem,
            noDataText,
            noDataImage,
            noFiltersDataText,
            politeMessage,
            alertText,
            defaultFilters,
            listMessagesParams,
        } = this.props;

        const withFilters = filterStatus({ filters, defaultFilters });

        const isEmpty = items.length === 0;

        if (withFilters && isEmpty) {
            this.renderPoliteMessage(noFiltersDataText, listMessagesParams?.noFiltersDataText);
        } else if (!firstFetched && isEmpty) {
            this.renderPoliteMessage(noDataText, listMessagesParams?.noDataText);
        }

        return (
            <PageLoading loading={firstFetched}>
                {!isEmpty && alertText && (
                    <Alert className="ui-mt-8 ui-mb-7" icon="images/ui-icons/ui-exclamation-orange.svg">
                        <I18n id={alertText} />
                    </Alert>
                )}

                {isEmpty ? (
                    <NoDataContainer
                        fetching={fetching}
                        filters={filters}
                        noDataImage={noDataImage}
                        noDataText={noDataText}
                        noFiltersDataText={noFiltersDataText}
                        withFilters={withFilters}
                        i18nParams={{
                            noFiltersDataText: listMessagesParams?.noFiltersDataText,
                            noDataText: listMessagesParams?.noDataText,
                        }}
                    />
                ) : (
                    <>{children(items.map(renderItem), this.renderLoadMore)}</>
                )}
                <div ref={politeMessage} className="visually-hidden" aria-live="polite" />
            </PageLoading>
        );
    }
}

export default ProductList;
