import React from "react";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import { bool } from "prop-types";

function RetentionTableHeader({ comeFromProcessed }) {
    if (comeFromProcessed) {
        return (
            <>
                <Table.Header>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.voucherNumber.processed.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.retetionDate.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.businessName.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.tax.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.regime.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.ticketOrigin.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.retetion.header.amount.label" />
                    </Table.HeaderData>
                </Table.Header>
            </>
        );
    }

    return (
        <>
            <Table.Header>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.voucherNumber.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.retetionDate.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.businessName.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.tax.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.regime.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.ticketOrigin.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.retetion.header.amount.label" />
                </Table.HeaderData>
            </Table.Header>
        </>
    );
}

RetentionTableHeader.propTypes = {
    comeFromProcessed: bool,
};

RetentionTableHeader.defaultProps = {
    comeFromProcessed: false,
};

export default RetentionTableHeader;
