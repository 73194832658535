import geocodeLatLngGoogle from "middleware/geolocation";
import { actions as geolocationActions, types as geolocationTypes } from "reducers/geolocation";
import { call, put, takeLatest } from "redux-saga/effects";
import currentLocation from "util/geolocation";

const geoLocationSagas = [takeLatest(geolocationTypes.GEOLOCATION_REQUEST, geolocationRequest)];

export default geoLocationSagas;

function* geolocationRequest({ google }) {
    const position = yield call(currentLocation);

    const geoData = yield call(geocodeLatLngGoogle, google, position);

    yield put(geolocationActions.geolocationSuccess(position, geoData));
}
