import React, { useEffect, useState } from "react";
import { actions as eCheqsActions, selectors as echeqsSelectors } from "reducers/echeqs";
import { actions as formActions, selectors as formSelector } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string, objectOf, any } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Loader from "pages/_components/Loader";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import Button from "pages/_components/Button";
import { format } from "date-fns";
import FormTransition from "../../../_components/FormTransition";
import * as i18nUtils from "../../../../../util/i18n";
import * as config from "../../../../../util/config";
import EmitIndividualEcheq from "./EmitIndividualEcheq";
import EmitPersonalizedEcheqs from "./EmitPersonalizedEcheqs";
import EmitMultipleEcheqs from "./EmitMultipleEcheqs";
import PersonalizedEcheqModal from "./PersonalizedEcheqModal";

const ID_ACTIVITY = "echeqs.emitEcheq.send";
const ID_ACTIVITY_PRE = "echeqs.emitEcheq.getAuthorizedAccounts";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");
const EmitEcheqForm = (props) => {
    const {
        mode,
        dispatch,
        currentLang,
        fromBackoffice,
        transaction,
        data,
        isMobile,
        location,
        history,
        preData,
        bankedCustomerInfo,
        previewData,
        fetching,
        validatedPersonalizedEcheqs,
        showModalCustom,
        isSubmitting,
        errors,
    } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        authorizedAccountsToEmit,
        ...restPreData
    } = preData || {};

    const [echeqTypeSelected, setEcheqTypeSelected] = useState([]);
    const [emissionTypeSelected, setEmissionTypeSelected] = useState(["single"]);
    const [amountSelected, setAmountSelected] = useState();
    const [totalAmountSelected, setTotalAmountSelected] = useState({ currency: "ARS", quantity: null });
    const [amountTypeSelected, setAmountTypeSelected] = useState("");
    const [otherFieldsSelected, setOtherFieldsSelected] = useState({});
    const [echeqsQuantitySelected, setEcheqsQuantitySelected] = useState(null);
    const [currentEcheqInfo, setCurrentEcheqInfo] = useState({
        echeqType: "",
        amount: { quantity: "", currency: "ARS" },
        paymentDate: null,
        character: null,
        reference: null,
    });

    useEffect(() => {
        if (!authorizedAccountsToEmit && mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
        }

        if (
            transaction?.data &&
            Object.keys(transaction?.data)?.length > 0 &&
            (!previewData || previewData?.echeqs?.[0] === 0)
        ) {
            if (data?.emissionType) {
                setEmissionTypeSelected(data.emissionType);
            }
            if (data?.echeqsQuantity) {
                setEcheqsQuantitySelected(data.echeqsQuantity[0]);
            }
            if (data?.amountType) {
                setAmountTypeSelected(data.amountType[0]);
            }
            if (data?.amount) {
                setAmountSelected(data.amount.quantity);
            }
            if (data?.echeqType) {
                setEcheqTypeSelected(data.echeqType[0]);
            }
            if (data?.amountType) {
                setAmountTypeSelected(data?.amountType[0]);
            }

            const otherFieldsFromData = {};

            if (data?.paymentDate) {
                const dateToday = new Date();
                otherFieldsFromData.paymentDate =
                    moment(data.paymentDate).toDate() >= dateToday
                        ? data.paymentDate
                        : format(dateToday, "YYYY-MM-DDTHH:mm:ss.SSSXZ");
            }
            if (data?.character) {
                otherFieldsFromData.character = data?.character;
            }
            if (data?.originAccount) {
                otherFieldsFromData.originAccount = data?.originAccount;
            }
            if (data?.concept) {
                otherFieldsFromData.concept = data?.concept?.[0];
            }
            if (data?.reason) {
                otherFieldsFromData.reason = data?.reason;
            }
            setOtherFieldsSelected({ ...otherFieldsSelected, ...otherFieldsFromData });
        }

        if (mode === "preview" && emissionTypeSelected?.[0] === "personalized" && previewData?.totalAmount) {
            setTotalAmountSelected(previewData.totalAmount);
        }
    }, [location, mode, transaction]);

    const idTransaction = transaction?.idTransaction;

    const authorizedAccountsOptions =
        mode !== "edit"
            ? data?.authorizedAccountsOptions
            : {
                  options: authorizedAccountsToEmit?.map((acc) => ({
                      ...acc,
                      balance: { currency: acc.currency, quantity: acc.balance },
                      id: acc.idProduct,
                  })),
              };

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const amountData = {
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: "ARS",
                label: i18nUtils.get("currency.label.ARS"),
            },
        ],
    };
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const echeqsQuantityOptions = numbers.map((num) => ({
        id: num,
        label: num,
    }));

    // mismos conceptos que en transferencias
    const concepts = config.get("transfers.concepts").split("|");
    const conceptsList = concepts.map((value) => ({
        label: i18nUtils.get(`forms.transfers.concepts.${value}.label`),
        id: value,
    }));

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => history.goBack();

    const validatedEcheqs =
        mode === "edit" && emissionTypeSelected[0] === "personalized" ? validatedPersonalizedEcheqs : null;
    const echeqsOnData = previewData?.echeqs || data?.echeqs;
    const echeqsForDataObj = echeqsOnData || validatedEcheqs || [];
    const disableComponents = transaction?.idTransactionStatus === "DRAFT" || echeqsOnData?.length > 0 || false;

    const renderFields = (setFieldValue) => {
        function searchSuccessAction(bankedCustomerInfoData) {
            setFieldValue("originAccount", otherFieldsSelected?.originAccount);
            setFieldValue("emissionType", emissionTypeSelected);
            setFieldValue("echeqType", [echeqTypeSelected]);
            setFieldValue("paymentDate", otherFieldsSelected?.paymentDate);
            setFieldValue("character", [otherFieldsSelected?.character]);
            setFieldValue("amount", { currency: "ARS", quantity: amountSelected });
            setFieldValue("concept", [otherFieldsSelected?.concept]);
            setFieldValue("reason", otherFieldsSelected?.reason);
            setFieldValue("reference", otherFieldsSelected?.reference);
            setFieldValue("bankedCustomerInfo", bankedCustomerInfoData);
            setFieldValue("amountType", [amountTypeSelected]);
            setFieldValue("echeqsQuantity", [echeqsQuantitySelected]);
            setFieldValue("totalAmount", totalAmountSelected);
            setFieldValue("mode", ["crossed"]);
        }

        const handleChangeAccount = (id) => {
            const { currency } = authorizedAccountsToEmit?.find((account) => account.idProduct === id) || data;
            setFieldValue("amount", {
                currency,
                quantity: data.amount?.quantity && amountSelected === "" ? data.amount?.quantity : amountSelected,
            });
            setFieldValue("originAccount", id);
            setOtherFieldsSelected({ ...otherFieldsSelected, originAccount: id });
        };

        const handleEcheqType = (echeqType) => {
            setEcheqTypeSelected(echeqType);
            let paymentDate;
            if (echeqType === "today") {
                paymentDate = moment(new Date());
            } else {
                paymentDate = null;
            }
            setFieldValue("paymentDate", paymentDate);
            setOtherFieldsSelected({ ...otherFieldsSelected, paymentDate });
        };

        const handleOtherFields = (fieldName, value) => {
            setOtherFieldsSelected({ ...otherFieldsSelected, [fieldName]: value });
            setFieldValue(fieldName, value);
        };

        const handleAmount = (value, fieldName) => {
            let totalAmount;
            if (fieldName === "echeqsQuantity") {
                setEcheqsQuantitySelected(value);
                totalAmount = value && amountTypeSelected === "individual" ? amountSelected * value : amountSelected;
            } else if (fieldName === "amountType") {
                totalAmount =
                    value === "individual" && echeqsQuantitySelected
                        ? amountSelected * echeqsQuantitySelected
                        : amountSelected;
                setAmountTypeSelected(value);
            } else {
                setAmountSelected(value);
                switch (emissionTypeSelected[0]) {
                    case "single":
                        totalAmount = value;
                        break;
                    case "multiple":
                        totalAmount =
                            echeqsQuantitySelected && amountTypeSelected === "individual"
                                ? value * echeqsQuantitySelected
                                : value;
                        break;
                    case "personalized":
                        break;
                    default:
                        break;
                }
            }
            const totalAmountValue = { currency: "ARS", quantity: totalAmount };
            setTotalAmountSelected(totalAmountValue);
            setFieldValue("totalAmount", totalAmountValue);
        };

        const handleEmissionType = (value) => {
            setEmissionTypeSelected(value);
            setFieldValue("emissionType", value);
            if (value[0] === "personalized") {
                dispatch(eCheqsActions.resetPersonalizedEcheq());
            } else {
                const totalAmount = { currency: "ARS", quantity: 0 };
                if (
                    value[0] === "multiple" &&
                    amountSelected > 0 &&
                    echeqsQuantitySelected &&
                    amountTypeSelected === "individual"
                ) {
                    totalAmount.quantity = amountSelected * echeqsQuantitySelected;
                } else {
                    totalAmount.quantity = amountSelected;
                }
                setTotalAmountSelected(totalAmount);
                setFieldValue("totalAmount", totalAmount);
            }
        };

        const selectAndRenderEcheqForm = () => {
            const echeqFormProps = {
                mode,
                authorizedAccountsOptions,
                data,
                previewData,
                ID_FORM,
                searchSuccessAction,
                bankedCustomerInfo,
                handleEmissionType,
                emissionTypeSelected,
                amountData,
                handleAmount,
                handleEcheqType,
                handleOtherFields,
                otherFieldsSelected,
                genericProps,
                handleChangeAccount,
                isMobile,
                amountSelected,
                echeqTypeSelected,
                echeqsQuantityOptions,
                conceptsList,
                amountTypeSelected,
                totalAmountSelected,
                idForm: ID_FORM,
                dispatch,
                setFieldValue,
                isPersonalizedTicket: false,
                disableComponents,
            };

            const personalizedProps = {
                amountData,
                isMobile,
                ID_FORM,
                genericProps,
            };

            let echeqForm;

            if (
                (authorizedAccountsToEmit?.length > 0 && mode !== "view") ||
                (mode !== "edit" && transaction && Object.keys(transaction).length > 0) ||
                (mode === "view" && data?.emissionType?.[0] === "personalized" && data?.echeqs?.length > 0)
            ) {
                switch (emissionTypeSelected[0]) {
                    case "single":
                        echeqForm = <EmitIndividualEcheq {...echeqFormProps} />;
                        break;
                    case "multiple":
                        echeqForm = <EmitMultipleEcheqs {...echeqFormProps} />;
                        break;
                    case "personalized":
                        if (
                            Object.keys(transaction).length > 0 &&
                            ((mode === "view" && transaction?.idTransactionStatus !== "DRAFT") ||
                                (mode === "preview" && transaction?.idTransactionStatus !== "DRAFT"))
                        ) {
                            const finalEcheqFormProps = { ...echeqFormProps, isPersonalizedTicket: true };
                            echeqForm = <EmitIndividualEcheq {...finalEcheqFormProps} />;
                        } else {
                            echeqForm = (
                                <EmitPersonalizedEcheqs {...personalizedProps} echeqFormProps={echeqFormProps} />
                            );
                        }
                        break;
                    default:
                        echeqForm = <></>;
                        break;
                }
            } else {
                echeqForm = (
                    <NoDataContainer
                        noDataImage="images/coloredIcons/alert.svg"
                        noDataText={`${ID_FORM}.noAccounts`}
                        fetching={fetching}>
                        <Button
                            bsStyle="primary"
                            block
                            onClick={() => history.push("/echeqs/management/account/list")}
                            label={`${ID_FORM}.manageAccounts`}
                            className="ui-mt-8"
                        />
                    </NoDataContainer>
                );
            }
            return echeqForm;
        };

        return <>{fetching ? <Loader /> : selectAndRenderEcheqForm()}</>;
    };

    const modalCustom = (setFieldValue, isSubmittingModal) => {
        const modalProps = {
            showModal: showModalCustom,
            amountData,
            isMobile,
            dispatch,
            setCurrentEcheqInfo,
            currentEcheqInfo,
            data,
            ID_FORM,
            emissionTypeSelected,
            otherFieldsSelected,
            genericProps,
            idForm: ID_FORM,
            setFieldValue,
            setEcheqTypeSelected,
            setOtherFieldsSelected,
        };
        return <PersonalizedEcheqModal {...modalProps} isSubmitting={isSubmittingModal} />;
    };

    const formProps = {
        title: `${ID_FORM}.${mode}${mode === "view" ? `.${data.emissionType[0]}` : ""}`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
            showSubmit: authorizedAccountsToEmit?.length > 0,
            multipleTransactionsForm:
                data?.emissionType?.length > 0 && data?.echeqs?.length > 1
                    ? data?.emissionType?.[0] === "personalized"
                    : false,
        },
        data: {
            originAccount: previewData?.originAccount || data?.originAccount,
            echeqs: echeqsForDataObj,
            bankedCustomerInfo:
                mode === "edit" && Object.keys(bankedCustomerInfo).length > 0
                    ? bankedCustomerInfo
                    : previewData?.bankedCustomerInfo || data?.bankedCustomerInfo,
            authorizedAccountsOptions,
            checkbookNumber: previewData?.echeqs?.[0]?.numero_chequera || data?.echeqs?.[0]?.numero_chequera || null,
            echeqNumbers: previewData?.echeqs?.[0]?.cheque_numero || data?.echeqs?.[0]?.cheque_numero || null,
            totalAmount: previewData?.totalAmount || data?.totalAmount,
            amount: previewData?.amount || data?.amount,
            amountType: previewData?.amountType || data?.amountType,
            paymentDate: mode !== "edit" ? previewData?.paymentDate || data?.paymentDate : null,
            validatePersonalizedEcheq: false,
            emissionType: mode === "edit" ? emissionTypeSelected : previewData?.emissionType || data?.emissionType,
            reason: previewData?.reason || data?.reason,
            concept: previewData?.concept || data?.concept,
            mode: previewData?.mode || data?.mode,
            activitySuffix: emissionTypeSelected,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "forms.echeqs.emitEcheq.create",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
        modalCustom,
        showModalCustom,
        isSubmitting,
        errorsAux: errors,
    };

    return <FormTransition {...props} {...formProps} />;
};

EmitEcheqForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

EmitEcheqForm.propTypes = {
    dispatch: func.isRequired,
    history: objectOf(any).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    mode: string.isRequired,
    location: shape({}).isRequired,
    bankedCustomerInfo: shape({}).isRequired,
    isMobile: bool,
    fetching: bool.isRequired,
    useDefaultSubmit: bool,
    previewData: shape({}).isRequired,
    validatedPersonalizedEcheqs: shape([]).isRequired,
    showModalCustom: bool.isRequired,
    isSubmitting: bool.isRequired,
    errors: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    previewData: formSelector.getPreviewData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    fetching: echeqsSelectors.getFetching(state) || formSelector.getFetching(state),
    isSubmitting: echeqsSelectors.getFetching(state),
    bankedCustomerInfo: echeqsSelectors.getBankedCustomerInfo(state),
    validatedPersonalizedEcheqs: echeqsSelectors.getPersonalizedEcheqsList(state),
    showModalCustom: echeqsSelectors.getShowPersonalizedEcheqModal(state),
    errors: echeqsSelectors.getErrors(state),
});

export default compose(connect(mapStateToProps), withRouter)(EmitEcheqForm);
