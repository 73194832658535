import React, { Fragment, Component } from "react";
import { compose } from "redux";
import classNames from "classnames";
import { routerActions } from "react-router-redux/actions";
import { connect } from "react-redux";
import { string, bool, func, arrayOf, number, shape } from "prop-types";

import * as fileMiddleware from "middleware/file";
import { actions as fileActions } from "reducers/files";
import { actions as formActions } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions, selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { actions as notificationActions } from "reducers/notification";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import FileUploader from "pages/_components/FileUploader";
import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import FileActions from "pages/forms/_components/_fields/_commons/FileActions";
import FieldError from "pages/_components/fields/FieldError";
import Button from "pages/_components/Button";
import withFocus from "pages/_components/withFocus";

class FilePayment extends Component {
    static propTypes = {
        idField: string.isRequired,
        idForm: string.isRequired,
        field: shape({}),
        form: shape({}),
        formTitle: string,
        dispatch: func.isRequired,
        setValue: func.isRequired,
        processedFileData: shape({
            fileIdentifier: string.isRequired,
            hasFile: bool.isRequired,
            validLines: number,
            invalidLines: number,
            totalAmount: shape({
                currency: string.isRequired,
                quantity: number.isRequired,
            }).isRequired,
        }).isRequired,
        fieldList: arrayOf(shape({})),
        idRelatedFile: string,
        idFile: string,
        value: arrayOf(),
        label: string.isRequired,
        maxFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf().isRequired,
        isFocused: bool,
        toggleIsFocused: func,
    };

    static defaultProps = {
        field: null,
        form: null,
        fieldList: [],
        idRelatedFile: null,
        idFile: null,
        formTitle: "",
        value: null,
        isFocused: false,
        toggleIsFocused: null,
    };

    componentDidMount() {
        const { dispatch, setValue, processedFileData } = this.props;

        if (processedFileData.hasFile) {
            setValue([processedFileData.filesMetadata]);
        }
        dispatch(transactionLinesActions.onPaymentMethodChange());
    }

    handleFileProcess = (data) => {
        const { dispatch } = this.props;

        dispatch(actions.onFileProcess(data));
    };

    handleError = (data) => {
        const { dispatch } = this.props;
        const { errorMessage } = data;

        dispatch(notificationActions.showNotification(`${errorMessage}.`, "error", ["form"]));
    };

    renderLinesInfo = (downloadFile) => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines) {
            return validLines;
        }

        return (
            <Fragment>
                <div>
                    {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                </div>
                <div>
                    <I18n id="forms.inputFile.massivePayments.invalid.lines" /> {invalidLines} / {validLines}{" "}
                    <I18n id="forms.inputFile.massivePayments.valid.lines" />
                </div>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.errors" />
                </button>
            </Fragment>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;

        if (!invalidLines) {
            return <FormattedAmount className="data-desc" {...totalAmount} />;
        }

        return (
            <Fragment>
                <FormattedAmount className="data-desc" {...totalAmount} />*
                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </Fragment>
        );
    };

    handleClick = () => {
        const { dispatch, form, fieldList, idFile, idForm, idRelatedFile, idField } = this.props;
        const { idField: selectorId, optionList } = fieldList.find(
            (field) => field.type === "selector" && field.defaultValue === "file",
        );

        if (idRelatedFile !== undefined) {
            fileMiddleware.deleteFile(Number(idRelatedFile));
        }
        dispatch(transactionLinesActions.setIsEditingPayment(false));
        dispatch(fileActions.getFileContentsRequest(idFile, true));
        dispatch(routerActions.push(`/form/${idForm}/manual`));
        dispatch(
            formActions.setData({
                ...form.values,
                [selectorId]: [optionList[1].id],
                [idField]: [],
            }),
        );
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;

        field.onBlur(event);
        toggleIsFocused();
    };

    renderDetail = ({ downloadFile }) => {
        const { dispatch, processedFileData } = this.props;
        const { invalidLines, invalidHeader, fileIdentifier, invalidFile, filesMetadata, message } = processedFileData;

        if (invalidLines === null) {
            return null;
        }

        if (invalidHeader) {
            return (
                <div className="alert alert-warning" role="alert">
                    <I18n id="forms.inputFile.massivePayments.invalid.header" invalidLines={invalidLines} />
                </div>
            );
        }

        if (filesMetadata) {
            dispatch(notificationActions.showNotification(`${message}.`, "success", ["form"]));
        }

        return (
            <DetailBox>
                {invalidLines > 0 && (
                    <div className="alert alert-warning mTop" role="alert">
                        {invalidFile ? (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.file"
                                percentage={configUtils.getInteger(
                                    "forms.fields.multilinefile.minimum.invalid.percentage.allowed",
                                )}
                            />
                        ) : (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.lines.warning"
                                invalidLines={invalidLines}
                            />
                        )}
                    </div>
                )}
                <DetailBox.Data label="forms.inputFile.massivePayments.id">{fileIdentifier}</DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                    {this.renderLinesInfo(downloadFile)}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    {this.renderAmount()}
                </DetailBox.Data>
                <div className="detailBox-row">
                    <Button
                        image="images/plus.svg"
                        bsStyle="primary"
                        className="btn-small"
                        onClick={this.handleClick}
                        label="forms.inputFile.massivePayments.editPayments"
                    />
                </div>
            </DetailBox>
        );
    };

    renderError = () => {
        const { form, idField, processedFileData } = this.props;

        if (!form.touched[idField]) {
            return null;
        }
        if (!processedFileData.hasFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.required")} />;
        }
        if (processedFileData.invalidFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.field")} />;
        }
        return null;
    };

    isValid = () => {
        const { processedFileData } = this.props;
        const { invalidHeader, invalidFile, hasFile } = processedFileData;
        return !invalidHeader && !invalidFile && hasFile;
    };

    render() {
        const {
            idForm,
            idField,
            value,
            setValue,
            formTitle,
            label,
            maxFileSizeMB,
            acceptedFileTypes,
            dispatch,
            isFocused,
            toggleIsFocused,
        } = this.props;

        return (
            <FileActions
                {...this.props}
                renderPreview={this.renderDetail}
                render={({ onRemoveFile, onAddFile, downloadFile }) => (
                    <div
                        onFocus={toggleIsFocused}
                        onBlur={this.handleBlur}
                        className={classNames("form-group", { "has-error": !this.isValid(), "has-focus": isFocused })}>
                        <FileUploader
                            name={idField}
                            idActivity="core.forms.send"
                            idForm={idForm}
                            idFormField={idField}
                            description={`${formTitle} - ${label}`}
                            files={value}
                            allowMultiple={false}
                            maxFileSize={`${maxFileSizeMB}mb`}
                            maxTotalFileSize={`${maxFileSizeMB}mb`}
                            maxFiles={1}
                            allowImagePreview
                            acceptedFileTypes={acceptedFileTypes}
                            onFileProcess={this.handleFileProcess}
                            onError={this.handleError}
                            onAddFile={onAddFile}
                            onRemoveFile={(file) => {
                                onRemoveFile(file);
                                dispatch(actions.onFileRemoved());
                            }}
                            setValue={setValue}
                        />
                        {this.renderDetail({ downloadFile })}
                        {this.renderError()}
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
});

export default compose(connect(mapStateToProps), withFocus)(FilePayment);
