import React from "react";
import { string, bool, shape, number, func } from "prop-types";
import ProgressBar from "react-bootstrap/lib/ProgressBar";

import { calculateScoreStrengthPassword } from "util/settings";

import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class Password extends React.Component {
    inputRef = React.createRef();

    static propTypes = {
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        autoComplete: string,
        maxLength: number,
        autoFocus: bool,
        showStrength: bool,
        copyEnabled: bool,
        inputRef: shape().isRequired,
        additionalClassName: string,
        onClick: func,
    };

    static defaultProps = {
        autoComplete: "on",
        maxLength: 20,
        autoFocus: false,
        showStrength: false,
        copyEnabled: "true",
        additionalClassName: "",
        onClick: null,
    };

    state = {
        isVisible: false,
    };

    handleOnChange = (event) => {
        this.setState({
            scoreStrengthPass: calculateScoreStrengthPassword(event.target.value),
        });
        this.props.form.setErrors({});
    };

    toggleIsVisible = () => {
        this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
    };

    revealFunction = () => {
        const { isVisible } = this.state;
        return (
            <>
                <Button className="btn-only-icon btn-password" block={false} onClick={this.toggleIsVisible}>
                    <I18n
                        id={
                            isVisible
                                ? "login.step1.secondFactor.maskPassword.a11y"
                                : "login.step1.secondFactor.unmaskPassword.a11y"
                        }
                        componentProps={{ className: "visually-hidden" }}
                    />
                    <div className={isVisible ? "hide" : ""}>
                        <Image src="images/password-hide.svg" />
                    </div>
                    <div className={!isVisible ? "hide" : ""}>
                        <Image src="images/password-show.svg" />
                    </div>
                </Button>
            </>
        );
    };

    render() {
        const { isVisible, scoreStrengthPass } = this.state;
        const { showStrength, copyEnabled, additionalClassName } = this.props;
        return (
            <>
                <TextField
                    {...this.props}
                    inputRef={this.inputRef}
                    type={isVisible ? "text" : "password"}
                    inputFunctions={this.revealFunction()}
                    handleOnChange={this.handleOnChange}
                    autoCapitalize="none"
                    copyEnabled={copyEnabled}
                    additionalClassName={additionalClassName}
                />
                {showStrength && <ProgressBar now={scoreStrengthPass} />}
            </>
        );
    }
}

export default Password;
