import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "userTransactions/LIST_REQUEST",
    LIST_FAILURE: "userTransactions/LIST_FAILURE",
    LIST_SUCCESS: "userTransactions/LIST_SUCCESS",
    SET_FILTERS: "userTransactions/SET_FILTERS",
    LOAD_MORE_REQUEST: "userTransactions/LOAD_MORE_REQUEST",
    LOAD_MORE_SUCCESS: "userTransactions/LOAD_MORE_SUCCESS",

    USER_TRANSACTION_DETAIL_REQUEST: "userTransactions/USER_TRANSACTION_DETAIL_REQUEST",
    USER_TRANSACTION_DETAIL_FAILURE: "userTransactions/USER_TRANSACTION_DETAIL_FAILURE",
    USER_TRANSACTION_DETAIL_SUCCESS: "userTransactions/USER_TRANSACTION_DETAIL_SUCCESS",
};

export const INITIAL_STATE = {
    userTransactions: null,
    isFetching: false,
    filters: {
        activity: "",
        dateFrom: "",
        dateTo: "",
    },
    hasMoreData: false,
    pageNumber: 1,
    isFirstFetching: true,
    showFilters: false,
    userTransactionDetail: null,
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
        isFirstFetching: action.isFirstFetching === true,
    }),
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        userTransactions: action.userTransactions,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        pageNumber: action.pageNumber,
        showFilters: state.isFirstFetching ? action.totalPages > 1 : state.showFilters,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: action.filters,
    }),
    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        userTransactions: state.userTransactions
            ? state.userTransactions.concat(action.userTransactions)
            : action.userTransactions,
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
    }),
    [types.USER_TRANSACTION_DETAIL_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.USER_TRANSACTION_DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.USER_TRANSACTION_DETAIL_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "filters", "page", "isFirstFetching", "formikBag"),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "userTransactions", "pageNumber", "totalPages"),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    loadMoreRequest: makeActionCreator(types.LOAD_MORE_REQUEST, "filters"),
    loadMoreSuccess: makeActionCreator(types.LOAD_MORE_SUCCESS, "userTransactions", "pageNumber", "totalPages"),
};

export const selectors = {
    getUserTransactions: ({ userTransactions }) => userTransactions.userTransactions,
    getFirstFetch: ({ userTransactions }) => userTransactions.isFirstFetching,
    getShowFilters: ({ userTransactions }) => userTransactions.showFilters,
    getFetching: ({ userTransactions }) => userTransactions.isFetching,
    getHasMoreData: ({ userTransactions }) => userTransactions.hasMoreData,
    getPageNumber: ({ userTransactions }) => userTransactions.pageNumber,
    getFilters: ({ userTransactions }) => userTransactions.filters,
};
