import { call, put, takeLatest, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as enrollment from "middleware/enrollment";

import { types as enrollmentTypes, selectors as enrollmentSelectors } from "reducers/enrollment";
import { types as loginTypes } from "reducers/login";
import { selectors as sessionSelectors, types as sessionTypes } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";

import { adjustIdFieldErrors } from "util/form";
import { get } from "util/i18n";
import { getSafeRandomNumber } from "util/number";

const sagas = [
    takeLatest(enrollmentTypes.GO_TO_STEP_0, goToStep0),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_1_PRE_REQUEST, associateStep1Pre),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_REQUEST, associateStep1Verify),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_REQUEST, associateStep2Verify),
    takeLatest(enrollmentTypes.ASSOCIATE_STEP_3_REQUEST, associateStep3),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_PRE_REQUEST, requestInvitationCodePre),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_REQUEST, requestInvitationCode),
    takeLatest(enrollmentTypes.REQUEST_PERSONAL_DATA_REQUEST, requestPersonalData),
    takeLatest(enrollmentTypes.REQUEST_SECURITY_SEALS_REQUEST, requestSecuritySeals),
    takeLatest(enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_REQUEST, requestVerificationCodePre),
    takeLatest(enrollmentTypes.RESEND_VERIFICATION_CODE_REQUEST, resendVerificationCode),
    takeLatest(enrollmentTypes.ENROLLMENT_STEP_1_VERIFY_REQUEST, enrollmentStep1Verify),
    takeLatest(enrollmentTypes.ENROLLMENT_STEP_2_VERIFY_REQUEST, enrollmentStep2Verify),
    takeLatest(enrollmentTypes.ENROLLMENT_STEP_3_FINISH_REQUEST, enrollmentStep3Finish),
    takeLatest(enrollmentTypes.ACCEPT_ESIGN_REQUEST, esignAccept),
    takeLatest(enrollmentTypes.VERIFY_INVITATION_CODE_REQUEST, verifyInvitationCode),
    takeLatest(enrollmentTypes.VERIFY_VERIFICATION_CODE_REQUEST, verifyVerificationCode),
    takeLatest(enrollmentTypes.SEND_DOCUMENTS_BY_MAIL, sendDocumentsByMail),
    takeLatest(enrollmentTypes.SEND_IRS_REQUEST, sendIRS),
    takeLatest(enrollmentTypes.SEND_PEP_REQUEST, sendPEP),
    takeLatest(enrollmentTypes.REACTIVATE_TOKEN, reactivateToken),
];

export default sagas;

const APIErrorCodes = {
    API508W: "invalid",
    API509W: "expired",
    API510W: "alreadyUsed",
    API511W: "cancelled",
    API512W: "invalid",
    API513E: "usernameExists",
};

const APIFieldErrorCodes = {
    API016W: {
        errors: ["password"],
        mustShowCaptcha: true,
    },
    API019W: {
        errors: ["password"],
        mustShowCaptcha: false,
    },
    API020W: {
        errors: ["password"],
        mustShowCaptcha: true,
    },
    API021W: {
        errors: ["username", "secondFactor"],
        mustShowCaptcha: true,
    },
};

function* associateStep1Pre({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.associateStep1Pre, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_PRE_ERROR,
            });
        } else {
            const { _exchangeToken, ...rest } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_PRE_SUCCESS,
                exchangeToken: _exchangeToken,
                ...rest,
            });
        }
    }
}

function* associateStep1Verify({ user, document, formikBag }) {
    const { exchangeToken } = formikBag.props;

    const response = yield call(enrollment.associateStep1Verify, user, document, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            if (APIFieldErrorCodes[response.data.code]) {
                const errorsToShow = {};

                APIFieldErrorCodes[response.data.code].errors.map((error) => {
                    errorsToShow[error] = response.data.message;

                    return errorsToShow;
                });

                formikBag.setErrors(errorsToShow);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_ERROR,
                    captchaRequired: APIFieldErrorCodes[response.data.code].mustShowCaptcha,
                });
            } else {
                const error =
                    null ||
                    (APIErrorCodes[response.data.code] &&
                        `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_ERROR,
                    error,
                });

                if (APIErrorCodes[response.data.code]) {
                    yield put(push("/enrollment/error"));
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, _securitySeal } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_1_VERIFY_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeal: _securitySeal,
            });

            yield put(push("/enrollment/associate/step2"));
        }
    }
}

function* associateStep2Verify({ captcha, password, formikBag }) {
    const { invitationCode, exchangeToken } = formikBag.props;

    const response = yield call(enrollment.associateStep2Verify, captcha, invitationCode, password, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            if (APIFieldErrorCodes[response.data.code]) {
                const errorsToShow = {};

                APIFieldErrorCodes[response.data.code].errors.map((error) => {
                    errorsToShow[error] = response.data.message;

                    return errorsToShow;
                });

                formikBag.setErrors(errorsToShow);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_ERROR,
                    captchaRequired: APIFieldErrorCodes[response.data.code].mustShowCaptcha,
                });
            } else {
                const error =
                    null ||
                    (APIErrorCodes[response.data.code] &&
                        `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`);

                yield put({
                    type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_ERROR,
                    error,
                });

                if (APIErrorCodes[response.data.code]) {
                    yield put(push("/enrollment/error"));
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_2_VERIFY_SUCCESS,
                exchangeToken: _exchangeToken,
            });

            yield put(push("/enrollment/associate/step3"));
        }
    }
}

function* associateStep3({ invitationCode, exchangeToken }) {
    const invitation = yield select(enrollmentSelectors.getInvitation);

    const response = yield call(enrollment.associateStep3, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            const { NO_FIELD } = response.data.data;

            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_3_ERROR,
                error: NO_FIELD,
            });

            yield put(push("/enrollment/error"));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.ASSOCIATE_STEP_3_SUCCESS,
            });

            const fromOnboardingLoginData = {
                messageKey: "enrollment.associate.success",
                account: invitation.customParameters,
            };
            yield put({
                type: loginTypes.SET_FROM_ONBOARDING_DATA,
                fromOnboardingLoginData,
            });
            yield put(push("/enrollment/success"));
        }
    }
}

function* goToStep0() {
    yield put(replace("/enrollment"));
}

function* requestInvitationCode({ captcha, documentInfo, formikBag }) {
    const { country, document, type } = documentInfo;
    const { exchangeToken } = formikBag.props;
    const response = yield call(enrollment.requestInvitationCode, captcha, country, document, type, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    const { channelSent } = response.data.data;
    yield put({
        type: enrollmentTypes.REQUEST_INVITATION_CODE_SUCCESS,
        channelSent,
    });

    yield put(push("/enrollment/requestInvitationCode/success"));
}

function* requestInvitationCodePre() {
    const response = yield call(enrollment.requestInvitationCodePre);

    if (response && response.status === 200) {
        const { countryList, documentTypeList, _exchangeToken } = response.data.data;

        yield put({
            type: enrollmentTypes.REQUEST_INVITATION_CODE_PRE_SUCCESS,
            countryList,
            documentTypeList,
            exchangeToken: _exchangeToken,
        });
    } else if (response.status === 401) {
        yield put(push("/"));
        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
    }
}

function* requestPersonalData({ invitationCode, verificationCode, exchangeToken }) {
    const response = yield call(enrollment.requestPersonalData, invitationCode, verificationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_ERROR,
            });

            yield put(
                yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step2"])),
            );
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_SUCCESS,
            });
        }
    }
}

function* requestSecuritySeals({ exchangeToken }) {
    const response = yield call(enrollment.requestSecuritySeals, exchangeToken);
    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, _securitySeals } = response.data.data;
            const safeRandom = parseFloat(`0.${getSafeRandomNumber()}`);
            const shuffled = Object.entries(_securitySeals).sort(() => 0.5 - safeRandom);
            let securitySealsResult = {};
            shuffled.slice(0, 8).forEach(([id, securitySeal]) => {
                securitySealsResult = {
                    ...securitySealsResult,
                    [id]: securitySeal,
                };
            });
            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeals: securitySealsResult,
            });
        }
    }
}

function* requestVerificationCodePre({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.requestVerificationCodePre, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* resendVerificationCode({ invitationCode, exchangeToken }) {
    const response = yield call(enrollment.resendVerificationCode, invitationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_ERROR,
            });

            yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step1"]));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_SUCCESS,
            });

            yield put(
                notificationActions.showNotification(
                    get("enrollment.step1.verificationCode.request.success"),
                    "success",
                    ["enrollment/step1"],
                ),
            );
        }
    }
}

function* enrollmentStep1Verify({ user, userConfirmation, password, passwordConfirmation, formikBag }) {
    const { exchangeToken, invitationCode } = formikBag.props;
    try {
        const response = yield call(
            enrollment.enrollmentStep1Verify,
            user,
            userConfirmation,
            invitationCode,
            password,
            passwordConfirmation,
            exchangeToken,
        );

        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                formikBag.setErrors({ ...errors });

                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_1_VERIFY_ERROR,
                });

                yield put(
                    notificationActions.showNotification(get("global.unexpectedError.register"), "error", ["step1"]),
                );
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else {
                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_1_VERIFY_SUCCESS,
                });
                yield put(push("/enrollment/step2"));
            }
        }
    } catch (ex) {
        if (!APIErrorCodes || !APIErrorCodes[ex.data.code]) {
            throw ex;
        }
        const error = `enrollment.index.invitationCode.${APIErrorCodes[ex.data.code]}`;
        yield put({
            type: enrollmentTypes.USERNAME_ALREADY_EXISTS_ERROR,
            error,
        });
        yield put(push("/enrollment/error"));
    }
}

function* enrollmentStep2Verify({ securitySealId, formikBag }) {
    const { exchangeToken } = formikBag.props;
    try {
        const response = yield call(enrollment.enrollmentStep2Verify, securitySealId, exchangeToken);

        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                formikBag.setErrors({ ...errors });

                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_2_VERIFY_ERROR,
                });

                yield put(
                    notificationActions.showNotification(get("global.unexpectedError.register"), "error", ["step2"]),
                );
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else {
                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_2_VERIFY_SUCCESS,
                });
                yield put(push("/enrollment/step3"));
            }
        }
    } catch (ex) {
        if (!APIErrorCodes || !APIErrorCodes[ex.data.code]) {
            throw ex;
        }
        yield put(push("/enrollment/error"));
    }
}

function* enrollmentStep3Finish({ otp, formikBag }) {
    const { exchangeToken, invitationCode } = formikBag.props;
    try {
        const response = yield call(enrollment.enrollmentStep3Finish, otp, invitationCode, exchangeToken);

        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                formikBag.setErrors({ ...errors });

                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_3_FINISH_ERROR,
                });

                yield put(
                    notificationActions.showNotification(get("global.unexpectedError.register"), "error", ["step3"]),
                );
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else {
                const { idEnvironment } = response.data.data;
                yield put({
                    type: enrollmentTypes.ENROLLMENT_STEP_3_FINISH_SUCCESS,
                    idEnvironment,
                });

                const fromOnboardingLoginData = {
                    messageKey: "enrollment.success.info",
                };
                yield put({
                    type: loginTypes.SET_FROM_ONBOARDING_DATA,
                    fromOnboardingLoginData,
                });

                yield put(push("/enrollment/success"));
            }
        }
    } catch (ex) {
        if (!APIErrorCodes || !APIErrorCodes[ex.data.code]) {
            throw ex;
        }
        yield put(push("/enrollment/error"));
    }
}

function* sendIRS({ IRS, validateSSNID, setErrors }) {
    try {
        const response = yield call(enrollment.sendIRS, IRS, validateSSNID);
        if (response) {
            if (response.type === "W") {
                if (setErrors !== null) {
                    setErrors(adjustIdFieldErrors(response.data.data));
                }
                yield put(notificationActions.showNotification(response.data.message, "error", ["pendingActions"]));
                yield put({
                    type: enrollmentTypes.SEND_IRS_ERROR,
                    error: response.data.data,
                });
            } else {
                yield put({
                    type: enrollmentTypes.SEND_IRS_SUCCESS,
                });

                yield put({ type: sessionTypes.UPDATE_PENDINGACTIONS_IRS });
                const user = yield select(sessionSelectors.getUser);
                if (user.pepCompleted && user.irsCompleted) {
                    yield put(push("/desktop"));
                } else {
                    yield put(push("/pendingActions"));
                }
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["pendingActions"]));
        } else {
            throw error;
        }
    }
}

function* sendPEP() {
    try {
        const response = yield call(enrollment.sendPEP);
        if (response) {
            if (response.type === "W") {
                yield put(notificationActions.showNotification(response.data.message, "error", ["pendingActions"]));
                yield put({
                    type: enrollmentTypes.SEND_PEP_ERROR,
                });
            } else {
                yield put({
                    type: enrollmentTypes.SEND_PEP_SUCCESS,
                });

                yield put({ type: sessionTypes.UPDATE_PENDINGACTIONS_PEP });
                const user = yield select(sessionSelectors.getUser);
                if (user.pepCompleted && user.irsCompleted) {
                    yield put(push("/desktop"));
                } else {
                    yield put(push("/pendingActions"));
                }
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["pendingActions"]));
        } else {
            throw error;
        }
    }
}

function* esignAccept({ idEnvironment, userEmail, exchangeToken, firstName, lastName, acceptESign }) {
    if (!acceptESign) {
        yield put({
            type: enrollmentTypes.ACCEPT_ESIGN_SUCCESS,
        });
        yield put({
            type: loginTypes.SET_FROM_ONBOARDING_DATA,
            fromOnboardingLoginData: {
                firstName,
                lastName,
                acceptESign,
            },
        });

        yield put(push("/enrollment/success"));
        return;
    }

    try {
        const response = yield call(enrollment.esignAccept, userEmail, idEnvironment, exchangeToken);
        if (response) {
            if (response.type === "W") {
                yield put(notificationActions.showNotification(response.data.message, "error", ["enrollment"]));
                yield put({
                    type: enrollmentTypes.ACCEPT_ESIGN_ERROR,
                });
            } else {
                yield put({
                    type: enrollmentTypes.ACCEPT_ESIGN_SUCCESS,
                });
                yield put({
                    type: loginTypes.SET_FROM_ONBOARDING_DATA,
                    fromOnboardingLoginData: {
                        firstName,
                        lastName,
                        acceptESign,
                    },
                });

                yield put(push("/enrollment/success"));
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put({
                type: enrollmentTypes.ACCEPT_ESIGN_ERROR,
                error: error.data.message,
            });
            yield put(push("/enrollment/error"));
        } else {
            throw error;
        }
    }
}

function* sendDocumentsByMail({ userEmail }) {
    yield put(
        notificationActions.showNotification(get("generalConditionDocument.email.front.text.sent"), "success", [
            "enrollmentTermsAndConditions",
        ]),
    );
    yield call(enrollment.sendDocumentsByEmail, userEmail);
}

function* verifyInvitationCode({ invitationCode, formikBag }) {
    const response = yield call(enrollment.verifyInvitationCode, invitationCode);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const error = response.data.code.includes("API")
                ? get(`enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`)
                : adjustIdFieldErrors(response.data.data).code;

            formikBag.setErrors({ invitationCode: error });

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { associate, hasOtp, passcode, _securitySeals, _exchangeToken, invitation } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_SUCCESS,
                hasOtp,
                securitySeals: _securitySeals,
                passcode,
                exchangeToken: _exchangeToken,
                invitationCode,
                invitation,
            });

            yield put(push(`/enrollment/${associate ? "associate/step1" : "step0"}`));
        }
    }
}

function* verifyVerificationCode({ verificationCode, formikBag }) {
    const { exchangeToken, invitationCode, personalDataEnabled } = formikBag.props;
    const response = yield call(
        enrollment.verifyVerificationCode,
        personalDataEnabled,
        invitationCode,
        verificationCode,
        exchangeToken,
    );

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const { verificationCode: verificationCodeParam, NO_FIELD } = adjustIdFieldErrors(response.data.data);

            if (NO_FIELD) {
                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                    error: `enrollment.step1.verificationCode.maxAttemptsReached`,
                });

                yield put(push("/enrollment/requestInvitationCode"));
            } else {
                formikBag.setErrors({ verificationCode: verificationCodeParam });

                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                });
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_VERIFICATION_CODE_SUCCESS,
                exchangeToken: _exchangeToken,
                verificationCode,
            });

            yield put(push("/enrollment/step1"));
        }
    }
}

function* reactivateToken({ exchangeToken, invitationCode }) {
    const response = yield call(enrollment.reactivateToken, exchangeToken, invitationCode);
    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REACTIVATE_TOKEN_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, passcode } = response.data.data;

            yield put({
                type: enrollmentTypes.REACTIVATE_TOKEN_SUCCESS,
                exchangeToken: _exchangeToken,
                passcode,
            });
        }
    }
}
