import React, { Component, Fragment } from "react";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { bool, objectOf, any } from "prop-types";
import { connect } from "react-redux";
import { selectors as footerSelectors } from "reducers/footer";
import HeaderLogin from "./header/HeaderLogin";

class TermsAndConditions extends Component {
    static propTypes = {
        fromOnboarding: bool,
        isMobile: bool.isRequired,
        hasActiveSession: bool,
        history: objectOf(any).isRequired,
        location: objectOf(any).isRequired,
    };

    static defaultProps = {
        fromOnboarding: false,
        hasActiveSession: false,
    };

    renderHeader = () => {
        const { fromOnboarding, isMobile, hasActiveSession } = this.props;
        if (!hasActiveSession) {
            return <HeaderLogin onHeaderClose={!fromOnboarding && this.backButtonAction} showBack />;
        }
        if (isMobile) {
            return (
                <Head
                    showLogo
                    arrowWhite
                    onBack={this.backButtonAction}
                    additionalClassName="blue-main-header-mobile"
                />
            );
        }
        return <Head />;
    };

    backButtonAction = () => {
        const { history, location } = this.props;

        if (location?.state?.prevPath === "/loginStep4") {
            history.goBack();
        } else {
            history.push("/");
        }
    };

    render() {
        const { hasActiveSession, isMobile } = this.props;

        const termsAndConditionsAllTexts = [
            ["title1", "content1"],
            ["title2", "content2"],
            ["title3", "content3"],
            ["title4", "content4"],
            ["title5", "content5"],
            ["title6", "content6"],
            ["title7", "content7"],
            ["title8", "content8"],
            ["title9", "content9"],
            ["title10", "content10"],
            ["title11", "content11"],
            ["title12", "content12"],
            ["title13", "content13"],
        ];

        let disclaimerMaxHeight = "";

        if (!hasActiveSession) {
            if (isMobile) {
                disclaimerMaxHeight = "calc(100vh - 250px)";
            } else {
                disclaimerMaxHeight = "calc(100vh - 280px)";
            }
        }

        return (
            <div className={!hasActiveSession ? "login-content" : ""}>
                {this.renderHeader()}

                <MainContainer>
                    <div className="documents-container">
                        <div className={!hasActiveSession ? "ui-mb-7 ui-text-center" : "title-left-header"}>
                            <I18n id="global.termAndConditions" component="h1" />
                        </div>

                        <DisclaimerContainer maxHeight={disclaimerMaxHeight} justifyText={false}>
                            {termsAndConditionsAllTexts.map((text) => (
                                <Fragment key={text[0]}>
                                    <I18n key={text[0]} id={`termsAndConditions.${text[0]}`} component="h2" />
                                    <I18n key={text[1]} id={`termsAndConditions.${text[1]}`} component="p" />
                                </Fragment>
                            ))}
                        </DisclaimerContainer>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPath: footerSelectors.getCurrentPath(state),
    location: state.router.location,
});

export default connect(mapStateToProps)(TermsAndConditions);
