import React, { useEffect } from "react";
import { selectors as echeqsSelector } from "reducers/echeqs";
import { actions as formActions, selectors as formSelector } from "reducers/form";
import { selectors as i18nSelector } from "reducers/i18n";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field } from "formik";
import { goBack } from "react-router-redux";
import FormTransition from "../../_components/FormTransition";
import DisclaimerContainer from "../../../../components/DisclaimerContainer/DisclaimerContainer";
import I18n from "../../../_components/I18n";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import * as i18nUtils from "../../../../util/i18n";
import typeSelector from "../../../../reducers/types/form";

const ID_ACTIVITY = "echeqs.managementAccounts.activateOrDeactivate.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");
const EnableOrDisableAccountsToIssueECheq = (props) => {
    const { mode, dispatch, currentLang, fromBackoffice, transaction, data, account, location, isMobile } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const idTransaction = transaction?.idTransaction;

    const accountSelected = data?.account || account;
    const action = data?.action || "enable";

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    useEffect(() => {
        if (mode !== "view") {
            if (account && Object.keys(account).length) {
                dispatch({
                    type: typeSelector.PREVIEW_FORM_SUCCESS,
                    credentialsGroups: [
                        {
                            idCredentialGroup: "accessToken",
                            credentials: ["accessToken"],
                        },
                    ],
                    submitAction: formActions.sendForm,
                    submitActionParams: {
                        idForm: null,
                        idActivity: ID_ACTIVITY,
                        idTransaction: null,
                        values: {
                            account: accountSelected,
                        },
                    },
                    previewData: {},
                    mode: "preview",
                    prevMode: null,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [location, dispatch]);

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const renderFields = () => (
        <>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.account.label`} componentProps={{ className: "data-label" }} />
                <span>{accountSelected?.label}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.operation.label`} componentProps={{ className: "data-label" }} />
                <span>{i18nUtils.get(`forms.echeqs.managementAccounts.${action}.operation.label`)}</span>
            </div>
        </>
    );

    const renderEditableInPreview = () => {
        if (action === "enable") {
            return (
                <>
                    <div className="termsAndConditions ui-mt-8">
                        <DisclaimerContainer maxHeight="134px">
                            <I18n
                                id={`${ID_FORM}.termsAndConditions.label`}
                                componentProps={{ className: "ui-fw-sbold" }}
                            />
                            <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                        </DisclaimerContainer>
                    </div>
                    <Field
                        {...genericProps}
                        mode="edit"
                        component={FormFieldsComponents.Selector}
                        optionList={[
                            {
                                id: "1",
                                label: i18nUtils.get(`${ID_FORM}.termsAndConditionsAcceptance.checkbox.label`),
                            },
                        ]}
                        key="termsAndConditionsAcceptance"
                        name="termsAndConditionsAcceptance"
                        idField="termsAndConditionsAcceptance"
                        renderAs="check"
                        additionalClassName={isMobile ? "ui-mt-8" : "ui-mt-7"}
                    />
                </>
            );
        }

        return <></>;
    };

    const formProps = {
        title: `${ID_FORM}.${action}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            issuer: {
                cbu: accountSelected?.cbu,
                cuit: accountSelected?.client?.idClient,
                businessName: accountSelected?.ownerName,
                digit: accountSelected?.digit,
            },
            branch: accountSelected?.branch,
            action,
            account: accountSelected,
            activitySuffix: action,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        renderEditableInPreview,
        handleClickDownload,
        noPre: true,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

EnableOrDisableAccountsToIssueECheq.defaultProps = {
    fromBackoffice: false,
    preData: null,
    isMobile: false,
};

EnableOrDisableAccountsToIssueECheq.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    mode: string.isRequired,
    location: shape({}).isRequired,
    account: shape({}).isRequired,
    isFetching: bool.isRequired,
    isMobile: bool,
};

const mapStateToProps = (state) => ({
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    fetching: formSelector.getFetching(state),
    account: echeqsSelector.getAccountSelected(state),
    currentLang: i18nSelector.getLang(state),
});

export default compose(connect(mapStateToProps), withRouter)(EnableOrDisableAccountsToIssueECheq);
