import { call, put, takeLatest, select } from "redux-saga/effects";

import * as echeqsMiddleware from "middleware/echeqs";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as echeqsActions, selectors as echeqsSelectors, types, actions } from "reducers/echeqs";
import * as i18n from "util/i18n";

import { push } from "react-router-redux";
import { downloadPdf, downloadXls, downloadCsv } from "util/download";
import { base64toBlob } from "util/file";
import { NO_TRANSITION } from "../constants";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_ECHEQS_REQUEST, fetchMoreEcheqs),
    takeLatest(types.ECHEQS_DETAIL_REQUEST, getECheqDetail),
    takeLatest(types.SEARCH_BANKED_CUSTOMER_REQUEST, getBankedCustomerInfo),
    takeLatest(types.LIST_IMF_REQUEST, getListIMF),
    takeLatest(types.AUTHORIZED_ENTITY_LIST_REQUEST, getAuthorizedEntityList),
    takeLatest(types.ECHEQ_ISSUING_ACCOUNTS_REQUEST, getEcheqIssuingAccounts),
    takeLatest(types.ECHEQS_DISABLE_ACCOUNT_REQUEST, disableAccountToIssueECheq),
    takeLatest(types.GET_ACCEPT_ECHEQ_TERMS_CONDITIONS_REQUEST, getAcceptEcheqTermsConditionsRequest),
    takeLatest(types.DOWNLOAD_ECHEQ_LIST_REQUESTED, downloadEcheqList),
    takeLatest(types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUESTED, downloadEcheqMovementDetail),
    takeLatest(types.VALIDATE_PERSONALIZED_ECHEQ_REQUEST, validatePersonalizedEcheq),
    takeLatest(types.REMOVE_PERSONALIZED_ECHEQ_LINE_REQUEST, removeLinesAndSetValues),
];

export default sagas;

function* loadListRequest({ filters }) {
    const response = yield call(echeqsMiddleware.loadListRequest, filters);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqsList"]));
    } else {
        const { echeqs, pageNumber, totalPages, moreEcheqs } = response.data.data;
        yield put(actions.loadListSuccess(echeqs, pageNumber, totalPages, moreEcheqs));
    }
}

function* fetchMoreEcheqs({ filters }) {
    const response = yield call(echeqsMiddleware.loadListRequest, { ...filters });
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqsList"]));
    } else {
        const { echeqs, pageNumber, totalPages, moreEcheqs } = response.data.data;
        yield put(actions.loadMoreEcheqsSuccess(echeqs, pageNumber, totalPages, moreEcheqs));
    }
}

function* getECheqDetail({ id, filters }) {
    const response = yield call(echeqsMiddleware.getECheqDetail, id);

    if (response.type === "W") {
        yield put(actions.getEcheqDetailFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["checksList"]));
    } else {
        yield put(actions.setSelectedEcheq(response.data.data.detail));
        yield put(actions.setFilters(filters));
        yield put(actions.getEcheqDetailSuccess(response.data.data));
    }
}

function* getBankedCustomerInfo({ data }) {
    const { documentType, documentNumber, idActivity, searchSuccessAction } = data;
    const response = yield call(echeqsMiddleware.getBankedCustomerInfo, documentType, documentNumber);

    if (response.type === "W") {
        yield put(actions.getBankedCustomerInfoFailure(response.data.data));
        const message = response.data.data?.NO_FIELD ?? response.data.data?.message;
        yield put(notificationActions.showNotification(message, "error", ["form"]));
    } else if (response.data.data.data) {
        const dataResponse = response.data.data.data;
        yield put(actions.getBankedCustomerInfoSuccess(dataResponse));
        if (searchSuccessAction) {
            searchSuccessAction(dataResponse);
        }
    } else {
        const { message } = response.data.data;
        yield put(actions.getBankedCustomerInfoFailure(response.data.data));
        yield put(
            notificationActions.showNotification(
                idActivity === "echeqs.requestMandate.payment.send"
                    ? i18n.get("forms.echeqs.requestMandate.payment.mandatary.notFound")
                    : message,
                "error",
                ["form"],
            ),
        );
    }
}

function* getListIMF({ successAction }) {
    const response = yield call(echeqsMiddleware.getListIMF);
    if (response.type === "W") {
        yield put(actions.getListIMFFailure());
        const message = response.data.data?.NO_FIELD ?? response.data.data?.message;
        yield put(notificationActions.showNotification(message, "error", ["form"]));
    } else if (response.data.data.listIMF) {
        const { listIMF } = response.data.data;
        yield put(actions.getListIMFSuccess(listIMF));

        if (successAction) {
            successAction();
        }
    } else {
        const { message } = response.data.data;
        yield put(actions.getListIMFFailure());
        yield put(notificationActions.showNotification(message, "error", ["form"]));
    }
}

function* getAuthorizedEntityList({ successAction }) {
    const response = yield call(echeqsMiddleware.getAuthorizedEntityList);
    if (response.type === "W") {
        yield put(actions.getAuthorizedEntityListFailure(response.data));
        const { message } = response.data;
        yield put(notificationActions.showNotification(message, "error", ["form"]));
    } else if (response.data.data) {
        const { authorizedEntities } = response.data.data;
        yield put(actions.getAuthorizedEntityListSuccess(authorizedEntities));
    }
    if (successAction) {
        successAction();
    }
}

function* disableAccountToIssueECheq({ action, cbu, cuit, account }) {
    const response = yield call(echeqsMiddleware.disableAccount, action, cbu, cuit, account);

    const message = response.data.data?.NO_FIELD || response.data.data?.message;
    if (response.type === "W") {
        yield put(actions.disableAccountFailure());
        yield put(notificationActions.showNotification(message, "error", ["eCheqAccounts"]));
    } else {
        yield put(actions.disableAccountSuccess());
        yield put(
            push({
                pathname: `/transaction/${response.data?.idTransaction}`,
                state: { transition: NO_TRANSITION },
            }),
        );
    }
}

function* getEcheqIssuingAccounts({ status }) {
    const response = yield call(echeqsMiddleware.getEcheqIssuingAccounts, status);
    if (response.type === "W") {
        yield put(actions.getEcheqIssuingAccountsFailure());
    } else {
        const { authorizedAccountsToEmit } = response.data.data;

        yield put(actions.getEcheqIssuingAccountsSuccess(authorizedAccountsToEmit));
    }
}

function* getAcceptEcheqTermsConditionsRequest() {
    const response = yield call(echeqsMiddleware.getAcceptEcheqTermsConditionsRequest);
    if (response.type === "W") {
        yield put(actions.getAcceptEcheqTermsConditionsFailure());
    } else {
        const { successEnrollment } = response.data.data;
        yield put(actions.getAcceptEcheqTermsConditionsSuccess(successEnrollment));
        if (!successEnrollment) {
            yield put(push("/echeqs/acceptEcheqTermsConditions"));
            yield put(
                notificationModalActions.showNotification(
                    i18n.get("forms.echeqs.acceptTermsAndConditions.modal"),
                    "warn",
                    [],
                ),
            );
        }
    }
}

function* downloadEcheqList({ format, filters }) {
    const response = yield call(echeqsMiddleware.downloadEcheqList, format, filters);

    if (response && response.status === 200) {
        const { content, fileName } = response.data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else if (format === "xls") {
            downloadXls(fileName, content);
        } else {
            const blob = base64toBlob(content, "text/csv");
            downloadCsv(fileName, blob);
        }
        yield put(echeqsActions.downloadEcheqListSuccess());
        yield put(
            notificationModalActions.showNotification(i18n.get("forms.echeqs.list.download.successMessage"), "success"),
        );
    } else {
        yield put(echeqsActions.downloadEcheqListFailure());
    }
}

function* downloadEcheqMovementDetail({ echeq, format, subStates }) {
    const { type, data } = yield call(echeqsMiddleware.downloadEcheqMovementDetail, echeq, format, subStates);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_REQUEST_FAILED });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["echeqs/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put({ type: types.DOWNLOAD_ECHEQ_MOVEMENT_DETAIL_RECEIVED });
        yield put(
            notificationModalActions.showNotification(i18n.get("echeqs.detail.download.successMessage"), "success"),
        );
    }
}

function* validatePersonalizedEcheq({ formikBag, values, validateSuccessAction }) {
    const response = yield call(echeqsMiddleware.validatePersonalizedEcheqRequest, values);
    if (response.type === "W") {
        const errors = response.data.data;
        yield put(actions.validatePersonalizedEcheqFailure(errors));
        formikBag.setErrors(response.data.data);
    } else {
        const { validatedEcheq } = response.data.data;
        yield put(actions.validatePersonalizedEcheqSuccess(validatedEcheq));
        validateSuccessAction();
    }
    formikBag.setSubmitting(false);
}

function* removeLinesAndSetValues({ lineNumberToRemove, setFieldsAfterRemove, setFieldValue }) {
    const currentValidatedEcheqsList = yield select(echeqsSelectors.getPersonalizedEcheqsList);
    const finalValidatedEcheqsList = currentValidatedEcheqsList.reduce((result, line) => {
        if (line.lineNumber === lineNumberToRemove) {
            return [...result];
        }
        if (line.lineNumber < lineNumberToRemove) {
            return [...result, line];
        }
        return [
            ...result,
            {
                ...line,
                lineNumber: line.lineNumber - 1,
            },
        ];
    }, []);
    yield put(echeqsActions.removePersonalizedEcheqLineSuccess(finalValidatedEcheqsList));
    setFieldsAfterRemove(setFieldValue);
}
