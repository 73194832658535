import React from "react";
import GenericItem from "components/GenericItem/GenericItem";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import * as stringUtils from "util/string";

const RetentionItem = (props) => {
    const { detail, isMobile, comeFromProcessed } = props;
    const formattedCuit = `${stringUtils.formatCuit(detail.cuit)}`;

    const renderItem = () => {
        if (isMobile) {
            return (
                <>
                    <GenericItem
                        options={
                            <Dropdown
                                image="images/ui-icons/ui-dropdown-mobile.svg"
                                label=""
                                bsStyle="link"
                                className="btn-small"
                                pullRight
                            />
                        }>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.voucherNumber.label" />
                            </div>
                            <div className="data-text">{detail.voucherNumber}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.retentionDate.label" />
                            </div>
                            <div className="data-text">{detail.retentionDate}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.businessName.label" />
                            </div>
                            <div className="data-text">{detail.businessName}</div>
                            <div className="data-text">{formattedCuit}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.tax.label" />
                            </div>
                            <div className="data-text">{detail.tax}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.regime.label" />
                            </div>
                            <div className="data-text">{detail.regime}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.ticketOrigin.label" />
                            </div>
                            <div className="data-text">{detail.voucherOrigin}</div>
                            <FormattedAmount
                                currency={detail.voucherAmount?.currency}
                                quantity={detail.voucherAmount?.quantity}
                            />
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.amount.label" />
                                <FormattedAmount
                                    currency={detail.amount?.currency}
                                    quantity={detail.amount?.quantity}
                                />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                <Table.Data align="left">
                    <span className="data-text">{detail.voucherNumber}</span>
                </Table.Data>

                <Table.Data align="left">
                    <div className="data-text">
                        <FormattedDate date={detail.retentionDate} />
                    </div>
                </Table.Data>

                <Table.Data align="left">
                    {detail.businessName && (
                        <>
                            <span className="data-text">{detail.businessName}</span>
                            <br />
                        </>
                    )}
                    <span className="data-text">{formattedCuit}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.tax}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{detail.regime}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{detail.voucherOrigin}</span>
                    <FormattedAmount
                        currency={detail.voucherAmount?.currency}
                        quantity={detail.voucherAmount?.quantity}
                    />
                </Table.Data>
                <Table.Data align="left">
                    <FormattedAmount currency={detail.amount?.currency} quantity={detail.amount?.quantity} />
                </Table.Data>
            </Table.Row>
        );
    };

    const renderProcessedItem = () => {
        if (isMobile) {
            return (
                <>
                    <GenericItem
                        options={
                            <Dropdown
                                image="images/ui-icons/ui-dropdown-mobile.svg"
                                label=""
                                bsStyle="link"
                                className="btn-small"
                                pullRight
                            />
                        }>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.voucherNumber.label" />
                            </div>
                            <div className="data-text">{detail.voucherNumber}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.retentionDate.label" />
                            </div>
                            <div className="data-text">{detail.retentionDate}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.businessName.label" />
                            </div>
                            <div className="data-text">{detail.businessName}</div>
                            <div className="data-text">{formattedCuit}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.tax.label" />
                            </div>
                            <div className="data-text">{detail.tax}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.regime.label" />
                            </div>
                            <div className="data-text">{detail.regime}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.ticketOrigin.label" />
                            </div>
                            <div className="data-text">{detail.voucherOrigin}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.retetion.header.amount.label" />
                                <FormattedAmount
                                    currency={detail.amount?.currency}
                                    quantity={detail.amount?.quantity}
                                />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                <Table.Data align="left">
                    <span className="data-text">{detail.voucherNumber}</span>
                </Table.Data>

                <Table.Data align="left">
                    <div className="data-text">
                        <FormattedDate date={detail.retentionDate} />
                    </div>
                </Table.Data>

                <Table.Data align="left">
                    {detail.businessName && (
                        <>
                            <span className="data-text">{detail.businessName}</span>
                            <br />
                        </>
                    )}
                    <span className="data-text">{formattedCuit}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.tax}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{detail.regime}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{detail.voucherOrigin}</span>
                    <FormattedAmount
                        currency={detail.voucherAmount?.currency}
                        quantity={detail.voucherAmount?.quantity}
                    />
                </Table.Data>
                <Table.Data align="left">
                    <FormattedAmount currency={detail.amount?.currency} quantity={detail.amount?.quantity} />
                </Table.Data>
            </Table.Row>
        );
    };

    if (comeFromProcessed) {
        return renderProcessedItem();
    }

    return renderItem();
};

RetentionItem.propTypes = {
    dispatch: func.isRequired,
    headInfo: shape({}).isRequired,
    detail: shape({}).isRequired,
    isMobile: bool,
    comeFromProcessed: bool,
};

RetentionItem.defaultProps = {
    isMobile: false,
    comeFromProcessed: false,
};

export default connect()(RetentionItem);
