import React, { Component } from "react";

import { number, string, bool, func } from "prop-types";

import Image from "pages/_components/Image";
import { Link } from "react-router-dom";
import * as configUtils from "util/config";
import { Mixpanel } from "util/clickstreaming";

import CloseCampaignModal from "pages/campaigns/CloseCampaignModal";
import { openLink } from "util/browser";

class CampaignItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idCampaign: number.isRequired,
        name: string.isRequired,
        // priority: number.isRequired,
        // creator: string.isRequired,
        // creationDate: string.isRequired,
        // startDate: string.isRequired,
        // endDate: string.isRequired,
        // contentType: string.isRequired,
        clickable: bool.isRequired,
        url: string.isRequired,
        // suspended: bool.isRequired,
        // dismissable: bool.isRequired, // Should this be 'true' by default?
        // sectionList: arrayOf().isRequired,
        // imageList: arrayOf(shape({})).isRequired,
        // rules: arrayOf().isRequired,
        // expired: bool.isRequired,
        // startDateAsString: string.isRequired,
        // endDateAsString: string.isRequired,
        image: string.isRequired,
        isMobileNative: bool.isRequired,
        section: string.isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        displayModal: false,
        selectedCampaign: null,
    };

    renderImage = (image, url = null) => (
        // eslint-disable-next-line
        <img src={`data:image/png;base64, ${image}`} alt="" onClick={url ? () => this.handleClick(url) : null} />
    );

    handleClick = (url) => {
        const { isMobileNative, idCampaign } = this.props;
        Mixpanel.track("campaign.click", {
            id: idCampaign,
        });
        if (isMobileNative) {
            openLink(url).then();
        } else {
            window.open(url, "_blank").focus();
        }
    };

    showModal = () => {
        const { idCampaign } = this.props;
        this.setState({ displayModal: true, selectedCampaign: idCampaign });
    };

    hideModal = () => {
        this.setState({ displayModal: false, selectedCampaign: null });
    };

    render() {
        const { idCampaign, url, image, clickable, dispatch, name, section, isDesktop } = this.props;
        const baseUrl = configUtils.get("client.baseURL");
        const { selectedCampaign, displayModal } = this.state;
        const dismissButton = (
            <div className="dismiss-campaign" onClick={this.showModal}>
                <Image src="images/cross.svg" className="svg-icon-small" />
            </div>
        );

        const linkURL = url && url.includes(baseUrl) ? url.replace(baseUrl, "") : url;

        Mixpanel.track_user("campaign.show", {
            id: idCampaign,
            name,
            url,
            clickable,
        });
        return (
            <>
                <CloseCampaignModal
                    dispatch={dispatch}
                    idCampaign={selectedCampaign}
                    isDisplayed={displayModal}
                    closeModal={this.hideModal}
                    section={section}
                    isDesktop={isDesktop}
                />
                <div className="campaign-container" key={idCampaign}>
                    {linkURL ? (
                        <Link to="#">{this.renderImage(image, clickable ? linkURL : null)}</Link>
                    ) : (
                        this.renderImage(image)
                    )}
                    {dismissButton}
                </div>
            </>
        );
    }
}

export default CampaignItem;
