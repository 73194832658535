import { array, bool, func, int, oneOf, oneOfType } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLastLoginPlace } from "util/settings";

import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";

import { naturalDate } from "util/format";

import ReportSuspiciousActivity from "pages/communications/_components/ReportSuspiciousActivity";
import ReportSuspiciousActivityHeader from "pages/communications/_components/ReportSuspiciousActivityHeader";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import NoDataTextContainer from "components/NoDataContainer/NoDataTextContainer";

class MyDevices extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        moreData: bool,
        nextPage: int,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        moreData: false,
        nextPage: 1,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.listAllUserDevicesRest());
        dispatch(pushNotificationsActions.listAllUserDevices(1));
    }

    handleShowMore = () => {
        const { dispatch, nextPage } = this.props;
        dispatch(pushNotificationsActions.listAllUserDevices(nextPage));
    };

    renderDevices = () => {
        const { pushNotificationsConfiguredUserDevices, isMobile, moreData } = this.props;

        if (pushNotificationsConfiguredUserDevices && pushNotificationsConfiguredUserDevices.length) {
            return (
                <>
                    <I18n id="settings.myDevices.usedDevices" component="h4" />
                    <ul className="navigational-list my-devices">
                        {pushNotificationsConfiguredUserDevices.map(
                            ({
                                idDevice,
                                model,
                                platform,
                                modificationDate,
                                lastEntryCity,
                                lastEntryCountry,
                                ...rest
                            }) => (
                                <li key={idDevice} className="my-devices__device-container">
                                    <div className="my-devices__device-icon">{this.renderIcon(rest)}</div>
                                    <div className="my-devices__device-name">
                                        <div>
                                            {model ? (
                                                <I18n
                                                    id={`devices.apple.identifier.${model}`}
                                                    defaultValue={`${model}`}
                                                />
                                            ) : (
                                                <span>{platform}</span>
                                            )}
                                            {!isMobile && " | "}
                                            <span>****{idDevice.substring(idDevice.length - 4, idDevice.length)}</span>
                                        </div>
                                        <small>
                                            {naturalDate(modificationDate)} -{" "}
                                            {getLastLoginPlace(lastEntryCity, lastEntryCountry)}
                                        </small>
                                    </div>
                                </li>
                            ),
                        )}
                    </ul>

                    <div className="ui-dflex-center">
                        {moreData ? (
                            <Button
                                block={isMobile}
                                label="global.button.showMore"
                                image="images/plusWhite.svg"
                                type="button"
                                bsStyle="secondary"
                                loading={false}
                                onClick={this.handleShowMore}
                                className="ui-mt-6"
                            />
                        ) : (
                            <NoDataTextContainer noMoreDataText="settings.myDevices.noMoreDevices" />
                        )}
                    </div>
                </>
            );
        }
        return <I18n id="settings.pushNotifications.configuredDevices.none" />;
    };

    renderIcon = (data) => {
        const icon = data.isAndroid || data.isIOS ? "images/mobile.svg" : "images/desktop.svg";

        return <Image src={icon} className="svg-icon" />;
    };

    getContent = () => {
        const { pushNotificationsConfiguredUserDevices, isMobile, dispatch } = this.props;

        return (
            pushNotificationsConfiguredUserDevices && (
                <div className="container-center">
                    <div className="title-left-header">
                        <I18n id="settings.myDevices" component="h1" />
                    </div>
                    <form className="form-content">
                        <div className="form-text-info ui-mb-7">
                            <I18n id="settings.myDevices.title" />
                        </div>
                        <div className="form-text-info ui-mb-8">
                            <I18n componentProps={{ className: "ui-fw-sbold" }} id="settings.myDevices.subtitle" />
                        </div>

                        {this.renderDevices()}

                        {isMobile && (
                            <ReportSuspiciousActivity dispatch={dispatch} isMobile={isMobile} origin="myDevices" />
                        )}
                    </form>
                </div>
            )
        );
    };

    renderHeader = () => {
        const { isMobile, dispatch } = this.props;
        return <ReportSuspiciousActivityHeader dispatch={dispatch} isMobile={isMobile} origin="myDevices" />;
    };

    render() {
        return (
            <>
                <Notification scopeToShow="pushNotifications" />

                {this.renderHeader()}

                <MainContainer>{this.getContent()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
    moreData: pushNotificationsSelectors.getMoreData(state),
    nextPage: pushNotificationsSelectors.getNextPage(state),
});

export default connect(mapStateToProps)(MyDevices);
