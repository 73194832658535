import React, { Component, Fragment } from "react";
import { shape, node, bool } from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";

class DetailHeadInfo extends Component {
    static propTypes = {
        account: shape({}).isRequired,
        children: node,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        children: null,
    };

    render() {
        const { account, children, isDesktop } = this.props;
        const {
            balance,
            countableBalance,
            currency,
            overdraftLine,
            productType,
            depositToEstablishToday,
            depositToEstablish48h,
        } = account;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const availableBalanceData = (
            <div className="data-wrapper">
                <I18n id="accounts.availableBalance" componentProps={{ className: "data-label" }} />
                <FormattedAmount className="head-data" currency={currency} quantity={balance} />
            </div>
        );

        const countableBalanceData = (
            <div className="data-wrapper">
                <I18n id="accounts.countableBalance" componentProps={{ className: "data-label" }} />
                <FormattedAmount className="head-data" currency={currency} quantity={countableBalance} />
            </div>
        );

        const overdraftLineData = productType === "CC" && overdraftLine > 0 && (
            <div className="data-wrapper">
                <I18n id="accounts.overdraftAmount" componentProps={{ className: "data-label" }} />
                <FormattedAmount className="head-data" currency={currency} quantity={overdraftLine} />
            </div>
        );

        const depositToEstablishTodayData = productType === "CC" && (
            <div className="data-wrapper">
                <I18n id="accounts.depositToEstablishToday.label" componentProps={{ className: "data-label" }} />
                <FormattedAmount className="head-data" currency={currency} quantity={depositToEstablishToday} />
            </div>
        );

        const depositToEstablish48hsData = productType === "CC" && (
            <div className="data-wrapper">
                <I18n id="accounts.depositToEstablish48hs.label" componentProps={{ className: "data-label" }} />
                <FormattedAmount className="head-data" currency={currency} quantity={depositToEstablish48h} />
            </div>
        );

        return (
            (isDesktop && (
                <Fragment>
                    <div
                        className={classNames("content-data detail-head-info account", {
                            special: productType !== "CC",
                        })}>
                        {availableBalanceData}
                        {countableBalanceData}
                        {overdraftLineData}
                        {depositToEstablishTodayData}
                        {depositToEstablish48hsData}
                    </div>
                    {children && <div className="account-nav-buttons">{children}</div>}
                </Fragment>
            )) || (
                <>
                    <div className="slick-slider-wrapper">
                        <Slider {...settings}>
                            {availableBalanceData}
                            {countableBalanceData}
                            {overdraftLineData}
                            {depositToEstablishTodayData}
                            {depositToEstablish48hsData}
                        </Slider>
                        {children || null}
                    </div>
                </>
            )
        );
    }
}

export default DetailHeadInfo;
