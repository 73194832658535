import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, string, func } from "prop-types";

import { selectors as formSelectors } from "reducers/form";
import {
    actions as transactionLinesActions,
    selectors as transactionLinesSelectors,
} from "reducers/form/transactionLines";
import { actions as massPaymentsActions } from "reducers/massPayments";

import PaymentLines from "pages/forms/PaymentLines";
import FileCollapsibleInfo from "pages/forms/_components/FileCollapsibleInfo";

class TransactionPaymentLines extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                idTransaction: string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(transactionLinesActions.clearTransactionLines());
    }

    handleDownloadClick = (filterState, format) => {
        const { transaction, dispatch } = this.props;
        const { filterData } = filterState;
        dispatch(massPaymentsActions.downloadLinesRequest(transaction.idTransaction, format, filterData));
    };

    render() {
        return (
            <PaymentLines
                isEditingPayment={false}
                renderMoreDetails={() => <FileCollapsibleInfo />}
                isTicket
                onDownloadClick={this.handleDownloadClick}
                formats={["xls", "txt"]}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    transaction: formSelectors.getTransaction(state),
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    totalAmount: transactionLinesSelectors.getTotalAmount(state),
    totalLines: transactionLinesSelectors.getTotalLines(state),
    isFetching: formSelectors.getFetching(state),
    isLastPage: transactionLinesSelectors.isLastPage(state),
});

export default connect(mapStateToProps)(TransactionPaymentLines);
