import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { arrayOf, func, shape, string } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions } from "reducers/settings";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { getArray } from "util/config";

class LanguageSelection extends Component {
    static propTypes = {
        activeLanguage: string.isRequired,
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }),
        supportedLanguages: arrayOf(string).isRequired,
        legendTextID: string,
    };

    static defaultProps = {
        history: null,
        legendTextID: "settings.changeLanguage",
    };

    handleClick = (language) => {
        const { activeLanguage, dispatch } = this.props;

        if (language !== activeLanguage) {
            document.documentElement.lang = language;
            dispatch(settingsActions.changeLanguage(language, false));
        }
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { activeLanguage, supportedLanguages, legendTextID } = this.props;

        return (
            <Fragment>
                <div className="mtop-4rem">
                    <Head
                        title="settings.changeLanguage"
                        onBack={this.backButtonAction}
                        arrowBackClass="arrow-back-login"
                    />
                </div>
                <Notification scopeToShow="changeLanguage" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <fieldset className="form-group">
                                            {legendTextID && (
                                                <I18n
                                                    id={legendTextID}
                                                    component="legend"
                                                    componentProps={{ className: "visually-hidden" }}
                                                />
                                            )}
                                            <div className="selection-list">
                                                {Object.keys(supportedLanguages).map((idLanguage) => (
                                                    <div
                                                        className="c-control  c-control--radio"
                                                        key={`language-${idLanguage}`}>
                                                        <input
                                                            defaultChecked={
                                                                supportedLanguages[idLanguage] === activeLanguage
                                                                    ? "checked"
                                                                    : ""
                                                            }
                                                            id={`language-${supportedLanguages[idLanguage]}`}
                                                            type="radio"
                                                            name="language"
                                                            className="c-control-input"
                                                            onClick={() =>
                                                                this.handleClick(supportedLanguages[idLanguage])
                                                            }
                                                        />
                                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                        <label
                                                            className="c-control-label c-control-label--environment"
                                                            htmlFor={`language-${supportedLanguages[idLanguage]}`}>
                                                            <I18n
                                                                id={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    supportedLanguages: getArray("frontend.i18n.languages"),
});

export default connect(mapStateToProps)(LanguageSelection);
