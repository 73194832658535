import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    CHECKS_DEFERRED_LIST_REQUEST: "checks/CHECKS_DEFERRED_LIST_REQUEST",
    CHECKS_DEFERRED_LIST_SUCCESS: "checks/CHECKS_DEFERRED_LIST_SUCCESS",
    CHECKS_DEFERRED_LIST_FAILURE: "checks/CHECKS_DEFERRED_LIST_FAILURE",

    LOAD_MORE_CHECKS_DEFERRED_SUCCESS: "checks/LOAD_MORE_CHECKS_DEFERRED_SUCCESS",
    LOAD_MORE_CHECKS_DEFERRED_REQUEST: "checks/LOAD_MORE_CHECKS_DEFERRED_REQUEST",
    LOAD_MORE_CHECKS_DEFERRED_FAILURE: "checks/LOAD_MORE_CHECKS_DEFERRED_FAILURE",

    CHECKS_DEFERRED_DETAIL_REQUEST: "checks/CHECKS_DEFERRED_DETAIL_REQUEST",
    CHECKS_DEFERRED_DETAIL_SUCCESS: "checks/CHECKS_DEFERRED_DETAIL_SUCCESS",
    CHECKS_DEFERRED_DETAIL_FAILURE: "checks/CHECKS_DEFERRED_DETAIL_FAILURE",

    SET_CHECK_DEFERRED: "checks/SET_CHECK_DEFERRED",

    SET_CHECK_TYPE_DEFERRED: "checks/SET_CHECK_TYPE_DEFERRED",
    LOAD_LIST_REQUEST: "checks/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "checks/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "checks/LOAD_LIST_SUCCESS",
    SET_FILTERS: "checks/SET_FILTERS",
    LOAD_MORE_CHECKS_REQUEST: "checks/LOAD_MORE_CHECKS_REQUEST",
    LOAD_MORE_CHECKS_SUCCESS: "checks/LOAD_MORE_CHECKS_SUCCESS",
    SET_SELECTED_CHECK: "checks/SET_SELECTED_CHECK",

    SET_ACCOUNT_ID_SELECTED: "checks/SET_ACCOUNT_ID_SELECTED",

    RESET_FILTERS: "checks/RESET_FILTERS",
    RESET_CHECKS: "checks/RESET_CHECKS",
};

export const INITIAL_STATE = {
    checksList: [],
    checksListDeferred: [],
    isFetching: false,
    totalPages: "",
    checkDetail: {},
    filters: { checkType: "emitted", checkTypeDeferred: "descontados", pageNumber: 1, state: [] },
    hasMoreData: false,
    isFirstFetching: true,
    moreChecks: false,
    selectedCheck: {},
    accountIdSelected: null,
    checkDeferred: {},
};

export default createReducer(INITIAL_STATE, {
    [types.CHECKS_DEFERRED_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: {
            ...state.filters,
            ...action.filters,
        },
        isFirstFetching: action.isFirstFetching === true,
    }),
    [types.CHECKS_DEFERRED_LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        checksListDeferred: action.checksListDeferred,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        totalPages: action.totalPages,
        moreChecks: action.moreChecks,
        isFirstFetching: false,
    }),
    [types.CHECKS_DEFERRED_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LOAD_MORE_CHECKS_DEFERRED_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_CHECKS_DEFERRED_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        checksListDeferred: state.checksListDeferred
            ? state.checksListDeferred.concat(action.checksListDeferred)
            : action.checksListDeferred,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        totalPages: action.totalPages,
        moreChecks: action.moreChecks,
    }),
    [types.LOAD_MORE_CHECKS_DEFERRED_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.CHECKS_DEFERRED_DETAIL_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.CHECKS_DEFERRED_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        checkDetail: action.checkDetail,
        isFetching: false,
    }),
    [types.CHECKS_DEFERRED_DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LOAD_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: {
            ...state.filters,
            ...action.filters,
        },
        isFirstFetching: true,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checksList: action.checks,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        moreChecks: action.moreChecks,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: {
            ...state.filters,
            ...action.filters,
        },
    }),
    [types.SET_SELECTED_CHECK]: (state, action) => ({
        ...state,
        selectedCheck: action.check,
    }),
    [types.LOAD_MORE_CHECKS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_MORE_CHECKS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        checksList: state.checksList ? state.checksList.concat(action?.checks) : action?.checks,
        hasMoreData: action.pageNumber < action.totalPages,
        filters: {
            ...state.filters,
            pageNumber: action.pageNumber,
        },
        moreChecks: action.moreChecks,
    }),
    [types.SET_ACCOUNT_ID_SELECTED]: (state, action) => ({
        ...state,
        accountIdSelected: action.accountId,
    }),

    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
    }),

    [types.SET_CHECK_DEFERRED]: (state, action) => ({
        ...state,
        checkDeferred: {
            type: action.checkType,
            check: action.check,
        },
    }),
});

export const actions = {
    checksDeferredListRequest: makeActionCreator(types.CHECKS_DEFERRED_LIST_REQUEST, "filters"),
    checksDeferredListSuccess: makeActionCreator(
        types.CHECKS_DEFERRED_LIST_SUCCESS,
        "checksListDeferred",
        "pageNumber",
        "totalPages",
        "moreChecks",
    ),
    checksDeferredListFailure: makeActionCreator(types.CHECKS_DEFERRED_LIST_FAILURE),

    loadMoreChecksDeferredRequest: makeActionCreator(types.LOAD_MORE_CHECKS_DEFERRED_REQUEST, "filters"),
    loadMoreChecksDeferredSuccess: makeActionCreator(
        types.LOAD_MORE_CHECKS_DEFERRED_SUCCESS,
        "checksListDeferred",
        "pageNumber",
        "totalPages",
        "moreChecks",
    ),
    loadMoreChecksDeferredFailure: makeActionCreator(types.LOAD_MORE_CHECKS_DEFERRED_FAILURE),
    checkDeferredDetailRequest: makeActionCreator(
        types.CHECKS_DEFERRED_DETAIL_REQUEST,
        "checkType",
        "branch",
        "operationNumber",
        "checkNumber",
        "orderNumber",
        "idCheck",
    ),

    checkDeferredDetailSuccess: makeActionCreator(types.CHECKS_DEFERRED_DETAIL_SUCCESS, "checkDetail"),
    checkDeferredDetailFailure: makeActionCreator(types.CHECKS_DEFERRED_DETAIL_FAILURE),
    loadListRequest: makeActionCreator(types.LOAD_LIST_REQUEST, "filters"),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "checks", "pageNumber", "totalPages", "moreChecks"),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    loadMoreChecksRequest: makeActionCreator(types.LOAD_MORE_CHECKS_REQUEST, "filters"),
    loadMoreChecksSuccess: makeActionCreator(
        types.LOAD_MORE_CHECKS_SUCCESS,
        "checks",
        "pageNumber",
        "totalPages",
        "moreChecks",
    ),

    setSelectedCheck: makeActionCreator(types.SET_SELECTED_CHECK, "check"),

    setAccountIdSelected: makeActionCreator(types.SET_ACCOUNT_ID_SELECTED, "accountId"),

    resetFilters: makeActionCreator(types.RESET_FILTERS),
    setCheckDeferred: makeActionCreator(types.SET_CHECK_DEFERRED, "checkType", "check"),
};

export const selectors = {
    getChecks: ({ checks }) => checks.checksList,
    getChecksDeferred: ({ checks }) => checks.checksListDeferred,
    getFetching: ({ checks }) => checks.isFetching,
    getFilters: ({ checks }) => checks.filters,
    getPageNumber: ({ checks }) => checks.filters.pageNumber,
    getTotalPages: ({ checks }) => checks.totalPages,
    getMoreChecks: ({ checks }) => checks.moreChecks,
    getCheckDetail: ({ checks }) => checks.checkDetail,
    getFirstFetched: ({ checks }) => checks.isFirstFetching,
    getHasMoreData: ({ checks }) => checks.hasMoreData,
    getSelectedCheck: ({ checks }) => checks.selectedCheck,
    getAccountIdSelected: ({ checks }) => checks.accountIdSelected,
    getCheckDeferred: ({ checks }) => checks.checkDeferred,
};
