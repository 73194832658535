import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as loginSelectors } from "reducers/login";

class Success extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fromOnboardingLoginData: shape({
            acceptESign: bool,
            firstName: string,
            lastName: string,
        }).isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;
        this.setState(() => {
            dispatch(push("/"));
        });
    };

    render() {
        const { fromOnboardingLoginData, dispatch } = this.props;
        return (
            <div className="view-page">
                <div className="title-header">
                    <I18n id="enrollment.index.header" component="h1" />
                </div>
                <div className="container">
                    <Image className="success-icon" src="images/ui-icons/colored/ui-success.svg" />

                    <div className="enrollment-success-text">
                        <I18n id={fromOnboardingLoginData?.messageKey} ACCOUNT={fromOnboardingLoginData?.account} />
                    </div>

                    {fromOnboardingLoginData.messageKey !== "enrollment.associate.success" && (
                        <I18n
                            id="enrollment.success.more.info"
                            component="p"
                            componentProps={{ className: "ui-text-center ui-mb-7" }}
                        />
                    )}

                    {fromOnboardingLoginData.messageKey !== "enrollment.associate.success" && (
                        <>
                            <I18n
                                id="enrollment.success.important"
                                component="div"
                                componentProps={{ className: "text-warning text-center" }}
                            />
                            <I18n
                                id="enrollment.success.more.important"
                                component="div"
                                componentProps={{ className: "text-center" }}
                            />
                        </>
                    )}

                    <Button
                        type="button"
                        bsStyle="primary"
                        label="enrollment.success.enter"
                        onClick={() => dispatch(push("/"))}
                        block
                        className="ui-mt-8"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(Success);
