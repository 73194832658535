import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as investmentSelectors } from "reducers/investments";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormTransition from "pages/forms/_components/FormTransition";
import CustomScheduler from "pages/forms/_components/_scheduler/CustomScheduler";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import { bool, shape, func, string } from "prop-types";
import I18n from "pages/_components/I18n";

const ID_FORM = "request.investment.subscribe";
const ID_ACTIVITY = "request.investment.subscribe.send";
const ID_ACTIVITY_PRE = "request.investment.subscribe.pre";

const RequestInvestmentSubscribeForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        data,
        currentLang,
        fromBackoffice,
        transaction,
        fundToSubscribe,
        operatingFunds,
        activeEnvironment,
        preData,
        isDesktop,
        accountSelected,
    } = props;

    const loadAccountOptions = (shareHolder) => {
        const shareholderAccountList = shareHolder?.bankAccounts || [];
        const optionsLoaded = {
            options: shareholderAccountList.map((acc) => ({
                ...acc,
                id: acc.idCpt,
                label: `${acc.accountCurrency} ${acc.accountNumber} ${acc.accountDescription}`,
            })),
        };

        return optionsLoaded;
    };

    const [selectedFundToSubscribe, setSelectedFundToSubscribe] = useState({});
    const [shareholderAccounts, setShareholderAccounts] = useState([]);
    const [shareholderAccountSelected, setShareholderAccountSelected] = useState({});
    const [accountsOptions, setAccountsOptions] = useState({ options: [] });
    const [sourceAccountSelected, setSourceAccountSelected] = useState("");
    const [amount, setAmount] = useState({});
    const [scheduler, setScheduler] = useState({});
    const [amountData, setAmountData] = useState({
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: "",
                label: "",
            },
        ],
    });

    const isEditing = mode === "edit";

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));
        }
        if (mode === "view") {
            setAccountsOptions(data?.accountsOptions);
        }

        setAmount(data.amount);
        setScheduler(data.scheduler);
        setSelectedFundToSubscribe(Object.keys(fundToSubscribe).length > 0 ? fundToSubscribe : data.fundToSubscribe);
    }, [dispatch, location, transaction]); // eslint-disable-line

    useEffect(() => {
        if (mode === "edit") {
            const shareholderAccountsLocal = preData?.shareholderAccounts || [];
            const shareHolder = accountSelected || data?.account;

            setShareholderAccounts(shareholderAccountsLocal);
            setShareholderAccountSelected(shareHolder);
            setAccountsOptions(loadAccountOptions(shareHolder));
            setAmount(data.amount);
            setScheduler(data.scheduler);
            setSelectedFundToSubscribe(
                Object.keys(fundToSubscribe).length > 0 ? fundToSubscribe : data.fundToSubscribe,
            );
            setSourceAccountSelected(data.sourceAccount);
        }
    }, [preData]);

    const { name, typeDescription } = selectedFundToSubscribe || {};
    const { name: accountName, number } = shareholderAccountSelected || {};

    const shareholderAccountOptions = data?.shareholderAccountOptions || {
        options: shareholderAccounts.map(({ number: numberParam, name: nameParam }) => ({
            id: numberParam,
            label: `${numberParam} - ${nameParam}`,
        })),
    };

    const idTransaction = transaction?.idTransaction;
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsView = {
        mode: "view",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const valueFCI = `${name} - ${typeDescription} `;

    const getNavigationLink = () => {
        const fundNumber = fundToSubscribe?.number || data?.fundToSubscribe.number;
        const url = configUtils.get(
            "mutualFunds.subscribe.clausesAndRegulationsLink",
            "https://www.marivafondos.com.ar/sites/default/files/rg_clausulas_particulares_<numFund>.pdf",
        );
        return url.replace("<numFund>", fundNumber);
    };

    const currencyToUse = data?.fundToSubscribe?.currencySymbol || fundToSubscribe?.currencySymbol || null;
    const currencyToShow = currencyToUse === "U$S" ? "USD" : currencyToUse || "ARS";
    let amountHelpMessage = "";

    if (fundToSubscribe.amountMax > 0 && fundToSubscribe.amountMin >= 0) {
        amountHelpMessage = i18nUtils.get("forms.request.investment.subscribe.amount.label.info", "", {
            MAX_AMOUNT: fundToSubscribe.amountMax
                .toFixed(2)
                .toString()
                .replace(".", ","),
            MIN_AMOUNT: fundToSubscribe.amountMin
                .toFixed(2)
                .toString()
                .replace(".", ","),
            CURRENCY: i18nUtils.get(`currency.label.${currencyToShow}`),
        });
    } else if (fundToSubscribe.amountMin >= 0 && fundToSubscribe.amountMax < 0) {
        amountHelpMessage = i18nUtils.get("forms.request.investment.subscribe.amount.min.label.info", "", {
            MIN_AMOUNT: fundToSubscribe.amountMin
                .toFixed(2)
                .toString()
                .replace(".", ","),
            CURRENCY: i18nUtils.get(`currency.label.${currencyToShow}`),
        });
    } else if (fundToSubscribe.amountMax > 0 && fundToSubscribe.amountMin <= 0) {
        amountHelpMessage = i18nUtils.get("forms.request.investment.subscribe.amount.max.label.info", "", {
            MAX_AMOUNT: fundToSubscribe.amountMax
                .toFixed(2)
                .toString()
                .replace(".", ","),
            CURRENCY: i18nUtils.get(`currency.label.${currencyToShow}`),
        });
    }

    const handleSourceAccount = (id) => {
        setSourceAccountSelected(id);
    };

    const handleChangeAccount = (id) => {
        const selectedAccount = shareholderAccounts.find((item) => item.number === id);

        const shareholderAccountList = selectedAccount?.bankAccounts || [];
        const fistOption = shareholderAccountList?.length > 0 ? shareholderAccountList[0] : null;

        const optionsLoaded = {
            options: shareholderAccountList.map((acc) => ({
                ...acc,
                id: acc.idCpt,
                label: `${acc.accountCurrency} ${acc.accountNumber} ${acc.accountDescription}`,
            })),
        };

        setShareholderAccountSelected(selectedAccount);
        setAccountsOptions(optionsLoaded);
        handleSourceAccount("");

        if (fistOption) {
            const currency = fistOption.currencyId;

            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: currency,
                        label: i18nUtils.get(`currency.label.${currency}`),
                    },
                ],
            });

            setAmount({ ...amount, currency });
        }
    };

    const handleSetAmount = (amountValue) => {
        setAmount({ ...amount, quantity: amountValue });
    };

    const handleValueChangeScheduler = (e) => {
        setScheduler(e);
    };

    const renderFields = () => (
        <>
            <div className="hidden-field">
                <Field
                    {...genericPropsView}
                    component={FormFieldsComponents.Text}
                    key="fci"
                    name="fci"
                    idField="fci"
                    value={data?.fci ? data.fci : valueFCI}
                />
            </div>

            <div className="form-group">
                <h4 className="ui-text-uppercase">
                    {i18nUtils.get("forms.request.investment.subscribe.fci.label")} <br />
                    {data?.fci ? data.fci : valueFCI}
                </h4>
            </div>

            <Field
                {...genericProps}
                component={FormFieldsComponents.ProductselectorCustom}
                key="accountShareholder"
                name="accountShareholder"
                idField="accountShareholder"
                data={shareholderAccountOptions}
                value={number || data?.accountShareholder}
                handleChange={(e) => handleChangeAccount(e)}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Amount}
                key="amount"
                name="amount"
                idField="amount"
                data={amountData}
                value={data.amount || ""}
                handleChange={(e) => handleSetAmount(e)}
                hideCurrency
                externalCurrencyData={currencyToShow}
                messageHelp={amountHelpMessage}
            />

            <CustomScheduler
                {...genericProps}
                isDesktop={isDesktop}
                scheduler={data?.scheduler}
                submitDate={transaction?.submitDateTime}
                schedulerLabelCustom="forms.request.investment.subscribe.scheduler.label"
                modalTitleLabelCustom="forms.request.investment.subscribe.scheduler.modal.title"
                disclaimerLabelCustom="forms.request.investment.subcribeRescue.scheduler.disclaimer.text"
                isSubscriptionOrRescue
                handleValueChange={(e) => handleValueChangeScheduler(e)}
            />

            <I18n
                id="forms.request.investment.rescue.payment.label"
                component="h4"
                componentProps={{ className: `${mode === "edit" ? "ui-mb-3" : "ui-mt-7"}` }}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.ProductselectorCustom}
                key="sourceAccount"
                name="sourceAccount"
                idField="sourceAccount"
                data={accountsOptions}
                value={sourceAccountSelected}
                handleChange={(e) => handleSourceAccount(e)}
            />

            <DisclaimerContainer className={`important-text ${mode !== "edit" ? "ui-mt-7" : ""}`}>
                <I18n id="forms.request.investment.subscribe.importantAnnouncement.confirm.label" />
                {mode === "edit" && (
                    <I18n
                        id="forms.request.investment.disclaimer.CNV.label"
                        component="p"
                        componentProps={{ className: "ui-fw-sbold ui-mt-7" }}
                    />
                )}
            </DisclaimerContainer>
        </>
    );

    const renderFieldsTermsAndConditions = () => (
        <>
            <div className="termsAndConditions ui-mt-8 ui-mb-7">
                <DisclaimerContainer maxHeight="134px">
                    <I18n
                        id="forms.request.investment.subscribe.disclaimer.label"
                        componentProps={{ className: "ui-fw-bold" }}
                        component="p"
                    />
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimer"
                        name="disclaimer"
                        idField="disclaimer"
                    />
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimerLink"
                        name="disclaimerLink"
                        idField="disclaimerLink"
                        link="https://www.marivafondos.com.ar"
                        nameLink="Mariva Asset Management"
                    />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`forms.${ID_FORM}.termsAndConditionsSubscription.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
                values={data.termsAndConditionsAcceptance || false}
            />

            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`forms.${ID_FORM}.clausesAndRegulations.checkbox.label`),
                        link: getNavigationLink(),
                        nameLink: i18nUtils.get(`forms.${ID_FORM}.clausesAndRegulationsLink.label.nameLink`),
                    },
                ]}
                key="clausesAndRegulationsAcceptance"
                name="clausesAndRegulationsAcceptance"
                idField="clausesAndRegulationsAcceptance"
                renderAs="check"
                values={data.clausesAndRegulationsAcceptance || []}
            />

            {fundToSubscribe.riskLevel > activeEnvironment.riskLevel && (
                <Field
                    {...genericPropsConditions}
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "1",
                            label: i18nUtils.get(`forms.${ID_FORM}.fundRisks.checkbox.label`, null, {
                                investorRisk: activeEnvironment.riskDescription,
                                fundRisk: fundToSubscribe.riskDescription,
                            }),
                        },
                    ]}
                    key="fundRisksAcceptance"
                    name="fundRisksAcceptance"
                    idField="fundRisksAcceptance"
                    renderAs="check"
                    values={data.fundRisksAcceptance || []}
                />
            )}
        </>
    );

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const shareholderLabel = `${number} ${accountName}` || "";

    const formProps = {
        title: "forms.request.investment.subscribe.formName",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        returnBack: true,
        renderFieldsTermsAndConditions,
        data: {
            account: isEditing ? shareholderAccountSelected : data?.account,
            sourceAccount: isEditing ? sourceAccountSelected : data?.sourceAccount,
            fundToSubscribe: isEditing ? selectedFundToSubscribe : data?.fundToSubscribe,
            amount: isEditing ? amount : data?.amount,
            scheduler: isEditing ? scheduler : data?.scheduler,
            operatingFunds,
            accountsOptions,
            shareholderLabel: isEditing ? shareholderLabel : data?.shareholderLabel,
            shareholderAccountOptions,
            accountShareholder: isEditing ? number : data?.accountShareholder,
        },
        isCustom: true,
        idActivity: ID_ACTIVITY,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        additionalFieldsToValidate:
            fundToSubscribe.riskLevel > activeEnvironment?.riskLevel
                ? ["termsAndConditionsAcceptance", "clausesAndRegulationsAcceptance", "fundRisksAcceptance"]
                : ["termsAndConditionsAcceptance", "clausesAndRegulationsAcceptance"],
    };
    return <FormTransition {...props} {...formProps} />;
};

RequestInvestmentSubscribeForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    operatingFunds: shape({}).isRequired,
    fundToSubscribe: shape({}).isRequired,
    accountSelected: shape({}).isRequired,
};

RequestInvestmentSubscribeForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    fundToSubscribe: investmentSelectors.getSelectedInvestment(state),
    operatingFunds: investmentSelectors.getOperatingFunds(state),
    activeEnvironment:
        sessionSelectors.getEnvironments(state)[sessionSelectors.getActiveEnvironment(state)?.id] || null,
    accountSelected: investmentSelectors.getSelectedAccount(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestInvestmentSubscribeForm);
