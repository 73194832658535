export const typeFileByName = (name) => {
    const nomenclature = name.substring(0, 3);
    switch (nomenclature.toUpperCase()) {
        case "SN1":
            return "transfer";
        case "MS1":
            return "check";
        case "MS3":
            return "echeck";
        case "RE1":
            return "retention";
        case "RSN":
            return "rejected";
        default:
            break;
    }
    return "";
};

export default typeFileByName;
