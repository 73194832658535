import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { string, number, func, bool, shape } from "prop-types";
import { connect } from "react-redux";

import Image from "pages/_components/Image";
import Badge from "pages/_components/Badge";
import I18n from "pages/_components/I18n";
import { Mixpanel } from "util/clickstreaming";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";

class MenuItem extends React.Component {
    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        dispatch: func.isRequired,
        title: string, // en caso de que el titulo ya venga "traducido"
        titleKey: string, // en caso de que el titulo sea una key de mensaje
        linkTo: string,
        image: string,
        onSelect: func,
        onClick: func,
        closeOnSelect: bool,
        notificationCount: number,
        notificationCountTextID: string,
        hasPermission: bool,
        isMobile: bool.isRequired,
        changeEnvironmentOption: bool,
        featureEnabled: bool,
        disableMessage: string,
    };

    static defaultProps = {
        title: null,
        titleKey: "",
        linkTo: "",
        image: "",
        onSelect: null,
        onClick: null,
        closeOnSelect: false,
        notificationCount: 0,
        notificationCountTextID: "",
        hasPermission: true,
        changeEnvironmentOption: false,
        featureEnabled: true,
        disableMessage: "",
    };

    onClickMenu = () => {
        const { linkTo, closeOnSelect, onSelect } = this.props;
        Mixpanel.track_user("menu.action", { linkTo });
        if (closeOnSelect) {
            return onSelect;
        }
        return null;
    };

    isSelected = (location, linkTo) => {
        let aux = false;
        if (location.pathname === linkTo) {
            aux = true;
        }
        return aux;
    };

    renderNotificationCount = () => {
        const { notificationCount, notificationCountTextID } = this.props;

        return (
            <>
                <Badge count={notificationCount} />
                <I18n id={notificationCountTextID} componentProps={{ className: "visually-hidden" }} />
            </>
        );
    };

    render() {
        const {
            location,
            linkTo,
            image,
            title,
            titleKey,
            onClick: handleOnClick,
            notificationCount,
            hasPermission,
            changeEnvironmentOption,
            featureEnabled,
            disableMessage
        } = this.props;

        let tooltipData = "";
        if (!hasPermission) {
            tooltipData = i18nUtils.get("returnCode.COR005E");
            if (linkTo === "/accounts") {
                tooltipData = i18nUtils.get("accounts.list.empty");
            }
        } else if (!featureEnabled) {
            if(disableMessage){
                tooltipData = disableMessage
            } else {
                tooltipData = i18nUtils.get("menu.feature.disabled");
            }  
        }

        if (tooltipData !== "") {
            const tooltipMobileProps = {
                "data-tooltip": tooltipData,
                "data-tooltip-position": "top",
            };

            return (
                <a className="disabled" role="menuitem" {...tooltipMobileProps}>
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} />}
                </a>
            );
        }
        if (linkTo) {
            return (
                <NavLink
                    role="menuitem"
                    to={linkTo}
                    activeClassName=""
                    className={classNames({
                        active: this.isSelected(location, linkTo),
                        "with-badge": notificationCount,
                        "menu-changeEnvironment": changeEnvironmentOption,
                    })}
                    onClick={this.onClickMenu}>
                    {image && <Image src={image} />}
                    {(title && <div className="menu-label">{title}</div>) || <I18n id={titleKey} />}
                    {changeEnvironmentOption && <I18n id="menu.chageEnvironment" />}
                    {notificationCount > 0 && this.renderNotificationCount()}
                </NavLink>
            );
        }
        return (
            <a role="button" className={`${notificationCount && "with-badge"}`} onClick={handleOnClick}>
                {image && <Image src={image} />}
                {title || <I18n id={titleKey} />}
            </a>
        );
    }
}

export default withRouter(connect()(MenuItem));
