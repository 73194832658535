/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, arrayOf, string, shape, number, objectOf, oneOfType } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelectors, actions } from "reducers/desktop";
import { selectors as campaignsSelectors } from "reducers/campaigns";
import { selectors as loanSelectors, actions as loanAction } from "reducers/loans";
import { selectors as loginSelectors } from "reducers/login";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as stringUtils from "util/string";
import * as i18n from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import DraggableList from "pages/_components/DraggableList";
import Header from "pages/_components/header/Header";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import * as Widgets from "pages/desktop/widgets";
import I18n from "pages/_components/I18n";
import Campaigns from "pages/campaigns/Campaigns";
import { Helmet } from "react-helmet";
import { Field, Formik } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import BigModal from "pages/_components/BigModal/BigModal";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import EconomicGroups from "./widgets/economicGroup/EconomicGroups";
import BiometricIdentification from "./widgets/BiometricIdentification/BiometricIdentification";

class Desktop extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        layout: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isDesktop: bool,
        availableWidgets: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isEditable: bool,
        sessionFetching: bool,
        desktopFetching: bool,
        campaigns: arrayOf(
            shape({
                clickable: bool,
                contentType: string,
                creationDate: string,
                creator: string,
                dismissable: bool,
                endDate: string,
                endDateAsString: string,
                expired: bool,
                idCampaign: number,
                image: string,
                imageList: arrayOf(
                    shape({
                        content: string,
                        creationDate: string,
                        fileName: string,
                        idCampaign: number,
                        idImage: number,
                        imageSize: objectOf(oneOfType([number, string])).isRequired,
                    }),
                ).isRequired,
                name: string,
                priority: number,
                length: number,
                startDate: string,
                startDateAsString: string,
                suspended: bool,
                url: string,
            }),
        ).isRequired,
        activeEnvironment: shape({ type: string }).isRequired,
        displayCampaigns: bool,
        loansExpiringSoon: arrayOf(shape({})).isRequired,
        showExpiringLoansModal: bool.isRequired,
        availableFeatures: shape({}).isRequired,
        featuresForWidgets: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: null,
        isEditable: null,
        sessionFetching: null,
        desktopFetching: null,
        displayCampaigns: null,
    };

    componentDidMount = () => {
        const { dispatch, featuresForWidgets } = this.props;
        const widgetFeatureEnabled = featuresForWidgets?.["desktop.loadLayout"];
        if (widgetFeatureEnabled) {
            dispatch(actions.loadLayoutRequest());
        }
    };

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(loanAction.resetLoansExpiringSoon());
    };

    getColumns = (layout) =>
        layout.reduce((obj, item) => {
            const columnValue = obj[item.column] || [];

            return { ...obj, [item.column]: [...columnValue, item] };
        }, {});

    handleClick = (item) => {
        const { dispatch, layout } = this.props;
        const { column, row } = item;

        dispatch(actions.deleteWidget(layout.findIndex((widget) => widget.column === column && widget.row === row)));
    };

    handleIsEditableChange = () => {
        const { dispatch } = this.props;
        dispatch(actions.toggleIsEditable());
    };

    handleItemsPositionChange = (items) => {
        const { dispatch } = this.props;

        dispatch(actions.setLayout(items));
        dispatch(actions.saveLayoutRequest());
    };

    renderEditableButton = () => {
        const { dispatch, availableWidgets, isEditable, featuresForWidgets } = this.props;

        const hasAvailableWidgets = availableWidgets.length > 0;
        const canEdit = featuresForWidgets?.["desktop.saveLayout"];

        if (!canEdit) {
            return <></>;
        }

        return (
            <div className="toolbar-item desktop-edit toolbar-item--fixed-right">
                {!isEditable ? (
                    <Button
                        image="images/editDashboard.svg"
                        label="desktop.editLayout.edit.label"
                        onClick={() => this.handleIsEditableChange()}
                        bsStyle="outline"
                        className="btn-small"
                        id="editDashboardBtn"
                    />
                ) : (
                    <>
                        {hasAvailableWidgets ? (
                            <Formik onSubmit={() => {}}>
                                <Field
                                    idActivity="select-widget"
                                    mode="edit"
                                    component={FormFieldsComponents.Selector}
                                    optionList={availableWidgets.map((widget, index) => ({
                                        id: index,
                                        label: i18n.get(`list.addWidget.${widget.id}`),
                                    }))}
                                    key="select-widget"
                                    name="select-widget"
                                    idField="select-widget"
                                    renderAs="combo"
                                    value=""
                                    labelI18n={i18n.get("desktop.selectWidget")}
                                    isRequired
                                    onChange={({ id }) => {
                                        dispatch(actions.addWidget(id));
                                    }}
                                />
                            </Formik>
                        ) : (
                            <I18n
                                id="desktop.widgets.empty"
                                componentProps={{ className: "desktop-edit-empty-message" }}
                            />
                        )}
                        <Button
                            className="btn widget-close-button btn-small"
                            bsStyle="primary"
                            onClick={() => this.handleIsEditableChange()}>
                            <Image src="images/crossWhite.svg" className="svg-wrapper" />
                            <I18n id="desktop.editLayout.finish.label" />
                        </Button>
                    </>
                )}
            </div>
        );
    };

    renderHeader = () => {
        const {
            isDesktop,
            activeEnvironment: { type },
            featuresForWidgets,
        } = this.props;

        const title = "menu.desktop";
        const widgetFeatureEnabled = featuresForWidgets?.["desktop.loadLayout"];

        if (!isDesktop) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        if (widgetFeatureEnabled && type !== CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            return (
                <Header>
                    <Helmet>
                        <title>{`Banco Mariva - ${i18n.get(title)}`}</title>
                    </Helmet>
                    <div className="toolbar-item view-title">
                        <I18n id={title} component="h1" componentProps={{ className: "visually-hidden" }} />
                    </div>
                    {this.renderEditableButton()}
                </Header>
            );
        }
        return <></>;
    };

    handleCloseModal = () => {
        const { dispatch } = this.props;
        dispatch(loanAction.closeLoansExpiringSoonModal());
    };

    setAliasOrNumOperation = (item) => {
        const aliasOperation = item.productAlias != null ? item.productAlias : item.operationCode;
        return aliasOperation;
    };

    soonToExpireModal = () => {
        const { loansExpiringSoon, showExpiringLoansModal, isDesktop } = this.props;

        return (
            <BigModal
                className="soonToExpireModal"
                handleShowModal={this.handleCloseModal}
                modalImage="images/marivaDetailCheck.svg"
                modalTitle="loans.list.nextExpirations.modal.title"
                showModal={showExpiringLoansModal}
                isMobile={!isDesktop}>
                <DisclaimerContainer maxHeight="175px">
                    {loansExpiringSoon?.map((item) => (
                        <>
                            <div className="data-wrapper soonToExpireData">
                                <div className="data-label">
                                    <span>{`${this.setAliasOrNumOperation(item)} - ${item.descriptionSIOC}`}</span>
                                </div>
                                <div className="data-text">
                                    <FormattedDate date={item.nextExpiration.substring(0, 10)} />
                                    <FormattedAmount currency={item.currency} quantity={item.amount} />
                                </div>
                            </div>
                        </>
                    ))}
                </DisclaimerContainer>
                <SeuoLabel />
            </BigModal>
        );
    };

    renderWidgets() {
        const { isDesktop, featuresForWidgets, layout, isEditable, desktopFetching } = this.props;

        const widgetFeatureEnabled = featuresForWidgets?.["desktop.loadLayout"];

        let widgetsAreAvailable = false;

        Object.keys(featuresForWidgets).forEach((key) => {
            if (key.includes("widgets.") && featuresForWidgets[key]) {
                widgetsAreAvailable = true;
            }
        });

        if (widgetFeatureEnabled && widgetsAreAvailable) {
            return (
                <>
                    {layout.length > 0 && (
                        <DraggableList
                            columns={this.getColumns(layout)}
                            isDragEnabled={isEditable}
                            itemRenderer={this.renderItem}
                            onItemsPositionChange={this.handleItemsPositionChange}
                        />
                    )}

                    {layout.length === 0 && !isEditable && (
                        <NoDataContainer
                            fetching={desktopFetching}
                            withFilters={false}
                            noDataImage="images/coloredIcons/folder-empty.svg"
                            noDataText="widgets.list.empty.title">
                            {isDesktop && (
                                <p>
                                    <I18n id="widgets.list.empty.description" />
                                    <I18n id="desktop.editLayout.edit.label" />.
                                </p>
                            )}
                        </NoDataContainer>
                    )}
                </>
            );
        }

        return (
            <>
                <NoDataContainer
                    fetching={desktopFetching}
                    withFilters={false}
                    noDataImage="images/coloredIcons/setup.svg"
                    noDataText="widgets.disabled.title"
                    customIconStyles={{ height: "55px" }}>
                    <I18n id="widgets.disabled.description" />
                </NoDataContainer>
            </>
        );
    }

    renderItem = (item, { draggableItemProps }) => {
        const { isEditable, isDesktop } = this.props;
        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item?.id)];

        const buttonActionDesc = `${i18n.get("global.close")} ${i18n.get("global.widget")}, ${i18n.get(
            `list.addWidget.${item.id}`,
        )}`;

        return (
            <Widget
                className={classNames({ "draggable-list__item": isEditable })}
                closeButton={
                    isEditable && (
                        <Button className="btn-outline widget-close-button" onClick={() => this.handleClick(item)}>
                            <Image src="images/cross.svg" />
                            <span className="visually-hidden">{buttonActionDesc}</span>
                        </Button>
                    )
                }
                draggableItemProps={draggableItemProps}
                isEditable={isEditable}
                isDesktop={isDesktop}
            />
        );
    };

    render() {
        const {
            activeEnvironment: { type },
            campaigns,
            sessionFetching,
            desktopFetching,
            isDesktop,
            displayCampaigns,
            availableFeatures,
        } = this.props;

        const campaignsFeatureEnabled = !!availableFeatures?.["campaigns"];

        return (
            <>
                <Notification scopeToShow="desktop" />

                {!sessionFetching && !desktopFetching && this.renderHeader()}

                <MainContainer showLoader={sessionFetching || desktopFetching}>
                    <div className="container-left">
                        {campaignsFeatureEnabled && displayCampaigns && campaigns && (
                            <Campaigns section="mariva-desktop-header" isDesktop={isDesktop} />
                        )}

                        {type === CORPORATE_GROUP_ENVIRONMENT_TYPE && <EconomicGroups />}

                        {!desktopFetching && type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && this.renderWidgets()}

                        <BiometricIdentification />
                    </div>
                    {this.soonToExpireModal()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
    isEditable: desktopSelectors.getIsEditale(state),
    campaigns: campaignsSelectors.getCampaigns(state),
    sessionFetching: sessionSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    desktopFetching: desktopSelectors.isFetching(state),
    displayCampaigns: loginSelectors.getDisplayCampaigns(state),
    loansExpiringSoon: loanSelectors.getLoansSoonToExpire(state),
    showExpiringLoansModal: loanSelectors.showExpiringLoansModal(state),
    availableFeatures: sessionSelectors.getAvailableFeatures(state),
    featuresForWidgets: sessionSelectors.getFeaturesForWidgets(state),
});

export default connect(mapStateToProps)(Desktop);
