import React from "react";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import { array, bool, oneOfType } from "prop-types";
import TableHeaderCheckbox from "../_components/TableHeaderCheckbox";

function EcheckTableHeader({ comeFromProcessed, linesDetail }) {
    if (comeFromProcessed) {
        const vouchersToDownload = linesDetail.map((item) => item.eCheckNumber);

        return (
            <>
                <Table.Header>
                    <TableHeaderCheckbox vouchersToDownload={vouchersToDownload} />
                    <Table.HeaderData align="left" styles={{ minWidth: "115px" }}>
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.issuingAccount.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.supplier.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left" styles={{ minWidth: "115px" }}>
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.payTo.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.deferralDate.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.echeck.header.echeckNumber.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="left">
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.character.label" />
                    </Table.HeaderData>
                    <Table.HeaderData align="right">
                        <I18n id="forms.payments.suppliers.detail.checkEcheck.header.amount.label" />
                    </Table.HeaderData>
                    <Table.HeaderData />
                </Table.Header>
            </>
        );
    }

    return (
        <>
            <Table.Header>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.issuingAccount.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.supplier.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.payTo.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.dateOfIssue.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.correlativeNumber.label" />
                </Table.HeaderData>
                <Table.HeaderData align="left">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.character.label" />
                </Table.HeaderData>
                <Table.HeaderData align="right">
                    <I18n id="forms.payments.suppliers.detail.checkEcheck.header.amount.label" />
                </Table.HeaderData>
            </Table.Header>
        </>
    );
}

EcheckTableHeader.propTypes = {
    linesDetail: oneOfType([array]).isRequired,
    comeFromProcessed: bool,
};

EcheckTableHeader.defaultProps = {
    comeFromProcessed: false,
};

export default EcheckTableHeader;
