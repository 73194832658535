import React, { useState } from "react";
import { func, bool, string, number, oneOfType, objectOf, arrayOf, any } from "prop-types";
import { connect } from "react-redux";

import { actions as massPaymentsActions } from "reducers/massPayments";
import * as config from "util/config";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import Checkbox from "pages/_components/Checkbox";
import ProductList from "pages/_components/product/List";
import PaymentItem from "./PaymentItem";

const PaymentList = (props) => {
    const {
        dispatch,
        paymentItems,
        hasMoreData,
        isDesktop,
        fetching,
        mode,
        paymentDeleteHandler,
        paymentEditHandler,
        isFinished,
        idTransaction,
        fetchingDownload,
        fetchMorePayments,
        pageNumber,
        firstFetched,
        frequentRecipients,
        frequentRecipientsForMassive,
        frequentRecipientsPermissions,
    } = props;

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [allBoxesChecked, setAllBoxesChecked] = useState(false);
    const [fileToDownload, setFileToDownload] = useState("");
    const isRenderTransactionColumn = mode === "view";
    const isRenderCheckbox = mode === "view" && isFinished;
    const isPending = paymentItems.find((item) => item.status === "PENDING");

    const multipleDownloadLimit = config.get("transactions.maxLines.to.download", 50);
    const LIST_ID = "forms.pay.transfers.massive.payment.list";

    const paymentCheckBoxHandler = (e, payment) => {
        if (selectedCheckboxes.includes(payment.id)) {
            const index = selectedCheckboxes.indexOf(payment.id);
            selectedCheckboxes.splice(index, 1);
            setSelectedCheckboxes(selectedCheckboxes);
        } else {
            selectedCheckboxes.push(payment.id);
            setSelectedCheckboxes(selectedCheckboxes);
        }

        setAllBoxesChecked(false);
    };

    const checkAllBoxes = () => {
        const isItemChecked = !allBoxesChecked;
        if (isItemChecked) {
            const items = [];
            paymentItems.map((payment) => (items.length < multipleDownloadLimit ? items.push(payment.id) : null));
            setSelectedCheckboxes(items);
        } else {
            setSelectedCheckboxes([]);
        }

        setAllBoxesChecked(isItemChecked);
    };

    const renderCheckboxAll = () => (
        <Table.HeaderData align="left">
            <Checkbox
                key="checkAll"
                name="checkAll"
                value="ALL"
                label=""
                checked={allBoxesChecked}
                onChange={checkAllBoxes}
                hideLabel
            />
        </Table.HeaderData>
    );

    const renderDownloadMultipleButton = () => (
        <>
            <div className="ui-dflex ui-mt-8">
                <Button
                    label="pays.supplier.list.file.query.button.downloadSelection.label"
                    image="images/ui-icons/ui-download.svg"
                    onClick={() => {
                        setFileToDownload("");
                        const selectedPayments = paymentItems.filter((item) => selectedCheckboxes.includes(item.id));
                        dispatch(
                            massPaymentsActions.downloadTicketDetails(idTransaction, "multiple", selectedPayments),
                        );
                        setSelectedCheckboxes([]);
                        setAllBoxesChecked(false);
                    }}
                    bsStyle="outline"
                    loading={fetchingDownload && fileToDownload === ""}
                    block={false}
                />
            </div>
            <I18n
                id="forms.massivePayments.transactions.maxLines.to.download"
                MAXLINES={multipleDownloadLimit}
                componentProps={{ className: "ui-body-small ui-mt-1" }}
            />
        </>
    );

    const renderItem = (payment) => {
        const isFrequentRecipient =
            frequentRecipients.some((recipient) => recipient.destinationIdentifier === payment.cbu) ||
            frequentRecipientsForMassive.some((recipient) => recipient.destinationIdentifier === payment.cbu);

        if (isDesktop) {
            return (
                <Table.Row key={payment.id} renderAs="div" additionalClassName="no-hover">
                    <PaymentItem
                        key={payment.id}
                        payment={payment}
                        isDesktop={isDesktop}
                        mode={mode}
                        paymentDeleteHandler={paymentDeleteHandler}
                        paymentEditHandler={paymentEditHandler}
                        isRenderTransactionColumn={isRenderTransactionColumn}
                        idTransaction={idTransaction}
                        selectedCheckboxes={selectedCheckboxes}
                        isRenderCheckbox={isRenderCheckbox}
                        paymentCheckBoxHandler={paymentCheckBoxHandler}
                        fetchingDownload={fetchingDownload}
                        downloadFileData={[fileToDownload, setFileToDownload]}
                        multipleDownloadLimit={multipleDownloadLimit}
                        isFrequentRecipient={isFrequentRecipient}
                        frequentRecipientsPermissions={frequentRecipientsPermissions}
                    />
                </Table.Row>
            );
        }
        return (
            <PaymentItem
                key={payment.id}
                payment={payment}
                isDesktop={isDesktop}
                mode={mode}
                paymentDeleteHandler={paymentDeleteHandler}
                paymentEditHandler={paymentEditHandler}
                isRenderTransactionColumn={isRenderTransactionColumn}
                idTransaction={idTransaction}
                fetchingDownload={fetchingDownload}
                downloadFileData={[fileToDownload, setFileToDownload]}
            />
        );
    };

    const renderList = (list, renderLoadMore) =>
        (isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        {isRenderCheckbox && renderCheckboxAll()}
                        <Table.HeaderData align="left">
                            <I18n id={`${LIST_ID}.head.name.label`} />
                        </Table.HeaderData>
                        {isRenderTransactionColumn && (
                            <Table.HeaderData align="left">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.transferNumber.label" />
                            </Table.HeaderData>
                        )}
                        <Table.HeaderData align="left">
                            <I18n id={`${LIST_ID}.head.cuit.label`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id={`${LIST_ID}.head.cbu.label`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="left">
                            <I18n id={`${LIST_ID}.head.reference.label`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id={`${LIST_ID}.head.amount.label`} />
                        </Table.HeaderData>
                        {mode !== "preview" && !isPending && <Table.HeaderData />}
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>
                {isRenderCheckbox && renderDownloadMultipleButton()}
                {renderLoadMore()}
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">{list}</div>
                {renderLoadMore()}
            </>
        ));

    return (
        <ProductList
            fetching={fetching}
            items={paymentItems}
            renderItem={renderItem}
            lastPage={!hasMoreData}
            pageNumber={pageNumber}
            onLoadMoreClick={fetchMorePayments}
            firstFetched={firstFetched}
            filters={{}}
            loadMoreText="investments.listFixedTermDeposits.searchMore"
            noDataText={`${LIST_ID}.noData.message`}
            noFiltersDataText=""
            noMoreDataText={`${LIST_ID}.noMoreData.message`}
            isMobile={!isDesktop}>
            {renderList}
        </ProductList>
    );
};

PaymentList.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isFinished: bool.isRequired,
    mode: string.isRequired,
    hasMoreData: bool.isRequired,
    fetching: bool.isRequired,
    paymentDeleteHandler: func.isRequired,
    paymentEditHandler: func.isRequired,
    paymentItems: oneOfType([objectOf(any), arrayOf(any)]).isRequired,
    idTransaction: string,
    fetchingDownload: bool.isRequired,
    fetchMorePayments: func.isRequired,
    pageNumber: number.isRequired,
    firstFetched: bool.isRequired,
    frequentRecipients: arrayOf(any).isRequired,
    frequentRecipientsForMassive: arrayOf(any).isRequired,
    frequentRecipientsPermissions: bool.isRequired,
};

PaymentList.defaultProps = {
    idTransaction: "",
};

export default connect()(PaymentList);
