import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, func, shape } from "prop-types";

import SelectorInput from "pages/_components/fields/SelectorInput";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";

class FormikSelectorInput extends Component {
    static propTypes = {
        form: shape({}).isRequired,
        field: shape({}).isRequired,
        inputProps: shape({}).isRequired,
        selectProps: shape({}).isRequired,
        toggleIsFocused: func.isRequired,
        isFocused: bool.isRequired,
        label: string.isRequired,
        onChange: func.isRequired,
        selectorSize: string,
    };

    static defaultProps = {
        selectorSize: "medium",
    };

    handleBlur = () => {
        const { field, form } = this.props;

        form.setFieldTouched(field.name);
    };

    render() {
        const {
            form,
            field,
            isFocused,
            label,
            inputProps,
            toggleIsFocused,
            selectProps,
            onChange,
            selectorSize,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--composite", `selector-size--${selectorSize}`, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                <FieldLabel idField={field.name} labelText={label} />
                <SelectorInput
                    selectProps={selectProps}
                    inputProps={inputProps}
                    idField={field.name}
                    onChange={onChange}
                    isFocused={isFocused}
                    toggleIsFocused={toggleIsFocused}
                    onBlur={this.handleBlur}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default withFocus(FormikSelectorInput);
