import React, { Component } from "react";
import { Link } from "react-router-dom";
import { element, func, shape, string } from "prop-types";
import Image from "pages/_components/Image";

import * as i18n from "util/i18n";

class UserLink extends Component {
    static propTypes = {
        children: element,
        loggedUser: shape({
            userFullName: string.isRequired,
            email: string.isRequired,
        }).isRequired,
        getLastLoginInfo: func.isRequired,
    };

    static defaultProps = {
        children: null,
    };

    render() {
        const {
            children,
            loggedUser: { userFullName, email },
            getLastLoginInfo,
        } = this.props;

        return (
            <>
                <div className="data-info">
                    <Image src="images/ui-icons/ui-header-profile.svg" className="svg-icon" />
                    <span className="ui-fw-sbold">{userFullName}</span>
                </div>
                {email && email !== "" && (
                    <div className="data-info">
                        <Image src="images/ui-icons/ui-header-email-gray.svg" className="svg-icon" />
                        <span>{email}</span>
                    </div>
                )}
                <div className="data-info">
                    <Image src="images/ui-icons/ui-header-settings.svg" className="svg-icon" />
                    <Link to="/settings">
                        <b>{i18n.get("settings.index")}</b>
                    </Link>
                </div>
                {children}

                <div className="login-info">
                    <div className="data-info">
                        <Image src="images/ui-icons/ui-header-user.svg" className="svg-icon" />
                        <Link to="/userTransactions/list">{i18n.get("settings.userTransactions")}</Link>
                    </div>
                    {getLastLoginInfo()}
                </div>
            </>
        );
    }
}

export default UserLink;
