import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import classNames from "classnames";
import { format } from "date-fns";
import { replace } from "react-router-redux";

import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Statement from "pages/loans/_components/Statement";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import { string, shape, func, bool } from "prop-types";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import NoDataTextContainer from "components/NoDataContainer/NoDataTextContainer";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";

class Statements extends Component {
    static propTypes = {
        loan: shape({}).isRequired,
        statements: shape({}).isRequired,
        dispatch: func.isRequired,
        selectedFilter: string.isRequired,
        isDesktop: bool,
        fetching: bool.isRequired,
        loanCurrency: string.isRequired,
        moreStatements: bool.isRequired,
        pageNumber: string.isRequired,
        loanId: string.isRequired,
        history: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: false,
    };

    state = {
        searchMoreStatementsVisible: false,
    };

    componentDidMount() {}

    fetchMoreStatements = () => {
        const {
            loan: { operationCode, operationBranch, operationOrder },
            pageNumber,
            selectedFilter,
            dispatch,
        } = this.props;
        const filters = { pageNumber: pageNumber + 1, state: selectedFilter };
        const loanData = { operationCode, operationBranch, operationOrder };
        dispatch(loansActions.fetchMoreStatements(loanData, filters));
    };

    handleClick = () => {
        this.fetchMoreStatements();
    };

    handleFiletrClick = () => {
        const { loanId, dispatch } = this.props;
        dispatch(replace(`/loans/${loanId}/filters`));
    };

    handleStatementClick = (statement) => {
        const { dispatch, loan, history } = this.props;
        const { operationBranch, operationCode, operationOrder } = loan;

        dispatch(loansActions.setSelectedStatement(statement));
        history.push(`/loans/${statement.feeNumber}/${operationCode}/${operationBranch}/${operationOrder}/statement`);
    };

    handlePullForMoreInfo = () => {
        this.setState({ searchMoreStatementsVisible: true });
    };

    render() {
        const { searchMoreStatementsVisible } = this.state;
        const { isDesktop, moreStatements, statements, fetching, loanCurrency, loan, loanId } = this.props;
        const { operationBranch, operationCode, operationOrder } = loan;

        const endOfListItem = <NoDataTextContainer noMoreDataText="loans.statements.noMoreMovements" />;

        const noDataMessage = (
            <NoDataContainer
                noDataImage="images/coloredIcons/folder-empty.svg"
                noDataText="loans.statements.none"
                fetching={fetching}
            />
        );

        const buttonMoreData = (
            <Button
                block={!isDesktop}
                onClick={this.handleClick}
                image="images/plusWhite.svg"
                label="loans.statements.moreMovements"
                bsStyle="secondary"
                className="btn-show-more"
            />
        );

        if (!statements) {
            return null;
        }
        const list = statements.map((statement) =>
            (isDesktop ? (
                <Table.Row
                    role="button"
                    renderAs={Link}
                    to={`/loans/${statement.feeNumber}/${operationCode}/${operationBranch}/${operationOrder}/statement`}
                    key={statement.idStatement}
                    className={classNames("table-row", {
                        "table-row-expired": statement.status === "pending" && format(statement.dueDate) < new Date(),
                    })}>
                    <Statement isDesktop loanCurrency={loanCurrency} statement={statement} />
                </Table.Row>
            ) : (
                <Statement
                    handleClick={this.handleStatementClick}
                    isDesktop={false}
                    loanCurrency={loanCurrency}
                    statement={statement}
                />
            )),
        );

        if (!isDesktop) {
            return (
                <>
                    <PageLoading loading={fetching}>
                        {statements.length ? (
                            <div className="generic-movements-wrapper">
                                {list}
                                <SeuoLabel />
                                {statements.length > 0 && moreStatements && buttonMoreData}
                                {statements.length > 0 && !moreStatements && endOfListItem}
                            </div>
                        ) : (
                            noDataMessage
                        )}
                    </PageLoading>
                </>
            );
        }
        return (
            <PageLoading loading={fetching}>
                <>
                    {statements.length ? (
                        <>
                            <Table className="gridTable">
                                <Table.Header>
                                    <Table.HeaderData align="left">
                                        <I18n id="loan.detail.fees.fee" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <I18n id="loan.detail.fees.expiryDate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <I18n id="loan.detail.fees.state" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <I18n id="loan.detail.fees.arrear" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="left">
                                        <I18n id="loan.detail.fees.paymentDate" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id="loan.detail.fees.payment" />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id="loan.detail.fees.amount" />
                                    </Table.HeaderData>
                                    <Table.HeaderData />
                                </Table.Header>
                                <Table.Body>{list}</Table.Body>
                            </Table>

                            <SeuoLabel />

                            {searchMoreStatementsVisible && (
                                <Button
                                    onClick={this.handleFiletrClick}
                                    bsStyle="secondary"
                                    className="btn-show-more"
                                    key="searchStatementsButton"
                                    to={`/loans/${loanId}/filters`}
                                    label="loans.statements.moreMovements"
                                />
                            )}
                        </>
                    ) : (
                        noDataMessage
                    )}
                    {statements.length > 0 && moreStatements && buttonMoreData}
                    {statements.length > 0 && !moreStatements && endOfListItem}
                </>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    statements: loansSelectors.getStatements(state),
    moreStatements: loansSelectors.getMoreStatements(state),
    pageNumber: loansSelectors.getPageNumber(state),
    fetching: loansSelectors.isFetchingDetail(state) || loansSelectors.getFetchingStatements(state),
    loan: loansSelectors.getSelectedLoan(state),
});

export default connect(mapStateToProps)(Statements);
