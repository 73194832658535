import GenericItem from "components/GenericItem/GenericItem";
import AccordionItem from "pages/_components/AccordionItem/AccordionItem";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import { bool, shape } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";

function WithholdingsList({ withholdings, isMobile }) {
    const emptyMessageKey = "accounts.qrChargesCertificates.charges.details.empty.message";
    const withholdingsLabel = i18n.get("accounts.qrChargesCertificates.charges.details.withholdings.label");
    const emptyMessageWithholdings = i18n.get(emptyMessageKey, null, {
        withholdings_commissions: withholdingsLabel.toLowerCase(),
    });

    const renderItem = (item) => {
        const { uuid, taxDescription, taxpayerDescription, taxableBase, aliquot, retention } = item;
        return isMobile ? (
            <GenericItem hoverBehavior={false}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.tax" />
                    </div>
                    <span className="data-text">{taxDescription}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.regime" />
                    </div>
                    <span className="data-text">{taxpayerDescription}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.taxBase" />
                    </div>
                    <span className="data-text">{taxableBase}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.aliquot" />
                    </div>
                    <span className="data-text">{aliquot}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.amount" />
                    </div>
                    <FormattedAmount maximumDecimals="6" currency="ARS" quantity={retention} />
                </div>
            </GenericItem>
        ) : (
            <Table.Row key={uuid} additionalClassName="no-hover">
                <Table.Data align="left">
                    <span className="data-text">{taxDescription}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{taxpayerDescription}</span>
                </Table.Data>
                <Table.Data align="right">
                    <span className="data-text">{taxableBase}</span>
                </Table.Data>
                <Table.Data align="right">
                    <span className="data-text">{aliquot}</span>
                </Table.Data>
                <Table.Data align="right">
                    <FormattedAmount currency="ARS" quantity={retention} />
                </Table.Data>
            </Table.Row>
        );
    };

    const renderList = (list, renderLoadMore) => {
        const isEmpty = list.length === 0;

        if (!isEmpty) {
            return isMobile ? (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                </>
            ) : (
                <>
                    <Table className="gridTable">
                        <Table.Header>
                            <Table.HeaderData align="left" styles={{ maxWidth: "100px" }}>
                                <I18n id="tableHeader.tax" />
                            </Table.HeaderData>

                            <Table.HeaderData align="left">
                                <I18n id="tableHeader.regime" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="tableHeader.taxBase" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="tableHeader.aliquot" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="tableHeader.amount" />
                            </Table.HeaderData>
                        </Table.Header>

                        <Table.Body>{list}</Table.Body>
                    </Table>
                    <SeuoLabel />
                    {renderLoadMore()}
                </>
            );
        }
        return <></>;
    };

    return (
        <AccordionItem customTitle={<h4>{withholdingsLabel}</h4>} isOpened additionalClassName="accordion-charges-qr">
            <ProductList
                items={withholdings}
                renderItem={renderItem}
                loadMoreText="global.label.loadMore"
                noDataText={emptyMessageWithholdings}
                isMobile={isMobile}>
                {renderList}
            </ProductList>
        </AccordionItem>
    );
}

WithholdingsList.propTypes = {
    isMobile: bool.isRequired,
    withholdings: shape([]),
};
WithholdingsList.defaultProps = {
    withholdings: [],
};

export default WithholdingsList;
