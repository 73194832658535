import React from "react";
import { string } from "prop-types";

const FieldError = (props) => {
    const { error } = props;

    return (
        error && (
            <div className="form-group-error">
                <span>{error}</span>
            </div>
        )
    );
};

FieldError.propTypes = {
    error: string.isRequired,
};

export default FieldError;
