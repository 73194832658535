import React, { Component } from "react";
import { arrayOf, shape, string } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";
import I18n from "pages/_components/I18n";
import { selectors as loginSelectors } from "reducers/login";
import { REGION_USA, RETAIL_ENVIRONMENT_TYPE } from "constants.js";
import { connect } from "react-redux";
import * as utilDownload from "util/download";

import { selectors as sessionSelectors } from "reducers/session";

class AccountOpening extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
        activeRegion: string.isRequired,
        transferReceipt: arrayOf(shape({ fileNameKey: string, size: string })).isRequired,
        activeEnvironment: shape({ type: string }).isRequired,
    };

    handleClickDownloadPdf = (fileNameKey) => {
        utilDownload.downloadLink(fileNameKey, "/pdf/overdraft.pdf");
    };

    render() {
        const {
            transaction,
            activeRegion,
            transferReceipt,
            transaction: {
                data: { accountType },
            },
            activeEnvironment: { type },
        } = this.props;
        const isUSA = REGION_USA === activeRegion;

        let shouldShowDownload = true;
        if (accountType[0] !== "CC" || !isUSA || type !== RETAIL_ENVIRONMENT_TYPE) {
            shouldShowDownload = false;
        }

        return (
            <section className="container--fluid flex-grow">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                            <div className="media-object ">
                                {
                                    <figure>
                                        <TransactionStatus
                                            iconFidelity="ticket"
                                            idTransactionStatus={transaction.idTransactionStatus}
                                        />
                                    </figure>
                                }
                                {
                                    <div className="media-object--text">
                                        <p className="text-lead">
                                            <I18n
                                                id={`forms.transaction.ticket.status.${transaction.idTransactionStatus}`}
                                            />
                                        </p>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                    {shouldShowDownload && (
                        <div className="form-group">
                            {transferReceipt.map((doc) => (
                                <button
                                    id="btn-downloadOverdraftPDF"
                                    type="button"
                                    className="btn btn-asLink color-red"
                                    onClick={() => this.handleClickDownloadPdf(doc.fileNameKey)}>
                                    <I18n id="forms.openingAccount.downloadOverdraftTandC" />
                                </button>
                            ))}
                        </div>
                    )}
                </Grid>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
    transferReceipt: [{ fileNameKey: "overdraft.pdf", size: "345" }],
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AccountOpening);
