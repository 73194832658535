import React from "react";
import { bool, func, oneOf, shape, string } from "prop-types";
import Image from "./Image";
import I18n from "./I18n";

function FileUploadButton({ id, onChange, block, image, label, defaultLabelText, bsStyle, fileInputRef }) {
    return (
        <>
            <input
                id={id}
                ref={fileInputRef}
                type="file"
                className="btn"
                style={{ display: "none" }}
                onChange={onChange}
            />

            <label htmlFor={id} className={`btn btn-${bsStyle} ${block ? "btn-block" : ""}`}>
                {image && <Image src={image} className="svg-icon" />}
                <I18n id={label} defaultValue={defaultLabelText} />
            </label>
        </>
    );
}

FileUploadButton.propTypes = {
    id: string,
    onChange: func.isRequired,
    block: bool,
    image: string,
    label: string.isRequired,
    defaultLabelText: string,
    bsStyle: oneOf(["success", "warning", "danger", "info", "default", "primary", "link", "outline", "secondary"]),
    fileInputRef: shape({}).isRequired,
};

FileUploadButton.defaultProps = {
    id: "myFileUpload",
    block: false,
    defaultLabelText: "Texto for defecto",
    image: null,
    bsStyle: "primary",
};

export default FileUploadButton;
