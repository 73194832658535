import React from "react";
import { connect } from "react-redux";
import { array, bool, func, oneOfType, shape } from "prop-types";
import GenericItem from "components/GenericItem/GenericItem";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Table from "pages/_components/Table";
import Button from "../../_components/Button";
import { selectors } from "../../../reducers/investments";
import { selectors as sessionSelectors } from "../../../reducers/session";
import FormattedAmount from "../../_components/FormattedAmount";
import I18n from "../../_components/I18n";
import ProductList from "../../_components/product/List";
import Dropdown from "../../_components/Dropdown";
import Loader from "../../_components/Loader";
import * as config from "../../../util/config";

const Holding = ({ mutualFunds, isMobile, filters, isFetching, handleOnClick, isFirstFetched }) => {
    const moneyMarketId = config.get("mutualFunds.moneyMarket.identifier", "1");
    const moneyMarketDecimal = config.get("mutualFunds.defaultDecimals.moneyMarket", 8);
    const otherFundDecimal = config.get("mutualFunds.defaultDecimals.otherFunds", 6);
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const { date } = filters;
    const showRescueButton = date === "" || new Date(date).setHours(0, 0, 0, 0) === todaysDate;

    const renderItem = (item) => {
        const { id, name, balance, typeDescription, currencyId, number } = item;
        const defaultDecimals = number === moneyMarketId ? moneyMarketDecimal : otherFundDecimal;
        return isMobile ? (
            <GenericItem
                key={id}
                hoverBehavior={false}
                options={
                    <Dropdown
                        pullRight
                        image="images/ui-icons/ui-dropdown-mobile.svg"
                        bsStyle="btn-link"
                        buttonClass="btn-only-icon">
                        <Button
                            key="investments.mutualFund.actions.subscription"
                            label="investments.mutualFund.actions.subscription"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => handleOnClick(item, "Subscribe")}
                        />
                        {showRescueButton ? (
                            <Button
                                key="investments.mutualFund.actions.rescue"
                                label="investments.mutualFund.actions.rescue"
                                className="dropdown__item-btn"
                                bsStyle="link"
                                onClick={() => handleOnClick(item, "Rescue")}
                            />
                        ) : (
                            <></>
                        )}

                        <Button
                            key="investments.mutualFund.actions.queryMovements"
                            label="investments.mutualFund.actions.queryMovements"
                            onClick={() => handleOnClick(item, "Movements")}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                }>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.name" />
                    </div>
                    <span className="data-text">{`${name} - ${typeDescription}`}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.quantity" />
                    </div>
                    <FormattedAmount
                        hideCurrency
                        quantity={balance.total}
                        customMinimumDecimals={6}
                        customMaximumDecimals={6}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.lastValue" />
                    </div>
                    <FormattedAmount
                        customMinimumDecimals={defaultDecimals}
                        customMaximumDecimals={defaultDecimals}
                        currency={currencyId}
                        quantity={balance.lastValue}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.lastValueDate" />
                    </div>
                    <span className="data-text">{balance.lastValueDate}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.holding" />
                    </div>
                    <FormattedAmount currency="ARS" quantity={balance.holding} />
                </div>
            </GenericItem>
        ) : (
            <div className="table-row no-hover" key={id}>
                <div className="table-data">
                    <span className="data-text">{`${name} - ${typeDescription}`}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount
                        hideCurrency
                        quantity={balance.total}
                        customMinimumDecimals={6}
                        customMaximumDecimals={6}
                    />
                </div>
                <div className="table-data">
                    <FormattedAmount
                        customMinimumDecimals={defaultDecimals}
                        customMaximumDecimals={defaultDecimals}
                        currency={currencyId}
                        quantity={balance.lastValue}
                    />
                </div>
                <div className="table-data text-center">
                    <span className="data-text">{balance.lastValueDate}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount maximumDecimals="6" currency={currencyId} quantity={balance.holding} />
                </div>
                <div className="table-data table-data-icon">
                    <Dropdown
                        image="images/ui-icons/ui-dropdown.svg"
                        label=""
                        bsStyle="link"
                        className="btn-small"
                        pullRight>
                        <Button
                            key="investments.mutualFund.actions.subscription"
                            label="investments.mutualFund.actions.subscription"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => handleOnClick(item, "Subscribe")}
                        />
                        {showRescueButton ? (
                            <Button
                                key="investments.mutualFund.actions.rescue"
                                label="investments.mutualFund.actions.rescue"
                                className="dropdown__item-btn"
                                bsStyle="link"
                                onClick={() => handleOnClick(item, "Rescue")}
                            />
                        ) : (
                            <></>
                        )}
                        <Button
                            key="investments.mutualFund.actions.queryMovements"
                            label="investments.mutualFund.actions.queryMovements"
                            className="dropdown__item-btn"
                            onClick={() => handleOnClick(item, "Movements")}
                            bsStyle="link"
                        />
                        <Button
                            key="investments.mutualFund.actions.supplementaryInformation"
                            label="investments.mutualFund.actions.supplementaryInformation"
                            onClick={() => handleOnClick(item, "supplementaryInformation")}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                </div>
            </div>
        );
    };

    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    {renderLoadMore()}
                    <DisclaimerContainer
                        tableFooter="investments.mutualFund.tableFooter.label"
                        disclaimer="investments.mutualFund.disclaimer.label"
                        maxHeight="270px"
                        textSmall
                    />
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="investments.mutualFund.tableHeader.name" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="investments.mutualFund.tableHeader.quantity" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="investments.mutualFund.tableHeader.lastValue" />
                        </Table.HeaderData>

                        <Table.HeaderData align="center">
                            <I18n id="investments.mutualFund.tableHeader.lastValueDate" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right">
                            <I18n id="investments.mutualFund.tableHeader.holding" />
                        </Table.HeaderData>

                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>

                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer
                    tableFooter="investments.mutualFund.tableFooter.label"
                    disclaimer="investments.mutualFund.disclaimer.label"
                    textSmall
                    maxHeight="210px"
                />
            </>
        );
    };

    return (
        <>
            {isFetching && isFirstFetched ? (
                <Loader />
            ) : (
                <ProductList
                    firstFetched={isFirstFetched}
                    fetching={isFetching}
                    items={mutualFunds}
                    renderItem={renderItem}
                    filters={filters}
                    loadMoreText="investments.mutualFund.movements.searchMore"
                    noDataText="investments.mutualFund.notFound.message"
                    noFiltersDataText="investments.mutualFund.filter.notFound.message"
                    isMobile={isMobile}
                    onLoadMoreClick={() => {}}>
                    {renderList}
                </ProductList>
            )}
        </>
    );
};

Holding.propTypes = {
    mutualFunds: oneOfType([array]).isRequired,
    isMobile: bool.isRequired,
    filters: shape({}).isRequired,
    isFetching: bool.isRequired,
    handleOnClick: func.isRequired,
    isFirstFetched: bool.isRequired,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
    isFetching: selectors.getFetching(state),
    mutualFunds: selectors.mutualFunds(state),
    filters: selectors.getFilters(state),
    toggleState: selectors.toggleState(state),
    isFirstFetched: selectors.getFirstFetched(state),
});

export default connect(mapStateToProps)(Holding);
