import { takeLatest, put, call } from "redux-saga/effects";

import * as creditCardMovementDetails from "middleware/creditCardMovementDetails";
import { actions as notificationActions } from "reducers/notification";
import { types, actions } from "reducers/creditCardMovementDetails";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailRequest),
    takeLatest(types.UPDATE_NOTE_REQUEST, updateNoteRequest),
];

export default sagas;

function* detailRequest({ idCreditCard, idStatement }) {
    const response = yield call(creditCardMovementDetails.detailRequest, idCreditCard, idStatement);

    if (response.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardMovementDetail",
            ]),
        );
    } else {
        const { statement } = response.data.data;

        yield put(actions.detailSuccess(statement));
    }
}

function* updateNoteRequest({ idProduct, idStatement, note }) {
    const response = yield call(creditCardMovementDetails.updateNoteRequest, idProduct, idStatement, note);

    if (response.type === "W") {
        yield put(actions.updateNoteFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardMovementDetail",
            ]),
        );
    } else {
        yield put(actions.updateNoteSuccess(note));
        yield put(
            notificationActions.showNotification(i18n.get("creditCards.movement.detail.noteSaved"), "success", [
                "creditCardMovementDetail",
            ]),
        );
    }
}
