import React, { Component, Fragment } from "react";
import { string, node, oneOfType } from "prop-types";

import Image from "pages/_components/Image";

class GeneralMsg extends Component {
    static propTypes = {
        title: oneOfType([string, node]),
        description: oneOfType([string, node]),
        imagePath: string,
        pageTitle: node,
        callToAction: node,
    };

    static defaultProps = {
        title: null,
        description: null,
        imagePath: null,
        pageTitle: null,
        callToAction: null,
    };

    render() {
        const { title, description, imagePath, callToAction, pageTitle } = this.props;

        return (
            <Fragment>
                {pageTitle}
                <div className="general-message">
                    {imagePath && <Image src={imagePath} className="svg-medium-icon" />}
                    {title && <p className="icon-message-page--title">{title}</p>}
                    {description && <p className="text-center">{description}</p>}
                    {callToAction}
                </div>
            </Fragment>
        );
    }
}

export default GeneralMsg;
