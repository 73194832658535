import React from "react";
import { shape, string } from "prop-types";

import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import * as maskUtils from "util/mask";

import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { Field } from "formik";

const RecipientInputs = (props) => {
    const { genericProps, recipientData, data, idForm } = props;
    const currencyTypesValues = config.get("frequentDestination.currencyTypes").split("|");
    const currencyTypes = currencyTypesValues.map((value) => ({
        id: value,
        label: i18nUtils.get(`forms.frequentDestination.create.currencyOptions.${value}.label`),
    }));

    return (
        <>
            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="recipientName"
                name="recipientName"
                idField="recipientName"
                maxLength={15}
                mode="preview"
                value={recipientData.clientDescription}
                idForm={idForm}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reference"
                name="reference"
                idField="reference"
                maxLength={15}
                value={data?.reference || data?.recipientName}
                idForm={idForm}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="cuit"
                name="cuit"
                idField="cuit"
                mask={maskUtils.cuitMask}
                mode="preview"
                value={recipientData.cuit}
                idForm={idForm}
                maxLength={12}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="entity"
                name="entity"
                idField="entity"
                mode="preview"
                value={recipientData.bank}
                idForm={idForm}
                maxLength={50}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="number"
                name="number"
                idField="number"
                maxLength={20}
                mode="preview"
                value={recipientData.accountDescription}
                idForm={idForm}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                optionList={currencyTypes}
                key="currency"
                name="currency"
                idField="currency"
                renderAs="combo"
                mode="preview"
                value={recipientData.currency ? recipientData.currency : null}
                idForm={idForm}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="aliascbu"
                name="aliascbu"
                idField="aliascbu"
                mode="preview"
                value={recipientData.alias}
                idForm={idForm}
                maxLength={150}
            />

            <div className="hidden-field">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="destinationType"
                    name="destinationType"
                    idField="destinationType"
                    mode="preview"
                    value={recipientData.detinationType}
                    idForm={idForm}
                    maxLength={150}
                />
            </div>

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="destinationIdentifier"
                name="destinationIdentifier"
                idField="destinationIdentifier"
                mode="preview"
                value={recipientData.cbu}
                idForm={idForm}
                maxLength={150}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="email"
                name="email"
                idField="email"
                idForm={idForm}
                maxLength={50}
            />
        </>
    );
};

RecipientInputs.propTypes = {
    genericProps: shape({}).isRequired,
    recipientData: shape({}).isRequired,
    data: shape({}),
    idForm: string.isRequired,
};

RecipientInputs.defaultProps = {
    data: {},
};

export default RecipientInputs;
