import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "reducers/types/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormTransition from "../../_components/FormTransition";
import Alert from "../../../_components/Alert/Alert";

const ID_ACTIVITY_PRE = "echeqs.depositEcheq.pre";
const ID_ACTIVITY = "echeqs.depositEcheq.send";
const ID_FORM = "forms.echeqs.depositEcheq";

const DepositEcheq = (props) => {
    const { echeq, data, dispatch, transaction, mode, location, fromBackoffice, preData, currentLang } = props;
    const { idTransactionStatus, idTransaction } = transaction;
    const listEnabledAccounts = preData?.listEnabledAccounts || data?.listEnabledAccounts || [];
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = preData || {};
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        cheque_tipo,
        beneficiario_nombre,
        cheque_caracter,
        tenencia_beneficiario_documento,
        tenencia_beneficiario_documento_tipo,
        fecha_emision,
        emisor_cuenta,
        emisor_banco_codigo,
        emisor_sucursal_codigo,
        emisor_sucursal_cp,
    } = echeq;

    const hasMandate = echeq.mandatos?.length > 0;

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
            if (!fromBackoffice) {
                const formData = {};
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            }
        }
    }, [dispatch, location]);

    const listEnabledAccountsOptions = {
        options: listEnabledAccounts.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
        })),
    };

    const handleChangeAccount = (option, field, setFieldValue) => {
        const accountSelected = listEnabledAccounts?.find((acc) => acc.idProduct === option);
        setFieldValue(field, accountSelected);
    };

    const renderFields = (setFieldValue) => (
        <>
            {mode === "edit" && (
                <div className="form-text-info ui-mb-7">
                    <I18n id={`${ID_FORM}.message.label`} />
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.dataEcheqTitle.label`} componentProps={{ className: "data-label" }} />
            </div>

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.idEcheq.label`} />
                </span>
                <span className="data-text">{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} />
                </span>
                <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqType.label`} />
                </span>
                <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqCharacter.label`} />
                </span>
                <span className="data-text">{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.CUIT/CUILIssuer.label`} />
                </span>
                <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.issuerName.label`} />
                </span>
                <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                </span>
                <span className="data-text">{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.state.label`} />
                    </span>
                    <span className="data-text">{data?.estado || estado}</span>
                </div>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.amount.label`} />
                </span>
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.paymentDate.label`} />
                </span>
                <FormattedDate date={data?.fecha_pago || fecha_pago} />
            </div>

            {mode === "edit" ? (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        data={listEnabledAccountsOptions}
                        key="selectedAccount"
                        name="selectedAccount"
                        idField="selectedAccount"
                        handleChange={(e) => handleChangeAccount(e, "selectedAccount", setFieldValue)}
                    />
                </>
            ) : (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.selectedAccount.label`} />
                        </span>
                        <span className="data-text">{data?.selectedAccount?.label || "-"}</span>
                    </div>
                    {mode === "preview" && (
                        <>
                            <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mt-7 ui-mb-0">
                                <I18n id={`${ID_FORM}.warning.message`} />
                            </Alert>
                        </>
                    )}
                </>
            )}
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id,
            cheque_numero,
            cheque_tipo,
            cheque_caracter,
            emisor_cuit,
            emisor_razon_social,
            beneficiario_nombre,
            estado,
            amount: {
                quantity: monto,
                currency: emisor_moneda,
            },
            fecha_pago,
            tenencia_beneficiario_documento,
            tenencia_beneficiario_documento_tipo,
            fecha_emision,
            emisor_cuenta,
            emisor_banco_codigo,
            emisor_sucursal_codigo,
            emisor_sucursal_cp,
            hasMandate,
            mandatario_nombre: hasMandate ? echeq.mandatos?.[0]?.mandatario_nombre : null,
            mandatario_documento: hasMandate ? echeq.mandatos?.[0]?.mandatario_documento : null,
            mandante_nombre: hasMandate ? echeq.mandatos?.[0]?.mandante_nombre : null,
            mandante_documento: hasMandate ? echeq.mandatos?.[0]?.mandante_documento : null,
            emisor_documento_tipo: "CUIT",
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

DepositEcheq.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};

DepositEcheq.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(DepositEcheq);
