import React, { useEffect, useState } from "react";
import { selectors as echeqsSelector } from "reducers/echeqs";
import { actions as formActions, selectors as formSelector } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import * as config from "util/config";
import * as stringUtils from "util/string";
import FormTransition from "../../_components/FormTransition";
import I18n from "../../../_components/I18n";
import FormattedAmount from "../../../_components/FormattedAmount";
import FormattedDate from "../../../_components/FormattedDate";
import typeSelector from "../../../../reducers/types/form";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import Alert from "../../../_components/Alert/Alert";
import DisclaimerContainer from "../../../../components/DisclaimerContainer/DisclaimerContainer";
import * as i18nUtils from "../../../../util/i18n";

const ID_ACTIVITY = "echeqs.putIntoCustody.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");
const CustodyRequest = (props) => {
    const { mode, dispatch, currentLang, fromBackoffice, transaction, data, echeq, location } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const cutOffTime = config.get("echeq.custody.cut_off_time");
    const preData = data?.preData || props?.preData;

    const accountsAuthorized =
        transaction?.data && mode !== "edit" ? data?.accountsAuthorized : preData?.accountsAuthorized;

    const { idTransaction, idTransactionStatus } = transaction;

    const echeqSelected = Object.keys(echeq).length > 0 ? echeq : data?.echeq;

    const [accountSelected, setAccountSelected] = useState(null);

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    useEffect(() => {
        if (mode === "edit") {
            if (echeqSelected && Object.keys(echeqSelected).length > 0) {
                dispatch(formActions.preForm({ idActivity: "echeqs.putIntoCustody.pre", formData: {} }));
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch({
                type: typeSelector.PRE_FORM_SUCCESS,
            });
        }
    }, [dispatch, location]);

    useEffect(() => {
        if (Object.keys(data)?.length > 0 && data?.depositAccount?.[0]) {
            const accountFiltered = accountsAuthorized.find((acc) => acc.cbu === data.depositAccount[0]);
            setAccountSelected(accountFiltered);
        }
    }, [data]);

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => null;

    const renderFields = (setFieldValue) => {
        const handleChangeAccount = (account) => {
            const accountFiltered = accountsAuthorized.find((acc) => acc.cbu === account?.id);
            setAccountSelected(accountFiltered);
            setFieldValue("accountLabel", accountFiltered.label);
        };

        return (
            <>
                {mode === "edit" && (
                    <div className="form-text-info">
                        <I18n id={`${ID_FORM}.message.text`} />
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label ui-fw-bold" }} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_id}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.checkNumber.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_numero}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                    <I18n id={`forms.echeqs.type.${echeqSelected?.cheque_tipo}`} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.cheque_caracter}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.issuerDocument.label`} componentProps={{ className: "data-label" }} />
                    <span>{stringUtils.formatCuit(echeqSelected?.emisor_cuit)}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.issuerName.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.emisor_razon_social}</span>
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.beneficiario_nombre}</span>
                </div>
                {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                        <span>{echeqSelected?.estado}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                    <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
                </div>

                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                    <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
                </div>

                {mode === "edit" ? (
                    <>
                        <hr className="ui-mt-7 ui-mb-7" />
                        <div className="form-text-info">
                            <I18n id={`${ID_FORM}.depositAccount.info.message`} />
                        </div>

                        <Field
                            {...genericProps}
                            name="depositAccount"
                            idField="depositAccount"
                            key="depositAccount"
                            optionList={accountsAuthorized.map((account) => ({
                                id: account?.cbu,
                                label: account?.label,
                            }))}
                            value={accountSelected?.cbu ? [accountSelected?.cbu] : ""}
                            component={FormFieldsComponents.Selector}
                            renderAs="combo"
                            onChange={(account) => handleChangeAccount(account)}
                        />
                    </>
                ) : (
                    <>
                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.depositAccount.label`} componentProps={{ className: "data-label" }} />
                            <span>{accountSelected?.label}</span>
                        </div>
                        {mode === "preview" && (
                            <>
                                <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mt-7 ui-mb-0">
                                    {i18nUtils.get(`${ID_FORM}.warning.message`, null, { cutOffTime })}
                                </Alert>
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="134px">
                    <I18n id={`${ID_FORM}.termsAndConditions.title`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
                additionalClassName="ui-mt-7"
                mode="edit"
            />
        </>
    );

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            depositAccount: data?.depositAccount,
            echeq: echeqSelected,
            cheque_id: data?.cheque_id || echeqSelected?.cheque_id,
            cheque_numero: data?.cheque_numero || echeqSelected?.cheque_numero,
            fecha_emision: data?.fecha_emision || echeqSelected?.fecha_emision,
            cheque_tipo: data?.cheque_tipo || echeqSelected?.cheque_tipo,
            amount: data?.amount || {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
            emisor_cuenta: data?.emisor_cuenta || echeqSelected?.emisor_cuenta,
            emisor_razon_social: data?.emisor_razon_social || echeqSelected?.emisor_razon_social,
            emisor_cuit: data?.emisor_cuit || echeqSelected?.emisor_cuit,
            emisor_banco_codigo: data?.emisor_banco_codigo || echeqSelected?.emisor_banco_codigo,
            emisor_sucursal_codigo: data?.emisor_sucursal_codigo || echeqSelected?.emisor_sucursal_codigo,
            emisor_sucursal_cp: data?.emisor_sucursal_cp || echeqSelected?.emisor_sucursal_cp,
            fecha_pago: data?.fecha_pago || echeqSelected?.fecha_pago,
            beneficiario_nombre: data?.beneficiario_nombre || echeqSelected?.tenencia_beneficiario_nombre,
            beneficiario_documento_tipo:
                data?.beneficiario_documento_tipo || echeqSelected?.tenencia_beneficiario_documento_tipo,
            beneficiario_documento: data?.beneficiario_documento || echeqSelected?.tenencia_beneficiario_documento,
            cheque_caracter: data?.cheque_caracter || echeqSelected?.cheque_caracter,
            accountsAuthorized: accountsAuthorized || [],
            accountLabel: data?.accountLabel,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        renderEditableInPreview,
        handleClickDownload,
        handleBack,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

CustodyRequest.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

CustodyRequest.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    useDefaultSubmit: bool,
};

const mapStateToProps = (state) => ({
    echeq: echeqsSelector.getSelectedEcheq(state),
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    fetching: formSelector.getFetching(state),
});

export default compose(connect(mapStateToProps), withRouter)(CustodyRequest);
