import GenericItem from "components/GenericItem/GenericItem";
import AccordionItem from "pages/_components/AccordionItem/AccordionItem";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import { bool, shape } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";

function CommissionsList({ commissions, isMobile }) {
    const emptyMessageKey = "accounts.qrChargesCertificates.charges.details.empty.message";
    const commissionsLabel = i18n.get("accounts.qrChargesCertificates.charges.details.commissions.label");
    const emptyMessageCommissions = i18n.get(emptyMessageKey, null, {
        withholdings_commissions: commissionsLabel.toLowerCase(),
    });

    const renderItem = (item) => {
        const { type, value, percentage } = item;
        return isMobile ? (
            <GenericItem hoverBehavior={false}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.type" />
                    </div>
                    <span className="data-text">{type}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.amount" />
                    </div>
                    <FormattedAmount currency="ARS" quantity={value} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.percentage" />
                    </div>
                    <span className="data-text">{percentage}</span>
                </div>
            </GenericItem>
        ) : (
            <Table.Row key={`${type}-${percentage}`} additionalClassName="no-hover">
                <Table.Data align="left">
                    <span className="data-text">{type}</span>
                </Table.Data>
                <Table.Data align="left">
                    <FormattedAmount className="data-amount-left" currency="ARS" quantity={value} />
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text">{percentage}</span>
                </Table.Data>
            </Table.Row>
        );
    };

    const renderList = (list, renderLoadMore) => {
        const isEmpty = list.length === 0;

        if (!isEmpty) {
            return isMobile ? (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                </>
            ) : (
                <>
                    <Table className="gridTable">
                        <Table.Header>
                            <Table.HeaderData align="left">
                                <I18n id="tableHeader.type" />
                            </Table.HeaderData>

                            <Table.HeaderData align="left">
                                <I18n id="tableHeader.amount" />
                            </Table.HeaderData>
                            <Table.HeaderData align="left">
                                <I18n id="tableHeader.percentage" />
                            </Table.HeaderData>
                        </Table.Header>

                        <Table.Body>{list}</Table.Body>
                    </Table>
                    {renderLoadMore()}
                </>
            );
        }
        return <></>;
    };

    return (
        <AccordionItem customTitle={<h4>{commissionsLabel}</h4>} isOpened additionalClassName="accordion-charges-qr">
            <ProductList
                items={commissions}
                renderItem={renderItem}
                loadMoreText="global.label.loadMore"
                noDataText={emptyMessageCommissions}
                isMobile={isMobile}>
                {renderList}
            </ProductList>
        </AccordionItem>
    );
}

CommissionsList.propTypes = {
    isMobile: bool.isRequired,
    commissions: shape([]),
};
CommissionsList.defaultProps = {
    commissions: [],
};

export default CommissionsList;
