import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as checksSelectors, actions as checksActions } from "reducers/checks";
import { bool, func, string, arrayOf, number, shape, objectOf, any } from "prop-types";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Loader from "pages/_components/Loader";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Table from "pages/_components/Table";
import Check from "./Check";

class List extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        pageNumber: number.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        firstFetched: bool.isRequired,
        checks: arrayOf(shape({})).isRequired,
        checkType: string.isRequired,
        moreChecks: bool.isRequired,
        idActivity: string.isRequired,
        history: objectOf(any).isRequired,
        isFetchingChecks: bool.isRequired,
    };

    state = {
        showFilters: false,
    };

    fetchMoreChecks = () => {
        const { dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(checksActions.loadMoreChecksRequest(filters));
    };

    handleCheckClick = (check, link) => {
        const { dispatch, history } = this.props;
        dispatch(checksActions.setSelectedCheck(check));
        history.push(link);
    };

    handleFiltersClick = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    renderItem = (check) => {
        const { isDesktop, idActivity, checkType } = this.props;
        return isDesktop ? (
            <div
                className="table-row"
                role="button"
                key={check.checkNumber}
                onClick={() => this.handleCheckClick(check, `/checks/${check.checkNumber}/detail`)}>
                <Check isDesktop={isDesktop} check={check} idActivity={idActivity} checkType={checkType} />
            </div>
        ) : (
            <Check
                key={check.checkNumber}
                isDesktop={isDesktop}
                check={check}
                idActivity={idActivity}
                checkType={checkType}
                onClick={() => this.handleCheckClick(check, `/checks/${check.checkNumber}/detail`)}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, idActivity, checkType } = this.props;

        return isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.checkNumber.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left" styles={{ minWidth: "125px" }}>
                            <I18n id={`${idActivity}.account.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.${checkType}.bank.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.depositDate.label`} />
                        </Table.HeaderData>

                        {checkType === "emitted" && (
                            <Table.HeaderData align="left">
                                <I18n id={`${idActivity}.${checkType}.date.label`} />
                            </Table.HeaderData>
                        )}

                        <Table.HeaderData align="right">
                            <I18n id={`${idActivity}.amount.label`} />
                        </Table.HeaderData>

                        {checkType === "received" && (
                            <Table.HeaderData align="left">
                                <I18n id={`${idActivity}.${checkType}.date.label`} />
                            </Table.HeaderData>
                        )}

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.state.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData />
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const { firstFetched, moreChecks, checks, filters, isFetchingChecks, checkType, isMobile } = this.props;

        const defaultFilters = {
            dateFrom: defaultDateFromList().checks.dateFrom,
            dateTo: defaultDateFromList().checks.dateTo,
        };

        // Evaluamos si hay filtros aplicados, enviamos la fecha por defecto para que, en caso que sea igual a la fecha aplicada, sea excluido como filtro.
        const withFilters = getFiltersStatus({ filters, defaultFilters });

        let noMoreDataText;
        const noDataText = `checks.listChecks.${checkType}.empty.label`;

        if (withFilters) {
            noMoreDataText = `checks.listChecks.${checkType}.noMoreChecks.withFilters`;
        } else {
            noMoreDataText = `checks.listChecks.${checkType}.noMoreChecks.default`;
        }

        return (
            <>
                {isFetchingChecks && firstFetched ? (
                    <Loader />
                ) : (
                    <ProductList
                        fetching={isFetchingChecks}
                        items={checks}
                        renderItem={this.renderItem}
                        lastPage={!moreChecks}
                        onLoadMoreClick={this.fetchMoreChecks}
                        firstFetched={firstFetched}
                        filters={filters || {}}
                        noMoreDataText={noMoreDataText}
                        loadMoreText="checks.listChecks.searchMoreChecks"
                        noDataText={noDataText}
                        noFiltersDataText="checks.listChecks.filters.none"
                        defaultFilters={defaultFilters}
                        isMobile={isMobile}>
                        {this.renderList}
                    </ProductList>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: checksSelectors.getFilters(state),
    firstFetched: checksSelectors.getFirstFetched(state),
    checks: checksSelectors.getChecks(state),
    moreChecks: checksSelectors.getMoreChecks(state),
    pageNumber: checksSelectors.getPageNumber(state),
    isFetchingChecks: checksSelectors.getFetching(state),
});

export default connect(mapStateToProps)(List);
