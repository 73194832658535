import React from "react";
import { shape, string, bool, func, oneOfType, objectOf, any, number } from "prop-types";
import NumberFormat from "react-number-format";
import { compose } from "redux";

import * as numberUtils from "util/number";
import * as configUtils from "util/config";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import FormattedAmount from "pages/_components/FormattedAmount";
import Select from "pages/forms/_components/_fields/Select";
import AmountLabel from "pages/forms/_components/_fields/Amount/AmountLabel";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldHelp from "pages/_components/fields/FieldHelp";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

class Amount extends React.Component {
    quantityRef = null;

    static propTypes = {
        data: shape({}).isRequired,
        mode: string.isRequired,
        field: shape({ value: oneOfType([string, objectOf(any)]) }).isRequired,
        focus: bool,
        value: shape({ currency: string, quantity: oneOfType([string, number]) }),
        setValue: func.isRequired,
        editing: bool.isRequired,
        placeholder: string,
        toggleIsFocused: func,
        onBlur: func,
        idField: string.isRequired,
        hideCurrency: bool,
        hideCurrencyViewMode: bool,
        externalCurrencyData: string,
        handleChange: func,
        amountNull: bool,
        messageHelp: string,
    };

    static defaultProps = {
        value: {},
        placeholder: "",
        onBlur: null,
        toggleIsFocused: null,
        hideCurrency: false,
        hideCurrencyViewMode: false,
        externalCurrencyData: null,
        handleChange: null,
        amountNull: false,
        messageHelp: "",
        focus: false,
    };

    componentDidMount() {
        const { mode, field, focus, setValue, amountNull } = this.props;
        if (mode === "edit" && (field.value === "" || !field.value)) {
            setValue({ currency: configUtils.get("core.masterCurrency"), quantity: amountNull ? "" : "0" });
        }
        // al primer campo del formulario por lo general se le pasa focus en true
        if (this.quantityRef && focus) {
            this.quantityRef.focus();
        }
    }

    handleChange = (selectedCurrency = {}) => {
        const {
            data: { decimalSeparator, precision },
            value,
            setValue,
            handleChange,
        } = this.props;
        let quantity = this.quantityRef.value.replace(INPUT_REGEX_REPLACE, "");
        quantity = numberUtils.toNumber(quantity, decimalSeparator, precision);
        const amount = {
            currency: selectedCurrency.value || value.currency,
            quantity: quantity !== "" ? quantity : "",
        };

        setValue(amount);
        if (handleChange) {
            handleChange(quantity);
        }
    };

    handleFocus() {
        const { setValue, value } = this.props;
        if (value.quantity === "0") {
            const amount = {
                currency: value.currency,
                quantity: "",
            };
            setValue(amount);
        }
    }

    handleBlur() {
        const { onBlur, setValue, value } = this.props;
        if (value.quantity === "0" || value.quantity === "") {
            const amount = {
                currency: value.currency,
                quantity: "0",
            };
            setValue(amount);
        }
        if (onBlur) {
            onBlur();
        }
    }

    render() {
        const {
            editing,
            value,
            placeholder,
            toggleIsFocused,
            data: { options, decimalSeparator, precision, thousandsSeparator },
            idField,
            hideCurrency,
            hideCurrencyViewMode,
            externalCurrencyData,
            setValue,
            messageHelp,
        } = this.props;

        if (externalCurrencyData && externalCurrencyData !== value.currency) {
            setValue({ ...value, currency: externalCurrencyData });
        }
        const selectedCurrency = value ? value.currency : "";
        const selectedQuantity = value ? value.quantity : "";
        if (editing) {
            return (
                <div>
                    <div className="input-group" onFocus={toggleIsFocused} onBlur={toggleIsFocused}>
                        {!hideCurrency && options.length === 1 ? (
                            <span className="currency">{options[0].label}</span>
                        ) : (
                            !hideCurrency && (
                                <>
                                    <FieldLabel
                                        idField={`${idField}.currency`}
                                        hideLabel
                                        labelKey="form.field.amount.currency"
                                        mode="edit"
                                    />
                                    <Select
                                        id={`${idField}.currency`}
                                        className="currency-selector slideFromBottom flex-container"
                                        name="currency"
                                        searchable={false}
                                        onChange={this.handleChange}
                                        value={selectedCurrency}
                                        options={options.map(({ id, label }) => ({ value: id, label }))}
                                        clearable={false}
                                        optionClassName="needsclick"
                                    />
                                </>
                            )
                        )}
                        <NumberFormat
                            id={idField}
                            name="quantity"
                            className="form-control"
                            type="text"
                            onBlur={() => this.handleBlur()}
                            onFocus={() => this.handleFocus()}
                            onChange={this.handleChange}
                            maxLength="20"
                            value={selectedQuantity}
                            placeholder={placeholder}
                            getInputRef={(ref) => {
                                this.quantityRef = ref;
                            }}
                            decimalScale={precision}
                            thousandSeparator={thousandsSeparator}
                            decimalSeparator={decimalSeparator}
                            fixedDecimalScale
                        />
                    </div>
                    <FieldHelp text={messageHelp} />
                </div>
            );
        }
        const valueData = { ...value, currency: externalCurrencyData || value.currency };
        return <FormattedAmount {...valueData} hideCurrency={hideCurrencyViewMode} customMaximumDecimals={precision} />;
    }
}

export default compose(
    withFocus,
    formField({
        formClass: "form-group--composite",
        customLabel: AmountLabel,
    }),
)(Amount);
