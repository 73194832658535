import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import GenericItem from "components/GenericItem/GenericItem";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Loader from "pages/_components/Loader";
import ProductList from "pages/_components/product/List";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import { array, bool, func, oneOfType, shape, number } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { actions as qrActions, selectors as qrSelectors } from "reducers/qr";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";

const Certificates = ({
    certificateList,
    isMobile,
    filters,
    isFetching,
    dispatch,
    isFetchingGetCertificate,
    pageNumber,
    isFirstFetched,
}) => {
    const renderDownloadButton = (uuid) => (
        <Button
            onClick={() => dispatch(qrActions.getCertificate(uuid, filters.selectedAccount.idProduct))}
            loading={isFetchingGetCertificate}
            label="global.viewCertificate"
            bsStyle="outline"
            className={`btn-small ${isMobile ? "ui-mt-3" : ""}`}
            image="images/ui-icons/ui-eye.svg"
        />
    );

    const renderItem = (item) => {
        const { uuid, date, taxDescription } = item;

        return isMobile ? (
            <GenericItem hoverBehavior={false} className="qr-certificates">
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.date" />
                    </div>
                    <span className="data-text">
                        <FormattedDate date={date} />
                    </span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.tax" />
                    </div>
                    <span className="data-text">{taxDescription}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="tableHeader.certificate" />
                    </div>
                    {renderDownloadButton(uuid)}
                </div>
            </GenericItem>
        ) : (
            <div className="table-row no-hover" key={uuid} role="button">
                <div className="table-data">
                    <span className="data-text text-left">
                        <FormattedDate date={date} />
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-text">{taxDescription}</span>
                </div>
                <div className="table-data">{renderDownloadButton(uuid)}</div>
            </div>
        );
    };

    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    <DisclaimerContainer
                        className={`${certificateList.length ? "ui-mt-8" : ""}`}
                        disclaimer="accounts.qrChargesCertificates.disclaimer.label"
                        textSmall
                    />
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left" styles={{ width: "45%" }}>
                            <I18n id="tableHeader.date" componentProps={{ className: "data-label" }} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left" styles={{ width: "45%" }}>
                            <I18n id="tableHeader.tax" componentProps={{ className: "data-label" }} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left" styles={{ width: "10%" }}>
                            <I18n id="tableHeader.certificate" componentProps={{ className: "data-label" }} />
                        </Table.HeaderData>
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer
                    className="ui-mt-8"
                    disclaimer="accounts.qrChargesCertificates.disclaimer.label"
                    textSmall
                />
            </>
        );
    };

    const defaultFilters = {
        dateFrom: defaultDateFromList().accountsQR,
        dateTo: defaultDateFromList().accountsQR,
        toggleState: filters.toggleState,
    };

    const withFilters = getFiltersStatus({
        filters,
        defaultFilters,
    });

    let noMoreDataText;

    if (withFilters) {
        noMoreDataText = "accounts.qrChargesCertificates.certificates.list.noMoreData.withFilters.label";
    } else {
        noMoreDataText = "accounts.qrChargesCertificates.certificates.list.noMoreData.default.label";
    }

    return (
        <>
            {isFetching && isFirstFetched ? (
                <Loader />
            ) : (
                <ProductList
                    firstFetched={isFirstFetched}
                    fetching={isFetching}
                    items={certificateList}
                    renderItem={renderItem}
                    filters={filters}
                    pageNumber={pageNumber}
                    loadMoreText="global.label.loadMore"
                    noDataText="accounts.qrChargesCertificates.certificates.list.empty.label"
                    noMoreDataText={noMoreDataText}
                    noFiltersDataText="accounts.qrChargesCertificates.filter.notFound.message"
                    defaultFilters={defaultFilters}
                    isMobile={isMobile}
                    onLoadMoreClick={() => {}}>
                    {renderList}
                </ProductList>
            )}
        </>
    );
};

Certificates.propTypes = {
    certificateList: oneOfType([array]).isRequired,
    isMobile: bool.isRequired,
    filters: shape({}).isRequired,
    isFetching: bool.isRequired,
    isFetchingGetCertificate: bool.isRequired,
    dispatch: func.isRequired,
    pageNumber: number.isRequired,
    isFirstFetched: bool.isRequired,
};

const mapStateToProps = (state) => ({
    certificateList: qrSelectors.getCertificateList(state),
    filters: qrSelectors.getFilters(state),
    isFetching: qrSelectors.getFetching(state),
    isFetchingGetCertificate: qrSelectors.getFetchingCertificate(state),
    pageNumber: qrSelectors.getPageNumber(state),
    isFirstFetched: qrSelectors.getFirstFetching(state),
});

export default connect(mapStateToProps)(Certificates);
