import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as footerSelectors } from "reducers/footer";
import HeaderLogin from "./header/HeaderLogin";

class SecurityPolicies extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        hasActiveSession: bool,
        dispatch: func.isRequired,
        currentPath: string.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    backButtonAction = () => {
        const { dispatch, currentPath } = this.props;
        dispatch(routerActions.replace(currentPath));
    };

    renderHeader = () => {
        const { isMobile, hasActiveSession } = this.props;
        if (!hasActiveSession) {
            return <HeaderLogin onHeaderClose={this.backButtonAction} showBack />;
        }
        if (isMobile) {
            return (
                <Head
                    showLogo
                    arrowWhite
                    onBack={this.backButtonAction}
                    additionalClassName="blue-main-header-mobile"
                />
            );
        }
        return <Head />;
    };

    render() {
        const { hasActiveSession, isMobile } = this.props;

        let disclaimerMaxHeight = "";

        if (!hasActiveSession) {
            if (isMobile) {
                disclaimerMaxHeight = "calc(100vh - 250px)";
            } else {
                disclaimerMaxHeight = "calc(100vh - 280px)";
            }
        }

        return (
            <div className={!hasActiveSession ? "login-content" : ""}>
                {this.renderHeader()}

                <MainContainer>
                    <div className="documents-container">
                        <div className={!hasActiveSession ? "ui-mb-7 ui-text-center" : "title-left-header"}>
                            <I18n id="global.privacy" component="h1" />
                        </div>

                        <DisclaimerContainer maxHeight={disclaimerMaxHeight} justifyText={false}>
                            <I18n id="securityPolicies.head.label" component="p" />
                            <I18n id="securityPolicies.topic.1.title" component="h2" />
                            <I18n id="securityPolicies.topic.1.content" component="p" />
                            <I18n id="securityPolicies.topic.2.title" component="h2" />
                            <I18n id="securityPolicies.topic.2.content" component="p" />
                            <I18n id="securityPolicies.topic.3.title" component="h2" />
                            <I18n id="securityPolicies.topic.3.content" component="p" />
                            <div className="mt-1">
                                <I18n id="securityPolicies.footer.label" component="p" />
                            </div>
                        </DisclaimerContainer>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPath: footerSelectors.getCurrentPath(state),
});

export default connect(mapStateToProps)(SecurityPolicies);
