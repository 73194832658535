import React, { Component } from "react";
import { string, number, func, bool, shape, arrayOf, oneOfType, instanceOf, any } from "prop-types";
import { compose } from "redux";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import * as i18n from "util/i18n";
import * as numberUtils from "util/number";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";
import FieldHelp from "pages/_components/fields/FieldHelp";

const INPUT_REGEX_REPLACE = /[^0-9.,]/g;

class Text extends Component {
    static propTypes = {
        maxLength: number.isRequired,
        validationRegularExpresion: string,
        minLength: number,
        setValue: func.isRequired,
        idValidation: string,
        editing: bool.isRequired,
        placeholder: string,
        value: string,
        name: string,
        toggleIsFocused: func,
        idField: string.isRequired,
        onChange: func,
        decimalScale: number,
        allowNegative: bool,
        type: string,
        mode: string.isRequired,
        field: shape({ value: string }).isRequired,
        mask: oneOfType([arrayOf(any), instanceOf(RegExp)]),
        inputRef: oneOfType([func, shape({})]),
        messageHelp: string,
    };

    static defaultProps = {
        minLength: 0,
        validationRegularExpresion: "",
        idValidation: "",
        placeholder: "",
        value: "",
        name: "",
        toggleIsFocused: null,
        onChange: null,
        decimalScale: 0,
        type: null,
        allowNegative: false,
        mask: null,
        inputRef: React.createRef(),
        messageHelp: null,
    };

    componentDidMount() {
        const { mode, field, type, setValue } = this.props;
        if (mode === "edit" && type === "number" && (field.value === "" || !field.value)) {
            setValue("");
        }
    }

    handleChangeMask = (event) => {
        const { setValue, onChange } = this.props;

        setValue(event.target.value);

        if (onChange) {
            onChange(event.target.value);
        }
    };

    handleChange = ({ target }) => {
        const { setValue, onChange, type, decimalScale } = this.props;

        if (type === "number") {
            let quantity = target.value.replace(INPUT_REGEX_REPLACE, "");
            quantity = numberUtils.toNumber(quantity, ",", decimalScale);
            setValue(quantity !== "" ? quantity : "");
        } else {
            setValue(target.value);
            this.customMinLengthValidation({ target });
        }

        if (onChange) {
            onChange(target.value);
        }
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idValidation, validationRegularExpresion } = this.props;

        const { value } = target;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idValidation && validationRegularExpresion) {
            const rexp = new RegExp(validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    handleFocus() {
        const { toggleIsFocused, setValue, value } = this.props;
        if (value === "0") {
            setValue("");
        }
        if (toggleIsFocused) {
            toggleIsFocused();
        }
    }

    handleBlur() {
        const { toggleIsFocused, setValue, value } = this.props;
        if (value === "0" || value === "") {
            setValue("");
        }
        if (toggleIsFocused) {
            toggleIsFocused();
        }
    }

    render() {
        const {
            editing,
            type,
            placeholder,
            minLength,
            maxLength,
            value,
            name,
            decimalScale,
            toggleIsFocused,
            allowNegative,
            idField,
            mask,
            inputRef,
            messageHelp,
        } = this.props;

        if (editing) {
            if (mask) {
                return (
                    <>
                        <div className="input-group">
                            <MaskedInput
                                className="form-control"
                                guide={false}
                                mask={mask}
                                onBlur={toggleIsFocused}
                                onFocus={toggleIsFocused}
                                value={value}
                                ref={inputRef}
                                onChange={this.handleChangeMask}
                            />
                        </div>
                        {messageHelp && <FieldHelp text={messageHelp} />}
                    </>
                );
            }
            if (type === "number") {
                return (
                    <>
                        <div className="input-group">
                            <NumberFormat
                                id={idField}
                                name="quantity"
                                className="form-control"
                                type="text"
                                allowNegative={allowNegative}
                                onBlur={() => this.handleBlur()}
                                onChange={this.handleChange}
                                onFocus={() => this.handleFocus()}
                                minLength={minLength}
                                maxLength={maxLength}
                                value={value}
                                placeholder={placeholder}
                                decimalScale={decimalScale}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale
                                mask={mask}
                            />
                        </div>
                        {messageHelp && <FieldHelp text={messageHelp} />}
                    </>
                );
            }
            return (
                <>
                    <div className="input-group">
                        <input
                            id={idField}
                            onInvalid={this.customMinLengthValidation}
                            className="form-control"
                            type="text"
                            value={value || ""}
                            name={name}
                            onChange={this.handleChange}
                            onBlur={toggleIsFocused}
                            minLength={minLength}
                            maxLength={maxLength}
                            placeholder={placeholder}
                            ref={inputRef}
                            // pattern={validationRegularExpresion}
                            onFocus={toggleIsFocused}
                        />
                    </div>
                    {messageHelp && <FieldHelp text={messageHelp} />}
                </>
            );
        }
        return (
            <ul>
                <li>{value}</li>
            </ul>
        );
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);
                    return regex.test(value);
                } catch (e) {
                    return true;
                }
            }

            return true;
        },
    }),
)(Text);
