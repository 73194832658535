import Button from "pages/_components/Button";
import React from "react";

import { bool, func, string } from "prop-types";
import { actions as communicationActions } from "reducers/communication";

function ReportSuspiciousActivity({ dispatch, isMobile, origin }) {
    const handleReportClick = (event) => {
        event.preventDefault();
        dispatch(communicationActions.startPreloadedMessage(true, isMobile, origin));
    };

    return (
        <Button
            block={isMobile}
            onClick={handleReportClick}
            image="images/alert-white.svg"
            label="settings.lastLogin.report"
            bsStyle="outline"
        />
    );
}

ReportSuspiciousActivity.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    origin: string.isRequired,
};

export default ReportSuspiciousActivity;
