import React, { Component } from "react";
import { bool, func, int, shape, string } from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { actions as productsActions } from "reducers/products";
import { actions as accountActions, selectors as accountsSelectors } from "reducers/accounts";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import EditableLabel from "pages/_components/fields/EditableLabel";
import QrAccountButton from "pages/qr/QrAccountButton";

class DetailHeadTitle extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        account: shape({
            idEnvironment: int,
            idProduct: string,
            productType: string.isRequired,
            productAlias: string,
            label: string,
            shortLabel: string,
            extraInfo: string,
            formattedAccountNumber: string,
            ownerName: string,
            currency: string.isRequired,
            extraInfoMarked: string,
        }).isRequired,
        isFetchingExport: bool.isRequired,
    };

    static defaultProps = {};

    saveAlias = (alias) => {
        const { account, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, account.idProduct));
    };

    renderAsignEditAlias = () => {
        const { account, isFetchingExport } = this.props;
        const { productAlias } = account;

        return (
            <>
                <EditableLabel
                    onSave={this.saveAlias}
                    onCancel={this.cancelAlias}
                    value={productAlias}
                    editWithModal
                    modalMainLabel="accounts.alias.modal.title"
                    modalButtonLabel="accounts.alias.modal.confirm.button"
                    isTitle
                />
                {account.productType === "CC" && this.renderGetQrButton()}
                <Button
                    className="btn-only-icon"
                    image="images/ui-icons/ui-account-download.svg"
                    onClick={() => this.handleDownloadAccountDetails(true)}
                    loading={isFetchingExport}
                />
            </>
        );
    };

    handleDownloadAccountDetails = (isDesktop) => {
        const { dispatch, account } = this.props;

        const { client, cbu, cbuDetail, cbuNick, idProduct } = account;
        const titular = cbuDetail?.titular || "";
        const cuitTitular = cbuDetail?.cuitTitular || client?.idClient;
        const cuit = cuitTitular
            ?.substring(0, 2)
            .concat("-")
            .concat(cuitTitular?.substring(2, 10))
            .concat("-")
            .concat(cuitTitular?.substring(10));

        const accountType = `${i18nUtils.get(`productType.${account?.productType}`)}`;
        const accountNumber = account?.formattedAccountNumber;
        const accountData = `${accountType} ${accountNumber}`;
        const alias = cbuDetail?.cbuAlias || cbuNick;

        const dataToExport = {
            titular,
            cuit,
            accountData,
            cbu,
            alias,
            accountType,
            accountNumber,
        };

        dispatch(accountActions.exportAccountDetailsRequest(dataToExport, idProduct, isDesktop));
    };

    renderGetQrButton = () => {
        const { account, isDesktop } = this.props;

        return <QrAccountButton account={account} isDesktop={isDesktop} />;
    };

    render() {
        const { account, isDesktop } = this.props;
        const { formattedAccountNumber, productAlias, productType, currency } = account;

        return (
            <>
                <div
                    className={classNames("title-left-header ui-mb-3", {
                        "ui-mt-3": isDesktop,
                    })}>
                    <div className="visually-hidden" tabIndex="0">
                        <span>{`${productType} ${currency}, ${productAlias || formattedAccountNumber}`}</span>
                    </div>
                    <div className="editable-title">{this.renderAsignEditAlias()}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetchingExport: accountsSelectors.getIsFetchingExport(state),
});

export default connect(mapStateToProps)(DetailHeadTitle);
