import React from "react";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import { string } from "prop-types";

const HeaderInfo = ({ paymentType, processDate }) => (
    <>
        {processDate && (
            <div className="data-wrapper">
                <I18n
                    id="pays.supplier.list.file.query.header.dateOfProcess.label"
                    componentProps={{ className: "data-label" }}
                />
                <FormattedDate className="data-bold" date={processDate} />
            </div>
        )}
        <div className="data-wrapper">
            <I18n
                id="forms.payments.suppliers.detail.transfer.headerInfo.typeFile.label"
                componentProps={{ className: "data-label" }}
            />
            <div className="data-bold">
                <I18n id={`forms.payments.suppliers.typeFile.${paymentType}.label`} />
            </div>
        </div>
    </>
);

HeaderInfo.propTypes = {
    paymentType: string.isRequired,
    processDate: string,
};

HeaderInfo.defaultProps = {
    processDate: null,
};

export default HeaderInfo;
