import React, { useState } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/investments";
import ReactDOM from "react-dom";
import { array, bool, func, oneOfType, node, shape, string } from "prop-types";
import { get } from "util/i18n";
import NumberFormat from "react-number-format";

import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import GenericItem from "components/GenericItem/GenericItem";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";

import Table from "pages/_components/Table";
import TableRow from "pages/_components/table/Row";
import BigModal from "pages/_components/BigModal/BigModal";
import * as config from "../../../util/config";
import Button from "../../_components/Button";
import Dropdown from "../../_components/Dropdown";
import formatPaymentDeadline from "../../../util/investments";
import FormattedAmount from "../../_components/FormattedAmount";
import I18n from "../../_components/I18n";
import Image from "../../_components/Image";
import Loader from "../../_components/Loader";
import NumberFormatPercentage from "../../_components/NumberFormatPercentage";
import ProductList from "../../_components/product/List";

const Offers = ({ isMobile, isFetching, filters, operatingFunds, handleOnClick, isFirstFetched }) => {
    const [isEditingItem, setEditingItem] = useState(false);
    const [fundSelected, setFundSelected] = useState({});
    const moneyMarketId = config.get("mutualFunds.moneyMarket.identifier", "1");
    const moneyMarketDecimal = config.get("mutualFunds.defaultDecimals.moneyMarket", 8);
    const otherFundDecimal = config.get("mutualFunds.defaultDecimals.otherFunds", 6);
    const showModal = (id, typeId, currency, defaultDecimals) => {
        const operationFund = operatingFunds.find((item) => item.id === id && item.typeId === typeId);
        operationFund.defaultDecimals = defaultDecimals;
        operationFund.currency = currency;
        setFundSelected(operationFund);
        setEditingItem(true);
    };

    const handleCloseModal = () => {
        setEditingItem(false);
    };

    const renderPercentages = (amountFormatted) => (
        <span className="align-right data-amount data-amount-currency">{amountFormatted}</span>
    );

    const createModal = () => {
        const {
            balance,
            classDescription,
            currency,
            currencyId,
            currencyDescription,
            deadline,
            defaultDecimals,
            endTime,
            name,
            number,
            startTime,
            typeDescription,
        } = fundSelected || {};

        const MutualFundDetail = ({ id, value }) => (
            <div key={id} className="data-wrapper inline">
                <I18n id={`investments.mutualFund.detail.${id}`} componentProps={{ className: "data-label" }} />
                <span className="data-text">{value}</span>
            </div>
        );

        MutualFundDetail.propTypes = {
            id: string.isRequired,
            value: node.isRequired,
        };

        const items = [
            { id: "patrimony", value: <FormattedAmount currency={currencyId} quantity={balance?.patrimony} /> },
            {
                id: "value",
                value: (
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={Math.max(defaultDecimals, defaultDecimals)}
                        renderText={renderPercentages}
                        value={balance?.lastValue}
                        displayType="text"
                        prefix={currency}
                    />
                ),
            },
            { id: "type", value: <span>{classDescription}</span> },
            { id: "currency", value: <span>{currencyDescription}</span> },
            { id: "deadline", value: <span>{formatPaymentDeadline(deadline)}</span> },
            { id: "schedule", value: <span>{`${startTime} HS - ${endTime} HS`}</span> },
        ];

        const goalDescription = get(`investments.mutualFund.goals.${number}.label`, "");

        const fundInformationModal = (
            <BigModal
                showModal={isEditingItem}
                handleShowModal={handleCloseModal}
                modalWidth="584px"
                modalTitle=""
                isMobile={isMobile}
                className="mutualFundsModal">
                <div className="mutualFunds-details">
                    <Image src="images/marivaDetailCheck.svg" />

                    <p className="title">{`${name} - ${typeDescription}`}</p>

                    {goalDescription.length > 0 && (
                        <div className="goals">
                            <I18n component="p" id="investments.mutualFund.detail.goal.label" />
                            <p>{goalDescription}</p>
                        </div>
                    )}

                    <div className="generic-movements-wrapper ui-mt-3">
                        <GenericItem hoverBehavior={false}>{items.map((item) => MutualFundDetail(item))}</GenericItem>
                    </div>
                    <SeuoLabel />

                    <Button
                        className="ui-mt-3 ui-mb-8"
                        bsStyle="primary"
                        defaultLabelText="Suscribir"
                        block
                        onClick={() => handleOnClick(fundSelected, "Subscribe")}
                    />

                    <DisclaimerContainer
                        maxHeight="98px"
                        textSmall
                        disclaimer={get("investments.mutualFund.disclaimer.label", "")}
                    />
                </div>
            </BigModal>
        );

        return ReactDOM.createPortal(fundInformationModal, document.querySelector("#modal"));
    };

    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    {renderLoadMore()}
                    <DisclaimerContainer
                        maxHeight="210px"
                        tableFooter="investments.operatingFund.tableFooter.label"
                        disclaimer="investments.mutualFund.disclaimer.label"
                        textSmall
                    />
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="investments.operatingFund.tableHeader.name" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="investments.operatingFund.tableHeader.currency" />
                        </Table.HeaderData>

                        <Table.HeaderData align="right" styles={{ maxWidth: "100px" }}>
                            <I18n id="investments.operatingFund.tableHeader.lastValue" />
                        </Table.HeaderData>

                        <Table.HeaderData align="center">
                            <I18n id="investments.operatingFund.tableHeader.lastValueDate" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <Table className="innerTable--header">
                                <TableRow>
                                    <Table.HeaderData align="center">
                                        <I18n id="investments.operatingFund.tableHeader.variation" />
                                    </Table.HeaderData>
                                </TableRow>

                                <TableRow>
                                    <Table.HeaderData align="right">
                                        <I18n id="investments.operatingFund.tableHeader.dailyPercentage" />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="right">
                                        <I18n id="investments.operatingFund.tableHeader.sevenDays" />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="right">
                                        <I18n id="investments.operatingFund.tableHeader.thirtyDays" />
                                    </Table.HeaderData>

                                    <Table.HeaderData align="right">
                                        <I18n id="investments.operatingFund.tableHeader.ytd" />
                                    </Table.HeaderData>
                                </TableRow>
                            </Table>
                        </Table.HeaderData>

                        <Table.HeaderData align="left" />
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>

                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer
                    maxHeight="200px"
                    tableFooter="investments.operatingFund.tableFooter.label"
                    disclaimer="investments.mutualFund.disclaimer.label"
                    textSmall
                />
            </>
        );
    };

    const renderItem = (item) => {
        const {
            id,
            name,
            typeDescription,
            balance,
            variation,
            percentages,
            typeId,
            currencyDescription,
            currencySymbol,
            number,
            isAnnualizedVariation,
        } = item;
        const currency = get(`currency.label.${currencySymbol === "U$S" ? "USD" : currencySymbol}`);
        const defaultDecimals = number === moneyMarketId ? moneyMarketDecimal : otherFundDecimal;
        const defaultDecimalsVariation = number === moneyMarketId ? 2 : 3;
        const addAsterik = isAnnualizedVariation ? "*" : "**";
        const dailyPercentage = percentages.dailyPercentage * 100;
        const sevenPercent = percentages.sevenPercent * 100;
        const thirtyPercent = percentages.thirtyPercent * 100;
        const YTD = percentages.YTD * 100;

        return isMobile ? (
            <GenericItem
                key={`${id}-${typeDescription}-${variation}`}
                options={
                    <Dropdown
                        image="images/ui-icons/ui-dropdown-mobile.svg"
                        bsStyle="link"
                        className="btn-only-icon"
                        label=""
                        pullRight>
                        <Button
                            label="investments.operatingFund.actions.subscription"
                            key="investments.operatingFund.actions.subscription"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => handleOnClick(item, "Subscribe")}
                        />

                        <Button
                            label="investments.operatingFund.actions.fundInformation"
                            key="investments.operatingFund.actions.fundInformation"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => showModal(id, typeId, currency, defaultDecimals)}
                        />

                        <Button
                            label="investments.operatingFund.actions.supplementaryInformation"
                            key="investments.operatingFund.actions.supplementaryInformation"
                            onClick={() => handleOnClick(item, "supplementaryInformation")}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                }
                onClick={(e) =>
                    !e.target.classList.contains("btn-link") && showModal(id, typeId, currency, defaultDecimals)
                }>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.name" />
                    </div>
                    <span className="data-text">{`${name} - ${typeDescription}${addAsterik}`}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.currency" />
                    </div>
                    <span className="data-text">{currencyDescription}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.lastValue" />
                    </div>
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={Math.max(defaultDecimals, defaultDecimals)}
                        renderText={renderPercentages}
                        value={balance?.lastValue}
                        displayType="text"
                        prefix={currency}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.lastValueDate" />
                    </div>
                    <span className="data-text">{balance?.lastValueDate}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.dailyPercentage" />
                    </div>
                    <NumberFormatPercentage
                        decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                        renderText={renderPercentages}
                        value={dailyPercentage}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.sevenDays" />
                    </div>
                    <NumberFormatPercentage
                        decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                        renderText={renderPercentages}
                        value={sevenPercent}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.thirtyDays" />
                    </div>
                    <NumberFormatPercentage
                        decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                        renderText={renderPercentages}
                        value={thirtyPercent}
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.operatingFund.tableHeader.ytd" />
                    </div>
                    <NumberFormatPercentage
                        decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                        renderText={renderPercentages}
                        value={YTD}
                    />
                </div>
            </GenericItem>
        ) : (
            <div
                className="table-row"
                key={`${id}-${typeDescription}-${variation}`}
                onClick={(e) =>
                    !e.target.classList.contains("btn-link") && showModal(id, typeId, currency, defaultDecimals)
                }
                role="button">
                <div className="table-data">
                    <span className="data-text">{`${name} - ${typeDescription}${addAsterik}`}</span>
                </div>
                <div className="table-data">
                    <span className="data-text">{currencyDescription}</span>
                </div>
                <div className="table-data">
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={Math.max(defaultDecimals, defaultDecimals)}
                        renderText={renderPercentages}
                        value={balance.lastValue}
                        displayType="text"
                        prefix={currency}
                    />
                </div>
                <div className="table-data text-center">
                    <span className="data-text">{balance.lastValueDate}</span>
                </div>
                <div className="table-data">
                    <Table className="innerTable--body">
                        <Table.Row>
                            <div className="table-data align-right">
                                <NumberFormatPercentage
                                    decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                                    value={dailyPercentage}
                                />
                            </div>
                            <div className="table-data align-right">
                                <NumberFormatPercentage
                                    decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                                    value={sevenPercent}
                                />
                            </div>
                            <div className="table-data align-right">
                                <NumberFormatPercentage
                                    decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                                    value={thirtyPercent}
                                />
                            </div>
                            <div className="table-data align-right">
                                <NumberFormatPercentage
                                    decimalScale={Math.max(defaultDecimalsVariation, defaultDecimalsVariation)}
                                    value={YTD}
                                />
                            </div>
                        </Table.Row>
                    </Table>
                </div>

                <div className="table-data table-data-icon">
                    <Dropdown
                        image="images/ui-icons/ui-dropdown.svg"
                        label=""
                        bsStyle="link"
                        className="btn-small"
                        pullRight>
                        <Button
                            label="investments.operatingFund.actions.subscription"
                            key="investments.operatingFund.actions.subscription"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => handleOnClick(item, "Subscribe")}
                        />

                        <Button
                            label="investments.operatingFund.actions.fundInformation"
                            key="investments.operatingFund.actions.fundInformation"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => showModal(id, typeId, currency, defaultDecimals)}
                        />

                        <Button
                            label="investments.operatingFund.actions.supplementaryInformation"
                            key="investments.operatingFund.actions.supplementaryInformation"
                            onClick={() => handleOnClick(item, "supplementaryInformation")}
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    </Dropdown>
                </div>
            </div>
        );
    };

    return (
        <>
            {isFetching && isFirstFetched ? (
                <Loader />
            ) : (
                <>
                    <ProductList
                        firstFetched={isFirstFetched}
                        fetching={isFetching}
                        items={operatingFunds}
                        renderItem={renderItem}
                        filters={filters}
                        loadMoreText={!isMobile ? "investments.operatingFund.errorMessage.label" : ""}
                        noDataText="investments.operatingFund.notFound.message"
                        noFiltersDataText="investments.operatingFund.notFound.message"
                        isMobile={isMobile}
                        onLoadMoreClick={() => {}}>
                        {renderList}
                    </ProductList>
                    {operatingFunds.length > 0 && createModal()}
                </>
            )}
        </>
    );
};

Offers.propTypes = {
    operatingFunds: oneOfType([array]).isRequired,
    isMobile: bool.isRequired,
    filters: shape({}).isRequired,
    isFetching: bool.isRequired,
    handleOnClick: func.isRequired,
    isFirstFetched: bool.isRequired,
};

const mapStateToProps = (state) => ({
    isFetching: selectors.getFetching(state),
    operatingFunds: state.investments.operatingFunds,
    filters: selectors.getFilters(state),
    isFirstFetched: selectors.getFirstFetched(state),
});

export default connect(mapStateToProps)(Offers);
