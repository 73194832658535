import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import { string, shape, func, bool } from "prop-types";
import React from "react";
import * as configUtils from "util/config";
import { actions as echeqsActions } from "reducers/echeqs";
import { push } from "react-router-redux/actions";
import moment from "moment";

const EcheqActions = ({ echeq, idActivity, echeqType, dispatch, activeEnvironment, isMobile }) => {
    const clientDocNumber = activeEnvironment?.clients ? activeEnvironment.clients[0].idClient : null;
    const {
        estado,
        fecha_pago,
        fecha_pago_vencida,
        cheque_caracter,
        total_cesiones,
        cesiones,
        certficiado_emitido,
        tenencia_beneficiario_documento,
        re_presentar,
        endosos,
        solicitando_acuerdo,
        emisor_cuit,
        cesion_pendiente,
        avalistas,
        es_ultimo_mandante,
        total_endosos,
        cheque_acordado,
        acuerdo_rechazado,
        cuit_solic_devol,
        mandato_neg,
        mandato_cobro,
        mandatos,
        emisor_banco_codigo,
        cbu_deposito,
        certificado_emitido,
    } = echeq;
    const actions = [];
    const maxAssignmentsAllowed = configUtils.get("echeq.assign.max");
    const maxEndorsementsAllowed = configUtils.get("echeq.endorsements.max");
    const isClientOwner = clientDocNumber === tenencia_beneficiario_documento;
    const isClientEmitter = emisor_cuit === clientDocNumber;
    const fechaPagoDate = moment(fecha_pago).toDate();

    const handleOption = (action) => {
        dispatch(echeqsActions.setSelectedEcheq(echeq));
        dispatch(push(`/echeqs/formCustom/${action}`));
    };
    if (echeqType === "received") {
        if (
            !fecha_pago_vencida &&
            ((estado === "ACTIVO-PENDIENTE" && endosos[0].benef_documento === clientDocNumber) ||
                estado === "EMITIDO-PENDIENTE")
        ) {
            actions.push("accept");
            actions.push("repudiate");
        }

        if (
            isClientOwner &&
            new Date() >= fechaPagoDate &&
            !fecha_pago_vencida &&
            (estado === "ACTIVO" || (estado === "CUSTODIA" && mandato_cobro))
        ) {
            actions.push("deposit");
        }

        if (estado === "ACTIVO" && isClientOwner && !fecha_pago_vencida && maxEndorsementsAllowed > total_endosos) {
            actions.push("endorseRequest");
        }

        if (
            total_cesiones < maxAssignmentsAllowed &&
            !certificado_emitido &&
            (isClientOwner || clientDocNumber === cesiones.cedente_documento) &&
            ((estado === "ACTIVO" && cheque_caracter.toUpperCase() === "NO A LA ORDEN") ||
                (estado === "RECHAZADO" && !certficiado_emitido && !re_presentar && !cesion_pendiente))
        ) {
            actions.push("cede");
        }

        if (estado === "ACTIVO" && new Date() < fechaPagoDate) {
            actions.push("putIntoCustody");
        }

        if (estado === "CUSTODIA" && new Date() < fechaPagoDate) {
            actions.push("rescueFromCustody");
        }

        if (estado === "DEVOLUCION-PENDIENTE") {
            if (isClientOwner) {
                actions.push("acceptReturn");
                actions.push("rejectReturn");
            }
        }

        if (estado === "RECHAZADO" && solicitando_acuerdo && isClientOwner) {
            actions.push("acceptAgreement");
            actions.push("repudiateAgreement");
        }

        if (
            (estado === "CESION-PENDIENTE" || (estado === "RECHAZADO" && cesion_pendiente)) &&
            cesiones[0].cesionario_documento === clientDocNumber
        ) {
            actions.push("acceptCession");
            actions.push("repudiateCession");
        }

        if (estado === "ACTIVO" && isClientOwner) {
            actions.push("requestGuarantee");
        }

        if (estado === "AVAL-PENDIENTE" && isClientOwner) {
            actions.push("negateGuarantee");
        }

        if (estado === "AVAL-PENDIENTE" && avalistas[0].aval_documento === clientDocNumber) {
            actions.push("repudiateGuarantee");
            actions.push("admitGuarantee");
        }

        if (
            isClientOwner &&
            estado === "RECHAZADO" &&
            !certificado_emitido &&
            !cesion_pendiente &&
            !re_presentar &&
            !cheque_acordado &&
            cbu_deposito &&
            (emisor_banco_codigo === "254" || cbu_deposito.substring(0, 3) === "254")
        ) {
            actions.push("emitCertificate");
        }

        if ((es_ultimo_mandante || isClientOwner) && estado === "ACTIVO") {
            const negotiationMandatesQuantity = mandatos.filter(
                (mandato) => mandato.estado !== "ANULADO" && mandato.tipo_mandato === "NEG",
            ).length;
            if (negotiationMandatesQuantity < 10) {
                actions.push("requestMandateForNegotiation");
            }
            actions.push("requestMandatePayment");
        }

        if (estado === "MANDATO-PENDIENTE") {
            const pendingMandante = mandatos.filter((mandato) => mandato.estado === "PENDIENTE")[0];
            if (pendingMandante.tipo_mandato === "COB") {
                actions.push("acceptMandate.payment");
                actions.push("repudiateMandatePayment");
            } else if (pendingMandante.tipo_mandato === "NEG") {
                actions.push("acceptMandate.negotiation");
                actions.push("repudiateMandateForNegotiation");
            }
        }
    } else {
        if (clientDocNumber === cuit_solic_devol) {
            if (estado === "RECHAZADO" && solicitando_acuerdo) {
                actions.push("cancelAgreement");
            } else if (estado === "DEVOLUCION-PENDIENTE") {
                actions.push("cancelReturn");
            }
        }
        if (total_endosos > 0 && (estado === "ACTIVO-PENDIENTE" || estado === "NEGOCIACION-PENDIENTE")) {
            if (endosos[0].estado_endoso === "pendiente" && endosos[0].emisor_documento === clientDocNumber) {
                actions.push("cancelEndorsement");
            }
        }
        if (estado === "ACTIVO-PENDIENTE" || estado === "EMITIDO-PENDIENTE") {
            actions.push("cancelEcheq");
        }

        if (
            (estado === "CESION-PENDIENTE" || (estado === "RECHAZADO" && cesion_pendiente)) &&
            cesiones[0].cedente_documento === clientDocNumber
        ) {
            actions.push("cancelCession");
        }

        if (estado === "MANDATO-PENDIENTE") {
            const pendingMandante = mandatos.filter((mandato) => mandato.estado === "PENDIENTE")[0];
            if (pendingMandante.tipo_mandato === "COB") {
                actions.push("negateMandatePayment");
            } else if (pendingMandante.tipo_mandato === "NEG") {
                actions.push("negateMandateForNegotiation");
            }
        }

        const isInGuarantorsList =
            avalistas?.filter((avalista) => avalista.aval_documento === clientDocNumber).length > 0;
        const isInEndorsementsList =
            endosos?.filter((endoso) => endoso.emisor_documento === clientDocNumber).length > 0;

        if (
            (estado === "ACTIVO" || estado === "RECHAZADO") &&
            (isClientEmitter || isInEndorsementsList || isInGuarantorsList)
        ) {
            if (cheque_caracter.toUpperCase() === "A LA ORDEN") {
                if (estado === "RECHAZADO") {
                    if (!re_presentar && !acuerdo_rechazado && !solicitando_acuerdo && !cheque_acordado) {
                        actions.push("requestAgreement");
                    }
                } else if (isClientEmitter || isInGuarantorsList || endosos[0].emisor_documento === clientDocNumber) {
                    actions.push("requestReturn");
                }
            } else if (isClientEmitter || isInGuarantorsList) {
                if (estado === "RECHAZADO") {
                    if (!re_presentar && !acuerdo_rechazado && !solicitando_acuerdo && !cheque_acordado) {
                        actions.push("requestAgreement");
                    }
                } else {
                    actions.push("requestReturn");
                }
            }
        }
        const hasEndorsement = echeq.endosos?.length > 0;
        if (estado === "ACTIVO" && mandato_neg && !hasEndorsement) {
            actions.push("revokeMandate.negotiation");
        } else if (estado === "CUSTODIA" && mandato_cobro) {
            actions.push("revokeMandate.payment");
        }
    }

    if (actions.length > 0) {
        if (idActivity === "echeqs.detail") {
            return (
                <Dropdown
                    pullRight
                    image="images/selectArrowDownWhite.svg"
                    label="echeqs.detail.actions.title.label"
                    bsStyle="tertiary btn-dropdown">
                    {actions.map((action) => (
                        <Button
                            key={`${idActivity}.actions.${action}`}
                            label={`${idActivity}.actions.${action}`}
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => handleOption(action)}
                        />
                    ))}
                </Dropdown>
            );
        }

        return (
            <Dropdown
                pullRight
                image={`images/ui-icons/${isMobile ? "ui-dropdown-mobile.svg" : "ui-dropdown.svg"}`}
                label=""
                bsStyle="link"
                className="btn-small">
                {actions.map((action) => (
                    <Button
                        key={`${idActivity}.actions.${action}`}
                        label={`${idActivity}.actions.${action}`}
                        className="dropdown__item-btn"
                        bsStyle="link"
                        onClick={() => handleOption(action)}
                    />
                ))}
            </Dropdown>
        );
    }
    return "";
};

EcheqActions.propTypes = {
    echeq: shape({}),
    idActivity: string.isRequired,
    echeqType: string.isRequired,
    dispatch: func.isRequired,
    activeEnvironment: shape({}),
    isMobile: bool,
};

EcheqActions.defaultProps = {
    echeq: {},
    activeEnvironment: {},
    isMobile: false,
};

export default EcheqActions;
