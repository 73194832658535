import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as sessionActions } from "reducers/session";

import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func } from "prop-types";
import { Link } from "react-router-dom";
import SwitchField from "pages/_components/fields/formik/SwitchField";

const FORM_ID = "settings.TyC";

class TermsAndConditions extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        isSubmitting: bool.isRequired,
        history: func.isRequired,
    };

    TyCForm = () => {
        const { isSubmitting } = this.props;

        return (
            <Form className="above-the-fold">
                <section className="container--layout align-items-center container-mWidth">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col className="col col-12">
                                <I18n id="generalConditions.term.Esign.body" componentProps={{ className: "mb-1" }} />

                                <Link to="/termsAndConditions" className="btn-link-gray mb-25">
                                    <I18n id="generalConditions.TermsAndConditions.link.label" />
                                </Link>

                                <Field name="acceptConditions" idForm={FORM_ID} component={SwitchField} formGroup />
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="col col-12">
                                <Button
                                    type="submit"
                                    loading={isSubmitting}
                                    label="login.comeIn"
                                    bsStyle="primary"
                                    className="mt-75"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        );
    };

    render() {
        const { history, isMobile } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="TyC" />
                <Head
                    onBack={history.goBack}
                    headerClassName={!isMobile && "mt-2"}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                    additionalClassName={isMobile && "blue-main-header-mobile"}
                />

                <MainContainer>
                    <div className="title-left-header">
                        <I18n id="settings.TyC" component="h3" />
                    </div>
                    {this.TyCForm()}
                </MainContainer>
            </Fragment>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ acceptConditions: false }),
        validationSchema: () =>
            Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.acceptConditions.required`)),
            }),
        handleSubmit: ({ acceptConditions }, formikBag) => {
            formikBag.props.dispatch(sessionActions.changeEnvironmentSignTyc(acceptConditions, formikBag));
        },
    }),
)(TermsAndConditions);
