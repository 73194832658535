import React, { Component, Fragment } from "react";
import { string, object } from "prop-types";
import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";

class PayThirdPartiesLoan extends Component {
    static propTypes = {
        name: string.isRequired,
        previewData: object,
    };

    render() {
        const { debitAmount, amount, loanNumber, debitAccountAlias } = this.props.previewData;

        const showDebitAmount = debitAmount.currency !== amount.currency;

        return (
            <Fragment>
                <p className="text-lead">
                    <I18n id="forms.payLoan.confirmation.title" />
                </p>
                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payLoan.confirmation.loan" />{" "}
                    </span>
                    <span className="data-name">{loanNumber}</span>
                </div>

                <div className="transfer-block">
                    <div className="transfer-data transfer-data-highlight">
                        <span className="data-label">
                            <I18n id="forms.payLoan.confirmation.amount" />{" "}
                        </span>
                        <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                    </div>

                    {showDebitAmount && (
                        <Fragment>
                            <div className="transfer-data data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.payLoan.confirmation.debitAmount" />{" "}
                                </span>
                                <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                            </div>
                        </Fragment>
                    )}
                </div>

                <div className="data-wrapper">
                    <span className="data-label">
                        <I18n id="forms.payLoan.confirmation.debitAccount" />{" "}
                    </span>
                    <span className="data-name">{debitAccountAlias}</span>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    name: formSelectors.getName(state),
    previewData: formSelectors.getPreviewData(state),
});

export default connect(mapStateToProps)(PayThirdPartiesLoan);
