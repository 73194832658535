import React, { Component, Fragment } from "react";
import { bool, func, objectOf, any } from "prop-types";

import { connect } from "react-redux";
import { actions as userTransactionsActions, selectors as userTransactionsSelectors } from "reducers/userTransactions";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import List from "pages/userTransactions/_components/List";
import Filters from "pages/userTransactions/_components/Filters";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Loader from "pages/_components/Loader";
import defaultDateFromList from "util/defaultDateFromList";

class UserTransactionsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: objectOf(any).isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isFirstFetching: bool.isRequired,
        isMobile: bool.isRequired,
    };

    componentWillMount = () => {
        const { dispatch } = this.props;

        const defaultFilters = {
            activity: "ALL",
            dateFrom: defaultDateFromList().userTransactions.dateFrom,
            dateTo: defaultDateFromList().userTransactions.dateTo,
        };

        dispatch(userTransactionsActions.setFilters(defaultFilters));
        dispatch(userTransactionsActions.listRequest(defaultFilters, 1, true));
    };

    render() {
        const { fetching, isDesktop, dispatch, isFirstFetching, isMobile, history } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="userTransactions" />
                <Head
                    onBack={isMobile ? history.goBack : null}
                    additionalClassName={isMobile ? "blue-main-header-mobile" : ""}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                />
                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="userTransactions.list.title" component="h1" />
                        </div>
                        <Filters dispatch={dispatch} isDesktop={isDesktop} fetching={fetching} />

                        {fetching && isFirstFetching ? (
                            <Loader />
                        ) : (
                            <List isDesktop={isDesktop} showSearchButton showEnvironment itemsAreClickeable={false} />
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    isFirstFetching: userTransactionsSelectors.getFirstFetch(state),
    showFilters: userTransactionsSelectors.getShowFilters(state),
    fetching: userTransactionsSelectors.getFetching(state),
    hasMoreData: userTransactionsSelectors.getHasMoreData(state),
});

export default connect(mapStateToProps)(UserTransactionsList);
