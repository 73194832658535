import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bool, func, shape } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as suppliersSelectors, actions as supplierActions } from "reducers/paymentToSuppliers";
import Yup from "yup";
import * as i18n from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import * as config from "util/config";
import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Button from "pages/_components/Button";
import { clearFiltersAndSubmit } from "util/form";
import DateSelector from "../_components/fields/DateField";

const FORM_ID = "pays.supplier.list.file.query";

const SupplierPaymentSentFilters = (props) => {
    const { isFetching, values, defaultDateFrom, setFieldValue, setFieldError, setValues, submitForm } = props;

    const handleClearFilters = () => {
        clearFiltersAndSubmit(
            () =>
                setValues({
                    dateFrom: defaultDateFrom,
                    dateTo: moment(),
                }),
            submitForm,
        );
    };

    return (
        <div className="filters-container">
            <Form className="form-content">
                <Field
                    idField="dateFrom"
                    component={DateSelector}
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="dateFrom"
                    selectsStart
                    showMonthYearDropdown
                    startDate={moment(values?.dateFrom)}
                    isClearable={false}
                    handleChange={(value) => {
                        setFieldValue("dateFrom", value);
                        setFieldError("dateFrom", null);
                    }}
                    minDate={moment(defaultDateFrom)}
                    maxDate={moment(values.dateTo) ?? moment()}
                />

                <Field
                    idField="dateTo"
                    component={DateSelector}
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="dateTo"
                    selectsStart
                    showMonthYearDropdown
                    startDate={moment(values?.dateFrom)}
                    isClearable={false}
                    handleChange={(value) => {
                        setFieldValue("dateTo", value);
                        setFieldError("dateTo", null);
                    }}
                    minDate={moment(values.dateFrom) || moment(defaultDateFrom)}
                />

                <Button
                    bsStyle="primary"
                    label="pays.supplier.list.file.query.filter.button.label"
                    loading={isFetching}
                    type="submit"
                />

                <Button
                    bsStyle="outline"
                    className="btn-clear-filters"
                    label="product.filters.clearFilters"
                    loading={isFetching}
                    onClick={handleClearFilters}
                    image="images/ui-icons/ui-clear-filters.svg"
                />
            </Form>
        </div>
    );
};

SupplierPaymentSentFilters.propTypes = {
    isFetching: bool.isRequired,
    values: shape({}).isRequired,
    defaultDateFrom: shape({}).isRequired,
    setFieldValue: func.isRequired,
    setFieldError: func.isRequired,
    setValues: func.isRequired,
    submitForm: func.isRequired,
};

const mapStateToProps = (state) => ({
    currentLang: i18nSelectors.getLang(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
    filters: suppliersSelectors.getFiltersQuery(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            fileStatus: props.fileStatus,
            dateFrom: props.filters.dateFrom || null,
            dateTo: props?.filters?.dateTo ?? moment().toDate(),
        }),
        validationSchema: (props) =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .min(
                                  moment().subtract(
                                      props.fileStatus.toUpperCase() === "PROCESSED"
                                          ? config.get("payment.supplier.default_days.search") + 1
                                          : config.get("default_days.Search_process.Transfer") + 1,
                                      "days",
                                  ),
                                  props.fileStatus === "PROCESSED"
                                      ? i18n.get(`${FORM_ID}.processed.daysAgo.validate.label`, null, {
                                            QUANTITYDAYSAGO: config.get("payment.supplier.default_days.search"),
                                        })
                                      : i18n.get(`${FORM_ID}.answer.daysAgo.validate.label`, null, {
                                            QUANTITYDAYSAGO: config.get("default_days.Search_process.Transfer"),
                                        }),
                              )
                              .max(values.dateTo, i18n.get(`${FORM_ID}.dateFrom.validate.label`))
                              .required(i18n.get(`${FORM_ID}.dateFrom.error.required.label`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`${FORM_ID}.dateFrom.error.required.label`)),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`${FORM_ID}.dateTo.validate.label`))
                              .required(i18n.get(`${FORM_ID}.dateTo.error.required.label`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`${FORM_ID}.dateTo.error.required.label`)),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch, user, fileStatus } = formikBag.props;
            dispatch(supplierActions.resetAnswerFilesData());
            dispatch(
                supplierActions.setFilterQuery({
                    dateFrom: moment(values.dateFrom).format("YYYY-MM-DD"),
                    dateTo: moment(values.dateTo).format("YYYY-MM-DD"),
                }),
            );
            dispatch(
                supplierActions.getAnswerFilesRequest(
                    {
                        docTypeClient: user.documentType,
                        docNumberClient: user.documentNumber,
                        docType: "CU",
                        dateFrom: moment(values.dateFrom).format("YYYY-MM-DD"),
                        dateTo: moment(values.dateTo).format("YYYY-MM-DD"),
                        filterFileType: "",
                        pageNumber: 1,
                        fileStatus,
                    },
                    true,
                ),
            );
        },
    }),
)(SupplierPaymentSentFilters);
