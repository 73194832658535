import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as echeqsSelectors, actions as echeqsActions } from "reducers/echeqs";
import { selectors as sessionSelectors } from "reducers/session";
import { bool, func, string, arrayOf, number, shape } from "prop-types";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Loader from "pages/_components/Loader";
import OrderFilterHeadTable from "pages/_components/OrderFilterHeadTable";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import Echeq from "./Echeq";

class List extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        pageNumber: number.isRequired,
        isDesktop: bool.isRequired,
        firstFetched: bool.isRequired,
        echeqs: arrayOf(shape({})).isRequired,
        echeqType: string.isRequired,
        moreEcheqs: bool.isRequired,
        idActivity: string.isRequired,
        history: shape({}).isRequired,
        isFetchingECheqs: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    state = {
        showFilters: false,
    };

    fetchMoreECheqs = () => {
        const { dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(echeqsActions.loadMoreEcheqsRequest(filters));
    };

    handleECheqClick = (link) => {
        const { history } = this.props;
        history.push(link);
    };

    handleFiltersClick = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    changeOrderFilter = (isAscendent) => {
        const { filters, dispatch, isDesktop } = this.props;
        const filtersEcheq = {
            ...filters,
            orderDirection: isAscendent ? "ascendent" : "descendent",
            pageNumber: 1,
        };
        dispatch(echeqsActions.loadListRequest(filtersEcheq, isDesktop));
    };

    renderItem = (echeq) => {
        const { isDesktop, idActivity, echeqType, dispatch, activeEnvironment } = this.props;
        const urlToRedirect = `/echeqs/${echeq?.cheque_id}/detail`;
        return isDesktop ? (
            <Table.Row
                key={echeq?.cheque_numero}
                onClick={(e) => !e.target.classList.contains("btn-link") && this.handleECheqClick(urlToRedirect)}
                role="button">
                <Echeq
                    isDesktop={isDesktop}
                    echeq={echeq}
                    idActivity={idActivity}
                    echeqType={echeqType}
                    dispatch={dispatch}
                    activeEnvironment={activeEnvironment}
                    onClick={() => this.handleECheqClick(urlToRedirect)}
                />
            </Table.Row>
        ) : (
            <Echeq
                key={echeq?.cheque_numero}
                isDesktop={isDesktop}
                echeq={echeq}
                idActivity={idActivity}
                echeqType={echeqType}
                onClick={() => this.handleECheqClick(urlToRedirect)}
                dispatch={dispatch}
                activeEnvironment={activeEnvironment}
            />
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, idActivity, echeqType, filters } = this.props;
        return isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left" styles={{ minWidth: "100px" }}>
                            <I18n id={`forms.${idActivity}.${echeqType}.number.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData />

                        {echeqType === "sent" ? (
                            <>
                                <Table.HeaderData align="left" styles={{ minWidth: "120px" }}>
                                    <I18n id={`forms.${idActivity}.${echeqType}.issueDate.label`} />
                                </Table.HeaderData>

                                <Table.HeaderData align="left">
                                    <I18n id={`forms.${idActivity}.${echeqType}.sentTo.label`} />
                                </Table.HeaderData>
                            </>
                        ) : (
                            <>
                                <Table.HeaderData align="left" styles={{ minWidth: "150px" }}>
                                    <I18n id={`forms.${idActivity}.${echeqType}.receivedFrom.label`} />
                                </Table.HeaderData>
                                <Table.HeaderData align="left">
                                    <I18n id={`forms.${idActivity}.${echeqType}.character.label`} />
                                </Table.HeaderData>
                            </>
                        )}

                        <Table.HeaderData align="right" className="hide-on-lg">
                            <I18n id={`forms.${idActivity}.${echeqType}.amount.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left" styles={{ minWidth: "125px" }}>
                            <I18n id={`forms.${idActivity}.${echeqType}.paymentDate.label`} />
                            <OrderFilterHeadTable
                                filterName="fecha_pago"
                                columnFilter={{
                                    filterSelected: filters.orderColumn,
                                    directionFilter: filters.orderDirection,
                                }}
                                changeFilter={this.changeOrderFilter}
                            />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`forms.${idActivity}.${echeqType}.status.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData />

                        <Table.HeaderData />

                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">{list}</div>
                <SeuoLabel />
                {renderLoadMore()}
            </>
        );
    };

    render() {
        const { firstFetched, moreEcheqs, echeqs, filters, isFetchingECheqs, isDesktop } = this.props;
        const { echeqType } = filters;

        const defaultFilters = {
            paymentDateFrom: defaultDateFromList().echeqs.paymentDateFrom,
            paymentDateTo: defaultDateFromList().echeqs.paymentDateTo,
        };

        const withFilters = getFiltersStatus({ filters, defaultFilters });

        let noMoreDataText;
        const noDataText = `forms.echeqs.list.${echeqType}.empty`;

        if (withFilters) {
            noMoreDataText = `forms.echeqs.list.${echeqType}.noMoreData.withFilters`;
        } else {
            noMoreDataText = `forms.echeqs.list.${echeqType}.noMoreData.default`;
        }

        return (
            <>
                {isFetchingECheqs && firstFetched ? (
                    <Loader />
                ) : (
                    <ProductList
                        fetching={isFetchingECheqs}
                        items={echeqs}
                        renderItem={this.renderItem}
                        lastPage={!moreEcheqs}
                        onLoadMoreClick={this.fetchMoreECheqs}
                        firstFetched={firstFetched}
                        filters={filters || {}}
                        noMoreDataText={noMoreDataText}
                        loadMoreText="forms.echeqs.list.searchMore.label"
                        noDataText={noDataText}
                        noFiltersDataText="forms.echeqs.list.filters.noResults"
                        defaultFilters={defaultFilters}
                        isMobile={!isDesktop}>
                        {this.renderList}
                    </ProductList>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: echeqsSelectors.getFilters(state),
    firstFetched: echeqsSelectors.getFirstFetched(state),
    echeqs: echeqsSelectors.getEcheqs(state),
    moreEcheqs: echeqsSelectors.getMoreEcheqs(state),
    pageNumber: echeqsSelectors.getPageNumber(state),
    isFetchingECheqs: echeqsSelectors.getFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(List);
