import React, { useEffect } from "react";
import { compose } from "redux";
import moment from "moment";
import { connect } from "react-redux";
import { array, bool, func, oneOfType, shape, string, objectOf, any, number } from "prop-types";
import NumberFormat from "react-number-format";
import GenericItem from "components/GenericItem/GenericItem";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Table from "pages/_components/Table";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import { actions as filterActions } from "reducers/filters";
import { actions } from "../../reducers/investments";
import { selectors as sessionSelectors } from "../../reducers/session";
import I18n from "../_components/I18n";
import * as config from "../../util/config";
import { get } from "../../util/i18n";

import Head from "../_components/Head";
import ProductList from "../_components/product/List";
import MovementFilters from "./MovementFilters";
import FormattedAmount from "../_components/FormattedAmount";
import FormattedDate from "../_components/FormattedDate";
import MainContainer from "../_components/MainContainer";

const MovementDetail = ({
    shareholderAccounts,
    availableFunds,
    isMobile,
    history,
    movements,
    filters,
    isFetching,
    dispatch,
    moreMovements,
    nextPage,
    user,
    isDownloading,
    isFirstFetching,
}) => {
    const moneyMarketDecimal = config.get("mutualFunds.defaultDecimals.moneyMarket", 8);
    const otherFundDecimal = config.get("mutualFunds.defaultDecimals.otherFunds", 6);
    const moneyMarketId = config.get("mutualFunds.moneyMarket.identifier", "1");
    const classLabel = get("investments.mutualFund.movements.class.label", "Class");

    useEffect(() => {
        dispatch(
            actions.setMovementFilters({
                dateFrom: defaultDateFromList().mutualFundsMovements.dateFrom,
                dateTo: defaultDateFromList().mutualFundsMovements.dateTo,
            }),
        );
        dispatch(
            actions.loadAvailableFunds(user.documentNumber, {
                shareholderAccount: filters.shareholderAccount,
                mutualFund: filters.mutualFund === "" ? {} : filters.mutualFund,
                dateFrom: defaultDateFromList().mutualFundsMovements.dateFrom,
                dateTo: defaultDateFromList().mutualFundsMovements.dateTo,
                pageNumber: 1,
            }),
        );
        return () => {
            dispatch(actions.clearMovementsData());
        };
    }, []);

    const renderDecimals = (amountFormatted) => (
        <span className="align-right data-amount data-amount-currency">{amountFormatted}</span>
    );

    const handleClickDownload = (format) => {
        const fundSelected = Object.keys(filters?.mutualFund).length > 0 ? filters.mutualFund : availableFunds[0];
        const accountSelected =
            shareholderAccounts.find((account) => account.number === filters.shareholderAccount) ||
            shareholderAccounts[0];

        dispatch(
            actions.downloadFundMovements(
                accountSelected?.number,
                accountSelected?.name,
                fundSelected?.number,
                fundSelected?.name,
                fundSelected?.typeId,
                filters.dateFrom === ""
                    ? moment()
                          .add(-config.get("mutualFunds.movements.defaultDaysBack", 7), "days")
                          .toDate()
                    : filters.dateFrom,
                filters.dateTo === "" ? new Date() : filters.dateTo,
                1,
                format,
            ),
        );
    };

    const handleBack = () => {
        dispatch(filterActions.setResetFilters(false));
        history.goBack();
    };

    const renderHeader = () => {
        if (isMobile) {
            return <Head onBack={handleBack} arrowWhite showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                onBack={handleBack}
                showPlusIcon
                accessibilityTextId="investments.mutualFund.movements.title.label"
                exportList=""
                isFetchingExport={isDownloading}
                handleClick={handleClickDownload}
            />
        );
    };

    const renderItem = (item) => {
        const {
            id,
            fundName,
            fundClass,
            operationType,
            quotaShare,
            quotaShareValue,
            settlementDate,
            status,
            amount,
            agreementDate,
            fundNumber,
        } = item;
        const defaultDecimals = fundNumber === moneyMarketId ? moneyMarketDecimal : otherFundDecimal;
        return isMobile ? (
            <GenericItem key={id} hoverBehavior={false}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.tableHeader.date" />
                    </div>
                    <FormattedDate date={agreementDate} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.name" />
                    </div>
                    <span className="data-text">{`${fundName} - ${classLabel} ${fundClass}`}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.operationType" />
                    </div>
                    <span className="data-text">{operationType}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.quotaShare" />
                    </div>
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={defaultDecimals}
                        fixedDecimalScale
                        value={quotaShare}
                        renderText={renderDecimals}
                        displayType="text"
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.quotaShareValue" />
                    </div>
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={defaultDecimals}
                        fixedDecimalScale
                        value={quotaShareValue}
                        renderText={renderDecimals}
                        displayType="text"
                    />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.settlementDate" />
                    </div>
                    <FormattedDate date={settlementDate} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="investments.mutualFund.movements.tableHeader.amount" />
                    </div>
                    <FormattedAmount maximumDecimals="6" currency="ARS" quantity={amount} />
                </div>
                {status && (
                    <div className="data-wrapper">
                        <span className="data-text">{status}</span>
                    </div>
                )}
            </GenericItem>
        ) : (
            <Table.Row key={id} additionalClassName="no-hover">
                <div className="table-data">
                    <FormattedDate date={agreementDate} />
                </div>
                <div className="table-data">
                    <span className="data-text">{`${fundName} - ${classLabel} ${fundClass}`}</span>
                </div>
                <div className="table-data">
                    <span className="data-text">{operationType}</span>
                </div>
                <div className="table-data">
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={defaultDecimals}
                        fixedDecimalScale
                        value={quotaShare}
                        renderText={renderDecimals}
                        displayType="text"
                    />
                </div>
                <div className="table-data">
                    <NumberFormat
                        decimalSeparator="."
                        thousandSeparator=","
                        decimalScale={defaultDecimals}
                        value={quotaShareValue}
                        fixedDecimalScale
                        renderText={renderDecimals}
                        displayType="text"
                    />
                </div>
                <div className="table-data">
                    <FormattedDate date={settlementDate} />
                </div>
                <div className="table-data">
                    <FormattedAmount currency="ARS" quantity={amount} />
                </div>
                <div className="table-data">{status && <span className="data-text">{status}</span>}</div>
            </Table.Row>
        );
    };

    const renderList = (list, renderLoadMore) => {
        const isEmpty = list.length === 0;

        if (!isEmpty && !isFetching) {
            return isMobile ? (
                <>
                    <div className="generic-movements-wrapper">{list}</div>
                    <SeuoLabel />
                    {renderLoadMore()}
                </>
            ) : (
                <>
                    <Table className="gridTable">
                        <Table.Header>
                            <Table.HeaderData align="left" styles={{ maxWidth: "100px" }}>
                                <I18n id="investments.mutualFund.tableHeader.date" />
                            </Table.HeaderData>

                            <Table.HeaderData align="left">
                                <I18n id="investments.mutualFund.movements.tableHeader.name" />
                            </Table.HeaderData>

                            <Table.HeaderData align="left">
                                <I18n id="investments.mutualFund.movements.tableHeader.operationType" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="investments.mutualFund.movements.tableHeader.quotaShare" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="investments.mutualFund.movements.tableHeader.quotaShareValue" />
                            </Table.HeaderData>

                            <Table.HeaderData align="left">
                                <I18n id="investments.mutualFund.movements.tableHeader.settlementDate" />
                            </Table.HeaderData>

                            <Table.HeaderData align="right">
                                <I18n id="investments.mutualFund.movements.tableHeader.amount" />
                            </Table.HeaderData>

                            <Table.HeaderData />
                        </Table.Header>

                        <Table.Body>{list}</Table.Body>
                    </Table>

                    <SeuoLabel />

                    {renderLoadMore()}
                </>
            );
        }
        return <></>;
    };

    const loadMoreMovements = () => {
        dispatch(
            actions.loadFundMovements(
                filters.shareholderAccount === "" ? shareholderAccounts[0]?.number : filters.shareholderAccount,
                filters.mutualFund?.number === "" ? "" : filters.mutualFund.number,
                filters.mutualFund?.typeId === "" ? "" : filters.mutualFund.typeId,
                filters.dateFrom,
                filters.dateTo,
                nextPage,
            ),
        );
    };

    const defaultFilters = {
        dateFrom: defaultDateFromList().mutualFundsMovements.dateFrom,
        dateTo: defaultDateFromList().mutualFundsMovements.dateTo,
    };

    let noMoreDataText;

    const withFilters = getFiltersStatus({ filters, defaultFilters });

    if (withFilters) {
        noMoreDataText = "investments.mutualFund.movements.noMoreData.withFilters";
    } else {
        noMoreDataText = "investments.mutualFund.movements.noMoreData.default";
    }

    return (
        <>
            {renderHeader()}

            <MainContainer className="main-container" showLoader={isFetching}>
                <div className="container-left">
                    <div className="title-left-header">
                        <I18n id="investments.mutualFund.movements.title.label" component="h1" />
                    </div>
                    <>
                        <MovementFilters isMobile={isMobile} />
                        <ProductList
                            fetching={isFetching}
                            firstFetched={isFirstFetching}
                            items={movements}
                            renderItem={renderItem}
                            filters={filters}
                            lastPage={!moreMovements}
                            pageNumber={nextPage}
                            loadMoreText="investments.mutualFund.movements.searchMore"
                            noDataText="investments.mutualFund.movements.empty.message"
                            noFiltersDataText="investments.mutualFund.movements.notFound.message"
                            noMoreDataText={noMoreDataText}
                            onLoadMoreClick={loadMoreMovements}
                            defaultFilters={defaultFilters}
                            isMobile={isMobile}>
                            {renderList}
                        </ProductList>

                        {movements.length > 0 && (
                            <DisclaimerContainer
                                disclaimer="investments.mutualFund.movements.disclaimer.label"
                                className="important-text ui-mt-8"
                            />
                        )}
                    </>
                </div>
            </MainContainer>
        </>
    );
};

MovementDetail.propTypes = {
    isMobile: bool.isRequired,
    moreMovements: bool.isRequired,
    dispatch: func.isRequired,
    history: objectOf(any).isRequired,
    filters: shape({}).isRequired,
    shareholderAccounts: oneOfType([array]).isRequired,
    availableFunds: oneOfType([array]).isRequired,
    movements: oneOfType([array]).isRequired,
    isFetching: bool.isRequired,
    isFirstFetching: bool.isRequired,
    user: shape({}).isRequired,
    nextPage: oneOfType([string, number]).isRequired,
    isDownloading: bool.isRequired,
    isFirstFetching: bool.isRequired,
};

const mapPropsToState = (state) => ({
    shareholderAccounts: state.investments.shareholderAccounts,
    availableFunds: state.investments.availableFunds,
    mutualFunds: state.investments.mutualFunds,
    filters: state.investments.movementFilters,
    isFetching: state.investments.isFetchingMovements,
    movements: state.investments.movements,
    moreMovements: state.investments.moreMovements,
    nextPage: state.investments.pageNumber,
    user: sessionSelectors.getUser(state),
    isDownloading: state.investments.isDownloading,
    isFirstFetching: state.investments.isFirstFetching,
});

export default compose(connect(mapPropsToState))(MovementDetail);
