import React, { Component } from "react";
import Image from "pages/_components/Image";

class ChevromRight extends Component {
    render() {
        return (
            <div className="ui-dflex-center">
                <Image src="images/ui-icons/ui-chevrom-right.svg" className="svg-icon svg-caret" />
            </div>
        );
    }
}

export default ChevromRight;
