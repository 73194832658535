import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { oneOf, func, array, oneOfType, bool } from "prop-types";

import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";

import * as i18n from "util/i18n";
import * as pushNotificationsUtils from "util/pushNotifications";
import * as deviceUtils from "util/device";
import SimpleModal from "components/SimpleModal/SimpleModal";
import { capitalizeFirstLetter } from "../../util/string";

class PushNotifications extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        fetching: bool,
        isMobile: bool.isRequired,
        history: func.isRequired,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        fetching: false,
    };

    state = {
        displayModal: false,
        displayActivationModal: false,
        deviceToDelete: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.pushNotificationsListUserDevices());
    }

    configure = (e) => {
        e.preventDefault();
        pushNotificationsUtils.showOSSettings();
    };

    deleteCurrentDevice = (e) => {
        e.preventDefault();
        const { pushNotificationsConfiguredUserDevices } = this.props;
        // get current device index
        const deviceUUID = window.app.getDeviceUUID();
        const deviceIndex = pushNotificationsConfiguredUserDevices.findIndex((element) => element.uuid === deviceUUID);
        this.deleteDevicePre(e, deviceIndex);
    };

    deleteDevicePre = (e, deviceIndex) => {
        e.preventDefault();
        const { pushNotificationsConfiguredUserDevices, dispatch } = this.props;
        dispatch(
            pushNotificationsActions.pushNotificationsDeleteUserDevicePre(
                pushNotificationsConfiguredUserDevices[deviceIndex],
            ),
        );
        dispatch(replace("/settings/pushNotifications/deleteConfirmation"));
    };

    showModal = (e, deviceIndex) => {
        e.preventDefault();
        const { dispatch, pushNotificationsConfiguredUserDevices } = this.props;

        dispatch(
            pushNotificationsActions.pushNotificationsDeleteUserDevicePre(
                pushNotificationsConfiguredUserDevices[deviceIndex],
            ),
        );

        this.setState({
            displayModal: true,
            deviceToDelete: pushNotificationsConfiguredUserDevices[deviceIndex],
        });
    };

    hideModal = () => {
        this.setState({ displayModal: false });
    };

    registerDevice = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        this.setState({
            displayActivationModal: false,
        });
        dispatch(pushNotificationsActions.pushNotificationsRegisterUserDevice());
    };

    renderDevices = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;

        return (
            <>
                <I18n id="settings.pushNotifications.configuredDevices" component="h4" />

                <div className="navigational-list devices">
                    {pushNotificationsConfiguredUserDevices && pushNotificationsConfiguredUserDevices.length ? (
                        Object.keys(pushNotificationsConfiguredUserDevices).map((deviceIndex) => {
                            const { model, manufacturer } = pushNotificationsConfiguredUserDevices[deviceIndex];
                            return (
                                <li key={deviceIndex} className="navigational-list-item device">
                                    <div className="navigational-list-item-container">
                                        <div>
                                            <span>
                                                {capitalizeFirstLetter(manufacturer ?? "")}
                                                {` - `}
                                            </span>
                                            <I18n id={`devices.apple.identifier.${model}`} defaultValue={`${model}`} />
                                        </div>
                                        <Button
                                            block={false}
                                            className="btn-only-icon btn-delete"
                                            image="images/ui-icons/ui-delete.svg"
                                            onClick={(e) => this.showModal(e, deviceIndex)}
                                            label="global.delete"
                                        />
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <I18n id="settings.pushNotifications.configuredDevices.none" />
                    )}
                </div>
            </>
        );
    };

    areNotificationsEnabledOnCurrentDevice = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;
        return pushNotificationsConfiguredUserDevices.find((device) => device.uuid === window.app.getDeviceUUID());
    };

    renderConfigureButton = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;
        if (deviceUtils.isMobileNativeFunc()) {
            if (pushNotificationsUtils.isPushEnabled()) {
                if (pushNotificationsConfiguredUserDevices && !this.areNotificationsEnabledOnCurrentDevice()) {
                    return (
                        <Button
                            block
                            label="settings.pushNotifications.device.register"
                            bsStyle="primary"
                            onClick={() => this.setState({ displayActivationModal: true })}
                        />
                    );
                }
            } else {
                return (
                    <>
                        <Button
                            block
                            label="settings.pushNotifications.device.configure"
                            bsStyle="primary"
                            onClick={this.configure}
                        />
                    </>
                );
            }
        }
        return null;
    };

    getContent = ({ isMobile } = this.props) => (
        <>
            <div className="title-left-header">
                {isMobile ? (
                    <I18n id="settings.pushNotifications.title.mobile" component="h1" />
                ) : (
                    <I18n id="settings.pushNotifications.title.desktop" component="h1" />
                )}
            </div>
            <div className="container-center">
                <form className="form-content">
                    <div className="form-text-info ui-mb-8">
                        {isMobile ? (
                            <>
                                <p>{i18n.get("settings.pushNotifications.textInfo.mobile.part1")}</p>
                                <p className="mt-1-5 mb-1-5">
                                    <span className="text-warning">
                                        {i18n.get("settings.pushNotifications.textInfo.important")}&nbsp;
                                    </span>
                                    {i18n.get("settings.pushNotifications.textInfo.mobile.part2")}
                                </p>
                            </>
                        ) : (
                            <I18n
                                id="settings.pushNotifications.textInfo.desktop"
                                component="p"
                                componentProps={{ className: "mb-2" }}
                            />
                        )}
                    </div>

                    {this.renderDevices()}

                    {this.renderConfigureButton()}
                </form>
            </div>
        </>
    );

    renderModal() {
        const { dispatch } = this.props;

        const { displayModal, deviceToDelete } = this.state;

        const { idDevice, model } = deviceToDelete;

        const deleteDevice = (e) => {
            e.preventDefault();
            dispatch(pushNotificationsActions.pushNotificationsDeleteUserDevice(idDevice, model));

            this.setState({
                displayModal: false,
                deviceToDelete: {},
            });
        };

        return (
            <SimpleModal
                dispatch={dispatch}
                isDisplayed={displayModal}
                closeModal={this.hideModal}
                modalIcon="images/warningIcon.svg"
                modalMainText="settings.pushNotifications.modalText"
                buttonAction={deleteDevice}>
                <div className="device-name ui-mt-7">
                    {idDevice && (
                        <>
                            <I18n id={`devices.apple.identifier.${model}`} defaultValue={model} />
                            <span>&nbsp;- {idDevice.substring(idDevice.length - 4, idDevice.length)}</span>
                        </>
                    )}
                </div>
            </SimpleModal>
        );
    }

    renderActivationModal() {
        const { dispatch } = this.props;
        const { displayActivationModal } = this.state;
        return (
            <SimpleModal
                dispatch={dispatch}
                isDisplayed={displayActivationModal}
                closeModal={() => this.setState({ displayActivationModal: false })}
                modalIcon="images/coloredIcons/notifications.svg"
                modalMainText="settings.pushNotifications.device.register.title"
                buttonAction={this.registerDevice}
            />
        );
    }

    render() {
        const { fetching, history, isMobile } = this.props;

        const { displayModal, displayActivationModal } = this.state;

        return (
            <React.Fragment>
                <Notification scopeToShow="pushNotifications" />
                {!fetching && (
                    <>
                        {!isMobile ? (
                            <Head
                                propsClassNames="title-size"
                                headerClassName={!isMobile && "mt-2"}
                                onBack={history.goBack}
                            />
                        ) : (
                            <Head
                                onBack={history.goBack}
                                showLogo
                                arrowWhite
                                additionalClassName={isMobile && "blue-main-header-mobile"}
                            />
                        )}
                    </>
                )}
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
                {displayModal && this.renderModal()}
                {displayActivationModal && this.renderActivationModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(PushNotifications);
