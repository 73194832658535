import React from "react";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { bool, shape } from "prop-types";
import HeaderInfo from "../_components/HeaderInfo";

function TransferHeaderInfo({ comeFromProcessed, headInfo }) {
    if (comeFromProcessed) {
        const processDate = headInfo?.processDate;

        return <HeaderInfo paymentType="transfer" processDate={processDate} />;
    }

    const date = headInfo?.date;
    const totalAmount = headInfo?.totalAmount;

    return (
        <>
            {date && (
                <div className="data-wrapper">
                    <I18n
                        id="forms.payments.suppliers.detail.transfer.headerInfo.creationDate.label"
                        componentProps={{ className: "data-label" }}
                    />
                    <FormattedDate className="data-bold" date={date} />
                </div>
            )}
            <div className="data-wrapper">
                <I18n
                    id="forms.payments.suppliers.detail.transfer.headerInfo.typeFile.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="data-bold">
                    <I18n id="forms.payments.suppliers.typeFile.transfer.label" />
                </div>
            </div>
            <div className="data-wrapper">
                <I18n
                    id="forms.payments.suppliers.detail.transfer.headerInfo.numberOfPayments.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="data-bold">{headInfo.totalLines}</div>
            </div>
            <div className="data-wrapper">
                <I18n
                    id="forms.payments.suppliers.detail.transfer.headerInfo.debitAccount.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="data-bold">{headInfo.debitAccount}</div>
            </div>
            <div className="data-wrapper">
                <I18n
                    id="forms.payments.suppliers.detail.transfer.headerInfo.totalAmount.label"
                    componentProps={{ className: "data-label" }}
                />
                <FormattedAmount
                    className="data-bold"
                    currency={totalAmount?.currency}
                    quantity={totalAmount?.quantity}
                />
            </div>
            <div className="data-wrapper">
                <I18n
                    id="forms.payments.suppliers.detail.transfer.headerInfo.typeOfRegister.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="data-bold">{headInfo.typeRegister}</div>
            </div>
        </>
    );
}

TransferHeaderInfo.propTypes = {
    comeFromProcessed: bool.isRequired,
    headInfo: shape({}).isRequired,
};

export default TransferHeaderInfo;
