import React from "react";
import GenericItem from "components/GenericItem/GenericItem";
import Dropdown from "pages/_components/Dropdown";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import * as stringUtils from "util/string";
import Button from "pages/_components/Button";
import { actions as supplierActions } from "reducers/paymentToSuppliers";
import CheckBoxForItem from "../_components/CheckBoxForItem";

const TransferItem = (props) => {
    const { dispatch, detail, isMobile, headInfo, comeFromProcessed } = props;

    const renderCheckbox = () => (
        <CheckBoxForItem detail={detail} paymentType="transfer" keyForChechBox={detail.transferNumber} />
    );

    const renderItem = () => {
        if (isMobile) {
            return (
                <>
                    <GenericItem
                        options={
                            <Dropdown
                                image="images/ui-icons/ui-dropdown-mobile.svg"
                                label=""
                                bsStyle="link"
                                className="btn-small"
                                pullRight
                            />
                        }>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.operatingCode.label" />
                            </div>
                            <div className="data-text">
                                <I18n
                                    id={`supplier.detail.transfer.operatingCode.${headInfo.transferType}${detail.operatingCode}`}
                                />
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.cuit.label" />
                            </div>
                            <div className="data-text">{detail.cuit}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.name.label" />
                            </div>
                            <div className="data-text">{detail.name}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.cbu.label" />
                            </div>
                            <div className="data-text">{detail.cbu}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.reference.label" />
                            </div>
                            <div className="data-text">{detail.unequivocalReference}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.amount.label" />
                                <FormattedAmount currency={headInfo.currency} quantity={detail.quantity} />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                <Table.Data align="left">
                    <I18n
                        id={`supplier.detail.transfer.operatingCode.${headInfo.transferType}${detail.operatingCode}`}
                    />
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.cuit}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.name}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.cbu}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.unequivocalReference}</span>
                </Table.Data>

                <Table.Data align="left">
                    <FormattedAmount currency={headInfo.currency} quantity={detail.quantity} />
                </Table.Data>
            </Table.Row>
        );
    };

    const renderProcessedItem = () => {
        const formattedCuit = `${stringUtils.formatCuit(detail.cuit)}`;
        if (isMobile) {
            return (
                <>
                    <GenericItem
                        options={
                            <Dropdown
                                image="images/ui-icons/ui-dropdown-mobile.svg"
                                label=""
                                bsStyle="link"
                                className="btn-small"
                                pullRight
                            />
                        }>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.operatingCode.label" />
                            </div>
                            <div className="data-text">
                                <I18n id={`supplier.detail.transfer.operatingCode.${detail.operatingCode}`} />
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.receiver.label" />
                            </div>
                            <div className="data-text">
                                {formattedCuit} <br /> {detail.name}
                            </div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.cbu.label" />
                            </div>
                            <div className="data-text">{detail.cbu}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.reference.label" />
                            </div>
                            <div className="data-text">{detail.transferNumber}</div>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id="forms.payments.suppliers.detail.transfer.header.amount.label" />
                                <FormattedAmount currency={headInfo.currency} quantity={detail.quantity} />
                            </div>
                        </div>
                    </GenericItem>
                </>
            );
        }

        return (
            <Table.Row additionalClassName="no-hover">
                {renderCheckbox()}
                <Table.Data align="left">
                    <I18n id={`supplier.detail.transfer.operatingCode.${detail.operatingCode}`} />
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {formattedCuit} <br /> {detail.name}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.cbu}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{detail.transferNumber}</span>
                </Table.Data>

                <Table.Data align="left">
                    <FormattedAmount currency={headInfo.currency} quantity={detail.quantity} />
                </Table.Data>
                <Table.Data className="table-data-icons" align="right">
                    <div className="icons-container">
                        <Button
                            type="button"
                            className="btn-only-icon list-item-icon--download"
                            block={false}
                            onClick={() => {
                                dispatch(
                                    supplierActions.downloadVoucherDetails("transfer", "single", headInfo, [detail]),
                                );
                            }}
                            image="images/download.svg"
                        />
                    </div>
                </Table.Data>
            </Table.Row>
        );
    };

    if (comeFromProcessed) {
        return renderProcessedItem();
    }

    return renderItem();
};

TransferItem.propTypes = {
    dispatch: func.isRequired,
    headInfo: shape({}).isRequired,
    detail: shape({}).isRequired,
    isMobile: bool,
    comeFromProcessed: bool,
};

TransferItem.defaultProps = {
    isMobile: false,
    comeFromProcessed: false,
};

export default connect()(TransferItem);
