import { takeLatest, put, call } from "redux-saga/effects";

import { types, actions } from "reducers/creditCard";
import * as creditCardmw from "middleware/creditCard";
import { downloadPdf, downloadXls } from "util/download";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailsRequest),
    takeLatest(types.MOVEMENTS_REQUEST, movementsRequest),
    takeLatest(types.FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovementsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
];

export default sagas;

function* detailsRequest({ id, filters }) {
    const detailResponse = yield call(creditCardmw.detailsRequest, id);
    const movementsResponse = yield call(creditCardmw.movementsRequest, id, filters);

    if (detailResponse.type === "W" || movementsResponse.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { creditCard } = detailResponse.data.data;
        const { statements, pageNumber, moreStatements, totalCount, totalCurrentPeriod } = movementsResponse.data.data;

        yield put(
            actions.detailSuccess(creditCard, statements, pageNumber, moreStatements, totalCount, totalCurrentPeriod),
        );
    }
}

function* movementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.movementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod } = response.data.data;
        yield put(actions.movementsSuccess(statements, moreStatements, totalCount, totalCurrentPeriod));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* fetchMoreMovementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.fetchMoreMovementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod } = response.data.data;

        yield put(
            actions.fetchMoreMovementsSuccess(
                statements,
                moreStatements,
                totalCount,
                totalCurrentPeriod,
                filters.pageNumber,
            ),
        );
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* downloadMovements({ idCreditCard, filters, format }) {
    const response = yield call(creditCardmw.downloadMovements, idCreditCard, filters, format);

    if (response.type === "W") {
        yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
        );
    } else {
        const { content, fileName } = response.data.data;
        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }
        yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    }
}
