import React from "react";
import { shape, string } from "prop-types";
import Gravatar from "react-gravatar";

function Avatar({ user }) {
    const userInitials = user.userFullName
        .match(/\b(\w)/g)
        .join("")
        .slice(0, 2);

    let content = null;
    if (userInitials) {
        content = <p className="avatar-text">{userInitials}</p>;
    } else if (user.email) {
        content = (
            <figure>
                <Gravatar
                    alt={user.userFullName}
                    email={user.email}
                    default="404"
                    onError={() => userInitials}
                    className="rounder-content"
                />
            </figure>
        );
    }

    return <div className="avatar-userInitials">{content}</div>;
}

Avatar.propTypes = {
    user: shape({
        userFullName: string.isRequired,
        defaultAvatarId: string.isRequired,
    }).isRequired,
};

export default Avatar;
