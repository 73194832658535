export const types = {
    GET_QR_ACCOUNT_REQUEST: "qr/GET_QR_ACCOUNT_REQUEST",
    GET_QR_ACCOUNT_FAILURE: "qr/GET_QR_ACCOUNT_FAILURE",
    GET_QR_ACCOUNT_SUCCESS: "qr/GET_QR_ACCOUNT_SUCCESS",
    DOWNLOAD_QR_ACCOUNT_REQUEST: "qr/DOWNLOAD_QR_ACCOUNT_REQUEST",
    DOWNLOAD_QR_ACCOUNT_SUCCESS: "qr/DOWNLOAD_QR_ACCOUNT_SUCCESS",
    HIDE_GET_QR_ACCOUNT_REQUEST: "qr/HIDE_GET_QR_ACCOUNT_REQUEST",
    QR_ACCOUNT_LIST_REQUEST: "qr/QR_ACCOUNT_LIST_REQUEST",
    QR_ACCOUNT_LIST_SUCCESS: "qr/QR_ACCOUNT_LIST_SUCCESS",
    QR_ACCOUNT_LIST_FAILURE: "qr/QR_ACCOUNT_LIST_FAILURE",
    QR_FILTER_CHARGES_REQUEST: "qr/QR_FILTER_CHARGES_REQUEST",
    QR_FILTER_CHARGES_SUCCESS: "qr/QR_FILTER_CHARGES_SUCCESS",
    QR_FILTER_CHARGES_FAILURE: "qr/QR_FILTER_CHARGES_FAILURE",
    QR_FILTER_CERTIFICATES_REQUEST: "qr/QR_FILTER_CERTIFICATES_REQUEST",
    QR_FILTER_CERTIFICATES_SUCCESS: "qr/QR_FILTER_CERTIFICATES_SUCCESS",
    QR_FILTER_CERTIFICATES_FAILURE: "qr/QR_FILTER_CERTIFICATES_FAILURE",
    QR_SET_FILTERS: "qr/SET_FILTERS",
    QR_RESET_FILTERS: "qr/RESET_FILTERS",
    QR_GET_CERTIFICATE_REQUEST: "qr/GET_CERTIFICATE_REQUEST",
    QR_GET_CERTIFICATE_SUCCESS: "qr/GET_CERTIFICATE_SUCCESS",
    QR_GET_CERTIFICATE_FAILURE: "qr/GET_CERTIFICATE_FAILURE",
    QR_VALIDATION_TYC: "qr/QR_VALIDATION_TYC",
};
export const INITIAL_STATE = {
    fetchingDownloadQR: false,
    fetchingGetCertificate: false,
    fetching: false,
    isFirstFetching: true,
    showQRmodal: false,
    qrAccountBase64: null,
    urlQR: null,
    accountList: [],
    chargeList: [],
    certificateList: [],
    moreItems: false,
    pageNumber: 1,
    filters: {
        selectedAccount: undefined,
        status: "ALL",
        dateFrom: null,
        dateTo: null,
        toggleState: "charges",
    },
    urlCertificate: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        type,
        qrAccountBase64,
        urlQR,
        accountList,
        chargeList,
        pageNumber,
        moreItems,
        certificateList,
        urlCertificate,
    } = action;

    switch (type) {
        case types.GET_QR_ACCOUNT_REQUEST:
            return {
                ...state,
                fetchingDownloadQR: true,
            };
        case types.GET_QR_ACCOUNT_FAILURE:
            return {
                ...state,
                fetchingDownloadQR: false,
                showQRmodal: false,
            };
        case types.GET_QR_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetchingDownloadQR: false,
                showQRmodal: true,
                qrAccountBase64,
                urlQR,
            };
        case types.DOWNLOAD_QR_ACCOUNT_REQUEST:
            return {
                ...state,
                fetchingDownloadQR: true,
                showQRmodal: false,
            };
        case types.DOWNLOAD_QR_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetchingDownloadQR: false,
            };
        case types.HIDE_GET_QR_ACCOUNT_REQUEST:
            return {
                ...state,
                showQRmodal: false,
            };

        case types.QR_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                fetchingDownloadQR: false,
                fetchingGetCertificate: false,
                isFirstFetching: true,
            };
        case types.QR_ACCOUNT_LIST_SUCCESS:
            return {
                ...state,
                fetching: false,
                accountList,
                filters: INITIAL_STATE.filters,
                isFirstFetching: false,
            };
        case types.QR_ACCOUNT_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
                isFirstFetching: false,
            };
        case types.QR_RESET_FILTERS:
            return {
                ...state,
                filters: INITIAL_STATE.filters,
            };
        case types.QR_SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case types.QR_FILTER_CHARGES_REQUEST:
            return {
                ...state,
                fetching: true,
                pageNumber: 1,
                isFirstFetching: true,
            };
        case types.QR_FILTER_CHARGES_SUCCESS:
            return {
                ...state,
                fetching: false,
                chargeList,
                moreItems,
                pageNumber,
                isFirstFetching: false,
            };
        case types.QR_FILTER_CHARGES_FAILURE:
            return {
                ...state,
                fetching: false,
                isFirstFetching: false,
            };

        case types.QR_FILTER_CERTIFICATES_REQUEST:
            return {
                ...state,
                fetching: true,
                pageNumber: 1,
            };
        case types.QR_FILTER_CERTIFICATES_SUCCESS:
            return {
                ...state,
                fetching: false,
                certificateList,
                moreItems,
                pageNumber,
            };
        case types.QR_FILTER_CERTIFICATES_FAILURE:
            return {
                ...state,
                fetching: false,
            };

        case types.QR_GET_CERTIFICATE_REQUEST:
            return {
                ...state,
                fetchingGetCertificate: true,
                urlCertificate: null,
            };
        case types.QR_GET_CERTIFICATE_SUCCESS:
            return {
                ...state,
                fetchingGetCertificate: false,
                urlCertificate,
            };

        case types.QR_GET_CERTIFICATE_FAILURE:
            return {
                ...state,
                fetchingGetCertificate: false,
                urlCertificate: null,
            };
        default:
            return state;
    }
};

export const actions = {
    getQrAccountRequest: (cbuAccount, idAccount) => ({
        type: types.GET_QR_ACCOUNT_REQUEST,
        cbuAccount,
        idAccount,
    }),
    downloadQrAccountRequest: (qrBase64, cbuAccount) => ({
        type: types.DOWNLOAD_QR_ACCOUNT_REQUEST,
        qrBase64,
        cbuAccount,
    }),
    hideQrModal: () => ({
        type: types.HIDE_GET_QR_ACCOUNT_REQUEST,
    }),

    loadAccountListForQR: () => ({
        type: types.QR_ACCOUNT_LIST_REQUEST,
    }),

    filterChargesForQR: (selectedAccount, status, dateFrom, dateTo) => ({
        type: types.QR_FILTER_CHARGES_REQUEST,
        selectedAccount,
        status,
        dateFrom,
        dateTo,
    }),

    filterCertificatesForQR: (selectedAccount, dateFrom, dateTo) => ({
        type: types.QR_FILTER_CERTIFICATES_REQUEST,
        selectedAccount,
        dateFrom,
        dateTo,
    }),

    resetFilters: () => ({
        type: types.QR_RESET_FILTERS,
    }),

    // setFilters: (selectedAccount, status, dateFrom, dateTo, toggleState) => ({
    setFilters: (filters) => ({
        type: types.QR_SET_FILTERS,
        filters,
        // selectedAccount,
        // status,
        // dateFrom,
        // dateTo,
        // toggleState,
    }),
    getCertificate: (uuid, idAccount) => ({
        type: types.QR_GET_CERTIFICATE_REQUEST,
        uuid,
        idAccount,
    }),
    validateTyC: (cbuAccount) => ({
        type: types.QR_VALIDATION_TYC,
        cbuAccount,
    }),
};
export const selectors = {
    showQRmodal: ({ qr }) => qr.showQRmodal,
    getUrlQR: ({ qr }) => qr.urlQR,
    getQrAccountBase64: ({ qr }) => qr.qrAccountBase64,
    getFetchingDownloadQR: ({ qr }) => qr.fetchingDownloadQR,
    getFetching: ({ qr }) => qr.fetching,
    getAccountListForQR: ({ qr }) => qr.accountList,
    getChargeList: ({ qr }) => qr.chargeList,
    getCertificateList: ({ qr }) => qr.certificateList,
    getFilters: ({ qr }) => qr.filters,
    getFetchingCertificate: ({ qr }) => qr.fetchingGetCertificate,
    getPageNumber: ({ qr }) => qr.pageNumber,
    getFirstFetching: ({ qr }) => qr.isFirstFetching,
};
