import moment from "moment";
import DatePicker from "react-datepicker";
import * as i18n from "util/i18n";

class CustomDatePicker extends DatePicker {
    componentDidMount() {
        moment.locale(i18n.getLang(), { week: { dow: 0 } });
    }

    deferFocusInput = () => {
        this.cancelFocusInput();
    };

    componentDidUpdate(prevState) {
        const { open } = this.state;

        if (open && open !== prevState.open) {
            const modalContainer = document.querySelector(".bigModal-container");
            const datePickerWrapper = modalContainer?.querySelector(".react-datepicker-popper");

            if (modalContainer) {
                setTimeout(() => {
                    datePickerWrapper.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 10);
            }
        }
    }
}

export default CustomDatePicker;
