import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, number, shape } from "prop-types";

import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

import { get as getConfig } from "util/config";

class ChangeSecuritySeal extends Component {
    static propTypes = {
        currentSecuritySeal: number,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        securitySeals: shape({}).isRequired,
        history: func.isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        currentSecuritySeal: null,
    };

    state = {
        showMore: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealPre());
    }

    getContent() {
        const { securitySeals, currentSecuritySeal } = this.props;

        const { showMore } = this.state;

        let shownImages = 0;

        const imagePerPage = getConfig("security_image.imagePerPage");

        return (
            <div className="container-center">
                <div className="title-left-header">
                    <I18n id="settings.changeSecuritySeal" component="h1" />
                </div>
                <form className="form-content">
                    <div className="form-text-info">
                        <I18n id="settings.changeSecuritySeal.title" component="p" />
                    </div>

                    <div className="securitySealCarrousel-container">
                        <div className="securitySealCarrousel__container-images">
                            {Object.entries(securitySeals).map(([id, securitySeal]) => {
                                const btnClass = classNames(
                                    `btn-image security-image ${id === currentSecuritySeal ? "selected" : ""}`,
                                );
                                shownImages += 1;
                                if (showMore || shownImages <= imagePerPage) {
                                    return (
                                        <Button
                                            className={btnClass}
                                            id={id}
                                            label=""
                                            onClick={() => {
                                                this.handleClick(id);
                                            }}>
                                            <figure>
                                                <img src={securitySeal} alt="securitySealAlternativeText" />
                                            </figure>
                                        </Button>
                                    );
                                }
                                return null;
                            })}
                        </div>

                        <Button
                            label={showMore ? "securitySeals.showLess" : "securitySeals.showMore"}
                            image={showMore ? "images/lessWhite.svg" : "images/plusWhite.svg"}
                            type="button"
                            bsStyle="secondary"
                            loading={false}
                            onClick={this.handleShowMore}
                        />
                    </div>
                </form>
            </div>
        );
    }

    handleShowMore = () => {
        const { showMore } = this.state;

        this.setState({ showMore: !showMore });
    };

    handleClick = (id) => {
        const { dispatch } = this.props;

        dispatch(settingsActions.changeSecuritySealConfirmationPre(id));
    };

    render() {
        const { fetching, history, isMobile } = this.props;
        return (
            <Fragment>
                {!fetching && (
                    <>
                        {!isMobile ? (
                            <Head
                                propsClassNames="title-size"
                                headerClassName={!isMobile && "mt-2"}
                                onBack={history.goBack}
                            />
                        ) : (
                            <Head
                                onBack={history.goBack}
                                showLogo
                                arrowWhite
                                additionalClassName="blue-main-header-mobile"
                            />
                        )}
                    </>
                )}
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeals: settingsSelectors.getSecuritySeals(state),
    fetching: settingsSelectors.isFetching(state),
    currentSecuritySeal: sessionSelectors.getUserSecuritySeal(state),
});

export default connect(mapStateToProps)(ChangeSecuritySeal);
