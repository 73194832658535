import { bool, shape, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "reducers/types/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as stringUtils from "util/string";
import FormTransition from "../../_components/FormTransition";
import { selectors as echeqsSelectors } from "../../../../reducers/echeqs";
import CustomerEcheqData from "./_components/CustomerEcheqData";

const ID_ACTIVITY = "echeqs.giveEcheq.send";
const ID_FORM = "forms.echeqs.giveEcheq";
const GiveEcheq = (props) => {
    const {
        echeq,
        data,
        dispatch,
        transaction,
        mode,
        location,
        currentLang,
        fromBackoffice,
        dataBeneficiaryAssignment,
        isMobile,
        errors,
    } = props;
    const { idTransaction, idTransactionStatus } = transaction;
    const hasBeneficiaryAssignment =
        Object.keys(data).length !== 0 || Object.keys(dataBeneficiaryAssignment).length !== 0;
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};
    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        cheque_tipo,
        cheque_caracter,
        total_cesiones,
        tenencia_beneficiario_documento,
        tenencia_beneficiario_nombre,
    } = echeq;

    const [guarantorData, setGuarantorData] = useState({
        document_number_assignment: data?.document_number_assignment || dataBeneficiaryAssignment?.documento,
        document_type_assignment: data?.document_type_assignment || dataBeneficiaryAssignment?.documento_tipo,
        name_beneficiary_assignment: data?.name_beneficiary_assignment || dataBeneficiaryAssignment?.nombre,
    });

    useEffect(() => {
        if (Object.keys(dataBeneficiaryAssignment).length) {
            setGuarantorData({
                document_number_assignment: dataBeneficiaryAssignment?.documento,
                document_type_assignment: dataBeneficiaryAssignment?.documento_tipo,
                name_beneficiary_assignment: dataBeneficiaryAssignment?.nombre,
            });
        }
    }, [dataBeneficiaryAssignment]);

    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const renderFields = (setFieldValue) => {
        function searchSuccessAction(bankedCustomerInfoData) {
            setFieldValue("bankedCustomerInfo", bankedCustomerInfoData);
        }

        function getDataFromComponent(searchInfo) {
            setGuarantorData({
                document_type_assignment: searchInfo.documentType,
                document_number_assignment: searchInfo.documentNumber,
            });
        }

        return (
            <>
                {mode === "edit" && (
                    <div className="form-text-info">
                        <I18n id={`${ID_FORM}.message.label`} />
                    </div>
                )}
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.dataEcheqTitle.label`} />
                    </span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.idEcheq.label`} />
                    </span>
                    <span className="data-text">{data?.cheque_id || cheque_id}</span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.echeqNumber.label`} />
                    </span>
                    <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.echeqType.label`} />
                    </span>
                    <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.echeqCharacter.label`} />
                    </span>
                    <span className="data-text">{data?.cheque_caracter || cheque_caracter}</span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.CUIT/CUILIssuer.label`} />
                    </span>
                    <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.issuerName.label`} />
                    </span>
                    <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                    </span>
                    <span className="data-text">
                        {data?.tenencia_beneficiario_nombre || tenencia_beneficiario_nombre}
                    </span>
                </div>
                {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.state.label`} />
                        </span>
                        <span className="data-text">{data?.estado || estado}</span>
                    </div>
                )}
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.amount.label`} />
                    </span>
                    <FormattedAmount
                        quantity={data?.amount?.quantity || monto}
                        currency={data?.amount?.currency || emisor_moneda}
                    />
                </div>
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.paymentDate.label`} />
                    </span>
                    <FormattedDate date={data?.fecha_pago || fecha_pago} />
                </div>

                <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-3" : "ui-mb-7"}`} />

                <div
                    className={`data-wrapper inline ${!hasBeneficiaryAssignment ? "ui-mb-3" : ""} ${
                        mode === "view" ? "ui-mt-0" : ""
                    }`}>
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.beneficiaryAssignment.${mode !== "edit" ? "view." : ""}title.label`} />
                    </span>
                </div>
                {hasBeneficiaryAssignment ? (
                    <>
                        <div className="data-wrapper inline">
                            <span className="data-label">
                                <I18n id={`${ID_FORM}.beneficiaryAssignment.label`} />
                            </span>
                            <span className="data-text">
                                {data?.name_beneficiary_assignment || dataBeneficiaryAssignment?.nombre || "-"}
                            </span>
                        </div>
                        <div className="data-wrapper inline">
                            <span className="data-label">
                                <I18n id={`${ID_FORM}.documentTypeAndNumberAssignment.label`} />
                            </span>
                            <span className="data-text">
                                {stringUtils.returnTypeAndNumberDocument(
                                    data?.document_type_assignment || dataBeneficiaryAssignment?.documento_tipo,
                                    data?.document_number_assignment || dataBeneficiaryAssignment?.documento,
                                ) || "-"}
                            </span>
                        </div>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="domicile"
                            name="domicile"
                            idField="domicileAssignment"
                            isRequired
                            editing={mode === "edit"}
                            idActivity={ID_ACTIVITY}
                            value={data?.domicile || ""}
                        />
                    </>
                ) : (
                    <>
                        <CustomerEcheqData
                            idActivity={ID_ACTIVITY}
                            genericProps={genericProps}
                            isMobile={isMobile}
                            searchSuccessAction={searchSuccessAction}
                            data={data}
                            buttonLabel={`${ID_FORM}.requestBeneficiary.button.label`}
                            sendDataBack={getDataFromComponent}
                        />
                    </>
                )}
            </>
        );
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id,
            cheque_numero,
            cheque_tipo,
            cheque_caracter,
            emisor_cuit,
            emisor_razon_social,
            tenencia_beneficiario_nombre,
            tenencia_beneficiario_documento,
            estado,
            amount: {
                quantity: monto,
                currency: emisor_moneda,
            },
            fecha_pago,
            ...guarantorData,
            total_cesiones: data?.total_cesiones || total_cesiones,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
        errorsAux: errors,
    };
    return <FormTransition {...props} {...formProps} />;
};

GiveEcheq.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
    dataBeneficiaryAssignment: shape({}).isRequired,
    isMobile: bool,
    errors: shape({}).isRequired,
};

GiveEcheq.defaultProps = {
    fromBackoffice: false,
    preData: null,
    isMobile: false,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    dataBeneficiaryAssignment: echeqsSelectors.getBankedCustomerInfo(state),
    errors: echeqsSelectors.getErrors(state),
});

export default compose(connect(mapStateToProps), withRouter)(GiveEcheq);
