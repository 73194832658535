import React from "react";
import { string, shape } from "prop-types";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18nUtils from "../../../util/i18n";

const PendingSignature = ({ signature, signatureLevel, customClass }) => {
    const { firstName, lastName, date, fullName } = signature;

    const username = fullName || (lastName ? `${lastName}, ${firstName}` : firstName);

    const hasSignature = !!Object.keys(signature).length;

    return (
        <div className={`data-wrapper inline ${customClass}`}>
            <Field
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get("forms.transaction.ticket.pendingSignatures.signatureLevel", "", {
                            SIGNATURE_LEVEL: signature.signatureLevel || signatureLevel,
                        }),
                    },
                ]}
                key="signatureCheck"
                name="signatureCheck"
                idField="signatureCheck"
                renderAs="check"
                mode="edit"
                value={hasSignature && "1"}
                disabled
                isChecked={hasSignature}
                isRequired
            />
            <span className="data-text">
                {username && `${username} `}
                {date && <FormattedDate date={date} dateFormat="MM/DD/YYYY HH:mm" />}
            </span>
        </div>
    );
};

PendingSignature.propTypes = {
    signature: shape({}),
    signatureLevel: string,
    customClass: string,
};

PendingSignature.defaultProps = {
    signature: {},
    signatureLevel: "",
    customClass: "",
};

export default PendingSignature;
