import { createReducer, makeActionCreator } from "util/redux";
import { types as productsTypes } from "reducers/products";
import formTypes from "reducers/types/form";
import { LOCATION_CHANGE } from "react-router-redux";

export const types = {
    LIST_FIXED_TERM_DEPOSITS_REQUEST: "investments/LIST_FIXED_TERM_DEPOSITS_REQUEST",
    LIST_FIXED_TERM_DEPOSITS_FAILURE: "investments/LIST_FIXED_TERM_DEPOSITS_FAILURE",
    LIST_FIXED_TERM_DEPOSITS_SUCCESS: "investments/LIST_FIXED_TERM_DEPOSITS_SUCCESS",
    LIST_FIXED_TERM_MORE_REQUEST: "investments/LIST_FIXED_TERM_MORE_REQUEST",
    LIST_FIXED_TERM_MORE_SUCCESS: "investments/LIST_FIXED_TERM_MORE_SUCCESS",
    SET_SELECTED_DEPOSIT: "investments/SET_SELECTED_DEPOSIT",
    READ_DEPOSIT_DETAIL_REQUEST: "investments/READ_DEPOSIT_DETAIL_REQUEST",
    READ_DEPOSIT_DETAIL_FAILURE: "investments/READ_DEPOSIT_DETAIL_FAILURE",
    READ_DEPOSIT_DETAIL_SUCCESS: "investments/READ_DEPOSIT_DETAIL_SUCCESS",
    TOGGLE_OPTIONS: "investments/TOGGLE_OPTIONS",
    CLOSE_OPTIONS: "investments/CLOSE_OPTIONS",
    RESET_PAGE_NUMBER: "investments/RESET_PAGE_NUMBER",

    SIMULATE_DEPOSIT_REQUEST: "investments/SIMULATE_DEPOSIT_REQUEST",
    SIMULATE_DEPOSIT_SUCCESS: "investments/SIMULATE_DEPOSIT_SUCCESS",
    SIMULATE_DEPOSIT_FAILURE: "investments/SIMULATE_DEPOSIT_FAILURE",
    PREVIEW_FORM_REQUEST: "investments/PREVIEW_FORM_REQUEST",
    SHAREHOLDER_ACCOUNTS_REQUESTED: "investments/SHAREHOLDER_ACCOUNTS_REQUESTED",
    SHAREHOLDER_ACCOUNTS_REQUEST_FAILED: "investments/SHAREHOLDER_ACCOUNTS_REQUEST_FAILED",
    SHAREHOLDER_ACCOUNTS_RECEIVED: "investments/SHAREHOLDER_ACCOUNTS_RECEIVED",
    MUTUAL_FUNDS_REQUESTED: "investments/MUTUAL_FUNDS_REQUESTED_REQUESTED",
    MUTUAL_FUNDS_REQUEST_FAILED: "investments/MUTUAL_FUNDS_REQUEST_FAILED",
    MUTUAL_FUNDS_RECEIVED: "investments/MUTUAL_FUNDS_RECEIVED",
    MUTUAL_FUNDS_TOGGLE_OPTIONS: "investments/MUTUAL_FUNDS_TOGGLE_OPTIONS",
    FUND_OFFERS_REQUESTED: "investments/FUND_OFFERS_REQUESTED",
    FUND_OFFERS_REQUEST_FAILED: "investments/FUND_OFFERS_REQUEST_FAILED",
    FUND_OFFERS_RECEIVED: "investments/FUND_OFFERS_RECEIVED",
    AVAILABLE_FUNDS_REQUESTED: "investments/AVAILABLE_FUNDS_REQUESTED",
    AVAILABLE_FUNDS_REQUEST_FAILED: "investments/AVAILABLE_FUNDS_REQUEST_FAILED",
    AVAILABLE_FUNDS_RECEIVED: "investments/AVAILABLE_FUNDS_RECEIVED",
    OPERATING_FUNDS_REQUESTED: "investments/OPERATING_FUNDS_REQUESTED",
    OPERATING_FUNDS_REQUEST_FAILED: "investments/OPERATING_FUNDS_REQUEST_FAILED",
    OPERATING_FUNDS_RECEIVED: "investments/OPERATING_FUNDS_RECEIVED",

    SET_SELECTED_INVESTMENT: "investments/SET_SELECTED_INVESTMENT",
    CLEAR_SELECTED_INVESTMENT: "investments/CLEAR_SELECTED_INVESTMENT",
    FUND_DATA_CLEARED: "investments/FUND_DATA_CLEARED",
    FUND_MOVEMENTS_REQUESTED: "investments/FUND_MOVEMENTS_REQUESTED",
    FUND_MOVEMENTS_REQUEST_FAILED: "investments/FUND_MOVEMENTS_REQUEST_FAILED",
    FUND_MOVEMENTS_RECEIVED: "investments/FUND_MOVEMENTS_RECEIVED",
    SET_MOVEMENT_FILTERS: "investments/SET_MOVEMENT_FILTERS",
    MOVEMENTS_DATA_CLEARED: "investments/MOVEMENTS_DATA_CLEARED",

    CANCEL_DEPOSIT_RENEWAL_PREVIEW_REQUEST: "investments/CANCEL_DEPOSIT_RENEWAL_PREVIEW_REQUEST",
    CANCEL_DEPOSIT_RENEWAL_SEND_SUCCESS: "investments/CANCEL_DEPOSIT_RENEWAL_SEND_SUCCESS",
    SET_FUND_LIST_FILTERS: "investments/SET_FUND_LIST_FILTERS",

    DOWNLOAD_FUND_MOVEMENTS_REQUESTED: "investments/DOWNLOAD_FUND_MOVEMENTS_REQUESTED",
    DOWNLOAD_FUND_MOVEMENTS_REQUEST_FAILED: "investments/DOWNLOAD_FUND_MOVEMENTS_REQUEST_FAILED",
    DOWNLOAD_FUND_MOVEMENTS_RECEIVED: "investments/DOWNLOAD_FUND_MOVEMENTS_RECEIVED",

    VALIDATION_ACCESS_REQUESTED: "investments/VALIDATION_ACCESS_REQUESTED",
    VALIDATION_ACCESS_FAILED: "investments/VALIDATION_ACCESS_FAILED",
    VALIDATION_ACCESS_RECEIVED: "investments/VALIDATION_ACCESS_RECEIVED",

    INVERSOR_PROFILE_REQUESTED: "investments/INVERSOR_PROFILE_REQUESTED",
    INVERSOR_PROFILE_FAILED: "investments/INVERSOR_PROFILE_FAILED",
    INVERSOR_PROFILE_RECEIVED: "investments/INVERSOR_PROFILE_RECEIVED",

    CALCULATE_INVESTOR_PROFILE_REQUEST: "investments/CALCULATE_INVESTOR_PROFILE_REQUEST",
    CALCULATE_INVESTOR_PROFILE_SUCCESS: "investments/CALCULATE_INVESTOR_PROFILE_SUCCESS",
    CALCULATE_INVESTOR_PROFILE_FAILURE: "investments/CALCULATE_INVESTOR_PROFILE_FAILURE",
    RESET_CALCULATED_INVESTOR_PROFILE: "investments/RESET_CALCULATED_INVESTOR_PROFILE",
    SAVE_INVESTOR_PROFILE: "investments/SAVE_INVESTOR_PROFILE",
    DOWNLOAD_INVESTOR_PROFILE_REQUEST: "investments/DOWNLOAD_INVESTOR_PROFILE_REQUEST",
    DOWNLOAD_INVESTOR_PROFILE_SUCCESS: "investments/DOWNLOAD_INVESTOR_PROFILE_SUCCESS",
    DOWNLOAD_INVESTOR_PROFILE_FAILURE: "investments/DOWNLOAD_INVESTOR_PROFILE_FAILURE",

    RESET_FILTERS: "investments/RESET_FILTERS",
};

export const INITIAL_STATE = {
    depositsList: [],
    isFetching: false,
    isFirstFetching: true,
    isFetchingDownload: false,
    depositDetail: {},
    selectedDeposit: {},
    isOptionsVisible: false,
    pageNumber: 1,
    moreDeposits: false,
    hasSimulatedOnce: false,
    errors: {},
    shareholderAccounts: [],
    mutualFunds: [],
    filters: { accountNumber: "", date: "" },
    toggleState: "holding",
    fundOffers: [],
    availableFunds: [],
    operationFunds: [],
    operatingFunds: [],
    investment: {},
    accountSelected: null,
    movements: [],
    movementFilters: { shareholderAccount: "", mutualFund: {}, dateFrom: "", dateTo: "" },
    moreMovements: false,
    cancelledRenewalIdProduct: null,
    isDownloading: false,
    isFetchingMovements: false,
    isAccessAllowed: false,
    investorProfile: null,
    investorProfileCalculated: null,
    filteredAnswers: null,
    formikBag: null,
    investorProfileFile: {
        fileName: null,
        base64: null,
    },
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_FIXED_TERM_DEPOSITS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LIST_FIXED_TERM_DEPOSITS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_FIXED_TERM_DEPOSITS_SUCCESS]: (state, action) => ({
        ...state,
        depositsList: action.deposits,
        moreDeposits: action.moreDeposits,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LIST_FIXED_TERM_MORE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LIST_FIXED_TERM_MORE_SUCCESS]: (state, action) => ({
        ...state,
        depositsList: state.depositsList ? state.depositsList.concat(action.deposits) : action.deposits,
        pageNumber: action.pageNumber,
        moreDeposits: action.moreDeposits,
        isFetching: false,
    }),
    [types.SET_SELECTED_DEPOSIT]: (state, action) => ({
        ...state,
        selectedDeposit: action.selectedDeposit,
    }),
    [types.READ_DEPOSIT_DETAIL_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.READ_DEPOSIT_DETAIL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.READ_DEPOSIT_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        depositDetail: action.deposit,
    }),
    [types.TOGGLE_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: !state.isOptionsVisible,
    }),
    [types.CLOSE_OPTIONS]: (state) => ({
        ...state,
        isOptionsVisible: false,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS]: (state, action) => ({
        ...state,
        depositDetail: {
            ...state.depositDetail,
            productAlias: action.alias,
        },
        isFetching: false,
    }),
    [types.RESET_PAGE_NUMBER]: (state) => ({
        ...state,
        pageNumber: 1,
    }),
    [types.SIMULATE_DEPOSIT_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.SIMULATE_DEPOSIT_FAILURE]: (state, action) => ({
        ...state,
        isFetching: false,
        errors: action.errors,
    }),
    [types.SIMULATE_DEPOSIT_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        depositDetail: action.depositDetail,
        hasSimulatedOnce: true,
        errors: INITIAL_STATE.errors,
    }),
    [formTypes.FORM_CLOSED]: (state) => ({
        ...state,
        hasSimulatedOnce: INITIAL_STATE.hasSimulatedOnce,
        depositDetail: INITIAL_STATE.depositDetail,
        errors: INITIAL_STATE.errors,
    }),
    [formTypes.PRE_FORM_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [formTypes.PRE_FORM_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [formTypes.PRE_FORM_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [formTypes.PREVIEW_FORM_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [formTypes.PREVIEW_FORM_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [formTypes.SEND_FORM_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [formTypes.SEND_FORM_CREDENTIAL_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [formTypes.SEND_FORM_DATA_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [formTypes.SEND_FORM_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SHAREHOLDER_ACCOUNTS_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.SHAREHOLDER_ACCOUNTS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.SHAREHOLDER_ACCOUNTS_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        shareholderAccounts: action.shareholderAccounts,
        defaultValue: action.shareholderAccounts[0]?.number,
    }),
    [types.MUTUAL_FUNDS_TOGGLE_OPTIONS]: (state, action) => ({
        ...state,
        toggleState: action.option,
    }),
    [types.MUTUAL_FUNDS_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
        isFirstFetching: true,
    }),
    [types.MUTUAL_FUNDS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.MUTUAL_FUNDS_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        mutualFunds: action.mutualFunds.length ? action.mutualFunds : [],
        operationFunds: action.operationFunds.length ? action.operationFunds : [],
        isFirstFetching: false,
    }),
    [types.FUND_OFFERS_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.FUND_OFFERS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.FUND_OFFERS_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        fundOffers: action.fundOffers,
    }),
    [types.AVAILABLE_FUNDS_REQUESTED]: (state) => ({
        ...state,
        isFetchingMovements: true,
    }),
    [types.AVAILABLE_FUNDS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetchingMovements: false,
    }),
    [types.AVAILABLE_FUNDS_RECEIVED]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        availableFunds: action.availableFunds,
    }),

    [types.OPERATING_FUNDS_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.OPERATING_FUNDS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.OPERATING_FUNDS_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        operatingFunds: action.operatingFunds,
    }),
    [types.SET_SELECTED_INVESTMENT]: (state, action) => ({
        ...state,
        investment: action.investment,
        accountSelected: action.accountSelected,
    }),
    [types.CLEAR_SELECTED_INVESTMENT]: (state) => ({
        ...state,
        investment: {},
    }),
    [types.FUND_DATA_CLEARED]: (state) => ({
        ...state,
        isFetching: false,
        shareholderAccounts: INITIAL_STATE.shareholderAccounts,
        mutualFunds: INITIAL_STATE.mutualFunds,
        fundOffers: INITIAL_STATE.fundOffers,
        operatingFunds: INITIAL_STATE.operatingFunds,
        toggleState: INITIAL_STATE.toggleState,
        filters: INITIAL_STATE.filters,
    }),
    [types.SET_MOVEMENT_FILTERS]: (state, action) => ({
        ...state,
        movementFilters: Object.assign(Object.assign({}, state.movementFilters), action.filters),
        pageNumber: INITIAL_STATE.pageNumber,
        moreMovements: INITIAL_STATE.moreMovements,
    }),
    [types.FUND_MOVEMENTS_REQUESTED]: (state) => ({
        ...state,
        isFetchingMovements: true,
        isFirstFetching: true,
    }),
    [types.FUND_MOVEMENTS_REQUEST_FAILED]: (state) => ({
        ...state,
        isFetchingMovements: false,
        isFirstFetching: false,
    }),
    [types.FUND_MOVEMENTS_RECEIVED]: (state, action) => ({
        ...state,
        isFetchingMovements: false,
        movements: state.pageNumber >= 2 ? state.movements.concat(action.movements) : action.movements,
        moreMovements: action.done,
        pageNumber: action.nextPage,
        isFirstFetching: false,
    }),
    [types.MOVEMENTS_DATA_CLEARED]: (state) => ({
        ...state,
        isFetchingMovements: false,
        movements: INITIAL_STATE.movements,
        moreMovements: INITIAL_STATE.moreMovements,
        pageNumber: INITIAL_STATE.pageNumber,
        movementFilters: INITIAL_STATE.movementFilters,
        availableFunds: INITIAL_STATE.availableFunds,
        isDownloading: INITIAL_STATE.isDownloading,
    }),
    [types.CANCEL_DEPOSIT_RENEWAL_PREVIEW_REQUEST]: (state, action) => ({
        ...state,
        depositDetail: {
            ...state.depositDetail,
            idProduct: action.payload.values.idProduct,
            operationOrder: action.payload.values.operationOrder,
            operationCode: action.payload.values.operationCode,
        },
    }),
    [types.CANCEL_DEPOSIT_RENEWAL_SEND_SUCCESS]: (state, action) => ({
        ...state,
        cancelledRenewalIdProduct: action.idProduct,
    }),
    [types.SET_FUND_LIST_FILTERS]: (state, action) => ({
        ...state,
        filters: {
            ...state.filters,
            ...action.filters,
        },
    }),
    [types.DOWNLOAD_FUND_MOVEMENTS_REQUESTED]: (state) => ({
        ...state,
        isDownloading: true,
    }),
    [types.DOWNLOAD_FUND_MOVEMENTS_REQUEST_FAILED]: (state) => ({
        ...state,
        isDownloading: false,
    }),
    [types.DOWNLOAD_FUND_MOVEMENTS_RECEIVED]: (state) => ({
        ...state,
        isDownloading: false,
    }),
    [types.VALIDATION_ACCESS_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.VALIDATION_ACCESS_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        isAccessAllowed: action.isAccessAllowed,
    }),
    [types.VALIDATION_ACCESS_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [LOCATION_CHANGE]: (state) => ({
        ...state,
        depositDetail: INITIAL_STATE.depositDetail,
        fetching: false,
    }),
    [types.INVERSOR_PROFILE_REQUESTED]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.INVERSOR_PROFILE_RECEIVED]: (state, action) => ({
        ...state,
        isFetching: false,
        investorProfile: action.profile,
    }),
    [types.INVERSOR_PROFILE_FAILED]: (state) => ({
        ...state,
        investorProfile: null,
        isFetching: false,
    }),
    [types.CALCULATE_INVESTOR_PROFILE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.CALCULATE_INVESTOR_PROFILE_FAILURE]: (state) => ({
        ...state,
        investorProfileCalculated: null,
        isFetching: false,
    }),
    [types.CALCULATE_INVESTOR_PROFILE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        investorProfileCalculated: action.profile,
        filteredAnswers: action.filteredAnswers,
        formikBag: action.formikBag,
    }),
    [types.RESET_CALCULATED_INVESTOR_PROFILE]: (state) => ({
        ...state,
        isFetching: false,
        investorProfileCalculated: null,
    }),
    [types.DOWNLOAD_INVESTOR_PROFILE_REQUEST]: (state) => ({
        ...state,
        isFetchingDownload: true,
    }),
    [types.DOWNLOAD_INVESTOR_PROFILE_FAILURE]: (state) => ({
        ...state,
        isFetchingDownload: false,
        investorProfileFile: INITIAL_STATE.investorProfileFile,
    }),
    [types.DOWNLOAD_INVESTOR_PROFILE_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingDownload: false,
        investorProfileFile: {
            fileName: action.fileName,
            base64: action.base64,
        },
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(types.LIST_FIXED_TERM_DEPOSITS_REQUEST),
    loadListFailure: makeActionCreator(types.LIST_FIXED_TERM_DEPOSITS_FAILURE),
    loadListSuccess: makeActionCreator(types.LIST_FIXED_TERM_DEPOSITS_SUCCESS, "deposits", "moreDeposits"),
    fetchMoreFixedDeposits: makeActionCreator(types.LIST_FIXED_TERM_MORE_REQUEST, "pageNumber"),
    fetchMoreFixedDepositsSuccess: makeActionCreator(
        types.LIST_FIXED_TERM_MORE_SUCCESS,
        "deposits",
        "pageNumber",
        "moreDeposits",
    ),
    readDepositDetails: makeActionCreator(types.READ_DEPOSIT_DETAIL_REQUEST, "idDeposit", "operationOrder"),
    readDepositDetailsFailure: makeActionCreator(types.READ_DEPOSIT_DETAIL_FAILURE),
    readDepositDetailsSuccess: makeActionCreator(types.READ_DEPOSIT_DETAIL_SUCCESS, "deposit"),
    setSelectedDeposit: makeActionCreator(types.SET_SELECTED_DEPOSIT, "deposit"),
    toggleOptions: makeActionCreator(types.TOGGLE_OPTIONS),
    closeOptions: makeActionCreator(types.CLOSE_OPTIONS),
    resetPageNumber: makeActionCreator(types.RESET_PAGE_NUMBER),

    simulateDepositRequest: makeActionCreator(types.SIMULATE_DEPOSIT_REQUEST, "formData"),
    simulateDepositFailure: makeActionCreator(types.SIMULATE_DEPOSIT_FAILURE, "errors"),
    simulateDepositSuccess: makeActionCreator(types.SIMULATE_DEPOSIT_SUCCESS, "depositDetail"),

    createDepositPreview: makeActionCreator(types.PREVIEW_FORM_REQUEST),
    loadShareholderAccounts: makeActionCreator(types.SHAREHOLDER_ACCOUNTS_REQUESTED, "documentNumber"),
    loadShareholderAccountsSuccess: makeActionCreator(types.SHAREHOLDER_ACCOUNTS_RECEIVED, "shareholderAccounts"),
    loadShareholderAccountsFailure: makeActionCreator(types.SHAREHOLDER_ACCOUNTS_REQUEST_FAILED),
    loadMutualFundByAccount: makeActionCreator(types.MUTUAL_FUNDS_REQUESTED, "accountNumber", "date", "documentNumber"),
    loadMutualFundByAccountSuccess: makeActionCreator(types.MUTUAL_FUNDS_RECEIVED, "mutualFunds", "operationFunds"),
    loadMutualFundByAccountFailure: makeActionCreator(types.MUTUAL_FUNDS_REQUEST_FAILED),
    onToggleOptions: makeActionCreator(types.MUTUAL_FUNDS_TOGGLE_OPTIONS, "option"),
    loadFundOffers: makeActionCreator(types.FUND_OFFERS_REQUESTED, "accountNumber", "date", "fundType", "riskType"),
    loadFundOffersSuccess: makeActionCreator(types.FUND_OFFERS_RECEIVED, "fundOffers"),
    loadFundOffersFailure: makeActionCreator(types.FUND_OFFERS_REQUEST_FAILED),
    loadAvailableFunds: makeActionCreator(types.AVAILABLE_FUNDS_REQUESTED, "documentNumber", "filters"),
    loadAvailableFundsSuccess: makeActionCreator(types.AVAILABLE_FUNDS_RECEIVED, "availableFunds"),
    loadAvailableFundsFailure: makeActionCreator(types.AVAILABLE_FUNDS_REQUEST_FAILED),
    loadOperatingFunds: makeActionCreator(
        types.OPERATING_FUNDS_REQUESTED,
        "accountNumber",
        "date",
        "fundType",
        "riskType",
    ),
    loadOperatingFundsSuccess: makeActionCreator(types.OPERATING_FUNDS_RECEIVED, "operatingFunds"),
    loadOperatingFundsFailure: makeActionCreator(types.OPERATING_FUNDS_REQUEST_FAILED),
    setSelectedInvestment: makeActionCreator(types.SET_SELECTED_INVESTMENT, "investment", "accountSelected"),
    clearSelectedInvestment: makeActionCreator(types.SET_SELECTED_INVESTMENT),
    clearFundData: makeActionCreator(types.FUND_DATA_CLEARED),
    loadShareholderAccountsAndAvailableFunds: makeActionCreator(types),
    loadFundMovements: makeActionCreator(
        types.FUND_MOVEMENTS_REQUESTED,
        "accountNumber",
        "fundNumber",
        "fundClass",
        "dateFrom",
        "dateTo",
        "pageNumber",
    ),
    loadFundMovementsSuccess: makeActionCreator(types.FUND_MOVEMENTS_RECEIVED, "movements", "nextPage", "done"),
    loadFundMovementsFailure: makeActionCreator(types.FUND_MOVEMENTS_REQUEST_FAILED),
    setMovementFilters: makeActionCreator(types.SET_MOVEMENT_FILTERS, "filters"),
    clearMovementsData: makeActionCreator(types.MOVEMENTS_DATA_CLEARED),
    cancelDepositRenewalPreview: makeActionCreator(types.CANCEL_DEPOSIT_RENEWAL_PREVIEW_REQUEST, "payload"),
    setFundListFilters: makeActionCreator(types.SET_FUND_LIST_FILTERS, "filters"),
    downloadFundMovements: makeActionCreator(
        types.DOWNLOAD_FUND_MOVEMENTS_REQUESTED,
        "accountNumber",
        "accountName",
        "fundNumber",
        "fundName",
        "fundClass",
        "dateFrom",
        "dateTo",
        "pageNumber",
        "format",
    ),
    downloadFundMovementsSuccess: makeActionCreator(types.DOWNLOAD_FUND_MOVEMENTS_RECEIVED, "movements"),
    downloadFundMovementsFailure: makeActionCreator(types.DOWNLOAD_FUND_MOVEMENTS_REQUEST_FAILED),
    validateAccessRestriction: makeActionCreator(types.VALIDATION_ACCESS_REQUESTED, "actionType", "mutualFund"),
    validateAccessRestrictionSuccess: makeActionCreator(types.VALIDATION_ACCESS_RECEIVED, "isAccessAllowed"),
    validateAccessRestrictionFailure: makeActionCreator(types.VALIDATION_ACCESS_FAILED),
    getInvestorProfileRequest: makeActionCreator(types.INVERSOR_PROFILE_REQUESTED, "segment"),
    getInvestorProfileFailure: makeActionCreator(types.INVERSOR_PROFILE_FAILED),
    getInvestorProfileSuccess: makeActionCreator(types.INVERSOR_PROFILE_RECEIVED, "profile"),
    calculateInvestorProfileRequest: makeActionCreator(
        types.CALCULATE_INVESTOR_PROFILE_REQUEST,
        "formData",
        "questionsList",
        "segment",
    ),
    calculateInvestorProfileFailure: makeActionCreator(types.CALCULATE_INVESTOR_PROFILE_FAILURE),
    calculateInvestorProfileSuccess: makeActionCreator(
        types.CALCULATE_INVESTOR_PROFILE_SUCCESS,
        "profile",
        "filteredAnswers",
        "formikBag",
    ),
    resetInvestorCalculated: makeActionCreator(types.RESET_CALCULATED_INVESTOR_PROFILE),
    saveInvestorCalculated: makeActionCreator(types.SAVE_INVESTOR_PROFILE, "payload"),
    downloadInvestorProfileRequest: makeActionCreator(types.DOWNLOAD_INVESTOR_PROFILE_REQUEST, "segment", "profile"),
    downloadInvestorProfileFailure: makeActionCreator(types.DOWNLOAD_INVESTOR_PROFILE_FAILURE),
    downloadInvestorProfileSuccess: makeActionCreator(types.DOWNLOAD_INVESTOR_PROFILE_SUCCESS, "fileName", "base64"),
    resetFilters: makeActionCreator(types.RESET_FILTERS),
};

export const selectors = {
    getDeposits: ({ investments }) => investments.depositsList,
    getFetching: ({ investments }) => investments.isFetching,
    getDepositDetail: ({ investments }) => investments.depositDetail,
    getSelectedDeposit: ({ investments }) => investments.selectedDeposit,
    getIsOptionsVisible: ({ investments }) => investments.isOptionsVisible,
    getPageNumber: ({ investments }) => investments.pageNumber,
    getMoreDeposits: ({ investments }) => investments.moreDeposits,
    getHasSimulatedOnce: ({ investments }) => investments.hasSimulatedOnce,
    getErrors: ({ investments }) => investments.errors,
    mutualFunds: ({ investments }) => investments.mutualFunds,
    getFilters: ({ investments }) => investments.filters,
    toggleState: ({ investments }) => investments.toggleState,
    shareholderAccounts: ({ investments }) => investments.shareholderAccounts,
    getSelectedInvestment: ({ investments }) => investments.investment,
    getSelectedAccount: ({ investments }) => investments.accountSelected,
    getOperatingFunds: ({ investments }) => investments.operationFunds,
    getCancelledRenewalIdProduct: ({ investments }) => investments.cancelledRenewalIdProduct,
    getDownloading: ({ investments }) => investments.isDownloading,
    getInvestorProfile: ({ investments }) => investments.investorProfile,
    getInvestorProfileCalculated: ({ investments }) => investments.investorProfileCalculated,
    getInvestorProfileAnswers: ({ investments }) => investments.filteredAnswers,
    getInvestorProfileFormikBag: ({ investments }) => investments.formikBag,
    getFetchingDownload: ({ investments }) => investments.isFetchingDownload,
    getFirstFetched: ({ investments }) => investments.isFirstFetching,
};
