import * as API from "middleware/api";
import { format } from "date-fns";

export const loadListRequest = (filters) => {
    let { dateFrom, dateTo } = filters;
    const { checkNumber, state, pageNumber, minAmount, maxAmount, channel, client, checkType } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/checks.list", {
        checkNumber,
        dateFrom,
        dateTo,
        pageNumber,
        minAmount,
        maxAmount,
        checkType,
        state,
        channel,
        client,
    });
};

export const getPendingchecksQuantity = () => API.executeWithAccessToken("/checks.get.pending.quantity");

export const getChecksDeferredList = (filters) => {
    let { dateFrom, dateTo } = filters;
    const { pageNumber, checkFrom, checkTypeDeferred, operationNumber, state } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/check.deferred.list", {
        checkTypeDeferred,
        dateFrom,
        dateTo,
        checkFrom,
        pageNumber,
        operationNumber,
        state: state ? state[0] : null,
    });
};

export const getChecksDeferredDetail = (checkType, branch, operationNumber, checkNumber, orderNumber, idCheck) =>
    API.executeWithAccessToken("/check.deferred.detail", {
        checkType,
        branch,
        operationNumber,
        checkNumber,
        orderNumber,
        idCheck,
    });
