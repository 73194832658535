import Button from "pages/_components/Button";
import { bool, func, node, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as formSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as templateSelectors } from "reducers/template";
import { get } from "util/i18n";
import * as configUtils from "util/config";
import * as transactionUtil from "util/transaction";
import ModifyTransaction from "./_confirmations/ModifyTransaction";

class FormActions extends Component {
    static propTypes = {
        onClick: func.isRequired,
        fetching: bool,
        mode: string.isRequired,
        draftsEnabled: bool,
        templatesEnabled: bool,
        transaction: shape({
            idTransactionStatus: string,
        }),
        isEditable: bool,
        isCancellingTransaction: bool.isRequired,
        idForm: string,
        isModalVisible: bool,
        isDesktop: bool.isRequired,
        showSubmit: bool,
        additionalActions: func,
        renderFootNote: node,
        user: shape({}).isRequired,
        buttonLabel: string,
        handleDownload: func,
        withoutTransaction: bool,
        userTransactionDetail: shape({}),
        isCancelling: bool,
        submitDisabled: bool,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
        draftsEnabled: false,
        templatesEnabled: false,
        isEditable: true,
        transaction: {},
        isModalVisible: false,
        showSubmit: true,
        additionalActions: null,
        renderFootNote: null,
        handleDownload: null,
        buttonLabel: "global.send",
        withoutTransaction: false,
        userTransactionDetail: null,
        isCancelling: false,
        submitDisabled: false,
        idForm: "",
    };

    state = {
        modifyTransactionConfirm: false,
    };

    viewEditButtonsMobile = () => {
        const {
            onClick,
            fetching,
            draftsEnabled,
            templatesEnabled,
            isModalVisible,
            idForm,
            additionalActions,
            showSubmit,
            buttonLabel,
            renderFootNote,
            submitDisabled,
        } = this.props;
        const label = `forms.${idForm}.send`;
        const defaultLabelText = get("global.send", "");
        return (
            <>
                {renderFootNote && <div className="footer-notes">{renderFootNote()}</div>}

                {showSubmit && (
                    <Button
                        block
                        type="submit"
                        label={idForm ? label : buttonLabel}
                        bsStyle="primary"
                        loading={fetching}
                        defaultLabelText={defaultLabelText}
                        disabled={submitDisabled}
                    />
                )}
                {additionalActions && additionalActions(fetching)}

                {draftsEnabled && (
                    <Button
                        block
                        type="button"
                        bsStyle="outline"
                        onClick={() => onClick("saveDraft")}
                        label="forms.saveDraft.link"
                    />
                )}

                {templatesEnabled && (
                    <Button
                        block
                        type="button"
                        className="btn btn-outline"
                        onClick={() => onClick("createTemplate")}
                        aria-haspopup="dialog"
                        aria-expanded={isModalVisible}
                        label="forms.saveTemplate.link"
                    />
                )}
            </>
        );
    };

    viewEditButtonsDesktop = () => {
        const {
            onClick,
            fetching,
            draftsEnabled,
            templatesEnabled,
            isModalVisible,
            idForm,
            additionalActions,
            showSubmit,
            renderFootNote,
            buttonLabel,
            submitDisabled,
        } = this.props;
        const label = `forms.${idForm}.send`;
        const defaultLabelText = get("global.send", "");
        return (
            <>
                {templatesEnabled && (
                    <Button
                        type="button"
                        onClick={() => onClick("createTemplate")}
                        label="forms.saveTemplate.link"
                        bsStyle="outline"
                        aria-haspopup="dialog"
                        aria-expanded={isModalVisible}
                    />
                )}

                {draftsEnabled && (
                    <Button
                        type="button"
                        bsStyle="outline"
                        onClick={() => onClick("saveDraft")}
                        label="forms.saveDraft.link"
                    />
                )}

                {renderFootNote && <div className="footer-notes">{renderFootNote()}</div>}

                {additionalActions && additionalActions(fetching)}

                {showSubmit && (
                    <Button
                        block
                        type="submit"
                        label={idForm ? label : buttonLabel}
                        bsStyle="primary"
                        loading={fetching}
                        defaultLabelText={defaultLabelText}
                        disabled={submitDisabled}
                    />
                )}
            </>
        );
    };

    getEditButtons = () => {
        const { mode, isDesktop } = this.props;

        if (mode === "edit") {
            return (
                <React.Fragment>
                    {isDesktop ? this.viewEditButtonsDesktop() : this.viewEditButtonsMobile()}
                </React.Fragment>
            );
        }

        return null;
    };

    validateActivityForModifyButtonOnMobile = (transaction) => {
        const activitiesToHideModifyButton = configUtils.get("core.activities.not.defined.for.mobile", []);

        if (activitiesToHideModifyButton.includes(transaction.idActivity)) {
            return false;
        }

        return true;
    };

    validateActivityForModify = (transaction) => {
        const activitiesToHideModifyButton = configUtils.get("core.activities.not.show.modify.button", []);

        if (activitiesToHideModifyButton.includes(transaction.idActivity)) {
            return false;
        }

        return true;
    };

    renderModifyButton = (userSign) => {
        const { fetching, transaction, isEditable, isDesktop } = this.props;
        const showModifyButton = this.validateActivityForModify(transaction);

        if (showModifyButton) {
            if (isDesktop && isEditable) {
                return (
                    <Button
                        bsStyle={userSign ? "primary" : "outline"}
                        onClick={() => this.verifyBeforeModifyTransaction()}
                        label="forms.modifyTransaction.link"
                        loading={fetching}
                    />
                );
            }

            const showModifyButtonOnMobile = this.validateActivityForModifyButtonOnMobile(transaction);

            if (!isDesktop && showModifyButtonOnMobile && isEditable) {
                return (
                    <Button
                        bsStyle={userSign ? "primary" : "outline"}
                        onClick={() => this.verifyBeforeModifyTransaction()}
                        label="forms.modifyTransaction.link"
                        block
                        loading={fetching}
                    />
                );
            }
        }

        return null;
    };

    renderCancelButton = () => {
        const {
            transaction,
            activeEnvironment,
            onClick,
            isCancellingTransaction,
            isCancelling,
            isDesktop,
        } = this.props;
        const hasPermissionToCancel = transactionUtil.hasPermissionToCancel(transaction, activeEnvironment);

        if (hasPermissionToCancel) {
            return (
                <Button
                    onClick={() => onClick("cancelTransaction")}
                    label="forms.cancelTransaction.link"
                    bsStyle="outline"
                    block={!isDesktop}
                    loading={isCancellingTransaction}
                    className={isCancelling ? "active" : ""}
                />
            );
        }
        return null;
    };

    getViewButtons = () => {
        const {
            onClick,
            fetching,
            transaction,
            mode,
            additionalActions,
            user,
            handleDownload,
            isDesktop,
            userTransactionDetail,
            withoutTransaction,
        } = this.props;
        const { modifyTransactionConfirm } = this.state;
        const { signatures } = transaction;
        const { userId } = user;
        const userSign = signatures?.find((userS) => userId === userS.idUser);
        const isTransactionRequestCancelable = configUtils
            .get("activities.transactional.cancelRequest.cancelable")
            .split("|")
            .includes(transaction.idActivity);

        if (mode === "view" && (transaction || userTransactionDetail)) {
            const { idTransactionStatus } =
                withoutTransaction && userTransactionDetail ? userTransactionDetail : transaction;

            if (idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") {
                return (
                    <>
                        {modifyTransactionConfirm && (
                            <ModifyTransaction
                                isDesktop={isDesktop}
                                modifyTransactionConfirm={modifyTransactionConfirm}
                                onClose={this.cancelModifyHandleClick}
                                modifyTransactionClick={() => onClick("modifyTransaction")}
                            />
                        )}
                        {isDesktop ? (
                            <>
                                {this.renderCancelButton()}

                                {this.renderModifyButton(userSign)}

                                {idTransactionStatus === "PENDING" && !userSign && (
                                    <Button
                                        onClick={() => onClick("signTransaction")}
                                        label="forms.signTransaction.link"
                                        bsStyle="primary"
                                        loading={fetching}
                                    />
                                )}
                                {additionalActions && additionalActions(fetching)}
                            </>
                        ) : (
                            <>
                                {idTransactionStatus === "PENDING" && !userSign && (
                                    <Button
                                        onClick={() => onClick("signTransaction")}
                                        label="forms.signTransaction.link"
                                        bsStyle="primary"
                                        loading={fetching}
                                    />
                                )}

                                {this.renderModifyButton(userSign)}

                                {this.renderCancelButton()}

                                {additionalActions && additionalActions(fetching)}
                            </>
                        )}
                    </>
                );
            }

            if (idTransactionStatus === "PROCESSING" && isTransactionRequestCancelable) {
                return (
                    <Button
                        onClick={() => onClick("cancelRequest")}
                        label="forms.cancelTransaction.link"
                        bsStyle="outline"
                        block={!isDesktop}
                    />
                );
            }

            if (handleDownload && !isDesktop) {
                return (
                    <>
                        <Button
                            onClick={handleDownload}
                            label="global.download"
                            bsStyle="outline"
                            loading={fetching}
                            image="images/download.svg"
                        />
                    </>
                );
            }
            if (additionalActions !== null) {
                return additionalActions(fetching);
            }
        }

        return null;
    };

    verifyBeforeModifyTransaction = () => {
        const { onClick, transaction } = this.props;

        if (transaction.signatures.length > 0) {
            this.setState({ modifyTransactionConfirm: true });
        } else {
            onClick("modifyTransaction");
        }
    };

    cancelModifyHandleClick = () => {
        this.setState({ modifyTransactionConfirm: false });
    };

    render() {
        const editButtons = this.getEditButtons();
        const viewButtons = this.getViewButtons();
        const { idForm } = this.props;

        return (
            (editButtons || viewButtons) && (
                <div className={`form-footer ${idForm}`}>
                    {editButtons}
                    {viewButtons}
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formSelectors.getIsCancellingTransaction(state),
    isCancellingTransaction: formSelectors.getFetching(state) && formSelectors.getIsCancellingTransaction(state),
    isModalVisible: templateSelectors.isCreateModalVisible(state),
    user: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(FormActions);
