import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors, actions } from "reducers/notificationModal";

import { bool, func, string } from "prop-types";

import SimpleModal from "components/SimpleModal/SimpleModal";

class NotificationModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isOpen: bool.isRequired,
        notificationMessage: string,
        notificationAltMessage: string,
        notificationIcon: string,
        notificationBigTitle: string,
        notificationButtonAction: func,
        closeModalAction: func,
    };

    static defaultProps = {
        notificationMessage: "",
        notificationIcon: null,
        notificationAltMessage: "",
        notificationBigTitle: "",
        notificationButtonAction: null,
        closeModalAction: null,
    };

    closeModal = () => {
        const { dispatch, closeModalAction } = this.props;

        if (closeModalAction) {
            closeModalAction();
        }

        dispatch(actions.removeNotification());
    };

    render() {
        const {
            isOpen,
            notificationMessage,
            notificationAltMessage,
            notificationIcon,
            notificationBigTitle,
            notificationButtonAction,
        } = this.props;

        let icon = "";

        switch (notificationIcon) {
            case "success":
                icon = "success.svg";
                break;

            case "warning":
                icon = "warningIcon.svg";
                break;

            case "error":
                icon = "error.svg";
                break;

            case "beneficiary":
                icon = "beneficiary.svg";
                break;

            case "blue-alert":
                icon = "blue-alert.svg";
                break;

            default:
                icon = "warningIcon.svg";
                break;
        }

        return (
            <SimpleModal
                isDisplayed={isOpen}
                modalIcon={icon}
                closeModal={() => this.closeModal()}
                modalBigTitle={notificationBigTitle}
                defaultBigTitle={notificationBigTitle}
                modalMainText={notificationMessage}
                defaultMainText={notificationMessage}
                showActions={notificationButtonAction}
                buttonAction={notificationButtonAction}>
                {notificationAltMessage && <div className="modal-alt-text">{notificationAltMessage}</div>}
            </SimpleModal>
        );
    }
}
const mapStateToProps = (state) => ({
    isOpen: selectors.getShowModal(state),
    notificationMessage: selectors.getMessage(state),
    notificationAltMessage: selectors.getAltMessage(state),
    notificationIcon: selectors.getIcon(state),
    notificationBigTitle: selectors.getBigTitle(state),
    notificationButtonAction: selectors.getButtonAction(state),
    closeModalAction: selectors.getCloseModalAction(state),
});

export default connect(mapStateToProps)(NotificationModal);
