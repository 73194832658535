import React, { Fragment } from "react";

import Head from "pages/_components/Head";
import { bool, func, string } from "prop-types";
import { goBack } from "react-router-redux";
import { actions as communicationActions } from "reducers/communication";

function ReportSuspiciousActivityHeader({ dispatch, isMobile, origin }) {
    const handleReportClick = (event) => {
        event.preventDefault();
        dispatch(communicationActions.startPreloadedMessage(true, isMobile, origin));
    };

    const handleBackClick = () => {
        dispatch(communicationActions.resetPreloadedMessage());
        dispatch(goBack());
    };

    return (
        <Fragment>
            {!isMobile ? (
                <Head
                    headerClassName="mt-2"
                    addLinkToLabel="settings.lastLogin.report"
                    addLinkTo="/"
                    customButtonIcon="/images/alert-white.svg"
                    customButtonOnClick={handleReportClick}
                    onBack={handleBackClick}
                />
            ) : (
                <Head onBack={handleBackClick} showLogo arrowWhite additionalClassName="blue-main-header-mobile" />
            )}
        </Fragment>
    );
}

ReportSuspiciousActivityHeader.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    origin: string.isRequired,
};

export default ReportSuspiciousActivityHeader;
