import React from "react";
import { string, object, oneOfType } from "prop-types";
import * as i18n from "util/i18n";

const TooltipTourContent = React.memo((props) => {
    const { title, content: Content } = props;
    return (
        <div className="container">
            <div className="row">
                {!!title && (
                    <p>
                        <strong>{i18n.get(title, title)}</strong>
                    </p>
                )}
                <p>{Content}</p>
            </div>
        </div>
    );
});

TooltipTourContent.propTypes = {
    title: string,
    content: oneOfType([string, object]).isRequired,
};

TooltipTourContent.defaultProps = {
    title: "",
};

export default TooltipTourContent;
