import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { func, bool } from "prop-types";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "recoveryPassword.step2";

class Step2Content extends Component {
    handleResendEmail = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.backToStep1());
    };

    render() {
        const { isSubmitting, fetching } = this.props;
        return (
            <Form className="form-content container">
                <div className="pre-form-info">
                    <div className="pre-form-info__text">
                        <I18n id="recoveryPassword.step2.mailSent.title" />
                        <I18n id="recoveryPassword.step2.mailSent.message" component="p" />
                    </div>
                </div>

                <Field name="resetCode" idForm={FORM_ID} autoComplete="off" maxLength={14} component={TextField} />

                <Button block type="submit" loading={isSubmitting} label="global.continue" bsStyle="primary" />

                <div className="options-login ui-mt-7">
                    <I18n id="recoveryPassword.step2.resendMail.receivedQuestion" component="p" />
                </div>

                <Button
                    block
                    loading={fetching}
                    bsStyle="outline"
                    label="recoveryPassword.step2.resendMail.link"
                    className="ui-mt-0"
                    onClick={this.handleResendEmail}
                />
            </Form>
        );
    }
}

Step2Content.propTypes = {
    isSubmitting: bool,
    dispatch: func.isRequired,
    fetching: bool,
};

Step2Content.defaultProps = {
    isSubmitting: false,
    fetching: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ resetCode: "" }),
    validationSchema: () =>
        Yup.object().shape({
            resetCode: Yup.string().required(i18n.get(`${FORM_ID}.resetCode.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);
        formikBag.props.dispatch(recoveryPasswordActions.recoveryPassStep2(values.resetCode, formikBag));
    },
})(Step2Content);
