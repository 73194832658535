import React, { Component } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import { selectors as transactionsSelectors } from "reducers/transactions";
import HiddenFilters from "pages/transactions/_components/HiddenFilters";

class MobileFilters extends Component {
    propTypes = {
        dispatch: func.isRequired,
    };

    render() {
        return <HiddenFilters {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
});

export default connect(mapStateToProps)(MobileFilters);
