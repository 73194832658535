import React, { Component } from "react";
import Collapse from "react-bootstrap/lib/Collapse";
import { Formik, Form, Field } from "formik";
import { string, func, bool } from "prop-types";

import Select from "pages/_components/fields/Select";
import TextField from "pages/_components/fields/TextField";
import AmountField from "pages/_components/fields/formik/AmountField";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as paymentUtils from "util/massivePayments";

const initialFilters = {
    creditAccount: "",
    accountName: "",
    minAmount: null,
    maxAmount: null,
    bank: "",
};
class TransactionLinesFiltersForm extends Component {
    static propTypes = {
        isExpanded: bool.isRequired,
        currency: string.isRequired,
        onSubmit: func.isRequired,
    };

    static filters = {
        account: (filterAccount, lineAccount) => lineAccount.toLowerCase().includes(filterAccount.toLowerCase()),
        payee: (filterName, lineName) => lineName.toLowerCase().includes(filterName.toLowerCase()),
        amount: (min, max, amount) => min <= amount && amount <= max,
        bank: (filterBank, creditAccountBank) =>
            (filterBank && creditAccountBank && creditAccountBank.toLowerCase().includes(filterBank.toLowerCase())) ||
            (filterBank === "techbank" && !creditAccountBank),
    };

    render() {
        const { currency, isExpanded, onSubmit } = this.props;
        return (
            <Collapse in={isExpanded}>
                <div>
                    <Formik initialValues={{ filter: "account", data: initialFilters }} onSubmit={onSubmit}>
                        {({ values, resetForm }) => (
                            <Form className="filters">
                                <Container className="container--layout">
                                    <Container.Column sm={12} md={3}>
                                        <Select
                                            name="filter"
                                            label={i18nUtils.get("transaction.process.details.filters.search.by")}
                                            className="form-group form-group--select"
                                            onChange={({ value }) => resetForm({ filter: value, data: initialFilters })}
                                            options={[
                                                {
                                                    label: i18nUtils.get("transaction.process.details.filters.all"),
                                                    value: "all",
                                                },
                                                {
                                                    label: i18nUtils.get("transaction.process.details.filters.account"),
                                                    value: "account",
                                                },
                                                {
                                                    label: i18nUtils.get("transaction.process.details.filters.name"),
                                                    value: "payee",
                                                },
                                                {
                                                    label: i18nUtils.get("transaction.process.details.filters.amount"),
                                                    value: "amount",
                                                },
                                                {
                                                    label: i18nUtils.get("transaction.process.details.filters.bank"),
                                                    value: "bank",
                                                },
                                            ]}
                                        />
                                    </Container.Column>
                                    <Container.Column sm={12} md={3}>
                                        {values.filter === "bank" && (
                                            <Select
                                                name="data.accountBank"
                                                label={i18nUtils.get("transaction.process.details.filters.bank")}
                                                options={paymentUtils.retrieveBankOptions("techbank")}
                                                searchable={false}
                                                optionClassName="needsclick"
                                            />
                                        )}
                                        {values.filter === "account" && (
                                            <Field
                                                name="data.creditAccount"
                                                component={TextField}
                                                placeholderText=""
                                                labelText={i18nUtils.get("transaction.process.details.filters.account")}
                                            />
                                        )}
                                        {values.filter === "payee" && (
                                            <Field
                                                name="data.accountName"
                                                component={TextField}
                                                placeholderText=""
                                                labelText={i18nUtils.get("transaction.process.details.filters.name")}
                                            />
                                        )}
                                        {values.filter === "amount" && (
                                            <Field
                                                name="data.minAmount"
                                                component={AmountField}
                                                data={{
                                                    options: [
                                                        {
                                                            id: 1,
                                                            label: i18nUtils.get(`currency.label.${currency}`),
                                                        },
                                                    ],
                                                }}
                                                disableSelect
                                                labelText={i18nUtils.get(
                                                    "transaction.process.details.filters.amount.min",
                                                )}
                                            />
                                        )}
                                    </Container.Column>
                                    {values.filter === "amount" && (
                                        <Container.Column sm={12} md={3}>
                                            <Field
                                                name="data.maxAmount"
                                                component={AmountField}
                                                data={{
                                                    options: [
                                                        {
                                                            id: 1,
                                                            label: i18nUtils.get(`currency.label.${currency}`),
                                                        },
                                                    ],
                                                }}
                                                disableSelect
                                                labelText={i18nUtils.get(
                                                    "transaction.process.details.filters.amount.max",
                                                )}
                                            />
                                        </Container.Column>
                                    )}

                                    <Container.Column sm={12} md={3} style={{ alignSelf: "flex-end" }}>
                                        <div className="form-group">
                                            <Button bsStyle="primary" label="product.filters.filter" type="submit" />
                                        </div>
                                    </Container.Column>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Collapse>
        );
    }
}

export default TransactionLinesFiltersForm;
