import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, arrayOf } from "prop-types";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

import * as i18n from "util/i18n";
import * as fileMiddleware from "middleware/file";
import { selectors as fileSelectors } from "reducers/formFields/multilineFile";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as i18nSelectors } from "reducers/i18n";
import { channel } from "middleware/api";

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class FileUploader extends Component {
    static propTypes = {
        idForm: string,
        idFormField: string,
        idActivity: string,
        description: string,
        onRemoveFile: func,
        onAddFile: func,
        onError: func,
        onFileProcess: func,
        setValue: func,
        lang: string.isRequired,
        idEnvironment: string.isRequired,
        files: arrayOf(),
        idRelatedFile: string,
        accessToken: string.isRequired,
    };
    // More props:
    // - https://pqina.nl/filepond/docs/patterns/api/filepond-instance/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/file-validate-size/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/file-validate-type/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/image-preview/#properties

    static defaultProps = {
        idForm: null,
        idFormField: null,
        idActivity: null,
        description: null,
        onRemoveFile: null,
        onAddFile: null,
        setValue: null,
        files: null,
        idRelatedFile: null,
        onError: null,
        onFileProcess: null,
    };

    state = {
        files: [],
    };

    shouldComponentUpdate(nextProps) {
        const { files } = this.state;

        return nextProps.files.length !== files.length;
    }

    componentWillUnmount() {
        const { setValue } = this.props;
        if (setValue) {
            setValue([]);
        }
    }

    onProcessFile = (error, fileItem) => {
        const { onAddFile } = this.props;

        if (!error && fileItem) {
            const { files } = this.props;
            const file = {
                fileId: parseInt(fileItem.serverId, 10),
                fileName: fileItem.filename,
                fileSize: fileItem.fileSize,
                fileType: fileItem.fileType,
            };

            this.setState({ files: [...files, file] }, () => {
                if (onAddFile) {
                    onAddFile(file);
                }
            });
        }
    };

    onRemoveFile = ({ serverId }) => {
        const { onRemoveFile, idRelatedFile } = this.props;
        const { files } = this.state;

        this.setState({ files: files.filter((file) => file.fileId !== serverId) }, () => {
            if (onRemoveFile) {
                onRemoveFile(parseInt(serverId, 10));
            }
        });

        fileMiddleware.deleteFile(parseInt(serverId, 10));
        if (idRelatedFile) {
            fileMiddleware.deleteFile(parseInt(idRelatedFile, 10));
        }
        return true;
    };

    getLabels = () => ({
        labelIdle: i18n.get("file.upload.input.labelIdle"),
        labelFileWaitingForSize: i18n.get("file.upload.input.labelFileWaitingForSize"),
        labelFileSizeNotAvailable: i18n.get("file.upload.input.labelFileSizeNotAvailable"),
        labelFileLoading: i18n.get("file.upload.input.labelFileLoading"),
        labelFileLoadError: i18n.get("file.upload.input.labelFileLoadError"),
        labelFileProcessing: i18n.get("file.upload.input.labelFileProcessing"),
        labelFileProcessingComplete: i18n.get("file.upload.input.labelFileProcessingComplete"),
        labelFileProcessingAborted: i18n.get("file.upload.input.labelFileProcessingAborted"),
        labelFileProcessingError: i18n.get("file.upload.input.labelFileProcessingError"),
        labelTapToCancel: i18n.get("file.upload.input.labelTapToCancel"),
        labelTapToRetry: i18n.get("file.upload.input.labelTapToRetry"),
        labelTapToUndo: i18n.get("file.upload.input.labelTapToUndo"),
        labelButtonRemoveItem: i18n.get("file.upload.input.labelButtonRemoveItem"),
        labelButtonAbortItemLoad: i18n.get("file.upload.input.labelButtonAbortItemLoad"),
        labelButtonRetryItemLoad: i18n.get("file.upload.input.labelButtonRetryItemLoad"),
        labelButtonAbortItemProcessing: i18n.get("file.upload.input.labelButtonAbortItemProcessing"),
        labelButtonUndoItemProcessing: i18n.get("file.upload.input.labelButtonUndoItemProcessing"),
        labelButtonRetryItemProcessing: i18n.get("file.upload.input.labelButtonRetryItemProcessing"),
        labelButtonProcessItem: i18n.get("file.upload.input.labelButtonProcessItem"),
        labelMaxFileSizeExceeded: i18n.get("file.upload.input.labelMaxFileSizeExceeded"),
        labelMaxFileSize: i18n.get("file.upload.input.labelMaxFileSize"),
        labelTotalFileSizeExceeded: i18n.get("file.upload.input.labelTotalFileSizeExceeded"),
        labelMaxTotalFileSize: i18n.get("file.upload.input.labelMaxTotalFileSize"),
        labelFileTypeNotAllowed: i18n.get("file.upload.input.labelFileTypeNotAllowed"),
        fileValidateTypeLabelExpectedTypes: i18n.get("file.upload.input.fileValidateTypeLabelExpectedTypes"),
    });

    // TODO: Pending migrate this logic to use our middleware (Axios)
    getServerOptions = () => ({
        load: null,
        fetch: null,
        restore: null,
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            const { idForm, idFormField, idActivity, description, accessToken, lang, idEnvironment } = this.props;

            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();

            if (idForm) {
                formData.append("idForm", idForm);
            }
            if (idFormField) {
                formData.append("idFormField", idFormField);
            }
            if (idActivity) {
                formData.append("idActivity", idActivity);
            }
            if (description) {
                formData.append("description", description);
            }
            formData.append("_file", file, file.name);
            formData.append("channel", channel());
            formData.append("idEnvironment", idEnvironment);
            formData.append("lang", lang);

            const request = new XMLHttpRequest();
            request.open("POST", `${window.API_URL}/files.upload`);
            request.setRequestHeader("Authorization", `bearer ${accessToken}`);
            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = () => {
                const { onError } = this.props;
                const errorMessage = i18n.get("forms.notification.uploadFile.fail");

                if (request.status >= 200 && request.status < 300) {
                    const response = JSON.parse(request.responseText);

                    if (response.code.endsWith("I")) {
                        const { onFileProcess } = this.props;
                        const { idFile, validLines, invalidLines, ...data } = response.data;

                        const uploadFileSuccessMessage = i18n.get("forms.notification.uploadFile.success");
                        const validLinesMessage = i18n.get("forms.notification.uploadFile.success.validLines");
                        const invalidLinesMessage = i18n.get("forms.notification.uploadFile.success.invalidLines");
                        const message = `${uploadFileSuccessMessage}. ${validLinesMessage}: ${validLines}, ${invalidLinesMessage}: ${invalidLines}`;

                        load(response.data.idFile);

                        if (onFileProcess) {
                            onFileProcess({ idFile, message, validLines, invalidLines, ...data });
                        }
                    } else {
                        if (onError) {
                            onError({ errorMessage, ...response.data });
                        }
                        error("oh no");
                    }
                } else {
                    // Can call the error method if something is wrong, should exit after
                    if (onError) {
                        onError({ errorMessage });
                    }
                    error("oh no");
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                },
            };
        },
        revert: (uniqueFileId, load) => {
            // Should remove the earlier created temp file here
            if (uniqueFileId) {
                this.onRemoveFile({ serverId: uniqueFileId });
            }
            load();
        },
    });

    render() {
        const { idForm, idFormField, idActivity, description, accessToken, files, ...rest } = this.props;
        const labels = this.getLabels();
        const serverOptions = this.getServerOptions();

        return (
            <FilePond
                ref={(ref) => {
                    this.pond = ref;
                }}
                server={serverOptions}
                {...labels}
                {...rest}
                onprocessfile={this.onProcessFile}
                beforeRemoveFile={this.onRemoveFile}>
                {/* Update current files  */}
                {files &&
                    files.map((fileItem) => (
                        <File
                            key={fileItem.fileId}
                            src={fileItem.fileId}
                            origin="local"
                            name={fileItem.fileName}
                            size={fileItem.fileSize}
                            type={fileItem.fileType}
                        />
                    ))}
            </FilePond>
        );
    }
}

const mapStateToProps = (state) => ({
    accessToken: sessionSelectors.getAccessToken(state),
    idRelatedFile: fileSelectors.getIdRelatedFile(state),
    lang: i18nSelectors.getLang(state),
    idEnvironment: sessionSelectors.getActiveEnvironment(state).id,
});

export default connect(mapStateToProps)(FileUploader);
