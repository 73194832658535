import React, { Component } from "react";
import Slider from "react-slick";
import { any, bool, objectOf, string } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";

import "./_DetailHeadInfo.scss";

class DetailHeadInfo extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isFinished: bool.isRequired,
        details: objectOf(any),
        mode: string.isRequired,
        currency: string.isRequired,
    };

    static defaultProps = {
        details: {},
    };

    render() {
        const { isDesktop, mode, isFinished, currency, details } = this.props;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const totalAmount = (
            <div className="data-wrapper">
                <I18n
                    id="forms.pay.transfers.massive.payment.list.headInfo.totalAmount.label"
                    componentProps={{ className: "data-label" }}
                />
                <FormattedAmount className="head-data" currency={currency} quantity={details?.total} />
            </div>
        );

        const totalPaymentLines = (
            <div className="data-wrapper">
                <I18n
                    id="forms.pay.transfers.massive.payment.list.headInfo.totalPaymentLines.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="head-data">{details?.quantity || 0}</div>
            </div>
        );

        const validPayments = (
            <div className="data-wrapper">
                <I18n
                    id="forms.pay.transfers.massive.payment.list.headInfo.validPayments.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="head-data ui-text-success">{details?.validPayments}</div>
            </div>
        );

        const invalidPayments = (
            <div className="data-wrapper">
                <I18n
                    id="forms.pay.transfers.massive.payment.list.headInfo.invalidPayments.label"
                    componentProps={{ className: "data-label" }}
                />
                <div className="head-data ui-text-error">{details?.invalidPayments}</div>
            </div>
        );

        return (
            (isDesktop && (
                <div className="transfers-massive__detail">
                    <div className="content-data detail-head-info">
                        {totalAmount}
                        {totalPaymentLines}
                        {mode !== "edit" && isFinished && (
                            <>
                                {validPayments}
                                {invalidPayments}
                            </>
                        )}
                    </div>
                </div>
            )) || (
                <>
                    <div className="slick-slider-wrapper transfers-massive__slider">
                        <Slider {...settings}>
                            {totalAmount}
                            {totalPaymentLines}
                            {mode !== "edit" && isFinished && (
                                <>
                                    {validPayments}
                                    {invalidPayments}
                                </>
                            )}
                        </Slider>
                    </div>
                </>
            )
        );
    }
}

export default DetailHeadInfo;
