export const types = {
    SHOW_NOTIFICATION: "notificationModal/SHOW_NOTIFICATION",
    SHOW_CONFIRMATION: "notificationModal/SHOW_CONFIRMATION",
    REMOVE_NOTIFICATION: "notificationModal/REMOVE_NOTIFICATION",
};

export const INITIAL_STATE = {
    message: null,
    showModal: false,
    icon: null,
    altMessage: null,
    bigTitle: null,
    closeModalAction: null,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
            return {
                ...state,
                message: action.message,
                showModal: true,
                icon: action.icon,
                altMessage: action.altMessage,
                bigTitle: action.bigTitle,
                closeModalAction: action.closeModalAction,
            };
        case types.SHOW_CONFIRMATION:
            return {
                ...state,
                message: action.message,
                showModal: true,
                icon: action.icon,
                buttonAction: action.buttonAction,
            };
        case types.REMOVE_NOTIFICATION:
            return INITIAL_STATE;
        default:
            return state;
    }
};

// Action creators
export const actions = {
    showNotification: (message, icon, altMessage, bigTitle, closeModalAction) => ({
        type: types.SHOW_NOTIFICATION,
        message,
        icon,
        altMessage,
        bigTitle,
        closeModalAction,
    }),
    showConfirmation: (message, icon, buttonAction) => ({
        type: types.SHOW_CONFIRMATION,
        message,
        icon,
        buttonAction,
    }),
    removeNotification: () => ({
        type: types.REMOVE_NOTIFICATION,
    }),
};

// Selectors
export const selectors = {
    getMessage: (state) => state.notificationModal.message,
    getIcon: (state) => state.notificationModal.icon,
    getShowModal: (state) => state.notificationModal.showModal,
    getAltMessage: (state) => state.notificationModal.altMessage,
    getBigTitle: (state) => state.notificationModal.bigTitle,
    getButtonAction: (state) => state.notificationModal.buttonAction,
    getCloseModalAction: (state) => state.notificationModal.closeModalAction,
};
