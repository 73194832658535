import { types as productsTypes } from "reducers/products";

export const types = {
    DOWNLOAD_PAYMENT_REQUEST: "loans/DOWNLOAD_PAYMENT_REQUEST",
    DOWNLOAD_PAYMENT_FAILURE: "loans/DOWNLOAD_PAYMENT_FAILURE",
    DOWNLOAD_PAYMENT_SUCCESS: "loans/DOWNLOAD_PAYMENT_SUCCESS",
    LIST_LOANS_REQUEST: "loans/LIST_LOANS_REQUEST",
    LIST_LOANS_SUCCESS: "loans/LIST_LOANS_SUCCESS",
    LOAN_DETAILS_REQUEST: "loans/LOAN_DETAILS_REQUEST",
    LOAN_DETAILS_SUCCESS: "loans/LOAN_DETAILS_SUCCESS",
    LOAN_READ_REQUEST: "loans/LOAN_READ_REQUEST",
    LOAN_READ_SUCCESS: "loans/LOAN_READ_SUCCESS",
    LOAN_LOADED_SUCCESS: "loans/LOAN_LOADED_SUCCESS",
    SET_SELECTED_LOAN: "loans/SET_SELECTED_LOAN",
    LOAN_FETCH_MORE_STATEMENTS_REQUEST: "loans/LOAN_FETCH_MORE_STATEMENTS_REQUEST",
    LOAN_FETCH_MORE_STATEMENTS_SUCCESS: "loans/LOAN_FETCH_MORE_STATEMENTS_SUCCESS",
    STATEMENT_READ_REQUEST: "loans/STATEMENT_READ_REQUEST",
    STATEMENT_READ_SUCCESS: "loans/STATEMENT_READ_SUCCESS",
    STATEMENT_READ_FAILURE: "loans/STATEMENT_READ_FAILURE",
    TOGGLE_OPTIONS: "loans/TOGGLE_OPTIONS",
    CLOSE_OPTIONS: "loans/CLOSE_OPTIONS",
    SET_SELECTED_FILTER: "loans/SET_SELECTED_FILTER",
    SET_SELECTED_STATEMENT: "loans/SET_SELECTED_STATEMENT",
    LOAD_MORE_LOANS_REQUEST: "loans/LOAD_MORE_LOANS_REQUEST",
    LOAD_MORE_LOANS_SUCCESS: "loans/LOAD_MORE_LOANS_SUCCESS",

    RESET_FILTERS: "loans/RESET_FILTERS",
    SET_FILTERS: "loans/SET_FILTERS",
    RESET_STATEMENTS: "loans/RESET_STATEMENTS",

    LIST_LOAN_TYPES_REQUEST: "loans/LIST_LOAN_TYPES_REQUEST",
    LIST_LOAN_TYPES_SUCCESS: "loans/LIST_LOAN_TYPES_SUCCESS",

    LIST_LOANS_EXPIRING_SOON_REQUEST: "loans/LIST_LOANS_EXPIRING_SOON_REQUEST",
    LIST_LOANS_EXPIRING_SOON_SUCCESS: "loans/LIST_LOANS_EXPIRING_SOON_SUCCESS",
    LIST_LOANS_EXPIRING_SOON_FAILURE: "loans/LIST_LOANS_EXPIRING_SOON_FAILURE",

    LIST_LOANS_EXPIRING_SOON_RESET: "loans/LIST_LOANS_EXPIRING_SOON_RESET",
    CLOSE_LOANS_EXPIRING_SOON_MODAL: "loans/CLOSE_LOANS_EXPIRING_SOON_MODAL",
};

export const INITIAL_STATE = {
    fetchingDownload: false,
    selectedLoan: null,
    statements: [],
    moreStatements: false,
    pageNumber: 1,
    fetching: false,
    isFirstFetching: true,
    isFetchingDetail: false,
    isOptionsVisible: false,
    selectedFilter: "pendingFees",
    selectedStatement: null,
    amortizedLoans: null,
    propertiesLoans: null,
    loansList: [],
    loanTypesList: [],
    totalCount: true,
    filters: {
        loanType: "current",
        pageNumber: 1,
    },
    moreLoans: false,
    fetchingStatements: false,
    loanProductSelected: null,
    loansExpiringSoon: [],
    showExpiringLoansModal: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        statements,
        moreStatements,
        totalCount,
        selectedStatement,
        loansList,
        alias,
        filters,
        loanTypesList,
        ...rest
    } = action;

    switch (action.type) {
        case types.DOWNLOAD_PAYMENT_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_PAYMENT_FAILURE:
        case types.DOWNLOAD_PAYMENT_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.LIST_LOANS_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
                isFirstFetching: true,
            };
        case types.LIST_LOANS_SUCCESS:
            return {
                ...state,
                loansList,
                fetching: false,
                filters: {
                    ...state.filters,
                    pageNumber: action.pageNumber,
                },
                totalPages: action.totalPages,
                moreLoans: action.moreLoans,
                isFirstFetching: false,
            };
        case types.LIST_LOAN_TYPES_SUCCESS:
            return {
                ...state,
                loanTypesList,
            };
        case types.LOAN_DETAILS_REQUEST:
            return {
                ...state,
                isFetchingDetail: true,
            };
        case types.LOAN_DETAILS_SUCCESS:
            return {
                ...state,
                statements,
                moreStatements,
                totalCount,
                pageNumber: action.pageNumber,
                isFetchingDetail: false,
                ...rest,
            };
        case types.LOAN_READ_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LOAN_READ_SUCCESS:
            return {
                ...state,
                selectedLoan: action.loanDetail,
                numberOfFees: action.loanDetail.totalFees,
                fetching: false,
            };
        case types.LOAN_FETCH_MORE_STATEMENTS_REQUEST:
            return {
                ...state,
                fetchingStatements: true,
            };
        case types.LOAN_FETCH_MORE_STATEMENTS_SUCCESS:
            return {
                ...state,
                statements: state.statements.concat(statements),
                moreStatements,
                totalCount,
                fetchingStatements: false,
                pageNumber: action.pageNumber,
                filters: {
                    ...state.filters,
                    pageNumber: action.pageNumber,
                },
                ...rest,
            };
        case types.STATEMENT_READ_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.STATEMENT_READ_SUCCESS:
            return {
                ...state,
                selectedStatement: { ...state.selectedStatement, ...action.statementDetail },
                fetching: false,
            };
        case types.STATEMENT_READ_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAN_LOADED_SUCCESS:
            return {
                ...state,
                fetching: false,
                isFetchingDetail: false,
            };
        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.SET_SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: action.selectedFilter,
            };
        case types.SET_SELECTED_STATEMENT:
            return {
                ...state,
                selectedStatement,
            };
        case productsTypes.CHANGE_PRODUCT_ALIAS: {
            return {
                ...state,
                fetching: true,
            };
        }
        case productsTypes.CHANGE_PRODUCT_ALIAS_FAILURE: {
            return {
                ...state,
                fetching: false,
            };
        }
        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            return {
                ...state,
                selectedLoan: {
                    ...state.selectedLoan,
                    productAlias: alias,
                },
                fetching: false,
            };
        }
        case types.RESET_FILTERS: {
            return {
                ...state,
                filters: INITIAL_STATE.filters,
            };
        }
        case types.LOAD_MORE_LOANS_REQUEST: {
            return {
                ...state,
                fetching: true,
            };
        }
        case types.LOAD_MORE_LOANS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                loansList: state.loansList ? state.loansList.concat(action?.loansList) : action?.loansList,
                hasMoreData: action.pageNumber < action.totalPages,
                filters: {
                    ...state.filters,
                    pageNumber: action.pageNumber,
                },
                pageNumber: action.pageNumber,
                moreLoans: action.moreLoans,
            };
        }
        case types.SET_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        }
        case types.RESET_STATEMENTS: {
            return {
                ...state,
                statements: [],
            };
        }
        case types.SET_SELECTED_LOAN: {
            return {
                ...state,
                loanProductSelected: {
                    idProduct: action.loan.idProduct,
                    operationCode: action.loan.operationCode,
                    operationOrder: action.loan.operationOrder,
                    operationBranch: action.loan.operationBranch,
                },
            };
        }
        case types.LIST_LOANS_EXPIRING_SOON_REQUEST: {
            return {
                ...state,
                fetching: true,
            };
        }
        case types.LIST_LOANS_EXPIRING_SOON_SUCCESS: {
            return {
                ...state,
                loansExpiringSoon: action.loansExpiringSoon,
                showExpiringLoansModal: action.showExpiringLoansModal,
                fetching: false,
            };
        }

        case types.LIST_LOANS_EXPIRING_SOON_FAILURE: {
            return {
                ...state,
                fetching: false,
                showExpiringLoansModal: false,
            };
        }
        case types.LIST_LOANS_EXPIRING_SOON_RESET: {
            return {
                ...state,
                loansExpiringSoon: [],
                fetching: false,
                showExpiringLoansModal: false,
            };
        }
        case types.CLOSE_LOANS_EXPIRING_SOON_MODAL: {
            return {
                ...state,
                showExpiringLoansModal: false,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    listLoansSuccess: (loansList, pageNumber, moreLoans, totalPages, loanTypesList) => ({
        type: types.LIST_LOANS_SUCCESS,
        loansList,
        pageNumber,
        moreLoans,
        totalPages,
        loanTypesList,
    }),
    listLoans: (filters) => ({
        type: types.LIST_LOANS_REQUEST,
        filters,
    }),
    listLoansSoonToExpire: () => ({
        type: types.LIST_LOANS_EXPIRING_SOON_REQUEST,
    }),
    details: (selectedLoanId, filters) => ({
        type: types.LOAN_DETAILS_REQUEST,
        selectedLoanId,
        filters,
    }),
    downloadPayment: (idLoan, format) => ({
        type: types.DOWNLOAD_PAYMENT_REQUEST,
        idLoan,
        format,
    }),
    readLoan: (operationCode, operationBranch, operationOrder, idLoan, loanType) => ({
        type: types.LOAN_READ_REQUEST,
        operationCode,
        operationBranch,
        operationOrder,
        idLoan,
        loanType,
    }),
    fetchMoreStatements: (loanData, filters) => ({
        type: types.LOAN_FETCH_MORE_STATEMENTS_REQUEST,
        loanData,
        filters,
    }),
    fetchMoreStatementsSuccess: (statements, moreStatements, totalCount, pageNumber) => ({
        type: types.LOAN_FETCH_MORE_STATEMENTS_SUCCESS,
        statements,
        moreStatements,
        totalCount,
        pageNumber,
    }),
    readStatement: (operationCode, operationBranch, operationOrder, idStatement, feeType) => ({
        type: types.STATEMENT_READ_REQUEST,
        operationCode,
        operationBranch,
        operationOrder,
        idStatement,
        feeType,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    setSelectedFilter: (selectedFilter) => ({
        type: types.SET_SELECTED_FILTER,
        selectedFilter,
    }),
    setSelectedStatement: (selectedStatement) => ({
        type: types.SET_SELECTED_STATEMENT,
        selectedStatement,
    }),
    setBackToDesktop: (backToDesktop) => ({
        type: types.SET_BACK_TO_DESKTOP,
        backToDesktop,
    }),
    loadedLoan: () => ({
        type: types.LOAN_LOADED_SUCCESS,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    loadMoreLoansRequest: (filters) => ({
        type: types.LOAD_MORE_LOANS_REQUEST,
        filters,
    }),
    loadMoreLoansSuccess: (loansList, pageNumber, moreLoans, totalPages) => ({
        type: types.LOAD_MORE_LOANS_SUCCESS,
        loansList,
        pageNumber,
        moreLoans,
        totalPages,
    }),
    setFilters: (filters) => ({
        type: types.SET_FILTERS,
        filters,
    }),
    resetStatements: () => ({
        type: types.RESET_STATEMENTS,
    }),
    selectLoan: (loan) => ({
        type: types.SET_SELECTED_LOAN,
        loan,
    }),
    requestLoanTypesList: (loanType) => ({
        type: types.LIST_LOAN_TYPES_REQUEST,
        loanType,
    }),

    resetLoansExpiringSoon: () => ({
        type: types.LIST_LOANS_EXPIRING_SOON_RESET,
    }),
    closeLoansExpiringSoonModal: () => ({
        type: types.CLOSE_LOANS_EXPIRING_SOON_MODAL,
    }),
};

export const selectors = {
    getLoans: ({ loans }) => loans.loansList,
    getSelectedLoan: ({ loans }) => loans.selectedLoan,
    getPageNumber: ({ loans }) => loans.pageNumber,
    getStatements: ({ loans }) => loans.statements,
    getMoreLoans: ({ loans }) => loans.moreLoans,
    getFetching: ({ loans }) => loans.fetching,
    getFetchingDownload: ({ loans }) => loans.fetchingDownload,
    getIsOptionsVisible: ({ loans }) => loans.isOptionsVisible,
    getSelectedFilter: ({ loans }) => loans.selectedFilter,
    getSelectedStatement: ({ loans }) => loans.selectedStatement,
    getBackToDesktop: ({ loans }) => loans.backToDesktop,
    isFetchingDetail: ({ loans }) => loans.isFetchingDetail,
    getTotalCount: ({ loans }) => loans.totalCount,
    getFilters: ({ loans }) => loans.filters,
    getFetchingStatements: ({ loans }) => loans.fetchingStatements,
    getMoreStatements: ({ loans }) => loans.moreStatements,
    getLoanProductSelected: ({ loans }) => loans.loanProductSelected,
    getLoanTypesForSelector: ({ loans }) => loans.loanTypesList,
    getLoansSoonToExpire: ({ loans }) => loans.loansExpiringSoon,
    showExpiringLoansModal: ({ loans }) => loans.showExpiringLoansModal,
    getFirstFetching: ({ loans }) => loans.isFirstFetching,
};
