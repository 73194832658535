import React, { Component } from "react";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import { bool, number, string } from "prop-types";

import * as i18n from "util/i18n";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        quantity: number,
        currency: string,
        showDisclaimer: bool,
    };

    static defaultProps = {
        quantity: 0,
        currency: "",
        showDisclaimer: false,
    };

    render() {
        const { quantity, currency, showDisclaimer } = this.props;

        return (
            <>
                <div className="equivalent-total-balance">
                    <h4 className="data-label">
                        {i18n.get("accounts.equivalentTotalBalance.currency", null, {
                            currency: i18n.get(`currency.name.${currency}`).toLowerCase(),
                        })}
                    </h4>

                    <I18n id="accounts.balanceComposed.a11y" componentProps={{ className: "visually-hidden" }} />

                    <h3>
                        <FormattedAmount className="data-text" currency={currency} quantity={quantity} />
                    </h3>

                    {showDisclaimer && (
                        <div className="ui-body-small ui-mt-7">*{i18n.get("accounts.balanceComposed")}</div>
                    )}
                </div>
            </>
        );
    }
}

export default EquivalentTotalBalance;
