import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import * as configUtils from "util/config";

import * as i18nUtils from "util/i18n";

import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import I18n from "pages/_components/I18n";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "account.requestOpening";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const AccountOpeningForm = (props) => {
    const { mode, dispatch, location, fromBackoffice, transaction } = props;
    useEffect(() => {
        if (
            mode === "edit" ||
            (mode === "view" && transaction?.data?.accountBranchLabel === undefined && !fromBackoffice)
        ) {
            const formData = {};
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, location, fromBackoffice, mode, transaction]);

    const { idTransaction } = transaction || {};

    const renderFields = () => {
        const { currentLang, preData, data } = props;
        const accountTypeList = preData?.accountTypeList || [];
        const accountBranchList = preData?.accountBranchList || [];

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        return (
            <>
                {mode === "view" && data?.accountBranchLabel && data?.accountTypeLabel ? (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="accountTypeLabel"
                            name="accountTypeLabel"
                            idField="accountType"
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="accountBranchLabel"
                            name="accountBranchLabel"
                            idField="accountBranch"
                        />
                        {data?.cancelReason && (
                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id="forms.cancelRequest.cancelReason.ticket.label" />
                                </span>
                                <span className="data-text">{data.cancelReason}</span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={accountTypeList}
                            key="accountType"
                            name="accountType"
                            idField="accountType"
                            renderAs="combo"
                            value={data.accountType ? data.accountType[0] : ""}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={accountBranchList}
                            key="accountBranch"
                            name="accountBranch"
                            idField="accountBranch"
                            renderAs="combo"
                            value={data.accountBranch ? data.accountBranch : "1"}
                            additionalClassName="accountBranch"
                        />
                    </>
                )}
                {mode === "view" && <hr className="ui-mt-3 ui-mb-0" />}
                <DisclaimerContainer textSmall className="account-opening-termsAndConditions-disclaimer">
                    <I18n id="forms.account.requestOpening.disclaimer.termsAndConditions" component="p" />
                </DisclaimerContainer>
                {mode === "view" && <hr className="ui-mt-0 ui-mb-3" />}
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "1",
                            label:
                                mode === "edit"
                                    ? i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.checkbox.label`)
                                    : i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.checkboxView.label`),
                            link: configUtils.get("accountOpening.termsAndConditions"),
                            nameLink: i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.checkbox.label.nameLink`),
                        },
                    ]}
                    key="termsAndConditionsAcceptance"
                    // se cambia para que aparezca el titulo de campo solo si viene de backoffice
                    name={!fromBackoffice ? "termsAndConditionsAcceptance" : "termsAndConditions"}
                    idField={!fromBackoffice ? "termsAndConditionsAcceptance" : "termsAndConditions"}
                    renderAs="check"
                    value={data.termsAndConditionsAcceptance || false}
                    additionalClassName="termsAndConditions-checkbox"
                />
                {fromBackoffice && data.termsAndConditionsLink && (
                    // aparece debajo del campo de checkbox solo en backoffice
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="termsAndConditions"
                        name="termsAndConditionsLinkBackoffice"
                        idField="termsAndConditionsLinkBackoffice"
                        maxLength={150}
                        editing={false}
                        value={i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.link.selected`)}
                    />
                )}
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: `forms.${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};

AccountOpeningForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
};

AccountOpeningForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            accountBranch: "1",
            accountType: "",
            accountBranchLabel: props.data.accountBranchLabel,
            accountTypeLabel: props.data.accountTypeLabel,
            termsAndConditionsAcceptance: false,
        }),
    }),
    withRouter,
)(AccountOpeningForm);
