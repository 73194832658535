import { LOCATION_CHANGE } from "react-router-redux";

import { makeActionCreator, createReducer } from "util/redux";
import paginate, { selectors as paginateSelectors } from "reducers/paginate";
import { types as fileTypes } from "reducers/files";
import * as configUtils from "util/config";
import { types as multilineFileTypes } from "reducers/formFields/multilineFile";
import formTypes from "reducers/types/form";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import types from "./transactionLinesTypes";

const reducer = createReducer(
    {},
    {
        [LOCATION_CHANGE]: (state, action) => {
            if (action.payload.pathname.indexOf("transaction") === -1) {
                return { ...state, shouldLoadDraft: true };
            }
            return { ...state };
        },
        [types.ON_PAYMENT_METHOD_CHANGE]: (state) => ({
            ...state,
            list: [],
        }),
        [types.ADD_TRANSACTION_LINE]: (state, action) => ({
            ...state,
            editedLines: [action.payload, ...state.editedLines].map((line, index) => ({
                ...line,
                lineNumber: index + 1,
            })),
        }),
        [types.REMOVE_TRANSACTION_LINE]: (state, { lineNumberToRemove }) => ({
            ...state,
            editedLines: state.editedLines.reduce((result, line) => {
                if (line.lineNumber === lineNumberToRemove) {
                    return [...result];
                }
                if (line.lineNumber < lineNumberToRemove) {
                    return [...result, line];
                }
                return [
                    ...result,
                    {
                        ...line,
                        lineNumber: line.lineNumber - 1,
                    },
                ];
            }, []),
        }),
        [types.EDIT_TRANSACTION_LINE]: (state, action) => ({
            ...state,
            editedLines: state.editedLines.map((line) => {
                if (action.payload.lineNumber === line.lineNumber) {
                    return action.payload;
                }
                return line;
            }),
        }),
        [types.SAVE_CHANGED_TRANSACTION_LINES]: (state) => ({
            ...state,
            shouldLoadDraft: false,
            editedLines: [],
            list: [...state.editedLines],
        }),
        [types.CANCEL_CHANGED_TRANSACTION_LINES]: (state) => ({
            ...state,
            shouldLoadDraft: false,
            editedLines: [...state.list],
            shouldResetLines: true,
        }),
        [types.CHANGE_TRANSACTION_LINE]: (state, action) => ({
            ...state,
            editedLines: {
                ...state.editedLines,
                ...action.payload,
            },
        }),
        [types.STOP_RESETTING_LINES]: (state) => ({
            ...state,
            shouldResetLines: false,
        }),
        [types.INITIALIZE_EDITED_LINES]: (state) => {
            const editedLines = state.files && state.lines.length ? state.files.lines : state.list;
            return {
                ...state,
                editedLines,
            };
        },
        [types.ADD_EDITED_LINES]: (state) => ({
            ...state,
            linesBeingEdited: state.linesBeingEdited + 1,
        }),
        [types.SUBSTRACT_EDITED_LINES]: (state) => ({
            ...state,
            linesBeingEdited: state.linesBeingEdited - 1,
        }),
        [types.RESET_EDITED_LINES]: (state) => ({
            ...state,
            linesBeingEdited: 0,
        }),
        [types.SET_CURRENCY]: (state, action) => ({
            ...state,
            currency: action.payload,
            list: state.list.map((line) => ({
                ...line,
                creditAmountCurrency: action.payload,
            })),
        }),
        [types.SET_IS_EDITING_PAYMENT]: (state, action) => ({
            ...state,
            isEditingPayment: action.payload,
        }),
        [fileTypes.GET_FILE_CONTENTS_SUCCESS]: (state, action) => ({
            ...state,
            list: action.payload,
            editedLines: action.payload,
            fetching: false,
        }),
        [fileTypes.GET_FILE_CONTENTS_REQUEST]: (state) => ({
            ...state,
            fetching: true,
        }),
        [types.SET_PAGE_NUMBER]: (state, action) => ({
            ...state,
            pageNumber: action.payload,
        }),
        [types.CLEAR_TRANSACTION_LINES]: (state) => ({
            ...state,
            list: [],
            editedLines: [],
            fetching: false,
        }),
        [types.LOAD_TEMPLATE_DATA]: (state, actions) => {
            if (
                actions.payload &&
                actions.payload.uploadBy &&
                actions.payload.uploadBy[0] &&
                actions.payload.uploadBy[0] !== "file"
            ) {
                return { ...state, list: actions.payload[actions.payload.uploadBy[0]] };
            }
            return { ...state };
        },
        [multilineFileTypes.ON_FILE_PROCESS]: (state, action) => ({
            ...state,
            currency: action.processedFileData.totalAmount.currency,
        }),
        [formTypes.READ_TRANSACTION_SUCCESS]: (state, actions) => {
            const { idForm, transaction } = actions;

            if (
                idForm === SALARY_PAYMENT_ID_FORM &&
                transaction.idTransactionStatus === "DRAFT" &&
                state.shouldLoadDraft
            ) {
                if (
                    transaction.data.uploadBy &&
                    transaction.data.uploadBy[0] &&
                    transaction.data.uploadBy[0] !== "file"
                ) {
                    return { ...state, list: transaction.data[transaction.data.uploadBy[0]] };
                }
            }

            return { ...state };
        },
        [types.ON_FILTERS_CHANGED]: (state, action) => ({
            ...state,
            activeFilters: action.payload,
        }),
    },
);

const pagination = paginate([
    types.LIST_TRANSACTION_LINES_REQUEST,
    types.LIST_TRANSACTION_LINES_SUCCESS,
    types.LIST_TRANSACTION_LINES_FAILURE,
]);

export default (state, action) => {
    const { filters } = action.payload || {};
    return {
        editedLines: [],
        linesWithInitialValues: [],
        linesBeingEdited: 0,
        currency: "",
        ...reducer(pagination(state, action), action),
        activeFilters: filters || (state && state.activeFilters),
    };
};

export const actions = {
    listTransactionLinesRequest: makeActionCreator(types.LIST_TRANSACTION_LINES_REQUEST),
    listTransactionLinesFailure: makeActionCreator(types.LIST_TRANSACTION_LINES_FAILURE),
    listTransactionLinesSuccess: makeActionCreator(types.LIST_TRANSACTION_LINES_SUCCESS),
    clearTransactionLines: makeActionCreator(types.CLEAR_TRANSACTION_LINES),
    addTransactionLine: makeActionCreator(types.ADD_TRANSACTION_LINE),
    removeTransactionLine: makeActionCreator(types.REMOVE_TRANSACTION_LINE, "lineNumberToRemove"),
    editTransactionLine: makeActionCreator(types.EDIT_TRANSACTION_LINE),
    saveLines: makeActionCreator(types.SAVE_CHANGED_TRANSACTION_LINES),
    cancelEdition: makeActionCreator(types.CANCEL_CHANGED_TRANSACTION_LINES),
    changeTransactionLine: makeActionCreator(types.CHANGE_TRANSACTION_LINE),
    stopResettingLines: makeActionCreator(types.STOP_RESETTING_LINES),
    initializeEditedLines: makeActionCreator(types.INITIALIZE_EDITED_LINES),
    addEditedLines: makeActionCreator(types.ADD_EDITED_LINES),
    substractEditedLines: makeActionCreator(types.SUBSTRACT_EDITED_LINES),
    resetEditedLines: makeActionCreator(types.RESET_EDITED_LINES),
    removeAllFromInitialValues: makeActionCreator(types.REMOVE_ALL_FROM_INITIAL_VALUES),
    setCurrency: makeActionCreator(types.SET_CURRENCY),
    onPaymentMethodChange: makeActionCreator(types.ON_PAYMENT_METHOD_CHANGE),
    setIsEditingPayment: makeActionCreator(types.SET_IS_EDITING_PAYMENT),
    getFileContentsSuccess: makeActionCreator(fileTypes.GET_FILE_CONTENTS_SUCCESS),
    setPageNumber: makeActionCreator(types.SET_PAGE_NUMBER),
    loadTransactionLinesTemplateData: makeActionCreator(types.LOAD_TEMPLATE_DATA),
    onFiltersChanged: makeActionCreator(types.ON_FILTERS_CHANGED),
};

export const selectors = {
    getFailedLines: ({ form, formFields }) =>
        formFields.multilineFileProcess.failedLines ||
        form.transactionLines.list.reduce(
            (failedLines, { errorCode }) => (errorCode ? failedLines + 1 : failedLines),
            0,
        ),
    getProcessedLines: ({ form, formFields }) => {
        const { processedLines } = formFields.multilineFileProcess;
        return processedLines.length > 0
            ? processedLines.length
            : form.transactionLines.list.filter(({ errorCode }) => !errorCode).length;
    },
    getTotalAmount: ({ form, formFields }) => {
        const { totalAmount } = formFields.multilineFile;

        return totalAmount.quantity
            ? totalAmount
            : form.transactionLines.list.reduce(
                  ({ currency, quantity }, { creditAmountQuantity }) => ({
                      currency,
                      quantity: quantity + creditAmountQuantity,
                  }),
                  {
                      quantity: 0,
                      currency: selectors.getCurrency({
                          form,
                      }),
                  },
              );
    },
    getTotalLines: ({ form }) => paginateSelectors.getTotalLines(form.transactionLines),
    getTransactionLines: ({ form, files }) => {
        const { lines } = files;
        return lines.length ? lines : paginateSelectors.getList(form.transactionLines);
    },
    getPageNumber: ({ form }) => paginateSelectors.getPageNumber(form.transactionLines),
    isLastPage: ({ form }) => paginateSelectors.isLastPage(form.transactionLines),
    isFetching: ({ form }) => paginateSelectors.isFetching(form.transactionLines),
    getActiveFilters: ({ form }) => form.transactionLines.activeFilters,
    getEditedLines: ({ form }) => form.transactionLines.editedLines,
    shouldResetLines: ({ form }) => form.transactionLines.shouldResetLines,
    getLinesBeingEdited: ({ form }) => form.transactionLines.linesBeingEdited,
    getLinesWithInitialValues: ({ form }) => form.transactionLines.linesWithInitialValues,
    getCurrency: ({ form }) => {
        if (form.transactionLines.currency !== "") {
            return form.transactionLines.currency;
        }
        return configUtils.get("core.masterCurrency");
    },
    isEditingPayment: ({ form }) => form.transactionLines.isEditingPayment,
    getLinesWithNoAmount: ({ form }) => {
        if (form.transactionLines.editedLines.length) {
            return form.transactionLines.editedLines.filter((line) => !line.creditAmountQuantity);
        }
        return form.transactionLines.list.filter((line) => !line.creditAmountQuantity);
    },
};
