import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import Yup from "yup";

import moment from "moment";
import * as i18n from "util/i18n";
import defaultDateFromList from "util/defaultDateFromList";
import { DateField } from "pages/_components/fields/DateField";
import { Field, Form, withFormik } from "formik";
import { clearFiltersAndSubmit } from "util/form";
import * as config from "../../util/config";
import { actions } from "../../reducers/investments";
import { selectors as i18nSelectors } from "../../reducers/i18n";

import * as FormFieldsComponents from "../forms/_components/_fields/Index";
import Button from "../_components/Button";

const MovementFilters = ({
    isMobile,
    dispatch,
    filters,
    values,
    shareholderAccounts,
    availableFunds,
    setFieldValue,
    setFieldError,
    currentLang,
    setValues,
    submitForm,
}) => {
    const [showFilters, setShowFilters] = useState(false);
    const myRef = useRef();
    const minDaysBack = config.get("mutualFunds.movements.minDaysBack", 730);

    useEffect(() => {
        myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }, []);

    const refreshMovements = ({ id }) => {
        dispatch(
            actions.setMovementFilters({
                shareholderAccount: id,
                mutualFund: {},
                dateFrom: defaultDateFromList().mutualFundsMovements.dateFrom,
                dateTo: defaultDateFromList().mutualFundsMovements.dateTo,
            }),
        );
        dispatch(
            actions.loadAvailableFunds(null, {
                shareholderAccount: id,
                mutualFund: {},
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo,
                pageNumber: 1,
            }),
        );
    };

    const handleClearFilters = () => {
        clearFiltersAndSubmit(
            () =>
                setValues({
                    ...values,
                    mutualFund: [""],
                    dateFrom: defaultDateFromList().mutualFundsMovements.dateFrom,
                    dateTo: defaultDateFromList().mutualFundsMovements.dateTo,
                }),
            submitForm,
        );
    };

    const renderFiltersFields = () => (
        <>
            <Field
                component={FormFieldsComponents.Selector}
                optionList={availableFunds.map(({ number, name }) => ({
                    id: number,
                    label: `${name}`,
                }))}
                key="mutualFund"
                name="mutualFund"
                idField="mutualFund"
                renderAs="combo"
                idActivity="investment.mutualFund.movements.send"
                idForm="investment.mutualFund.movements"
                mode="edit"
                isRequired
                lang={currentLang}
            />

            <Field
                idField="dateFrom"
                component={DateField}
                hidePlaceholder
                name="dateFrom"
                idForm="investments.mutualFund.movements"
                maxDate={values.dateTo ?? moment()}
                minDate={moment().add(-minDaysBack, "days")}
                isClearable
                showMonthYearDropdown
                onChange={(e) => {
                    setFieldValue("dateFrom", e);
                    setFieldError("dateFrom", null);
                }}
            />

            <Field
                idField="dateTo"
                component={DateField}
                hidePlaceholder
                name="dateTo"
                idForm="investments.mutualFund.movements"
                dateFormat="DD/MM/YYYY"
                maxDate={moment()}
                minDate={values.dateFrom ?? moment()}
                selectsStart
                showMonthYearDropdown
                onChange={(e) => {
                    setFieldValue("dateTo", e);
                    setFieldError("dateTo", null);
                }}
            />

            <Button
                className="btn-submit"
                block={isMobile}
                bsStyle="primary"
                label="investments.mutualFund.movements.filter.label"
                type="submit"
            />
            <Button
                className="btn-clear-filters"
                block={isMobile}
                bsStyle="outline"
                label="product.filters.clearFilters"
                image="images/ui-icons/ui-clear-filters.svg"
                onClick={handleClearFilters}
            />
        </>
    );

    return (
        <div ref={myRef} className="filters-container mutual-funds-movements">
            <Form className="form-content">
                {isMobile ? (
                    <>
                        <Field
                            component={FormFieldsComponents.Selector}
                            optionList={shareholderAccounts.map(({ number, name }) => ({
                                id: number,
                                label: `${number} - ${name}`,
                            }))}
                            key="shareholderAccount"
                            name="shareholderAccount"
                            idField="shareholderAccount"
                            renderAs="combo"
                            idActivity="investment.mutualFund.movements.send"
                            idForm="investment.mutualFund.movements"
                            mode="edit"
                            onChange={(e) => refreshMovements(e)}
                            isRequired
                            additionalClassName="ui-mb-0"
                            lang={currentLang}
                        />

                        <hr className="ui-mt-7 ui-mb-7" />

                        <Button
                            onClick={() => setShowFilters(!showFilters)}
                            bsStyle="filter"
                            className={`btn-link ui-mt-0 ${showFilters ? "ui-mb-7" : ""}`}
                            block
                            image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                            label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                        />

                        {showFilters && renderFiltersFields()}
                    </>
                ) : (
                    <>
                        <div className="filters-row">
                            <Field
                                component={FormFieldsComponents.Selector}
                                optionList={shareholderAccounts.map(({ number, name }) => ({
                                    id: number,
                                    label: `${number} - ${name}`,
                                }))}
                                key="shareholderAccount"
                                name="shareholderAccount"
                                idField="shareholderAccount"
                                renderAs="combo"
                                idActivity="investment.mutualFund.movements.send"
                                idForm="investment.mutualFund.movements"
                                mode="edit"
                                onChange={(e) => refreshMovements(e)}
                                isRequired
                                lang={currentLang}
                            />
                        </div>
                        <div className="filters-row">{renderFiltersFields()}</div>
                    </>
                )}
            </Form>
        </div>
    );
};

MovementFilters.propTypes = {
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    filters: shape({}).isRequired,
    values: shape({}).isRequired,
    shareholderAccounts: oneOfType([array]).isRequired,
    availableFunds: oneOfType([array]).isRequired,
    setFieldValue: func.isRequired,
    setFieldError: func.isRequired,
    currentLang: string.isRequired,
    setValues: func.isRequired,
    submitForm: func.isRequired,
};

const mapPropsToState = (state) => ({
    shareholderAccounts: state.investments.shareholderAccounts,
    availableFunds: state.investments.availableFunds,
    mutualFunds: state.investments.mutualFunds,
    filters: state.investments.movementFilters,
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapPropsToState),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ filters, shareholderAccounts, availableFunds }) => ({
            dateFrom: filters.dateFrom ? filters.dateFrom : null,
            dateTo: filters.dateTo ? filters.dateTo : null,
            shareholderAccount:
                filters.shareholderAccount === "" ? [shareholderAccounts[0]?.number] : [filters?.shareholderAccount],
            mutualFund:
                Object.keys(filters?.mutualFund).length > 0 ? [filters.mutualFund.number] : availableFunds[0]?.number,
        }),
        validationSchema: () => {
            const minDaysBack = config.get("mutualFunds.movements.minDaysBack", 730);
            const minDateValidationMsg = i18n.get(
                "investments.mutualFund.movements.dateFrom.minValidation.message",
                "",
            );
            const maxDateValidationMsg = i18n.get("investments.mutualFund.movements.dateTo.maxValidation.message", "");
            return Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: Yup.date()
                        .nullable()
                        .max(values.dateTo ?? new Date(), minDateValidationMsg)
                        .required(i18n.get("investments.mutualFund.required.message")),
                    dateTo: Yup.date()
                        .nullable()
                        .min(values.dateFrom ?? moment().add(-minDaysBack, "days"), maxDateValidationMsg)
                        .required(i18n.get("investments.mutualFund.required.message")),
                }),
            );
        },
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch, availableFunds, shareholderAccounts } = formikBag.props;
            const mutualFund = availableFunds.find((fund) => fund.number === values.mutualFund[0]);
            dispatch(
                actions.setMovementFilters({
                    shareholderAccount: values.shareholderAccount[0] || shareholderAccounts[0]?.number,
                    mutualFund,
                    dateFrom: values.dateFrom,
                    dateTo: values.dateTo,
                }),
            );
            dispatch(
                actions.loadFundMovements(
                    values.shareholderAccount[0] || shareholderAccounts[0]?.number,
                    mutualFund?.number,
                    mutualFund?.typeId,
                    values.dateFrom,
                    values.dateTo,
                    1,
                ),
            );
        },
    }),
)(MovementFilters);
