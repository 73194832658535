import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";

import { actions as settingsActions } from "reducers/settings";
import * as i18n from "util/i18n";
import * as config from "util/config";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { func, bool } from "prop-types";

const FORM_ID = "settings.changePassword";

class ChangePassword extends Component {
    static propTypes = {
        isMobile: bool,
        history: func.isRequired,
        isSubmitting: bool,
    };

    static defaultProps = {
        isSubmitting: false,
        isMobile: false,
    };

    renderHeader = () => {
        const { history, isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo arrowWhite onBack={history.goBack} additionalClassName="blue-main-header-mobile" />;
        }

        return <Head onBack={history.goBack} accessibilityTextId="settings.index" />;
    };

    render() {
        const { isSubmitting } = this.props;
        const maxLength = config.getInteger("core.password.maxLength");

        return (
            <React.Fragment>
                {this.renderHeader()}
                <MainContainer>
                    <div className="container-center">
                        <div className="title-left-header">
                            <I18n id="settings.changePassword" component="h1" />
                        </div>
                        <Form className="form-content">
                            <div className="form-text-info">
                                <I18n
                                    id="settings.changePassword.textInfo"
                                    component="p"
                                    componentProps={{ className: "ui-mb-7" }}
                                />
                                <ul className="ui-fw-bold">
                                    <I18n id="settings.changePassword.passwordHelp.rule1" component="li" />
                                    <I18n id="settings.changePassword.passwordHelp.rule2" component="li" />
                                    <I18n id="settings.changePassword.passwordHelp.rule3" component="li" />
                                    <I18n id="recoveryPassword.step3.requirements.rule4" component="li" />
                                </ul>
                            </div>

                            <Field name="password" idForm={FORM_ID} component={Credential} maxLength={maxLength} />
                            <Field
                                name="newPassword"
                                idForm={FORM_ID}
                                component={Credential}
                                showStrength
                                maxLength={maxLength}
                            />
                            <Field
                                name="newPasswordConfirmation"
                                idForm={FORM_ID}
                                component={Credential}
                                maxLength={maxLength}
                            />
                            <Button
                                block
                                label="global.modify"
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                            />
                        </Form>
                    </div>
                </MainContainer>
            </React.Fragment>
        );
    }
}

export default compose(
    connect(null),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ password: "", newPassword: "", newPasswordConfirmation: "" }),
        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                newPasswordConfirmation: Yup.string().required(i18n.get(`${FORM_ID}.newPasswordConfirmation.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changePassword(
                    values.password,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(ChangePassword);
