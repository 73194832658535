import React from "react";
import { connect } from "react-redux";
import * as login from "reducers/login";
import { bool, func } from "prop-types";
import { push } from "react-router-redux";
import I18n from "pages/_components/I18n";

import SimpleModal from "components/SimpleModal/SimpleModal";

const ExpiredPasswordModal = ({ isOpen, dispatch }) => {
    const closeAlert = () => {
        dispatch(login.actions.hideAlertExpiredPassword());
    };

    const changePassword = () => {
        dispatch(push("/loginStep2/passwordExpired"));
    };

    return (
        <SimpleModal
            isDisplayed={isOpen}
            closeModal={closeAlert}
            modalIcon="images/error.svg"
            modalBigTitle="expired.password.modalTitle"
            buttonAction={changePassword}
            buttonActionLabel="expired.password.confirm.button.label"
            noCancelButton>
                <I18n
                    component="p"
                    id="expired.password.modalMessage"
                />
        </SimpleModal>
    );
};

ExpiredPasswordModal.propTypes = {
    isOpen: bool.isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: login.selectors.isShowAlertExpiredPassword(state),
});

export default connect(mapStateToProps)(ExpiredPasswordModal);
