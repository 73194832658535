import { Field, withFormik } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import InvestorProfileModal from "pages/investment/_components/investorProfile/InvestorProfileModal";
import InvestorProfileName from "pages/investment/_components/investorProfile/InvestorProfileName";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as investmentsActions, selectors as investmentsSelectors } from "reducers/investments";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import InvestorProfileImage from "pages/investment/_components/investorProfile/InvestorProfileImage";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import { selectors as templateSelectors } from "../../../reducers/template";
import FormTransition from "../_components/FormTransition";

const ID_ACTIVITY_PRE = "investments.investerProfileForm.pre";
const ID_ACTIVITY = "investments.investerProfileForm.send";
const ID_FORM = "investments.investerProfileForm";

const InvesterProfileForm = (props) => {
    const {
        mode,
        dispatch,
        currentLang,
        fromBackoffice,
        transaction,
        location,
        data,
        preData,
        investorProfileCalculated,
        invertorProfileAnswers,
        formikBag,
        profile,
        activeEnvironment,
        isDesktop,
    } = props;

    const isTicket = location.pathname.match("/transaction/") != null;
    const idTransaction = transaction?.idTransaction;
    const [useDefaultSubmit, setUseDefaultSubmit] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const questionsList = preData?.questions || [];

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = preData || {};

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };
    const genericPropsTicket = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
        disabled: true,
    };

    useEffect(() => {
        if (mode === "edit") {
            const requestData = {
                segment: activeEnvironment.segment,
                getProfile: "false",
                getQuestions: "true",
                getAnswers: profile?.created && profile?.status === "upToDate",
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        }
    }, [dispatch, location, transaction]); // eslint-disable-line

    const hideModal = () => {
        setDisplayModal(false);
        dispatch(investmentsActions.resetInvestorCalculated());
    };

    const saveProfile = () => {
        const payLoad = {
            idActivity: ID_ACTIVITY,
            values: {
                profile: investorProfileCalculated?.type,
                filteredAnswers: invertorProfileAnswers,
                questionsList,
                segment: activeEnvironment.segment,
            },
            formikBag,
        };
        dispatch(investmentsActions.saveInvestorCalculated(payLoad));
        setDisplayModal(false);
    };

    const renderInvestorProfileCalculated = () => {
        setDisplayModal(true);

        return (
            <InvestorProfileModal
                buttonAction={saveProfile}
                closeModal={hideModal}
                isDesktop={isDesktop}
                isDisplayed={displayModal}
                profile={investorProfileCalculated}
            />
        );
    };

    const renderFields = () => {
        const renderEditMode = () => (
            <>
                {questionsList.map((q) => (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key={q.questionId}
                        name={q.questionId}
                        idField={q.questionId}
                        idForm={ID_FORM}
                        renderAs="radio"
                        onChange={() => {}}
                        optionList={q.options}
                        value={q.selectedOptionId ? [q.selectedOptionId] : ""}
                        labelText={q.questionLabel}
                    />
                ))}
            </>
        );

        const renderForTicket = () => {
            const optionList = data?.filteredAnswers || [];
            const questionsListTicket = data?.questionsList || [];
            const dataProfile = { type: data?.profile };

            const finalQuestionListTicket = questionsListTicket.map((item) => {
                const filteredNode = optionList.find((itemOption) => itemOption.questionId === item.questionId);
                return {
                    ...item,
                    selectedOptionId: filteredNode.answerId,
                };
            });

            if (finalQuestionListTicket.length === 0) {
                return null;
            }

            return (
                <>
                    {finalQuestionListTicket.map((q) => (
                        <Field
                            {...genericPropsTicket}
                            component={FormFieldsComponents.Selector}
                            key={q.questionId}
                            name={q.questionId}
                            idField={q.questionId}
                            idForm={ID_FORM}
                            renderAs="radio"
                            onChange={() => {}}
                            optionList={q.options}
                            value={q.selectedOptionId ? [q.selectedOptionId] : ""}
                            labelText={q.questionLabel}
                            additionalClassName="ui-mb-3"
                        />
                    ))}

                    <div className="profileTicket">
                        <InvestorProfileImage profile={dataProfile} />
                        <InvestorProfileName profileName={dataProfile.type} usedFor="forTicket" />
                    </div>

                    <DisclaimerContainer textSmall className="ui-mt-8">
                        <I18n id="investments.mutualFund.investerProfile.ticket.disclaimer" component="p" />

                        <I18n
                            id="investments.mutualFund.investerProfile.save.disclaimer"
                            component="p"
                            componentProps={{ className: "ui-mt-4" }}
                        />
                    </DisclaimerContainer>
                </>
            );
        };

        return (
            <>
                {investorProfileCalculated && renderInvestorProfileCalculated()}
                {mode === "edit" && renderEditMode()}
                {(isTicket || mode === "view") && renderForTicket()}
            </>
        );
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const renderAdditionalActions = (fetching) => {
        const confirmActionLabel = i18nUtils.get(`forms.investments.investerProfileForm.action.confirm.label`);
        if (mode === "edit") {
            return (
                <Button
                    block
                    type="submit"
                    label={confirmActionLabel}
                    bsStyle="primary"
                    loading={fetching}
                    defaultLabelText={confirmActionLabel}
                    onClick={() => setUseDefaultSubmit(false)}
                />
            );
        }
        return null;
    };

    const alternateSubmitFunc = (formData) =>
        investmentsActions.calculateInvestorProfileRequest(formData, questionsList, activeEnvironment.segment);

    const formProps = {
        title: "investments.mutualFund.investerProfile",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            returnBack: true,
            hideHeaderBack: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
            showSubmit: false,
        },
        renderFields,
        renderAdditionalActions,
        useDefaultSubmit,
        alternateSubmitFunc,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        showConfirmationMessageAboveField: true,
        idActivity: ID_ACTIVITY,
        subtitle: i18nUtils.get("investments.mutualFund.investerProfile.subtitle", null, {
            EMPRESA: activeEnvironment?.name,
        }),
        subtitleClean: true,
        modalButtonLabel: "forms.saveTemplate.link",
        buttonLabel: "investments.mutualFund.investerProfile.buttonConfirm",
        customLabelMessage: "",
        exportList: "pdf",
        handleClickDownload,
        hideHeaderBack: false,
    };

    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    mode: formSelectors.getMode(state),
    preData: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    data: formSelectors.getData(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    investorProfileCalculated: investmentsSelectors.getInvestorProfileCalculated(state),
    invertorProfileAnswers: investmentsSelectors.getInvestorProfileAnswers(state),
    formikBag: investmentsSelectors.getInvestorProfileFormikBag(state),
    profile: investmentsSelectors.getInvestorProfile(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

InvesterProfileForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    fetching: bool.isRequired,
    fromBackoffice: bool,
    useDefaultSubmit: bool,
    currentLang: string.isRequired,
    isDesktop: bool.isRequired,
    location: shape({}).isRequired,
    formErrors: shape({}).isRequired,
    profile: shape({}).isRequired,
    investorProfileCalculated: shape({}).isRequired,
    invertorProfileAnswers: shape({}).isRequired,
    formikBag: shape({}).isRequired,
    activeEnvironment: shape({}).isRequired,
};

InvesterProfileForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(InvesterProfileForm);
