import * as API from "middleware/api";

export const getListOfTransferPayments = (transactionId, pageNumber) =>
    API.executeWithAccessToken("/pay.transfers.massive.list", {
        transactionId,
        pageNumber,
    });

export const validatePaymentByTransfer = (debitAccount, name, cuit, cbu, amount, reference) =>
    API.executeWithAccessToken("/pay.transfers.massive.validate", {
        debitAccount,
        name,
        cuit,
        cbu,
        amount,
        reference,
    });

export const getPaymentSummary = (transactionId) =>
    API.executeWithAccessToken("/pay.summary", {
        transactionId,
    });

export const downloadPaymentSampleFile = () => API.executeWithAccessToken("/pay.transfers.massive.file.download");

export const downloadPaymentsCsvFile = (transactionId, transactionStatusLabel, paymentDetails, paymentsToDownload) =>
    API.executeWithAccessToken("/pay.transfers.massive.paylines.file.download", {
        transactionId,
        transactionStatusLabel,
        paymentDetails,
        paymentsToDownload,
    });

export const downloadTicketDetails = (idTransaction, downloadType, payments) =>
    API.executeWithAccessToken("/pay.transfers.massive.download.ticket", {
        idTicket: idTransaction,
        isFromDetail: true,
        type: downloadType,
        payments,
    });
