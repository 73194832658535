import { bool, func, shape } from "prop-types";
import React, { Component, Fragment } from "react";

import Compose from "pages/communications/_components/Compose";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { connect } from "react-redux";
import { selectors as communicationSelectors } from "reducers/communication";
import Notification from "pages/_components/Notification";

class NewMessage extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        history: shape({ goBack: func }),
        preloadedMessage: shape({}).isRequired,
    };

    static defaultProps = {
        history: null,
    };

    handleBackClick = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { isDesktop, preloadedMessage } = this.props;

        return (
            <>
                {!isDesktop ? (
                    <Fragment>
                        <Notification scopeToShow="communications" />
                        <Head showLogo additionalClassName="blue-main-header-mobile" />
                        <MainContainer>
                            <Compose preloadedMessage={preloadedMessage} isCompose />
                        </MainContainer>
                    </Fragment>
                ) : (
                    this.handleBackClick()
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    preloadedMessage: communicationSelectors.getPreloadedMessage(state),
});
export default connect(mapStateToProps)(NewMessage);
