import React, { useEffect, useState } from "react";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";
import { bool, func, shape, string } from "prop-types";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as dateUtils from "util/date";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import AddFrequentRecipientButton from "pages/transfers/_components/AddFrequentRecipientButton";
import FormTransition from "../_components/FormTransition";
import * as config from "../../../util/config";
import * as i18nUtils from "../../../util/i18n";
import Button from "../../_components/Button";
import { selectors as templateSelectors } from "../../../reducers/template";
import FrequentRecipients from "../../transfers/FrequentRecipients";
import ChangeStatusTransactionComponent from "./ChangeStatusTransactionComponent";
import CustomScheduler from "../_components/_scheduler/CustomScheduler";
import * as maskUtils from "util/mask";

const ID_ACTIVITY_PRE = "transfers.local.pre";
const ID_ACTIVITY = "transfers.local.send";
const ID_FORM = "transfers.local";

const TransferLocalForm = (props) => {
    const {
        mode,
        dispatch,
        data,
        currentLang,
        fromBackoffice,
        transaction,
        values,
        location,
        formErrors,
        isDesktop,
    } = props;
    const idTransaction = transaction?.idTransaction;
    const [amount, setAmount] = useState("");
    const [isEmailEnabled, setEmailEnabled] = useState(false);
    const [isSameCuit, setSameCuit] = useState(false);
    const [isFrequentDestinationsShowed, setStatusFrequentDestination] = useState(false);
    const [errors, setErrors] = useState({});
    const [amountMax, setAmountMax] = useState(null);
    const [errorsAux, setErrorsAux] = useState({});
    const destinationTypes = config
        .get("forms.transfers.local.accountIdentifier")
        .split("|")
        .map((id) => ({
            label: i18nUtils.get(`forms.${ID_FORM}.accountIdentifier.${id}.label`),
            id,
        }));
    const [cuitToAdd, setCuitToAdd] = useState(null);
    const [destinationTypeToAdd, setDestinationTypeToAdd] = useState([destinationTypes[0].id]);
    const [destinationIdentifierToAdd, setDestinationIdentifierToAdd] = useState(null);

    const mapsFieldsToChange = new Map([
        ["cuit", "cuit"],
        ["destination", "destinationIdentifier"],
        ["type", "destinationType"],
        ["email", "email"],
    ]);

    const [amountData, setAmountData] = useState({
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [
            {
                id: "",
                label: "",
            },
        ],
    });

    const { ...restPreData } = props.preData || {};

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    useEffect(() => {
        setErrors(formErrors);
    }, [formErrors]);

    useEffect(() => {
        if (mode === "edit") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
                transfersValidation: {
                    accountDescription: null,
                    clientDescription: null,
                    cuit: null,
                    bank: null,
                    alias: null,
                    cbu: null,
                },
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            setAmountData({
                options: [
                    {
                        id: "",
                        label: "",
                    },
                ],
            });
        }
        setAmount("");
        setSameCuit(data.cuitConfirmation && data.cuitConfirmation.length > 1 ? data.cuitConfirmation : false);
        setEmailEnabled(data.mailConfirmation && data.mailConfirmation.length > 1 ? data.mailConfirmation : false);
        setDestinationIdentifierToAdd(
            data.destinationIdentifier && data.destinationIdentifier.length > 0 ? data.destinationIdentifier : null,
        );
        setDestinationTypeToAdd(
            data.destinationType && data.destinationType.length > 0 ? data.destinationType : [destinationTypes[0].id],
        );
        setCuitToAdd(data.cuit && data.cuit.length > 0 ? data.cuit : null);
    }, [dispatch, location, transaction]); // eslint-disable-line

    // Función para que el focus se aplique a ambos campos en casos como este
    // Solo reemplazar el targetId por el del input text (no el del selector)
    const focusDoubleInput = (e) => {
        const targetId = "destinationIdentifier";
        const targetElement = document.querySelector(`.${targetId}`);

        if (targetElement) {
            if (e.target.classList.contains("Select-control")) {
                targetElement.classList.add("has-focus");
            } else if (e.target.id !== targetId) {
                targetElement.classList.remove("has-focus");
            }
        }
    };

    const onTemplateLoad = (templateData) => {
        if (Object.keys(templateData).length > 0 && mode === "edit") {
            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: templateData.amount.currency,
                        label: i18nUtils.get(`currency.label.${templateData.amount.currency}`),
                    },
                ],
            });
        }
        setEmailEnabled(templateData?.mailConfirmation?.includes("1") || false);
        setDestinationIdentifierToAdd(
            templateData.destinationIdentifier && templateData.destinationIdentifier.length > 0
                ? templateData.destinationIdentifier
                : null,
        );
        setDestinationTypeToAdd(
            templateData.destinationType && templateData.destinationType.length > 0
                ? templateData.destinationType
                : [destinationTypes[0].id],
        );
        setCuitToAdd(templateData.cuit && templateData.cuit.length > 0 ? templateData.cuit : null);
        setSameCuit(templateData?.cuitConfirmation?.includes("1") || false);
    };

    useEffect(() => {
        document.addEventListener("click", focusDoubleInput);
        document.addEventListener("touchstart", focusDoubleInput);

        return () => {
            document.removeEventListener("click", focusDoubleInput);
            document.removeEventListener("touchstart", focusDoubleInput);
        };
    }, []);
    // Fin de la función y su useEffect

    const renderFields = (setFieldValue) => {
        const { isMobile } = props;

        const preData = values.preData || {
            currencyList: [],
            debitAccountList: [],
            cuit: "",
        };

        const handleChangeAccount = (id) => {
            const { currency, balance, label } =
                preData.debitAccountList.find((account) => account.idProduct === id) || data;
            setAmountData({
                decimalSeparator: ",",
                precision: 2,
                thousandsSeparator: ".",
                options: [
                    {
                        id: currency,
                        label: i18nUtils.get(`currency.label.${currency}`),
                    },
                ],
            });
            setFieldValue("amount", {
                currency,
                quantity: data.amount?.quantity && amount === "" ? data.amount?.quantity : amount,
            });
            setAmountMax(balance);
            setFieldValue("labelDebit", label);
        };

        const handleCuitConfirmation = (e) => {
            const cuit = e.includes("1") ? preData?.cuit : "";
            setFieldValue("cuit", cuit.replace(/^(\d{2})(\d{8})(\d{1}).*/, "$1-$2-$3"));
            setSameCuit(!isSameCuit);
        };

        const handleEmailConfirmation = (e) => {
            setEmailEnabled(e.includes("1"));
            if (!e.includes("1")) {
                setFieldValue("email", null);
                setFieldValue("message", null);
            }
        };

        const conceptTypes = config
            .get("transfers.concepts")
            .split("|")
            .map((id) => ({
                label: i18nUtils.get(`forms.transfers.concepts.${id}.label`),
                id,
            }));

        const accounts = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };

        const tooltipProps = {
            "data-tooltip": i18nUtils.get("forms.transfers.local.cuit.tooltip.label"),
            "data-tooltip-position": "right",
        };

        const handleChangeAmount = (e) => {
            if (amountMax || amountMax === 0) {
                if (e > amountMax && e > 0 && !errors.amount) {
                    if (dateUtils.isOutOfTime(config.get("transfers.timeLimit"), dateUtils.TIME_ZONE_BANK)) {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.error") });
                    } else {
                        setErrorsAux({ amount: i18nUtils.get("forms.transfers.internal.amount.exceeds.message") });
                    }
                } else {
                    setErrorsAux({ amount: null });
                }
            }
        };

        const { transfersValidation } = data || null;

        return (
            <>
                {mode === "view" || mode === "preview" ? (
                    <>
                        <Field
                            {...genericProps}
                            name="debitAccount"
                            idField="debitAccount"
                            key="debitAccount"
                            data={accounts}
                            value={data.debitAccount || ""}
                            component={FormFieldsComponents.ProductselectorCustom}
                            labelBackoffice={data.labelDebit}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="destinationAccount"
                            name="destinationAccount"
                            idField="destinationAccount"
                            value={transfersValidation.accountDescription || ""}
                            maxLength={50}
                        />

                        {(mode === "preview" || mode === "view") && isSameCuit && (
                            <div className="data-wrapper">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    optionList={[
                                        {
                                            id: "1",
                                            label: i18nUtils.get(`forms.${ID_FORM}.cuitConfirmation.label`),
                                        },
                                    ]}
                                    key="cuitConfirmation"
                                    name="cuitConfirmation"
                                    idField="cuitCheck"
                                    renderAs="check"
                                    mode="edit"
                                    handleChange={() => {}}
                                    onChange={() => {}}
                                    value={data?.cuitConfirmation}
                                    disabled={mode !== "edit"}
                                />
                            </div>
                        )}

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="holder"
                            name="holder"
                            idField="holder"
                            value={transfersValidation.clientDescription || ""}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="cuit"
                            name="cuit"
                            idField="cuit"
                            mask={maskUtils.cuitMask}
                            value={transfersValidation.cuit}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="bank"
                            name="bank"
                            idField="bank"
                            value={transfersValidation.bank || ""}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="alias"
                            name="alias"
                            idField="alias"
                            value={transfersValidation.alias || ""}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="cbu"
                            name="cbu"
                            idField="cbu"
                            value={transfersValidation.cbu || ""}
                            maxLength={50}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            data={amountData}
                            key="amount"
                            name="amount"
                            idField="amount"
                            value={data.amount || "$8.000"}
                            amountNull
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={conceptTypes}
                            key="concept"
                            name="concept"
                            idField="concept"
                            renderAs="combo"
                            value={data.concept ? data.concept[0] : ["VAR"]}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="reference"
                            name="reference"
                            idField="reference"
                            maxLength={11}
                            value={data.reference || ""}
                        />

                        <CustomScheduler {...genericProps} isDesktop={isDesktop} scheduler={data.scheduler} />

                        {data.mailConfirmation.length > 1 && (
                            <>
                                {mode !== "edit" && <hr />}
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="email"
                                    name="email"
                                    idField="email"
                                />
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Textarea}
                                    key="notificationBody"
                                    name="message"
                                    idField="message"
                                    maxLength={500}
                                />
                            </>
                        )}

                        <ChangeStatusTransactionComponent transaction={transaction} genericProps={genericProps} />
                    </>
                ) : (
                    <>
                        <Field
                            {...genericProps}
                            name="debitAccount"
                            idField="debitAccount"
                            key="debitAccount"
                            data={accounts}
                            value={data.debitAccount || ""}
                            handleChange={(e) => handleChangeAccount(e)}
                            component={FormFieldsComponents.ProductselectorCustom}
                        />

                        {mode === "edit" && (
                            <>
                                <hr className="ui-mt-0 ui-mb-7" />

                                <div className="form-group ui-mb-3">
                                    <div className="form-group-text">
                                        <label className="control-label">
                                            {i18nUtils.get("forms.transfers.local.destinationType.label")}
                                        </label>
                                    </div>
                                    <Button
                                        image="images/ui-icons/ui-add-user.svg"
                                        label={`forms.${ID_FORM}.recipientAddressBook.label`}
                                        bsStyle="link"
                                        className="btn-link--lowerCase btn-link--wIcon"
                                        onClick={() => setStatusFrequentDestination(true)}
                                    />
                                </div>

                                {isFrequentDestinationsShowed && (
                                    <FrequentRecipients
                                        mapsFieldsToChange={mapsFieldsToChange}
                                        setFieldValueToChange={setFieldValue}
                                        changeStatusModal={setStatusFrequentDestination}
                                        renderAs="modal"
                                        {...props}
                                    />
                                )}
                            </>
                        )}

                        <div className="form-group--mixed-field">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={destinationTypes}
                                key="destinationType"
                                name="destinationType"
                                renderAs="combo"
                                onChange={({ id }) => setDestinationTypeToAdd([id])}
                                value={data.destinationType || [destinationTypes[0].id]}
                                additionalClassName="recipientType-selector"
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="destinationIdentifier"
                                name="destinationIdentifier"
                                value={data.destinationIdentifier}
                                onChange={setDestinationIdentifierToAdd}
                                maxLength={22}
                                idField="recipient.accountIdentifier"
                            />
                        </div>

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={[
                                {
                                    id: "1",
                                    label: i18nUtils.get(`forms.${ID_FORM}.cuitConfirmation.label`),
                                },
                            ]}
                            key="cuitConfirmation"
                            name="cuitConfirmation"
                            idField="cuitCheck"
                            renderAs="check"
                            onChange={handleCuitConfirmation}
                        />

                        <Field
                            {...genericProps}
                            mode={isSameCuit ? "view" : "edit"}
                            component={FormFieldsComponents.Text}
                            key="cuit"
                            name="cuit"
                            idField="cuit"
                            mask={maskUtils.cuitMask}
                            value={data.cuit}
                            onChange={setCuitToAdd}
                            maxLength={12}
                        />

                        {mode === "edit" && (
                            <AddFrequentRecipientButton
                                className={`${isSameCuit ? "ui-mt-7" : ""}`}
                                isMobile={isMobile}
                                urlOrigin={location.pathname}
                                cuit={cuitToAdd}
                                destinationType={destinationTypeToAdd}
                                destinationIdentifierS={destinationIdentifierToAdd}
                            />
                        )}

                        <hr className="ui-mt-7 ui-mb-7" />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            key="amount"
                            name="amount"
                            idField="amount"
                            data={amountData}
                            tooltipProps={tooltipProps}
                            handleChange={(e) => handleChangeAmount(e)}
                            amountNull
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={conceptTypes}
                            key="concept"
                            name="concept"
                            idField="concept"
                            renderAs="combo"
                            value={data.concept ? data.concept[0] : ["VAR"]}
                        />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="reference"
                            name="reference"
                            idField="reference"
                            maxLength={11}
                            value={data.reference || ""}
                        />

                        <CustomScheduler {...genericProps} isDesktop={isDesktop} />

                        <div className="form-group form-group--message">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={[
                                    {
                                        id: "1",
                                        label: i18nUtils.get(`forms.${ID_FORM}.emailConfirmation.label`),
                                    },
                                ]}
                                key="mailConfirmation"
                                name="mailConfirmation"
                                idField="mailConfirmation"
                                renderAs="check"
                                values={data.mailConfirmation || []}
                                onChange={handleEmailConfirmation}
                            />

                            {isEmailEnabled && (
                                <>
                                    {mode !== "edit" && <hr />}
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Text}
                                        key="email"
                                        name="email"
                                        idField="email"
                                    />
                                    <Field
                                        {...genericProps}
                                        key="notificationBody"
                                        name="message"
                                        idField="message"
                                        maxLength={500}
                                        component={FormFieldsComponents.Textarea}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderFieldsTermsAndConditions = () => (
        <>
            <hr />
            <DisclaimerContainer textSmall>
                <div className="termsAndConditions text-justify">
                    <Field
                        isOnlyText
                        {...genericPropsConditions}
                        component={FormFieldsComponents.Termsandconditions}
                        key="disclaimer"
                        name="disclaimer"
                        idField="disclaimer"
                        link="https://www.bcra.gob.ar"
                        nameLink="www.bcra.gob.ar."
                    />
                </div>
            </DisclaimerContainer>
        </>
    );

    const validateFieldDraft = (valuesDraft) => {
        const draftErrors = {};
        const { debitAccount } = valuesDraft;
        if (!debitAccount || debitAccount.length === 0) {
            draftErrors.debitAccount = i18nUtils.get("program.date.blank");
        }
        return draftErrors;
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: "forms.transfers.local.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            idActivity: ID_ACTIVITY,
            onTemplateLoad,
        },
        renderFields,
        preData: restPreData,
        renderFieldsTermsAndConditions,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle: "forms.transfers.local.subtitle",
        validateFieldDraft,
        modalButtonLabel: "forms.saveTemplate.link",
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
    };

    return <FormTransition {...props} {...formProps} errorsAux={errorsAux} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    mode: formSelectors.getMode(state),
    preData: formSelectors.getPreData(state),
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    data: formSelectors.getData(state),
    previewData: formSelectors.getPreviewData(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    formErrors: formSelectors.getErrors(state),
});

TransferLocalForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

TransferLocalForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    previewData: shape({}).isRequired,
    preData: shape({}),
    values: shape({}).isRequired,
    fetching: bool.isRequired,
    fromBackoffice: bool,
    useDefaultSubmit: bool,
    currentLang: string.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool,
    location: shape({}).isRequired,
    formErrors: shape({}).isRequired,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(TransferLocalForm);
