import React from "react";
import { string } from "prop-types";

import * as i18n from "util/i18n";
import I18n from "../I18n";

const FieldHelp = (props) => {
    const { text } = props;

    const printMessage = i18n.get(text);

    if (text) {
        // TODO: This component requires proper HTML ;)
        return (
            <div className="form-group-help">
                {printMessage.startsWith("*") && printMessage.endsWith("*") ? <span>{text}</span> : <I18n id={text} />}
            </div>
        );
    }
    return null;
};

FieldHelp.propTypes = {
    text: string,
};

FieldHelp.defaultProps = {
    text: "",
};

export default FieldHelp;
