import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AccountTransfers from "pages/accounts/_components/AccountTransfers";

class MobileFilters extends Component {
    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.readAccount(rest.match.params.id));
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { account } = this.props;

        return (
            <Fragment>
                <Head onClose={this.goBack} title="menu.transfers" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        {account && <AccountTransfers account={account} classNames="btn btn-block" />}
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
});

export default connect(mapStateToProps)(MobileFilters);
