import React, { Component } from "react";
import { string } from "prop-types";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class NoProduct extends Component {
    static propTypes = {
        text: string.isRequired,
        imagePath: string,
    };

    static defaultProps = {
        imagePath: null,
    };

    render() {
        const { text, imagePath } = this.props;

        return (
            <div className="no-product">
                {imagePath && <Image src={imagePath} className="svg-big-icon" />}
                <I18n id={text} />
            </div>
        );
    }
}

export default NoProduct;
