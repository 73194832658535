import React, { Component } from "react";
import { connect } from "react-redux";
import { any, bool, func, objectOf } from "prop-types";

import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import Step4Content from "pages/login/_components/Step4Content";
import MainContainer from "pages/_components/MainContainer";
import { push } from "react-router-redux/actions";
import HeaderLogin from "../_components/header/HeaderLogin";

class LoginStep4 extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        termsAndConditions: bool.isRequired,
        history: objectOf(any).isRequired,
    };

    componentDidMount() {
        const { dispatch, termsAndConditions } = this.props;

        if (!termsAndConditions) {
            dispatch(push("/desktop"));
        }
    }

    onHeaderClose = () => {
        const { history } = this.props;

        history.push("/loginStep2");
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="loginStep4" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <Step4Content isDesktop={isDesktop} />
                    </MainContainer>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
});
export default connect(mapStateToProps)(LoginStep4);
