import React, { Component } from "react";

import bool from "prop-types";

import Step4Content from "pages/recoveryPassword/_components/Step4Content";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import HeaderLogin from "../_components/header/HeaderLogin";

class RecoveryPassStep4 extends Component {
    render() {
        return (
            <>
                <Notification scopeToShow="recoveryPassword" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <Step4Content {...this.props} />
                    </MainContainer>
                </div>
            </>
        );
    }
}

RecoveryPassStep4.propTypes = {
    isDesktop: bool.isRequired,
};

export default withExchangeToken(RecoveryPassStep4);
