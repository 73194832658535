import React, { Component } from "react";
import { shape, bool, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Enviroments from "pages/_components/Enviroments";
import Button from "pages/_components/Button";
import SimpleModal from "components/SimpleModal/SimpleModal";

const FORM_ID = "settings";

class SetDefaultEnvironment extends Component {
    static propTypes = {
        environments: shape([]).isRequired,
        activeEnvironment: shape({}),
        userData: shape({}),
        isFetching: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        history: shape({}).isRequired,
    };

    static defaultProps = {
        activeEnvironment: {},
        userData: {},
    };

    state = {
        displayModal: false,
    };

    handleRemoveDefaultEnvironmentClick = () => {
        const { dispatch } = this.props;
        dispatch(settingsActions.changeDefaultEnvironment(0));
    };

    showModal = (e) => {
        e.preventDefault();

        this.setState({
            displayModal: true,
        });
    };

    hideModal = () => {
        this.setState({ displayModal: false });
    };

    renderModal() {
        const { dispatch } = this.props;

        const { displayModal } = this.state;

        return (
            <SimpleModal
                dispatch={dispatch}
                isDisplayed={displayModal}
                closeModal={this.hideModal}
                modalIcon="images/warningIcon.svg"
                modalMainText="settings.defaultEnvironment.modalText"
                buttonAction={this.handleRemoveDefaultEnvironmentClick}
            />
        );
    }

    render() {
        const { environments, userData, isFetching, activeEnvironment, isMobile, history } = this.props;

        const { displayModal } = this.state;

        return (
            <>
                <Head
                    onBack={history.goBack}
                    headerClassName={!isMobile && "mt-2"}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                    additionalClassName={isMobile && "blue-main-header-mobile"}
                />

                <Notification scopeToShow="changeDefaultEnvironment" />
                <MainContainer>
                    <div className="container-center">
                        <div className="title-left-header">
                            <I18n id="settings.defaultEnvironment" component="h1" />
                        </div>
                        <Form className="form-content">
                            <div className="form-text-info ui-mb-8">
                                <I18n id="settings.defaultEnvironment.textInfo" component="p" />
                            </div>
                            <Field
                                name="environment"
                                environments={environments}
                                activeEnvironment={activeEnvironment}
                                component={Enviroments}
                                legendTextID="settings.changeEnvironment"
                                fromSetDefaultEnvironmentPage
                                userData={userData}
                            />
                            <div className="form-footer">
                                {isMobile && (
                                    <Button
                                        type="submit"
                                        loading={isFetching}
                                        label="global.confirm"
                                        bsStyle="primary"
                                        block
                                    />
                                )}
                                {userData.idDefaultEnvironment && (
                                    <Button
                                        type="button"
                                        loading={isFetching}
                                        label="settings.defaultEnvironment.buttonRemoveDefaultEnvironment"
                                        className="btn-outline"
                                        onClick={(e) => this.showModal(e)}
                                        block={isMobile}
                                    />
                                )}
                                {!isMobile && (
                                    <Button
                                        type="submit"
                                        loading={isFetching}
                                        label="global.confirm"
                                        bsStyle="primary"
                                    />
                                )}
                            </div>
                        </Form>
                    </div>
                </MainContainer>
                {displayModal && this.renderModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(sessionSelectors.getEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    userData: sessionSelectors.getUser(state),
    isFetching: settingsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: ({ userData }) => ({
            environment: userData.idDefaultEnvironment || "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.defaultEnvironment.environment.required`)),
            }),
        handleSubmit: ({ environment }, formikBag) => {
            formikBag.props.dispatch(settingsActions.changeDefaultEnvironment(environment));
        },
    }),
)(SetDefaultEnvironment);
