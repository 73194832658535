import React, { Component } from "react";
import Grid from "react-bootstrap/lib/Grid";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { GoogleApiWrapper } from "google-maps-react";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import Step1Content from "pages/login/_components/Step1Content";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { bool, func, shape, string } from "prop-types";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as geolocationActions } from "reducers/geolocation";
import { selectors as loginSelectors } from "reducers/login";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as config from "util/config";

import Image from "pages/_components/Image";
import NotificationModal from "pages/_components/NotificationModal";
import HeaderLogin from "../_components/header/HeaderLogin";

class LoginStep1 extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        isFromAmazon: bool,
        isFromGoogle: bool,
        isFromMessenger: bool,
        isFromWhatsapp: bool,
        fingerprintSubmiting: shape({}),
        rememberedUser: bool,
        activeRegion: string,
        hasActiveSession: bool.isRequired,
        google: shape({}).isRequired,
    };

    static defaultProps = {
        isFromAmazon: false,
        isFromGoogle: false,
        isFromMessenger: false,
        isFromWhatsapp: false,
        fingerprintSubmiting: null,
        rememberedUser: false,
        activeRegion: null,
    };

    componentDidMount() {
        const { dispatch, hasActiveSession, fingerprintSubmiting, google } = this.props;
        if (hasActiveSession && !fingerprintSubmiting) {
            dispatch(sessionActions.logout()); // End any previous session open session
        }

        dispatch(geolocationActions.geolocationRequest(google));
    }

    onHeaderClose = () => {
        const { dispatch, isDesktop } = this.props;
        if (!isDesktop) {
            dispatch(routerActions.push("/"));
        }
    };

    mobileView = () => (
        <div className="form-content">
            <Step1Content {...this.props} />
        </div>
    );

    desktopView = () => {
        const { isFromAmazon, isFromGoogle, isFromMessenger, isFromWhatsapp } = this.props;

        return (
            <div className="container">
                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && <AssistantAlert />}
                        <Step1Content {...this.props} />
                        <div style={{ height: "0px", overflow: "hidden" }}>
                            <Image src="images/coloredIcons/no-connection.svg" />
                            <Image src="/images/logoMariva.svg" />
                        </div>
                    </Grid>
                </section>
            </div>
        );
    };

    getUserNameFormated = (firstName) => firstName.split(" ")[0];

    render() {
        const { isDesktop, fingerprintSubmiting } = this.props;

        return (
            <>
                <Notification scopeToShow="login" />
                <NotificationModal />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} />

                    <MainContainer showLoader={fingerprintSubmiting} shouldHideOnLoad className="main-container">
                        {isDesktop ? this.desktopView() : this.mobileView()}
                    </MainContainer>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fingerprintSubmiting: loginSelectors.getFingerprintSubmiting(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    rememberedUser: loginSelectors.getRememberedUser(state),
    activeRegion: loginSelectors.getRegion(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    customMapsApi: config.get("googlemap.apikey", "AIzaSyAeG8KAM9fZ7amUikdy5AZvsD5bYCAXH40"),
});

export default compose(
    connect(mapStateToProps),
    GoogleApiWrapper((props) => ({
        apiKey: props.customMapsApi,
    })),
)(LoginStep1);
