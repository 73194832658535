import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bool, func, string, any, number, arrayOf, objectOf } from "prop-types";

import { actions as massPaymentsActions } from "reducers/massPayments";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as frequentRecipientsActions } from "reducers/frequentRecipients";

import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import classNames from "classnames";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";
import Checkbox from "pages/_components/fields/Checkbox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "../../../_components/Dropdown";

const PaymentItem = (props) => {
    const {
        dispatch,
        isDesktop,
        payment,
        mode,
        paymentDeleteHandler,
        paymentEditHandler,
        isRenderCheckbox,
        isRenderTransactionColumn,
        idTransaction,
        selectedCheckboxes,
        paymentCheckBoxHandler,
        fetchingDownload,
        downloadFileData,
        multipleDownloadLimit,
        isFrequentRecipient,
        frequentRecipientsPermissions,
    } = props;

    const { id, name, operationNumber, cuit, cbu, reference, amount, status, rejectionReasonDescription } = payment;
    const LIST_ID = "forms.pay.transfers.massive.payment.list";
    const cancelStatus = ["CANCELLED", "RETURNED", "FAILED"];
    const [isSelectedCheckBox, setSelectedCheckBox] = useState(false);
    const [fileToDownload, setFileToDownload] = downloadFileData;

    useEffect(() => {
        const isSelected = selectedCheckboxes.includes(id);
        setSelectedCheckBox(isSelected);
    }, [selectedCheckboxes]);

    const handleChangeAddList = (e) => {
        if (selectedCheckboxes.length < multipleDownloadLimit) {
            setSelectedCheckBox(!isSelectedCheckBox);
            paymentCheckBoxHandler(e, payment);
        } else if (isSelectedCheckBox) {
            setSelectedCheckBox(false);
            paymentCheckBoxHandler(e, payment);
        } else {
            dispatch(
                notificationModalActions.showNotification(
                    i18n.get("forms.massivePayments.transactions.maxLines.to.download.error", "", {
                        maxLines: multipleDownloadLimit,
                    }),
                    "warning",
                ),
            );
        }
    };

    const handleAddBeneficiary = () => {
        dispatch(frequentRecipientsActions.setShowBeneficiaryModal(true));

        dispatch(
            frequentRecipientsActions.searchRecipientRequest(["CBU"], cbu, stringUtils.formatCuit(cuit), null, true),
        );
    };

    const renderCheckbox = () => (
        <Table.Data align="left">
            <Checkbox
                key={id}
                name={id}
                label=""
                checked={isSelectedCheckBox}
                onChange={(e) => handleChangeAddList(e)}
                hideLabel
            />
        </Table.Data>
    );

    const getIconDetail = () => {
        if (status === "FINISHED") {
            return "success.svg";
        }

        if (status === "PROCESSING") {
            return "sign.svg";
        }

        if (cancelStatus.includes(status)) {
            return "error.svg";
        }

        return "";
    };

    const getTooltipText = (title, subtitle, descriptionClass) => (
        <div className="tooltip-text">
            <span className="tooltip-title">{title}</span>
            <p className={`tooltip-description ${descriptionClass}`}>{subtitle}</p>
        </div>
    );

    const renderTooltipText = () => {
        let tooltipText = "";
        if (status === "FINISHED") {
            tooltipText = getTooltipText(
                i18n.get("forms.pay.transfers.massive.payment.list.status.processed.tooltip.label"),
                i18n.get("forms.pay.transfers.massive.payment.list.status.processed.tooltip.label"),
                "ui-text-success",
            );
        }

        if (status === "PROCESSING") {
            tooltipText = getTooltipText(
                i18n.get("transaction.status.PENDING"),
                i18n.get("transaction.status.PROCESSING"),
                "ui-text-alert",
            );
        }

        if (cancelStatus.includes(status)) {
            tooltipText = getTooltipText(
                i18n.get("forms.pay.transfers.massive.payment.list.status.cancelled.tooltip.label"),
                rejectionReasonDescription,
                "ui-text-error",
            );
        }
        return tooltipText;
    };

    const tooltip = <Tooltip id="tooltip-massive">{renderTooltipText()}</Tooltip>;

    const tooltipAddBeneficiaryProps = {
        "data-tooltip": i18n.get("forms.transfers.tooltip.permissions.frequentDestinations"),
        "data-tooltip-position": "bottom-left",
    };

    const webContent = (
        <>
            {isRenderCheckbox && renderCheckbox()}

            <Table.Data align="left">
                <span className="data-text">{name}</span>
            </Table.Data>

            {isRenderTransactionColumn && (
                <Table.Data align="left">
                    <span className="data-text">{operationNumber}</span>
                </Table.Data>
            )}

            <Table.Data align="left">
                <span className="data-text">{stringUtils.formatCuit(cuit)}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className="data-text">{cbu}</span>
            </Table.Data>

            <Table.Data align="left">
                <span className="data-text">{reference}</span>
            </Table.Data>

            <Table.Data align="left">
                <FormattedAmount currency={amount?.currency} quantity={amount?.quantity} />
            </Table.Data>

            {mode !== "preview" && status !== "PENDING" && (
                <Table.Data className="table-data-icons" align="right">
                    <div className="icons-container">
                        {mode === "edit" && (
                            <>
                                <Button
                                    type="button"
                                    className="btn-only-icon"
                                    block={false}
                                    onClick={() => paymentEditHandler(id)}
                                    image="images/ui-icons/ui-edit-pen-list.svg"
                                    label={`${LIST_ID}.dropdown.editIcon.a11y`}
                                />
                                {!isFrequentRecipient && (
                                    <Button
                                        type="button"
                                        className={classNames("btn-only-icon btn-add-beneficiary", {
                                            "btn-disabled-with-tooltip": !frequentRecipientsPermissions,
                                        })}
                                        block={false}
                                        onClick={() => handleAddBeneficiary()}
                                        image="images/ui-icons/ui-add-user-grey.svg"
                                        label={`${LIST_ID}.dropdown.addUserIcon.a11y`}
                                        disabled={!frequentRecipientsPermissions}
                                        {...(!frequentRecipientsPermissions && tooltipAddBeneficiaryProps)}
                                    />
                                )}
                                <Button
                                    type="button"
                                    className="btn-only-icon btn-delete"
                                    block={false}
                                    onClick={() => paymentDeleteHandler(id)}
                                    image="images/ui-icons/ui-delete.svg"
                                    label={`${LIST_ID}.dropdown.trashIcon.a11y`}
                                />
                            </>
                        )}
                        {mode === "view" && (
                            <>
                                <OverlayTrigger placement="left" overlay={tooltip}>
                                    <Button
                                        type="button"
                                        className="btn-only-icon list-item-icon--status"
                                        block={false}
                                        image={getIconDetail() ? `images/${getIconDetail()}` : ""}
                                    />
                                </OverlayTrigger>
                                {(cancelStatus.includes(status) || status === "FINISHED") && (
                                    <Button
                                        type="button"
                                        className="btn-only-icon list-item-icon--download"
                                        block={false}
                                        onClick={() => {
                                            setFileToDownload(id);
                                            dispatch(
                                                massPaymentsActions.downloadTicketDetails(idTransaction, "single", [
                                                    payment,
                                                ]),
                                            );
                                        }}
                                        image="images/download.svg"
                                        loading={fetchingDownload && fileToDownload === id}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </Table.Data>
            )}
        </>
    );

    const mobileContent = (
        <GenericItem
            hoverBehavior={false}
            options={
                mode === "edit" ? (
                    <Dropdown
                        image="images/ui-icons/ui-dropdown-mobile.svg"
                        label=""
                        bsStyle="link"
                        className="btn-small"
                        pullRight>
                        <Button
                            label="global.edit"
                            key="edit"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => paymentEditHandler(id)}
                        />

                        <Button
                            label="global.delete"
                            key="delete"
                            className="dropdown__item-btn"
                            bsStyle="link"
                            onClick={() => paymentDeleteHandler(id)}
                        />
                    </Dropdown>
                ) : null
            }
            customOption={
                mode === "view" && getIconDetail() ? (
                    <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <a role="button">
                            <Image src={getIconDetail()} wrapperClassName="tiny-small-icon" />
                        </a>
                    </OverlayTrigger>
                ) : null
            }>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${LIST_ID}.head.name.label`} />
                </div>
                <div className="data-text">{name}</div>
            </div>

            {isRenderTransactionColumn && operationNumber && (
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="forms.payments.suppliers.detail.transfer.header.transferNumber.label" />
                    </div>
                    <div className="data-text">{operationNumber}</div>
                </div>
            )}

            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${LIST_ID}.head.cuit.label`} />
                </div>
                <div className="data-text">{cuit}</div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${LIST_ID}.head.cbu.label`} />
                </div>
                <div className="data-text">{cbu}</div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${LIST_ID}.head.reference.label`} />
                </div>
                <div className="data-text">{reference}</div>
            </div>
            <div className="data-wrapper">
                <div className="data-label">
                    <I18n id={`${LIST_ID}.head.amount.label`} />
                </div>
                <div className="data-amount">
                    <FormattedAmount currency={amount?.currency} quantity={amount?.quantity} />
                </div>
            </div>
        </GenericItem>
    );

    return isDesktop ? webContent : mobileContent;
};

PaymentItem.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    payment: objectOf(any).isRequired,
    paymentDeleteHandler: func.isRequired,
    paymentEditHandler: func.isRequired,
    mode: string.isRequired,
    idTransaction: string.isRequired,
    isRenderCheckbox: bool,
    isRenderTransactionColumn: bool,
    paymentCheckBoxHandler: func,
    selectedCheckboxes: arrayOf(any),
    fetchingDownload: bool.isRequired,
    downloadFileData: arrayOf(any),
    multipleDownloadLimit: number.isRequired,
    isFrequentRecipient: bool.isRequired,
    setShowAddBeneficiaryModal: func.isRequired,
    frequentRecipientsPermissions: bool.isRequired,
};

PaymentItem.defaultProps = {
    isRenderCheckbox: false,
    isRenderTransactionColumn: false,
    selectedCheckboxes: [],
    paymentCheckBoxHandler: {},
};

export default connect()(PaymentItem);
