import React, { Component, Fragment } from "react";
import { shape, bool, string, array, oneOfType } from "prop-types";
import classNames from "classnames";
import Select from "react-select";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import FieldError from "pages/_components/fields/FieldError";
import EnvironmentTag from "pages/_components/EnvironmentTag";

class Enviroments extends Component {
    static propTypes = {
        environments: oneOfType([array]).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        activeEnvironment: shape({}),
        fromSetDefaultEnvironmentPage: bool,
        userData: shape({}),
        legendTextID: string,
    };

    static defaultProps = {
        activeEnvironment: {},
        fromSetDefaultEnvironmentPage: false,
        userData: {},
        legendTextID: "settings.changeEnvironment.label",
    };

    state = {
        focusSelect: false,
        selectEnvironment: null,
        activeID: [],
    };

    handleClick = (e, value) => {
        e.preventDefault();
        const { field, form } = this.props;
        form.setFieldValue(field.name, value);
        const activeState = [];
        activeState[value] = true;
        this.setState({ activeID: activeState });
    };

    handleChange = (selectedOption) => {
        const { field, form } = this.props;
        this.setState({ selectEnvironment: selectedOption ? selectedOption.value : "" });
        form.setFieldValue(field.name, selectedOption ? selectedOption.value : "");
    };

    toggleFocusSelect = () => {
        const { focusSelect } = this.state;
        this.setState({
            focusSelect: !focusSelect,
        });
    };

    render() {
        const {
            environments,
            legendTextID,
            activeEnvironment,
            form: { errors },
            field,
            fromSetDefaultEnvironmentPage,
            userData,
        } = this.props;
        const { focusSelect, activeID } = this.state;

        const environmentToCheck = fromSetDefaultEnvironmentPage ? userData.idDefaultEnvironment : activeEnvironment.id;

        return environments.length <= 3 ? (
            <div className={classNames("form-group", { "has-error": errors[field.name] })}>
                {legendTextID && (
                    <I18n id={legendTextID} component="legend" componentProps={{ className: "visually-hidden" }} />
                )}

                <div className="selection-list environments">
                    {environments.map(([id, environment]) => {
                        const allowedToAccess = environment.allowedToAccess.toString() === "true";
                        return (
                            <div
                                key={id}
                                className={`environment-wrapper ${
                                    !allowedToAccess && !fromSetDefaultEnvironmentPage ? "env-disabled" : ""
                                }`}>
                                <input
                                    disabled={!allowedToAccess && !fromSetDefaultEnvironmentPage}
                                    defaultChecked={Number(id) === environmentToCheck}
                                    id={`environment-${id}`}
                                    type="radio"
                                    name="environment"
                                    className="c-control-input"
                                />
                                <button
                                    className={`btn btn-outline btn-environment btn-block ${
                                        activeID[id] || (activeID.length === 0 && Number(id) === environmentToCheck)
                                            ? "active"
                                            : "not-active"
                                    }`}
                                    type="button"
                                    onClick={(e) => this.handleClick(e, id)}>
                                    <EnvironmentTag
                                        name={environment.name}
                                        type={environment.environmentType}
                                        showIcon={false}
                                    />
                                </button>
                                {!allowedToAccess && !fromSetDefaultEnvironmentPage && (
                                    <div className="environment-disabled-message">
                                        <Image src="images/warningIcon.svg" className="svg-icon" />
                                        <I18n id="administration.restrictions.unavailableEnvironment" />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                {errors[field.name] && (
                    <div className="text-center">
                        <FieldError error={errors[field.name]} />
                    </div>
                )}
            </div>
        ) : (
            <Fragment>
                <div
                    className={classNames("form-group", "form-group--composite", "environments", {
                        "has-error": errors[field.name],
                    })}>
                    {legendTextID && (
                        <I18n id={legendTextID} component="legend" componentProps={{ className: "visually-hidden" }} />
                    )}
                    {!fromSetDefaultEnvironmentPage && (
                        <div className="form-group-text">
                            <div className="control-label">
                                <I18n id="settings.changeEnvironment.label" />
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames("input-group", {
                            "has-focus": focusSelect,
                            "login-account-select": !fromSetDefaultEnvironmentPage,
                        })}>
                        <Select
                            placeholder={i18n.get("settings.changeEnvironment")}
                            noResultsText=""
                            clearable={false}
                            className=""
                            onChange={this.handleChange}
                            onBlur={() => this.toggleFocusSelect()}
                            onFocus={() => this.toggleFocusSelect()}
                            options={
                                environments
                                    ? environments.map(([id, environment]) => ({
                                          value: id,
                                          label: environment.name,
                                      }))
                                    : []
                            }
                            searchable={false}
                            value={this.state.selectEnvironment}
                            optionClassName="needsclick"
                        />
                    </div>
                    {errors[field.name] && (
                        <div className="text-center">
                            <FieldError error={errors[field.name]} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default Enviroments;
