import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { shape, node, func, bool, array, arrayOf } from "prop-types";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";
import { resizableRoute } from "pages/_components/Resizable";
import { actions, selectors } from "reducers/widgets";
import * as i18nUtils from "util/i18n";
import FixedTermDepositsListItem from "pages/investment/_components/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";
import FormattedAmount from "pages/_components/FormattedAmount";
import Link from "react-router-dom/Link";
import Button from "pages/_components/Button";
import * as config from "util/config";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import FormattedDate from "pages/_components/FormattedDate";
import WidgetLoading from "pages/_components/WidgetLoading";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import WidgetHeader from "../_components/WidgetHeader";
import Overlay from "../_components/Overlay";

class FixedTermDeposits extends Component {
    static propTypes = {
        isFetching: bool,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
        isEditable: bool,
        listDeposits: arrayOf(array).isRequired,
    };

    static defaultProps = {
        isFetching: false,
        closeButton: null,
        isDesktop: false,
        isEditable: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(actions.listRequest("fixedTermDeposits"));
    }

    renderAll = (uiAutomationProp) => {
        const { isDesktop, draggableItemProps, closeButton, isEditable } = this.props;

        if (isDesktop) {
            return (
                <WidgetList
                    item={FixedTermDepositsListItem}
                    keyExtractor={(item) => item.idProduct}
                    name="fixedTermDeposits"
                    withDisclaimer>
                    {() => (
                        <div
                            role={isEditable ? "button" : ""}
                            className="newWidget"
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader
                                title={i18nUtils.get("desktop.widgets.fixedTermDeposits.title")}
                                action={closeButton}
                            />
                            {this.renderList()}
                            <Overlay />
                        </div>
                    )}
                </WidgetList>
            );
        }
        return (
            <div
                className="newWidget"
                {...uiAutomationProp}
                {...draggableItemProps}
                aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                <WidgetHeader title={i18nUtils.get("desktop.widgets.fixedTermDeposits.title")} action={closeButton} />
                <div className="widget--fixedTermDeposits" aria-hidden={isEditable}>
                    {this.renderList()}
                </div>
                <Overlay />
            </div>
        );
    };

    renderItem = (deposit, isDesktop) => {
        const finalPath = {
            pathname: `/fixedTermDeposits/${deposit.idProduct}/${deposit.operationOrder}`,
        };
        if (isDesktop) {
            return (
                <div key={deposit.idProduct} className="table-row-container">
                    <Link aria-describedby={deposit.idProduct} to={finalPath} role="menuitem">
                        <div key={deposit.idProduct} className="table-row">
                            <div className="table-data data-name">{deposit.productAlias || deposit.operationCode}</div>
                            <div className="table-data data-details">
                                <span className="data-label">{deposit.descriptionSIOC}</span>
                            </div>
                            <div className="table-data data-date">
                                <div className="data-label">
                                    {i18nUtils.get(`desktop.widgets.fixedTermDeposits.expirationDate.label`)}
                                </div>
                                <div className="data-info">
                                    <FormattedDate date={deposit.dueDate} />
                                </div>
                            </div>
                            <div className="table-data data-number">
                                <div className="data-label">
                                    {i18nUtils.get(`desktop.widgets.fixedTermDeposits.amount.label`)}
                                </div>
                                <FormattedAmount quantity={deposit.amount} currency={deposit.currency} />
                            </div>
                        </div>
                    </Link>
                </div>
            );
        }
        return (
            <Link aria-describedby={deposit.idProduct} to={finalPath} role="menuitem">
                <Col key={deposit.idProduct} className="col-12">
                    <Row className="data-wrapper text-center">
                        <span className="data-name">{deposit.productAlias || deposit.operationCode}</span>
                        <span className="visually-hidden">
                            {i18nUtils.get(`desktop.widgets.fixedTermDeposits.title`)}
                        </span>
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">{deposit.descriptionSIOC}</span>
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">
                            {i18nUtils.get(`desktop.widgets.fixedTermDeposits.expirationDate.label`)}
                        </span>
                        <FormattedDate date={deposit.dueDate} />
                    </Row>
                    <Row className="data-wrapper text-center">
                        <span className="data-label">
                            {i18nUtils.get(`desktop.widgets.fixedTermDeposits.amount.label`)}
                        </span>
                        <FormattedAmount quantity={deposit.amount} currency={deposit.currency} />
                    </Row>
                    <Row className="data-wrapper text-right">
                        <hr />
                        <SeuoLabel />
                    </Row>
                </Col>
            </Link>
        );
    };

    renderList = () => {
        const { listDeposits, isFetching, isDesktop, isEditable } = this.props;
        const ArrowLeft = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-left.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const ArrowRight = (props) => (
            <Button
                onClick={props.onClick}
                image="images/arrow-right.svg"
                className="col"
                bsStyle="link"
                aria-label={i18nUtils.get("widgets.exchangeRates.arrow.prev.a11y")}
            />
        );
        const settings = {
            arrows: true,
            prevArrow: <ArrowLeft />,
            nextArrow: <ArrowRight />,
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: config.get("fixedTermDeposits.productsPerWidget"),
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 860,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        if (!listDeposits.length) {
            if (!isFetching) {
                return (
                    <NoProduct
                        text="desktop.widgets.fixedTermDeposits.empty"
                        imagePath="images/coloredIcons/component.svg"
                    />
                );
            }
        }
        if (isDesktop) {
            return (
                <WidgetLoading loading={!listDeposits.length && isFetching}>
                    {listDeposits ? (
                        <div className="table" aria-hidden={isEditable}>
                            <div className="table-body">
                                {listDeposits.map((item) => this.renderItem(item, isDesktop))}
                            </div>
                            <SeuoLabel />
                        </div>
                    ) : null}
                </WidgetLoading>
            );
        }
        return (
            <Slider {...settings} dots infinite={false} speed={200} slidesToShow={1} slidesToScroll={1}>
                {listDeposits.map((deposit) => (
                    <div key={deposit.idProduct}>{this.renderItem(deposit, isDesktop)}</div>
                ))}
            </Slider>
        );
    };

    render() {
        const uiAutomationProp = { "data-ui-automation": "widgetLoans" };
        return this.renderAll(uiAutomationProp, this.settings);
    }
}
const mapStateToProps = (state) => ({
    isFetching: selectors.getWidget(state, "fixedTermDeposits").isFetching,
    listDeposits: selectors.getWidget(state, "fixedTermDeposits").data.fixedTermDeposits,
});
export default compose(connect(mapStateToProps), resizableRoute)(FixedTermDeposits);
