import { call, put, takeLatest } from "redux-saga/effects";

import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as massPaymentsActions } from "reducers/massPayments";
import { actions, types } from "reducers/files";
import { actions as notificationActions } from "reducers/notification";
import * as fileMiddleware from "middleware/file";
import * as i18n from "util/i18n";
import { base64toBlob, readFileAsBinaryData } from "../util/file";
import { downloadCsv } from "../util/download";

const sagas = [
    takeLatest(types.DOWNLOAD_FILE_REQUEST, downloadFileAsStreamRequest),
    takeLatest(types.GET_FILE_CONTENTS_REQUEST, getFileContentsRequest),
    takeLatest(types.UPLOAD_FILE_REQUEST, uploadFile),
];

export default sagas;

function* downloadFileAsStreamRequest({ idFile, fileName }) {
    const fileResponse = yield call(fileMiddleware.downloadStream, idFile);
    if (fileResponse.status === 200) {
        yield downloadCsv(fileName, fileResponse.data);
        yield put(actions.downloadFileSuccess());
        yield put(
            notificationActions.showNotification(i18n.get("notification.download.successful"), "success", ["form"]),
        );
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("cybo.campaignsManagement.actions.export.error.message"),
                "error",
                ["form"],
            ),
        );
    }
}

function getBlobContent(blob) {
    const url = URL.createObjectURL(blob);
    const request = new XMLHttpRequest();
    request.open("GET", url, false);
    request.send();
    URL.revokeObjectURL(url);
    return request.responseText;
}

function* getFileContentsRequest({ idFile, deleteFile }) {
    const fileResponse = yield fileMiddleware.downloadStream(idFile);
    const content = getBlobContent(fileResponse.data)
        .split("\n")
        .slice(1)
        .map((line, i) => {
            const [
                creditAccountNumber,
                creditAmountCurrency,
                creditAmountQuantity,
                creditAccountName,
                bankIdentifier,
            ] = line.split(",");
            return {
                creditAccountNumber,
                creditAmountCurrency,
                creditAmountQuantity: Number(creditAmountQuantity),
                creditAccountName,
                bankIdentifier,
                lineNumber: i + 1,
            };
        });
    if (deleteFile) {
        yield fileMiddleware.deleteFile(Number(idFile));
    }
    yield put(transactionLinesActions.getFileContentsSuccess(content));
}

function* uploadFile({ idActivity, idForm, description, idEnvironment, lang, content, accountSelected }) {
    const response = yield call(
        fileMiddleware.commonUpload,
        idActivity,
        idForm,
        description,
        idEnvironment,
        lang,
        content,
    );

    if (response.type !== "W") {
        yield put(actions.uploadFileResponse(response.data.data));

        const validContent = response.data.data?.validContent;

        if (validContent && validContent !== "") {
            const blob = base64toBlob(validContent, "application/octet-stream");
            const data = yield readFileAsBinaryData(blob);
            const payments = convertToPaymentList(data, accountSelected);

            yield put(massPaymentsActions.setPaymentsSuccess(payments));
        }

        const details = {
            validFileId: response.data.data?.idFile,
            invalidFileId: response.data.data?.idRelatedFile,
            validLines: response.data.data?.validTotal,
            invalidLines: response.data.data?.invalidTotal,
            total: response.data.data?.validAmount,
            quantity: response.data.data?.quantity,
        };

        yield put(massPaymentsActions.setPaymentDetails(details));
    } else {
        yield put(actions.uploadFileFailure());
        yield put(notificationActions.showNotification(response.data?.data?._file, "error", ["form"]));
    }
}

function convertToPaymentList(str, accountSelected) {
    const headers = ["name", "cuit", "cbu", "amount", "reference", "id"];

    const rows = str.split("\n");

    return rows.map((row) => {
        const values = row.split(",");
        values[3] = { currency: accountSelected?.currency, quantity: Number(values[3]) };
        return headers.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
        }, {});
    });
}
