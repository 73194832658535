import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string, func, shape, arrayOf, objectOf, any } from "prop-types";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as filterSelectors } from "reducers/filters";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";
import { selectors as sessionSelectors } from "reducers/session";
import Date from "pages/_components/fields/DateField";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Yup from "yup";
import * as i18n from "util/i18n";
import { compose } from "redux";
import defaultDateFromList from "util/defaultDateFromList";
import { clearFiltersAndSubmit } from "util/form";
import moment from "moment";

class LoanFilters extends Component {
    static propTypes = {
        fetching: bool,
        dateFrom: string,
        dateTo: string,
        idForm: string.isRequired,
        filters: shape().isRequired,
        isMobile: bool.isRequired,
        loanTypesList: arrayOf(shape({})).isRequired,
        setFieldValue: func.isRequired,
        setFieldError: func.isRequired,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
        values: objectOf(any).isRequired,
    };

    static defaultProps = {
        fetching: false,
        dateFrom: null,
        dateTo: null,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    operationType: "",
                    dateFrom: defaultDateFromList().loans.dateFrom,
                    dateTo: defaultDateFromList().loans.dateTo,
                }),
            submitForm,
        );
    };

    render() {
        const {
            fetching,
            idForm,
            dateTo,
            dateFrom,
            filters: { loanType },
            isMobile,
            loanTypesList,
            setFieldValue,
            setFieldError,
            currentLang,
            values,
        } = this.props;
        return (
            <div ref={this.myRef} className="filters-container">
                <Form className="form-content">
                    <Field
                        component={FormFieldsComponents.Selector}
                        optionList={loanTypesList}
                        key="operationType"
                        name="operationType"
                        idField="operationType"
                        renderAs="combo"
                        idActivity={`${idForm}.send`}
                        mode="edit"
                        isRequired
                        idForm="loans.list"
                        lang={currentLang}
                    />
                    {loanType === "history" && (
                        <>
                            <Field
                                idField="dateFrom"
                                component={Date}
                                endDate={dateTo}
                                hidePlaceholder
                                idForm={idForm}
                                isClearable
                                name="dateFrom"
                                selectsStart
                                showMonthYearDropdown
                                startDate={dateFrom}
                                minDate={defaultDateFromList().loans.dateFrom}
                                maxDate={values.dateTo ?? moment()}
                                onChange={(e) => {
                                    setFieldValue("dateFrom", e);
                                    setFieldError("dateFrom", null);
                                }}
                            />

                            <Field
                                idField="dateToPeriodFilter"
                                component={Date}
                                endDate={dateTo}
                                hidePlaceholder
                                idForm={idForm}
                                isClearable
                                name="dateTo"
                                selectsEnd
                                showMonthYearDropdown
                                startDate={dateFrom}
                                minDate={values.dateFrom ?? defaultDateFromList().loans.dateFrom}
                                onChange={(e) => {
                                    setFieldValue("dateTo", e);
                                    setFieldError("dateTo", null);
                                }}
                            />
                        </>
                    )}
                    <Button
                        block={isMobile}
                        bsStyle="primary"
                        label="product.filters.filter"
                        loading={fetching}
                        type="submit"
                    />
                    <Button
                        block={isMobile}
                        bsStyle="outline"
                        className="btn-clear-filters"
                        label="product.filters.clearFilters"
                        image="images/ui-icons/ui-clear-filters.svg"
                        loading={fetching}
                        onClick={this.handleClearFilters}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentLang: i18nSelectors.getLang(state),
    filters: loansSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    loanTypesList: loansSelectors.getLoanTypesForSelector(state),
    fetching: loansSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            operationType: props.filters.operationType ? props.filters.operationType : "",
            dateFrom: props.filters.dateFrom ? props.filters.dateFrom : null,
            dateTo: props.filters.dateTo ? props.filters.dateTo : null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`loans.list.dateFrom.error`))
                              .required(i18n.get(`loans.list.dateFrom.empty.error`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`loans.list.dateFrom.empty.error`)),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`loans.list.dateTo.error`))
                              .required(i18n.get(`loans.list.dateTo.empty.error`))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get(`loans.list.dateTo.empty.error`)),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { filters, dispatch } = formikBag.props;
            const { loanType } = filters;

            const newFilters = { ...values, loanType };
            dispatch(loansActions.listLoans(newFilters, formikBag));
        },
    }),
)(LoanFilters);
