import React from "react";
import { func, string } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import ExternalLinkComponent from "pages/_components/ExternalLink";

class ExternalLink extends React.Component {
    static propTypes = {
        idForm: string.isRequired,
        idField: string.isRequired,
        setValue: func.isRequired,
        to: string.isRequired,
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { idForm, idField, className, setValue, to } = this.props;

        return (
            <ExternalLinkComponent
                idForm={idForm}
                idField={idField}
                className={className}
                setValue={setValue}
                to={to}
            />
        );
    }
}

export default formField({
    formClass: "form-group--button",
})(ExternalLink);
