import dateFormat from "date-fns";
import * as config from "util/config";

export const formatDate = (dateToFormat, format = null) => {
    let date = dateToFormat.replace("T", " ");
    date = new Date(date).getTime();

    if (format === null) {
        return dateFormat(date);
    }
    return dateFormat(date, format);
};

export const getNestedObject = (nestedObj, pathArr) =>
    pathArr.reduce((obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined), nestedObj);

export const getTransactionStatusIcon = (idTransactionStatus) => {
    let result = "";
    switch (idTransactionStatus) {
        case "PENDING":
            result = "sign";
            break;
        case "CANCELLED":
            result = "cross";
            break;
        case "SCHEDULED":
            result = "schedule";
            break;
        case "ACCEPTED":
            result = "accepted";
            break;
        case "FINISHED":
            result = "check";
            break;
        case "RETURNED":
            result = "returned";
            break;
        case "FAILED":
            result = "cross";
            break;
        case "PROCESSING":
            result = "process";
            break;
        case "DRAFT":
            result = "draft";
            break;
        default:
            result = "process";
    }
    return result;
};

export const isTransactionStatusAlert = (idTransactionStatus) =>
    idTransactionStatus === "RETURNED" || idTransactionStatus === "FAILED" || idTransactionStatus === "CANCELLED";

export const retrieveMobileCountryCodes = () => {
    const options = config.getArray("country.codes").map((country) => {
        const code = config.get(`cellPhone.code.${country}`);

        return {
            value: code,
            label: code,
            country,
        };
    });
    return options;
};

export const validationRegexIP = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
);

export const loadScript = (url, callback) => {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};
