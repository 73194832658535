import { call, put, takeEvery } from "redux-saga/effects";

import { types, actions } from "reducers/widgets";
import * as widgets from "middleware/widgets";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [takeEvery(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest({ widget }) {
    const response = yield call(widgets.listRequest, widget);

    if (response.type === "W") {
        yield put(actions.listFailure(widget));
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
    } else {
        yield put(actions.listSuccess(widget, response.data.data));
    }
}
