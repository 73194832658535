import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, shape, func } from "prop-types";

import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

import "./_securitySealCarrouselField.scss";

class SecuritySealCarrouselField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        hideLabel: bool,
        securitySeals: shape([]),
        currentSecuritySeal: shape({}),
        handleSecuritySealClick: func.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        hideLabel: false,
        securitySeals: [],
        currentSecuritySeal: {},
    };

    state = {
        showMore: false,
    };

    handleShowMore = () => {
        const { showMore } = this.state;

        this.setState({ showMore: !showMore });
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
            securitySeals,
            currentSecuritySeal,
            handleSecuritySealClick,
            form,
            isDesktop,
        } = this.props;
        const { showMore } = this.state;
        const hasError = touched[field.name] && errors[field.name];
        let shownImages = 0;
        return (
            <div className="securitySealCarrousel-container">
                <div
                    className={classNames("form-group", "form-group--image-selector", {
                        "has-error": hasError,
                    })}>
                    {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                    <div className="securitySealCarrousel__container-images">
                        {Object.entries(securitySeals).map(([id, securitySeal]) => {
                            const btnClass = classNames("btn-image security-image", {
                                selected: id === `${currentSecuritySeal.id}`,
                                unselected:
                                    currentSecuritySeal && currentSecuritySeal.id && id !== `${currentSecuritySeal.id}`,
                            });
                            shownImages += 1;
                            if (showMore || shownImages <= 12) {
                                return (
                                    <Button
                                        key={`securitySeal-${id}`}
                                        className={btnClass}
                                        id={id}
                                        label=""
                                        onClick={() => {
                                            form.setFieldValue(field.name, {
                                                id,
                                                image: securitySeals[id],
                                            });
                                            handleSecuritySealClick(id);
                                        }}>
                                        <figure>
                                            <img src={securitySeal} alt="securitySealAlternativeText" />
                                        </figure>
                                    </Button>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className="ui-dflex-center">
                        <Button
                            label={showMore ? "securitySeals.showLess" : "securitySeals.showMore"}
                            image={showMore ? "images/lessWhite.svg" : "images/plusWhite.svg"}
                            type="button"
                            bsStyle="secondary"
                            loading={false}
                            onClick={this.handleShowMore}
                            block={!isDesktop}
                        />
                    </div>
                    {hasError && <FieldError error={errors[field.name]} />}
                </div>
            </div>
        );
    }
}

export default SecuritySealCarrouselField;
