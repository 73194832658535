import * as API from "middleware/api";
import { format } from "date-fns";

const list = (filters, pageNumber) => {
    let { dateFrom, dateTo } = filters;
    const { activity } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/userTransactions.list", {
        activity,
        dateFrom,
        dateTo,
        pageNumber,
    });
};

export default list;

export const readUserTransaction = (idTransaction) =>
    API.executeWithAccessToken("/userTransaction.detail", {
        idTransactionToRead: idTransaction,
    });
