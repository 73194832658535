import React from "react";
import { func, string, bool } from "prop-types";

class MobileCustomDatePickerInput extends React.Component {
    static propTypes = {
        onClick: func,
        value: string,
        disabled: bool,
        onChange: func.isRequired,
    };

    static defaultProps = {
        onClick: null,
        value: "",
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        return this.props.value
            ? this.inputRef.current.parentElement.classList.add("date-picker--withoutIcon")
            : this.inputRef.current.parentElement.classList.remove("date-picker--withoutIcon");
    }

    componentDidUpdate() {
        return this.props.value
            ? this.inputRef.current.parentElement.classList.add("date-picker--withoutIcon")
            : this.inputRef.current.parentElement.classList.remove("date-picker--withoutIcon");
    }

    render() {
        const { onClick, value, disabled, onChange } = this.props;

        return (
            /* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
            <input
                type="text"
                autoComplete="off"
                className="mobile-custom-datepicker-input expand-area-input-picker"
                onClick={onClick}
                ref={this.inputRef}
                role="presentation"
                disabled={disabled}
                value={value}
                onChange={onChange}
            />
        );
    }
}

export default MobileCustomDatePickerInput;
