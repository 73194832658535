import React, { Component } from "react";
import { string, number as numberType, bool, oneOfType } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as dateUtils from "util/date";

import ListItem from "pages/_components/listItem/ListItem";

class LoansListItem extends Component {
    static propTypes = {
        number: string.isRequired,
        productAlias: string,
        idProduct: string,
        productType: string.isRequired,
        paymentAmount: numberType.isRequired,
        nextDueDate: string,
        numberOfFees: numberType.isRequired,
        numberOfPaidFees: numberType.isRequired,
        operationCode: string.isRequired,
        nextExpiration: string.isRequired,
        accountNumber: string.isRequired,
        descriptionSIOC: string.isRequired,
        currencyDescription: string.isRequired,
        balance: numberType.isRequired,
        showPayment: bool,
        showLink: bool,
        operationBranch: string.isRequired,
        operationOrder: oneOfType([string, numberType]).isRequired,
    };

    static defaultProps = {
        productAlias: null,
        idProduct: null,
        showPayment: false,
        showLink: true,
        nextDueDate: "",
    };

    render() {
        const {
            productAlias,
            number,
            paymentAmount,
            idProduct,
            nextDueDate,
            numberOfFees,
            numberOfPaidFees,
            showPayment,
            showLink,
            operationCode,
            nextExpiration,
            accountNumber,
            balance,
            descriptionSIOC,
            currencyDescription,
            operationBranch,
            operationOrder,
            ...props
        } = this.props;
        const productName = productAlias || operationCode;
        const isExpired = dateUtils.isDateLessThanToday(nextExpiration);

        const finalPath = {
            pathname: `/loans/${idProduct}/${operationCode}/${operationBranch}/${operationOrder}/detail`,
        };

        return (
            <ListItem
                {...props}
                idProduct={idProduct}
                title={descriptionSIOC}
                name={productName}
                currency={currencyDescription}
                productNumber={operationCode}
                amountLabel={i18nUtils.get("desktop.widgets.loans.amount.label")}
                expiredText={i18nUtils.get("loans.list.item.expired")}
                expirationText={i18nUtils.get("loans.list.item.expiration")}
                isExpired={isExpired}
                amount={balance}
                expirationDate={nextExpiration}
                path={showLink ? finalPath : null}
                isDepositsWidget
            />
        );
    }
}

export default LoansListItem;
