import { bool, func, shape, string } from "prop-types";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";

import MultipleEcheqsTicketHeader from "pages/forms/_components/ticketHeaders/MultipleEcheqsTicketHeader";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import * as i18nUtils from "util/i18n";
import { format } from "date-fns";

import "./_transactionTicket.scss";

class MultipleTransactionsTicket extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        children: shape({}),
        dispatch: func,
        isDesktop: bool.isRequired,
        title: string.isRequired,
        subtitle: string,
        subtitleClean: bool,
        ticketDate: string.isRequired,
        transactionsRequireSignature: bool.isRequired,
        history: shape({}).isRequired,
        match: shape({}).isRequired,
        headerMessage: string,
    };

    static defaultProps = {
        fromBackoffice: false,
        children: null,
        dispatch: () => {},
        subtitle: "",
        subtitleClean: false,
        headerMessage: null,
    };

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match;

        if (!pathname.includes(url)) {
            dispatch(formsActions.formClosed());
        }
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    orderingByDate = (obj1, obj2) => {
        const dateA = new Date(obj1.valueDateTime).getTime();
        const dateB = new Date(obj2.valueDateTime).getTime();
        if (dateA < dateB) {
            return 1;
        }

        if (dateA > dateB) {
            return -1;
        }

        return 0;
    };

    render() {
        const {
            fromBackoffice,
            children,
            title,
            subtitle,
            subtitleClean,
            dispatch,
            transactionsRequireSignature,
            headerMessage,
        } = this.props;

        const subtitleText = subtitleClean ? subtitle : i18nUtils.get(subtitle);
        const transactionsExecutionDate = format(new Date().toString(), "DD/MM/YYYY HH:mm:ss");

        return (
            <>
                <div className="transaction-ticket">
                    {!fromBackoffice && (
                        <MultipleEcheqsTicketHeader
                            {...this.props}
                            transactionStatus={transactionsRequireSignature ? "PENDING" : "PROCESSING"}
                            customHeaderMessage={headerMessage}
                        />
                    )}

                    <div className="transaction-ticket__body">
                        <div className="body-header">
                            <div className="body-header__logo">
                                <Image src="images/marivaDetailCheck.svg" />
                            </div>

                            <hr />

                            <h3 className="body-header__title">
                                {i18nUtils.get(title)}
                                {subtitle && ` ${subtitleText[0].toLowerCase()}${subtitleText.slice(1)}`}
                            </h3>
                        </div>

                        <div className="body-content inline">
                            <div className="data-wrapper">
                                <I18n id="forms.transaction.ticket.date" componentProps={{ className: "data-label" }} />
                                <span className="data-text">{transactionsExecutionDate}</span>
                            </div>

                            {children || null}
                        </div>
                    </div>

                    <Button
                        className="btn-primary"
                        label={`forms.transaction.ticket.${
                            transactionsRequireSignature ? "goToPendingList" : "goToTransactionList"
                        }`}
                        onClick={() =>
                            dispatch(
                                push(transactionsRequireSignature ? "/pendingTransaction/list" : "/transactions/list"),
                            )
                        }
                        block
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionsRequireSignature: formsSelectors.getMultipleTransactionsRequireSignature(state),
    headerMessage: formsSelectors.getMultipleTransactionsSuccessMessage(state),
});

export default connect(mapStateToProps)(MultipleTransactionsTicket);
