import React, { Component } from "react";
import { node } from "prop-types";

import TransactionStatus from "pages/transactions/_components/TransactionStatus/TransactionStatus";

import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";

import "./_notifications.scss";

// move this to another dir and make it a class component
const NotificationItem = () => (
    <div className="table-row widget--notifications">
        <div className="table-data table-data-icon">
            <TransactionStatus idTransactionStatus="FINISHED" />
        </div>

        <div className="table-data">
            <div className="data-aux">Transferencia interbancaria</div>
            <div className="data-name">
                <h4>Nº 999999999999999999999</h4>
            </div>
        </div>

        <div className="table-data">
            <div className="data-aux">Realizada con éxito</div>
        </div>
    </div>
);

class Notifications extends Component {
    static propTypes = {
        closeButton: node.isRequired,
        draggableItemProps: node.isRequired,
    };

    render() {
        const { closeButton, draggableItemProps } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetNotifications" };

        return (
            <WidgetList item={NotificationItem} keyExtractor={(item) => item.idProduct} name="accounts">
                {(list) => (
                    <div className="widget" {...uiAutomationProp} {...draggableItemProps}>
                        <div className="flex-container">
                            <h3>Notificaciones</h3>
                            {closeButton}
                        </div>
                        {list}
                        <div className="overlay" />
                    </div>
                )}
            </WidgetList>
        );
    }
}

export default Notifications;
