import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool, shape } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import SecuritySealCarrouselField from "pages/_components/fields/SecuritySealCarrouselField/SecuritySealCarrouselField";

import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";

const FORM_ID = "enrollment.step3";

class EnrollmentStep2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isSubmitting: bool.isRequired,
        securitySeals: shape().isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        currentSecuritySeal: {},
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleSecuritySealClick = (id) => {
        const { securitySeals } = this.props;

        const currentSecuritySeal = {
            id,
            image: securitySeals[id],
        };

        this.setState({ currentSecuritySeal });
    };

    render() {
        const { isSubmitting, securitySeals, fetching, isDesktop } = this.props;
        const { currentSecuritySeal } = this.state;

        return (
            <>
                <Notification scopeToShow="step3" />
                <div className="view-page justify-content-center">
                    <div className="view-content">
                        <main className="main-container">
                            <div className="title-header">
                                <I18n id="enrollment.index.header" component="h1" />
                            </div>
                            <Form className="form-content">
                                <I18n
                                    id="enrollment.steps.step2.title"
                                    component="h2"
                                    componentProps={{ className: "ui-text-skyblue" }}
                                />

                                <div className="securitySeal">
                                    <I18n id="enrollment.setcredentials.securitySeal.info" component="p" />

                                    <Field
                                        component={SecuritySealCarrouselField}
                                        idForm={FORM_ID}
                                        name="securitySeal"
                                        securitySeals={securitySeals}
                                        currentSecuritySeal={currentSecuritySeal}
                                        handleSecuritySealClick={this.handleSecuritySealClick}
                                        hideLabel
                                        isDesktop={isDesktop}
                                    />
                                </div>

                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    disabled={fetching}
                                    block
                                />
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.getFetching(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    securitySeals: enrollmentSelectors.getSecuritySeals(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            securitySeal: {},
        }),
        handleSubmit: ({ securitySeal }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.enrollmentStep2Verify(securitySeal.id, formikBag));
        },
    }),
)(withExchangeToken(EnrollmentStep2));
