import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bool, string, func, object, objectOf } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import Step3Content from "pages/login/_components/Step3Content";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { push } from "react-router-redux/actions";
import WidgetLoading from "pages/_components/WidgetLoading";
import HeaderLogin from "../_components/header/HeaderLogin";

class LoginStep3 extends Component {
    componentDidMount() {
        const { dispatch, activeEnvironment, username } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
        } else if (!username) {
            dispatch(push("/"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
        dispatch(push("/"));
    };

    render() {
        const { isFetchingLang, fetching, loginSuccess } = this.props;

        return (
            <>
                <Notification scopeToShow="loginStep3" />
                <div className="login-content">
                    {!loginSuccess && <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />}

                    <MainContainer className="main-container">
                        {isFetchingLang || fetching ? <WidgetLoading loading /> : <Step3Content />}
                    </MainContainer>
                </div>
            </>
        );
    }
}

LoginStep3.propTypes = {
    dispatch: func.isRequired,
    username: string,
    activeEnvironment: objectOf(object),
    isFetchingLang: bool.isRequired,
    fetching: bool.isRequired,
    loginSuccess: bool.isRequired,
};

LoginStep3.defaultProps = {
    activeEnvironment: null,
    username: "",
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
    isFetchingLang: i18nSelectors.getFetching(state),
    fetching: loginSelectors.getFetching(state),
    loginSuccess: loginSelectors.isLoginSuccess(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep3));
