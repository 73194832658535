import React, { useEffect, useState } from "react";
import { selectors as echeqsSelector } from "reducers/echeqs";
import { selectors as formSelector, actions as formActions, selectors as formSelectors } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field } from "formik";
import * as config from "util/config";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import { goBack } from "react-router-redux";
import * as maskUtils from "util/mask";
import FormTransition from "../../_components/FormTransition";
import I18n from "../../../_components/I18n";
import FormattedAmount from "../../../_components/FormattedAmount";
import FormattedDate from "../../../_components/FormattedDate";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import * as i18nUtils from "../../../../util/i18n";
import { selectors as i18nSelectors } from "../../../../reducers/i18n";

const ID_ACTIVITY = "echeqs.requestMandateForNegotiation.send";
const ID_ACTIVITY_PRE = "echeqs.requestMandateForNegotiation.pre";
const ID_FORM = "forms.echeqs.requestMandateForNegotiation";

const RequestMandateForNegotiation = (props) => {
    const { mode, dispatch, fromBackoffice, transaction, data, echeq, location, lang, history } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const preData = data?.preData || props?.preData;

    const idTransaction = transaction?.idTransaction;

    const genericProps = {
        mode,
        lang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const echeqSelected = Object.keys(echeq).length ? echeq : data?.echeq;
    // const mandate = data?.mandateInfo ?? echeq?.mandatos[0];

    const documentTypes = config.getArray("echeq.documentTypesAllowed").map((item) => ({
        id: item,
        label: item,
    }));

    const mandataryTypes = config.getArray("echeq.mandataryTypes").map((item) => ({
        id: item,
        label: i18nUtils.get(`${ID_FORM}.mandataryType.${item}.label`),
    }));

    const [mandataries, setMandataries] = useState([]);
    const [guarantorConfirmation, setGuarantorConfirmation] = useState(
        data?.guarantorConfirmation && data?.guarantorConfirmation.includes("1"),
    );

    useEffect(() => {
        if (mode === "edit") {
            if (echeqSelected && Object.keys(echeqSelected).length) {
                dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, transaction, location]);

    useEffect(() => {
        if (data?.mandataryType && preData) {
            setMandataries(preData[data?.mandataryType[0].toLowerCase()]);
        }
    }, [data?.mandataryType, preData]);

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => history.goBack();

    const onChangeMandataryType = (setFieldValue, { id, label }) => {
        setMandataries(preData[id.toLowerCase()]);
        setFieldValue("mandataryTypeLabel", label);
        setFieldValue("mandatary", null);
    };

    const onChangeMandatary = (setFieldvalue, value) => {
        setFieldvalue("mandataryLabel", value?.label);
    };

    const renderFields = (setFieldValue) => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.message.text`} />
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label ui-fw-bold" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_id}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_numero}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                <I18n id={`forms.echeqs.type.${echeqSelected?.cheque_tipo}`} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_caracter}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.issuerDocument.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.emisor_cuit}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.issuerName.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.emisor_razon_social}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.beneficiario_nombre}</span>
            </div>

            {mode !== "view" && (
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                    <span>{echeqSelected?.estado}</span>
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
            </div>

            <hr className={`ui-mt-7 ${mode !== "preview" ? "ui-mb-3" : "ui-mb-7"} `} />

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.mandateInfo.title`} componentProps={{ className: "data-label ui-fw-bold" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.mandateType.label`} componentProps={{ className: "data-label" }} />
                <I18n id="forms.echeqs.mandate.mandateType.NEG" />
            </div>

            {mode !== "edit" ? (
                <>
                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.principalHome.label`} componentProps={{ className: "data-label" }} />
                        <span>{data?.principalHome}</span>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.mandataryType.label`} componentProps={{ className: "data-label" }} />
                        <span>{data?.mandataryTypeLabel}</span>
                    </div>

                    <div className="data-wrapper">
                        <I18n id={`${ID_FORM}.mandatary.label`} componentProps={{ className: "data-label" }} />
                        <span>{data?.mandataryLabel}</span>
                    </div>

                    {guarantorConfirmation && (
                        <>
                            <hr className={`ui-mt-7 ${mode !== "view" ? "ui-mb-7" : "ui-mb-3"}`} />

                            <div className="data-wrapper">
                                <I18n
                                    id={`${ID_FORM}.guaranteeInfo.title`}
                                    componentProps={{ className: "data-label" }}
                                />
                            </div>

                            <div className="data-wrapper">
                                <I18n
                                    id={`${ID_FORM}.guarantor.documentInfo.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                                <span>{`${data?.guarantorDocumentType} ${data?.guarantorDocument}`}</span>
                            </div>

                            <div className="data-wrapper">
                                <I18n
                                    id={`${ID_FORM}.guarantorHome.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                                <span>{data?.guarantorHome}</span>
                            </div>

                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                                <I18n id={`${ID_FORM}.character.value.total`} />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="principalHome"
                        name="principalHome"
                        isRequired
                        idField="principalHome"
                        idActivity={ID_ACTIVITY}
                        value={data?.principalHome}
                    />

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={mandataryTypes}
                        key="mandataryType"
                        name="mandataryType"
                        idField="mandataryType"
                        renderAs="combo"
                        value={data?.mandataryType}
                        onChange={(e) => onChangeMandataryType(setFieldValue, e)}
                        /* value={data.destinationType || [destinationTypes[0].id]} */
                    />

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={mandataries}
                        key="mandatary"
                        name="mandatary"
                        renderAs="combo"
                        idField="mandatary"
                        value={data?.mandatary}
                        onChange={(value) => onChangeMandatary(setFieldValue, value)}
                    />

                    <hr className={`${mode !== "view" ? "ui-mt-0 ui-mb-3" : "ui-mb-7"}`} />

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={[
                            {
                                id: "1",
                                label: i18nUtils.get(`${ID_FORM}.guarantorConfirmation.label`),
                            },
                        ]}
                        key="guarantorConfirmation"
                        name="guarantorConfirmation"
                        renderAs="check"
                        /* values={guarantorConfirmation ? {id: "1"} : []} */
                        value={data?.guarantorConfirmation}
                        onChange={() => setGuarantorConfirmation((prevState) => !prevState)}
                    />

                    {guarantorConfirmation && (
                        <>
                            <div className="form-group--mixed-field">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    optionList={documentTypes}
                                    key="guarantorDocumentType"
                                    name="guarantorDocumentType"
                                    renderAs="combo"
                                    value={data?.guarantorDocumentType}
                                    /* onChange={setDestinationTypeToAdd}
                                            value={data.destinationType || [destinationTypes[0].id]} */
                                />

                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="guarantorDocument"
                                    name="guarantorDocument"
                                    mask={maskUtils.cuitMask}
                                    maxLength={50}
                                    idField="guarantor.documentInfo"
                                    value={data?.guarantorDocument}
                                />
                            </div>

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="guarantorHome"
                                name="guarantorHome"
                                isRequired
                                idField="guarantorHome"
                                idActivity={ID_ACTIVITY}
                                value={data?.guarantorHome}
                            />

                            <div className="data-wrapper">
                                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                                <I18n id={`${ID_FORM}.character.value.total`} />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="134px">
                    <I18n id={`${ID_FORM}.termsAndConditions.label`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>

                <Field
                    {...genericProps}
                    mode="edit"
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "1",
                            label: i18nUtils.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                        },
                    ]}
                    key="termsAndConditionsAcceptance"
                    name="termsAndConditionsAcceptance"
                    idField="termsAndConditionsAcceptance"
                    renderAs="check"
                    additionalClassName="ui-mt-7"
                />
            </div>
        </>
    );

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            checkId: echeqSelected?.cheque_id,
            echeq: echeqSelected,
            amount: data?.amount || {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        /* renderFieldsTermsAndConditions, */
        renderEditableInPreview,
        handleClickDownload,
        handleBack,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

RequestMandateForNegotiation.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    isMobile: false,
};

RequestMandateForNegotiation.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    isMobile: bool,
    isFetching: bool.isRequired,
    lang: string.isRequired,
    useDefaultSubmit: bool,
};

const mapStateToProps = (state) => ({
    echeq: echeqsSelector.getSelectedEcheq(state),
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    fetching: formSelectors.getFetching(state),
    lang: i18nSelectors.getLang(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestMandateForNegotiation);
