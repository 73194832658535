import React, { Component } from "react";
import { array, shape, bool, string, func } from "prop-types";
import flowRight from "lodash/flowRight";

import formField from "pages/forms/_components/_fields/_commons/formField";
import FormattedAmount from "pages/_components/FormattedAmount";
import withFocus from "pages/_components/withFocus";
import ProductTag from "pages/_components/ProductTag";
import Select from "pages/forms/_components/_fields/Select";

class Productselector extends Component {
    static propTypes = {
        data: shape({ options: array }).isRequired,
        editing: bool.isRequired,
        value: shape({
            isFrequentDestination: bool,
            value: string,
        }).isRequired,
        toggleIsFocused: func.isRequired,
        mode: string.isRequired,
        isRequired: bool.isRequired,
        setValue: func.isRequired,
        idField: string.isRequired,
    };

    componentDidMount() {
        const { mode, isRequired, data } = this.props;
        let { value } = this.props;

        if (mode === "edit") {
            if (!value && !isRequired) {
                value = "_empty";
            }
            if (!value) {
                this.handleChange({ id: data.options[0].id });
            } else {
                // es value.value si es un campo cargado con setFieldValue (dado que el valor es
                // {value: xxx, isFrequestDestination: bool}) si es un valor que viene cargado por url,
                // el value es directamente el id de la cuenta es asÃ­ hasta que eliminemos el
                // isFrequestDestination y esto se resuelva de otra forma (con value solito :P)
                this.handleChange({ id: value.value || value });
            }
        }
    }

    handleChange = ({ id }) => {
        const { setValue } = this.props;
        if (id) {
            setValue({ value: id, isFrequentDestination: false });
        }
    };

    buildProductOptions = (originalOptions) => {
        const { isRequired } = this.props;

        // \u00A0 es un character especial para forzar un espacio en blanco
        const options = isRequired
            ? originalOptions
            : [{ id: "_empty", isFrequentDestination: false, label: "\u00A0" }, ...originalOptions];
        return options.map((elem) => {
            if (elem.balance) {
                return {
                    id: elem.id,
                    label: (
                        <>
                            <span className="control-label">{elem.label}</span>
                            <FormattedAmount quantity={elem.balance.quantity} currency={elem.balance.currency} />
                        </>
                    ),
                };
            }
            return {
                id: elem.id,
                label: elem.label,
            };
        });
    };

    render() {
        const {
            editing,
            value,
            toggleIsFocused,
            data: { options },
            idField,
        } = this.props;
        const productId = value && value.value ? value.value : value;

        if (editing) {
            const newOptions = this.buildProductOptions(options);

            return (
                <div className="input-group ">
                    <div style={{ flex: 1 }}>
                        <Select
                            id={idField}
                            value={productId}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="label"
                            options={newOptions}
                            onFocus={toggleIsFocused}
                            onBlur={toggleIsFocused}
                            className="flex-container slideFromBottom"
                            optionClassName="needsclick"
                        />
                    </div>
                </div>
            );
        }
        const { label } = options.find(({ id }) => id === productId) || {};

        return (
            <>
                <ProductTag alias={label} />
            </>
        );
    }
}

export default flowRight(withFocus, formField())(Productselector);
