import React, { useEffect } from "react";
import { selectors as echeqsSelector } from "reducers/echeqs";
import { selectors as formSelector, actions as formActions } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import types from "reducers/types/form";
import { Field } from "formik";
import FormTransition from "../../_components/FormTransition";
import I18n from "../../../_components/I18n";
import FormattedDate from "../../../_components/FormattedDate";
import FormattedAmount from "../../../_components/FormattedAmount";
import Alert from "../../../_components/Alert/Alert";
import DisclaimerContainer from "../../../../components/DisclaimerContainer/DisclaimerContainer";
import * as FormFieldsComponents from "../../_components/_fields/Index";
import * as i18nUtils from "../../../../util/i18n";
import { selectors as i18nSelectors } from "../../../../reducers/i18n";

const ID_ACTIVITY = "echeqs.admitGuaranteeRequest.send";
const ID_FORM = `forms.${ID_ACTIVITY}`.replace(".send", "");

const AdmitGuaranteeRequest = (props) => {
    const { mode, dispatch, currentLang, fromBackoffice, transaction, data, echeq, location } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const idTransaction = transaction?.idTransaction;

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        ...genericProps,
        mode: "edit",
    };

    const echeqSelected = Object.keys(echeq).length ? echeq : data?.echeq;
    const guarantor = data?.guarantor || echeqSelected?.avalistas[0];

    useEffect(() => {
        if (mode === "edit") {
            if ((echeqSelected && Object.keys(echeqSelected).length) || Object.keys(data)?.length) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch({
                type: types.PRE_FORM_SUCCESS,
            });
        }
    }, [dispatch, location]);

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => dispatch(goBack());
    const renderFields = () => (
        <>
            {mode === "edit" && (
                <>
                    <Alert icon="images/ui-icons/ui-exclamation-orange.svg" className="ui-mb-7">
                        <I18n id={`${ID_FORM}.warning.text`} />
                    </Alert>
                    <div className="form-text-info ui-mb-8">
                        <I18n id={`${ID_FORM}.message.text`} />
                    </div>
                </>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_id}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_numero}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.estado}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.concept.label`} componentProps={{ className: "data-label" }} />
                {echeqSelected?.cheque_concepto && (
                    <I18n
                        id={`forms.transfers.concepts.${echeqSelected.cheque_concepto}.label`}
                        defaultValue={echeqSelected.cheque_concepto}
                    />
                )}
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
            </div>

            <hr className="ui-mt-7 ui-mb-7" />

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.endorsement.title`} componentProps={{ className: "data-label" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitHolder.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.tenencia_beneficiario_documento}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.subjectHolder.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.tenencia_beneficiario_nombre}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.guarantorCuit.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_documento}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.guarantor.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_razon_social}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_caracter}</span>
            </div>
        </>
    );

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="134px">
                    <I18n id={`${ID_FORM}.termsAndConditions.title`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericPropsConditions}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
                additionalClassName="ui-mt-7"
                values={data?.termsAndConditions || false}
            />
        </>
    );

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            guarantor,
            echeq: echeqSelected,
            checkId: echeqSelected?.cheque_id,
            debitAccount: "2ad0045a84a440d33b7409ffc61572fbfe22fc55",
            amount: data?.amount || {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        renderEditableInPreview,
        handleClickDownload,
        handleBack,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

AdmitGuaranteeRequest.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

AdmitGuaranteeRequest.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    useDefaultSubmit: bool,
};

const mapStateToProps = (state) => ({
    echeq: echeqsSelector.getSelectedEcheq(state),
    transaction: formSelector.getTransaction(state),
    fetching: formSelector.getFetching(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(connect(mapStateToProps), withRouter)(AdmitGuaranteeRequest);
