import { call, put, takeLatest, select } from "redux-saga/effects";
import { delay } from "redux-saga";

import globalTypes from "reducers/types/global";
import { types, actions } from "reducers/transactions";
import { types as loginTypes } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as configUtil from "util/config";
import * as transactionsMiddleware from "middleware/transactions";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(loginTypes.LOGIN_SUCCESS, refreshPendingTransactionsQuantity),
    takeLatest(globalTypes.INIT, refreshPendingTransactionsQuantity),
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_TRANSACTIONS_REQUEST, fetchMoreTransactions),
    takeLatest(types.DELETE_DRAFT_REQUEST, deleteDraftRequest),
];

export default sagas;

function* refreshPendingTransactionsQuantity() {
    const activeEnvironment = yield select(sessionSelectors.getActiveEnvironment);
    if (activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE) {
        if (configUtil.get("feature.transactions.refreshPendingQuantity")) {
            while (true) {
                const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
                if (!hasActiveSession) {
                    break;
                }

                try {
                    const response = yield call(transactionsMiddleware.getPendingTransactionsQuantity);
                    if (response.status !== 304 && response.type === "I") {
                        yield put({
                            type: types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS,
                            pendingTransactionsQuantity: response.data.data.pendingTransactionsQuantity,
                        });
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(err);
                }
                yield call(delay, configUtil.get("transactions.pending.refreshRate", 60) * 1000);
            }
        }
    }
}

function* loadListRequest({ filters, onlyPendings, pendingDispatch }) {
    const response = yield call(transactionsMiddleware.loadListRequest, filters, onlyPendings, pendingDispatch);

    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadListSuccess(transactions, pageNumber, totalPages));
    }
}

function* fetchMoreTransactions({ filters, onlyPendings, pendingDispatch }) {
    const page = filters.pageNumber;
    const response = yield call(
        transactionsMiddleware.loadListRequest,
        { ...filters, pageNumber: page + 1 },
        onlyPendings,
        pendingDispatch,
    );
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { transactions, pageNumber, totalPages } = response.data.data;
        yield put(actions.loadMoreTransactionsSuccess(transactions, pageNumber, totalPages));
    }
}

function* deleteDraftRequest({ idTransaction }) {
    const response = yield call(transactionsMiddleware.deleteDraftRequest, idTransaction);

    if (response.type === "W") {
        yield put(actions.deleteDraftFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["transactions"]));
    } else {
        const { deleted } = response.data.data;

        if (deleted) {
            yield put(actions.deleteDraftSuccess(idTransaction));
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted"), "success", [
                    "transactions",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("transactions.list.draft.deleted.fail"), "success", [
                    "transactions",
                ]),
            );
        }
    }
}
