import * as i18nUtils from "util/i18n";

export const TODAY = "TODAY";
export const ONCE = "FUTURE";
export const DAY = "DAY";
export const WEEK = "WEEK";
export const MONTH = "MONTH";
export const YEAR = "YEAR";
export const CUSTOM = "CUSTOM";
export const NEVER = "UNLIMITED";
export const UP_TO = "UP_TO";
export const UNTIL = "UNTIL";

const FIRST = "first";
const SECOND = "second";
const THRID = "third";
const FOURTH = "fourth";
const LAST = "last";

export const listRecurrencies = () => [
    {
        value: ONCE,
        label: i18nUtils.get(`scheduler.${ONCE.toLowerCase()}`),
    },
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}`),
    },
    {
        value: CUSTOM,
        label: i18nUtils.get(`scheduler.${CUSTOM.toLowerCase()}`),
    },
];

export const listEvery = () => [
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}s`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}s`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}s`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}s`),
    },
];

export const getOccurrenceWithWeekOfMonth = (weekOfMonth) => {
    switch (weekOfMonth) {
        case 1:
            return FIRST;
        case 2:
            return SECOND;
        case 3:
            return THRID;
        case 4:
            return FOURTH;
        default:
            return LAST;
    }
};
