const footerPaths = ["/securityPolicies", "/termsAndConditions", "/support"];

const setCurrentPath = (currentPath, newPath) => {
    if (footerPaths.includes(newPath)) {
        return currentPath;
    }

    return newPath;
};

export default setCurrentPath;
