import Fingerprint2 from "fingerprintjs2";
import { mobileVendor, mobileModel, isTablet, isAndroid, isIOS } from "react-device-detect";

export const ANDROID_DEVICE = "Android";

export const IOS_DEVICE = "iOS";

export const getDevicePlatform = () => window.deviceInfo.getPlatform();

export const getDeviceId = () => window.deviceInfo.getId();

export const getDeviceManufacturer = () => window.deviceInfo.getManufacturer();

export const getDeviceModel = () => window.deviceInfo.getModel();

export const getDisplay = () => (window.innerWidth >= 860 ? "desktop" : "mobile");

export const isMobileNative = !!window.cordova;
export const isMobileNativeFunc = () => !!window.cordova;

export const getMobileOS = (display) => {
    if (isMobileNative) {
        return getDevicePlatform();
    }
    if (display === "mobile") {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return ANDROID_DEVICE;
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return IOS_DEVICE;
        }
    }

    return "unknown";
};

const deviceOptions = {
    NOT_AVAILABLE: "false",
    ERROR: "error",
    EXCLUDED: "excluded",
    excludes: {
        language: true,
        timezone: true,
        timezoneOffset: true,
        userAgent: true,
        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        doNotTrack: true,
        plugins: true,
        canvas: true,
        webgl: true,
        adBlock: true,
        enumerateDevices: true,
        availableScreenResolution: true,
        deviceMemory: true,
        fonts: true,
        audio: true,
        pixelRatio: true,
        hardwareConcurrency: true,
    },
    fonts: { extendedJsFonts: true },
};

export const getExtraInfo = async () => {
    const components = await Fingerprint2.getPromise(deviceOptions);
    const obj = components.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
    }, {});
    if (mobileVendor !== "none") {
        obj.model = `${mobileVendor} - ${mobileModel}`;
    }
    obj.isTablet = isTablet;
    obj.isAndroid = isAndroid;
    obj.isIOS = isIOS;
    return obj;
};

export const getDeviceFingerprint = async () => {
    const components = await Fingerprint2.getPromise(deviceOptions);

    const values = components.map((component) => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);

    return fingerprint;
};
