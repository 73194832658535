import React, { Component } from "react";
import { any, bool, func, objectOf } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import moment from "moment";
import Button from "pages/_components/Button";
import Date from "pages/_components/fields/DateField";
import AmountField from "pages/_components/fields/formik/AmountField";
import TextField from "pages/_components/fields/TextField";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { resizableRoute } from "pages/_components/Resizable";
import { compose } from "redux";
import { connect } from "react-redux";
import defaultDateFromList from "util/defaultDateFromList";
import { clearFiltersAndSubmit } from "util/form";

class MoreFilters extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        values: objectOf(any),
        openMoreFilters: bool.isRequired,
        resetForm: func.isRequired,
        isMobile: bool.isRequired,
        setValues: func.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        values: {},
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        selectedDateFrom: null,
        open: false,
    };

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState !== null && nextProps.openMoreFilters !== prevState.open) {
            nextProps.resetForm();
            return { selectedDateFrom: null, open: nextProps.openMoreFilters };
        }
        return null;
    }

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    reference: "",
                    dateFrom: defaultDateFromList().accountsMovements.dateFrom,
                    dateTo: defaultDateFromList().accountsMovements.dateTo,
                    minAmount: { amount: null },
                    maxAmount: { amount: null },
                }),
            submitForm,
        );
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const {
            isSubmitting,
            values: { dateFrom, dateTo },
            isMobile,
        } = this.props;
        const { selectedDateFrom } = this.state;
        const monthsBack = config.get("accounts.statements.maxMonthsBack", 6);

        return (
            <div className="filters-container ui-mb-7" ref={this.myRef}>
                <Form className="form-content">
                    <Field
                        component={TextField}
                        hidePlaceholder
                        idForm="accounts.movements.filters.reference"
                        name="reference"
                        type="text"
                        maxLength={30}
                    />
                    <Field
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm="accounts.movements.filters.period"
                        name="dateFrom"
                        idField="dateFrom"
                        selectsStart
                        showMonthYearDropdown
                        startDate={dateFrom}
                        handleChange={this.handleChangeDateFrom}
                        minDate={moment().add(-monthsBack, "months")}
                    />
                    <Field
                        component={Date}
                        endDate={dateTo}
                        hidePlaceholder
                        idForm="accounts.movements.filters.period"
                        isClearable
                        name="dateTo"
                        idField="dateTo"
                        selectsEnd
                        showMonthYearDropdown
                        startDate={dateFrom}
                        minDate={selectedDateFrom || moment().add(-monthsBack, "months")}
                    />
                    <Field
                        component={AmountField}
                        hideCurrency
                        idForm="accounts.movements.filters.amount"
                        name="minAmount"
                    />
                    <Field
                        component={AmountField}
                        hideCurrency
                        idForm="accounts.movements.filters.amount"
                        name="maxAmount"
                    />
                    <Button
                        block={isMobile}
                        bsStyle="primary"
                        label="product.filters.filter"
                        loading={isSubmitting}
                        type="submit"
                    />
                    <Button
                        block={isMobile}
                        bsStyle="outline"
                        className="btn-clear-filters"
                        image="images/ui-icons/ui-clear-filters.svg"
                        label="product.filters.clearFilters"
                        loading={isSubmitting}
                        onClick={this.handleClearFilters}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filtersSaved: accountsSelectors.getFilters(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            dateFrom: props.filtersSaved ? props.filtersSaved.dateFrom : null,
            dateTo: props.filtersSaved ? props.filtersSaved.dateTo : null,
            minAmount: {
                amount: props.filtersSaved ? props.filtersSaved.minAmount : "",
            },
            maxAmount: {
                amount: props.filtersSaved ? props.filtersSaved.maxAmount : "",
            },
            reference: props.filtersSaved ? props.filtersSaved.reference || "" : "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`accounts.movements.filters.period.dateFrom.error`))
                              .required(i18n.get("accounts.movements.filters.period.dateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("accounts.movements.filters.period.dateFrom.empty.error")),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`accounts.movements.filters.period.dateTo.error`))
                              .required(i18n.get("accounts.movements.filters.period.dateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("accounts.movements.filters.period.dateTo.empty.error")),
                    minAmount: Yup.object().shape({
                        // eslint-disable-next-line no-nested-ternary
                        amount: Number.isInteger(values.minAmount.amount)
                            ? Number.isInteger(values.maxAmount.amount)
                                ? Yup.number().max(
                                      values.maxAmount.amount,
                                      i18n.get(`accounts.movements.filters.amount.minAmount.error`),
                                  )
                                : Yup.number()
                            : Yup.string().nullable(),
                    }),
                    maxAmount: Yup.object().shape({
                        // eslint-disable-next-line no-nested-ternary
                        amount: Number.isInteger(values.maxAmount.amount)
                            ? Number.isInteger(values.minAmount.amount)
                                ? Yup.number().min(
                                      values.minAmount.amount,
                                      i18n.get(`accounts.movements.filters.amount.maxAmount.error`),
                                  )
                                : Yup.number()
                            : Yup.string().nullable(),
                    }),
                    reference: Yup.string().max(30, i18n.get(`accounts.movements.filters.reference.reference.error`)),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const { dispatch, productId, resetSelectedFilter, filtersSaved } = formikBag.props;
            const filters = {
                ...values,
                minAmount: values.minAmount.amount,
                maxAmount: values.maxAmount.amount,
                movementType: filtersSaved.movementType,
            };
            dispatch(accountsActions.details(productId, filters, formikBag));
            if (resetSelectedFilter) {
                resetSelectedFilter();
            }
        },
    }),
)(resizableRoute(MoreFilters));
