import React, { Component, Fragment } from "react";
import { func, shape, bool, arrayOf, string } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import MoreFilters from "./MoreFilters";

class MobileFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        channels: arrayOf(string).isRequired,
        account: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    state = {
        lastMonthPeriod: null,
        secondLastMonthPeriod: null,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.readAccount(rest.match.params.id));

        this.setState({
            lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            secondLastMonthPeriod: dateUtils.getSecondLastMonthPeriod(),
        });
    }

    handleClick = (selectedPredefinedFilter) => {
        const { lastMonthPeriod, secondLastMonthPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        let period = [];
        switch (selectedPredefinedFilter) {
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "secondLastMonth":
                period = secondLastMonthPeriod;
                break;
            case "all":
            default:
                break;
        }

        const filters = { filter: selectedPredefinedFilter, dateFrom: period[0], dateTo: period[1] };

        dispatch(accountsActions.setMobileFilter(filters));
        dispatch(push(`/accounts/${rest.match.params.id}/filters/results`));
    };

    render() {
        const { secondLastMonthPeriod } = this.state;
        const { channels, isSubmitting, ...rest } = this.props;

        return (
            <Fragment>
                <Head closeLinkTo={`/accounts/${rest.match.params.id}`} title="accounts.movements.filters" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-link"
                                            key="all"
                                            label="accounts.movements.filters.all"
                                            onClick={() => this.handleClick("all")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="lastMonth"
                                            label="accounts.movements.filters.lastMonth"
                                            onClick={() => this.handleClick("lastMonth")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="secondLastMonth"
                                            label="accounts.movements.filters.secondLastMonth"
                                            onClick={() => this.handleClick("secondLastMonth")}
                                            replace={{
                                                SECOND_LAST_MONTH:
                                                    secondLastMonthPeriod &&
                                                    `${
                                                        i18n.getArray("global.months")[
                                                            secondLastMonthPeriod[0].getMonth()
                                                        ]
                                                    } ${secondLastMonthPeriod[0].getFullYear().toString()}`,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <MoreFilters {...this.props} />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
});

export default connect(mapStateToProps)(MobileFilters);
