import React, { Component } from "react";
import { Col, ButtonGroup, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { arrayOf, bool, number, element, func } from "prop-types";
import Measure from "react-measure";

import { actions as communicationsActions, selectors as communicationsSelectors } from "reducers/communications";
import { actions as communicationAction } from "reducers/communication";
import { actions as notificationModalActions } from "reducers/notificationModal";

import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import MessageItem from "pages/communications/_components/MessageItem";

import NoResults from "pages/communications/_components/NoResults";

import * as i18n from "util/i18n";

const trays = {
    all: "all",
    unread: "unread",
    read: "read",
    sent: "sent",
};

class List extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        totalPages: number.isRequired,
        currentPage: number.isRequired,
        list: arrayOf(element).isRequired,
        dispatch: func.isRequired,
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        activeTray: trays.all,
        searchSubject: "",
        filtersVisibility: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(communicationAction.setSelectedIndex(null));
    }

    handleUnreadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.unread });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: true }));
    };

    handleAllClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.all });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    };

    handleSentClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.sent });
        dispatch(communicationsActions.listRequest({ direction: "CUSTOMER_TO_BANK" }));
    };

    handleReadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.read });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: false }));
    };

    handleSelectMessageClick = (idCommunication, userRead, index) => {
        const { dispatch, isDesktop } = this.props;

        dispatch(communicationAction.setSelectedIndex(index));
        dispatch(communicationAction.setSelectedIdCommunication(idCommunication));
        if (isDesktop) {
            dispatch(communicationsActions.showReadPanel());
            dispatch(communicationAction.detailRequest(idCommunication, index));
        } else {
            dispatch(replace(`/communications/read/${idCommunication}`));
        }
        if (!userRead) {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleChangeMessageStatus = (e, idCommunication, userRead, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch } = this.props;
        const { activeTray } = this.state;

        if (userRead) {
            dispatch(communicationAction.markAsUnReadRequest(idCommunication));
        } else {
            dispatch(communicationAction.markAsReadRequest(idCommunication));
        }

        if (activeTray !== trays.all) {
            dispatch(communicationsActions.removeFromTray(index));
        } else {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleRemoveClick = (e, idCommunication) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch, isDesktop } = this.props;

        dispatch(
            notificationModalActions.showConfirmation(
                i18n.get("communications.deleteMessage.confirmationModal.title"),
                "",
                () => dispatch(communicationAction.deleteRequest(idCommunication, isDesktop)),
            ),
        );
    };

    handleFetchMoreClick = () => {
        const { currentPage, dispatch, isFetching } = this.props;
        const { activeTray } = this.state;
        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case trays.read:
                    filters.onlyUnread = false;
                    break;
                case trays.unread:
                    filters.onlyUnread = true;
                    break;
                case trays.sent:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case trays.all:
                    filters.direction = "BANK_TO_CUSTOMER";
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(communicationsActions.fetchMoreRequest(filters));
        }
    };

    handleInputSearch = (e) => {
        if (e.key === "Enter") {
            this.setState({ searchSubject: e.target.value });
        } else if (e.type === "click") {
            this.setState({ searchSubject: this.inputRef.current.value });
        }
    };

    getItems = () => {
        const { list } = this.props;
        const { searchSubject } = this.state;
        const search = searchSubject.toLocaleLowerCase();

        return list.reduce((acc, communication, index) => {
            if (
                communication.subject.toLocaleLowerCase().indexOf(search) >= 0 ||
                communication.body.toLocaleLowerCase().indexOf(search) >= 0
            ) {
                return [
                    ...acc,
                    <MessageItem
                        communication={communication}
                        index={index}
                        handleRemoveClick={this.handleRemoveClick}
                        handleChangeMessageStatus={this.handleChangeMessageStatus}
                        handleSelectMessageClick={this.handleSelectMessageClick}
                        key={communication.idCommunication}
                    />,
                ];
            }
            return acc;
        }, []);
    };

    render() {
        const { currentPage, totalPages, isDesktop, isFetching } = this.props;
        const { activeTray, dimensions, searchSubject, filtersVisibility } = this.state;
        const moreMessages = totalPages > currentPage;
        const list = this.getItems();

        return (
            <>
                <section>
                    <Row className="justify-content-center">
                        <Col className="col col-12">
                            {filtersVisibility && (
                                <ButtonGroup className="icon-tags">
                                    <Button
                                        label="communications.trays.received"
                                        className={`btn btn-outline btn-hint-icon ${
                                            activeTray === trays.all ? "active" : ""
                                        }`}
                                        onClick={this.handleAllClick}
                                        image="images/inbox.svg"
                                    />
                                    <Button
                                        label="communications.trays.unread"
                                        className={`btn btn-outline btn-hint-icon ${
                                            activeTray === trays.unread ? "active" : ""
                                        }`}
                                        onClick={this.handleUnreadClick}
                                        image="images/email.svg"
                                    />
                                    <Button
                                        label="communications.trays.read"
                                        className={`btn btn-outline btn-hint-icon ${
                                            activeTray === trays.read ? "active" : ""
                                        }`}
                                        onClick={this.handleReadClick}
                                        image="images/read.svg"
                                    />
                                    <Button
                                        label="communications.trays.sent"
                                        className={`btn btn-outline btn-hint-icon ${
                                            activeTray === trays.sent ? "active" : ""
                                        }`}
                                        onClick={this.handleSentClick}
                                        image="images/sent.svg"
                                    />
                                </ButtonGroup>
                            )}
                            <div className="form-group">
                                <div className="input-group messages-search">
                                    <Button className="btn-only-icon" onClick={this.handleInputSearch}>
                                        <Image src="images/iconos/search.svg" alt="global.search" />
                                    </Button>
                                    <input
                                        ref={this.inputRef}
                                        type="search"
                                        className="form-control"
                                        placeholder={i18n.get("communications.list.search.placeholder")}
                                        required=""
                                        id="search"
                                        autoComplete="off"
                                        onKeyUp={this.handleInputSearch}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>

                <Measure
                    bounds
                    onResize={(contentRect) => {
                        if (!dimensions) {
                            this.setState({ dimensions: contentRect.bounds });
                        }
                    }}>
                    {({ measureRef }) => {
                        if (!isDesktop) {
                            return (
                                <PageLoading loading={isFetching && currentPage !== 0}>
                                    {searchSubject && (
                                        <span className="ui-dflex ui-mb-3">
                                            {i18n.get("communications.list.search.currentSearch.info")}&nbsp;{" "}
                                            <b>{`'${searchSubject}'`}</b>
                                        </span>
                                    )}
                                    {(list.length > 0 && (
                                        <>
                                            {<ul className="message-list">{list}</ul>}
                                            {moreMessages && (
                                                <Container className="container--layout align-items-center" />
                                            )}
                                        </>
                                    )) || (
                                        <Container className="container--layout align-items-center">
                                            <Col className="col col-12 ">
                                                {searchSubject ? (
                                                    <NoResults
                                                        message={
                                                            moreMessages
                                                                ? "communications.list.search.empty.showMore"
                                                                : "communications.list.search.empty"
                                                        }
                                                        image="search-no-results.svg"
                                                    />
                                                ) : (
                                                    <NoResults
                                                        message="communications.list.empty"
                                                        image="mailbox-empty.svg"
                                                    />
                                                )}
                                            </Col>
                                        </Container>
                                    )}
                                    {moreMessages && (
                                        <Col className="col col-12">
                                            <Button
                                                label={`${
                                                    searchSubject
                                                        ? "communications.messages.search.more"
                                                        : "communications.messages.more"
                                                }`}
                                                bsStyle="secondary"
                                                onClick={this.handleFetchMoreClick}
                                                className="ui-mt-7"
                                                image="images/plusWhite.svg"
                                                block
                                            />
                                        </Col>
                                    )}
                                </PageLoading>
                            );
                        }
                        return (
                            <section className="flex-grow container--layout" ref={measureRef}>
                                <Grid>
                                    <Row className="justify-content-center">
                                        <Col className="col col-12 all-messages">
                                            <PageLoading loading={isFetching && list.length === 0}>
                                                {searchSubject && (
                                                    <span className={`ui-dflex ${list.length ? "ui-mb-3" : ""}`}>
                                                        {i18n.get("communications.list.search.currentSearch.info")}
                                                        &nbsp; <b>{`'${searchSubject}'`}</b>
                                                    </span>
                                                )}
                                                {(list.length > 0 && (
                                                    <>{isDesktop && <ul className="message-list">{list}</ul>}</>
                                                )) ||
                                                    (!isFetching && (
                                                        <Container className="container--layout align-items-center">
                                                            <Col className="col col-12 ">
                                                                {searchSubject ? (
                                                                    <NoResults
                                                                        message={
                                                                            moreMessages
                                                                                ? "communications.list.search.empty.showMore"
                                                                                : "communications.list.search.empty"
                                                                        }
                                                                        image="search-no-results.svg"
                                                                    />
                                                                ) : (
                                                                    <NoResults
                                                                        message="communications.list.empty"
                                                                        image="mailbox-empty.svg"
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Container>
                                                    ))}
                                            </PageLoading>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        );
                    }}
                </Measure>
                {isDesktop && moreMessages && (
                    <Col className="col col-12 ui-text-center">
                        <Button
                            label={`${
                                searchSubject ? "communications.messages.search.more" : "communications.messages.more"
                            }`}
                            bsStyle="secondary"
                            onClick={this.handleFetchMoreClick}
                            image="/images/plusWhite.svg"
                        />
                    </Col>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    list: communicationsSelectors.list(state),
    currentPage: communicationsSelectors.currentPage(state),
    totalPages: communicationsSelectors.totalPages(state),
    isFetching: communicationsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(List);
