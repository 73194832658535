import React, { Component } from "react";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { string, shape, bool, number } from "prop-types";
import I18n from "pages/_components/I18n";
import GenericItem from "components/GenericItem/GenericItem";

class Check extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        check: shape({
            date: string,
            checkNumber: string,
            state: string,
            account: shape({
                number: string,
                currency: string,
                formattedAccountNumber: string,
            }),
            amount: number,
            debitDate: string,
            currency: string,
            productType: string,
            accreditationDate: string,
            rejectionReason: string,
        }).isRequired,
        checkType: string.isRequired,
        idActivity: string.isRequired,
        onClick: bool,
    };

    static defaultProps = {
        onClick: null,
    };

    renderCheckMobile = () => {
        const { check, checkType, idActivity, onClick } = this.props;
        const { checkNumber, bank, account, amount, debitDate, state, depositDate, accreditationDate } = check;

        return (
            <GenericItem arrow onClick={onClick} role="button">
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.checkNumber.label`} />
                    </div>
                    <span className="data-text">{checkNumber}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.account.label`} />
                    </div>
                    <span className="data-text">{account && account.formattedAccountNumber}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.${checkType}.bank.label`} />
                    </div>
                    <span className="data-text">{bank}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.depositDate.label`} />
                    </div>

                    {depositDate && (
                        <span>
                            <FormattedDate date={depositDate} />
                        </span>
                    )}
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.amount.label`} />
                    </div>

                    <FormattedAmount currency={(account && account.currency) || "ARS"} quantity={amount} />
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.${checkType}.date.label`} />
                    </div>
                    <span>
                        <FormattedDate date={debitDate || accreditationDate} />
                    </span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.state.label`} />
                    </div>
                    <span className="data-text data-uppercase">{state}</span>
                </div>
            </GenericItem>
        );
    };

    render() {
        const { isDesktop, check } = this.props;
        const { checkNumber, bank, account, amount, debitDate, state, depositDate, accreditationDate } = check;
        return isDesktop ? (
            <>
                <div className="table-data">
                    <span>{checkNumber}</span>
                </div>
                <div className="table-data hide-on-lg">
                    <span>{account && account.formattedAccountNumber}</span>
                </div>
                <div className="table-data">
                    <span>{bank}</span>
                </div>

                <div className="table-data hide-on-lg">
                    <span>{depositDate && <FormattedDate date={depositDate} />}</span>
                </div>
                {debitDate && (
                    <div className="table-data">
                        <span>
                            <FormattedDate date={debitDate} />
                        </span>
                    </div>
                )}
                <div className="table-data">
                    <FormattedAmount currency={(account && account.currency) || "ARS"} quantity={amount} />
                </div>
                {accreditationDate && (
                    <div className="table-data">
                        <span>
                            <FormattedDate date={accreditationDate} />
                        </span>
                    </div>
                )}
                <div className="table-data hide-on-lg">
                    <span className="data-uppercase">{state}</span>
                </div>

                <div className="table-data table-data-icon">
                    <ChevromRight />
                </div>
            </>
        ) : (
            this.renderCheckMobile()
        );
    }
}

export default Check;
