import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";
import { array, bool, element, func, object, string, number } from "prop-types";

import * as i18n from "util/i18n";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

class MaskedTextField extends Component {
    static propTypes = {
        autoComplete: string,
        autoFocus: bool,
        field: object.isRequired,
        form: object.isRequired,
        handleChange: func,
        hidelabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: object,
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        mask: array,
        maxLength: number,
        mobileOS: string,
        pattern: string,
        renderAs: string,
        type: string,
        uppercase: bool,
    };

    static defaultProps = {
        autoComplete: "on",
        autoFocus: false,
        hidelabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        mask: null,
        maxLength: 50,
        pattern: null,
        renderAs: "input",
        type: "text",
        uppercase: true,
    };

    handleBlur = (event) => {
        const {
            field: { onBlur },
        } = this.props;

        onBlur(event);
    };

    handleChange = (event) => {
        const { field, form, uppercase } = this.props;

        form.setFieldValue(field.name, uppercase ? event.target.value.toUpperCase() : event.target.value);
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hidelabel,
            hidePlaceholder,
            idForm,
            inputFunctions,
            isFocused,
            mask,
        } = this.props;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hidelabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}

                <div className="input-group">
                    <MaskedInput
                        className="form-control"
                        guide={false}
                        mask={mask}
                        onBlur={this.handleBlur}
                        placeholder={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                        {...field}
                        onChange={this.handleChange}
                    />
                    {inputFunctions}
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default MaskedTextField;
