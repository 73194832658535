import filesize from "filesize";
import { Field, Form, withFormik } from "formik";
import Parser from "html-react-parser";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";

import { routerActions } from "react-router-redux/actions";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import {
    actions as communicationTraysActions,
    selectors as communicationTraysSelectors,
} from "reducers/communicationTrays";
import { actions as notificationActions } from "reducers/notification";

import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import TextArea from "pages/_components/fields/TextArea";
import TextField from "pages/_components/fields/TextField";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";

import * as config from "util/config";
import * as i18n from "util/i18n";
import FileUploadButton from "pages/_components/FileUploadButton";

const FORM_ID = "communications.compose";

const MessageHeader = ({ title, onClose }) => (
    <div className="container flex-container d-flex-align-items-flexstart">
        <div className="view-title message-subject">
            <h3>{title}</h3>
        </div>
        {onClose && (
            <Button
                className="btn-only-icon btn-link "
                image="images/ui-icons/ui-cross.svg"
                label="global.close"
                onClick={onClose}
            />
        )}
    </div>
);

MessageHeader.propTypes = {
    title: string.isRequired,
    onClose: func,
};

MessageHeader.defaultProps = {
    onClose: null,
};

class Compose extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        onCloseClick: func.isRequired,
        selectedFiles: arrayOf(string),
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        detail: shape({}).isRequired,
        trays: arrayOf(shape({})),
        isReply: bool,
        currentPage: number.isRequired,
        preloadedMessage: shape({}),
    };

    static defaultProps = {
        selectedFiles: [],
        trays: [],
        isReply: false,
        preloadedMessage: null,
    };

    state = {
        fileInputRef: React.createRef(),
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(communicationTraysActions.listRequest());
    }

    onChangeFile(event) {
        const { dispatch } = this.props;

        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        if (file) {
            const fileTypes = config.get("files.allowedFileExtensions");
            const fileExtension = `${file.name.split(".").slice(-1)}`;

            if (fileTypes.includes(fileExtension)) {
                dispatch(communicationActions.selectAttachment(file));
            } else {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.invalid"),
                        "error",
                        ["communications"],
                    ),
                );
            }
        }
    }

    removeSelectedFile = (index) => {
        const { dispatch } = this.props;
        const { fileInputRef } = this.state;

        dispatch(communicationActions.removeSelectedAttachment(index));

        fileInputRef.current.value = "";
    };

    close = () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.resetPreloadedMessage());
        dispatch(
            routerActions.replace({ pathname: "/communications/", state: { transition: "transition-flow-close" } }),
        );
    };

    renderHeader = () => {
        const { isReply, detail, isDesktop, onCloseClick } = this.props;

        if (isDesktop) {
            return (
                <div className="d-flex-align-items-flexstart">
                    {(!isReply && (
                        <div className="view-title new-message">
                            <I18n id="communications.compose.message.new" component="h3" />
                        </div>
                    )) ||
                        (detail && detail[0] && (
                            <div className="view-title">
                                <h3>&nbsp;</h3>
                            </div>
                        ))}
                    <Button
                        label="global.close"
                        className="btn-only-icon btn-link "
                        image="images/cross.svg"
                        onClick={onCloseClick}
                    />
                </div>
            );
        }

        return (
            <div className="container flex-container d-flex-align-items-flexstart">
                <div className="view-title">
                    {!isReply ? <h3>{i18n.get("communications.compose.message.new")}</h3> : <h3>&nbsp;</h3>}
                </div>
                <Button
                    className="btn-only-icon btn-link "
                    image="images/cross.svg"
                    label="global.close"
                    onClick={this.close}
                />
            </div>
        );
    };

    render() {
        const {
            trays,
            isReply,
            detail,
            isDesktop,
            isFetching,
            selectedFiles,
            currentPage,
            preloadedMessage,
        } = this.props;
        const { fileInputRef } = this.state;

        return (
            <>
                <Notification scopeToShow="communicationCompose" />
                <PageLoading loading={isFetching && currentPage !== 0}>
                    <Form className={`form-content ${!isDesktop ? "mailing compose" : ""}`}>
                        {this.renderHeader()}

                        {!isReply && (
                            <Field
                                hidePlaceholder
                                component={TextField}
                                idForm={FORM_ID}
                                name="subject"
                                type="text"
                                maxLength={100}
                                disabled={preloadedMessage}
                            />
                        )}

                        {isReply ? (
                            detail && (
                                <>
                                    <Field
                                        hidePlaceholder
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="subject"
                                        type="text"
                                        value={`RE: ${detail[0].subject}`}
                                        editing={false}
                                        disabled
                                    />
                                    <Field
                                        hidePlaceholder
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="tray"
                                        type="text"
                                        value={Parser(
                                            i18n.get(
                                                `communications.tray.${detail[0].communicationTray.idCommunicationTray}`,
                                            ),
                                        )}
                                        editing={false}
                                        disabled
                                    />
                                </>
                            )
                        ) : (
                            <Field
                                component={Selector}
                                options={trays.map((tray) => ({
                                    value: tray.idCommunicationTray,
                                    label: Parser(i18n.get(`communications.tray.${tray.idCommunicationTray}`)),
                                }))}
                                className="slideFromBottom"
                                idForm={FORM_ID}
                                name="tray"
                                type="text"
                                disabled={preloadedMessage}
                            />
                        )}

                        <Field hidePlaceholder component={TextArea} idForm={FORM_ID} name="body" maxLength={1500} />

                        {selectedFiles && (
                            <div className="form-group attachments">
                                {selectedFiles.map((file, index) => (
                                    <div key={file.name}>
                                        <span>
                                            {file.name} ({filesize(file.size)}){" "}
                                        </span>
                                        <Button
                                            label="global.delete"
                                            className="btn-icon-only button-delete-selectedFiles"
                                            onClick={() => this.removeSelectedFile(index)}
                                            image="images/ui-icons/ui-delete.svg"
                                            bsStyle="circle"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="form-footer">
                            <FileUploadButton
                                bsStyle="outline"
                                image="images/attachment.svg"
                                label="communications.compose.attach"
                                id="myFileUpload"
                                onChange={(e) => this.onChangeFile(e)}
                                block={!isDesktop}
                                fileInputRef={fileInputRef}
                            />

                            <Button
                                bsStyle="primary"
                                label="communications.compose.send"
                                type="submit"
                                block={!isDesktop}
                            />
                        </div>
                    </Form>
                </PageLoading>
            </>
        );
    }
}

const chargeSubject = (props) => {
    const { isReply, detail, preloadedMessage, isCompose } = props;

    if (isReply) {
        if (detail && detail[0]) {
            return detail[0].subject;
        }
        if (preloadedMessage) {
            return preloadedMessage.subject;
        }
    } else if (isCompose && preloadedMessage) {
        return preloadedMessage.subject;
    }
    return "";
};

const chargeBody = (props) => {
    const { preloadedMessage, isCompose } = props;

    if (isCompose && preloadedMessage) {
        return preloadedMessage.body;
    }
    return "";
};

const chargeTray = (props) => {
    const { preloadedMessage, isCompose } = props;

    if (isCompose && preloadedMessage) {
        return Number(preloadedMessage.tray);
    }
    return 1;
};

const mapsStateToProps = (state) => ({
    trays: communicationTraysSelectors.list(state),
    detail: communicationSelectors.detail(state),
    isFetching: communicationSelectors.isFetching(state),
    selectedFiles: communicationSelectors.selectedAttachments(state),
});

export default compose(
    connect(mapsStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            subject: chargeSubject(props),
            body: chargeBody(props),
            tray: chargeTray(props),
            isSuspiciousActivity: props.preloadedMessage?.suspiciousActivity,
        }),
        validationSchema: (props) => {
            const { isReply } = props;

            return Yup.object().shape({
                subject: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.subject.required"))
                    : Yup.string().notRequired(),
                body: Yup.string()
                    .trim()
                    .required(i18n.get("communications.body.required")),
                tray: !isReply
                    ? Yup.string()
                          .trim()
                          .required(i18n.get("communications.tray.required"))
                    : Yup.string().notRequired(),
            });
        },

        handleSubmit: (fields, formikBag) => {
            const { dispatch, selectedFiles, isReply, isDesktop } = formikBag.props;
            const body = fields.body.trim();
            const subject = fields.subject.trim();
            const { tray } = fields;

            if (isReply) {
                const { detail } = formikBag.props;

                dispatch(
                    communicationActions.replyRequest(
                        detail[0].communicationTray.idCommunicationTray,
                        `RE: ${detail[0].subject}`,
                        body,
                        detail[0].idCommunication,
                        0,
                        selectedFiles,
                        isDesktop,
                    ),
                );
            } else {
                const { isSuspiciousActivity } = fields;

                dispatch(
                    communicationActions.sendRequest(
                        tray,
                        subject,
                        body,
                        selectedFiles,
                        isDesktop,
                        isSuspiciousActivity,
                    ),
                );
            }
        },
    }),
)(Compose);
