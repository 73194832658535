import React, { Component } from "react";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { string, shape, bool, number, func } from "prop-types";
import I18n from "pages/_components/I18n";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import moment from "moment";
import GenericItem from "components/GenericItem/GenericItem";

class Loan extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        loan: shape({
            date: string,
            operationCode: string,
            descriptionSIOC: string,
            nextExpiration: string,
            lastPayment: string,
            feesPendingPayment: number,
            balance: number,
            amount: number,
            currency: string,
        }).isRequired,
        loanType: string.isRequired,
        idActivity: string.isRequired,
        productAlias: string,
        onClick: func,
    };

    static defaultProps = {
        productAlias: null,
        onClick: null,
    };

    renderCheckMobile = () => {
        const { idActivity, loan, loanType, onClick } = this.props;
        const {
            operationCode,
            descriptionSIOC,
            date,
            nextExpiration,
            lastExpiration,
            lastPayment,
            feesPendingPayment,
            totalFees,
            balance,
            amount,
            currencyDescription,
            currency,
            productAlias,
        } = loan;
        const expiryDate = nextExpiration !== null ? nextExpiration : lastExpiration;
        let dueDate = moment(expiryDate);
        dueDate = dueDate.add(1, "days").toDate();
        const isExpired = dateUtils.isDateLessThanToday(dueDate);
        const haveFeesPendingPayment = !!(totalFees === 0 || totalFees === 1);
        const currencyAmount = currency === "VN" ? currency : currencyDescription;
        const species = currency === "VN" ? currencyDescription : null;
        return (
            <GenericItem arrow onClick={onClick} role="button">
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.operationNumber.label`} />
                    </div>
                    <span className="data-text">{productAlias || operationCode}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.operationType.label`} />
                    </div>
                    <span className="data-text">{descriptionSIOC}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.dateOfOrigin.label`} />
                    </div>
                    <span className="data-date">
                        <FormattedDate date={date.substring(0, 10)} />
                    </span>
                </div>

                {loanType === "current" ? (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.nextExpiration.label`} />
                            </div>
                            <span className={`${isExpired && expiryDate ? "data-date-expired" : "data-date"}`}>
                                {expiryDate ? (
                                    <FormattedDate date={expiryDate.substring(0, 10)} />
                                ) : (
                                    <I18n id={`${idActivity}.emptyValue.label`} />
                                )}
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.pendingFees.label`} />
                            </div>
                            <span className="data-text">
                                {totalFees === 0 ? i18n.get(`${idActivity}.NA.label`) : feesPendingPayment}
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.amount.label`} />
                            </div>
                            {haveFeesPendingPayment ? (
                                <span className="data-text">{i18n.get(`${idActivity}.NA.label`)}</span>
                            ) : (
                                <FormattedAmount species={species} currency={currencyAmount} quantity={amount} />
                            )}
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.balance.label`} />
                            </div>

                            <FormattedAmount species={species} currency={currencyAmount} quantity={balance} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.cancellationDate.label`} />
                            </div>
                            <span className="data-date">
                                {lastPayment ? (
                                    <FormattedDate date={lastPayment.substring(0, 10)} />
                                ) : (
                                    <I18n id={`${idActivity}.emptyValue.label`} />
                                )}
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idActivity}.amountHistory.label`} />
                            </div>

                            <FormattedAmount species={species} currency={currencyAmount} quantity={amount} />
                        </div>
                    </>
                )}
            </GenericItem>
        );
    };

    render() {
        const { isDesktop, loan, loanType, idActivity } = this.props;
        const {
            operationCode,
            descriptionSIOC,
            date,
            nextExpiration,
            lastExpiration,
            lastPayment,
            feesPendingPayment,
            balance,
            totalFees,
            amount,
            productAlias,
            currencyDescription,
            currency,
        } = loan;
        const haveFeesPendingPayment = !!(totalFees === 0 || totalFees === 1);
        const expiryDate = nextExpiration !== null ? nextExpiration : lastExpiration;
        let dueDate = moment(expiryDate);
        dueDate = dueDate.add(1, "days").toDate();
        const isExpired = dateUtils.isDateLessThanToday(dueDate);
        const currencyAmount = currency === "VN" ? currency : currencyDescription;
        const species = currency === "VN" ? currencyDescription : null;
        return isDesktop ? (
            <>
                <div className="table-data">
                    <span className="data-text">{productAlias || operationCode}</span>
                </div>
                <div className="table-data">
                    <span className="data-text">{descriptionSIOC}</span>
                </div>

                <div className="table-data">
                    <span className="data-date">{date && <FormattedDate date={date.substring(0, 10)} />}</span>
                </div>
                {loanType === "current" ? (
                    <>
                        <div className="table-data">
                            <span className={`${isExpired && expiryDate ? "data-date-expired" : "data-date"}`}>
                                {expiryDate ? (
                                    <FormattedDate date={expiryDate.substring(0, 10)} />
                                ) : (
                                    <I18n id={`${idActivity}.emptyValue.label`} />
                                )}
                            </span>
                        </div>
                        <div className="table-data">
                            <span className="data-text">
                                {totalFees === 0 ? i18n.get(`${idActivity}.NA.label`) : feesPendingPayment}
                            </span>
                        </div>
                        <div className="table-data align-right">
                            {haveFeesPendingPayment ? (
                                <span className="data-text">{i18n.get(`${idActivity}.NA.label`)}</span>
                            ) : (
                                <FormattedAmount
                                    className="data-amount"
                                    species={species}
                                    currency={currencyAmount}
                                    quantity={amount}
                                />
                            )}
                        </div>
                        <div className="table-data align-right">
                            <FormattedAmount species={species} currency={currencyAmount} quantity={balance} />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="table-data">
                            <span className="data-date">
                                {lastPayment ? (
                                    <FormattedDate date={lastPayment.substring(0, 10)} />
                                ) : (
                                    <I18n id={`${idActivity}.emptyValue.label`} />
                                )}
                            </span>
                        </div>
                        <div className="table-data">
                            <FormattedAmount
                                className="data-amount"
                                species={species}
                                currency={currencyAmount}
                                quantity={amount}
                            />
                        </div>
                    </>
                )}
                <div className="table-data table-data-icon">
                    <ChevromRight />
                </div>
            </>
        ) : (
            this.renderCheckMobile()
        );
    }
}

export default Loan;
