import React from "react";
import "./_alert.scss";
import { func, string, node } from "prop-types";
import Image from "../Image";
import Button from "../Button";

const Alert = ({ closedButton, children, className, icon }) => (
    <div className={`alert ${className}`}>
        <Image src={icon} className="svg-icon" alt="Alert" />

        <div className="alert__text">{children}</div>

        {closedButton && (
            <Button
                label=""
                bsStyle="link"
                className="btn-only-icon"
                image="images/ui-icons/ui-cross.svg"
                onClick={closedButton}
            />
        )}
    </div>
);

Alert.propTypes = {
    closedButton: func,
    children: node,
    className: string,
    icon: string,
};

Alert.defaultProps = {
    closedButton: null,
    children: "",
    className: "",
    icon: "images/ui-icons/ui-exclamation-red.svg",
};

export default Alert;
