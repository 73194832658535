import React, { Component } from "react";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { bool, func, shape, string } from "prop-types";
import I18n from "pages/_components/I18n";

import GenericItem from "components/GenericItem/GenericItem";

class CheckDeferred extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        check: shape({}).isRequired,
        idActivity: string.isRequired,
        onClick: func,
    };

    static defaultProps = {
        onClick: null,
    };

    renderCheckMobile() {
        const { check, idActivity, onClick } = this.props;
        const { checkNumber, idCheck, operationNumber, bank, dateDeposit, operationDate, amount, state } = check;

        return (
            <GenericItem role="button" arrow onClick={onClick}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.checkNumber.label`} />
                    </div>
                    <span className="data-text">{checkNumber}</span>
                </div>

                {idCheck && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${idActivity}.idCheck.label`} />
                        </div>
                        <span className="data-text">{idCheck}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.dateOperation.label`} />
                    </div>
                    <span className="data-text">
                        <FormattedDate date={operationDate.substring(0, 10)} />
                    </span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.operationNumber.label`} />
                    </div>
                    <span className="data-text">{operationNumber}</span>
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.bank.label`} />
                    </div>
                    <span className="data-text">{bank}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.dateDeposit.label`} />
                    </div>
                    <span className="data-text">
                        <FormattedDate date={dateDeposit.substring(0, 10)} />
                    </span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.amount.label`} />
                    </div>
                    <FormattedAmount className="data-amount data-numeric" currency="ARS" quantity={amount} />
                </div>

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idActivity}.state.label`} />
                    </div>
                    <span className="data-text">{state}</span>
                </div>
            </GenericItem>
        );
    }

    render() {
        const { isDesktop, check } = this.props;
        const { checkNumber, idCheck, operationNumber, bank, dateDeposit, operationDate, amount, state } = check;

        return isDesktop ? (
            <>
                <div className="table-data">
                    <span>{checkNumber}</span>
                </div>

                <div className="table-data hide-on-lg">
                    <span>{idCheck}</span>
                </div>

                <div className="table-data">
                    <span className="data-date">
                        <FormattedDate date={operationDate.substring(0, 10)} />
                    </span>
                </div>

                <div className="table-data hide-on-lg">
                    <span>{operationNumber}</span>
                </div>

                <div className="table-data">
                    <span>{bank}</span>
                </div>

                <div className="table-data">
                    <span>
                        <FormattedDate date={dateDeposit.substring(0, 10)} />
                    </span>
                </div>

                <div className="table-data">
                    <FormattedAmount currency="ARS" quantity={amount} />
                </div>

                <div className="table-data hide-on-lg">
                    <span>{state}</span>
                </div>

                <div className="table-data table-data-icon">
                    <ChevromRight />
                </div>
            </>
        ) : (
            this.renderCheckMobile()
        );
    }
}

export default CheckDeferred;
