import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";

import { types as echeqTypes, actions as echeqsActions, selectors as echeqsSelectors } from "reducers/echeqs";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { selectors as sessionSelectors } from "reducers/session";
import defaultDateFromList from "util/defaultDateFromList";
import { validateToResetFilters } from "util/filters";
import List from "./List";
import ECheqFilters from "./Filters";

const ID_ACTIVITY = "echeqs.list";

class ECheqsList extends Component {
    static propTypes = {
        isMobile: bool,
        isDesktop: bool.isRequired,
        history: shape({}).isRequired,
        dispatch: func.isRequired,
        isResetQuery: bool.isRequired,
        filters: shape({
            echeqType: string.isRequired,
        }).isRequired,
        activeEnvironment: shape({
            permissions: shape({}),
        }).isRequired,
        idActivityOnFilter: string.isRequired,
        reloadData: bool.isRequired,
        isDownloading: bool.isRequired,
    };

    static defaultProps = {
        isMobile: false,
    };

    componentWillMount = () => {
        const { dispatch, isResetQuery, filters, activeEnvironment } = this.props;
        const { permissions } = activeEnvironment;
        if (
            !permissions["echeqs.termsAndConditions.alreadySigned"] &&
            permissions["echeqs.termsAndConditions.adherence"]
        ) {
            dispatch(echeqsActions.getAcceptEcheqTermsConditionsRequest());
        }

        dispatch(echeqsActions.cleanBankedCustomerInfo());

        this.handleTabClick(isResetQuery ? "received" : filters.echeqType);
    };

    handleTabClick = (echeqType) => {
        const { filters, dispatch, isResetQuery, idActivityOnFilter, reloadData } = this.props;

        let defaultFilters = {};
        dispatch(filterActions.validateToResetFilters(echeqTypes.RESET_FILTERS, ID_ACTIVITY));
        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            dispatch(
                echeqsActions.setFilters({
                    echeqType,
                }),
            );

            defaultFilters = {
                echeqType,
                extraInfo: "",
                payedFrom: null,
                payedTo: null,
                createdFrom: null,
                createdTo: null,
                filterByEmissionDate: null,
                payedCheck: null,
                status: "TODOS",
                grouper: null,
                number: "",
                id: null,
                emittedBy: null,
                option: null,
                character: "",
                ownership: null,
                orderDirection: "ascendent",
                paymentDateFrom: defaultDateFromList().echeqs.paymentDateFrom,
                paymentDateTo: defaultDateFromList().echeqs.paymentDateTo,
            };
            dispatch(echeqsActions.loadListRequest(defaultFilters));
        } else {
            defaultFilters = filters;
            if (reloadData) {
                dispatch(echeqsActions.loadListRequest(defaultFilters));
            }
        }

        dispatch(filterActions.setReloadData(false));
    };

    handleClickDownload = (format) => {
        const { dispatch, filters } = this.props;
        dispatch(echeqsActions.downloadEcheqList(format, filters));
    };

    renderMenuOptions = () => {
        const { dispatch, filters } = this.props;

        return [
            <Button
                key="echeqs.listEcheqs.mobileOptions.downloadPdf"
                className="dropdown__item-btn"
                label="echeqs.listEcheqs.mobileOptions.downloadPdf.label"
                onClick={() => dispatch(echeqsActions.downloadEcheqList("pdf", filters))}
                bsStyle="link"
            />,
        ];
    };

    renderHeader = () => {
        const { isMobile, isDownloading } = this.props;

        return (
            <Head
                showLogo={isMobile}
                additionalClassName={(isMobile && "blue-main-header-mobile") || null}
                accessibilityTextId="echeqs.listEcheqs.title"
                showPlusIcon={!isMobile}
                addLinkTo="/echeqs/formCustom/emitEcheq"
                addLinkToLabel="accounts.echeq.new"
                handleClick={this.handleClickDownload}
                mobileMenuOptions={this.renderMenuOptions()}
                DropdownCustom="echeqs.list"
                isFetchingExport={isDownloading}
            />
        );
    };

    renderFilters = () => {
        const { isMobile, filters, dispatch } = this.props;
        const { echeqType } = filters;
        return <ECheqFilters idForm={ID_ACTIVITY} echeqType={echeqType} isMobile={isMobile} dispatch={dispatch} />;
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    render() {
        const { isDesktop, history, isMobile, filters } = this.props;
        const { echeqType } = filters;

        return (
            <>
                <Notification scopeToShow="echeqsList" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="title-left-header">
                        <I18n id="echeqs.listEcheqs.title" component="h1" />
                    </div>

                    <div className="container-left">
                        {isMobile && (
                            <Button
                                label="accounts.echeq.new"
                                bsStyle="secondary"
                                block
                                image="images/plusWhite.svg"
                                onClick={() => history.push("/echeqs/formCustom/emitEcheq")}
                                className="ui-mb-4"
                            />
                        )}

                        <>
                            <div className="tabs-wrapper">
                                <Button
                                    label={`${ID_ACTIVITY}.received.label`}
                                    className={`${echeqType === "received" ? "active" : ""}`}
                                    onClick={() => this.handleTabClick("received")}
                                    type="button"
                                    bsStyle="outline"
                                />
                                <Button
                                    label={`${ID_ACTIVITY}.sent.label`}
                                    className={`${echeqType === "sent" ? "active" : ""}`}
                                    onClick={() => this.handleTabClick("sent")}
                                    type="button"
                                    bsStyle="outline"
                                />
                            </div>

                            {this.renderFilters()}

                            <List
                                history={history}
                                isMobile={isMobile}
                                idActivity={ID_ACTIVITY}
                                isDesktop={isDesktop}
                                echeqType={echeqType}
                            />
                        </>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isResetQuery: filterSelectors.isResetFilters(state),
    filters: echeqsSelectors.getFilters(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
    reloadData: filterSelectors.isReloadData(state),
    isDownloading: echeqsSelectors.getIsDownloading(state),
});

export default connect(mapStateToProps)(ECheqsList);
