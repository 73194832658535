import { bool, func, oneOfType, arrayOf, objectOf, any } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions as qrActions, selectors as qrSelectors } from "reducers/qr";
import { actions as filterActions } from "reducers/filters";

import Head from "../../_components/Head";
import I18n from "../../_components/I18n";
import MainContainer from "../../_components/MainContainer";
import Notification from "../../_components/Notification";
import FiltersChargesCertificates from "./FiltersChargesCertificates";

const ChargesCertificateContainer = ({ isMobile, isFetching, dispatch, accountsForQr, ...rest }) => {
    useEffect(() => {
        if (rest.match.params.value === "initValue") {
            dispatch(qrActions.loadAccountListForQR());
        } else {
            dispatch(filterActions.setResetFilters(true));
        }
    }, []);

    const renderHeader = () => {
        if (isMobile) {
            return <Head showLogo arrowWhite additionalClassName="blue-main-header-mobile" />;
        }

        return <Head showPlusSecondary accessibilityTextId="accounts.qrChargesCertificates.title" />;
    };

    return (
        <>
            <Notification scopeToShow="qrChargesCertificates" />
            {renderHeader()}
            <MainContainer>
                <div className="container-left">
                    <div className="title-left-header">
                        <I18n id="accounts.qrChargesCertificates.title" component="h1" />
                        <I18n id="accounts.qrChargesCertificates.subtitle" component="h2" />
                    </div>
                    <FiltersChargesCertificates isMobile={isMobile} />
                </div>
            </MainContainer>
        </>
    );
};

ChargesCertificateContainer.propTypes = {
    isFetching: bool.isRequired,
    isMobile: bool.isRequired,
    dispatch: func.isRequired,
    accountsForQr: oneOfType([arrayOf(any), objectOf(any)]).isRequired,
};

ChargesCertificateContainer.defaultProps = {};

const mapStateToProps = (state) => ({
    isFetching: qrSelectors.getFetching(state),
    accountsForQr: qrSelectors.getAccountListForQR(state),
});

export default connect(mapStateToProps)(ChargesCertificateContainer);
