import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { selectors as accountsSelectors } from "reducers/accounts";
import I18n from "pages/_components/I18n";

import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "account.requestClosing";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const AccountClosingForm = (props) => {
    const { mode, dispatch, location, fromBackoffice, transaction, isDesktop, account: accountSelected } = props;
    useEffect(() => {
        if (mode === "edit" || (mode === "view" && transaction?.data?.accountLabel === undefined && !fromBackoffice)) {
            const formData = {};
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, location, fromBackoffice, mode, transaction]);

    const { idTransaction } = transaction || {};

    const renderFields = () => {
        const { currentLang, preData, data } = props;
        const accounts = preData?.accounts || [];
        const accountsOptions = accounts.map((account) => ({
            id: account.label,
            label: account.label,
            shortLabel: account.shortLabel,
            idProduct: account.idProduct,
        }));
        const preDataAccount =
            accountSelected && accountsOptions.filter((account) => account.idProduct === accountSelected.idProduct)[0];

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        let accountValue = "";
        if (data.accountLabel) {
            accountValue = data.accountLabel[0];
        } else if (preDataAccount) {
            accountValue = [preDataAccount.id];
        }

        return (
            <>
                {mode === "view" && data?.accountLabel && data?.reason ? (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="accountLabel"
                            name="accountLabel"
                            idField="accountLabel"
                        />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Textarea}
                            key="reason"
                            name="reason"
                            idField="reason"
                        />

                        {data?.cancelReason && (
                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id="forms.cancelRequest.cancelReason.ticket.label" />
                                </span>
                                <span className="data-text">{data.cancelReason}</span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={accountsOptions}
                            key="accountLabel"
                            name="accountLabel"
                            idField="accountLabel"
                            renderAs="combo"
                            value={accountValue}
                            useShortLabel={!isDesktop}
                        />
                        {mode === "edit" && (
                            <div className="form-text-info">
                                <I18n id="forms.account.requestClosing.reasonText.label" component="p" />
                            </div>
                        )}
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Textarea}
                            key="reason"
                            name="reason"
                            idField="reason"
                            value={data.reason ? data.reason : ""}
                            maxLength={200}
                        />
                        {mode === "preview" && (
                            <DisclaimerContainer
                                className="ui-mt-8"
                                textSmall
                                justifyText
                                disclaimer="forms.account.requestClosing.termsAndConditions.label"
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: `forms.${ID_FORM}.formTitle`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};

AccountClosingForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    isDesktop: bool.isRequired,
    account: shape({
        idProduct: string,
    }),
};

AccountClosingForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    account: null,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    account: accountsSelectors.getSelectedAccount(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            accountLabel: props.data.accountLabel,
            reason: props.data.reason,
        }),
    }),
    withRouter,
)(AccountClosingForm);
