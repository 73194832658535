import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

import ReactPhoneInput from "react-phone-input-2";
import { shape, string, bool, func } from "prop-types";

import es from "react-phone-input-2/lang/es.json";
import pt from "react-phone-input-2/lang/pt.json";

import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";

class PhoneInput extends Component {
    state = {
        valueData: {
            dialCode: "54",
        },
        value: "54",
    };

    masks = () => {
        const { configs } = this.props;
        const dbMasks = Object.keys(configs).filter((config) => config.includes("phone.mask"));
        const customMasks = {};

        dbMasks.map((mask) => {
            const countryMask = mask.split(".")[2].toLowerCase();
            if (countryMask) {
                customMasks[countryMask] = configs[mask];
            }

            return customMasks;
        });
        return customMasks;
    };

    location = () => {
        const { activeLanguage } = this.props;
        if (activeLanguage === "es") {
            return es;
        }
        if (activeLanguage === "pt") {
            return pt;
        }
        return undefined;
    };

    handleOnChange = (valueChanged, data, e, formattedValue) => {
        const { field, form, onChangeCallback } = this.props;
        const previousDialCode = this.state.valueData.dialCode;
        const { dialCode } = data;

        let phoneValue = valueChanged;
        if (previousDialCode !== dialCode) {
            phoneValue = dialCode;
        } else if (valueChanged.length === 0) {
            phoneValue = dialCode;
        }
        this.setState({
            value: phoneValue,
            valueData: data,
        });
        if (form) {
            let areaCode = "";
            if (previousDialCode === dialCode && formattedValue.includes("(")) {
                areaCode = formattedValue.split("(")[1].split(")")[0];
                if (dialCode.includes(areaCode)) {
                    areaCode = "";
                }
            }
            phoneValue = phoneValue.substring(dialCode.length + areaCode.length);
            form.setFieldValue(field.name, `${dialCode}${areaCode}${phoneValue}`);
            if (onChangeCallback) {
                onChangeCallback({ fullName: `${dialCode}${areaCode}${phoneValue}`, dialCode, areaCode, phoneValue });
            }
        }
    };

    handleOnKeyDown = (e) => {
        const { valueData: dialCode, value } = this.state;
        const { value: targetValue } = e.currentTarget;
        const extraCharacter = targetValue.includes("(") ? 3 : 1;
        if (
            dialCode.length + extraCharacter > e.currentTarget.selectionStart &&
            targetValue.length === e.currentTarget.selectionEnd
        ) {
            e.currentTarget.selectionStart = dialCode.length + extraCharacter;
        } else if (
            dialCode.length + extraCharacter > e.currentTarget.selectionStart &&
            e.currentTarget.selectionStart === e.currentTarget.selectionEnd
        ) {
            e.currentTarget.selectionStart = targetValue.length;
        }
        if (e.keyCode === 8 && dialCode && dialCode.length > 0 && dialCode.length === value.length) {
            e.preventDefault();
        }
    };

    handleIsValid = (validValue, validCountry) => {
        const { countryCode, dialCode, format, name } = validCountry;

        const previousDialCode = this.state.valueData.dialCode;

        if (previousDialCode !== dialCode) {
            this.setState({
                value: validValue,
                valueData: {
                    countryCode,
                    dialCode,
                    format,
                    name,
                },
            });
        }
        return true;
    };

    render() {
        const { editing, country, idForm, field, value } = this.props;
        let { idField } = this.props;
        if (!idField) {
            idField = `${idForm}.${field.name}`;
        }
        if (editing) {
            return (
                <ReactPhoneInput
                    inputProps={{ id: idField }}
                    inputStyle={{ width: "100%" }}
                    searchStyle={{ width: "100%" }}
                    onChange={this.handleOnChange}
                    onKeyDown={this.handleOnKeyDown}
                    isValid={this.handleIsValid}
                    searchPlaceholder={i18nUtils.get("settings.changePhone.mobilePhone.search.placeholder")}
                    searchNotFound={i18nUtils.get("settings.changePhone.mobilePhone.search.countryNotFound")}
                    country={country}
                    localization={this.location}
                    value={value}
                    defaultMask="..............."
                    preferredCountries={["us"]}
                    enableSearch
                    containerClass="container-phone-input"
                    buttonClass="button-phone-input"
                    dropdownClass="dropdown-phone-input"
                    searchClass="search-phone-input"
                    alwaysDefaultMask
                    {...this.props}
                />
            );
        }
        return (
            <ul>
                <li>{`+${value}`}</li>
            </ul>
        );
    }
}

PhoneInput.propTypes = {
    idForm: string.isRequired,
    form: shape({}).isRequired,
    idField: string.isRequired,
    field: shape({}).isRequired,
    mobilePhone: string.isRequired,
    activeLanguage: string.isRequired,
    configs: shape({}).isRequired,
    country: string,
    hideLabel: bool,
    editing: bool.isRequired,
    value: string.isRequired,
    onChangeCallback: func,
};

PhoneInput.defaultProps = {
    country: "ar",
    hideLabel: false,
    onChangeCallback: null,
};

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    configs: configSelectors.getConfig(state),
});

export default compose(
    connect(mapStateToProps),
    withFocus,
    formField({
        formClass: "form-group--composite",
        isEmptyValue: (value) => !value,
        renderLegend: true,
    }),
)(PhoneInput);
