import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";

class MobileFilters extends Component {
    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(loansActions.readLoan(rest.match.params.id));
    }

    handleClick = (selectedPredefinedFilter) => {
        const { dispatch } = this.props;
        dispatch(loansActions.setSelectedFilter(selectedPredefinedFilter));
        this.goBack();
    };

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <Fragment>
                <Head onClose={this.goBack} title="accounts.movements.filters" />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout">
                            <Grid>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col">
                                        <Button
                                            className="btn btn-link"
                                            key="all"
                                            label="loans.statements.filters.all"
                                            onClick={() => this.handleClick("allFees")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="paidFees"
                                            label="loans.statements.filters.paidFees"
                                            onClick={() => this.handleClick("paidFees")}
                                        />
                                        <Button
                                            className="btn btn-link"
                                            key="pendingFees"
                                            label="loans.statements.filters.pendingFees"
                                            onClick={() => this.handleClick("pendingFees")}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: loansSelectors.getSelectedLoan(state),
});

export default connect(mapStateToProps)(MobileFilters);
