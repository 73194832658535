import React from "react";
import Image from "pages/_components/Image";

import I18n from "pages/_components/I18n";

const Overlay = ({ ...draggableItemProps }) => (
    <div className="overlay" {...draggableItemProps}>
        <Image src="images/drag.svg" />
        <I18n id="desktop.widgets.drag.label" componentProps={{ className: "overlay-message" }} />
    </div>
);

export default Overlay;
