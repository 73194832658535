import React from "react";
import { arrayOf, bool, func, oneOfType, string, shape, any } from "prop-types";
import flowRight from "lodash/flowRight";
import withFocus from "pages/_components/withFocus";

import formField from "pages/forms/_components/_fields/_commons/formField";
import CheckboxGroup from "pages/forms/_components/_fields/_commons/CheckboxGroup";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Select from "pages/forms/_components/_fields/Select";

class Selector extends React.Component {
    static propTypes = {
        optionList: arrayOf(shape({ id: string, label: string })).isRequired,
        idField: string.isRequired,
        renderAs: string.isRequired,
        defaultValue: oneOfType([string, arrayOf(any)]).isRequired,
        setValue: func.isRequired,
        editing: bool.isRequired,
        value: oneOfType([string, arrayOf(any), shape({})]),
        placeholder: string,
        name: string.isRequired,
        onChange: func,
        form: shape({}).isRequired,
        field: shape({}).isRequired,
        disabled: bool,
        toggleIsFocused: func.isRequired,
        labelI18n: string,
        inLineControl: bool,
        searchable: bool,
        additionalClassName: string,
        useShortLabel: bool,
    };

    static defaultProps = {
        value: "",
        placeholder: "",
        disabled: false,
        labelI18n: "",
        inLineControl: false,
        searchable: false,
        additionalClassName: "",
        useShortLabel: false,
        onChange: null,
    };

    componentDidMount() {
        const { defaultValue, value, setValue } = this.props;
        if (defaultValue !== null && !value) {
            setValue([defaultValue]);
        } else if (!value) {
            setValue([]);
        }
    }

    handleChange = (newValue) => {
        const { renderAs, setValue, onChange } = this.props;
        if (onChange) {
            onChange(newValue);
        }
        let selectedValues;
        if (renderAs === "combo") {
            if (newValue !== null && newValue !== undefined) {
                selectedValues = [newValue.id];
            }
        } else if (renderAs === "radio") {
            selectedValues = [newValue];
        } else {
            selectedValues = newValue;
        }

        setValue(selectedValues);
    };

    viewAsCheck() {
        const { optionList, value, disabled, additionalClassName } = this.props;
        const checkValue = value || [];
        return (
            <CheckboxGroup
                options={optionList}
                values={checkValue}
                onChange={this.handleChange}
                mode="view"
                disabled={disabled}
                additionalClassName={additionalClassName}
            />
        );
    }

    renderAsCombo() {
        const {
            optionList,
            value,
            placeholder,
            idField,
            disabled,
            toggleIsFocused,
            labelI18n,
            searchable,
            useShortLabel,
        } = this.props;
        const comboValue = value ? value[0] : "";

        return (
            <div className="input-group">
                <div style={{ flex: 1 }}>
                    <Select
                        id={idField}
                        placeholder={placeholder || labelI18n}
                        value={comboValue}
                        clearable={false}
                        searchable={searchable}
                        onChange={this.handleChange}
                        valueKey="id"
                        labelKey={useShortLabel ? "shortLabel" : "label"}
                        options={optionList}
                        className="flex-container slideFromBottom"
                        optionClassName="needsclick"
                        disabled={disabled}
                        onFocus={toggleIsFocused}
                        onBlur={toggleIsFocused}
                    />
                </div>
            </div>
        );
    }

    renderAsCheck() {
        const { optionList, value, name, disabled } = this.props;
        const checkValue = value || [];
        return (
            <CheckboxGroup
                options={optionList}
                values={checkValue}
                onChange={this.handleChange}
                name={name}
                disabled={disabled}
            />
        );
    }

    renderAsRadio() {
        const { optionList, value, idField, name, inLineControl, disabled } = this.props;
        const radioValue = value ? value[0] : "";

        return (
            <RadioButtonGroup
                value={radioValue}
                selectorId={idField}
                options={optionList}
                onChange={this.handleChange}
                name={name}
                inLineControl={inLineControl}
                disabled={disabled}
            />
        );
    }

    renderEditMode() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "check") {
            return this.renderAsCheck();
        }
        return this.renderAsRadio();
    }

    renderViewMode() {
        const { optionList, value, renderAs, form, field, additionalClassName } = this.props;

        if (renderAs === "check") {
            return this.viewAsCheck();
        }
        // Para tickets de activities transaccionales

        if (value && form.values[`${field.name}Label`]) {
            return (
                <ul>
                    <li key={value}>{form.values[`${field.name}Label`]}</li>
                </ul>
            );
        }
        const options = optionList.filter((option) => value.indexOf(option.id) !== -1);

        if (options.length === 0) {
            if (value.length > 0) {
                return (
                    <ul>
                        <li key={value[0]}>{value[0]}</li>
                    </ul>
                );
            }
            return null;
        }
        return (
            <ul className={additionalClassName || ""}>
                {options.map((option) => (
                    <li key={option.id}>{option.label}</li>
                ))}
            </ul>
        );
    }

    render() {
        const { editing } = this.props;
        if (editing) {
            return this.renderEditMode();
        }
        return this.renderViewMode();
    }
}

const options = () => {
    const { renderAs, additionalClassName } = this.props;
    const asLegend = renderAs === "radio";

    return {
        formClass: `form-group--select ${additionalClassName || null}`,
        isEmptyValue: (value) => value.length === 0,
        isValidValue: (value) => Array.isArray(value),
        renderLegend: asLegend,
    };
};

export default flowRight(withFocus, formField(options))(Selector);
