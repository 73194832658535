import { call, put, takeLatest } from "redux-saga/effects";

import * as loans from "middleware/loans";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { types, actions } from "reducers/loans";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LIST_LOANS_REQUEST, listLoansRequest),
    takeLatest(types.LOAD_MORE_LOANS_REQUEST, loadMoreLoansRequest),
    takeLatest(types.LOAN_DETAILS_REQUEST, loanDetails),
    takeLatest(types.LOAN_FETCH_MORE_STATEMENTS_REQUEST, fetchMoreStatements),
    takeLatest(types.LOAN_READ_REQUEST, loanRead),
    takeLatest(types.STATEMENT_READ_REQUEST, statementRead),
    takeLatest(types.DOWNLOAD_PAYMENT_REQUEST, downloadPayment),
    takeLatest(types.LIST_LOAN_TYPES_REQUEST, listLoanTypesRequest),
    takeLatest(types.LIST_LOANS_EXPIRING_SOON_REQUEST, listLoansExpiringSoonRequest),
];

export default sagas;

const defaultFilters = {
    status: "allFees",
};

function* listLoanTypesRequest({ loanType }) {
    const response = yield call(loans.listLoanTypes, loanType);
    if (response && response.status === 200) {
        const { loanTypesList } = response.data.data;
        yield put({
            type: types.LIST_LOAN_TYPES_SUCCESS,
            loanTypesList,
        });
    }
}

function* listLoansRequest({ filters }) {
    const response = yield call(loans.listLoans, {
        ...filters,
    });
    if (response && response.status === 200) {
        const { loansList, moreLoans, pageNumber, totalPages } = response.data.data;

        yield put(actions.listLoansSuccess(loansList, pageNumber, moreLoans, totalPages));
    }
}

function* loadMoreLoansRequest({ filters }) {
    const response = yield call(loans.listLoans, {
        ...filters,
    });
    if (response && response.status === 200) {
        const { loansList, moreLoans, pageNumber, totalPages } = response.data.data;

        yield put(actions.loadMoreLoansSuccess(loansList, pageNumber, moreLoans, totalPages));
    }
}
function* loanDetails({ selectedLoanId, filters = defaultFilters }) {
    const response = yield call(loans.listStatements, selectedLoanId, filters);

    if (response && response.status === 200) {
        yield put({
            type: types.LOAN_DETAILS_SUCCESS,
            ...response.data.data,
        });
    }
}

function* fetchMoreStatements({ loanData, filters = defaultFilters }) {
    const response = yield call(loans.listStatements, loanData, filters);

    if (response && response.status === 200) {
        const { statements, moreStatements, totalCount, pageNumber } = response.data.data;
        yield put(actions.fetchMoreStatementsSuccess(statements, moreStatements, totalCount, pageNumber));
    }
}

function* loanRead({ operationCode, operationBranch, operationOrder, idLoan, loanType }) {
    const readLoan = loanType === "history" ? loans.readHistoryLoan : loans.readActiveLoan;
    const response = yield call(readLoan, { operationCode, operationBranch, operationOrder, idLoan });

    if (response && response.status === 200) {
        yield put({
            type: types.LOAN_READ_SUCCESS,
            ...response.data.data,
        });
    }
}

function* statementRead({ operationCode, operationBranch, operationOrder, idStatement, feeType }) {
    const response = yield call(loans.readLoanStatement, {
        operationCode,
        operationBranch,
        operationOrder,
        feeNumber: idStatement,
        feeType,
    });

    if (response && response.status === 200) {
        yield put({
            type: types.STATEMENT_READ_SUCCESS,
            ...response.data.data,
        });
    } else {
        yield put({ type: types.STATEMENT_READ_FAILURE });
    }
}

function* downloadPayment({ idLoan, format }) {
    const { type, data } = yield call(loans.downloadPayment, idLoan, defaultFilters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_PAYMENT_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loan/details"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put(
            notificationModalActions.showNotification(i18n.get("loans.details.download.successMessage"), "success"),
        );

        yield put({ type: types.DOWNLOAD_PAYMENT_SUCCESS });
    }
}

function* listLoansExpiringSoonRequest() {
    const response = yield call(loans.listLoanExpiringSoon);
    if (response && response.status === 200) {
        const { loansExpiringSoon } = response.data.data;
        const showExpiringLoansModal = loansExpiringSoon?.length > 0;

        yield put({ type: types.LIST_LOANS_EXPIRING_SOON_SUCCESS, loansExpiringSoon, showExpiringLoansModal });

        if (showExpiringLoansModal) {
            yield call(loans.notifyLoansToExpire, loansExpiringSoon);
        }
    }
}
