import React, { Component } from "react";
import { bool, func } from "prop-types";
import SimpleModal from "components/SimpleModal/SimpleModal";

class ModifyTransaction extends Component {
    static propTypes = {
        modifyTransactionClick: func.isRequired,
        onClose: func.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { modifyTransactionClick, onClose, isDesktop } = this.props;

        return (
            <SimpleModal
                className="modify-transaction-modal"
                modalMainText="forms.modifyTransaction.Warning"
                modalIcon="images/ui-icons/ui-modify-warning.svg"
                isDisplayed
                closeModal={onClose}
                buttonAction={modifyTransactionClick}
                reverse={!isDesktop}
            />
        );
    }
}

export default ModifyTransaction;
