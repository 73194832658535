import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string, objectOf, any } from "prop-types";

import { types as checkTypes, actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import defaultDateFromList from "util/defaultDateFromList";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { validateToResetFilters } from "util/filters";
import List from "./List";
import ChecksFilters from "./Filters";

const ID_ACTIVITY = "checks.listChecks";

class CheckList extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        history: objectOf(any).isRequired,
        dispatch: func.isRequired,
        isResetQuery: bool.isRequired,
        filters: shape({
            checkType: string.isRequired,
        }).isRequired,
        idActivityOnFilter: string.isRequired,
        showFilters: bool.isRequired,
    };

    componentWillMount = () => {
        const { isResetQuery, filters } = this.props;

        this.handleTabClick(isResetQuery ? "emitted" : filters.checkType);
    };

    handleTabClick = (checkType) => {
        const { dispatch, isResetQuery, idActivityOnFilter } = this.props;

        dispatch(filterActions.validateToResetFilters(checkTypes.RESET_FILTERS, ID_ACTIVITY));

        if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
            dispatch(
                checksActions.setFilters({
                    checkType,
                    dateFrom: defaultDateFromList().checks.dateFrom,
                    dateTo: defaultDateFromList().checks.dateTo,
                }),
            );
            const defaultFilters = {
                checkType,
                state: "",
                minAmount: null,
                maxAmount: null,
                checkNumber: "",
                dateFrom: defaultDateFromList().checks.dateFrom,
                dateTo: defaultDateFromList().checks.dateTo,
                pageNumber: 1,
            };
            dispatch(checksActions.loadListRequest(defaultFilters));
        }
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }
        return <Head accessibilityTextId="menu.checks.listChecks" showPlusIcon />;
    };

    renderFilters = () => {
        const { isMobile, filters, showFilters } = this.props;
        const { checkType } = filters;
        if (isMobile) {
            return showFilters && <ChecksFilters idForm={ID_ACTIVITY} checkType={checkType} isMobile />;
        }

        return <ChecksFilters idForm={ID_ACTIVITY} checkType={checkType} />;
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    render() {
        const { isDesktop, history, isMobile, filters, showFilters } = this.props;
        const { checkType } = filters;

        return (
            <>
                <Notification scopeToShow="checksList" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="title-left-header">
                        <I18n id="menu.checks.listChecks" component="h1" />
                    </div>
                    <div className="container-left">
                        <>
                            <div className="tabs-wrapper">
                                <Button
                                    label={`${ID_ACTIVITY}.emitted.label`}
                                    onClick={() => this.handleTabClick("emitted")}
                                    bsStyle="outline"
                                    className={`${checkType === "emitted" ? "active" : ""}`}
                                />
                                <Button
                                    label={`${ID_ACTIVITY}.received.label`}
                                    onClick={() => this.handleTabClick("received")}
                                    bsStyle="outline"
                                    className={`${checkType === "received" ? "active" : ""}`}
                                />
                            </div>

                            {isMobile && (
                                <Button
                                    onClick={() => this.onToggleFilters()}
                                    bsStyle="filter"
                                    className="btn-link"
                                    block
                                    image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                                    label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                                />
                            )}

                            {this.renderFilters()}

                            <List
                                history={history}
                                isMobile={isMobile}
                                idActivity={ID_ACTIVITY}
                                isDesktop={isDesktop}
                                checkType={checkType}
                                showFilters={showFilters}
                            />
                        </>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isResetQuery: filterSelectors.isResetFilters(state),
    filters: checksSelectors.getFilters(state),
    showFilters: filterSelectors.getShowFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
});

export default connect(mapStateToProps)(CheckList);
