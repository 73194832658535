import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "widgets/LIST_REQUEST",
    LIST_FAILURE: "widgets/LIST_FAILURE",
    LIST_SUCCESS: "widgets/LIST_SUCCESS",
    DELETE_WIDGET: "widgets/DELETE_WIDGET",
    RESET_WIDGET: "widgets/RESET_WIDGET",
};

export const INITIAL_STATE = {
    accounts: {
        isFetching: false,
        data: { accounts: [] },
    },
    creditCards: {
        isFetching: false,
        data: { creditCards: [] },
    },
    exchangeRates: {
        isFetching: false,
        data: { rates: [] },
    },
    portfolio: {
        isFetching: false,
        data: undefined,
    },
    loans: {
        isFetching: false,
        data: { loans: [] },
    },
    notifications: {
        isFetching: false,
        data: undefined,
    },
    pendingTransactions: {
        isFetching: false,
        data: { pendingTransactions: [] },
    },
    scheduledTransactions: {
        isFetching: false,
        data: { scheduledTransactions: [] },
    },
    fixedTermDeposits: {
        isFetching: false,
        data: { fixedTermDeposits: [] },
    },
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: true,
        },
    }),
    [types.LIST_FAILURE]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: false,
        },
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        [action.widget]: {
            data: action.data,
            isFetching: false,
        },
    }),
    [types.DELETE_WIDGET]: (state, action) => ({
        ...state,
        [action.widget.id]: {
            ...INITIAL_STATE[action.widget.id],
        },
    }),
    [types.RESET_WIDGET]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...INITIAL_STATE[action.widget],
        },
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "widget"),
    listFailure: makeActionCreator(types.LIST_FAILURE, "widget"),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "widget", "data"),
    deleteWidget: makeActionCreator(types.DELETE_WIDGET, "widget"),
    resetWidget: makeActionCreator(types.RESET_WIDGET, "widget"),
};

export const selectors = {
    getWidget: ({ widgets }, name) => widgets[name],
};
