import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { string, func, bool, shape } from "prop-types";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";

class MenuFormItem extends Component {
    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        dispatch: func.isRequired,
        link: string.isRequired,
        name: string.isRequired,
        onClick: func,
        hasPermission: bool,
        isMobile: bool.isRequired,
        hasTransferInternalAccounts: bool,
        featureEnabled: bool,
    };

    static defaultProps = {
        hasPermission: true,
        hasTransferInternalAccounts: true,
        featureEnabled: true,
        onClick: null,
    };

    isSelected = (location, link) => {
        let aux = false;
        if (location.pathname === link) {
            aux = true;
        }
        return aux;
    };

    render() {
        const {
            link,
            name,
            hasPermission,
            featureEnabled,
            hasTransferInternalAccounts,
            location,
            onClick,
        } = this.props;

        let tooltipData;
        if (!hasPermission) {
            tooltipData = i18nUtils.get("returnCode.COR005E");
        } else if (!featureEnabled) {
            tooltipData = i18nUtils.get("menu.feature.disabled");
        } else if (!hasTransferInternalAccounts) {
            tooltipData = i18nUtils.get("transferInternal.disabled");
        }

        if (tooltipData) {
            const tooltipMobileProps = {
                "data-tooltip": tooltipData,
                "data-tooltip-position": "top",
            };

            return (
                <a className="disabled" role="menuitem" {...tooltipMobileProps}>
                    <span>{name}</span>
                </a>
            );
        }
        return (
            <NavLink
                to={link}
                activeClassName=""
                role="menuitem"
                onClick={onClick}
                className={classNames({
                    active: this.isSelected(location, link),
                })}>
                <span>{name}</span>
            </NavLink>
        );
    }
}

export default withRouter(connect()(MenuFormItem));
