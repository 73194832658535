import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { any, bool, func, instanceOf, objectOf, shape, string } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import DatePicker from "pages/_components/fields/datepicker";
import * as i18n from "util/i18n";
import FieldHelp from "./FieldHelp";

export class DateField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        form: shape({ errors: objectOf(string), touched: objectOf(any) }).isRequired,
        handleChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        endDate: instanceOf(moment),
        maxDate: instanceOf(moment),
        minDate: instanceOf(moment),
        dateFormat: string,
        idForm: string,
        showDisabledMonthNavigation: bool,
        startDate: instanceOf(moment),
        showYearDropdown: bool,
        showMonthDropdown: bool,
        idField: string.isRequired,
        resetFilters: bool,
        handleResetDateFilters: func,
        exceptionDatePicker: bool,
        messageHelp: string,
        availableAllDayofWeek: bool,
        labelText: string,
        defaultValue: instanceOf(moment),
    };

    static defaultProps = {
        handleChange: () => {},
        hideLabel: false,
        hidePlaceholder: false,
        maxDate: moment(),
        minDate: moment().add(-6, "months"),
        dateFormat: null,
        endDate: null,
        idForm: "",
        showDisabledMonthNavigation: true,
        startDate: null,
        showYearDropdown: false,
        showMonthDropdown: false,
        resetFilters: false,
        handleResetDateFilters: null,
        exceptionDatePicker: false,
        messageHelp: null,
        availableAllDayofWeek: false,
        labelText: null,
        defaultValue: null,
    };

    state = {
        isFocused: false,
    };

    validateDate = (selectedDate) => {
        const { maxDate, minDate } = this.props;
        if (
            selectedDate &&
            minDate &&
            selectedDate.diff(moment(minDate)) < 0 &&
            !selectedDate.isSame(moment(minDate), "day")
        ) {
            return false;
        }

        if (selectedDate && maxDate && selectedDate.diff(moment(maxDate) >= 0)) {
            return false;
        }

        return true;
    };

    validateWeekend = (selectedDate) => selectedDate.day() !== 0 && selectedDate.day() !== 6;

    handleChange = (selectedDate) => {
        const { field, form, handleChange, idForm } = this.props;
        if (!this.validateDate(selectedDate)) {
            return;
        }

        if (idForm && idForm === "scheduler" && !this.validateWeekend(selectedDate)) {
            return;
        }

        if (handleChange) {
            handleChange(selectedDate);
        }

        form.setFieldValue(field.name, selectedDate ? selectedDate.toDate() : null);
    };

    toggleFocusInput = () => {
        const { isFocused } = this.state;
        this.setState({
            isFocused: !isFocused,
        });
    };

    renderFieldLabel = () => {
        const { hideLabel, labelText, idForm, field } = this.props;
        if (!hideLabel) {
            const labelTextCustom = !labelText ? i18n.get(`${idForm}.${field.name}.label`) : labelText;
            return <FieldLabel labelText={labelTextCustom} idField={field.name} />;
        }
        return null;
    };

    render() {
        const { isFocused } = this.state;
        const {
            endDate,
            field,
            form: { touched, errors, setFieldValue },
            hideLabel,
            hidePlaceholder,
            idForm,
            maxDate,
            minDate,
            showDisabledMonthNavigation,
            startDate,
            dateFormat,
            idField,
            resetFilters,
            handleResetDateFilters,
            exceptionDatePicker,
            messageHelp,
            availableAllDayofWeek,
            defaultValue,
            ...rest
        } = this.props;

        if (resetFilters) {
            handleResetDateFilters(false);
            setFieldValue(field.name, null);
        }

        const hasError = touched && touched[field.name] && errors && errors[field.name];
        return (
            <div
                className={classNames("form-group", "form-group--datepicker", {
                    "has-error": hasError,
                    "has-focus": isFocused,
                    "has-help": messageHelp,
                })}>
                {this.renderFieldLabel()}
                <div className="input-group">
                    <div className="form-control">
                        <DatePicker
                            dateFormat={dateFormat === null ? i18n.get("datepicker.format") : dateFormat}
                            className="form-control"
                            endDate={endDate ? moment(endDate) : undefined}
                            maxDate={maxDate ? moment(maxDate) : undefined}
                            minDate={minDate ? moment(minDate) : undefined}
                            placeholderText={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                            selected={(field.value && moment(field.value)) || null}
                            showDisabledMonthNavigation={showDisabledMonthNavigation}
                            startDate={startDate ? moment(startDate) : undefined}
                            onChange={this.handleChange}
                            id={field.name}
                            autoComplete="off"
                            onBlur={() => this.toggleFocusInput()}
                            onFocus={() => this.toggleFocusInput()}
                            isClearable={false}
                            exceptionDatePicker={exceptionDatePicker}
                            availableAllDayofWeek={availableAllDayofWeek}
                            defaultValue={defaultValue}
                            {...rest}
                        />
                    </div>
                </div>
                {hasError && <FieldError error={errors[field.name]} />}

                {messageHelp && <FieldHelp text={messageHelp} />}
            </div>
        );
    }
}

export default DateField;
