import React, { Component } from "react";
import { node, string, shape } from "prop-types";

import TableData from "pages/_components/table/Data";

class TableHeaderData extends Component {
    static propTypes = {
        children: node,
        align: string,
        styles: shape({}),
    };

    static defaultProps = {
        children: null,
        align: "center",
        styles: {},
    };

    render() {
        const { children, styles, ...props } = this.props;
        return (
            <TableData className="table-data-head" styles={styles} {...props}>
                {children}
            </TableData>
        );
    }
}

export default TableHeaderData;
