import * as API from "middleware/api";
import { format } from "date-fns";

export const listLoans = (filters) => {
    let { dateFrom, dateTo } = filters;
    const { pageNumber, loanType, operationType } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;
    return API.executeWithAccessToken("/loans.list", {
        loanType,
        dateFrom,
        dateTo,
        pageNumber,
        codeSIOC: operationType ? operationType[0] : null,
    });
};

export const listLoanExpiringSoon = () => API.executeWithAccessToken("/loans.expiringSoon.list", {});
export const notifyLoansToExpire = (loansComingDue) =>
    API.executeWithAccessToken("/loans.expiringSoon.notify", {
        loansComingDue,
    });

export const listLoanTypes = (loanType) =>
    API.executeWithAccessToken("/loans.selectorTypes.list", {
        loanType,
    });

export const listStatements = (loanData, filters) => {
    const { state, pageNumber } = filters;
    const { operationCode, operationBranch, operationOrder } = loanData;
    return API.executeWithAccessToken("/loans.statements.list", {
        state,
        pageNumber,
        operationCode,
        operationBranch,
        operationOrder,
    });
};

export const readHistoryLoan = ({ operationCode, operationBranch, operationOrder }) =>
    API.executeWithAccessToken("/loans.history.read", { operationCode, operationBranch, operationOrder });

export const readActiveLoan = ({ operationCode, operationBranch, operationOrder, idLoan }) =>
    API.executeWithAccessToken("/loans.active.read", { operationCode, operationBranch, operationOrder, idLoan });

export const readLoanStatement = ({ operationCode, operationBranch, operationOrder, feeNumber, feeType }) =>
    API.executeWithAccessToken("/loans.statement.read", {
        operationCode,
        operationBranch,
        operationOrder,
        feeNumber,
        feeType,
    });

export const downloadPayment = (idLoan, { ...filters }, fileFormat) => {
    const { idProduct, operationCode, operationBranch, operationOrder } = idLoan;

    return API.executeWithAccessToken("/loans.downloadPayment", {
        idLoan: idProduct,
        operationCode,
        operationBranch,
        operationOrder,
        format: fileFormat,
        ...filters,
    });
};
