import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import Yup from "yup";
import { string, func, shape } from "prop-types";
import Row from "react-bootstrap/lib/Row";

import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import * as i18nUtils from "util/i18n";
import * as paymentUtils from "util/massivePayments";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Select from "pages/_components/fields/Select";
import AmountField from "pages/_components/fields/formik/AmountField";
import Button from "pages/_components/Button";

const FORM_ID = "salaryPayment.manual";

export function validationSchema() {
    return Yup.object().shape({
        creditAccountNumber: Yup.string()
            .trim()
            .required(i18nUtils.get("salaryPayment.manual.creditAccountNumber.required.error")),
        creditAccountName: Yup.string()
            .trim()
            .required(i18nUtils.get("salaryPayment.manual.creditAccountName.required.error")),
        credit: Yup.object().shape({
            currency: Yup.string().required(),
            amount: Yup.number().transform((currentValue) => (Number.isNaN(currentValue) ? undefined : currentValue)),
        }),
    });
}

class PaymentLinesForm extends Component {
    static propTypes = {
        paymentCurrency: string.isRequired,
        onSubmit: func.isRequired,
        submitButtonLabel: string,
        cancelButtonHandler: func.isRequired,
        initialValues: shape({
            creditAccountNumber: string.isRequired,
            creditAccountName: string.isRequired,
            credit: shape({
                currency: string.isRequired,
                amount: string.isRequired,
            }),
        }).isRequired,
    };

    static defaultProps = {
        submitButtonLabel: "global.accept",
    };

    numInput = React.createRef();

    componentDidMount() {
        this.numInput.current.focus();
    }

    handleSubmit = (
        {
            creditAccountNumber,
            creditAccountName,
            credit: { currency: creditAmountCurrency, amount: creditAmountQuantity },
            bankIdentifier,
        },
        { resetForm },
    ) => {
        this.numInput.current.focus();

        const { onSubmit } = this.props;
        const amount = creditAmountQuantity !== "" ? creditAmountQuantity : 0;

        onSubmit({
            creditAccountNumber,
            creditAccountName,
            creditAmountCurrency,
            creditAmountQuantity: amount,
            bankIdentifier,
        });

        resetForm();
    };

    render() {
        const { paymentCurrency, initialValues, submitButtonLabel, cancelButtonHandler } = this.props;

        return (
            <Formik
                onSubmit={this.handleSubmit}
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}>
                {() => (
                    <Form>
                        <Row>
                            <Container.Column sm={12} md={6}>
                                <Field
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="creditAccountNumber"
                                    inputRef={this.numInput}
                                />
                            </Container.Column>

                            <Container.Column sm={12} md={6}>
                                <Field component={TextField} idForm={FORM_ID} name="creditAccountName" />
                            </Container.Column>
                            <Container.Column sm={12} md={6}>
                                <Field
                                    optional={i18nUtils.get("form.field.optional")}
                                    component={AmountField}
                                    idForm={FORM_ID}
                                    name="credit"
                                    maxLength="15"
                                    data={{
                                        options: [
                                            {
                                                id: paymentCurrency,
                                                label: i18nUtils.get(`currency.label.${paymentCurrency}`),
                                            },
                                        ],
                                    }}
                                    searchable={false}
                                    clearable={false}
                                />
                            </Container.Column>
                            <Container.Column sm={12} md={6}>
                                <Select
                                    idForm={FORM_ID}
                                    name="bankIdentifier"
                                    placeholder={i18nUtils.get("salaryPayment.manual.creditAccountBank.placeholder")}
                                    label={i18nUtils.get("salaryPayment.manual.creditAccountBank.label")}
                                    options={paymentUtils.retrieveBankOptions("")}
                                    searchable={false}
                                    optionClassName="needsclick"
                                />
                            </Container.Column>
                            <Container.Column sm={12}>
                                <div className="form-group">
                                    <Button
                                        bsStyle="primary"
                                        label={submitButtonLabel}
                                        type="submit"
                                        block={false}
                                        style={{ marginRight: "1rem" }}
                                    />
                                    <Button
                                        className="btn-outline"
                                        label="global.cancel"
                                        onClick={cancelButtonHandler}
                                        block={false}
                                    />
                                </div>
                            </Container.Column>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    paymentCurrency: transactionLinesSelectors.getCurrency(state),
});

export default connect(mapStateToProps)(PaymentLinesForm);
