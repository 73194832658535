import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import Compose from "pages/communications/_components/Compose";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";

class ReplyMessage extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape().isRequired,
        detail: shape().isRequired,
        history: shape().isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(communicationActions.detailRequest(match.params.id));
    }

    handleBackClick = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { detail, isDesktop } = this.props;

        if (!detail) {
            return null;
        }

        return (
            <>
                {!isDesktop ? (
                    <Fragment>
                        <Notification scopeToShow="communications" />
                        <Head showLogo additionalClassName="blue-main-header-mobile" />
                        <MainContainer>
                            <Compose isReply />
                        </MainContainer>
                    </Fragment>
                ) : (
                    this.handleBackClick()
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
});

export default connect(mapStateToProps)(ReplyMessage);
