import React, { Component } from "react";
import { node, string, shape } from "prop-types";
import classNames from "classnames";

class TableData extends Component {
    static propTypes = {
        children: node,
        align: string,
        className: string,
        styles: shape({}),
    };

    static defaultProps = {
        children: null,
        align: "center",
        className: "",
        styles: {},
    };

    render() {
        const { children, align, className, styles } = this.props;
        return (
            <div className={classNames("table-data", `ui-text-${align}`, className)} style={styles}>
                {children}
            </div>
        );
    }
}

export default TableData;
