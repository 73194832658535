import React from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import { push } from "react-router-redux/actions";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";

const AddFrequentRecipientButton = ({
    dispatch,
    isMobile,
    className,
    activeEnvironment,
    urlOrigin,
    cuit,
    destinationType,
    destinationIdentifierS,
}) => {
    const hasPermission = !!activeEnvironment?.permissions.frequentDestinations;

    const tooltipPropsPermission = hasPermission
        ? {}
        : {
              "data-tooltip": i18nUtils.get("forms.transfers.tooltip.permissions.frequentDestinations"),
              "data-tooltip-position": `${isMobile ? "bottom" : "right"}`,
          };

    const goToAddFrequentRecipientForm = () => {
        if (hasPermission) {
            dispatch(push("/recipients/search"));
            dispatch(
                formActions.setData({
                    cuit,
                    destinationType,
                    destinationIdentifierS,
                    urlOrigin,
                }),
            );
        }
    };

    return (
        <div className={`form-group ui-mb-0 ${className}`}>
            <Button
                block={isMobile}
                label="forms.frequentDestination.newAddressBook.label"
                bsStyle="outline"
                image="images/plus.svg"
                onClick={() => goToAddFrequentRecipientForm()}
                disabled={!hasPermission}
                {...tooltipPropsPermission}
            />
        </div>
    );
};

AddFrequentRecipientButton.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    activeEnvironment: shape({}).isRequired,
    cuit: string.isRequired,
    destinationType: shape([]).isRequired,
    destinationIdentifierS: string.isRequired,
    urlOrigin: string.isRequired,
    className: string,
};

AddFrequentRecipientButton.defaultProps = {
    className: "",
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AddFrequentRecipientButton);
