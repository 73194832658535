import React, { Component } from "react";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { string, shape, bool, func } from "prop-types";
import I18n from "pages/_components/I18n";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";
import Button from "pages/_components/Button";
import { actions as echeqsActions } from "reducers/echeqs";

class EcheqGridItem extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        echeq: shape({}).isRequired,
        idForm: string.isRequired,
        emissionType: string.isRequired,
        dispatch: func.isRequired,
        showProcessedEcheqItems: bool.isRequired,
        mode: bool.isRequired,
        setFieldsAfterRemove: func,
        setFieldValue: func.isRequired,
        disableComponents: bool.isRequired,
    };

    static defaultProps = {
        setFieldsAfterRemove: null,
    };

    handleRemoveEcheq = () => {
        const { dispatch, echeq, setFieldsAfterRemove, setFieldValue } = this.props;
        dispatch(
            echeqsActions.removePersonalizedEcheqLineRequest(echeq.lineNumber, setFieldsAfterRemove, setFieldValue),
        );
    };

    renderEcheqsMobile = () => {
        const { echeq, idForm, emissionType, mode, disableComponents } = this.props;
        const {
            cheque_numero,
            numero_chequera,
            emisor_moneda,
            monto,
            fecha_pago,
            cheque_caracter,
            referencia,
            cheque_tipo,
            showProcessedEcheqItems,
        } = echeq;
        return (
            <GenericItem
                customOption={
                    mode !== "preview" &&
                    !showProcessedEcheqItems && (
                        <Button
                            type="button"
                            className="btn-only-icon btn-delete"
                            image="images/ui-icons/ui-delete.svg"
                            block={false}
                            onClick={this.handleRemoveEcheq}
                            disabled={disableComponents}
                        />
                    )
                }>
                {cheque_numero && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${idForm}.echeqNumber.label`} />
                        </div>
                        <span className="data-text">{cheque_numero}</span>
                    </div>
                )}

                {numero_chequera && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${idForm}.checkbookNumber.label`} />
                        </div>
                        <span className="data-text">{numero_chequera}</span>
                    </div>
                )}

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idForm}.amount.label`} />
                    </div>
                    <FormattedAmount currency={emisor_moneda || "ARS"} quantity={monto} />
                </div>

                {emissionType === "personalized" && !showProcessedEcheqItems && (
                    <div className="data-wrapper">
                        <div className="data-label">
                            <I18n id={`${idForm}.echeqType.tableHeader.label`} />
                        </div>
                        <div className="data-text">
                            <I18n id={`${idForm}.echeqType.${cheque_tipo}.label`} />
                        </div>
                    </div>
                )}

                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id={`${idForm}.paymentDate.label`} />
                    </div>
                    <div className="data-text">
                        <FormattedDate date={fecha_pago.substring(0, 10)} />
                        {emissionType === "personalized" && showProcessedEcheqItems && (
                            <I18n id={`forms.echeqs.emitEcheq.echeqType.${cheque_tipo}.label`} />
                        )}
                    </div>
                </div>

                {emissionType === "personalized" && (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idForm}.character.label`} />
                            </div>
                            <span className="data-text">
                                <I18n id={`${idForm}.character.${cheque_caracter}.label`} />
                            </span>
                        </div>

                        <div className="data-wrapper">
                            <div className="data-label">
                                <I18n id={`${idForm}.reference.label`} />
                            </div>
                            <span className="data-text">{referencia}</span>
                        </div>
                    </>
                )}
            </GenericItem>
        );
    };

    render() {
        const { isDesktop, echeq, emissionType, showProcessedEcheqItems, idForm, disableComponents } = this.props;
        const {
            cheque_numero,
            numero_chequera,
            emisor_moneda,
            monto,
            fecha_pago,
            cheque_caracter,
            referencia,
            cheque_tipo,
        } = echeq;

        return isDesktop ? (
            <>
                {cheque_numero && (
                    <Table.Data align="left">
                        <span>{cheque_numero}</span>
                    </Table.Data>
                )}

                {numero_chequera && (
                    <Table.Data align="left">
                        <span>{numero_chequera}</span>
                    </Table.Data>
                )}

                <Table.Data align="center">
                    <FormattedAmount currency={emisor_moneda} quantity={monto} />
                </Table.Data>

                {!showProcessedEcheqItems && (
                    <Table.Data align="left">
                        <I18n id={`${idForm}.echeqType.${cheque_tipo}.label`} />
                    </Table.Data>
                )}

                <Table.Data align="left">
                    <span>{fecha_pago && <FormattedDate date={fecha_pago.substring(0, 10)} />}</span>
                    {emissionType === "personalized" && showProcessedEcheqItems && (
                        <div>
                            <I18n id={`${idForm}.echeqType.${cheque_tipo}.label`} />
                        </div>
                    )}
                </Table.Data>

                {emissionType === "personalized" && (
                    <>
                        <Table.Data align="left">
                            <span>
                                <I18n id={`${idForm}.character.${cheque_caracter}.label`} />
                            </span>
                        </Table.Data>
                        <Table.Data align="left">
                            <span>{referencia}</span>
                        </Table.Data>
                    </>
                )}

                {!showProcessedEcheqItems && (
                    <Table.Data align="right">
                        <Button
                            type="button"
                            className="btn-only-icon btn-delete"
                            image="images/ui-icons/ui-delete.svg"
                            onClick={this.handleRemoveEcheq}
                            block
                            disabled={disableComponents}
                        />
                    </Table.Data>
                )}
            </>
        ) : (
            this.renderEcheqsMobile()
        );
    }
}

export default EcheqGridItem;
