import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bool, number, string } from "prop-types";

import { resizableRoute } from "pages/_components/Resizable";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import GeneralMsg from "pages/_components/GeneralMsg";
import Image from "pages/_components/Image";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";

const ServerError = ({ hasActiveSession, lang, timesConfigFailed, timesI18nFailed }) => {
    const errorTitle = {
        es: "Estamos realizando tareas de mantenimiento en los servidores.",
        en: "We are performing maintenance tasks on the servers.",
        pt: "Estamos realizando tarefas de manutenção nos servidores.",
    };

    const errorDescription = {
        es: `Por favor, intentá ingresar nuevamente al Online Banking más tarde.`,
        en: "Please try to access Online Banking again later.",
        pt: "Por favor, tente fazer login novamente para o Internet Banking mais tarde.",
    };

    if (timesConfigFailed === 0 && timesI18nFailed === 0) {
        return hasActiveSession ? <Redirect to="/desktop" /> : <Redirect to="/" />;
    }

    return (
        <ErrorBoundary>
            <div className="error-page-container">
                <GeneralMsg title={errorTitle[lang]} imagePath="images/coloredIcons/no-connection.svg" />
                <GeneralMsg title={errorDescription[lang]} />
                <Link className="navbar-brand" to="/desktop">
                    <Image src="images/logoMariva.svg" className="svg-big-ico" />
                </Link>
            </div>
        </ErrorBoundary>
    );
};

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    lang: i18nSelectors.getLang(state),
    timesConfigFailed: configSelectors.getTimesFailed(state),
    timesI18nFailed: i18nSelectors.getTimesFailed(state),
});

export default connect(mapStateToProps)(resizableRoute(ServerError));

ServerError.propTypes = {
    hasActiveSession: bool,
    lang: string,
    timesConfigFailed: number,
    timesI18nFailed: number,
};

ServerError.defaultProps = {
    hasActiveSession: false,
    lang: "es",
    timesConfigFailed: 0,
    timesI18nFailed: 0,
};
