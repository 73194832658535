import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { number, shape, func, bool, string } from "prop-types";
import { routerActions } from "react-router-redux/actions";

import { actions as filterActions } from "reducers/filters";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as loansSelectors, actions as loansActions } from "reducers/loans";
import I18n from "pages/_components/I18n";

import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import ProductToolbar from "pages/_components/ProductToolbar";
import Action from "pages/loans/_components/Action";
import DetailHeadInfo from "pages/loans/_components/DetailHeadInfo";
import DetailHeadTitle from "pages/loans/_components/DetailHeadTitle";
import Information from "pages/loans/_components/Information";
import Statements from "pages/loans/Statements";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

class Details extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        loan: shape({
            idProduct: string,
            currency: string,
        }),
        rest: shape().isRequired,
        match: shape().isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        fetchingDownload: func.isRequired,
        isLoanRequest: bool.isRequired,
        totalCount: number.isRequired,
        loanType: string.isRequired,
        fetchingStatements: bool.isRequired,
        loanProductSelected: shape().isRequired,
        history: shape({}).isRequired,
        loadFilters: shape({}).isRequired,
    };

    static defaultProps = {
        loan: null,
    };

    state = {
        selectedFilter: "V",
    };

    componentDidMount() {
        const { loan, dispatch, loanType, loadFilters, ...rest } = this.props;
        const { id, code, branch, order } = rest.match.params;
        const loanData = { operationCode: code, operationBranch: branch, operationOrder: order };

        if (!loan || loan?.operationCode !== rest.match.params.code) {
            dispatch(loansActions.readLoan(code, branch, order, id, loanType));
        } else {
            dispatch(loansActions.loadedLoan());
        }
        if (loanType === "history") {
            this.setState({ selectedFilter: "T" });
            const filtersHistory = { pageNumber: "1", state: "T" };
            dispatch(loansActions.fetchMoreStatements(loanData, filtersHistory));
        } else {
            dispatch(loansActions.fetchMoreStatements(loanData, loadFilters));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(loansActions.resetStatements());
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(loansActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { dispatch, ...rest } = this.props;
        const { selectedFilter } = this.state;
        this.setState({ selectedFilter: id });

        const filters = { state: id };
        const { code, branch, order } = rest.match.params;
        const loanData = { operationCode: code, operationBranch: branch, operationOrder: order };
        if (selectedFilter !== id) {
            dispatch(loansActions.resetStatements());
        }
        dispatch(loansActions.fetchMoreStatements(loanData, filters));
    };

    toolbarChildren = () => {
        const { isDesktop } = this.props;
        return (
            <>
                <Action
                    isDesktop={isDesktop}
                    labelKey="loans.request"
                    to="/form/requestLoan"
                    className={!isDesktop ? "dropdown__item-btn" : ""}
                />
            </>
        );
    };

    handleBack = () => {
        const { dispatch, history } = this.props;
        dispatch(loansActions.closeOptions());
        dispatch(filterActions.setResetFilters(false));
        history.goBack();
    };

    handleClickDownload = (format) => () => {
        const { dispatch, loanProductSelected } = this.props;
        dispatch(loansActions.downloadPayment(loanProductSelected, format));
    };

    /* eslint-disable */
    handleDetailClick = (loan) => {
        // es-lint disable
        const { operationCode, operationBranch, operationOrder } = loan;
        const { dispatch } = this.props;
        dispatch(routerActions.push(`/loans/1/${operationCode}/${operationBranch}/${operationOrder}/statement`));
    };

    renderDisclaimers = () => {
        const {
            loan: { rateType, codeSIOC },
        } = this.props;
        return (
            <DisclaimerContainer textSmall className="ui-mt-8">
                <I18n id="loan.detail.fees.detail.disclaimer.general.label" component="p" />
                {rateType === "VARIABLE" && (
                    <I18n id="loan.detail.fees.detail.disclaimer.variableRate.label" component="p" />
                )}
                {(codeSIOC === "MINAGD" || codeSIOC === "MINAGD2") && (
                    <I18n id="loan.detail.fees.detail.disclaimer.MINAGD.label" component="p" />
                )}
                {(codeSIOC === "COCAMV" || codeSIOC === "COCAMC") && (
                    <I18n id="loan.detail.fees.detail.disclaimer.COCAM.label" component="p" />
                )}
                {codeSIOC === "PRE990" && <I18n id="loan.detail.fees.detail.disclaimer.PRE990.label" component="p" />}
            </DisclaimerContainer>
        );
    };

    mobileMenuOptions = () => {
        const { isDesktop, isLoanRequest } = this.props;

        let options = [];

        isLoanRequest &&
            options.push(
                <Action
                    isDesktop={isDesktop}
                    labelKey="loans.request"
                    to="/form/requestLoan"
                    className={!isDesktop ? "dropdown__item-btn" : ""}
                />,
            );

        return options.length ? options : null;
    };

    render() {
        const { selectedFilter } = this.state;
        const {
            loan,
            loanType,
            isDesktop,
            dispatch,
            fetching,
            fetchingDownload,
            fetchingStatements,
            isLoanRequest,
            totalCount,
            ...rest
        } = this.props;
        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "V",
                })}
                key="pendingFees"
                label="loan.detail.filters.V"
                onClick={() => this.handleFilterButtonsClick("V")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "P",
                })}
                key="cancelledFees"
                label="loan.detail.filters.P"
                onClick={() => this.handleFilterButtonsClick("P")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "T",
                })}
                key="allFees"
                label="loan.detail.filters.T"
                onClick={() => this.handleFilterButtonsClick("T")}
            />,
        ];

        const showDetailButton =
            loan?.codeSIOC !== "MINAGD" &&
            loan?.codeSIOC !== "MINAGD2" &&
            loan?.codeSIOC !== "COCAMV" &&
            loan?.codeSIOC !== "COCAMC";

        return (
            <PageLoading loading={fetching}>
                {!fetching && loan && (
                    <Fragment>
                        <Notification scopeToShow="loans/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                handleOptionsClick={loanType !== "history" ? this.handleClick : null}
                                dispatch={dispatch}
                                productId={loanType}
                                fetchingDownload={fetchingDownload}
                                onClickDownloadPDF={this.handleClickDownload("pdf")}
                                onClickDownloadXLS={this.handleClickDownload("xls")}
                                mobileMenuOptions={
                                    loanType !== "history" && this.mobileMenuOptions()
                                }></ProductDetail.Head>
                            <MainContainer>
                                <div className="container-left">
                                    <ProductDetail>
                                        <DetailHeadTitle
                                            loan={loan}
                                            loanType={loanType}
                                            isDesktop={isDesktop}
                                            dispatch={dispatch}
                                        />
                                        <Information
                                            loanId={rest.match.params.id}
                                            keyLabel="loans.information"
                                            dispatch={dispatch}
                                            loan={loan}
                                            loanType={loanType}
                                        />
                                        <DetailHeadInfo {...this.props} loanType={loanType} />
                                        {isDesktop && isLoanRequest && (
                                            <ProductToolbar>
                                                <Action
                                                    isDesktop={isDesktop}
                                                    labelKey="loans.request"
                                                    to="/form/requestLoan"
                                                />
                                            </ProductToolbar>
                                        )}
                                        <ProductDetail.Body
                                            isDesktop
                                            filters={loan.totalFees > 1 && loanType === "current" && filters}
                                            filtersKeyLabel="loan.detail.expirationSchedule"
                                            moreFilters={false}
                                            fetching={fetching}
                                            hideTabs={loan.totalFees <= 1 || loanType === "history"}
                                            className="loan-detail-filters">
                                            {loan.totalFees > 1 ? (
                                                <>
                                                    <Statements
                                                        {...this.props}
                                                        loanId={rest.match.params.id}
                                                        loanCurrency={loan.currencyCode}
                                                        isDesktop={isDesktop}
                                                        selectedFilter={selectedFilter}
                                                        keyLabel="loan.detail.expirationSchedule"
                                                    />
                                                </>
                                            ) : (
                                                showDetailButton && (
                                                    <Button
                                                        key="feeDetail"
                                                        label="loan.detail.fees.detail"
                                                        onClick={() => this.handleDetailClick(loan)}
                                                        bsStyle="secondary"
                                                        className="btn-show-more ui-mt-0"
                                                    />
                                                )
                                            )}
                                        </ProductDetail.Body>

                                        {!fetchingStatements && this.renderDisclaimers()}
                                    </ProductDetail>
                                </div>
                            </MainContainer>
                        </ProductDetail>
                    </Fragment>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    loans: loansSelectors.getLoans(state),
    fetching: loansSelectors.getFetching(state),
    fetchingStatements: loansSelectors.getFetchingStatements(state),
    fetchingDownload: loansSelectors.getFetchingDownload(state),
    isLoanRequest: sessionSelectors.hasForm(state, "loans", "requestLoan"),
    totalCount: loansSelectors.getTotalCount(state),
    loanType: loansSelectors.getFilters(state).loanType,
    loanProductSelected: loansSelectors.getLoanProductSelected(state),
    loadFilters: loansSelectors.getFilters(state),
});

export default connect(mapStateToProps)(Details);
