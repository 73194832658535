import { string } from "prop-types";
import React from "react";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import ExternalLinkComponent from "pages/_components/ExternalLink";

const AccountQRComponent = ({ urlQR }) => (
    <div className="form-content">
        <img src={urlQR} alt="qr account" className="img-qr-account" />

        <div className="termsAndConditions qr-text">
            <div>
                <span>{i18n.get("forms.account.qr.download.terms1")} </span>
                <ExternalLinkComponent
                    key="termsAndConditions"
                    className="termsAndConditionsLink"
                    name="termsAndConditionsLink"
                    label="forms.account.qr.download.modal.link"
                    to={configUtils.get(
                        "account.qr.termsAndConditions.url",
                        "https://www.mariva.com.ar/sites/default/files/modo_-_teminos_y_condiciones_del_servicio_de_aceptacion_pct.pdf",
                    )}
                />
                <span> {i18n.get("forms.account.qr.download.terms2")}</span>
            </div>
        </div>
    </div>
);
AccountQRComponent.propTypes = {
    urlQR: string,
};
AccountQRComponent.defaultProps = {
    urlQR: "",
};
export default AccountQRComponent;
