import React from "react";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as i18n from "util/i18n";
import { shape } from "prop-types";

const ChangeStatusTransactionComponent = ({ transaction }) => {
    const renderItem = (value, label) => (
        <div className="data-wrapper">
            <FieldLabel labelText={i18n.get(label)} mode="view" />
            <span className="data-name">{value}</span>
        </div>
    );

    const renderFields = () => {
        if (!transaction || !transaction.data) {
            return <div />;
        }

        const { rejectionReasonCode, rejectionReasonDescription, operationNumber } = transaction.data;

        return (
            <>
                {rejectionReasonCode &&
                    renderItem(rejectionReasonCode, "transaction.update.status.rejectionReasonCode")}

                {rejectionReasonDescription &&
                    renderItem(rejectionReasonDescription, "transaction.update.status.rejectionReasonDescription")}

                {operationNumber && renderItem(operationNumber, "transaction.update.status.operationNumber")}
            </>
        );
    };

    return <>{renderFields()}</>;
};

ChangeStatusTransactionComponent.propTypes = {
    transaction: shape({}).isRequired,
};

export default ChangeStatusTransactionComponent;
