import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    SET_RESET_FILTERS: "filters/SET_RESET_FILTERS",
    RESET_ALL_FILTERS: "filters/RESET_ALL_FILTERS",
    TOGGLE_SHOW_FILTERS: "filters/TOGGLE_SHOW_FILTERS",
    SET_TOGGLE_FILTERS: "filters/SET_TOGGLE_FILTERS",
    VALIDATE_TO_RESET_FILTERS: "filters/VALIDATE_TO_RESET_FILTERS",
    SET_RELOAD_DATA: "filters/SET_RELOAD_DATA",
};

export const INITIAL_STATE = {
    resetFilterOnMount: true,
    idActivity: "",
    showFilters: false,
    reloadData: false,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_RESET_FILTERS]: (state, action) => ({
        ...state,
        resetFilterOnMount: action.reset,
        idActivity: action.idActivity ? action.idActivity : state.idActivity,
    }),
    [types.RESET_ALL_FILTERS]: () => ({
        ...INITIAL_STATE,
    }),

    [types.TOGGLE_SHOW_FILTERS]: (state) => ({
        ...state,
        showFilters: !state.showFilters,
    }),
    [types.SET_TOGGLE_FILTERS]: (state, action) => ({
        ...state,
        showFilters: action.value,
    }),
    [types.SET_RELOAD_DATA]: (state, action) => ({
        ...state,
        reloadData: action.value,
    }),
});

export const actions = {
    setResetFilters: makeActionCreator(types.SET_RESET_FILTERS, "reset", "idActivity"),
    toggleShowFilters: makeActionCreator(types.TOGGLE_SHOW_FILTERS),
    setToggleFilters: makeActionCreator(types.SET_TOGGLE_FILTERS, "value"),
    resetAllFilters: makeActionCreator(types.RESET_ALL_FILTERS),
    validateToResetFilters: makeActionCreator(types.VALIDATE_TO_RESET_FILTERS, "filterToReset", "idActivity"),
    setReloadData: makeActionCreator(types.SET_RELOAD_DATA, "value"),
};

export const selectors = {
    isResetFilters: ({ filters }) => filters.resetFilterOnMount,
    getIdActivityOnFilter: ({ filters }) => filters.idActivity,
    getShowFilters: ({ filters }) => filters.showFilters,
    isReloadData: ({ filters }) => filters.reloadData,
};
