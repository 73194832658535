import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import withRouter from "react-router-dom/withRouter";
import I18n from "pages/_components/I18n";
import { push, replace } from "react-router-redux/actions";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { actions as investmentsActions, selectors as investmentsSelectors } from "reducers/investments";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18nUtils from "util/i18n";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as config from "util/config";
import { bool, shape, func, string } from "prop-types";
import Button from "pages/_components/Button";
import moment from "moment";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "fixedTermDeposit.create";
const ID_ACTIVITY = "fixedTermDeposit.create.send";
const ID_ACTIVITY_PRE = "fixedTermDeposit.create.pre";

const CreateFixedTermDepositForm = (props) => {
    const {
        currentLang,
        fromBackoffice,
        transaction,
        mode,
        dispatch,
        location,
        data,
        preData,
        hasSimulatedOnce,
        depositDetailSimulated,
        previewDataDeposit,
    } = props;
    const [useDefaultSubmit, setUseDefaultSubmit] = useState(false);
    const [filteredAccounts, setFilteredAccounts] = useState();

    const debitAccountList = preData?.debitAccountList || [];
    const idTransaction = transaction?.idTransaction;
    const draftTransaction = transaction?.draftTransaction || false;

    const filterAccountsByCurrency = useCallback(
        (currency) => ({
            options: debitAccountList
                .filter((acc) => acc.currency === currency)
                .map((acc) => ({
                    ...acc,
                    balance: { currency: acc.currency, quantity: acc.balance },
                    id: acc.idProduct,
                })),
        }),
        [debitAccountList],
    );
    useEffect(() => {
        if (preData === null && mode === "edit") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, location, mode, preData]);

    useEffect(() => {
        if (draftTransaction && debitAccountList.length > 0) {
            const filteredOptions = filterAccountsByCurrency(transaction.data.currency[0]);
            setFilteredAccounts(filteredOptions);
        }
    }, [transaction, draftTransaction, debitAccountList, filterAccountsByCurrency]);

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const [selectedCurrency, setSelectedCurrency] = useState("");
    const depositTypesValues = config.get("createFixedTermDeposit.depositTypes").split("|");
    const depositTypes = depositTypesValues.map((value) => ({
        id: value,
        label: i18nUtils.get(`${ID_FORM}.type.${value}`),
    }));
    const currencyTypesValues = config.get("createFixedTermDeposit.currencyTypes").split("|");
    const currencyTypes =
        currencyTypesValues?.map((value) => ({
            id: value,
            label: i18nUtils.get(`${ID_FORM}.currencyOptions.${value}`),
        })) || [];

    const selectorOptions = {
        options: debitAccountList.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
        })),
    };
    const actionsAtExpiration = [
        {
            id: "D",
            label: i18nUtils.get(`forms.${ID_FORM}.action.D.label`),
        },
        {
            id: "C",
            label: i18nUtils.get(`forms.${ID_FORM}.action.C.label`),
        },
        {
            id: "T",
            label: i18nUtils.get(`forms.${ID_FORM}.action.T.label`),
        },
    ];
    const valueCurrency = (e, setFieldValue, formValues) => {
        setSelectedCurrency(e.id);
        const filteredOptions = filterAccountsByCurrency(e.id);
        setFilteredAccounts(filteredOptions);
        setFieldValue("account", null);
        setFieldValue("amount", Object.assign(formValues?.capitalToInvest, { currency: e.id }));
    };

    const calculateExpiryDate = (e, setFieldValue) => {
        const selectedDate = e && e !== "" ? moment().add(parseInt(e, 10), "days") : null;
        setFieldValue("expiryDate", selectedDate);
    };

    const dataAmount = {
        decimalSeparator: ",",
        precision: 2,
        thousandsSeparator: ".",
        options: [],
    };

    const defaultDepositType = depositTypesValues.length === 1 ? depositTypesValues[0] : null;
    const depositDetail = previewDataDeposit || depositDetailSimulated || {};
    const { totalInterest, incomeTax, agreedRate, effectiveRate, days, dueDate, currency, netToPay } = depositDetail;
    const transactionDepositDetail = transaction?.data?.depositDetail || null;
    const { channel, accountLabel } = transaction?.data || {};
    const isTicket = location.pathname.match("/transaction/") != null;
    const showSimulationData =
        (!useDefaultSubmit && mode === "edit" && !draftTransaction) ||
        (previewDataDeposit === null && !draftTransaction) ||
        (Object.keys(depositDetail).length > 0 && draftTransaction);
    const previewDraftDepositData = previewDataDeposit || transactionDepositDetail || {};
    const useTransactionDetail =
        (draftTransaction && Object.keys(depositDetail).length === 0) ||
        (transactionDepositDetail && Object.keys(transactionDepositDetail).length > 0 && !draftTransaction);

    const renderFields = (setFieldValue, formValues) => (
        <>
            {mode === "edit" || (mode === "preview" && draftTransaction) || (mode === "preview" && !isTicket) ? (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={depositTypes}
                        key="type"
                        name="type"
                        idField="type"
                        renderAs="combo"
                        value={data.type ? [data?.type] : null}
                    />

                    <div className="form-group--double-field">
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={currencyTypes || []}
                            key="currency"
                            name="currency"
                            idField="currency"
                            onChange={(e) => valueCurrency(e, setFieldValue, formValues)}
                            renderAs="combo"
                        />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            key="capitalToInvest"
                            name="capitalToInvest"
                            idField="capitalToInvest"
                            data={dataAmount}
                            value={data.capitalToInvest || ""}
                            hideCurrency
                            handleChange={(e) => {
                                setFieldValue("amount", Object.assign(formValues?.capitalToInvest, { quantity: e }));
                            }}
                            externalCurrencyData={data?.currency?.length > 0 ? data?.currency[0] : selectedCurrency}
                            messageHelp={
                                selectedCurrency !== "" &&
                                i18nUtils.get(`${ID_FORM}.range.money`, "", {
                                    MIN_RANGE:
                                        i18nUtils.get(`currency.label.${selectedCurrency}`) +
                                        config.get(
                                            `createFixedTermDeposit.${selectedCurrency}.capitalToInvest.minAmount`,
                                        ),
                                    MAX_RANGE:
                                        i18nUtils.get(`currency.label.${selectedCurrency}`) +
                                        config.get(
                                            `createFixedTermDeposit.${selectedCurrency}.capitalToInvest.maxAmount`,
                                        ),
                                })
                            }
                        />
                    </div>

                    <div className="form-group--double-field">
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            type="number"
                            key="days"
                            name="days"
                            idField="days"
                            maxLength="3"
                            onChange={(e) => calculateExpiryDate(e, setFieldValue)}
                            pattern="^[0-9]"
                            value={data?.days || depositDetail?.days}
                            messageHelp={i18nUtils.get(`${ID_FORM}.range.days`, "", {
                                MIN_DAYS: config.get("createFixedTermDeposit.days.minAmount"),
                                MAX_DAYS: config.get("createFixedTermDeposit.days.maxAmount"),
                            })}
                        />

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Date}
                            key="expiryDate"
                            name="expiryDate"
                            idField="expiryDate"
                            disabled
                            editing={false}
                            value={transactionDepositDetail?.dueDate || depositDetail?.dueDate}
                        />
                    </div>

                    {((hasSimulatedOnce && Object.keys(depositDetail).length > 0) || draftTransaction) && (
                        <>
                            <hr className={`${mode === "edit" ? "ui-mt-0" : "ui-mt-7"} ui-mb-4`} />

                            <div className="data-wrapper inline ui-mt-0">
                                <I18n
                                    id={`forms.${ID_FORM}.amount.label`}
                                    componentProps={{ className: "data-label" }}
                                />
                                <FormattedAmount
                                    currency={showSimulationData ? currency : previewDraftDepositData?.currency}
                                    quantity={showSimulationData ? netToPay : previewDraftDepositData?.netToPay}
                                />
                            </div>

                            <div className="form-group--double-field fixed-grid inline">
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.totalInterest.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <FormattedAmount
                                        currency={showSimulationData ? currency : previewDraftDepositData?.currency}
                                        quantity={
                                            showSimulationData ? totalInterest : previewDraftDepositData?.totalInterest
                                        }
                                    />
                                </div>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.tax.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <FormattedAmount
                                        currency={showSimulationData ? currency : previewDraftDepositData?.currency}
                                        quantity={showSimulationData ? incomeTax : previewDraftDepositData?.incomeTax}
                                    />
                                </div>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.agreedRate.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>
                                        {" "}
                                        {`${
                                            showSimulationData ? agreedRate : previewDraftDepositData?.agreedRate
                                        } %`}{" "}
                                    </span>
                                </div>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.effectiveRate.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>
                                        {" "}
                                        {`${
                                            showSimulationData ? effectiveRate : previewDraftDepositData?.effectiveRate
                                        } %`}{" "}
                                    </span>
                                </div>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.dueDate.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span>
                                        <FormattedDate
                                            date={showSimulationData ? dueDate : previewDraftDepositData?.dueDate}
                                        />
                                    </span>
                                </div>
                                <div className="data-wrapper">
                                    <I18n
                                        id={`forms.${ID_FORM}.realTerm.label`}
                                        componentProps={{ className: "data-label" }}
                                    />
                                    <span> {showSimulationData ? days : previewDraftDepositData?.days} </span>
                                </div>
                            </div>

                            <hr className="ui-mt-4 ui-mb-7" />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={filteredAccounts || selectorOptions}
                                key="account"
                                name="account"
                                idField="account"
                                value={previewDraftDepositData?.account || null}
                            />
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={actionsAtExpiration}
                                key="actionAtExpiration"
                                name="actionAtExpiration"
                                idField="actionAtExpiration"
                                renderAs="combo"
                                value={
                                    previewDraftDepositData?.actionAtExpiration
                                        ? previewDraftDepositData.actionAtExpiration[0]
                                        : ""
                                }
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="operationNumber"
                        name="operationNumber"
                        idField="ticket.operationNumber"
                        value={transactionDepositDetail?.operationCode || null}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="depositType"
                        name="depositType"
                        idField="ticket.type"
                        value={
                            transactionDepositDetail?.descriptionSIOC ||
                            i18nUtils.get(`${ID_FORM}.type.${data.type[0]}`)
                        }
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="currencyType"
                        name="currencyType"
                        idField="ticket.currency"
                        value={i18nUtils.get(
                            `currency.name.${
                                transactionDepositDetail?.currency ? transactionDepositDetail?.currency : data.currency
                            }`,
                        )}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Date}
                        key="openDate"
                        name="openDate"
                        idField="ticket.creationDate"
                        value={transactionDepositDetail?.openDate ? transactionDepositDetail?.openDate : null}
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Date}
                        key="dueDate"
                        name="dueDate"
                        idField="ticket.expiryDate"
                        value={transactionDepositDetail?.dueDate ? transactionDepositDetail?.dueDate : null}
                        editing={false}
                    />
                    <hr />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="transactionChannel"
                        name="transactionChannel"
                        idField="ticket.channel"
                        value={i18nUtils.get(`userTransactions.channels.${channel}`)}
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="holder"
                        name="holder"
                        idField="ticket.holder"
                        value={transactionDepositDetail?.holder || null}
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="cuit"
                        name="cuit"
                        idField="ticket.cuit"
                        value={
                            transactionDepositDetail?.cuit
                                ? transactionDepositDetail.cuit
                                      .substring(0, 2)
                                      .concat("-")
                                      .concat(transactionDepositDetail.cuit.substring(2, 10))
                                      .concat("-")
                                      .concat(transactionDepositDetail.cuit.substring(10))
                                : null
                        }
                    />
                    <hr />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Amount}
                        key="depositedAmount"
                        name="depositedAmount"
                        idField="ticket.depositedAmount"
                        value={
                            {
                                currency: transactionDepositDetail?.currency
                                    ? transactionDepositDetail?.currency
                                    : data.capitalToInvest.currency,
                                quantity: transactionDepositDetail?.amount
                                    ? transactionDepositDetail?.amount
                                    : data.capitalToInvest.quantity,
                            } || null
                        }
                        data={dataAmount}
                        hideCurrency
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.ProductselectorCustom}
                        key="accountLabel"
                        name="accountLabel"
                        idField="ticket.account"
                        data={filteredAccounts || selectorOptions}
                        value={accountLabel || transaction?.data?.account || null}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="term"
                        name="term"
                        idField="ticket.term"
                        value={transactionDepositDetail?.days || null}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="TNA"
                        name="TNA"
                        idField="ticket.TNA"
                        value={transactionDepositDetail?.agreedRate ? `${transactionDepositDetail.agreedRate} %` : null}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="TEA"
                        name="TEA"
                        idField="ticket.TEA"
                        value={
                            transactionDepositDetail?.effectiveRate
                                ? `${transactionDepositDetail.effectiveRate} %`
                                : null
                        }
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Amount}
                        key="totalInterest"
                        name="totalInterest"
                        idField="ticket.totalInterest"
                        value={{
                            currency: transactionDepositDetail?.currency,
                            quantity: transactionDepositDetail?.totalInterest,
                        }}
                        data={dataAmount}
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Amount}
                        key="tax"
                        name="tax"
                        idField="ticket.tax"
                        value={{
                            currency: transactionDepositDetail?.currency,
                            quantity: transactionDepositDetail?.incomeTax,
                        }}
                        data={dataAmount}
                        editing={false}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Amount}
                        key="totalAmount"
                        name="totalAmount"
                        idField="ticket.totalAmount"
                        value={{
                            currency: transactionDepositDetail?.currency,
                            quantity: transactionDepositDetail?.netToPay,
                        }}
                        data={dataAmount}
                        editing={false}
                    />
                    <hr />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="actionAtTheEnd"
                        name="actionAtTheEnd"
                        idField="ticket.actionAtExpiration"
                        value={
                            i18nUtils.get(
                                `forms.fixedTermDeposit.create.action.${
                                    transactionDepositDetail?.actionAtTheEnd
                                        ? transactionDepositDetail?.actionAtTheEnd
                                        : data.actionAtExpiration
                                }.label`,
                            ) || null
                        }
                    />
                    <hr />
                    <DisclaimerContainer disclaimer={`forms.${ID_FORM}.disclaimer.label`} textSmall />
                </>
            )}
        </>
    );

    const goToPreview = () => {
        dispatch(investmentsActions.createDepositPreview());
        setUseDefaultSubmit(true);
    };

    const renderAdditionalActions = (fetching) => {
        const { isMobile } = props;

        if (mode === "edit") {
            return (
                <>
                    {!isMobile && (
                        <Button
                            block={isMobile}
                            type="button"
                            label={i18nUtils.get(`forms.${ID_FORM}.cancel.label`)}
                            bsStyle="outline"
                            loading={fetching}
                            defaultLabelText={i18nUtils.get(`forms.${ID_FORM}.cancel.label`)}
                            onClick={() => dispatch(replace("/fixedTermDeposits"))}
                        />
                    )}
                    {hasSimulatedOnce && !isMobile && (
                        <Button
                            block={isMobile}
                            type="submit"
                            label={`forms.${ID_FORM}.simulateAgain`}
                            bsStyle="secondary"
                            loading={fetching}
                            onClick={() => setUseDefaultSubmit(false)}
                        />
                    )}
                    {!hasSimulatedOnce && (
                        <Button
                            block={isMobile}
                            type="submit"
                            label={`forms.${ID_FORM}.simulate`}
                            bsStyle="primary"
                            loading={fetching}
                            onClick={() => setUseDefaultSubmit(false)}
                        />
                    )}
                    {(hasSimulatedOnce || draftTransaction) && (
                        <Button
                            block={isMobile}
                            type="submit"
                            label={i18nUtils.get(`forms.${ID_FORM}.send`)}
                            bsStyle="primary"
                            loading={fetching}
                            defaultLabelText={i18nUtils.get(`forms.${ID_FORM}.send`)}
                            onClick={goToPreview}
                        />
                    )}
                    {hasSimulatedOnce && isMobile && (
                        <Button
                            block={isMobile}
                            type="submit"
                            label={`forms.${ID_FORM}.simulateAgain`}
                            bsStyle="secondary"
                            loading={fetching}
                            onClick={() => setUseDefaultSubmit(false)}
                        />
                    )}
                    {isMobile && (
                        <Button
                            block={isMobile}
                            type="button"
                            label={i18nUtils.get(`forms.${ID_FORM}.cancel.label`)}
                            bsStyle="outline"
                            loading={fetching}
                            defaultLabelText={i18nUtils.get(`forms.${ID_FORM}.cancel.label`)}
                            onClick={() => dispatch(replace("/fixedTermDeposits"))}
                        />
                    )}
                </>
            );
        }
        if (mode === "view" && !isTicket) {
            return (
                <Button
                    type="button"
                    label={i18nUtils.get("global.continue")}
                    bsStyle="primary"
                    loading={fetching}
                    defaultLabelText={i18nUtils.get("global.continue")}
                    onClick={() => dispatch(push("/fixedTermDeposits"))}
                />
            );
        }
        return null;
    };

    const renderFootNote = () => {
        if (!hasSimulatedOnce) {
            return <I18n id={`forms.${ID_FORM}.note1.label`} />;
        }
        return <DisclaimerContainer disclaimer={`forms.${ID_FORM}.disclaimer.label`} textSmall />;
    };

    const alternateSubmitFunc = (formData) => investmentsActions.simulateDepositRequest(formData);

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 1,
        ...restPreData
    } = preData || {};

    const formProps = {
        title: "fixedTermDeposit.new",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
            showSubmit: false,
        },
        data: {
            ...data,
            amount: {
                currency: useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency,
                quantity: useTransactionDetail ? transactionDepositDetail?.amount : depositDetail?.amount,
            },
            depositedAmount: {
                currency: useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency,
                quantity: useTransactionDetail ? transactionDepositDetail?.amount : depositDetail?.amount,
            },
            days: useTransactionDetail ? transactionDepositDetail?.days : depositDetail?.days,
            depositType: useTransactionDetail ? transactionDepositDetail?.codeSIOC : depositDetail?.codeSIOC,
            type: !useTransactionDetail ? [defaultDepositType] : data?.type || [depositDetail?.codeSIOC],
            currency: useTransactionDetail ? [transactionDepositDetail?.currency] : [depositDetail?.currency] || null,
            capitalToInvest: useTransactionDetail
                ? data?.capitalToInvest
                : { currency: depositDetail?.currency, quantity: depositDetail?.amount },
            expiryDate: useTransactionDetail ? transactionDepositDetail?.dueDate : depositDetail?.dueDate,
            currencyType: i18nUtils.get(
                `currency.name.${useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency}`,
            ),
            openDate: useTransactionDetail ? transactionDepositDetail?.openDate : depositDetail?.openDate,
            dueDate: useTransactionDetail ? transactionDepositDetail?.dueDate : depositDetail?.dueDate,
            account: previewDraftDepositData?.account || data?.account || null,
            accountLabel: useTransactionDetail ? transaction?.data?.accountLabel : null,
            term: useTransactionDetail ? transactionDepositDetail?.days : depositDetail?.days,
            TNA: `${useTransactionDetail ? transactionDepositDetail?.agreedRate : depositDetail?.agreedRate} %`,
            TEA: `${useTransactionDetail ? transactionDepositDetail?.effectiveRate : depositDetail?.effectiveRate} %`,
            totalInterest: {
                currency: useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency,
                quantity: useTransactionDetail ? transactionDepositDetail?.totalInterest : depositDetail?.totalInterest,
            },
            tax: {
                currency: useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency,
                quantity: useTransactionDetail ? transactionDepositDetail?.incomeTax : depositDetail?.incomeTax,
            },
            totalAmount: {
                currency: useTransactionDetail ? transaction.data.currency[0] : depositDetail?.currency,
                quantity: useTransactionDetail ? transactionDepositDetail?.netToPay : depositDetail?.netToPay,
            },
            actionAtTheEnd:
                i18nUtils.get(
                    `forms.fixedTermDeposit.create.action.${
                        useTransactionDetail ? transactionDepositDetail?.actionAtTheEnd : data.actionAtExpiration
                    }.label`,
                ) || null,
            transactionChannel: i18nUtils.get(`userTransactions.channels.${channel}`),
            operationNumber: useTransactionDetail
                ? transactionDepositDetail?.operationCode
                : depositDetail?.operationCode,
        },
        renderFields,
        renderAdditionalActions,
        useDefaultSubmit,
        alternateSubmitFunc,
        renderFootNote,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};
CreateFixedTermDepositForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    setFieldValue: func.isRequired,
    useDefaultSubmit: bool,
    errors: shape({}).isRequired,
    depositDetailSimulated: shape({}).isRequired,
    hasSimulatedOnce: bool.isRequired,
    previewDataDeposit: shape({}),
    isMobile: bool.isRequired,
};

CreateFixedTermDepositForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    previewDataDeposit: null,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    fetching: formSelectors.getFetching(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    hasSimulatedOnce: investmentsSelectors.getHasSimulatedOnce(state),
    errors: investmentsSelectors.getErrors(state),
    depositDetailSimulated: investmentsSelectors.getDepositDetail(state),
    previewDataDeposit: formSelectors?.getPreviewData(state)?.depositDetail || null,
});

export default compose(connect(mapStateToProps), withRouter)(CreateFixedTermDepositForm);
