import React from "react";
import { string, bool, shape } from "prop-types";
import Image from "pages/_components/Image";

const getFocus = (id) => {
    const element = document.getElementById(id) || document.getElementById(`react-select-${id}--value`);
    if (element && element.nodeName === "DIV") {
        element.querySelector(".Select-input").focus();
    }
};

const AmountLabel = (props) => {
    const { editing, label, isRequired, optionalMessage, idField, hideCurrency, tooltipProps } = props;

    if (editing) {
        return (
            <div className="form-group-text">
                {!hideCurrency && (
                    <label
                        className="control-label"
                        htmlFor={`${idField}.currency`}
                        role="presentation"
                        onClick={() => getFocus(`${idField}.currency`)}>
                        {!isRequired && <small className="text-optional">{optionalMessage}</small>}
                    </label>
                )}
                <label className="control-label" htmlFor="amount">
                    {label}
                    {tooltipProps && (
                        <a role="button" {...tooltipProps}>
                            <Image src="images/iconos/warning.svg" wrapperClassName="tiny-small-icon" />
                        </a>
                    )}
                </label>
            </div>
        );
    }
    return null;
};

AmountLabel.propTypes = {
    editing: bool.isRequired,
    label: string.isRequired,
    isRequired: bool.isRequired,
    optionalMessage: string.isRequired,
    idField: string.isRequired,
    hideCurrency: bool,
    tooltipProps: shape({}),
};

AmountLabel.defaultProps = {
    hideCurrency: false,
    tooltipProps: null,
};

export default AmountLabel;
