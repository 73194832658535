import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { array, bool, func, number, object, oneOfType, oneOf, objectOf, any } from "prop-types";
import { push } from "react-router-redux";
import { compose } from "redux";
import ReactDOM from "react-dom";
import { get } from "util/i18n";

import { Field, withFormik } from "formik";
import Button from "pages/_components/Button";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";
import BigModal from "pages/_components/BigModal/BigModal";
import { actions } from "../../reducers/frequentRecipients";
import MainContainer from "../_components/MainContainer";
import I18n from "../_components/I18n";
import ProductList from "../_components/product/List";
import Head from "../_components/Head";
import ChevromRight from "../_components/listItem/ChevromRight";
import TextField from "../_components/fields/TextField";
import Loader from "../_components/Loader";

const FrequentRecipients = ({
    isMobile,
    dispatch,
    history,
    fetching,
    recipients,
    nextPage,
    lastPage,
    renderAs,
    changeStatusModal,
    setFieldValueToChange,
    mapsFieldsToChange,
    isFirstFetching,
    showTitle,
    showHeader,
    handleOnClickItem,
}) => {
    const [filter, setFilter] = useState(null);
    const [isShowModal, setShowModal] = useState(false);

    useEffect(() => {
        if (renderAs === "modal") {
            setShowModal(true);
        }
    }, [renderAs]);

    useEffect(() => {
        dispatch(actions.loadFrequentRecipients(1, null, true));
        return () => {
            if (renderAs !== "intoModal") {
                dispatch(actions.resetFrequentRecipients());
            }
        };
    }, [dispatch]);

    const loadMoreData = () => {
        dispatch(actions.loadFrequentRecipients(nextPage, filter, false));
    };

    const handleDismissModal = () => {
        changeStatusModal(false);
        setShowModal(false);
    };

    const handleOnClick = (item) => {
        if (renderAs === "modal") {
            setFieldValueToChange(
                mapsFieldsToChange.get("cuit"),
                item?.cuit.replace(/^(\d{2})(\d{8})(\d{1}).*!/, "$1-$2-$3"),
            );
            setFieldValueToChange(mapsFieldsToChange.get("destination"), item?.destinationIdentifier);
            setFieldValueToChange(mapsFieldsToChange.get("type"), [item?.destinationType]);
            setFieldValueToChange(mapsFieldsToChange.get("email"), item?.email);

            handleDismissModal();
        } else if (renderAs === "intoModal") {
            handleOnClickItem(item);
        } else {
            dispatch(actions.selectRecipient(item));
            dispatch(push("/recipients/modify"));
        }
    };

    const renderHeaders = () => {
        if (isMobile) {
            return <Head showLogo arrowWhite additionalClassName="blue-main-header-mobile" />;
        }

        return (
            <Head
                addLinkToLabel="frequentDestination.list.action.create"
                addLinkTo="/recipients/search"
                showPlusSecondary
                accessibilityTextId="frequentDestination.list.tittle"
            />
        );
    };

    const renderItem = (item) => {
        const { accountNumber, bank, reference, headline, currency, destinationIdentifier } = item;
        return isMobile ? (
            <GenericItem role="button" onClick={() => handleOnClick(item)} arrow={!isShowModal}>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="frequentDestination.list.tableHeader.owned" />
                    </div>
                    <span className="data-text">{headline}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="frequentDestination.list.tableHeader.reference" />
                    </div>
                    <span className="data-text">{reference}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="frequentDestination.list.tableHeader.entity" />
                    </div>
                    <span className="data-text">{bank}</span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="frequentDestination.list.tableHeader.currency" />
                    </div>
                    <span className="data-text">
                        {get(`forms.frequentDestination.create.currencyOptions.${currency}.label`, "")}
                    </span>
                </div>
                <div className="data-wrapper">
                    <div className="data-label">
                        <I18n id="frequentDestination.list.tableHeader.accountIdentifier" />
                    </div>
                    <span className="data-text">{destinationIdentifier}</span>
                </div>
            </GenericItem>
        ) : (
            <Table.Row key={accountNumber} role="button" onClick={() => handleOnClick(item)}>
                <Table.Data align="left">
                    <span className="data-text">{headline}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{reference}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">{bank}</span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {get(`forms.frequentDestination.create.currencyOptions.${currency}.label`, "")}
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <span className="data-text">
                        {renderAs === "intoModal" ? `${destinationIdentifier.slice(0, 10)}...` : destinationIdentifier}
                    </span>
                </Table.Data>

                {renderAs === "form" && (
                    <Table.Data align="right" className="table-data-icon">
                        <ChevromRight />
                    </Table.Data>
                )}
            </Table.Row>
        );
    };
    const renderList = (list, renderLoadMore) => {
        if (isMobile) {
            return (
                <>
                    <div className="generic-movements-wrapper">
                        <div className="list-container">{list}</div>
                    </div>
                    {renderLoadMore()}
                </>
            );
        }
        return (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id="frequentDestination.list.tableHeader.owned" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="frequentDestination.list.tableHeader.reference" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="frequentDestination.list.tableHeader.entity" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="frequentDestination.list.tableHeader.currency" />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id="frequentDestination.list.tableHeader.accountIdentifier" />
                        </Table.HeaderData>

                        {renderAs === "form" && <Table.HeaderData />}
                    </Table.Header>

                    <Table.Body>{list}</Table.Body>
                </Table>
                {renderLoadMore()}
            </>
        );
    };

    const onSearch = (event) => {
        dispatch(actions.resetFrequentRecipients());
        setFilter(event.target.value);
        dispatch(actions.loadFrequentRecipients(1, event.target.value, false));
    };

    const renderIntoModal = () => (
        <>
            <div className="ui-dflex-column">
                {fetching && isFirstFetching ? (
                    <div className="ui-mt-8">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="form-content">
                            <div className="form-group form-group--search">
                                <Field
                                    idForm="forms.frequentDestination.list"
                                    component={TextField}
                                    key="filter"
                                    name="filter"
                                    idField="filter"
                                    type="text"
                                    onKeyUp={onSearch}
                                    hideLabel
                                    value={filter || ""}
                                />
                            </div>
                        </div>
                        <ProductList
                            firstFetched={isFirstFetching}
                            fetching={fetching}
                            items={recipients}
                            filters={{ search: filter }}
                            renderItem={renderItem}
                            lastPage={!lastPage}
                            pageNumber={nextPage}
                            loadMoreText="frequentDestination.list.searchMore"
                            noDataText="frequentDestination.list.empty"
                            noMoreDataText="frequentDestination.list.noMoreMovements"
                            noFiltersDataText="frequentDestination.list.notFound.message"
                            onLoadMoreClick={loadMoreData}
                            isMobile={isMobile}>
                            {renderList}
                        </ProductList>
                    </>
                )}
            </div>
        </>
    );

    const renderElements = () => (
        <>
            {showHeader && renderHeaders()}
            <MainContainer>
                <div className="container-left">
                    {showTitle && (
                        <div className="title-left-header">
                            <I18n id="frequentDestination.list.tittle" component="h1" />
                        </div>
                    )}

                    {isMobile && (
                        <Button
                            block
                            label="frequentDestination.list.action.create"
                            bsStyle="secondary"
                            image="images/plusWhite.svg"
                            onClick={() => history.push("/recipients/search")}
                            className="ui-mb-7"
                        />
                    )}

                    {fetching && isFirstFetching ? (
                        <Loader />
                    ) : (
                        <>
                            <div className={`form-content ${renderAs === "intoModal" ? "ui-mb-0" : "ui-mb-3"}`}>
                                <div className="form-group form-group--search">
                                    <Field
                                        idForm="forms.frequentDestination.list"
                                        component={TextField}
                                        key="filter"
                                        name="filter"
                                        idField="filter"
                                        type="text"
                                        onKeyUp={onSearch}
                                        hideLabel
                                    />
                                </div>
                            </div>
                            {renderAs === "intoModal" && filter === null ? (
                                <></>
                            ) : (
                                <ProductList
                                    firstFetched={filter ? fetching : isFirstFetching}
                                    fetching={fetching}
                                    items={recipients}
                                    filters={{ search: filter }}
                                    renderItem={renderItem}
                                    lastPage={!lastPage}
                                    pageNumber={nextPage}
                                    loadMoreText="frequentDestination.list.searchMore"
                                    noDataText="frequentDestination.list.empty"
                                    noMoreDataText="frequentDestination.list.noMoreMovements"
                                    noFiltersDataText="frequentDestination.list.notFound.message"
                                    onLoadMoreClick={loadMoreData}
                                    isMobile={isMobile}>
                                    {renderList}
                                </ProductList>
                            )}
                        </>
                    )}
                </div>
            </MainContainer>
        </>
    );

    return (
        <>
            {renderAs === "modal"
                ? ReactDOM.createPortal(
                      <BigModal
                          showModal={isShowModal}
                          handleShowModal={() => handleDismissModal()}
                          modalTitle="frequentDestination.list.tittle"
                          modalWidth="auto"
                          isMobile={isMobile}
                          className="frequentRecipients-modal">
                          {renderIntoModal()}
                      </BigModal>,
                      document.querySelector("#modal"),
                  )
                : renderElements()}
        </>
    );
};

FrequentRecipients.propTypes = {
    fetching: bool.isRequired,
    isFirstFetching: bool.isRequired,
    isMobile: bool.isRequired,
    recipients: oneOfType([array]).isRequired,
    history: objectOf(any).isRequired,
    dispatch: func.isRequired,
    nextPage: number.isRequired,
    lastPage: bool.isRequired,
    renderAs: oneOf(["modal", "intoModal", "form"]),
    changeStatusModal: func.isRequired,
    setFieldValueToChange: func.isRequired,
    mapsFieldsToChange: oneOfType([object]).isRequired,
    showTitle: bool,
    showHeader: bool,
    handleOnClickItem: func,
};

FrequentRecipients.defaultProps = {
    renderAs: "form",
    showTitle: true,
    showHeader: true,
    handleOnClickItem: null,
};

const mapStateToProps = (state) => ({
    fetching: state.frequentRecipients.fetching,
    recipients: state.frequentRecipients.recipientsList,
    nextPage: state.frequentRecipients.nextPage,
    lastPage: state.frequentRecipients.moreData,
    isFirstFetching: state.frequentRecipients.isFirstFetching,
});
export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: () => ({
            filter: "",
        }),
    }),
)(FrequentRecipients);
