import * as i18nUtils from "util/i18n";
import { naturalDate } from "util/format";
import { noGpsResponse, unknownResponse } from "./geolocation";

export const getLastLoginDate = (previousLoginInfo) => {
    if (previousLoginInfo) {
        const { date } = previousLoginInfo;

        return naturalDate(date);
    }
    return null;
};

export const getLastLoginPlace = (city, country, showDefault = false) => {
    if (city === unknownResponse.city || country === unknownResponse.country) {
        return i18nUtils.get("settings.lastLogin.place.unknown.tittle");
    }

    if (city === noGpsResponse.city || country === noGpsResponse.country) {
        if (showDefault) {
            return "Buenos Aires, Argentina";
        }

        return i18nUtils.get("settings.lastLogin.place.noGps.tittle");
    }

    if (city && country) {
        return `${city}, ${country}`;
    }
    if (city) {
        return `${city}`;
    }
    if (country) {
        return `${country}`;
    }

    return null;
};

export const getLastLoginPosition = (previousLoginList) => {
    if (previousLoginList && previousLoginList.length > 1) {
        return [previousLoginList[0]];
    }
    return null;
};

export const calculateScoreStrengthPassword = (pass) => {
    let score = 0;
    if (!pass) {
        return score;
    }

    const letters = {};
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 8.0 / letters[pass[i]];
    }

    const variations = {
        digits: /\d/.test(pass),
        chars: /[#_?!@$%^&*-]/.test(pass),
    };

    const variationCount = Object.keys(variations).reduce((count, value) => {
        if (variations[value]) {
            return count + 1;
        }
        return count;
    }, 0);

    score += (variationCount - 1) * 10;
    return parseInt(score, 10);
};
