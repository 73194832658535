import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as loanSelectors, actions as loanActions } from "reducers/loans";
import { bool, func, string, arrayOf, number, shape } from "prop-types";

import I18n from "pages/_components/I18n";
import ProductList from "pages/_components/product/List";
import Loader from "pages/_components/Loader";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import Table from "pages/_components/Table";
import defaultDateFromList from "util/defaultDateFromList";
import getFiltersStatus from "util/getFiltersStatus";
import Loan from "./Loan";

class List extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        pageNumber: number.isRequired,
        isDesktop: bool.isRequired,
        loans: arrayOf(shape({})).isRequired,
        loanType: string.isRequired,
        moreLoans: bool.isRequired,
        idActivity: string.isRequired,
        isFetchingLoans: bool.isRequired,
        isFirstFetched: bool.isRequired,
    };

    state = {
        showFilters: false,
    };

    fetchMoreLoans = () => {
        const { dispatch } = this.props;
        let { filters, pageNumber } = this.props;

        pageNumber += 1;
        filters = { ...filters, pageNumber };
        dispatch(loanActions.loadMoreLoansRequest(filters));
    };

    /* eslint-disable */
    handleLoanClick = (loan) => {
        // es-lint disable
        const { idProduct, operationCode, operationBranch, operationOrder } = loan;
        const { dispatch, history } = this.props;
        dispatch(loanActions.selectLoan(loan));
        history.push(`/loans/${idProduct}/${operationCode}/${operationBranch}/${operationOrder}/detail`);
    };

    handleFiltersClick = () => {
        const { showFilters } = this.state;
        this.setState({ showFilters: !showFilters });
    };

    renderItem = (loan) => {
        const { isDesktop, idActivity, loanType } = this.props;
        return isDesktop ? (
            <div className="table-row" role="button" key={loan.number} onClick={() => this.handleLoanClick(loan)}>
                <Loan isDesktop={isDesktop} loan={loan} loanType={loanType} idActivity={idActivity} />
            </div>
        ) : (
            <Loan key={loan.number} isDesktop={isDesktop} loan={loan} idActivity={idActivity} loanType={loanType} onClick={() => this.handleLoanClick(loan)}/>
        );
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, idActivity, loanType  } = this.props;
        return isDesktop ? (
            <>
                <Table className="gridTable">
                    <Table.Header>
                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.operationNumber.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.operationType.label`} />
                        </Table.HeaderData>

                        <Table.HeaderData align="left">
                            <I18n id={`${idActivity}.dateOfOrigin.label`} />
                        </Table.HeaderData>

                        {loanType === "current" ? (
                            <>
                                <Table.HeaderData align="left">
                                    <I18n id={`${idActivity}.nextExpiration.label`} />
                                </Table.HeaderData>

                                <Table.HeaderData align="left">
                                    <I18n id={`${idActivity}.pendingFees.label`} />
                                </Table.HeaderData>

                                <Table.HeaderData align="right">
                                    <I18n id={`${idActivity}.amount.label`} />
                                </Table.HeaderData>

                                <Table.HeaderData align="right">
                                    <I18n id={`${idActivity}.balance.label`} />
                                </Table.HeaderData>
                            </>

                        ) : (
                            <>
                                <Table.HeaderData align="right">
                                    <I18n id={`${idActivity}.cancellationDate.label`} />
                                </Table.HeaderData>

                                <Table.HeaderData align="right">
                                    <I18n id={`${idActivity}.amountHistory.label`} />
                                </Table.HeaderData>
                            </>
                        )}

                        <Table.HeaderData />
                    </Table.Header>

                    <Table.Body>
                        {list}
                    </Table.Body>
                </Table>
                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer disclaimer="loans.list.disclaimer.label" className="important-text ui-mt-8" textSmall />
            </>
        ) : (
            <>
                <div className="generic-movements-wrapper">
                    {list}
                </div>
                <SeuoLabel />
                {renderLoadMore()}
                <DisclaimerContainer disclaimer="loans.list.disclaimer.label" className="important-text ui-mt-8" textSmall />
            </>
        );
    };

    render() {
        const { isFetchingLoans, moreLoans, loans, isDesktop, filters, loanType, isFirstFetched } = this.props;

        const defaultFilters = {
            dateFrom: defaultDateFromList().loans.dateFrom,
            dateTo: defaultDateFromList().loans.dateTo,
            operationType: ['']
        };

        const withFilters = getFiltersStatus({filters, defaultFilters});

        let noMoreDataText, noDataText;

        if(loanType === "current") {
            noDataText = "loans.list.empty.label"
            noMoreDataText =  "loans.list.noMoreLoans";
        } else {
            noDataText = "loans.list.empty.label.history";
            if(withFilters) {
                noMoreDataText = "loans.list.history.noMoreData.withFilters";
            } else {
                noMoreDataText = "loans.list.history.noMoreData.default";
            }
        }

        return (
            <>
                {isFetchingLoans && isFirstFetched ? <Loader /> : (
                    <ProductList
                        fetching={isFetchingLoans}
                        firstFetched={isFirstFetched}
                        items={loans}
                        renderItem={this.renderItem}
                        lastPage={!moreLoans}
                        onLoadMoreClick={this.fetchMoreLoans}
                        filters={filters || {}}
                        noMoreDataText={noMoreDataText}
                        loadMoreText="loans.list.searchMoreLoans"
                        noDataText={noDataText}
                        noFiltersDataText="loans.list.filters.none"
                        defaultFilters={defaultFilters}
                        isMobile={!isDesktop}
                        >
                        {this.renderList}
                    </ProductList>
                )}

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: loanSelectors.getFilters(state),
    loans: loanSelectors.getLoans(state),
    moreLoans: loanSelectors.getMoreLoans(state),
    pageNumber: loanSelectors.getPageNumber(state),
    isFetchingLoans: loanSelectors.getFetching(state),
    isFirstFetched: loanSelectors.getFirstFetching(state)
});

export default connect(mapStateToProps)(List);
