import React, { Component } from "react";
import { instanceOf, shape, string, bool, oneOfType } from "prop-types";
import { Link } from "react-router-dom";

import * as i18nUtils from "util/i18n";

import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

import Message from "pages/forms/_components/_fields/_scheduler/Message";

import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";

class ScheduledTransactionsListItem extends Component {
    static propTypes = {
        activityName: string.isRequired,
        idTransaction: string.isRequired,
        isDesktop: bool.isRequired,
        creationDateTime: oneOfType([string, instanceOf(Date)]).isRequired,
        valueDateTime: oneOfType([string, instanceOf(Date)]).isRequired,
        data: shape({}).isRequired,
        extendedData: string.isRequired,
    };

    render() {
        const {
            activityName,
            idTransaction,
            isDesktop,
            creationDateTime,
            valueDateTime,
            data: { scheduler, amount },
            extendedData,
        } = this.props;

        const extendedDataObj = JSON.parse(extendedData);
        const programed = !!scheduler?.program;

        if (isDesktop) {
            return (
                <div className="table-row-container">
                    <Link className="table-row" to={`/transaction/${idTransaction}`}>
                        <div className="table-data data-details">
                            <div className="data-info">{activityName}</div>
                        </div>
                        <div className="table-data data-icon">
                            <div className="transaction-info">
                                <div className="ui-dflex">
                                    <Image
                                        src={`images/ui-icons/ui-scheduled-${
                                            programed ? "programed.svg" : "no-programed.svg"
                                        }`}
                                        className="svg-icon"
                                    />
                                </div>
                                <div className="data-info">
                                    <Message value={scheduler} />
                                </div>
                            </div>
                        </div>
                        <div className="table-data data-date">
                            <div className="data-label">{i18nUtils.get("transactions.list.item.created")}</div>{" "}
                            <div className="data-info">
                                <FormattedDate date={creationDateTime} />
                            </div>
                        </div>
                        <div className="table-data data-date">
                            <div className="data-label">
                                {programed
                                    ? i18nUtils.get("transactions.list.item.sent")
                                    : i18nUtils.get("transactions.list.item.scheduled.sent")}
                            </div>{" "}
                            <div className="data-info">
                                <FormattedDate date={programed ? extendedDataObj.nextFireTime : valueDateTime} />
                            </div>
                        </div>
                        <div className="table-data data-number">
                            {amount && <FormattedAmount quantity={amount.quantity} currency={amount.currency} />}
                        </div>
                    </Link>
                </div>
            );
        }
        return (
            <div className="table-row-container">
                <Link className="table-row" to={`/transaction/${idTransaction}`}>
                    <div className="table-data data-details">
                        <div className="data-info">{activityName}</div>
                    </div>
                    <div className="table-data data-details">
                        <div className="transaction-info">
                            <div className="ui-dflex">
                                <Image
                                    src={`images/ui-icons/ui-scheduled-${
                                        programed ? "programed.svg" : "no-programed.svg"
                                    }`}
                                    className="svg-icon"
                                />
                            </div>
                            <div className="data-info">
                                <Message value={scheduler} />
                            </div>
                        </div>
                    </div>
                    <div className="table-data data-number">
                        {amount && <FormattedAmount quantity={amount.quantity} currency={amount.currency} />}
                    </div>
                </Link>
            </div>
        );
    }
}

export default resizableRoute(ScheduledTransactionsListItem);
