import React, { Component } from "react";
import { func, bool, shape, string } from "prop-types";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { actions as formActions } from "reducers/form";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import * as utilDownload from "util/download";
import { get } from "util/i18n";
import { REGION_USA, RETAIL_ENVIRONMENT_TYPE } from "constants.js";
import { selectors as loginSelectors } from "reducers/login";

import { selectors as sessionSelectors } from "reducers/session";

class OpeningAccountStep2 extends Component {
    static propTypes = {
        overdraft: bool,
        accountType: shape([]).isRequired,
        setFieldValue: func,
        fetching: bool,
        dispatch: func.isRequired,
        activeRegion: string.isRequired,
        activeEnvironment: shape({ type: string }).isRequired,
    };

    static defaultProps = {
        overdraft: null,
        fetching: null,
        setFieldValue: () => {},
    };

    state = {
        overdraftError: false,
    };

    componentDidMount() {
        const {
            dispatch,
            accountType,
            activeRegion,
            activeEnvironment: { type },
        } = this.props;
        const isUSA = REGION_USA === activeRegion;

        if (accountType[0] !== "CC" || !isUSA || type !== RETAIL_ENVIRONMENT_TYPE) {
            dispatch(formActions.setMode("preview", "edit"));
        }
    }

    handleClickDownloadPdf = (fileNameKey) => {
        utilDownload.downloadLink(fileNameKey, "/pdf/overdraft.pdf");
    };

    changeOverdraftValue = (radio) => {
        const { setFieldValue } = this.props;
        const newValue = [radio];
        setFieldValue("overdraft", newValue);
    };

    onContinue = () => {
        const { overdraft, dispatch } = this.props;
        this.setState({ overdraftError: false });

        if (!overdraft || overdraft.length === 0) {
            this.setState({ overdraftError: true });
            return;
        }

        dispatch(formActions.setMode("preview", "edit-step2"));
        dispatch(
            formActions.addExtraParams({
                overdraft,
            }),
        );
    };

    render() {
        const { overdraft, fetching } = this.props;
        const { overdraftError } = this.state;
        const defaultLabelText = get("global.continue", "");

        return (
            <section className="container--layout align-items-top flex-grow">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="form-group-text">
                                <h4 className="table-legend">
                                    <I18n id="forms.openingAccount.whatDoyouNeed" />
                                </h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="overdraft-box">
                                <I18n id="forms.openingAccount.overdraftTandC" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center link-overdraft">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <button
                                type="button"
                                className="btn btn-asLink color-red"
                                onClick={() => this.handleClickDownloadPdf("Overdraft_T&C")}>
                                <I18n id="forms.openingAccount.downloadOverdraftTandC" />
                            </button>
                        </Col>
                    </Row>

                    <Row className="justify-content-center radio-overdraft">
                        <Col className={`col col-12 col-lg-6 col-md-9 ${overdraftError ? "has-error" : ""}`}>
                            <RadioButtonGroup
                                inLineControl
                                name="overdraft"
                                onChange={this.changeOverdraftValue}
                                optionClassName="needsclick"
                                options={[
                                    {
                                        id: "Si",
                                        label: get("forms.openingAccount.iDoWant", ""),
                                    },
                                    {
                                        id: "No",
                                        label: get("forms.openingAccount.iDoNotWant", ""),
                                    },
                                ]}
                                value={overdraft ? overdraft[0] : null}
                            />
                            {overdraftError && <FieldError error={get("forms.openingAccount.fieldRequired", "")} />}
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button
                                type="button"
                                bsStyle="primary"
                                loading={fetching}
                                defaultLabelText={defaultLabelText}
                                onClick={this.onContinue}
                            />
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(OpeningAccountStep2);
