import React, { Component } from "react";
import { Link } from "react-router-dom";
import { func, string, bool } from "prop-types";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class DepositAction extends Component {
    static propTypes = {
        handleClick: func.isRequired,
        className: string,
        labelKey: string,
        to: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        className: null,
        labelKey: null,
        to: null,
    };

    handleClick = () => {
        const { handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
    };

    render() {
        const { className, labelKey, to, isDesktop } = this.props;
        return (
            (to && (
                <Link className={className} to={to} onClick={this.handleClick}>
                    <I18n id={labelKey} />
                </Link>
            )) || (
                <Button
                    className={className}
                    label={labelKey}
                    onClick={this.handleClick}
                    bsStyle="secondary"
                    block={!isDesktop}
                />
            )
        );
    }
}

export default DepositAction;
