import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as assistantSelectors } from "reducers/assistant";
import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import { Link } from "react-router-dom";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Button from "pages/_components/Button";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "login.step4";

class Step4Content extends Component {
    render() {
        const { isSubmitting } = this.props;

        return (
            <Form className="form-content container">
                <div className="pre-form-info">
                    <div className="pre-form-info__text">
                        <I18n id="login.step4.title.termsAndConditions" />

                        <I18n
                            id="generalConditions.term.Esign.body"
                            component="p"
                            componentProps={{ className: "ui-mb-7" }}
                        />

                        <Link
                            to={{
                                pathname: "/termsAndConditions",
                                state: { prevPath: window.location.pathname },
                            }}
                            className="ui-link-gray">
                            {i18n.get("generalConditions.TermsAndConditions.link.label")}
                        </Link>
                    </div>
                </div>
                <Field
                    name="acceptConditions"
                    idForm={FORM_ID}
                    component={SwitchField}
                    formGroup
                    additionalClassName="ui-dflex-center"
                />
                <Button type="submit" loading={isSubmitting} label="login.comeIn" bsStyle="primary" block />
            </Form>
        );
    }
}

Step4Content.propTypes = {
    isSubmitting: bool,
};

Step4Content.defaultProps = {
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ acceptConditions: false }),
        validationSchema: () =>
            Yup.object().shape({
                acceptConditions: Yup.boolean().oneOf([true], i18n.get(`${FORM_ID}.acceptConditions.required`)),
            }),
        handleSubmit: ({ acceptConditions }, formikBag) => {
            Mixpanel.track(FORM_ID, { acceptConditions });
            formikBag.props.dispatch(loginActions.loginStep4(acceptConditions, formikBag));
        },
    }),
)(Step4Content);
