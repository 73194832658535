import React, { useEffect } from "react";
import { bool, shape, func } from "prop-types";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { connect } from "react-redux";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import PageLoading from "pages/_components/PageLoading";
import StatementsAccordion from "./Statement/StatementsAccordion";
import StatementsFilters from "./Statement/StatementsFilters";

const Statements = ({ isDesktop, statementsByYear, fetching, dispatch, match, history }) => {
    const accountId = match.params.id;

    useEffect(() => {
        loadStatements();
    }, []);

    useEffect(() => {
        // Esto es para forzar la recarga si el usuario accede desde accounts/:id/statements a accounts/statements/list
        loadStatements();
    }, [history.location.pathname]);

    function loadStatements() {
        if (accountId) {
            dispatch(accountsActions.loadListAccounts(accountId));
        } else {
            dispatch(accountsActions.loadListAccounts());
        }
    }

    return (
        <>
            <Head
                onBack={accountId && history.goBack}
                showLogo={!isDesktop}
                additionalClassName={!isDesktop && "blue-main-header-mobile"}
                arrowWhite={!isDesktop}
            />

            <Notification scopeToShow="accountsStatements" />

            <MainContainer>
                <div className="container-center">
                    <div className="title-left-header">
                        <I18n id="accounts.statements" component="h1" />
                    </div>

                    <PageLoading loading={fetching}>
                        <StatementsFilters />

                        {!fetching && statementsByYear && Object.keys(statementsByYear).length !== 0 ? (
                            <StatementsAccordion statementsByYear={statementsByYear} showFilters />
                        ) : (
                            <NoDataContainer
                                fetching={fetching}
                                noDataText="accounts.statements.list.empty.label"
                                noDataImage="images/coloredIcons/folder-empty.svg"
                            />
                        )}
                    </PageLoading>
                </div>
            </MainContainer>
        </>
    );
};

Statements.propTypes = {
    isDesktop: bool.isRequired,
    statementsByYear: shape({}).isRequired,
    fetching: bool,
    dispatch: func.isRequired,
    match: shape().isRequired,
    history: func.isRequired,
};

Statements.defaultProps = {
    fetching: false,
};

const mapStateToProps = (state) => ({
    statementsByYear: accountsSelectors.getStatementLinesByYear(state),
    fetching: accountsSelectors.getFetching(state),
});

export default connect(mapStateToProps)(Statements);
