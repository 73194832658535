import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { node, string } from "prop-types";

class Header extends Component {
    static propTypes = {
        children: node.isRequired,
        addClassName: string,
        additionalClassName: string,
    };

    static defaultProps = {
        addClassName: "",
        additionalClassName: "",
    };

    render() {
        const { addClassName, children, additionalClassName } = this.props;
        return (
            <header className={`view-header ${additionalClassName}`}>
                <Navbar collapseOnSelect fluid>
                    <Navbar.Header>
                        <div className={`toolbar ${addClassName}`}>{children}</div>
                    </Navbar.Header>
                </Navbar>
            </header>
        );
    }
}

export default Header;
