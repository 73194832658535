import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { bool, func, shape } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { compose } from "redux";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

const ID_FORM = "forms.cancelRequest";

const CancelRequest = (props) => {
    const { isFetching, dispatch, transaction } = props;

    const { idTransaction } = transaction || {};
    const credentials = {
        idTransaction,
        transaction,
        idForm: ID_FORM,
        isLoading: false,
    };

    const [cancelReason, setCancelReason] = useState("");
    const handleChange = ({ target: { value } }) => {
        setCancelReason(value);
    };

    const sendCancelRequest = () => {
        dispatch(formActions.cancelRequest(idTransaction, credentials, cancelReason));
    };

    const renderFields = () => (
        <>
            <div className="form-content">
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.reference.label`} />
                    </span>
                    <span className="data-text">{idTransaction}</span>
                </div>

                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.activityName.label`} defaultValue="Tipo de transacción" />
                    </span>
                    <span className="data-text">{transaction.activityName}</span>
                </div>

                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.date.label`} defaultValue="Fecha de transacción" />
                    </span>
                    <span className="data-text">{transaction.submitDateTimeAsString.substring(0, 10)}</span>
                </div>

                <div className="form-text-info ui-mt-7 ui-mb-3">
                    <I18n id="forms.cancelRequest.cancelReason.text.label" component="p" />
                </div>

                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.cancelReason.label`} defaultValue="Motivo" />
                    </span>
                </div>
                <div className="input-group textarea">
                    <textarea
                        id="cancelReason"
                        className="form-control"
                        value={cancelReason}
                        onChange={handleChange}
                        minLength={0}
                        maxLength={200}
                    />
                </div>

                <DisclaimerContainer
                    className="important-text ui-mt-8"
                    textSmall
                    justifyText
                    disclaimer="forms.cancelRequest.termsAndConditions.label"
                />

                <Button
                    type="button"
                    label="forms.cancelRequest.sendButton.label"
                    bsStyle="primary"
                    loading={isFetching}
                    onClick={sendCancelRequest}
                    block
                />
            </div>
        </>
    );

    return <>{renderFields()}</>;
};

CancelRequest.propTypes = {
    isFetching: bool.isRequired,
    transaction: shape({}),
    dispatch: func.isRequired,
};

CancelRequest.defaultProps = {
    transaction: {},
};

const mapStateToProps = (state) => ({
    isFetching: formSelectors.getFetching(state),
});

export default compose(withRouter, connect(mapStateToProps))(CancelRequest);
