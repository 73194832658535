import React, { Component } from "react";
import { number, string, func, bool } from "prop-types";
import { compose } from "redux";

import formField from "pages/forms/_components/_fields/_commons/formField";
import withFocus from "pages/_components/withFocus";

class Textarea extends Component {
    static propTypes = {
        maxLength: number.isRequired,
        validationRegularExpresion: string,
        minLength: number,
        setValue: func.isRequired,
        editing: bool.isRequired,
        placeholder: string,
        value: string,
        name: string,
        toggleIsFocused: func,
        idField: string.isRequired,
    };

    static defaultProps = {
        minLength: 0,
        validationRegularExpresion: "",
        placeholder: "",
        value: "",
        name: "",
        toggleIsFocused: null,
    };

    handleChange = ({ target: { value } }) => {
        const { setValue } = this.props;
        setValue(value);
    };

    render() {
        const {
            editing,
            placeholder,
            minLength,
            maxLength,
            value,
            name,
            validationRegularExpresion,
            toggleIsFocused,
            idField,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <textarea
                        id={idField}
                        className="form-control"
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                        onBlur={toggleIsFocused}
                        minLength={minLength}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        pattern={validationRegularExpresion}
                        onFocus={toggleIsFocused}
                    />
                </div>
            );
        }
        return <span className="textarea-view">{value}</span>;
    }
}

export default compose(
    withFocus,
    formField({
        isValidValue: (value, props) => {
            const { validationRegularExpresion } = props;

            if (validationRegularExpresion) {
                try {
                    const regex = new RegExp(validationRegularExpresion);
                    return regex.test(value);
                } catch (e) {
                    return true;
                }
            }

            return true;
        },
    }),
)(Textarea);
