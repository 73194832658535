// Action types
export const types = {
    CLEAN: "recoverUser/CLEAN",

    RECOVERY_USER_CODE_REQUEST: "recoverUser/RECOVERY_USER_CODE_REQUEST",
    RECOVERY_USER_REQUEST: "recoverUser/RECOVER_USER",
    RECOVERY_USER_SUCCESS: "recoverUser/RECOVERY_USER_SUCCESS",
    RECOVERY_USER_CODE_REQUEST_FAILURE_REQUIRE_CAPTCHA:
        "recoverUser/RECOVERY_USER_CODE_REQUEST_FAILURE_REQUIRE_CAPTCHA",
};

// Initial state
export const INITIAL_STATE = {
    showCaptcha: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN:
        case types.RECOVERY_USER_SUCCESS:
            return INITIAL_STATE;
        case types.RECOVERY_USER_CODE_REQUEST_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    recoveryUserSendValidatonCode: (
        documentType,
        documentNumber,
        email,
        secondFactor,
        recaptchaResponse,
        formikBag,
    ) => ({
        type: types.RECOVERY_USER_CODE_REQUEST,
        documentType,
        documentNumber,
        email,
        secondFactor,
        recaptchaResponse,
        formikBag,
    }),
    recoveryUser: (code, newUser, newUserRepeat, formikBag) => ({
        type: types.RECOVERY_USER_REQUEST,
        code,
        newUser,
        newUserRepeat,
        formikBag,
    }),
    recoveryUserCleanUp: () => ({
        type: types.CLEAN,
    }),
};

// Selectors
export const selectors = {
    getShowCaptcha: (state) => state.recoverUser.showCaptcha,
};
