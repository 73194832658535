import React from "react";
import { number, string, func } from "prop-types";

import Image from "pages/_components/Image";
import Button from "pages/_components/Button";

import "./_uploadedFile.scss";

const UploadedFile = ({ nameFile, idFileCharged, onClick }) => {
    const getFileExtension = (fileName) => fileName.split(".").pop();

    const drawAttachmentIcon = (fileName) => {
        if (fileName === "txt") {
            return <Image src="images/fileIconsByExtension/file-txt.svg" />;
        }
        if (fileName === "doc" || fileName === "docx") {
            return <Image src="images/fileIconsByExtension/file-doc.svg" />;
        }
        if (fileName === "xls" || fileName === "xlsx") {
            return <Image src="images/fileIconsByExtension/file-xls.svg" />;
        }
        if (fileName === "ppt" || fileName === "pptx") {
            return <Image src="images/fileIconsByExtension/file-ppt.svg" />;
        }
        if (fileName === "jpg") {
            return <Image src="images/fileIconsByExtension/file-jpg.svg" />;
        }
        if (fileName === "gif") {
            return <Image src="images/fileIconsByExtension/file-gif.svg" />;
        }
        if (fileName === "bmp") {
            return <Image src="images/fileIconsByExtension/file-bmp.svg" />;
        }
        if (fileName === "png") {
            return <Image src="images/fileIconsByExtension/file-png.svg" />;
        }
        return <Image src="images/fileIconsByExtension/file-default.svg" />;
    };

    if (nameFile) {
        return (
            <div className="uploaded-file" key={idFileCharged || nameFile}>
                {drawAttachmentIcon(getFileExtension(nameFile))}
                <span>{nameFile.slice(0, -4)}</span>
                <Button
                    label="global.delete"
                    className="btn-icon-only btn-delete"
                    onClick={onClick}
                    image="images/ui-icons/ui-delete.svg"
                />
            </div>
        );
    }

    return null;
};

UploadedFile.propTypes = {
    nameFile: string.isRequired,
    idFileCharged: number,
    onClick: func.isRequired,
};

UploadedFile.defaultProps = {
    idFileCharged: null,
};

export default UploadedFile;
