import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool, func, string, number, shape, oneOfType, object } from "prop-types";
import * as i18nUtils from "util/i18n";

class AssociateStep3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        account: shape({
            balance: number,
            currency: string,
            idProduct: string,
            number: string,
            productAlias: string,
            productType: string,
        }).isRequired,
        exchangeToken: string,
        fetching: bool,
        invitation: oneOfType([string, object]).isRequired,
    };

    static defaultProps = {
        fetching: true,
        exchangeToken: "",
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    handleClick = () => {
        const { exchangeToken, dispatch, invitationCode } = this.props;

        dispatch(enrollmentActions.associateStep3(invitationCode, exchangeToken));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { account, invitation, fetching } = this.props;

        const info = {
            name: invitation.firstName,
            documentType: i18nUtils.get(`documentType.label.${invitation.documentType}`),
            documentNumber: invitation.documentNumber,
            account: account.accountName,
        };

        return (
            <div className="app-page">
                <div className="app-content">
                    <div className="view-wrapper">
                        <Notification scopeToShow="enrollment/associate/step3" />
                        <div className="view-page">
                            <div className="view-content">
                                <div className="title-header">
                                    <I18n
                                        id="enrollment.associate.step3.title"
                                        component="p"
                                        componentProps={{ className: "ui-body-medium" }}
                                    />
                                </div>
                                <main className="container">
                                    <div className="data-personal">
                                        <div className="data-item">
                                            <I18n id="enrollment.associate.step3.name" component="p" />
                                            <p>{info.name}</p>
                                        </div>

                                        <div className="data-item">
                                            <I18n id="enrollment.associate.step3.documentType" component="p" />
                                            <p>{info.documentType}</p>
                                        </div>

                                        <div className="data-item">
                                            <I18n id="enrollment.associate.step3.documentNumber" component="p" />
                                            <p>{info.documentNumber}</p>
                                        </div>

                                        <div className="data-item">
                                            <I18n id="enrollment.associate.step3.account" component="p" />
                                            <p>{info.account}</p>
                                        </div>
                                    </div>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        onClick={this.handleClick}
                                        type="button"
                                        loading={fetching}
                                        block
                                    />
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    fetching: enrollmentSelectors.getFetching(state),
});

export default connect(mapStateToProps)(withExchangeToken(AssociateStep3));
