import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { shape, node, bool, func } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";

import { actions, selectors } from "reducers/widgets";

import ScheduledTransactionsListItem from "pages/desktop/widgets/_components/ScheduledTransactionsListItem";
import WidgetList from "pages/desktop/widgets/WidgetList/WidgetList";
import WidgetLoading from "pages/_components/WidgetLoading";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import WidgetHeader from "../_components/WidgetHeader";
import Overlay from "../_components/Overlay";

import "./_scheduledTransactions.scss";

const WIDGET_NAME = "scheduledTransactions";

const ScheduledTransactions = ({ closeButton, isFetching, draggableItemProps, isEditable, dispatch }) => {
    const scheduledWidgetRef = useRef();

    const transactionsToShow = config.get("desktop.widgets.scheduledTransactions.view", 5);

    const [widgetListHeight, setWidgetListHeight] = useState(0);

    const uiAutomationProp = { "data-ui-automation": "widgetScheduledTransactions" };

    useEffect(() => {
        if (!isFetching) {
            const parent = scheduledWidgetRef.current;
            const items = parent ? Array.from(parent.querySelectorAll(".table-row-container")) : [];

            if (items.length) {
                let height = 0;
                items.map((item, index) => {
                    if (index < transactionsToShow) {
                        height += item.offsetHeight + 0.39;
                    }
                    return height;
                });

                setWidgetListHeight(height);
            }
        }
    }, [isFetching]);

    useEffect(() => {
        dispatch(actions.listRequest("scheduledTransactions"));
    }, []);

    const renderList = (list) => {
        let scheduledTransactions = [];
        if (list.length) {
            scheduledTransactions = list.map(({ transaction, transactionAmounts }) => {
                const [currency, quantity] = Object.entries(transactionAmounts);

                return {
                    ...transaction,
                    currency,
                    quantity,
                };
            });
        }

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                {list.length ? (
                    <div className="ui-scrollbar" style={{ maxHeight: widgetListHeight }} ref={scheduledWidgetRef}>
                        <div className="table" aria-hidden={isEditable}>
                            <div className="table-body">
                                {scheduledTransactions.map((item) => (
                                    <ScheduledTransactionsListItem key={item.idTransaction} {...item} />
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    !isFetching && (
                        <div aria-hidden={isEditable}>
                            <NoProduct
                                text={`desktop.widgets.${WIDGET_NAME}.empty`}
                                imagePath="images/coloredIcons/component.svg"
                            />
                        </div>
                    )
                )}
            </WidgetLoading>
        );
    };

    return (
        <WidgetList name={WIDGET_NAME} shouldMapList={false}>
            {(list) => (
                <div
                    role={isEditable ? "button" : ""}
                    className="newWidget"
                    {...uiAutomationProp}
                    {...draggableItemProps}
                    aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                    <div className="widget--scheduledTransactions">
                        <WidgetHeader
                            title={i18nUtils.get("desktop.widgets.scheduledTransactions.title")}
                            action={closeButton}
                        />
                        {renderList(list)}
                        <Overlay />
                    </div>
                </div>
            )}
        </WidgetList>
    );
};

ScheduledTransactions.propTypes = {
    closeButton: node,
    isFetching: bool,
    draggableItemProps: shape({}).isRequired,
    isEditable: bool,
    dispatch: func.isRequired,
};

ScheduledTransactions.defaultProps = {
    closeButton: null,
    isFetching: false,
    isEditable: false,
};

const mapStateToProps = (state) => {
    const { isFetching } = selectors.getWidget(state, WIDGET_NAME);

    return { isFetching };
};

export default connect(mapStateToProps)(ScheduledTransactions);
