import { call, put, takeLatest } from "redux-saga/effects";

import * as campaigns from "middleware/campaigns";
import { types } from "reducers/campaigns";

const sagas = [
    takeLatest(types.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(types.LOAD_CAMPAIGN_IMAGE, loadCampaignImage),
    takeLatest(types.DISMISS_CAMPAIGN_PERMANENT_REQUEST, dismissPermanentCampaign),
    takeLatest(types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS, loadCampaignImage),
];

export default sagas;

function* loadCampaign({ section }) {
    try {
        const response = yield call(campaigns.fetchCampaign, section);
        if (response.status !== 304) {
            const { data } = response.data;

            yield put({ type: types.LOAD_CAMPAIGN_IMAGE, item: data.campaign });
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function getFittingImage(imageList) {
    let imageType;
    if (window.innerWidth < 769) {
        imageType = "MOBILE";
    } else if (window.innerWidth < 993) {
        imageType = "NARROW";
    } else {
        imageType = "NORMAL";
    }

    return imageList.find((image) => image.imageSize.display === imageType);
}

function* loadCampaignImage({ item }) {
    try {
        const image = getFittingImage(item.imageList);
        if (!image) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
        } else {
            const { idImage, idCampaign } = image;
            const response = yield call(campaigns.fetchCampaignImage, { idImage, idCampaign });

            if (response.status !== 304) {
                const { data } = response.data;
                yield put({ type: types.LOAD_CAMPAIGN_SUCCESS, item: { ...item, image: data.image.content } });
            }
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* dismissPermanentCampaign({ idCampaign, section }) {
    try {
        const response = yield call(campaigns.dismissPermanentCampaign, { idCampaign });

        if (response.data.code === "COR000I") {
            try {
                const responseRemainingCampaigns = yield call(campaigns.fetchCampaign, section);
                if (responseRemainingCampaigns.status !== 304) {
                    const { data } = responseRemainingCampaigns.data;
                    // yield put({ type: types.LOAD_CAMPAIGN_IMAGE, item: data.campaign });
                    yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS, item: data.campaign });
                }
            } catch (err) {
                yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
            }
        } else {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
        }
    } catch (err) {
        yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
    }
}
