import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as footerSelectors } from "reducers/footer";
import * as configUtils from "util/config";
import HeaderLogin from "./header/HeaderLogin";

class SupportInfo extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        hasActiveSession: bool,
        dispatch: func.isRequired,
        currentPath: string.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    backButtonAction = () => {
        const { dispatch, currentPath } = this.props;
        dispatch(routerActions.replace(currentPath));
    };

    renderHeader = () => {
        const { isMobile, hasActiveSession } = this.props;
        if (!hasActiveSession) {
            return <HeaderLogin onHeaderClose={this.backButtonAction} showBack />;
        }
        if (isMobile) {
            return (
                <Head
                    showLogo
                    arrowWhite
                    onBack={this.backButtonAction}
                    additionalClassName="blue-main-header-mobile"
                />
            );
        }
        return <Head />;
    };

    render() {
        const { hasActiveSession, isMobile } = this.props;

        let disclaimerMaxHeight = "";

        if (!hasActiveSession) {
            if (isMobile) {
                disclaimerMaxHeight = "calc(100vh - env(safe-area-inset-top) - 250px)";
            } else {
                disclaimerMaxHeight = "calc(100vh - 280px)";
            }
        }

        return (
            <div className={!hasActiveSession ? "login-content" : ""}>
                {this.renderHeader()}
                <MainContainer>
                    <div className="documents-container">
                        <div className={!hasActiveSession ? "ui-mb-7 ui-text-center" : "title-left-header"}>
                            <I18n id="global.support" component="h1" />
                        </div>
                        <DisclaimerContainer maxHeight={disclaimerMaxHeight} justifyText={false}>
                            <div className="ui-mb-7">
                                <Image src="images/coloredIcons/support.svg" className="ui-mb-3" />
                                <div className="ui-dflex-center ui-text-center">
                                    <I18n
                                        componentProps={{ className: "fw-bold ui" }}
                                        EMAIL={configUtils.get("client.support.email")}
                                        PHONE_NUMBER={configUtils.get("client.support.phoneNumber")}
                                        id="supportInfo.textInfo"
                                    />
                                </div>
                            </div>
                            <I18n id="supportInfo.suggestions.title" component="p" />
                            <ul>
                                <I18n
                                    id="supportInfo.suggestions.item1"
                                    component="li"
                                    componentProps={{ className: "ui-mb-7" }}
                                />
                                <li className="ui-mb-7">
                                    <I18n
                                        id="supportInfo.suggestions.item2"
                                        componentProps={{ className: "ui-mb-3" }}
                                    />
                                    <ul>
                                        <I18n
                                            id="supportInfo.suggestions.item2.subItem1"
                                            component="li"
                                            componentProps={{ className: "ui-mb-3" }}
                                        />
                                        <I18n id="supportInfo.suggestions.item2.subItem2" component="li" />
                                    </ul>
                                </li>
                                <I18n id="supportInfo.suggestions.item3" component="li" />
                            </ul>
                        </DisclaimerContainer>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentPath: footerSelectors.getCurrentPath(state),
});

export default connect(mapStateToProps)(SupportInfo);
