import * as API from "middleware/api";

export const getDocumentTypes = (filter) =>
    API.executeAnonymous("/misc.listDocumentTypes", {
        idCountry: "AR",
        filter,
    });
export const getCertificateCalfCemUrl = () => API.executeWithAccessToken("/certificateCalfCem.Url");
export const getInvoiceMipymeConsultationUrl = () => API.executeWithAccessToken("/invoiceMipymeConsultation.Url");
export const getJumpUrl = () => API.executeWithAccessToken("/position.jump");
export const getEcheqUrl = () => API.executeWithAccessToken("/check.jumping");
export const getDigitalSignatureUrl = () => API.executeWithAccessToken("/digital.signature");
