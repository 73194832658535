import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { string, bool, shape, func } from "prop-types";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import GeneralMsg from "pages/_components/GeneralMsg";
import Image from "pages/_components/Image";

import * as i18n from "util/i18n";

import "./_error.scss";

const Error = ({ dispatch, hasActiveSession, location }) => {
    const { code } = location;

    useEffect(() => {
        if (code === "COR041E") {
            dispatch(
                loginActions.goBackToLoginAndShowMessage(
                    i18n.get("administration.restrictions.unavailableEnvironment"),
                ),
            );
        }
        window.common.hideSplashScreen();
    }, []);

    if (!code) {
        return <Redirect to="/desktop" />;
    }

    let errorTitle = i18n.get("error.title");
    if (!errorTitle || errorTitle === "*error.title*") {
        errorTitle = "Ocurrió un error inesperado.";
    }

    let errorMessage = i18n.get("global.error.message");
    if (!errorMessage || errorMessage === "*global.error.message*") {
        errorMessage = "Por favor, intentalo nuevamente más tarde.";
    }

    let errorBack = i18n.get("error.back");
    if (!errorBack || errorBack === "error.back") {
        errorBack = "Volver";
    }

    const btnHandlerOnClick = () => {
        dispatch(push(hasActiveSession ? "/desktop" : "/"));
    };

    const button = code !== "COR086E" && (
        <Button className="btn btn-primary btn-block" defaultLabelText={errorBack} onClick={btnHandlerOnClick} />
    );

    return (
        <ErrorBoundary>
            <div className="error-page-container">
                <GeneralMsg
                    title={errorTitle}
                    description={errorMessage}
                    imagePath="images/coloredIcons/no-connection.svg"
                    callToAction={button}
                />

                <Link className="navbar-brand" to="/desktop">
                    <Image src="images/logoMariva.svg" className="svg-big-ico" />
                </Link>
            </div>
        </ErrorBoundary>
    );
};

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(Error);

Error.propTypes = {
    dispatch: func.isRequired,
    hasActiveSession: bool.isRequired,
    location: shape({
        code: string,
        message: string,
    }).isRequired,
};
