import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";
import { actions as notificationModalActions } from "reducers/notificationModal";

import * as i18n from "util/i18n";

import Loader from "pages/_components/Loader";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

import ChatLayout from "pages/communications/_components/ChatLayout";

const ButtonWrapper = ({ onSubmit, onDelete, isDesktop }) => (
    <div className="btn-wrapper ui-mt-7">
        {isDesktop ? (
            <>
                {onDelete && (
                    <Button
                        className="btn-outline"
                        image="images/delete-message.svg"
                        label="communications.message.delete.label"
                        onClick={onDelete}
                    />
                )}
                <Button bsStyle="primary" label="communications.message.reply.label" onClick={onSubmit} />
            </>
        ) : (
            <>
                <Button
                    bsStyle="primary"
                    label="communications.message.reply.label"
                    onClick={onSubmit}
                    className="btn-block"
                />
                {onDelete && (
                    <Button
                        className="btn-outline"
                        image="images/delete-message.svg"
                        label="communications.message.delete.label"
                        onClick={onDelete}
                    />
                )}
            </>
        )}
    </div>
);

ButtonWrapper.propTypes = {
    onSubmit: func.isRequired,
    onDelete: func,
    isDesktop: bool.isRequired,
};

ButtonWrapper.defaultProps = {
    onDelete: null,
};

const MessageHeader = ({ title, onClose, isMobile }) => (
    <div className={`${isMobile ? "container" : ""} flex-container d-flex-align-items-flexstart`}>
        <div className="view-title message-subject">
            <h3>{title}</h3>
        </div>
        {onClose && (
            <Button
                className="btn-only-icon btn-link "
                image="images/cross.svg"
                label="global.close"
                onClick={onClose}
            />
        )}
    </div>
);

MessageHeader.propTypes = {
    title: string.isRequired,
    onClose: func,
    isMobile: bool.isRequired,
};

MessageHeader.defaultProps = {
    onClose: null,
};
class Reader extends Component {
    static propTypes = {
        attachments: arrayOf({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        dispatch: func.isRequired,
        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        isDesktop: bool.isRequired,
        isFetching: bool,
        close: func.isRequired,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
        isFetching: true,
    };

    handleDeleteClick = () => {
        const { detail, dispatch, isDesktop } = this.props;

        dispatch(
            notificationModalActions.showConfirmation(
                i18n.get("communications.deleteMessage.confirmationModal.title"),
                "",
                () => dispatch(communicationActions.deleteRequest(detail[0].idCommunication, isDesktop)),
            ),
        );
    };

    handleReplyClick = () => {
        const { detail, dispatch, isDesktop } = this.props;

        if (isDesktop) {
            dispatch(communicationActions.detailRequest(detail[0].idCommunication));
            dispatch(communicationsActions.showReplyPanel());
        } else {
            dispatch(push(`/communications/reply/${detail[0].idCommunication}`));
        }
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationsActions.hideAllPanels());
    };

    handleDownload = (idFile) => () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.downloadAttachmentRequest(idFile));
    };

    scrollToBottom = () => {
        const { isDesktop } = this.props;

        const nodes = document.getElementsByClassName("view-content");
        let objDiv = nodes[nodes.length - 1];
        if (isDesktop) {
            objDiv = document.getElementById("chatLayout");
        }
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    render() {
        const { detail, attachments, isDesktop, isFetching, close } = this.props;

        return (
            (isFetching && <Loader />) ||
            (detail &&
                (isDesktop ? (
                    <div className="above-the-fold" style={{ height: "100%" }}>
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <MessageHeader title={detail[0].subject} onClose={this.handleCloseClick} />
                            <div
                                id="chatLayout"
                                style={{
                                    overflow: "auto",
                                }}>
                                <ChatLayout
                                    detail={detail}
                                    attachments={attachments}
                                    scrollToBottom={this.scrollToBottom}
                                    handleDownloadAttachment={this.handleDownload}
                                />
                            </div>
                            <ButtonWrapper
                                onSubmit={this.handleReplyClick}
                                onDelete={this.handleDeleteClick}
                                isDesktop={isDesktop}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="form-content mailing">
                        <Container className="container--layout container--scroll">
                            <Col className="col col-12">
                                <MessageHeader title={detail[0].subject} onClose={close} />
                            </Col>
                            <Col className="col col-12 open-conversation">
                                <ChatLayout
                                    detail={detail}
                                    attachments={attachments}
                                    scrollToBottom={this.scrollToBottom}
                                    handleDownloadAttachment={this.handleDownload}
                                />
                            </Col>
                        </Container>
                        <Container className="container--layout">
                            <Col className="col col-12">
                                <ButtonWrapper
                                    onSubmit={this.handleReplyClick}
                                    onDelete={this.handleDeleteClick}
                                    isDesktop={isDesktop}
                                />
                            </Col>
                        </Container>
                    </div>
                )))
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    attachments: communicationSelectors.attachments(state),
    selectedIndex: communicationSelectors.getSelectedIndex(state),
    isFetching: communicationSelectors.isFetching(state),
});

export default connect(mapStateToProps)(Reader);
