import * as qrMiddleware from "middleware/qr";
import moment from "moment";
import { push } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { actions as qrActions, types } from "reducers/qr";
import { call, put, takeLatest } from "redux-saga/effects";
import { openLink } from "util/browser";
import { isMobileNative } from "util/device";
import { downloadImageBase64, downloadImageBase64Mobile } from "util/download";
import * as i18n from "util/i18n";

const notificationScopes = ["accounts", "account/details", "form", "qrChargesCertificates"];

const sagas = [
    takeLatest(types.GET_QR_ACCOUNT_REQUEST, getQrAccountRequest),
    takeLatest(types.DOWNLOAD_QR_ACCOUNT_REQUEST, downloadQrAccountRequest),
    takeLatest(types.QR_ACCOUNT_LIST_REQUEST, getAccountListRequest),
    takeLatest(types.QR_FILTER_CHARGES_REQUEST, filterChargesRequest),
    takeLatest(types.QR_FILTER_CERTIFICATES_REQUEST, filterCertificatesRequest),
    takeLatest(types.QR_GET_CERTIFICATE_REQUEST, getQrCertificateRequest),
    takeLatest(types.QR_VALIDATION_TYC, validateTyC),
];

export default sagas;

function* getQrAccountRequest({ cbuAccount, idAccount }) {
    const { type, data } = yield call(qrMiddleware.getQrAccountRequest, cbuAccount, idAccount);

    if (type === "W") {
        yield put({ type: types.GET_QR_ACCOUNT_FAILURE });

        if (data.data.hasPendingQrEnrollment) {
            yield put(
                notificationModalActions.showNotification(
                    "forms.account.qr.download.showqr.requireSignatures",
                    "warningIcon.svg",
                ),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("forms.account.qr.download.showqr.backendService.error"),
                    "error",
                    notificationScopes,
                ),
            );
        }
    } else {
        const { enableEnrollment, qrBase64, urlQR } = data.data;
        if (enableEnrollment) {
            yield put({ type: types.GET_QR_ACCOUNT_FAILURE });
            yield put(push("/formCustom/enrollmentqrAccount"));
        } else if (qrBase64 && qrBase64.length > 0) {
            yield put({ type: types.GET_QR_ACCOUNT_SUCCESS, qrAccountBase64: qrBase64, urlQR });
        } else {
            yield put({ type: types.GET_QR_ACCOUNT_FAILURE });
            yield put(
                notificationActions.showNotification(
                    i18n.get("forms.account.qr.download.showqr.backendService.error"),
                    "error",
                    notificationScopes,
                ),
            );
        }
    }
}

function* downloadQrAccountRequest({ cbuAccount, qrBase64 }) {
    if (isMobileNative) {
        try {
            const response = yield call(downloadImageBase64Mobile, cbuAccount, qrBase64);
            if (response) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("accounts.qr.download.success"),
                        "success",
                        notificationScopes,
                    ),
                );
            }
        } catch (error) {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    } else {
        downloadImageBase64(cbuAccount, qrBase64);
        yield put(
            notificationActions.showNotification(
                i18n.get("accounts.qr.download.success"),
                "success",
                notificationScopes,
            ),
        );
    }

    yield put({ type: types.DOWNLOAD_QR_ACCOUNT_SUCCESS });
}

function* getAccountListRequest() {
    const { type, data } = yield call(qrMiddleware.getAccountListRequest);

    if (type === "W") {
        yield put({ type: types.QR_ACCOUNT_LIST_FAILURE });

        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
        );
    } else {
        const { accountList } = data.data;

        if (accountList) {
            yield put({ type: types.QR_ACCOUNT_LIST_SUCCESS, accountList });

            const account = accountList[0];
            const dateFrom = moment().toDate();
            const dateTo = moment().toDate();
            yield put(qrActions.filterChargesForQR(account, "ALL", dateFrom, dateTo));
        } else {
            yield put({ type: types.QR_ACCOUNT_LIST_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    }
}

function* filterChargesRequest({ selectedAccount, status, dateFrom, dateTo }) {
    const { type, data } = yield call(
        qrMiddleware.filterCharges,
        selectedAccount.idProduct,
        selectedAccount.cbu,
        selectedAccount.client.idClient,
        status,
        dateFrom,
        dateTo,
    );

    yield put(qrActions.setFilters({ selectedAccount, status, dateFrom, dateTo, toggleState: "charges" }));

    if (type === "W") {
        yield put({ type: types.QR_FILTER_CHARGES_FAILURE });

        if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", notificationScopes));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    } else {
        const { chargeList, moreItems, pageNumber } = data.data;

        if (chargeList) {
            yield put({ type: types.QR_FILTER_CHARGES_SUCCESS, chargeList, moreItems, pageNumber });
        } else {
            yield put({ type: types.QR_FILTER_CHARGES_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    }
}

function* filterCertificatesRequest({ selectedAccount, dateFrom, dateTo }) {
    const { type, data } = yield call(
        qrMiddleware.filterCertificates,
        selectedAccount.idProduct,
        selectedAccount.cbu,
        selectedAccount.client.idClient,
        dateFrom,
        dateTo,
    );

    yield put(qrActions.setFilters({ selectedAccount, status: "ALL", dateFrom, dateTo, toggleState: "certificates" }));

    if (type === "W") {
        yield put({ type: types.QR_FILTER_CERTIFICATES_FAILURE });

        if (data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(data.data.NO_FIELD, "error", notificationScopes));
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    } else {
        const { certificateList, moreItems, pageNumber } = data.data;

        if (certificateList) {
            yield put({ type: types.QR_FILTER_CERTIFICATES_SUCCESS, certificateList, moreItems, pageNumber });
        } else {
            yield put({ type: types.QR_FILTER_CERTIFICATES_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    }
}

function* getQrCertificateRequest({ uuid, idAccount }) {
    const { type, data } = yield call(qrMiddleware.getQrCertificate, uuid, idAccount);

    if (type === "W") {
        yield put({ type: types.QR_GET_CERTIFICATE_FAILURE });

        yield put(
            notificationActions.showNotification(
                i18n.get("forms.account.qr.download.showqr.backendService.error"),
                "error",
                notificationScopes,
            ),
        );
    } else {
        const { urlCertificate } = data.data;
        if (urlCertificate) {
            yield put({ type: types.QR_GET_CERTIFICATE_SUCCESS, urlCertificate });
            if (isMobileNative) {
                openLink(urlCertificate).then();
            } else {
                window.open(urlCertificate, "_blank").focus();
            }
        } else {
            yield put({ type: types.QR_GET_CERTIFICATE_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", notificationScopes),
            );
        }
    }
}

function* validateTyC({ cbuAccount }) {
    const { type, data } = yield call(qrMiddleware.validateTyC, cbuAccount);
    if (type === "W") {
        yield put(push("/desktop"));
        yield put(notificationModalActions.showNotification(data.message, "warningIcon.svg"));
    } else if (data.data.enableEnrollment) {
        yield put(push("/formCustom/enrollmentqrAccount"));
    } else if (data.data.hasPendingQrEnrollment) {
        yield put(push("/desktop"));
        yield put(
            notificationModalActions.showNotification(
                "forms.account.qr.download.showqr.requireSignatures",
                "warningIcon.svg",
            ),
        );
    }
}
