import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import types from "reducers/types/form";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import * as stringUtils from "util/string";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.rescueFromCustody.send";
const ID_FORM = "forms.echeqs.rescueFromCustody";

const RescueFromCustodyForm = (props) => {
    const { echeq, mode, dispatch, location, data, transaction } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};
    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const echeqSelected = Object.keys(echeq).length > 0 ? echeq : data?.echeq;

    const {
        cheque_id,
        cheque_numero,
        cheque_tipo,
        cheque_caracter,
        beneficiario_nombre,
        estado,
        monto,
        fecha_pago,
        fecha_ult_modif,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
    } = echeqSelected || {};

    const { idTransactionStatus, idTransaction } = transaction;

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.rescueFromCustody.message`} />
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label ui-fw-bold" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqId.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.type.label`} componentProps={{ className: "data-label" }} />
                <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitEmitter.label`} componentProps={{ className: "data-label" }} />
                <span>{stringUtils.formatCuit(data?.emisor_cuit || emisor_cuit)}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.emitter.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.beneficiary.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.estado || estado}</span>
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <span>
                    <FormattedDate date={data?.fecha_pago || fecha_pago} />
                </span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.depositDate.label`} componentProps={{ className: "data-label" }} />
                <span>
                    {data?.fecha_deposito || fecha_ult_modif ? (
                        <FormattedDate date={data?.fecha_deposito || fecha_ult_modif} />
                    ) : (
                        ""
                    )}
                </span>
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            echeq: echeqSelected,
            cbu_deposito: data?.cbu_deposito || echeqSelected?.cbu_deposito || echeqSelected?.cbu_custodia,
            cheque_id: data?.cheque_id || echeqSelected?.cheque_id,
            cheque_numero: data?.cheque_numero || echeqSelected?.cheque_numero,
            fecha_emision: data?.fecha_emision || echeqSelected?.fecha_emision,
            cheque_tipo: data?.cheque_tipo || echeqSelected?.cheque_tipo,
            amount: data?.amount || {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
            emisor_cuenta: data?.emisor_cuenta || echeqSelected?.emisor_cuenta,
            emisor_razon_social: data?.emisor_razon_social || echeqSelected?.emisor_razon_social,
            emisor_cuit: data?.emisor_cuit || echeqSelected?.emisor_cuit,
            emisor_banco_codigo: data?.emisor_banco_codigo || echeqSelected?.emisor_banco_codigo,
            emisor_sucursal_codigo: data?.emisor_sucursal_codigo || echeqSelected?.emisor_sucursal_codigo,
            emisor_sucursal_cp: data?.emisor_sucursal_cp || echeqSelected?.emisor_sucursal_cp,
            fecha_pago: data?.fecha_pago || echeqSelected?.fecha_pago,
            beneficiario_nombre: data?.beneficiario_nombre || echeqSelected?.tenencia_beneficiario_nombre,
            beneficiario_documento_tipo:
                data?.beneficiario_documento_tipo || echeqSelected?.tenencia_beneficiario_documento_tipo,
            beneficiario_documento: data?.beneficiario_documento || echeqSelected?.tenencia_beneficiario_documento,
            cheque_caracter: data?.cheque_caracter || echeqSelected?.cheque_caracter,
            fecha_deposito: data?.fecha_deposito || echeq.fecha_ult_modif,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

RescueFromCustodyForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};
RescueFromCustodyForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(RescueFromCustodyForm);
