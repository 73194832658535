import React from "react";
import { bool, shape } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import getSubStatesByState from "util/echeqs";

function EcheqSubStates({ echeq, isDesktop, echeqSubStates, isForDetail }) {
    const subStates = getSubStatesByState(echeq, echeqSubStates);

    if (isForDetail) {
        return (
            <mark className="mark-subtitle">
                <small>
                    {`${echeq?.estado}`}
                    {subStates.length > 0 && subStates.map((substatus) => <>{`-${substatus.toUpperCase()}`}</>)}
                </small>
            </mark>
        );
    }

    if (isDesktop) {
        return (
            <div className="echeq-subStatuses">
                {subStates.length > 0 &&
                    subStates.map((substatus) => <mark key={substatus}>{substatus.toUpperCase()}</mark>)}
            </div>
        );
    }

    return (
        <div className="echeq-subStatuses">
            {subStates.length > 0 &&
                subStates.map((substatus) => (
                    <mark key={substatus}>
                        <span>{substatus.toUpperCase()}</span>
                    </mark>
                ))}
        </div>
    );
}

EcheqSubStates.propTypes = {
    echeq: shape({}).isRequired,
    isDesktop: bool,
    echeqSubStates: shape(),
    isForDetail: bool,
};

EcheqSubStates.defaultProps = {
    isDesktop: false,
    echeqSubStates: null,
    isForDetail: false,
};

const mapStateToProps = (state) => ({
    echeqSubStates: sessionSelectors.getEcheqSubStates(state),
});
export default connect(mapStateToProps)(EcheqSubStates);
