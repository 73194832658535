import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";

import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func } from "prop-types";

const FORM_ID = "settings.registerToken";

class RegisterToken extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        // obtain user's current email
        dispatch(settingsActions.getTokenData());
    }

    registerTokenForm = () => {
        const { isSubmitting } = this.props;

        return (
            <>
                <div className="container-center">
                    <div className="title-left-header">
                        <I18n id="settings.registerToken.title" component="h1" />
                    </div>
                    <Form className="form-content" noValidate="novalidate">
                        <div className="form-text-info">
                            <I18n id="settings.registerToken.info" component="p" />
                        </div>

                        <Field idForm={FORM_ID} name="password" component={Credential} type="password" />

                        <Button block type="submit" bsStyle="primary" label="global.continue" loading={isSubmitting} />
                    </Form>
                </div>
            </>
        );
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Fragment>
                <Head
                    headerClassName={!isMobile && "mt-2"}
                    showLogo={isMobile}
                    arrowWhite={isMobile}
                    additionalClassName={isMobile && "blue-main-header-mobile"}
                />

                <MainContainer>
                    <Notification scopeToShow="registerToken" />
                    {this.registerTokenForm()}
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasOtp: settingsSelectors.getHasOtp(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: ({ password }, formikBag) => {
            const { hasOtp } = formikBag.props;
            formikBag.props.dispatch(settingsActions.registerToken(hasOtp, password, formikBag));
        },
    }),
)(RegisterToken);
