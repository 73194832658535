import React, { Component, Fragment } from "react";
import { string, int, shape } from "prop-types";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";

class DetailHeadTitle extends Component {
    static propTypes = {
        loan: shape({
            idEnvironment: int,
            idProduct: string,
            productType: string.isRequired,
            label: string,
            shortLabel: string,
            extraInfo: string,
            number: string.isRequired,
            nextDueDate: string,
            constitutedDate: string,
            currency: string.isRequired,
            extraInfoMarked: string,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    renderProductType = (number, productType) => (
        <Fragment>
            <span>
                <I18n id={`loans.productType.${productType}`} />
            </span>
            <span>{number}</span>
        </Fragment>
    );

    render() {
        const { loan } = this.props;
        const { operationCode, productAlias, productType, currency } = loan;

        return (
            <Fragment>
                <div className="title-left-header">
                    <div className="visually-hidden" ref={this.titleRef} tabIndex="0">
                        <span>{`${productType} ${currency}, ${productAlias || operationCode}`}</span>
                    </div>
                    <h1 className="ellipsis">{i18n.get("loan.detail.title")}</h1>
                </div>
            </Fragment>
        );
    }
}

export default DetailHeadTitle;
