import React from "react";
import BigModal from "pages/_components/BigModal/BigModal";
import { bool, func, shape, string } from "prop-types";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import {
    selectors as frequentRecipientsSelectors,
    actions as frequentRecipientsActions,
} from "reducers/frequentRecipients";
import * as i18nUtils from "util/i18n";
import { connect } from "react-redux";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import Loader from "pages/_components/Loader";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import RecipientInputs from "./RecipientInputs";

const ID_ACTIVITY = "frequentDestination.create.send";

const AddRecipientModal = (props) => {
    const {
        dispatch,
        isMobile,
        currentLang,
        transaction,
        fromBackoffice,
        fetchingRecipient,
        recipientData,
        showAddBeneficiaryModal,
    } = props;

    const handleShowAddBeneficiaryModal = () => {
        dispatch(frequentRecipientsActions.setShowBeneficiaryModal(false));
    };

    const renderForm = () => {
        const genericProps = {
            mode: "edit",
            lang: currentLang,
            idTransactionTicket: transaction?.idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        return (
            <Form className="form-content">
                <Notification scopeToShow="searchRecipient" />
                <RecipientInputs
                    genericProps={genericProps}
                    recipientData={recipientData}
                    idForm="frequentDestination.create"
                />

                <Field idForm="form.credential" name="otp" component={Credential} type="otp" />

                <div className="form-footer">
                    <Button
                        block={isMobile}
                        label="global.cancel"
                        bsStyle="outline"
                        onClick={() => handleShowAddBeneficiaryModal()}
                    />
                    <Button block type="submit" bsStyle="primary" label="global.continue" />
                </div>
            </Form>
        );
    };

    return (
        <BigModal
            className="massive-transfers-create-beneficiary"
            showModal={showAddBeneficiaryModal}
            handleShowModal={() => handleShowAddBeneficiaryModal()}
            modalWidth="548px"
            modalTitle="forms.pay.transfers.massive.payment.create.modalBeneficiary.title"
            isMobile={isMobile}>
            {fetchingRecipient ? <Loader /> : renderForm()}
        </BigModal>
    );
};

AddRecipientModal.propTypes = {
    isMobile: bool.isRequired,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    fromBackoffice: bool,
    fetchingRecipient: bool.isRequired,
    recipientData: shape({}).isRequired,
    showAddBeneficiaryModal: bool.isRequired,
    dispatch: func.isRequired,
};

AddRecipientModal.defaultProps = {
    fromBackoffice: false,
};

const mapStateToProps = (state) => ({
    currentLang: i18nSelectors.getLang(state),
    transaction: formSelectors.getTransaction(state),
    fetchingRecipient: frequentRecipientsSelectors.getFetching(state),
    recipientData: frequentRecipientsSelectors.getRecipientFound(state),
    showAddBeneficiaryModal: frequentRecipientsSelectors.getShowAddBeneficiaryModal(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: "",
        }),
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    otp: Yup.string().required(i18nUtils.get(`form.credential.otp.required`)),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const {
                recipientName,
                cuit,
                entity,
                number,
                currency,
                destinationType,
                destinationIdentifier,
                reference,
                email,
                otp,
            } = values;

            const dataToSend = {
                recipientName,
                cuit,
                entity,
                number,
                currency,
                destinationType,
                destinationIdentifier,
                reference,
                email,
            };

            const credentials = {
                otp,
            };

            const { dispatch } = formikBag.props;

            dispatch(frequentRecipientsActions.createRecipientRequest(dataToSend, credentials, formikBag, true));
        },
    }),
)(AddRecipientModal);
