import React, { Component } from "react";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Yup from "yup";
import { actions as frecuentRecipients } from "../../../reducers/frequentRecipients";
import * as config from "../../../util/config";
import * as FormFieldsComponents from "../../forms/_components/_fields/Index";
import * as maskUtils from "util/mask";

const ID_ACTIVITY = "frequentDestination.create.send";

class FindRecipient extends Component {
    destinationTypes = config
        .get("frequentDestination.accountIdentifier")
        .split("|")
        ?.map((id) => ({
            id,
            label: i18n.get(`forms.frequentDestination.search.destinationType.${id}.label`),
        }));

    static propTypes = {
        mode: string.isRequired,
        history: func.isRequired,
        isMobile: bool.isRequired,
        currentLang: string.isRequired,
        data: shape({}).isRequired,
        fromBackoffice: bool,
        preData: shape({}),
        useDefaultSubmit: bool,
        isSubmitting: bool,
    };

    static defaultProps = {
        fromBackoffice: false,
        preData: null,
        useDefaultSubmit: true,
        isSubmitting: false,
    };

    renderHeader = () => {
        const { history, isMobile } = this.props;

        return (
            <Head
                headerClassName={!isMobile && "mt-2"}
                onBack={history.goBack}
                showLogo={isMobile}
                arrowWhite={isMobile}
                additionalClassName={isMobile && "blue-main-header-mobile"}
            />
        );
    };

    renderFindRecipientForm = () => {
        const { currentLang, data, fromBackoffice, isSubmitting, mode, isMobile } = this.props;

        const genericProps = {
            lang: currentLang,
            isRequired: true,
            mode,
            fromBackoffice,
            idActivity: ID_ACTIVITY,
        };

        return (
            <div className="container-center">
                <div className="title-left-header">
                    <I18n id="forms.frequentDestination.create.title" component="h1" />
                </div>
                <Form className="form-content">
                    <div className="form-group--mixed-field">
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={this.destinationTypes}
                            key="destinationType"
                            name="destinationType"
                            renderAs="combo"
                            value={data.destinationType || [this.destinationTypes[0].id]}
                            additionalClassName="recipientType-selector"
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Text}
                            key="destinationIdentifierS"
                            name="destinationIdentifierS"
                            maxLength="22"
                            value={data?.destinationIdentifierS}
                            idField="destinationType"
                        />
                    </div>

                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="cuit"
                        name="cuit"
                        idField="cuit"
                        mask={maskUtils.cuitMask}
                        value={data?.cuit}
                    />
                    <div className="form-footer">
                        <Button
                            label="global.search"
                            loading={isSubmitting}
                            type="submit"
                            bsStyle="primary"
                            block={isMobile}
                        />
                    </div>
                </Form>
            </div>
        );
    };

    render() {
        return (
            <>
                <Notification scopeToShow="searchRecipient" />
                {this.renderHeader()}
                <MainContainer>{this.renderFindRecipientForm()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    mode: "edit",
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
});

export default compose(
    connect(mapStateToProps),
    withRouter,
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ destinationIdentifierS: "", cuit: "" }),
        validationSchema: () =>
            Yup.object().shape({
                destinationIdentifierS: Yup.string().required(
                    i18n.get("forms.frequentDestination.validation.destinationIdentifier.required"),
                ),
                cuit: Yup.string().required(i18n.get("forms.frequentDestination.validation.cuit.required")),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                frecuentRecipients.searchRecipientRequest(
                    values.destinationType,
                    values.destinationIdentifierS,
                    values.cuit,
                    formikBag,
                ),
            );
        },
    }),
)(FindRecipient);
