import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, number, array, oneOfType, shape } from "prop-types";

import { selectors as investmentSelectors, actions as investmentActions } from "reducers/investments";
import { selectors as sessionSelectors } from "reducers/session";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Table from "pages/_components/Table";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import NoDataContainer from "components/NoDataContainer/NoDataContainer";
import * as dateUtils from "util/date";
import Deposit from "./Deposit";

class Deposits extends Component {
    static propTypes = {
        deposits: oneOfType([array]),
        dispatch: func.isRequired,
        fetching: bool,
        isMobile: bool,
        history: func.isRequired,
        pageNumber: number.isRequired,
        moreDeposits: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
        deposits: [],
        isMobile: false,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(investmentActions.loadListRequest());
    };

    componentWillMount = () => {
        const { dispatch } = this.props;
        dispatch(investmentActions.resetPageNumber());
    };

    renderHeader = () => {
        const { isMobile, deposits, activeEnvironment } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }
        if (activeEnvironment.permissions.fixedTermDepositOpening && deposits.length > 1) {
            return (
                <Head
                    addLinkToLabel="fixedTermDeposit.new"
                    addLinkTo="/formCustom/createFixedTermDeposit"
                    showPlusSecondary
                    accessibilityTextId="menu.investments.listFixedTermDeposits"
                />
            );
        }
        return <Head accessibilityTextId="menu.investments.listFixedTermDeposits" />;
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/formCustom/createFixedTermDeposit"));
    };

    handleCancelRenewal = (deposit) => {
        const { idProduct, operationOrder, operationCode, descriptionSIOC } = deposit;
        const { dispatch, history } = this.props;
        const amount = {
            currency: deposit.currency,
            quantity: deposit.amount,
        };
        const dueDate = dateUtils.parseServiceDate(deposit.dueDate);
        const openDate = dateUtils.parseServiceDate(deposit.openDate);
        const payLoad = {
            idForm: "investments.listFixedTermDeposits.cancelRenewal",
            idActivity: "fixedTermDeposits.cancelRenewal.send",
            values: { idProduct, operationOrder, operationCode, descriptionSIOC, openDate, dueDate, amount },
        };
        dispatch(investmentActions.cancelDepositRenewalPreview(payLoad));
        history.push("/formCustom/cancelDepositRenewal");
    };

    fetchMoreFixedDeposits = () => {
        const { dispatch } = this.props;
        let { pageNumber } = this.props;

        pageNumber += 1;
        dispatch(investmentActions.fetchMoreFixedDeposits(pageNumber));
    };

    renderList = () => {
        const { isMobile, deposits, activeEnvironment } = this.props;

        return !isMobile ? (
            <Table className="gridTable">
                <Table.Header>
                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.listFixedTermDeposits.alias" />
                    </Table.HeaderData>

                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.listFixedTermDeposits.descriptionSIOC" />
                    </Table.HeaderData>

                    <Table.HeaderData align="left">
                        <I18n id="tableHeader.listFixedTermDeposits.expiryDate" />
                    </Table.HeaderData>

                    <Table.HeaderData align="left" styles={{ maxWidth: "50px" }}>
                        <I18n id="tableHeader.listFixedTermDeposits.agreedRate" />
                    </Table.HeaderData>

                    <Table.HeaderData align="right">
                        <I18n id="tableHeader.listFixedTermDeposits.investedCapital" />
                    </Table.HeaderData>

                    <Table.HeaderData align="right">
                        <I18n id="tableHeader.listFixedTermDeposits.netToPay" />
                    </Table.HeaderData>

                    <Table.HeaderData />

                    <Table.HeaderData />
                </Table.Header>

                <Table.Body>
                    {Object.entries(deposits).map(([id, deposit]) => (
                        <Deposit
                            deposit={deposit}
                            key={`${id} - ${deposit.operationCode}`}
                            cancelRenewal={this.handleCancelRenewal}
                            hasPermission={!!activeEnvironment.permissions.cancelDepositRenewal}
                        />
                    ))}
                </Table.Body>
            </Table>
        ) : (
            <div className="generic-movements-wrapper">
                {Object.entries(deposits).map(([id, deposit]) => (
                    <Deposit
                        deposit={deposit}
                        key={`${id} - ${deposit.operationCode}`}
                        isMobile={isMobile}
                        cancelRenewal={this.handleCancelRenewal}
                        hasPermission={!!activeEnvironment.permissions.cancelDepositRenewal}
                    />
                ))}
            </div>
        );
    };

    render() {
        const { deposits, fetching, isMobile, moreDeposits, history } = this.props;
        const isLoading = fetching;

        return (
            <>
                <Notification scopeToShow="deposits" />

                {this.renderHeader()}

                <MainContainer showLoader={isLoading}>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="menu.investments.listFixedTermDeposits" component="h1" />
                        </div>

                        {deposits.length ? (
                            <>
                                {isMobile && (
                                    <Button
                                        onClick={() => history.push("/formCustom/createFixedTermDeposit")}
                                        image="images/plusWhite.svg"
                                        label="fixedTermDeposit.new"
                                        bsStyle="secondary"
                                        block
                                        className="ui-mb-7"
                                    />
                                )}

                                {this.renderList()}
                                <SeuoLabel />

                                {moreDeposits && (
                                    <Button
                                        onClick={this.fetchMoreFixedDeposits}
                                        image="images/plusWhite.svg"
                                        label="investments.listFixedTermDeposits.searchMore"
                                        bsStyle="secondary"
                                        className="btn-show-more"
                                    />
                                )}
                            </>
                        ) : (
                            <NoDataContainer
                                noDataImage="images/coloredIcons/accounts.svg"
                                noDataText="investments.listFixedTermDeposits.empty.label"
                                fetching={fetching}>
                                <Button
                                    bsStyle="primary"
                                    block
                                    onClick={() => history.push("/formCustom/createFixedTermDeposit")}
                                    label="fixedTermDeposit.new"
                                    className="ui-mt-8"
                                />
                            </NoDataContainer>
                        )}
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    deposits: investmentSelectors.getDeposits(state),
    fetching: investmentSelectors.getFetching(state),
    pageNumber: investmentSelectors.getPageNumber(state),
    moreDeposits: investmentSelectors.getMoreDeposits(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Deposits);
