export default {
    DOWNLOAD_TICKET_FAILURE: "forms/DOWNLOAD_TICKET_FAILURE",
    DOWNLOAD_TICKET_REQUEST: "forms/DOWNLOAD_TICKET_REQUEST",
    DOWNLOAD_TICKET_SUCCESS: "forms/DOWNLOAD_TICKET_SUCCESS",
    SHARE_TICKET: "forms/SHARE_TICKET",

    READ_FORM_SUCCESS: "forms/READ_FORM_SUCCESS",
    READ_FORM_FAILURE: "forms/READ_FORM_FAILURE",

    PRE_FORM_REQUEST: "forms/PRE_FORM_REQUEST",
    PRE_FORM_SUCCESS: "forms/PRE_FORM_SUCCESS",
    PRE_FORM_FAILURE: "forms/PRE_FORM_FAILURE",

    PREVIEW_FORM_REQUEST: "forms/PREVIEW_FORM_REQUEST",
    PREVIEW_FORM_SUCCESS: "forms/PREVIEW_FORM_SUCCESS",
    ADD_EXTRA_PARAMS: "forms/ADD_EXTRA_PARAMS",

    SEND_FORM_REQUEST: "forms/SEND_FORM_REQUEST",
    SEND_FORM_CREDENTIAL_FAILURE: "forms/SEND_FORM_CREDENTIAL_FAILURE",
    SEND_FORM_DATA_FAILURE: "forms/SEND_FORM_DATA_FAILURE",
    SEND_FORM_DATA_FAILURE_NO_PRE: "forms/SEND_FORM_DATA_FAILURE_NO_PRE",
    SEND_FORM_SUCCESS: "forms/SEND_FORM_SUCCESS",
    SEND_FORM_USER_SUCCESS: "forms/SEND_FORM_USER_SUCCESS",
    SEND_FORM_MULTIPLE_TRANSACTIONS_SUCCESS: "forms/SEND_FORM_MULTIPLE_TRANSACTIONS_SUCCESS",

    SAVE_DRAFT_REQUEST: "forms/SAVE_DRAFT_REQUEST",
    SAVE_DRAFT_SUCCESS: "forms/SAVE_DRAFT_SUCCESS",
    SAVE_DRAFT_FAILURE: "forms/SAVE_DRAFT_FAILURE",

    READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS: "forms/READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS",
    READ_USER_TRANSACTION_FROM_BACKOFFICE_SUCCESS: "forms/READ_USER_TRANSACTION_FROM_BACKOFFICE_SUCCESS",
    READ_TRANSACTION_REQUEST: "forms/READ_TRANSACTION_REQUEST",
    READ_TRANSACTION_SUCCESS: "forms/READ_TRANSACTION_SUCCESS",
    READ_TRANSACTION_FAILURE: "forms/READ_TRANSACTION_FAILURE",
    READ_USER_TRANSACTION_SUCCESS: "forms/READ_USER_TRANSACTION_SUCCESS",

    CANCEL_TRANSACTION_PRE_ERROR: "forms/CANCEL_TRANSACTION_PRE_ERROR",
    CANCEL_TRANSACTION_PRE_REQUEST: "forms/CANCEL_TRANSACTION_PRE_REQUEST",
    CANCEL_TRANSACTION_PRE_SUCCESS: "forms/CANCEL_TRANSACTION_PRE_SUCCESS",

    CANCEL_TRANSACTION_REQUEST: "forms/CANCEL_TRANSACTION_REQUEST",
    CANCEL_TRANSACTION_SUCCESS: "forms/CANCEL_TRANSACTION_SUCCESS",

    MODIFY_TRANSACTION_REQUEST: "forms/MODIFY_TRANSACTION_REQUEST",
    MODIFY_TRANSACTION_FAILURE: "forms/MODIFY_TRANSACTION_FAILURE",

    SIGN_TRANSACTION_PREVIEW_REQUEST: "forms/SIGN_TRANSACTION_PREVIEW_REQUEST",
    SIGN_TRANSACTION_PREVIEW_SUCCESS: "forms/SIGN_TRANSACTION_PREVIEW_SUCCESS",
    SIGN_TRANSACTION_REQUEST: "forms/SIGN_TRANSACTION_REQUEST",
    CLOSE_TRANSACTION_PREVIEW: "forms/CLOSE_TRANSACTION_PREVIEW",
    GO_BACK_CLEAN_TRANSACTION: "forms/GO_BACK_CLEAN_TRANSACTION",
    LIST_CREDENTIALS_GROUPS_REQUEST: "forms/LIST_CREDENTIALS_GROUPS_REQUEST",
    LIST_CREDENTIALS_GROUPS_SUCCESS: "forms/LIST_CREDENTIALS_GROUPS_SUCCESS",

    SET_PREVIEW_DATA: "forms/SET_PREVIEW_DATA",
    SET_DATA: "forms/SET_DATA",

    FORM_CLOSED: "forms/FORM_CLOSED",

    SET_MODE: "forms/SET_MODE",

    PREVIEW_FORM_CANCEL_DEPOSIT_RENEWAL_REQUEST: "forms/PREVIEW_FORM_CANCEL_DEPOSIT_RENEWAL_REQUEST",
    DOWNLOAD_TICKET_CUSTOM_REQUEST: "forms/DOWNLOAD_TICKET_CUSTOM_REQUEST",
    PREVIEW_FORM_ERRORS: "forms/PREVIEW_FORM_ERRORS",

    SET_ERROR_MESSAGE: "forms/SET_ERROR_MESSAGE",
    SET_CANCELLING_TRANSACTION: "forms/SET_CANCELLING_TRANSACTION",

    CANCEL_REQUEST: "forms/CANCEL_REQUEST",
    CANCEL_REQUEST_SUCCESS: "forms/CANCEL_REQUEST_SUCCESS",
    CANCEL_REQUEST_FAILURE: "forms/CANCEL_REQUEST_FAILURE",
};
