import React, { useEffect } from "react";
import { withFormik } from "formik";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { compose } from "redux";
import { bool, func, shape, string } from "prop-types";

import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as frequentRecipientsSelectors } from "reducers/frequentRecipients";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import types from "../../../reducers/types/form";
import FormTransition from "../_components/FormTransition";
import RecipientInputs from "./echeqs/frecuentRecipients/RecipientInputs";

const ID_ACTIVITY = "frequentDestination.create.send";
const ID_FORM = "frequentDestination.create";

const CreateFrequentRecipient = (props) => {
    const {
        mode,
        dispatch,
        location,
        currentLang,
        fromBackoffice,
        transaction,
        data,
        recipientData,
        fetchingRecipient,
    } = props;

    useEffect(() => {
        dispatch({
            type: types.PRE_FORM_SUCCESS,
        });
    }, [dispatch, location]);

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: transaction?.idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    // Función para que el focus se aplique a ambos campos en casos como este
    // Solo reemplazar el targetId por el del input text (no el del selector)
    const focusDoubleInput = (e) => {
        const targetId = "destinationIdentifier";
        const targetElement = document.querySelector(`.${targetId}`);

        if (targetElement) {
            if (e.target.classList.contains("Select-control")) {
                targetElement.classList.add("has-focus");
            } else if (e.target.id !== targetId) {
                targetElement.classList.remove("has-focus");
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", focusDoubleInput);
        document.addEventListener("touchstart", focusDoubleInput);

        return () => {
            document.removeEventListener("click", focusDoubleInput);
            document.removeEventListener("touchstart", focusDoubleInput);
        };
    }, []);
    // Fin de la función y su useEffect

    const renderFields = () => (
        <>
            <RecipientInputs genericProps={genericProps} recipientData={recipientData} data={data} idForm={ID_FORM} />
        </>
    );

    const handleClickDownload = () => {
        const {
            transaction: { idTransaction },
        } = props;
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const formProps = {
        title: "forms.frequentDestination.create.title",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        buttonLabel: "global.continue",
        handleClickDownload,
        exportList: "pdf",
        fetching: fetchingRecipient,
    };

    return <FormTransition {...props} {...formProps} />;
};

CreateFrequentRecipient.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

CreateFrequentRecipient.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    values: shape({}).isRequired,
    recipientData: shape({}).isRequired,
    fetching: bool.isRequired,
    fetchingRecipient: bool.isRequired,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    recipientData: frequentRecipientsSelectors.getRecipientFound(state),
    fetchingRecipient: frequentRecipientsSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(CreateFrequentRecipient);
