import React, { Component } from "react";
import { Parallax } from "react-spring";
import { shape, string, bool } from "prop-types";

import FormRender from "pages/forms/_components/FormRender";

import FormRenderCustom from "pages/forms/_components/FormRenderCustom";
import Notification from "pages/_components/Notification";

class FormTransition extends Component {
    offsetArrayEdit = { edit: 0, preview: 1, view: 2 };

    offsetArrayView = { view: 0, edit: 1, preview: 2 };

    parallax = null;

    static propTypes = {
        mode: string.isRequired,
        isMobile: bool.isRequired,
        isCustom: bool,
        fromBackoffice: bool,
        buttonLabel: string,
        currentLang: string.isRequired,
        errors: shape({}).isRequired,
        ignoreEditStep: bool,
    };

    static defaultProps = { isCustom: false, fromBackoffice: false, buttonLabel: "global.send", ignoreEditStep: false };

    componentDidUpdate() {
        const { mode } = this.props;
        if (this.parallax) {
            const _offsets = mode === "view" ? this.offsetArrayView : this.offsetArrayEdit;
            this.parallax.scrollTo(_offsets[mode]);
        }
    }

    getRenderFormStep = (mode, actualMode, offsets) => {
        const actualOffset = offsets[actualMode];
        const renderOffset = offsets[mode];
        let render = true;
        if (renderOffset > actualOffset) {
            render = false;
        } else if (actualOffset === 2 && renderOffset < actualOffset) {
            render = false;
        }
        return render;
    };

    renderFormStep = (offset, mode, isCustom, offsetArray) => (
        <Parallax.Layer
            style={{
                overflowX: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
            offset={offset}
            speed="0"
            onScroll={(e) => e.stopPropagation()}>
            {this.getRenderFormStep(mode, this.props.mode, offsetArray) &&
                ((isCustom && <FormRenderCustom {...this.props} mode={mode} />) || (
                    <FormRender {...this.props} mode={mode} />
                ))}
        </Parallax.Layer>
    );

    renderMobile = (isCustom, mode) => {
        let numberPages = 3;
        if (mode === "view") {
            numberPages = 4;
        } else if (mode === "massive-list-view" || mode === "massive-list-preview") {
            numberPages = 2;
        }

        return (
            <Parallax
                ref={(ref) => {
                    this.parallax = ref;
                }}
                pages={numberPages}
                horizontal
                scrolling={false}
                innerStyle={{
                    webkitOverflowScrolling: "auto",
                    overflowScrolling: "auto",
                    overflowX: "hidden",
                }}>
                {this.getStepMobile(isCustom, mode)}
            </Parallax>
        );
    };

    getStepMobile = (isCustom, mode) => {
        const { ignoreEditStep } = this.props;
        if (mode === "view") {
            return (
                <>
                    {this.renderFormStep(0, "view", isCustom, this.offsetArrayView)}
                    {this.renderFormStep(1, "edit", isCustom, this.offsetArrayView)}
                    {this.renderFormStep(2, "preview", isCustom, this.offsetArrayView)}
                    {this.renderFormStep(3, "view", isCustom, this.offsetArrayView)}
                </>
            );
        }

        if (mode === "massive-list-view") {
            return (
                <>
                    {this.renderFormStep(0, "massive-list-view", isCustom, this.offsetArrayView)}
                    {this.renderFormStep(1, "view", isCustom, this.offsetArrayView)}
                </>
            );
        }

        if (mode === "massive-list-preview") {
            return (
                <>
                    {this.renderFormStep(0, "massive-list-preview", isCustom, this.offsetArrayView)}
                    {this.renderFormStep(1, "preview", isCustom, this.offsetArrayView)}
                </>
            );
        }

        if (mode === "cancel-request") {
            return <>{this.renderFormStep(0, "cancel-request", isCustom, this.offsetArrayEdit)}</>;
        }

        return (
            <>
                {!ignoreEditStep && this.renderFormStep(0, "edit", isCustom, this.offsetArrayEdit)}
                {this.renderFormStep(1, "preview", isCustom, this.offsetArrayEdit)}
                {this.renderFormStep(2, "view", isCustom, this.offsetArrayEdit)}
            </>
        );
    };

    renderDesktop = () => <FormRender {...this.props} />;

    renderDesktopCustom = () => <FormRenderCustom {...this.props} />;

    render() {
        const { isMobile, isCustom, fromBackoffice, mode, errors, currentLang } = this.props;
        return (
            <>
                <Notification scopeToShow="form" errors={errors} currentLang={currentLang} />
                <Notification scopeToShow="transaction/details" />
                <Notification scopeToShow="transfer_massive_step2" />
                {isMobile && !fromBackoffice
                    ? this.renderMobile(isCustom, mode)
                    : (isCustom && this.renderDesktopCustom()) || this.renderDesktop()}
            </>
        );
    }
}

export default FormTransition;
