import * as config from "util/config";

export const unknownResponse = {
    city: "unknown",
    country: "unknown",
};

export const noGpsResponse = {
    city: "noGps",
    country: "noGps",
};

function getCurrentLocation() {
    return new Promise((resolve, reject) => {
        const { geolocation } = navigator;

        geolocation.getCurrentPosition(resolve, reject, {
            timeout: 5000,
            enableHighAccuracy: true,
            maximumAge: 0,
        });
    });
}

export default async function() {
    try {
        const position = await getCurrentLocation();
        const locationFound = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            isDefault: false,
        };
        // se agrega la consola para determinar si se pudo obtener la ubicacion
        // eslint-disable-next-line no-console
        console.log("locationFound ", locationFound);
        return locationFound;
    } catch (error) {
        // se agrega la consola para determinar el error al intentar capturar la ubicacion
        // eslint-disable-next-line no-console
        console.log("currentLocation on error ", error);
        const defaultLocation = config.getDefaultPosition("pois.defaultMapLocation");

        // se retorna la ubicacion por defecto
        // eslint-disable-next-line no-console
        console.log("default location ", defaultLocation);

        return defaultLocation;
    }
}
