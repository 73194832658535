import { bool, shape, func, string } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import types from "reducers/types/form";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY = "echeqs.acceptEcheq.send";
const ID_FORM = "forms.echeqs.acceptOrRepudiateEcheq";

const AcceptEcheqForm = (props) => {
    const { echeq, activeEnvironment, mode, dispatch, location, data, transaction } = props;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};
    useEffect(() => {
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const clientDocNumber = activeEnvironment?.clients ? activeEnvironment.clients[0].idClient : null;
    const {
        cheque_id,
        cheque_numero,
        beneficiario_documento,
        beneficiario_nombre,
        endosos,
        estado,
        monto,
        cheque_concepto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
    } = echeq;
    const clientEndorsementData =
        data?.clientEndorsementData ||
        endosos?.filter((endoso) => endoso.estado_endoso === "pendiente" && endoso.benef_documento === clientDocNumber);

    const echeqConcept = data?.cheque_concepto || cheque_concepto;

    const { idTransactionStatus, idTransaction } = transaction;

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.acceptEcheq.message`} />
                </div>
            )}
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqId.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitEmitter.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.emitter.label`} componentProps={{ className: "data-label" }} />
                <span>{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitEndorser.label`} componentProps={{ className: "data-label" }} />
                <span>{clientEndorsementData.length > 0 ? clientEndorsementData[0].benef_documento : "-"}</span>
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.endorser.label`} componentProps={{ className: "data-label" }} />
                <span>{clientEndorsementData.length > 0 ? clientEndorsementData[0].benef_razon_social : "-"}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper">
                    <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                    <span>{data?.estado || estado}</span>
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount
                    quantity={data?.amount?.quantity || monto}
                    currency={data?.amount?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.concept.label`} componentProps={{ className: "data-label" }} />
                {echeqConcept && (
                    <I18n id={`forms.transfers.concepts.${echeqConcept}.label`} defaultValue={echeqConcept} />
                )}
            </div>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <span>
                    <FormattedDate date={data?.fecha_pago || fecha_pago} />
                </span>
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const activitySuffix =
        data?.activitySuffix || (echeq.estado === "ACTIVO-PENDIENTE" ? "acceptEndorsement" : "acceptEmitted");

    const formProps = {
        title: `${ID_FORM}.${activitySuffix}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            cheque_id: data?.cheque_id || cheque_id,
            cheque_numero: data?.cheque_numero || cheque_numero,
            emisor_documento: data?.emisor_documento || emisor_cuit,
            emisor_nombre: data?.emisor_nombre || emisor_razon_social,
            endosante_documento: data?.endosante_documento || clientEndorsementData?.[0]?.emisor_documento || "-",
            endosante_nombre: data?.endosante_nombre || clientEndorsementData?.[0]?.emisor_razon_social || "-",
            endosos: data?.endosos || endosos,
            estado: data?.estado || estado,
            amount: data?.amount || {
                quantity: monto,
                currency: emisor_moneda,
            },
            cheque_concepto: data?.cheque_concepto || cheque_concepto,
            fecha_pago: data?.fecha_pago || fecha_pago,
            emisor_moneda: data?.emisor_moneda || emisor_moneda,
            ultimo_beneficiario_documento:
                data?.ultimo_beneficiario_documento ||
                clientEndorsementData?.[0]?.benef_documento ||
                beneficiario_documento,
            ultimo_beneficiario_nombre:
                data?.ultimo_beneficiario_nombre ||
                clientEndorsementData?.[0]?.benef_razon_social ||
                beneficiario_nombre,
            clientEndorsementData,
            activitySuffix: data?.activitySuffix || activitySuffix,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

AcceptEcheqForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};
AcceptEcheqForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(AcceptEcheqForm);
