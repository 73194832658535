import { bool, shape, func, string } from "prop-types";
import React, { useEffect, useState } from "react";
import withRouter from "react-router-dom/withRouter";
import { connect } from "react-redux";
import { compose } from "redux";
import types from "reducers/types/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as echeqsSelectors } from "reducers/echeqs";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { selectors as sessionSelectors } from "reducers/session";
import FormattedDate from "pages/_components/FormattedDate";
import { goBack } from "react-router-redux";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import * as i18nUtils from "util/i18n";
import * as stringUtils from "util/string";
import FormTransition from "../../_components/FormTransition";

const ID_ACTIVITY_PAYMENT_SEND = "echeqs.acceptMandatePayment.send";
const ID_ACTIVITY_NEGOTIATION_SEND = "echeqs.acceptMandateNegotiation.send";
const ID_FORM = "forms.echeqs.acceptMandatesNegotiationOrPayment";

const AcceptMandatesNegotiationOrPayment = (props) => {
    const { echeq, data, dispatch, transaction, mode, location, currentLang, fromBackoffice } = props;
    const { idTransactionStatus, idTransaction } = transaction;
    const [activitySuffix, setactivitySuffix] = useState();
    const [isNegotiation, setisNegotiation] = useState(false);
    const [idActivity, setidActivity] = useState();
    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const {
        cheque_id,
        cheque_numero,
        estado,
        monto,
        fecha_pago,
        emisor_moneda,
        emisor_cuit,
        emisor_razon_social,
        cheque_tipo,
        beneficiario_nombre,
        cheque_caracter,
        avalistas,
    } = echeq;
    const hasGuarantor =
        data?.activitySuffix !== "acceptMandate.payment" ? !!(data?.hasGuarantor || avalistas?.length > 0) : false;
    const pendingMandante = echeq.mandatos?.filter((mandato) => mandato.estado === "PENDIENTE")[0];
    const hasCbuMandate =
        data?.activitySuffix !== "acceptMandate.negotiation"
            ? data?.cbu_mandante || pendingMandante?.cbu_mandante !== ""
            : false;
    const isPaymentToClientAccount = data?.isPaymentToClientAccount || (hasCbuMandate ? "Si" : "No");
    const mandante_nombre = data?.mandante_nombre || pendingMandante.mandante_nombre;
    const mandante_documento = data?.mandante_documento || pendingMandante.mandante_documento;
    const tipo_mandato = data?.tipo_mandato || pendingMandante.tipo_mandato;
    const [mandatario_documento, setMandatarioDocumento] = useState("");
    const [mandatario_nombre, setMandatarioNombre] = useState("");

    const genericProps = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity,
    };

    useEffect(() => {
        if (tipo_mandato === "COB") {
            setactivitySuffix("acceptMandate.payment");
            setidActivity(ID_ACTIVITY_PAYMENT_SEND);
            setMandatarioDocumento(data?.mandatario_documento || pendingMandante.mandatario_documento);
        } else {
            setactivitySuffix("acceptMandate.negotiation");
            setidActivity(ID_ACTIVITY_NEGOTIATION_SEND);
            setisNegotiation(true);
            setMandatarioDocumento(data?.mandatario_documento || pendingMandante.mandatario_documento);
            setMandatarioNombre(data?.mandatario_nombre || pendingMandante.mandatario_nombre);
        }
        if (mode === "edit") {
            if (Object.keys(echeq).length > 0 || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        }
    }, [dispatch, location]);

    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.message.label`} />
                </div>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.dataEcheqTitle.label`} />
                </span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.idEcheq.label`} />
                </span>
                <span className="data-text">{data?.cheque_id || cheque_id}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqNumber.label`} />
                </span>
                <span className="data-text">{data?.cheque_numero || cheque_numero}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqType.label`} />
                </span>
                <I18n id={`forms.echeqs.type.${data?.cheque_tipo || cheque_tipo}`} />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.echeqCharacter.label`} />
                </span>
                <span className="data-text">{data?.cheque_caracter || cheque_caracter}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.CUIT/CUILIssuer.label`} />
                </span>
                <span className="data-text">{data?.emisor_cuit || emisor_cuit}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.issuerName.label`} />
                </span>
                <span className="data-text">{data?.emisor_razon_social || emisor_razon_social}</span>
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.beneficiaryName.label`} />
                </span>
                <span className="data-text">{data?.beneficiario_nombre || beneficiario_nombre}</span>
            </div>
            {idTransactionStatus !== "FINISHED" && mode !== "view" && (
                <div className="data-wrapper inline">
                    <span className="data-label">
                        <I18n id={`${ID_FORM}.state.label`} />
                    </span>
                    <span className="data-text">{data?.estado || estado}</span>
                </div>
            )}
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.amount.label`} />
                </span>
                <FormattedAmount
                    quantity={data?.monto?.quantity || monto}
                    currency={data?.monto?.currency || emisor_moneda}
                />
            </div>
            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.paymentDate.label`} />
                </span>
                <FormattedDate date={data?.fecha_pago || fecha_pago} />
            </div>

            <hr className={mode === "edit" ? "ui-mt-7 ui-mb-3" : "ui-mt-7 ui-mb-7"} />

            <div className="data-wrapper inline">
                <span className="data-label">
                    <I18n id={`${ID_FORM}.dataMandateTitle.label`} />
                </span>
            </div>

            {!isNegotiation ? (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.mandate.label`} />
                        </span>
                        <span className="data-text">{mandante_nombre}</span>
                    </div>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.CUIT/CUILMandate.label`} />
                        </span>
                        <span className="data-text">{mandante_documento}</span>
                    </div>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.typeMandate.label`} />
                        </span>
                        <I18n id="forms.echeqs.mandate.mandateType.COB" />
                    </div>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.paymentToClientAccount.label`} />
                        </span>
                        <span className="data-text">{data?.isPaymentToClientAccount || isPaymentToClientAccount}</span>
                    </div>
                    {data?.isPaymentToClientAccount === "Si" ||
                        (isPaymentToClientAccount === "Si" && (
                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.CBUMandate.label`} />
                                </span>
                                <span className="data-text">{data?.cbu_mandante || pendingMandante?.cbu_mandante}</span>
                            </div>
                        ))}
                </>
            ) : (
                <>
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.CUIT/CUILMandate.label`} />
                        </span>
                        <span className="data-text">{mandante_documento}</span>
                    </div>

                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.mandate.label`} />
                        </span>
                        <span className="data-text">{mandante_nombre}</span>
                    </div>

                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.CUIT/CUILMandatary.label`} />
                        </span>
                        <span className="data-text">{mandatario_documento}</span>
                    </div>

                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.mandatary.label`} />
                        </span>
                        <span className="data-text">{mandatario_nombre}</span>
                    </div>

                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id={`${ID_FORM}.typeMandate.label`} />
                        </span>
                        <I18n id="forms.echeqs.mandate.mandateType.NEG" />
                    </div>

                    {hasGuarantor && (
                        <>
                            <hr />

                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.dataGuarantorTitle.label`} />
                                </span>
                            </div>

                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.guarantorName.label`} />
                                </span>
                                <span className="data-text">
                                    {data?.aval_razon_social || avalistas?.[0]?.aval_razon_social}
                                </span>
                            </div>

                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.typeAndNumberDocument.label`} />
                                </span>
                                <span className="data-text">
                                    {data?.aval_documento ||
                                        stringUtils.returnTypeAndNumberDocument(
                                            avalistas?.[0]?.aval_documento_tipo,
                                            avalistas?.[0]?.aval_documento,
                                        )}
                                </span>
                            </div>

                            <div className="data-wrapper inline">
                                <span className="data-label">
                                    <I18n id={`${ID_FORM}.domicile.label`} />
                                </span>
                                <span className="data-text">
                                    {data?.aval_domicilio || avalistas?.[0]?.aval_domicilio}
                                </span>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );

    const renderEditableInPreview = () => (
        <>
            <div className="termsAndConditions ui-mt-8">
                <DisclaimerContainer maxHeight="134px">
                    <I18n id={`${ID_FORM}.disclaimerTitle.label`} componentProps={{ className: "fw-bold" }} />
                    <I18n id="echeqsForms.termsAndConditions.text" component="p" />
                </DisclaimerContainer>
            </div>
            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                optionList={[
                    {
                        id: "1",
                        label: i18nUtils.get(`${ID_FORM}.termsAndConditions.checkbox.label`),
                    },
                ]}
                key="termsAndConditionsAcceptance"
                name="termsAndConditionsAcceptance"
                idField="termsAndConditionsAcceptance"
                renderAs="check"
            />
        </>
    );

    const repeatedData = {
        cheque_id: data?.cheque_id || cheque_id,
        cheque_numero: data?.cheque_numero || cheque_numero,
        cheque_tipo: data?.cheque_tipo || cheque_tipo,
        cheque_caracter: data?.cheque_caracter || cheque_caracter,
        emisor_cuit: data?.emisor_cuit || emisor_cuit,
        emisor_razon_social: data?.emisor_razon_social || emisor_razon_social,
        beneficiario_nombre: data?.beneficiario_nombre || beneficiario_nombre,
        estado: data?.estado || estado,
        amount: data?.amount || {
            quantity: monto,
            currency: emisor_moneda,
        },
        fecha_pago: data?.fecha_pago || fecha_pago,
        mandante_documento: data?.mandante_documento || mandante_documento,
        mandante_nombre: data?.mandante_nombre || mandante_nombre,
        mandatario_documento: data?.mandatario_documento || mandatario_documento,
        tipo_mandato: data?.tipo_mandato || tipo_mandato,
        activitySuffix,
    };

    const dataPayment = {
        ...repeatedData,
        cbu_mandante: hasCbuMandate ? pendingMandante?.cbu_mandante : null,
        isPaymentToClientAccount,
    };

    const dataNegotiation = {
        ...repeatedData,
        mandatario_nombre,
        hasGuarantor,
        aval_razon_social: hasGuarantor ? avalistas?.[0]?.aval_razon_social : null,
        aval_documento: hasGuarantor
            ? stringUtils.returnTypeAndNumberDocument(
                  avalistas?.[0]?.aval_documento_tipo,
                  avalistas?.[0]?.aval_documento,
              )
            : null,
        aval_domicilio: hasGuarantor ? avalistas?.[0]?.aval_domicilio : null,
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, idActivity));
    };

    const handleBack = () => {
        if (mode === "edit") {
            dispatch(goBack());
        }
    };

    const formProps = {
        title: `${ID_FORM}.${activitySuffix}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity,
        },
        data: isNegotiation ? dataNegotiation : dataPayment,
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        renderEditableInPreview,
        returnBack: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

AcceptMandatesNegotiationOrPayment.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    useDefaultSubmit: bool,
    activeEnvironment: shape({}).isRequired,
    echeq: shape({}).isRequired,
};

AcceptMandatesNegotiationOrPayment.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    echeq: echeqsSelectors.getSelectedEcheq(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withRouter)(AcceptMandatesNegotiationOrPayment);
