import { call, put, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import * as i18n from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { adjustIdFieldErrors } from "util/form";
import {
    getFrequentRecipients,
    createFrequentRecipient,
    modifyFrequentDestination,
    deleteFrequentRecipient,
    frequentDestinationPre,
    searchFrequentRecipient,
} from "../middleware/frequentRecipients";
import { types, actions } from "../reducers/frequentRecipients";

const sagas = [
    takeLatest(types.FREQUENT_RECIPIENTS_REQUEST, loadFrequentRecipients),
    takeLatest(types.CREATE_FREQUENT_RECIPIENTS_REQUEST, createRecipientRequest),
    takeLatest(types.MODIFY_FREQUENT_RECIPIENTS_REQUEST, modifyFrequentDestinationRequest),
    takeLatest(types.DELETE_FREQUENT_RECIPIENTS_REQUEST, deleteRecipient),
    takeLatest(types.CREATE_PREVIEW_FREQUENT_RECIPIENT_REQUEST, createFrequentDestinationPre),
    takeLatest(types.SEARCH_FREQUENT_RECIPIENTS_REQUEST, handleSearchRecipientRequest),
];

export default sagas;

function* loadFrequentRecipients({ pageNumber, nameSearch }) {
    const response = yield call(getFrequentRecipients, pageNumber, nameSearch);
    if (response.status === 200) {
        const { frequentDestinations, moreStatements, nextPage } = response.data.data;
        yield put(actions.loadFrequentRecipientsSuccess(frequentDestinations, moreStatements, nextPage));
    }
}

function* createRecipientRequest({ data, credentials, formikBag, openInModal }) {
    const {
        recipientName,
        cuit,
        entity,
        number,
        currency,
        destinationType,
        destinationIdentifier,
        reference,
        email,
    } = data;

    const response = yield call(
        createFrequentRecipient,
        recipientName,
        cuit,
        entity,
        number,
        currency,
        destinationType,
        destinationIdentifier,
        reference,
        email,
        credentials,
    );

    if (response.type === "W") {
        yield put(actions.createRecipientFailure());
        if (formikBag) {
            formikBag.setSubmitting(false);
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }

        if (response.data.data.NO_FIELD) {
            yield put(
                notificationActions.showNotification(response.data.data.NO_FIELD, "error", [
                    "frequentDestination.create",
                ]),
            );
        } else if (response.data.code === "COR027W") {
            const errorMessage = { otp: response.data.message };
            formikBag.setErrors(adjustIdFieldErrors(errorMessage));
            yield put(notificationActions.showNotification(data.message, "error", ["frequentDestination.create"]));
        }
    } else {
        notificationActions.showNotification(i18n.get("frequentDestination.create.success"), "success", [
            "frequentDestination.create",
        ]);
        yield put(actions.createRecipientSuccess());

        if (openInModal) {
            if (response.data.code === "COR023I") {
                yield put(actions.createRecipientForMassive(data));
                yield put(actions.setShowBeneficiaryModal(false));
                yield put(
                    notificationModalActions.showNotification(
                        i18n.get(
                            "forms.pay.transfers.massive.payment.create.modalBeneficiary.pendingSignaturesNotification",
                        ),
                        "blue-alert",
                    ),
                );
            } else {
                yield put(actions.setShowBeneficiaryModal(false));
                yield put(
                    notificationModalActions.showNotification(
                        i18n.get("forms.pay.transfers.massive.payment.create.modalBeneficiary.successNotification"),
                        "success",
                    ),
                );
            }
            yield put(actions.loadFrequentRecipients(1, null, false));
        }
    }
}

function* modifyFrequentDestinationRequest(params) {
    const response = yield call(modifyFrequentDestination, params);
    if (response.type === "W") {
        yield put(actions.upeRecipientFailure());
        if (response.data.code === "COR020W") {
            const message = response.data.data?.email || response.data.data?.reference || response.data.message;
            yield put(
                notificationActions.showNotification(message, "error", ["frequentDestination.detailModifyDelete"]),
            );
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("frequentDestination.modify.success"), "success", [
                "frequentDestination",
            ]),
        );
        yield put(actions.updateRecipientSuccess());
        yield put(replace("/recipients"));
    }
}

function* deleteRecipient({ idFrequentDestination }) {
    const response = yield call(deleteFrequentRecipient, idFrequentDestination);
    if (response.type === "W") {
        yield put(actions.deleteRecipientFailure());
        yield put(
            notificationActions.showNotification(response.data.message, "error", [
                "frequentDestination.detailModifyDelete",
            ]),
        );
    } else {
        yield put(actions.deleteRecipientSuccess());
        yield put(replace("/recipients"));
    }
}

function* createFrequentDestinationPre() {
    const response = yield call(frequentDestinationPre);
    if (response.type === "W") {
        yield put(replace(`/`));
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["frequentDestination"]),
        );
    } else {
        const { countries, documentTypes } = response.data.data;
        yield put({
            type: types.CREATE_PREVIEW_FREQUENT_RECIPIENT_RECEIVED,
            countries,
            documentTypes,
        });
    }
}

function* handleSearchRecipientRequest({ destinationType, destinationIdentifier, cuit, formikBag, openInModal }) {
    const response = yield call(searchFrequentRecipient, destinationType, destinationIdentifier, cuit);
    if (response.type === "W") {
        yield put(actions.searchRecipientFailure());

        if (openInModal) {
            yield put(actions.setShowBenefiaryModal(false));
        }

        if (formikBag) {
            formikBag.setSubmitting(false);
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }

        if (response.data.data.NO_FIELD) {
            yield put(notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["searchRecipient"]));
        }

        if (!response.data || !response.data.code) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["searchRecipient"]));
        }
    } else {
        const { frequentDestination } = response.data.data;
        const frequentDestinationData = {
            ...frequentDestination,
            urlOrigin: formikBag?.props.data.urlOrigin,
        };

        yield put(actions.searchRecipientSuccess(frequentDestinationData));

        if (!openInModal) {
            yield put(replace("/recipients/create"));
        }
    }
}
