import * as API from "middleware/api";

export const getQrAccountRequest = (cbuAccount, idAccount) =>
    API.executeWithAccessToken("/account.get.qr", {
        cbu: cbuAccount,
        idAccount,
    });

export const getAccountListRequest = () => API.executeWithAccessToken("/qr.list.accounts");

export const filterCharges = (idAccount, cbuAccount, cuit, status, dateFrom, dateTo) =>
    API.executeWithAccessToken("/qr.changes", {
        idAccount,
        cbu: cbuAccount,
        cuit,
        status,
        dateFrom,
        dateTo,
    });

export const filterCertificates = (idAccount, cbuAccount, cuit, dateFrom, dateTo) =>
    API.executeWithAccessToken("/qr.certificates", {
        idAccount,
        cbu: cbuAccount,
        cuit,
        dateFrom,
        dateTo,
    });

export const getQrCertificate = (uuid, idAccount) =>
    API.executeWithAccessToken("/qr.download.certificate", {
        uuid,
        idAccount,
    });

export const validateTyC = (cbuAccount) =>
    API.executeWithAccessToken("/accounts.validation.qr", {
        cbu: cbuAccount,
    });
