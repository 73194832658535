import { func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as i18n from "../../../../util/i18n";
import "./_Statement.scss";

class StatementsFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        optionListAccounts: shape([]).isRequired,
        idAccount: string.isRequired,
        selectedAccount: shape({}),
    };

    static defaultProps = {
        selectedAccount: null,
    };

    handleOnChange = (e) => {
        const { dispatch } = this.props;
        dispatch(accountsActions.listStatementLines(e.id, e));
    };

    render() {
        const { optionListAccounts, idAccount, selectedAccount } = this.props;
        const owner = optionListAccounts.find((item) => item.id === idAccount);

        return (
            <>
                <div className="form-content">
                    <div className="form-group ui-mb-3">
                        <div className="input-group ui-mt-0">
                            <div style={{ flex: 1 }}>
                                <Select
                                    id="listAccounts"
                                    value={selectedAccount}
                                    clearable={false}
                                    searchable={false}
                                    onChange={this.handleOnChange}
                                    valueKey="id"
                                    labelKey="newLabel"
                                    options={optionListAccounts}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {owner && (
                    <div className="data-wrapper data-wrapper-flex-start ui-mb-3">
                        <div className="data-text-label">{i18n.get("accounts.accountHolder")}</div>
                        <span className="data-text">{owner.client?.name}</span>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    idAccount: accountsSelectors.getIdAccountForStatement(state),
    optionListAccounts: accountsSelectors.getOptionListAccounts(state),
    selectedAccount: accountsSelectors.getSelectedAccountStatement(state),
});

export default connect(mapStateToProps)(StatementsFilters);
