import React, { Component } from "react";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux/actions";
import Yup from "yup";
import { actions, selectors } from "reducers/investments";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import moment from "moment";
import { get } from "util/i18n";
import * as config from "util/config";
import { clearFiltersAndSubmit } from "util/form";

import { Field, Form, withFormik } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Button from "pages/_components/Button";
import DateSelector from "pages/_components/fields/DateField";

class Filters extends Component {
    static propTypes = {
        shareholderAccounts: oneOfType([array]).isRequired,
        fundType: oneOfType([array]).isRequired,
        toggleState: string.isRequired,
        isFetching: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        values: shape({}).isRequired,
        resetForm: func.isRequired,
        handleOnClick: func.isRequired,
        showFilters: bool.isRequired,
        isResetQuery: bool.isRequired,
        filters: shape().isRequired,
        setValues: func.isRequired,
        setFieldValue: func.isRequired,
        setFieldError: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
    };

    state = {
        currentTab: "",
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isResetQuery) {
            if (prevState !== null && nextProps.toggleState !== prevState.currentTab) {
                nextProps.resetForm();
                return { currentTab: nextProps.toggleState };
            }
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    handleResetFilters = () => {
        const { setValues, shareholderAccounts, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    date: moment().format("YYYY-MM-DD"),
                    shareholderAccount: [shareholderAccounts[0].number],
                    fundType: "",
                    riskType: ["all"],
                }),
            submitForm,
        );
    };

    changeAccountSelected = ({ id }) => {
        const { dispatch, filters } = this.props;
        const newFilters = {
            ...filters,
            accountNumber: id,
        };
        dispatch(actions.setFundListFilters(newFilters));
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    renderShareHolderAccountList = (isMobile) => {
        const { dispatch, shareholderAccounts, filters, currentLang } = this.props;

        if (shareholderAccounts?.length > 0 && !filters.accountNumber) {
            const firstOption = shareholderAccounts[0].number;

            const newFilters = {
                ...filters,
                accountNumber: firstOption,
            };
            dispatch(actions.setFundListFilters(newFilters));
        }

        if (shareholderAccounts.length > 0) {
            return (
                <Field
                    component={FormFieldsComponents.Selector}
                    optionList={shareholderAccounts.map(({ number, name }) => ({
                        id: number,
                        label: `${number} ${name}`,
                    }))}
                    key="shareholderAccount"
                    name="shareholderAccount"
                    idField="shareholderAccount"
                    renderAs="combo"
                    idActivity="investment.mutualFund.send"
                    idForm="investment.mutualFund"
                    mode="edit"
                    onChange={(e) => this.changeAccountSelected(e)}
                    defaultValue={[shareholderAccounts[0].number]}
                    isRequired
                    additionalClassName={`${isMobile ? "ui-mb-0" : ""}`}
                    lang={currentLang}
                />
            );
        }
        return null;
    };

    renderFiltersBtnActions = () => {
        const { isFetching, isMobile } = this.props;

        return (
            <>
                <Button
                    bsStyle="primary"
                    label="product.filters.filter"
                    loading={isFetching}
                    type="submit"
                    block={isMobile}
                />
                <Button
                    bsStyle="outline"
                    className="btn-clear-filters"
                    label="product.filters.clearFilters"
                    image="images/ui-icons/ui-clear-filters.svg"
                    loading={isFetching}
                    block={isMobile}
                    onClick={() => this.handleResetFilters()}
                />
            </>
        );
    };

    render() {
        const {
            isMobile,
            shareholderAccounts,
            dispatch,
            toggleState,
            values,
            fundType,
            handleOnClick,
            showFilters,
            currentLang,
            setFieldValue,
            setFieldError,
        } = this.props;

        const minDaysBack = config.get("mutualFunds.list.minDaysBack", 730);
        const riskTypesId = ["all", "1", "2", "3"];
        const getRiskTypes = () =>
            riskTypesId.map((id) => ({ id, label: get(`investments.mutualFund.riskTypes.options.${id}`) }));

        return (
            <div ref={this.myRef} className={`filters-container mutual-funds ${toggleState}`}>
                <Form className="form-content">
                    {toggleState === "holding" && (
                        <>
                            {isMobile ? (
                                <>
                                    {this.renderShareHolderAccountList(true)}

                                    <Button
                                        bsStyle="outline"
                                        label="investments.mutualFund.actions.queryMovements"
                                        onClick={() => handleOnClick({}, "Movements")}
                                        block
                                        className="ui-mt-7"
                                    />

                                    <Button
                                        bsStyle="outline"
                                        label="investments.mutualFund.actions.investorTest"
                                        onClick={() => dispatch(push("/investerProfile"))}
                                        className="investor-btn ui-mt-4"
                                        block
                                    />

                                    <hr className="ui-mt-7 ui-mb-7" />

                                    <Button
                                        onClick={() => this.onToggleFilters()}
                                        bsStyle="filter"
                                        className="btn-link ui-mt-0"
                                        block
                                        image={`images/${
                                            !showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"
                                        }`}
                                        label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                                    />

                                    {showFilters && (
                                        <>
                                            <Field
                                                idField="date"
                                                component={DateSelector}
                                                hidePlaceholder
                                                idForm="investments.mutualFund"
                                                name="date"
                                                selectsStart
                                                showMonthYearDropdown
                                                style={{ display: toggleState === "offer" ? "none" : "block" }}
                                                minDate={moment().add(-minDaysBack, "days")}
                                                maxDate={moment()}
                                                isClearable={false}
                                                messageHelp="investments.mutualFund.date.validation"
                                                lang={currentLang}
                                                onChange={() => {
                                                    setFieldValue("date", moment().format("MM-DD-YYYY"));
                                                    setFieldError("date", null);
                                                }}
                                            />

                                            {this.renderFiltersBtnActions()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="filters-row">
                                        {this.renderShareHolderAccountList(false)}
                                        <Button
                                            bsStyle="outline"
                                            label="investments.mutualFund.actions.queryMovements"
                                            onClick={() => handleOnClick({}, "Movements")}
                                        />

                                        <Button
                                            bsStyle="outline"
                                            label="investments.mutualFund.actions.investorTest"
                                            onClick={() => dispatch(push("/investerProfile"))}
                                            className="investor-btn"
                                        />
                                    </div>

                                    <div className="filters-row">
                                        <Field
                                            idField="date"
                                            component={DateSelector}
                                            hidePlaceholder
                                            idForm="investments.mutualFund"
                                            name="date"
                                            selectsStart
                                            showMonthYearDropdown
                                            style={{ display: toggleState === "offer" ? "none" : "block" }}
                                            minDate={moment().add(-minDaysBack, "days")}
                                            maxDate={moment()}
                                            isClearable={false}
                                            messageHelp="investments.mutualFund.date.validation"
                                            onChange={(e) => {
                                                setFieldValue("date", e);
                                                setFieldError("date", null);
                                            }}
                                        />

                                        {this.renderFiltersBtnActions()}
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    {toggleState === "offer" && (
                        <>
                            {isMobile ? (
                                <>
                                    {this.renderShareHolderAccountList(true)}

                                    <Button
                                        bsStyle="outline"
                                        label="investments.mutualFund.actions.investorTest"
                                        onClick={() => dispatch(push("/investerProfile"))}
                                        className="ui-mt-7"
                                        block
                                    />

                                    <hr className="ui-mt-7 ui-mb-7" />

                                    <Button
                                        onClick={() => this.onToggleFilters()}
                                        bsStyle="filter"
                                        className="btn-link ui-mt-0"
                                        block
                                        image={`images/${
                                            !showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"
                                        }`}
                                        label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                                    />

                                    {showFilters && (
                                        <>
                                            <Field
                                                component={FormFieldsComponents.Selector}
                                                optionList={fundType.map(({ number, description }) => ({
                                                    id: number,
                                                    label: description,
                                                }))}
                                                key="fundType"
                                                name="fundType"
                                                idField="fundType"
                                                renderAs="combo"
                                                idActivity="investment.mutualFund.send"
                                                idForm="investment.mutualFund"
                                                mode="edit"
                                                defaultValue={values.fundType}
                                                isRequired={toggleState === "offer"}
                                                lang={currentLang}
                                            />

                                            <Field
                                                component={FormFieldsComponents.Selector}
                                                optionList={getRiskTypes().map(({ id, label }) => ({
                                                    id,
                                                    label,
                                                }))}
                                                key="riskType"
                                                name="riskType"
                                                idField="riskType"
                                                renderAs="combo"
                                                idActivity="investment.mutualFund.send"
                                                idForm="investment.mutualFund"
                                                mode="edit"
                                                defaultValue={[riskTypesId[0]]}
                                                isRequired
                                                lang={currentLang}
                                            />

                                            {this.renderFiltersBtnActions()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="filters-row">
                                        {shareholderAccounts.length > 0 && (
                                            <>
                                                {this.renderShareHolderAccountList(false)}

                                                <Button
                                                    bsStyle="outline"
                                                    label="investments.mutualFund.actions.investorTest"
                                                    onClick={() => dispatch(push("/investerProfile"))}
                                                    className="investor-btn"
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div className="filters-row">
                                        <Field
                                            component={FormFieldsComponents.Selector}
                                            optionList={fundType.map(({ number, description }) => ({
                                                id: number,
                                                label: description,
                                            }))}
                                            key="fundType"
                                            name="fundType"
                                            idField="fundType"
                                            renderAs="combo"
                                            idActivity="investment.mutualFund.send"
                                            idForm="investment.mutualFund"
                                            mode="edit"
                                            isRequired={toggleState === "offer"}
                                            lang={currentLang}
                                        />

                                        <Field
                                            component={FormFieldsComponents.Selector}
                                            optionList={getRiskTypes().map(({ id, label }) => ({
                                                id,
                                                label,
                                            }))}
                                            key="riskType"
                                            name="riskType"
                                            idField="riskType"
                                            renderAs="combo"
                                            idActivity="investment.mutualFund.send"
                                            idForm="investment.mutualFund"
                                            mode="edit"
                                            defaultValue={[riskTypesId[0]]}
                                            isRequired
                                        />

                                        {this.renderFiltersBtnActions()}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: selectors.getFetching(state),
    shareholderAccounts: state.investments.shareholderAccounts,
    mutualFunds: state.investments.mutualFunds,
    operationFunds: state.investments.operationFunds,
    toggleState: selectors.toggleState(state),
    user: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getEnvironments(state)[sessionSelectors.getActiveEnvironment(state).id],
    fundType: state.investments.fundOffers,
    filters: selectors.getFilters(state),
    showFilters: filterSelectors.getShowFilters(state),
    currentLang: i18nSelectors.getLang(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { filters, shareholderAccounts } = props;
            let defaultAccountNumber = "";
            if (shareholderAccounts?.length > 0) {
                defaultAccountNumber = shareholderAccounts[0].number;
            }

            return {
                shareholderAccount: filters?.accountNumber ? [filters?.accountNumber] : [defaultAccountNumber],
                fundType: filters?.fundType ? filters?.fundType : "",
                riskType: filters?.riskType ? filters?.riskType : "",
                date: filters?.date === "" ? moment().toDate() : filters.date,
            };
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    date: Yup.date()
                        .nullable(false)
                        .typeError(get("investments.mutualFund.required.message"))
                        .min(
                            moment().add(-config.get("mutualFunds.list.minDaysBack", 730), "days"),
                            get("investments.mutualFund.date.validation.message", ""),
                        )
                        .max(
                            moment().add(moment().format("DD/MM/YYYY"), "days"),
                            get("investments.mutualFund.date.validation.message", ""),
                        ),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { dispatch, shareholderAccounts, toggleState } = formikBag.props;

            const accountNumber = values?.shareholderAccount.length > 0 ? values.shareholderAccount[0] : "";

            const { number } =
                shareholderAccounts.length > 0
                    ? shareholderAccounts.find((shareholderAccount) => shareholderAccount.number === accountNumber)
                    : "";

            if (toggleState === "holding") {
                dispatch(actions.loadMutualFundByAccount(number, values.date));
            } else {
                const riskType = values.riskType[0] === "all" ? "" : values.riskType[0];

                const newFilters = {
                    accountNumber,
                    date: moment().format("YYYY-MM-DD"),
                    fundType: values.fundType[0],
                    riskType,
                };
                dispatch(actions.setFundListFilters(newFilters));
                dispatch(
                    actions.loadOperatingFunds(
                        accountNumber,
                        moment().format("YYYY-MM-DD"),
                        values.fundType[0],
                        riskType,
                    ),
                );
            }
        },
    }),
)(Filters);
