import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import { format } from "date-fns";
import * as configUtils from "util/config";

export const downloadStatement = (idAccount, idStatementLine, path, fileName) =>
    API.executeWithAccessToken("/accounts.downloadStatementLine", { idAccount, idStatementLine, path, fileName });

export const editMovementNote = (idProduct, idStatement, note) =>
    API.executeWithAccessToken("/notes.editStatementNote", { idProduct, idStatement, note });

export const listAccounts = () => API.executeWithAccessToken("/accounts.list");

export const listMovements = (idAccount, filters) => {
    let { dateFrom, dateTo } = filters;
    const { channels, check, minAmount, maxAmount, pageNumber, reference, movementType } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    if (configUtils.getBoolean("ms.account.enabled", false)) {
        return restAPI.getWithAccessToken(`/api/accounts/v1/accounts/${idAccount}/statements`, {
            channels,
            check,
            dateFrom,
            dateTo,
            minAmount,
            maxAmount,
            pageNumber,
            reference,
            movementType,
        });
    }

    return API.executeWithAccessToken("/accounts.listStatements", {
        idAccount,
        channels,
        check,
        dateFrom,
        dateTo,
        minAmount,
        maxAmount,
        pageNumber,
        reference,
        movementType,
    });
};

export const listStatementLines = (idAccount) =>
    API.executeWithAccessToken("/accounts.listStatementLines", {
        idAccount,
    });

export const movementDetails = (idAccount, idStatement) =>
    API.executeWithAccessToken("/accounts.listStatementDetails", {
        idAccount,
        idStatement,
    });

export const readAccount = (idAccount) => {
    if (configUtils.getBoolean("ms.account.enabled", false)) {
        return restAPI.getWithAccessToken(`/api/accounts/v1/accounts/${idAccount}`);
    }
    return API.executeWithAccessToken("/accounts.read", { idAccount });
};

export const downloadMovements = (idAccount, { dateFrom = null, dateTo = null, ...filters }, fileFormat) =>
    API.executeWithAccessToken("/accounts.downloadMovements", {
        idAccount,
        format: fileFormat,
        dateFrom: dateFrom && format(dateFrom, "YYYY-MM-DD"),
        dateTo: dateTo && format(dateTo, "YYYY-MM-DD"),
        ...filters,
    });

export const exportCBUDetailRequest = (cbuData, idAccount) =>
    API.executeWithAccessToken("/account.download.cbuDetail", {
        cbuData,
        idAccount,
    });

export const exportAccountDetailRequest = (dataToExport, idAccount, fileExtension) =>
    API.executeWithAccessToken("/accounts.download.details", {
        dataToExport,
        idAccount,
        fileExtension,
    });
