import * as API from "middleware/api";

export const detailRequest = (params) =>
    API.executeWithAccessToken("/communications.read", { communicationId: params.idCommunication });

export const deleteRequest = (params) => API.executeWithAccessToken("/communications.delete", params);

export const sendRequest = (idCommunicationTray, subject, body, idFileList, isSuspiciousActivity) =>
    API.executeWithAccessToken("/communications.send", {
        idCommunicationTray,
        subject,
        body,
        idFileList,
        isSuspiciousActivity,
    });

export const replyRequest = (idCommunicationTray, subject, body, idFileList, idCommunication, communicationPriority) =>
    API.executeWithAccessToken("/communications.reply", {
        idCommunicationTray,
        subject,
        body,
        idFileList,
        idCommunication,
        communicationPriority,
    });

export const markAsReadRequest = (idCommunication) =>
    API.executeWithAccessToken("/communications.mark.read", { idCommunication });

export const markAsUnReadRequest = (idCommunication) =>
    API.executeWithAccessToken("/communications.mark.unread", { idCommunication });
