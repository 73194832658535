import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import classNames from "classnames";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import SimpleModal from "components/SimpleModal/SimpleModal";

class EditableLabel extends Component {
    static propTypes = {
        isTitle: bool.isRequired,
        hidePlaceholder: bool,
        onCancel: func,
        onSave: func.isRequired,
        placeholder: string,
        value: string,
        editWithModal: bool,
        modalMainLabel: string,
        modalButtonLabel: string,
    };

    static defaultProps = {
        hidePlaceholder: true,
        placeholder: "",
        onCancel: null,
        editWithModal: false,
        modalMainLabel: "",
        modalButtonLabel: "",
        value: "",
    };

    state = {
        isEditing: false,
        value: "",
        focusInput: false,
        showEditingModal: true,
    };

    handleEdit = () => {
        const { value, editWithModal } = this.props;

        this.setState({
            isEditing: true,
            showEditingModal: editWithModal,
            value,
        });
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        } else if (event.key === "Escape") {
            this.setState({ isEditing: false });
        }
    };

    handleOnChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        }
    };

    handleCancel = () => {
        const { value, onCancel, editWithModal } = this.props;

        this.setState({
            isEditing: false,
            showEditingModal: editWithModal,
            value,
        });

        if (onCancel) {
            onCancel();
        }
    };

    toggleFocusInput = () => {
        const { focusInput } = this.state;
        this.setState({
            focusInput: !focusInput,
        });
    };

    renderAlias = () => {
        const { value, isTitle, editWithModal } = this.props;

        if (value) {
            return (
                <>
                    {isTitle ? (
                        <>
                            <h1 className="product-title">{value}</h1>
                            <Button
                                className="btn-only-icon"
                                image="images/ui-icons/ui-edit-pen.svg"
                                label="products.alias.modifyButtonLabel.a11y"
                                onClick={this.handleEdit}
                            />
                        </>
                    ) : (
                        <>
                            <div className="data-text">
                                {value}
                                <Button
                                    className="btn-only-icon"
                                    image="images/ui-icons/ui-edit-pen.svg"
                                    label="products.alias.modifyButtonLabel.a11y"
                                    onClick={this.handleEdit}
                                />
                            </div>
                        </>
                    )}
                </>
            );
        }
        return (
            <Button
                className="btn-small"
                label="accounts.alias.custome.create"
                bsStyle="secondary"
                onClick={() =>
                    this.setState({
                        isEditing: true,
                        showEditingModal: editWithModal,
                    })
                }
            />
        );
    };

    renderEditWithInput = () => {
        const { hidePlaceholder, placeholder } = this.props;

        const { isEditing, value, focusInput } = this.state;

        return (
            <>
                <div
                    className={classNames("input-group", {
                        "has-focus": focusInput,
                    })}>
                    <label htmlFor="modifyAlias" className="visually-hidden">
                        <I18n id="products.alias.editingAliasLabel.a11y" />
                    </label>
                    <input
                        id="modifyAlias"
                        className="form-control"
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={!hidePlaceholder ? placeholder : ""}
                        value={value}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={isEditing}
                        maxLength="30"
                        onBlur={() => this.toggleFocusInput()}
                        onFocus={() => this.toggleFocusInput()}
                    />
                </div>
                <div className="modify-buttons">
                    <Button bsStyle="outline" label="global.cancel" onClick={this.handleCancel} />
                    <Button bsStyle="primary" label="global.modify" onClick={this.handleSave} />
                </div>
            </>
        );
    };

    renderEditWithModal = () => {
        const { hidePlaceholder, placeholder, modalMainLabel, modalButtonLabel } = this.props;

        const { isEditing, value, focusInput, showEditingModal } = this.state;

        return (
            <SimpleModal
                isDisplayed={showEditingModal}
                closeModal={() =>
                    this.setState({
                        showEditingModal: false,
                        isEditing: false,
                    })
                }
                modalMainText={modalMainLabel}
                className="edit-alias"
                noCancelButton
                buttonActionLabel={modalButtonLabel}
                buttonAction={this.handleSave}>
                <div
                    className={classNames("input-group ui-mt-3", {
                        "has-focus": focusInput,
                    })}>
                    <label htmlFor="modifyAlias" className="visually-hidden">
                        <I18n id="products.alias.editingAliasLabel.a11y" />
                    </label>
                    <input
                        id="modifyAlias"
                        className="form-control"
                        onChange={this.handleOnChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={!hidePlaceholder ? placeholder : ""}
                        value={value}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={isEditing}
                        maxLength="30"
                        onBlur={() => this.toggleFocusInput()}
                        onFocus={() => this.toggleFocusInput()}
                    />
                </div>
            </SimpleModal>
        );
    };

    render() {
        const { isEditing } = this.state;
        const { editWithModal, modalMainLabel } = this.props;

        if (isEditing) {
            if (editWithModal && modalMainLabel) {
                return (
                    <>
                        {this.renderAlias()}
                        {this.renderEditWithModal()}
                    </>
                );
            }
            return this.renderEditWithInput();
        }
        return this.renderAlias();
    }
}

export default EditableLabel;
