export const types = {
    LOAD_CAMPAIGN_REQUEST: "campaigns/LOAD_CAMPAIGN_REQUEST",
    LOAD_CAMPAIGN_SUCCESS: "campaigns/LOAD_CAMPAIGN_SUCCESS",
    LOAD_CAMPAIGN_FAILURE: "campaigns/LOAD_CAMPAIGN_FAILURE",
    LOAD_CAMPAIGN_IMAGE: "campaigns/LOAD_CAMPAIGN_IMAGE",
    REMOVE_CAMPAIGNS: "campaigns/REMOVE_CAMPAIGNS",
    DISMISS_CAMPAIGN_PERMANENT_REQUEST: "campaigns/DISMISS_CAMPAIGN_PERMANENT_REQUEST",
    DISMISS_CAMPAIGN_PERMANENT_SUCCESS: "campaigns/DISMISS_CAMPAIGN_PERMANENT_SUCCESS",
    DISMISS_CAMPAIGN_PERMANENT_FAILURE: "campaigns/DISMISS_CAMPAIGN_PERMANENT_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    items: [],
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOAD_CAMPAIGN_REQUEST:
            return {
                ...state,
                fetching: true,
            };

        case types.LOAD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                fetching: false,
                // items: [...state.items, action.item] //con esta logica en el escritorio se estan cargando dos imagenes bajo cierta situación
                items: [action.item],
            };

        case types.LOAD_CAMPAIGN_FAILURE:
            return {
                ...state,
                items: INITIAL_STATE.items,
                fetching: false,
            };

        case types.REMOVE_CAMPAIGNS:
            return INITIAL_STATE;

        case types.DISMISS_CAMPAIGN_PERMANENT_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS:
            return {
                ...INITIAL_STATE,
                fetching: true,
                items: [action.item],
            };
        case types.DISMISS_CAMPAIGN_PERMANENT_FAILURE:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export const actions = {
    loadCampaign: (section) => ({
        type: types.LOAD_CAMPAIGN_REQUEST,
        section,
    }),
    removeCampaigns: () => ({
        type: types.REMOVE_CAMPAIGNS,
    }),
    dismissPermanentCampaign: (idCampaign, section) => ({
        type: types.DISMISS_CAMPAIGN_PERMANENT_REQUEST,
        idCampaign,
        section,
    }),
};

export const selectors = {
    getCampaigns: ({ campaigns }) => campaigns.items,
    getFetching: ({ campaigns }) => campaigns.fetching,
};
