import React, { Component } from "react";
import { bool, shape, string } from "prop-types";
import I18n from "pages/_components/I18n";
import FeatureFlag from "pages/_components/FeatureFlag";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import PendingSignature from "pages/forms/_components/PendingSignature";
import moment from "moment";
import { DELAY_SECONDS_ADMITTED } from "util/form.js";
import * as transactionUtil from "util/transaction";
import * as config from "util/config";
import Signatures from "pages/forms/_components/Signatures";

const rename = (transformation, obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newKey = transformation[key] ? transformation[key] : key;
        acc[newKey] = obj[key];
        return acc;
    }, {});

const PageTitle = ({ i18n }) => <I18n id={i18n} component="h3" />;

PageTitle.propTypes = { i18n: string.isRequired };

class FormSignatures extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        fromBackoffice: bool,
    };

    static defaultProps = {
        fromBackoffice: false,
    };

    hasChanges = () => {
        const { transaction } = this.props;
        const { creationDateTime, modificationDateTime } = transaction;
        const secondsDelay = moment(modificationDateTime).diff(moment(creationDateTime), "seconds");
        const response = creationDateTime !== modificationDateTime && secondsDelay > DELAY_SECONDS_ADMITTED;
        return response;
    };

    hasDispatcher = () => {
        const {
            transaction: { dispatcher },
        } = this.props;
        return dispatcher;
    };

    authorizerSignature = (signature) => ({
        fullName: signature.userFullName,
        date: signature.creationDateTime,
        signatureLevel: signature.signatureLevel,
    });

    dispatcherSignature = (dispatcher) => ({
        lastName: dispatcher.split(" ")[1],
        firstName: dispatcher.split(" ")[0],
    });

    renderPendingSignatures = (authorizerSignature) => {
        // Esquemas de firmas requerido para esta transaccion
        const { transaction, activeEnvironment } = this.props;
        const requiredSignatures = transactionUtil.getRequiredSignatures(transaction, activeEnvironment);

        // Resultado a renderizar
        const pendingSignatures = [];

        // Se recorren los esquemas de firmas
        requiredSignatures.forEach((scheme) => {
            // Texto previo al listado de firmas
            pendingSignatures.push(
                <div className="data-wrapper">
                    <I18n
                        id={
                            pendingSignatures.length === 0
                                ? "forms.transaction.ticket.pendingSignatures.title"
                                : "forms.transaction.ticket.pendingSignatures.orElse"
                        }
                        componentProps={{ className: "data-label" }}
                    />
                </div>,
            );

            // Se almacenan las firmas ya utilizadas
            const signaturesUsed = [];

            // Se recorren los niveles de firma
            scheme.signatureGroup.split("").forEach((signatureLevel) => {
                if (authorizerSignature) {
                    // Se agrega un firmante
                    let signatureAdded = false;

                    authorizerSignature.forEach((signature) => {
                        if (
                            !signatureAdded &&
                            signatureLevel === signature.signatureLevel &&
                            !signaturesUsed.includes(signature)
                        ) {
                            pendingSignatures.push(<PendingSignature signature={signature} />);
                            signaturesUsed.push(signature);
                            signatureAdded = true;
                        }
                    });

                    // Si no se agrega un firmante, se renderiza solo el texto de nivel de firma
                    if (!signatureAdded) {
                        pendingSignatures.push(<PendingSignature signatureLevel={signatureLevel} />);
                    }
                }
            });
        });

        return pendingSignatures;
    };

    render() {
        const { transaction, fromBackoffice } = this.props;
        const { signatures, dispatcher, extendedData, idTransactionStatus } = transaction;
        const extendedDataObj = extendedData ? JSON.parse(extendedData) : {};
        const isTransactionException = config
            .get("activities.transactional.signature.disable")
            .split("|")
            .includes(transaction.idActivity);

        const creatorSignature = rename(
            {
                userCreatorLastName: "lastName",
                userCreatorFirstName: "firstName",
                creationDateTime: "date",
            },
            transaction,
        );

        const modifyerSignature = rename(
            {
                userEditorLastName: "lastName",
                userEditorFirstName: "firstName",
                modificationDateTime: "date",
            },
            transaction,
        );

        const cancellerSignature = rename(
            {
                userCancellerLastName: "lastName",
                userCancellerFirstName: "firstName",
                cancellationDateTime: "date",
            },
            extendedDataObj,
        );

        const authorizerSignature = signatures
            ?.filter((signature) => signature.signatureLevel !== null)
            .map(this.authorizerSignature);

        return (
            <>
                <hr />
                {!isTransactionException && <PageTitle i18n="forms.transaction.ticket.title" />}
                <Signatures title="forms.transaction.ticket.createdBy" list={[creatorSignature]} />

                {this.hasChanges() && idTransactionStatus !== "CANCELLED" && idTransactionStatus !== "FINISHED" && (
                    <Signatures title="forms.transaction.ticket.modifiedBy" list={[modifyerSignature]} />
                )}

                {idTransactionStatus === "CANCELLED" &&
                    (cancellerSignature?.fullName || cancellerSignature?.lastName || cancellerSignature?.firstName) && (
                        <Signatures title="forms.transaction.ticket.cancelledBy" list={[cancellerSignature]} />
                    )}

                {!fromBackoffice &&
                    !isTransactionException &&
                    idTransactionStatus !== "FINISHED" &&
                    idTransactionStatus !== "PROCESSING" &&
                    this.renderPendingSignatures(authorizerSignature)}

                {(fromBackoffice || idTransactionStatus === "FINISHED" || idTransactionStatus === "PROCESSING") &&
                    authorizerSignature?.length > 0 && (
                        <Signatures title="forms.transaction.ticket.authorizedBy" list={authorizerSignature} />
                    )}

                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                    {this.hasDispatcher() && (
                        <Signatures
                            title="forms.transaction.ticket.dispatchedBy"
                            list={[this.dispatcherSignature(dispatcher)]}
                        />
                    )}
                </FeatureFlag>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(FormSignatures);
