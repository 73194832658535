import React from "react";
import ReactDOM from "react-dom";
import { Field, withFormik } from "formik";
import * as stringUtils from "util/string";
import { Mixpanel } from "util/clickstreaming";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import moment from "moment";
import Button from "pages/_components/Button";
import { actions as echeqsActions } from "reducers/echeqs";
import { func, shape, bool, string } from "prop-types";
import BigModal from "pages/_components/BigModal/BigModal";
import defaultDateFromList from "util/defaultDateFromList";

const PersonalizedEcheqModal = (props) => {
    const {
        handleSubmit,
        amountData,
        showModal,
        ID_FORM,
        genericProps,
        isMobile,
        setCurrentEcheqInfo,
        currentEcheqInfo,
        dispatch,
        isSubmitting,
    } = props;

    const handleEcheqType = (echeqType) => {
        let paymentDate;
        if (echeqType === "today") {
            paymentDate = moment(new Date());
        } else {
            paymentDate = null;
        }
        setCurrentEcheqInfo({ ...currentEcheqInfo, paymentDate, echeqType });
    };

    const echeqItemForm = (
        <BigModal
            showModal={showModal}
            handleShowModal={() => dispatch(echeqsActions.setShowPersonalizedEcheqModal(false))}
            modalWidth="584px"
            modalTitle="forms.echeqs.emitEcheq.personalized.addEcheq"
            isMobile={isMobile}>
            <form className="form-content" onSubmit={handleSubmit}>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    key="amount"
                    name="amount"
                    idField="amount"
                    data={amountData}
                    handleChange={(e) =>
                        setCurrentEcheqInfo({
                            ...currentEcheqInfo,
                            amount: { ...currentEcheqInfo.amount, quantity: e },
                        })
                    }
                />
                <div className="form-group--double-field">
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        key="echeqType"
                        name="echeqType"
                        idField="echeqType"
                        renderAs="combo"
                        onChange={(e) => handleEcheqType(e.id)}
                        optionList={[
                            {
                                id: "today",
                                label: i18nUtils.get(`${ID_FORM}.echeqType.today.label`),
                            },
                            {
                                id: "deferred",
                                label: i18nUtils.get(`${ID_FORM}.echeqType.deferred.label`),
                            },
                        ]}
                        value={currentEcheqInfo?.echeqType}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Date}
                        key="paymentDate"
                        name="paymentDate"
                        idField="paymentDate"
                        disabled={currentEcheqInfo?.echeqType === "today"}
                        editing={false}
                        onChange={(e) => setCurrentEcheqInfo({ ...currentEcheqInfo, paymentDate: e })}
                        minDate={currentEcheqInfo?.echeqType === "today" ? moment() : moment().add(1, "days")}
                        maxDate={defaultDateFromList().echeqs.maxDaysToEmmit}
                    />
                </div>

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="character"
                    name="character"
                    idField="character"
                    renderAs="combo"
                    optionList={[
                        {
                            id: "transferable",
                            label: i18nUtils.get(`${ID_FORM}.character.transferable.label`),
                        },
                        {
                            id: "nonTransferable",
                            label: i18nUtils.get(`${ID_FORM}.character.nonTransferable.label`),
                        },
                    ]}
                    onChange={(e) => setCurrentEcheqInfo({ ...currentEcheqInfo, character: e.id })}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="reference"
                    name="reference"
                    idField="reference"
                    onChange={(e) => setCurrentEcheqInfo({ ...currentEcheqInfo, reference: e })}
                    maxLength={20}
                />
                <div className="form-footer">
                    {isMobile && (
                        <Button
                            block={isMobile}
                            label={`${ID_FORM}.personalized.addEcheq.stop`}
                            bsStyle="primary"
                            onClick={() => dispatch(echeqsActions.setShowPersonalizedEcheqModal(false))}
                        />
                    )}
                    <Button
                        block={isMobile}
                        label={`${ID_FORM}.personalized.saveAndAddEcheq`}
                        bsStyle="outline"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                    />
                    {!isMobile && (
                        <Button
                            block={isMobile}
                            label={`${ID_FORM}.personalized.addEcheq.stop`}
                            bsStyle="primary"
                            onClick={() => dispatch(echeqsActions.setShowPersonalizedEcheqModal(false))}
                        />
                    )}
                </div>
            </form>
        </BigModal>
    );

    return ReactDOM.createPortal(echeqItemForm, document.querySelector("#modal"));
};

PersonalizedEcheqModal.propTypes = {
    ID_FORM: string,
    amountData: shape({}).isRequired,
    genericProps: shape({}).isRequired,
    isMobile: bool.isRequired,
    setCurrentEcheqInfo: func.isRequired,
    currentEcheqInfo: shape({}).isRequired,
    showModal: bool.isRequired,
    otherFieldsSelected: shape({}).isRequired,
    isSubmitting: bool.isRequired,
};

export default compose(
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ currentEcheqInfo }) => ({
            amount: currentEcheqInfo?.amount || null,
            echeqType: [currentEcheqInfo?.echeqType] || null,
            paymentDate: currentEcheqInfo?.paymentDate || null,
            character: [currentEcheqInfo?.character] || null,
            reference: currentEcheqInfo?.reference || null,
        }),
        handleSubmit: (values, formikBag) => {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(values).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0]?.text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            Mixpanel.track_form(formikBag.props.id, filteredValues);

            const {
                otherFieldsSelected,
                emissionTypeSelected,
                dispatch,
                setFieldValue,
                setCurrentEcheqInfo,
            } = formikBag.props;

            function validateSuccessAction() {
                setFieldValue("originAccount", otherFieldsSelected?.originAccount);
                setFieldValue("mode", ["crossed"]);
                setFieldValue("concept", [otherFieldsSelected?.concept]);
                setFieldValue("reason", otherFieldsSelected?.reason);
                setFieldValue("emissionType", emissionTypeSelected);

                setCurrentEcheqInfo({
                    echeqType: "",
                    amount: { quantity: "", currency: "ARS" },
                    paymentDate: null,
                    character: null,
                    reference: null,
                });
            }
            dispatch(
                echeqsActions.validatePersonalizedEcheqRequest(formikBag, { ...filteredValues }, validateSuccessAction),
            );
        },
    }),
)(PersonalizedEcheqModal);
