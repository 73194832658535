import { Field } from "formik";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Button from "pages/_components/Button";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as checksSelectors } from "reducers/checks";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as miscActions, selectors as miscSelectors } from "reducers/misc";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import FormTransition from "../_components/FormTransition";

const ID_ACTIVITY = "request.checkbook.send";
const ID_ACTIVITY_PRE = "request.checkbook.pre";
const RequestCheckbookForm = (props) => {
    const { mode, dispatch, location, documentTypes, data, accountSelected, transaction } = props;
    const [amount, setAmount] = useState([]);

    const [anotherPerson, setAnotherPerson] = useState(false);
    const [isCheckbook, setIsCheckbook] = useState(false);
    useEffect(() => {
        if (mode === "edit") {
            const formData = {
                amount: null,
                accountDebit: null,
                creditAccount: null,
                creditReference: null,
            };
            dispatch(miscActions.getDocumentTypes("noCU"));
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
            if (!data) {
                setIsCheckbook(false);
                setAnotherPerson(false);
            } else if (Object.keys(data).length !== 0) {
                if (data?.authorizedAnotherPerson) {
                    setAnotherPerson(true);
                }
                const isCommonCheckbook =
                    data?.typeCheckbook[0] === "Cheque común" || data?.typeCheckbook[0] === "Cheque diferido";
                const amountValues = config
                    .get(`checkbook.${isCommonCheckbook ? "amountCheckbookCommon" : "amountCheckbookForm"}.values`)
                    .split("|");
                setIsCheckbook(isCommonCheckbook);
                setAmount(() =>
                    amountValues.map((value) => ({
                        label: value,
                        id: value,
                    })),
                );
            }
        } else if (mode === "view" && data) {
            if (data?.typeCheckbook[0] === "Cheque común" || data?.typeCheckbook[0] === "Cheque diferido") {
                setIsCheckbook(true);
            } else {
                setIsCheckbook(false);
            }
            if (data?.authorizedAnotherPerson?.length > 1) {
                setAnotherPerson(true);
            } else {
                setAnotherPerson(false);
            }
            if (data?.amountCheckbook?.length > 0) {
                setAmount(() =>
                    data.amountCheckbook.map((value) => ({
                        label: value,
                        id: value,
                    })),
                );
            }
        }
    }, [dispatch, location, transaction]); // eslint-disable-line

    const valueCheck = (e) => {
        if (e.id === "") {
            setIsCheckbook(false);
            setAmount([]);
        } else if (e.id === "Cheque común" || e.id === "Cheque diferido") {
            setIsCheckbook(true);
            const valuesTypesCheckbookCommon = config.get("checkbook.amountCheckbookCommon.values").split("|");
            setAmount(() =>
                valuesTypesCheckbookCommon.map((value) => ({
                    label: value,
                    id: value,
                })),
            );
        } else {
            setIsCheckbook(false);
            const valuesTypesCheckbookForm = config.get("checkbook.amountCheckbookForm.values").split("|");
            setAmount(() =>
                valuesTypesCheckbookForm.map((value) => ({
                    label: value,
                    id: value,
                })),
            );
        }
    };

    const onTemplateLoad = (templateData) => {
        if (Object.keys(templateData).length === 0 && mode === "edit") {
            setAnotherPerson(false);
            setIsCheckbook(false);
        } else {
            if (templateData.typeCheckbook) {
                valueCheck({ id: templateData.typeCheckbook[0] });
            }
            if (templateData.nameAnother?.length > 1) {
                setAnotherPerson(true);
            } else {
                setAnotherPerson(false);
            }
        }
    };

    const validateFieldDraft = (values) => {
        const errors = {};
        const { accountDebit } = values;
        if (!accountDebit || accountDebit.length === 0) {
            errors.accountDebit = i18nUtils.get("program.date.blank");
        }
        return errors;
    };

    const idTransaction = transaction?.idTransaction;

    const { preData } = props;
    const listProductDebit = preData?.listProductDebit || [];
    const listProductDebitExpenses = preData?.listProductDebitExpenses || [];
    const selectorAccountDebit = data.selectorAccountDebit || {
        options: listProductDebit.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
        })),
    };
    const selectorAccountDebitExpenses = data.selectorAccountDebitExpenses || {
        options: listProductDebitExpenses.map((acc) => ({
            ...acc,
            balance: { currency: acc.currency, quantity: acc.balance },
            id: acc.idProduct,
        })),
    };

    const pickupLocationsList = preData?.pickup || data.pickupLocationsList || [];
    const valuesTypesCheckbook = config.get("checkbookTypes.form.values").split("|");

    const selectorTypeCheckbook = data.selectorTypeCheckbook || {
        options: valuesTypesCheckbook.map((value) => ({
            id: value,
            label: value,
        })),
    };

    const documentFilter = data.documentFilter || {
        options: documentTypes.map((value) => ({
            documentCountry: value.id_country_code,
            id: value.id,
            name: value.id,
            value: value.id,
            documentTypeLabel: value.label,
        })),
    };

    const renderFields = (setFieldValue) => {
        const { currentLang, fromBackoffice, isMobile } = props;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
        const numberOfCheckbook = {
            options: numbers.map((num) => ({
                id: num,
                label: num,
            })),
        };
        const showAuthorizedAnother = mode === "edit";

        const showAnotherFields = anotherPerson || (mode !== "edit" && data?.nameAnother?.length > 1);

        const showTermsData = (showAuthorizedAnother && !fromBackoffice) || (mode === "view" && fromBackoffice);

        const changeCheckAnotherPerson = () => {
            setAnotherPerson(!anotherPerson);
            setFieldValue("authorizedAnotherPerson", !anotherPerson);
            setFieldValue("nameAnother", null);
            setFieldValue("lastnameAnother", null);
            setFieldValue("documentAnother", null);
        };

        let valueAmountCheckbook = "";
        if (data?.amountCheckbook) {
            valueAmountCheckbook =
                typeof data.amountCheckbook === "string" ? data.amountCheckbook : data?.amountCheckbook[0];
        }

        let valueNumberOfCheckbook = "";
        if (data?.numberOfCheckbook) {
            valueNumberOfCheckbook =
                typeof data.numberOfCheckbook === "string" ? data.numberOfCheckbook : data?.numberOfCheckbook[0];
        }

        return (
            <React.Fragment>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={selectorAccountDebit}
                    key="accountDebit"
                    name="accountDebit"
                    idField="accountDebit"
                    accountSelected={accountSelected}
                    labelBackoffice={data.accountDebitLabel ? data.accountDebitLabel : ""}
                    value={data.accountDebit ? data.accountDebit : ""}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={selectorTypeCheckbook.options}
                    onChange={valueCheck}
                    key="typeCheckbook"
                    name="typeCheckbook"
                    idField="typeCheckbook"
                    renderAs="combo"
                    value={data.typeCheckbook ? data.typeCheckbook : ""}
                />

                <div className="form-group--double-field">
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={amount}
                        disabled={amount?.length <= 0}
                        key="amountCheckbook"
                        name="amountCheckbook"
                        idField="amountCheckbook"
                        renderAs="combo"
                        value={[valueAmountCheckbook]}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={numberOfCheckbook.options}
                        disabled={!isCheckbook}
                        key="numberOfCheckbook"
                        name="numberOfCheckbook"
                        idField="numberOfCheckbook"
                        renderAs="combo"
                        value={[valueNumberOfCheckbook]}
                        defaultValue={mode !== "view" ? ["1"] : ""}
                    />
                </div>

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.ProductselectorCustom}
                    data={selectorAccountDebitExpenses}
                    key="accountDebitExpenses"
                    name="accountDebitExpenses"
                    idField="accountDebitExpenses"
                    accountSelected={accountSelected}
                    labelBackoffice={data.accountDebitExpensesLabel ? data.accountDebitExpensesLabel : ""}
                    value={data?.accountDebitExpenses || ""}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={pickupLocationsList}
                    key="pickup"
                    name="pickup"
                    idField="pickup"
                    renderAs="combo"
                    value={data.pickup ? data.pickup : ["1"]}
                    additionalClassName="pickup"
                />

                <h4 className={mode === "edit" ? "ui-mt-3 ui-mb-7" : "ui-mt-7"}>
                    {i18nUtils.get("forms.request.checkbook.authorizedDataTitle")}
                </h4>

                <div className="form-group--double-field">
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="name"
                        name="name"
                        idField="name"
                        maxLength="50"
                        value={data?.name || null}
                    />
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="lastname"
                        name="lastname"
                        idField="lastname"
                        maxLength="50"
                        value={data?.lastname || null}
                    />
                </div>

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Document}
                    data={documentFilter.options}
                    hideDocumentPlaceholder
                    mode={mode}
                    key="document"
                    name="document"
                    idField="document"
                    value={data?.document || null}
                />

                {mode !== "edit" && (
                    <>
                        <div className="data-wrapper">
                            <div className="data-label">{i18nUtils.get(`${ID_ACTIVITY}.document.view.label`)}</div>
                            <div className="data-text">{data?.document.document}</div>
                        </div>
                    </>
                )}

                {showAnotherFields && (
                    <>
                        <div className="form-group--double-field">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="nameAnother"
                                name="nameAnother"
                                idField="nameAnother"
                                maxLength="50"
                                value={data?.nameAnother || null}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="lastnameAnother"
                                name="lastnameAnother"
                                idField="lastnameAnother"
                                maxLength="50"
                                value={data?.lastnameAnother || null}
                            />
                        </div>

                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Document}
                            data={documentFilter.options}
                            hideDocumentPlaceholder
                            mode={mode}
                            key="documentAnother"
                            name="documentAnother"
                            idField="documentAnother"
                            value={data?.documentAnother || null}
                        />

                        {mode !== "edit" && (
                            <>
                                <div className="data-wrapper">
                                    <div className="data-label">
                                        {i18nUtils.get(`${ID_ACTIVITY}.documentAnother.view.label`)}
                                    </div>
                                    <div className="data-text">{data?.documentAnother.document}</div>
                                </div>
                            </>
                        )}
                    </>
                )}

                {mode === "edit" && (
                    <div className="ui-dflex-center ui-mt-3">
                        <Button
                            image={`images/ui-icons/${
                                showAnotherFields ? "ui-subtract-white.svg" : "ui-plus-white.svg"
                            }`}
                            label={`forms.request.checkbook.authorizedAnotherPerson.${
                                showAnotherFields ? "remove" : "add"
                            }.label`}
                            bsStyle="secondary"
                            onClick={changeCheckAnotherPerson}
                            block={isMobile}
                        />
                    </div>
                )}

                {data?.cancelReason && (
                    <div className="data-wrapper inline">
                        <span className="data-label">
                            <I18n id="forms.cancelRequest.cancelReason.ticket.label" />
                        </span>
                        <span className="data-text">{data.cancelReason}</span>
                    </div>
                )}

                {showTermsData && (
                    <React.Fragment>
                        {!fromBackoffice && (
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                optionList={[
                                    {
                                        id: "1",
                                        label: i18nUtils.get(
                                            `forms.request.investment.subscribe.termsAndConditions.termsAndConditions`,
                                        ),
                                    },
                                ]}
                                key="termsAndConditionsAcceptance"
                                // se cambia para que aparezca el titulo de campo solo si viene de backoffice
                                name="termsAndConditionsAcceptance"
                                idField={
                                    !fromBackoffice
                                        ? "termsAndConditionsAcceptance"
                                        : "termsAndConditionsAcceptanceBackoffice"
                                }
                                renderAs="check"
                                value={
                                    data.termsAndConditionsAcceptance?.length > 1
                                        ? data.termsAndConditionsAcceptance
                                        : ["0"]
                                }
                                additionalClassName="ui-mt-8"
                            />
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {}; // eslint-disable-line

    const formProps = {
        title: "forms.request.checkbook.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
            onTemplateLoad,
        },
        data: {
            selectorAccountDebit,
            selectorAccountDebitExpenses,
            pickupLocationsList,
            selectorTypeCheckbook,
            documentFilter,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        buttonLabel: "global.continue",
        validateFieldDraft,
        modalButtonLabel: "forms.saveTemplate.link",
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};
RequestCheckbookForm.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    accountSelected: string,
    useDefaultSubmit: bool,
    isMobile: bool.isRequired,
};

RequestCheckbookForm.defaultProps = {
    fromBackoffice: false,
    preData: null,
    accountSelected: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    documentTypes: miscSelectors.getDocumentTypes(state),
    accountSelected: checksSelectors.getAccountIdSelected(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestCheckbookForm);
