import { bool, func, shape } from "prop-types";
import React from "react";
import SimpleModal from "components/SimpleModal/SimpleModal";
import InvestorProfileImage from "./InvestorProfileImage";
import InvestorProfileName from "./InvestorProfileName";

const InvestorProfileModal = ({ isDisplayed, closeModal, buttonAction, profile, isDesktop }) => (
    <SimpleModal
        buttonAction={buttonAction}
        buttonActionLabel="investments.mutualFund.investerProfile.save"
        buttonCancelLabel="investments.mutualFund.investerProfile.redo"
        closeButton={false}
        closeModal={closeModal}
        disclaimer="investments.mutualFund.investerProfile.save.disclaimer"
        isDesktop={isDesktop}
        isDisplayed={isDisplayed}
        className="investorProfileModal">
        <InvestorProfileImage profile={profile} showClassName={false} />

        <div className="invester-text">
            <InvestorProfileName profileName={profile.type} usedFor="forMessage" component="span" />
        </div>
    </SimpleModal>
);

InvestorProfileModal.propTypes = {
    buttonAction: func.isRequired,
    closeModal: func.isRequired,
    isDisplayed: bool,
    isDesktop: bool,
    profile: shape({}).isRequired,
};

InvestorProfileModal.defaultProps = {
    isDisplayed: false,
    isDesktop: true,
};

export default InvestorProfileModal;
