import React, { useEffect, useState } from "react";
import Table from "pages/_components/Table";
import Checkbox from "pages/_components/fields/Checkbox";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { selectors as suppliersSelectors, actions as supplierActions } from "reducers/paymentToSuppliers";
import { connect } from "react-redux";
import { array, func, oneOfType, shape, string } from "prop-types";
import { getKeyForSupplierValidation } from "util/string";

const CheckBoxForItem = ({ dispatch, detail, vouchersToDownload, paymentType, keyForChechBox }) => {
    const multipleDownloadLimit = config.get("transactions.maxLines.to.download", 50);
    const [isSelectedCheckBox, setSelectedCheckBox] = useState(false);

    useEffect(() => {
        const isSelected = vouchersToDownload.includes(keyForChechBox);
        setSelectedCheckBox(isSelected);
    }, [vouchersToDownload]);

    const handleChangeAddList = () => {
        const keyDetailForValidation = getKeyForSupplierValidation(paymentType);
        if (vouchersToDownload.length < multipleDownloadLimit) {
            setSelectedCheckBox(!isSelectedCheckBox);
            dispatch(supplierActions.addVoucherToDownload(detail, keyDetailForValidation));
        } else if (isSelectedCheckBox) {
            setSelectedCheckBox(false);
            dispatch(supplierActions.addVoucherToDownload(detail, keyDetailForValidation));
        } else {
            dispatch(
                notificationModalActions.showNotification(
                    i18n.get("forms.massivePayments.transactions.maxLines.to.download.error", "", {
                        maxLines: multipleDownloadLimit,
                    }),
                    "warning",
                ),
            );
        }
    };

    return (
        <Table.Data align="left">
            <Checkbox
                key={keyForChechBox}
                name={keyForChechBox}
                value={keyForChechBox}
                label=""
                checked={isSelectedCheckBox}
                onChange={(e) => handleChangeAddList(e)}
                hideLabel
            />
        </Table.Data>
    );
};

CheckBoxForItem.propTypes = {
    dispatch: func.isRequired,
    detail: shape({}).isRequired,
    paymentType: string.isRequired,
    keyForChechBox: string.isRequired,
    vouchersToDownload: oneOfType([array]).isRequired,
};
const mapStateToProps = (state) => ({
    vouchersToDownload: suppliersSelectors.getVouchersToDownload(state),
});

export default connect(mapStateToProps)(CheckBoxForItem);
