import React, { useState } from "react";
import { bool, node, string } from "prop-types";

import Button from "../Button";

import "./_accordionItem.scss";

const AccordionItem = ({ title, children, isOpened, customTitle, additionalClassName }) => {
    const [openItem, setOpenItem] = useState(isOpened);

    return (
        <div className={`accordion-item-container ${additionalClassName || ""}`}>
            <div className="accordion-item__title" role="button" onClick={() => setOpenItem(!openItem)}>
                {title && <h2>{title}</h2>}
                {customTitle || null}
                <Button
                    bsStyle="link"
                    className="btn-only-icon"
                    image={`images/ui-icons/ui-arrow-${openItem ? "up" : "down"}-blue.svg`}
                    onClick={() => setOpenItem(!openItem)}
                />
            </div>
            {openItem && <div className="accordion-item__content">{children}</div>}
        </div>
    );
};

AccordionItem.propTypes = {
    title: string,
    children: node,
    isOpened: bool,
    customTitle: node,
    additionalClassName: string,
};

AccordionItem.defaultProps = {
    title: "",
    children: null,
    isOpened: false,
    customTitle: null,
    additionalClassName: "",
};

export default AccordionItem;
