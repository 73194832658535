import { Field, Form, withFormik } from "formik";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import Button from "pages/_components/Button";
import DocumentField from "pages/_components/fields/DocumentField";
import I18n from "pages/_components/I18n";
import { arrayOf, bool, func, node, object, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { selectors as assistantSelectors } from "reducers/assistant";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as miscActions, selectors as miscSelectors } from "reducers/misc";
import { compose } from "redux";
import { Mixpanel } from "util/clickstreaming";
import * as config from "util/config";
import * as i18n from "util/i18n";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";

const FORM_ID = "login.step1";

class Step1Content extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobileNative: bool.isRequired,
        isDesktop: bool.isRequired,
        isFromAmazon: bool.isRequired,
        isFromGoogle: bool.isRequired,
        isFromWhatsapp: bool.isRequired,
        isFromMessenger: bool.isRequired,
        isFetching: bool.isRequired,
        footer: node,
        fingerprintAvailable: shape({}),
        fingerPrintEnvironmentRestricted: bool,
        setValues: func.isRequired,
        user: string,
        values: shape({}).isRequired,
        userFullName: string,
        // eslint-disable-next-line react/forbid-prop-types
        documentTypes: arrayOf(object),
    };

    static defaultProps = {
        footer: null,
        fingerPrintEnvironmentRestricted: false,
        documentTypes: null,
        fingerprintAvailable: null,
        user: null,
        userFullName: null,
    };

    state = {
        fingerprintInitialized: false,
        openVirtualKeyboard: false,
        inputSelected: "",
        inputsValueKeyboard: {
            document: "",
            user: "",
            inputSelectedKeyboard: "",
        },
        layout: "default",
    };

    componentDidMount() {
        const { dispatch, isMobileNative } = this.props;

        dispatch(miscActions.getDocumentTypes());
        if (isMobileNative) {
            window.common.hideSplashScreen();
            dispatch(fingerprintActions.fingerprintAvailability());
        }
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isMobileNative, dispatch, fingerPrintEnvironmentRestricted } = this.props;
        const { fingerprintInitialized } = this.state;

        if (isMobileNative && !fingerprintInitialized && nextProps.fingerprintAvailable) {
            const { isHardwareDetected, hasEnrolledFingerprints, isAvailable } = nextProps.fingerprintAvailable;

            if (isHardwareDetected && hasEnrolledFingerprints && isAvailable && !fingerPrintEnvironmentRestricted) {
                dispatch(loginActions.fingerprintLoginPre());
            }

            this.setState({ fingerprintInitialized: true });
        }
    }

    shouldComponentUpdate(nextProps) {
        const { user } = this.props;

        // when user selected to be remembered, this prevents showing user welcome message when navigating to step 2
        if (nextProps.user && user === "") {
            return false;
        }

        return true;
    }

    toggleVirtualKeyboard() {
        const { openVirtualKeyboard } = this.state;
        this.setState({ openVirtualKeyboard: !openVirtualKeyboard });
    }

    setInputSelected = (e) => {
        this.setState({ inputSelected: e.target.name });
    };

    onChangeKeyboard = (e) => {
        const { inputSelected, inputsValueKeyboard } = this.state;
        const { setValues, values } = this.props;
        const copyValues = { ...values };
        if (inputSelected && inputSelected === inputsValueKeyboard.inputSelectedKeyboard) {
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: e,
                    inputSelectedKeyboard: inputSelected,
                },
            });
            if (inputSelected === "document") {
                copyValues.document = {
                    document: e,
                    type: copyValues.document.type,
                };
            } else {
                copyValues.user = e;
            }
            setValues(copyValues);
        } else if (inputSelected) {
            let valueInput;
            if (inputSelected === "document") {
                valueInput = inputsValueKeyboard.document
                    ? inputsValueKeyboard.document + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            } else {
                valueInput = inputsValueKeyboard.user
                    ? inputsValueKeyboard.user + e.substr(e.length - 1)
                    : e.substr(e.length - 1);
            }
            this.setState({
                inputsValueKeyboard: {
                    ...inputsValueKeyboard,
                    [inputSelected]: valueInput,
                    inputSelectedKeyboard: inputSelected,
                },
            });
            if (inputSelected === "document") {
                copyValues.document = {
                    document: valueInput,
                    type: copyValues.document.type,
                };
            } else {
                copyValues.user = valueInput;
            }
            setValues(copyValues);
        }
    };

    reset = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.removeRememberedUser());
    };

    onChangeInput = ({ target }) => {
        const { name, value } = target;

        this.setState((prevState) => ({
            inputsValueKeyboard: {
                ...prevState.inputsValueKeyboard,
                [name]: value,
                inputSelectedKeyboard: name,
            },
        }));
    };

    handleShift = () => {
        const newLayoutName = this.state.layout === "default" ? "shift" : "default";
        this.setState((prevState) => ({
            ...prevState,
            layout: newLayoutName,
        }));
    };

    onKeyPress = (button) => {
        if (button === "{shift}" || button === "{lock}") {
            this.handleShift();
        }
    };

    render() {
        const { openVirtualKeyboard, inputSelected } = this.state;
        const {
            footer,
            isDesktop,
            isFetching,
            user,
            userFullName,
            isFromAmazon,
            isFromGoogle,
            isFromMessenger,
            isFromWhatsapp,
            documentTypes,
        } = this.props;
        return (
            <Form className="form-content" noValidate="novalidate">
                {!isDesktop && (isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && (
                    <AssistantAlert />
                )}
                {user ? (
                    <div className="text-lead">
                        <h2>
                            {
                                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            }
                            <span>
                                <I18n id="login.step1.welcome" /> {userFullName}
                            </span>
                        </h2>
                        <p>
                            <I18n id="login.step1.areNotYou" />{" "}
                            <Button bsStyle="primary" onClick={this.reset} label="login.step1.forget" block={false} />
                        </p>
                    </div>
                ) : (
                    <>
                        <Field
                            idForm={FORM_ID}
                            name="document"
                            idField="document"
                            component={DocumentField}
                            autoComplete="off"
                            // autoFocus={isDesktop}
                            hideDocumentPlaceholder
                            handleOnChange={isDesktop && this.onChangeInput}
                            onClick={isDesktop && this.setInputSelected}
                            searchable
                            clearable={false}
                            data={{ documentTypes }}
                            inputRef={this.keyboard}
                        />
                        <Field
                            idForm={FORM_ID}
                            name="user"
                            idField="user"
                            hidePlaceholder
                            pattern={config.get("core.idUser.pattern")}
                            maxLength={config.getInteger("core.idUser.maxLength", 50)}
                            copyEnabled={false}
                            component={Credential}
                            additionalClassName="ui-mb-3"
                            onClick={isDesktop && this.setInputSelected}
                            inputRef={this.keyboard}
                        />
                        <div className="form-group">
                            <Link
                                to="/recoverUser/step1"
                                onClick={() => Mixpanel.track("login.recoverUser")}
                                className="recover-user">
                                <I18n id="login.step1.recoverUser.label" />
                            </Link>
                        </div>
                    </>
                )}
                <Button block type="submit" bsStyle="primary" label="global.continue" loading={isFetching} />
                <div className="options-login">
                    <Link to="/enrollment" onClick={() => Mixpanel.track("login.noUser")}>
                        <I18n id="login.footer.createUser" componentProps={{ "aria-hidden": "true" }} />
                        <span className="visually-hidden">{i18n.get("login.footer.createUser.a11y")}</span>
                    </Link>
                </div>

                {isDesktop && (
                    <>
                        <div aria-hidden="true" className="virtual-keyboard">
                            <Button
                                image="images/ui-icons/virtual_keyboard.svg"
                                className="btn-only-icon"
                                alt="Virtual keyboard"
                                onClick={() => this.toggleVirtualKeyboard()}
                            />
                            <span onClick={() => this.toggleVirtualKeyboard()}>Teclado Virtual</span>
                        </div>

                        {openVirtualKeyboard && (
                            <div className={`d-${openVirtualKeyboard ? "block" : "none"} ui-mt-6`}>
                                <Keyboard
                                    onChange={this.onChangeKeyboard}
                                    onKeyPress={inputSelected}
                                    inputName={inputSelected}
                                    keyboardRef={(e) => {
                                        this.keyboard = e;
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
                {footer}
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    ...loginSelectors.getRememberedUser(state),
    fingerprintAvailable: fingerprintSelectors.getAvailability(state),
    isFetching: loginSelectors.getFetching(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    fingerPrintEnvironmentRestricted: loginSelectors.getFingerPrintEnvironmentRestricted(state),
    documentTypes: miscSelectors.getDocumentTypes(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            user: props.user || "",
            document: {
                country: "",
                document: "",
                type: "DN",
            },
            rememberEmail: false,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    user: Yup.string()
                        .required(i18n.get("login.step1.user.error.empty"))
                        .test("format", i18n.get("global.username.format.invalid"), (value) => {
                            if (value?.length < config.get("username.minLength")) {
                                return false;
                            }
                            if (value?.length > config.get("username.maxLength")) {
                                return false;
                            }
                            const rexp = new RegExp(config.get("username.pattern"));
                            return rexp.test(value);
                        }),
                    document: Yup.object().shape({
                        document: Yup.string()
                            .max(
                                config.get(`documentType.maxLength.${values.document.type}`, 11),

                                i18n.get("global.document.format.invalid"),
                            )
                            .required(i18n.get(`${FORM_ID}.document.required`))
                            .test("format", i18n.get("global.document.format.invalid"), (value) => {
                                const rexp = new RegExp("^[0-9]*$");
                                return rexp.test(value);
                            }),
                        type: Yup.string().required(i18n.get(`${FORM_ID}.documentType.required`)),
                    }),
                }),
            ),
        handleSubmit: ({ user, document, rememberEmail }, formikBag) => {
            Mixpanel.identify(user);
            Mixpanel.track(FORM_ID, { rememberEmail });
            formikBag.props.dispatch(
                loginActions.loginStep1(user, document.type, document.document, rememberEmail, formikBag),
            );
        },
    }),
)(Step1Content);
