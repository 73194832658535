import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";
import { actions as miscActions, selectors as miscSelectors } from "reducers/misc";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import DocumentField from "pages/_components/fields/DocumentField";

import withExchangeToken from "pages/_components/withExchangeToken";
import * as config from "util/config";

import { bool, func, string, shape, arrayOf } from "prop-types";

import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.associate.step1";

class AssociateStep1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        account: shape({ accountName: string.isRequired }).isRequired,
        documentTypes: arrayOf(shape({})),
    };

    static defaultProps = {
        documentTypes: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(miscActions.getDocumentTypes());
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { account, documentTypes, isDesktop, isSubmitting } = this.props;

        return (
            <div className="app-page">
                <div className="app-content">
                    <div className="view-wrapper">
                        <div className="view-page justify-content-center associate-step">
                            <div className="view-content">
                                <div className="title-header">
                                    <I18n id="enrollment.associate.step1.header" component="h1" />
                                </div>
                                <Form className="form-content container">
                                    <div className="pre-form-info">
                                        <div className="pre-form-info__text">
                                            <I18n
                                                id="enrollment.associate.step1.title"
                                                component="p"
                                                componentProps={{ className: "ui-mt-0" }}
                                                ENVIRONMENT_OWNER={account && account.accountName}
                                            />
                                        </div>
                                    </div>

                                    <Field
                                        idForm={FORM_ID}
                                        name="document"
                                        component={DocumentField}
                                        autoComplete="off"
                                        hideDocumentPlaceholder={false}
                                        handleOnChange={isDesktop && this.onChangeInput}
                                        onClick={isDesktop && this.setInputSelected}
                                        searchable
                                        clearable={false}
                                        data={{ documentTypes }}
                                        inputRef={this.keyboard}
                                    />

                                    <Field
                                        idForm={FORM_ID}
                                        name="user"
                                        component={Credential}
                                        autoFocus={isDesktop}
                                        hidePlaceholder={false}
                                        handleOnChange={isDesktop && this.onChangeInput}
                                        onClick={isDesktop && this.setInputSelected}
                                        pattern={config.get("core.idUser.pattern")}
                                        maxLength={config.getInteger("core.idUser.maxLength", 50)}
                                        copyEnabled={false}
                                    />

                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={isSubmitting}
                                        type="submit"
                                        block
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: enrollmentSelectors.getAccount(state),
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentTypes: miscSelectors.getDocumentTypes(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            document: {
                country: "AR",
                document: "",
                type: "DN",
            },
            user: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    document: Yup.object().shape({
                        document: Yup.string()
                            .max(
                                config.get(`documentType.maxLength.${values.document.type}`, 11),
                                i18nUtils.get("global.document.format.invalid"),
                            )
                            .required(i18nUtils.get(`${FORM_ID}.document.error.empty`))
                            .test("format", i18nUtils.get("global.document.format.invalid"), (value) => {
                                const rexp = new RegExp("^[0-9]*$");
                                return rexp.test(value);
                            }),
                        type: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentType.error.required`)),
                    }),
                    user: Yup.string()
                        .required(i18nUtils.get(`${FORM_ID}.username.error.empty`))
                        .test("format", i18nUtils.get("global.username.format.invalid"), (value) => {
                            if (value?.length < config.get("username.minLength")) {
                                return false;
                            }
                            if (value?.length > config.get("username.maxLength")) {
                                return false;
                            }
                            const rexp = new RegExp(config.get("username.pattern"));
                            return rexp.test(value);
                        }),
                }),
            ),
        handleSubmit: ({ user, document }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.associateStep1Verify(user, document, formikBag));
        },
    }),
)(withExchangeToken(AssociateStep1));
