/**
 * crypto.util.react.js (banking-api extension).
 * Requires: aes.js, pbkdf2.js (CryptoJS v3.1.2+), jsencrypt.min.js.
 */
import JSEncrypt from "node-jsencrypt";

const publicRSAKey =
    "-----BEGIN PUBLIC KEY----- 		MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4b2L3Gk8Q+qlcFQSRmho	 	/fDPvrZ3A5jx3/9XE2X7N6EO2F9RcH23b8EY8hEEd7lHNXqHDYcpt97QPgey1lTq	 	NItUrRqYFACU+5FFxUD6hwcibZFRaBy8PjHsfT2m9VcTlCAvViybHrwWMH73o9V8	 	zLJfoFtxKKxfiTv4CBVIxLN+dTOJbqKgOYSRCG/3022rWcB4KpUqQlqTfp+3CreI	 	IOcTVq9MZ41MvniMczBdC4ywOtBm/sd9acpUch2MKUbEe8nL9izfjahS6MADFAHq	 	DTStCAsfPwh5S3yOj0cVtun6rkMhPG1Av6HT1bAWW8Du6BvF1ZKdC4ZY4t3/e005	 	oQIDAQAB	 	-----END PUBLIC KEY-----";

/**
 * Encrypt received text with RSA public key.
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function encryptText(text) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicRSAKey);
    return encrypt.encrypt(text);
}

export function mapActiveEnvironment(data, forms) {
    const filteredEnvironment = data?.environments[data?.activeIdEnvironment];

    return {
        permissions: data?.permissions,
        forms,
        name: data?.activeEnvironmentName,
        type: data?.activeEnvironmentType,
        id: data?.activeIdEnvironment,
        administrationScheme: data?.administrationScheme,
        clients: data?.clients,
        segment: filteredEnvironment?.segment,
        accountOfficer: filteredEnvironment?.accountOfficer,
        transactionTypes: data?.transactionTypes,
        availableFeatures: data?.availableFeatures,
        userSignatureLevel: data?.userSignatureLevel,
        environmentSignaturesLevels: data?.signatures,
        featureActivities: data?.featureActivities,
    };
}
