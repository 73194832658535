import React, { Component } from "react";
import { connect } from "react-redux";
import { actions as filterActions, selectors as filterSelectors } from "reducers/filters";
import { types as loanTypes, actions as loanActions, selectors as loanSelectors } from "reducers/loans";
import { bool, func, shape, string, objectOf, any } from "prop-types";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import * as i18n from "util/i18n";
import Button from "pages/_components/Button";
import defaultDateFromList from "util/defaultDateFromList";
import { validateToResetFilters } from "util/filters";
import List from "./List";
import Filters from "./Filters";

const ID_ACTIVITY = "loans.list";

class LoansList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        history: objectOf(any).isRequired,
        filters: shape({}).isRequired,
        isResetQuery: bool.isRequired,
        isMobile: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        idActivityOnFilter: string.isRequired,
        showFilters: bool.isRequired,
    };

    componentWillMount = () => {
        const { isResetQuery, filters, activeEnvironment } = this.props;
        const { permissions } = activeEnvironment;
        const type = permissions["loans.active"] ? "current" : "history";

        this.handleTabClick(isResetQuery ? type : filters.loanType);
    };

    handleTabClick = (loanType) => {
        const { dispatch, filters, isResetQuery, activeEnvironment, idActivityOnFilter } = this.props;

        const { permissions } = activeEnvironment;
        const hasActivePermission = permissions["loans.active"] || false;
        const hasHistoryPermission = permissions["loans.history"] || false;

        if (
            loanType !== filters.loanType &&
            ((loanType === "current" && !hasActivePermission) || (loanType === "history" && !hasHistoryPermission))
        ) {
            dispatch(notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["loansList"]));
        } else {
            dispatch(loanActions.requestLoanTypesList(loanType));
            dispatch(filterActions.validateToResetFilters(loanTypes.RESET_FILTERS, ID_ACTIVITY));

            if (validateToResetFilters(isResetQuery, idActivityOnFilter, ID_ACTIVITY)) {
                dispatch(
                    loanActions.setFilters({
                        loanType,
                        operationType: "",
                        dateFrom: defaultDateFromList().loans.dateFrom,
                        dateTo: defaultDateFromList().loans.dateTo,
                    }),
                );

                const defaultFilters = {
                    loanType,
                    operationType: "",
                    dateFrom: defaultDateFromList().loans.dateFrom,
                    dateTo: defaultDateFromList().loans.dateTo,
                    pageNumber: 1,
                };
                dispatch(loanActions.listLoans(defaultFilters));
            }
        }
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" />;
        }

        return <Head showPlusIcon />;
    };

    renderFilters = () => {
        const { isMobile, showFilters } = this.props;

        if (isMobile) {
            return showFilters && <Filters idForm={ID_ACTIVITY} isMobile={isMobile} />;
        }

        return <Filters idForm={ID_ACTIVITY} isMobile={isMobile} />;
    };

    onToggleFilters = () => {
        const { dispatch } = this.props;
        dispatch(filterActions.toggleShowFilters());
    };

    render() {
        const {
            isDesktop,
            history,
            isMobile,
            filters: { loanType },
            showFilters,
        } = this.props;

        return (
            <>
                <Notification scopeToShow="loansList" />

                {this.renderHeader()}

                <MainContainer>
                    <div className="container-left">
                        <div className="title-left-header">
                            <I18n id="menu.loans.list.label" component="h1" />
                        </div>
                        <>
                            <div className="tabs-wrapper">
                                <Button
                                    label={`${ID_ACTIVITY}.current.label`}
                                    onClick={() => this.handleTabClick("current")}
                                    bsStyle="outline"
                                    className={`${loanType === "current" ? "active" : ""}`}
                                />
                                <Button
                                    label={`${ID_ACTIVITY}.history.label`}
                                    onClick={() => this.handleTabClick("history")}
                                    bsStyle="outline"
                                    className={`${loanType === "history" ? "active" : ""}`}
                                />
                            </div>
                        </>

                        {isMobile && (
                            <Button
                                onClick={() => this.onToggleFilters()}
                                bsStyle="filter"
                                className="btn-link"
                                block
                                image={`images/${!showFilters ? "selectArrowDown.svg" : "selectArrowUpBlack.svg"}`}
                                label={`checks.listChecks.filterMovements.${showFilters ? "hide" : "show"}`}
                            />
                        )}

                        {this.renderFilters()}

                        <List
                            history={history}
                            isMobile={isMobile}
                            idActivity={ID_ACTIVITY}
                            isDesktop={isDesktop}
                            loanType={loanType}
                        />
                    </div>
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    isResetQuery: filterSelectors.isResetFilters(state),
    filters: loanSelectors.getFilters(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    showFilters: filterSelectors.getShowFilters(state),
    idActivityOnFilter: filterSelectors.getIdActivityOnFilter(state),
});

export default connect(mapStateToProps)(LoansList);
