import React from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import TextField from "./TextField";
import FieldLabel from "./FieldLabel";

function TextFieldSearch(props) {
    const { hideLabel, labelText, idForm, field, optional, idField } = props;
    const fieldLabel = labelText || `${idForm}.${field.name}.label`;
    const id = idField || `${idForm}.${field.name}`;
    return (
        <div className="form-group  form-group--search">
            {!hideLabel && <FieldLabel labelKey={fieldLabel} optional={optional} idField={id} />}
            <TextField {...props} type="text" hideLabel hidePlaceholder />
        </div>
    );
}

TextFieldSearch.propTypes = {
    hideLabel: bool,
    labelText: string,
    idForm: string,
    field: shape({
        onBlur: func,
        onChange: func,
        name: string,
        value: oneOfType([number, string]),
    }).isRequired,
    optional: string,
    idField: string,
};

TextFieldSearch.defaultProps = {
    hideLabel: false,
    labelText: null,
    idForm: "",
    optional: "",
    idField: "",
};

export default TextFieldSearch;
