import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { actions as massPaymentsActions, selectors as massivePaymentsSelector } from "reducers/massPayments";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, func, string, oneOfType, arrayOf, objectOf, any } from "prop-types";

import Button from "pages/_components/Button";
import SimpleModal from "components/SimpleModal/SimpleModal";
import { actions as fileActions } from "reducers/files";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";
import FormTransition from "../_components/FormTransition";
import ChangeStatusTransactionComponent from "./ChangeStatusTransactionComponent";
import PaymentDetail from "../_components/PaymentDetail/PaymentDetail";
import I18n from "../../_components/I18n";
import FormattedAmount from "../../_components/FormattedAmount";
import CustomScheduler from "../_components/_scheduler/CustomScheduler";

const ID_FORM = "pay.transfers.massive";
const ID_ACTIVITY = "pay.transfers.massive.send";
const ID_ACTIVITY_PRE = "pay.transfers.massive.pre";

const TransferMassive = (props) => {
    const {
        mode,
        dispatch,
        data,
        currentLang,
        fromBackoffice,
        transaction,
        isMobile,
        history,
        paymentDetails,
        payments,
        location,
        isDesktop,
        preData,
        dataToEdit: { paymentsToEdit, detailsToEdit },
    } = props;

    const idTransaction = transaction?.idTransaction;
    const tooltipPosition = isMobile ? "bottom-right" : "right";

    const getTransferType = () => {
        if (data?.transferType && data?.transferType.length > 0) {
            return data.transferType[0];
        }

        return null;
    };

    const getConcept = () => {
        if (data?.concept && data?.concept.length > 0) {
            return data.concept[0];
        }

        return null;
    };

    const getUploadType = () => {
        if (data?.uploadType && data?.uploadType.length > 0) {
            return data?.uploadType[0];
        }

        return null;
    };

    const isConceptDisplayed = () => {
        const transferType = data?.transferType || null;

        return transferType && transferType[0] === "3";
    };

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [accountSelected, setAccountSelected] = useState(null);
    const [transferTypeSelected, setTransferTypeSelected] = useState(null);
    const [conceptSelected, setConceptSelected] = useState(null);
    const [uploadTypeSelected, setUploadTypeSelected] = useState(null);
    const [showConcept, setShowConcept] = useState(() => isConceptDisplayed());
    const [modalDataToChange, setModalDataToChange] = useState(null);
    const [subtitle, setSubTitle] = useState("");
    const [mobileMenuOptions, setMobileMenuOptions] = useState(null);

    const setPayments = (from) => {
        if (from === "fromData") {
            if (data?.payments && data.payments.length > 0 && data?.paymentDetails) {
                dispatch(massPaymentsActions.setPaymentsRequest(data.payments, data.paymentDetails));
                dispatch(
                    massPaymentsActions.setPaymentHeader({
                        account: data?.account,
                        currency: data?.account?.currency,
                    }),
                );
            } else {
                dispatch(massPaymentsActions.setPaymentsRequest([]));
            }
        } else {
            let dataToLoad = [];

            if (payments && payments.length > 0) {
                dataToLoad = payments;
            } else if (data?.payments && data.payments.length > 0) {
                dataToLoad = data?.payments;
            }

            dispatch(massPaymentsActions.setPaymentsRequest(dataToLoad));

            const accountData = accountSelected || data?.account;
            dispatch(
                massPaymentsActions.setPaymentHeader({
                    account: accountData,
                    currency: accountData?.currency,
                }),
            );
        }
    };

    const handleReportClick = () => {
        dispatch(
            massPaymentsActions.downloadPaymentsCsvFile(
                transaction?.idTransaction,
                transaction?.statusLabel,
                detailsToEdit,
                paymentsToEdit,
            ),
        );
    };

    const renderMenuOption = () => {
        const menuOptions = [];
        menuOptions.push(
            <Button
                label="forms.pay.transfers.massive.payment.list.action.downloadDetail.label"
                key="downloadPayments"
                className="dropdown__item-btn"
                bsStyle="link"
                onClick={handleReportClick}
            />,
        );

        setMobileMenuOptions(menuOptions);
    };

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE }));

            setAccountSelected(data?.account || null);
            setTransferTypeSelected(getTransferType());
            setConceptSelected(getConcept());
            setUploadTypeSelected(getUploadType());

            const newForm = location.pathname.match("/formCustom/transferMassive") != null;

            if (newForm) {
                setPayments("fromData");
            } else if (data?.paymentDetails?.validFileId) {
                setPayments("fromData");
            } else {
                setPayments();
            }
        } else if (mode === "view") {
            setPayments("fromData");
        }
    }, [dispatch, location, transaction]);

    useEffect(() => {
        if (mode.includes("massive-list-")) {
            const newSubTitle = transaction?.successfullyCompleted
                ? i18nUtils.get("forms.pay.transfers.massive.paymentsDetail.subtitle.finished")
                : i18nUtils.get("forms.pay.transfers.massive.paymentsDetail.subtitle");
            setSubTitle(newSubTitle);

            if (mode === "massive-list-edit") {
                renderMenuOption();
            }
        } else {
            setSubTitle("");
            setMobileMenuOptions(null);
        }
    }, [mode]);

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const genericPropsConditions = {
        mode: "edit",
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const onTemplateLoad = (templateData) => {
        if (Object.keys(templateData).length > 0 && mode === "edit") {
            setUploadTypeSelected(templateData?.uploadType[0]);
            setShowConcept(!!templateData?.concept);
            dispatch(
                massPaymentsActions.setPaymentHeader({
                    account: templateData?.account,
                    currency: templateData?.currency,
                }),
            );
            setAccountSelected(templateData?.account);
            return null;
        }

        return null;
    };

    const setDataForAccount = (idAccount, setFieldValue) => {
        if (idAccount) {
            const preDataToLoad = preData || {
                currencyList: [],
                debitAccountList: [],
            };

            const filteredOptions = preDataToLoad.debitAccountList?.find((acc) => acc.idProduct === idAccount) || data;
            const currencySelected = filteredOptions?.currency || data?.currency;
            setAccountSelected(filteredOptions);
            setFieldValue("labelDebit", filteredOptions?.label);
            setFieldValue("currency", currencySelected);
            setFieldValue("account", filteredOptions);

            dispatch(
                massPaymentsActions.setPaymentHeader({
                    account: filteredOptions,
                    currency: currencySelected,
                }),
            );
        }
    };

    const setDataForTransferType = (idTransferType) => {
        if (idTransferType) {
            setTransferTypeSelected(idTransferType);
            if (idTransferType === "3") {
                setShowConcept(true);
            } else {
                setShowConcept(false);
                setConceptSelected(null);
            }
        }
    };

    const setDataForConcept = (idConcept) => {
        if (idConcept) {
            setConceptSelected(idConcept);
        }
    };

    const setDataForUploadType = (idUploadType) => {
        if (idUploadType) {
            setUploadTypeSelected(idUploadType);
        }
    };

    const closeModal = () => {
        setShowConfirmationModal(!showConfirmationModal);
        setModalDataToChange(null);
    };

    const validateToOpenModal = () => {
        if (paymentDetails && paymentDetails.quantity) {
            return true;
        }

        return false;
    };

    const openModal = (from, dataToChange) => {
        setModalDataToChange(null);
        if (from && dataToChange) {
            setShowConfirmationModal(!showConfirmationModal);
            setModalDataToChange({ from, dataToChange });
        }
    };

    const handleNonAcceptanceResponse = (setFieldValue) => {
        const { from } = modalDataToChange;
        if (from) {
            if (from === "account") {
                setFieldValue("debitAccount", accountSelected?.idProduct);
                setDataForAccount(accountSelected?.idProduct, setFieldValue);
            } else if (from === "transferType") {
                setFieldValue("transferType", [transferTypeSelected]);
                setDataForTransferType(transferTypeSelected);
            } else if (from === "concept") {
                setFieldValue("concept", [conceptSelected]);
                setDataForConcept(conceptSelected);
            } else if (from === "uploadType") {
                setFieldValue("uploadType", [uploadTypeSelected]);
                setDataForUploadType(uploadTypeSelected);
            }

            closeModal();
        }
    };

    const renderFields = (setFieldValue, values) => {
        const handleChangeConfirmationResponse = () => {
            const { from, dataToChange } = modalDataToChange;
            if (from) {
                if (from === "account") {
                    setAccountSelected(dataToChange, setFieldValue);
                    setDataForAccount(dataToChange, setFieldValue);
                } else if (from === "transferType") {
                    setDataForTransferType(dataToChange);
                } else if (from === "concept") {
                    setDataForConcept(dataToChange);
                } else if (from === "uploadType") {
                    setDataForUploadType(dataToChange);
                }

                dispatch(fileActions.resetData());
                setFieldValue("fileId", null);

                if (from !== "uploadType") {
                    setUploadTypeSelected(null);
                    setFieldValue("uploadType", [""]);
                }

                dispatch(massPaymentsActions.setPaymentsRequest([]));
                closeModal();
            }
        };

        const preDataToLoad = preData || {
            currencyList: [],
            debitAccountList: [],
        };
        const selectorOptions = {
            options: preDataToLoad.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
            })),
        };

        const transferTypes = config.get("transfers.massive.types").split("|");
        const listTypes = transferTypes.map((typeCode) => ({
            label: i18nUtils.get(`forms.pay.transfers.massive.transferType.types.${typeCode}.label`),
            id: typeCode,
        }));

        const uploadTypeOptions = [
            {
                id: "file",
                label: i18nUtils.get("forms.pay.transfers.massive.uploadType.fileUpload.label"),
            },
            {
                id: "manually",
                label: i18nUtils.get("forms.pay.transfers.massive.uploadType.manualUpload.label"),
            },
        ];
        const concepts = config.get("transfers.concepts").split("|");
        const listConcepts = concepts.map((value) => ({
            label: i18nUtils.get(`forms.transfers.concepts.${value}.label`),
            id: value,
        }));

        const valueCurrency = (e) => {
            if (e !== accountSelected?.idProduct) {
                if (validateToOpenModal()) {
                    openModal("account", e);
                } else {
                    setDataForAccount(e, setFieldValue);
                }
            }
        };

        const handleTransferType = (e) => {
            if (e.id !== transferTypeSelected) {
                if (validateToOpenModal()) {
                    openModal("transferType", e.id);
                } else {
                    setDataForTransferType(e.id);
                }
            }
        };

        const handleConcept = (e) => {
            if (e.id !== conceptSelected) {
                if (validateToOpenModal()) {
                    openModal("concept", e.id);
                } else {
                    setDataForConcept(e.id);
                }
            }
        };

        const handleUploadType = (e) => {
            if (e !== uploadTypeSelected) {
                if (validateToOpenModal()) {
                    openModal("uploadType", e);
                } else {
                    setDataForUploadType(e);
                }
            }
        };

        const validateUploadType = () => {
            const accountToValidate = values?.account;
            const transferTypeToValidate = values?.transferType;
            const conceptToValidate = values?.concept;

            if (!accountToValidate?.idProduct) {
                return true;
            }

            if (!transferTypeToValidate || transferTypeToValidate[0] === "") {
                return true;
            }

            if (transferTypeToValidate[0] === "3" && (!conceptToValidate || conceptToValidate[0] === "")) {
                return true;
            }

            return false;
        };

        const isDisabled = validateUploadType();

        return (
            <>
                <Field
                    {...genericProps}
                    name="debitAccount"
                    idField="debitAccount"
                    key="debitAccount"
                    data={selectorOptions}
                    component={FormFieldsComponents.ProductselectorCustom}
                    value={accountSelected || values?.account}
                    handleChange={(e) => valueCurrency(e)}
                    labelBackoffice={data?.account?.label}
                    triggerHandlerChange={false}
                    idForm={ID_FORM}
                />

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    optionList={listTypes}
                    key="transferType"
                    name="transferType"
                    idField="transferType"
                    renderAs="combo"
                    value={transferTypeSelected ? [transferTypeSelected] : null}
                    onChange={(e) => handleTransferType(e)}
                    idForm={ID_FORM}
                />
                {showConcept && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Selector}
                        optionList={listConcepts}
                        key="concept"
                        name="concept"
                        idField="concept"
                        renderAs="combo"
                        value={conceptSelected ? [conceptSelected] : null}
                        onChange={(e) => handleConcept(e)}
                    />
                )}
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="reference"
                    name="reference"
                    idField="reference"
                    maxLength={30}
                    idForm={ID_FORM}
                />

                {mode === "edit" && (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            optionList={uploadTypeOptions}
                            key="uploadType"
                            name="uploadType"
                            idField="uploadType"
                            renderAs="radio"
                            onChange={(e) => handleUploadType(e)}
                            disabled={isDisabled}
                            idForm={ID_FORM}
                        />

                        {uploadTypeSelected && (
                            <PaymentDetail
                                detail={paymentDetails}
                                dispatch={dispatch}
                                setFieldValue={setFieldValue}
                                history={history}
                                uploadType={values?.uploadType[0]}
                                accountSelected={accountSelected}
                                isMobile={isMobile}
                            />
                        )}
                    </>
                )}

                <CustomScheduler
                    {...genericProps}
                    isDesktop={isDesktop}
                    schedulerTooltip={{
                        "data-tooltip": i18nUtils.get("forms.pay.transfers.massive.scheduler.tooltip.text"),
                        "data-tooltip-position": tooltipPosition,
                        "data-tooltip-class": "scheduler",
                    }}
                    schedulerLabelCustom="forms.pay.transfers.massive.scheduler.label"
                    scheduler={data.scheduler}
                />

                {mode === "view" && (
                    <ChangeStatusTransactionComponent transaction={transaction} genericProps={genericProps} />
                )}

                {mode !== "edit" && (
                    <>
                        {mode === "preview" && (
                            <>
                                <hr />
                                <div className="data-wrapper">
                                    <I18n
                                        id="forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail.title"
                                        component="h4"
                                        componentProps={{ className: "data-label" }}
                                    />
                                </div>
                            </>
                        )}
                        <div className="data-wrapper">
                            <I18n
                                id="forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail.currency.label"
                                componentProps={{ className: "data-label" }}
                            />

                            <I18n
                                id={`forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail.currency.${
                                    mode === "preview" ? accountSelected?.currency || data?.currency : data?.currency
                                }`}
                                componentProps={{
                                    className: "data-text",
                                }}
                            />
                        </div>
                        <div className="data-wrapper">
                            <I18n
                                id="forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail.numberOfPayments.label"
                                componentProps={{ className: "data-label" }}
                            />

                            <span className="data-text">
                                {mode === "preview"
                                    ? paymentDetails?.quantity || data?.paymentDetails.quantity
                                    : data?.paymentDetails.quantity}
                            </span>
                        </div>
                        <div className="data-wrapper">
                            <I18n
                                id="forms.pay.transfers.massive.uploadType.fileUpload.paymentDetail.totalAmount.label"
                                componentProps={{ className: "data-label" }}
                            />
                            <FormattedAmount
                                maximumDecimals="2"
                                currency={
                                    mode === "preview" ? accountSelected?.currency || data?.currency : data?.currency
                                }
                                quantity={
                                    mode === "preview"
                                        ? paymentDetails?.total || data?.paymentDetails.total
                                        : data?.paymentDetails.total
                                }
                            />
                        </div>
                    </>
                )}

                {mode === "preview" && (
                    <Button
                        block={false}
                        bsStyle="outline mt-50"
                        image="images/password-show.svg"
                        label="forms.pay.transfers.massive.seePaymentsDetail.button.label"
                        defaultLabelText="Ver detalle de pagos"
                        onClick={() => {
                            dispatch(formActions.setMode("massive-list-preview", "preview"));
                        }}
                    />
                )}

                {showConfirmationModal && (
                    <SimpleModal
                        isDisplayed={showConfirmationModal}
                        closeModal={() => handleNonAcceptanceResponse(setFieldValue)}
                        customCancellationFunction={() => handleNonAcceptanceResponse(setFieldValue)}
                        modalIcon="images/warningIcon.svg"
                        modalMainText="forms.pay.transfers.massive.modal.mainText"
                        buttonAction={() => handleChangeConfirmationResponse()}
                        allowActionAndClose={false}
                    />
                )}
            </>
        );
    };

    const renderFieldsTermsAndConditions = () => (
        <>
            <hr />
            <div className="termsAndConditions">
                <DisclaimerContainer maxHeight="115px">
                    <I18n
                        id="forms.pay.transfers.massive.termsAndConditions.title"
                        componentProps={{ className: "fw-bold" }}
                    />
                    <I18n id="forms.pay.transfers.massive.termsAndConditions.content.1" component="p" />
                    <I18n id="forms.pay.transfers.massive.termsAndConditions.content.2" component="p" />
                </DisclaimerContainer>

                <Field
                    {...genericPropsConditions}
                    component={FormFieldsComponents.Selector}
                    optionList={[
                        {
                            id: "1",
                            label: i18nUtils.get(`forms.${ID_FORM}.termsAndConditions.checkbox.label`),
                        },
                    ]}
                    key="termsAndConditionsAcceptance"
                    name="termsAndConditionsAcceptance"
                    idField="termsAndConditionsAcceptance"
                    renderAs="check"
                    additionalClassName="ui-mt-7"
                    // values={data.termsAndConditions || []}
                />
            </div>
        </>
    );

    const renderAdditionalActions = (fetching) => {
        if (idTransaction !== undefined && mode === "view") {
            return (
                <Button
                    type="button"
                    label="forms.pay.transfers.massive.seePaymentsDetail.button.label"
                    bsStyle="primary"
                    loading={fetching}
                    onClick={() => {
                        dispatch(
                            massPaymentsActions.setPaymentHeader({
                                currency: data?.account?.currency,
                            }),
                        );
                        dispatch(formActions.setMode("massive-list-view", "view"));
                    }}
                />
            );
        }

        return <></>;
    };

    const validateFieldDraft = (values) => {
        const draftErrors = {};
        const { debitAccount } = values;
        if (!debitAccount || debitAccount.length === 0) {
            draftErrors.debitAccount = i18nUtils.get("program.date.blank");
        }
        return draftErrors;
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const { ...restPreData } = preData || {};

    const formProps = {
        title: "forms.pay.transfers.massive.formName",
        metadata: {
            draftsEnabled: true,
            templatesEnabled: true,
            idActivity: ID_ACTIVITY,
            onTemplateLoad,
        },
        renderFields,
        preData: restPreData,
        renderFieldsTermsAndConditions,
        isCustom: true,
        returnBack: false,
        idActivity: ID_ACTIVITY,
        useDefaultSubmit: true,
        subtitle,
        subtitleClean: true,
        confirmLabelEmpty: true,
        validateFieldDraft,
        modalButtonLabel: "forms.saveTemplate.link",
        exportList: "pdf",
        handleClickDownload,
        mobileMenuOptions,
        showDownloadButton: paymentsToEdit?.length > 0,
        handleReportClick,
        buttonLabel: "global.continue",
        renderAdditionalActions,
    };
    return <FormTransition {...props} {...formProps} />;
};

TransferMassive.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: objectOf(any).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: objectOf(any).isRequired,
    data: objectOf(any).isRequired,
    previewData: objectOf(any),
    formErrors: oneOfType([arrayOf(any), objectOf(any)]).isRequired,
    preData: objectOf(any),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    values: objectOf(any).isRequired,
    accountSelected: string,
    isMobile: bool,
    history: objectOf(any).isRequired,
    payments: arrayOf(any),
    paymentDetails: objectOf(any),
    dataToEdit: objectOf(any),
};

TransferMassive.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
    accountSelected: null,
    isMobile: false,
    dataToEdit: {},
    previewData: null,
    payments: [],
    paymentDetails: {},
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    previewData: formSelectors.getPreviewData(state),
    formErrors: formSelectors.getErrors(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    paymentDetails: massivePaymentsSelector.getPaymentDetails(state),
    payments: massivePaymentsSelector.getPayments(state),
    dataToEdit: massivePaymentsSelector.getDataToEdit(state),
    location: state.router.location,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
    }),
    withRouter,
)(TransferMassive);
