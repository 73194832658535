import React, { useEffect } from "react";
import { selectors as echeqsSelector } from "reducers/echeqs";
import { selectors as formSelector, actions as formActions } from "reducers/form";
import { compose } from "redux";
import { func, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field } from "formik";
import { goBack } from "react-router-redux";
import types from "reducers/types/form";
import FormTransition from "../../_components/FormTransition";
import I18n from "../../../_components/I18n";
import FormattedAmount from "../../../_components/FormattedAmount";
import FormattedDate from "../../../_components/FormattedDate";
import * as FormFieldsComponents from "../../_components/_fields/Index";

const CancelGuaranteeRequest = (props) => {
    const { mode, dispatch, currentLang, fromBackoffice, transaction, data, echeq, location, history } = props;

    const echeqSelected = Object.keys(echeq).length ? echeq : data?.echeq;

    useEffect(() => {
        if (mode === "edit") {
            if ((echeqSelected && Object.keys(echeqSelected).length > 0) || Object.keys(data)?.length > 0) {
                dispatch({
                    type: types.PRE_FORM_SUCCESS,
                });
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch({
                type: types.PRE_FORM_SUCCESS,
            });
        }
    }, [dispatch, location]);

    const action = data?.action || location?.pathname.split("/")[3].replace("Guarantee", "");

    const guarantor = data?.guarantor || echeqSelected?.avalistas[0];

    const ID_ACTIVITY = `echeqs.${action}GuaranteeRequest.send`;
    const ID_FORM = "forms.echeqs.cancelGuaranteeRequest.send".replace(".send", "");

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const idTransaction = transaction?.idTransaction;

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: "forms.echeqs.cancelGuaranteeRequest.send",
    };

    const handleClickDownload = () => dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    const handleBack = () => history.goBack();
    const renderFields = () => (
        <>
            {mode === "edit" && (
                <div className="form-text-info">
                    <I18n id={`${ID_FORM}.${action}.message.text`} />
                </div>
            )}

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.echeqInfo.title`} componentProps={{ className: "data-label" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkId.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_id}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.checkNumber.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.cheque_numero}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.status.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.estado}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.amount.label`} componentProps={{ className: "data-label" }} />
                <FormattedAmount currency={echeqSelected?.emisor_moneda} quantity={echeqSelected?.monto} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.concept.label`} componentProps={{ className: "data-label" }} />
                {echeqSelected?.cheque_concepto && (
                    <I18n
                        id={`forms.transfers.concepts.${echeqSelected.cheque_concepto}.label`}
                        defaultValue={echeqSelected.cheque_concepto}
                    />
                )}
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.paymentDate.label`} componentProps={{ className: "data-label" }} />
                <FormattedDate date={echeqSelected?.fecha_pago} dateFormat="DD/MM/YYYY" />
            </div>

            <hr className={`ui-mt-7 ${mode === "edit" ? "ui-mb-3" : "ui-mb-7"}`} />

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.endorsement.title`} componentProps={{ className: "data-label" }} />
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuitHolder.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.tenencia_beneficiario_documento}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.subjectHolder.label`} componentProps={{ className: "data-label" }} />
                <span>{echeqSelected?.tenencia_beneficiario_nombre}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.guarantorCuit.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_documento}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.guarantor.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_razon_social}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.character.label`} componentProps={{ className: "data-label" }} />
                <span>{guarantor?.aval_caracter}</span>
            </div>

            {action !== "approve" && (
                <>
                    <hr className="ui-mt-7 ui-mb-7" />
                    {mode === "edit" ? (
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Textarea}
                            key="reason"
                            name="reason"
                            isRequired
                            idField="reason"
                            idActivity={`echeqs.cancelGuaranteeRequest.${action}.send`}
                            maxLength={100}
                        />
                    ) : (
                        <div className="data-wrapper">
                            <I18n
                                id={`${ID_FORM}.${action}.reason.text`}
                                componentProps={{ className: "data-label" }}
                            />
                            <span className="textarea-view">{data?.reason}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );

    const formProps = {
        title: `${ID_FORM}.${action}.${mode}.title`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        data: {
            action,
            holder: {
                name: echeqSelected?.tenencia_beneficiario_nombre,
                documentNumber: echeqSelected?.tenencia_beneficiario_documento,
                documentType: echeqSelected?.tenencia_beneficiario_documento_tipo,
            },
            guarantor,
            checkId: echeqSelected?.cheque_id,
            echeq: echeqSelected,
            amount: {
                quantity: echeqSelected?.monto,
                currency: echeqSelected?.emisor_moneda,
            },
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        buttonLabel: "global.continue",
        exportList: "pdf",
        handleClickDownload,
        handleBack,
        returnBack: true,
    };

    return <FormTransition {...props} {...formProps} />;
};

CancelGuaranteeRequest.defaultProps = {
    fromBackoffice: false,
    preData: null,
    isMobile: false,
};

CancelGuaranteeRequest.propTypes = {
    dispatch: func.isRequired,
    history: func.isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    echeq: shape({}).isRequired,
    mode: string.isRequired,
    location: shape({}).isRequired,
    isMobile: bool,
    isFetching: bool.isRequired,
};

const mapStateToProps = (state) => ({
    echeq: echeqsSelector.getSelectedEcheq(state),
    transaction: formSelector.getTransaction(state),
    data: formSelector.getData(state),
    preData: formSelector.getPreData(state),
    mode: formSelector.getMode(state),
    isFetching: echeqsSelector.getFetching(state),
    fetching: formSelector.getFetching(state),
});

export default compose(connect(mapStateToProps), withRouter)(CancelGuaranteeRequest);
