import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Field, withFormik } from "formik";
import withRouter from "react-router-dom/withRouter";
import I18n from "pages/_components/I18n";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { actions as accountActions, selectors as accountsSelectors } from "reducers/accounts";
import AccountAction from "pages/accounts/_components/AccountAction";
import * as config from "util/config";
import * as i18nUtils from "util/i18n";
import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import Button from "pages/_components/Button";
import types from "reducers/types/form";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "accounts.cbu.change";
const ID_ACTIVITY_MODIFY = "account.set.cbuAlias";
const ID_ACTIVITY_DELETE = "account.delete.cbuAlias";
const ID_ACTIVITY_PRE = "account.read.cbuAlias";

const SetCBUAlias = (props) => {
    const {
        currentLang,
        fromBackoffice,
        transaction,
        mode,
        dispatch,
        location,
        preData,
        cbuNumber,
        history,
        account,
        fetching,
        setFieldValue,
        changeCBUSendActivity,
        isFetchingExport,
        data,
        isVisible,
        isDesktop,
        newCbuAlias,
        ...rest
    } = props;
    const isFirstMount = useRef(true);
    const isTicket = location.pathname.match("/transaction/") != null;
    const idTransaction = transaction?.numberCashManagmentLocal;
    const idAccount = isTicket || fromBackoffice ? transaction.data.idAccount : rest.match.params.id;
    const noPre = preData?.cbuDetail?.cbuAlias === null;
    const { cbuDetail } = preData || {};

    useEffect(() => {
        if (cbuNumber !== null && account !== null && !(isTicket || fromBackoffice)) {
            const requestData = {
                cbuNumber,
                idAccount,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData }));
        } else if (!(isTicket || fromBackoffice)) {
            history.goBack();
        }
    }, [dispatch, location, cbuNumber, history, idAccount, account, isTicket, fromBackoffice, transaction]);

    useEffect(() => {
        if (noPre) {
            dispatch({
                type: types.PREVIEW_FORM_SUCCESS,
                credentialsGroups: [
                    {
                        idCredentialGroup: "accessToken-otp",
                        credentials: ["accessToken", "otp"],
                    },
                ],
                submitAction: formActions.sendForm,
                submitActionParams: {
                    idForm: null,
                    idActivity: ID_ACTIVITY_MODIFY,
                    idTransaction: null,
                    values: { newAlias: null },
                },
                previewData: {},
                mode: "preview",
                prevMode: null,
            });
        }
    }, [dispatch, noPre]);

    useEffect(() => {
        if (isFirstMount.current) {
            isFirstMount.current = false;
        }
        return () => {
            if (!isFirstMount.current) {
                dispatch(accountActions.setNewCbuAlias(""));
            }
        };
    }, [dispatch]);

    const genericProps = {
        mode,
        lang: currentLang,
        idTransactionTicket: idTransaction,
        fromBackoffice,
        isRequired: true,
        idActivity: changeCBUSendActivity,
    };

    const handleClick = (idActivity) => {
        dispatch({
            type: types.PREVIEW_FORM_SUCCESS,
            credentialsGroups: [
                {
                    idCredentialGroup: "accessToken-otp",
                    credentials: ["accessToken", "otp"],
                },
            ],
            submitAction: formActions.sendForm,
            submitActionParams: {
                idForm: null,
                idActivity,
                idTransaction: null,
                values: { newAlias: cbuDetail?.cbuAlias || null },
            },
            previewData: {},
            mode: "preview",
            prevMode: noPre ? null : "edit",
        });
        dispatch(accountActions.setChangeCBUSendActivity(idActivity));
    };

    const accountData =
        isTicket || fromBackoffice
            ? data?.accountData
            : `${i18nUtils.get(`productType.${account?.productType}`)} ${account?.formattedAccountNumber}`;
    const cbu = isTicket || fromBackoffice ? data?.cbuNumber : cbuDetail?.cbu || account?.cbu;
    const cuitTitular = isTicket || fromBackoffice ? data?.cuit : cbuDetail?.cuitTitular || account?.client?.idClient;
    const formattedCuit = cuitTitular
        ?.substring(0, 2)
        .concat("-")
        .concat(cuitTitular?.substring(2, 10))
        .concat("-")
        .concat(cuitTitular?.substring(10));

    const handleAliasChange = (e) => {
        dispatch(accountActions.setNewCbuAlias(e));
    };

    const handleClickDownload = () => {
        if (isTicket) {
            dispatch(
                formActions.downloadTicketCustom(props?.transaction?.idTransaction, "cbuAlias.change.download.ticket"),
            );
        } else {
            const ticketTitle = i18nUtils.get("accounts.cbu.downloadDetail.title");
            const cbuData = {
                ticketTitle,
                titular: data?.titular || cbuDetail?.titular || "",
                cuit: formattedCuit,
                accountData,
                currency: i18nUtils.get(`currency.name.${data?.currency || account.currency}`),
                cbu,
                alias: data?.newAlias || data?.alias || cbuDetail.cbuAlias || null,
            };
            dispatch(accountActions.exportCBUDetailRequest(cbuData, idAccount));
        }
    };

    const renderMenuOption = () => {
        if (!isDesktop) {
            return [
                <AccountAction
                    isDesktop={isDesktop}
                    key="downloadPdf"
                    labelKey="accounts.mobile.pdfFile"
                    handleClick={handleClickDownload}
                    className="dropdown__item-btn"
                />,
            ];
        }
        return null;
    };

    const renderFields = () => (
        <>
            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.titular.label`} componentProps={{ className: "data-label" }} />
                <span>{cbuDetail?.titular || data?.titular}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.cuit.label`} componentProps={{ className: "data-label" }} />
                <span>{formattedCuit}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.accountType.label`} componentProps={{ className: "data-label" }} />
                <span>{accountData}</span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.currency.label`} componentProps={{ className: "data-label" }} />
                <span>
                    {i18nUtils.get(`currency.name.${isTicket || fromBackoffice ? data.currency : account.currency}`)}
                </span>
            </div>

            <div className="data-wrapper">
                <I18n id={`${ID_FORM}.number.label`} componentProps={{ className: "data-label" }} />
                <span>{cbu}</span>
            </div>

            {(mode !== "preview" || changeCBUSendActivity === ID_ACTIVITY_DELETE) && (
                <div className="data-wrapper">
                    <I18n id="accounts.cbuNick.label" componentProps={{ className: "data-label" }} />
                    <span className="fw-bold">{cbuDetail?.cbuAlias || data?.newAlias}</span>
                </div>
            )}

            {!(isTicket || fromBackoffice) && mode === "edit" && <hr className="ui-mt-7 ui-mb-7" />}

            {!noPre && !(isTicket || fromBackoffice) && (
                <div className="btn-wrapper">
                    <Button
                        type="submit"
                        label={`${ID_FORM}.delete.btn.label`}
                        bsStyle={`outline ${mode === "preview" &&
                            changeCBUSendActivity === ID_ACTIVITY_DELETE &&
                            "active"}`}
                        loading={fetching}
                        defaultLabelText={`${ID_FORM}.delete.btn.label`}
                        onClick={() => handleClick(ID_ACTIVITY_DELETE)}
                    />
                    <Button
                        type="submit"
                        bsStyle={`outline ${mode === "preview" &&
                            changeCBUSendActivity === ID_ACTIVITY_MODIFY &&
                            "active"}`}
                        loading={fetching}
                        label="global.modify"
                        onClick={() => handleClick(ID_ACTIVITY_MODIFY)}
                    />
                </div>
            )}
            {mode === "preview" && (
                <hr className={`ui-mt-7 ${changeCBUSendActivity === ID_ACTIVITY_DELETE ? "ui-mb-0" : ""}`} />
            )}
        </>
    );

    const renderEditableInPreview = () => {
        if (changeCBUSendActivity === ID_ACTIVITY_MODIFY) {
            return (
                <>
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Text}
                        key="newAlias"
                        name="newAlias"
                        idField="newAlias"
                        isRequired
                        maxLength="20"
                        forceEditable
                        idActivity={`${ID_ACTIVITY_MODIFY}.send`}
                        validationRegularExpresion={config.get("cbuAlias.pattern", "^[a-zA-Z0-9 .-]*$")}
                        onChange={handleAliasChange}
                    />
                    <div className="ui-fw-sbold ui-mt-7 ui-mb-7">
                        <I18n id={`${ID_FORM}.alias.requirements.label`} />
                        <ul>
                            <li>{i18nUtils.get(`${ID_FORM}.alias.requirements.length.label`)}</li>
                            <li>{i18nUtils.get(`${ID_FORM}.alias.requirements.characters.label`)}</li>
                            <li>{i18nUtils.get(`${ID_FORM}.alias.requirements.specialCharacters.label`)}</li>
                            <li>{i18nUtils.get(`${ID_FORM}.alias.requirements.caps.label`)}</li>
                        </ul>
                    </div>
                    <I18n
                        id={`${ID_FORM}.alias.requirements.note.label`}
                        componentProps={{ className: "ui-fw-sbold" }}
                    />
                </>
            );
        }
        return null;
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 1,
        ...restPreData
    } = preData || {};

    const formProps = {
        title: isTicket || fromBackoffice ? `activities.${transaction.idActivity}` : "accounts.cbu.change.title",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: changeCBUSendActivity,
            showSubmit: false,
        },
        renderFields,
        renderEditableInPreview,
        data: {
            idAccount,
            cbuNumber: cbu,
            cuit: cuitTitular,
            newAlias: newCbuAlias || cbuDetail?.cbuAlias || "",
            alias: cbuDetail?.cbuAlias,
            currency: account?.currency,
            titular: cbuDetail?.titular,
            accountData,
        },
        preData: restPreData,
        isCustom: true,
        noPre,
        returnBack: true,
        showConfirmationMessageAboveField: true,
        idActivity: changeCBUSendActivity,
        showMessageAboveField: changeCBUSendActivity === ID_ACTIVITY_DELETE,
        customLabelMessage: changeCBUSendActivity === ID_ACTIVITY_DELETE ? `${ID_FORM}.delete.note.label` : "",
        handleCancel: () => history.goBack(),
        exportList: "pdf",
        isFetchingExport,
        handleClickDownload,
        mobileMenuOptions: renderMenuOption(),
        showDownloadButtonInForm: (noPre && mode === "preview") || mode === "edit",
        additionalFieldsToValidate: ["newAlias"],
    };
    return <FormTransition {...props} {...formProps} />;
};
SetCBUAlias.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    setFieldValue: func.isRequired,
    hasSimulatedOnce: bool.isRequired,
    previewDataDeposit: shape({}),
    history: shape({}).isRequired,
    cbuNumber: string.isRequired,
    account: shape({
        productType: string,
        formattedAccountNumber: string,
        currency: string,
    }).isRequired,
    fetching: bool.isRequired,
    changeCBUSendActivity: string.isRequired,
    isFetchingExport: bool.isRequired,
    isVisible: bool,
    newCbuAlias: string.isRequired,
};

SetCBUAlias.defaultProps = {
    fromBackoffice: false,
    preData: null,
    previewDataDeposit: null,
    isVisible: false,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    currentLang: i18nSelectors.getLang(state),
    fetching: formSelectors.getFetching(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    cbuNumber: accountsSelectors?.getSelectedAccount(state)?.cbu || null,
    previewDataDeposit: formSelectors?.getPreviewData(state) || null,
    account: accountsSelectors.getSelectedAccount(state),
    changeCBUSendActivity: accountsSelectors.getChangeCBUSendActivity(state),
    isFetchingExport: accountsSelectors.isFetchingExport(state),
    isVisible: accountsSelectors.getIsOptionsVisible(state),
    newCbuAlias: accountsSelectors.getNewCbuAlias(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            newAlias: props?.data?.newAlias || props?.preData?.cbuDetail?.cbuAlias || null,
        }),
    }),
    withRouter,
)(SetCBUAlias);
