import React, { Component } from "react";
import { bool, shape, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";

import Head from "pages/_components/Head";
import Enviroments from "pages/_components/Enviroments";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class ChangeEnvironment extends Component {
    static propTypes = {
        isSubmitting: bool,
        history: shape({
            goBack: func,
        }).isRequired,
        environments: shape([]).isRequired,
        activeEnvironment: shape([]).isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    renderHeader = () => {
        const { isMobile } = this.props;
        if (isMobile) {
            return (
                <Head
                    showLogo
                    arrowWhite
                    onBack={this.backButtonAction}
                    additionalClassName="blue-main-header-mobile"
                />
            );
        }

        return <Head onBack={this.backButtonAction} />;
    };

    render() {
        const { environments, activeEnvironment, isSubmitting } = this.props;

        return (
            <>
                {this.renderHeader()}
                <div className="view-page">
                    <div className="main-container">
                        <div className="container-fluid">
                            <div className="title-left-header">
                                <I18n id="settings.changeEnvironment" component="h1" />
                            </div>
                            <Form className="form-content">
                                <Field
                                    name="environment"
                                    environments={environments}
                                    component={Enviroments}
                                    legendTextID="settings.changeEnvironment"
                                    activeEnvironment={activeEnvironment}
                                    fromSetDefaultEnvironmentPage={false}
                                />
                                <Button
                                    block
                                    type="submit"
                                    loading={isSubmitting}
                                    label="global.select"
                                    bsStyle="primary"
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(sessionSelectors.getEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ activeEnvironment }) => ({
            environment: activeEnvironment.id,
            rememberEnvironment: false,
        }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            formikBag.props.dispatch(
                sessionActions.changeEnvironment(environment, rememberEnvironment, null, formikBag),
            );
        },
    }),
)(ChangeEnvironment);
