import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { selectors, actions as misc } from "reducers/misc";

import I18n from "pages/_components/I18n";
import { bool, func } from "prop-types";
import SimpleModal from "components/SimpleModal/SimpleModal";
import { getDisplay } from "util/device";

class ModalConsultation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isOpen: bool.isRequired,
    };

    state = {
        display: getDisplay(),
    };

    componentDidUpdate() {
        this.updateDisplay();
    }

    goUrl = () => {
        const { dispatch } = this.props;
        dispatch(misc.getInvoiceMipymeConsultationUrl());
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(misc.setModalInvoiceMipymeConsultation(false));
    };

    updateDisplay() {
        const { display } = this.state;
        const newDisplay = getDisplay();

        if (display !== newDisplay) {
            // Dispatch 'display-changed' event
            document.dispatchEvent(new Event("display-changed"));

            this.setState({
                display: newDisplay,
            });
        }
    }

    render() {
        const { isOpen } = this.props;
        return (
            <SimpleModal
                isDisplayed={isOpen}
                closeModal={this.closeModal}
                modalIcon="images/warningIcon.svg"
                buttonAction={this.goUrl}
                className="modal-consultation"
                reverse={this.state.display === "mobile"}>
                <Col className="col col-12 text-center modalConsultationGap">
                    <I18n id="misc.modal.invoiceMipymeConsultation.informationText.part1" />
                    <I18n id="misc.modal.invoiceMipymeConsultation.informationText.part2" />
                    <I18n id="misc.modal.invoiceMipymeConsultation.informationText.part3" />
                </Col>
            </SimpleModal>
        );
    }
}
const mapStateToProps = (state) => ({
    isOpen: selectors.getModalInvoiceMipymeConsultation(state),
});

export default connect(mapStateToProps)(ModalConsultation);
