// Action types
export const types = {
    CLEAN: "recoveryPassword/CLEAN",

    RECOVERY_PASS_BACK_TO_STEP_1: "recoveryPassword/BACK_TO_STEP_1",
    RECOVERY_PASS_STEP1_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP1_REQUEST",
    RECOVERY_PASS_STEP2_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP2_REQUEST",
    RECOVERY_PASS_STEP3_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP3_REQUEST",

    RECOVERY_PASS_STEP2_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP2_SUCCESS",
    RECOVERY_PASS_STEP3_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP3_SUCCESS",

    RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA: "recoveryPassword/RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA",

    RECOVERY_PASS_SUCCESS: "recoveryPassword/RECOVERY_PASS_SUCCESS",
};

// Initial state
export const INITIAL_STATE = {
    resetCode: null,
    exchangeToken: null,
    showCaptcha: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN_UP:
        case types.RECOVERY_PASS_BACK_TO_STEP_1:
            return INITIAL_STATE;
        case types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        case types.RECOVERY_PASS_STEP2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, resetCode: action.resetCode };
        case types.RECOVERY_PASS_STEP3_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    backToStep1: () => ({
        type: types.RECOVERY_PASS_BACK_TO_STEP_1,
    }),
    recoveryPassStep1: (documentType, documentNumber, secondFactor, recaptchaResponse, formikBag) => ({
        type: types.RECOVERY_PASS_STEP1_REQUEST,
        documentType,
        documentNumber,
        secondFactor,
        recaptchaResponse,
        formikBag,
    }),
    recoveryPassStep2: (code, formikBag) => ({
        type: types.RECOVERY_PASS_STEP2_REQUEST,
        code,
        formikBag,
    }),
    recoveryPassStep3: (newPassword, newPasswordConfirmation, exchangeToken, resetCode, formikBag) => ({
        type: types.RECOVERY_PASS_STEP3_REQUEST,
        newPassword,
        newPasswordConfirmation,
        exchangeToken,
        resetCode,
        formikBag,
    }),
    recoveryPassCleanUp: () => ({
        type: types.CLEAN,
    }),
};

// Selectors
export const selectors = {
    getExchangeToken: (state) => state.recoveryPassword.exchangeToken,
    getResetCode: (state) => state.recoveryPassword.resetCode,
    getShowCaptcha: (state) => state.recoveryPassword.showCaptcha,
};
