import React, { Component } from "react";

import { bool, shape, string, func } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";

class Movement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        accountCurrency: string.isRequired,
        movement: shape({}).isRequired,
        filters: shape({}).isRequired,
        role: string,
        onClick: func,
        isAccountMovement: bool.isRequired,
        hasDetails: bool.isRequired,
        renderDetails: func.isRequired,
    };

    static defaultProps = {
        role: "",
        onClick: () => {},
    };

    render() {
        const {
            accountCurrency,
            isDesktop,
            movement,
            filters,
            role,
            onClick,
            isAccountMovement,
            hasDetails,
            renderDetails,
        } = this.props;
        const { balance, voucher, reference, credit, date, debit } = movement;
        const primaryFilter =
            filters.selectedFilter === "all" ||
            filters.selectedFilter === "lastMonth" ||
            filters.selectedFilter === "secondLastMonth";
        const emptyFilter = !(
            filters.dateFrom ||
            filters.dateTo ||
            filters.maxAmount ||
            filters.minAmount ||
            filters.reference
        );
        const showBalance = primaryFilter || emptyFilter;
        const hasDebit = debit ? debit !== 0 : false;
        const hasCredit = credit ? credit !== 0 : false;

        return isDesktop ? (
            <>
                <Table.Data align="left">
                    <span>
                        <FormattedDate date={date.substring(0, 10)} />
                    </span>
                </Table.Data>

                <Table.Data align="left">
                    <span>{reference}</span>
                    {hasDetails && isAccountMovement && <span>{renderDetails(movement)}</span>}
                </Table.Data>

                <Table.Data align="left" className="hide-on-lg">
                    <span>{voucher}</span>
                </Table.Data>

                <Table.Data align="right">
                    {(hasDebit && (
                        <FormattedAmount
                            className="data-amount data-numeric data-amount-debit"
                            currency={accountCurrency}
                            quantity={debit}
                        />
                    )) || <span>-</span>}
                </Table.Data>

                <Table.Data align="right">
                    {(hasCredit && (
                        <FormattedAmount
                            className="data-amount data-numeric data-amount-credit"
                            currency={accountCurrency}
                            quantity={credit}
                        />
                    )) || <span>-</span>}
                </Table.Data>

                {showBalance && (
                    <Table.Data align="right" className="hide-on-lg">
                        <FormattedAmount currency={accountCurrency} quantity={balance} />
                    </Table.Data>
                )}

                <Table.Data align="center" className="table-data-icon" styles={{ width: movement.note && "100px" }}>
                    {movement.note && <Image src="images/note.svg" className="svg-icon" />}
                </Table.Data>

                <Table.Data align="right" className="table-data-icon">
                    <ChevromRight />
                </Table.Data>
            </>
        ) : (
            <GenericItem arrow role={role} onClick={onClick}>
                <div className="data-wrapper-group">
                    <div className="data-wrapper">
                        <div className="data-date">
                            <FormattedDate date={date.substring(0, 10)} />
                        </div>
                        <span className="data-text">{reference}</span>
                        {hasDetails && isAccountMovement && <span>{renderDetails(movement)}</span>}
                    </div>
                    <div className="data-wrapper ui-mt-0">
                        <FormattedAmount
                            className={`data-number data-numeric data-amount-${credit ? "credit" : "debit"}`}
                            currency={accountCurrency}
                            quantity={credit || debit}
                        />
                    </div>
                </div>
            </GenericItem>
        );
    }
}

export default Movement;
