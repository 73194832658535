import setCurrentPath from "util/footer";

export const types = {
    FOOTER_SET_CURRENT_PATH: "FOOTER_SET_CURRENT_PATH",
    FOOTER_RESET_CURRENT_PATH: "FOOTER_SET_CURRENT_PATH",
};

const INITIAL_STATE = {
    currentPath: "",
};

const footerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FOOTER_SET_CURRENT_PATH:
            return { ...state, currentPath: setCurrentPath(state.currentPath, action.path) };

        case types.FOOTER_RESET_CURRENT_PATH:
            return { state: INITIAL_STATE };

        default:
            return state;
    }
};

export const actions = {
    setCurrentPath: (path) => ({
        type: types.FOOTER_SET_CURRENT_PATH,
        path,
    }),
    resetCurrentPath: () => ({
        type: types.FOOTER_RESET_CURRENT_PATH,
    }),
};

export const selectors = {
    getCurrentPath: ({ footer }) => footer.currentPath,
};

export default footerReducer;
