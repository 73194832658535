import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    optionsVisible: false,
    fetchingDownload: false,
    paymentHeader: null,
    saving: false,
    paymentDetails: null,
    payments: null,
    fileUploaded: null,
    fetching: false,
    firstFetching: false,
    pageNumber: 1,
    totalPages: 0,
    hasMoreData: false,
    formErrors: {},
    previewData: {},
    dataToEdit: {
        paymentsToEdit: null,
        detailsToEdit: null,
    },
    notificationModalShowed: false,
};

export const types = {
    TOGGLE_OPTIONS: "massPayments/TOGGLE_OPTIONS",
    DOWNLOAD_LINES_REQUEST: "massPayments/DOWNLOAD_LINES_REQUEST",
    DOWNLOAD_LINES_FAILURE: "massPayments/DOWNLOAD_LINES_FAILURE",
    DOWNLOAD_LINES_SUCCESS: "massPayments/DOWNLOAD_LINES_SUCCESS",
    LIST_TRANSFER_TRANSACTIONS_REQUEST: "massPayments/LIST_TRANSFER_TRANSACTIONS_REQUEST",
    LIST_TRANSFER_TRANSACTIONS_FAILURE: "massPayments/LIST_TRANSFER_TRANSACTIONS_FAILURE",
    LIST_TRANSFER_TRANSACTIONS_SUCCESS: "massPayments/LIST_TRANSFER_TRANSACTIONS_SUCCESS",
    VALIDATE_PAYMENT_BY_TRANSFER_REQUEST: "massPayments/VALIDATE_PAYMENT_BY_TRANSFER_REQUEST",
    VALIDATE_PAYMENT_BY_TRANSFER_SUCCESS: "massPayments/VALIDATE_PAYMENT_BY_TRANSFER_SUCCESS",
    VALIDATE_PAYMENT_BY_TRANSFER_FAILURE: "massPayments/VALIDATE_PAYMENT_BY_TRANSFER_FAILURE",
    SET_PAYMENT_HEADER: "massPayments/SET_PAYMENT_HEADER",
    SET_PAYMENT_DETAILS: "massPayments/SET_PAYMENT_DETAILS",
    UPDATE_PAYMENT_DETAILS: "massPayments/UPDATE_PAYMENT_DETAILS",
    SET_PAYMENTS_REQUEST: "massPayments/SET_PAYMENTS_REQUEST",
    SET_PAYMENTS: "massPayments/SET_PAYMENTS",
    ADD_EDIT_PAYMENT_REQUEST: "massPayments/ADD_EDIT_PAYMENT_REQUEST",
    ADD_EDIT_PAYMENT_FAILURE: "massPayments/ADD_EDIT_PAYMENT_FAILURE",
    ADD_EDIT_PAYMENT_SUCCESS: "massPayments/ADD_EDIT_PAYMENT_SUCCESS",
    RESET_DATA: "massPayments/RESET_DATA",
    REMOVE_PAYMENT: "massPayments/REMOVE_PAYMENT",
    SET_FILE_UPLOADED: "massPayments/SET_FILE_UPLOADED",
    ADD_PAYMENT: "massPayments/ADD_PAYMENT",
    EDIT_PAYMENT: "massPayments/EDIT_PAYMENT",
    CLEAR_ERRORS: "massPayments/CLEAR_ERRORS",
    SUMMARY_PAYMENT_REQUEST: "massPayments/SUMMARY_PAYMENT_REQUEST",
    SUMMARY_PAYMENT_FAILURE: "massPayments/SUMMARY_PAYMENT_FAILURE",
    SUMMARY_PAYMENT_SUCCESS: "massPayments/SUMMARY_PAYMENT_SUCCESS",
    DOWNLOAD_FILE_EXAMPLE_REQUEST: "massPayments/DOWNLOAD_FILE_EXAMPLE_REQUEST",
    DOWNLOAD_FILE_EXAMPLE_FAILURE: "massPayments/DOWNLOAD_FILE_EXAMPLE_FAILURE",
    DOWNLOAD_FILE_EXAMPLE_SUCCESS: "massPayments/DOWNLOAD_FILE_EXAMPLE_SUCCESS",
    DOWNLOAD_PAYMENTS_CVS_FILE: "massPayments/DOWNLOAD_PAYMENTS_CVS_FILE",
    LOAD_PAYMENTS_TO_EDIT: "massPayments/LOAD_PAYMENTS_TO_EDIT",
    SET_PAYMENTS_FROM_EDIT: "massPayments/SET_PAYMENTS_FROM_EDIT",
    DOWNLOAD_TICKET_DETAILS_REQUEST: "massPayments/DOWNLOAD_TICKET_DETAILS_REQUEST",
    DOWNLOAD_TICKET_DETAILS_FAILURE: "massPayments/DOWNLOAD_TICKET_DETAILS_FAILURE",
    DOWNLOAD_TICKET_DETAILS_SUCCESS: "massPayments/DOWNLOAD_TICKET_DETAILS_SUCCESS",
    SET_NOTIFICATION_MODAL_SHOWED: "massPayments/SET_NOTIFICATION_MODAL_SHOWED",
};

export default createReducer(INITIAL_STATE, {
    [types.TOGGLE_OPTIONS]: (state) => ({
        ...state,
        optionsVisible: !state.optionsVisible,
    }),
    [types.LIST_TRANSFER_TRANSACTIONS_REQUEST]: (state, actions) => ({
        ...state,
        fetching: true,
        firstFetching: actions.isFirstFetching,
    }),
    [types.LIST_TRANSFER_TRANSACTIONS_SUCCESS]: (state, actions) => {
        const { paymentsToEdit } = state.dataToEdit;

        const newPaymentList = state?.hasMoreData
            ? paymentsToEdit.concat(actions.response.payments)
            : actions.response.payments;

        return {
            ...state,
            fetching: false,
            firstFetching: false,
            dataToEdit: {
                ...state.dataToEdit,
                paymentsToEdit: newPaymentList,
            },
            pageNumber: actions.response.pageNumber,
            totalPages: actions.response.totalPages,
            hasMoreData: actions.response.pageNumber < actions.response.totalPages,
        };
    },
    [types.LIST_TRANSFER_TRANSACTIONS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        firstFetching: false,
    }),
    [types.VALIDATE_PAYMENT_BY_TRANSFER_SUCCESS]: (state) => ({
        ...state,
        transactions: state.transactions,
    }),
    [types.SET_PAYMENT_HEADER]: (state, actions) => ({
        ...state,
        paymentHeader: Object.assign(state?.paymentHeader ?? {}, actions.data),
    }),
    [types.SET_PAYMENT_DETAILS]: (state, actions) => ({
        ...state,
        paymentDetails: actions.detail,
    }),
    [types.UPDATE_PAYMENT_DETAILS]: (state, action) => {
        const total = state?.payments.reduce((acc, currentValue) => acc + Number(currentValue?.amount?.quantity), 0);
        const quantity = state?.payments.length;

        return {
            ...state,
            paymentDetails: { ...state?.paymentDetails, total, quantity, ...action?.additionalData },
        };
    },
    [types.ADD_EDIT_PAYMENT_REQUEST]: (state) => ({
        ...state,
        saving: true,
    }),
    [types.ADD_PAYMENT]: (state, actions) => {
        const { paymentsToEdit } = state.dataToEdit;
        const newPaymentList = paymentsToEdit ? [actions.payment, ...paymentsToEdit] : [actions.payment];
        const total = newPaymentList.reduce((acc, currentValue) => acc + Number(currentValue?.amount?.quantity), 0);
        const quantity = newPaymentList.length;
        const newDataToEdit = {
            paymentsToEdit: newPaymentList,
            detailsToEdit: { total, quantity },
        };

        return {
            ...state,
            saving: false,
            dataToEdit: newDataToEdit,
        };
    },
    [types.ADD_EDIT_PAYMENT_FAILURE]: (state, actions) => ({
        ...state,
        saving: false,
        formErrors: actions.errors,
    }),
    [types.RESET_DATA]: (state) => ({
        ...state,
        paymentHeader: INITIAL_STATE.paymentHeader,
        payments: INITIAL_STATE.payments,
        paymentDetails: INITIAL_STATE.paymentDetails,
        saving: INITIAL_STATE.saving,
        fetching: INITIAL_STATE.fetching,
        pageNumber: INITIAL_STATE.pageNumber,
        totalPages: INITIAL_STATE.totalPage,
        hasMoreData: INITIAL_STATE.hasMoreData,
        dataToEdit: INITIAL_STATE.dataToEdit,
    }),
    [types.REMOVE_PAYMENT]: (state, action) => {
        const newPaymentList = state.dataToEdit.paymentsToEdit.filter((payment) => payment?.id !== action?.id);
        const total = newPaymentList.reduce((acc, currentValue) => acc + Number(currentValue?.amount?.quantity), 0);
        const quantity = newPaymentList.length;
        const newDataToEdit = {
            paymentsToEdit: newPaymentList,
            detailsToEdit: { total, quantity },
        };

        return {
            ...state,
            dataToEdit: newDataToEdit,
        };
    },

    [types.SET_FILE_UPLOADED]: (state, action) => ({
        ...state,
        fileUploaded: action.file,
    }),
    [types.SET_PAYMENTS]: (state, action) => ({
        ...state,
        payments: action.payments,
    }),
    [types.EDIT_PAYMENT]: (state, action) => {
        const { paymentsToEdit } = state.dataToEdit;
        const index = paymentsToEdit.findIndex((payment) => payment.id === action?.paymentId);
        const newPaymentList = paymentsToEdit;
        newPaymentList[index] = action?.payment;

        const total = newPaymentList.reduce((acc, currentValue) => acc + Number(currentValue?.amount?.quantity), 0);
        const quantity = newPaymentList.length;
        const newDataToEdit = {
            paymentsToEdit: newPaymentList,
            detailsToEdit: { total, quantity },
        };

        return {
            ...state,
            saving: false,
            dataToEdit: newDataToEdit,
        };
    },
    [types.CLEAR_ERRORS]: (state) => ({
        ...state,
        formErrors: INITIAL_STATE.formErrors,
    }),
    [types.SUMMARY_PAYMENT_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SUMMARY_PAYMENT_SUCCESS]: (state, actions) => ({
        ...state,
        fetching: false,
        dataToEdit: {
            ...state.dataToEdit,
            detailsToEdit: actions?.summary,
        },
    }),
    [types.SUMMARY_PAYMENT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_PAYMENTS_TO_EDIT]: (state) => ({
        ...state,
        dataToEdit: {
            paymentsToEdit: state.payments,
            detailsToEdit: {
                total: state?.paymentDetails?.total,
                quantity: state?.paymentDetails?.quantity,
            },
        },
    }),
    [types.SET_PAYMENTS_FROM_EDIT]: (state) => {
        const { paymentsToEdit, detailsToEdit } = state.dataToEdit;

        return {
            ...state,
            payments: paymentsToEdit,
            paymentDetails: detailsToEdit,
            dataToEdit: INITIAL_STATE.dataToEdit,
        };
    },
    [types.DOWNLOAD_TICKET_DETAILS_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_TICKET_DETAILS_FAILURE]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_TICKET_DETAILS_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.SET_NOTIFICATION_MODAL_SHOWED]: (state, action) => ({
        ...state,
        notificationModalShowed: action.notificationModalShowed,
    }),
});

export const actions = {
    toggleOptions: makeActionCreator(types.TOGGLE_OPTIONS),
    downloadLinesRequest: makeActionCreator(types.DOWNLOAD_LINES_REQUEST, "idTransaction", "format", "filters"),
    getListOfTransferPaymentsRequest: makeActionCreator(
        types.LIST_TRANSFER_TRANSACTIONS_REQUEST,
        "transactionId",
        "pageNumber",
        "isFirstFetching",
    ),
    getPaymentSummary: makeActionCreator(types.SUMMARY_PAYMENT_REQUEST, "transactionId"),
    getPaymentSummarySuccess: makeActionCreator(types.SUMMARY_PAYMENT_SUCCESS, "summary"),
    getPaymentSummaryFailure: makeActionCreator(types.SUMMARY_PAYMENT_FAILURE),
    getListOfTransferPaymentsSuccess: makeActionCreator(types.LIST_TRANSFER_TRANSACTIONS_SUCCESS, "response"),
    getListOfTransferPaymentsFailure: makeActionCreator(types.LIST_TRANSFER_TRANSACTIONS_FAILURE),
    setPaymentHeader: makeActionCreator(types.SET_PAYMENT_HEADER, "data"),
    setPaymentDetails: makeActionCreator(types.SET_PAYMENT_DETAILS, "detail"),
    addOrEditPayment: makeActionCreator(types.ADD_EDIT_PAYMENT_REQUEST, "payment", "paymentId", "formikBag"),
    addOrEditPaymentFailure: makeActionCreator(types.ADD_EDIT_PAYMENT_FAILURE, "errors"),
    resetData: makeActionCreator(types.RESET_DATA),
    deletePayment: makeActionCreator(types.REMOVE_PAYMENT, "id"),
    setFile: makeActionCreator(types.SET_FILE_UPLOADED, "file"),
    setPaymentsRequest: makeActionCreator(types.SET_PAYMENTS_REQUEST, "payments", "additionalData"),
    setPaymentsSuccess: makeActionCreator(types.SET_PAYMENTS, "payments"),
    updatePaymentDetails: makeActionCreator(types.UPDATE_PAYMENT_DETAILS, "additionalData"),
    addPayment: makeActionCreator(types.ADD_PAYMENT, "payment"),
    editPayment: makeActionCreator(types.EDIT_PAYMENT, "payment", "paymentId"),
    clearErrors: makeActionCreator(types.CLEAR_ERRORS),
    downloadPaymentSampleFile: makeActionCreator(types.DOWNLOAD_FILE_EXAMPLE_REQUEST),
    downloadPaymentsCsvFile: makeActionCreator(
        types.DOWNLOAD_PAYMENTS_CVS_FILE,
        "transactionId",
        "transactionStatusLabel",
        "paymentDetails",
        "paymentsToDownload",
    ),
    loadPaymentsToEdit: makeActionCreator(types.LOAD_PAYMENTS_TO_EDIT),
    setPaymentsFromEdit: makeActionCreator(types.SET_PAYMENTS_FROM_EDIT),
    downloadTicketDetails: makeActionCreator(
        types.DOWNLOAD_TICKET_DETAILS_REQUEST,
        "idTransaction",
        "downloadType",
        "payments",
    ),
    setNotificationModalShowed: makeActionCreator(types.SET_NOTIFICATION_MODAL_SHOWED, "notificationModalShowed"),
};

export const selectors = {
    getProcessedFileData: ({ formFields }) => formFields.multilineFile,
    getPaymentHeader: ({ massPayments }) => massPayments?.paymentHeader,
    isSaving: ({ massPayments }) => massPayments.saving,
    getPayments: ({ massPayments }) => massPayments.payments,
    getPaymentDetails: ({ massPayments }) => massPayments.paymentDetails,
    getFormErrors: ({ massPayments }) => massPayments.formErrors,
    getDataToEdit: ({ massPayments }) => massPayments.dataToEdit,
    getFetchingDownloadFile: ({ massPayments }) => massPayments.fetchingDownload,
    getPageNumber: ({ massPayments }) => massPayments.pageNumber,
    getIsFirstFetching: ({ massPayments }) => massPayments.firstFetching,
    getNotificationModalShowed: ({ massPayments }) => massPayments.notificationModalShowed,
};
