import { string } from "prop-types";
import React from "react";

import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";

const getProfileText = (profileName, usedFor, component) => {
    let name = "";
    switch (profileName) {
        case "conservative":
            name = i18nUtils.get("investments.mutualFund.riskTypes.options.1").toLowerCase();
            break;
        case "moderate":
            name = i18nUtils.get("investments.mutualFund.riskTypes.options.2").toLocaleUpperCase();
            break;

        case "aggressive":
            name = i18nUtils.get("investments.mutualFund.riskTypes.options.3").toLocaleUpperCase();
            break;
        default:
            name = "";
            break;
    }

    if (usedFor === "forProfile") {
        return (
            <I18n
                id="investments.mutualFund.investerProfile.profileType.profile"
                PROFILE={name}
                component={component}
                componentProps={{ className: "profileTitle" }}
            />
        );
    }

    if (usedFor === "forMessage") {
        return (
            <I18n
                id="investments.mutualFund.investerProfile.profileType.message"
                PROFILE={name}
                component={component}
                componentProps={{ className: "profileTitle" }}
            />
        );
    }

    if (usedFor === "forTicket") {
        return (
            <I18n
                id="investments.mutualFund.investerProfile.profileType.ticket"
                PROFILE={name}
                component={component}
                componentProps={{ className: "profileTitle" }}
            />
        );
    }

    return profileName;
};

const InvestorProfileName = ({ profileName, usedFor, component }) => getProfileText(profileName, usedFor, component);

InvestorProfileName.propTypes = {
    profileName: string,
    usedFor: string,
    component: string,
};

InvestorProfileName.defaultProps = {
    profileName: "",
    usedFor: "",
    component: "span",
};

export default InvestorProfileName;
