import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    FREQUENT_RECIPIENTS_REQUEST: "transfers/FREQUENT_RECIPIENTS_REQUEST",
    FREQUENT_RECIPIENTS_RECEIVED: "transfers/FREQUENT_RECIPIENTS_RECEIVED",
    FREQUENT_RECIPIENTS_FAILURE: "transfers/FREQUENT_RECIPIENTS_FAILURE",
    MORE_RECIPIENTS_REQUEST: "transfers/MORE_RECIPIENTS_REQUEST",
    MORE_RECIPIENTS_RECEIVED: "transfers/MORE_RECIPIENTS_RECEIVED",
    FREQUENT_RECIPIENTS_RESET: "transfers/FREQUENT_RECIPIENTS_RESET",
    CREATE_PREVIEW_FREQUENT_RECIPIENT_REQUEST: "transfers/CREATE_PREVIEW_FREQUENT_RECIPIENT_REQUEST",
    CREATE_PREVIEW_FREQUENT_RECIPIENT_RECEIVED: "transfers/CREATE_PREVIEW_FREQUENT_RECIPIENT_RECEIVED",
    CREATE_PREVIEW_FREQUENT_RECIPIENT_FAILURE: "transfers/CREATE_PREVIEW_FREQUENT_RECIPIENT_FAILURE",
    CREATE_FREQUENT_RECIPIENTS_REQUEST: "transfers/CREATE_FREQUENT_RECIPIENTS_REQUEST",
    CREATE_FREQUENT_RECIPIENTS_RECEIVED: "transfers/CREATE_FREQUENT_RECIPIENTS_RECEIVED",
    CREATE_FREQUENT_RECIPIENTS_FAILURE: "transfers/CREATE_FREQUENT_RECIPIENTS_FAILURE",
    CREATE_FREQUENT_RECIPIENT_FOR_MASSIVE: "transfers/CREATE_FREQUENT_RECIPIENT_FOR_MASSIVE",
    MODIFY_FREQUENT_RECIPIENTS_REQUEST: "transfers/MODIFY_FREQUENT_RECIPIENTS_REQUEST",
    MODIFY_FREQUENT_RECIPIENTS_RECEIVED: "transfers/MODIFY_FREQUENT_RECIPIENTS_RECEIVED",
    MODIFY_FREQUENT_RECIPIENTS_FAILURE: "transfers/MODIFY_FREQUENT_RECIPIENTS_FAILURE",
    DELETE_FREQUENT_RECIPIENTS_REQUEST: "transfers/DELETE_FREQUENT_RECIPIENTS_REQUEST",
    DELETE_FREQUENT_RECIPIENTS_RECEIVED: "transfers/DELETE_FREQUENT_RECIPIENTS_RECEIVED",
    DELETE_FREQUENT_RECIPIENTS_FAILURE: "transfers/DELETE_FREQUENT_RECIPIENTS_FAILURE",
    SELECT_FREQUENT_RECIPIENT: "transfers/SELECT_FREQUENT_RECIPIENT",
    SEARCH_FREQUENT_RECIPIENTS_REQUEST: "transfers/SEARCH_FREQUENT_RECIPIENTS_REQUEST",
    SEARCH_FREQUENT_RECIPIENTS_RECEIVED: "transfers/SEARCH_FREQUENT_RECIPIENTS_RECEIVED",
    SEARCH_FREQUENT_RECIPIENTS_FAILURE: "transfers/SEARCH_FREQUENT_RECIPIENTS_FAILURE",
    SHOW_BENEFICIARY_MODAL: "transfers/SHOW_BENEFICIARY_MODAL",
};

const INITIAL_STATE = {
    fetching: false,
    fetchingMore: false,
    isFirstFetching: true,
    recipientsList: [],
    recipientsListForMassive: [],
    nextPage: 1,
    moreData: false,
    recipientSelected: {},
    countries: [],
    documentTypes: [],
    recipientFound: {},
    showAddBeneficiaryModal: false,
};

export default createReducer(INITIAL_STATE, {
    [types.FREQUENT_RECIPIENTS_REQUEST]: (state, action) => ({
        ...state,
        fetching: true,
        fetchingMore: state.nextPage >= 2,
        isFirstFetching: action.isFirstFetching === true,
    }),
    [types.FREQUENT_RECIPIENTS_RECEIVED]: (state, action) => ({
        ...state,
        fetching: false,
        isFirstFetching: false,
        recipientsList:
            state.nextPage >= 2
                ? state.recipientsList.concat(action.frequentDestinations)
                : action.frequentDestinations,
        nextPage: action?.nextPage != null ? action.nextPage : state.nextPage,
        moreData: action.moreStatements,
    }),
    [types.FREQUENT_RECIPIENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        isFirstFetching: false,
    }),
    [types.FREQUENT_RECIPIENTS_RESET]: (state) => ({
        ...state,
        fetching: INITIAL_STATE.fetching,
        fetchingMore: INITIAL_STATE.fetchingMore,
        isFirstFetching: INITIAL_STATE.isFirstFetching,
        recipientsList: INITIAL_STATE.recipientsList,
        nextPage: INITIAL_STATE.nextPage,
        moreData: INITIAL_STATE.moreData,
    }),
    [types.CREATE_FREQUENT_RECIPIENTS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CREATE_FREQUENT_RECIPIENT_FOR_MASSIVE]: (state, action) => ({
        ...state,
        fetching: false,
        recipientsListForMassive: [...state.recipientsListForMassive, action.data],
    }),
    [types.CREATE_FREQUENT_RECIPIENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CREATE_PREVIEW_FREQUENT_RECIPIENT_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CREATE_PREVIEW_FREQUENT_RECIPIENT_RECEIVED]: (state, action) => ({
        ...state,
        fetching: false,
        countries: action.countries,
        documentTypes: action.documentTypes,
    }),
    [types.CREATE_PREVIEW_FREQUENT_RECIPIENT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.MODIFY_FREQUENT_RECIPIENTS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.MODIFY_FREQUENT_RECIPIENTS_RECEIVED]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.MODIFY_FREQUENT_RECIPIENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DELETE_FREQUENT_RECIPIENTS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.DELETE_FREQUENT_RECIPIENTS_RECEIVED]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DELETE_FREQUENT_RECIPIENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SELECT_FREQUENT_RECIPIENT]: (state, action) => ({
        ...state,
        recipientSelected: action.recipient,
    }),
    [types.SEARCH_FREQUENT_RECIPIENTS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEARCH_FREQUENT_RECIPIENTS_RECEIVED]: (state, action) => ({
        ...state,
        fetching: false,
        recipientFound: action.frequentDestination,
    }),
    [types.SEARCH_FREQUENT_RECIPIENTS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        recipientFound: INITIAL_STATE.recipientFound,
    }),
    [types.SHOW_BENEFICIARY_MODAL]: (state, action) => ({
        ...state,
        fetching: false,
        showAddBeneficiaryModal: action.showAddBeneficiaryModal,
    }),
});

export const actions = {
    loadFrequentRecipients: makeActionCreator(
        types.FREQUENT_RECIPIENTS_REQUEST,
        "pageNumber",
        "nameSearch",
        "isFirstFetching",
    ),
    loadFrequentRecipientsSuccess: makeActionCreator(
        types.FREQUENT_RECIPIENTS_RECEIVED,
        "frequentDestinations",
        "moreStatements",
        "nextPage",
    ),
    resetFrequentRecipients: makeActionCreator(types.FREQUENT_RECIPIENTS_RESET),
    createPreview: makeActionCreator(types.CREATE_PREVIEW_FREQUENT_RECIPIENT_REQUEST),
    createPreviewFailed: makeActionCreator(types.CREATE_PREVIEW_FREQUENT_RECIPIENT_FAILURE),
    createPreviewSuccess: makeActionCreator(
        types.CREATE_PREVIEW_FREQUENT_RECIPIENT_RECEIVED,
        "countries",
        "documentTypes",
    ),
    createRecipientRequest: makeActionCreator(
        types.CREATE_FREQUENT_RECIPIENTS_REQUEST,
        "data",
        "credentials",
        "formikBag",
        "openInModal",
    ),
    createRecipientSuccess: makeActionCreator(types.CREATE_FREQUENT_RECIPIENTS_RECEIVED),
    createRecipientFailure: makeActionCreator(types.CREATE_FREQUENT_RECIPIENTS_FAILURE),
    createRecipientForMassive: makeActionCreator(types.CREATE_FREQUENT_RECIPIENT_FOR_MASSIVE, "data"),
    updateRecipientRequest: makeActionCreator(
        types.MODIFY_FREQUENT_RECIPIENTS_REQUEST,
        "frequentDestination",
        "formikBag",
    ),
    updateRecipientSuccess: makeActionCreator(types.MODIFY_FREQUENT_RECIPIENTS_RECEIVED, "data"),
    upeRecipientFailure: makeActionCreator(types.MODIFY_FREQUENT_RECIPIENTS_FAILURE),
    deleteRecipientRequest: makeActionCreator(types.DELETE_FREQUENT_RECIPIENTS_REQUEST, "idFrequentDestination"),
    deleteRecipientSuccess: makeActionCreator(types.DELETE_FREQUENT_RECIPIENTS_RECEIVED, "data"),
    deleteRecipientFailure: makeActionCreator(types.DELETE_FREQUENT_RECIPIENTS_FAILURE),
    selectRecipient: makeActionCreator(types.SELECT_FREQUENT_RECIPIENT, "recipient"),
    searchRecipientRequest: makeActionCreator(
        types.SEARCH_FREQUENT_RECIPIENTS_REQUEST,
        "destinationType",
        "destinationIdentifier",
        "cuit",
        "formikBag",
        "openInModal",
    ),
    searchRecipientSuccess: makeActionCreator(types.SEARCH_FREQUENT_RECIPIENTS_RECEIVED, "frequentDestination"),
    searchRecipientFailure: makeActionCreator(types.SEARCH_FREQUENT_RECIPIENTS_FAILURE),
    setShowBeneficiaryModal: makeActionCreator(types.SHOW_BENEFICIARY_MODAL, "showAddBeneficiaryModal"),
};

export const selectors = {
    getRecipientFound: ({ frequentRecipients }) => frequentRecipients.recipientFound,
    getRecipientList: ({ frequentRecipients }) => frequentRecipients.recipientsList,
    getRecipientListForMassive: ({ frequentRecipients }) => frequentRecipients.recipientsListForMassive,
    getFetching: ({ frequentRecipients }) => frequentRecipients.fetching,
    getShowAddBeneficiaryModal: ({ frequentRecipients }) => frequentRecipients.showAddBeneficiaryModal,
};
