import React from "react";
import { Field } from "formik";
import { func, shape, bool, string, array, number } from "prop-types";
import moment from "moment";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import defaultDateFromList from "util/defaultDateFromList";
import * as i18nUtils from "../../../../../util/i18n";
import * as FormFieldsComponents from "../../../_components/_fields/Index";
import EmitEcheqCommonFields from "./EmitEcheqCommonFields";
import MultipleEcheqsGrid from "./MultipleEcheqsGrid";

const EmitMultipleEcheqs = (echeqFormProps) => {
    const {
        data,
        ID_FORM,
        amountData,
        handleAmount,
        handleEcheqType,
        handleOtherFields,
        otherFieldsSelected,
        genericProps,
        amountSelected,
        echeqTypeSelected,
        echeqsQuantityOptions,
        echeqsQuantitySelected,
        amountTypeSelected,
        conceptsList,
        mode,
        totalAmountSelected,
        idForm,
        isMobile,
        disableComponents,
    } = echeqFormProps;

    return (
        <>
            <EmitEcheqCommonFields {...echeqFormProps} />

            <div className="form-group--double-field">
                <Field
                    {...genericProps}
                    idActivity="echeqs.emitEcheq.multiple.send"
                    component={FormFieldsComponents.Selector}
                    key="echeqsQuantity"
                    name="echeqsQuantity"
                    idField="echeqsQuantity"
                    renderAs="combo"
                    optionList={echeqsQuantityOptions}
                    value={data?.echeqsQuantity || [echeqsQuantitySelected]}
                    onChange={(e) => handleAmount(e.id, "echeqsQuantity")}
                    disabled={disableComponents}
                />
                {mode === "edit" ? (
                    <div className="form-group--mixed-field">
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Selector}
                            key="amountType"
                            name="amountType"
                            idField="amountType"
                            renderAs="combo"
                            optionList={[
                                {
                                    id: "total",
                                    label: i18nUtils.get(`${ID_FORM}.multiple.amount.total`),
                                },
                                {
                                    id: "individual",
                                    label: i18nUtils.get(`${ID_FORM}.multiple.amount.individual`),
                                },
                            ]}
                            value={data?.amountType || [amountTypeSelected]}
                            onChange={(e) => handleAmount(e.id, "amountType")}
                        />
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Amount}
                            key="amount"
                            name="amount"
                            idField="amount"
                            value={data?.amount || { currency: "ARS", quantity: amountSelected }}
                            data={amountData}
                            handleChange={(e) => handleAmount(e, "amount")}
                        />
                    </div>
                ) : (
                    <>
                        <MultipleEcheqsGrid emissionType="multiple" idForm={idForm} isDesktop={!isMobile} />
                        <div className="data-wrapper">
                            <I18n id={`${ID_FORM}.totalAmount.label`} componentProps={{ className: "data-label" }} />
                            <FormattedAmount
                                currency={totalAmountSelected.currency || data?.totalAmount?.currency}
                                quantity={totalAmountSelected.quantity || data?.totalAmount?.quantity}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className="form-group--double-field">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="echeqType"
                    name="echeqType"
                    idField="echeqType"
                    renderAs="combo"
                    onChange={(e) => handleEcheqType(e.id)}
                    optionList={[
                        {
                            id: "today",
                            label: i18nUtils.get(`${ID_FORM}.echeqType.today.label`),
                        },
                        {
                            id: "deferred",
                            label: i18nUtils.get(`${ID_FORM}.echeqType.deferred.label`),
                        },
                    ]}
                    value={data?.echeqType || [echeqTypeSelected]}
                    disabled={disableComponents}
                />
                {mode === "edit" && (
                    <Field
                        {...genericProps}
                        component={FormFieldsComponents.Date}
                        key="paymentDate"
                        name="paymentDate"
                        idField="paymentDate"
                        disabled={echeqTypeSelected === "today"}
                        editing={false}
                        value={otherFieldsSelected?.paymentDate}
                        onChange={(e) => handleOtherFields("paymentDate", e)}
                        minDate={echeqTypeSelected === "today" ? moment() : moment().add(1, "days")}
                        maxDate={defaultDateFromList().echeqs.maxDaysToEmmit}
                    />
                )}
            </div>

            <div className="form-group--double-field">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="character"
                    name="character"
                    idField="character"
                    renderAs="combo"
                    optionList={[
                        {
                            id: "transferable",
                            label: i18nUtils.get(`${ID_FORM}.character.transferable.label`),
                        },
                        {
                            id: "nonTransferable",
                            label: i18nUtils.get(`${ID_FORM}.character.nonTransferable.label`),
                        },
                    ]}
                    onChange={(e) => handleOtherFields("character", e.id)}
                    value={data?.character || [otherFieldsSelected?.character]}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Selector}
                    key="mode"
                    name="mode"
                    idField="mode"
                    renderAs="combo"
                    optionList={[
                        {
                            id: "crossed",
                            label: i18nUtils.get(`${ID_FORM}.mode.crossed.label`),
                        },
                    ]}
                    value={data?.mode || ["crossed"]}
                />
            </div>

            <Field
                {...genericProps}
                component={FormFieldsComponents.Selector}
                key="concept"
                name="concept"
                idField="concept"
                renderAs="combo"
                optionList={conceptsList}
                onChange={(e) => handleOtherFields("concept", e.id)}
                value={data?.concept || [otherFieldsSelected?.concept]}
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reason"
                name="reason"
                idField="reason"
                onChange={(e) => handleOtherFields("reason", e)}
                value={data?.reason || otherFieldsSelected.reason}
                maxLength={150}
                additionalClassName="reason-input"
            />

            <Field
                {...genericProps}
                component={FormFieldsComponents.Text}
                key="reference"
                name="reference"
                idField="reference"
                value={data?.reference || otherFieldsSelected.reference}
                onChange={(e) => handleOtherFields("reference", e)}
                maxLength={20}
            />
        </>
    );
};
EmitMultipleEcheqs.propTypes = {
    echeqFormProps: {
        mode: string,
        authorizedAccountsOptions: array.isRequired,
        data: shape({}).isRequired,
        ID_FORM: string,
        searchSuccessAction: func.isRequired,
        bankedCustomerInfo: shape({}).isRequired,
        handleEmissionType: func.isRequired,
        amountData: shape({}).isRequired,
        setAmountSelected: func.isRequired,
        handleEcheqType: func.isRequired,
        handleOtherFields: func.isRequired,
        otherFieldsSelected: shape({}).isRequired,
        genericProps: shape({}).isRequired,
        handleChangeAccount: func.isRequired,
        isMobile: bool.isRequired,
        amountSelected: number.isRequired,
        echeqTypeSelected: array.isRequired,
        echeqsQuantityOptions: array.isRequired,
    }.isRequired,
    disableComponents: bool.isRequired,
};

export default EmitMultipleEcheqs;
