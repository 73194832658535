import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { compose } from "redux";
import { replace } from "react-router-redux";
import PropTypes from "prop-types";

import * as i18n from "util/i18n";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Credential from "pages/_components/fields/credentials/Credential";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import Button from "pages/_components/Button";

const FORM_ID = "settings.changeSecuritySeal";
class ChangeSecuritySealConfirmation extends Component {
    static propTypes = {
        selectedSecuritySeal: PropTypes.shape({
            id: PropTypes.string.isRequired,
            securitySeal: PropTypes.string.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        fetching: PropTypes.bool.isRequired,
    };

    state = {
        currentImage: "",
    };

    componentDidMount() {
        const { selectedSecuritySeal, dispatch } = this.props;
        if (!selectedSecuritySeal || !selectedSecuritySeal.id) {
            dispatch(replace("/settings/"));
        }
        if (selectedSecuritySeal) {
            this.setState({
                currentImage: selectedSecuritySeal.securitySeal,
            });
        }
    }

    back = (event) => {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealConfirmationBack());
    };

    render() {
        const { selectedSecuritySeal, isMobile, history, fetching } = this.props;

        const { currentImage } = this.state;

        return (
            <Fragment>
                <>
                    {!isMobile ? (
                        <Head
                            propsClassNames="title-size"
                            headerClassName={!isMobile && "mt-2"}
                            onBack={history.goBack}
                        />
                    ) : (
                        <Head
                            onBack={history.goBack}
                            showLogo
                            arrowWhite
                            additionalClassName="blue-main-header-mobile"
                        />
                    )}
                </>
                <MainContainer>
                    <div className="container-center">
                        <div className="title-left-header">
                            <I18n id="settings.changeSecuritySeal.confirmation" component="h1" />
                        </div>

                        <Form className="form-content">
                            <div className="changeSecuritySeal-container">
                                <div className="security-image">
                                    <div className="image selected">
                                        <figure>
                                            <img
                                                src={selectedSecuritySeal.securitySeal || currentImage}
                                                alt={selectedSecuritySeal.id}
                                            />
                                        </figure>
                                    </div>
                                </div>
                                <div>
                                    <I18n id="settings.changeSecuritySeal.confirmation.title" component="p" />

                                    <Button
                                        block={isMobile}
                                        label="settings.changeSecuritySeal.confirmation.back"
                                        bsStyle="outline"
                                        className="btn-outline ui-mt-7"
                                        onClick={this.back}
                                        type="button"
                                    />
                                </div>
                            </div>

                            <div className="token-form ui-mt-8">
                                <Field name="otp" type="otp" idForm={FORM_ID} component={Credential} />
                            </div>

                            <Button
                                block
                                label="global.confirm"
                                type="submit"
                                bsStyle="primary"
                                onClick={this.send}
                                loading={fetching}
                            />
                        </Form>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: settingsSelectors.getErrors(state),
    selectedSecuritySeal: {
        id: settingsSelectors.getSelectedSecuritySealId(state),
        securitySeal: settingsSelectors.getSelectedSecuritySeal(state),
    },
    fetching: settingsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            otp: "",
            selectedSecuritySeal: props.selectedSecuritySeal,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ otp, selectedSecuritySeal }, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changeSecuritySealConfirmation(selectedSecuritySeal.id, otp, formikBag),
            );
        },
    }),
)(ChangeSecuritySealConfirmation);
