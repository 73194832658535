import React, { Component } from "react";
import { func, string, bool, shape, array } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelectors } from "reducers/desktop";
import { selectors } from "reducers/widgets";

import WidgetLoading from "pages/_components/WidgetLoading";
import NoProduct from "pages/desktop/widgets/_components/NoProduct";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";

class WidgetList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        children: func.isRequired,
        item: func,
        keyExtractor: func,
        name: string.isRequired,
        shouldMapList: bool,
        list: array.isRequired,
        isFetching: bool,
        isEditable: bool,
        activeEnvironment: shape({}),
        withDisclaimer: bool,
        handleOnClick: func,
    };

    static defaultProps = {
        shouldMapList: true,
        keyExtractor: () => {},
        item: null,
        isFetching: false,
        isEditable: false,
        activeEnvironment: {},
        withDisclaimer: false,
        handleOnClick: null,
    };

    renderItem = (item) => {
        const { item: Item, keyExtractor, handleOnClick } = this.props;
        if (handleOnClick) {
            return <Item onClick={() => handleOnClick(item)} key={keyExtractor(item)} {...item} />;
        }
        return <Item key={keyExtractor(item)} {...item} />;
    };

    renderList = () => {
        const { list, isFetching, name, shouldMapList, activeEnvironment, isEditable, withDisclaimer } = this.props;

        if (!(list.length || isFetching)) {
            let buttonData;
            let button;

            const isSubFormAvailable = (formName) =>
                !!activeEnvironment.forms[name.toLowerCase()].find(({ idForm }) => idForm === formName);

            if (activeEnvironment.forms[name.toLowerCase()]) {
                if (isSubFormAvailable("accountOpening")) {
                    buttonData = {
                        text: "accounts.new",
                        pushPath: "/formCustom/accountOpening",
                    };
                } else if (isSubFormAvailable("requestLoan")) {
                    buttonData = {
                        text: "loan.new",
                        pushPath: "/form/requestLoan/",
                    };
                }
            }

            if (buttonData) {
                const handlerOnClick = () => {
                    const { dispatch } = this.props;
                    dispatch(push(buttonData.pushPath));
                };

                button = (
                    <Button
                        className="btn btn-primary btn-small"
                        block={false}
                        onClick={handlerOnClick}
                        label={buttonData.text}
                    />
                );
            }

            return (
                <div className="table table--products" aria-hidden={isEditable}>
                    <NoProduct
                        imagePath="images/coloredIcons/component.svg"
                        text={`desktop.widgets.${name}.empty`}
                        button={button}
                    />
                </div>
            );
        }

        if (!shouldMapList) {
            return list;
        }

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                <div className="table table--products" aria-hidden={isEditable}>
                    <div
                        aria-label={i18nUtils.get(`widgets.list.title.${name}.a11y`)}
                        role="menu"
                        className="table-body">
                        {list.map(this.renderItem)}
                        {!!list.length && withDisclaimer && <SeuoLabel />}
                    </div>
                </div>
            </WidgetLoading>
        );
    };

    render() {
        const { children } = this.props;

        return children(this.renderList());
    }
}

const mapStateToProps = (state, { name }) => {
    const { isFetching, data } = selectors.getWidget(state, name);

    return {
        isFetching,
        list: data[name],
        isEditable: desktopSelectors.getIsEditale(state),
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    };
};

export default connect(mapStateToProps)(WidgetList);
