import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, string, bool, element } from "prop-types";
import CampaignItem from "pages/campaigns/CampaignItem";

import { selectors, actions } from "reducers/campaigns";

import WidgetLoading from "pages/_components/WidgetLoading";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        container: element,
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        container: null,
    };

    componentDidMount() {
        const { dispatch, section } = this.props;
        dispatch(actions.loadCampaign(section));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.removeCampaigns());
    }

    render() {
        const { items, container, dispatch, isFetching, section, isDesktop } = this.props;

        if (items.length === 0 || isFetching) {
            return <WidgetLoading loading={isFetching} />;
        }

        const list = items.map((item) => (
            <CampaignItem {...item} section={section} dispatch={dispatch} isDesktop={isDesktop} />
        ));

        const campaignsOut = container ? (
            <container.element className={container.className}>{list}</container.element>
        ) : (
            <Fragment>{list}</Fragment>
        );

        return campaignsOut;
    }
}

const mapStateToProps = (state) => ({
    items: selectors.getCampaigns(state),
    isFetching: selectors.getFetching(state),
});

export default connect(mapStateToProps)(Campaigns);
