import React, { Component } from "react";
import { number, string, arrayOf, shape, func, bool } from "prop-types";

import FileUploader from "pages/_components/FileUploader";
import FileActions from "pages/forms/_components/_fields/_commons/FileActions";
import formField from "pages/forms/_components/_fields/_commons/formField";

class Inputfile extends Component {
    static propTypes = {
        value: arrayOf(
            shape({
                fileId: number.isRequired,
                fileSize: number.isRequired,
                fileName: string.isRequired,
                fileType: string.isRequired,
            }),
        ),
        setValue: func.isRequired,
        fromBackoffice: bool.isRequired,
        editing: bool.isRequired,
        idForm: string.isRequired,
        idField: string.isRequired,
        formTitle: string.isRequired,
        label: string.isRequired,
        allowMultiple: bool.isRequired,
        maxFileSizeMB: number.isRequired,
        maxFiles: number.isRequired,
        maxTotalFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf(string).isRequired,
    };

    static defaultProps = {
        value: null,
    };

    render() {
        const {
            idForm,
            idField,
            value,
            formTitle,
            label,
            allowMultiple,
            maxFileSizeMB,
            maxTotalFileSizeMB,
            maxFiles,
            acceptedFileTypes,
        } = this.props;

        return (
            <FileActions
                {...this.props}
                render={({ onRemoveFile, onAddFile }) => (
                    <FileUploader
                        name={idField}
                        idActivity="core.forms.send"
                        idForm={idForm}
                        idFormField={idField}
                        onRemoveFile={onRemoveFile}
                        onAddFile={onAddFile}
                        description={`${formTitle} - ${label}`}
                        files={value}
                        allowMultiple={allowMultiple}
                        maxFileSize={`${maxFileSizeMB}mb`}
                        maxTotalFileSize={`${maxTotalFileSizeMB}mb`}
                        maxFiles={maxFiles}
                        acceptedFileTypes={acceptedFileTypes}
                        allowImagePreview
                    />
                )}
            />
        );
    }
}

export default formField()(Inputfile);
