import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, func, number } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import StepPasswordExpired from "pages/login/_components/StepPasswordExpired";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { push } from "react-router-redux/actions";
import HeaderLogin from "../_components/header/HeaderLogin";

class LoginPasswordExpired extends Component {
    componentDidMount() {
        const { dispatch, activeEnvironment, username } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
        } else if (!username) {
            dispatch(push("/"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.goBackToLogin());
        dispatch(push("/"));
    };

    render() {
        return (
            <>
                <Notification scopeToShow="loginPasswordExpired" />
                <div className="login-content">
                    <HeaderLogin onHeaderClose={this.onHeaderClose} showBack />
                    <MainContainer className="main-container">
                        <StepPasswordExpired />
                    </MainContainer>
                </div>
            </>
        );
    }
}

LoginPasswordExpired.propTypes = {
    dispatch: func.isRequired,
    username: string.isRequired,
    activeEnvironment: number,
};

LoginPasswordExpired.defaultProps = {
    activeEnvironment: null,
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
    isFetchingLang: i18nSelectors.getFetching(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginPasswordExpired));
