import React, { Component } from "react";
import { string, number, bool } from "prop-types";

import { history } from "store";

import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import GenericItem from "components/GenericItem/GenericItem";
import Table from "pages/_components/Table";

class AccountsListItem extends Component {
    static propTypes = {
        currency: string.isRequired,
        formattedAccountNumber: string,
        productAlias: string,
        idProduct: string,
        productType: string.isRequired,
        amountLabel: string.isRequired,
        balance: number.isRequired,
        isMobile: bool,
    };

    static defaultProps = {
        productAlias: null,
        formattedAccountNumber: "",
        idProduct: null,
        isMobile: false,
    };

    render() {
        const {
            currency,
            formattedAccountNumber,
            productAlias,
            balance,
            productType,
            amountLabel = i18nUtils.get("accounts.availableBalance"),
            idProduct,
            isMobile,
        } = this.props;

        return (
            <>
                {!isMobile ? (
                    <Table.Row
                        aria-label={productAlias}
                        aria-describedby={idProduct}
                        onClick={() => history.push(`accounts/${idProduct}`)}
                        role="button">
                        <Table.Data align="left" styles={{ width: "87%" }}>
                            <span className="data-medium-size ui-mb-3">{productAlias}</span>
                            <span>{i18nUtils.get(`productType.${productType}`)}</span>
                            <span className="data-bold">{formattedAccountNumber}</span>
                        </Table.Data>
                        <Table.Data align="left" className="v-align-bottom">
                            <span>{amountLabel}</span>
                            <FormattedAmount
                                quantity={balance}
                                currency={currency}
                                className="data-amount ui-fw-bold ui-text-left"
                            />
                        </Table.Data>
                    </Table.Row>
                ) : (
                    <GenericItem onClick={() => history.push(`accounts/${idProduct}`)} role="button">
                        {productAlias && (
                            <div className="data-wrapper">
                                <div className="data-medium-size ui-mb-3">{productAlias}</div>
                            </div>
                        )}

                        <div className="data-wrapper">
                            <div className="data-text">{i18nUtils.get(`productType.${productType}`)}</div>
                            <div className="data-bold">{formattedAccountNumber}</div>
                        </div>

                        <div className="data-wrapper">
                            <div className="data-text">{amountLabel}</div>
                            <FormattedAmount quantity={balance} currency={currency} />
                        </div>
                    </GenericItem>
                )}
            </>
        );
    }
}

export default AccountsListItem;
