import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, bool, node, oneOfType, arrayOf, element } from "prop-types";

import * as i18n from "util/i18n";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";

class ErrorBoundary extends React.Component {
    static propTypes = {
        hasActiveSession: bool,
        dispatch: func.isRequired,
        children: oneOfType([node, arrayOf(element)]).isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
    };

    componentDidCatch(error, info) {
        const { hasActiveSession, dispatch } = this.props;
        // eslint-disable-next-line no-console
        console.group("Component did catch");
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line no-console
        console.warn(info);
        // eslint-disable-next-line no-console
        console.groupEnd("Component did catch");

        dispatch(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        dispatch(hasActiveSession ? push("/desktop") : push("/"));
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(ErrorBoundary);
