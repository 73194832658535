import React from "react";
import Checkbox from "pages/forms/_components/_fields/_commons/Checkbox";
import * as utilI18n from "util/i18n";
import { string, shape, func, bool } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import withFocus from "pages/_components/withFocus";
import formField from "./_commons/formField";

const CheckBoxSelect = ({ labelCheck, value, setValue, onChangeParent, disabled }) => {
    const handleChange = () => {
        const newValue = value === "0" ? "1" : "0";
        setValue(newValue);
        onChangeParent(newValue);
    };

    const renderEditMode = () => (
        <Checkbox
            label={utilI18n.get(labelCheck)}
            checked={value === "1"}
            onChange={handleChange}
            disabled={disabled}
        />
    );

    return <>{renderEditMode()}</>;
};
CheckBoxSelect.propTypes = {
    labelCheck: string,
    value: shape({}),
    setValue: func,
    onChangeParent: func,
    disabled: bool,
};
CheckBoxSelect.defaultProps = {
    labelCheck: "",
    value: {},
    setValue: () => {},
    onChangeParent: null,
    disabled: false,
};
const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    configs: configSelectors.getConfig(state),
});
export default compose(
    connect(mapStateToProps),
    withFocus,
    formField({
        formClass: "form-group--composite",
        isEmptyValue: (value) => !value,
        renderLegend: true,
    }),
)(CheckBoxSelect);
