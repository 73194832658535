import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, string, func } from "prop-types";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as checksActions, selectors as checksSelectors } from "reducers/checks";
import { selectors as filterSelectors } from "reducers/filters";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import moment from "moment";
import * as config from "util/config";
import Yup from "yup";
import * as i18n from "util/i18n";
import defaultDateFromList from "util/defaultDateFromList";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { Field, Form, withFormik } from "formik";
import Date from "pages/_components/fields/DateField";
import Button from "pages/_components/Button";
import { clearFiltersAndSubmit } from "util/form";

class Filters extends Component {
    static propTypes = {
        fetching: bool,
        idForm: string.isRequired,
        isMobile: bool,
        setFieldValue: func.isRequired,
        setFieldError: func.isRequired,
        setValues: func.isRequired,
        currentLang: string.isRequired,
        submitForm: func.isRequired,
    };

    static defaultProps = {
        fetching: false,
        isMobile: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    handleClearFilters = () => {
        const { setValues, submitForm } = this.props;

        clearFiltersAndSubmit(
            () =>
                setValues({
                    operationNumber: "",
                    checkFrom: "",
                    state: 0,
                    dateFrom: defaultDateFromList().checksDeferred.dateFrom,
                    dateTo: defaultDateFromList().checksDeferred.dateTo,
                }),
            submitForm,
        );
    };

    getStateCheck = () => {
        const states = config.get("checks.deferred.state.types").split("|");
        const valuesFinal = states.map((valuesState) => ({
            id: valuesState,
            label: i18n.get(`check.deferred.list.typeStateCheck.${valuesState}.label`),
        }));
        return valuesFinal;
    };

    render() {
        const { fetching, idForm, isMobile, setFieldValue, setFieldError, currentLang } = this.props;

        const maxDaysBack = config.get("checks.deferred.maxDaysBack", 365);
        const maxDaysForward = config.get("checks.deferred.maxDaysForward", 365);

        return (
            <div ref={this.myRef} className="filters-container">
                <Form className="form-content">
                    <Field
                        mode="edit"
                        component={FormFieldsComponents.Text}
                        idField="operationNumber"
                        name="operationNumber"
                        idActivity={`${idForm}.send`}
                        idForm={idForm}
                        maxLength={7}
                        isRequired
                        value=""
                        validationRegularExpresion="^[0-9]*$"
                        lang={currentLang}
                    />

                    <Field
                        mode="edit"
                        component={FormFieldsComponents.Text}
                        idField="checkFrom"
                        name="checkFrom"
                        idActivity={`${idForm}.send`}
                        idForm={idForm}
                        maxLength={10}
                        isRequired
                        value=""
                        validationRegularExpresion="^[0-9]*$"
                        lang={currentLang}
                    />

                    <Field
                        component={FormFieldsComponents.Selector}
                        optionList={this.getStateCheck()}
                        key="state"
                        name="state"
                        idField="state"
                        renderAs="combo"
                        idActivity={`${idForm}.send`}
                        idForm={idForm}
                        mode="edit"
                        isRequired
                        lang={currentLang}
                    />

                    <Field
                        idField="dateFrom"
                        component={Date}
                        hidePlaceholder
                        idForm={idForm}
                        isClearable
                        name="dateFrom"
                        selectsStart
                        showMonthYearDropdown
                        minDate={moment().add(-maxDaysBack, "days")}
                        maxDate={moment().add(maxDaysForward, "days")}
                        onChange={(e) => {
                            setFieldValue("dateFrom", e);
                            setFieldError("dateFrom", null);
                        }}
                    />

                    <Field
                        idField="dateTo"
                        component={Date}
                        hidePlaceholder
                        idForm={idForm}
                        isClearable
                        name="dateTo"
                        selectsEnd
                        showMonthYearDropdown
                        minDate={moment().add(-maxDaysBack, "days")}
                        maxDate={moment().add(maxDaysForward, "days")}
                        onChange={(e) => {
                            setFieldValue("dateTo", e);
                            setFieldError("dateTo", null);
                        }}
                    />

                    <Button
                        block={isMobile}
                        bsStyle="primary"
                        label="product.filters.filter"
                        loading={fetching}
                        type="submit"
                    />

                    <Button
                        block={isMobile}
                        bsStyle="outline"
                        className="btn-clear-filters"
                        label="product.filters.clearFilters"
                        image="images/ui-icons/ui-clear-filters.svg"
                        loading={fetching}
                        onClick={this.handleClearFilters}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    currentLang: i18nSelectors.getLang(state),
    filters: checksSelectors.getFilters(state),
    isResetQuery: filterSelectors.isResetFilters(state),
    fetching: checksSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            dateFrom: props.filters.dateFrom ? props.filters.dateFrom : null,
            dateTo: props.filters.dateTo ? props.filters.dateTo : null,
            state: props.filters.state ? props.filters.state : "",
            checkFrom: props.filters.checkFrom ? props.filters.checkFrom : "",
            operationNumber: props.filters.operationNumber ? props.filters.operationNumber : "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get("check.deferred.list.dateFrom.error"))
                              .required(i18n.get("check.deferred.list.dateFrom.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("check.deferred.list.dateFrom.empty.error")),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get("check.deferred.list.dateTo.error"))
                              .required(i18n.get("check.deferred.list.dateTo.empty.error"))
                        : Yup.date()
                              .nullable()
                              .required(i18n.get("check.deferred.list.dateTo.empty.error")),
                }),
            ),
        handleSubmit: ({ ...values }, formikBag) => {
            const { filters, dispatch } = formikBag.props;
            const { checkTypeDeferred } = filters;
            const state = values.state[0];
            const newFilters = { ...values, checkTypeDeferred, state };
            dispatch(checksActions.checksDeferredListRequest(newFilters, formikBag));
        },
    }),
)(Filters);
