import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as templateSelectors } from "reducers/template";
import { selectors as miscSelectors } from "reducers/misc";

import { compose } from "redux";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, shape, func, string } from "prop-types";
import I18n from "pages/_components/I18n";
import FormTransition from "../_components/FormTransition";

export const ID_ACTIVITY = "request.changePersonalData.send";
const ID_ACTIVITY_PRE = "request.changePersonalData.pre";

const addClass = (e) => {
    if (e.target.id === "mobilePhone") {
        const focusElement = e.target;
        const focusElementSibling = focusElement.nextElementSibling;

        focusElement.classList.add("has-focus");
        focusElementSibling.classList.add("has-focus");
    }
};

const removeClass = (e) => {
    if (e.target.id === "mobilePhone") {
        const focusElement = e.target;
        const focusElementSibling = focusElement.nextElementSibling;

        focusElement.classList.remove("has-focus");
        focusElementSibling.classList.remove("has-focus");
    }
};

const RequestChangePersonalData = (props) => {
    const { mode, dispatch, location, transaction, userTransactionDetail, data, preData } = props;

    const [dialCore, setDialCore] = useState();
    const [telArea, setTelArea] = useState();
    const [phoneValue, setPhoneValue] = useState();
    const [email, setEmail] = useState();
    const [mobilePhone, setMobilePhone] = useState();

    useEffect(() => {
        document.addEventListener("focusin", addClass);

        document.addEventListener("focusout", removeClass);

        return () => {
            document.removeEventListener("focusin", addClass);
            document.removeEventListener("focusOut", removeClass);
        };
    }, []);

    useEffect(() => {
        if (mode === "edit") {
            const formData = {
                amount: null,
                debitAccount: null,
                creditAccount: null,
                creditReference: null,
            };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, location]); // eslint-disable-line

    useEffect(() => {
        setEmail(data?.email || preData?.email);
        setMobilePhone(data?.mobilePhone || preData?.mobilePhone);
    }, preData);

    const handleEmailInput = (dataInput) => {
        setEmail(dataInput);
    };

    const handlePhoneInput = (phoneData) => {
        setDialCore(phoneData.dialCode);
        setTelArea(phoneData.areaCode);
        setPhoneValue(phoneData.phoneValue);
        setMobilePhone(phoneData.fullName);
    };

    const isEditing = mode === "edit";
    const idTransaction = userTransactionDetail?.idTransaction || transaction?.idTransaction || null;

    const renderFields = () => {
        const { currentLang, fromBackoffice } = props;
        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        return (
            <>
                {mode !== "view" && (
                    <div className="form-text-info ui-mb-8">
                        {mode === "edit" && (
                            <I18n id="forms.request.changePersonalData.informationText" component="p" />
                        )}
                        {mode === "preview" && (
                            <I18n id="form.confirmation.request.changePersonalData.send.title" component="p" />
                        )}
                    </div>
                )}

                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Text}
                    key="email"
                    name="email"
                    idField="email"
                    onChange={handleEmailInput}
                />
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.PhoneInput}
                    key="mobilePhone"
                    name="mobilePhone"
                    idField="mobilePhone"
                    additionalClassName={mode === "preview" ? "ui-mb-3" : ""}
                    onChangeCallback={handlePhoneInput}
                />
            </>
        );
    };

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = preData || {};

    const formProps = {
        title: `forms.request.changePersonalData.${mode}.title`,
        buttonLabel: "global.continue",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        data: {
            email: isEditing ? email : data?.email,
            mobilePhone: isEditing ? mobilePhone : data?.mobilePhone,
            telCountry: isEditing ? dialCore : data?.dialCore,
            telArea: isEditing ? telArea : data?.telArea,
            phone: isEditing ? phoneValue : data?.phoneValue,
        },
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        withoutTransaction: true,
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};
RequestChangePersonalData.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    userTransactionDetail: shape({}).isRequired,
};

RequestChangePersonalData.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    userTransactionDetail: formSelectors.getUserTransactionDetail(state),
    documentTypes: miscSelectors.getDocumentTypes(state),
});

export default compose(connect(mapStateToProps), withRouter)(RequestChangePersonalData);
