import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, func, bool, string } from "prop-types";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { selectors as loansSelectors, actions as loansActions } from "reducers/loans";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

import "../../../styles/components/modules/_details.scss";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";

class StatementDetail extends Component {
    static propTypes = {
        loan: shape({}).isRequired,
        statement: shape({}).isRequired,
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        history: shape({ goBack: func }),
        feeType: string.isRequired,
    };

    static defaultProps = {
        history: null,
    };

    componentDidMount() {
        const { statement, dispatch, loan, history, feeType, ...rest } = this.props;
        const { id, code, branch, order } = rest.match.params;
        const { feeNumber, operationCode, operationBranch } = statement || {};
        if (!loan || statement?.feeNumber === null) {
            history.goBack();
        } else if (feeNumber !== parseInt(id, 10) || operationCode !== code || operationBranch !== branch) {
            dispatch(loansActions.readStatement(code, branch, order, id, feeType));
        }
    }

    renderHeader = () => {
        const { isMobile, history } = this.props;
        if (isMobile) {
            return <Head showLogo additionalClassName="blue-main-header-mobile" onBack={history.goBack} arrowWhite />;
        }
        return <Head onBack={history.goBack} showPlusIcon />;
    };

    renderTaxItemsList = (taxList, label) => {
        if (taxList && taxList.length > 0) {
            const taxItems = [];
            taxList.forEach((taxItem) => {
                if (
                    (taxItem.description !== "" && taxItem.description !== null) ||
                    (taxItem.currency !== "" && taxItem.currency !== null) ||
                    (taxItem.amount !== 0 && taxItem.amount !== "0")
                ) {
                    taxItems.push(
                        <li className="data-wrapper">
                            {taxItem.description !== "" && <span className="data-label">{taxItem.description}</span>}
                            {taxItem.amount !== 0 && taxItem.amount !== "0" && (
                                <FormattedAmount currency={taxItem.currency} quantity={taxItem.amount} />
                            )}
                            {taxItem.commission !== 0 && (
                                <Fragment>
                                    <span className="data-label">
                                        <I18n id="loan.detail.fees.detail.commission.label" />
                                    </span>
                                    <span className="data-desc">{`${taxItem.commission} %`}</span>
                                </Fragment>
                            )}
                        </li>,
                    );
                }
            });
            if (taxItems?.length > 0) {
                return (
                    <>
                        {label === "taxFields" && (
                            <li className="data-wrapper">
                                <I18n
                                    id={`loan.detail.fees.detail.${label}.label`}
                                    component="h5"
                                    componentProps={{ className: "data-label" }}
                                />
                            </li>
                        )}
                        {taxItems}
                    </>
                );
            }
        }
        return null;
    };

    renderPaymentSeries = (paymentSeries) => {
        const {
            loan: { codeSIOC },
        } = this.props;
        return (
            <>
                <li className="data-wrapper">
                    <I18n
                        id="loan.detail.fees.detail.cancellationDetail.label"
                        component="h5"
                        componentProps={{ className: "data-label" }}
                    />
                </li>
                {paymentSeries.map((paymentItem) => (
                    <>
                        {paymentItem.cancellationDate && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.cancellationDate.label" />
                                </span>
                                <span className="data-date">
                                    <FormattedDate date={paymentItem.cancellationDate} />
                                </span>
                            </li>
                        )}
                        {paymentItem.arrears > 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.arrear.label" />
                                </span>
                                <span className="data-desc">{`${paymentItem.arrears} ${i18n.get(
                                    "loan.detail.fees.days.label",
                                )}`}</span>
                            </li>
                        )}
                        {paymentItem.amount > 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.amount.label" />
                                </span>
                                <FormattedAmount currency={paymentItem.currencyAmount} quantity={paymentItem.amount} />
                            </li>
                        )}
                        {paymentItem.capitalAdjustment > 0 && codeSIOC === "OPREV1" && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.capitalAdjustment.label" />
                                </span>
                                <FormattedAmount
                                    currency={
                                        paymentItem.currencyCapitalAdjustment === "$"
                                            ? "ARS"
                                            : paymentItem.currencyCapitalAdjustment
                                    }
                                    quantity={paymentItem.capitalAdjustment}
                                />
                            </li>
                        )}
                        {paymentItem.interest !== 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.interests.label" />
                                </span>
                                <FormattedAmount
                                    currency={paymentItem.currencyInterest}
                                    quantity={paymentItem.interest}
                                />
                            </li>
                        )}
                        {paymentItem.compensatoryInterest !== 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.compensatoryInterest.label" />
                                </span>
                                <FormattedAmount
                                    currency={paymentItem.currencyCompensatoryInterest}
                                    quantity={paymentItem.compensatoryInterest}
                                />
                            </li>
                        )}
                        {paymentItem.punitiveInterest !== 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.punitiveInterests.label" />
                                </span>
                                <FormattedAmount
                                    currency={paymentItem.currencyPunitiveInterest}
                                    quantity={paymentItem.punitiveInterest}
                                />
                            </li>
                        )}
                    </>
                ))}
                {paymentSeries.map(
                    (paymentItem) =>
                        paymentItem.taxField.length > 0 && this.renderTaxItemsList(paymentItem.taxField, "taxFields"),
                )}
                {paymentSeries.map(
                    (paymentItem) =>
                        paymentItem.totalPayment !== 0 && (
                            <li className="data-wrapper">
                                <span className="data-label">
                                    <I18n id="loan.detail.fees.detail.totalToPay.label" />
                                </span>
                                <FormattedAmount
                                    currency={paymentItem.totalPaymentCurrency}
                                    quantity={paymentItem.totalPayment}
                                />
                            </li>
                        ),
                )}
            </>
        );
    };

    renderDisclaimers = () => (
        <>
            <SeuoLabel />
            <DisclaimerContainer disclaimer="loan.detail.fees.detail.disclaimer.label" textSmall className="ui-mt-7" />
        </>
    );

    render() {
        const { loan, statement, fetching } = this.props;
        const {
            status,
            beneficiary,
            dueDate,
            cancellationDate,
            negotiationDate,
            feeNumber,
            arrears,
            commissionCurrency,
            currencyIntAvn,
            currencyIntAlqVNPaidPesos,
            quoteMarketValue,
            currencyCapitalUVA,
            valueUVA,
            currencyCapitalAdjustment,
            capitalAdjustment,
            operationCurrency,
            currencyValueOperation,
            currencyFeeOperation,
            currencyCompensatoryInterestOperation,
            currencyPunitiveInterest,
            totalPaymentCurrency,
            commission,
            commissionRate,
            residualValue,
            intAvn,
            intAlqVNPaidPesos,
            amountUVA,
            amount,
            tax,
            compensatoryInterest,
            punitiveInterest,
            totalPayment,
            taxSet,
            taxField,
            paymentSeries,
        } = statement || {};
        const { currency, speciesDescription, codeSIOC } = loan || {};

        return (
            statement && (
                <Fragment>
                    <Notification scopeToShow="loanStatementDetail" />

                    {this.renderHeader()}

                    <MainContainer showLoader={fetching}>
                        <div className="container-center">
                            <div className="title-left-header">
                                <I18n id="loan.detail.fee.detail.title" component="h1" />
                            </div>

                            <div className="details-container">
                                <div className="details__primary-content">
                                    <ul className="data-list inline">
                                        {dueDate && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.dateExpiration.label" />
                                                </span>
                                                <span className="data-date">
                                                    <FormattedDate date={dueDate} />
                                                </span>
                                            </li>
                                        )}
                                        {operationCurrency && codeSIOC !== "PRE990" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.currency.label" />
                                                </span>
                                                <span>
                                                    <span className="data-desc">
                                                        {i18n.get(`loan.detail.currency.${currency}`)}
                                                    </span>
                                                </span>
                                            </li>
                                        )}
                                        {loan.totalFees > 1 && feeNumber && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.nroFee.label" />
                                                </span>
                                                <span className="data-desc">{feeNumber}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="details__default-content">
                                    <ul className="data-list inline">
                                        {status && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.stateFee.label" />
                                                </span>
                                                <span>
                                                    <span className="data-desc">{status}</span>
                                                </span>
                                            </li>
                                        )}
                                        {negotiationDate && (codeSIOC === "GOCPDA" || codeSIOC === "GOCPDAE") && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.tradingDate.label" />
                                                </span>
                                                <span className="data-date">
                                                    <FormattedDate date={negotiationDate} />
                                                </span>
                                            </li>
                                        )}
                                        {beneficiary &&
                                            (codeSIOC === "GOVS" || codeSIOC === "GOAVBA" || codeSIOC === "GOAVON") && (
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loan.detail.fees.detail.beneficiary.label" />
                                                    </span>
                                                    <span>
                                                        <span className="data-desc">{beneficiary}</span>
                                                    </span>
                                                </li>
                                            )}
                                        {speciesDescription && codeSIOC === "PRE990" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.species.label" />
                                                </span>
                                                <span className="data-desc">{speciesDescription}</span>
                                            </li>
                                        )}
                                        {amountUVA !== 0 && codeSIOC === "OPREV1" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.nroFee.label" />
                                                </span>
                                                <span className="data-desc">{amountUVA}</span>
                                            </li>
                                        )}
                                        {residualValue !== 0 && codeSIOC === "PRE990" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.residualValue.label" />
                                                </span>
                                                <span className="data-desc">{residualValue}</span>
                                            </li>
                                        )}
                                        {(codeSIOC === "GOCPDA" || codeSIOC === "GOCPDAE") && (
                                            <>
                                                {commissionRate !== 0 && (
                                                    <li className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id="loan.detail.fees.detail.commission.label" />
                                                        </span>
                                                        <span className="data-desc">{`${commissionRate} %`}</span>
                                                    </li>
                                                )}
                                                {commission !== 0 && (
                                                    <li className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id="loan.detail.fees.detail.totalCommission.label" />
                                                        </span>
                                                        <FormattedAmount
                                                            currency={commissionCurrency}
                                                            quantity={commission}
                                                        />
                                                    </li>
                                                )}
                                                {this.renderTaxItemsList(taxSet, "taxSet")}
                                            </>
                                        )}
                                        {codeSIOC === "PRE990" && (
                                            <>
                                                {quoteMarketValue !== "0" && (
                                                    <li className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id="loan.detail.fees.detail.quote.label" />
                                                        </span>
                                                        <span className="data-desc">{quoteMarketValue}</span>
                                                    </li>
                                                )}
                                                {intAvn !== 0 && (
                                                    <li className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id="loan.detail.fees.detail.intVN.label" />
                                                        </span>
                                                        <FormattedAmount currency={currencyIntAvn} quantity={intAvn} />
                                                    </li>
                                                )}
                                                {intAlqVNPaidPesos !== 0 && (
                                                    <li className="data-wrapper">
                                                        <span className="data-label">
                                                            <I18n id="loan.detail.fees.detail.intVN.label" />
                                                        </span>
                                                        <FormattedAmount
                                                            currency={currencyIntAlqVNPaidPesos}
                                                            quantity={intAlqVNPaidPesos}
                                                        />
                                                    </li>
                                                )}
                                            </>
                                        )}

                                        {valueUVA && valueUVA !== 0 ? (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.valueUVA.label" />
                                                </span>
                                                <FormattedAmount
                                                    currency={currencyCapitalUVA || "ARS"}
                                                    quantity={valueUVA}
                                                />
                                            </li>
                                        ) : (
                                            ""
                                        )}

                                        {cancellationDate && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.cancellationDate.label" />
                                                </span>
                                                <span className="data-date">
                                                    <FormattedDate date={cancellationDate} />
                                                </span>
                                            </li>
                                        )}

                                        {arrears !== 0 && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.arrear.label" />
                                                </span>
                                                <span className="data-desc">{`${arrears} ${i18n.get(
                                                    "loan.detail.fees.days.label",
                                                )}`}</span>
                                            </li>
                                        )}
                                        {currency !== "UVA" && amount !== 0 && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.amount.label" />
                                                </span>
                                                <FormattedAmount currency={currencyValueOperation} quantity={amount} />
                                            </li>
                                        )}
                                        {capitalAdjustment !== "0" && codeSIOC === "OPREV1" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.capitalAdjustment.label" />
                                                </span>
                                                <FormattedAmount
                                                    currency={currencyCapitalAdjustment}
                                                    quantity={parseFloat(capitalAdjustment)}
                                                />
                                            </li>
                                        )}
                                        {tax !== 0 && codeSIOC !== "PRE990" && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.interests.label" />
                                                </span>
                                                <FormattedAmount currency={currencyFeeOperation} quantity={tax} />
                                            </li>
                                        )}
                                        {compensatoryInterest !== 0 && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.compensatoryInterest.label" />
                                                </span>
                                                <FormattedAmount
                                                    currency={currencyCompensatoryInterestOperation}
                                                    quantity={compensatoryInterest}
                                                />
                                            </li>
                                        )}
                                        {punitiveInterest !== 0 && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="loan.detail.fees.detail.punitiveInterests.label" />
                                                </span>
                                                <FormattedAmount
                                                    currency={currencyPunitiveInterest}
                                                    quantity={punitiveInterest}
                                                />
                                            </li>
                                        )}
                                        {codeSIOC !== "GOCPDA" &&
                                            codeSIOC !== "GOCPDAE" &&
                                            this.renderTaxItemsList(taxField, "taxFields")}
                                        {totalPayment !== 0 &&
                                            codeSIOC !== "PRE990" &&
                                            codeSIOC !== "GOVS" &&
                                            codeSIOC !== "GOAVBA" &&
                                            codeSIOC !== "GOAVON" && (
                                                <li className="data-wrapper">
                                                    <span className="data-label">
                                                        <I18n id="loan.detail.fees.detail.totalToPay.label" />
                                                    </span>
                                                    <FormattedAmount
                                                        currency={totalPaymentCurrency}
                                                        quantity={totalPayment}
                                                    />
                                                </li>
                                            )}
                                        {paymentSeries?.length > 0 &&
                                            codeSIOC !== "GOCPDA" &&
                                            codeSIOC !== "GOCPDAE" &&
                                            this.renderPaymentSeries(paymentSeries)}
                                    </ul>
                                </div>

                                <div className="details__footer">{this.renderDisclaimers()}</div>
                            </div>
                        </div>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    loan: loansSelectors.getSelectedLoan(state),
    statement: loansSelectors.getSelectedStatement(state),
    fetching: loansSelectors.getFetching(state),
    feeType: loansSelectors.getFilters(state).state,
});

export default connect(mapStateToProps)(StatementDetail);
