import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { func } from "prop-types";
import Image from "pages/_components/Image";

class Step4Content extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    send = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    render() {
        return (
            <form className="form-content container">
                <div className="ui-text-center">
                    <Image src="images/ui-icons/colored/ui-success.svg" className="svg-icon" />

                    <I18n
                        id="recoveryPassword.step4.text1"
                        componentProps={{ className: "data-strong ui-mt-3" }}
                        component="p"
                    />

                    <I18n id="recoveryPassword.step4.text2" component="p" componentProps={{ className: "ui-mb-0" }} />

                    <Button block label="global.login" onClick={this.send} bsStyle="primary" className="ui-mt-8" />
                </div>
            </form>
        );
    }
}

export default connect(null)(Step4Content);
