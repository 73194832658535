import React from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as formSelectors, actions as formActions } from "reducers/form";
import { selectors as investmentSelectors } from "reducers/investments";
import { selectors as miscSelectors } from "reducers/misc";

import { compose } from "redux";
import { bool, shape, func, string } from "prop-types";

import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormTransition from "../_components/FormTransition";

const ID_ACTIVITY = "fixedTermDeposits.cancelRenewal.send";
const CancelDepositRenewal = (props) => {
    const { data, dispatch, transaction, mode } = props;
    const { idTransaction } = transaction;
    const renderFields = () => (
        <>
            {mode !== "view" && (
                <div className="form-text-info">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.checkMessage" component="p" />
                </div>
            )}

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.transactionId" />
                </span>
                <span className="data-text">{data.idProduct}</span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.operationNumber" />
                </span>
                <span className="data-text">{data.operationCode}</span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.fixedTermDepositType" />
                </span>
                <span className="data-text">{data.descriptionSIOC}</span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.currency" />
                </span>
                <span className="data-text">{i18nUtils.get(`currency.name.${data.amount.currency}`)}</span>
                {/* Este dato creo que no es el correcto  */}
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.creationDate" />
                </span>
                <span className="data-text">
                    <FormattedDate date={data.openDate} />
                </span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.expirationDate" />
                </span>
                <span className="data-text">
                    <FormattedDate date={data.dueDate} />
                </span>
            </div>

            <div className="data-wrapper">
                <span className="data-label">
                    <I18n id="activities.fixedTermDeposits.cancelRenewal.depositedAmount" />
                </span>
                <span className="data-text">
                    <FormattedAmount quantity={data.amount.quantity} currency={data.amount.currency} />
                </span>
            </div>
        </>
    );

    const handleClickDownload = () => {
        dispatch(formActions.downloadTicketCustom(idTransaction, ID_ACTIVITY));
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = props.preData || {};

    const formProps = {
        title: `investments.listFixedTermDeposits.cancelRenewal${mode === "view" ? ".view.label" : ".label"}`,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },
        renderFields,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        returnBack: true,
        noPre: true,
        data: {
            operationOrder: data.operationOrder,
            idProduct: data.idProduct,
            operationCode: data.operationCode,
        },
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        formConfirmationClass: "cancel-deposit-renewal",
        confirmLabelEmpty: true,
        buttonLabel: "form.confirm.fixedTermDeposits.cancelRenewal.send",
        exportList: "pdf",
        handleClickDownload,
    };
    return <FormTransition {...props} {...formProps} />;
};
CancelDepositRenewal.propTypes = {
    mode: string.isRequired,
    dispatch: func.isRequired,
    location: shape({}).isRequired,
    documentTypes: shape({}).isRequired,
    fromBackoffice: bool,
    currentLang: string.isRequired,
    transaction: shape({}).isRequired,
    data: shape({}).isRequired,
    preData: shape({}),
    isDesktop: bool.isRequired,
    useDefaultSubmit: bool,
    deposit: shape({}).isRequired,
};

CancelDepositRenewal.defaultProps = {
    fromBackoffice: false,
    preData: null,
    useDefaultSubmit: true,
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    deposit: investmentSelectors.getDepositDetail(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    data: formSelectors.getData(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preData: formSelectors.getPreData(state),
    documentTypes: miscSelectors.getDocumentTypes(state),
});

export default compose(connect(mapStateToProps), withRouter)(CancelDepositRenewal);
