import React from "react";
import { connect } from "react-redux";
import { selectors, actions } from "reducers/session";
import * as i18n from "util/i18n";
import { bool, func, string } from "prop-types";
import I18n from "pages/_components/I18n";

import SimpleModal from "components/SimpleModal/SimpleModal";

const ExpireEnvironmentModal = ({ daysToExpire, environmentName, isOpen, dispatch, isDefaultExpired }) => {
    const closeAlert = () => {
        dispatch(actions.hideAlertEnvironmentExpire());
    };

    const getLabel = () => {
        let keyLabelExpire;

        if (daysToExpire) {
            keyLabelExpire = "environment.next.expire.label";
        } else if (isDefaultExpired) {
            keyLabelExpire = "environment.expired.label";
        } else {
            keyLabelExpire = "returnCode.API526W";
        }
        const labelExpire = i18n
            .get(keyLabelExpire)
            // eslint-disable-next-line
            .replace("${ENVIRONMENT}", `<b>${environmentName}</b>`);
        return labelExpire;
    };

    return (
        <SimpleModal
            isDisplayed={isOpen}
            closeModal={closeAlert}
            modalIcon={daysToExpire ? "images/warningIcon.svg" : "images/importantIcon.svg"}
            modalMainText=""
            showActions={false}
            buttonAction={() => {}}>
            <div className="ui-text-center">
                <I18n id={getLabel()} component="span" />
            </div>
        </SimpleModal>
    );
};

ExpireEnvironmentModal.propTypes = {
    daysToExpire: string,
    isOpen: bool.isRequired,
    dispatch: func.isRequired,
    environmentName: string,
    isDefaultExpired: bool.isRequired,
};

ExpireEnvironmentModal.defaultProps = {
    daysToExpire: null,
    environmentName: null,
};

const mapStateToProps = (state) => ({
    isOpen: selectors.isShowAlertExpire(state),
    daysToExpire: selectors.getDaysToExpire(state),
    environmentName: selectors.getEnvironamentNameExpire(state),
    isDefaultExpired: selectors.getIsDefaultExpired(state),
});

export default connect(mapStateToProps)(ExpireEnvironmentModal);
