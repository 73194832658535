import React from "react";
import { Modal } from "react-bootstrap";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { bool, func, node, string, object, oneOfType } from "prop-types";
import Image from "pages/_components/Image";

import "./_simpleModal.scss";

import DisclaimerContainer from "components/DisclaimerContainer/DisclaimerContainer";

const SimpleModal = ({
    buttonAction,
    buttonActionLabel,
    buttonCancelLabel,
    children,
    className,
    closeButton,
    closeModal,
    customCancellationFunction,
    defaultBigTitle,
    defaultMainText,
    disclaimer,
    isDisplayed,
    isDesktop,
    modalBigTitle,
    modalBigTitleParams,
    modalIcon,
    modalMainText,
    modalMainTextParams,
    noCancelButton,
    showActions,
    reverse,
    allowActionAndClose,
}) => (
    <Modal className={className} show={isDisplayed} onHide={closeModal}>
        <div className="modal-container">
            <Modal.Header closeButton={closeButton} />
            <Modal.Body>
                {modalIcon && (
                    <div className="illustration-wrapper">
                        <Image src={modalIcon} className="svg-big-icon" />
                    </div>
                )}

                {modalBigTitle && (
                    <I18n
                        id={modalBigTitle}
                        defaultValue={defaultBigTitle}
                        componentProps={{ className: "modal-bigTitle" }}
                        {...modalBigTitleParams}
                    />
                )}
                {modalMainText && (
                    <I18n
                        id={modalMainText}
                        defaultValue={defaultMainText}
                        componentProps={{ className: "modal-title" }}
                        {...modalMainTextParams}
                    />
                )}

                {children}
            </Modal.Body>
            {showActions && (
                <Modal.Footer>
                    {reverse && (
                        <Button
                            label={buttonActionLabel || "global.confirm"}
                            onClick={(e) => {
                                buttonAction(e);
                                if (allowActionAndClose) {
                                    closeModal();
                                }
                            }}
                            bsStyle="primary"
                        />
                    )}

                    {!noCancelButton && (
                        <Button
                            bsStyle="outline"
                            label={buttonCancelLabel || "global.cancel"}
                            onClick={customCancellationFunction ?? closeModal}
                        />
                    )}

                    {!reverse && (
                        <Button
                            onClick={(e) => {
                                buttonAction(e);
                                if (allowActionAndClose) {
                                    closeModal();
                                }
                            }}
                            bsStyle="primary"
                            label={buttonActionLabel || "global.confirm"}
                        />
                    )}
                </Modal.Footer>
            )}
            {disclaimer && (
                <DisclaimerContainer
                    disclaimer={disclaimer}
                    maxHeight={!isDesktop && "140px"}
                    textSmall
                    className="ui-mt-8 important-text"
                />
            )}
        </div>
    </Modal>
);

SimpleModal.propTypes = {
    buttonAction: func,
    buttonActionLabel: string,
    buttonCancelLabel: string,
    children: node,
    className: string,
    closeButton: bool,
    closeModal: func,
    customCancellationFunction: func,
    defaultBigTitle: string,
    defaultMainText: string,
    disclaimer: string,
    isDesktop: bool,
    isDisplayed: bool,
    modalBigTitle: string,
    modalBigTitleParams: oneOfType([object]),
    modalIcon: string,
    modalMainText: string,
    modalMainTextParams: oneOfType([object]),
    reverse: bool,
    showActions: bool,
    noCancelButton: bool,
    allowActionAndClose: bool,
};

SimpleModal.defaultProps = {
    buttonAction: null,
    buttonActionLabel: null,
    buttonCancelLabel: null,
    children: null,
    className: null,
    closeButton: true,
    closeModal: null,
    customCancellationFunction: null,
    defaultBigTitle: "",
    defaultMainText: "",
    disclaimer: null,
    isDesktop: true,
    isDisplayed: false,
    modalBigTitle: "",
    modalBigTitleParams: {},
    modalIcon: "",
    modalMainText: "",
    modalMainTextParams: {},
    reverse: false,
    showActions: true,
    noCancelButton: false,
    allowActionAndClose: true,
};

export default SimpleModal;
