import React from "react";
import { Field } from "formik";
import { bool, func, shape, string } from "prop-types";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import I18n from "pages/_components/I18n";
import Message from "../_fields/_scheduler/Message";

function CustomScheduler(props) {
    const {
        lang,
        mode,
        schedulerTooltip,
        schedulerLabelCustom,
        isDesktop,
        metadata: { enabledWeekDays, firstWorkingDate, maxDaysToSchedule, nonWorkingDays, programable, schedulable },
        scheduler,
        submitDate,
        modalTitleLabelCustom,
        disclaimerLabelCustom,
        isSubscriptionOrRescue,
        handleValueChange,
    } = props;

    const renderSchedulerOnEdit = () => {
        const data = {
            lang,
            mode,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            nonWorkingDays,
            programable,
            schedulable,
            isDesktop,
        };

        if (mode === "edit") {
            return (
                <Field
                    component={Scheduler}
                    data={data}
                    name="scheduler"
                    schedulerLabelCustom={schedulerLabelCustom}
                    schedulerTooltip={schedulerTooltip}
                    modalTitleLabelCustom={modalTitleLabelCustom}
                    disclaimerLabelCustom={disclaimerLabelCustom}
                    isSubscriptionOrRescue={isSubscriptionOrRescue}
                    handleValueChange={handleValueChange}
                />
            );
        }

        return null;
    };

    const renderSchedulerOnPreview = () => {
        if (mode === "preview" || mode === "view") {
            return (
                <div className="data-wrapper inline scheduler">
                    <span className="data-label">
                        <I18n id={schedulerLabelCustom} />
                    </span>
                    <span className="data-text">
                        <Message value={scheduler} submitValue={submitDate} />
                    </span>
                </div>
            );
        }

        return null;
    };

    return <> {mode === "edit" ? renderSchedulerOnEdit() : renderSchedulerOnPreview()}</>;
}

CustomScheduler.propTypes = {
    lang: string.isRequired,
    isDesktop: bool.isRequired,
    mode: string.isRequired,
    schedulerTooltip: shape({}),
    schedulerLabelCustom: string,
    metadata: shape({}),
    scheduler: shape({}),
    submitDate: string,
    modalTitleLabelCustom: string,
    disclaimerLabelCustom: string,
    isSubscriptionOrRescue: bool,
    handleValueChange: func,
};

CustomScheduler.defaultProps = {
    schedulerTooltip: null,
    schedulerLabelCustom: "forms.confirmation.scheduler.transfers",
    metadata: {
        schedulable: true,
        programable: true,
        nonWorkingDays: [],
        enabledWeekDays: [false, true, true, true, true, true, true, true],
        firstWorkingDate: new Date(),
        maxDaysToSchedule: 30,
    },
    scheduler: null,
    submitDate: null,
    modalTitleLabelCustom: null,
    disclaimerLabelCustom: null,
    isSubscriptionOrRescue: false,
    handleValueChange: null,
};

export default CustomScheduler;
