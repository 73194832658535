import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as configSelectors } from "reducers/config";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { string, shape, func, bool } from "prop-types";
import * as i18n from "util/i18n";
import GenericItem from "components/GenericItem/GenericItem";

class Statement extends Component {
    static propTypes = {
        statement: shape({}).isRequired,
        loanCurrency: string.isRequired,
        handleClick: func,
        isDesktop: bool,
    };

    static defaultProps = {
        handleClick: null,
        isDesktop: false,
    };

    handleClick = () => {
        const { handleClick, statement } = this.props;

        if (handleClick) {
            handleClick(statement);
        }
    };

    render() {
        const { loanCurrency, statement, isDesktop } = this.props;
        const { dueDate, paidDate, importAmount, feeNumber, stateDescription, arrears, currentBalance } = statement;
        if (!isDesktop) {
            return (
                <GenericItem role="button" onClick={this.handleClick} arrow>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.fee" componentProps={{ className: "data-label" }} />
                        <div className="data-text">{feeNumber}</div>
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.expiryDate" componentProps={{ className: "data-label" }} />
                        <span className="data-text">
                            <FormattedDate date={dueDate} />
                        </span>
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.state" componentProps={{ className: "data-label" }} />
                        <div className="data-text">{stateDescription}</div>
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.arrear" componentProps={{ className: "data-label" }} />
                        <div className="data-text">
                            {arrears > 0
                                ? `${arrears} ${i18n.get("loan.detail.fees.days.label")}`
                                : i18n.get("loans.list.emptyValue.label")}
                        </div>
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.paymentDate" componentProps={{ className: "data-label" }} />
                        <div className="data-text">
                            {paidDate ? <FormattedDate date={paidDate} /> : i18n.get("loans.list.emptyValue.label")}
                        </div>
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.payment" componentProps={{ className: "data-label" }} />
                        <FormattedAmount currency={loanCurrency} quantity={importAmount} />
                    </div>
                    <div className="data-wrapper">
                        <I18n id="loan.detail.fees.amount" componentProps={{ className: "data-label" }} />
                        <FormattedAmount currency={loanCurrency} quantity={currentBalance} />
                    </div>
                </GenericItem>
            );
        }
        return (
            <>
                <div className="table-data">
                    <span className="data-text">{feeNumber}</span>
                </div>
                <div className="table-data">
                    <span className="data-date">
                        <FormattedDate date={dueDate} />
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-aux">{stateDescription}</span>
                </div>
                <div className="table-data">
                    <span className="data-aux">
                        {arrears > 0
                            ? `${arrears} ${i18n.get("loan.detail.fees.days.label")}`
                            : i18n.get("loans.list.emptyValue.label")}
                    </span>
                </div>
                <div className="table-data">
                    <span className="data-date">{paidDate && <FormattedDate date={paidDate} />}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount currency={loanCurrency} quantity={importAmount} />
                </div>
                <div className="table-data">
                    <FormattedAmount currency={loanCurrency} quantity={currentBalance} />
                </div>
                <div className="table-data table-data-icon">
                    <ChevromRight />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    dateFormat: configSelectors.getConfig(state)["frontend.shortDateFormat"],
});

export default connect(mapStateToProps)(Statement);
