import { string, node } from "prop-types";
import React from "react";

import "./_tooltipUi.scss";

const TooltipUi = ({ target, position }) => {
    const refPositionData = target.getBoundingClientRect();

    const styles = {
        left: position === "right" && refPositionData?.x + refPositionData?.width + 10,
        top: position === "right" && refPositionData?.y - 52 - refPositionData?.height / 5,
        bottom: position === "top" && window.innerHeight - refPositionData?.bottom + refPositionData?.height,
    };

    return (
        <div className={`tooltip-ui ${position}`} style={styles}>
            {target?.dataset.tooltip}
        </div>
    );
};

TooltipUi.propTypes = {
    target: node,
    position: string.isRequired,
};

TooltipUi.defaultProps = {
    target: null,
};

export default TooltipUi;
