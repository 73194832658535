import React, { Component } from "react";
import { connect } from "react-redux";
import { func, number, shape, string, bool, array, oneOfType } from "prop-types";

import { selectors as investmentSelectors, actions as investmentActions } from "reducers/investments";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import SeuoLabel from "pages/accounts/SeuoLabel/SeuoLabel";
import * as i18nUtils from "util/i18n";
import * as dateUtils from "util/date";
import DetailHeadTitle from "./_components/DetailHeadTitle";
import DetailHeadInfo from "./_components/DetailHeadInfo";
import DepositAction from "./_components/DepositAction";
import Information from "./_components/Information";
import { selectors as widgetSelector } from "../../reducers/widgets";

const ID_FORM = "investments.listFixedTermDeposits";
const ID_FORM_DETAIL = `${ID_FORM}.detail`;
class DepositDetail extends Component {
    static propTypes = {
        deposit: shape({
            productType: string,
            idProduct: string,
            permissions: shape(),
            currency: string,
            openDate: string,
            dueDate: string,
            days: number,
            amount: number,
            adjustable: string,
            adjustmentAccrued: number,
            amountUVA: number,
            agreedRate: number,
            effectiveRate: number,
            totalInterest: number,
            interestsUVA: number,
            incomeTax: number,
            incomeTaxUVA: number,
            netToPay: number,
            netToPayUVA: number,
            baseQuote: number,
            lastQuoteUsed: number,
            renewable: string,
        }).isRequired,
        depositsList: oneOfType([array]).isRequired,
        fetching: bool.isRequired,
        match: shape().isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        activeRegion: string.isRequired,
        depositOrder: number.isRequired,
        history: shape({ goBack: func }),
        cancelledRenewalIdProduct: string,
        dataFromWidget: shape({}).isRequired,
        activeEnvironment: shape().isRequired,
    };

    static defaultProps = {
        history: null,
        cancelledRenewalIdProduct: null,
    };

    componentDidMount() {
        const { dispatch, depositOrder, ...rest } = this.props;
        const { id, order } = rest.match.params;
        dispatch(investmentActions.readDepositDetails(id, order));
    }

    handleOptionsClick = () => {
        const { dispatch } = this.props;

        dispatch(investmentActions.toggleOptions());
    };

    handleCancelRenewal = (deposit) => {
        const { idProduct, operationOrder, operationCode, descriptionSIOC } = deposit;
        const { dispatch, history } = this.props;
        const amount = {
            currency: deposit.currency,
            quantity: deposit.amount,
        };
        const dueDate = dateUtils.parseServiceDate(deposit.dueDate);
        const openDate = dateUtils.parseServiceDate(deposit.openDate);

        const payLoad = {
            idForm: "investments.listFixedTermDeposits.cancelRenewal",
            idActivity: "fixedTermDeposits.cancelRenewal.send",
            values: { idProduct, operationOrder, operationCode, descriptionSIOC, openDate, dueDate, amount },
        };
        dispatch(investmentActions.cancelDepositRenewalPreview(payLoad));
        history.push("/formCustom/cancelDepositRenewal");
    };

    renderCancelButton = () => {
        const { deposit, cancelledRenewalIdProduct, isDesktop, activeEnvironment } = this.props;

        const hasPermission = !!activeEnvironment.permissions.cancelDepositRenewal;

        const { renewable, idProduct } = deposit;

        return (
            renewable === "S" &&
            hasPermission &&
            (cancelledRenewalIdProduct === null || cancelledRenewalIdProduct !== idProduct) && (
                <DepositAction
                    isDesktop={isDesktop}
                    key="cancelRenewal"
                    labelKey={`${ID_FORM}.cancelRenewal.label`}
                    handleClick={() => this.handleCancelRenewal(deposit)}
                    className={isDesktop ? "btn-small" : "ui-mt-8"}
                />
            )
        );
    };

    render() {
        const {
            deposit,
            fetching,
            dispatch,
            isDesktop,
            activeRegion,
            history,
            cancelledRenewalIdProduct,
            depositsList,
            dataFromWidget,
            activeEnvironment,
            ...rest
        } = this.props;
        const {
            openDate,
            dueDate,
            days,
            currency,
            amount,
            adjustable,
            adjustmentAccrued,
            amountUVA,
            agreedRate,
            effectiveRate,
            totalInterest,
            interestsUVA,
            incomeTax,
            incomeTaxUVA,
            netToPay,
            netToPayUVA,
            baseQuote,
            lastQuoteUsed,
            renewable,
            idProduct,
        } = deposit;

        return (
            deposit && (
                <>
                    <Notification scopeToShow="fixedTermDeposit/details" />

                    <ProductDetail>
                        <ProductDetail.Head
                            onBack={history.goBack}
                            dispatch={dispatch}
                            handleOptionsClick={() => dispatch(investmentActions.toggleOptions())}
                            productId={rest.match.params.id}
                            hideDownloadButton
                            className="deposit-head"
                            customRightButton={this.renderCancelButton()}>
                            <DetailHeadInfo {...this.props} />
                        </ProductDetail.Head>
                    </ProductDetail>

                    <MainContainer showLoader={fetching}>
                        <div className="container-center">
                            <ProductDetail>
                                <DetailHeadTitle deposit={deposit} isDesktop={isDesktop} dispatch={dispatch} />
                                <Information
                                    deposit={deposit}
                                    keyLabel="fixedTermDeposit.information"
                                    dispatch={dispatch}
                                    activeRegion={activeRegion}
                                    idForm={ID_FORM}
                                />
                                <ProductDetail.Body isDesktop={isDesktop} fetching={fetching} hideTabs>
                                    <>
                                        <div className="details-container">
                                            <div className="details__default-content">
                                                <ul className="data-list inline">
                                                    <hr />
                                                    <div keyLabel="deposit.creationDate" className="data-wrapper">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.creationDate`)}
                                                        </span>
                                                        <span>
                                                            <FormattedDate date={openDate} />
                                                        </span>
                                                    </div>
                                                    <div keyLabel="deposit.days" className="data-wrapper">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.days`)}
                                                        </span>
                                                        <span>{days}</span>
                                                    </div>
                                                    <div keyLabel="deposit.expiryDate" className="data-wrapper">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM}.expiryDate.label`)}
                                                        </span>
                                                        <span>
                                                            <FormattedDate date={dueDate} />
                                                        </span>
                                                    </div>
                                                    <div keyLabel="deposit.currency" className="data-wrapper">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.currency`)}
                                                        </span>
                                                        <span>
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.currency.${currency}`)}
                                                        </span>
                                                    </div>
                                                    <div keyLabel="deposit.investedCapital" className="data-wrapper">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.investedCapital`)}
                                                        </span>
                                                        <FormattedAmount currency={currency} quantity={amount} />
                                                    </div>
                                                    {adjustable === "S" && (
                                                        <div
                                                            keyLabel="deposit.adjustmentAccrued"
                                                            className="data-wrapper">
                                                            <span className="data-label">
                                                                {i18nUtils.get(`${ID_FORM_DETAIL}.adjustmentAccrued`)}
                                                            </span>
                                                            <FormattedAmount
                                                                currency={currency}
                                                                quantity={adjustmentAccrued}
                                                            />
                                                        </div>
                                                    )}
                                                    {agreedRate !== 0 && (
                                                        <div keyLabel="deposit.agreedRate" className="data-wrapper">
                                                            <span className="data-label">
                                                                {i18nUtils.get(`${ID_FORM_DETAIL}.agreedRate`)}
                                                            </span>
                                                            <span>{`${agreedRate} %`}</span>
                                                        </div>
                                                    )}
                                                    {effectiveRate !== 0 && (
                                                        <div keyLabel="deposit.effectiveRate" className="data-wrapper">
                                                            <span className="data-label">
                                                                {i18nUtils.get(`${ID_FORM_DETAIL}.effectiveRate`)}
                                                            </span>
                                                            <span>{`${effectiveRate} %`}</span>
                                                        </div>
                                                    )}
                                                    {adjustable === "S" ? (
                                                        <>
                                                            <div keyLabel="deposit.amountUVA" className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.amountUVA`)}
                                                                </span>
                                                                <span>{amountUVA}</span>
                                                            </div>
                                                            <div
                                                                keyLabel="deposit.interestsUVA"
                                                                className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.interestsUVA`)}
                                                                </span>
                                                                <span>{interestsUVA}</span>
                                                            </div>
                                                            <div
                                                                keyLabel="deposit.incomeTaxUVA"
                                                                className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.incomeTaxUVA`)}
                                                                </span>
                                                                <span>{incomeTaxUVA}</span>
                                                            </div>
                                                            <div
                                                                keyLabel="deposit.netToPayUVA"
                                                                className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.netToPayUVA`)}
                                                                </span>
                                                                <span>{netToPayUVA}</span>
                                                            </div>
                                                            <div keyLabel="deposit.baseQuote" className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.baseQuote`)}
                                                                </span>
                                                                <FormattedAmount
                                                                    currency={currency}
                                                                    quantity={baseQuote}
                                                                />
                                                            </div>
                                                            <div
                                                                keyLabel="deposit.lastQuoteUsed"
                                                                className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.lastQuoteUsed`)}
                                                                </span>
                                                                <FormattedAmount
                                                                    currency={currency}
                                                                    quantity={lastQuoteUsed}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                keyLabel="deposit.totalInterest"
                                                                className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.totalInterest`)}
                                                                </span>
                                                                <FormattedAmount
                                                                    currency={currency}
                                                                    quantity={totalInterest}
                                                                />
                                                            </div>
                                                            <div keyLabel="deposit.incomeTax" className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.incomeTax`)}
                                                                </span>
                                                                <FormattedAmount
                                                                    currency={currency}
                                                                    quantity={incomeTax}
                                                                />
                                                            </div>
                                                            <div keyLabel="deposit.netToPay" className="data-wrapper">
                                                                <span className="data-label">
                                                                    {i18nUtils.get(`${ID_FORM_DETAIL}.netToPay`)}
                                                                </span>
                                                                <FormattedAmount
                                                                    currency={currency}
                                                                    quantity={netToPay}
                                                                />
                                                            </div>
                                                        </>
                                                    )}

                                                    <hr />

                                                    <div
                                                        keyLabel="deposit.renewable"
                                                        className="data-wrapper inline-fixed">
                                                        <span className="data-label">
                                                            {i18nUtils.get(`${ID_FORM_DETAIL}.renewal`)}
                                                        </span>
                                                        <mark>
                                                            {i18nUtils.get(
                                                                `${ID_FORM_DETAIL}.renewal.${
                                                                    cancelledRenewalIdProduct === null ||
                                                                    cancelledRenewalIdProduct !== idProduct
                                                                        ? renewable
                                                                        : "N"
                                                                }`,
                                                            )}
                                                        </mark>
                                                    </div>
                                                </ul>
                                            </div>

                                            <div className="details__footer">
                                                <SeuoLabel />
                                            </div>
                                        </div>
                                    </>
                                </ProductDetail.Body>
                            </ProductDetail>

                            {!isDesktop && this.renderCancelButton()}
                        </div>
                    </MainContainer>
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    depositOrder: investmentSelectors.getSelectedDeposit(state).operationOrder || null,
    deposit: investmentSelectors.getDepositDetail(state) || {},
    depositsList: investmentSelectors.getDeposits(state),
    fetching: investmentSelectors.getFetching(state),
    activeRegion: loginSelectors.getRegion(state),
    cancelledRenewalIdProduct: investmentSelectors.getCancelledRenewalIdProduct(state),
    dataFromWidget: widgetSelector.getWidget(state, "fixedTermDeposits"),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(DepositDetail);
