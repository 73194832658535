import { call, put, takeLatest } from "redux-saga/effects";

import * as comexMiddleware from "middleware/comex";
import { actions as notificationActions } from "reducers/notification";
import { actions as notificationModalActions } from "reducers/notificationModal";
import { downloadPdf } from "util/download";
import { types, actions } from "reducers/comex";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.RECEIPTS_COMEX_LIST_REQUEST, getReceiptsList),
    takeLatest(types.DOWNLOAD_RECEIPTS_COMEX_REQUEST, downloadReceiptComex),
    takeLatest(types.LOAD_MORE_RECEIPTS_COMEX_LIST_REQUEST, loadMoreReceiptsList),
];

export default sagas;

function* getReceiptsList({ filters }) {
    const response = yield call(comexMiddleware.listReceiptsComex, {
        ...filters,
    });
    if (response.type === "W") {
        yield put(actions.receiptsComexListFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["receiptsList"]));
    } else {
        const { receiptsList, pageNumber, totalPages, moreReceipts } = response.data.data;
        yield put(actions.receiptsComexListSuccess(receiptsList, pageNumber, totalPages, moreReceipts));
    }
}

function* loadMoreReceiptsList({ filters }) {
    const response = yield call(comexMiddleware.listReceiptsComex, {
        ...filters,
    });
    if (response.type === "W") {
        yield put(actions.loadMoreReceiptsComexListFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["receiptsList"]));
    } else {
        const { receiptsList, pageNumber, totalPages, moreReceipts } = response.data.data;
        yield put(actions.loadMoreReceiptsComexListSuccess(receiptsList, pageNumber, totalPages, moreReceipts));
    }
}
function* downloadReceiptComex({ path, fileName }) {
    const response = yield call(comexMiddleware.downloadReceipt, path, fileName);
    if (response.type === "W") {
        yield put(actions.downloadReceiptsComexFailure());
        yield put(notificationActions.showNotification(response.data.message, "error", ["receiptsList"]));
    } else {
        const { base64 } = response.data.data;
        if (base64) {
            downloadPdf(fileName, base64);
            yield put(actions.downloadReceiptsComexSuccess());
            yield put(
                notificationModalActions.showNotification(
                    i18n.get("comex.receiptsList.receipts.download.successMessage"),
                    "success",
                ),
            );
        } else {
            yield put(actions.downloadReceiptsComexFailure());
            yield put(
                notificationActions.showNotification(i18n.get("comex.receiptsList.receipts.downloadEmpty"), "error", [
                    "receiptsList",
                ]),
            );
        }
    }
}
